import { ActionItemDynamicFieldValueResponse } from "../../responses/action-items/action-item-dynamic-field-values-response";
import { Response } from "../../responses/common/response-response";
import {
    ActionItemDynamicFieldDetailsDto,
    createActionItemDynamicFieldDetailsDto,
} from "./action-item-dynamic-fields-dto";

export class ActionItemDynamicFieldValueDto {
    actionItemDynamicFieldId: number;
    actionItemId: number;
    value: string;
    actionItemDynamicFieldDetailsDto: ActionItemDynamicFieldDetailsDto;

    public constructor(response: ActionItemDynamicFieldValueResponse) {
        this.actionItemDynamicFieldId = response.actionItemDynamicFieldId;
        this.actionItemId = response.actionItemId;
        this.value = response.value;
        this.actionItemDynamicFieldDetailsDto = createActionItemDynamicFieldDetailsDto(
            response.actionItemDynamicFieldDetailsResponse
        );
    }

    public static toActionItemDynamicFieldValueDtos = (
        response: Response<ActionItemDynamicFieldValueResponse[]>
    ): ActionItemDynamicFieldValueDto[] =>
        response.data?.map((x) => new ActionItemDynamicFieldValueDto(x));
}
