import ReviewRating from "../../enums/review-reports/review-ratings";

export class PublishReviewReportSetDto {
    reviewReportSetId: number;
    reviewRating: ReviewRating;

    public constructor(reviewReportSetId: number, reviewRating: ReviewRating) {
        this.reviewReportSetId = reviewReportSetId;
        this.reviewRating = reviewRating;
    }
}
