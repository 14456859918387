import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
    ChecklistQuestions,
    Common,
    HierarchyTypeTreeView,
    Nodes,
} from "../../../core/constants/translation-namespace";
import { HierarchyMenuType } from "../../../core/hierarchyMenuType";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    PageSubHeading,
} from "../../../core/theme/global-styles";
import {
    AccordionTitles,
    DrawerTitles,
    HierarchyAssignableFlag,
    NavbarTitles,
} from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    isMutationLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { BaseNodeDetailsDto } from "../../../domain/dtos/hierarchy/base-node-details-dto";
import { createAssociateDissociateQuestionTaxonomyNodeDto } from "../../../domain/dtos/questions/associate-dissociate-question-taxonomy-node-dto";
import { useGetHierarchyTypesByHierarchyAssignableFlag } from "../../../domain/viewmodels/hierarchy/hierarchy-types-by-hierarchy-assignable-flag-viewmodel";
import {
    useAssociateTaxonomyNode,
    useGetTaxonomyNodeDetails,
} from "../../../domain/viewmodels/questions/associate-taxonomy-node-viewmodel";
import { AssociateButton, CancelButton } from "../../atoms/SbButton";
import { RadioSelectProps } from "../../molecules/DataTableAction";
import SbTabs, { SbTabItemProps } from "../../molecules/SbTabs";
import NodeHierarchyContainer from "../hierarchy/NodeHierarchyContainer";
import TextSearchContainer from "../hierarchy/TextSearchContainer";

const AssociateQuestionTaxonomyNodeContainer: React.FC = () => {
    const [selectedTaxonomyNode, setSelectedTaxonomyNode] = useState<number>();

    const navigate = useNavigate();
    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const questionId = Number(useParams().questionId);

    const getHierarchyTypesByHierarchyAssignableFlag =
        useGetHierarchyTypesByHierarchyAssignableFlag(HierarchyAssignableFlag.Question);
    const getAssociateTaxonomyNodeDetails = useGetTaxonomyNodeDetails(questionId);
    const associateTaxonomyNode = useAssociateTaxonomyNode();

    useLoader(
        areQueriesLoading([
            getAssociateTaxonomyNodeDetails,
            getHierarchyTypesByHierarchyAssignableFlag,
        ]) || isMutationLoading(associateTaxonomyNode),
        AssociateQuestionTaxonomyNodeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Questions);
    }, []);

    const radioSelect: RadioSelectProps = {
        type: "radio",
        label: `${t("SelectNode", { keyPrefix: HierarchyTypeTreeView })}`,
    };

    const selectTextSearchNode = (metadata: BaseNodeDetailsDto): void =>
        setSelectedTaxonomyNode(metadata.nodeId);

    const selectVisualSearchNode = (nodeId: number): void => setSelectedTaxonomyNode(nodeId);

    const selectedNode = (metadata: BaseNodeDetailsDto): boolean => {
        const taxonomyNode = metadata.nodeId;

        return taxonomyNode === selectedTaxonomyNode;
    };

    const handleAssociateTaxonomyNode = (): void => {
        associateTaxonomyNode.mutate(
            createAssociateDissociateQuestionTaxonomyNodeDto(questionId, selectedTaxonomyNode!),
            {
                onSuccess: async () => {
                    queryClient.invalidateQueries(["getAssociatedTaxonomyNodes"]);

                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "messageKey",
                            "SuccessfullyAssociatedTaxonomyNodeToQuestion"
                        ),
                    ];

                    navigateSearch(`${getPath(AccordionTitles.Questions)}/${questionId}`, params);
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    const buildSbTabItemProps = (): SbTabItemProps[] => {
        const textSearchContainer = (
            <TextSearchContainer
                mode={"select"}
                selectNode={selectTextSearchNode}
                selectedNode={selectedNode}
                radioSelect={radioSelect}
                hierarchyTypes={getHierarchyTypesByHierarchyAssignableFlag.data!}
            />
        );

        const triggerAdhocForUserComponent = (
            <NodeHierarchyContainer
                menuType={
                    HierarchyMenuType.Select |
                    HierarchyMenuType.Details |
                    HierarchyMenuType.ViewUsers
                }
                selectNode={selectVisualSearchNode}
                hierarchyTypes={getHierarchyTypesByHierarchyAssignableFlag.data!}
            />
        );

        return [
            new SbTabItemProps(t("TextSearch", { keyPrefix: Common }), textSearchContainer, true),
            new SbTabItemProps(
                t("VisualSearch", { keyPrefix: Common }),
                triggerAdhocForUserComponent
            ),
        ];
    };

    return (
        <>
            <PageHeading>{t("AssociateNode", { keyPrefix: Nodes })}</PageHeading>
            <PageSubHeading>
                {t("AssociateNodeHelpTextQuestionTaxonomy", { keyPrefix: ChecklistQuestions })}
            </PageSubHeading>
            <LargeVerticalSpace />

            {isQuerySuccessful(getAssociateTaxonomyNodeDetails) && (
                <>
                    <ContentContainer>
                        <Row>
                            <Col md="auto">
                                <DetailsLabel>
                                    {t("Question", { keyPrefix: ChecklistQuestions })}
                                </DetailsLabel>
                            </Col>
                            <Col md="auto">
                                <DetailsValue>{getAssociateTaxonomyNodeDetails.data}</DetailsValue>
                            </Col>
                        </Row>
                    </ContentContainer>
                    <LargeVerticalSpace />
                </>
            )}

            {isQuerySuccessful(getHierarchyTypesByHierarchyAssignableFlag) && (
                <ContentContainer>
                    <SbTabs values={buildSbTabItemProps()} />
                </ContentContainer>
            )}
            <LargeVerticalSpace />

            <EndAlignedDiv>
                <Col sm={"auto"}>
                    <AssociateButton
                        onClick={handleAssociateTaxonomyNode}
                        disabled={!selectedTaxonomyNode}
                    />
                </Col>
                <Col sm={"auto"}>
                    <CancelButton onClick={() => navigate(-1)} />
                </Col>
            </EndAlignedDiv>
        </>
    );
};

export default AssociateQuestionTaxonomyNodeContainer;
