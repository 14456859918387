import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import { filterLibraryChecklists } from "../../../data/services/checklists/checklist-service";
import { FilterChecklistsDto } from "../../dtos/checklists/filter-checklists-dto";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { createFilterChecklistsRequestFromDto } from "../../requests/checklists/filter-checklists-request";
import { LibraryChecklistResponse } from "../../responses/checklists/library-checklist-response";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";

export const useFilterLibraryChecklists = (
    filterChecklistsDto: FilterChecklistsDto
): UseQueryResult<PaginatedTableDto<number>, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterLibraryChecklists", filterChecklistsDto],
        () =>
            filterLibraryChecklists(
                url.baseUrl,
                createFilterChecklistsRequestFromDto(filterChecklistsDto)
            ),
        {
            keepPreviousData: true,
            select: transformToDataTableRows,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const transformToDataTableRows = (
    response: Response<PaginationResponse<LibraryChecklistResponse>>
): PaginatedTableDto<number> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((checklist) => ({
        metadata: checklist.checklistId,
        showRibbon: checklist.isTraining,
        columns: [
            {
                value: checklist.name.toString(),
                type: DataTableColumnTypes.LinkAndRibbon,
            },
            {
                value: checklist.modifiedByUserFullName?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: toDateAndTimeFormat(checklist.dateModifiedUtc.toString()),
                type: DataTableColumnTypes.Text,
            },
            {
                value: checklist.questionSetCount?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: checklist.checklistAssignmentCount?.toString(),
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
