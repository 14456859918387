import { ReactElement } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { DetailsLabel, DetailsValue, EndAlignedDiv } from "../../core/theme/global-styles";
import { CancelButton } from "../atoms/SbButton";
import { TextLink } from "../atoms/SbLink";
import { TextTitledPanel } from "../molecules/SbPanel";

export const StyledTableDataLabel = styled.td`
    padding-right: ${(props) => props.theme.padding.xl};
`;

export abstract class ConfirmationRow<TType> {
    label: string;
    values: (TType | null)[] | undefined;

    public constructor(label: string, values: (TType | null)[] | undefined) {
        this.label = label;
        this.values = values;
    }

    public abstract getJsxForValue(): JSX.Element[] | null;

    public getJsxForLabel = (): JSX.Element => <DetailsLabel>{this.label}</DetailsLabel>;
}

export class TextConfirmationRow extends ConfirmationRow<string> {
    public constructor(label: string, value: string | null | undefined) {
        super(label, value != undefined ? [value] : undefined);
    }

    public getJsxForValue = (): JSX.Element[] => [
        <DetailsValue>{this.values?.map((x, index) => [index > 0 && ", ", x])}</DetailsValue>,
    ];
}

export class CheckboxConfirmationRow extends ConfirmationRow<boolean> {
    public constructor(label: string, value: boolean | undefined) {
        super(label, value !== undefined ? [value] : undefined);
    }

    public getJsxForValue = (): JSX.Element[] | null =>
        this.values?.map((x) => (x != null ? <Form.Check defaultChecked={x} disabled /> : <></>)) ??
        null;
}

export class LinkConfirmationValue {
    value: string | undefined;
    navigationUrl: string;

    public constructor(value: string | undefined, navigationUrl: string) {
        this.value = value;
        this.navigationUrl = navigationUrl;
    }
}

export class LinkConfirmationRow extends ConfirmationRow<LinkConfirmationValue> {
    public constructor(label: string, values: LinkConfirmationValue[] | undefined) {
        super(label, values);
    }

    public getJsxForValue = (): JSX.Element[] | null =>
        this.values?.map((x, index) =>
            x ? (
                <>
                    {[
                        index > 0 && ", ",
                        <TextLink label={x.value || "-"} navigateTo={x.navigationUrl} />,
                    ]}
                </>
            ) : (
                <></>
            )
        ) ?? null;
}

export interface ActionConfirmationProps<TRowType, TRow extends ConfirmationRow<TRowType>> {
    panelTitle: string;
    rows: TRow[];
    primaryButton: ReactElement<typeof Button>;
    cancelButton?: ReactElement<typeof Button>;
    isNested?: boolean;
}

export const ActionConfirmation = <TRowType, TRow extends ConfirmationRow<TRowType>>({
    panelTitle,
    rows,
    primaryButton,
    cancelButton,
    isNested,
}: ActionConfirmationProps<TRowType, TRow>): JSX.Element => {
    const navigate = useNavigate();

    return (
        <TextTitledPanel title={panelTitle}>
            {rows.map((x) => (
                <Row>
                    <Col md={isNested ? 3 : 2}>
                        <StyledTableDataLabel>{x.getJsxForLabel()}</StyledTableDataLabel>
                    </Col>
                    <Col md={isNested ? 9 : 10}>{x.getJsxForValue()}</Col>
                </Row>
            ))}

            <EndAlignedDiv>
                <>
                    {primaryButton}
                    {cancelButton ? cancelButton : <CancelButton onClick={() => navigate(-1)} />}
                </>
            </EndAlignedDiv>
        </TextTitledPanel>
    );
};
