import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
    createBasePaginationRequestFromDto,
} from "../common/base-pagination-request";

export interface FilterActionItemDynamicFieldTextsRequest extends BasePaginationRequest {
    actionItemDynamicFieldId: number;
    isDefaultLanguage: boolean | null;
}

export const createFilterActionItemDynamicFieldTextsRequest = (
    actionItemDynamicFieldId: number,
    paginationDto: BasePaginationDto,
    isDefaultLanguage: boolean | null = null
): FilterActionItemDynamicFieldTextsRequest => ({
    actionItemDynamicFieldId: actionItemDynamicFieldId,
    isDefaultLanguage: isDefaultLanguage,
    ...createBasePaginationRequestFromDto(paginationDto),
});

export const buildFilterActionItemDynamicFieldTextsSearchParameters = (
    request: FilterActionItemDynamicFieldTextsRequest
): URLSearchParams => {
    const searchParams = buildBasePaginationSearchParameters(request);

    searchParams.set("actionItemDynamicFieldId", request.actionItemDynamicFieldId.toString());

    if (request.isDefaultLanguage != null) {
        searchParams.set("isDefaultLanguage", request.isDefaultLanguage.toString());
    }

    return searchParams;
};
