import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ActionItems } from "../../../core/constants/translation-namespace";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { PageHeading, SectionVerticalSpace } from "../../../core/theme/global-styles";
import { notificationTriggerColumnNames } from "../../../core/utilities/dataTableColumns";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import {
    BasePaginationDto,
    defaultBasePaginationDto,
} from "../../../domain/dtos/common/base-pagination-dto";
import { hasRoleTypeInGroup, NotificationTriggerRoleGroup } from "../../../domain/enums/Roles";
import { useFilterNotificationTriggers } from "../../../domain/viewmodels/notification-triggers/notification-triggers-viewmodel";
import { DataTable } from "../../organisms/DataTable";

const NotificationTriggersContainer: React.FC = () => {
    const [paginationDto, setPaginationDto] = useState<BasePaginationDto>(defaultBasePaginationDto);

    const menu = useMenu();
    const navigate = useNavigate();
    const auth = useAuth();
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });

    const filterNotificationTriggers = useFilterNotificationTriggers(paginationDto);

    const filterNotificationTriggersData = filterNotificationTriggers.data;

    useLoader(isQueryLoading(filterNotificationTriggers), NotificationTriggersContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.ActionTracker);
        menu.changeActiveDrawerItem(DrawerTitles.NotificationTriggers);
    }, []);

    const navigateToNotificationTriggerDetails = (notificationTriggerId: number): void =>
        navigate(`${getPath(DrawerTitles.NotificationTriggers)}/${notificationTriggerId}`);

    const navigateToEditNotificationTrigger = (notificationTriggerId: number): void =>
        navigate(`${getPath(DrawerTitles.NotificationTriggers)}/${notificationTriggerId}/edit`);

    return (
        <>
            <PageHeading>{t("NotificationTriggers")}</PageHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(filterNotificationTriggers) && (
                <>
                    <DataTable
                        keyPrefix={ActionItems}
                        columns={notificationTriggerColumnNames}
                        rows={filterNotificationTriggersData!.rows}
                        viewItem={navigateToNotificationTriggerDetails}
                        editItem={
                            hasRoleTypeInGroup(
                                auth.userRoles,
                                NotificationTriggerRoleGroup.WriteRoles
                            )
                                ? navigateToEditNotificationTrigger
                                : undefined
                        }
                        totalItems={filterNotificationTriggersData!.recordCount}
                        paginationDto={paginationDto}
                        setPaginationDto={setPaginationDto}
                    />
                </>
            )}
        </>
    );
};

export default NotificationTriggersContainer;
