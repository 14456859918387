import {
    createDisassociateLinkedNodeTypeDto,
    DisassociateLinkedNodeTypeDto,
} from "./disassociate-linked-node-type-dto";

export interface AssociateLinkedNodeTypeDto extends DisassociateLinkedNodeTypeDto {
    isRequired: boolean;
}

export const createAssociateLinkedNodeTypeDto = (
    primaryNodeTypeId: number,
    linkedNodeTypeId: number,
    isRequired: boolean
): AssociateLinkedNodeTypeDto => ({
    ...createDisassociateLinkedNodeTypeDto(primaryNodeTypeId, linkedNodeTypeId),
    isRequired: isRequired,
});
