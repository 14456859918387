import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    getActionPlanDetails,
    softDeleteActionPlan,
} from "../../../data/services/actions/action-plans-service";
import { ActionPlanDetailsDto } from "../../dtos/actions/action-plan-details-dto";
import { Response } from "../../responses/common/response-response";

export const useGetActionPlanDetails = (
    actionPlanId: number
): UseQueryResult<ActionPlanDetailsDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getActionPlanDetails", actionPlanId],
        () => getActionPlanDetails(url.baseUrl, actionPlanId),
        {
            select: ActionPlanDetailsDto.constructFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useSoftDeleteActionPlan = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    number
> => {
    const url = useUrl();

    return useMutation((actionPlanId: number) => softDeleteActionPlan(url.baseUrl, actionPlanId));
};
