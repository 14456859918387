export const answerTypeColumnToProperty: Record<string, string> = {
    Name: "name",
};

export const auditColumnToProperty: Record<string, string> = {
    "Event Date": "timestamp",
    "Event Time": "timestamp",
    "Event Type": "actionType",
    "Table Name": "tableName",
    "Primary Key": "entityKey",
    "User Name": "userName",
};

export const auditSecurityColumnToProperty: Record<string, string> = {
    "Event Date": "loginDateTime",
    "Event Time": "loginDateTime",
    "Event Type": "source",
    "User Name": "userName",
    "IP Address": "ipAddress",
};

export const checklistColumnToProperty: Record<string, string> = {
    Name: "name",
    "Last Modified By": "modifiedByUserFullName",
    "Event Date": "dateModified",
};

export const nonWorkingDaySetColumnToProperty: Record<string, string> = {
    Name: "name",
};

export const questionCategoryColumnToProperty: Record<string, string> = {
    Name: "name",
};

export const questionSetColumnToProperty: Record<string, string> = {
    Name: "name",
    Process: "process",
    "Schedule Description": "Description",
    "Last Modified By": "modifiedByUserFullName",
    "Last Date Modified": "dateModified",
};

export const referentialLinkTypeColumnToProperty: Record<string, string> = {
    Name: "name",
};

export const userManagementColumnToProperty: Record<string, string> = {
    "Last Name": "lastName",
    "First Name": "firstName",
    "Email Address": "email",
    "Modified By Username": "modifiedByUsername",
    "Date Modified": "dateModified",
};

export const actionPlanColumnToProperty: Record<string, string> = {
    Name: "name",
    Description: "description",
    "Action Items": "actionItemCount",
};

export const emailQueueColumnToProperty: Record<string, string> = {
    "Date Sent": "dateSent",
    Subject: "subject",
};
