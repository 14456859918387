import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SingleValue } from "react-select";
import { ChecklistAnswers, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { getEnumsForType } from "../../../core/utilities/enum-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isMutationLoading } from "../../../core/utilities/responseStateHelper";
import { createCreateAnswerTypeDto } from "../../../domain/dtos/answer-types/create-answer-type-dto";
import { createReactSelectDto, ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import AnswerCategory from "../../../domain/enums/answer-types/answer-category";
import AnswerFlagMode from "../../../domain/enums/answer-types/answer-flag-mode";
import AnswerRenderMode from "../../../domain/enums/answer-types/answer-render-mode";
import AnswerValueType from "../../../domain/enums/answer-types/answer-value-type";
import { Response } from "../../../domain/responses/common/response-response";
import { useCreateAnswerType } from "../../../domain/viewmodels/answer-types/create-answer-type-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import { translateText } from "../../helpers/translate";
import { translateWithFallback } from "../../helpers/translate-with-common-fallback";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const answerCategoriesArray = getEnumsForType(AnswerCategory).map((x) =>
    createReactSelectDto(x, AnswerCategory[x])
);

const answerFlagModesArray = getEnumsForType(AnswerFlagMode).map((x) =>
    createReactSelectDto(x, AnswerFlagMode[x])
);

const answerRenderModesArray = getEnumsForType(AnswerRenderMode).map((x) =>
    createReactSelectDto(x, AnswerRenderMode[x])
);

const answerValueTypesArray = getEnumsForType(AnswerValueType).map((x) =>
    createReactSelectDto(x, AnswerValueType[x])
);

const CreateAnswerTypeContainer: React.FC = () => {
    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const navigate = useNavigate();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistAnswers });
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const [answerTypeLookups, setAnswerTypeLookups] = useState({
        answerCategoryId: 0,
        valueTypeId: 0,
        renderModeId: 0,
        flagModeId: 0,
    });
    const [answerCategorySelected, setAnswerCategorySelected] = useState(0);

    const createAnswerType = useCreateAnswerType();

    useLoader(isMutationLoading(createAnswerType), CreateAnswerTypeContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.AnswerTypes);
    }, []);

    const onAnswerCategoryChange = (option: SingleValue<ReactSelectDto<AnswerCategory>>): void => {
        setAnswerCategorySelected(option!.value);
        setAnswerTypeLookups({
            ...answerTypeLookups,
            answerCategoryId: option!.value,
        });
    };

    const onRenderModeSelected = (option: SingleValue<ReactSelectDto<AnswerRenderMode>>): void =>
        setAnswerTypeLookups({
            ...answerTypeLookups,
            renderModeId: option!.value,
        });

    const onFlagModeSelected = (option: SingleValue<ReactSelectDto<AnswerFlagMode>>): void =>
        setAnswerTypeLookups({
            ...answerTypeLookups,
            flagModeId: option!.value,
        });

    const onAnswerValueTypeSelected = (
        option: SingleValue<ReactSelectDto<AnswerValueType>>
    ): void =>
        setAnswerTypeLookups({
            ...answerTypeLookups,
            valueTypeId: option!.value,
        });

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        createAnswerType.mutate(
            createCreateAnswerTypeDto(
                new FormData(event.currentTarget),
                answerTypeLookups.answerCategoryId,
                answerTypeLookups.renderModeId,
                answerTypeLookups.flagModeId,
                answerTypeLookups.valueTypeId
            ),
            {
                onSuccess: async (response: Response<number | null>) => {
                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "messageKey",
                            "SuccessfullyCreatedAnswerType"
                        ),
                    ];

                    navigateSearch(
                        `${getPath(AccordionTitles.AnswerTypes)}/${response.data}`,
                        params
                    );
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    return (
        <>
            <PageHeading>{t("AnswerTypeCreateTitle")}</PageHeading>
            <PageSubHeading>{t("AnswerTypeCreateHelperText")}</PageSubHeading>

            <SectionVerticalSpace />

            <TextTitledPanel title={t("CreateAnswerType")}>
                <Form onSubmit={handleSubmit}>
                    <SbFormTextFieldGroup
                        name="name"
                        label={t("Name")}
                        maxLength={50}
                        type="text"
                        required
                    />

                    <SbFormSelectFieldGroup
                        name={"answerCategoryId"}
                        label={t("AnswerCategory")}
                        placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                        searchable={false}
                        clearable
                        required
                        items={answerCategoriesArray ?? []}
                        itemDisplayText={(option: ReactSelectDto<AnswerCategory>) =>
                            t(AnswerCategory[option.value])
                        }
                        onChange={onAnswerCategoryChange}
                    />

                    {answerCategorySelected == AnswerCategory.MutuallyExclusive && (
                        <>
                            <SbFormSelectFieldGroup
                                name={"renderModeId"}
                                label={t("AnswerRenderMode")}
                                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                                searchable={false}
                                clearable
                                required
                                items={answerRenderModesArray ?? []}
                                itemDisplayText={(option: ReactSelectDto<AnswerRenderMode>) =>
                                    translateWithFallback(t, option.label, ChecklistAnswers)
                                }
                                onChange={onRenderModeSelected}
                            />

                            <SbFormSelectFieldGroup
                                name={"flagModeId"}
                                label={t("FlagMode")}
                                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                                searchable={false}
                                clearable
                                required
                                items={answerFlagModesArray ?? []}
                                itemDisplayText={(option: ReactSelectDto<AnswerFlagMode>) =>
                                    translateText(t, option.label, ChecklistAnswers)
                                }
                                onChange={onFlagModeSelected}
                            />
                        </>
                    )}

                    {answerCategorySelected == AnswerCategory.InputValue && (
                        <SbFormSelectFieldGroup
                            name={"answerValueTypeId"}
                            label={t("AnswerValueType")}
                            placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                            searchable={false}
                            clearable
                            required
                            items={answerValueTypesArray ?? []}
                            itemDisplayText={(option: ReactSelectDto<AnswerValueType>) =>
                                t(AnswerValueType[option.value], { keyPrefix: Common })
                            }
                            onChange={onAnswerValueTypeSelected}
                        />
                    )}

                    <EndAlignedDiv>
                        <SaveButton type="submit" />
                        <CancelButton onClick={() => navigate(-1)} />
                    </EndAlignedDiv>
                </Form>
            </TextTitledPanel>
        </>
    );
};

export default CreateAnswerTypeContainer;
