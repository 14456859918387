import RaciRelationship from "../../enums/action-items/RaciRelationship";
import { RaciParticipantDto } from "./raci-participant-dto";

export class CreateRaciSetUserDto {
    userId: number;
    raciRelationship: RaciRelationship;

    public constructor(userId: number, raciRelationship: RaciRelationship) {
        this.userId = userId;
        this.raciRelationship = raciRelationship;
    }

    public static toCreateRaciSetUserDtos = (
        dtos: RaciParticipantDto[],
        formData: FormData
    ): CreateRaciSetUserDto[] => {
        const createRaciUserDtos = [];
        formData.get("responsibleUser") &&
            createRaciUserDtos.push(
                new CreateRaciSetUserDto(
                    Number(formData.get("responsibleUser") as string),
                    RaciRelationship.Responsible
                )
            );

        formData.get("accountableUser") &&
            createRaciUserDtos.push(
                new CreateRaciSetUserDto(
                    Number(formData.get("accountableUser") as string),
                    RaciRelationship.Accountable
                )
            );

        dtos.map(
            (x) =>
                formData.get(`userId${x.index!.toString()}`) &&
                createRaciUserDtos.push(
                    new CreateRaciSetUserDto(
                        Number(formData.get(`userId${x.index!.toString()}`) as string),
                        Number(formData.get(`raciRelationship${x.index!.toString()}`) as string)
                    )
                )
        );

        return createRaciUserDtos;
    };

    public static toCreateRaciSetUserDtosForClone = (
        dtos: RaciParticipantDto[],
        formData: FormData
    ): CreateRaciSetUserDto[] => {
        const createRaciUserDtos: CreateRaciSetUserDto[] = [];

        dtos.map(
            (x) =>
                formData.get(`userId${x.index!.toString()}`) &&
                createRaciUserDtos.push(
                    new CreateRaciSetUserDto(
                        Number(formData.get(`userId${x.index!.toString()}`) as string),
                        Number(formData.get(`raciRelationship${x.index!.toString()}`) as string)
                    )
                )
        );

        return createRaciUserDtos;
    };
}
