import { HierarchyAssignable } from "../../enums/hierarchy/HierarchyAssignable";
import {
    createCreateHierarchyTypeRequest,
    CreateHierarchyTypeRequest,
} from "./create-hierarchy-type-request";

export interface EditHierarchyTypeRequest extends CreateHierarchyTypeRequest {
    hierarchyTypeId: number;
}

export const createEditHierarchyTypeRequest = (
    hierarchyTypeId: number,
    name: string,
    hierarchyAssignables: HierarchyAssignable[]
): EditHierarchyTypeRequest => ({
    hierarchyTypeId: hierarchyTypeId,
    ...createCreateHierarchyTypeRequest(name, hierarchyAssignables),
});
