import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { softDeleteQuestion } from "../../../data/services/questions/question-service";
import { Response } from "../../responses/common/response-response";

export const useSoftDeleteQuestion = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    number
> => {
    const url = useUrl();

    return useMutation((questionId: number) => softDeleteQuestion(url.baseUrl, questionId));
};
