import { WizardCardResponse } from "../../responses/wizards/wizard-card-response";

export interface WizardCardDto {
    cardId: number;
    heading: string;
    description: string;
    index: number;
    wizardId: number;
    fileDetailsId: number;
    fileImagePath: string;
}

export const toWizardCardDto = (wizardCardResponse: WizardCardResponse): WizardCardDto => ({
    cardId: wizardCardResponse.cardId,
    heading: wizardCardResponse.heading,
    description: wizardCardResponse.description,
    index: wizardCardResponse.index,
    wizardId: wizardCardResponse.wizardId,
    fileDetailsId: wizardCardResponse.fileDetailsId,
    fileImagePath: wizardCardResponse.fileImagePath,
});
