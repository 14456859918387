import AnswerFlagMode from "../../enums/answer-types/answer-flag-mode";

export interface EditPredefinedAnswerDto {
    predefinedAnswerId: number;
    flagMode: AnswerFlagMode | null;
    formData: FormData;
}

export const createEditPredefinedAnswerDto = (
    predefinedAnswerId: number,
    flagMode: AnswerFlagMode | null,
    formData: FormData
): EditPredefinedAnswerDto => ({
    predefinedAnswerId: predefinedAnswerId,
    flagMode: flagMode,
    formData: formData,
});
