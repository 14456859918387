import { convertToggleValueToBoolean } from "../../../core/utilities/convertToBoolean";
import { PeriodType } from "../../enums/PeriodType";

export class CreateActionItemNotificationTriggerDto {
    notificationTriggerId: number;
    sendWindowInMinutesPeriodType: PeriodType;
    sendWindowInMinutes: number;
    isEnabled: boolean;

    public constructor(formData: FormData, notificationTriggerId: number) {
        this.notificationTriggerId = notificationTriggerId;
        this.sendWindowInMinutesPeriodType = Number(
            formData.get(`sendWindowInMinutesPeriodType${notificationTriggerId}`) as string
        );
        this.sendWindowInMinutes = Number(
            formData.get(`sendWindowInMinutes${notificationTriggerId}`) as string
        );
        this.isEnabled = convertToggleValueToBoolean(
            formData.get(`isEnabled${notificationTriggerId}`) as string
        );
    }
}
