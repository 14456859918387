import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { getNullableFormField } from "../../../core/utilities/formDataHelper";
import { getNodeDetails, suspendNode } from "../../../data/services/hierarchy/nodes-service";
import { createNodeDetailsDto, NodeDetailsDto } from "../../dtos/hierarchy/node-details-dto";
import { createSuspendNodeRequest } from "../../requests/hierarchy/suspend-node-request";
import { Response } from "../../responses/common/response-response";

export const useGetNodeDetails = (nodeId: number): UseQueryResult<NodeDetailsDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(["useGetNodeDetails", nodeId], () => getNodeDetails(url.baseUrl, nodeId), {
        select: createNodeDetailsDto,
        onError: errorResponseToDisplayHandler,
    });
};

interface SuspendParams {
    nodeId: number;
    formData: FormData;
}

export const createSuspendParams = (nodeId: number, formData: FormData): SuspendParams => ({
    nodeId: nodeId,
    formData: formData,
});

export const useSuspendNode = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    SuspendParams
> => {
    const url = useUrl();

    return useMutation((mutationKey: SuspendParams) => {
        const { nodeId, formData } = mutationKey;
        const suspendFrom = getNullableFormField(formData.get("suspendFrom") as string);
        const suspendTo = getNullableFormField(formData.get("suspendTo") as string);

        const request = createSuspendNodeRequest(
            nodeId,
            suspendFrom != null ? new Date(suspendFrom) : null,
            suspendTo != null ? new Date(suspendTo) : null
        );

        return suspendNode(url.baseUrl, request);
    });
};
