import { Form } from "react-bootstrap";
import { toDateAndTimeFormat, toDateFormat } from "../../../core/utilities/date-helper";
import { BaseSbFieldProps } from "../../props/input/InputFieldProps";

export interface SbDateTimeFieldProps extends BaseSbFieldProps {
    value?: Date | null;
    defaultValue?: Date | null;
    excludeTime?: boolean;
    onInputChange?: (input: Date) => void;
}

const SbDateTimeField: React.FC<SbDateTimeFieldProps> = ({
    name,
    value,
    defaultValue,
    excludeTime,
    required,
    disabled,
    onInputChange,
}) => {
    const dateToString = (date: Date | null | undefined): string | null => {
        // 'date' types require the format to be in YYYY-MM-DD or else they won't be set

        let dateString = date
            ? toDateAndTimeFormat(date.toISOString()).replaceAll("/", "-").replaceAll(",", "")
            : null;

        if (excludeTime && date) {
            dateString = toDateFormat(date.toISOString()).replaceAll("/", "-");
        }

        return dateString;
    };

    return (
        <Form.Control
            name={name}
            type={excludeTime ? "date" : "datetime-local"}
            key={defaultValue ? defaultValue?.toString() : undefined}
            // If null/undefined are passed into the below two properties, the component
            // will ignore any external value management, therefore, if we want to clear
            // the value currently present in the input, we need to provide an empty
            // string, as opposed to null/undefined

            // However, we have two scenarios that we would like this to cater for:
            // 1. No external value management, value wont be passed in so that this
            //    component can manage its own value state
            // 2. External value management, value being passed in

            // In scenario 2, we would want to either pass in a date to be shown, or null,
            // to remove it, however, as per the first comment block, null/undefined doesnt
            // remove the input (it hands over value management to the component itself),
            // an empty string will

            // In scenario 1, we wont pass in a value and so it will be undefined, allowing
            // it to manage its value itself
            // Therefore, we treat undefined and null differently, having undefined mean
            // that the component will manage its state, and null meaning that the input is to be reset
            value={value === undefined ? undefined : (dateToString(value) ?? "")}
            defaultValue={
                defaultValue === undefined ? undefined : (dateToString(defaultValue) ?? "")
            }
            required={required}
            disabled={disabled}
            onChange={(event) => onInputChange && onInputChange(new Date(event.target.value))}
        />
    );
};

export default SbDateTimeField;
