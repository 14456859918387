import { IntervalResponse } from "../../responses/common/interval-response";
import { BaseIntervalDto } from "./base-interval-dto";

export class IntervalDto extends BaseIntervalDto {
    timeSpan: string;

    public constructor(response: IntervalResponse) {
        const { value, periodType, timeSpan } = response;
        super(value, periodType);

        this.timeSpan = timeSpan;
    }
}
