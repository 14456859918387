import { DayOrdinal } from "../../enums/questions/question-sets/DayOrdinalNumber";
import { DaysOfWeek } from "../../enums/questions/question-sets/DaysOfTheWeek";
import { FrequencyType } from "../../enums/questions/question-sets/FrequencyType";
import { MonthOfTheYear } from "../../enums/questions/question-sets/MonthOfTheYear";
import { NonWorkingDayRolloverType } from "../../enums/questions/question-sets/NonWorkingDayRolloverType";
import { ChecklistAssignmentDetailsResponse } from "../../responses/checklist-assignment/checklist-assignment-details-response";
import { QuestionSetAssignmentScheduleResponse } from "../../responses/checklists/question-set-assignment-schedule-response";
import { Response } from "../../responses/common/response-response";

export default class ScheduleDto {
    questionSetAssignmentScheduleId: number;
    isDefaultSchedule: boolean;
    frequencyType: FrequencyType;
    baseStartTimeOfDay: string;
    viewableMinutesBeforeBaseStart: number;
    viewableTimeBeforeBaseStart: number;
    viewableIntervalBeforeBaseStart: number;
    daysOfWeek: DaysOfWeek;
    dayOfMonth: number;
    dayOrdinal: DayOrdinal | null;
    monthOfYear: MonthOfTheYear;
    year: number;
    interval: number;
    maxOccurrences: number;
    nonWorkingDayRolloverType: NonWorkingDayRolloverType;
    scheduleDescription: string;
    isLockoutEnabled: boolean;
    lockoutMinutesAfterDue: number;
    viewableReminderRequired: boolean;

    public constructor(schedule: QuestionSetAssignmentScheduleResponse) {
        const {
            questionSetAssignmentScheduleId,
            isDefaultSchedule,
            frequencyType,
            baseStartTimeOfDay,
            viewableMinutesBeforeBaseStart,
            viewableIntervalBeforeBaseStart,
            viewableTimeBeforeBaseStart,
            daysOfWeek,
            dayOfMonth,
            dayOrdinal,
            monthOfYear,
            year,
            interval,
            maxOccurrences,
            nonWorkingDayRolloverType,
            scheduleDescription,
            isLockoutEnabled,
            lockoutMinutesAfterDue,
            viewableReminderRequired,
        } = schedule;

        this.questionSetAssignmentScheduleId = questionSetAssignmentScheduleId;
        this.isDefaultSchedule = isDefaultSchedule;
        this.frequencyType = frequencyType;
        this.baseStartTimeOfDay = baseStartTimeOfDay;
        this.viewableMinutesBeforeBaseStart = viewableMinutesBeforeBaseStart;
        this.viewableTimeBeforeBaseStart = viewableTimeBeforeBaseStart;
        this.viewableIntervalBeforeBaseStart = viewableIntervalBeforeBaseStart;
        this.daysOfWeek = daysOfWeek;
        this.dayOfMonth = dayOfMonth;
        this.dayOrdinal = dayOrdinal;
        this.monthOfYear = monthOfYear;
        this.year = year;
        this.interval = interval;
        this.maxOccurrences = maxOccurrences;
        this.nonWorkingDayRolloverType = nonWorkingDayRolloverType;
        this.scheduleDescription = scheduleDescription;
        this.isLockoutEnabled = isLockoutEnabled;
        this.lockoutMinutesAfterDue = lockoutMinutesAfterDue;
        this.viewableReminderRequired = viewableReminderRequired;
    }

    public static constructFromResponse = (
        questionSetAssignmentScheduleId: number,
        response: Response<ChecklistAssignmentDetailsResponse>
    ): ScheduleDto => {
        const questionSetAssignmentScheduleResponse =
            response.data.questionSetAssignmentScheduleResponses.filter(
                (x) => x.questionSetAssignmentScheduleId === questionSetAssignmentScheduleId
            );

        return new ScheduleDto(questionSetAssignmentScheduleResponse[0]);
    };
}
