import { PeriodType } from "../../enums/PeriodType";

export default class EditChecklistAssignmentQuestionScheduleDto {
    questionAssignmentScheduleId: number | null;
    requiredByTime: string;
    openIntervalBeforeDueTime: number | null;
    openPeriodTypeBeforeDueTime: PeriodType | null;
    escalationL1IntervalAfterDueTime: number | null;
    escalationL1PeriodTypeAfterDueTime: PeriodType | null;
    escalationL2IntervalAfterEscalationL1: number | null;
    escalationL2PeriodTypeAfterEscalationL1: PeriodType | null;
    escalationL3IntervalAfterEscalationL2: number | null;
    escalationL3PeriodTypeAfterEscalationL2: PeriodType | null;

    public constructor(
        questionAssignmentScheduleId: number | null,
        requiredByTime: string,
        openIntervalBeforeDueTime: number | null,
        openPeriodTypeBeforeDueTime: PeriodType | null,
        escalationL1IntervalAfterDueTime: number | null,
        escalationL1PeriodTypeAfterDueTime: PeriodType | null,
        escalationL2IntervalAfterEscalationL1: number | null,
        escalationL2PeriodTypeAfterEscalationL1: PeriodType | null,
        escalationL3IntervalAfterEscalationL2: number | null,
        escalationL3PeriodTypeAfterEscalationL2: PeriodType | null
    ) {
        this.questionAssignmentScheduleId = questionAssignmentScheduleId;
        this.requiredByTime = requiredByTime;
        this.openIntervalBeforeDueTime = openIntervalBeforeDueTime;
        this.openPeriodTypeBeforeDueTime = openPeriodTypeBeforeDueTime;
        this.escalationL1IntervalAfterDueTime = escalationL1IntervalAfterDueTime;
        this.escalationL1PeriodTypeAfterDueTime = escalationL1PeriodTypeAfterDueTime;
        this.escalationL2IntervalAfterEscalationL1 = escalationL2IntervalAfterEscalationL1;
        this.escalationL2PeriodTypeAfterEscalationL1 = escalationL2PeriodTypeAfterEscalationL1;
        this.escalationL3IntervalAfterEscalationL2 = escalationL3IntervalAfterEscalationL2;
        this.escalationL3PeriodTypeAfterEscalationL2 = escalationL3PeriodTypeAfterEscalationL2;
    }
}
