export interface EditNodeTypeValueRequest {
    nodeTypeValueId: number;
    value: string;
    code: string | null;
    description: string | null;
    roleIds: number[] | null;
}

export const createEditNodeTypeValueRequest = (
    nodeTypeValueId: number,
    value: string,
    code: string | null,
    description: string | null,
    roleIds: number[] | null
): EditNodeTypeValueRequest => ({
    nodeTypeValueId: nodeTypeValueId,
    value: value,
    code: code,
    description: description,
    roleIds: roleIds,
});
