import { BaseReferentialLinkTypeReferentialLinkResponse } from "../../responses/referential-links/base-referential-link-type-referential-link-response";
import BaseReferentialLinkDto from "./base-referential-link-dto";
import BaseReferentialLinkTypeDto from "./base-referential-link-type-dto";

export default class BaseReferentialLinkTypeReferentialLinkDto {
    referentialLinkTypeDto: BaseReferentialLinkTypeDto;
    referentialLinkDtos: BaseReferentialLinkDto[];

    public constructor(response: BaseReferentialLinkTypeReferentialLinkResponse) {
        const { referentialLinkTypeResponse, referentialLinkResponses } = response;

        this.referentialLinkTypeDto = new BaseReferentialLinkTypeDto(
            referentialLinkTypeResponse.referentialLinkTypeId,
            referentialLinkTypeResponse.name
        );
        this.referentialLinkDtos =
            BaseReferentialLinkDto.toBaseReferentialLinkDtos(referentialLinkResponses);
    }

    public static toBaseReferentialLinkTypeReferentialLinkDto = (
        responses: BaseReferentialLinkTypeReferentialLinkResponse[]
    ): BaseReferentialLinkTypeReferentialLinkDto[] =>
        responses.map((x) => new BaseReferentialLinkTypeReferentialLinkDto(x));
}
