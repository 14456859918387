import { ActionItemImportance } from "../../enums/action-items/ActionItemImportance";
import ActionItemInstanceEventResponse from "../../responses/action-items/action-item-instance-event-response";
import BaseActionItemEventDto from "./base-action-item-event-dto";

export default class ActionItemInstanceEventDto extends BaseActionItemEventDto {
    actionItemInstanceId: number;
    urgency: string;

    public constructor(response: ActionItemInstanceEventResponse) {
        super(response);

        const { actionItemInstanceId, urgency } = response;

        this.actionItemInstanceId = actionItemInstanceId;
        this.urgency = ActionItemImportance[urgency];
    }

    public static constructFromResponses = (
        responses: ActionItemInstanceEventResponse[]
    ): ActionItemInstanceEventDto[] => responses.map((x) => new ActionItemInstanceEventDto(x));
}
