import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { TableRow } from "../../../core/utilities/customTypes";
import { AccordionTitles, DataTableColumnTypes } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    associateNode,
    getActionItemDetails,
} from "../../../data/services/actions/action-items-service";
import { filterOrganisationalUserNodes } from "../../../data/services/hierarchy/nodes-service";
import { ActionItemDetailsDto } from "../../dtos/actions/action-item-details-dto";
import { AssociateNodeToActionItemDto } from "../../dtos/actions/associate-node-to-action-item-dto";
import {
    BaseNodeDetailsDto,
    createBaseNodeDetailsDto,
} from "../../dtos/hierarchy/base-node-details-dto";
import { AssociateNodeToActionItemRequest } from "../../requests/actions/associate-node-to-action-item-request";
import { Response } from "../../responses/common/response-response";
import { BaseNodeDetailsResponse } from "../../responses/hierarchy/base-node-details-response";

export const useGetAssociateActionItemDetails = (
    actionItemId: number
): UseQueryResult<ActionItemDetailsDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getActionItemDetails", actionItemId],
        () => getActionItemDetails(url.baseUrl, actionItemId),
        {
            select: ActionItemDetailsDto.constructFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useGetUserOrganisationalNodes = (
    userId: number
): UseQueryResult<TableRow<BaseNodeDetailsDto>[], HTTPError> => {
    const url = useUrl();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterOrganisationalUserNodes", userId],
        () => filterOrganisationalUserNodes(url.baseUrl, userId),
        {
            select: (response: Response<BaseNodeDetailsResponse[]>) =>
                toToDataTableBaseNodeDetailsDtoRows(response, navigate),
            onError: errorResponseToDisplayHandler,
        }
    );
};

const toToDataTableBaseNodeDetailsDtoRows = (
    response: Response<BaseNodeDetailsResponse[]>,
    navigate: NavigateFunction
): TableRow<BaseNodeDetailsDto>[] => {
    const rows = response.data.map((x) => ({
        metadata: createBaseNodeDetailsDto(x),
        showSelectAction: true,
        columns: [
            {
                value: x.shortDescription,
                type: DataTableColumnTypes.LinkAndRibbon,
                linkItemAction: (metadata: BaseNodeDetailsDto) =>
                    navigate(`${getPath(AccordionTitles.VisualTree)}/${metadata.nodeId}`),
                showRibbon: x.isTraining,
            },
            {
                value: x.path,
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return rows;
};

export const useAssociateActionItemNode = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    AssociateNodeToActionItemDto
> => {
    const url = useUrl();

    return useMutation((dto: AssociateNodeToActionItemDto) => {
        const request = new AssociateNodeToActionItemRequest(dto);

        return associateNode(url.baseUrl, request);
    });
};
