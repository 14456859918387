import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { createNonWorkingDaySet } from "../../../data/services/non-working-days/non-working-day-set-service";
import { Response } from "../../responses/common/response-response";

interface CreateParameters {
    formData: FormData;
}

export const useCreateNonWorkingDaySet = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    CreateParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: CreateParameters) => {
        const formData = mutationKey.formData;

        const request = {
            nonWorkingDaySetName: formData.get("name")! as string,
        };

        return createNonWorkingDaySet(url.baseUrl, request);
    });
};
