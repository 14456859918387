import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import { getWizards } from "../../../data/services/wizards/wizards-service";
import { Response } from "../../../domain/responses/common/response-response";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { PaginationDto } from "../../dtos/common/pagination-dto";
import { createWizardDto, WizardDto } from "../../dtos/wizards/wizard-dto";
import { createPaginationRequestFromDto } from "../../requests/common/pagination-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { WizardResponse } from "../../responses/wizards/wizard-response";

export const useGetWizards = (
    paginationDto: PaginationDto
): UseQueryResult<PaginatedTableDto<WizardDto>, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getWizards", paginationDto],
        () => getWizards(url.originalBaseUrl, createPaginationRequestFromDto(paginationDto)),
        {
            select: transformToDataTableRows,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const transformToDataTableRows = (
    data: Response<PaginationResponse<WizardResponse>>
): PaginatedTableDto<WizardDto> => {
    const responseData = data.data;

    const rows = responseData.results!.map((wizard) => ({
        metadata: createWizardDto(wizard),
        showEditAction: !wizard.sent,
        columns: [
            { value: wizard.name, type: DataTableColumnTypes.Link },
            {
                value: toDateAndTimeFormat(wizard.dateCreatedUtc.toString()),
                type: DataTableColumnTypes.Text,
            },
            { value: wizard.createdByUserName, type: DataTableColumnTypes.Text },
            {
                value: wizard.sent ? "Released" : "Not Released",
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: responseData.pageCount,
        pageSize: responseData.pageSize,
        currentPage: responseData.currentPage,
        recordCount: responseData.recordCount,
        rows: rows,
    };
};
