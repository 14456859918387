import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaSave } from "react-icons/fa";
import styled from "styled-components";
import { Common } from "../../core/constants/translation-namespace";
import {
    DetailsLabel,
    DetailsValue,
    StyledActionItemSectionContainer,
} from "../../core/theme/global-styles";
import { CancelButton, SbButton } from "../atoms/SbButton";

const StyledCommentTextArea = styled(Form.Control)`
    border-radius: ${(props) => props.theme.padding.sm};
    width: 65%;
`;

const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: ${(props) => props.theme.padding.md};
    padding-top: ${(props) => props.theme.padding.lg};
`;

const StyledDatePicker = styled.input`
    border-radius: ${(props) => props.theme.padding.sm};
    width: 20%;
`;

const ActionItemActionConfirmation: React.FC<{
    confirmationText: string;
    commentLabelText?: string;
    selectDate?: boolean;
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
    setShowActionItemActionConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
    confirmationText,
    commentLabelText,
    selectDate,
    handleSubmit,
    setShowActionItemActionConfirmation,
}) => {
    const { t } = useTranslation("translation");
    return (
        <StyledActionItemSectionContainer onSubmit={handleSubmit}>
            <DetailsLabel>{confirmationText}</DetailsLabel>

            {commentLabelText && (
                <>
                    <DetailsValue>{commentLabelText}</DetailsValue>
                    <StyledCommentTextArea
                        as="textarea"
                        name={"comment"}
                        rows={3}
                        maxLength={4000}
                    />
                </>
            )}

            <StyledButtonContainer>
                {selectDate && (
                    <StyledDatePicker
                        name={"dueDate"}
                        className="form-control"
                        type={"datetime-local"}
                        data-date-format="YYYY/MM/DD"
                    />
                )}
                <SbButton
                    variant="primary"
                    type="submit"
                    label={t("Confirm", { keyPrefix: Common })}
                    icon={FaSave}
                />
                <CancelButton onClick={() => setShowActionItemActionConfirmation(false)} />
            </StyledButtonContainer>
        </StyledActionItemSectionContainer>
    );
};

export default ActionItemActionConfirmation;
