export interface SearchDto {
    searchText: string | null;
    maxSearchResults: number | null;
}

export interface AnswerTypeSearchDto extends SearchDto {
    questionTypeId: number | null;
}

export const createSearchDto = (
    searchText: string | null,
    maxSearchResults: number | null
): SearchDto => ({
    searchText,
    maxSearchResults,
});

export const createAnswerTypeSearchDto = (
    searchText: string | null,
    questionTypeId: number | null,
    maxSearchResults: number | null
): AnswerTypeSearchDto => ({
    searchText,
    questionTypeId,
    maxSearchResults,
});

export const defaultSearchDto = (): SearchDto => createSearchDto(null, null);

export const defaultAnswerTypeSearchDto = (): AnswerTypeSearchDto =>
    createAnswerTypeSearchDto(null, null, null);

export interface AssuranceReviewSearchDto {
    searchText: string | null;
    assuranceReviewId: number | null;
}

export const createAssuranceReviewSearchDto = (
    searchText: string | null,
    assuranceReviewId: number | null
): AssuranceReviewSearchDto => ({
    searchText,
    assuranceReviewId,
});

export const defaultAssuranceReviewSearchDto = (): AssuranceReviewSearchDto =>
    createAssuranceReviewSearchDto(null, null);
