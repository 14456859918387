import { useQueries, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { getAllAggregationTypes } from "../../../data/services/aggregation-types/aggregation-types-service";
import { getAllAnswerThresholdOperators } from "../../../data/services/answer-threshold-operators/answer-threshold-operators-service";
import {
    lookupAnswerTypes,
    lookupQuestionCategories,
} from "../../../data/services/lookup/lookup-service";
import { getAllQuestionImportance } from "../../../data/services/question-importance/question-importance-service";
import { getAllQuestionTypes } from "../../../data/services/question-types/question-types-service";
import {
    AggregationTypeDto,
    toAggregationTypeDto,
} from "../../dtos/aggregation-type/aggregation-type-dto";
import {
    AnswerThresholdOperatorsDto,
    toAnswerThresholdOperatorsDto,
} from "../../dtos/answer-threshold-operators/answer-threshold-operators-dto";
import AnswerTypeDto from "../../dtos/answer-types/answer-type-dto";
import { AnswerTypeSearchDto, SearchDto } from "../../dtos/common/search-dto";
import {
    QuestionCategoryDto,
    toQuestionCategoryDtos,
} from "../../dtos/question-categories/question-category-dto";
import {
    QuestionImportanceDto,
    toQuestionImportanceDto,
} from "../../dtos/question-importance/question-importance-dto";
import { QuestionTypeDto, toQuestionTypeDto } from "../../dtos/question-type/question-type-dto";
import {
    createAnswerTypeSearchRequestFromDto,
    createSearchRequestFromDto,
} from "../../requests/common/search-request";
import { AggregationTypeResponse } from "../../responses/aggregation-type/aggregation-type-response";
import { AnswerThresholdOperatorsResponse } from "../../responses/answer-threshold-operators/answer-threshold-operators-response";
import { QuestionImportanceResponse } from "../../responses/question-importance/question-importance-response";
import { QuestionTypeResponse } from "../../responses/question-type/question-type-response";

export const useGetQuestionDropdownValues = (): [
    UseQueryResult<QuestionTypeResponse[], HTTPError>,
    UseQueryResult<QuestionImportanceResponse[], HTTPError>,
    UseQueryResult<AggregationTypeResponse[], HTTPError>,
    UseQueryResult<AnswerThresholdOperatorsResponse[], HTTPError>,
] => {
    const url = useUrl();

    const queries = useQueries({
        queries: [
            {
                queryKey: ["getAllQuestionTypes"],
                queryFn: () => getAllQuestionTypes(url.baseUrl),
                select: toQuestionTypeDto,
            },
            {
                queryKey: ["getAllQuestionImportance"],
                queryFn: () => getAllQuestionImportance(url.baseUrl),
                select: toQuestionImportanceDto,
            },
            {
                queryKey: ["getAllAggregationTypes"],
                queryFn: () => getAllAggregationTypes(url.baseUrl),
                select: toAggregationTypeDto,
            },
            {
                queryKey: ["getAllAnswerThresholdOperators"],
                queryFn: () => getAllAnswerThresholdOperators(url.baseUrl),
                select: toAnswerThresholdOperatorsDto,
            },
        ],
    });

    return queries as [
        UseQueryResult<QuestionTypeDto[], HTTPError>,
        UseQueryResult<QuestionImportanceDto[], HTTPError>,
        UseQueryResult<AggregationTypeDto[], HTTPError>,
        UseQueryResult<AnswerThresholdOperatorsDto[], HTTPError>,
    ];
};

export const useLookupQuestionCategories = (
    searchDto: SearchDto
): UseQueryResult<QuestionCategoryDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["lookupQuestionCategories", searchDto],
        () => lookupQuestionCategories(url.baseUrl, createSearchRequestFromDto(searchDto)),
        {
            select: toQuestionCategoryDtos,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useARMonitoringLookupQuestionCategories = (
    searchDto: SearchDto
): UseQueryResult<QuestionCategoryDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["lookupQuestionCategories", searchDto],
        () => lookupQuestionCategories(url.baseUrl, createSearchRequestFromDto(searchDto)),
        {
            select: toQuestionCategoryDtos,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useLookupAnswerTypes = (
    answerTypeSearchDto: AnswerTypeSearchDto
): UseQueryResult<AnswerTypeDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["lookupAnswerTypes", answerTypeSearchDto],
        () =>
            lookupAnswerTypes(
                url.baseUrl,
                createAnswerTypeSearchRequestFromDto(answerTypeSearchDto)
            ),
        {
            select: AnswerTypeDto.constructFromResponses,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useARMonitoringLookupAnswerTypes = (
    answerTypeSearchDto: AnswerTypeSearchDto
): UseQueryResult<AnswerTypeDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["lookupAnswerTypes", answerTypeSearchDto],
        () =>
            lookupAnswerTypes(
                url.baseUrl,
                createAnswerTypeSearchRequestFromDto(answerTypeSearchDto)
            ),
        {
            select: AnswerTypeDto.constructFromResponses,
            onError: errorResponseToDisplayHandler,
        }
    );
};
