export enum ActionInstanceCommentStatus {
    UserComment,
    ExtendedComment,
    OnHoldComment,
    CancelledComment,
    CompletedOverdueComment,
    ReActivateComment,
    ReOpen,
    CompletedComment,
}
