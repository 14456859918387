import FilterActionItemEventsRequest from "../../../domain/requests/action-items/filter-action-item-events-request";
import { ActionItemEventsResponse } from "../../../domain/responses/action-items/action-item-events-response";
import { Response } from "../../../domain/responses/common/response-response";
import client from "../../http-client";

const route = "actionitemevents";

export const filterActionItemEvents = async (
    prefixUrl: string,
    request: FilterActionItemEventsRequest
): Promise<Response<ActionItemEventsResponse>> => {
    const searchParams = request.buildSearchParameters();

    return await client
        .get(`${route}/filter`, {
            prefixUrl: prefixUrl,
            searchParams: searchParams,
        })
        .json();
};
