import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Common, Hierarchy, HierarchyTypes } from "../../../core/constants/translation-namespace";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    ContentContainer,
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import { useGetHierarchyImportDetails } from "../../../domain/viewmodels/hierarchy/hierarchy-import-details-viewmodel";
import { ViewLink } from "../../atoms/SbLink";
import { CreateLabelToDetailRows } from "../../molecules/CreateLabelToDetailRows";
import { ConfirmationRow, TextConfirmationRow } from "../../organisms/ActionConfirmation";

const HierarchyImportDetailsContainer = (): JSX.Element => {
    const menu = useMenu();
    const { t } = useTranslation("translation", { keyPrefix: Hierarchy });

    const hierarchyImportId = Number(useParams().hierarchyImportId);

    const getHierarchyImportDetails = useGetHierarchyImportDetails(hierarchyImportId);
    const hierarchyImportDetailsResponseData = getHierarchyImportDetails.data;

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.HierarchyImport);
    }, []);

    useLoader(isQueryLoading(getHierarchyImportDetails), HierarchyImportDetailsContainer);

    const confirmationRows = (): ConfirmationRow<any>[] => [
        new TextConfirmationRow(
            t("UploadFileName", { keyPrefix: Common }),
            hierarchyImportDetailsResponseData!.uploadedFileName
        ),
        new TextConfirmationRow(
            t("HierarchyTypeId", { keyPrefix: HierarchyTypes }),
            hierarchyImportDetailsResponseData!.hierarchyTypeId.toString()
        ),
        new TextConfirmationRow(
            t("CreatedBy", { keyPrefix: Common }),
            hierarchyImportDetailsResponseData!.userEmail
        ),
        new TextConfirmationRow(
            t("DateCreated", { keyPrefix: Common }),
            hierarchyImportDetailsResponseData!.createdDate
        ),
        new TextConfirmationRow(
            t("DateCompleted", { keyPrefix: Common }),
            hierarchyImportDetailsResponseData!.completedDate
        ),
        new TextConfirmationRow(
            t("Error", { keyPrefix: Common }),
            hierarchyImportDetailsResponseData!.errors
        ),
    ];

    return (
        <>
            <PageHeading>{t("HierarchyImportDetailsTitle")}</PageHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(getHierarchyImportDetails) && (
                <>
                    <ContentContainer>
                        {CreateLabelToDetailRows(confirmationRows(), 2, 10)}
                        <EndAlignedDiv>
                            <ViewLink
                                label={t("HierarchyImportList")}
                                navigateTo={`${getPath(AccordionTitles.HierarchyImport)}`}
                            />
                        </EndAlignedDiv>
                    </ContentContainer>
                </>
            )}
        </>
    );
};

export default HierarchyImportDetailsContainer;
