import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { suspendChecklistAssignment } from "../../../data/services/checklist-assignment/checklist-assignment-service";
import { createSuspendChecklistAssignmentRequest } from "../../requests/checklist-assignment/suspend-checklist-assignment-request";
import { Response } from "../../responses/common/response-response";

interface SuspendParameters {
    checklistAssignmentId: number;
    formData: FormData;
}

export const useSuspendChecklistAssignment = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    SuspendParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: SuspendParameters) => {
        const formData = mutationKey.formData;
        const request = createSuspendChecklistAssignmentRequest(
            mutationKey.checklistAssignmentId,
            new Date(formData.get("suspendFrom")! as string),
            new Date(formData.get("suspendTo")! as string)
        );

        return suspendChecklistAssignment(url.baseUrl, request);
    });
};
