import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import { filterUsers } from "../../../data/services/users/users-service";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { BaseUserDto, createBaseUserDtoFromUserResponse } from "../../dtos/users/base-user-dto";
import { FilterUsersDto } from "../../dtos/users/filter-users-dto";
import { createFilterUsersRequestFromDto } from "../../requests/users/filter-users-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { UserResponse } from "../../responses/users/user-response";

export const useFilterUsers = (
    filterUsersDto: FilterUsersDto
): UseQueryResult<PaginatedTableDto<BaseUserDto>, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterUsers", filterUsersDto],
        () => filterUsers(url.baseUrl, createFilterUsersRequestFromDto(filterUsersDto)),
        {
            keepPreviousData: true,
            select: transformToDataTableRows,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const transformToDataTableRows = (
    response: Response<PaginationResponse<UserResponse>>
): PaginatedTableDto<BaseUserDto> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((user) => ({
        metadata: createBaseUserDtoFromUserResponse(user),
        showEditAction: !user.isDeleted,
        showDeleteAction: !user.isDeleted,
        columns: [
            { value: user.lastName, type: DataTableColumnTypes.Text },
            { value: user.firstName, type: DataTableColumnTypes.Text },
            { value: user.emailAddress, type: DataTableColumnTypes.Text },
            { value: user.modifiedByEmailAddress, type: DataTableColumnTypes.Text },
            {
                value: toDateAndTimeFormat(user.dateModifiedUtc.toString()),
                type: DataTableColumnTypes.Text,
            },
            {
                value: user.isLockedOut ? "True" : "False",
                type: DataTableColumnTypes.Check,
            },
            {
                value: user.isDeleted ? "True" : "False",
                type: DataTableColumnTypes.Check,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
