export interface FilterActionItemDto {
    actionItemId: string | null;
    name: string | null;
    actionItemTypeNodeId: number | null;
    actionItemSubTypeNodeId: number | null;
    raciParticipant: string | null;
    actionItemImportanceId: number | null;
    actionInstanceStatusId: number | null;
    dueDateFrom: Date | null;
    dueDateTo: Date | null;
    actionItemNodeId: number | null;
}

export const createFilterActionItemsDto = (
    actionItemId: string | null,
    name: string | null,
    actionItemImportanceId: number | null,
    raciParticipant: string | null,
    actionItemTypeNodeId: number | null,
    actionItemSubTypeNodeId: number | null,
    actionInstanceStatusId: number | null,
    dueDateFrom: Date | null,
    dueDateTo: Date | null,
    actionItemNodeId: number | null
): FilterActionItemDto => ({
    actionItemId: actionItemId,
    name: name,
    actionItemImportanceId: actionItemImportanceId,
    raciParticipant: raciParticipant,
    actionItemTypeNodeId: actionItemTypeNodeId,
    actionItemSubTypeNodeId: actionItemSubTypeNodeId,
    actionInstanceStatusId: actionInstanceStatusId,
    dueDateFrom: dueDateFrom,
    dueDateTo: dueDateTo,
    actionItemNodeId: actionItemNodeId,
});

export const defaultFilterActionItemsDto: FilterActionItemDto = createFilterActionItemsDto(
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null
);
