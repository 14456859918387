export interface FilterNodeTypeHierarchyRequest {
    hierarchyTypeId: number;
}

export const createFilterNodeTypeHierarchyRequest = (
    hierarchyTypeId: number
): FilterNodeTypeHierarchyRequest => ({
    hierarchyTypeId: hierarchyTypeId,
});

export const buildFilterNodeTYpeHierarchySearchParameters = (
    request: FilterNodeTypeHierarchyRequest
): URLSearchParams => {
    const searchParams = new URLSearchParams();

    searchParams.set("hierarchyTypeId", request.hierarchyTypeId.toString());

    return searchParams;
};
