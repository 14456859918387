import { FilterAuditsSecurityLoginDto } from "../../dtos/audits/filter-audits-security-login-dto";
import {
    buildPaginationSearchParameters,
    createPaginationRequest,
    PaginationRequest,
} from "../common/pagination-request";

export interface FilterAuditsSecurityLoginRequest extends PaginationRequest {
    username: string | null;
    startDate: string | null;
    endDate: string | null;
    ipAddress: string | null;
    source: string | null;
    wasSuccessful: string | null;
}

export const createFilterAuditsSecurityLoginRequestFromDto = (
    dto: FilterAuditsSecurityLoginDto
): FilterAuditsSecurityLoginRequest => {
    const { pageNumber, pageSize, sortByColumn, sortByDescending } = dto;

    return {
        ...dto,
        ...createPaginationRequest(pageNumber, pageSize, sortByColumn, sortByDescending),
    };
};

export const buildFilterAuditsSecurityLoginSearchParameters = (
    request: FilterAuditsSecurityLoginRequest
): URLSearchParams => {
    const searchParams = buildPaginationSearchParameters(request);

    if (request.username) {
        searchParams.set("username", request.username);
    }

    if (request.startDate) {
        searchParams.set("startDate", request.startDate);
    }

    if (request.endDate) {
        searchParams.set("endDate", request.endDate);
    }

    if (request.ipAddress) {
        searchParams.set("ipAddress", request.ipAddress);
    }

    if (request.source) {
        searchParams.set("source", request.source);
    }

    if (request.wasSuccessful) {
        searchParams.set("wasSuccessful", request.wasSuccessful);
    }

    return searchParams;
};
