import { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { PieChart } from "react-minimal-pie-chart";
import styled, { ThemeContext } from "styled-components";
import { ActionItems } from "../../core/constants/translation-namespace";
import {
    createNavigateSearchParameter,
    NavigateSearchParameter,
    useNavigateSearch,
} from "../../core/hooks/navigateSearch";
import { DetailsLabel, DetailsValue, LargeVerticalSpace } from "../../core/theme/global-styles";
import { DrawerTitles } from "../../core/utilities/enums";
import { getPath } from "../../core/utilities/getPath";
import { ActionItemSummaryDto } from "../../domain/dtos/action-items/action-item-summary-dto";
import { ActionInstanceOnHoldStatus } from "../../domain/enums/action-items/ActionInstanceOnHoldStatus";
import { ActionInstanceStatus } from "../../domain/enums/action-items/ActionInstanceStatus";
import RaciRelationship from "../../domain/enums/action-items/RaciRelationship";

const StyledDataContainer = styled.div`
    align-items: center;
    width: 20%;
    padding: 2%;
    box-shadow: 0 3px 6px ${(props) => props.theme.palette.grey};
    background-color: ${(props) => props.theme.palette.purewhite};
    margin-left: ${(props) => props.theme.padding.md};
    margin-right: ${(props) => props.theme.padding.md};
    margin-bottom: ${(props) => props.theme.padding.md};
`;

const StyledRow = styled(Row)`
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: ${(props) => props.theme.padding.xs};
    padding-bottom: ${(props) => props.theme.padding.xs};
`;

const StyledHr = styled.hr`
    border-bottom: 3px double ${(props) => props.theme.palette.black};
`;

const StyledLeftCol = styled(Col)`
    padding: 0;
`;

const StyledRightCol = styled(Col)`
    padding-left: ${(props) => props.theme.padding.sm};
`;

export const ActionItemSummaryCard: React.FC<{
    actionItemSummary: ActionItemSummaryDto;
}> = ({ actionItemSummary }) => {
    const [, setHovered] = useState<number | undefined>(undefined);
    const [lineWidth, setLineWidth] = useState<number>(20);

    const navigateSearch = useNavigateSearch();
    const themeContext = useContext(ThemeContext);

    const { t } = useTranslation("translation");

    const onHoldCount = actionItemSummary.onHoldCount;
    const overdueCount = actionItemSummary.overdueCount;
    const dueCount = actionItemSummary.dueCount;
    const openCount = actionItemSummary.openCount;
    const pendingCount = actionItemSummary.pendingCount;
    const actionItemCount = actionItemSummary.actionItemCount;
    const raciRelationship = actionItemSummary.raciRelationship;

    const responseData = [
        {
            title: onHoldCount,
            value: onHoldCount,
            color: themeContext!.palette.actionInstanceStatus.onhold,
        },
        {
            title: overdueCount,
            value: overdueCount,
            color: themeContext!.palette.actionInstanceStatus.overdue,
        },
        {
            title: dueCount,
            value: dueCount,
            color: themeContext!.palette.actionInstanceStatus.due,
        },
        {
            title: openCount,
            value: openCount,
            color: themeContext!.palette.actionInstanceStatus.open,
        },
        {
            title: pendingCount,
            value: pendingCount,
            color: themeContext!.palette.actionInstanceStatus.pending,
        },
    ];

    const actionInstanceStatusMapper = [
        {
            dataIndex: 1,
            actionInstanceStatus: ActionInstanceStatus.Overdue,
        },
        {
            dataIndex: 2,
            actionInstanceStatus: ActionInstanceStatus.Due,
        },
        {
            dataIndex: 3,
            actionInstanceStatus: ActionInstanceStatus.Open,
        },
        {
            dataIndex: 4,
            actionInstanceStatus: ActionInstanceStatus.Pending,
        },
    ];

    const getActionInstanceStatusByDataIndex = (
        dataIndex: Number
    ): ActionInstanceStatus | undefined => {
        const actionInstanceStatus = actionInstanceStatusMapper.filter(
            (x) => x.dataIndex === dataIndex
        )[0];
        return actionInstanceStatus && actionInstanceStatus.actionInstanceStatus;
    };

    const handleOnClick = (dataIndex: number): void => {
        let params: NavigateSearchParameter[] = [];
        params.push(createNavigateSearchParameter("raciRelationship", raciRelationship.toString()));

        if (dataIndex === 0) {
            params.push(
                createNavigateSearchParameter(
                    "actionInstanceOnHoldStatus",
                    ActionInstanceOnHoldStatus.OnHold.toString()
                )
            );
        } else {
            const actionInstanceStatus = getActionInstanceStatusByDataIndex(dataIndex);

            if (actionInstanceStatus) {
                params.push(
                    createNavigateSearchParameter(
                        "actionInstanceStatusId",
                        Number(actionInstanceStatus).toString()
                    )
                );
            }

            params.push(
                createNavigateSearchParameter(
                    "actionInstanceOnHoldStatus",
                    ActionInstanceOnHoldStatus.NotOnHold.toString()
                )
            );
        }

        navigateSearch(getPath(DrawerTitles.MyActionItems), params);
    };

    return (
        <StyledDataContainer>
            <>
                <Row>
                    <StyledLeftCol md="auto">
                        <DetailsLabel>{"RACI:"}</DetailsLabel>
                    </StyledLeftCol>
                    <StyledRightCol>
                        <DetailsValue>{RaciRelationship[raciRelationship]}</DetailsValue>
                    </StyledRightCol>
                </Row>
                <Row>
                    <StyledLeftCol md="auto">
                        <DetailsLabel>{t("ActionItems", { keyPrefix: ActionItems })}:</DetailsLabel>
                    </StyledLeftCol>
                    <StyledRightCol>
                        <DetailsValue>{actionItemCount.toString()}</DetailsValue>
                    </StyledRightCol>
                </Row>
            </>

            <Row>
                <StyledHr />
            </Row>

            <LargeVerticalSpace />
            <StyledRow>
                <PieChart
                    lineWidth={lineWidth}
                    totalValue={actionItemCount}
                    radius={50}
                    paddingAngle={1}
                    label={() => actionItemCount}
                    labelStyle={{
                        fontSize: "10px",
                        fontFamily: "sans-serif",
                        fill: "#000000",
                    }}
                    labelPosition={0}
                    segmentsStyle={{ transition: "stroke .3s", cursor: "hand" }}
                    onClick={(_, index) => {
                        handleOnClick(index);
                    }}
                    onMouseOver={(_, index) => {
                        setHovered(index);
                        setLineWidth(25);
                    }}
                    onMouseOut={() => {
                        setHovered(undefined);
                        setLineWidth(20);
                    }}
                    data={responseData}
                    animate
                ></PieChart>
            </StyledRow>
        </StyledDataContainer>
    );
};
