import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    editQuestionSet,
    getQuestionSetDetails,
} from "../../../data/services/question-set/question-set-service";
import {
    createQuestionSetDetailsDto,
    QuestionSetDetailsDto,
} from "../../dtos/question-sets/question-set-details-dto";
import { QuestionSetScheduleRequest } from "../../requests/question-set/create-question-set-schedule-request";
import { Response } from "../../responses/common/response-response";

interface EditParameters {
    questionSetId: number;
    formData: FormData;
}

export const useEditQuestionSet = (
    questionSetScheduleRequest: QuestionSetScheduleRequest
): UseMutationResult<Response<boolean>, HTTPError, EditParameters> => {
    const url = useUrl();

    return useMutation((mutationKey: EditParameters) => {
        const formData = mutationKey.formData;

        const request = {
            questionSetId: mutationKey.questionSetId,
            name: formData.get("name")! as string,
            description: formData.get("description")! as string,
            process: formData.get("process")! as string,
            questionSetScheduleRequest: questionSetScheduleRequest,
        };

        return editQuestionSet(url.baseUrl, request);
    });
};

export const useGetQuestionSetDetails = (
    questionSetId: number
): UseQueryResult<QuestionSetDetailsDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getQuestionSetDetails", questionSetId],
        () => getQuestionSetDetails(url.baseUrl, questionSetId),
        {
            keepPreviousData: true,
            select: createQuestionSetDetailsDto,
            onError: errorResponseToDisplayHandler,
        }
    );
};
