import { FilterFinalizedActionItemsDto } from "../../dtos/action-items/filter-finalized-action-items-dto";
import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import { ReactSelectDto } from "../../dtos/common/react-select-dto";
import RaciRelationship from "../../enums/action-items/RaciRelationship";
import {
    buildFilterSearchActionItemsParameters,
    FilterActionItemsRequest,
} from "../actions/filter-action-items-request";
import { createBasePaginationRequest } from "../common/base-pagination-request";

export interface FilterFinalizedActionItemsRequest extends FilterActionItemsRequest {
    raciRelationship: ReactSelectDto<RaciRelationship>;
}

export const createFilterFinalizedActionItemsRequest = (
    dto: FilterFinalizedActionItemsDto,
    paginationDto: BasePaginationDto
): FilterFinalizedActionItemsRequest => ({
    ...dto,
    ...createBasePaginationRequest(paginationDto.pageNumber, paginationDto.pageSize),
});

export const buildFilterSearchFinalizedActionItemsParameters = (
    request: FilterFinalizedActionItemsRequest
): URLSearchParams => {
    const searchParams = buildFilterSearchActionItemsParameters(request);

    if (request.raciRelationship && Number(request.raciRelationship.value) !== 0) {
        searchParams.set("raciRelationship", request.raciRelationship.value.toString());
    }

    return searchParams;
};
