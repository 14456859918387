import { RemoveResolveReviewFindingDto } from "../../dtos/review-reports/remove-review-finding-dto";

export class RemoveResolveReviewFindingRequest {
    questionSetInstanceAnswerId: number;
    questionSetInstanceReviewId: number;
    comment: string;

    public constructor(dto: RemoveResolveReviewFindingDto) {
        this.questionSetInstanceAnswerId = dto.questionSetInstanceAnswerId;
        this.questionSetInstanceReviewId = dto.questionSetInstanceReviewId;
        this.comment = dto.comment;
    }
}
