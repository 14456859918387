import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SingleValue } from "react-select";
import { EnterKey } from "../../core/constants/KeyboardKeys";
import { Common, Home, Tenants } from "../../core/constants/translation-namespace";
import useKeyPress from "../../core/hooks/keyPress";
import { useAuth } from "../../core/store/auth-context";
import { useMenu } from "../../core/store/menu-context";
import {
    PageHeading,
    PageSubHeading,
    SectionContainer,
    SectionVerticalSpace,
} from "../../core/theme/global-styles";
import { UsersInRoleColumnNames } from "../../core/utilities/dataTableColumns";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../core/utilities/enums";
import { getPath } from "../../core/utilities/getPath";
import { isQuerySuccessful } from "../../core/utilities/responseStateHelper";
import { getNullableString } from "../../core/utilities/string-helper";
import {
    createUsersInRoleUserDto,
    UsersInRoleDto,
} from "../../domain/dtos/dashboard/filter-users-in-role-dto";
import { RoleDto } from "../../domain/dtos/users/role-dto";
import { createUsersInRoleRequestFromDto } from "../../domain/requests/dashboard/users-in-role-request";
import { UsersInRoleResponse } from "../../domain/responses/dashboard/users-in-role-response";
import {
    useGetRoles,
    useGetUsersInRole,
} from "../../domain/viewmodels/dashboard/dashboard-container-viewmodel";
import { SbAlert } from "../atoms/SbAlert";
import { SbAccordion } from "../molecules/SbAccordion";
import { ComponentPanel } from "../molecules/SbPanel";
import { DataTable } from "../organisms/DataTable";
import { UsersInRoleFilter } from "../organisms/filters/UsersInRoleFilter";

const DashboardContainer: React.FC = () => {
    const menu = useMenu();
    const auth = useAuth();
    const navigate = useNavigate();

    const { t } = useTranslation("translation", { keyPrefix: Home });

    const getRoles = useGetRoles();
    const getRolesData = getRoles.data;

    const allFilterStatusOption: RoleDto = {
        name: "All",
        roleId: -1,
    };

    const [filter, setFilter] = useState(createUsersInRoleUserDto(null, null, null, 1, 5));
    const [searchParams, setSearchParams] = useState<UsersInRoleDto>(
        createUsersInRoleUserDto(null, null, null, 1, 5)
    );
    const [role, setRole] = useState<SingleValue<RoleDto>>(allFilterStatusOption);

    const getUsersInRole = useGetUsersInRole(filter);
    const getUsersInRoleData = getUsersInRole?.data?.rows;

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Dashboard);
    }, [auth.isImpersonating]);

    const changeEmailAddress = (emailAddress: string): void => {
        setSearchParams({ ...searchParams, emailAddress: getNullableString(emailAddress) });
    };

    const changeRole = (role: SingleValue<RoleDto>): void => {
        setRole(role);
        if (role?.roleId == -1) {
            setSearchParams({
                ...searchParams,
                roleIds: [...(getRolesData?.map((x) => x.roleId) ?? [])],
            });
        } else {
            setSearchParams({ ...searchParams, roleIds: [role?.roleId ? role.roleId : -1] });
        }
    };

    const changeUserId = (userId: string): void => {
        setSearchParams({ ...searchParams, userId: getNullableString(userId) });
    };

    const search = () => {
        setFilter(createUsersInRoleRequestFromDto(searchParams));
    };

    const resetFilter = () => {
        setSearchParams(createUsersInRoleUserDto(null, null, null, 1, 5));
        setFilter(createUsersInRoleUserDto(null, null, null, 1, 5));
        setRole(allFilterStatusOption);
    };

    useKeyPress(EnterKey, search, searchParams);

    const navigateToViewUser = (metadata: UsersInRoleResponse): void =>
        navigate(`${getPath(AccordionTitles.Users)}/${metadata.userId}`);
    return (
        <>
            <PageHeading>{t("WelcomeToAtlas")}</PageHeading>
            <PageSubHeading>
                {t("CFWelcomeMessage")} {t("CFWelcomeMessage2")}
            </PageSubHeading>
            {auth.isAdmin && auth.isImpersonating && isQuerySuccessful(getRoles) ? (
                <>
                    <SbAccordion title={t("UsersInRolePanelTitle")}>
                        <SectionVerticalSpace />
                        <SectionContainer>
                            <ComponentPanel>
                                <UsersInRoleFilter
                                    userId={searchParams.userId?.toString()}
                                    changeUserId={changeUserId}
                                    emailAddress={searchParams.emailAddress}
                                    changeEmailAddress={changeEmailAddress}
                                    role={role}
                                    roles={[allFilterStatusOption, ...getRolesData!]}
                                    changeRole={changeRole}
                                    search={search}
                                    resetFilter={resetFilter}
                                />
                            </ComponentPanel>
                            {isQuerySuccessful(getUsersInRole) && (
                                <DataTable
                                    columns={UsersInRoleColumnNames}
                                    rows={getUsersInRoleData}
                                    noResultsMessage={`${t("NoRecordsFound", {
                                        keyPrefix: Common,
                                    })}`}
                                    viewItem={navigateToViewUser}
                                    totalItems={getUsersInRole.data!.recordCount}
                                    paginationDto={filter}
                                    setPaginationDto={setFilter}
                                />
                            )}
                        </SectionContainer>
                    </SbAccordion>
                </>
            ) : (
                <>
                    <SbAlert text={t("TenantRequired", { keyPrefix: Tenants })} variant="info" />
                </>
            )}
        </>
    );
};

export default DashboardContainer;
