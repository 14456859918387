import AdhocChecklistNotificationDto from "./adhoc-checklist-notification-dto";
import IntervalOverridesDto from "./interval-overrides-dto";

export default class TriggerAdhocQuestionSetDto {
    questionSetAssignmentScheduleId: number;
    functionUserId: number | null;
    intervalOverridesDto: IntervalOverridesDto | null;
    adhocChecklistNotificationDto: AdhocChecklistNotificationDto | null;
    referentialLinks: number[] | null;
    createdReferentialLinks: [number, string][] | null;
    assignedNodeId: number;

    public constructor(
        questionSetAssignmentScheduleId: number,
        functionUserId: number | null,
        intervalOverridesDto: IntervalOverridesDto | null,
        adhocChecklistNotificationDto: AdhocChecklistNotificationDto | null,
        referentialLinks: (number | null)[] | null,
        createdReferentialLinks: Map<number, string | null> | null,
        assignedNodeId: number
    ) {
        this.questionSetAssignmentScheduleId = questionSetAssignmentScheduleId;
        this.functionUserId = functionUserId;
        this.intervalOverridesDto = intervalOverridesDto;
        this.adhocChecklistNotificationDto = adhocChecklistNotificationDto;

        const filteredReferentialLinks = referentialLinks
            ? (referentialLinks.filter((x) => x !== null) as number[])
            : null;
        this.referentialLinks = filteredReferentialLinks?.length ? filteredReferentialLinks : null;

        const filteredCreatedReferentialLinks = createdReferentialLinks
            ? (Array.from(createdReferentialLinks.entries()).filter((x) => x[1] !== null) as [
                  number,
                  string,
              ][])
            : null;

        this.createdReferentialLinks = filteredCreatedReferentialLinks?.length
            ? filteredCreatedReferentialLinks
            : null;

        this.assignedNodeId = assignedNodeId;
    }
}
