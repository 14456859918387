import React from "react";
import { useTranslation } from "react-i18next";
import { FaCheck, FaCoffee, FaShare, FaTimesCircle } from "react-icons/fa";
import styled, { css } from "styled-components";
import { ActionItems } from "../../core/constants/translation-namespace";
import { ActionInstanceCommentStatus } from "../../domain/enums/action-items/ActionInstanceCommentStatus";
import { translateText } from "../helpers/translate";

export interface StyledActionItemCommentStatusProps {
    $actionInstanceCommentStatus: ActionInstanceCommentStatus;
}

const StyledSbActionItemCommentStatusIconLabelContainer = styled.div<StyledActionItemCommentStatusProps>`
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: ${(props) => props.theme.padding.md};

    ${({ $actionInstanceCommentStatus }) =>
        $actionInstanceCommentStatus === ActionInstanceCommentStatus.ExtendedComment &&
        css`
            color: ${(props) => props.theme.palette.actionInstanceStatus.overdue};
        `}
    ${({ $actionInstanceCommentStatus }) =>
        $actionInstanceCommentStatus === ActionInstanceCommentStatus.OnHoldComment &&
        css`
            color: ${(props) => props.theme.palette.actionInstanceStatus.onhold};
        `}
    ${({ $actionInstanceCommentStatus }) =>
        $actionInstanceCommentStatus === ActionInstanceCommentStatus.CancelledComment &&
        css`
            color: ${(props) => props.theme.palette.actionInstanceStatus.overdue};
        `}
    ${({ $actionInstanceCommentStatus }) =>
        $actionInstanceCommentStatus === ActionInstanceCommentStatus.CompletedOverdueComment &&
        css`
            color: ${(props) => props.theme.palette.actionInstanceStatus.completed};
        `}
    ${({ $actionInstanceCommentStatus }) =>
        $actionInstanceCommentStatus === ActionInstanceCommentStatus.ReActivateComment &&
        css`
            color: ${(props) => props.theme.palette.actionInstanceStatus.cancelled};
        `}
    ${({ $actionInstanceCommentStatus }) =>
        $actionInstanceCommentStatus === ActionInstanceCommentStatus.ReOpen &&
        css`
            color: ${(props) => props.theme.palette.actionInstanceStatus.cancelled};
        `}
    ${({ $actionInstanceCommentStatus }) =>
        $actionInstanceCommentStatus === ActionInstanceCommentStatus.CompletedComment &&
        css`
            color: ${(props) => props.theme.palette.actionInstanceStatus.completed};
        `}
`;

const StyledStatusLabel = styled.div`
    font-weight: bold;
`;

const SbActionItemCommentStatusIconLabel: React.FC<{
    actionInstanceCommentStatus: ActionInstanceCommentStatus;
}> = ({ actionInstanceCommentStatus }) => {
    const getActionItemCommentStatus = (
        actionInstanceCommentStatus: ActionInstanceCommentStatus
    ): string => {
        const { t } = useTranslation("translation");

        if (actionInstanceCommentStatus) {
            if (actionInstanceCommentStatus === ActionInstanceCommentStatus.CompletedOverdueComment)
                return translateText(t, "Completed", ActionItems);

            const status = ActionInstanceCommentStatus[actionInstanceCommentStatus].replace(
                "Comment",
                ""
            ); //remove "Comment" at the end
            return status ? translateText(t, status, ActionItems) : "";
        }
        return "";
    };

    const renderActionItemCommentIcon = (
        actionInstanceCommentStatus: ActionInstanceCommentStatus
    ): JSX.Element | undefined => {
        switch (actionInstanceCommentStatus) {
            case ActionInstanceCommentStatus.ExtendedComment:
                return <FaShare size={18} />;
            case ActionInstanceCommentStatus.OnHoldComment:
                return <FaCoffee size={18} />;
            case ActionInstanceCommentStatus.CancelledComment:
                return <FaTimesCircle size={18} />;
            case ActionInstanceCommentStatus.CompletedOverdueComment:
            case ActionInstanceCommentStatus.CompletedComment:
                return <FaCheck size={18} />;
            default:
                return;
        }
    };

    return (
        <StyledSbActionItemCommentStatusIconLabelContainer
            $actionInstanceCommentStatus={actionInstanceCommentStatus}
        >
            {renderActionItemCommentIcon(actionInstanceCommentStatus)}
            <StyledStatusLabel>
                {getActionItemCommentStatus(actionInstanceCommentStatus)}
            </StyledStatusLabel>
        </StyledSbActionItemCommentStatusIconLabelContainer>
    );
};

export default SbActionItemCommentStatusIconLabel;
