import { SetStateAction, useContext, useState } from "react";
import { Col, OverlayTrigger, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { PieChart } from "react-minimal-pie-chart";
import styled, { ThemeContext } from "styled-components";
import { ChecklistAnswers, Nodes, Users } from "../../core/constants/translation-namespace";
import { createNavigateSearchParameter, useNavigateSearch } from "../../core/hooks/navigateSearch";
import {
    DetailsLabel,
    DetailsValue,
    LargeVerticalSpace,
    StyledPopover,
} from "../../core/theme/global-styles";
import { DrawerTitles } from "../../core/utilities/enums";
import { getPath } from "../../core/utilities/getPath";
import { AnswerSummaryDto } from "../../domain/dtos/answer-summary/answer-summary-dto";
import { AnswerSummaryType } from "../../domain/enums/answer-summary/AnswerSummaryType";
import InstanceStatus from "../../domain/enums/questions/instance-status";
import SbLabelText from "../atoms/SbLabelText";

const StyledDataContainer = styled.div`
    align-items: center;
    width: 20%;
    padding: 2%;
    box-shadow: 0 3px 6px ${(props) => props.theme.palette.grey};
    background-color: ${(props) => props.theme.palette.purewhite};
    margin-left: ${(props) => props.theme.padding.md};
    margin-right: ${(props) => props.theme.padding.md};
    margin-bottom: ${(props) => props.theme.padding.md};
`;

const StyledRow = styled(Row)`
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: ${(props) => props.theme.padding.xs};
    padding-bottom: ${(props) => props.theme.padding.xs};
`;

const StyledHr = styled.hr`
    border-bottom: 3px double ${(props) => props.theme.palette.black};
`;

const StyledLeftCol = styled(Col)`
    padding: 0;
`;

const StyledRightCol = styled(Col)`
    padding-left: ${(props) => props.theme.padding.sm};
`;

export const AnswerSummaryCard: React.FC<{
    answerSummary: AnswerSummaryDto;
    answerSummaryType: AnswerSummaryType;
}> = ({ answerSummary, answerSummaryType }) => {
    const [, setHovered] = useState<number | undefined>(undefined);
    const [lineWidth, setLineWidth] = useState<number>(20);

    const navigateSearch = useNavigateSearch();
    const { t } = useTranslation("translation", { keyPrefix: Nodes });
    const themeContext = useContext(ThemeContext);
    const completedCount = answerSummary.completedCount;
    const overdueCount = answerSummary.overdueCount;
    const dueCount = answerSummary.dueCount;
    const notDueCount = answerSummary.notDueCount;
    const questionCount = answerSummary.questionCount;
    const nodePath = answerSummary.nodePath.replaceAll(",", " | ");

    const responseData = [
        {
            title: dueCount,
            value: dueCount,
            color: themeContext!.palette.instanceStatus.due,
        },
        {
            title: completedCount,
            value: completedCount,
            color: themeContext!.palette.instanceStatus.completed,
        },
        {
            title: overdueCount,
            value: overdueCount,
            color: themeContext!.palette.instanceStatus.overdue,
        },
        {
            title: notDueCount,
            value: notDueCount,
            color: themeContext!.palette.instanceStatus.notDue,
        },
    ];

    const instanceStatusMapper = [
        {
            dataIndex: 0,
            actionInstanceStatus: InstanceStatus.Due,
        },
        {
            dataIndex: 1,
            actionInstanceStatus: InstanceStatus.Completed,
        },
        {
            dataIndex: 2,
            actionInstanceStatus: InstanceStatus.Overdue,
        },
        {
            dataIndex: 3,
            actionInstanceStatus: InstanceStatus.Pending,
        },
    ];

    const getInstanceStatusByDataIndex = (dataIndex: Number): InstanceStatus | undefined => {
        const instanceStatus = instanceStatusMapper.filter((x) => x.dataIndex === dataIndex)[0];
        return instanceStatus && instanceStatus.actionInstanceStatus;
    };

    const onTeamAnswerSummaryClick = (dataIndex: number): void => {
        const instanceStatus = getInstanceStatusByDataIndex(dataIndex);

        const params = [
            createNavigateSearchParameter("instanceStatus", Number(instanceStatus).toString()),
            createNavigateSearchParameter("nodeId", answerSummary.nodeId.toString()),
            createNavigateSearchParameter("isTraining", answerSummary.isTraining.toString()),
            createNavigateSearchParameter(
                "answerSummaryType",
                Number(answerSummaryType).toString()
            ),
        ];

        navigateSearch(`${getPath(DrawerTitles.AnswerSummary)}/answer-details`, params);
    };

    const onAnswerSummaryClick = (dataIndex: number): void => {
        const instanceStatus = getInstanceStatusByDataIndex(dataIndex);

        const baseParams = [
            createNavigateSearchParameter("nodeId", answerSummary.nodeId.toString()),
            createNavigateSearchParameter("instanceStatus", Number(instanceStatus).toString()),
            createNavigateSearchParameter("isTraining", answerSummary.isTraining.toString()),
        ];

        if (instanceStatus === InstanceStatus.Completed) {
            const params = [
                ...baseParams,
                createNavigateSearchParameter(
                    "answerSummaryType",
                    Number(answerSummaryType).toString()
                ),
            ];

            navigateSearch(`${getPath(DrawerTitles.AnswerSummary)}/answer-details`, params);
        } else {
            navigateSearch(getPath(DrawerTitles.AnswerCapture), baseParams);
        }
    };

    const handleOnClick = (dataIndex: number): void => {
        if (answerSummaryType === AnswerSummaryType.Team)
            return onTeamAnswerSummaryClick(dataIndex);
        else answerSummaryType === AnswerSummaryType.User;
        return onAnswerSummaryClick(dataIndex);
    };

    return (
        <StyledDataContainer>
            {answerSummaryType === AnswerSummaryType.Team && (
                <>
                    <Row>
                        <StyledLeftCol md="auto">
                            <DetailsLabel>{t("ParentNodes")}:</DetailsLabel>
                        </StyledLeftCol>
                        <StyledRightCol>
                            <DetailsValue>{answerSummary.parentNodeTypeValue}</DetailsValue>
                        </StyledRightCol>
                    </Row>
                    <Row>
                        <StyledLeftCol md="auto">
                            <DetailsLabel>{t("Node")}:</DetailsLabel>
                        </StyledLeftCol>
                        <StyledRightCol>
                            <OverlayTrigger
                                overlay={<StyledPopover>{nodePath}</StyledPopover>}
                                placement={"top-start"}
                            >
                                <DetailsValue>{answerSummary.nodeTypeValue}</DetailsValue>
                            </OverlayTrigger>
                        </StyledRightCol>
                    </Row>
                    <Row>
                        <StyledLeftCol md="auto">
                            <DetailsLabel>
                                {t("QuestionCount", { keyPrefix: ChecklistAnswers })}:
                            </DetailsLabel>
                        </StyledLeftCol>
                        <StyledRightCol>
                            <DetailsValue>{questionCount.toString()}</DetailsValue>
                        </StyledRightCol>
                    </Row>
                    <Row>
                        <StyledLeftCol md="auto">
                            <DetailsLabel>
                                {t("ChecklistCount", { keyPrefix: ChecklistAnswers })}:
                            </DetailsLabel>
                        </StyledLeftCol>
                        <StyledRightCol>
                            <DetailsValue>{answerSummary.checklistCount.toString()}</DetailsValue>
                        </StyledRightCol>
                    </Row>
                    <Row>
                        <StyledLeftCol md="auto">
                            <DetailsLabel> {t("UserCount", { keyPrefix: Users })}:</DetailsLabel>
                        </StyledLeftCol>
                        <StyledRightCol>
                            <DetailsValue>{answerSummary.userCount.toString()}</DetailsValue>
                        </StyledRightCol>
                    </Row>
                </>
            )}

            {answerSummaryType === AnswerSummaryType.User && (
                <OverlayTrigger
                    key={nodePath}
                    placement={"auto"}
                    overlay={<StyledPopover id={`popover-${nodePath}`}>{nodePath}</StyledPopover>}
                >
                    <Row>
                        <SbLabelText label={answerSummary.nodeTypeValue} />
                    </Row>
                </OverlayTrigger>
            )}

            <Row>
                <StyledHr />
            </Row>

            <LargeVerticalSpace />

            <StyledRow>
                <PieChart
                    lineWidth={lineWidth}
                    totalValue={questionCount}
                    radius={50}
                    paddingAngle={1}
                    label={() => questionCount}
                    labelStyle={{
                        fontSize: "10px",
                        fontFamily: "sans-serif",
                        fill: "#000000",
                    }}
                    labelPosition={0}
                    segmentsStyle={{ transition: "stroke .3s", cursor: "hand" }}
                    onClick={(_: any, index: number) => {
                        handleOnClick(index);
                    }}
                    onMouseOver={(_: any, index: SetStateAction<number | undefined>) => {
                        setHovered(index);
                        setLineWidth(25);
                    }}
                    onMouseOut={() => {
                        setHovered(undefined);
                        setLineWidth(20);
                    }}
                    data={responseData}
                    animate
                ></PieChart>
            </StyledRow>
        </StyledDataContainer>
    );
};
