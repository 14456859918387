import NestedQuestionCaptureDto from "./nested-question-capture-dto";
import QuestionCaptureDto from "./question-capture-dto";

export default class QuestionAnswerInstanceDto {
    questionSetInstanceAnswerId: number;
    questionId: number;
    parentQuestionId: number | null;

    constructor(
        questionSetInstanceAnswerId: number,
        questionId: number,
        parentQuestionId: number | null
    ) {
        this.questionSetInstanceAnswerId = questionSetInstanceAnswerId;
        this.questionId = questionId;
        this.parentQuestionId = parentQuestionId;
    }

    public getKey(): string {
        return `${this.questionSetInstanceAnswerId}${this.questionId}${this.parentQuestionId}`;
    }

    public static constructFromQuestionCaptureDto = (
        dto: QuestionCaptureDto
    ): QuestionAnswerInstanceDto => {
        const { questionSetInstanceAnswerId, questionId } = dto;

        return new QuestionAnswerInstanceDto(questionSetInstanceAnswerId, questionId, null);
    };

    public static constructFromNestedQuestionCaptureDto = (
        dto: NestedQuestionCaptureDto
    ): QuestionAnswerInstanceDto => {
        const { parentQuestionSetInstanceAnswerId, questionId, parentQuestionId } = dto;

        return new QuestionAnswerInstanceDto(
            parentQuestionSetInstanceAnswerId,
            questionId,
            parentQuestionId
        );
    };
}
