export class ExtendActionItemDto {
    actionItemInstanceId: number;
    comment: string;
    dueDate: Date;

    public constructor(formData: FormData, actionItemInstanceId: number) {
        this.actionItemInstanceId = actionItemInstanceId;
        this.comment = formData.get("comment") as string;
        this.dueDate = new Date(formData.get("dueDate") as string);
    }
}
