import { useState } from "react";
import styled from "styled-components";
import SbCheckField from "../atoms/input/SbCheckField";
import SbDateTimeField from "../atoms/input/SbDateTimeField";

const StyledLinkTd = styled.td`
    color: ${(props) => props.theme.palette.primary};
    cursor: pointer;
`;

export const QuestionSetAction: React.FC<{
    id: number;
    name: string;
    navigateToViewQuestionSetDetails: (id: number) => void;
}> = ({ id, name, navigateToViewQuestionSetDetails }) => {
    const [calendarEntry, setCalendarEntry] = useState(false);

    return (
        <tr>
            <StyledLinkTd key={id} onClick={() => navigateToViewQuestionSetDetails(id)}>
                {name}
            </StyledLinkTd>
            <td>
                {
                    <SbCheckField
                        aria-label={name}
                        key={`Calendar Entry ${id}`}
                        onChangeHandler={(_: string, checked: boolean) => setCalendarEntry(checked)}
                        type={"checkbox"}
                        name={`Calendar Entry ${id}`}
                    />
                }
            </td>
            <td>
                {
                    <SbCheckField
                        aria-label={name}
                        key={`Review ${id}`}
                        type={"checkbox"}
                        name={`Review ${id}`}
                    />
                }
            </td>
            <td>
                {
                    <SbDateTimeField
                        name={`startDate ${id}`}
                        defaultValue={new Date()}
                        disabled={!calendarEntry}
                    />
                }
            </td>
            <td>{<SbDateTimeField name={`endDate ${id}`} disabled={!calendarEntry} />}</td>
        </tr>
    );
};
