import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { orderWizardCards } from "../../../data/services/wizards/wizard-cards-service";
import { CardOrder } from "../../requests/wizards/order-wizard-card-request";
import { Response } from "../../responses/common/response-response";

interface OrderParameters {
    wizardId: number;
    wizardCards: CardOrder[];
}

export const useOrderWizardCards = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    OrderParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: OrderParameters) => {
        const request = {
            wizardId: mutationKey.wizardId,
            wizardCards: mutationKey.wizardCards,
        };

        return orderWizardCards(url.originalBaseUrl, request);
    });
};
