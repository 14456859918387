export interface SearchActionItemAssociationDto {
    actionItemId: number | null;
}

export const emptySearchActionItemAssociationDto = (): SearchActionItemAssociationDto => ({
    actionItemId: null,
});

const SearchParameters = {
    actionItemId: "actionItemId",
};

export const buildSearchActionItemAssociationSearchParameters = (
    dto: SearchActionItemAssociationDto,
    searchParams: URLSearchParams
): URLSearchParams => {
    if (dto.actionItemId != null) {
        searchParams.set(SearchParameters.actionItemId, dto.actionItemId.toString());
    }

    return searchParams;
};
