import GenerateQuestionAnswerActionItemDto from "../../dtos/action-items/generate-question-answer-action-item-dto";
import { useAnswerCapture } from "../../stores/answer-capture-context";

export const useOnTriggerActionItemSubmit = (): ((
    questionSetInstanceAnswerId: number,
    date: Date
) => void) => {
    const answerCapture = useAnswerCapture();

    return (questionSetInstanceAnswerId: number, date: Date): void => {
        const dto = new GenerateQuestionAnswerActionItemDto(questionSetInstanceAnswerId, date);

        answerCapture.updateTriggeringQuestionActionItem(dto);
    };
};
