import { Form } from "react-bootstrap";
import { getNullableString } from "../../../core/utilities/string-helper";
import { BaseSbFieldProps } from "../../props/input/InputFieldProps";

export interface SbTextFieldProps extends BaseSbFieldProps {
    type: string;
    defaultValue?: string | null;
    value?: string;
    maxLength?: number;
    minLength?: number;
    onTextChanged?: (_: string | null) => void;
}

const SbTextField: React.FC<SbTextFieldProps> = ({
    name,
    type,
    defaultValue,
    value,
    onTextChanged,
    required,
    disabled,
    maxLength,
    minLength,
}) => (
    <Form.Control
        name={name}
        type={type}
        key={defaultValue}
        defaultValue={defaultValue ?? undefined}
        value={value}
        required={required}
        disabled={disabled}
        maxLength={maxLength}
        minLength={minLength}
        onChange={(event) => onTextChanged && onTextChanged(getNullableString(event.target.value))}
    />
);

export default SbTextField;
