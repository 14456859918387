import { AadAuthenticationRequest } from "../../../domain/requests/auth/aad-authentication-request";
import { AuthenticationRequest } from "../../../domain/requests/auth/authentication-request";
import { RefreshTokenRequest } from "../../../domain/requests/auth/refresh-token-request";
import {
    AuthTokenResponse,
    RefreshTokenResponse,
    TokenResponse,
} from "../../../domain/responses/account/token-response";
import { Response } from "../../../domain/responses/common/response-response";
import client from "../../http-client";

const route = "account";
const authRoute = "auth";

export const login = async (
    prefixUrl: string,
    request: AuthenticationRequest
): Promise<Response<TokenResponse>> =>
    await client
        .post(`${route}/login`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const loginAad = async (
    prefixUrl: string,
    request: AadAuthenticationRequest
): Promise<Response<TokenResponse>> =>
    await client
        .post(`${route}/login-aad`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const createRefreshToken = async (
    prefixUrl: string,
    request: AuthenticationRequest
): Promise<Response<RefreshTokenResponse>> =>
    await client
        .post(`${authRoute}/create-refresh-token`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const createAadRefreshToken = async (
    prefixUrl: string,
    request: AadAuthenticationRequest
): Promise<Response<RefreshTokenResponse>> =>
    await client
        .post(`${authRoute}/create-aad-refresh-token`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const refreshTokens = async (
    prefixUrl: string,
    request: RefreshTokenRequest
): Promise<Response<AuthTokenResponse>> =>
    await client
        .post(`${authRoute}/refresh-token`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();
