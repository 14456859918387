import { HTTPError } from "ky";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaUpload } from "react-icons/fa";
import { SingleValue } from "react-select";
import {
    ProcessHierarchyImportEvent,
    ValidateHierarchyImportEvent,
} from "../../../core/constants/application-insights-events";
import {
    ActionPlans,
    Common,
    Hierarchy,
    HierarchyTypes,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { createErrorToastProps, useToast } from "../../../core/store/toast-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getBase64FileString } from "../../../core/utilities/file-helper";
import { getPath } from "../../../core/utilities/getPath";
import {
    areMutationsLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import HierarchyImportHeaderDto from "../../../domain/dtos/data-import/hierarchy-import-header-dto";
import ProcessHierarchyImportDto from "../../../domain/dtos/data-import/process-hierarchy-import-dto";
import ValidateHierarchyImportDto from "../../../domain/dtos/data-import/validate-hierarchy-import-dto";
import { HierarchyTypeDto } from "../../../domain/dtos/hierarchy/hierarchy-type-dto";
import ValidateHierarchyImportResponse from "../../../domain/responses/data-import/validate-hierarchy-import-response";
import {
    useProcessHierarchyImport,
    useValidateHierarchyImport,
} from "../../../domain/viewmodels/data-import/hierarchy-import-viewmodel";
import { useFilterHierarchyTypes } from "../../../domain/viewmodels/hierarchy/text-search-node-viewmodel";
import { SbButton } from "../../atoms/SbButton";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import { SbFileUpload } from "../../molecules/SbFileUpload";
import { TextTitledPanel } from "../../molecules/SbPanel";
import { HierarchyImportTable } from "../../organisms/HierarchyImportTable";

const CreateHierarchyImportContainer: React.FC = () => {
    const [hierarchyTypeId, setHierarchyTypeId] = useState<number | null>(null);
    const [file, setFile] = useState<File>();
    const [fileBase64String, setFileBase64String] = useState<string>("");
    const [validateHierarchyImportResponse, setValidateHierarchyImportResponse] =
        useState<ValidateHierarchyImportResponse | null>(null);

    const selectedHierarchyTypeId = useRef<number | null>(null);

    const menu = useMenu();
    const toast = useToast();
    const auth = useAuth();
    const navigateSearch = useNavigateSearch();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: Hierarchy });

    const filterHierarchyTypes = useFilterHierarchyTypes();
    const filterHierarchyTypesResponseData = filterHierarchyTypes.data!;
    const validateHierarchyImport = useValidateHierarchyImport();
    const processHierarchyImport = useProcessHierarchyImport();

    useLoader(
        isQueryLoading(filterHierarchyTypes) ||
            areMutationsLoading([validateHierarchyImport, processHierarchyImport]),
        CreateHierarchyImportContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.HierarchyImport);
    }, []);

    const setDefaultSelectedHierarchyTypeItem = (): HierarchyTypeDto => {
        if (selectedHierarchyTypeId.current == null) {
            selectedHierarchyTypeId.current = !filterHierarchyTypesResponseData.length
                ? 0
                : filterHierarchyTypesResponseData[0].hierarchyTypeId;

            if (filterHierarchyTypesResponseData.length) {
                setHierarchyTypeId(filterHierarchyTypesResponseData[0].hierarchyTypeId);
            }
        }

        return filterHierarchyTypesResponseData[0];
    };

    const onHierarchyTypeSelected = (option: SingleValue<HierarchyTypeDto>): void => {
        setHierarchyTypeId(option!.hierarchyTypeId);
    };

    const onUploadFile = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        const { files } = event.target;
        const selectedFiles = files as FileList;
        const file = selectedFiles?.[0];

        const fileBase64String = await getBase64FileString(file);

        if (fileBase64String === null) {
            toast.addToast(
                createErrorToastProps([t("FailedToReadFile", { keyPrefix: ActionPlans })])
            );
        } else {
            setFile(file);
            setFileBase64String(fileBase64String);
        }
    };

    const onValidate = (): void => {
        validateHierarchyImport.mutate(
            new ValidateHierarchyImportDto(hierarchyTypeId!, file!, fileBase64String!),
            {
                onSuccess: async (response) => {
                    trackAppInsightsEvent(
                        auth.email,
                        window.location.href,
                        ValidateHierarchyImportEvent
                    );
                    setValidateHierarchyImportResponse(response.data);
                },
                onError: (error: HTTPError) => {
                    trackAppInsightsException(
                        auth.email,
                        window.location.href,
                        ValidateHierarchyImportEvent,
                        error
                    );
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    };

    const onProcess = (): void => {
        processHierarchyImport.mutate(
            new ProcessHierarchyImportDto(validateHierarchyImportResponse!),
            {
                onSuccess: async () => {
                    trackAppInsightsEvent(
                        auth.email,
                        window.location.href,
                        ProcessHierarchyImportEvent
                    );

                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter("messageKey", "SuccessfullFileUpload"),
                    ];

                    navigateSearch(`${getPath(AccordionTitles.Questions)}`, params);
                },
                onError: (error: HTTPError) => {
                    trackAppInsightsException(
                        auth.email,
                        window.location.href,
                        ProcessHierarchyImportEvent,
                        error
                    );
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    };

    return (
        <>
            <PageHeading>{t("ImportHierarchy")}</PageHeading>
            <SectionVerticalSpace />

            <TextTitledPanel title={t("ImportHierarchy")}>
                {isQuerySuccessful(filterHierarchyTypes) && (
                    <SbFormSelectFieldGroup
                        name={"hierarchyTypes"}
                        label={t("HierarchyTypes", { keyPrefix: HierarchyTypes })}
                        placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                        searchable={false}
                        clearable={false}
                        items={filterHierarchyTypesResponseData.sort(
                            (a, b) => a.hierarchyTypeId - b.hierarchyTypeId
                        )}
                        itemDisplayText={(option: HierarchyTypeDto) => option.name}
                        defaultSelectedItem={setDefaultSelectedHierarchyTypeItem()}
                        onChange={onHierarchyTypeSelected}
                    />
                )}
                <SbFileUpload
                    label={t("SelectFile", { keyPrefix: Common })}
                    onUploadFile={onUploadFile}
                />
                <EndAlignedDiv>
                    <SbButton
                        variant="primary"
                        type="button"
                        label={t("Validate", { keyPrefix: Common })}
                        icon={FaUpload}
                        onClick={onValidate}
                        disabled={file === undefined}
                    />
                </EndAlignedDiv>
                <SectionVerticalSpace />
                {validateHierarchyImportResponse && (
                    <HierarchyImportTable
                        headerMetadata={HierarchyImportHeaderDto.constructFromResponses(
                            validateHierarchyImportResponse?.hierarchyImportProcessResponse
                                ?.headerMetadata
                        )}
                        onProcess={onProcess}
                    />
                )}
            </TextTitledPanel>
        </>
    );
};

export default CreateHierarchyImportContainer;
