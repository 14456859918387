import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { editQuestionSchedule } from "../../../data/services/question-set/question-set-service";
import { EditQuestionScheduleDto } from "../../dtos/question-sets/edit-question-schedule-dto";
import { createEditQuestionScheduleRequest } from "../../requests/question-set/edit-question-schedule-request";
import { Response } from "../../responses/common/response-response";

export const useEditQuestionScheduleRequest = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    EditQuestionScheduleDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: EditQuestionScheduleDto) =>
        editQuestionSchedule(url.baseUrl, createEditQuestionScheduleRequest(mutationKey))
    );
};
