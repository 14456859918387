import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import {
    ChecklistQuestions,
    Common,
    HierarchyTypeTreeView,
    Nodes,
} from "../../../core/constants/translation-namespace";
import { HierarchyMenuType } from "../../../core/hierarchyMenuType";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    PageSubHeading,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { areQueriesLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import { BaseNodeDetailsDto } from "../../../domain/dtos/hierarchy/base-node-details-dto";
import { useGetAssociatedNodeAndHierarchyDetails } from "../../../domain/viewmodels/checklists/associate-node-viewmodel";
import { CancelButton, SbButton } from "../../atoms/SbButton";
import { RadioSelectProps } from "../../molecules/DataTableAction";
import SbTabs, { SbTabItemProps } from "../../molecules/SbTabs";
import NodeHierarchyContainer from "../hierarchy/NodeHierarchyContainer";
import TextSearchContainer from "../hierarchy/TextSearchContainer";

const AssociateNodeContainer: React.FC = () => {
    const [selectedNode, setSelectedNode] = useState<number>();

    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();
    const menu = useMenu();
    const { t } = useTranslation("translation", { keyPrefix: Nodes });

    const checklistId = Number(useParams().checklistId);

    const getAssociatedNodeAndHierarchyDetails =
        useGetAssociatedNodeAndHierarchyDetails(checklistId);

    const associatedNodeDetailsResponse = getAssociatedNodeAndHierarchyDetails[0];
    const associatedNodeDetailsResponseData = associatedNodeDetailsResponse.data;
    const hierarchyDetailsResponse = getAssociatedNodeAndHierarchyDetails[1];
    const hierarchyDetailsResponseData = hierarchyDetailsResponse.data;

    useLoader(areQueriesLoading(getAssociatedNodeAndHierarchyDetails), AssociateNodeContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Checklists);
    }, []);

    const selectTextSearchNode = (metadata: BaseNodeDetailsDto): void =>
        setSelectedNode(metadata.nodeId);

    const selectVisualSearchNode = (nodeId: number): void => setSelectedNode(nodeId);

    const checkSelectedNode = (metadata: BaseNodeDetailsDto): boolean => {
        const node = metadata.nodeId;

        return node === selectedNode;
    };

    const radioSelect: RadioSelectProps = {
        type: "radio",
        label: `${t("SelectNode", { keyPrefix: HierarchyTypeTreeView })}`,
    };

    const navigateToAssignNodeTypeValue = (): void => {
        const params = [
            createNavigateSearchParameter("nodeId", selectedNode!.toString()),
            createNavigateSearchParameter("checklistId", checklistId.toString()),
        ];

        navigateSearch(
            `${getPath(AccordionTitles.Checklists)}/${checklistId}/assign-node-type-value`,
            params
        );
    };

    const buildSbTabItemProps = (): SbTabItemProps[] => {
        const textSearchContainer = (
            <TextSearchContainer
                mode={"select"}
                selectNode={selectTextSearchNode}
                selectedNode={checkSelectedNode}
                radioSelect={radioSelect}
                hierarchyTypes={hierarchyDetailsResponseData!.sort(
                    (a, b) => a.hierarchyTypeId - b.hierarchyTypeId
                )}
            />
        );

        const nodeHierarchyContainer = (
            <NodeHierarchyContainer
                menuType={
                    HierarchyMenuType.Select |
                    HierarchyMenuType.Details |
                    HierarchyMenuType.ViewUsers
                }
                selectNode={selectVisualSearchNode}
                hierarchyTypes={hierarchyDetailsResponseData!.sort(
                    (a, b) => a.hierarchyTypeId - b.hierarchyTypeId
                )}
            />
        );

        return [
            new SbTabItemProps(t("TextSearch", { keyPrefix: Common }), textSearchContainer, true),
            new SbTabItemProps(t("VisualSearch", { keyPrefix: Common }), nodeHierarchyContainer),
        ];
    };

    return (
        <>
            <PageHeading>{t("AssignNode")}</PageHeading>
            <PageSubHeading>
                {t("AssignNodeHelpTextChecklist", { keyPrefix: ChecklistQuestions })}
            </PageSubHeading>
            <LargeVerticalSpace />

            {isQuerySuccessful(associatedNodeDetailsResponse) && (
                <>
                    <ContentContainer>
                        <Row>
                            <Col md="auto">
                                <DetailsLabel>
                                    {t("ChecklistName", { keyPrefix: ChecklistQuestions })}
                                </DetailsLabel>
                            </Col>
                            <Col md="auto">
                                <DetailsValue>{associatedNodeDetailsResponseData}</DetailsValue>
                            </Col>
                        </Row>
                    </ContentContainer>
                    <LargeVerticalSpace />
                </>
            )}

            {isQuerySuccessful(hierarchyDetailsResponse) && (
                <ContentContainer>
                    <SbTabs values={buildSbTabItemProps()} />
                </ContentContainer>
            )}
            <LargeVerticalSpace />

            <EndAlignedDiv>
                <Col sm={"auto"}>
                    <CancelButton onClick={() => navigate(-1)} />
                </Col>
                <Col sm={"auto"}>
                    <SbButton
                        icon={FaArrowRight}
                        variant={"primary"}
                        type={"button"}
                        label={t("Next", { keyPrefix: Common })}
                        onClick={navigateToAssignNodeTypeValue}
                        disabled={!selectedNode}
                    />
                </Col>
            </EndAlignedDiv>
        </>
    );
};

export default AssociateNodeContainer;
