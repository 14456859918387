export interface DissociateChecklistFromNodeRequest {
    checklistId: number;
    nodeId: number;
}

export const createDissociateChecklistFromNodeRequest = (
    checklistId: number,
    nodeId: number
): DissociateChecklistFromNodeRequest => ({
    checklistId,
    nodeId,
});
