import { HTTPError } from "ky";
import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { IconType } from "react-icons";
import { FaLevelDownAlt, FaLevelUpAlt } from "react-icons/fa";
import { useParams, useSearchParams } from "react-router-dom";
import {
    DemoteChecklistAssignmentEvent,
    PromoteChecklistAssignmentEvent,
} from "../../../core/constants/application-insights-events";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isMutationLoading,
} from "../../../core/utilities/responseStateHelper";
import {
    useGetPromoteDemoteChecklistAssignmentDetails,
    usePromoteDemoteChecklistAssignment,
} from "../../../domain/viewmodels/checklist-assignment/promote-demote-checklist-assignment-viewmodel";
import { SbButton } from "../../atoms/SbButton";
import {
    CheckboxConfirmationRow,
    ConfirmationRow,
    TextConfirmationRow,
} from "../../organisms/ActionConfirmation";
import { ActionConfirmationFragment } from "../../organisms/ActionConfirmationFragment";

export interface PromoteDemoteChecklistAssignmentProps {
    checklist: string;
    node: string;
    isEnabled: boolean;
    isTraining: boolean;
}

const PromoteDemoteChecklistAssignmentContainer: React.FC = () => {
    const menu = useMenu();
    const auth = useAuth();
    const [urlSearchParams] = useSearchParams();
    const navigateSearch = useNavigateSearch();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const checklistAssignmentId = Number(useParams().checklistAssignmentId);
    const nodeId = Number(urlSearchParams.get("nodeId"));
    const checklistId = Number(urlSearchParams.get("checklistId"));

    const promoteDemoteChecklistAssignmentDetails = useGetPromoteDemoteChecklistAssignmentDetails(
        checklistAssignmentId,
        checklistId,
        nodeId
    );
    const promoteDemoteChecklistAssignment = usePromoteDemoteChecklistAssignment();

    let promoteDemoteChecklistAssignmentDetailsResponseData =
        promoteDemoteChecklistAssignmentDetails[0].data;

    useLoader(
        areQueriesLoading(promoteDemoteChecklistAssignmentDetails) ||
            isMutationLoading(promoteDemoteChecklistAssignment),
        PromoteDemoteChecklistAssignmentContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Checklists);
    }, []);

    const getButtonIcon = (): IconType =>
        promoteDemoteChecklistAssignmentDetailsResponseData!.isTraining
            ? FaLevelUpAlt
            : FaLevelDownAlt;

    const handlePromoteDemoteChecklistAssignment = (): void => {
        const eventType = promoteDemoteChecklistAssignmentDetailsResponseData!.isTraining
            ? PromoteChecklistAssignmentEvent
            : DemoteChecklistAssignmentEvent;

        promoteDemoteChecklistAssignment.mutate(
            {
                checklistAssignmentId: checklistAssignmentId,
                isTraining: !promoteDemoteChecklistAssignmentDetailsResponseData!.isTraining,
            },
            {
                onSuccess: async () => {
                    trackAppInsightsEvent(auth.email, window.location.href, eventType);
                    const params = [
                        createNavigateSearchParameter("nodeId", nodeId.toString()),
                        createNavigateSearchParameter("checklistId", checklistId.toString()),
                    ];

                    navigateSearch(
                        `${getPath(
                            AccordionTitles.Checklists
                        )}/${checklistAssignmentId}/checklist-assignment-details`,
                        params
                    );
                },
                onError: (error: HTTPError) => {
                    trackAppInsightsException(auth.email, window.location.href, eventType, error);
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    };

    const buildDeleteButton = (): ReactElement<typeof Button> => (
        <SbButton
            variant={
                promoteDemoteChecklistAssignmentDetailsResponseData!.isTraining
                    ? "primary"
                    : "warning"
            }
            type="button"
            label={
                promoteDemoteChecklistAssignmentDetailsResponseData!.isTraining
                    ? "Promote"
                    : "Demote"
            }
            icon={getButtonIcon()}
            onClick={handlePromoteDemoteChecklistAssignment}
        />
    );

    const buildConfirmationRows = (): ConfirmationRow<any>[] => [
        new TextConfirmationRow("Checklist", promoteDemoteChecklistAssignmentDetails[1].data!.name),
        new TextConfirmationRow(
            "Node",
            promoteDemoteChecklistAssignmentDetails[2].data!.node.nodeTypeValue!.value!
        ),
        new CheckboxConfirmationRow(
            "Is Enabled",
            promoteDemoteChecklistAssignmentDetailsResponseData!.isEnabled
        ),
    ];

    return areQueriesSuccessful(promoteDemoteChecklistAssignmentDetails) ? (
        <ActionConfirmationFragment
            pageHeading={
                promoteDemoteChecklistAssignmentDetailsResponseData!.isTraining
                    ? "Promote"
                    : "Demote"
            }
            panelTitle={
                promoteDemoteChecklistAssignmentDetailsResponseData!.isTraining
                    ? "Promote"
                    : "Demote"
            }
            alert={{
                variant: promoteDemoteChecklistAssignmentDetailsResponseData!.isTraining
                    ? "primary"
                    : "warning",
                messages: [
                    promoteDemoteChecklistAssignmentDetailsResponseData!.isTraining
                        ? "You are about to Promote this checklist assignment. This would switch off training and reschedule all future checklist questions starting tomorrow. Are you sure want to proceed?"
                        : "You are about to Demote this checklist assignment. This would switch on training and reschedule all future checklist questions starting tomorrow. Are you sure want to proceed?",
                ],
            }}
            rows={buildConfirmationRows()}
            primaryButton={buildDeleteButton()}
        />
    ) : (
        <></>
    );
};

export default PromoteDemoteChecklistAssignmentContainer;
