import { localDateSerialiserReplacer } from "../../../core/utilities/serialiser";
import { CreateNonWorkingDayRequest } from "../../../domain/requests/non-working-days/create-non-working-day-request";
import { EditNonWorkingDayRequest } from "../../../domain/requests/non-working-days/edit-non-working-day-request";
import { Response } from "../../../domain/responses/common/response-response";
import { NonWorkingDayResponse } from "../../../domain/responses/non-working-days/non-working-day-response";
import client from "../../http-client";

const route = "nonworkingday";

export const getAssociatedNonWorkingDaySetNodes = async (
    prefixUrl: string,
    nonWorkingDaySetId: number
): Promise<Response<NonWorkingDayResponse[]>> => {
    return await client
        .get(`${route}/${nonWorkingDaySetId}`, {
            prefixUrl: prefixUrl,
        })
        .json();
};

export const createNonWorkingDay = async (
    prefixUrl: string,
    request: CreateNonWorkingDayRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/create`, {
            body: JSON.stringify(request, localDateSerialiserReplacer(["date"])),
            prefixUrl: prefixUrl,
        })
        .json();

export const deleteNonWorkingDay = async (
    prefixUrl: string,
    nonWorkingDayId: number
): Promise<Response<boolean>> =>
    await client
        .delete(`${route}/delete/${nonWorkingDayId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const editNonWorkingDay = async (
    prefixUrl: string,
    request: EditNonWorkingDayRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/edit`, {
            body: JSON.stringify(request, localDateSerialiserReplacer(["date"])),
            prefixUrl: prefixUrl,
        })
        .json();
