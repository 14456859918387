import { Response } from "../../responses/common/response-response";
import { BaseNodeResponse } from "../../responses/hierarchy/base-node-response";
import { createNodeTypeDto, NodeTypeDto } from "./node-type-dto";
import { createNodeTypeValueDto, NodeTypeValueDto } from "./node-type-value-dto";

export interface BaseNodeDto {
    nodeId: number;
    shortDescription: string;
    longDescription: string;
    nodeType: NodeTypeDto; // TODO: Given the current implementation and usages, this should not be null
    nodeTypeValue: NodeTypeValueDto;
}

export const createBaseNodeDto = (response: BaseNodeResponse): BaseNodeDto => ({
    nodeId: response.nodeId,
    shortDescription: response.shortDescription,
    longDescription: response.longDescription,
    nodeType: createNodeTypeDto(response.nodeType),
    nodeTypeValue: createNodeTypeValueDto(response.nodeTypeValue),
});

export const toBaseNodeDtosFromResponse = (
    responses: Response<BaseNodeResponse[]>
): BaseNodeDto[] => toBaseNodeDtos(responses.data);

export const toBaseNodeDtos = (responses: BaseNodeResponse[]): BaseNodeDto[] =>
    responses?.map((x) => createBaseNodeDto(x));

export const createBaseNodeDtosFromResponse = (
    response: Response<BaseNodeResponse[]>
): BaseNodeDto[] => toBaseNodeDtos(response.data);
