import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { TableRow } from "../../../core/utilities/customTypes";
import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import {
    downloadQuestionSetInstanceAnswerFile,
    getAnswerDetails,
    getAnswerSummaries,
    getTeamAnswerSummaries,
} from "../../../data/services/answer-summary/answer-summary-service";
import { AnswerDetailsDto } from "../../dtos/answer-summary/answer-details-dto";
import { AnswerSummaryDto } from "../../dtos/answer-summary/answer-summary-dto";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { FileDownloadDto } from "../../dtos/file-storage/file-download-dto";
import { FileDto } from "../../dtos/file-storage/file-dto";
import InstanceStatus from "../../enums/questions/instance-status";
import { AnswerDetailsRequest } from "../../requests/answer-summary/answer-details-request";
import { AnswerDetailsResponse } from "../../responses/answer-summary/answer-details-response";
import { AnswerSummaryResponse } from "../../responses/answer-summary/answer-summary-response";
import { Response } from "../../responses/common/response-response";

export const useGetTeamAnswerSummary = (
    isTraining: boolean | null
): UseQueryResult<AnswerSummaryResponse[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getTeamAnswerSummary", isTraining],
        () => getTeamAnswerSummaries(url.baseUrl, isTraining),
        {
            select: AnswerSummaryDto.constructFromAnswerSummaryResponses,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useGetAnswerSummary = (
    isTraining: boolean | null
): UseQueryResult<AnswerSummaryResponse[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getAnswerSummary", isTraining],
        () => getAnswerSummaries(url.baseUrl, isTraining),
        {
            select: AnswerSummaryDto.constructFromAnswerSummaryResponses,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useDownloadQuestionSetInstanceAnswerFile = (
    questionSetInstanceAnswerFileId: number | null
): UseQueryResult<FileDownloadDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["downloadQuestionSetInstanceAnswerFile", questionSetInstanceAnswerFileId],
        () => downloadQuestionSetInstanceAnswerFile(url.baseUrl, questionSetInstanceAnswerFileId!),
        {
            enabled: questionSetInstanceAnswerFileId != null,
            select: FileDownloadDto.constructFromQuestionSetInstanceAnswerFileDownloadResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useGetAnswerDetails = (
    request: AnswerDetailsDto | null
): UseQueryResult<PaginatedTableDto<number>, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getAnswerDetails", request],
        () => getAnswerDetails(url.baseUrl, new AnswerDetailsRequest(request!)),
        {
            enabled: request !== null,
            select: transformToDataTableRows,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const transformToDataTableRows = (
    response: Response<AnswerDetailsResponse[]>
): PaginatedTableDto<number> => {
    const responseData = response.data;

    const rows = responseData!.map((answerDetail) => ({
        metadata: answerDetail.questionSetInstanceAnswerId,
        nestedRows:
            answerDetail.childAnswerDetails && answerDetail.childAnswerDetails.length > 0
                ? transformToNestedQuestionsRows(answerDetail.childAnswerDetails)
                : undefined,
        attachedFiles:
            answerDetail.attachedEvidence && answerDetail.attachedEvidence.length > 0
                ? FileDto.constructFromQuestionSetInstanceAnswerFileDownloadResponses(
                      answerDetail.attachedEvidence
                  )
                : undefined,
        columns: [
            {
                value: answerDetail.checklistName?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: answerDetail.questionText?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: answerDetail.userFullName?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: answerDetail.answerValue?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: answerDetail.comment?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: toDateAndTimeFormat(answerDetail.openDateTimeUtc.toString()),
                type: DataTableColumnTypes.Text,
            },
            {
                value: toDateAndTimeFormat(answerDetail.dueDateTimeUtc.toString()),
                type: DataTableColumnTypes.Text,
            },
            {
                // Nested questions
                value:
                    answerDetail.childAnswerDetails && answerDetail.childAnswerDetails.length > 0
                        ? "Show/Hide"
                        : "None",
                type: DataTableColumnTypes.DisplayNestedTableButton,
                metadata: "NestedQuestions",
            },
            {
                value: InstanceStatus[answerDetail.instanceStatus].toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: toDateAndTimeFormat(answerDetail.statusDateUtc.toString()),
                type: DataTableColumnTypes.Text,
            },
            {
                // Attached Files
                value:
                    answerDetail.attachedEvidence && answerDetail.attachedEvidence.length > 0
                        ? "Show/Hide"
                        : "None",
                type: DataTableColumnTypes.DisplayAttachedFilesButton,
                metadata: "AttachedFiles",
            },
        ],
    }));

    return {
        numberOfPages: 100,
        pageSize: 100,
        currentPage: 1,
        recordCount: responseData.length,
        rows: rows,
    };
};

const transformToNestedQuestionsRows = (
    nestedAnswers: AnswerDetailsResponse[]
): TableRow<number>[] => {
    const rows = nestedAnswers.map((nestedAnswer) => ({
        metadata: nestedAnswer.questionSetInstanceAnswerId,
        columns: [
            {
                value: nestedAnswer.checklistName?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: nestedAnswer.questionText?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: nestedAnswer.userFullName?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: nestedAnswer.answerValue?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: nestedAnswer.comment?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: toDateAndTimeFormat(nestedAnswer.openDateTimeInChecklistTimeZone.toString()),
                type: DataTableColumnTypes.Text,
            },
            {
                value: toDateAndTimeFormat(nestedAnswer.actionItemDueDate.toString()),
                type: DataTableColumnTypes.Text,
            },
            {
                value: InstanceStatus[nestedAnswer.instanceStatus].toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                // Attached Files
                value:
                    nestedAnswer.attachedEvidence && nestedAnswer.attachedEvidence.length > 0
                        ? "Show/Hide"
                        : "None",
                type: DataTableColumnTypes.DisplayAttachedFilesButton,
                metadata: "AttachedFiles",
            },
        ],
    }));

    return rows;
};
