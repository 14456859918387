import { buildPaginationSearchParameters, PaginationRequest } from "../common/pagination-request";

export interface FilterChildNodesRequest extends PaginationRequest {
    nodeId: number;
}

export const createFilterChildNodesRequest = (
    paginationRequest: PaginationRequest,
    nodeId: number
): FilterChildNodesRequest => ({
    ...paginationRequest,
    nodeId: nodeId,
});

export const buildFilterSearchParameters = (request: FilterChildNodesRequest): URLSearchParams => {
    const searchParams = buildPaginationSearchParameters(request);
    searchParams.set("nodeId", request.nodeId.toString());

    return searchParams;
};
