import React from "react";
import { OverlayTrigger } from "react-bootstrap";
import styled from "styled-components";
import { StyledPopover } from "../../core/theme/global-styles";
import SbCheckField, { CheckboxProps } from "./input/SbCheckField";

const StyledDiv = styled.div``;

export interface TooltipCheckboxProps extends CheckboxProps {
    tooltip: string;
}

const SbTooltipCheckField: React.FC<TooltipCheckboxProps> = ({
    tooltip,
    type,
    name,
    label,
    defaultSelected,
    disabled,
    onChangeHandler,
}) => {
    return (
        <OverlayTrigger overlay={<StyledPopover>{tooltip}</StyledPopover>} placement={"top-start"}>
            <StyledDiv>
                <SbCheckField
                    type={type}
                    name={name}
                    label={label}
                    defaultSelected={defaultSelected}
                    disabled={disabled}
                    onChangeHandler={onChangeHandler}
                />
            </StyledDiv>
        </OverlayTrigger>
    );
};

export default SbTooltipCheckField;
