import AnswerValueType from "../../enums/answer-types/answer-value-type";
import {
    CreateActionItemDynamicFieldRequest,
    createCreateActionItemDynamicFieldRequest,
} from "./create-action-item-dynamic-field-request";

export interface EditActionItemDynamicFieldRequest extends CreateActionItemDynamicFieldRequest {
    actionItemDynamicFieldId: number;
}

export const createEditActionItemDynamicFieldRequest = (
    actionItemDynamicFieldId: number,
    fieldName: string | null,
    actionItemTypeNodeId: number,
    valueType: AnswerValueType,
    listData: string | null,
    isRequired: boolean
): EditActionItemDynamicFieldRequest => ({
    actionItemDynamicFieldId: actionItemDynamicFieldId,
    ...createCreateActionItemDynamicFieldRequest(
        fieldName,
        actionItemTypeNodeId,
        valueType,
        listData,
        isRequired
    ),
});
