import { Response } from "../../responses/common/response-response";
import { RoleRestrictedNodeResponse } from "../../responses/hierarchy/role-restricted-node-response";

export default class RoleRestrictedNodeDto {
    nodeId: number;
    roleId: number;

    constructor(response: RoleRestrictedNodeResponse) {
        this.nodeId = response.nodeId;
        this.roleId = response.roleId;
    }

    public static constructFromResponses = (
        responses: Response<RoleRestrictedNodeResponse[]>
    ): RoleRestrictedNodeDto[] => responses.data.map((x) => new RoleRestrictedNodeDto(x));
}
