import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { getNullableFormField } from "../../../core/utilities/formDataHelper";
import { filterChildNodeTypes } from "../../../data/services/hierarchy/node-types-service";
import {
    createChildNode,
    filterChildNodes,
    getLinkableNodes,
    getNodeDetails,
} from "../../../data/services/hierarchy/nodes-service";
import { LinkableNodeDto } from "../../dtos/hierarchy/linkable-node-dto";
import { createNodeDetailsDto, NodeDetailsDto } from "../../dtos/hierarchy/node-details-dto";
import { NodeDto } from "../../dtos/hierarchy/node-dto";
import {
    NodeTypeDto,
    toNodeTypeDtosFromPaginatedResponse,
} from "../../dtos/hierarchy/node-type-dto";
import { createPaginationRequest } from "../../requests/common/pagination-request";
import { createCreateChildNodeRequest } from "../../requests/hierarchy/create-child-node-request";
import { CreateNodeTypeValueRequest } from "../../requests/hierarchy/create-node-type-value-request";
import { createFilterByNodeTypeRequest } from "../../requests/hierarchy/filter-by-node-type-request";
import { createFilterChildNodesRequest } from "../../requests/hierarchy/filter-child-nodes-request";
import LinkableNodesRequest from "../../requests/hierarchy/linkable-nodes-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { BaseNodeResponse } from "../../responses/hierarchy/base-node-response";

export const useGetNodeDetails = (nodeId: number): UseQueryResult<NodeDetailsDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(["getNodeDetails", nodeId], () => getNodeDetails(url.baseUrl, nodeId), {
        select: createNodeDetailsDto,
        onError: errorResponseToDisplayHandler,
    });
};

export const useHasChildNodes = (nodeId: number): UseQueryResult<boolean, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterChildNodes", nodeId, 1, 1],
        () =>
            filterChildNodes(
                url.baseUrl,
                createFilterChildNodesRequest(createPaginationRequest(1, 1), nodeId)
            ),
        {
            select: (response: Response<PaginationResponse<BaseNodeResponse>>) =>
                response.data.results.length > 0,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useFilterChildNodeTypes = (
    nodeTypeId: number | null
): UseQueryResult<NodeTypeDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterChildNodeTypes", nodeTypeId],
        () =>
            filterChildNodeTypes(url.baseUrl, createFilterByNodeTypeRequest(1, 1000, nodeTypeId!)),
        {
            enabled: nodeTypeId != null,
            select: toNodeTypeDtosFromPaginatedResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useGetLinkableNodes = (
    nodeId: number,
    nodeTypeId: number | null
): UseQueryResult<LinkableNodeDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const request = new LinkableNodesRequest(nodeId, nodeTypeId);

    return useQuery(
        ["getLinkableNodes", nodeId, nodeTypeId],
        () => getLinkableNodes(url.baseUrl, request),
        {
            select: LinkableNodeDto.constructFromLinkableNodeResponses,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export interface CreateParameters {
    parentNodeDetails: NodeDto;
    formData: FormData;
    isCreatingNodeTypeValue: boolean;
    inheritsTraining: boolean;
    linkedNodeId: number | null;
    childNodeTypes: NodeTypeDto[];
    selectedNodeTypeValueId: number | null;
    roleIds?: number[] | null;
}

export const useCreateChildNode = (): UseMutationResult<
    Response<number>,
    HTTPError,
    CreateParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: CreateParameters) => {
        const {
            parentNodeDetails,
            formData,
            isCreatingNodeTypeValue,
            inheritsTraining,
            childNodeTypes,
            selectedNodeTypeValueId,
            linkedNodeId,
            roleIds,
        } = mutationKey;

        let nodeTypeValueId: number | null = null;

        let createNodeTypeValueRequest: CreateNodeTypeValueRequest | null = null;

        const isTraining = !inheritsTraining ? formData.get("isTraining") == "on" : null;

        const childNodeTypeId = getSelectedNodeTypeId(
            formData.get("nodeType") as string,
            childNodeTypes
        );

        if (isCreatingNodeTypeValue) {
            createNodeTypeValueRequest = {
                nodeTypeId: childNodeTypeId,
                value: formData.get("nodeTypeValueValue") as string,
                code: getNullableFormField(formData.get("nodeTypeValueCode") as string),
                description: getNullableFormField(
                    formData.get("nodeTypeValueDescription") as string
                ),
                roleIds: roleIds,
            };
        } else {
            nodeTypeValueId = selectedNodeTypeValueId ? selectedNodeTypeValueId : null;
        }

        const request = createCreateChildNodeRequest(
            parentNodeDetails.nodeId,
            childNodeTypeId,
            isTraining,
            linkedNodeId,
            nodeTypeValueId,
            createNodeTypeValueRequest
        );

        return createChildNode(url.baseUrl, request);
    });
};

const getSelectedNodeTypeId = (nodeTypeValue: string, nodeTypes: NodeTypeDto[]): number =>
    nodeTypes.find((x) => x.name == nodeTypeValue)!.nodeTypeId;
