import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { convertToggleValueToBoolean } from "../../../core/utilities/convertToBoolean";
import { createNonWorkingDay } from "../../../data/services/non-working-days/non-working-day-service";
import { Response } from "../../responses/common/response-response";

interface CreateParameters {
    nonWorkingDaySetId: number;
    formData: FormData;
}

export const useCreateNonWorkingDay = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    CreateParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: CreateParameters) => {
        const formData = mutationKey.formData;

        const request = {
            nonWorkingDayName: formData.get("name")! as string,
            date: new Date(formData.get("date")! as string),
            recurAnnually: convertToggleValueToBoolean(formData.get("recurAnnually")! as string),
            recurNumberOfYears: Number(formData.get("years")!),
            rolloverSundays: convertToggleValueToBoolean(formData.get("rollover")! as string),
            nonWorkingDaySetId: mutationKey.nonWorkingDaySetId,
        };

        return createNonWorkingDay(url.baseUrl, request);
    });
};
