import { MaxIntValue } from "../../../../core/constants/numerical";

export enum DaysOfWeek {
    None = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 4,
    Thursday = 8,
    Friday = 16,
    Saturday = 32,
    Sunday = 64,
    Weekday = 31,
    WeekdayInclSaturday = 63,
    Day = MaxIntValue, //Find out why this value is duplicated for 3 enum items :/
    All = MaxIntValue,
    Any = MaxIntValue,
}
