import QuestionImportance from "../../enums/questions/question-importance";
import { QuestionResponse } from "../../responses/questions/question-response";
import BaseAnswerTypeDto from "../answer-types/base-answer-type-dto";
import { QuestionCategoryDto } from "../question-categories/question-category-dto";
import { QuestionTypeDto } from "../question-type/question-type-dto";
import { AggregationTypeDto } from "./aggregation-type-dto";
import { NestedQuestionDto } from "./nested-question-dto";
import { QuestionTextDto } from "./question-text-dto";

export class QuestionDto {
    questionId: number;
    questionTypeId: number;
    answerTypeId: number;
    questionCategoryId: number;
    questionImportance: QuestionImportance;
    isDeleted: boolean;
    dateCreated: Date;
    dateModified: Date;
    createdByUserFullName: string;
    createdByUserId: number | null;
    modifiedByUserFullName: string;
    modifiedByUserId: number | null;
    isAggregate: boolean;
    aggregationTypeId: number;
    questionText: QuestionTextDto;
    questionType: QuestionTypeDto;
    questionCategory: QuestionCategoryDto;
    answerTypeDto: BaseAnswerTypeDto;
    aggregationType: AggregationTypeDto;
    childQuestionDtos: NestedQuestionDto[] | null;

    public constructor(response: QuestionResponse) {
        const {
            questionId,
            questionTypeId,
            answerTypeId,
            questionCategoryId,
            questionImportance,
            isDeleted,
            dateCreated,
            dateModified,
            createdByUserFullName,
            createdByUserId,
            modifiedByUserFullName,
            modifiedByUserId,
            isAggregate,
            aggregationTypeId,
            questionText,
            questionType,
            questionCategory,
            answerTypeResponse,
            aggregationType,
            childQuestionResponses,
        } = response;

        this.questionId = questionId;
        this.questionTypeId = questionTypeId;
        this.answerTypeId = answerTypeId;
        this.questionCategoryId = questionCategoryId;
        this.questionImportance = questionImportance;
        this.isDeleted = isDeleted;
        this.dateCreated = dateCreated;
        this.dateModified = dateModified;
        this.createdByUserFullName = createdByUserFullName;
        this.createdByUserId = createdByUserId;
        this.modifiedByUserFullName = modifiedByUserFullName;
        this.modifiedByUserId = modifiedByUserId;
        this.isAggregate = isAggregate;
        this.aggregationTypeId = aggregationTypeId;
        this.questionText = questionText;
        this.questionType = questionType;
        this.questionCategory = questionCategory;
        this.answerTypeDto = new BaseAnswerTypeDto(answerTypeResponse);
        this.aggregationType = aggregationType;
        this.childQuestionDtos = NestedQuestionDto.toNestedQuestionDtos(childQuestionResponses);
    }
}
