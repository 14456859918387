import FileDetailsUploadRequest from "../file-storage/file-details-upload-request";

export default class ValidateHierarchyImportRequest {
    hierarchyTypeId: Number;
    fileDetailsUploadRequest: FileDetailsUploadRequest;

    public constructor(
        hierarchyTypeId: Number,
        fileDetailsUploadRequest: FileDetailsUploadRequest
    ) {
        this.hierarchyTypeId = hierarchyTypeId;
        this.fileDetailsUploadRequest = fileDetailsUploadRequest;
    }
}
