export class QuestionScheduleBaseRequest {
    questionSetId: number;
    questionId: number;
    dueMinutesFromBaseStart: number;
    openDeltaMinutes: number;
    escalationL1MinutesFromBaseStart: number;
    escalationL2MinutesFromBaseStart: number | null;
    escalationL3MinutesFromBaseStart: number | null;
    isDefaultOpenDelta: boolean;

    public constructor(
        questionSetId: number,
        questionId: number,
        dueMinutesFromBaseStart: number,
        openDeltaMinutes: number,
        escalationL1MinutesFromBaseStart: number,
        escalationL2MinutesFromBaseStart: number | null,
        escalationL3MinutesFromBaseStart: number | null,
        isDefaultOpenDelta: boolean
    ) {
        this.questionSetId = questionSetId;
        this.questionId = questionId;
        this.dueMinutesFromBaseStart = dueMinutesFromBaseStart;
        this.openDeltaMinutes = openDeltaMinutes;
        this.escalationL1MinutesFromBaseStart = escalationL1MinutesFromBaseStart;
        this.escalationL2MinutesFromBaseStart = escalationL2MinutesFromBaseStart;
        this.escalationL3MinutesFromBaseStart = escalationL3MinutesFromBaseStart;
        this.isDefaultOpenDelta = isDefaultOpenDelta;
    }
}
