import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Common, NonWorkingDays } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { isMutationLoading } from "../../../core/utilities/responseStateHelper";
import { useCreateNonWorkingDay } from "../../../domain/viewmodels/non-working-days/create-non-working-day-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import SbFormCheckFieldGroup from "../../molecules/input/SbFormCheckFieldGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const CreateNonWorkingDayContainer: React.FC = () => {
    const [recurAnnually, setRecurAnnually] = useState(false);

    const menu = useMenu();
    const navigate = useNavigate();
    const nonWorkingDaySetId = Number(useParams().nonWorkingDaySetId);
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: NonWorkingDays });

    const createNonWorkingDay = useCreateNonWorkingDay();

    useLoader(isMutationLoading(createNonWorkingDay), CreateNonWorkingDayContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.NonWorkingDay);
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        createNonWorkingDay.mutate(
            {
                nonWorkingDaySetId: nonWorkingDaySetId,
                formData: new FormData(event.currentTarget),
            },
            {
                onSuccess: async () => navigate(-1),
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    const handleRecurAnnuallyChange = (_: string, checked: boolean): void => {
        setRecurAnnually(checked);
    };

    return (
        <>
            <PageHeading>{t("NonWorkingDayCreateTitle")}</PageHeading>
            <SectionVerticalSpace />
            <TextTitledPanel title={t("CreateNonWorkingDay")}>
                <Form onSubmit={handleSubmit}>
                    <SbFormTextFieldGroup
                        name="name"
                        label={t("Name", { keyPrefix: Common })}
                        maxLength={50}
                        type="text"
                        required
                    />

                    <SbFormTextFieldGroup
                        name="date"
                        label={t("Date", { keyPrefix: Common })}
                        type="date"
                        required
                    />

                    <SbFormCheckFieldGroup
                        fieldLabel={t("RecurAnnually")}
                        type="checkbox"
                        values={[
                            {
                                name: "recurAnnually",
                                onChangeHandler: handleRecurAnnuallyChange,
                            },
                        ]}
                    />

                    {recurAnnually && (
                        <>
                            <SbFormTextFieldGroup
                                name="years"
                                label={t("NumberOfYearsToGenerate")}
                                type="number"
                            />

                            <SbFormCheckFieldGroup
                                fieldLabel={t("RollOverSundayOccurrencesToMondays")}
                                type="checkbox"
                                values={[
                                    {
                                        name: "rollover",
                                    },
                                ]}
                            />
                        </>
                    )}

                    <EndAlignedDiv>
                        <SaveButton type="submit" />
                        <CancelButton onClick={() => navigate(-1)} />
                    </EndAlignedDiv>
                </Form>
            </TextTitledPanel>
        </>
    );
};

export default CreateNonWorkingDayContainer;
