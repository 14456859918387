import React, { ReactElement, useContext } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { SingleValue } from "react-select";
import styled, { css, ThemeContext } from "styled-components";
import {
    disabledSelectStyles,
    getSelectErrorStyle,
    StyledErrorDiv,
} from "../../../core/theme/global-styles";
import { CreateOnNoResultsSelect, SbSelect } from "../../atoms/input/SbSelect";
import SbLabelText from "../../atoms/SbLabelText";

const StyledFormLabel = styled(Form.Label)`
    text-align: right;
    font-weight: 600;
    color: ${(props) => props.theme.palette.secondary};

    ${({ variant }) =>
        variant === "danger" &&
        css`
            color: ${(props) => props.theme.palette.danger};
        `}
    ${({ variant }) =>
        variant === "warning" &&
        css`
            color: ${(props) => props.theme.palette.warning};
        `};
`;

interface SelectFieldGroupProps<TType> {
    name: string;
    label: string;
    placeholderText?: string;
    searchable: boolean;
    clearable: boolean;
    loading?: boolean;
    disabled?: boolean;
    required?: boolean;
    items: TType[] | undefined;
    defaultSelectedItem?: TType | null;
    itemDisplayText: (item: TType) => string;
    onChange?: (item: SingleValue<TType>) => void;
    value?: TType | null;
    onSearchTextChanged?: (searchString: string) => void;
    children?: React.ReactNode;
    variant?: "danger" | "warning";
    error?: string;
    touched?: boolean;
}

interface CreateOnNoResultsSelectFieldGroupProps<TType> extends SelectFieldGroupProps<TType> {
    createNewValue: (_: string) => TType;
    styles?: any;
}

export const SbFormSelectFieldGroup = <TType,>({
    name,
    label,
    placeholderText,
    searchable,
    clearable,
    loading,
    disabled,
    required,
    items,
    defaultSelectedItem,
    itemDisplayText,
    onChange,
    value,
    onSearchTextChanged,
    children,
    variant,
    error,
    touched,
}: SelectFieldGroupProps<TType>): ReactElement => {
    const themeContext = useContext(ThemeContext);

    return (
        <Form.Group as={Row} className="mb-3">
            <StyledFormLabel variant={variant} column sm={2}>
                <SbLabelText label={label} required={required} />
            </StyledFormLabel>
            <Col sm={4}>
                <SbSelect
                    styles={
                        disabled
                            ? disabledSelectStyles
                            : error && touched
                              ? getSelectErrorStyle(themeContext!)
                              : undefined
                    }
                    name={name}
                    placeholderText={placeholderText}
                    searchable={searchable}
                    clearable={clearable}
                    loading={loading}
                    disabled={disabled}
                    items={items}
                    defaultSelectedItem={defaultSelectedItem}
                    onChange={onChange}
                    onSearchTextChanged={onSearchTextChanged}
                    itemLabel={itemDisplayText}
                    itemValue={itemDisplayText}
                    value={value}
                />
                {error && touched && <StyledErrorDiv>{error}</StyledErrorDiv>}
            </Col>
            {children && <Col> {children}</Col>}
        </Form.Group>
    );
};

export const SbFormCreateOnNoResultsSelectFieldGroup = <TType,>({
    styles,
    name,
    label,
    placeholderText,
    searchable,
    clearable,
    loading,
    disabled,
    required,
    items,
    defaultSelectedItem,
    itemDisplayText,
    onChange,
    value,
    onSearchTextChanged,
    children,
    variant,
    createNewValue,
}: CreateOnNoResultsSelectFieldGroupProps<TType>): ReactElement => (
    <Form.Group as={Row} className="mb-3">
        <StyledFormLabel variant={variant} column sm={2}>
            <SbLabelText label={label} required={required} />
        </StyledFormLabel>
        <Col sm={4}>
            <CreateOnNoResultsSelect
                styles={styles}
                name={name}
                placeholderText={placeholderText}
                searchable={searchable}
                clearable={clearable}
                loading={loading}
                disabled={disabled}
                items={items}
                defaultSelectedItem={defaultSelectedItem}
                onChange={onChange}
                onSearchTextChanged={onSearchTextChanged}
                itemLabel={itemDisplayText}
                itemValue={itemDisplayText}
                value={value}
                createNewValue={createNewValue}
                required={required}
            />
        </Col>
        {children && <Col> {children}</Col>}
    </Form.Group>
);
