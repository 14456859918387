import { ActionItemSummaryResponse } from "../../../domain/responses/action-items/action-item-summary-response";
import { Response } from "../../../domain/responses/common/response-response";
import client from "../../http-client";

const route = "actionitemsummary";

export const getActionItemSummaries = async (
    prefixUrl: string
): Promise<Response<ActionItemSummaryResponse[]>> =>
    await client
        .get(`${route}`, {
            prefixUrl: prefixUrl,
        })
        .json();
