import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
    Common,
    HierarchyTypeTreeView,
    Nodes,
    NonWorkingDays,
} from "../../../core/constants/translation-namespace";
import { HierarchyMenuType } from "../../../core/hierarchyMenuType";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    PageSubHeading,
} from "../../../core/theme/global-styles";
import { DrawerTitles, HierarchyAssignableFlag } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { BaseNodeDetailsDto } from "../../../domain/dtos/hierarchy/base-node-details-dto";
import { AssociateNonWorkingDaySetNodeDto } from "../../../domain/dtos/non-working-days/associate-non-working-day-set-node-dto";
import {
    useAssociateNonWorkingDaySetNode,
    useGetAssociatedNonWorkingDaySetNodeAndHierarchyDetails,
} from "../../../domain/viewmodels/non-working-days/associated-non-working-day-set-node-viewmodel";
import { AssociateButton, CancelButton } from "../../atoms/SbButton";
import { RadioSelectProps } from "../../molecules/DataTableAction";
import SbTabs, { SbTabItemProps } from "../../molecules/SbTabs";
import NodeHierarchyContainer from "../hierarchy/NodeHierarchyContainer";
import TextSearchContainer from "../hierarchy/TextSearchContainer";

const AssociateNonWorkingDaySetNodeContainer: React.FC = () => {
    const [selectedNode, setSelectedNode] = useState<number>();

    const navigate = useNavigate();
    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation");

    const nonWorkingDaySetId = Number(useParams().nonWorkingDaySetId);

    const associateNonWorkingDaySetNode = useAssociateNonWorkingDaySetNode();

    const getAssociatedNonWorkingDaySetNodeAndHierarchyDetails =
        useGetAssociatedNonWorkingDaySetNodeAndHierarchyDetails(
            nonWorkingDaySetId,
            HierarchyAssignableFlag.NonWorkingDaySet
        );

    const nonWorkingDaySetResponseData =
        getAssociatedNonWorkingDaySetNodeAndHierarchyDetails[0].data;
    const hierarchyDetailsResponseData =
        getAssociatedNonWorkingDaySetNodeAndHierarchyDetails[1].data;

    useLoader(
        areQueriesLoading(getAssociatedNonWorkingDaySetNodeAndHierarchyDetails),
        AssociateNonWorkingDaySetNodeContainer
    );

    useEffect(() => {
        menu.changeActiveDrawerItem(DrawerTitles.NonWorkingDay);
    }, []);

    const selectTextSearchNode = (metadata: BaseNodeDetailsDto): void =>
        setSelectedNode(metadata.nodeId);

    const selectVisualSearchNode = (nodeId: number): void => setSelectedNode(nodeId);

    const checkSelectedNode = (metadata: BaseNodeDetailsDto): boolean => {
        const node = metadata.nodeId;

        return node === selectedNode;
    };

    const radioSelect: RadioSelectProps = {
        type: "radio",
        label: `${t("SelectNode", { keyPrefix: HierarchyTypeTreeView })}`,
    };

    const handleAssociateNode = (): void => {
        associateNonWorkingDaySetNode.mutate(
            new AssociateNonWorkingDaySetNodeDto(selectedNode!, nonWorkingDaySetId!),
            {
                onSuccess: async () => {
                    queryClient.invalidateQueries(["getNonWorkingDaySetNodes"]);

                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter("messageKey", "AssociatedToNode"),
                    ];

                    navigateSearch(
                        `${getPath(DrawerTitles.NonWorkingDay)}/${nonWorkingDaySetId}`,
                        params
                    );
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    const buildSbTabItemProps = (): SbTabItemProps[] => {
        const textSearchContainer = (
            <TextSearchContainer
                mode={"select"}
                selectNode={selectTextSearchNode}
                selectedNode={checkSelectedNode}
                radioSelect={radioSelect}
                hierarchyTypes={hierarchyDetailsResponseData!.sort(
                    (a, b) => a.hierarchyTypeId - b.hierarchyTypeId
                )}
            />
        );

        const nodeHierarchyContainer = (
            <NodeHierarchyContainer
                menuType={
                    HierarchyMenuType.Select |
                    HierarchyMenuType.Details |
                    HierarchyMenuType.ViewUsers
                }
                selectNode={selectVisualSearchNode}
                hierarchyTypes={hierarchyDetailsResponseData!.sort(
                    (a, b) => a.hierarchyTypeId - b.hierarchyTypeId
                )}
            />
        );

        return [
            new SbTabItemProps(t("TextSearch", { keyPrefix: Common }), textSearchContainer, true),
            new SbTabItemProps(t("VisualSearch", { keyPrefix: Common }), nodeHierarchyContainer),
        ];
    };

    return (
        <>
            <PageHeading>{t("AssignNode", { keyPrefix: Nodes })}</PageHeading>
            <PageSubHeading>
                {t("AssociateNodeHelpTextNonWorkingDay", { keyPrefix: NonWorkingDays })}
            </PageSubHeading>
            <LargeVerticalSpace />

            {areQueriesSuccessful(getAssociatedNonWorkingDaySetNodeAndHierarchyDetails) && (
                <>
                    <ContentContainer>
                        <Row>
                            <Col md="auto">
                                <DetailsLabel>
                                    {t("NonWorkingDaySet", {
                                        keyPrefix: NonWorkingDays,
                                    })}
                                </DetailsLabel>
                            </Col>
                            <Col md="auto">
                                <DetailsValue>{nonWorkingDaySetResponseData?.name}</DetailsValue>
                            </Col>
                        </Row>
                    </ContentContainer>
                    <LargeVerticalSpace />
                </>
            )}

            {areQueriesSuccessful(getAssociatedNonWorkingDaySetNodeAndHierarchyDetails) && (
                <ContentContainer>
                    <SbTabs values={buildSbTabItemProps()} />
                </ContentContainer>
            )}
            <LargeVerticalSpace />

            <EndAlignedDiv>
                <Col sm={"auto"}>
                    <AssociateButton onClick={handleAssociateNode} disabled={!selectedNode} />
                </Col>
                <Col sm={"auto"}>
                    <CancelButton onClick={() => navigate(-1)} />
                </Col>
            </EndAlignedDiv>
        </>
    );
};

export default AssociateNonWorkingDaySetNodeContainer;
