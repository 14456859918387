import { Draggable, DraggingStyle, NotDraggingStyle } from "react-beautiful-dnd";
import { Col, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { FaEdit } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import styled from "styled-components";
import { DetailsLabel, DetailsValue } from "../../core/theme/global-styles";
import { WizardCardDto } from "../../domain/dtos/wizards/wizard-card-dto";
import { ImageLibraryViewResponse } from "../../domain/responses/image-library/image-library-view-response";
import { SbActionButton } from "../atoms/SbActionButton";

const StyledImage = styled(Image)`
    height: 200px;
    width: 250px;
    padding: ${(props) => props.theme.padding.lg};
`;

const StyledImgContainer = styled(Col)`
    display: flex;
    align-items: center;
`;

const StyledDataContainer = styled(Col)`
    display: flex;
    align-items: center;
`;

const StyledActionsContainer = styled(Col)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: ${(props) => props.theme.padding.lg};
`;

const getWizardCardStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined
): React.CSSProperties => ({
    background: isDragging ? "#f1f1f2" : "",
    ...draggableStyle,
});

export const WizardCard: React.FC<{
    index: number;
    wizardCard: WizardCardDto;
    editWizardCard: (metadata: WizardCardDto) => void;
    deleteWizardCard: (metadata: WizardCardDto) => void;
    getImageLibraryImage: (fileDetailsId: number) => ImageLibraryViewResponse | undefined;
    sent: boolean;
}> = ({ index, wizardCard, editWizardCard, deleteWizardCard, getImageLibraryImage, sent }) => {
    return (
        <Draggable key={index} draggableId={index.toString()} index={index} isDragDisabled={sent}>
            {(provided, snapshot): JSX.Element => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getWizardCardStyle(snapshot.isDragging, provided.draggableProps.style)}
                >
                    <Row key={index}>
                        <StyledImgContainer md="auto">
                            <StyledImage
                                src={getImageLibraryImage(wizardCard.fileDetailsId)?.fileImagePath}
                                alt="wizard card img"
                            />
                        </StyledImgContainer>
                        <StyledDataContainer md="auto">
                            <Row>
                                <Col md="auto">
                                    <DetailsLabel>Heading</DetailsLabel>
                                    <DetailsLabel>Description</DetailsLabel>
                                    <DetailsLabel>Index</DetailsLabel>
                                    <DetailsLabel>FileName</DetailsLabel>
                                </Col>
                                <Col md="auto">
                                    <DetailsValue>{wizardCard.heading}</DetailsValue>
                                    <DetailsValue>{wizardCard.description}</DetailsValue>
                                    <DetailsValue>{wizardCard.index}</DetailsValue>
                                    <DetailsValue>
                                        {getImageLibraryImage(wizardCard.fileDetailsId)?.fileName}
                                    </DetailsValue>
                                </Col>
                            </Row>
                        </StyledDataContainer>
                        <StyledActionsContainer>
                            <SbActionButton
                                variant={"primary"}
                                text={"Edit"}
                                icon={FaEdit}
                                disabled={sent}
                                clickHandler={() => editWizardCard(wizardCard)}
                            />
                            <SbActionButton
                                variant={"danger"}
                                text={"Delete"}
                                icon={IoCloseSharp}
                                disabled={sent}
                                clickHandler={() => deleteWizardCard(wizardCard)}
                            />
                        </StyledActionsContainer>
                    </Row>
                </div>
            )}
        </Draggable>
    );
};
