import { Col, Row } from "react-bootstrap";
import { ConfirmationRow } from "../organisms/ActionConfirmation";

export const CreateLabelToDetailRows = (
    rows: ConfirmationRow<any>[],
    mdLabel: number,
    mdValue: number
): JSX.Element[] => {
    return rows.map((value) => {
        return (
            <Row>
                <Col md={mdLabel}>{value.getJsxForLabel()}</Col>
                <Col md={mdValue}>{value.getJsxForValue()}</Col>
            </Row>
        );
    });
};
