import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { deleteAlternativeQuestionText } from "../../../data/services/questions/question-service";
import { Response } from "../../responses/common/response-response";

export const useDeleteAlternativeQuestionText = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    number
> => {
    const url = useUrl();

    return useMutation((questionTextId: number) => {
        return deleteAlternativeQuestionText(url.baseUrl, questionTextId);
    });
};
