import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { createWizard } from "../../../data/services/wizards/wizards-service";
import { Response } from "../../responses/common/response-response";

interface CreateParameters {
    formData: FormData;
}

export const useCreateWizard = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    CreateParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: CreateParameters) => {
        const formData = mutationKey.formData;

        const request = {
            wizardName: formData.get("name")! as string,
        };

        return createWizard(url.originalBaseUrl, request);
    });
};
