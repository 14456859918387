import { useMutation, UseMutationResult, useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { AuthContextType, useAuth } from "../../../core/store/auth-context";
import { useUrl } from "../../../core/store/url-context";
import { AccordionTitles, DataTableColumnTypes } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { filterNodeTypeValues } from "../../../data/services/hierarchy/node-type-values-service";
import {
    associateChildNodeType,
    associateLinkedNodeType,
    disassociateChildNodeType,
    disassociateLinkedNodeType,
    filterChildNodeTypes,
    filterLinkableChildNodeTypes,
    filterLinkedNodeTypes,
    filterNodeTypes,
    getNodeTypeDetails,
} from "../../../data/services/hierarchy/node-types-service";
import { BasePaginationDto, createBasePaginationDto } from "../../dtos/common/base-pagination-dto";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { AssociateDisassociateNodeTypeDto } from "../../dtos/hierarchy/associate-disassociate-node-type-dto";
import { AssociateLinkedNodeTypeDto } from "../../dtos/hierarchy/associate-linked-node-type-dto";
import { DisassociateLinkedNodeTypeDto } from "../../dtos/hierarchy/disassociate-linked-node-type-dto";
import {
    createLinkedNodeTypeDto,
    LinkedNodeTypeDto,
} from "../../dtos/hierarchy/linked-node-type-dto";
import {
    createNodeTypeDetailsDto,
    NodeTypeDetailsDto,
} from "../../dtos/hierarchy/node-type-details-dto";
import {
    NodeTypeDto,
    toNodeTypeDtosFromPaginatedResponse,
    toNodeTypeDtosFromResponse,
} from "../../dtos/hierarchy/node-type-dto";
import { hasRoleTypeInGroup, NodeTypeRoleGroup } from "../../enums/Roles";
import { createAssociateLinkedNodeTypeRequest } from "../../requests/hierarchy/associate-linked-node-type-request";
import { createDisassociateLinkedNodeTypeRequest } from "../../requests/hierarchy/disassociate-linked-node-type-request";
import { createFilterByNodeTypeRequestFromDto } from "../../requests/hierarchy/filter-by-node-type-request";
import { createFilterNodeTypeValuesRequest } from "../../requests/hierarchy/filter-node-type-values-request";
import { createFilterNodeTypesRequest } from "../../requests/hierarchy/filter-node-types-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { createAssociateDisassociateNodeTypeRequest } from "../../responses/hierarchy/associate-disassociate-node-type-request";
import { LinkedNodeTypeResponse } from "../../responses/hierarchy/linked-node-type-response";
import { NodeTypeResponse } from "../../responses/hierarchy/node-type-response";
import { NodeTypeValueResponse } from "../../responses/hierarchy/node-type-value-response";

export const useGetNodeTypeData = (
    nodeTypeId: number,
    nodeTypeValueValue: string | null,
    nodeTypeValuesPaginationDto: BasePaginationDto,
    childNodeTypesPaginationDto: BasePaginationDto,
    linkedNodeTypesPaginationDto: BasePaginationDto
): [
    UseQueryResult<NodeTypeDetailsDto, HTTPError>,
    UseQueryResult<PaginatedTableDto<number>, HTTPError>,
    UseQueryResult<PaginatedTableDto<number>, HTTPError>,
    UseQueryResult<NodeTypeDto[], HTTPError>,
    UseQueryResult<PaginatedTableDto<LinkedNodeTypeDto>, HTTPError>,
    UseQueryResult<NodeTypeDto[], HTTPError>,
] => {
    const url = useUrl();
    const auth = useAuth();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getNodeTypeDetails", nodeTypeId],
                queryFn: () => getNodeTypeDetails(url.baseUrl, nodeTypeId),
                enabled: hasRoleTypeInGroup(auth.userRoles, NodeTypeRoleGroup.WriteRoles),
                select: createNodeTypeDetailsDto,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: [
                    "filterNodeTypeValues",
                    nodeTypeId,
                    nodeTypeValueValue,
                    nodeTypeValuesPaginationDto,
                ],
                queryFn: () =>
                    filterNodeTypeValues(
                        url.baseUrl,
                        createFilterNodeTypeValuesRequest(
                            null,
                            nodeTypeId,
                            nodeTypeValueValue,
                            null,
                            nodeTypeValuesPaginationDto
                        )
                    ),
                keepPreviousData: true,
                select: (response: Response<PaginationResponse<NodeTypeValueResponse>>) =>
                    toNodeTypeValuesDataTableDtoRows(response.data, navigate),
                enabled: hasRoleTypeInGroup(auth.userRoles, NodeTypeRoleGroup.WriteRoles),
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["filterChildNodeTypes", nodeTypeId, childNodeTypesPaginationDto],
                queryFn: () =>
                    filterChildNodeTypes(
                        url.baseUrl,
                        createFilterByNodeTypeRequestFromDto(
                            childNodeTypesPaginationDto,
                            nodeTypeId
                        )
                    ),
                select: (response: Response<PaginationResponse<NodeTypeResponse>>) =>
                    toChildNodeTypesDataTableDtoRows(response.data, auth, navigate),
                enabled: hasRoleTypeInGroup(auth.userRoles, NodeTypeRoleGroup.WriteRoles),
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["filterLinkableChildNodeTypes", nodeTypeId],
                queryFn: () => filterLinkableChildNodeTypes(url.baseUrl, nodeTypeId),
                enabled: hasRoleTypeInGroup(auth.userRoles, NodeTypeRoleGroup.WriteRoles),
                select: toNodeTypeDtosFromResponse,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["filterLinkedNodeTypes", nodeTypeId, linkedNodeTypesPaginationDto],
                queryFn: () =>
                    filterLinkedNodeTypes(
                        url.baseUrl,
                        createFilterByNodeTypeRequestFromDto(
                            linkedNodeTypesPaginationDto,
                            nodeTypeId
                        )
                    ),
                select: (response: Response<PaginationResponse<LinkedNodeTypeResponse>>) =>
                    toLinkedNodeTypesDataTableDtoRows(response.data, auth, navigate),
                enabled: hasRoleTypeInGroup(auth.userRoles, NodeTypeRoleGroup.WriteRoles),
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["filterNodeTypes"],
                queryFn: () =>
                    filterNodeTypes(
                        url.baseUrl,
                        createFilterNodeTypesRequest(null, null, createBasePaginationDto(1, 1000))
                    ),
                enabled: hasRoleTypeInGroup(auth.userRoles, NodeTypeRoleGroup.WriteRoles),
                select: toNodeTypeDtosFromPaginatedResponse,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<NodeTypeDetailsDto, HTTPError>,
        UseQueryResult<PaginatedTableDto<number>, HTTPError>,
        UseQueryResult<PaginatedTableDto<number>, HTTPError>,
        UseQueryResult<NodeTypeDto[], HTTPError>,
        UseQueryResult<PaginatedTableDto<LinkedNodeTypeDto>, HTTPError>,
        UseQueryResult<NodeTypeDto[], HTTPError>,
    ];
};

export const useAssociateChildNodeType = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    AssociateDisassociateNodeTypeDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: AssociateDisassociateNodeTypeDto) => {
        const request = createAssociateDisassociateNodeTypeRequest(mutationKey);

        return associateChildNodeType(url.baseUrl, request);
    });
};

export const useDisassociateChildNodeType = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    AssociateDisassociateNodeTypeDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: AssociateDisassociateNodeTypeDto) => {
        const request = createAssociateDisassociateNodeTypeRequest(mutationKey);

        return disassociateChildNodeType(url.baseUrl, request);
    });
};

export const useAssociateLinkedNodeType = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    AssociateLinkedNodeTypeDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: AssociateLinkedNodeTypeDto) => {
        const request = createAssociateLinkedNodeTypeRequest(mutationKey);

        return associateLinkedNodeType(url.baseUrl, request);
    });
};

export const useDisassociateLinkedNodeType = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    DisassociateLinkedNodeTypeDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: DisassociateLinkedNodeTypeDto) => {
        const request = createDisassociateLinkedNodeTypeRequest(mutationKey);

        return disassociateLinkedNodeType(url.baseUrl, request);
    });
};

const toNodeTypeValuesDataTableDtoRows = (
    response: PaginationResponse<NodeTypeValueResponse>,
    navigate: NavigateFunction
): PaginatedTableDto<number> => {
    const { pageCount, pageSize, currentPage, recordCount } = response;

    const rows = response.results.map((x) => ({
        metadata: x.nodeTypeValueId,
        columns: [
            {
                value: x.value,
                type: DataTableColumnTypes.Link,
                linkItemAction: (nodeTypeValueId: number) => {
                    navigate(
                        `${getPath(AccordionTitles.VisualStructureValues)}/${nodeTypeValueId}`
                    );
                },
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};

const toChildNodeTypesDataTableDtoRows = (
    response: PaginationResponse<NodeTypeResponse>,
    auth: AuthContextType,
    navigate: NavigateFunction
): PaginatedTableDto<number> => {
    const { pageCount, pageSize, currentPage, recordCount } = response;

    const showDeleteAction = hasRoleTypeInGroup(auth.userRoles, NodeTypeRoleGroup.WriteRoles);

    const rows = response.results.map((x) => ({
        metadata: x.nodeTypeId,
        showDeleteAction: showDeleteAction,
        columns: [
            {
                value: x.name,
                type: DataTableColumnTypes.Link,
                linkItemAction: (nodeTypeId: number) =>
                    navigate(`${getPath(AccordionTitles.VisualStructure)}/${nodeTypeId}`),
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};

const toLinkedNodeTypesDataTableDtoRows = (
    response: PaginationResponse<LinkedNodeTypeResponse>,
    auth: AuthContextType,
    navigate: NavigateFunction
): PaginatedTableDto<LinkedNodeTypeDto> => {
    const { pageCount, pageSize, currentPage, recordCount } = response;

    const showDeleteAction = hasRoleTypeInGroup(auth.userRoles, NodeTypeRoleGroup.WriteRoles);

    const rows = response.results.map((x) => ({
        metadata: createLinkedNodeTypeDto(x),
        showDeleteAction: showDeleteAction,
        columns: [
            {
                value: x.nodeType.name,
                type: DataTableColumnTypes.Link,
                linkItemAction: (linkedNodeTypeDto: LinkedNodeTypeDto) =>
                    navigate(
                        `${getPath(AccordionTitles.VisualStructure)}/${
                            linkedNodeTypeDto.nodeType.nodeTypeId
                        }`
                    ),
            },
            {
                value: x.isRequired.toString(),
                type: DataTableColumnTypes.Check,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
