import { PeriodType } from "../../domain/enums/PeriodType";
import { TableRow } from "./customTypes";
import { PeriodTypeOptions } from "./QuestionSetValues";

export const columnStringValue = (
    columnMetadata: string,
    row: TableRow<number>
): string | undefined => {
    return row.columns.find((x) => x.metadata === columnMetadata)?.value;
};

export const columnPeriodValue = (
    columnMetadata: string,
    row: TableRow<number>
): PeriodType | undefined => {
    return PeriodTypeOptions.find(
        (x) => x.label === row.columns.find((x) => x.metadata === columnMetadata)?.periodTypeValue
    )?.value;
};

export const columnMinutesValue = (
    columnMetadata: string,
    row: TableRow<number>
): number | undefined => {
    const period = PeriodTypeOptions.find(
        (x) => x.label === row.columns.find((x) => x.metadata === columnMetadata)?.periodTypeValue
    )?.value;

    const value = row.columns.find((x) => x.metadata === columnMetadata)?.value;

    return calculateNumberOfMinutesFromInterval(period ?? PeriodType.Hours, Number(value));
};

const numberOfMinutesInAnHour = 60;
const numberOfMinutesInADay = 1440;

const calculateNumberOfMinutesFromInterval = (period: PeriodType, value: number): number => {
    return period === PeriodType.Days
        ? value * numberOfMinutesInADay
        : value * numberOfMinutesInAnHour;
};
