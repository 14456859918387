import { useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { getChecklistAssignmentDetails } from "../../../data/services/checklist-assignment/checklist-assignment-service";
import { getChecklistDetails } from "../../../data/services/checklists/checklist-service";
import {
    ChecklistAssignmentDetailsDto,
    createChecklistAssignmentDetailsDto,
} from "../../dtos/checklist-assignment/checklist-assignment-details-dto";
import { ChecklistDto, createChecklistDto } from "../../dtos/checklists/checklist-dto";

export const useGetActivateSuspendChecklistAssignmentDetails = (
    checklistAssignmentId: number,
    checklistId: number
): [
    UseQueryResult<ChecklistAssignmentDetailsDto, HTTPError>,
    UseQueryResult<ChecklistDto, HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getChecklistAssignmentDetails", checklistAssignmentId],
                queryFn: () => getChecklistAssignmentDetails(url.baseUrl, checklistAssignmentId),
                select: createChecklistAssignmentDetailsDto,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getChecklist", checklistId],
                queryFn: () => getChecklistDetails(url.baseUrl, checklistId),
                select: createChecklistDto,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<ChecklistAssignmentDetailsDto, HTTPError>,
        UseQueryResult<ChecklistDto, HTTPError>,
    ];
};
