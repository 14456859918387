import { convertToggleValueToBoolean } from "../../../core/utilities/convertToBoolean";
import { getNullableFormField } from "../../../core/utilities/formDataHelper";
import { toBoolean } from "../../../core/utilities/string-helper";
import { ActionItemImportance } from "../../enums/action-items/ActionItemImportance";
import { ActionItemNodeAssociationMode } from "../../enums/action-items/ActionItemNodeAssociationMode";
import AnswerValueType from "../../enums/answer-types/answer-value-type";
import { NotificationTriggerDto } from "../notification-triggers/notification-trigger-dto";
import { ActionItemDynamicFieldDetailsDto } from "./action-item-dynamic-fields-dto";
import { CreateActionItemCustomNotificationDto } from "./create-action-item-custom-notification-dto";
import { CreateActionItemNotificationTriggerDto } from "./create-action-item-notification-trigger-dto";
import { CreateEditActionItemDynamicFieldValueDto } from "./create-edit-action-item-dynamic-field-value-dto";
import { CreateRaciSetUserDto } from "./create-raci-set-user-dto";
import { RaciParticipantDto } from "./raci-participant-dto";

export class CreateActionItemDto {
    actionPlanId: number | null;
    name: string;
    description: string;
    actionItemImportance: ActionItemImportance;
    startDate: Date;
    dueDate: Date;
    isPersonalActionItem: boolean;
    actionItemSubTypeNodeId: number;
    requiredOutcome: string;
    createEditActionItemDynamicFieldValueDto: CreateEditActionItemDynamicFieldValueDto[];
    createRaciSetUserDto: CreateRaciSetUserDto[];
    overrideNotificationTriggers: boolean;
    createActionItemCustomNotificationDto: CreateActionItemCustomNotificationDto | null;
    createActionItemNotificationTriggerDto: CreateActionItemNotificationTriggerDto[] | null;
    organisationalNodeId: number;
    nodeAssociationMode: ActionItemNodeAssociationMode;

    public constructor(
        formData: FormData,
        actionItemImportance: ActionItemImportance,
        actionItemSubTypeNodeId: number,
        actionItemDynamicFieldDetailsDtos: ActionItemDynamicFieldDetailsDto[],
        raciParticipants: RaciParticipantDto[],
        actionPlanId: number | undefined,
        notificationTriggerDtos: NotificationTriggerDto[] | null,
        actionItemTypeNodeIdChanged: boolean,
        isClone: boolean,
        organisationalNodeId: number,
        nodeAssociationMode: ActionItemNodeAssociationMode
    ) {
        this.actionPlanId = actionPlanId ?? null;
        this.name = formData.get("name") as string;
        this.description = formData.get("description") as string;
        this.actionItemImportance = actionItemImportance;
        this.startDate = new Date(formData.get("startDate") as string);
        this.dueDate = new Date(formData.get("dueDate") as string);
        this.isPersonalActionItem = convertToggleValueToBoolean(
            formData.get("isPersonalActionItem")! as string
        );
        this.actionItemSubTypeNodeId = actionItemSubTypeNodeId;
        this.requiredOutcome = formData.get("requiredOutcome") as string;
        this.createEditActionItemDynamicFieldValueDto = [];

        this.createRaciSetUserDto = isClone
            ? CreateRaciSetUserDto.toCreateRaciSetUserDtosForClone(raciParticipants, formData)
            : CreateRaciSetUserDto.toCreateRaciSetUserDtos(raciParticipants, formData);

        this.overrideNotificationTriggers = convertToggleValueToBoolean(
            formData.get("overrideNotificationTriggers")! as string
        );
        this.createActionItemCustomNotificationDto = null;
        if (this.overrideNotificationTriggers === true) {
            this.createActionItemCustomNotificationDto = new CreateActionItemCustomNotificationDto(
                formData
            );
        }
        this.createActionItemNotificationTriggerDto = [];

        notificationTriggerDtos &&
            notificationTriggerDtos?.map((x) =>
                this.createActionItemNotificationTriggerDto?.push(
                    new CreateActionItemNotificationTriggerDto(formData, x.notificationTriggerId)
                )
            );

        actionItemDynamicFieldDetailsDtos?.map((x) => {
            const actionItemDynamicFieldId = actionItemTypeNodeIdChanged
                ? x.actionItemDynamicFieldId.toString()
                : `existing${x.actionItemDynamicFieldId.toString()}`;

            const actionItemDynamicFieldValue = getNullableFormField(
                formData.get(actionItemDynamicFieldId) as string
            );

            this.createEditActionItemDynamicFieldValueDto?.push({
                actionItemDynamicFieldId: x.actionItemDynamicFieldId,
                intValue:
                    x.valueType == AnswerValueType.Integer && actionItemDynamicFieldValue !== null
                        ? Number(actionItemDynamicFieldValue!)
                        : null,
                boolValue:
                    x.valueType == AnswerValueType.Boolean && actionItemDynamicFieldValue !== null
                        ? toBoolean(actionItemDynamicFieldValue!)
                        : null,
                dateValue:
                    x.valueType == AnswerValueType.DateTime && actionItemDynamicFieldValue !== null
                        ? new Date(actionItemDynamicFieldValue!)
                        : null,
                decimalValue:
                    x.valueType == AnswerValueType.Decimal && actionItemDynamicFieldValue !== null
                        ? Number(actionItemDynamicFieldValue!)
                        : null,
                texArea:
                    x.valueType == AnswerValueType.TextArea && actionItemDynamicFieldValue !== null
                        ? actionItemDynamicFieldValue!
                        : null,
                textValue:
                    (x.valueType == AnswerValueType.Text || x.valueType == AnswerValueType.List) &&
                    actionItemDynamicFieldValue !== null
                        ? actionItemDynamicFieldValue!
                        : null,
            });
        });

        this.organisationalNodeId = organisationalNodeId;
        this.nodeAssociationMode = nodeAssociationMode;
    }

    public static createActionItemDtoForCloneDto = (
        formData: FormData,
        actionItemImportance: ActionItemImportance,
        actionItemSubTypeNodeId: number,
        actionItemDynamicFieldDetailsDtos: ActionItemDynamicFieldDetailsDto[],
        raciParticipants: RaciParticipantDto[],
        actionPlanId: number | undefined,
        notificationTriggerDtos: NotificationTriggerDto[] | null,
        actionItemTypeNodeIdChanged: boolean,
        organisationalNodeId: number,
        nodeAssociationMode: ActionItemNodeAssociationMode
    ): CreateActionItemDto =>
        new CreateActionItemDto(
            formData,
            actionItemImportance,
            actionItemSubTypeNodeId,
            actionItemDynamicFieldDetailsDtos,
            raciParticipants,
            actionPlanId,
            notificationTriggerDtos,
            actionItemTypeNodeIdChanged,
            true,
            organisationalNodeId,
            nodeAssociationMode
        );

    public static createActionItemDto = (
        formData: FormData,
        actionItemImportance: ActionItemImportance,
        actionItemSubTypeNodeId: number,
        actionItemDynamicFieldDetailsDtos: ActionItemDynamicFieldDetailsDto[],
        raciParticipants: RaciParticipantDto[],
        actionPlanId: number | undefined,
        organisationalNodeId: number,
        nodeAssociationMode: ActionItemNodeAssociationMode
    ): CreateActionItemDto =>
        new CreateActionItemDto(
            formData,
            actionItemImportance,
            actionItemSubTypeNodeId,
            actionItemDynamicFieldDetailsDtos,
            raciParticipants,
            actionPlanId,
            null,
            true,
            false,
            organisationalNodeId,
            nodeAssociationMode
        );
}
