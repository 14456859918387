import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { getBase64FileString } from "../../../core/utilities/file-helper";
import { uploadImage } from "../../../data/services/image-library/image-library-service";
import { Response } from "../../../domain/responses/common/response-response";

export const useUploadImage = (): UseMutationResult<
    Response<number | null>,
    HTTPError,
    FormData
> => {
    const url = useUrl();

    return useMutation(async (formData: FormData): Promise<Response<number | null>> => {
        const file = formData.get("file")! as File;
        const fileString = await getBase64FileString(file);
        const uploadImageRequest = {
            fileName: file.name,
            fileType: file.type,
            file: fileString ? fileString : "", // TODO: Cater for error case (for when base64 of file fails)
        };

        return uploadImage(url.baseUrl, uploadImageRequest);
    });
};
