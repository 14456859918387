import { AnswerSummaryResponse } from "../../responses/answer-summary/answer-summary-response";
import { Response } from "../../responses/common/response-response";

export class AnswerSummaryDto {
    nodeId: number;
    parentNodeTypeValue: string;
    nodeTypeValue: string;
    checklistCount: number;
    questionCount: number;
    userCount: number;
    notDueCount: number;
    completedCount: number;
    dueCount: number;
    overdueCount: number;
    isTraining: number;
    nodePath: string;
    totalCount: number;

    public constructor(response: AnswerSummaryResponse) {
        this.nodeId = response.nodeId;
        this.parentNodeTypeValue = response.parentNodeTypeValue;
        this.nodeTypeValue = response.nodeTypeValue;
        this.checklistCount = response.checklistCount;
        this.questionCount = response.questionCount;
        this.userCount = response.userCount;
        this.notDueCount = response.notDueCount;
        this.completedCount = response.completedCount;
        this.dueCount = response.dueCount;
        this.overdueCount = response.overdueCount;
        this.isTraining = response.isTraining;
        this.nodePath = response.nodePath;
        this.totalCount = response.totalCount;
    }

    public static constructFromAnswerSummaryResponses = (
        response: Response<AnswerSummaryResponse[]>
    ): AnswerSummaryDto[] => response.data?.map((x) => new AnswerSummaryDto(x));
}
