import { AadAuthenticationRequestDto } from "../../dtos/auth/aad-authentication-request-dto";

export class AadAuthenticationRequest {
    token: string;
    account: any;

    public constructor(dto: AadAuthenticationRequestDto) {
        this.token = dto.token;
        this.account = dto.account;
    }
}
