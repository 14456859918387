import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { TableRow } from "../../../core/utilities/customTypes";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import { filterQuestions } from "../../../data/services/questions/question-service";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { FilterQuestionsDto } from "../../dtos/questions/filter-questions-dto";
import { createFilterQuestionsRequestFromDto } from "../../requests/questions/filter-questions-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { NestedQuestionResponse } from "../../responses/questions/nested-question-response";
import { QuestionResponse } from "../../responses/questions/question-response";

export const useGetQuestions = (
    filterDto: FilterQuestionsDto
): UseQueryResult<PaginatedTableDto<number>, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterQuestions", filterDto],
        () => filterQuestions(url.baseUrl, createFilterQuestionsRequestFromDto(filterDto)),
        {
            keepPreviousData: true,
            select: transformToDataTableRows,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const transformToDataTableRows = (
    response: Response<PaginationResponse<QuestionResponse>>
): PaginatedTableDto<number> => {
    const rows = response.data.results!.map((question) => {
        return {
            metadata: question.questionId,
            nestedRows: question.childQuestionResponses?.length
                ? transformToNestedQuestionsRows(question.childQuestionResponses)
                : undefined,
            columns: [
                {
                    //ID
                    value: question.questionId.toString(),
                    type: DataTableColumnTypes.Text,
                },
                {
                    //Question Text
                    value: question.questionText.text,
                    type: DataTableColumnTypes.Text,
                },
                {
                    //Question Type
                    value: question.questionType.name,
                    type: DataTableColumnTypes.Text,
                },
                {
                    //Category name
                    value: question.questionCategory.name,
                    type: DataTableColumnTypes.Text,
                },
                {
                    //Answer Type
                    value: question.answerTypeResponse.name,
                    type: DataTableColumnTypes.Text,
                },
                {
                    // Nested questions
                    value:
                        question.childQuestionResponses && question.childQuestionResponses.length
                            ? "Show/Hide"
                            : "None",
                    type: DataTableColumnTypes.DisplayNestedTableButton,
                    metadata: "NestedQuestions",
                },
            ],
        };
    });

    return {
        numberOfPages: response.data.pageCount,
        pageSize: response.data.pageSize,
        currentPage: response.data.currentPage,
        recordCount: response.data.recordCount,
        rows: rows,
    };
};

const transformToNestedQuestionsRows = (
    nestedQuestions: NestedQuestionResponse[]
): TableRow<number>[] => {
    const rows = nestedQuestions.map((nestedQuestion) => ({
        metadata: nestedQuestion.nestedQuestionId,
        columns: [
            {
                // Order Index
                value: nestedQuestion.orderIndex?.toString(),
                type: DataTableColumnTypes.Text,
                metadata: "OrderIndex",
            },
            {
                // ID
                value: nestedQuestion.questionId.toString(),
                type: DataTableColumnTypes.Link,
                metadata: "ID",
            },
            {
                // Question Text
                value: nestedQuestion.questionText?.text,
                type: DataTableColumnTypes.Text,
                metadata: "QuestionText",
            },
            {
                // Category
                value: nestedQuestion.questionCategory?.name,
                type: DataTableColumnTypes.Text,
                metadata: "Category",
            },
            {
                // Type
                value: nestedQuestion.questionType?.name,
                type: DataTableColumnTypes.Text,
                metadata: "Type",
            },
            {
                // Answer Type
                value: nestedQuestion.answerTypeResponse?.name,
                type: DataTableColumnTypes.Text,
                metadata: "AnswerType",
            },
            {
                // Render Mode
                value: nestedQuestion.renderInline ? "Inline" : "Default",
                type: DataTableColumnTypes.Text,
                metadata: "RenderMode",
            },
            {
                // Parent Question Answer Trigger
                value:
                    nestedQuestion.parentQuestionAnswerTrigger?.toString() === ""
                        ? "No"
                        : nestedQuestion.parentQuestionAnswerTrigger?.toString(),
                type: DataTableColumnTypes.Text,
                metadata: "ParentQuestionAnswerTrigger",
            },
        ],
    }));

    return rows;
};
