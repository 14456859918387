import NestedQuestionCaptureResponse from "../../responses/answer-capture/nested-question-capture-response";
import QuestionCaptureResponse from "../../responses/answer-capture/question-capture-response";
import SavedNestedQuestionCaptureResponse from "../../responses/answer-capture/saved-nested-question-capture-response";
import QuestionSetInstanceAnswerStagingFileDetailsResponse from "../../responses/question-set-instance-answers/question-set-instance-answer-staging-file-details-response";
import { FileDto } from "../file-storage/file-dto";
import BaseQuestionAnswerDto from "./base-question-answer-dto";
import QuestionAnswerInstanceDto from "./question-answer-instance-dto";

export default class NestedQuestionAnswerDto extends BaseQuestionAnswerDto {
    questionSetInstanceAnswerStagingId: number;
    questionSetInstanceAnswerStagingFileDtos: FileDto[];

    constructor(
        questionSetInstanceAnswerId: number,
        questionId: number,
        parentQuestionId: number | null,
        questionSetInstanceAnswerStagingId: number,
        questionSetInstanceAnswerFileDetailsResponses: QuestionSetInstanceAnswerStagingFileDetailsResponse[]
    ) {
        super(questionSetInstanceAnswerId, questionId, parentQuestionId);

        this.questionSetInstanceAnswerStagingId = questionSetInstanceAnswerStagingId;
        this.questionSetInstanceAnswerStagingFileDtos =
            FileDto.constructFromQuestionSetInstanceAnswerStagingFileDetailsResponses(
                questionSetInstanceAnswerFileDetailsResponses
            );
    }

    public static constructFromSavedAnswer = (
        questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        savedNestedQuestionCaptureResponse: SavedNestedQuestionCaptureResponse
    ): NestedQuestionAnswerDto => {
        const { questionSetInstanceAnswerId, questionId, parentQuestionId } =
            questionAnswerInstanceDto;

        const {
            predefinedAnswerId,
            textValue,
            intValue,
            decimalValue,
            boolValue,
            commentText,
            questionReviewComment,
            actionItemDueDateLocal,
            questionSetInstanceAnswerStagingId,
            questionSetInstanceAnswerFileDetailsResponses,
        } = savedNestedQuestionCaptureResponse;

        const nestedQuestionAnswerDto = new NestedQuestionAnswerDto(
            questionSetInstanceAnswerId,
            questionId,
            parentQuestionId,
            questionSetInstanceAnswerStagingId,
            questionSetInstanceAnswerFileDetailsResponses
        );

        nestedQuestionAnswerDto.textValue = textValue;
        nestedQuestionAnswerDto.intValue = intValue;
        nestedQuestionAnswerDto.decimalValue = decimalValue;
        nestedQuestionAnswerDto.boolValue = boolValue;
        nestedQuestionAnswerDto.predefinedAnswerId = predefinedAnswerId;
        nestedQuestionAnswerDto.actionItemDueDateTimeLocal = actionItemDueDateLocal
            ? new Date(actionItemDueDateLocal)
            : null;
        nestedQuestionAnswerDto.commentText = commentText;
        nestedQuestionAnswerDto.reviewCommentText = questionReviewComment;

        return nestedQuestionAnswerDto;
    };

    // TODO: Improvement - Have this accept a list of SavedNestedQuestionCaptureResponses instead
    // TODO: Improvement - Move the creation of QuestionAnswerInstanceDto into NestedQuestionAnswerDto.constructFromSavedAnswer
    public static constructFromQuestionCaptureResponses = (
        questionCaptureResponses: QuestionCaptureResponse[]
    ): NestedQuestionAnswerDto[] => {
        const getSavedNestedQuestionAnswers = (
            responses: NestedQuestionCaptureResponse[] | null
        ): NestedQuestionAnswerDto[] =>
            (responses || []).flatMap((y) => [
                ...(y.savedNestedQuestionCaptureResponse
                    ? [
                          NestedQuestionAnswerDto.constructFromSavedAnswer(
                              new QuestionAnswerInstanceDto(
                                  y.parentQuestionSetInstanceAnswerId,
                                  y.questionTextResponse.questionId,
                                  y.parentQuestionId
                              ),
                              y.savedNestedQuestionCaptureResponse
                          ),
                      ]
                    : []),
                ...getSavedNestedQuestionAnswers(y.nestedQuestionCaptureResponses || []),
            ]);

        return questionCaptureResponses.flatMap((x) =>
            getSavedNestedQuestionAnswers(x.nestedQuestionCaptureResponses)
        );
    };
}
