import { Response } from "../../responses/common/response-response";
import { UserClaimResponse } from "../../responses/users/user-claim-response";

export class UserClaimDto {
    languageCulture: string;
    windowsTimeZoneId: string;
    ianaTimeZoneId: string;
    homePreference: string;

    public constructor(userClaimResponse: UserClaimResponse) {
        this.languageCulture = userClaimResponse.languageCulture;
        this.windowsTimeZoneId = userClaimResponse.windowsTimeZoneId;
        this.ianaTimeZoneId = userClaimResponse.ianaTimeZoneId;
        this.homePreference = userClaimResponse.homePreference;
    }
}

export const toUserClaimDto = (response: Response<UserClaimResponse>): UserClaimDto =>
    new UserClaimDto(response.data);
