import { useTranslation } from "react-i18next";
import { Common } from "../../core/constants/translation-namespace";
import { FrequencyType } from "../../domain/enums/questions/question-sets/FrequencyType";
import SbFormCheckFieldGroup from "../molecules/input/SbFormCheckFieldGroup";

export const FrequencyTypeSchedule: React.FC<{
    handleFrequencyChange: (_name: string, _checked: boolean) => void;
    selectedFrequencyOption: string;
    useAutoColumnWidth?: boolean | null;
    disabled?: boolean;
}> = ({ handleFrequencyChange, selectedFrequencyOption, useAutoColumnWidth, disabled }) => {
    const { t } = useTranslation("translation", { keyPrefix: Common });
    return (
        <>
            <SbFormCheckFieldGroup
                fieldLabel=""
                type="radio"
                useAutoColumnWidth={useAutoColumnWidth}
                values={[
                    {
                        name: FrequencyType.Daily.toString(),
                        label: `${t("Daily")}`,
                        defaultSelected: selectedFrequencyOption === FrequencyType.Daily.toString(),
                        onChangeHandler: handleFrequencyChange,
                        disabled: disabled,
                    },
                    {
                        name: FrequencyType.Weekly.toString(),
                        label: `${t("Weekly")}`,
                        defaultSelected:
                            selectedFrequencyOption === FrequencyType.Weekly.toString(),
                        onChangeHandler: handleFrequencyChange,
                        disabled: disabled,
                    },
                    {
                        name: FrequencyType.Monthly.toString(),
                        label: `${t("Monthly")}`,
                        defaultSelected:
                            selectedFrequencyOption === FrequencyType.Monthly.toString(),
                        onChangeHandler: handleFrequencyChange,
                        disabled: disabled,
                    },
                    {
                        name: FrequencyType.Yearly.toString(),
                        label: `${t("Yearly")}`,
                        defaultSelected:
                            selectedFrequencyOption === FrequencyType.Yearly.toString(),
                        onChangeHandler: handleFrequencyChange,
                        disabled: disabled,
                    },
                    {
                        name: FrequencyType.OnceOff.toString(),
                        label: `${t("OnceOff")}`,
                        defaultSelected:
                            selectedFrequencyOption === FrequencyType.OnceOff.toString(),
                        onChangeHandler: handleFrequencyChange,
                        disabled: disabled,
                    },
                    {
                        name: FrequencyType.Adhoc.toString(),
                        label: `${t("Adhoc")}`,
                        defaultSelected: selectedFrequencyOption === FrequencyType.Adhoc.toString(),
                        onChangeHandler: handleFrequencyChange,
                        disabled: disabled,
                    },
                ]}
            />
        </>
    );
};
