import { EditActionItemDto } from "../../dtos/actions/edit-action-item-dto";
import { ActionItemImportance } from "../../enums/action-items/ActionItemImportance";
import { CreateEditActionItemDynamicFieldValueRequest } from "./create-edit-action-item-dynamic-field-value-request";

export class EditActionItemRequest {
    actionItemId: number;
    name: string;
    description: string;
    actionItemImportance: ActionItemImportance;
    startDate: Date | null;
    dueDate: Date | null;
    isPersonalActionItem: boolean;
    actionItemSubTypeNodeId: number;
    requiredOutcome: string;
    createEditActionItemDynamicFieldValueRequests: CreateEditActionItemDynamicFieldValueRequest[];

    public constructor(dto: EditActionItemDto) {
        const {
            actionItemId,
            name,
            description,
            actionItemImportance,
            startDate,
            dueDate,
            isPersonalActionItem,
            actionItemSubTypeNodeId,
            createEditActionItemDynamicFieldValueDto,
            requiredOutcome,
        } = dto;

        this.actionItemId = actionItemId;
        this.name = name;
        this.description = description;
        this.actionItemImportance = actionItemImportance;
        this.startDate = startDate;
        this.dueDate = dueDate;
        this.isPersonalActionItem = isPersonalActionItem;
        this.actionItemSubTypeNodeId = actionItemSubTypeNodeId;
        this.requiredOutcome = requiredOutcome;
        this.createEditActionItemDynamicFieldValueRequests =
            CreateEditActionItemDynamicFieldValueRequest.toCreateEditActionItemDynamicFieldValueRequests(
                createEditActionItemDynamicFieldValueDto
            );
    }
}
