import { ActionItemImportanceResponse } from "../../../domain/responses/actions/action-item-importance-response";
import { Response } from "../../../domain/responses/common/response-response";
import client from "../../http-client";

const route = "actionitemimportance";

export const getActionItemImportance = async (
    prefixUrl: string
): Promise<Response<ActionItemImportanceResponse[]>> =>
    await client
        .get(route, {
            prefixUrl: prefixUrl,
        })
        .json();
