import { OverlayTrigger } from "react-bootstrap";
import { FaQuestionCircle } from "react-icons/fa";
import styled from "styled-components";
import { StyledPopover } from "../../core/theme/global-styles";

const StyledSpan = styled.span`
    class: d-inline-block;
`;

const SbFormToolTip: React.FC<{
    toolTip: string;
}> = ({ toolTip }) => {
    return (
        <OverlayTrigger overlay={<StyledPopover>{toolTip}</StyledPopover>}>
            <StyledSpan>
                <FaQuestionCircle style={{ pointerEvents: "none" }} />
            </StyledSpan>
        </OverlayTrigger>
    );
};

export default SbFormToolTip;
