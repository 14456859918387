import { HTTPError } from "ky";
import { ReactElement, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { PromoteNodeEvent } from "../../../core/constants/application-insights-events";
import {
    Common,
    Hierarchy,
    Nodes,
    NodeTypeValues,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { PageHeading, SectionVerticalSpace } from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import {
    useGetNodeDetails,
    usePromoteNode,
} from "../../../domain/viewmodels/hierarchy/promote-node-viewmodel";
import { SbAlert } from "../../atoms/SbAlert";
import { PromoteButton } from "../../atoms/SbButton";
import {
    ActionConfirmation,
    LinkConfirmationRow,
    LinkConfirmationValue,
} from "../../organisms/ActionConfirmation";

const PromoteNodeContainer: React.FC = () => {
    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: Nodes });

    const [promoteButtonDisabled, setPromoteButtonDisabled] = useState<boolean>(false);

    const nodeId = Number(useParams().nodeId);

    const getNodeDetails = useGetNodeDetails(nodeId);
    const promoteNode = usePromoteNode();

    const nodeDetailsResponseData = getNodeDetails.data?.node;

    useLoader(
        isQueryLoading(getNodeDetails) || isMutationLoading(promoteNode),
        PromoteNodeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.VisualTree);
    }, []);

    const handlePromoteNode = (): void => {
        setPromoteButtonDisabled(true);
        promoteNode.mutate(nodeId, {
            onSuccess: onPromoteNodeSuccess,
            onError: (error: HTTPError) => {
                trackAppInsightsException(
                    auth.email,
                    window.location.href,
                    PromoteNodeEvent,
                    error
                );
                errorResponseToDisplayHandler(error);
            },
        });
    };

    const onPromoteNodeSuccess = async (): Promise<void> => {
        trackAppInsightsEvent(auth.email, window.location.href, PromoteNodeEvent);
        const params = [
            createNavigateSearchParameter("success", "true"),
            createNavigateSearchParameter("messageKey", "SuccessfullyPromotedNode"),
        ];

        navigateSearch(`${getPath(AccordionTitles.VisualTree)}/${nodeId}`, params);
    };

    const buildWarningAlert = (): ReactElement<HTMLDivElement> => (
        <SbAlert variant={"warning"} text={t("PromoteNodeConfirmationText")} />
    );

    const buildPromoteButton = (): ReactElement<typeof Button> => (
        <PromoteButton onClick={handlePromoteNode} disabled={promoteButtonDisabled} />
    );

    const buildNodeTypeConfirmationRow = (): LinkConfirmationRow =>
        new LinkConfirmationRow(t("HierarchyLevel", { keyPrefix: Hierarchy }), [
            new LinkConfirmationValue(
                nodeDetailsResponseData!.nodeType.name,
                `${getPath(AccordionTitles.VisualStructure)}/${
                    nodeDetailsResponseData!.nodeType.nodeTypeId
                }`
            ),
        ]);

    const buildNodeTypeValueConfirmationRow = (): LinkConfirmationRow =>
        new LinkConfirmationRow(t("NodeTypeValue", { keyPrefix: NodeTypeValues }), [
            new LinkConfirmationValue(
                nodeDetailsResponseData!.nodeTypeValue?.value || "-",
                `${getPath(AccordionTitles.VisualStructureValues)}/${
                    nodeDetailsResponseData!.nodeTypeValue?.nodeTypeValueId
                }`
            ),
        ]);

    const buildParentNodesConfirmationRow = (): LinkConfirmationRow =>
        new LinkConfirmationRow(
            t("ParentNodes"),
            nodeDetailsResponseData!.parentNodes.map(
                (x) =>
                    new LinkConfirmationValue(
                        x.shortDescription,
                        `${getPath(AccordionTitles.VisualTree)}/${x.nodeId}`
                    )
            )
        );

    return (
        <>
            <PageHeading>{t("PromoteNode")}</PageHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(getNodeDetails) && (
                <>
                    {buildWarningAlert()}

                    <ActionConfirmation
                        panelTitle={t("Promote", { keyPrefix: Common })}
                        rows={[
                            buildNodeTypeConfirmationRow(),
                            buildNodeTypeValueConfirmationRow(),
                            buildParentNodesConfirmationRow(),
                        ]}
                        primaryButton={buildPromoteButton()}
                    />
                </>
            )}
        </>
    );
};

export default PromoteNodeContainer;
