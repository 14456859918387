import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useAuth } from "../../../core/store/auth-context";
import { useUrl } from "../../../core/store/url-context";
import {
    getLatestWizardNotification,
    getWizardNotification,
} from "../../../data/services/wizards/wizard-notification-service";
import { Response } from "../../../domain/responses/common/response-response";
import { toWizardCardDto, WizardCardDto } from "../../dtos/wizards/wizard-card-dto";
import { WizardCardResponse } from "../../responses/wizards/wizard-card-response";

export const useGetWizardNotification = (
    userId: number | null
): UseQueryResult<WizardCardDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getWizardNotification", userId],
        () => getWizardNotification(url.originalBaseUrl, userId!),
        {
            select: transformToWizardCardDto,
            onError: errorResponseToDisplayHandler,
            enabled: userId !== null,
        }
    );
};

export const useGetLatestWizardNotification = (
    userId: number | null
): UseQueryResult<WizardCardDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const auth = useAuth();

    return useQuery(
        ["getLatestWizardNotification", userId],
        () => getLatestWizardNotification(url.originalBaseUrl, userId!),
        {
            select: transformToWizardCardDto,
            onError: errorResponseToDisplayHandler,
            enabled: userId !== null && !auth.isAdmin,
        }
    );
};

const transformToWizardCardDto = (data: Response<WizardCardResponse[]>): WizardCardDto[] => {
    const responseData = data.data;

    return responseData.map((wizardCard) => toWizardCardDto(wizardCard));
};
