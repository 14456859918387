import { ActionItemNodeAssociationMode } from "../../enums/action-items/ActionItemNodeAssociationMode";

export class AssociateNodeToActionItemDto {
    actionItemId: number;
    nodeId: number;
    nodeAssociationMode: ActionItemNodeAssociationMode;

    public constructor(
        actionItemId: number,
        nodeId: number,
        nodeAssociationMode: ActionItemNodeAssociationMode
    ) {
        this.actionItemId = actionItemId;
        this.nodeId = nodeId;
        this.nodeAssociationMode = nodeAssociationMode;
    }
}
