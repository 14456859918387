import { CreateHierarchyTypeRequest } from "../../../domain/requests/hierarchy/create-hierarchy-type-request";
import { EditHierarchyTypeRequest } from "../../../domain/requests/hierarchy/edit-hierarchy-type-request";
import {
    buildFilterSearchParameters,
    FilterHierarchyTypesRequest,
} from "../../../domain/requests/hierarchy/filter-hierarchy-types-request";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import { HierarchyTypeDetailsResponse } from "../../../domain/responses/hierarchy/hierarchy-type-details-response";
import { HierarchyTypeResponse } from "../../../domain/responses/hierarchy/hierarchy-type-response";
import client from "../../http-client";

const route = "hierarchytypes";

export const filterHierarchyTypes = async (
    prefixUrl: string,
    request: FilterHierarchyTypesRequest
): Promise<Response<PaginationResponse<HierarchyTypeResponse>>> => {
    const searchParams = buildFilterSearchParameters(request);

    return await client
        .get(`${route}/filter`, {
            prefixUrl: prefixUrl,
            searchParams: searchParams,
        })
        .json();
};

export const getHierarchyTypeDetails = async (
    prefixUrl: string,
    hierarchyTypeId: number
): Promise<Response<HierarchyTypeDetailsResponse>> =>
    await client
        .get(`${route}/${hierarchyTypeId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const editHierarchyType = async (
    prefixUrl: string,
    request: EditHierarchyTypeRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/edit`, {
            prefixUrl: prefixUrl,
            body: JSON.stringify(request),
        })
        .json();

export const createHierarchyType = async (
    prefixUrl: string,
    request: CreateHierarchyTypeRequest
): Promise<Response<number | null>> =>
    await client
        .post(`${route}/create`, {
            prefixUrl: prefixUrl,
            body: JSON.stringify(request),
        })
        .json();
