import { AssociateActionItemToActionPlanRequest } from "../../../domain/requests/actions/associate-action-item-to-action-plan-request";
import { CreateActionPlanRequest } from "../../../domain/requests/actions/create-action-plan-request";
import { EditActionPlanRequest } from "../../../domain/requests/actions/edit-action-plan-request";
import {
    buildFilterSearchParameters,
    FilterActionPlansRequest,
} from "../../../domain/requests/actions/filter-action-plans-request";
import {
    buildAssociatedActionItemsSearchParameters,
    FilterAssociatedActionItemsRequest,
} from "../../../domain/requests/actions/filter-associated-action-items-request";
import { UploadActionPlanFileRequest } from "../../../domain/requests/actions/upload-action-plan-file-request";
import { ActionPlanDetailsResponse } from "../../../domain/responses/actions/action-plan-details-response";
import ActionPlanFileDownloadResponse from "../../../domain/responses/actions/action-plan-file-download-response";
import { ActionPlanResponse } from "../../../domain/responses/actions/action-plan-response";
import { AssociatedActionItemResponse } from "../../../domain/responses/actions/associated-action-item-response";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import client from "../../http-client";

const route = "actionplans";

export const filterActionPlans = async (
    prefixUrl: string,
    request: FilterActionPlansRequest
): Promise<Response<PaginationResponse<ActionPlanResponse>>> => {
    const searchParams = buildFilterSearchParameters(request);

    return await client
        .get(`${route}/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const createActionPlan = async (
    prefixUrl: string,
    request: CreateActionPlanRequest
): Promise<Response<number>> =>
    await client
        .post(`${route}/create`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const softDeleteActionPlan = async (
    prefixUrl: string,
    actionPlanId: number
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/soft-delete/${actionPlanId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const getActionPlanDetails = async (
    prefixUrl: string,
    actionPlanId: number
): Promise<Response<ActionPlanDetailsResponse>> =>
    await client
        .get(`${route}/${actionPlanId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const filterActionPlanFiles = async (
    prefixUrl: string,
    actionPlanId: number
): Promise<Response<ActionPlanFileDownloadResponse[]>> => {
    const searchParams = new URLSearchParams();

    searchParams.set("actionPlanId", actionPlanId.toString());

    return await client
        .get(`${route}/files/filter`, {
            prefixUrl: prefixUrl,
            searchParams: searchParams,
        })
        .json();
};

export const downloadActionPlanFile = async (
    prefixUrl: string,
    actionPlanFileId: number
): Promise<Response<ActionPlanFileDownloadResponse>> =>
    await client
        .get(`${route}/download-file/${actionPlanFileId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const editActionPlan = async (
    prefixUrl: string,
    request: EditActionPlanRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/edit`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const uploadActionPlanFile = async (
    prefixUrl: string,
    request: UploadActionPlanFileRequest
): Promise<Response<number | null>> =>
    await client
        .post(`${route}/upload-file`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const removeActionPlanFile = async (
    prefixUrl: string,
    actionPlanFileId: number
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/remove-file/${actionPlanFileId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const filterAssociatedActionItems = async (
    prefixUrl: string,
    request: FilterAssociatedActionItemsRequest
): Promise<Response<PaginationResponse<AssociatedActionItemResponse>>> => {
    const searchParams = buildAssociatedActionItemsSearchParameters(request);

    return await client
        .get(`${route}/associated-action-items/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const associateActionItem = async (
    prefixUrl: string,
    request: AssociateActionItemToActionPlanRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/associate-action-items`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();
