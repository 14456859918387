import { PaginationDto } from "../../dtos/common/pagination-dto";
import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
    createBasePaginationRequestFromDto,
} from "../common/base-pagination-request";

export interface FilterNodesRequest extends BasePaginationRequest {
    nodeTypeValueId: number;
}

export const createFilterNodesRequest = (
    nodeTypeValueId: number,
    paginationDto: PaginationDto
): FilterNodesRequest => ({
    nodeTypeValueId: nodeTypeValueId,
    ...createBasePaginationRequestFromDto(paginationDto),
});

export const buildFilterNodeTypesSearchParameters = (
    request: FilterNodesRequest
): URLSearchParams => {
    const searchParams = buildBasePaginationSearchParameters(request);

    searchParams.set("nodeTypeValueId", request.nodeTypeValueId.toString());

    return searchParams;
};
