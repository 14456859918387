import { HTTPError } from "ky";
import { ReactElement, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { DemoteNodeEvent } from "../../../core/constants/application-insights-events";
import {
    Common,
    Hierarchy,
    Nodes,
    NodeTypeValues,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import {
    useDemoteNode,
    useGetNodeDetails,
} from "../../../domain/viewmodels/hierarchy/demote-node-viewmodel";
import { DemoteButton } from "../../atoms/SbButton";
import { LinkConfirmationRow, LinkConfirmationValue } from "../../organisms/ActionConfirmation";
import {
    ActionConfirmationFragment,
    AlertProps,
    createWarningAlertProps,
} from "../../organisms/ActionConfirmationFragment";

const DemoteNodeContainer: React.FC = () => {
    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: Common });

    const [demoteButtonDisabled, setDemoteButtonDisabled] = useState<boolean>(false);

    const nodeId = Number(useParams().nodeId);

    const getNodeDetails = useGetNodeDetails(nodeId);
    const demoteNode = useDemoteNode();

    const nodeDetailsResponseData = getNodeDetails.data?.node;

    useLoader(isQueryLoading(getNodeDetails) || isMutationLoading(demoteNode), DemoteNodeContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.VisualTree);
    }, []);

    const handleDemoteNode = (): void => {
        setDemoteButtonDisabled(true);
        demoteNode.mutate(nodeId, {
            onSuccess: onDemoteNodeSuccess,
            onError: (error: HTTPError) => {
                trackAppInsightsException(auth.email, window.location.href, DemoteNodeEvent, error);
                errorResponseToDisplayHandler(error);
            },
        });
    };

    const onDemoteNodeSuccess = async (): Promise<void> => {
        trackAppInsightsEvent(auth.email, window.location.href, DemoteNodeEvent);

        const params = [
            createNavigateSearchParameter("success", "true"),
            createNavigateSearchParameter("messageKey", "SuccessfullyDemotedNode"),
        ];

        navigateSearch(`${getPath(AccordionTitles.VisualTree)}/${nodeId}`, params);
    };

    const buildWarningAlert = (): AlertProps =>
        createWarningAlertProps([t("DemoteNodeConfirmation", { keyPrefix: Nodes })]);

    const buildDemoteButton = (): ReactElement<typeof Button> => (
        <DemoteButton onClick={handleDemoteNode} disabled={demoteButtonDisabled} />
    );

    const buildConfirmationRows = (): LinkConfirmationRow[] => [
        new LinkConfirmationRow(t("HierarchyLevel", { keyPrefix: Hierarchy }), [
            new LinkConfirmationValue(
                nodeDetailsResponseData?.nodeType.name,
                `${getPath(AccordionTitles.VisualStructure)}/${
                    nodeDetailsResponseData?.nodeType.nodeTypeId
                }`
            ),
        ]),
        new LinkConfirmationRow(t("NodeTypeValue", { keyPrefix: NodeTypeValues }), [
            new LinkConfirmationValue(
                nodeDetailsResponseData?.nodeTypeValue?.value,
                `${getPath(AccordionTitles.VisualStructureValues)}/${
                    nodeDetailsResponseData?.nodeTypeValue?.nodeTypeValueId
                }`
            ),
        ]),
        new LinkConfirmationRow(
            t("ParentNodes", { keyPrefix: Nodes }),
            nodeDetailsResponseData?.parentNodes.map(
                (x) =>
                    new LinkConfirmationValue(
                        x.shortDescription,
                        `${getPath(AccordionTitles.VisualTree)}/${x.nodeId}`
                    )
            )
        ),
    ];

    return (
        <ActionConfirmationFragment
            pageHeading={t("DemoteNode")}
            panelTitle={t("Demote")}
            alert={buildWarningAlert()}
            rows={buildConfirmationRows()}
            primaryButton={buildDemoteButton()}
            areQueriesSuccessful={isQuerySuccessful(getNodeDetails)}
        />
    );
};

export default DemoteNodeContainer;
