import { useMutation, UseMutationResult, useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    dissociateChecklistFromNodeByChecklistId,
    dissociateNode,
    getChecklistDetails,
} from "../../../data/services/checklists/checklist-service";
import { getNodeDetails } from "../../../data/services/hierarchy/nodes-service";
import {
    createDissociateNodeDetailsDto,
    DissociateNodeDetailsDto,
} from "../../dtos/checklists/dissociate-node-details-dto";
import { createDissociateChecklistFromNodeRequest } from "../../requests/hierarchy/dissociate-checklist-from-node-request";
import { ChecklistResponse } from "../../responses/checklists/checklist-response";
import { Response } from "../../responses/common/response-response";

interface DissociateChecklistFromNodeParameters {
    checklistId: number;
    nodeId: number;
}

export const useGetDissociateNodeDetails = (
    checklistId: number,
    nodeId: number
): [UseQueryResult<string, HTTPError>, UseQueryResult<DissociateNodeDetailsDto, HTTPError>] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getChecklist", checklistId],
                queryFn: () => getChecklistDetails(url.baseUrl, checklistId),
                select: createChecklistDto,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getNodeDetails", nodeId],
                queryFn: () => getNodeDetails(url.baseUrl, nodeId),
                select: createDissociateNodeDetailsDto,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [UseQueryResult<string, HTTPError>, UseQueryResult<DissociateNodeDetailsDto, HTTPError>];
};

const createChecklistDto = (response: Response<ChecklistResponse>): string => response.data.name;

export const useDissociateNode = (): UseMutationResult<Response<boolean>, HTTPError, number> => {
    const url = useUrl();

    return useMutation((checklistAssignmentId: number) =>
        dissociateNode(url.baseUrl, checklistAssignmentId)
    );
};

export const useDissociateChecklistFromNodeByChecklistId = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    DissociateChecklistFromNodeParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: DissociateChecklistFromNodeParameters) =>
        dissociateChecklistFromNodeByChecklistId(
            url.baseUrl,
            createDissociateChecklistFromNodeRequest(mutationKey.checklistId, mutationKey.nodeId)
        )
    );
};
