import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    deleteActionItemDynamicField,
    getActionItemDynamicFieldDetails,
} from "../../../data/services/action-items/action-item-dynamic-fields-service";
import {
    ActionItemDynamicFieldDetailsDto,
    createActionItemDynamicFieldDetailsDto,
} from "../../dtos/action-items/action-item-dynamic-field-details-dto";
import { Response } from "../../responses/common/response-response";

export const useGetActionItemDynamicFieldDetails = (
    actionItemDynamicFieldId: number
): UseQueryResult<ActionItemDynamicFieldDetailsDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getActionItemDynamicFieldDetails", actionItemDynamicFieldId],
        () => getActionItemDynamicFieldDetails(url.baseUrl, actionItemDynamicFieldId),
        { select: createActionItemDynamicFieldDetailsDto, onError: errorResponseToDisplayHandler }
    );
};

export const useDeleteActionItemDynamicField = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    number
> => {
    const url = useUrl();

    return useMutation((actionItemDynamicFieldId: number) =>
        deleteActionItemDynamicField(url.baseUrl, actionItemDynamicFieldId)
    );
};
