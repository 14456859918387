import { FilterActionPlansDto } from "../../dtos/actions/filter-action-plans-dto";
import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
    createBasePaginationRequest,
} from "../common/base-pagination-request";

export interface FilterActionPlansRequest extends BasePaginationRequest {
    name: string | null;
    description: string | null;
}

export const createFilterActionPlansRequest = (
    filterActionPlansDto: FilterActionPlansDto,
    paginationDto: BasePaginationDto
): FilterActionPlansRequest => ({
    ...filterActionPlansDto,
    ...createBasePaginationRequest(paginationDto.pageNumber, paginationDto.pageSize),
});

export const buildFilterSearchParameters = (request: FilterActionPlansRequest): URLSearchParams => {
    const searchParams = buildBasePaginationSearchParameters(request);

    if (request.name) {
        searchParams.set("name", request.name);
    }

    if (request.description) {
        searchParams.set("description", request.description);
    }

    return searchParams;
};
