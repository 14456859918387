import { EscalationLevel } from "../../enums/hierarchy/EscalationLevel";
import { HierarchyAssignable } from "../../enums/hierarchy/HierarchyAssignable";

export interface EditNodeTypeRequest {
    nodeTypeId: number;
    name: string;
    hierarchyTypeId: number;
    containsTimeZoneCode: boolean;
    hierarchyAssignables: HierarchyAssignable[];
    escalationLevels: EscalationLevel[];
}

export const createEditNodeTypeRequest = (
    nodeTypeId: number,
    name: string,
    hierarchyTypeId: number,
    containsTimeZoneCode: boolean,
    hierarchyAssignables: HierarchyAssignable[],
    escalationLevels: EscalationLevel[]
): EditNodeTypeRequest => ({
    nodeTypeId: nodeTypeId,
    name: name,
    hierarchyTypeId: hierarchyTypeId,
    containsTimeZoneCode: containsTimeZoneCode,
    hierarchyAssignables: hierarchyAssignables,
    escalationLevels: escalationLevels,
});
