import { Response } from "../../responses/common/response-response";
import { PrePublishReviewStatusResponse } from "../../responses/review-reports/pre-publish-review-status-response";

export class PrePublishReviewStatusDto {
    canPrePublishReview: boolean;

    public constructor(response: PrePublishReviewStatusResponse) {
        this.canPrePublishReview = response.canPrePublishReview;
    }

    public static constructFromResponse = (
        response: Response<PrePublishReviewStatusResponse>
    ): PrePublishReviewStatusDto => new PrePublishReviewStatusDto(response.data);
}
