import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ChecklistQuestions } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import {
    useDeleteQuestionCategory,
    useGetQuestionCategoryDetails,
} from "../../../domain/viewmodels/question-categories/delete-question-category-viewmodel";
import { DeleteButton } from "../../atoms/SbButton";
import { ConfirmationRow, TextConfirmationRow } from "../../organisms/ActionConfirmation";
import { ActionConfirmationFragment } from "../../organisms/ActionConfirmationFragment";

const DeleteQuestionCategoryContainer: React.FC = () => {
    const menu = useMenu();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const questionCategoryId = Number(useParams().questionCategoryId);

    const questionCategoryDetails = useGetQuestionCategoryDetails(questionCategoryId);
    const questionCategoryDetailsData = questionCategoryDetails.data;
    const deleteQuestionCategory = useDeleteQuestionCategory();

    const handleDeleteQuestionCategory = (): void => {
        deleteQuestionCategory.mutate(questionCategoryId, {
            onSuccess: async () => navigate(-1),
            onError: errorResponseToDisplayHandler,
        });
    };

    const buildDeleteButton = (): ReactElement<typeof Button> => (
        <DeleteButton onClick={() => handleDeleteQuestionCategory()} />
    );

    useLoader(
        isQueryLoading(questionCategoryDetails) || isMutationLoading(deleteQuestionCategory),
        DeleteQuestionCategoryContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.QuestionCategories);
    }, []);

    const buildConfirmationRows = (): ConfirmationRow<any>[] => [
        new TextConfirmationRow(t("Name"), questionCategoryDetailsData?.name),
    ];

    return (
        <ActionConfirmationFragment
            pageHeading={t("QuestionCategoryDeleteTitle")}
            panelTitle={t("AreYouSureDeleteQuestionCategory")}
            rows={buildConfirmationRows()}
            primaryButton={buildDeleteButton()}
            areQueriesSuccessful={isQuerySuccessful(questionCategoryDetails)}
        />
    );
};

export default DeleteQuestionCategoryContainer;
