import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
} from "../common/base-pagination-request";

export class FilterQuestionSetInstanceReviewsRequest extends BasePaginationRequest {
    reviewReportSetId: number;

    public constructor(reviewReportSetId: number, basePaginationDto: BasePaginationDto) {
        super(basePaginationDto.pageNumber, basePaginationDto.pageSize);
        this.reviewReportSetId = reviewReportSetId;
    }
}

export const buildFilterQuestionSetInstanceReviewsSearchParameters = (
    request: FilterQuestionSetInstanceReviewsRequest
): URLSearchParams => {
    const searchParams = buildBasePaginationSearchParameters(request);

    if (request.reviewReportSetId) {
        searchParams.set("reviewReportSetId", request.reviewReportSetId.toString());
    }

    return searchParams;
};
