import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaLink } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import {
    Common,
    Hierarchy,
    HierarchyTypeTreeView,
    Users,
} from "../../../core/constants/translation-namespace";
import { HierarchyMenuType } from "../../../core/hierarchyMenuType";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    PageSubHeading,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    isMutationLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import { BaseNodeDetailsDto } from "../../../domain/dtos/hierarchy/base-node-details-dto";
import { AssociateDissociateUserNodeDto } from "../../../domain/dtos/users/associate-dissociate-user-node-dto";
import {
    useAssignUserToNode,
    useGetUserDetails,
} from "../../../domain/viewmodels/users/associate-user-node-viewmodel";
import { CancelButton, SbButton } from "../../atoms/SbButton";
import { RadioSelectProps } from "../../molecules/DataTableAction";
import SbTabs, { SbTabItemProps } from "../../molecules/SbTabs";
import NodeHierarchyContainer from "../hierarchy/NodeHierarchyContainer";
import TextSearchContainer from "../hierarchy/TextSearchContainer";

const AssociateUserNodeContainer: React.FC = () => {
    const userId = Number(useParams().userId);

    const [selectedNode, setSelectedNode] = useState<number>();

    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();
    const menu = useMenu();
    const { t } = useTranslation("translation", { keyPrefix: Users });

    const getUserDetails = useGetUserDetails(userId);

    const userDetailsResponse = getUserDetails[0];
    const userDetailsResponseData = userDetailsResponse.data;
    const hierarchyDetailsResponse = getUserDetails[1];
    const hierarchyDetailsResponseData = hierarchyDetailsResponse.data;

    const assignUserToNode = useAssignUserToNode();

    useLoader(
        areQueriesLoading(getUserDetails) || isMutationLoading(assignUserToNode),
        AssociateUserNodeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.UserManagement, AccordionTitles.Users);
    }, []);

    const selectTextSearchNode = (metadata: BaseNodeDetailsDto): void =>
        setSelectedNode(metadata.nodeId);

    const selectVisualSearchNode = (nodeId: number): void => setSelectedNode(nodeId);

    const checkSelectedNode = (metadata: BaseNodeDetailsDto): boolean => {
        const node = metadata.nodeId;

        return node === selectedNode;
    };

    const radioSelect: RadioSelectProps = {
        type: "radio",
        label: `${t("SelectNode", { keyPrefix: HierarchyTypeTreeView })}`,
    };

    const onAssignUserToNode = (): void =>
        assignUserToNode.mutate(new AssociateDissociateUserNodeDto(selectedNode!, userId!), {
            onSuccess: async () => {
                const params = [
                    createNavigateSearchParameter("success", "true"),
                    createNavigateSearchParameter("messageKey", "SuccessfullyAssociatedUserToNode"),
                ];

                navigateSearch(`${getPath(AccordionTitles.Users)}/${userId}`, params);
            },
            onError: errorResponseToDisplayHandler,
        });

    const buildSbTabItemProps = (): SbTabItemProps[] => {
        const textSearchContainer = (
            <TextSearchContainer
                mode={"select"}
                selectNode={selectTextSearchNode}
                selectedNode={checkSelectedNode}
                radioSelect={radioSelect}
                hierarchyTypes={hierarchyDetailsResponseData!.sort(
                    (a, b) => a.hierarchyTypeId - b.hierarchyTypeId
                )}
            />
        );

        const nodeHierarchyContainer = (
            <NodeHierarchyContainer
                menuType={
                    HierarchyMenuType.Select |
                    HierarchyMenuType.Details |
                    HierarchyMenuType.ViewUsers
                }
                selectNode={selectVisualSearchNode}
                hierarchyTypes={hierarchyDetailsResponseData!.sort(
                    (a, b) => a.hierarchyTypeId - b.hierarchyTypeId
                )}
            />
        );

        return [
            new SbTabItemProps(t("TextSearch", { keyPrefix: Common }), textSearchContainer, true),
            new SbTabItemProps(t("VisualSearch", { keyPrefix: Common }), nodeHierarchyContainer),
        ];
    };

    return (
        <>
            <PageHeading>{t("AssignUserToHierarchy", { keyPrefix: Hierarchy })}</PageHeading>
            <PageSubHeading>{t("AssignNodeHelpTextUsers")}</PageSubHeading>
            <LargeVerticalSpace />

            {isQuerySuccessful(userDetailsResponse) && (
                <>
                    <ContentContainer>
                        <Row>
                            <Col md="auto">
                                <DetailsLabel>{t("User")}</DetailsLabel>
                            </Col>
                            <Col md="auto">
                                <DetailsValue>{`${userDetailsResponseData?.firstName} ${userDetailsResponseData?.lastName}`}</DetailsValue>
                            </Col>
                        </Row>
                    </ContentContainer>
                    <LargeVerticalSpace />
                </>
            )}

            {isQuerySuccessful(hierarchyDetailsResponse) && (
                <ContentContainer>
                    <SbTabs values={buildSbTabItemProps()}></SbTabs>
                </ContentContainer>
            )}
            <LargeVerticalSpace />

            <EndAlignedDiv>
                <Col sm={"auto"}>
                    <SbButton
                        icon={FaLink}
                        variant={"primary"}
                        type={"button"}
                        label={t("Assign", { keyPrefix: Common })}
                        onClick={onAssignUserToNode}
                        disabled={!selectedNode}
                    />
                </Col>
                <Col sm={"auto"}>
                    <CancelButton onClick={() => navigate(-1)} />
                </Col>
            </EndAlignedDiv>
        </>
    );
};

export default AssociateUserNodeContainer;
