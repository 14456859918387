import { OverlayTrigger } from "react-bootstrap";
import { IconType } from "react-icons";
import { StyledPopover } from "../../core/theme/global-styles";
import { SbButton } from "./SbButton";

const SbToolTipButton: React.FC<{
    toolTip?: string;
    label: string;
    icon?: IconType;
    iconPosition?: "left" | "right";
    onClick: () => void;
    disabled?: boolean;
    variant:
        | "primary"
        | "primary-transparent"
        | "secondary"
        | "secondary-transparent"
        | "secondary-white"
        | "warning"
        | "danger"
        | "info"
        | "advance"
        | "success"
        | "transparent";
}> = ({ toolTip, label, icon, iconPosition, onClick, disabled, variant }) => {
    const renderSbButton = (): JSX.Element => {
        return (
            <SbButton
                variant={variant}
                type="button"
                label={label}
                icon={icon}
                onClick={onClick}
                iconPosition={iconPosition}
                disabled={disabled}
            />
        );
    };

    return toolTip ? (
        <OverlayTrigger overlay={<StyledPopover>{toolTip}</StyledPopover>}>
            <span>{renderSbButton()}</span>
        </OverlayTrigger>
    ) : (
        renderSbButton()
    );
};

export default SbToolTipButton;
