import { createPaginationDto, defaultPaginationDto, PaginationDto } from "../common/pagination-dto";

export interface FilterChecklistsDto extends PaginationDto {
    name: string | null;
    modifiedByUserFullName: string | null;
    isTraining: boolean | null;
}

export const createFilterChecklistsDto = (
    name: string | null,
    modifiedByUserFullName: string | null,
    isTraining: boolean | null,
    pageNumber: number,
    pageSize: number,
    sortByColumn: string | null,
    sortByDescending: boolean
): FilterChecklistsDto => ({
    name: name,
    modifiedByUserFullName: modifiedByUserFullName,
    isTraining: isTraining,
    ...createPaginationDto(pageNumber, pageSize, sortByColumn, sortByDescending),
});

export const defaultFilterChecklistsDto = (): FilterChecklistsDto => {
    const { pageNumber, pageSize, sortByColumn, sortByDescending } = defaultPaginationDto;

    return createFilterChecklistsDto(
        null,
        null,
        null,
        pageNumber,
        pageSize,
        sortByColumn,
        sortByDescending
    );
};
