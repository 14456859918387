import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Email } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import {
    ContentContainer,
    EndAlignedDiv,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import { Response } from "../../../domain/responses/common/response-response";
import { useGetEmailQueueDetails } from "../../../domain/viewmodels/email-queue/email-queue-details-viewmodel";
import { useResendEmail } from "../../../domain/viewmodels/email-queue/resend-mail-viewmodel";
import { ResendButton } from "../../atoms/SbButton";
import { ViewLink } from "../../atoms/SbLink";
import { CreateLabelToDetailRows } from "../../molecules/CreateLabelToDetailRows";
import {
    CheckboxConfirmationRow,
    ConfirmationRow,
    TextConfirmationRow,
} from "../../organisms/ActionConfirmation";

const EmailQueueDetailsContainer = (): JSX.Element => {
    const emailQueueId = Number(useParams().emailQueueId);

    const menu = useMenu();
    const { t } = useTranslation("translation", { keyPrefix: Email });

    const getEmailQueueDetails = useGetEmailQueueDetails(emailQueueId);
    const emailQueueDetailsResponseData = getEmailQueueDetails.data;
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const navigateSearch = useNavigateSearch();

    const resendMail = useResendEmail();

    useLoader(isQueryLoading(getEmailQueueDetails), EmailQueueDetailsContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.EmailQueue);
    }, []);

    const resendEmail = (event: React.FormEvent<HTMLFormElement>): void => {
        const params = [createNavigateSearchParameter("success", "true")];

        event.preventDefault();

        resendMail.mutate(emailQueueId, {
            onSuccess: async (_: Response<boolean>) => {
                await getEmailQueueDetails.refetch();

                navigateSearch(getPath(DrawerTitles.EmailQueue), params);
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    const confirmationRows = (): ConfirmationRow<any>[] => [
        new CheckboxConfirmationRow(t("Sent"), emailQueueDetailsResponseData!.sent),
        new TextConfirmationRow(t("Recipients"), emailQueueDetailsResponseData!.toEmailAddress),
        new TextConfirmationRow(t("Subject"), emailQueueDetailsResponseData!.subject),
        new TextConfirmationRow(t("DateSent"), emailQueueDetailsResponseData!.dateSentLocal),
        new TextConfirmationRow(
            t("SendAttempts"),
            emailQueueDetailsResponseData!.sendAttempts.toString()
        ),
        new TextConfirmationRow(
            t("EmailErrorMessage"),
            emailQueueDetailsResponseData!.errorMessage?.toString()
        ),
    ];

    const renderEmailQueueDetail = (): JSX.Element => (
        <ContentContainer>
            {CreateLabelToDetailRows(confirmationRows(), 2, 10)}
            <EndAlignedDiv>
                {!emailQueueDetailsResponseData!.sent && (
                    <Form name="resendForm" onSubmit={resendEmail}>
                        <ResendButton label={t("ResendMail")} type={"submit"} />
                    </Form>
                )}
                <ViewLink
                    label={t("EmailsQueueList")}
                    navigateTo={`${getPath(DrawerTitles.EmailQueue)}`}
                />
            </EndAlignedDiv>
        </ContentContainer>
    );

    const renderEmailBody = (): JSX.Element => (
        <ContentContainer>
            <PageSubHeading>{t("EmailBody")}</PageSubHeading>
            <div dangerouslySetInnerHTML={{ __html: emailQueueDetailsResponseData!.body }} />
        </ContentContainer>
    );

    return (
        <>
            <PageHeading>{t("EmailQueueDetailsTitle")}</PageHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(getEmailQueueDetails) && (
                <>
                    {renderEmailQueueDetail()}
                    <SectionVerticalSpace />

                    {renderEmailBody()}
                    <SectionVerticalSpace />
                </>
            )}
        </>
    );
};

export default EmailQueueDetailsContainer;
