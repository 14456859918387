import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { createQuestionCategory } from "../../../data/services/question-categories/question-category-service";
import CreateQuestionCategoryDto from "../../dtos/question-categories/create-question-category-dto";
import { Response } from "../../responses/common/response-response";

export const useCreateQuestionCategory = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    CreateQuestionCategoryDto
> => {
    const url = useUrl();

    return useMutation((dto: CreateQuestionCategoryDto) => {
        const request = {
            questionCategoryName: dto.name,
        };

        return createQuestionCategory(url.baseUrl, request);
    });
};
