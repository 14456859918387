import { CreateEditActionItemDynamicFieldValueDto } from "../../dtos/actions/create-edit-action-item-dynamic-field-value-dto";

export class CreateEditActionItemDynamicFieldValueRequest {
    actionItemDynamicFieldId: number;
    intValue: number | null;
    boolValue: boolean | null;
    dateValue: Date | null;
    decimalValue: number | null;
    textValue: string | null;
    texArea: string | null;

    public constructor(dto: CreateEditActionItemDynamicFieldValueDto) {
        const {
            actionItemDynamicFieldId,
            intValue,
            boolValue,
            dateValue,
            decimalValue,
            textValue,
            texArea,
        } = dto;

        this.actionItemDynamicFieldId = actionItemDynamicFieldId;
        this.intValue = intValue;
        this.boolValue = boolValue;
        this.dateValue = dateValue;
        this.decimalValue = decimalValue;
        this.textValue = textValue;
        this.texArea = texArea;
    }

    public static toCreateEditActionItemDynamicFieldValueRequests = (
        response: CreateEditActionItemDynamicFieldValueDto[]
    ): CreateEditActionItemDynamicFieldValueRequest[] =>
        response.map((x) => new CreateEditActionItemDynamicFieldValueRequest(x));
}
