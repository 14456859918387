import React, { useContext, useState } from "react";
import { NavigateSearchParameter } from "../hooks/navigateSearch";

export enum ToastVariant {
    success,
    warning,
    danger,
}

export const toastTitles = new Map<ToastVariant, string>([
    [ToastVariant.success, "Successful request"],
    [ToastVariant.warning, "Partially successful request"],
    [ToastVariant.danger, "Unsuccessful request"],
    [ToastVariant.warning, "Unauthorized request"],
]);

export interface ToastNavigationProps {
    navigatePath: string;
    navigateSearchParameters: NavigateSearchParameter[];
}

export const createToastNavigationProps = (
    navigatePath: string,
    navigateSearchParameters: NavigateSearchParameter[]
): ToastNavigationProps => ({
    navigatePath: navigatePath,
    navigateSearchParameters: navigateSearchParameters,
});

export interface ToastProps {
    variant: ToastVariant;
    messages: string[];
    navigationProps?: ToastNavigationProps;
    autoHide?: boolean;
}

export interface ToastDetails {
    id: number;
    props: ToastProps;
}

export const createErrorToastProps = (
    messages: string[],
    autoHide: boolean = false
): ToastProps => ({
    messages: messages,
    variant: ToastVariant.danger,
    autoHide: autoHide,
});

export const createSuccessToastProps = (messages: string[]): ToastProps => ({
    messages: messages,
    variant: ToastVariant.success,
});

export const createPartialSuccessToastProps = (messages: string[]): ToastProps => ({
    messages: messages,
    variant: ToastVariant.warning,
});

export const createUnauthorisedToastProps = (messages: string[]): ToastProps => ({
    messages: messages,
    variant: ToastVariant.warning,
});

export const createSuccessNavigateToastProps = (
    messages: string[],
    navigationProps: ToastNavigationProps
): ToastProps => ({
    messages: messages,
    variant: ToastVariant.success,
    navigationProps: navigationProps,
});

const generateId = (): number => Date.now();

export interface ToastContextType {
    toasts: ToastDetails[];
    addToast: (_: ToastProps) => void;
    isVisible: boolean;
    dismiss: () => void;
    dismissToast: (_: number) => void;
    dismissAndClear: () => void;
}

const ToastContext = React.createContext<ToastContextType>({
    toasts: [],
    addToast: (_: ToastProps) => {},
    isVisible: false,
    dismiss: () => {},
    dismissToast: (_: number) => {},
    dismissAndClear: () => {},
});

export const ToastProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const [isVisible, updateIsVisible] = useState(false);
    const [toasts, updateToasts] = useState<ToastDetails[]>([]);

    const addToast = (properties: ToastProps): void => {
        updateToasts((prev) => [...prev, { id: generateId(), props: properties }]);

        updateIsVisible(true);
    };

    const dismiss = (): void => updateIsVisible(false);

    const dismissToast = (id: number): void =>
        updateToasts((prev) => prev.filter((x) => x.id != id));

    const dismissAndClear = (): void => {
        dismiss();
        updateToasts([]);
    };

    const value = {
        toasts,
        addToast,
        isVisible,
        dismiss,
        dismissToast,
        dismissAndClear,
    };

    return <ToastContext.Provider value={value}>{children}</ToastContext.Provider>;
};

export const useToast = (): ToastContextType => useContext(ToastContext);
