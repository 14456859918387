import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { isMutationLoading } from "../../../core/utilities/responseStateHelper";
import { ImageLibraryViewResponse } from "../../../domain/responses/image-library/image-library-view-response";
import { useCreateWizardCard } from "../../../domain/viewmodels/wizards/create-wizard-card-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import SbFormTextAreaGroup from "../../molecules/input/SbFormTextAreaGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

export interface CreateWizardCardProps {
    imageLibraryImages: ImageLibraryViewResponse[];
}

const CreateWizardCardContainer: React.FC = () => {
    const wizardId = Number(useParams().wizardId);

    const [fileDetailsId, setFileDetailsId] = useState<number>();

    const menu = useMenu();
    const navigate = useNavigate();
    const location = useLocation();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: Common });

    const { imageLibraryImages } = location.state as CreateWizardCardProps;

    const createWizardCard = useCreateWizardCard();

    useLoader(isMutationLoading(createWizardCard), CreateWizardCardContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.WizardCards, AccordionTitles.Wizards);
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        createWizardCard.mutate(
            {
                wizardId: wizardId,
                fileDetailsId: fileDetailsId!,
                formData: new FormData(event.currentTarget),
            },
            {
                onSuccess: async () => navigate(-1),
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    const onImageLibraryImageSelected = (option: SingleValue<ImageLibraryViewResponse>): void => {
        setFileDetailsId(option!.fileDetailsId);
    };

    return (
        <>
            <PageHeading>{t("CreateWizardCard")}</PageHeading>
            <SectionVerticalSpace />
            <TextTitledPanel title={t("CreateWizardCard")}>
                <Form onSubmit={handleSubmit}>
                    <SbFormTextFieldGroup
                        name="heading"
                        label={t("Heading")} //There seems to be no translation for this so I've added a default English value
                        type="text"
                        maxLength={100}
                        required
                    />

                    <SbFormTextAreaGroup
                        name="description"
                        label={t("Description")}
                        required
                        maxLength={1000}
                    />

                    <SbFormSelectFieldGroup
                        name={"images"}
                        label={t("Images")} // No Translation for this as well
                        placeholderText={t("PleaseSelect")!}
                        searchable
                        required
                        clearable={false}
                        items={imageLibraryImages}
                        itemDisplayText={(option: ImageLibraryViewResponse) => option.fileName}
                        onChange={onImageLibraryImageSelected}
                    />

                    <EndAlignedDiv>
                        <SaveButton type="submit" />
                        <CancelButton onClick={() => navigate(-1)} />
                    </EndAlignedDiv>
                </Form>
            </TextTitledPanel>
        </>
    );
};

export default CreateWizardCardContainer;
