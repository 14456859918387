import { AnswerSummaryType } from "../../enums/answer-summary/AnswerSummaryType";
import InstanceStatus from "../../enums/questions/instance-status";

export class AnswerDetailsDto {
    instanceStatus: InstanceStatus;
    nodeId: number;
    completedDateTime: Date | null;
    answerSummaryType: AnswerSummaryType;
    isTraining: string;

    public constructor(
        instanceStatus: InstanceStatus,
        nodeId: number,
        completedDateTime: Date | null,
        answerSummaryType: AnswerSummaryType,
        isTraining: string
    ) {
        this.instanceStatus = instanceStatus;
        this.nodeId = nodeId;
        this.completedDateTime = completedDateTime;
        this.answerSummaryType = answerSummaryType;
        this.isTraining = isTraining;
    }
}
