import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Common, Tenants } from "../../../core/constants/translation-namespace";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import { useGetTenantDetails } from "../../../domain/viewmodels/tenants/tenant-details-viewmodel";
import { EditLink, ViewLink } from "../../atoms/SbLink";
import { ComponentPanel } from "../../molecules/SbPanel";

const TenantDetailsContainer: React.FC = () => {
    const tenantId = Number(useParams().tenantId);

    const menu = useMenu();
    const { t } = useTranslation("translation", { keyPrefix: Tenants });

    const tenantDetails = useGetTenantDetails(tenantId);

    useLoader(isQueryLoading(tenantDetails), TenantDetailsContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.TenantManagement);
    }, []);

    return (
        <>
            <PageHeading>{t("TenantDetailsTitle")}</PageHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(tenantDetails) && (
                <ComponentPanel>
                    <Row>
                        <Col sm="3">
                            <DetailsLabel>{t("Name", { keyPrefix: Common })}</DetailsLabel>
                        </Col>
                        <Col sm="auto">
                            <DetailsValue>{tenantDetails.data!.name ?? "-"}</DetailsValue>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="3">
                            <DetailsLabel>{t("Description", { keyPrefix: Common })}</DetailsLabel>
                        </Col>
                        <Col sm="auto">
                            <DetailsValue>{tenantDetails.data!.description ?? "-"}</DetailsValue>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="3">
                            <DetailsLabel>{t("WebConnectionString")}</DetailsLabel>
                        </Col>
                        <Col sm="9">
                            <DetailsValue>
                                {tenantDetails.data!.connectionString ?? "-"}
                            </DetailsValue>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="3">
                            <DetailsLabel>{t("WebAuditConnectionString")}</DetailsLabel>
                        </Col>
                        <Col sm="9">
                            <DetailsValue>
                                {tenantDetails.data!.auditConnectionString ?? "-"}
                            </DetailsValue>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="3">
                            <DetailsLabel>{t("WorkerConnectionString")}</DetailsLabel>
                        </Col>
                        <Col sm="9">
                            <DetailsValue>
                                {tenantDetails.data!.workerConnectionString ?? "-"}
                            </DetailsValue>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="3">
                            <DetailsLabel>{t("WorkerAuditConnectionString")}</DetailsLabel>
                        </Col>
                        <Col sm="9">
                            <DetailsValue>
                                {tenantDetails.data!.workerAuditConnectionString ?? "-"}
                            </DetailsValue>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="3">
                            <DetailsLabel>{t("BaseURL")}</DetailsLabel>
                        </Col>
                        <Col sm="auto">
                            <DetailsValue>{tenantDetails.data!.baseUrl ?? "-"}</DetailsValue>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="3">
                            <DetailsLabel>{t("CSSTheme")}</DetailsLabel>
                        </Col>
                        <Col sm="auto">
                            <DetailsValue>{tenantDetails.data!.theme ?? "-"}</DetailsValue>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="3">
                            <DetailsLabel>{t("Features")}</DetailsLabel>
                        </Col>
                        <Col sm="auto">
                            <DetailsValue>
                                {tenantDetails.data!.features?.map((x, index) => (
                                    <>
                                        {x.name}{" "}
                                        {index !== tenantDetails.data!.features.length - 1 && (
                                            <>|</>
                                        )}{" "}
                                    </>
                                )) ?? "-"}
                            </DetailsValue>
                        </Col>
                    </Row>

                    <EndAlignedDiv>
                        <EditLink
                            navigateTo={`${getPath(
                                DrawerTitles.TenantManagement
                            )}/${tenantId}/edit`}
                        />
                        <ViewLink
                            label={t("Tenants")}
                            navigateTo={`${getPath(DrawerTitles.TenantManagement)}`}
                        />
                    </EndAlignedDiv>
                </ComponentPanel>
            )}
        </>
    );
};

export default TenantDetailsContainer;
