import { Response } from "../../responses/common/response-response";
import { NodeDetailsResponse } from "../../responses/hierarchy/node-details-response";
import {
    createNonWorkingDaySetDto,
    NonWorkingDaySetDto,
} from "../non-working-days/non-working-day-set-dto";
import { createNodeDto, NodeDto } from "./node-dto";

export interface NodeDetailsDto {
    node: NodeDto;
    inheritedNonWorkingDaySet: NonWorkingDaySetDto | null;
    inheritedTrainingFlag: boolean;
}

export const createNodeDetailsDto = (response: Response<NodeDetailsResponse>): NodeDetailsDto => {
    const responseData = response.data;

    return {
        node: createNodeDto(responseData.node),
        inheritedNonWorkingDaySet:
            responseData.inheritedNonWorkingDaySet != null
                ? createNonWorkingDaySetDto(responseData.inheritedNonWorkingDaySet)
                : null,
        inheritedTrainingFlag: responseData.inheritedTrainingFlag,
    };
};
