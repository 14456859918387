export const AllowedExtensionsForActionPlan = [
    ".msg",
    ".jpeg",
    ".jpg",
    ".png",
    ".pdf",
    ".xlsx",
    ".xls",
    ".docm",
    ".docx",
    ".ppt",
    ".pptx",
    ".xml",
    ".bmp",
    ".gif",
];
