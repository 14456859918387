import { HTTPError } from "ky";
import React, { ReactElement, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SingleValue } from "react-select";
import styled from "styled-components";
import { EditQuestionSetScheduleEvent } from "../../../core/constants/application-insights-events";
import { ChecklistQuestions } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { convertToggleValueToBoolean } from "../../../core/utilities/convertToBoolean";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    calculateMinutes,
    DayOrdinalOptions,
    DaysOfTheWeekOptions,
    FrequencyOptions,
    NonWorkingDayRolloverOptions,
    PeriodTypeOptions,
} from "../../../core/utilities/QuestionSetValues";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import EditScheduleDto from "../../../domain/dtos/checklist-assignment/edit-schedule-dto";
import { ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import { PeriodType } from "../../../domain/enums/PeriodType";
import { DailyScheduleOption } from "../../../domain/enums/questions/question-sets/DailyScheduleOptions";
import { DayOrdinal } from "../../../domain/enums/questions/question-sets/DayOrdinalNumber";
import { DaysOfWeek } from "../../../domain/enums/questions/question-sets/DaysOfTheWeek";
import { FrequencyType } from "../../../domain/enums/questions/question-sets/FrequencyType";
import { MonthlyScheduleOption } from "../../../domain/enums/questions/question-sets/MonthlyScheduleOption";
import { MonthOfTheYear } from "../../../domain/enums/questions/question-sets/MonthOfTheYear";
import { NonWorkingDayRolloverType } from "../../../domain/enums/questions/question-sets/NonWorkingDayRolloverType";
import { WeeklyScheduleOption } from "../../../domain/enums/questions/question-sets/WeeklyScheduleOption";
import { YearlyScheduleOption } from "../../../domain/enums/questions/question-sets/YearlyScheduleOption";
import { CreateQuestionSetSchedule } from "../../../domain/requests/question-set/create-question-set-schedule-request";
import {
    useEditSchedule,
    useGetScheduleDetails,
} from "../../../domain/viewmodels/checklist-assignment/edit-schedule-viewmodel";
import { SbAlert } from "../../atoms/SbAlert";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import { EditLink } from "../../atoms/SbLink";
import { AdhocHolidayRollOverSchedule } from "../../molecules/AdhocHolidayRollOverSchedule";
import { DailySchedule } from "../../molecules/DailySchedule";
import { FrequencyTypeSchedule } from "../../molecules/FrequencyTypeSchedule";
import { HolidayRollOverSchedule } from "../../molecules/HolidayRollOverSchedule";
import SbFormCheckFieldGroup from "../../molecules/input/SbFormCheckFieldGroup";
import SbFormDateTimeFieldGroup from "../../molecules/input/SbFormDateTimeFieldGroup";
import { MonthlySchedule } from "../../molecules/MonthlySchedule";
import { OnceOffSchedule } from "../../molecules/OnceOffSchedule";
import { ComponentPanel, TextTitledPanel } from "../../molecules/SbPanel";
import { WeeklySchedule } from "../../molecules/WeeklySchedule";
import { YearlySchedule } from "../../molecules/YearlySchedule";

const StyledForm = styled(Form)`
    overflow: visible;
    position: relative;
`;

const StyledSubFormDiv = styled.div`
    padding-left: ${(props) => props.theme.padding.xxxl};
    color: ${(props) => props.theme.palette.secondary};
    font-weight: 600;
`;

const StyledDivSeparatorLine = styled.div`
    border-right: ${(props) => props.theme.padding.xxs} solid ${(props) => props.theme.palette.grey};
    padding-right: ${(props) => props.theme.padding.lg};
    height: ${(props) => props.theme.dimensions.height.xxxl};
`;

const EditScheduleContainer: React.FC = () => {
    let questionSetSchedule = CreateQuestionSetSchedule();

    const [currentQuestionSetSchedule, setSelectedQuestionSetSchedule] =
        useState(questionSetSchedule);
    const [selectedFrequencyOption, setSelectedFrequency] = useState("");
    const [selectedDailyScheduleDescription, setSelectedDailyScheduleDescription] = useState("");
    const [selectedMonthlyScheduleDescription, setSelectedMonthlyScheduleDescription] =
        useState("");
    const [selectedYearlyScheduleDescription, setSelectedYearlyScheduleDescription] = useState("");
    const [checkedMondayScheduleDescription, setCheckedMondayScheduleDescription] = useState(false);
    const [checkedTuesdayScheduleDescription, setCheckedTuesdayScheduleDescription] =
        useState(false);
    const [checkedWednesdayScheduleDescription, setCheckedWednesdayScheduleDescription] =
        useState(false);
    const [checkedThursdayScheduleDescription, setCheckedThursdayScheduleDescription] =
        useState(false);
    const [checkedFridayScheduleDescription, setCheckedFridayScheduleDescription] = useState(false);
    const [checkedSaturdayScheduleDescription, setCheckedSaturdayScheduleDescription] =
        useState(false);
    const [checkedSundayScheduleDescription, setCheckedSundayScheduleDescription] = useState(false);
    const [ViewableFromNumber, setViewableFromNumber] = useState(0);
    const [ViewableFromDropDown, setViewableFromDropDown] = useState(
        PeriodTypeOptions.find((x) => x.value === PeriodType.Hours)!.label
    );
    const [selectedLockoutOption, setSelectedLockoutOption] = useState(false);
    const [lockoutNumberAfterDue, setLockoutNumberAfterDue] = useState(0);
    const [lockoutOptionAfterDue, setLockoutOptionAfterDue] = useState(
        PeriodTypeOptions.find((x) => x.value === PeriodType.Hours)!.label
    );

    const menu = useMenu();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const navigate = useNavigate();
    const auth = useAuth();
    const [urlSearchParams] = useSearchParams();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const checklistAssignmentId = Number(useParams().checklistAssignmentId);
    const questionSetAssignmentScheduleId = Number(
        urlSearchParams.get("questionSetAssignmentScheduleId")
    );
    const questionSetId = Number(urlSearchParams.get("questionSetId"));

    const getScheduleDetails = useGetScheduleDetails(
        checklistAssignmentId,
        questionSetAssignmentScheduleId
    );
    const editSchedule = useEditSchedule(currentQuestionSetSchedule);

    const numberOfMinutesInAnHour = 60;
    const numberOfMinutesInADay = 1440;

    useLoader(
        isQueryLoading(getScheduleDetails) || isMutationLoading(editSchedule),
        EditScheduleContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Checklists);
    }, []);

    useEffect(() => {
        if (getScheduleDetails.status === "success" && getScheduleDetails.fetchStatus === "idle") {
            questionSetSchedule = {
                frequencyType: getScheduleDetails.data?.frequencyType,
                baseStartTimeOfDay: getScheduleDetails.data?.baseStartTimeOfDay,
                viewableMinutesBeforeBaseStart:
                    getScheduleDetails.data?.viewableMinutesBeforeBaseStart,
                dayOfWeek: getScheduleDetails.data?.daysOfWeek,
                dayOfMonth: getScheduleDetails.data?.dayOfMonth,
                dayOrdinal:
                    getScheduleDetails.data?.dayOrdinal === null
                        ? DayOrdinal.None
                        : getScheduleDetails.data?.dayOrdinal,
                monthsOfTheYearInterval: getScheduleDetails.data?.interval,
                monthOfTheYear: getScheduleDetails.data?.monthOfYear,
                yearInterval: getScheduleDetails.data?.interval,
                year: getScheduleDetails.data?.year,
                interval: getScheduleDetails.data?.interval,
                maxOccurrences: getScheduleDetails.data?.maxOccurrences,
                nonWorkingDayRolloverType: getScheduleDetails.data?.nonWorkingDayRolloverType,
                scheduleDescription: getScheduleDetails.data?.scheduleDescription,
                viewableReminderRequired: false,
                isLockoutEnabled: getScheduleDetails.data?.isLockoutEnabled,
                lockoutMinutesAfterDue: getScheduleDetails.data?.lockoutMinutesAfterDue,
                dailyScheduleOption: getDailyValue(getScheduleDetails.data?.daysOfWeek),
                numberOfDays: getScheduleDetails.data?.interval,
                numberOfWeeks: getScheduleDetails.data?.interval,
                weeklyScheduleOptions: getWeeklyOptions(
                    getScheduleDetails.data?.scheduleDescription
                ),
                monthlyScheduleOption: getScheduleDetails.data?.dayOfMonth
                    ? MonthlyScheduleOption.DayXOfEveryXmonths
                    : MonthlyScheduleOption.TheXXOfEveryXMonths,
                yearlyScheduleOption: getScheduleDetails.data?.dayOfMonth
                    ? YearlyScheduleOption.OnXX
                    : YearlyScheduleOption.OnTheXDayOfX,
            };

            if (selectedFrequencyOption == "") {
                setSelectedFrequency(getScheduleDetails.data?.frequencyType.toString());
                setSelectedDailyScheduleDescription(getDailyOption());
                setSelectedMonthlyScheduleDescription(getInitMonthlyScheduleDescription());
                setSelectedYearlyScheduleDescription(getInitYearlyDescription());
                setSelectedQuestionSetSchedule(questionSetSchedule);
                setSelectedLockoutOption(getScheduleDetails.data?.isLockoutEnabled);
                setViewableFromNumber(
                    getScheduleDetails.data?.viewableMinutesBeforeBaseStart >= numberOfMinutesInADay
                        ? getScheduleDetails.data!.viewableMinutesBeforeBaseStart /
                              numberOfMinutesInADay
                        : getScheduleDetails.data!.viewableMinutesBeforeBaseStart /
                              numberOfMinutesInAnHour
                );
                setViewableFromDropDown(
                    getScheduleDetails.data?.viewableMinutesBeforeBaseStart >= numberOfMinutesInADay
                        ? PeriodTypeOptions.find((x) => x.value === PeriodType.Days)!.label
                        : PeriodTypeOptions.find((x) => x.value === PeriodType.Hours)!.label
                );
                setLockoutNumberAfterDue(
                    getScheduleDetails.data?.lockoutMinutesAfterDue >= numberOfMinutesInADay
                        ? getScheduleDetails.data!.lockoutMinutesAfterDue / numberOfMinutesInADay
                        : getScheduleDetails.data!.lockoutMinutesAfterDue / numberOfMinutesInAnHour
                );
                setLockoutOptionAfterDue(
                    getScheduleDetails.data?.lockoutMinutesAfterDue >= numberOfMinutesInADay
                        ? PeriodTypeOptions.find((x) => x.value === PeriodType.Days)!.label
                        : PeriodTypeOptions.find((x) => x.value === PeriodType.Hours)!.label
                );
            }
        }
    }, [getScheduleDetails.fetchStatus]);

    const getInitMonthlyScheduleDescription = (): string => {
        if (getScheduleDetails.data?.dayOfMonth) {
            questionSetSchedule.monthlyScheduleOption = MonthlyScheduleOption.DayXOfEveryXmonths;
            return "dayXofEveryXMonths";
        } else {
            questionSetSchedule.monthlyScheduleOption = MonthlyScheduleOption.TheXXOfEveryXMonths;
            return "theXOfEveryXMonth";
        }
    };

    const getInitYearlyDescription = (): string => {
        if (getScheduleDetails.data?.dayOfMonth) {
            questionSetSchedule.yearlyScheduleOption = YearlyScheduleOption.OnXX;
            return "onX";
        } else {
            questionSetSchedule.yearlyScheduleOption = YearlyScheduleOption.OnTheXDayOfX;
            return "onTheXOf";
        }
    };

    const getDailyOption = (): string => {
        switch (getScheduleDetails.data?.daysOfWeek) {
            case DaysOfWeek.Weekday:
                return "everyWeekday";
            case DaysOfWeek.WeekdayInclSaturday:
                return "everyWeekday+Saturday";
            case DaysOfWeek.Any:
                return "everyXDays";
            default:
                return "everyXDays";
        }
    };

    const getDailyValue = (dayOfWeek: DaysOfWeek): DailyScheduleOption => {
        switch (dayOfWeek) {
            case DaysOfWeek.Weekday:
                return DailyScheduleOption.EveryWeekday;
            case DaysOfWeek.WeekdayInclSaturday:
                return DailyScheduleOption.EveryWeekdayPlusSaturday;
            case DaysOfWeek.Any:
                return DailyScheduleOption.EveryXDay;
            default:
                return DailyScheduleOption.EveryXDay;
        }
    };

    const getWeeklyOptions = (description: string): WeeklyScheduleOption[] => {
        let weeklyOptions: WeeklyScheduleOption[] = [];

        if (description.includes("Monday")) {
            weeklyOptions.push(WeeklyScheduleOption.Monday);
            setCheckedMondayScheduleDescription(true);
        }

        if (description.includes("Tuesday")) {
            weeklyOptions.push(WeeklyScheduleOption.Tuesday);
            setCheckedTuesdayScheduleDescription(true);
        }

        if (description.includes("Wednesday")) {
            weeklyOptions.push(WeeklyScheduleOption.Wednesday);
            setCheckedWednesdayScheduleDescription(true);
        }

        if (description.includes("Thursday")) {
            weeklyOptions.push(WeeklyScheduleOption.Thursday);
            setCheckedThursdayScheduleDescription(true);
        }

        if (description.includes("Friday")) {
            weeklyOptions.push(WeeklyScheduleOption.Friday);
            setCheckedFridayScheduleDescription(true);
        }

        if (description.includes("Saturday")) {
            weeklyOptions.push(WeeklyScheduleOption.Saturday);
            setCheckedSaturdayScheduleDescription(true);
        }

        if (description.includes("Sunday")) {
            weeklyOptions.push(WeeklyScheduleOption.Sunday);
            setCheckedSundayScheduleDescription(true);
        }
        return weeklyOptions;
    };

    function handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);

        editSchedule.mutate(
            new EditScheduleDto(
                getScheduleDetails.data!.questionSetAssignmentScheduleId,
                checklistAssignmentId,
                new Date(formData.get("activeDate") as string),
                new Date(formData.get("expiryDate") as string),
                convertToggleValueToBoolean(formData.get("effectChangesImmediately")! as string)
            ),
            {
                onSuccess: async () => {
                    trackAppInsightsEvent(
                        auth.email,
                        window.location.href,
                        EditQuestionSetScheduleEvent
                    );
                    navigate(-1);
                },
                onError: (error: HTTPError) => {
                    trackAppInsightsException(
                        auth.email,
                        window.location.href,
                        EditQuestionSetScheduleEvent,
                        error
                    );
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    }

    function setWeekDays(weeklyScheduleOption: WeeklyScheduleOption): void {
        const index =
            currentQuestionSetSchedule.weeklyScheduleOptions.indexOf(weeklyScheduleOption);
        const hasOption =
            currentQuestionSetSchedule.weeklyScheduleOptions.includes(weeklyScheduleOption);

        hasOption
            ? currentQuestionSetSchedule.weeklyScheduleOptions.splice(index, 1)
            : currentQuestionSetSchedule.weeklyScheduleOptions.push(weeklyScheduleOption);

        setSelectedQuestionSetSchedule(currentQuestionSetSchedule);
    }

    const handleFrequencyChange = (name: string, _: boolean): void => {
        setSelectedFrequency(name);
        currentQuestionSetSchedule.frequencyType =
            FrequencyOptions.find((x) => x.value.toString() == name)?.value ?? FrequencyType.None;
        setSelectedQuestionSetSchedule(currentQuestionSetSchedule);
    };

    const handleDailyScheduleDescriptionChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        switch (event.target.name) {
            case "everyXDays":
                currentQuestionSetSchedule.dailyScheduleOption = DailyScheduleOption.EveryXDay;
                currentQuestionSetSchedule.dayOfWeek = DaysOfWeek.Any;
                break;
            case "everyWeekday":
                currentQuestionSetSchedule.dailyScheduleOption = DailyScheduleOption.EveryWeekday;
                currentQuestionSetSchedule.dayOfWeek = DaysOfWeek.Weekday;
                break;
            case "everyWeekday+Saturday":
                currentQuestionSetSchedule.dailyScheduleOption =
                    DailyScheduleOption.EveryWeekdayPlusSaturday;
                currentQuestionSetSchedule.dayOfWeek = DaysOfWeek.WeekdayInclSaturday;
                break;
        }
        setSelectedDailyScheduleDescription(event.target.name);
        setSelectedQuestionSetSchedule(currentQuestionSetSchedule);
    };

    const handleMonthlyScheduleDescriptionChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        switch (event.target.name) {
            case "dayXofEveryXMonths":
                currentQuestionSetSchedule.monthlyScheduleOption =
                    MonthlyScheduleOption.DayXOfEveryXmonths;
                break;
            case "theXOfEveryXMonth":
                currentQuestionSetSchedule.monthlyScheduleOption =
                    MonthlyScheduleOption.TheXXOfEveryXMonths;
                break;
        }
        setSelectedQuestionSetSchedule(currentQuestionSetSchedule);
        setSelectedMonthlyScheduleDescription(event.target.name);
    };

    const handleYearlyScheduleDescriptionChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        switch (event.target.name) {
            case "onX":
                currentQuestionSetSchedule.yearlyScheduleOption = YearlyScheduleOption.OnXX;
                break;
            case "onTheXOf":
                currentQuestionSetSchedule.yearlyScheduleOption = YearlyScheduleOption.OnTheXDayOfX;
                break;
        }
        setSelectedQuestionSetSchedule(currentQuestionSetSchedule);
        setSelectedYearlyScheduleDescription(event.target.name);
    };

    const handleLockoutOption = (): void => {
        setSelectedLockoutOption(!selectedLockoutOption);
        currentQuestionSetSchedule.isLockoutEnabled = !selectedLockoutOption;
        setSelectedQuestionSetSchedule(currentQuestionSetSchedule);
    };

    const handleViewableFromNumberChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setViewableFromNumber(event.target.valueAsNumber);
        currentQuestionSetSchedule.viewableMinutesBeforeBaseStart = calculateMinutes(
            event.target.valueAsNumber,
            ViewableFromDropDown
        );
        setSelectedQuestionSetSchedule(currentQuestionSetSchedule);
    };

    const handleViewableFromDropDownChange = (
        item: SingleValue<{ value: string; label: string }>
    ): void => {
        setViewableFromDropDown(
            item
                ? item.value.toString()
                : PeriodTypeOptions.find((x) => x.value === PeriodType.Hours)!.label
        );
        currentQuestionSetSchedule.viewableMinutesBeforeBaseStart = calculateMinutes(
            ViewableFromNumber,
            item
                ? item.value.toString()
                : PeriodTypeOptions.find((x) => x.value === PeriodType.Hours)!.label
        );
        setSelectedQuestionSetSchedule(currentQuestionSetSchedule);
    };

    const handleBaseStartTimeOfDayChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        currentQuestionSetSchedule.baseStartTimeOfDay = event.target.value;
        setSelectedQuestionSetSchedule(currentQuestionSetSchedule);
    };

    const handleNumberOfDaysChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        currentQuestionSetSchedule.numberOfDays = event.target.valueAsNumber;
        setSelectedQuestionSetSchedule(currentQuestionSetSchedule);
    };

    const handleNumberOfWeeksChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        currentQuestionSetSchedule.numberOfWeeks = event.target.valueAsNumber;
        setSelectedQuestionSetSchedule(currentQuestionSetSchedule);
    };

    const handleDayOfTheMonthChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        currentQuestionSetSchedule.dayOfMonth = event.target.valueAsNumber;
        setSelectedQuestionSetSchedule(currentQuestionSetSchedule);
    };

    const handleMonthsOfTheYearIntervalChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        currentQuestionSetSchedule.monthsOfTheYearInterval = event.target.valueAsNumber;
        setSelectedQuestionSetSchedule(currentQuestionSetSchedule);
    };

    const handleDayOrdinalChange = (item: SingleValue<ReactSelectDto<DayOrdinal>>): void => {
        currentQuestionSetSchedule.dayOrdinal =
            DayOrdinalOptions.find((x) => x.label == item?.label)?.value ?? DayOrdinal.None;
        setSelectedQuestionSetSchedule(currentQuestionSetSchedule);
    };

    const handleDayOfTheWeekChange = (item: SingleValue<ReactSelectDto<DaysOfWeek>>): void => {
        currentQuestionSetSchedule.dayOfWeek =
            DaysOfTheWeekOptions.find((x) => x.label == item?.label)?.value ?? DaysOfWeek.Any;
        setSelectedQuestionSetSchedule(currentQuestionSetSchedule);
    };

    const handleWeeklyScheduleDescriptionChange = (name: string, _: boolean): void => {
        switch (name) {
            case "monday":
                setWeekDays(WeeklyScheduleOption.Monday);
                setCheckedMondayScheduleDescription(!checkedMondayScheduleDescription);
                break;

            case "tuesday":
                setWeekDays(WeeklyScheduleOption.Tuesday);
                setCheckedTuesdayScheduleDescription(!checkedTuesdayScheduleDescription);
                break;

            case "wednesday":
                setWeekDays(WeeklyScheduleOption.Wednesday);
                setCheckedWednesdayScheduleDescription(!checkedWednesdayScheduleDescription);
                break;

            case "thursday":
                setWeekDays(WeeklyScheduleOption.Thursday);
                setCheckedThursdayScheduleDescription(!checkedThursdayScheduleDescription);
                break;

            case "friday":
                setWeekDays(WeeklyScheduleOption.Friday);
                setCheckedFridayScheduleDescription(!checkedFridayScheduleDescription);
                break;

            case "saturday":
                setWeekDays(WeeklyScheduleOption.Saturday);
                setCheckedSaturdayScheduleDescription(!checkedSaturdayScheduleDescription);
                break;

            case "sunday":
                setWeekDays(WeeklyScheduleOption.Sunday);
                setCheckedSundayScheduleDescription(!checkedSundayScheduleDescription);
                break;
        }
    };

    const handleMonthOfTheYearChange = (
        item: SingleValue<ReactSelectDto<MonthOfTheYear>>
    ): void => {
        currentQuestionSetSchedule.monthOfTheYear =
            MonthOfTheYear[item?.label as keyof typeof MonthOfTheYear];
        setSelectedQuestionSetSchedule(currentQuestionSetSchedule);
    };

    const handleYearIntervalChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        currentQuestionSetSchedule.yearInterval = event.target.valueAsNumber;
        setSelectedQuestionSetSchedule(currentQuestionSetSchedule);
    };

    const handleYearChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        currentQuestionSetSchedule.year = event.target.valueAsNumber;
        setSelectedQuestionSetSchedule(currentQuestionSetSchedule);
    };

    const handleMaxOccurrencesChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        currentQuestionSetSchedule.maxOccurrences = event.target.valueAsNumber;
        setSelectedQuestionSetSchedule(currentQuestionSetSchedule);
    };

    const handleHolidayRollOverChange = (
        item: SingleValue<{ value: string; label: string }>
    ): void => {
        currentQuestionSetSchedule.nonWorkingDayRolloverType =
            NonWorkingDayRolloverOptions.find((x) => x.label == item?.label)?.value ??
            NonWorkingDayRolloverType.None;
        setSelectedQuestionSetSchedule(currentQuestionSetSchedule);
    };

    const handleLockoutNumberAfterDue = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setLockoutNumberAfterDue(event.target.valueAsNumber);
        currentQuestionSetSchedule.lockoutMinutesAfterDue = calculateMinutes(
            event.target.valueAsNumber,
            lockoutOptionAfterDue
        );
        setSelectedQuestionSetSchedule(currentQuestionSetSchedule);
    };

    const handleLockoutOptionAfterDue = (
        item: SingleValue<{ value: string; label: string }>
    ): void => {
        setLockoutOptionAfterDue(
            item
                ? item.value.toString()
                : PeriodTypeOptions.find((x) => x.value === PeriodType.Hours)!.label
        );
        currentQuestionSetSchedule.lockoutMinutesAfterDue = calculateMinutes(
            lockoutNumberAfterDue,
            item
                ? item.value.toString()
                : PeriodTypeOptions.find((x) => x.value === PeriodType.Hours)!.label
        );
        setSelectedQuestionSetSchedule(currentQuestionSetSchedule);
    };

    const buildQuestionSetEditLink = (): ReactElement<typeof Link> => (
        <EditLink navigateTo={`${getPath(AccordionTitles.QuestionSets)}/${questionSetId}/edit`} />
    );

    return (
        <>
            <PageHeading>Question Set Assignment Schedule</PageHeading>
            <SectionVerticalSpace />
            {isQuerySuccessful(getScheduleDetails) && (
                <TextTitledPanel title={"Override schedule from the default"}>
                    <StyledForm onSubmit={handleSubmit}>
                        <SbAlert
                            variant={"primary"}
                            text={t("OverridingNote")}
                            link={buildQuestionSetEditLink}
                        />

                        <SbFormCheckFieldGroup
                            fieldLabel={t("EffectChangesIncludingToday")}
                            type="checkbox"
                            values={[
                                {
                                    name: "effectChangesImmediately",
                                },
                            ]}
                        />

                        <SbFormDateTimeFieldGroup
                            name="activeDate"
                            label={t("ActiveDateTime")}
                            defaultValue={new Date()}
                            required
                        />

                        <SbFormDateTimeFieldGroup name="expiryDate" label={t("ExpiryDateTime")} />

                        <SbFormCheckFieldGroup
                            fieldLabel={t("IsDefaultSchedule")}
                            type="checkbox"
                            values={[
                                {
                                    name: "isDefaultSchedule",
                                    defaultSelected: getScheduleDetails.data?.isDefaultSchedule,
                                    disabled: true,
                                },
                            ]}
                        />

                        <ComponentPanel>
                            <StyledSubFormDiv>
                                <Row>
                                    {t("FrequencyType")}
                                    <Col sm={2}>
                                        <StyledDivSeparatorLine>
                                            <FrequencyTypeSchedule
                                                handleFrequencyChange={handleFrequencyChange}
                                                selectedFrequencyOption={selectedFrequencyOption}
                                                useAutoColumnWidth
                                            />
                                        </StyledDivSeparatorLine>
                                    </Col>
                                    <Col>
                                        {selectedFrequencyOption ===
                                            FrequencyType.Daily.toString() && (
                                            <DailySchedule
                                                handleDailyScheduleDescriptionChange={
                                                    handleDailyScheduleDescriptionChange
                                                }
                                                selectedDailyScheduleDescription={
                                                    selectedDailyScheduleDescription
                                                }
                                                handleNumberOfDaysChange={handleNumberOfDaysChange}
                                                numberOfDaysValue={
                                                    getScheduleDetails.data?.interval
                                                }
                                            ></DailySchedule>
                                        )}
                                        {selectedFrequencyOption ===
                                            FrequencyType.Weekly.toString() && (
                                            <WeeklySchedule
                                                handleNumberOfWeeksChange={
                                                    handleNumberOfWeeksChange
                                                }
                                                handleWeeklyScheduleDescriptionChange={
                                                    handleWeeklyScheduleDescriptionChange
                                                }
                                                checkedMondayScheduleDescription={
                                                    checkedMondayScheduleDescription
                                                }
                                                checkedTuesdayScheduleDescription={
                                                    checkedTuesdayScheduleDescription
                                                }
                                                checkedWednesdayScheduleDescription={
                                                    checkedWednesdayScheduleDescription
                                                }
                                                checkedThursdayScheduleDescription={
                                                    checkedThursdayScheduleDescription
                                                }
                                                checkedFridayScheduleDescription={
                                                    checkedFridayScheduleDescription
                                                }
                                                checkedSaturdayScheduleDescription={
                                                    checkedSaturdayScheduleDescription
                                                }
                                                checkedSundayScheduleDescription={
                                                    checkedSundayScheduleDescription
                                                }
                                                numberOfWeeks={getScheduleDetails.data?.interval}
                                            />
                                        )}
                                        {selectedFrequencyOption ===
                                            FrequencyType.Monthly.toString() && (
                                            <MonthlySchedule
                                                handleMonthlyScheduleDescriptionChange={
                                                    handleMonthlyScheduleDescriptionChange
                                                }
                                                selectedMonthlyScheduleDescription={
                                                    selectedMonthlyScheduleDescription
                                                }
                                                handleDayOfTheMonthChange={
                                                    handleDayOfTheMonthChange
                                                }
                                                handleMonthsOfTheYearIntervalChange={
                                                    handleMonthsOfTheYearIntervalChange
                                                }
                                                handleDayOrdinalChange={handleDayOrdinalChange}
                                                handleDayOfTheWeekChange={handleDayOfTheWeekChange}
                                                dayOfTheMonth={getScheduleDetails.data?.dayOfMonth}
                                                dayOfTheWeek={getScheduleDetails.data?.daysOfWeek?.toString()}
                                                monthOfTheYearInterval={
                                                    getScheduleDetails.data?.interval
                                                }
                                                dayOrdinal={getScheduleDetails.data?.dayOrdinal?.toString()}
                                            />
                                        )}
                                        {selectedFrequencyOption ===
                                            FrequencyType.Yearly.toString() && (
                                            <YearlySchedule
                                                selectedYearlyScheduleDescription={
                                                    selectedYearlyScheduleDescription
                                                }
                                                handleYearlyScheduleDescriptionChange={
                                                    handleYearlyScheduleDescriptionChange
                                                }
                                                handleDayOfTheMonthChange={
                                                    handleDayOfTheMonthChange
                                                }
                                                handleDayOrdinalChange={handleDayOrdinalChange}
                                                handleDaysOfTheWeekChange={handleDayOfTheWeekChange}
                                                handleMonthOfTheYearChange={
                                                    handleMonthOfTheYearChange
                                                }
                                                handleYearIntervalChange={handleYearIntervalChange}
                                                dayOfTheMonth={getScheduleDetails.data?.dayOfMonth}
                                                dayOfTheWeek={getScheduleDetails.data?.daysOfWeek?.toString()}
                                                monthOfTheYear={getScheduleDetails.data?.monthOfYear?.toString()}
                                                dayOrdinal={getScheduleDetails.data?.dayOrdinal?.toString()}
                                                yearInterval={getScheduleDetails.data?.interval}
                                            />
                                        )}
                                        {selectedFrequencyOption ===
                                            FrequencyType.OnceOff.toString() && (
                                            <OnceOffSchedule
                                                handleDayOfTheMonthChange={
                                                    handleDayOfTheMonthChange
                                                }
                                                handleMonthOfTheYearChange={
                                                    handleMonthOfTheYearChange
                                                }
                                                handleYearChange={handleYearChange}
                                                dayOfTheMonth={getScheduleDetails.data?.dayOfMonth}
                                                monthOfTheYear={getScheduleDetails.data?.monthOfYear?.toString()}
                                                year={getScheduleDetails.data?.year}
                                            />
                                        )}
                                        {selectedFrequencyOption === FrequencyType.Adhoc.toString()}
                                    </Col>
                                </Row>
                            </StyledSubFormDiv>
                        </ComponentPanel>

                        <SectionVerticalSpace />

                        {selectedFrequencyOption === FrequencyType.Adhoc.toString() ? (
                            <AdhocHolidayRollOverSchedule
                                handleLockoutOption={handleLockoutOption}
                                selectedLockoutOption={selectedLockoutOption}
                                handleLockoutNumberAfterDue={handleLockoutNumberAfterDue}
                                handleLockoutOptionAfterDue={handleLockoutOptionAfterDue}
                                lockoutNumber={
                                    getScheduleDetails.data!.lockoutMinutesAfterDue >=
                                    numberOfMinutesInADay
                                        ? getScheduleDetails.data!.lockoutMinutesAfterDue /
                                          numberOfMinutesInADay
                                        : getScheduleDetails.data!.lockoutMinutesAfterDue /
                                          numberOfMinutesInAnHour
                                }
                                lockoutOption={
                                    getScheduleDetails.data!.lockoutMinutesAfterDue >=
                                    numberOfMinutesInADay
                                        ? PeriodTypeOptions.find(
                                              (x) => x.value === PeriodType.Days
                                          )!.label
                                        : PeriodTypeOptions.find(
                                              (x) => x.value === PeriodType.Hours
                                          )!.label
                                }
                                viewableFromNumber={
                                    getScheduleDetails.data!.viewableMinutesBeforeBaseStart >=
                                    numberOfMinutesInADay
                                        ? getScheduleDetails.data!.viewableMinutesBeforeBaseStart /
                                          numberOfMinutesInADay
                                        : getScheduleDetails.data!.viewableMinutesBeforeBaseStart /
                                          numberOfMinutesInAnHour
                                }
                                viewableTimePeriod={
                                    getScheduleDetails.data!.viewableMinutesBeforeBaseStart >=
                                    numberOfMinutesInADay
                                        ? PeriodTypeOptions.find(
                                              (x) => x.value === PeriodType.Days
                                          )!.label
                                        : PeriodTypeOptions.find(
                                              (x) => x.value === PeriodType.Hours
                                          )!.label
                                }
                                handleViewableFromDropDownChange={handleViewableFromDropDownChange}
                                handleViewableFromNumberChange={handleViewableFromNumberChange}
                            />
                        ) : (
                            <HolidayRollOverSchedule
                                handleBaseStartTimeOfDayChange={handleBaseStartTimeOfDayChange}
                                handleLockoutOption={handleLockoutOption}
                                handleViewableFromDropDownChange={handleViewableFromDropDownChange}
                                handleViewableFromNumberChange={handleViewableFromNumberChange}
                                selectedLockoutOption={selectedLockoutOption}
                                handleMaxOccurrencesChange={handleMaxOccurrencesChange}
                                handleHolidayRollOverChange={handleHolidayRollOverChange}
                                handleLockoutNumberAfterDue={handleLockoutNumberAfterDue}
                                handleLockoutOptionAfterDue={handleLockoutOptionAfterDue}
                                holidayRollOverOption={
                                    NonWorkingDayRolloverOptions.find(
                                        (x) =>
                                            x.value ==
                                            getScheduleDetails.data!.nonWorkingDayRolloverType
                                    )?.label ?? "None"
                                }
                                baseStartTimeOfDay={getScheduleDetails.data!.baseStartTimeOfDay}
                                viewableFromNumber={
                                    getScheduleDetails.data!.viewableMinutesBeforeBaseStart >=
                                    numberOfMinutesInADay
                                        ? getScheduleDetails.data!.viewableMinutesBeforeBaseStart /
                                          numberOfMinutesInADay
                                        : getScheduleDetails.data!.viewableMinutesBeforeBaseStart /
                                          numberOfMinutesInAnHour
                                }
                                viewableTimePeriod={
                                    getScheduleDetails.data!.viewableMinutesBeforeBaseStart >=
                                    numberOfMinutesInADay
                                        ? PeriodTypeOptions.find(
                                              (x) => x.value === PeriodType.Days
                                          )!.label
                                        : PeriodTypeOptions.find(
                                              (x) => x.value === PeriodType.Hours
                                          )!.label
                                }
                                lockoutNumber={
                                    getScheduleDetails.data!.lockoutMinutesAfterDue >=
                                    numberOfMinutesInADay
                                        ? getScheduleDetails.data!.lockoutMinutesAfterDue /
                                          numberOfMinutesInADay
                                        : getScheduleDetails.data!.lockoutMinutesAfterDue /
                                          numberOfMinutesInAnHour
                                }
                                lockoutOption={
                                    getScheduleDetails.data!.lockoutMinutesAfterDue >=
                                    numberOfMinutesInADay
                                        ? PeriodTypeOptions.find(
                                              (x) => x.value === PeriodType.Days
                                          )!.label
                                        : PeriodTypeOptions.find(
                                              (x) => x.value === PeriodType.Hours
                                          )!.label
                                }
                                maxOccurrences={getScheduleDetails.data!.maxOccurrences}
                            />
                        )}
                        <SectionVerticalSpace />
                        <EndAlignedDiv>
                            <SaveButton type="submit" />
                            <CancelButton onClick={() => navigate(-1)} />
                        </EndAlignedDiv>
                    </StyledForm>
                </TextTitledPanel>
            )}
        </>
    );
};

export default EditScheduleContainer;
