import { PaginationDto } from "../../dtos/common/pagination-dto";
import { buildPaginationSearchParameters, PaginationRequest } from "../common/pagination-request";

export class FilterNestedQuestionsRequest extends PaginationRequest {
    parentQuestionId: number;

    public constructor(parentQuestionId: number, paginationDto: PaginationDto) {
        super(paginationDto);
        this.parentQuestionId = parentQuestionId;
    }
}

const SearchParameters = {
    parentQuestionId: "parentQuestionId",
};

export const buildFilterNestedQuestionsSearchParameters = (
    request: FilterNestedQuestionsRequest
): URLSearchParams => {
    const searchParams = buildPaginationSearchParameters(request);

    if (request.parentQuestionId != null) {
        searchParams.set(SearchParameters.parentQuestionId, request.parentQuestionId.toString());
    }

    return searchParams;
};
