import { localDateSerialiserReplacer } from "../../../core/utilities/serialiser";
import {
    buildFilterUserQuestionsSearchParameters,
    FilterUserQuestionsRequest,
} from "../../../domain/requests/answer-capture/filter-user-questions-request";
import IndicatorQuestionAnswerRequest from "../../../domain/requests/answer-capture/indicator-question-answer-request";
import QuestionAnswerRequest from "../../../domain/requests/answer-capture/question-answer-request";
import IndicatorQuestionAnswerFlagResponse from "../../../domain/responses/answer-capture/indicator-question-answer-flag-response";
import { QuestionAnswerDetailsResponse } from "../../../domain/responses/answer-capture/question-answer-details-response";
import QuestionCaptureResultsResponse from "../../../domain/responses/answer-capture/question-capture-results-response";
import QuestionCaptureSetResponse from "../../../domain/responses/answer-capture/question-capture-set-response";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import client from "../../http-client";

const route = "answercapture";

export const filterUserQuestions = async (
    prefixUrl: string,
    request: FilterUserQuestionsRequest
): Promise<Response<PaginationResponse<QuestionCaptureSetResponse>>> => {
    const searchParams = buildFilterUserQuestionsSearchParameters(request);

    return await client
        .get(`${route}/filter`, {
            prefixUrl: `${prefixUrl}`,
            searchParams: searchParams,
        })
        .json();
};

export const submitAnswers = async (
    prefixUrl: string,
    requests: QuestionAnswerRequest[]
): Promise<Response<QuestionCaptureResultsResponse>> =>
    await client
        .post(`${route}/submit`, {
            prefixUrl: `${prefixUrl}`,
            body: JSON.stringify(
                requests,
                localDateSerialiserReplacer(["dateTimeValueLocal", "actionItemDueDateTimeLocal"])
            ),
        })
        .json();

export const getQuestionAnswerDetails = async (
    prefixUrl: string,
    questionSetInstanceAnswerId: number
): Promise<Response<QuestionAnswerDetailsResponse>> =>
    await client
        .get(`${route}/${questionSetInstanceAnswerId}`, {
            prefixUrl: `${prefixUrl}`,
        })
        .json();

export const saveAnswers = async (
    prefixUrl: string,
    requests: QuestionAnswerRequest[]
): Promise<Response<QuestionCaptureResultsResponse>> =>
    await client
        .post(`${route}/save`, {
            prefixUrl: `${prefixUrl}`,
            body: JSON.stringify(
                requests,
                localDateSerialiserReplacer(["dateTimeValueLocal", "actionItemDueDateTimeLocal"])
            ),
        })
        .json();

export const getIndicatorQuestionAnswerFlag = async (
    prefixUrl: string,
    request: IndicatorQuestionAnswerRequest
): Promise<Response<IndicatorQuestionAnswerFlagResponse>> => {
    const searchParams = request.buildSearchParameters();

    return await client
        .get(`${route}/indicator-question-answer-flag`, {
            prefixUrl: `${prefixUrl}`,
            searchParams: searchParams,
        })
        .json();
};
