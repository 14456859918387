import { CreateActionItemDto } from "../../dtos/actions/create-action-item-dto";
import { ActionItemImportance } from "../../enums/action-items/ActionItemImportance";
import { ActionItemNodeAssociationMode } from "../../enums/action-items/ActionItemNodeAssociationMode";
import { CreateActionItemCustomNotificationRequest } from "./create-action-item-custom-notification-request";
import { CreateActionItemNotificationTriggersRequest } from "./create-action-item-notification-triggers-request";
import { CreateEditActionItemDynamicFieldValueRequest } from "./create-edit-action-item-dynamic-field-value-request";
import { CreateRaciSetUserRequest } from "./create-raci-set-user-request";

export class CreateActionItemRequest {
    actionPlanId: number | null;
    name: string;
    description: string;
    actionItemImportance: ActionItemImportance;
    startDate: Date;
    dueDate: Date;
    isPersonalActionItem: boolean;
    actionItemSubTypeNodeId: number;
    requiredOutcome: string;
    createEditActionItemDynamicFieldValueRequests: CreateEditActionItemDynamicFieldValueRequest[];
    createRaciSetUserRequests: CreateRaciSetUserRequest[];
    overrideNotificationTriggers: boolean;
    createActionItemCustomNotificationRequest: CreateActionItemCustomNotificationRequest | null;
    organisationalNodeId: number | null;
    nodeAssociationMode: ActionItemNodeAssociationMode | null;
    createActionItemNotificationTriggersRequests:
        | CreateActionItemNotificationTriggersRequest[]
        | null;

    public constructor(dto: CreateActionItemDto) {
        const {
            actionPlanId,
            name,
            description,
            actionItemImportance,
            startDate,
            dueDate,
            isPersonalActionItem,
            actionItemSubTypeNodeId,
            createEditActionItemDynamicFieldValueDto,
            requiredOutcome,
            createRaciSetUserDto,
            overrideNotificationTriggers,
            createActionItemCustomNotificationDto,
            createActionItemNotificationTriggerDto,
            organisationalNodeId,
            nodeAssociationMode,
        } = dto;

        this.actionPlanId = actionPlanId;
        this.name = name;
        this.description = description;
        this.actionItemImportance = actionItemImportance;
        this.startDate = startDate;
        this.dueDate = dueDate;
        this.isPersonalActionItem = isPersonalActionItem;
        this.actionItemSubTypeNodeId = actionItemSubTypeNodeId;
        this.requiredOutcome = requiredOutcome;
        this.createEditActionItemDynamicFieldValueRequests =
            CreateEditActionItemDynamicFieldValueRequest.toCreateEditActionItemDynamicFieldValueRequests(
                createEditActionItemDynamicFieldValueDto
            );
        this.createRaciSetUserRequests =
            CreateRaciSetUserRequest.toCreateRaciSetUserRequests(createRaciSetUserDto);
        this.createActionItemCustomNotificationRequest = null;
        this.overrideNotificationTriggers = overrideNotificationTriggers;
        if (overrideNotificationTriggers === true) {
            this.createActionItemCustomNotificationRequest =
                new CreateActionItemCustomNotificationRequest(
                    createActionItemCustomNotificationDto!
                );
        }

        this.createActionItemNotificationTriggersRequests = [];
        createActionItemNotificationTriggerDto &&
            createActionItemNotificationTriggerDto.map((x) =>
                this.createActionItemNotificationTriggersRequests?.push(
                    new CreateActionItemNotificationTriggersRequest(x)
                )
            );

        this.organisationalNodeId = organisationalNodeId;
        this.nodeAssociationMode = nodeAssociationMode;
    }
}
