import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { TenantResponse } from "../../responses/tenants/tenant-response";

export interface TenantDto {
    tenantId: number;
    name: string;
    baseUrl: string;
}

export const toTenantDto = (tenant: TenantResponse): TenantDto => ({
    tenantId: tenant.tenantId,
    name: tenant.name,
    baseUrl: tenant.baseUrl,
});

export const toTenantDtos = (tenants: TenantResponse[]): TenantDto[] =>
    tenants.map((x) => toTenantDto(x));

export const toTenantDtosFromResponse = (
    response: Response<PaginationResponse<TenantResponse>>
): TenantDto[] => response.data.results.map((x) => toTenantDto(x));
