import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Common, NonWorkingDays } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { isMutationLoading } from "../../../core/utilities/responseStateHelper";
import { useGetNonWorkingDaySet } from "../../../domain/viewmodels/non-working-days/associated-non-working-day-set-node-viewmodel";
import { useEditNonWorkingDaySet } from "../../../domain/viewmodels/non-working-days/edit-non-working-day-set-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const EditNonWorkingDaySetContainer: React.FC = () => {
    const menu = useMenu();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: NonWorkingDays });

    const nonWorkingDaySetId = Number(useParams().nonWorkingDaySetId);

    const getNonWorkingDaySet = useGetNonWorkingDaySet(nonWorkingDaySetId);
    const name = getNonWorkingDaySet[0].data?.name;

    const editNonWorkingDaySet = useEditNonWorkingDaySet();

    useLoader(isMutationLoading(editNonWorkingDaySet), EditNonWorkingDaySetContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.NonWorkingDay);
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);

        editNonWorkingDaySet.mutate(
            {
                nonWorkingDaySetId: nonWorkingDaySetId,
                formData: formData,
            },
            {
                onSuccess: async () => navigate(-1),
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    return (
        <>
            <PageHeading>{t("NonWorkingDaySetEditTitle")}</PageHeading>
            <SectionVerticalSpace />
            <TextTitledPanel title={t("EditNonWorkingDaySet")}>
                <Form onSubmit={handleSubmit}>
                    <SbFormTextFieldGroup
                        name="name"
                        label={t("Name", { keyPrefix: Common })}
                        type="text"
                        maxLength={50}
                        defaultValue={name}
                        required
                    />

                    <EndAlignedDiv>
                        <SaveButton type="submit" />
                        <CancelButton onClick={() => navigate(-1)} />
                    </EndAlignedDiv>
                </Form>
            </TextTitledPanel>
        </>
    );
};

export default EditNonWorkingDaySetContainer;
