import { ReactElement, useEffect } from "react";
import { Form, OverlayTrigger, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaDownload } from "react-icons/fa";
import { VscChromeClose } from "react-icons/vsc";
import styled from "styled-components";
import { ActionItems } from "../../core/constants/translation-namespace";
import {
    NoDataStateDiv,
    SectionVerticalSpace,
    StyledBoldVariantText,
    StyledDisabledDiv,
    StyledPopover,
    StyledVariantText,
} from "../../core/theme/global-styles";
import { DownloadFile } from "../../core/utilities/FileDownload";
import { FileDownloadDto } from "../../domain/dtos/file-storage/file-download-dto";
import { FileDto } from "../../domain/dtos/file-storage/file-dto";

const StyledFormControl = styled(Form.Control)`
    width: 300px;
    background-color: ${(props) => props.theme.palette.purewhite};
    color: ${(props) => props.theme.palette.black};
`;

const StyledRow = styled(Row)`
    padding: ${(props) => props.theme.padding.sm};

    &:hover {
        background-color: ${(props) => props.theme.palette.info};
    }
`;

const StyledSbFileActionsButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-color: ${(props) => props.theme.palette.grey};
    border-radius: ${(props) => props.theme.padding.md};
    padding-left: ${(props) => props.theme.padding.md};
`;

const StyledDownloadSection = styled.div`
    height: 100%;
    padding: ${(props) => props.theme.padding.md};
    background-color: ${(props) => props.theme.palette.primary};
    color: ${(props) => props.theme.palette.white};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: ${(props) => props.theme.padding.md};
`;

const StyledDeleteSection = styled.div`
    height: 100%;
    padding: ${(props) => props.theme.padding.md};
    background-color: ${(props) => props.theme.palette.primary};
    color: ${(props) => props.theme.palette.danger};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 ${(props) => props.theme.padding.md} ${(props) => props.theme.padding.md} 0;
    cursor: pointer;
`;

export const StartAlignedDiv = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const FileGallery: React.FC<{
    files: FileDto[] | undefined;
    filesLabel?: string | undefined;
    onDownloadFile: (fileId: number) => void;
    fileDownloadData: FileDownloadDto | null | undefined;
    uploadLabel?: string | undefined;
    onUploadFile?: React.ChangeEventHandler<HTMLInputElement>;
    onRemoveFile?: (fileId: number) => void;
    disabled?: boolean;
    disabledToolTipText?: string;
    supportedFileExtensions?: string[];
}> = ({
    files,
    filesLabel,
    onDownloadFile,
    fileDownloadData,
    uploadLabel,
    onUploadFile,
    onRemoveFile,
    disabled,
    disabledToolTipText,
    supportedFileExtensions,
}) => {
    const { t } = useTranslation("translation");

    useEffect(() => {
        if (fileDownloadData != null) {
            DownloadFile(fileDownloadData);
        }
    }, [fileDownloadData?.fileName, fileDownloadData?.file]);

    const buildUploadLabel = (): ReactElement<HTMLDivElement> =>
        onUploadFile ? (
            <>
                {uploadLabel && (
                    <>
                        {disabled === true ? buildDisabledUploadLabel() : buildEnabledUploadLabel()}
                    </>
                )}
                <SectionVerticalSpace />
            </>
        ) : (
            <></>
        );

    const buildEnabledUploadLabel = (): ReactElement<HTMLParagraphElement> => (
        <>
            <StyledBoldVariantText $variant="secondary">{uploadLabel}</StyledBoldVariantText>
            <StyledFormControl name="file" type="file" onChange={onUploadFile} />
            {supportedFileExtensions && (
                <StyledVariantText $variant="secondary">
                    ({supportedFileExtensions.toString().replaceAll(",", " | ")})
                </StyledVariantText>
            )}
        </>
    );

    const buildDisabledUploadLabel = (): ReactElement<HTMLDivElement> => (
        <OverlayTrigger overlay={<StyledPopover>{disabledToolTipText}</StyledPopover>}>
            <span>
                <StyledDisabledDiv $disabled={disabled}>
                    {buildEnabledUploadLabel()}
                </StyledDisabledDiv>
            </span>
        </OverlayTrigger>
    );

    return (
        <>
            {buildUploadLabel()}

            {filesLabel && (
                <StyledBoldVariantText $variant="secondary">{filesLabel}</StyledBoldVariantText>
            )}

            {(!files || files.length === 0) && (
                <NoDataStateDiv>
                    {t("NoEvidenceAttached", { keyPrefix: ActionItems }) as string}
                </NoDataStateDiv>
            )}
            {files?.map((file, index) => (
                <StyledRow key={index}>
                    {/* TODO: To use SbButtonGroup */}
                    <StartAlignedDiv>
                        <StyledSbFileActionsButton>
                            <>
                                {file.fileName}
                                <StyledDownloadSection
                                    onClick={() => {
                                        onDownloadFile(file.fileId);
                                    }}
                                >
                                    <FaDownload />
                                </StyledDownloadSection>
                                {onRemoveFile && (
                                    <StyledDeleteSection
                                        onClick={() => {
                                            onRemoveFile(file.fileId);
                                        }}
                                    >
                                        <VscChromeClose />
                                    </StyledDeleteSection>
                                )}
                            </>
                        </StyledSbFileActionsButton>
                    </StartAlignedDiv>
                </StyledRow>
            ))}
        </>
    );
};
