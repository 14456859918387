import { useEffect } from "react";
import { useMenu } from "../../core/store/menu-context";
import { DrawerTitles, NavbarTitles } from "../../core/utilities/enums";

const ReportsContainer: React.FC<{ route: string }> = ({ route }) => {
    const menu = useMenu();

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Reports);
        menu.changeActiveDrawerItem(DrawerTitles.Reports);
    }, []);

    return (
        <>
            <h2>Placeholder text for route:</h2>
            <h2>{route}</h2>
        </>
    );
};

export default ReportsContainer;
