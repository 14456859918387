import { HierarchyAssignable } from "../../enums/hierarchy/HierarchyAssignable";
import { Response } from "../../responses/common/response-response";
import { HierarchyTypeDetailsResponse } from "../../responses/hierarchy/hierarchy-type-details-response";

export interface HierarchyTypeDetailsDto {
    name: string;
    hierarchyAssignables: HierarchyAssignable[];
}

export const createHierarchyTypeDetailsDto = (
    response: Response<HierarchyTypeDetailsResponse>
): HierarchyTypeDetailsDto => ({
    ...response.data,
});
