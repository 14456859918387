import styled from "styled-components";

const StyledNonProdBannerDiv = styled.header`
    top: 0;
    position: sticky;
    z-index: 100;
    padding: 10px 0;
    background-color: ${(props) => props.theme.palette.transparentWarning};
    text-align: center;
    font-weight: bold;
    font-size: ${(props) => props.theme.font.xl};
`;

const NonProdBanner: React.FC = () => {
    const environment = process.env.REACT_APP_ENV;
    const isProductionEnvironment = environment?.toUpperCase() === "PROD";

    return (
        <>
            {!isProductionEnvironment && (
                <StyledNonProdBannerDiv>Non-Production Environment</StyledNonProdBannerDiv>
            )}
        </>
    );
};

export default NonProdBanner;
