import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import { Common } from "../../../core/constants/translation-namespace";
import QuestionAnswerInstanceDto from "../../../domain/dtos/answer-capture/question-answer-instance-dto";
import AnswerTypeDto from "../../../domain/dtos/answer-types/answer-type-dto";
import PredefinedAnswerDto from "../../../domain/dtos/predefined-answers/predefined-answer-dto";
import AnswerRenderMode from "../../../domain/enums/answer-types/answer-render-mode";
import { useAnswerCapture } from "../../../domain/stores/answer-capture-context";
import { CreateTooltipSelect } from "../../atoms/input/SbSelect";
import SbButtonGroup, { ButtonGroupItemProps } from "../../atoms/SbButtonGroup";

export const overflowSelectStyle = {
    menuList: ({ ...css }) => ({
        ...css,
        overflow: "visible",
    }),
    menu: ({ ...css }) => ({
        ...css,
        width: "max-content",
        minWidth: "100%",
    }),
};

const AnswerCaptureMutuallyExclusiveInput: React.FC<{
    answerTypeDto: AnswerTypeDto;
    questionAnswerInstanceDto: QuestionAnswerInstanceDto;
    onMutuallyExclusiveAnswerClicked?: (_: PredefinedAnswerDto) => void;
}> = ({ answerTypeDto, questionAnswerInstanceDto, onMutuallyExclusiveAnswerClicked }) => {
    const [savedPredefinedAnswerDto, setSavedPredefinedAnswerDto] = useState<PredefinedAnswerDto>();

    const { t } = useTranslation("translation");
    const answerCapture = useAnswerCapture();

    useEffect(() => {
        const savedPredefinedAnswerId =
            answerCapture.getQuestionAnswer(questionAnswerInstanceDto)?.predefinedAnswerId;

        if (savedPredefinedAnswerId) {
            const predefinedAnswerDto = answerTypeDto.predefinedAnswerDtos.find(
                (x) => x.predefinedAnswerId === savedPredefinedAnswerId
            );

            if (predefinedAnswerDto) {
                setSavedPredefinedAnswerDto(predefinedAnswerDto);

                onMutuallyExclusiveAnswerClicked &&
                    onMutuallyExclusiveAnswerClicked(predefinedAnswerDto);
            }
        }
    }, [questionAnswerInstanceDto.getKey()]);

    const onPredefinedAnswerChanged = (
        predefinedAnswerDto: SingleValue<PredefinedAnswerDto>
    ): void => {
        answerCapture.updateQuestionAnswerDtosForMutuallyExclusiveAnswer(
            questionAnswerInstanceDto,
            predefinedAnswerDto!.predefinedAnswerId
        );

        onMutuallyExclusiveAnswerClicked && onMutuallyExclusiveAnswerClicked(predefinedAnswerDto!);
    };

    switch (answerTypeDto.answerRenderMode) {
        case AnswerRenderMode.Default:
            return (
                <SbButtonGroup
                    values={answerTypeDto.predefinedAnswerDtos.map(
                        (x) =>
                            new ButtonGroupItemProps(
                                x.predefinedAnswerTextDto.textValue,
                                x,
                                x.predefinedAnswerId ===
                                    savedPredefinedAnswerDto?.predefinedAnswerId,
                                onPredefinedAnswerChanged
                            )
                    )}
                />
            );
        case AnswerRenderMode.DropDown:
            return (
                <CreateTooltipSelect
                    name={"answerDropDown"}
                    placeholderText={t("PleaseSelect", { keyPrefix: Common }) as string}
                    searchable
                    clearable={false}
                    items={answerTypeDto.predefinedAnswerDtos}
                    itemLabel={(option: PredefinedAnswerDto): string =>
                        option.predefinedAnswerTextDto.textValue
                    }
                    itemTooltip={(option: PredefinedAnswerDto): string =>
                        option.tooltipDescription ? option.tooltipDescription : ""
                    }
                    defaultSelectedItem={savedPredefinedAnswerDto}
                    onChange={onPredefinedAnswerChanged}
                    styles={overflowSelectStyle}
                />
            );
        default:
            return <></>;
    }
};

export default AnswerCaptureMutuallyExclusiveInput;
