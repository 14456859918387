import { useEffect } from "react";
import { useLoaderProvider } from "../store/loader-context";

const useLoader = (isLoading: boolean, component: React.FC<any>): void => {
    const loaderProvider = useLoaderProvider();

    useEffect(() => {
        loaderProvider.updateIsLoading(isLoading, component);

        return () => {
            loaderProvider.updateIsLoading(false, component);
        };
    }, [isLoading]);
};

export default useLoader;
