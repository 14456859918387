import QuestionCaptureSetResponse from "../../responses/answer-capture/question-capture-set-response";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import PaginatedDto, { createPaginatedDtoFromResponse } from "../common/paginated-dto";
import NestedQuestionAnswerDto from "./nested-question-answer-dto";
import QuestionAnswerDto from "./question-answer-dto";
import QuestionCaptureSetDto from "./question-capture-set-dto";

export default class FilterQuestionAnswersDto {
    paginatedQuestionCaptureSetDto: PaginatedDto<QuestionCaptureSetDto>;
    savedQuestionCaptureDtos: QuestionAnswerDto[];
    savedNestedQuestionCaptureDtos: NestedQuestionAnswerDto[];

    public constructor(
        paginatedQuestionCaptureSetDto: PaginatedDto<QuestionCaptureSetDto>,
        savedQuestionCaptureDtos: QuestionAnswerDto[],
        savedNestedQuestionCaptureDtos: NestedQuestionAnswerDto[]
    ) {
        this.paginatedQuestionCaptureSetDto = paginatedQuestionCaptureSetDto;
        this.savedQuestionCaptureDtos = savedQuestionCaptureDtos;
        this.savedNestedQuestionCaptureDtos = savedNestedQuestionCaptureDtos;
    }

    public static constructFromPaginatedQuestionCaptureSetResponse = (
        response: Response<PaginationResponse<QuestionCaptureSetResponse>>
    ): FilterQuestionAnswersDto => {
        const responseData = response.data;

        const questionCaptureResponses = responseData.results.flatMap(
            (x) => x.questionCaptureResponses
        );

        return new FilterQuestionAnswersDto(
            createPaginatedDtoFromResponse(
                responseData,
                QuestionCaptureSetDto.constructFromResponses(responseData.results)
            ),
            QuestionAnswerDto.constructFromQuestionCaptureResponses(questionCaptureResponses), // TODO: Rename and change the input data provided for the these object creations
            NestedQuestionAnswerDto.constructFromQuestionCaptureResponses(questionCaptureResponses)
        );
    };
}
