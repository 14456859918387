import {
    buildFilterReviewReportPdfParameters,
    ReviewReportPdfRequest,
} from "../../../domain/requests/review-reports/review-report-pdf-request";
import { Response } from "../../../domain/responses/common/response-response";
import BaseFileDetailsResponse from "../../../domain/responses/file-storage/base-file-details-response";
import client from "../../http-client";

const route = "reviewreportpdf";

export const getReviewReportPdf = async (
    prefixUrl: string,
    request: ReviewReportPdfRequest
): Promise<Response<BaseFileDetailsResponse>> => {
    const searchParams = buildFilterReviewReportPdfParameters(request);

    return await client
        .get(`${route}/review-report-pdf`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};
