import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import { getAuditChangeLogs } from "../../../data/services/audits/audits-service";
import { FilterAuditsChangeLogDto } from "../../dtos/audits/filter-audits-change-log-dto";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { createFilterAuditsChangeLogRequestFromDto } from "../../requests/audits/filter-audits-change-log-request";
import { AuditChangelogResponse } from "../../responses/audits/audit-change-log-response";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";

export const useGetAuditChangeLogs = (
    dto: FilterAuditsChangeLogDto
): UseQueryResult<PaginatedTableDto<number>, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getAuditChangeLogs", dto],
        () => getAuditChangeLogs(url.baseUrl, createFilterAuditsChangeLogRequestFromDto(dto)),
        {
            keepPreviousData: true,
            select: transformToDataTableRows,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const transformToDataTableRows = (
    response: Response<PaginationResponse<AuditChangelogResponse>>
): PaginatedTableDto<number> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((audit) => ({
        metadata: audit.changelogId,
        columns: [
            { value: audit.date.toString(), type: DataTableColumnTypes.Text },
            { value: audit.time.toString(), type: DataTableColumnTypes.Text },
            {
                value: audit.actionType.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: audit.tableName.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: audit.entityKey.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: audit.username.toString(),
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
