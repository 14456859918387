import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { createReferentialLink } from "../../../data/services/referential-link-types/referential-link-service";
import CreateReferentialLinkDto from "../../dtos/referential-links/create-referential-link-dto";
import { createCreateReferentialLinkRequest } from "../../requests/referential-link-types/create-referential-link-request";
import { Response } from "../../responses/common/response-response";

export const useCreateReferentialLink = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    CreateReferentialLinkDto
> => {
    const url = useUrl();

    return useMutation((dto: CreateReferentialLinkDto) => {
        const request = createCreateReferentialLinkRequest(
            dto.referentialLinkTypeId,
            dto.referentialLinkValue
        );

        return createReferentialLink(url.baseUrl, request);
    });
};
