import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import styled from "styled-components";
import { Roles, Users } from "../../../core/constants/translation-namespace";
import {
    ContentContainer,
    EndAlignedDiv,
    SectionContainer,
} from "../../../core/theme/global-styles";
import { RoleDto } from "../../../domain/dtos/users/role-dto";
import { splitCamelCase } from "../../../domain/helpers/split-camel-case/spilt-camel-case";
import { ResetButton, SearchButton } from "../../atoms/SbButton";
import { FilterInput } from "../../molecules/FilterInput";
import { FilterSelect } from "../../molecules/FilterSelect";
import { FilterNumberInput } from "../../molecules/input/FilterNumberInput";

const StyledRow = styled(Row)`
    padding-bottom: ${(props) => props.theme.padding.md};
`;

export const UsersInRoleFilter: React.FC<{
    userId: string | undefined;
    changeUserId: (userId: string) => void;
    emailAddress: string | null;
    changeEmailAddress: (emailAddress: string) => void;
    role: RoleDto | null;
    roles: RoleDto[] | null;
    changeRole: (role: SingleValue<RoleDto>) => void;
    search: () => void;
    resetFilter: () => void;
}> = ({
    userId,
    changeUserId,
    emailAddress,
    changeEmailAddress,
    role,
    roles,
    changeRole,
    search,
    resetFilter,
}) => {
    const { t } = useTranslation("translation", { keyPrefix: Users });

    return (
        <ContentContainer>
            <SectionContainer>
                <StyledRow>
                    <Col>
                        <FilterNumberInput
                            title={t("UserId")}
                            name={"UserId"}
                            onChangeEventHandler={changeUserId}
                            value={userId ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("EmailAddress")}
                            name={"emailAddress"}
                            type={"text"}
                            onChangeEventHandler={changeEmailAddress}
                            value={emailAddress ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterSelect
                            label={t("Role", { keyPrefix: Roles })}
                            name={"role"}
                            searchable
                            clearable={false}
                            items={roles ?? []}
                            itemDisplayText={(option: RoleDto) => splitCamelCase(option.name)}
                            onChange={changeRole}
                            value={role}
                        />
                    </Col>
                </StyledRow>
            </SectionContainer>
            <EndAlignedDiv>
                <SearchButton onClick={search} />
                <ResetButton onClick={resetFilter} />
            </EndAlignedDiv>
        </ContentContainer>
    );
};
