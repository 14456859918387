import {
    buildPaginationSearchParameters,
    PaginationRequest,
} from "../../../domain/requests/common/pagination-request";
import {
    buildFilterSearchParameters,
    FilterRoleUsersRequest,
} from "../../../domain/requests/users/filter-role-users-request";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import {
    RoleResponse,
    RoleUsersResponse,
    RoleWithUserCountResponse,
} from "../../../domain/responses/users/role-response";
import client from "../../http-client";

const route = "roles";

export const getRoles = async (
    prefixUrl: string,
    request: PaginationRequest
): Promise<Response<PaginationResponse<RoleResponse>>> => {
    const searchParams = buildPaginationSearchParameters(request);

    return await client
        .get(`${route}/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getRolesWithUserCount = async (
    prefixUrl: string
): Promise<Response<RoleWithUserCountResponse[]>> =>
    await client
        .get(`${route}/roles`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const getRole = async (prefixUrl: string, roleId: number): Promise<Response<RoleResponse>> =>
    await client
        .get(`${route}/${roleId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const getRoleUsers = async (
    prefixUrl: string,
    request: FilterRoleUsersRequest
): Promise<Response<PaginationResponse<RoleUsersResponse>>> => {
    const searchParams = buildFilterSearchParameters(request);

    return await client
        .get(`${route}/filter-role-users`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};
