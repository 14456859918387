import { ReferentialLinkResponse } from "../../responses/referential-links/referential-link-response";

export default class NodeReferentialLinksDto {
    referentialLinkTypeId: number;
    referentialLinkTypeName: string;
    referentialLinkId: number;
    referentialLinkValue: string;

    public constructor(response: ReferentialLinkResponse) {
        const { referentialLinkTypeId, referentialLinkTypeName, referentialLinkId, value } =
            response;

        this.referentialLinkTypeId = referentialLinkTypeId;
        this.referentialLinkTypeName = referentialLinkTypeName;
        this.referentialLinkId = referentialLinkId;
        this.referentialLinkValue = value;
    }

    public static toNodeReferentialLinksDto = (
        responses: ReferentialLinkResponse[]
    ): NodeReferentialLinksDto[] => responses.map((x) => new NodeReferentialLinksDto(x));
}
