import { ReactElement, useState } from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import styled from "styled-components";

const StyledToggleButton = styled(ToggleButton)`
    --bs-btn-active-bg: ${(props) => props.theme.palette.primary};
    --bs-btn-active-border-color: transparent;
    background-color: ${(props) => props.theme.palette.secondary};
    border-color: transparent;

    &:hover {
        background-color: ${(props) => props.theme.palette.primaryVariant} !important;
        border-color: transparent !important;
    }
`;

interface ButtonGroupProps<TType> {
    values: ButtonGroupItemProps<TType>[];
}

export class ButtonGroupItemProps<TType> {
    label: string;
    value: TType;
    defaultSelected?: boolean;
    onButtonClicked?: (item: TType) => void;

    constructor(
        label: string,
        value: TType,
        defaultSelected?: boolean,
        onButtonClicked?: (item: TType) => void
    ) {
        this.label = label;
        this.value = value;
        this.defaultSelected = defaultSelected;
        this.onButtonClicked = onButtonClicked;
    }
}

const SbButtonGroup = <TType,>({ values }: ButtonGroupProps<TType>): ReactElement => {
    const [buttonGroupValue, setButtonGroupValue] = useState<TType | null>();

    const onClick = (event: any, item: ButtonGroupItemProps<TType>): void => {
        event?.preventDefault();

        const itemValue = item.value;

        setButtonGroupValue(itemValue);
        item.onButtonClicked && item.onButtonClicked(itemValue);
    };

    return (
        <ButtonGroup>
            {values.map((x, index) => (
                <StyledToggleButton
                    key={index}
                    id={`radio-${index}`}
                    type="radio"
                    value={""}
                    checked={buttonGroupValue ? x.value === buttonGroupValue : x.defaultSelected}
                    onClick={(event) => onClick(event, x)}
                >
                    {x.label}
                </StyledToggleButton>
            ))}
        </ButtonGroup>
    );
};

export default SbButtonGroup;
