import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Roles, Users } from "../../../core/constants/translation-namespace";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { roleUserColumnNames } from "../../../core/utilities/dataTableColumns";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
} from "../../../core/utilities/responseStateHelper";
import { defaultPaginationDto, PaginationDto } from "../../../domain/dtos/common/pagination-dto";
import { RoleUserDto } from "../../../domain/dtos/users/role-dto";
import { useGetRoleDetails } from "../../../domain/viewmodels/users/view-role-viewmodel";
import { DataTable } from "../../organisms/DataTable";

const RoleAdminDetailContainer = (): JSX.Element => {
    const [paginationDto, setPaginationDto] = useState<PaginationDto>(defaultPaginationDto);

    const menu = useMenu();
    const navigate = useNavigate();
    const { t } = useTranslation("translation", { keyPrefix: Roles });
    const roleId = Number(useParams().roleId);

    const getRole = useGetRoleDetails(roleId, paginationDto);

    useLoader(areQueriesLoading(getRole), RoleAdminDetailContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.UserManagement, AccordionTitles.Roles);
    }, []);

    const viewRoleUser = (metadata: RoleUserDto): void => {
        navigate(`${getPath(AccordionTitles.Users)}/${metadata.userId}`);
    };

    return (
        <>
            <PageHeading>{t("RoleDetailsTitle")}</PageHeading>
            <SectionVerticalSpace />
            {areQueriesSuccessful(getRole) && (
                <>
                    <ContentContainer>
                        <Row>
                            <Col md="2">
                                <DetailsLabel>{t("RoleName")}</DetailsLabel>
                            </Col>
                            <Col>
                                <DetailsValue>{getRole[0]?.data?.name ?? "-"}</DetailsValue>
                            </Col>
                        </Row>
                    </ContentContainer>
                    <SectionVerticalSpace />

                    <DataTable
                        columns={roleUserColumnNames}
                        keyPrefix={Users}
                        rows={getRole[1]?.data?.rows}
                        viewItem={viewRoleUser}
                        totalItems={getRole[1].data!.recordCount}
                        paginationDto={paginationDto}
                        setPaginationDto={setPaginationDto}
                    />
                </>
            )}
        </>
    );
};

export default RoleAdminDetailContainer;
