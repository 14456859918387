import { ReviewTypeFieldResponse } from "../../responses/review-reports/review-type-field-response";
import { ReviewTypeDto } from "./review-type-dto";

export class ReviewTypeFieldDto {
    reviewTypeFieldsId: number;
    fieldName: string;
    inputType: string;
    reviewType: ReviewTypeDto;

    public constructor(reviewTypeFieldResponse: ReviewTypeFieldResponse) {
        this.reviewTypeFieldsId = reviewTypeFieldResponse.reviewTypeFieldsId;
        this.fieldName = reviewTypeFieldResponse.fieldName;
        this.inputType = reviewTypeFieldResponse.inputType;
        this.reviewType = reviewTypeFieldResponse.reviewType;
    }
}
