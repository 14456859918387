export interface UserNodeAssociationRequest {
    nodeId: number;
    userId: number;
}

export const createUserNodeAssociationRequest = (
    nodeId: number,
    userId: number
): UserNodeAssociationRequest => ({
    nodeId: nodeId,
    userId: userId,
});

export const buildUserNodeAssociationSearchParameters = (
    request: UserNodeAssociationRequest
): URLSearchParams => {
    const searchParams = new URLSearchParams();

    searchParams.set("nodeId", request.nodeId.toString());
    searchParams.set("userId", request.userId.toString());

    return searchParams;
};
