import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import { Audits, Common } from "../../../core/constants/translation-namespace";
import {
    ContentContainer,
    EndAlignedDiv,
    SectionContainer,
} from "../../../core/theme/global-styles";
import { bitDropdownItems } from "../../../core/utilities/filterDropdownItems";
import {
    createReactSelectDto,
    ReactSelectDto,
    valuesToReactSelectDtos,
} from "../../../domain/dtos/common/react-select-dto";
import { ResetButton, SearchButton } from "../../atoms/SbButton";
import { FilterInput } from "../../molecules/FilterInput";
import { FilterSelect } from "../../molecules/FilterSelect";

export const SecurityAuditFilter: React.FC<{
    ipAddress: string | null;
    source: string | null;
    startDate: string | null;
    endDate: string | null;
    userName: string | null;
    changeIpAddress: (ipAddress: string) => void;
    changeSource: (source: string) => void;
    changeStartDate: (startDate: string) => void;
    changeEndDate: (endDate: string) => void;
    changeUserName: (userName: string) => void;
    wasSuccessful: string | null;
    changeWasSuccessful: (option: SingleValue<ReactSelectDto<string>>) => void;
    search: () => void;
    resetFilter: () => void;
}> = ({
    ipAddress,
    changeIpAddress,
    source,
    changeSource,
    startDate,
    changeStartDate,
    endDate,
    changeEndDate,
    userName,
    changeUserName,
    wasSuccessful,
    changeWasSuccessful,
    search,
    resetFilter,
}) => {
    const { t } = useTranslation("translation", { keyPrefix: Audits });
    return (
        <ContentContainer>
            <SectionContainer>
                <Row>
                    <Col>
                        <FilterInput
                            title={t("Username")}
                            name={"userName"}
                            type={"text"}
                            onChangeEventHandler={changeUserName}
                            value={userName ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("DateFrom")}
                            name={"dateFrom"}
                            type={"date"}
                            onChangeEventHandler={changeStartDate}
                            value={startDate ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("DateTo")}
                            name={"dateTo"}
                            type={"date"}
                            onChangeEventHandler={changeEndDate}
                            value={endDate ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("EventType")}
                            name={"source"}
                            type={"text"}
                            onChangeEventHandler={changeSource}
                            value={source ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("IPAddress")}
                            name={"ipAddress"}
                            type={"text"}
                            onChangeEventHandler={changeIpAddress}
                            value={ipAddress ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterSelect
                            name={"wasSuccessful"}
                            label={t("Status")!}
                            placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                            searchable
                            clearable
                            items={valuesToReactSelectDtos(bitDropdownItems)}
                            itemDisplayText={(option: ReactSelectDto<string>) =>
                                t(option.value, { keyPrefix: Common })
                            }
                            onChange={changeWasSuccessful}
                            value={
                                wasSuccessful != null
                                    ? createReactSelectDto(wasSuccessful, wasSuccessful)
                                    : null
                            }
                        />
                    </Col>
                </Row>
            </SectionContainer>
            <EndAlignedDiv>
                <SearchButton onClick={search} />
                <ResetButton onClick={resetFilter} />
            </EndAlignedDiv>
        </ContentContainer>
    );
};
