import { HTTPError } from "ky";
import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaSave } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReviewChecklistEvent } from "../../../core/constants/application-insights-events";
import { ChecklistQuestions, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { isMutationLoading } from "../../../core/utilities/responseStateHelper";
import { useReviewChecklist } from "../../../domain/viewmodels/checklists/review-checklist-viewmodel";
import { SbButton } from "../../atoms/SbButton";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import {
    ActionConfirmationFragment,
    AlertProps,
    createPrimaryAlertProps,
} from "../../organisms/ActionConfirmationFragment";

export interface ReviewConfirmationProps {
    name: string;
    description: string;
}

const ReviewChecklistContainer: React.FC = () => {
    const menu = useMenu();
    const location = useLocation();
    const navigate = useNavigate();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const checklistId = Number(useParams().checklistId);
    const { name, description } = location.state as ReviewConfirmationProps; // TODO: Fetching Checklist data on this page vs passing it through from the previous page

    const reviewChecklist = useReviewChecklist();

    useLoader(isMutationLoading(reviewChecklist), ReviewChecklistContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Checklists);
    }, []);

    const buildReviewButton = (): ReactElement<typeof Button> => (
        <SbButton
            variant="primary"
            type="button"
            label={t("MarkAsReviewed", { keyPrefix: Common })}
            icon={FaSave}
            onClick={() => handleReviewChecklist(checklistId)}
        />
    );

    const handleReviewChecklist = (checklistId: number): void => {
        reviewChecklist.mutate(checklistId, {
            onSuccess: async () => {
                navigate(-1);
                trackAppInsightsEvent(auth.email, window.location.href, ReviewChecklistEvent);
            },
            onError: (error: HTTPError) => {
                trackAppInsightsException(
                    auth.email,
                    window.location.href,
                    ReviewChecklistEvent,
                    error
                );
                errorResponseToDisplayHandler(error);
            },
        });
    };

    const buildConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow(t("Name"), name),
        new TextConfirmationRow("Description", description),
    ];

    const buildPrimaryAlert = (): AlertProps =>
        createPrimaryAlertProps([t("ReviewChecklistAlertText")]);

    return (
        <ActionConfirmationFragment
            pageHeading={t("Review")}
            panelTitle={t("Review")}
            alert={buildPrimaryAlert()}
            rows={buildConfirmationRows()}
            primaryButton={buildReviewButton()}
        />
    );
};

export default ReviewChecklistContainer;
