import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import { Common, Users } from "../../../core/constants/translation-namespace";
import {
    ContentContainer,
    EndAlignedDiv,
    SectionContainer,
} from "../../../core/theme/global-styles";
import { bitDropdownItems } from "../../../core/utilities/filterDropdownItems";
import {
    createReactSelectDto,
    ReactSelectDto,
    valuesToReactSelectDtos,
} from "../../../domain/dtos/common/react-select-dto";
import { ResetButton, SearchButton } from "../../atoms/SbButton";
import { FilterInput } from "../../molecules/FilterInput";
import { FilterSelect } from "../../molecules/FilterSelect";

export const UsersFilter: React.FC<{
    lastName: string | null;
    changeLastName: (lastName: string) => void;
    firstName: string | null;
    changeFirstName: (firstName: string) => void;
    emailAddress: string | null;
    changeEmailAddress: (emailAddress: string) => void;
    isDeleted: boolean | null;
    changeIsDeleted: (option: SingleValue<ReactSelectDto<string>>) => void;
    isLockedOut: boolean | null;
    changeIsLockedOut: (option: SingleValue<ReactSelectDto<string>>) => void;
    search: () => void;
    resetFilter: () => void;
}> = ({
    lastName,
    changeLastName,
    firstName,
    changeFirstName,
    emailAddress,
    changeEmailAddress,
    isDeleted,
    changeIsDeleted,
    isLockedOut,
    changeIsLockedOut,
    search,
    resetFilter,
}) => {
    const { t } = useTranslation("translation", { keyPrefix: Users });

    const createBitDropdownItem = (booleanValue: string): ReactSelectDto<string> => {
        return createReactSelectDto(toPascalCase(booleanValue), toPascalCase(booleanValue));
    };

    const toPascalCase = (value: string): string => {
        return value.substring(0, 1).toUpperCase() + value.substring(1);
    };

    return (
        <ContentContainer>
            <SectionContainer>
                <Row>
                    <Col>
                        <FilterInput
                            title={t("LastName")}
                            name={"lastName"}
                            type={"text"}
                            onChangeEventHandler={changeLastName}
                            value={lastName ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("FirstName")}
                            name={"firstName"}
                            type={"text"}
                            onChangeEventHandler={changeFirstName}
                            value={firstName ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("EmailAddress")}
                            name={"emailAddress"}
                            type={"text"}
                            onChangeEventHandler={changeEmailAddress}
                            value={emailAddress ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterSelect
                            name={"isLockedOut"}
                            label={t("IsLockedOut")!}
                            placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                            searchable
                            clearable
                            items={valuesToReactSelectDtos(bitDropdownItems)}
                            itemDisplayText={(option: ReactSelectDto<string>) =>
                                t(option.value, { keyPrefix: Common })
                            }
                            onChange={changeIsLockedOut}
                            value={
                                isLockedOut != null
                                    ? createBitDropdownItem(isLockedOut.toString())
                                    : null
                            }
                        />
                    </Col>
                    <Col>
                        <FilterSelect
                            name={"isDeleted"}
                            label={t("IsDeleted", { keyPrefix: Common })!}
                            placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                            searchable
                            clearable
                            items={valuesToReactSelectDtos(bitDropdownItems)}
                            itemDisplayText={(option: ReactSelectDto<string>) =>
                                t(option.value, { keyPrefix: Common })
                            }
                            onChange={changeIsDeleted}
                            value={
                                isDeleted != null
                                    ? createBitDropdownItem(isDeleted.toString())
                                    : null
                            }
                        />
                    </Col>
                </Row>
            </SectionContainer>
            <EndAlignedDiv>
                <SearchButton onClick={search} />
                <ResetButton onClick={resetFilter} />
            </EndAlignedDiv>
        </ContentContainer>
    );
};
