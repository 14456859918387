import { useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SingleValue } from "react-select";
import {
    Common,
    Languages,
    Profile,
    UserManagement,
    Users,
} from "../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../core/hooks/loaderManager";
import { useAuth } from "../../core/store/auth-context";
import { useMenu } from "../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../core/store/toast-context";
import { EndAlignedDiv, PageHeading, SectionVerticalSpace } from "../../core/theme/global-styles";
import { NavbarTitles } from "../../core/utilities/enums";
import { Feature, FeatureOptions } from "../../core/utilities/Features";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isMutationLoading,
    isQuerySuccessful,
} from "../../core/utilities/responseStateHelper";
import { RefreshTokenRequestDto } from "../../domain/dtos/auth/refresh-token-request-dto";
import { LanguageDto } from "../../domain/dtos/language/language-dto";
import { EditProfileDto } from "../../domain/dtos/profile/edit-profile-dto";
import TimeZoneDetailsDto from "../../domain/dtos/timezone/time-zone-details-dto";
import { AuthTokenResponse } from "../../domain/responses/account/token-response";
import { Response } from "../../domain/responses/common/response-response";
import {
    useHandleAuthTokenData,
    useRefreshTokens,
} from "../../domain/viewmodels/account/authentication-viewmodel";
import { useEditProfile } from "../../domain/viewmodels/profile/edit-profile-viewmodel";
import { useGetProfile } from "../../domain/viewmodels/profile/view-profile-viewmodel";
import { CheckboxFieldProps } from "../atoms/input/SbCheckField";
import { CancelButton, SaveButton } from "../atoms/SbButton";
import SbFormCheckFieldGroup from "../molecules/input/SbFormCheckFieldGroup";
import { SbFormSelectFieldGroup } from "../molecules/input/SbFormSelectFieldGroup";
import SbFormTextFieldGroup from "../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../molecules/SbPanel";

const ProfileContainer: React.FC = () => {
    const navigate = useNavigate();
    const menu = useMenu();
    const toast = useToast();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { userId, isAdmin } = useAuth();

    const languageCulture = useRef<string | null>(null);
    const timeZoneCountry = useRef<string | null>(null);
    const timeZoneNodeTypeValueId = useRef<number | null>(null);
    const homeScreenPreference = useRef<string | null>(null);

    const profileDetails = useGetProfile(userId!, isAdmin);
    const editProfile = useEditProfile();

    const refreshTokens = useRefreshTokens();
    const handleAuthTokenData = useHandleAuthTokenData();

    const languagesResponseData = profileDetails[0].data;
    const userDetailsResponseData = profileDetails[1].data;
    const userClaimResponseData = profileDetails[2].data;
    const timeZonesResponseData = profileDetails[3].data;
    const homePreferenceResponseData = profileDetails[4].data;

    const { t, i18n } = useTranslation("translation");

    useLoader(
        areQueriesLoading(profileDetails) || isMutationLoading(editProfile),
        ProfileContainer
    );
    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        const editProfileDto = new EditProfileDto(
            userId!,
            formData.get("firstName")! as string,
            formData.get("lastName")! as string,
            timeZoneCountry.current ?? userClaimResponseData?.windowsTimeZoneId!,
            languageCulture.current ?? userClaimResponseData?.languageCulture!,
            homeScreenPreference.current ?? userClaimResponseData?.homePreference!,
            userDetailsResponseData!.emailAddress,
            timeZoneNodeTypeValueId.current ??
                getDefaultTimeZoneNodeTypeValueId()?.timeZoneNodeTypeValueId ??
                null
        );

        editProfile.mutate(editProfileDto, {
            onSuccess: async () => {
                const refreshToken = JSON.parse(localStorage["refreshToken"]);
                const refreshTokenDto = new RefreshTokenRequestDto(refreshToken.refreshToken);

                refreshTokens.mutate(refreshTokenDto, {
                    onSuccess: async (response: Response<AuthTokenResponse>) => {
                        handleAuthTokenData(response.data);

                        toast.addToast(
                            createSuccessToastProps([
                                t("UpdateDetailsSuccessMessage", { keyPrefix: UserManagement }),
                            ])
                        );

                        i18n.changeLanguage(
                            languageCulture.current ?? userClaimResponseData?.languageCulture!
                        );

                        profileDetails[0].refetch;
                        profileDetails[1].refetch;
                        profileDetails[2].refetch;
                        !isAdmin && profileDetails[3].refetch;
                        profileDetails[4].refetch;
                    },
                });
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    const getHomeScreenPreferences = (): CheckboxFieldProps[] => {
        let checkboxFieldProps: CheckboxFieldProps[] = [];
        homePreferenceResponseData?.map((x) => {
            checkboxFieldProps.push({
                name: "homeScreenPreference",
                label:
                    x.name == Feature[Feature.CODAC]
                        ? FeatureOptions.find((x) => x.value === Feature.CODAC)!.label
                        : x.name,
                defaultSelected: x.name == userClaimResponseData?.homePreference,
                onChangeHandler: () => {
                    homeScreenPreference.current = x.name;
                },
            });
        });

        return checkboxFieldProps;
    };

    const getDefaultTimeZoneNodeTypeValueId = (): TimeZoneDetailsDto | null | undefined => {
        return userDetailsResponseData?.timeZoneNodeTypeValueId
            ? timeZonesResponseData?.filter(
                  (x) =>
                      x.timeZoneNodeTypeValueId === userDetailsResponseData?.timeZoneNodeTypeValueId
              )[0]
            : null;
    };

    return (
        <>
            {areQueriesSuccessful([
                profileDetails[0],
                profileDetails[1],
                profileDetails[2],
                profileDetails[4],
            ]) && (
                <>
                    <PageHeading>
                        {t("ManageYourSystemProfile", { keyPrefix: Profile })}
                    </PageHeading>
                    <SectionVerticalSpace />
                    <TextTitledPanel title={t("EditYourProfile", { keyPrefix: Profile })}>
                        <Form onSubmit={handleSubmit}>
                            <SbFormTextFieldGroup
                                name={"firstName"}
                                label={t("FirstName", { keyPrefix: Users })}
                                type={"text"}
                                required
                                maxLength={50}
                                defaultValue={userDetailsResponseData!.firstName}
                            />

                            <SbFormTextFieldGroup
                                name={"lastName"}
                                label={t("LastName", { keyPrefix: Users })}
                                type={"text"}
                                required
                                maxLength={50}
                                defaultValue={userDetailsResponseData!.lastName}
                            />
                            {!isAdmin && isQuerySuccessful(profileDetails[3]) && (
                                <SbFormSelectFieldGroup
                                    name={"timeZoneCountry"}
                                    label={t("TimeZoneCountry", { keyPrefix: Profile })}
                                    placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                                    searchable
                                    clearable={false}
                                    required
                                    items={timeZonesResponseData!}
                                    itemDisplayText={
                                        (option: TimeZoneDetailsDto) => option.displayName //TODO: Add translations for dynamic data
                                    }
                                    onChange={(option: SingleValue<TimeZoneDetailsDto>) => {
                                        timeZoneCountry.current = option!.timeZoneId;
                                        timeZoneNodeTypeValueId.current =
                                            option!.timeZoneNodeTypeValueId;
                                    }}
                                    defaultSelectedItem={getDefaultTimeZoneNodeTypeValueId()}
                                />
                            )}

                            <SbFormSelectFieldGroup
                                name={"language"}
                                label={t("Language", { keyPrefix: Languages })}
                                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                                searchable
                                clearable={false}
                                required
                                items={languagesResponseData!}
                                itemDisplayText={(option: LanguageDto) => option.name} //TODO: Add translations for dynamic data
                                onChange={(option: SingleValue<LanguageDto>) => {
                                    languageCulture.current = option!.culture;
                                }}
                                defaultSelectedItem={
                                    userClaimResponseData?.languageCulture
                                        ? languagesResponseData!.filter(
                                              (x) =>
                                                  x.culture ===
                                                  userClaimResponseData?.languageCulture
                                          )[0]
                                        : null
                                }
                            />

                            <SbFormTextFieldGroup
                                name={"email"}
                                label={t("Email", { keyPrefix: UserManagement })}
                                type={"text"}
                                disabled
                                defaultValue={userDetailsResponseData!.emailAddress}
                            />

                            <SbFormCheckFieldGroup
                                fieldLabel={t("HomeScreenPreference", {
                                    keyPrefix: Common,
                                })}
                                type="radio"
                                values={getHomeScreenPreferences()}
                            />

                            <EndAlignedDiv>
                                <SaveButton type="submit" />
                                <CancelButton onClick={() => navigate(-1)} />
                            </EndAlignedDiv>
                        </Form>
                    </TextTitledPanel>
                </>
            )}
        </>
    );
};

export default ProfileContainer;
