import styled from "styled-components";
import { StyledColorDiv } from "../../core/theme/global-styles";
import SbLabelText from "../atoms/SbLabelText";

const StyledContainerDiv = styled.div`
    display: flex;
    gap: ${(props) => props.theme.padding.md};
`;

const StyledItemDiv = styled.div`
    display: flex;
    gap: ${(props) => props.theme.padding.sm};
`;

const Legend: React.FC<{
    labelColourMap: Map<string, string>;
}> = ({ labelColourMap }) => (
    <StyledContainerDiv>
        {Array.from(labelColourMap.entries()).map((x, index) => (
            <StyledItemDiv key={index}>
                <StyledColorDiv $color={x[1]} />
                <SbLabelText label={x[0]} />
            </StyledItemDiv>
        ))}
    </StyledContainerDiv>
);

export default Legend;
