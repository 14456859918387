import InstanceStatus from "../../enums/questions/instance-status";
import QuestionCaptureResponse from "../../responses/answer-capture/question-capture-response";

export default class QuestionCaptureSetDetailsDto {
    questionSetName: string;
    isOverdue: boolean;

    public constructor(responses: QuestionCaptureResponse[]) {
        this.questionSetName = responses.length ? responses[0].questionSetName : "";
        this.isOverdue = responses.some((x) => x.status === InstanceStatus.Overdue);
    }
}
