import { ActionItemEventsResponse } from "../../responses/action-items/action-item-events-response";
import ActionItemInstanceEventDto from "./action-item-instance-event-dto";
import QuestionSetActionEventDto from "./question-set-action-event-dto";

export default class ActionItemEventsDto {
    actionItemInstanceEventDtos: ActionItemInstanceEventDto[];
    questionSetActionEventDtos: QuestionSetActionEventDto[];

    public constructor(response: ActionItemEventsResponse) {
        const { actionItemInstanceEventResponses, questionSetActionEventResponses } = response;

        this.actionItemInstanceEventDtos = ActionItemInstanceEventDto.constructFromResponses(
            actionItemInstanceEventResponses
        );
        this.questionSetActionEventDtos = QuestionSetActionEventDto.constructFromResponses(
            questionSetActionEventResponses
        );
    }
}
