import { useEffect, useRef } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { SingleValue } from "react-select";
import styled from "styled-components";
import { Hierarchy, HierarchyTypes } from "../../core/constants/translation-namespace";
import { useMenu } from "../../core/store/menu-context";
import {
    maxContentWidthSelectStyle,
    SectionVerticalSpace,
    StyledRequiredStar,
} from "../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../core/utilities/enums";
import { HierarchyTypeDto } from "../../domain/dtos/hierarchy/hierarchy-type-dto";
import { NodeSubTreeUpwardDto } from "../../domain/dtos/hierarchy/node-sub-tree-upward-dto";
import { SbSelect } from "../atoms/input/SbSelect";

const StyledFormLabel = styled(Form.Label)`
    font-weight: 600;
    color: ${(props) => props.theme.palette.primary};
    display: inline;
`;

const StyledH3 = styled.h3`
    color: ${(props) => props.theme.palette.primary};
`;

const HierarchyActionsContainer: React.FC<{
    hierarchyTypes: HierarchyTypeDto[];
    onHierarchyTypeChanged: (hierarchyTypeId: number) => void;
    onNodeSelected: (nodeId: number | null) => void;
    lookupNodesList: NodeSubTreeUpwardDto[];
    isLoading: boolean;
    setSearchText: React.Dispatch<React.SetStateAction<string | null>>;
}> = ({
    hierarchyTypes,
    onHierarchyTypeChanged,
    onNodeSelected,
    lookupNodesList,
    isLoading,
    setSearchText,
}) => {
    const selectedHierarchyTypeId = useRef<number | null>(null);

    const menu = useMenu();
    const [urlSearchParams] = useSearchParams();
    const { t } = useTranslation("translation");

    const hierarchyTypeIdParam = Number(urlSearchParams.get("hierarchyTypeId"));

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.VisualTree);
    }, []);

    const setDefaultSelectedHierarchyTypeItem = (): HierarchyTypeDto => {
        if (selectedHierarchyTypeId.current == null) {
            if (hierarchyTypeIdParam) {
                return hierarchyTypes.find((x) => x.hierarchyTypeId == hierarchyTypeIdParam)!;
            }
            selectedHierarchyTypeId.current = !hierarchyTypes.length
                ? 0
                : hierarchyTypes[0].hierarchyTypeId;

            if (hierarchyTypes.length) {
                onHierarchyTypeChanged(hierarchyTypes[0].hierarchyTypeId);
            }
        }
        return hierarchyTypes[0];
    };

    const onHierarchyTypeSelected = (option: SingleValue<HierarchyTypeDto>): void => {
        onHierarchyTypeChanged(option!.hierarchyTypeId);
    };

    return (
        <>
            <StyledH3>{t("Hierarchy", { keyPrefix: HierarchyTypes })}</StyledH3>
            <SectionVerticalSpace />
            <Form.Group as={Row}>
                <StyledFormLabel column sm={"auto"}>
                    {t("HierarchyType", { keyPrefix: HierarchyTypes })}
                    <StyledRequiredStar>*</StyledRequiredStar>
                </StyledFormLabel>
                <Col md={"3"}>
                    <SbSelect
                        name={"hierarchyTypes"}
                        styles={maxContentWidthSelectStyle}
                        searchable
                        clearable={false}
                        items={hierarchyTypes}
                        defaultSelectedItem={setDefaultSelectedHierarchyTypeItem()}
                        onChange={onHierarchyTypeSelected}
                        itemLabel={(option: HierarchyTypeDto) => option.name}
                        itemValue={(option: HierarchyTypeDto) => option.name}
                    />
                </Col>

                <Col sm={7}>
                    <SbSelect
                        name={"searchText"}
                        placeholderText={t("SearchSelectedHierarchy", { keyPrefix: Hierarchy })}
                        searchable={true}
                        clearable={true}
                        loading={isLoading}
                        items={lookupNodesList}
                        onSearchTextChanged={(searchText: string) => setSearchText(searchText)}
                        itemLabel={(option: NodeSubTreeUpwardDto) => option?.path}
                        itemValue={(option: NodeSubTreeUpwardDto) =>
                            option.subRootNodeId.toString()
                        }
                        onChange={(option: SingleValue<NodeSubTreeUpwardDto>) => {
                            onNodeSelected(option?.subRootNodeId! ?? null);
                        }}
                    />
                </Col>

                <Col />
            </Form.Group>
        </>
    );
};

export default HierarchyActionsContainer;
