import QuestionSetActionEventResponse from "../../responses/action-items/question-set-action-event-response";
import BaseActionItemEventDto from "./base-action-item-event-dto";

export default class QuestionSetActionEventDto extends BaseActionItemEventDto {
    questionSetActionId: number;

    public constructor(response: QuestionSetActionEventResponse) {
        super(response);

        this.questionSetActionId = response.questionSetActionId;
    }

    public static constructFromResponses = (
        responses: QuestionSetActionEventResponse[]
    ): QuestionSetActionEventDto[] => responses.map((x) => new QuestionSetActionEventDto(x));
}
