import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { getAuditChangeLog } from "../../../data/services/audits/audits-service";
import { AuditChangelogDto, createAuditChangelogDto } from "../../dtos/audits/audit-change-log-dto";

export const useGetAudit = (changelogId: number): UseQueryResult<AuditChangelogDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(["getAudit"], () => getAuditChangeLog(url.baseUrl, changelogId), {
        select: createAuditChangelogDto,
        onError: errorResponseToDisplayHandler,
    });
};
