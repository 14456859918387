import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { SingleValue } from "react-select";
import {
    ChecklistAnswers,
    ChecklistQuestions,
    Common,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import { LanguageDto } from "../../../domain/dtos/language/language-dto";
import { Response } from "../../../domain/responses/common/response-response";
import {
    createCreateParams,
    useCreatePredefinedAnswerText,
    useGetLanguageLookups,
} from "../../../domain/viewmodels/answer-types/create-predefined-answer-text-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const CreatePredefinedAnswerTextContainer: React.FC = () => {
    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const predefinedAnswerId = Number(useParams().predefinedAnswerId);
    const answerTypeId = Number(useParams().answerTypeId);
    const { t } = useTranslation("translation", { keyPrefix: ChecklistAnswers });

    const languageLookup = useGetLanguageLookups();
    const languageLookupResponse = languageLookup.data;

    const createPredefinedAnswerText = useCreatePredefinedAnswerText();

    useLoader(isQueryLoading(languageLookup), CreatePredefinedAnswerTextContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.AnswerTypes);
    }, []);

    const [languageId, setLanguageId] = useState(0);

    const onLanguageSelected = (option: SingleValue<LanguageDto>): void =>
        setLanguageId(option!.languageId);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        createPredefinedAnswerText.mutate(
            createCreateParams(predefinedAnswerId, languageId, new FormData(event.currentTarget)),
            {
                onSuccess: async (_: Response<number | null>) => {
                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "messageKey",
                            "SuccessfullyAddedPredefinedAnswer"
                        ),
                    ];

                    navigateSearch(
                        `${getPath(AccordionTitles.AnswerTypes)}/${answerTypeId}${getPath(
                            AccordionTitles.AnswerTypePredefinedAnswers
                        )}/${predefinedAnswerId}`,
                        params
                    );
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    return (
        <>
            <PageHeading>{t("PredefinedAnswer")}</PageHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(languageLookup) && (
                <TextTitledPanel title={t("AddPredefinedAnswerText")}>
                    <Form onSubmit={handleSubmit}>
                        <SbFormSelectFieldGroup
                            name={"languageId"}
                            label={t("Language", { keyPrefix: ChecklistQuestions })}
                            placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                            searchable={false}
                            clearable={false}
                            items={languageLookupResponse}
                            itemDisplayText={(option: LanguageDto) => option.name} //TODO: Add translations for dynamic data
                            onChange={onLanguageSelected}
                        />

                        <SbFormTextFieldGroup
                            name="value"
                            label={t("Value", { keyPrefix: Common })}
                            maxLength={20}
                            type="text"
                            required
                        />

                        <EndAlignedDiv>
                            <SaveButton type="submit" />
                            <CancelButton onClick={() => navigate(-1)} />
                        </EndAlignedDiv>
                    </Form>
                </TextTitledPanel>
            )}
        </>
    );
};

export default CreatePredefinedAnswerTextContainer;
