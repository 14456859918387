export interface SearchQuestionsRequest {
    questionId: number | null;
    questionSetName: string | null;
    questionText: string | null;
    questionTypeId: number | null;
    questionCategoryId: number | null;
    answerTypeId: number | null;
}

export const SearchParameters = {
    questionId: "questionId",
    questionSetName: "questionSetName",
    questionText: "questionText",
    questionTypeId: "questionTypeId",
    questionCategoryId: "questionCategoryId",
    answerTypeId: "answerTypeId",
};

export const buildQuestionsSearchParameters = (
    request: SearchQuestionsRequest,
    searchParams: URLSearchParams
): URLSearchParams => {
    if (request.questionId) {
        searchParams.set(SearchParameters.questionId.toString(), request.questionId.toString());
    }

    if (request.questionSetName != null) {
        searchParams.set(
            SearchParameters.questionSetName.toString(),
            request.questionSetName.toString()
        );
    }

    if (request.questionText) {
        searchParams.set(SearchParameters.questionText, request.questionText);
    }

    if (request.questionTypeId != null) {
        searchParams.set(
            SearchParameters.questionTypeId.toString(),
            request.questionTypeId.toString()
        );
    }

    if (request.questionCategoryId != null) {
        searchParams.set(
            SearchParameters.questionCategoryId.toString(),
            request.questionCategoryId.toString()
        );
    }

    if (request.answerTypeId != null) {
        searchParams.set(SearchParameters.answerTypeId.toString(), request.answerTypeId.toString());
    }

    return searchParams;
};
