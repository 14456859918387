import { HTTPError } from "ky";
import React, { ReactElement, useEffect, useState } from "react";
import { Col, Form, FormGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { CreateNodeEvent } from "../../../core/constants/application-insights-events";
import {
    Common,
    HierarchyTypeTreeView,
    Nodes,
    Roles,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
    StyledFormLabel,
} from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { hierarchyLevelValueActionsColumnNames } from "../../../core/utilities/dataTableColumns";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { defaultPaginationDto, PaginationDto } from "../../../domain/dtos/common/pagination-dto";
import { LinkableNodeDto } from "../../../domain/dtos/hierarchy/linkable-node-dto";
import { NodeTypeDto } from "../../../domain/dtos/hierarchy/node-type-dto";
import { HierarchyAssignable } from "../../../domain/enums/hierarchy/HierarchyAssignable";
import { splitCamelCase } from "../../../domain/helpers/split-camel-case/spilt-camel-case";
import { Response } from "../../../domain/responses/common/response-response";
import {
    useCreateChildNode,
    useFilterChildNodeTypes,
    useGetLinkableNodes,
    useGetNodeDetails,
    useHasChildNodes,
} from "../../../domain/viewmodels/hierarchy/create-node-viewmodel";
import { useGetNodeTypeDetails } from "../../../domain/viewmodels/hierarchy/edit-node-type-viewmodel";
import { useFilterNodeTypeValuesToTableRows } from "../../../domain/viewmodels/hierarchy/text-search-node-viewmodel";
import { useGetRoles } from "../../../domain/viewmodels/roles/role-viewmodel";
import { SbAlert } from "../../atoms/SbAlert";
import { CancelButton, SaveButton, SbButton } from "../../atoms/SbButton";
import SbLabelText from "../../atoms/SbLabelText";
import { SbLink } from "../../atoms/SbLink";
import { SbRibbon } from "../../atoms/SbRibbon";
import { RadioSelectProps } from "../../molecules/DataTableAction";
import SbFormCheckFieldGroup from "../../molecules/input/SbFormCheckFieldGroup";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";
import { DataTable } from "../../organisms/DataTable";

const CreateNodeContainer: React.FC = () => {
    const [isCreatingNewNodeTypeValue, setIsCreatingNewNodeTypeValue] = useState(false);
    const [selectedNodeTypeId, setSelectedNodeTypeId] = useState<number | null>(null);
    const [selectedLinkedNodeId, setSelectedLinkedNodeId] = useState<number | null>(null);
    const [selectedNodeTypeValueId, setSelectedNodeTypeValueId] = useState<number | null>(null);
    const [selectedIsTraining, setSelectedIsTraining] = useState<boolean | null>(null);
    const [nodeTypeValueSearchText, setNodeTypeValueSearchText] = useState<string | null>(null);
    const [nodeTypeValuesPaginationDto, setNodeTypeValuesPaginationDto] =
        useState<PaginationDto>(defaultPaginationDto);
    const [roleIds, setRoleIds] = useState<number[]>([]);

    const menu = useMenu();
    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: Nodes });

    const nodeId = Number(useParams().nodeId);

    const getNodeDetails = useGetNodeDetails(nodeId);
    const hasChildrenNodes = useHasChildNodes(nodeId);

    const getLinkableNodes = useGetLinkableNodes(nodeId, selectedNodeTypeId);
    const getRoles = useGetRoles();
    const getNodeTypeDetails = useGetNodeTypeDetails(selectedNodeTypeId ?? -1);

    const getRolesData = getRoles.data;
    const getNodeTypeDetailsData = getNodeTypeDetails.data;

    // TODO: When doing error scenario, consider adding the hasChildrenNodes as part of the enabled here?
    const filterChildNodeTypes = useFilterChildNodeTypes(
        getNodeDetails.data?.node.nodeType.nodeTypeId ?? null
    );
    const filterNodeTypeValues = useFilterNodeTypeValuesToTableRows(
        getNodeDetails.data?.node.nodeType.hierarchyTypeId ?? null,
        selectedNodeTypeId,
        nodeTypeValueSearchText,
        nodeTypeValuesPaginationDto
    );

    const performNodeTypeValueSearch = (searchString: string | null): void => {
        setNodeTypeValueSearchText(searchString);
    };

    const createChildNode = useCreateChildNode();

    useLoader(
        areQueriesLoading([
            getNodeDetails,
            hasChildrenNodes,
            filterChildNodeTypes,
            getLinkableNodes,
            getRoles,
            getNodeTypeDetails,
        ]) || isMutationLoading(createChildNode),
        CreateNodeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.VisualTree);
    }, []);

    useEffect(() => {
        if (!selectedIsTraining) {
            setSelectedIsTraining(getNodeDetails.data?.node.isTraining ?? false);
        }
    }, [getNodeDetails.data]);

    const queriesSuccessful = (): boolean =>
        areQueriesSuccessful([getNodeDetails, hasChildrenNodes, filterChildNodeTypes]);

    const radioSelect: RadioSelectProps = {
        type: "radio",
        label: `${t("SelectNode", { keyPrefix: HierarchyTypeTreeView })}`,
    };

    const onRoleIdChangeHandler = (roleId: number) => {
        const selectedRoleIds = [...roleIds];

        const index = selectedRoleIds.indexOf(roleId);
        if (index > -1) {
            selectedRoleIds.splice(index, 1);
        } else {
            selectedRoleIds.push(roleId);
        }

        setRoleIds(selectedRoleIds);
    };

    const buildRoleRestriction = (): JSX.Element => {
        return (
            <SbFormCheckFieldGroup
                fieldLabel={t("RoleRestrictions", { keyPrefix: Roles })}
                type="checkbox"
                values={
                    getRolesData?.map((x) => ({
                        name: x.roleId.toString(),
                        label: splitCamelCase(x.name),
                        onChangeHandler: (roleId) => {
                            onRoleIdChangeHandler(Number(roleId));
                        },
                    })) ?? []
                }
            />
        );
    };

    const buildRibbon = (): ReactElement | false =>
        (getNodeDetails.data!.node.isTraining ||
            getNodeDetails.data!.inheritedTrainingFlag ||
            selectedIsTraining == true) && (
            <SbRibbon size={"large"} label={t("Training", { keyPrefix: Common })} />
        );

    const buildNodeTypeValueFormFields = (): ReactElement<typeof FormGroup> => {
        const toggleIsCreatingNewNodeTypeValue = (): void =>
            setIsCreatingNewNodeTypeValue(!isCreatingNewNodeTypeValue);

        const buildSelectNodeTypeValueFormField = (): ReactElement<typeof FormGroup> => (
            <>
                <SbFormTextFieldGroup
                    name={"searchValue"}
                    label={t("SearchValue", { keyPrefix: Nodes })}
                    type={"text"}
                    value={nodeTypeValueSearchText || ""}
                    onTextChanged={performNodeTypeValueSearch}
                >
                    <SbButton
                        variant={"primary"}
                        type={"button"}
                        label={t("CreateNew", { keyPrefix: Common })}
                        icon={FaPlus}
                        onClick={toggleIsCreatingNewNodeTypeValue}
                        disabled={selectedNodeTypeId == null}
                    />
                </SbFormTextFieldGroup>

                {
                    <DataTable
                        columns={hierarchyLevelValueActionsColumnNames}
                        rows={filterNodeTypeValues.data?.rows}
                        totalItems={filterNodeTypeValues.data?.recordCount}
                        paginationDto={nodeTypeValuesPaginationDto}
                        selectItem={(nodeTypeValueId) => {
                            setSelectedNodeTypeValueId(nodeTypeValueId);
                        }}
                        radioSelect={radioSelect}
                        setPaginationDto={setNodeTypeValuesPaginationDto}
                        noResultsMessage={t("NoNodesFound")}
                        isLoading={isQueryLoading(filterNodeTypeValues)}
                        isSuccessful={isQuerySuccessful(filterNodeTypeValues)}
                    />
                }
            </>
        );

        const buildCreateNewNodeTypeValueFormFields = (): ReactElement<typeof FormGroup> => (
            <>
                <SbFormTextFieldGroup
                    name={"nodeTypeValueValue"}
                    label={t("NewHierarchyLevelValue")}
                    type={"text"}
                    maxLength={100}
                    required
                >
                    <CancelButton onClick={toggleIsCreatingNewNodeTypeValue} />
                </SbFormTextFieldGroup>

                <SbFormTextFieldGroup
                    name={"nodeTypeValueCode"}
                    label={t("Code", { keyPrefix: Common })}
                    maxLength={50}
                    type={"text"}
                />

                <SbFormTextFieldGroup
                    name={"nodeTypeValueDescription"}
                    label={t("Description", { keyPrefix: Common })}
                    maxLength={1000}
                    type={"text"}
                />

                {getNodeTypeDetailsData?.hierarchyAssignables.some(
                    (x) => x == HierarchyAssignable.ActionItemTypes
                ) && buildRoleRestriction()}
            </>
        );

        return isCreatingNewNodeTypeValue
            ? buildCreateNewNodeTypeValueFormFields()
            : buildSelectNodeTypeValueFormField();
    };

    const getDefaultSelectedNodeType = (): NodeTypeDto | undefined => {
        const nodeTypes = filterChildNodeTypes.data!;
        if (selectedNodeTypeId) {
            return nodeTypes.find((x) => x.nodeTypeId == selectedNodeTypeId);
        }

        if (nodeTypes.length == 1) {
            if (selectedNodeTypeId == null) {
                setSelectedNodeTypeId(nodeTypes[0].nodeTypeId);
            }

            return nodeTypes[0];
        }

        return undefined;
    };

    const toggleSelectedIsTraining = (): void => setSelectedIsTraining(!selectedIsTraining);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        createChildNode.mutate(
            {
                parentNodeDetails: getNodeDetails.data!.node,
                formData: new FormData(event.currentTarget),
                isCreatingNodeTypeValue: isCreatingNewNodeTypeValue,
                inheritsTraining: getNodeDetails.data!.inheritedTrainingFlag,
                childNodeTypes: filterChildNodeTypes.data!,
                selectedNodeTypeValueId: selectedNodeTypeValueId,
                linkedNodeId: selectedLinkedNodeId,
                roleIds: roleIds,
            },
            {
                onSuccess: async (response: Response<number>) => {
                    trackAppInsightsEvent(auth.email, window.location.href, CreateNodeEvent);

                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter("messageKey", "SuccessfullyCreatedNode"),
                    ];

                    navigateSearch(
                        `${getPath(AccordionTitles.VisualTree)}/${response.data}`,
                        params
                    );
                },
                onError: (error: HTTPError) => {
                    trackAppInsightsException(
                        auth.email,
                        window.location.href,
                        CreateNodeEvent,
                        error
                    );
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    };

    const buildErrorAlert = (): ReactElement<HTMLDivElement> =>
        selectedNodeTypeId == null ? (
            <SbAlert
                variant={"danger"}
                text={t("ChildCannotBeAddedToNode", { keyPrefix: Nodes })}
            />
        ) : (
            <></>
        );

    return (
        <>
            <PageHeading>{t("NodeCreateTitle")}</PageHeading>
            <SectionVerticalSpace />

            {queriesSuccessful() && (
                <>
                    {buildErrorAlert()}

                    <TextTitledPanel title={t("CreateNode")}>
                        {/* TODO: "Child cannot be added to this node" toast if parent has no child nodes */}

                        {buildRibbon()}

                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <StyledFormLabel column sm={2}>
                                    <SbLabelText label={"Parent"} />
                                </StyledFormLabel>
                                <Col sm={4}>
                                    <SbLink
                                        variant="primary"
                                        label={getNodeDetails.data!.node.shortDescription}
                                        navigateTo={`${getPath(
                                            AccordionTitles.VisualTree
                                        )}/${nodeId}`}
                                    />
                                </Col>
                            </Row>
                            {isQuerySuccessful(getLinkableNodes) && (
                                <SbFormSelectFieldGroup
                                    name={"nodeType"}
                                    label={"Hierarchy Level"}
                                    placeholderText={"Select"}
                                    searchable
                                    clearable={false}
                                    required
                                    items={filterChildNodeTypes.data!}
                                    defaultSelectedItem={getDefaultSelectedNodeType()}
                                    itemDisplayText={(option: NodeTypeDto) => option.name} //TODO: Add translations for dynamic data
                                    onChange={(option: SingleValue<NodeTypeDto>) => {
                                        if (
                                            option == null ||
                                            option!.nodeTypeId != selectedNodeTypeId
                                        ) {
                                            setSelectedNodeTypeValueId(null);
                                        }

                                        setSelectedNodeTypeId(option?.nodeTypeId ?? null);
                                        queryClient.refetchQueries([
                                            "getLinkableNodes",
                                            nodeId,
                                            selectedNodeTypeId,
                                        ]);
                                    }}
                                />
                            )}

                            {getNodeDetails.data!.inheritedTrainingFlag ? (
                                <SbFormCheckFieldGroup
                                    fieldLabel={t("IsTrainingInheritedDisplay")}
                                    type="checkbox"
                                    values={[
                                        {
                                            name: "isTrainingInherited",
                                            defaultSelected: true,
                                            disabled: true,
                                        },
                                    ]}
                                />
                            ) : (
                                <SbFormCheckFieldGroup
                                    fieldLabel={t("IsTrainingDisplay", { keyPrefix: Common })}
                                    type="checkbox"
                                    values={[
                                        {
                                            name: "isTraining",
                                            defaultSelected: getNodeDetails.data!.node.isTraining,
                                            onChangeHandler: toggleSelectedIsTraining,
                                        },
                                    ]}
                                />
                            )}

                            {getLinkableNodes.data?.length! > 0 && (
                                <SbFormSelectFieldGroup
                                    name={"linkableNode"}
                                    label={getLinkableNodes.data![0].descendantsLinkedNodeTypeName}
                                    placeholderText={"Select"}
                                    searchable
                                    clearable={false}
                                    required
                                    items={getLinkableNodes?.data}
                                    itemDisplayText={(option: LinkableNodeDto) =>
                                        option.descendantsLinkedNodeTypeValue
                                    }
                                    onChange={(option: SingleValue<LinkableNodeDto>) => {
                                        setSelectedLinkedNodeId(option!.descendantsLinkedNodeId);
                                    }}
                                />
                            )}

                            {buildNodeTypeValueFormFields()}

                            <EndAlignedDiv>
                                <SaveButton type="submit" />
                                <CancelButton onClick={() => navigate(-1)} />
                            </EndAlignedDiv>
                        </Form>
                    </TextTitledPanel>
                </>
            )}
        </>
    );
};

export default CreateNodeContainer;
