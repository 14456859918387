import { LinkReviewReportSetWithAssuranceReviewDto } from "../../dtos/review-reports/link-review-report-set-assurance-review-dto";

export interface LinkReviewReportSetWithAssuranceReviewRequest {
    reviewReportSetId: number;
    assuranceReviewId: number;
    assuranceReviewName: string;
}

export const createLinkReviewReportSetWithAssuranceReviewRequest = (
    dto: LinkReviewReportSetWithAssuranceReviewDto
): LinkReviewReportSetWithAssuranceReviewRequest => ({
    reviewReportSetId: dto.reviewReportSetId,
    assuranceReviewId: dto.assuranceReviewId,
    assuranceReviewName: dto.assuranceReviewName,
});
