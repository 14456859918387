import { useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { toDateFormat } from "../../../core/utilities/date-helper";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import { filterAssociatedNonWorkingDaySetNodes } from "../../../data/services/hierarchy/nodes-service";
import { getAssociatedNonWorkingDaySetNodes } from "../../../data/services/non-working-days/non-working-day-service";
import { ListTableDto } from "../../dtos/common/list-table-dto";
import {
    NonWorkingDayDto,
    toNonWorkingDayDto,
} from "../../dtos/non-working-days/non-working-day-dto";
import { NonWorkingDaySetNodeTypeResponse } from "../../requests/non-working-days/non-working-day-set-node-type-response";
import { Response } from "../../responses/common/response-response";
import { NonWorkingDayResponse } from "../../responses/non-working-days/non-working-day-response";

export const useGetNonWorkingDays = (
    nonWorkingDaySetId: number
): [
    UseQueryResult<ListTableDto<NonWorkingDayDto>, HTTPError>,
    UseQueryResult<ListTableDto<number>, HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getAssociatedNonWorkingDaySetNodes", nonWorkingDaySetId],
                queryFn: () => getAssociatedNonWorkingDaySetNodes(url.baseUrl, nonWorkingDaySetId),
                keepPreviousData: true,
                select: transformToDataTableRows,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["filterAssociatedNonWorkingDaySetNodes", nonWorkingDaySetId],
                queryFn: () =>
                    filterAssociatedNonWorkingDaySetNodes(url.baseUrl, nonWorkingDaySetId),
                select: transformNonWorkingDaySetNodesToDataTableRows,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<ListTableDto<NonWorkingDayDto>, HTTPError>,
        UseQueryResult<ListTableDto<number>, HTTPError>,
    ];
};

const transformToDataTableRows = (
    response: Response<NonWorkingDayResponse[]>
): ListTableDto<NonWorkingDayDto> => {
    const rows = response.data.map((nonWorkingDay) => ({
        metadata: toNonWorkingDayDto(nonWorkingDay),
        columns: [
            {
                value: nonWorkingDay.name,
                type: DataTableColumnTypes.Text,
            },
            {
                value: toDateFormat(nonWorkingDay.date.toString()),
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return { rows: rows };
};

const transformNonWorkingDaySetNodesToDataTableRows = (
    response: Response<NonWorkingDaySetNodeTypeResponse[]>
): ListTableDto<number> => {
    const rows = response.data.map((nonWorkingDaySetNode) => ({
        metadata: nonWorkingDaySetNode.nodeId,
        columns: [
            {
                value: nonWorkingDaySetNode.fullName,
                type: DataTableColumnTypes.Link,
            },
        ],
    }));

    return { rows: rows };
};
