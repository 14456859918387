import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import {
    cancelQuestionSetInstance,
    filterPendingAdhocChecklists,
} from "../../../data/services/checklists/adhoc-checklists-service";
import { getQuestionSetDetails } from "../../../data/services/question-set/question-set-service";
import CancelAdhocChecklistDto from "../../dtos/checklists/cancel-adhoc-checklist-dto";
import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { FilterPendingAdhocsDto } from "../../dtos/pending-adhocs/filter-pending-adhocs-dto";
import { createFilterAdhocChecklistRequestFromDto } from "../../requests/checklists/filter-adhoc-checklists-request";
import { AdhocQuestionSetInstanceResponse } from "../../responses/checklists/adhoc-question-set-instance-response";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { QuestionSetResponse } from "../../responses/question-sets/question-set-response";

export const useFilterPendingAdhocs = (
    filterDto: FilterPendingAdhocsDto,
    paginationDto: BasePaginationDto
): UseQueryResult<PaginatedTableDto<CancelAdhocChecklistDto>, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterPendingAdhocChecklists", filterDto, paginationDto],
        () =>
            filterPendingAdhocChecklists(
                url.baseUrl,
                createFilterAdhocChecklistRequestFromDto(filterDto, paginationDto)
            ),

        {
            keepPreviousData: true,
            select: transformToDataTableRows,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const transformToDataTableRows = (
    response: Response<PaginationResponse<AdhocQuestionSetInstanceResponse>>
): PaginatedTableDto<CancelAdhocChecklistDto> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((x) => ({
        metadata: new CancelAdhocChecklistDto(x.questionSetInstanceId, x.questionSetId),
        columns: [
            {
                value: x.assignedNodePath,
                type: DataTableColumnTypes.Text,
            },
            {
                value: x.country,
                type: DataTableColumnTypes.Text,
            },
            {
                value: x.checklistName,
                type: DataTableColumnTypes.Text,
            },
            {
                value: toDateAndTimeFormat(x.dueDateUtc.toString()),
                type: DataTableColumnTypes.Text,
            },
            {
                value: toDateAndTimeFormat(x.triggerDateUtc.toString()),
                type: DataTableColumnTypes.Text,
            },
            {
                value: x.triggeredFor,
                type: DataTableColumnTypes.Text,
            },
            {
                value: x.triggeredBy,
                type: DataTableColumnTypes.Text,
            },
            {
                value: x.referentialLinks,
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};

export const useGetCancelQuestionSetDetails = (
    questionSetId: number
): UseQueryResult<string, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getQuestionSetDetails", questionSetId],
        () => getQuestionSetDetails(url.baseUrl, questionSetId),
        {
            select: toCancelQuestionSetDetailsDto,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const toCancelQuestionSetDetailsDto = (response: Response<QuestionSetResponse>): string =>
    response.data.name;

export const useCancelQuestionSetInstance = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    number
> => {
    const url = useUrl();

    return useMutation((questionSetInstanceId: number) =>
        cancelQuestionSetInstance(url.baseUrl, questionSetInstanceId)
    );
};
