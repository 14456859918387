export interface CreateAnswerTypeDto {
    formData: FormData;
    answerCategoryId: number;
    renderModeId: number;
    flagModeId: number;
    valueTypeId: number;
}

export const createCreateAnswerTypeDto = (
    formData: FormData,
    answerCategoryId: number,
    renderModeId: number,
    flagModeId: number,
    valueTypeId: number
): CreateAnswerTypeDto => ({
    formData: formData,
    answerCategoryId,
    renderModeId,
    flagModeId,
    valueTypeId,
});
