import { SetStateAction, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaPlusCircle } from "react-icons/fa";
import styled from "styled-components";
import { ActionItems, Common } from "../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../core/hooks/loaderManager";
import { createSuccessToastProps, useToast } from "../../core/store/toast-context";
import { StyledActionItemSectionContainer } from "../../core/theme/global-styles";
import { isQueryLoading, isQuerySuccessful } from "../../core/utilities/responseStateHelper";
import queryClient from "../../data/query-client";
import { ActionItemInstanceCommentDto } from "../../domain/dtos/action-items/action-item-instance-comment-dto";
import { MyActionItemsDto } from "../../domain/dtos/action-items/my-action-items-dto";
import {
    useCreateActionItemInstanceComment,
    useGetActionItemInstanceComments,
} from "../../domain/viewmodels/action-items/action-item-comments-viewmodel";
import { SbButton } from "../atoms/SbButton";
import ActionItemComment from "../molecules/ActionItemComment";

const StyledTitleText = styled.div`
    font-size: ${(props) => props.theme.font.xl};
    font-weight: bold;
    color: ${(props) => props.theme.palette.secondary};
    padding-bottom: ${(props) => props.theme.padding.lg};
`;

const StyledCommentTextArea = styled(Form.Control)`
    border-radius: ${(props) => props.theme.padding.sm};
    width: 100%;
`;

const StyledCol = styled(Col)`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
`;

const StyledCommentsSection = styled.div`
    width: 100%;
    overflow-x: hidden;
    max-height: 250px;
    overflow-y: scroll;
    word-wrap: break-word;
    border: none;
`;

const ActionItemComments: React.FC<{ actionItem: MyActionItemsDto }> = ({ actionItem }) => {
    const [comment, setComment] = useState<string | null>();

    const getActionItemInstanceComments = useGetActionItemInstanceComments(
        actionItem.actionItemInstanceId
    );
    const createActionItemInstanceComment = useCreateActionItemInstanceComment();
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });

    const toast = useToast();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    useLoader(isQueryLoading(getActionItemInstanceComments), ActionItemComments);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        if (!comment) {
            return;
        }

        createActionItemInstanceComment.mutate(
            new ActionItemInstanceCommentDto(comment, actionItem.actionItemInstanceId),
            {
                onSuccess: async () => {
                    setComment(null);
                    toast.addToast(createSuccessToastProps([t("CommentSuccess")]));
                    queryClient.refetchQueries([
                        "getActionItemInstanceComments",
                        actionItem.actionItemInstanceId,
                    ]);
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    const onCommentInputChange = (event: {
        target: { value: SetStateAction<string | null | undefined> };
    }): void => {
        setComment(event.target.value);
    };

    return isQuerySuccessful(getActionItemInstanceComments) ? (
        <StyledActionItemSectionContainer onSubmit={handleSubmit}>
            <StyledTitleText>{t("Comments")}</StyledTitleText>
            <Row>
                <Col md="11">
                    <StyledCommentTextArea
                        as="textarea"
                        name={"comment"}
                        value={comment ?? ""}
                        rows={1}
                        maxLength={4000}
                        onChange={onCommentInputChange}
                    />
                </Col>
                <StyledCol md="1">
                    <SbButton
                        variant={"primary"}
                        type="submit"
                        label={t("Add", { keyPrefix: Common })}
                        icon={FaPlusCircle}
                        iconSize="lg"
                    />
                </StyledCol>
            </Row>
            <StyledCommentsSection>
                {getActionItemInstanceComments.data!.map((x) => (
                    <ActionItemComment comment={x} />
                ))}
            </StyledCommentsSection>
        </StyledActionItemSectionContainer>
    ) : (
        <></>
    );
};

export default ActionItemComments;
