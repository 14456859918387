import { Col, Form } from "react-bootstrap";
import { StyledFilterTitle } from "../../core/theme/global-styles";

export const FilterInput: React.FC<{
    title: string;
    name: string;
    type: string;
    onChangeEventHandler: (value: string) => void;
    value?: string;
}> = ({ title, name, type, onChangeEventHandler, value }) => {
    return (
        <Col>
            <StyledFilterTitle>{title}</StyledFilterTitle>
            <Form.Control
                name={name}
                type={type}
                onChange={(event) => {
                    onChangeEventHandler(event.target.value);
                }}
                value={value}
            />
        </Col>
    );
};
