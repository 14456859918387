import Col from "react-bootstrap/Col";
import styled from "styled-components";
import { getIcon } from "../../core/utilities/getIcon";

const StyledCol = styled(Col)`
    height: 100%;
    padding: 0;
`;

const StyledLink = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.palette.primary};
    padding-left: ${(props) => props.theme.padding.lg};
    padding-right: ${(props) => props.theme.padding.lg};
    cursor: pointer;

    &:hover {
        background-color: ${(props) => props.theme.palette.purple};
        color: ${(props) => props.theme.palette.white};
    }
`;

const StyledText = styled.div`
    padding-left: ${(props) => props.theme.padding.sm};
`;

const NavbarLink: React.FC<{
    title: string;
}> = ({ title }) => {
    return (
        <StyledCol sm="auto">
            <StyledLink onClick={() => window.location.replace(`${process.env.REACT_APP_RMP_URL}`)}>
                {getIcon(title)}
                <StyledText>{title}</StyledText>
            </StyledLink>
        </StyledCol>
    );
};

export default NavbarLink;
