import { QuestionSetScheduleRequest } from "./create-question-set-schedule-request";

export interface CreateQuestionSetRequest {
    name: string;
    description: string;
    process: string;
    isARMonitoring: boolean;
    questionSetScheduleRequest: QuestionSetScheduleRequest;
}

export const createCreateQuestionSetRequest = (
    name: string,
    description: string,
    process: string,
    isARMonitoring: boolean,
    questionSetScheduleRequest: QuestionSetScheduleRequest
): CreateQuestionSetRequest => ({
    name,
    description,
    process,
    isARMonitoring,
    questionSetScheduleRequest,
});
