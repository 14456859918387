import { FilterMyActionItemsDto } from "../../dtos/action-items/filter-my-action-items-dto";
import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import { ReactSelectDto } from "../../dtos/common/react-select-dto";
import { ActionInstanceOnHoldStatus } from "../../enums/action-items/ActionInstanceOnHoldStatus";
import RaciRelationship from "../../enums/action-items/RaciRelationship";
import {
    buildFilterSearchActionItemsParameters,
    FilterActionItemsRequest,
} from "../actions/filter-action-items-request";
import { createBasePaginationRequest } from "../common/base-pagination-request";

export interface FilterMyActionItemsRequest extends FilterActionItemsRequest {
    actionInstanceOnHoldStatus: ReactSelectDto<ActionInstanceOnHoldStatus>;
    raciRelationship: ReactSelectDto<RaciRelationship>;
    actionItemInstanceId: number | null;
}

export const createFilterMyActionItemsRequest = (
    filterMyActionItemsDto: FilterMyActionItemsDto,
    paginationDto: BasePaginationDto
): FilterMyActionItemsRequest => ({
    ...filterMyActionItemsDto,
    ...createBasePaginationRequest(paginationDto.pageNumber, paginationDto.pageSize),
});

export const buildFilterSearchMyActionItemsParameters = (
    request: FilterMyActionItemsRequest
): URLSearchParams => {
    const searchParams = buildFilterSearchActionItemsParameters(request);

    if (request.actionInstanceOnHoldStatus && request.actionInstanceOnHoldStatus.value !== 0) {
        searchParams.set(
            "actionInstanceOnHoldStatus",
            request.actionInstanceOnHoldStatus.value.toString()
        );
    }

    if (request.raciRelationship && request.raciRelationship.value > 0) {
        searchParams.set("raciRelationship", request.raciRelationship.value.toString());
    }

    if (request.actionItemInstanceId) {
        searchParams.set("actionItemInstanceId", request.actionItemInstanceId.toString());
    }

    return searchParams;
};
