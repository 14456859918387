import FileUploadDto from "../../dtos/file-storage/file-upload-dto";

export default class FileDetailsUploadRequest {
    fileName: string;
    file: string;
    fileType: string;

    public constructor(file: File, fileBase64String: string) {
        this.fileName = file.name;
        this.file = fileBase64String;
        this.fileType = file.type;
    }

    public static constructFromFileUploadDto = (dto: FileUploadDto): FileDetailsUploadRequest => {
        const { file, fileBase64String } = dto;

        return new FileDetailsUploadRequest(file, fileBase64String);
    };
}
