export interface SuspendNodeRequest {
    nodeId: number;
    suspensionDateTimeFrom: Date | null; // TODO: Extend SuspendRequest
    suspensionDateTimeTo: Date | null;
}

export const createSuspendNodeRequest = (
    nodeId: number,
    suspensionDateTimeFrom: Date | null,
    suspensionDateTimeTo: Date | null
): SuspendNodeRequest => ({
    nodeId: nodeId,
    suspensionDateTimeFrom: suspensionDateTimeFrom,
    suspensionDateTimeTo: suspensionDateTimeTo,
});
