import {
    AuthenticationProviderResponse,
    AuthenticationProvidersResponse,
} from "../../responses/tenants/authentication-providers-response";

export interface AuthenticationProviderDto {
    authProviderId: number;
    name: string;
    isDefault: boolean;
}

export const toAuthenticationProviderDto = (
    authenticationProvider: AuthenticationProviderResponse
): AuthenticationProviderDto => ({
    authProviderId: authenticationProvider.authProviderId,
    name: authenticationProvider.name,
    isDefault: authenticationProvider.isDefault,
});

export const toAuthenticationProviderDtos = (
    authenticationProviders: AuthenticationProvidersResponse
): AuthenticationProviderDto[] =>
    authenticationProviders.providers.map((x) => toAuthenticationProviderDto(x));
