import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import { FilterPendingAdhocsDto } from "../../dtos/pending-adhocs/filter-pending-adhocs-dto";
import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
    createBasePaginationRequestFromDto,
} from "../common/base-pagination-request";

export interface FilterAdhocChecklistRequest extends BasePaginationRequest {
    node: string | null;
    checklistName: string | null;
    referentialLinks: string | null;
    triggeredFor: string | null;
    triggeredBy: string | null;
    isTraining: boolean | null;
}

export const createFilterAdhocChecklistRequestFromDto = (
    filterDto: FilterPendingAdhocsDto,
    paginationDto: BasePaginationDto
): FilterAdhocChecklistRequest => ({
    node: filterDto.node,
    checklistName: filterDto.checklistName,
    referentialLinks: filterDto.referentialLinks,
    triggeredFor: filterDto.triggeredFor,
    triggeredBy: filterDto.triggeredBy,
    isTraining: filterDto.isTraining,
    ...createBasePaginationRequestFromDto(paginationDto),
});

export const buildFilterAdhocChecklistSearchParameters = (
    request: FilterAdhocChecklistRequest
): URLSearchParams => {
    const searchParams = buildBasePaginationSearchParameters(request);

    if (request.node) {
        searchParams.set("node", request.node);
    }

    if (request.checklistName) {
        searchParams.set("checklistName", request.checklistName);
    }

    if (request.referentialLinks) {
        searchParams.set("referentialLinks", request.referentialLinks);
    }

    if (request.triggeredFor) {
        searchParams.set("triggeredFor", request.triggeredFor);
    }

    if (request.triggeredBy) {
        searchParams.set("triggeredBy", request.triggeredBy);
    }

    if (request.isTraining != null) {
        searchParams.set("isTraining", request.isTraining.toString());
    }

    return searchParams;
};
