import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { getReferentialLinkTypeDetails } from "../../../data/services/referential-link-types/referential-link-type-service";
import BaseReferentialLinkTypeDto from "../../dtos/referential-links/base-referential-link-type-dto";

export const useGetReferentialLinkTypeDetails = (
    referentialLinkTypeId: number
): UseQueryResult<BaseReferentialLinkTypeDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getReferentialLinkTypeDetails", referentialLinkTypeId],
        () => getReferentialLinkTypeDetails(url.baseUrl, referentialLinkTypeId),
        {
            select: BaseReferentialLinkTypeDto.constructFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};
