import { FilterActionItemDynamicFieldsDto } from "../../dtos/action-items/filter-action-item-dynamic-fields-dto";
import {
    buildPaginationSearchParameters,
    createPaginationRequest,
    PaginationRequest,
} from "../common/pagination-request";

export interface FilterActionItemDynamicFieldsRequest extends PaginationRequest {
    name: string | null;
    actionItemTypeNodeId: number | null;
}

export const createFilterActionItemDynamicFieldsRequest = (
    name: string | null,
    actionItemTypeNodeId: number | null,
    pageNumber: number,
    pageSize: number,
    sortByColumn: string | null,
    sortByDescending: boolean = false
): FilterActionItemDynamicFieldsRequest => ({
    name: name,
    actionItemTypeNodeId: actionItemTypeNodeId,
    ...createPaginationRequest(pageNumber, pageSize, sortByColumn, sortByDescending),
});

export const createFilterActionItemDynamicFieldsRequestFromDto = (
    dto: FilterActionItemDynamicFieldsDto
): FilterActionItemDynamicFieldsRequest => {
    const { pageNumber, pageSize, sortByColumn, sortByDescending } = dto;

    return {
        ...dto,
        ...createPaginationRequest(pageNumber, pageSize, sortByColumn, sortByDescending),
    };
};

export const buildFilterActionItemDynamicFieldsSearchParameters = (
    request: FilterActionItemDynamicFieldsRequest
): URLSearchParams => {
    const searchParams = buildPaginationSearchParameters(request);

    if (request.name) {
        searchParams.set("name", request.name);
    }

    if (request.actionItemTypeNodeId) {
        searchParams.set("actionItemTypeNodeId", request.actionItemTypeNodeId.toString());
    }

    return searchParams;
};
