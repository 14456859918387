import { ReactSelectDto } from "../../domain/dtos/common/react-select-dto";
import { ActionInstanceOnHoldStatus } from "../../domain/enums/action-items/ActionInstanceOnHoldStatus";
import { ActionInstanceStatus } from "../../domain/enums/action-items/ActionInstanceStatus";
import RaciRelationship from "../../domain/enums/action-items/RaciRelationship";

export const RaciRelationshipOptions: ReactSelectDto<RaciRelationship>[] = [
    { label: "All", value: 0 },
    { label: "Responsible", value: RaciRelationship.Responsible },
    { label: "Accountable", value: RaciRelationship.Accountable },
    { label: "Consulted", value: RaciRelationship.Consulted },
    { label: "Informed", value: RaciRelationship.Informed },
];

export const ActionInstanceOnHoldStatusOptions: ReactSelectDto<ActionInstanceOnHoldStatus>[] = [
    { label: "All", value: ActionInstanceOnHoldStatus.All },
    { label: "On Hold", value: ActionInstanceOnHoldStatus.OnHold },
    { label: "Not On Hold", value: ActionInstanceOnHoldStatus.NotOnHold },
];

export const ActionInstanceStatusOptions: ReactSelectDto<ActionInstanceStatus>[] = [
    { label: "New", value: ActionInstanceStatus.New },
    { label: "Pending", value: ActionInstanceStatus.Pending },
    { label: "Overdue", value: ActionInstanceStatus.Overdue },
    { label: "Cancelled", value: ActionInstanceStatus.Cancelled },
    { label: "Completed", value: ActionInstanceStatus.Completed },
    { label: "Due", value: ActionInstanceStatus.Due },
    { label: "Open", value: ActionInstanceStatus.Open },
];
