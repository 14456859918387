import { PublishReviewReportSetDto } from "../../dtos/review-reports/publish-review-report-set-dto";
import ReviewRating from "../../enums/review-reports/review-ratings";

export class PublishReviewReportSetRequest {
    reviewReportSetId: number;
    reviewRating: ReviewRating;

    public constructor(dto: PublishReviewReportSetDto) {
        this.reviewReportSetId = dto.reviewReportSetId;
        this.reviewRating = dto.reviewRating;
    }
}
