import AppliesToUsers from "../../enums/checklists/AppliesToUsers";
import { ChecklistAssignmentDetailsResponse } from "../../responses/checklist-assignment/checklist-assignment-details-response";
import { QuestionSetAssignmentScheduleResponse } from "../../responses/checklists/question-set-assignment-schedule-response";
import { Response } from "../../responses/common/response-response";

export interface ChecklistAssignmentDetailsDto {
    checklistAssignmentId: number;
    checklistId: number;
    isEnabled: boolean;
    nodeId: number;
    timeZoneId: string;
    appliesToUsers: string;
    isTraining: boolean;
    activeDateNoTimeZone: Date;
    expiryDateNoTimeZone: Date | null;
    suspensionDateTimeFrom: Date | null;
    suspensionDateTimeTo: Date | null;
    review: boolean;
    allowActionItemTrigger: boolean;
    allowAdhocIntervalOverride: boolean;
    allowCustomNotifications: boolean;
    allowFileAttachment: boolean;
    questionSetAssignmentScheduleResponses: QuestionSetAssignmentScheduleResponse[];
}

export const createChecklistAssignmentDetailsDto = (
    response: Response<ChecklistAssignmentDetailsResponse>
): ChecklistAssignmentDetailsDto => {
    const responseData = response.data;

    return {
        checklistAssignmentId: responseData.checklistAssignmentId,
        checklistId: responseData.checklistId,
        isEnabled: responseData.isEnabled,
        nodeId: responseData.nodeId,
        timeZoneId: responseData.timeZoneId,
        appliesToUsers: AppliesToUsers[responseData.appliesToUsers],
        isTraining: responseData.isTraining,
        activeDateNoTimeZone: responseData.activeDateNoTimeZone,
        expiryDateNoTimeZone: responseData.expiryDateNoTimeZone,
        suspensionDateTimeFrom: responseData.suspensionDateTimeFrom,
        suspensionDateTimeTo: responseData.suspensionDateTimeTo,
        review: responseData.review,
        allowActionItemTrigger: responseData.allowActionItemTrigger,
        allowAdhocIntervalOverride: responseData.allowAdhocIntervalOverride,
        allowCustomNotifications: responseData.allowCustomNotifications,
        allowFileAttachment: responseData.allowFileAttachment,
        questionSetAssignmentScheduleResponses: responseData.questionSetAssignmentScheduleResponses,
    };
};
