import { Response } from "../../responses/common/response-response";
import { NodeTypeValueDetailsResponse } from "../../responses/hierarchy/node-type-value-details-response";
import { createNodeTypeDto, NodeTypeDto } from "./node-type-dto";
import { createNodeTypeValueDto, NodeTypeValueDto } from "./node-type-value-dto";

export interface NodeTypeValueDetailsDto extends NodeTypeValueDto {
    code: string | null;
    description: string | null;
    nodeType: NodeTypeDto;
}

export const createNodeTypeValueDetailsDto = (
    response: Response<NodeTypeValueDetailsResponse>
): NodeTypeValueDetailsDto => {
    const responseData = response.data;

    return {
        code: responseData.code,
        description: responseData.description,
        ...createNodeTypeValueDto(responseData),
        nodeType: createNodeTypeDto(responseData.nodeType),
    };
};
