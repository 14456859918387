import { useMutation, UseMutationResult, useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { HierarchyAssignableFlag } from "../../../core/utilities/enums";
import { filterHierarchyTypes } from "../../../data/services/hierarchy/hierarchy-types-service";
import {
    associateNode,
    getNonWorkingDaySet,
} from "../../../data/services/non-working-days/non-working-day-set-service";
import { HierarchyTypeDto, toHierarchyTypeDtos } from "../../dtos/hierarchy/hierarchy-type-dto";
import { AssociateNonWorkingDaySetNodeDto } from "../../dtos/non-working-days/associate-non-working-day-set-node-dto";
import {
    NonWorkingDaySetDto,
    toNonWorkingDaySetDto,
} from "../../dtos/non-working-days/non-working-day-set-dto";
import { createBasePaginationRequest } from "../../requests/common/base-pagination-request";
import { createFilterHierarchyTypesRequest } from "../../requests/hierarchy/filter-hierarchy-types-request";
import { emptySearchHierarchyTypesRequest } from "../../requests/hierarchy/search-hierarchy-types-request";
import { AssociateNonWorkingDaySetNodeRequest } from "../../requests/non-working-days/associate-non-working-day-set-node-request";
import { Response } from "../../responses/common/response-response";

export const useGetNonWorkingDaySet = (
    nonWorkingDaySetId: number
): [UseQueryResult<NonWorkingDaySetDto, HTTPError>] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getNonWorkingDaySet", nonWorkingDaySetId],
                queryFn: () => getNonWorkingDaySet(url.baseUrl, nonWorkingDaySetId),
                select: toNonWorkingDaySetDto,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [UseQueryResult<NonWorkingDaySetDto, HTTPError>];
};

export const useGetAssociatedNonWorkingDaySetNodeAndHierarchyDetails = (
    nonWorkingDaySetId: number,
    hierarchyAssignableFlag: HierarchyAssignableFlag
): [
    UseQueryResult<NonWorkingDaySetDto, HTTPError>,
    UseQueryResult<HierarchyTypeDto[], HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getNonWorkingDaySet", nonWorkingDaySetId],
                queryFn: () => getNonWorkingDaySet(url.baseUrl, nonWorkingDaySetId),
                select: toNonWorkingDaySetDto,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["filterHierarchyTypes"],
                queryFn: () =>
                    filterHierarchyTypes(
                        url.baseUrl,
                        createFilterHierarchyTypesRequest(createBasePaginationRequest(1, 1000), {
                            ...emptySearchHierarchyTypesRequest(),
                            hierarchyAssignableFlag,
                        })
                    ),
                select: toHierarchyTypeDtos,
            },
        ],
    }) as [
        UseQueryResult<NonWorkingDaySetDto, HTTPError>,
        UseQueryResult<HierarchyTypeDto[], HTTPError>,
    ];
};

export const useAssociateNonWorkingDaySetNode = (): UseMutationResult<
    Response<number | null>,
    HTTPError,
    AssociateNonWorkingDaySetNodeDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: AssociateNonWorkingDaySetNodeDto) => {
        const request = new AssociateNonWorkingDaySetNodeRequest(mutationKey);

        return associateNode(url.baseUrl, request);
    });
};
