import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { editNonWorkingDay } from "../../../data/services/non-working-days/non-working-day-service";
import { Response } from "../../responses/common/response-response";

interface EditParameters {
    nonWorkingDayId: number;
    formData: FormData;
}

export const useEditNonWorkingDay = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    EditParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: EditParameters) => {
        const formData = mutationKey.formData;

        const request = {
            nonWorkingDayId: mutationKey.nonWorkingDayId,
            nonWorkingDayName: formData.get("name")! as string,
            date: new Date(formData.get("date")! as string),
        };

        return editNonWorkingDay(url.baseUrl, request);
    });
};
