import { ActionInstanceStatusResponse } from "../../responses/actions/action-instance-status-response";
import { Response } from "../../responses/common/response-response";

export interface ActionInstanceStatusDto {
    actionInstanceStatusId: number;
    name: string;
}

export const createActionInstanceStatusDto = (
    actionInstanceStatusId: number,
    name: string
): ActionInstanceStatusDto => ({
    actionInstanceStatusId: actionInstanceStatusId,
    name: name,
});

export const createActionInstanceStatusDtoFromResponse = (
    response: ActionInstanceStatusResponse
): ActionInstanceStatusDto => ({
    ...response,
});

export const toActionInstanceStatusDtos = (
    response: Response<ActionInstanceStatusResponse[]>
): ActionInstanceStatusDto[] =>
    response.data?.map((x) => createActionInstanceStatusDtoFromResponse(x));
