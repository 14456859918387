import { DissociateQuestionSetQuestionDetailsDto } from "../../dtos/question-sets/dissociate-question-set-question-details-dto";

export interface DissociateQuestionSetQuestionRequest {
    questionSetId: number;
    questionIds: number[];
}

export const createDissociateQuestionSetQuestionRequest = (
    dto: DissociateQuestionSetQuestionDetailsDto
): DissociateQuestionSetQuestionRequest => ({ ...dto });
