import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { NodeResponse } from "../../responses/hierarchy/node-response";
import {
    createNonWorkingDaySetDto,
    NonWorkingDaySetDto,
} from "../non-working-days/non-working-day-set-dto";
import { createBaseNodeDetailsDto } from "./base-node-details-dto";
import { BaseNodeDto, toBaseNodeDtos } from "./base-node-dto";

export interface NodeDto extends BaseNodeDto {
    legacyId: number | null;
    path: string | null;
    isTraining: boolean;
    isEditable: boolean;
    isSuspended: boolean;
    dateCreatedLocal?: string | null;
    dateModifiedLocal?: string | null;
    modifiedByUserName?: string | null;
    createdByUserName?: string | null;
    createdByUserFullName: string | null;
    modifiedByUserFullName: string | null;
    suspensionDateTimeFrom: Date | null;
    suspensionDateTimeTo: Date | null;
    isNonWorkingDaySetAssignable: boolean;
    isChecklistAssignable: boolean;
    isUserAssignable: boolean;
    nonWorkingDaySet: NonWorkingDaySetDto | null;
    parentNodes: BaseNodeDto[];
}

export const createNodeDto = (response: NodeResponse): NodeDto => ({
    ...createBaseNodeDetailsDto(response),
    legacyId: response.legacyId,
    isEditable: response.isEditable,
    isSuspended: response.isSuspended,
    dateCreatedLocal: toDateAndTimeFormat(response.dateCreatedUtc),
    dateModifiedLocal: toDateAndTimeFormat(response.dateModifiedUtc),
    createdByUserName: response.createdByUserName,
    modifiedByUserName: response.modifiedByUserName,
    createdByUserFullName: response.createdByUserFullName,
    modifiedByUserFullName: response.modifiedByUserFullName,
    suspensionDateTimeFrom:
        response.suspensionDateTimeFrom != null
            ? new Date(response.suspensionDateTimeFrom.toString())
            : null,
    suspensionDateTimeTo:
        response.suspensionDateTimeTo != null
            ? new Date(response.suspensionDateTimeTo.toString())
            : null,
    isNonWorkingDaySetAssignable: response.isNonWorkingDaySetAssignable,
    isChecklistAssignable: response.isChecklistAssignable,
    isUserAssignable: response.isUserAssignable,
    nonWorkingDaySet:
        response.nonWorkingDaySet != null
            ? createNonWorkingDaySetDto(response.nonWorkingDaySet)
            : null,
    parentNodes: toBaseNodeDtos(response.parentNodes),
});
