import { HTTPError } from "ky";
import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaUnlink } from "react-icons/fa";
import { useParams, useSearchParams } from "react-router-dom";
import { DissociateQuestionSetFromChecklistEvent } from "../../../core/constants/application-insights-events";
import { ChecklistQuestions, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import {
    useDissociateQuestionSet,
    useGetDissociateQuestionSetDetails,
} from "../../../domain/viewmodels/checklists/dissociate-existing-question-set-viewmodel";
import { SbButton } from "../../atoms/SbButton";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import { ActionConfirmationFragment } from "../../organisms/ActionConfirmationFragment";

const DissociateQuestionSetContainer: React.FC = () => {
    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const auth = useAuth();
    const [urlSearchParams] = useSearchParams();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const checklistId = Number(useParams().checklistId);
    const questionSetId = Number(urlSearchParams.get("questionSetId"));

    const getDissociateQuestionSetDetails = useGetDissociateQuestionSetDetails(questionSetId);
    const dissociateQuestionSet = useDissociateQuestionSet();

    useLoader(
        isQueryLoading(getDissociateQuestionSetDetails) || isMutationLoading(dissociateQuestionSet),
        DissociateQuestionSetContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Checklists);
    }, []);

    const buildDissociateButton = (): ReactElement<typeof Button> => (
        <SbButton
            variant="danger"
            type="button"
            label={t("Dissociate", { keyPrefix: Common })}
            icon={FaUnlink}
            onClick={() => handleDissociateQuestionSet()}
        />
    );

    const handleDissociateQuestionSet = (): void => {
        let questionSetIds = [];
        questionSetIds.push(questionSetId);

        dissociateQuestionSet.mutate(
            { checklistId: checklistId, questionSetIds: questionSetIds },
            {
                onSuccess: async () => {
                    trackAppInsightsEvent(
                        auth.email,
                        window.location.href,
                        DissociateQuestionSetFromChecklistEvent
                    );

                    queryClient.invalidateQueries(["getAssociatedQuestionSets"]); // TODO: Is this needed?

                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "messageKey",
                            "SuccessfullyDissociatedQuestionSetFromChecklist"
                        ),
                    ];

                    navigateSearch(`${getPath(AccordionTitles.Checklists)}/${checklistId}`, params);
                },
                onError: (error: HTTPError) => {
                    trackAppInsightsException(
                        auth.email,
                        window.location.href,
                        DissociateQuestionSetFromChecklistEvent,
                        error
                    );
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    };

    const buildConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow(t("Name"), getDissociateQuestionSetDetails.data),
    ];

    return isQuerySuccessful(getDissociateQuestionSetDetails) ? (
        <ActionConfirmationFragment
            pageHeading={t("DissociateQuestionSetDisplay")}
            panelTitle={t("DissociateQuestionSetConfirmationDisplay")}
            rows={buildConfirmationRows()}
            primaryButton={buildDissociateButton()}
        />
    ) : (
        <></>
    );
};

export default DissociateQuestionSetContainer;
