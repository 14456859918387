import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { Response } from "../../responses/common/response-response";
import { WizardResponse } from "../../responses/wizards/wizard-response";

export interface WizardDetailDto {
    wizardId: number;
    name: string;
    dateCreatedLocal: string;
    sent: boolean;
    createdByUserId: number;
    createdByUserName: string;
}

export const toWizardDetailDto = (wizardResponse: WizardResponse): WizardDetailDto => ({
    wizardId: wizardResponse.wizardId,
    name: wizardResponse.name,
    dateCreatedLocal: toDateAndTimeFormat(wizardResponse.dateCreatedUtc.toString()),
    sent: wizardResponse.sent,
    createdByUserId: wizardResponse.createdByUserId,
    createdByUserName: wizardResponse.createdByUserName,
});

export const toWizardDetailDtoFromResponse = (
    response: Response<WizardResponse>
): WizardDetailDto => ({
    ...toWizardDetailDto(response.data),
});
