import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaSave } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import { createErrorToastProps, useToast } from "../../../core/store/toast-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isMutationLoading, isQueryLoading } from "../../../core/utilities/responseStateHelper";
import {
    useFilterTenants,
    useGetWizardDetails,
    usePublishWizard,
} from "../../../domain/viewmodels/wizards/publish-wizard-viewmodel";
import { CancelButton, SbButton } from "../../atoms/SbButton";
import SbFormCheckFieldGroup from "../../molecules/input/SbFormCheckFieldGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const StyledSubtitleText = styled.div`
    font-size: ${(props) => props.theme.font.md};
    color: ${(props) => props.theme.palette.secondary};
    padding-left: ${(props) => props.theme.padding.xxl};
    padding-top: ${(props) => props.theme.padding.lg};
    padding-bottom: ${(props) => props.theme.padding.lg};
`;

const PublishWizardContainer: React.FC = () => {
    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const toast = useToast();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: Common });

    const wizardId = Number(useParams().wizardId);

    const wizardDetail = useGetWizardDetails(wizardId);
    const filterTenants = useFilterTenants();
    const publishWizard = usePublishWizard();

    const [tenantIdsSelected, setTenantIdsSelected] = useState<number[]>([]);

    useLoader(
        isQueryLoading(filterTenants) || isMutationLoading(publishWizard),
        PublishWizardContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.WizardCards, AccordionTitles.Wizards);
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        if (tenantIdsSelected.length == 0) {
            toast.addToast(createErrorToastProps([t("WizardAtLeastOneTenantRequired")]));
            return;
        }

        publishWizard.mutate(
            {
                wizardId: wizardId,
                tenantIds: tenantIdsSelected,
            },
            {
                onSuccess: async () => {
                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter("messageKey", "WizardPublishSuccess"),
                    ];

                    navigateSearch(`${getPath(AccordionTitles.Wizards)}/${wizardId}`, params);
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    const onTenantCheck = (name: string, checked: boolean): void => {
        if (checked) {
            const tenantId = filterTenants.data!.find(
                (x) => x.tenantId.toString() === name
            )?.tenantId;
            tenantIdsSelected.push(tenantId!);
            setTenantIdsSelected(tenantIdsSelected);
        } else {
            const index = tenantIdsSelected.indexOf(Number(name), 0);
            tenantIdsSelected.splice(index, 1);
            setTenantIdsSelected(tenantIdsSelected);
        }
    };

    return (
        <>
            <PageHeading>Publish Wizard</PageHeading>
            <SectionVerticalSpace />
            <TextTitledPanel title="Are you sure you would like to publish the wizard?">
                <Form onSubmit={handleSubmit}>
                    <SbFormTextFieldGroup
                        name="name"
                        label="Wizard Name"
                        maxLength={255}
                        type="text"
                        defaultValue={wizardDetail.data?.name}
                        disabled
                    />

                    <StyledSubtitleText>Select tenants to send wizard to:</StyledSubtitleText>

                    {filterTenants.data!.map((tenant, index) => (
                        <SbFormCheckFieldGroup
                            key={`tenant${index}`}
                            fieldLabel={tenant.name}
                            type="checkbox"
                            values={[
                                {
                                    name: tenant.tenantId.toString(),
                                    onChangeHandler: onTenantCheck,
                                },
                            ]}
                        />
                    ))}

                    <EndAlignedDiv>
                        <SbButton variant="primary" type="submit" label="Publish" icon={FaSave} />
                        <CancelButton onClick={() => navigate(-1)} />
                    </EndAlignedDiv>
                </Form>
            </TextTitledPanel>
        </>
    );
};

export default PublishWizardContainer;
