import QuestionCaptureSetResponse from "../../responses/answer-capture/question-capture-set-response";
import QuestionCaptureDto from "./question-capture-dto";
import QuestionCaptureSetDetailsDto from "./question-capture-set-details-dto";

export default class QuestionCaptureSetDto {
    questionCaptureSetDetailsDto: QuestionCaptureSetDetailsDto;
    questionCaptureDtos: QuestionCaptureDto[];

    public constructor(response: QuestionCaptureSetResponse) {
        const questionCaptureResponses = response.questionCaptureResponses;

        this.questionCaptureSetDetailsDto = new QuestionCaptureSetDetailsDto(
            questionCaptureResponses
        );
        this.questionCaptureDtos =
            QuestionCaptureDto.toQuestionCaptureDtos(questionCaptureResponses);
    }

    public static constructFromResponses = (
        responses: QuestionCaptureSetResponse[]
    ): QuestionCaptureSetDto[] => responses.map((x) => new QuestionCaptureSetDto(x));
}
