import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { softDeleteImage } from "../../../data/services/image-library/image-library-service";
import { Response } from "../../responses/common/response-response";

export const useSoftDeleteImage = (): UseMutationResult<Response<boolean>, HTTPError, number> => {
    const url = useUrl();

    return useMutation((fileDetailsId: number) => softDeleteImage(url.baseUrl, fileDetailsId));
};
