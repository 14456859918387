import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { getHierarchyImportDetails } from "../../../data/services/hierarchy/hierarchy-import-service";
import HierarchyImportDetailsDto from "../../dtos/hierarchy/hierarchy-import-details-dto";

export const useGetHierarchyImportDetails = (
    hierarchyImportId: number
): UseQueryResult<HierarchyImportDetailsDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getHierarchyImportDetails", hierarchyImportId],
        () => getHierarchyImportDetails(url.baseUrl, hierarchyImportId),
        {
            select: HierarchyImportDetailsDto.constructFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};
