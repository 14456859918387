import { DissociateQuestionSetQuestionDetailsDto } from "./dissociate-question-set-question-details-dto";

export interface DissociateQuestionSetQuestionDto {
    questionSetQuestions: DissociateQuestionSetQuestionDetailsDto;
}

export const createDissociateQuestionSetQuestionDto = (
    questionSetQuestions: DissociateQuestionSetQuestionDetailsDto
): DissociateQuestionSetQuestionDto => ({
    questionSetQuestions: questionSetQuestions,
});
