import HierarchyImportHeaderResponse from "../../responses/data-import/hierarchy-import-header-response";
import HierarchyImportRowDto from "./hierarchy-import-row-dto";

export default class HierarchyImportHeaderDto {
    name: string;
    linkedNodeTypeId: number | null;
    nodeTypeId: number | null;
    columnId: number;
    columnOrder: number;
    error: string | null;
    userAssignable: boolean;
    geographyAssignable: boolean;
    rows: HierarchyImportRowDto[];

    public constructor(response: HierarchyImportHeaderResponse) {
        this.name = response.name;
        this.linkedNodeTypeId = response.linkedNodeTypeId;
        this.nodeTypeId = response.nodeTypeId;
        this.columnId = response.columnId;
        this.columnOrder = response.columnOrder;
        this.error = response.error;
        this.userAssignable = response.userAssignable;
        this.geographyAssignable = response.geographyAssignable;
        this.rows = HierarchyImportRowDto.constructFromResponses(response.rows);
    }

    public static constructFromResponses = (
        responses: HierarchyImportHeaderResponse[]
    ): HierarchyImportHeaderDto[] => responses.map((x) => new HierarchyImportHeaderDto(x));
}
