import { Response } from "../../responses/common/response-response";
import { NodeDetailsResponse } from "../../responses/hierarchy/node-details-response";

export interface DissociateNodeDetailsDto {
    nodeTypeName: string;
    nodeTypeValueValue: string;
    path: string;
}

export const createDissociateNodeDetailsDto = (
    response: Response<NodeDetailsResponse>
): DissociateNodeDetailsDto => {
    const responseData = response.data;

    return {
        nodeTypeName: responseData.node.nodeType.name,
        nodeTypeValueValue: responseData.node.nodeTypeValue?.value || "",
        path: responseData.node.path,
    };
};
