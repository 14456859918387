import { IPublicClientApplication, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { useEffect, useState } from "react";
import { createErrorToastProps, useToast } from "../../core/store/toast-context";
import { getEnvironment } from "../../core/utilities/environment";
import { getTenantIdentifier } from "../../core/utilities/tenant-identifier";
import { SecretsDto } from "../../domain/dtos/secrets/secrets-dto";
import { ControlFirstTenantIdentifier } from "../../domain/enums/common/TenantIdentifier";
import { SbButton } from "../atoms/SbButton";

interface AzureAdLoginApiRequest {
    scopes: string[];
}

const LoginAD: React.FC<{
    handleLoginAad: (token: string, account: any) => void;
    secrets: SecretsDto;
    success: boolean;
}> = ({ handleLoginAad, secrets, success }) => {
    const toast = useToast();
    const environment = getEnvironment();
    const tenantIdentifier = getTenantIdentifier(window.location.hostname);

    const [instance, setInstance] = useState<IPublicClientApplication | null>(null);
    const [loginApiRequest, setLoginApiRequest] = useState<AzureAdLoginApiRequest | null>(null);

    useEffect(() => {
        sessionStorage.clear();
    }, []);

    useEffect(() => {
        initializeMsalInstance(secrets);
    }, [secrets]);

    const initializeMsalInstance: (secrets: SecretsDto) => Promise<void> = async () => {
        if (secrets) {
            const scopeId = `api://${secrets.scopeId}`;

            const msalConfig = {
                auth: {
                    clientId: secrets.clientId,
                    tenantId: secrets.tenantId,
                    authority: secrets.authority,
                    redirectUri: secrets.redirectUri,
                },
                cache: {
                    cacheLocation: "sessionStorage", // This configures where your cache will be stored
                    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
                    claimsBasedCachingEnabled: true,
                },
                system: {
                    loggerOptions: {
                        loggerCallback: (level: any, message: string, containsPii: any) => {
                            if (containsPii) {
                                return;
                            }
                            if (process.env.REACT_APP_ENV !== "local") {
                                return;
                            }
                            switch (level) {
                                case LogLevel.Error:
                                    console.error(message);
                                    return;
                                case LogLevel.Info:
                                    console.info(message);
                                    return;
                                case LogLevel.Verbose:
                                    console.debug(message);
                                    return;
                                case LogLevel.Warning:
                                    console.warn(message);
                                    return;
                            }
                        },
                    },
                },
            };

            const loginApiRequest = {
                scopes: [scopeId],
            };

            const msalInstance =
                await PublicClientApplication.createPublicClientApplication(msalConfig);

            setInstance(msalInstance);
            setLoginApiRequest(loginApiRequest);
        }
    };

    useEffect(() => {
        if (
            instance &&
            loginApiRequest &&
            environment === "prod" &&
            tenantIdentifier !== ControlFirstTenantIdentifier.ICapture
            // || environment === "local" // uncomment this for local testing
        ) {
            loginWithAzureAD();
        }
    }, [instance, loginApiRequest]);

    const loginWithAzureAD = async (): Promise<void> => {
        if (!instance || !loginApiRequest) {
            toast.addToast(createErrorToastProps(["Something went wrong"]));
            return;
        }

        // TODO: In PROD, this needs to be a `redirect` as there is no login screen in PROD (except iCapture)
        // This is quite low priority and would argue to only implement if Abdiha requests differently
        // Login popup has been the de facto method and works
        instance
            .loginPopup(loginApiRequest)
            .then((response) => {
                const accessTokenTk = response.accessToken;
                handleLoginAad(accessTokenTk, response.account);
            })
            .catch((err) => {
                console.error(err);
                toast.addToast(createErrorToastProps(["Could not validate credentials"]));
            });
    };

    return (
        <SbButton
            variant={"primary"}
            type={"button"}
            label={
                tenantIdentifier === ControlFirstTenantIdentifier.Liberty
                    ? "Liberty Active Directory"
                    : "Standard Bank Active Directory"
            }
            onClick={loginWithAzureAD}
            disabled={!success}
        />
    );
};

export default LoginAD;
