import { FilterAnswerTypesDto } from "../../dtos/answer-types/filter-answer-types-dto";
import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
} from "../common/base-pagination-request";

export interface FilterAnswerTypesRequest extends BasePaginationRequest {
    name: string | null;
    answerCategoryId: number | null;
}

export const createFilterAnswerTypesRequestFromDto = (
    dto: FilterAnswerTypesDto
): FilterAnswerTypesRequest => ({
    pageNumber: dto.pageNumber,
    pageSize: dto.pageSize,
    name: dto.name,
    answerCategoryId: dto.answerCategoryId,
});

export const buildFilterAnswerTypesSearchParameters = (
    request: FilterAnswerTypesRequest
): URLSearchParams => {
    const searchParams = buildBasePaginationSearchParameters(request);

    if (request.name) {
        searchParams.set("name", request.name);
    }

    if (request.answerCategoryId) {
        searchParams.set("answerCategoryId", request.answerCategoryId.toString());
    }

    return searchParams;
};
