import { Response } from "../../responses/common/response-response";
import { ReviewReportSetTypeFieldsValueResponse } from "../../responses/review-reports/review-report-set-type-fields-value-response";
import { ReviewTypeFieldDto } from "./review-type-field-dto";

export class ReviewReportSetTypeFieldsValueDto {
    reviewReportSetTypeFieldsValueId: number;
    reviewTypeFieldsId: number;
    reviewReportSetId: number;
    value: string;
    reviewTypeFieldDto: ReviewTypeFieldDto;

    public constructor(
        reviewReportSetTypeFieldsValueResponse: ReviewReportSetTypeFieldsValueResponse
    ) {
        this.reviewReportSetTypeFieldsValueId =
            reviewReportSetTypeFieldsValueResponse.reviewReportSetTypeFieldsValueId;
        this.reviewTypeFieldsId = reviewReportSetTypeFieldsValueResponse.reviewTypeFieldsId;
        this.reviewReportSetId = reviewReportSetTypeFieldsValueResponse.reviewReportSetId;
        this.value = reviewReportSetTypeFieldsValueResponse.value;
        this.reviewTypeFieldDto = new ReviewTypeFieldDto(
            reviewReportSetTypeFieldsValueResponse.reviewTypeFieldResponse
        );
    }

    public static constructFromResponse = (
        response: Response<ReviewReportSetTypeFieldsValueResponse>
    ): ReviewReportSetTypeFieldsValueDto => new ReviewReportSetTypeFieldsValueDto(response.data);

    public static toReviewReportSetTypeFieldsValueDtos = (
        response: ReviewReportSetTypeFieldsValueResponse[]
    ): ReviewReportSetTypeFieldsValueDto[] =>
        response!.map((x) => new ReviewReportSetTypeFieldsValueDto(x));
}
