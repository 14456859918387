import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    deleteQuestionSet,
    getQuestionSetDetails,
} from "../../../data/services/question-set/question-set-service";
import {
    createQuestionSetDetailsDto,
    QuestionSetDetailsDto,
} from "../../dtos/question-sets/question-set-details-dto";
import { Response } from "../../responses/common/response-response";

interface DeleteParameters {
    questionSetId: number;
}

export const useDeleteQuestionSet = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    DeleteParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: DeleteParameters) =>
        deleteQuestionSet(url.baseUrl, mutationKey.questionSetId)
    );
};

export const useGetQuestionSetDetails = (
    questionSetId: number
): UseQueryResult<QuestionSetDetailsDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getQuestionSetDetails", questionSetId],
        () => getQuestionSetDetails(url.baseUrl, questionSetId),
        {
            select: createQuestionSetDetailsDto,
            onError: errorResponseToDisplayHandler,
        }
    );
};
