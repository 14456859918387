import QuestionImportance from "../../enums/questions/question-importance";
import { QuestionAnswerDetailsResponse } from "../../responses/answer-capture/question-answer-details-response";
import { Response } from "../../responses/common/response-response";
import ReferentialLinkAndTypeValuesDto from "../referential-links/referential-link-and-type-values-dto";

export default class QuestionAnswerDetailsDto {
    questionImportance: string;
    questionCategory: string;
    path: string;
    assignedUserEmailsDelimitered: string;
    escalationLevelThreeUserEmailsDelimitered: string;
    escalationLevelTwoUserEmailsDelimitered: string;
    escalationLevelOneUserEmailsDelimitered: string;
    referentialLinkAndTypeValuesDtos: ReferentialLinkAndTypeValuesDto[];

    public constructor(response: QuestionAnswerDetailsResponse) {
        const {
            questionImportance,
            questionCategoryResponse,
            path,
            assignedUserResponses,
            escalationLevelThreeUserResponses,
            escalationLevelTwoUserResponses,
            escalationLevelOneUserResponses,
            referentialLinkResponses,
        } = response;

        this.questionImportance = QuestionImportance[questionImportance];
        this.questionCategory = questionCategoryResponse.name;
        this.path = path;
        this.assignedUserEmailsDelimitered = assignedUserResponses
            .map((x) => x.emailAddress)
            .join(", ");
        this.escalationLevelThreeUserEmailsDelimitered = escalationLevelThreeUserResponses
            .map((x) => x.emailAddress)
            .join(", ");
        this.escalationLevelTwoUserEmailsDelimitered = escalationLevelTwoUserResponses
            .map((x) => x.emailAddress)
            .join(", ");
        this.escalationLevelOneUserEmailsDelimitered = escalationLevelOneUserResponses
            .map((x) => x.emailAddress)
            .join(", ");
        this.referentialLinkAndTypeValuesDtos =
            ReferentialLinkAndTypeValuesDto.toReferentialLinkAndTypeValuesDto(
                referentialLinkResponses
            );
    }

    public static constructFromResponse = (
        response: Response<QuestionAnswerDetailsResponse>
    ): QuestionAnswerDetailsDto => {
        const responseData = response.data;

        return new QuestionAnswerDetailsDto(responseData);
    };
}
