import QuestionAnswerFileUploadDto from "../../dtos/question-set-instance-answers/question-answer-file-upload-dto";
import FileDetailsUploadRequest from "../file-storage/file-details-upload-request";
import BaseQuestionAnswerFileUploadRequest from "./base-question-answer-file-upload-request";

export default class NestedQuestionAnswerFileUploadRequest extends BaseQuestionAnswerFileUploadRequest {
    questionSetInstanceAnswerStagingId: number;

    public constructor(
        questionSetInstanceAnswerStagingId: number,
        fileDetailsUploadRequests: FileDetailsUploadRequest[]
    ) {
        super(fileDetailsUploadRequests);

        this.questionSetInstanceAnswerStagingId = questionSetInstanceAnswerStagingId;
    }

    public static constructFromQuestionAnswerFileUploadDto = (
        dto: QuestionAnswerFileUploadDto
    ): NestedQuestionAnswerFileUploadRequest => {
        const { questionSetInstanceAnswerEntityId } = dto;

        return new NestedQuestionAnswerFileUploadRequest(questionSetInstanceAnswerEntityId, [
            FileDetailsUploadRequest.constructFromFileUploadDto(dto),
        ]);
    };
}
