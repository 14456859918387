import AgreedActionStatus from "../../enums/review-reports/agreed-action-status";
import { Response } from "../../responses/common/response-response";
import { AgreedActionResponse } from "../../responses/review-reports/agreed-action-response";
import { AgreedActionRaciUserDto } from "./agreed-action-raci-set-user-dto";

export class AgreedActionDto {
    agreedActionId: number;
    questionSetInstanceReviewId: number;
    actionItemDueDate: Date;
    agreedActionValue: string;
    agreedActionStatusId: AgreedActionStatus;
    agreedActionRaciUserDtos: AgreedActionRaciUserDto[];

    public constructor(agreedActionResponse: AgreedActionResponse) {
        this.agreedActionId = agreedActionResponse.agreedActionId;
        this.questionSetInstanceReviewId = agreedActionResponse.questionSetInstanceReviewId;
        this.actionItemDueDate = agreedActionResponse.actionItemDueDate;
        this.agreedActionValue = agreedActionResponse.agreedActionValue;
        this.agreedActionStatusId = agreedActionResponse.agreedActionStatusId;
        this.agreedActionRaciUserDtos = AgreedActionRaciUserDto.toAgreedActionRaciUserDtos(
            agreedActionResponse.agreedActionRaciUserResponses
        );
    }

    public static constructFromResponse = (
        response: Response<AgreedActionResponse>
    ): AgreedActionDto => new AgreedActionDto(response.data);

    public static toAgreedActionDtos = (responses: AgreedActionResponse[]): AgreedActionDto[] =>
        responses?.map((x) => new AgreedActionDto(x));
}
