import { useMutation, UseMutationResult, useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { getInheritedChecklistAssignments } from "../../../data/services/checklist-assignment/checklist-assignment-service";
import {
    getNodeDescriptionDetails,
    moveNode,
} from "../../../data/services/hierarchy/nodes-service";
import InheritedChecklistAssignmentDto from "../../dtos/checklist-assignment/inherited-checklist-assignment-dto";
import {
    createNodeDescriptionDetailsDtoFromResponse,
    NodeDescriptionDetailsDto,
} from "../../dtos/hierarchy/node-description-details-dto";
import { createMoveNodeRequest } from "../../requests/hierarchy/move-node-request";
import { Response } from "../../responses/common/response-response";

export const useGetMoveNodeDetails = (
    candidateNodeId: number,
    destinationNodeId: number
): [
    UseQueryResult<NodeDescriptionDetailsDto, HTTPError>,
    UseQueryResult<NodeDescriptionDetailsDto, HTTPError>,
    UseQueryResult<InheritedChecklistAssignmentDto[], HTTPError>,
    UseQueryResult<InheritedChecklistAssignmentDto[], HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getNodeDescriptionDetails", candidateNodeId],
                queryFn: () => getNodeDescriptionDetails(url.baseUrl, candidateNodeId),
                select: createNodeDescriptionDetailsDtoFromResponse,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getNodeDescriptionDetails", destinationNodeId],
                queryFn: () => getNodeDescriptionDetails(url.baseUrl, destinationNodeId),
                select: createNodeDescriptionDetailsDtoFromResponse,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getInheritedChecklistAssignments", candidateNodeId],
                queryFn: () => getInheritedChecklistAssignments(url.baseUrl, candidateNodeId),
                select: InheritedChecklistAssignmentDto.constructFromInheritedChecklistAssignmentResponse,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getInheritedChecklistAssignments", destinationNodeId],
                queryFn: () => getInheritedChecklistAssignments(url.baseUrl, destinationNodeId),
                select: InheritedChecklistAssignmentDto.constructFromInheritedChecklistAssignmentResponse,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<NodeDescriptionDetailsDto, HTTPError>,
        UseQueryResult<NodeDescriptionDetailsDto, HTTPError>,
        UseQueryResult<InheritedChecklistAssignmentDto[], HTTPError>,
        UseQueryResult<InheritedChecklistAssignmentDto[], HTTPError>,
    ];
};

interface MoveParameters {
    candidateNodeId: number;
    destinationNodeId: number;
}

export const useMoveNode = (): UseMutationResult<Response<boolean>, HTTPError, MoveParameters> => {
    const url = useUrl();

    return useMutation((mutationKey: MoveParameters) => {
        const { candidateNodeId, destinationNodeId } = mutationKey;
        const request = createMoveNodeRequest(candidateNodeId, destinationNodeId);

        return moveNode(url.baseUrl, request);
    });
};
