import FileDetailsUploadRequest from "../file-storage/file-details-upload-request";

export interface UploadActionPlanFileRequest {
    actionPlanId: Number;
    fileDetailsUploadRequest: FileDetailsUploadRequest;
}

export const createUploadActionPlanFileRequest = (
    actionPlanId: Number,
    uploadFileDetailsRequest: FileDetailsUploadRequest
): UploadActionPlanFileRequest => ({
    actionPlanId: actionPlanId,
    fileDetailsUploadRequest: uploadFileDetailsRequest,
});
