import { ChecklistAssignmentChecklistResponse } from "../../responses/checklists/checklist-assignment-checklist-response";
import BaseChecklistAssignmentDto from "../checklist-assignment/base-checklist-assignment-dto";
import BaseChecklistDto from "./base-checklist-dto";

export default class ChecklistAssignmentChecklistDto {
    checklistAssignmentDto: BaseChecklistAssignmentDto;
    checklistDto: BaseChecklistDto;

    public constructor(response: ChecklistAssignmentChecklistResponse) {
        this.checklistAssignmentDto = new BaseChecklistAssignmentDto(
            response.checklistAssignmentResponse
        );
        this.checklistDto = new BaseChecklistDto(response.checklistResponse);
    }
}
