export interface AssociateDissociateReferentialLinkToChecklistRequest {
    checklistId: number;
    referentialLinkIds: number[];
}

export const createAssociateDissociateReferentialLinkToChecklistRequest = (
    checklistId: number,
    referentialLinkIds: number[]
): AssociateDissociateReferentialLinkToChecklistRequest => ({
    checklistId: checklistId,
    referentialLinkIds: referentialLinkIds,
});
