import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import ReviewRating from "../../enums/review-reports/review-ratings";
import { Response } from "../../responses/common/response-response";
import { QuestionSetInstanceResponse } from "../../responses/review-reports/question-set-instance-response";
import { BaseNodeDetailsDto } from "../hierarchy/base-node-details-dto";
import { BaseUserDto } from "../users/base-user-dto";
import { QuestionSetQuestionCommentDto } from "./question-set-question-comment-dto";

export class QuestionSetInstanceDto {
    questionSetInstanceId: number;
    dateCreatedLocal: string;
    adhocTriggerUser: BaseUserDto;
    assignedNode: BaseNodeDetailsDto;
    reviewRating: ReviewRating;
    questionSetQuestionCommentDtos: QuestionSetQuestionCommentDto[];

    public constructor(questionSetInstanceResponse: QuestionSetInstanceResponse) {
        this.questionSetInstanceId = questionSetInstanceResponse.questionSetInstanceId;
        this.dateCreatedLocal = toDateAndTimeFormat(
            questionSetInstanceResponse.dateCreatedUtc.toString()
        );
        this.adhocTriggerUser = questionSetInstanceResponse.adhocTriggerUser;
        this.assignedNode = questionSetInstanceResponse.assignedNode;
        this.reviewRating = questionSetInstanceResponse.reviewRating;
        this.questionSetQuestionCommentDtos =
            QuestionSetQuestionCommentDto.toQuestionSetQuestionCommentDtoDtos(
                questionSetInstanceResponse.questionSetQuestionCommentResponses
            );
    }

    public static constructFromResponse = (
        response: Response<QuestionSetInstanceResponse>
    ): QuestionSetInstanceDto => new QuestionSetInstanceDto(response.data);
}
