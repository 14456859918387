import { FilterNonWorkingDaySetDto } from "../../dtos/non-working-days/filter-non-working-day-set-dto";
import {
    buildPaginationSearchParameters,
    createPaginationRequest,
    PaginationRequest,
} from "../common/pagination-request";
import {
    buildSearchNonWorkingDaySetSearchParameters,
    SearchNonWorkingDaySetRequest,
} from "./search-non-working-day-sets-request";

export interface FilterNonWorkingDaySetRequest
    extends SearchNonWorkingDaySetRequest,
        PaginationRequest {}

export const createFilterNonWorkingDaySetRequest = (
    nonWorkingDaySetName: string | null,
    nonWorkingDayName: string | null,
    date: string | null,
    pageNumber: number,
    pageSize: number,
    sortByColumn: string | null,
    sortByDescending: boolean
): FilterNonWorkingDaySetRequest => ({
    nonWorkingDaySetName: nonWorkingDaySetName,
    nonWorkingDayName: nonWorkingDayName,
    date: date,
    ...createPaginationRequest(pageNumber, pageSize, sortByColumn, sortByDescending),
});

export const createFilterNonWorkingDaySetRequestFromDto = (
    dto: FilterNonWorkingDaySetDto
): FilterNonWorkingDaySetRequest => {
    const { pageNumber, pageSize, sortByColumn, sortByDescending } = dto;

    return {
        ...dto,
        ...createPaginationRequest(pageNumber, pageSize, sortByColumn, sortByDescending),
    };
};

export const buildFilterSearchParameters = (
    request: FilterNonWorkingDaySetRequest
): URLSearchParams => {
    const searchParams = buildPaginationSearchParameters(request);
    buildSearchNonWorkingDaySetSearchParameters(request, searchParams);

    return searchParams;
};
