import { Response } from "../../responses/common/response-response";
import { UserDetailsResponse } from "../../responses/users/user-details-response";
import { createBaseUserDtoFromResponse } from "./base-user-dto";
import { RoleDto } from "./role-dto";
import { UserDto } from "./user-dto";
import {
    EditUserRoleDto,
    toEditUserRoleDto,
    toViewUserRoleDto,
    ViewUserRoleDto,
} from "./user-role-dto";

export interface ViewUserDetailsDto extends UserDto {
    roles: ViewUserRoleDto[];
    lastLoginDateUtc: Date;
}

export interface EditUserDetailsDto extends UserDto {
    roles: EditUserRoleDto[];
}

export const toViewUserDetailsDto = (
    response: Response<UserDetailsResponse>
): ViewUserDetailsDto => {
    const responseData = response.data;

    return {
        ...responseData,
        ...createBaseUserDtoFromResponse(responseData),
        dateCreatedUtc: responseData.dateCreatedUtc,
        dateModifiedUtc: responseData.dateModifiedUtc,
        roles: responseData.roles.map((y) => toViewUserRoleDto(y)),
        lastLoginDateUtc: responseData.lastLoginDateUtc,
    };
};

export const toEditUserDetailsDto = (
    response: UserDetailsResponse,
    allRoles: RoleDto[]
): EditUserDetailsDto => {
    const userRoleIds = response.roles.map((y) => y.roleId);

    return {
        ...response,
        ...createBaseUserDtoFromResponse(response),
        roles: allRoles.map((x) =>
            toEditUserRoleDto(
                x,
                userRoleIds.some((y) => y === x.roleId)
            )
        ),
    };
};
