export default class LocalStorageHelper {
    public static getLanguageCulture = (): string => {
        const { locale: browserLocale } = Intl.DateTimeFormat().resolvedOptions();

        return localStorage.getItem("i18nextLng") ?? browserLocale;
    };

    public static getIanaTimeZoneId = (): string => {
        const { timeZone: browserTimeZone } = Intl.DateTimeFormat().resolvedOptions();

        return localStorage.getItem("ianaTimeZoneId") ?? browserTimeZone;
    };
}
