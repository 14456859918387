import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { ChecklistAnswers, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import { createErrorToastProps, useToast } from "../../../core/store/toast-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { getEnumsForType } from "../../../core/utilities/enum-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isMutationLoading,
} from "../../../core/utilities/responseStateHelper";
import { createReactSelectDto, ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import { createEditPredefinedAnswerDto } from "../../../domain/dtos/predefined-answers/edit-predefined-answer-dto";
import AnswerFlagMode from "../../../domain/enums/answer-types/answer-flag-mode";
import AnswerRenderMode from "../../../domain/enums/answer-types/answer-render-mode";
import { Response } from "../../../domain/responses/common/response-response";
import {
    useEditPredefinedAnswer,
    useGetAnswerTypeDetails,
    useGetPredefinedAnswerDetails,
} from "../../../domain/viewmodels/answer-types/edit-predefined-answer-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import { translateText } from "../../helpers/translate";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import SbFormTextAreaGroup from "../../molecules/input/SbFormTextAreaGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const answerFlagModesArray = getEnumsForType(AnswerFlagMode).map((x) =>
    createReactSelectDto(x, AnswerFlagMode[x])
);

const EditPredefinedAnswerContainer: React.FC = () => {
    const predefinedAnswerId = Number(useParams().predefinedAnswerId);
    const answerTypeId = Number(useParams().answerTypeId);
    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const navigate = useNavigate();
    const toast = useToast();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistAnswers });

    const [answerFlagMode, setAnswerFlagMode] = useState<AnswerFlagMode | null>(null);

    const getPredefinedAnswerDetails = useGetPredefinedAnswerDetails(predefinedAnswerId);
    const getAnswerTypeDetails = useGetAnswerTypeDetails(answerTypeId);
    const editPredefinedAnswer = useEditPredefinedAnswer();

    const predefinedAnswerDetailsResponseData = getPredefinedAnswerDetails.data;

    useLoader(
        areQueriesLoading([getPredefinedAnswerDetails, getAnswerTypeDetails]) ||
            isMutationLoading(editPredefinedAnswer),
        EditPredefinedAnswerContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.AnswerTypes);
    }, []);

    useEffect(() => {
        if (predefinedAnswerDetailsResponseData) {
            setAnswerFlagMode(predefinedAnswerDetailsResponseData.answerFlagMode);
        }
    }, [predefinedAnswerDetailsResponseData]);

    const onFlagModeSelected = (option: SingleValue<ReactSelectDto<AnswerFlagMode>>): void =>
        setAnswerFlagMode(option!.value);

    const navigateToViewPredefinedAnswerDetails = (): void => {
        navigate(
            `${getPath(AccordionTitles.AnswerTypes)}/${answerTypeId}${getPath(
                AccordionTitles.AnswerTypePredefinedAnswers
            )}/${predefinedAnswerId}`
        );
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        if (answerFlagMode == null || answerFlagMode < 1) {
            toast.addToast(createErrorToastProps([t("PredefinedAnswerFlagModeRequired")]));
            return;
        }

        editPredefinedAnswer.mutate(
            createEditPredefinedAnswerDto(
                predefinedAnswerId,
                answerFlagMode,
                new FormData(event.currentTarget)
            ),
            {
                onSuccess: async (_: Response<boolean>) => {
                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "messageKey",
                            "SuccessfullyUpdatedPredefinedAnswer"
                        ),
                    ];

                    navigateSearch(
                        `${getPath(AccordionTitles.AnswerTypes)}/${answerTypeId}${getPath(
                            AccordionTitles.AnswerTypePredefinedAnswers
                        )}/${predefinedAnswerId}`,
                        params
                    );
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    return (
        <>
            <PageHeading>{t("EditPredefinedAnswer")}</PageHeading>
            <SectionVerticalSpace />

            {areQueriesSuccessful([getPredefinedAnswerDetails, getAnswerTypeDetails]) && (
                <TextTitledPanel title={t("Edit", { keyPrefix: Common })}>
                    <Form onSubmit={handleSubmit}>
                        <SbFormSelectFieldGroup
                            name={"answerFlagMode"}
                            label={t("FlagMode")}
                            clearable={false}
                            searchable={false}
                            items={answerFlagModesArray ?? []}
                            itemDisplayText={(option: ReactSelectDto<AnswerFlagMode>) =>
                                translateText(t, option.label, ChecklistAnswers)
                            }
                            defaultSelectedItem={answerFlagModesArray.find(
                                (x) =>
                                    x.value === predefinedAnswerDetailsResponseData?.answerFlagMode
                            )}
                            onChange={onFlagModeSelected}
                            required
                        />

                        {getAnswerTypeDetails.data!.answerRenderMode ==
                            AnswerRenderMode.DropDown && (
                            <SbFormTextAreaGroup
                                name="tooltip"
                                label={t("Tooltip")}
                                maxLength={1000}
                                defaultValue={getPredefinedAnswerDetails.data!.tooltipDescription}
                            />
                        )}

                        <EndAlignedDiv>
                            <SaveButton type="submit" />
                            <CancelButton onClick={() => navigateToViewPredefinedAnswerDetails()} />
                        </EndAlignedDiv>
                    </Form>
                </TextTitledPanel>
            )}
        </>
    );
};

export default EditPredefinedAnswerContainer;
