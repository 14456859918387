import React, { useContext, useEffect, useState } from "react";
import { WizardCardDto } from "../../domain/dtos/wizards/wizard-card-dto";
import {
    createEmptyImageLibraryViewResponse,
    ImageLibraryViewResponse,
} from "../../domain/responses/image-library/image-library-view-response";

export interface WizardNotificationContextType {
    show: boolean;
    updateShow: (show: boolean) => void;
    wizardCards?: WizardCardDto[];
    onWizardCardsFetch: (fetchedCards: WizardCardDto[]) => void;
    onImageLibraryFetch: (fetchedImages: ImageLibraryViewResponse[]) => void;
    getImageLibraryImage: (fileDetailsId: number) => ImageLibraryViewResponse | undefined;
    activeWizardCardIndex: number;
    onActiveWizardCardIndexChange: (index: number) => void;
    isFinished: boolean;
    wizardNotificationStatus: number;
    onWizardNotificationStatusChange: (wizardNotificationStatus: number) => void;
}

const WizardNotificationContext = React.createContext<WizardNotificationContextType>({
    show: false,
    updateShow: (show: boolean) => {},
    wizardCards: [],
    onWizardCardsFetch: () => {},
    onImageLibraryFetch: () => {},
    getImageLibraryImage: () => createEmptyImageLibraryViewResponse,
    activeWizardCardIndex: 0,
    onActiveWizardCardIndexChange: () => {},
    isFinished: false,
    wizardNotificationStatus: 0,
    onWizardNotificationStatusChange: () => {},
});

export function WizardNotificationProvider({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element {
    const [modalShow, setModalShow] = useState(false);
    const [activeWizardCardIndex, setActiveWizardCardIndex] = useState(0);
    const [viewedWizardCardIndexes, setViewedWizardCardIndexes] = useState([0]);
    const [wizardCards, setWizardCards] = useState<WizardCardDto[]>();
    const [imageLibrary, setImageLibrary] = useState<ImageLibraryViewResponse[]>();
    const [wizardNotificationStatus, setWizardNotificationStatus] = useState(0);

    const updateShow = (show: boolean): void => {
        if (!show) {
            setActiveWizardCardIndex(0);
            setViewedWizardCardIndexes([0]);
            setWizardNotificationStatus(0);
        }

        setModalShow(show);
    };

    const onWizardCardsFetch = (fetchedCards: WizardCardDto[]): void =>
        setWizardCards(fetchedCards);

    const onImageLibraryFetch = (fetchedImages: ImageLibraryViewResponse[]): void =>
        setImageLibrary(fetchedImages);

    const getImageLibraryImage = (fileDetailsId: number): ImageLibraryViewResponse | undefined => {
        return imageLibrary?.find(
            (imageLibraryImage) => imageLibraryImage.fileDetailsId === fileDetailsId
        );
    };

    const onWizardNotificationStatusChange = (wizardNotificationStatus: number): void =>
        setWizardNotificationStatus(wizardNotificationStatus);

    const onActiveWizardCardIndexChange = (index: number): void => setActiveWizardCardIndex(index);

    useEffect(() => {
        const viewedWizardCardIndexesArr = [...viewedWizardCardIndexes];
        const value = activeWizardCardIndex;

        if (!viewedWizardCardIndexesArr.includes(value)) {
            viewedWizardCardIndexesArr.push(value);
            setViewedWizardCardIndexes(viewedWizardCardIndexesArr);
        }
    }, [activeWizardCardIndex]);

    const value = {
        show: modalShow,
        updateShow: updateShow,
        wizardCards: wizardCards,
        onWizardCardsFetch: onWizardCardsFetch,
        onImageLibraryFetch: onImageLibraryFetch,
        getImageLibraryImage: getImageLibraryImage,
        activeWizardCardIndex: activeWizardCardIndex,
        onActiveWizardCardIndexChange: onActiveWizardCardIndexChange,
        isFinished: viewedWizardCardIndexes.length === wizardCards?.length,
        wizardNotificationStatus: wizardNotificationStatus,
        onWizardNotificationStatusChange: onWizardNotificationStatusChange,
    };

    return (
        <WizardNotificationContext.Provider value={value}>
            {children}
        </WizardNotificationContext.Provider>
    );
}

export function useWizardNotification(): WizardNotificationContextType {
    return useContext(WizardNotificationContext);
}
