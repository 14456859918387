import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { Response } from "../../responses/common/response-response";
import { EmailQueueDetailsResponse } from "../../responses/email-queue/email-queue-details-response";

export class EmailQueueDetailsDto {
    emailQueueId: number;
    sent: boolean;
    toEmailAddress: string;
    subject: string;
    dateSentLocal: string;
    sendAttempts: number;
    body: string;
    errorMessage: string | null;

    public constructor(response: EmailQueueDetailsResponse) {
        const {
            emailQueueId,
            sent,
            toEmailAddress,
            subject,
            dateSentUtc,
            sendAttempts,
            body,
            errorMessage,
        } = response;

        this.emailQueueId = emailQueueId;
        this.sent = sent;
        this.toEmailAddress = toEmailAddress;
        this.subject = subject;
        this.dateSentLocal = dateSentUtc ? toDateAndTimeFormat(dateSentUtc?.toString()) : "";
        this.sendAttempts = sendAttempts;
        this.body = body;
        this.errorMessage = errorMessage;
    }

    public static constructFromResponse = (
        response: Response<EmailQueueDetailsResponse>
    ): EmailQueueDetailsDto => new EmailQueueDetailsDto(response.data);
}
