import EditChecklistAssignmentQuestionScheduleDto from "../../dtos/checklist-assignment/edit-checklist-assignment-question-schedule-dto";
import EditChecklistAssignmentQuestionSchedulesDto from "../../dtos/checklist-assignment/edit-checklist-assignment-question-schedules-dto";
import EditChecklistAssignmentQuestionScheduleRequest from "./edit-checklist-assignment-question-schedule-request";

export default class EditChecklistAssignmentQuestionSchedulesRequest {
    checklistAssignmentId: number;
    questionSetAssignmentScheduleId: number;
    editChecklistAssignmentQuestionScheduleRequests: EditChecklistAssignmentQuestionScheduleRequest[];

    public constructor(
        checklistAssignmentId: number,
        questionSetAssignmentScheduleId: number,
        editChecklistAssignmentQuestionScheduleRequests: EditChecklistAssignmentQuestionScheduleRequest[]
    ) {
        this.checklistAssignmentId = checklistAssignmentId;
        this.questionSetAssignmentScheduleId = questionSetAssignmentScheduleId;
        this.editChecklistAssignmentQuestionScheduleRequests =
            editChecklistAssignmentQuestionScheduleRequests;
    }

    public static constructFromDtos = (
        editChecklistAssignmentQuestionSchedulesDto: EditChecklistAssignmentQuestionSchedulesDto,
        editChecklistAssignmentQuestionScheduleDtos: EditChecklistAssignmentQuestionScheduleDto[]
    ): EditChecklistAssignmentQuestionSchedulesRequest => {
        const { checklistAssignmentId, questionSetAssignmentScheduleId } =
            editChecklistAssignmentQuestionSchedulesDto;

        return new EditChecklistAssignmentQuestionSchedulesRequest(
            checklistAssignmentId,
            questionSetAssignmentScheduleId,
            editChecklistAssignmentQuestionScheduleDtos.map((x) =>
                EditChecklistAssignmentQuestionScheduleRequest.constructFromEditChecklistAssignmentQuestionScheduleDto(
                    x
                )
            )
        );
    };
}
