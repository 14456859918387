import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { filterTenants } from "../../../data/services/tenants/tenant-service";
import { getWizardDetails, publishWizard } from "../../../data/services/wizards/wizards-service";
import { TenantDto, toTenantDtosFromResponse } from "../../dtos/tenants/tenant-dto";
import {
    toWizardDetailDtoFromResponse,
    WizardDetailDto,
} from "../../dtos/wizards/wizard-detail-dto";
import { createPaginationRequest } from "../../requests/common/pagination-request";
import { Response } from "../../responses/common/response-response";

interface PublishParameters {
    wizardId: number;
    tenantIds: number[];
}

export const useGetWizardDetails = (
    wizardId: number
): UseQueryResult<WizardDetailDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getWizardDetails", wizardId],
        () => getWizardDetails(url.originalBaseUrl, wizardId),
        {
            select: toWizardDetailDtoFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const usePublishWizard = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    PublishParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: PublishParameters) => {
        const request = {
            wizardId: mutationKey.wizardId,
            tenantIds: mutationKey.tenantIds,
        };

        return publishWizard(url.originalBaseUrl, request);
    });
};

export const useFilterTenants = (): UseQueryResult<TenantDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterTenants"],
        () => filterTenants(url.originalBaseUrl, createPaginationRequest(1, 10)),
        {
            select: toTenantDtosFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};
