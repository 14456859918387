import { createSearchParams, Path, useNavigate } from "react-router-dom";

export interface NavigateSearchParameter {
    variableName: string;
    value: string;
}

export const createNavigateSearchParameter = (
    variableName: string,
    value: string
): NavigateSearchParameter => ({
    variableName: variableName,
    value: value,
});

export const useNavigateSearch = (): ((
    pathname: string,
    params: NavigateSearchParameter[]
) => void) => {
    const navigate = useNavigate();

    return (pathname: string, params: NavigateSearchParameter[]) => {
        const path = createNavigateToForSearch(pathname, params);

        return navigate(path);
    };
};

export const createNavigateToForSearch = (
    pathname: string,
    params: NavigateSearchParameter[]
): Partial<Path> => {
    const searchParams = new URLSearchParams(params.map((x) => [x.variableName, x.value]));

    return { pathname, search: `?${createSearchParams(searchParams)}` };
};
