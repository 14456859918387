import ActionItemInstanceFileDownloadResponse from "../../responses/action-items/action-item-instance-file-download-response";
import ActionPlanFileDownloadResponse from "../../responses/actions/action-plan-file-download-response";
import QuestionSetInstanceAnswerFileDownloadResponse from "../../responses/answer-summary/question-set-instance-answer-file-download-response";
import { Response } from "../../responses/common/response-response";
import BaseFileDetailsResponse from "../../responses/file-storage/base-file-details-response";
import FileContentResponse from "../../responses/reports/file-content-response";
import ReviewReportSetFileDownloadResponse from "../../responses/review-reports/review-report-set-file-download-response";

// TODO: To extend FileDto
export class FileDownloadDto {
    file: string;
    fileName: string;
    fileType: string;

    public constructor(file: string, fileName: string, fileType: string) {
        this.file = file;
        this.fileName = fileName;
        this.fileType = fileType;
    }

    public static constructFromBaseFileDetailsResponse = (
        response: Response<BaseFileDetailsResponse>
    ): FileDownloadDto => {
        const { file, fileName, fileType } = response.data;

        return new FileDownloadDto(file, fileName, fileType);
    };

    public static constructFromFileContentResponse = (
        response: Response<FileContentResponse>
    ): FileDownloadDto => {
        const { fileContents, fileName, fileType } = response.data;

        return new FileDownloadDto(fileContents, fileName, fileType);
    };

    public static constructFromActionPlanFileDownloadResponse = (
        response: Response<ActionPlanFileDownloadResponse>
    ): FileDownloadDto => {
        const { file, fileName, fileType } = response.data;

        return new FileDownloadDto(file, fileName, fileType);
    };

    public static constructFromActionItemInstanceFileDownloadResponse = (
        response: Response<ActionItemInstanceFileDownloadResponse>
    ): FileDownloadDto => {
        const { file, fileName, fileType } = response.data;

        return new FileDownloadDto(file, fileName, fileType);
    };

    public static constructFromQuestionSetInstanceAnswerFileDownloadResponse = (
        response: Response<QuestionSetInstanceAnswerFileDownloadResponse>
    ): FileDownloadDto => {
        const { file, fileName, fileType } = response.data;

        return new FileDownloadDto(file, fileName, fileType);
    };

    public static constructFromReviewReportSetFileDownloadResponse = (
        response: Response<ReviewReportSetFileDownloadResponse>
    ): FileDownloadDto => {
        const { file, fileName, fileType } = response.data;

        return new FileDownloadDto(file, fileName, fileType);
    };

    public static constructFromFileDetailsResponse = (
        response: Response<BaseFileDetailsResponse>
    ): FileDownloadDto => {
        const { file, fileName, fileType } = response.data;

        return new FileDownloadDto(file, fileName, fileType);
    };
}
