import { useMutation, UseMutationResult, useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    deleteNodeType,
    getCanDeleteNodeType,
    getNodeTypeDetails,
} from "../../../data/services/hierarchy/node-types-service";
import { createValidationResponseDto, ValidationResponseDto } from "../../dtos/common/response-dto";
import {
    createNodeTypeDetailsDto,
    NodeTypeDetailsDto,
} from "../../dtos/hierarchy/node-type-details-dto";
import { Response } from "../../responses/common/response-response";

export const useGetNodeTypeDetails = (
    nodeTypeId: number
): [
    UseQueryResult<NodeTypeDetailsDto, HTTPError>,
    UseQueryResult<ValidationResponseDto<boolean>, HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getNodeTypeDetails", nodeTypeId],
                queryFn: () => getNodeTypeDetails(url.baseUrl, nodeTypeId),
                select: createNodeTypeDetailsDto,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getCanDeleteNodeType", nodeTypeId],
                queryFn: () => getCanDeleteNodeType(url.baseUrl, nodeTypeId),
                select: createValidationResponseDto,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<NodeTypeDetailsDto, HTTPError>,
        UseQueryResult<ValidationResponseDto<boolean>, HTTPError>,
    ];
};

export const useDeleteNodeType = (): UseMutationResult<Response<boolean>, HTTPError, number> => {
    const url = useUrl();

    return useMutation((nodeTypeId: number) => deleteNodeType(url.baseUrl, nodeTypeId));
};
