enum ReviewRating {
    All = 0,
    AcceptableRoomForImprovement = 1,
    Good = 2,
    NotRated = 3,
    Unsatisfactory = 4,
    UnsatisfactoryRoomForImprovement = 5,
}

export default ReviewRating;

export const toRatingLabel = (rating: ReviewRating): string => {
    switch (rating) {
        case ReviewRating.AcceptableRoomForImprovement:
            return "Acceptable Room For Improvement";
        case ReviewRating.Good:
            return "Good";
        case ReviewRating.NotRated:
            return "Not Rated";
        case ReviewRating.Unsatisfactory:
            return "Unsatisfactory";
        case ReviewRating.UnsatisfactoryRoomForImprovement:
            return "Unsatisfactory Room For Improvement";
        default:
            return "Not Rated";
    }
};
