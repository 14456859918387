import { ReactElement } from "react";
import styled from "styled-components";
import {
    StyledQuestionSetCaptureContainerProps,
    StyledVariantText,
} from "../../../core/theme/global-styles";
import QuestionCaptureDto from "../../../domain/dtos/answer-capture/question-capture-dto";
import QuestionCaptureSetDetailsDto from "../../../domain/dtos/answer-capture/question-capture-set-details-dto";
import QuestionCapture from "./question-capture";

const StyledQuestionCaptureSetContainer = styled.div<StyledQuestionSetCaptureContainerProps>`
    padding: ${(props) => props.theme.padding.md};
    position: relative;
    &:before {
        content: "";
        position: absolute;
        display: block;
        width: 15px;
        left: -10px;
        top: 17px;
        bottom: 32px;
        border: solid;
        border-width: 6px 0 6px 6px;
        border-color: ${(props) =>
            props.$isOverdue ? props.theme.palette.danger : props.theme.palette.primaryVariant};
    }
`;

const QuestionCaptureSet: React.FC<{
    questionCaptureSetDetailsDto: QuestionCaptureSetDetailsDto;
    questionCaptureDtos: QuestionCaptureDto[];
}> = ({ questionCaptureSetDetailsDto, questionCaptureDtos }) => {
    const { isOverdue, questionSetName } = questionCaptureSetDetailsDto;

    const buildQuestionSetTitle = (): ReactElement<HTMLParagraphElement> => (
        <StyledVariantText $variant="secondary">
            <strong>Process</strong> - {questionSetName}
        </StyledVariantText>
    );

    return (
        <StyledQuestionCaptureSetContainer $isOverdue={isOverdue}>
            {buildQuestionSetTitle()}
            {questionCaptureDtos.map((x, index) => (
                <QuestionCapture key={index} questionCaptureDto={x} />
            ))}
            {buildQuestionSetTitle()}
        </StyledQuestionCaptureSetContainer>
    );
};

export default QuestionCaptureSet;
