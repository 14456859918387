import React, { useEffect, useMemo } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useErrorResponseToDisplayHandler } from "../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../core/hooks/loaderManager";
import { useAuth } from "../../core/store/auth-context";
import { SectionVerticalSpace } from "../../core/theme/global-styles";
import { DrawerTitles } from "../../core/utilities/enums";
import { getEnvironment } from "../../core/utilities/environment";
import { getPath } from "../../core/utilities/getPath";
import {
    areMutationsLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../core/utilities/responseStateHelper";
import { useSessionStorageNavigation } from "../../core/utilities/session-storage-navigation";
import { getTenantIdentifier } from "../../core/utilities/tenant-identifier";
import { AadAuthenticationRequestDto } from "../../domain/dtos/auth/aad-authentication-request-dto";
import { AuthenticationRequestDto } from "../../domain/dtos/auth/authentication-request-dto";
import { ControlFirstTenantIdentifier } from "../../domain/enums/common/TenantIdentifier";
import { RefreshTokenResponse, TokenResponse } from "../../domain/responses/account/token-response";
import { Response } from "../../domain/responses/common/response-response";
import {
    useCreateRefreshToken,
    useCreateRefreshTokenAad,
    useHandleLoginData,
    useHandleRefreshTokenData,
    useLogin,
    useLoginAad,
} from "../../domain/viewmodels/account/authentication-viewmodel";
import { useSecrets } from "../../domain/viewmodels/secrets/secrets-viewmodel";
import { SbAlert } from "../atoms/SbAlert";
import { SbButton } from "../atoms/SbButton";
import LoginAD from "../molecules/LoginAD";
import LoginForm from "../molecules/LoginForm";

const StyledRow = styled(Row)`
    display: flex;
    justify-content: space-between;
`;

const StyledH2 = styled.h2`
    color: ${(props) => props.theme.palette.secondary};
`;

const StyledH5 = styled.h5`
    width: 100%;
    text-align: left;
    color: ${(props) => props.theme.palette.secondary};
`;

const StyledDivider = styled.div`
    height: ${(props) => props.theme.padding.lg};
`;

const Login: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const handleLoginData = useHandleLoginData();
    const handleRefreshTokenData = useHandleRefreshTokenData();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const tenantIdentifier = getTenantIdentifier(window.location.hostname);
    const environment = getEnvironment();

    const secrets = useSecrets(tenantIdentifier);
    const secretsData = secrets.data!;
    const login = useLogin();
    const loginAad = useLoginAad();
    const refreshToken = useCreateRefreshToken();
    const refreshTokenAad = useCreateRefreshTokenAad();
    const auth = useAuth();
    const sessionStorageNavigation = useSessionStorageNavigation();

    const { t } = useTranslation("translation", { keyPrefix: "user.login" });

    useMemo(() => {
        const state = location.state as { from: Location };

        if (state && state.from) {
            return state.from;
        }

        return tenantIdentifier === ControlFirstTenantIdentifier.Admin
            ? getPath(DrawerTitles.Dashboard)
            : getPath(DrawerTitles.AnswerSummary);
    }, [location]);

    useLoader(areMutationsLoading([login, loginAad]) || isQueryLoading(secrets), Login);

    useEffect(() => {
        if (auth.userName) {
            tenantIdentifier === ControlFirstTenantIdentifier.Admin
                ? navigate(getPath(DrawerTitles.Dashboard))
                : navigate(getPath(DrawerTitles.AnswerSummary));
        }
    }, []);

    const showLoginForm = (): boolean => {
        if (
            environment === "prod"
            // || environment === "local" // uncomment this for local testing
        ) {
            if (tenantIdentifier === ControlFirstTenantIdentifier.ICapture) {
                return true;
            }

            return false;
        } else {
            return true;
        }
    };

    const allowLogin = (): boolean => {
        if (environment === "prod" && tenantIdentifier === ControlFirstTenantIdentifier.Liberty) {
            return false;
        }

        return true;
    };

    const displayDecommissionedElements = (): boolean => {
        if (tenantIdentifier === ControlFirstTenantIdentifier.Liberty) {
            return true;
        }

        return false;
    };

    const handleLogin = (email: string, password: string) => {
        login.mutate(new AuthenticationRequestDto(email, password), {
            onSuccess: async (response: Response<TokenResponse>) => {
                handleLoginData(response.data);
                refreshToken.mutate(new AuthenticationRequestDto(email, password), {
                    onSuccess: async (response: Response<RefreshTokenResponse>) => {
                        handleRefreshTokenData(response.data);

                        sessionStorageNavigation();
                    },
                    onError: errorResponseToDisplayHandler,
                });
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    const handleLoginAad = (token: string, account: any): void => {
        loginAad.mutate(new AadAuthenticationRequestDto(token, account), {
            onSuccess: async (response: Response<TokenResponse>) => {
                handleLoginData(response.data);
                refreshTokenAad.mutate(new AadAuthenticationRequestDto(token, account), {
                    onSuccess: async (response: Response<RefreshTokenResponse>) => {
                        handleRefreshTokenData(response.data);

                        sessionStorageNavigation();
                    },
                    onError: errorResponseToDisplayHandler,
                });
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    const handleNavigationToSBGTenant = (): void => {
        window.open(process.env.REACT_APP_UI_URL_STANDARD_BANK);
    };

    return (
        <Container fluid>
            {displayDecommissionedElements() && (
                <>
                    <SbAlert variant={"warning"} text={t("LibertyTenanDecommissionedtWarning")} />
                    <SbButton
                        variant={"primary"}
                        type={"button"}
                        label={t("NavigateToSBGTenant")}
                        onClick={() => handleNavigationToSBGTenant()}
                    />
                    <SectionVerticalSpace />
                </>
            )}
            {allowLogin() && (
                <>
                    <StyledH2>{t("Login")}</StyledH2>
                    <StyledRow>
                        {showLoginForm() && (
                            <Col sm="5">
                                <StyledH5>{t("LogInToTheControlFirstSystem")}</StyledH5>
                                <StyledDivider />
                                <LoginForm handleLogin={handleLogin} />
                            </Col>
                        )}
                        <Col sm="4">
                            <StyledH5>{t("LoginUsingOtherProviderTitle")}</StyledH5>
                            <StyledDivider />
                            <LoginAD
                                handleLoginAad={handleLoginAad}
                                secrets={secretsData}
                                success={isQuerySuccessful(secrets)}
                            />
                        </Col>
                    </StyledRow>
                </>
            )}
        </Container>
    );
};

export default Login;
