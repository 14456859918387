import { ImageLibraryViewResponse } from "../../responses/image-library/image-library-view-response";

export interface ImageLibraryDto {
    fileDetailsId: number;
    fileStoreId: number;
    fileName: string;
    isActive: boolean;
    fileImagePath: string;
}

export const createImageLibraryDto = (
    imageLibraryResponse: ImageLibraryViewResponse
): ImageLibraryDto => ({
    fileDetailsId: imageLibraryResponse.fileDetailsId,
    fileStoreId: imageLibraryResponse.fileStoreId,
    fileName: imageLibraryResponse.fileName,
    isActive: imageLibraryResponse.isActive,
    fileImagePath: imageLibraryResponse.fileImagePath,
});
