import { NotificationTriggerDto } from "../notification-triggers/notification-trigger-dto";

export interface NotificationTriggerToggleDto extends NotificationTriggerDto {
    isEnabled: boolean;
}

export const createNotificationTriggerToggleDto = (
    notificationTriggerDto: NotificationTriggerDto
): NotificationTriggerToggleDto => ({
    notificationTriggerId: notificationTriggerDto.notificationTriggerId,
    name: notificationTriggerDto.name,
    triggerBefore: notificationTriggerDto.triggerBefore,
    triggeringDate: notificationTriggerDto.triggeringDate,
    triggerTimeDeltaIntervalDto: notificationTriggerDto.triggerTimeDeltaIntervalDto,
    sendWindowIntervalDto: notificationTriggerDto.sendWindowIntervalDto,
    isEnabled: true,
});

export const convertNotificationTriggersDtosToNotificationTriggersToggleDtos = (
    notificationTriggerDtos: NotificationTriggerDto[]
): NotificationTriggerToggleDto[] =>
    notificationTriggerDtos?.map((x) => createNotificationTriggerToggleDto(x));
