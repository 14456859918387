import DataStatus from "../../enums/common/DataStatus";
import InstanceStatus from "../../enums/questions/instance-status";

export default class FilterUserQuestionsDto {
    nodeId: number | null;
    answerStatus: InstanceStatus | null;
    checklistId: number | null;
    dataStatus: DataStatus | null;

    constructor(
        nodeId: number | null,
        checklistId: number | null,
        answerStatus: InstanceStatus | null,
        dataStatus: DataStatus | null
    ) {
        this.nodeId = nodeId;
        this.checklistId = checklistId;
        this.answerStatus = answerStatus;
        this.dataStatus = dataStatus;
    }

    public static emptyFilterUserQuestionsDto = new FilterUserQuestionsDto(null, null, null, null);
}
