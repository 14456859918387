import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ControlFirstTenantIdentifier } from "../../domain/enums/common/TenantIdentifier";
import { hasRoleTypeInGroup } from "../../domain/enums/Roles";
import { ChildItem, MenuItem } from "../utilities/customTypes";
import { DrawerTitles, NavbarTitles } from "../utilities/enums";
import { getPath } from "../utilities/getPath";
import { initialMenuItems } from "../utilities/initialMenuItems";
import { getTenantIdentifier } from "../utilities/tenant-identifier";
import { useAuth } from "./auth-context";

interface MenuContextType {
    animate: boolean;
    drawerOpen: boolean;
    navbarItems: MenuItem[];
    changeActiveNavbarItem: (title: string) => void;
    changeActiveAccordion: (title: string) => void;
    toggleDrawer: (drawerOpen: boolean) => void;
    drawerItems: MenuItem[];
    changeActiveDrawerItem: (title: string, accordionItemTitle?: string) => void;
    activeAccordion: string;
}

const MenuContext = React.createContext<MenuContextType>({
    animate: false,
    drawerOpen: true,
    navbarItems: [],
    changeActiveNavbarItem: () => {},
    changeActiveAccordion: () => {},
    toggleDrawer: () => {},
    drawerItems: [],
    changeActiveDrawerItem: () => {},
    activeAccordion: "",
});

export function MenuProvider({ children }: { children: React.ReactNode }): JSX.Element {
    const [drawerOpen, setDrawerOpen] = useState(true);
    const [animate, setAnimate] = useState(false);
    const [navbarItems, setNavbarItems] = useState<MenuItem[]>([]);
    const [drawerItems, setDrawerItems] = useState<MenuItem[]>([]);
    const [activeAccordion, setActiveAccordion] = useState("");

    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const tenantIdentifier = getTenantIdentifier(window.location.hostname);

    useEffect(() => {
        setNavbarItems([]);

        initialMenuItems.forEach((navbarItem) => {
            setNavbarItems((prevNavbarItems) => {
                const items = [...prevNavbarItems];

                const tenantOnlyItemAndIsAdmin = navbarItem.tenantOnly && auth.isAdmin;
                if (tenantOnlyItemAndIsAdmin === true) {
                    return items;
                }

                return [
                    ...items,
                    {
                        title: navbarItem.title,
                        isActive: navbarItem.isActive,
                    },
                ];
            });
        });

        if (auth.isAdmin) {
            changeActiveNavbarItem(NavbarTitles.Admin);
        }
    }, [auth.isAdmin]);

    useEffect(() => {
        let navigationTo =
            tenantIdentifier === ControlFirstTenantIdentifier.Admin
                ? getPath(DrawerTitles.Dashboard)
                : getPath(DrawerTitles.AnswerSummary);
        if (location.pathname !== "/" && location.pathname !== "/login") {
            navigationTo = location.pathname;
        }
        navigate(navigationTo);
    }, [auth.isImpersonating]);

    const setActiveItem = (
        prevItems: MenuItem[] | ChildItem[],
        title: string
    ): (MenuItem | ChildItem)[] => {
        const prevActiveItemIndex = prevItems.findIndex((item) => item.isActive === true);

        const currActiveItemIndex = prevItems.findIndex((item) => item.title === title);

        if (prevActiveItemIndex !== -1 && prevActiveItemIndex !== currActiveItemIndex)
            prevItems[prevActiveItemIndex].isActive = false;

        if (currActiveItemIndex !== -1) prevItems[currActiveItemIndex].isActive = true;

        const newItems = [...prevItems];
        return newItems;
    };

    const setActiveMenuItem = (
        prevMenuItems: MenuItem[],
        title: string
    ): (MenuItem | ChildItem)[] => {
        return setActiveItem(prevMenuItems, title);
    };

    const setActiveChildItem = (
        prevChildItems: ChildItem[] | undefined,
        title: string
    ): undefined | (MenuItem | ChildItem)[] => {
        if (!prevChildItems) {
            return;
        }

        return setActiveItem(prevChildItems, title);
    };

    const changeActiveNavbarItem = (title: string): void => {
        setNavbarItems((prevNavbarItems) => setActiveMenuItem(prevNavbarItems, title));

        const titleMatchIndex = initialMenuItems.findIndex(
            (navbarItem) => navbarItem.title === title
        );

        if (titleMatchIndex === -1) {
            return;
        }

        const newDrawerItems: MenuItem[] = [];
        initialMenuItems[titleMatchIndex]?.drawerItems?.forEach((drawerItem) => {
            const tenantOnlyItemAndIsAdmin =
                drawerItem.tenantOnly && auth.isAdmin && !auth.isImpersonating;
            if (
                tenantOnlyItemAndIsAdmin === true ||
                !hasRoleTypeInGroup(auth.userRoles, drawerItem.requiredRoles ?? [])
            ) {
                return;
            }

            const adminOnlyItemAndIsTenant =
                drawerItem.adminOnly && (!auth.isAdmin || auth.isImpersonating);
            if (adminOnlyItemAndIsTenant === true) {
                return;
            }

            const isAccordion = drawerItem.accordionItems !== undefined;
            const accordionItems = drawerItem.accordionItems?.filter((x) =>
                hasRoleTypeInGroup(auth.userRoles, x.requiredRoles ?? [])
            );

            if (isAccordion && !accordionItems?.length) {
                return;
            }

            newDrawerItems.push({
                title: drawerItem.title,
                isActive: drawerItem.isActive,
                children: accordionItems,
            });
        });
        setDrawerItems(newDrawerItems);
    };

    const changeActiveDrawerItem = (title: string, accordionItemTitle?: string): void => {
        if (accordionItemTitle) {
            setDrawerItems((prevDrawerItems) => {
                const newDrawerItems: MenuItem[] = [];
                prevDrawerItems.forEach((prevDrawerItem) => {
                    if (prevDrawerItem.title === title) {
                        newDrawerItems.push({
                            title: prevDrawerItem.title,
                            isActive: prevDrawerItem.isActive,
                            children: setActiveChildItem(
                                prevDrawerItem.children,
                                accordionItemTitle
                            ),
                        });
                    } else if (prevDrawerItem.children) {
                        const activeMatchIndex = prevDrawerItem.children.findIndex(
                            (childItem) => childItem.isActive === true
                        );

                        if (activeMatchIndex === -1) {
                            newDrawerItems.push(prevDrawerItem);
                        } else {
                            prevDrawerItem.children[activeMatchIndex].isActive = false;
                            newDrawerItems.push(prevDrawerItem);
                        }
                    } else {
                        newDrawerItems.push(prevDrawerItem);
                    }
                });

                return newDrawerItems;
            });
        } else {
            setDrawerItems((prevDrawerItems) => setActiveMenuItem(prevDrawerItems, title));
        }
    };

    const toggleDrawer = (drawerOpen: boolean): void => {
        if (!animate) {
            setAnimate(true);
        }

        setDrawerOpen(!drawerOpen);
    };

    const changeActiveAccordion = (title: string): void => {
        title === "" &&
            setDrawerItems((prevDrawerItems) => {
                const newDrawerItems: MenuItem[] = [];
                prevDrawerItems.forEach((prevDrawerItem) => {
                    if (prevDrawerItem.children) {
                        const activeMatchIndex = prevDrawerItem.children.findIndex(
                            (childItem) => childItem.isActive === true
                        );

                        if (activeMatchIndex === -1) {
                            newDrawerItems.push(prevDrawerItem);
                        } else {
                            prevDrawerItem.children[activeMatchIndex].isActive = false;
                            newDrawerItems.push(prevDrawerItem);
                        }
                    } else {
                        newDrawerItems.push(prevDrawerItem);
                    }
                });

                return newDrawerItems;
            });

        setActiveAccordion((prevActiveAccordion) =>
            prevActiveAccordion !== title ? title : prevActiveAccordion
        );
        changeActiveDrawerItem("");
    };

    const value = {
        animate,
        drawerOpen,
        navbarItems,
        changeActiveNavbarItem,
        changeActiveAccordion,
        toggleDrawer,
        drawerItems,
        changeActiveDrawerItem,
        activeAccordion,
    };

    return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
}

export function useMenu(): MenuContextType {
    return useContext(MenuContext);
}
