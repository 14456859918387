import { ActionItemTypeResponse } from "../../../domain/responses/action-items/action-item-type-response";
import { Response } from "../../../domain/responses/common/response-response";
import client from "../../http-client";

const route = "actionitemtypes";

export const getActionItemTypes = async (
    prefixUrl: string
): Promise<Response<ActionItemTypeResponse[]>> =>
    await client
        .get(route, {
            prefixUrl: prefixUrl,
        })
        .json();

export const getActionItemSubTypes = async (
    prefixUrl: string
): Promise<Response<ActionItemTypeResponse[]>> =>
    await client
        .get(`${route}/action-item-sub-types`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const getActionItemSubTypesByActionItemTypeNodeId = async (
    prefixUrl: string,
    actionItemTypeId: number
): Promise<Response<ActionItemTypeResponse[]>> =>
    await client
        .get(`${route}/action-item-sub-types-by-action-item-type-node-id/${actionItemTypeId}`, {
            prefixUrl: prefixUrl,
        })
        .json();
