import { useMutation, UseMutationResult, useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import {
    deletePredefinedAnswerText,
    filterPredefinedAnswerTexts,
    getPredefinedAnswerDetails,
} from "../../../data/services/answer-types/answer-types-service";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { PaginationDto } from "../../dtos/common/pagination-dto";
import PredefinedAnswerDto from "../../dtos/predefined-answers/predefined-answer-dto";
import { createFilterPredefinedAnswerTextsRequest } from "../../requests/answer-types/filter-predefined-answer-text-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { PredefinedAnswerTextResponse } from "../../responses/predefined-answers/predefined-answer-text-response";

export const usePredefinedAnswerDetails = (
    predefinedAnswerId: number,
    predefinedAnswerTextPaginationDto: PaginationDto
): [
    UseQueryResult<PredefinedAnswerDto, HTTPError>,
    UseQueryResult<PaginatedTableDto<number>, HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getPredefinedAnswerDetails"],
                queryFn: () => getPredefinedAnswerDetails(url.baseUrl, predefinedAnswerId),
                select: PredefinedAnswerDto.constructFromResponse,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: [
                    "filterPredefinedAnswerTexts",
                    predefinedAnswerId,
                    predefinedAnswerTextPaginationDto,
                ],
                queryFn: () =>
                    filterPredefinedAnswerTexts(
                        url.baseUrl,
                        createFilterPredefinedAnswerTextsRequest(
                            predefinedAnswerTextPaginationDto,
                            predefinedAnswerId
                        )
                    ),
                select: toDataTableRows,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<PredefinedAnswerDto, HTTPError>,
        UseQueryResult<PaginatedTableDto<number>, HTTPError>,
    ];
};

const toDataTableRows = (
    response: Response<PaginationResponse<PredefinedAnswerTextResponse>>
): PaginatedTableDto<number> => {
    const responseData = response.data;

    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((x) => ({
        metadata: x.predefinedAnswerTextId,
        columns: [
            {
                value: x.predefinedAnswerTextLanguageResponse.name,
                type: DataTableColumnTypes.Text,
            },
            {
                value: x.textValue,
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};

export const useDeletePredefinedAnswerText = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    number
> => {
    const url = useUrl();

    return useMutation((predefinedAnswerTextId: number) =>
        deletePredefinedAnswerText(url.baseUrl, predefinedAnswerTextId)
    );
};
