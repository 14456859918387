import { CreateQuestionTextDto } from "../../dtos/questions/create-question-text-dto";

export interface CreateAlternativeQuestionTextRequest {
    languageId: number;
    questionId: number;
    questionText: string;
}

export const createCreateAlternativeQuestionTextRequest = (
    dto: CreateQuestionTextDto
): CreateAlternativeQuestionTextRequest => ({
    questionId: dto.questionId,
    languageId: dto.languageId!,
    questionText: dto.questionText,
});
