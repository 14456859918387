import { UseMutationResult, UseQueryResult } from "@tanstack/react-query";

export const isQueryLoading = <TData, TError>(
    useQueryResult: UseQueryResult<TData, TError>
): boolean => useQueryResult.isFetching;

export const isMutationLoading = <TData, TError, TParams, TContext>(
    useMutationResult: UseMutationResult<TData, TError, TParams, TContext>
): boolean => useMutationResult.isLoading;

export const areQueriesLoading = (useQueryResults: UseQueryResult<any, any>[]): boolean =>
    useQueryResults.some((x) => isQueryLoading(x));

export const areMutationsLoading = (
    useMutationResults: UseMutationResult<any, any, any, any>[]
): boolean => useMutationResults.some((x) => isMutationLoading(x));

export const isQuerySuccessful = <TData, TError>(
    useQueryResult: UseQueryResult<TData, TError>
): boolean => useQueryResult.isSuccess;

export const areQueriesSuccessful = (useQueryResults: UseQueryResult<any, any>[]): boolean =>
    useQueryResults.every((x) => isQuerySuccessful(x));

export const areQueriesPartiallySuccessful = (
    useQueryResults: UseQueryResult<any, any>[]
): boolean => useQueryResults.some((x) => isQuerySuccessful(x));
