import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    cancelUserNodeSuspension,
    getUserNodeSuspensionDetails,
    suspendUserNode,
} from "../../../data/services/hierarchy/nodes-service";
import { UserNodeSuspensionDetailsDto } from "../../dtos/users/suspend-user-node-details-dto";
import { UserNodeSuspensionRequest } from "../../requests/users/suspend-user-from-all-nodes-request";
import { Response } from "../../responses/common/response-response";

export const useUserNodeSuspensionDetails = (
    userId: number
): UseQueryResult<UserNodeSuspensionDetailsDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["useUserNodeSuspensionDetails", userId],
        () => getUserNodeSuspensionDetails(url.baseUrl, userId),
        {
            select: UserNodeSuspensionDetailsDto.createUserNodeSuspensionDto,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useCancelSuspendUserFomNode = (): UseMutationResult<
    Response<boolean | null>,
    HTTPError,
    number
> => {
    const url = useUrl();

    return useMutation((mutationKey: number) => cancelUserNodeSuspension(url.baseUrl, mutationKey));
};

export const useSuspendUserFomNode = (): UseMutationResult<
    Response<boolean | null>,
    HTTPError,
    UserNodeSuspensionRequest
> => {
    const url = useUrl();

    return useMutation((mutationKey: UserNodeSuspensionRequest) =>
        suspendUserNode(url.baseUrl, mutationKey)
    );
};
