export interface DisassociateLinkedNodeTypeDto {
    primaryNodeTypeId: number;
    linkedNodeTypeId: number;
}

export const createDisassociateLinkedNodeTypeDto = (
    primaryNodeTypeId: number,
    linkedNodeTypeId: number
): DisassociateLinkedNodeTypeDto => ({
    primaryNodeTypeId: primaryNodeTypeId,
    linkedNodeTypeId: linkedNodeTypeId,
});
