import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { AccordionTitles, DataTableColumnTypes } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { filterHierarchyImport } from "../../../data/services/hierarchy/hierarchy-import-service";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { PaginationDto } from "../../dtos/common/pagination-dto";
import { FilterHierarchyImportDto } from "../../dtos/hierarchy/filter-hierarchy-import-dto";
import ImportStatus from "../../enums/hierarchy/ImportStatus";
import { createFilterHierarchyImportRequestFromDto } from "../../requests/hierarchy/filter-hierarchy-import-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import HierarchyImportResponse from "../../responses/hierarchy/hierarchy-import-response";

export const useFilterHierarchyImport = (
    filterDto: FilterHierarchyImportDto,
    paginationDto: PaginationDto
): UseQueryResult<PaginatedTableDto<number>, HTTPError> => {
    const url = useUrl();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterHierarchyImport", filterDto, paginationDto],
        () =>
            filterHierarchyImport(
                url.baseUrl,
                createFilterHierarchyImportRequestFromDto(filterDto, paginationDto)
            ),
        {
            keepPreviousData: true,
            select: (response: Response<PaginationResponse<HierarchyImportResponse>>) =>
                transformToDataTableRows(response, navigate),
            onError: errorResponseToDisplayHandler,
        }
    );
};

const transformToDataTableRows = (
    response: Response<PaginationResponse<HierarchyImportResponse>>,
    navigate: NavigateFunction
): PaginatedTableDto<number> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((x) => ({
        metadata: x.hierarchyImportId,
        columns: [
            {
                value: x.uploadedFileName,
                type: DataTableColumnTypes.Link,
                linkItemAction: (hierarchyImportId: number) => {
                    navigate(`${getPath(AccordionTitles.HierarchyImport)}/${hierarchyImportId}`);
                },
            },
            {
                value: x.userEmail,
                type: DataTableColumnTypes.Text,
            },
            {
                value: toDateAndTimeFormat(x.createdDate.toString()),
                type: DataTableColumnTypes.Text,
            },
            {
                value: toDateAndTimeFormat(x.completedDate?.toString() ?? ""),
                type: DataTableColumnTypes.Text,
            },
            {
                value: ImportStatus[x.importStatus],
                type: DataTableColumnTypes.Text,
            },
            {
                value: x.errors,
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
