import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";

export class BasePaginationRequest {
    pageNumber: number;
    pageSize: number;

    public constructor(pageNumber: number, pageSize: number) {
        this.pageNumber = pageNumber;
        this.pageSize = pageSize;
    }
}

export const createBasePaginationRequest = (
    pageNumber: number = 1,
    pageSize: number = 10
): BasePaginationRequest => ({
    pageNumber,
    pageSize,
});

export const createBasePaginationRequestFromDto = (
    basePaginationDto: BasePaginationDto
): BasePaginationRequest => ({
    ...basePaginationDto,
});

const PaginationParameters = {
    pageNumber: "pageNumber",
    pageSize: "pageSize",
};

export const buildBasePaginationSearchParameters = (
    request: BasePaginationRequest
): URLSearchParams => {
    const searchParams = new URLSearchParams();

    searchParams.set(PaginationParameters.pageNumber, request.pageNumber.toString());
    searchParams.set(PaginationParameters.pageSize, request.pageSize.toString());

    return searchParams;
};
