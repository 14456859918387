import { AssociateChildQuestionDto } from "../../dtos/questions/associate-child-question-dto";

export class AssociateChildQuestionRequest {
    parentQuestionId: number;
    childQuestionId: number;
    triggerWhenParentDesirable: boolean;

    public constructor(associateChildQuestionDto: AssociateChildQuestionDto) {
        this.parentQuestionId = associateChildQuestionDto.parentQuestionId;
        this.childQuestionId = associateChildQuestionDto.childQuestionId;
        this.triggerWhenParentDesirable = associateChildQuestionDto.triggerWhenParentDesirable;
    }
}
