import IntervalOverridesDto from "../../dtos/checklists/interval-overrides-dto";
import { IntervalRequest } from "../common/interval-request";

export default class IntervalOverridesRequest {
    dueDateTimeUtc: Date;
    openBeforeDueIntervalRequest: IntervalRequest;

    public constructor(dto: IntervalOverridesDto) {
        const { dueDateTimeUtc, openBeforeDueIntervalDto } = dto;

        this.dueDateTimeUtc = dueDateTimeUtc;
        this.openBeforeDueIntervalRequest =
            IntervalRequest.constructFromDto(openBeforeDueIntervalDto);
    }
}
