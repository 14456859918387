import { Col } from "react-bootstrap";
import { StyledFilterTitle } from "../../../core/theme/global-styles";
import SbCheckField from "../../atoms/input/SbCheckField";

export const FilterCheckbox: React.FC<{
    title: string;
    name: string;
    onChangeEventHandler: (name: string, checked: boolean) => void;
    defaultSelected?: boolean | undefined;
    disabled?: boolean | undefined;
}> = ({ title, name, onChangeEventHandler, defaultSelected, disabled }) => (
    <Col>
        <StyledFilterTitle>{title}</StyledFilterTitle>
        <SbCheckField
            name={name}
            type={"checkbox"}
            defaultSelected={defaultSelected}
            onChangeHandler={onChangeEventHandler}
            disabled={disabled}
        />
    </Col>
);
