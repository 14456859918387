enum ReviewStatus {
    Review = 1,
    PrePublish = 2,
    Publish = 3,
    Removed = 4,
    Resolved = 5,
    InProgress = 6,
    Deleted = 7,
}

export default ReviewStatus;
