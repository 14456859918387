import React, { ReactElement, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { ChecklistQuestions } from "../../../core/constants/translation-namespace";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import { hasRoleTypeInGroup, QuestionCategoryRoleGroup } from "../../../domain/enums/Roles";
import { useGetQuestionCategoryDetails } from "../../../domain/viewmodels/question-categories/view-question-category-details-viewmodel";
import { EditLink, ViewLink } from "../../atoms/SbLink";

const ViewQuestionCategoryContainer: React.FC = () => {
    const questionCategoryId = Number(useParams().questionCategoryId);

    const menu = useMenu();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });
    const auth = useAuth();

    const questionCategoryDetails = useGetQuestionCategoryDetails(questionCategoryId);

    useLoader(isQueryLoading(questionCategoryDetails), ViewQuestionCategoryContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.QuestionCategories);
    }, []);

    const buildLinks = (): ReactElement<typeof Link> => {
        return (
            <>
                {hasRoleTypeInGroup(auth.userRoles, QuestionCategoryRoleGroup.WriteRoles) &&
                    buildEditLink()}
                {buildViewLink()}
            </>
        );
    };

    const buildEditLink = (): ReactElement<typeof Link> => (
        <EditLink
            navigateTo={`${getPath(AccordionTitles.QuestionCategories)}/${questionCategoryId}/edit`}
        />
    );

    const buildViewLink = (): ReactElement<typeof Link> => (
        <ViewLink
            label={t("QuestionCategories")}
            navigateTo={`${getPath(AccordionTitles.QuestionCategories)}`}
        />
    );

    return (
        <>
            <PageHeading>{t("QuestionCategoryDetailsTitle")}</PageHeading>
            <SectionVerticalSpace />
            {isQuerySuccessful(questionCategoryDetails) && (
                <ContentContainer>
                    <Row>
                        <Col md="auto">
                            <DetailsLabel>{t("Name")}</DetailsLabel>
                        </Col>
                        <Col md="auto">
                            <DetailsValue>{questionCategoryDetails.data!.name || "-"}</DetailsValue>
                        </Col>
                    </Row>
                    <EndAlignedDiv>{buildLinks()}</EndAlignedDiv>
                </ContentContainer>
            )}
        </>
    );
};

export default ViewQuestionCategoryContainer;
