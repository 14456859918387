export class AssociateChildQuestionDto {
    parentQuestionId: number;
    childQuestionId: number;
    triggerWhenParentDesirable: boolean;

    public constructor(
        parentQuestionId: number,
        childQuestionId: number,
        triggerWhenParentDesirable: boolean
    ) {
        this.parentQuestionId = parentQuestionId;
        this.childQuestionId = childQuestionId;
        this.triggerWhenParentDesirable = triggerWhenParentDesirable;
    }
}
