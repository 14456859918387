export interface CreatePredefinedAnswerTextRequest {
    predefinedAnswerId: number;
    languageId: number;
    value: string;
}

export const createCreatePredefinedAnswerTextRequest = (
    predefinedAnswerId: number,
    languageId: number,
    value: string
): CreatePredefinedAnswerTextRequest => ({
    predefinedAnswerId: predefinedAnswerId,
    languageId: languageId,
    value: value,
});
