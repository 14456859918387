import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { getQuestionCategoryDetails } from "../../../data/services/question-categories/question-category-service";
import { toQuestionCategoryDto } from "../../dtos/question-categories/question-category-dto";
import { QuestionCategoryResponse } from "../../responses/question-category/question-category-response";

export const useGetQuestionCategoryDetails = (
    questionCategoryId: number
): UseQueryResult<QuestionCategoryResponse, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getQuestionCategoryDetails", questionCategoryId],
        () => getQuestionCategoryDetails(url.baseUrl, questionCategoryId),
        {
            select: toQuestionCategoryDto,
            onError: errorResponseToDisplayHandler,
        }
    );
};
