import ky, { HTTPError } from "ky";
import { RefreshTokenRequestDto } from "../domain/dtos/auth/refresh-token-request-dto";
import { RefreshTokenRequest } from "../domain/requests/auth/refresh-token-request";
import { AuthTokenResponse } from "../domain/responses/account/token-response";
import { Response } from "../domain/responses/common/response-response";

const httpClient = ky
    .create({
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        timeout: 30000,
    })
    .extend({
        hooks: {
            beforeRequest: [
                (options: Request) => {
                    if (localStorage["token"]) {
                        const token = JSON.parse(localStorage["token"]);

                        if (token.token) {
                            const bearerToken = `Bearer ${token.token}`;
                            options.headers.set("Authorization", bearerToken);
                        }
                    }
                },
            ],
            afterResponse: [
                async (request, options, response) => {
                    if (response.status === 401) {
                        if (localStorage["refreshToken"]) {
                            const refreshToken = JSON.parse(localStorage["refreshToken"]);
                            const nonce = localStorage["token"]
                                ? JSON.parse(localStorage["token"]).nonce
                                : "";
                            const refreshTokenDto = new RefreshTokenRequestDto(
                                refreshToken.refreshToken
                            );
                            const refreshTokenRequest = new RefreshTokenRequest(refreshTokenDto);
                            try {
                                const authTokenResponse: Response<AuthTokenResponse> = await ky
                                    .post(`${options.prefixUrl}auth/refresh-token`, {
                                        body: JSON.stringify(refreshTokenRequest),
                                        headers: {
                                            Accept: "application/json",
                                            "Content-Type": "application/json",
                                            Nonce: nonce,
                                        },
                                    })
                                    .json();

                                const tokenJson = JSON.stringify(authTokenResponse.data.token);
                                const refreshTokenJson = JSON.stringify(
                                    authTokenResponse.data.refreshToken
                                );
                                localStorage.setItem("token", tokenJson);
                                localStorage.setItem("refreshToken", refreshTokenJson);

                                request.headers.set(
                                    "Authorization",
                                    `Bearer ${authTokenResponse.data.token.token}`
                                );

                                return ky(request);
                            } catch (err) {
                                if (err instanceof HTTPError) {
                                    if (err.response.status === 400) {
                                        localStorage.removeItem("token");
                                        localStorage.removeItem("refreshToken");
                                        window.location.reload(); // TODO: Find better way to Log User Out
                                    }
                                }
                            }
                        }
                    }
                },
            ],
        },
    });

export default httpClient;
