import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import {
    cloneChecklist,
    cloneChecklistAndQuestionSets,
} from "../../../data/services/checklists/checklist-service";
import { ChecklistResponse } from "../../responses/checklists/checklist-response";
import { Response } from "../../responses/common/response-response";

interface CloneParameters {
    checklistId: number;
    cloneChecklistAndQuestionSet: boolean;
}

export const createCloneParameters = (
    checklistId: number,
    cloneChecklistAndQuestionSet: boolean
): CloneParameters => ({
    checklistId: checklistId,
    cloneChecklistAndQuestionSet: cloneChecklistAndQuestionSet,
});

export const useCloneChecklist = (): UseMutationResult<
    Response<ChecklistResponse>,
    HTTPError,
    CloneParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: CloneParameters) => {
        if (mutationKey.cloneChecklistAndQuestionSet) {
            return cloneChecklistAndQuestionSets(url.baseUrl, mutationKey.checklistId);
        }

        return cloneChecklist(url.baseUrl, mutationKey.checklistId);
    });
};
