import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { TFunction } from "i18next";
import { HTTPError } from "ky";
import { useTranslation } from "react-i18next";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import { filterApplicableChecklists } from "../../../data/services/checklists/checklist-service";
import { getFunctionUserNodes } from "../../../data/services/hierarchy/nodes-service";
import {
    downloadChecklistExcelReport,
    filterChecklistReportSummaries,
    getChecklistReportDetails,
} from "../../../data/services/reports/checklist-reports-service";
import { filterUsersBelowNode } from "../../../data/services/users/users-service";
import BaseChecklistDto from "../../dtos/checklists/base-checklist-dto";
import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { FileDownloadDto } from "../../dtos/file-storage/file-download-dto";
import {
    BaseNodeDetailsDto,
    toBaseNodeDetailsDtosFromResponse,
} from "../../dtos/hierarchy/base-node-details-dto";
import ChecklistReportDetailsDto from "../../dtos/reports/checklist-report-details-dto";
import FilterChecklistReportSummariesDto from "../../dtos/reports/filter-checklist-report-summaries-dto";
import { BaseUserDto, toBaseUserDtosFromResponse } from "../../dtos/users/base-user-dto";
import ChecklistReportSummaryResponse from "../../requests/reports/checklist-report-summary-response";
import FilterChecklistReportSummariesRequest from "../../requests/reports/filter-checklist-report-summaries-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import ChecklistReportDetailsResponse from "../../responses/reports/checklist-report-details-response";

export const useGetFunctionUserNodes = (): UseQueryResult<BaseNodeDetailsDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(["getFunctionUserNodes"], () => getFunctionUserNodes(url.baseUrl), {
        select: toBaseNodeDetailsDtosFromResponse,
        onError: errorResponseToDisplayHandler,
    });
};

export const useFilterUsersBelowNode = (
    nodeId: number | null
): UseQueryResult<BaseUserDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterUsersBelowNode", nodeId],
        () => filterUsersBelowNode(url.baseUrl, nodeId!),
        {
            enabled: nodeId !== null,
            select: toBaseUserDtosFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useFilterApplicableChecklists = (
    nodeId: number | null
): UseQueryResult<BaseChecklistDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterApplicableChecklists", nodeId],
        () => filterApplicableChecklists(url.baseUrl, nodeId!),
        {
            enabled: nodeId !== null,
            select: BaseChecklistDto.constructFromBaseChecklistResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useGetChecklistReportDetails = (
    questionSetInstanceId: number | null
): UseQueryResult<ChecklistReportDetailsDto, HTTPError> => {
    const url = useUrl();
    const { t } = useTranslation("translation");
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getChecklistReportDetails", questionSetInstanceId],
        () => getChecklistReportDetails(url.baseUrl, questionSetInstanceId!),
        {
            enabled: questionSetInstanceId !== null,
            select: (response: Response<ChecklistReportDetailsResponse>) =>
                toChecklistReportDetailsDto(response.data, t),
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useDownloadChecklistExcelReport = (
    questionSetInstanceId: number | null,
    exportClicked: boolean
): UseQueryResult<FileDownloadDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["downloadChecklistExcelReport", questionSetInstanceId],
        () => downloadChecklistExcelReport(url.baseUrl, questionSetInstanceId!),
        {
            enabled: questionSetInstanceId !== null && exportClicked,
            select: FileDownloadDto.constructFromFileContentResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useFilterChecklistReportSummaries = (
    filterChecklistReportSummariesDto: FilterChecklistReportSummariesDto,
    basePaginationDto: BasePaginationDto
): UseQueryResult<PaginatedTableDto<number>, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterChecklistReportSummaries", filterChecklistReportSummariesDto, basePaginationDto],
        () =>
            filterChecklistReportSummaries(
                url.baseUrl,
                new FilterChecklistReportSummariesRequest(
                    filterChecklistReportSummariesDto.startDateUtc!,
                    filterChecklistReportSummariesDto.endDateUtc!,
                    filterChecklistReportSummariesDto.nodeId!,
                    filterChecklistReportSummariesDto.userId!,
                    filterChecklistReportSummariesDto.checklistId!,
                    basePaginationDto
                )
            ),
        {
            enabled:
                filterChecklistReportSummariesDto.startDateUtc !== null &&
                filterChecklistReportSummariesDto.endDateUtc !== null &&
                filterChecklistReportSummariesDto.nodeId !== null &&
                filterChecklistReportSummariesDto.userId !== null &&
                filterChecklistReportSummariesDto.checklistId !== null,
            keepPreviousData: true,
            select: transformToDataTableRows,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const toChecklistReportDetailsDto = (
    response: ChecklistReportDetailsResponse,
    translatorFn: TFunction
): ChecklistReportDetailsDto => new ChecklistReportDetailsDto(response, translatorFn);

const transformToDataTableRows = (
    response: Response<PaginationResponse<ChecklistReportSummaryResponse>>
): PaginatedTableDto<number> => {
    const rows = response.data.results!.map((x) => ({
        metadata: x.questionSetInstanceId,
        columns: [
            {
                value: x.path,
                type: DataTableColumnTypes.Text,
            },
            {
                value: toDateAndTimeFormat(x.completedDateUtc.toString()),
                type: DataTableColumnTypes.Text,
            },
            {
                value: x.checklistName,
                type: DataTableColumnTypes.Text,
            },
            {
                value: x.questionSetName,
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: response.data.pageCount,
        pageSize: response.data.pageSize,
        currentPage: response.data.currentPage,
        recordCount: response.data.recordCount,
        rows: rows,
    };
};
