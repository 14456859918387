import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    downloadAnswerFile,
    downloadNestedAnswerFile,
    filterAnswerFiles,
    filterNestedAnswerFiles,
    softDeleteAnswerFile,
    softDeleteNestedAnswerFile,
    uploadAnswerFiles,
    uploadNestedAnswerFiles,
} from "../../../data/services/question-set-instance-answers/question-set-instance-answer-files-service";
import { FileDownloadDto } from "../../dtos/file-storage/file-download-dto";
import { FileDto } from "../../dtos/file-storage/file-dto";
import QuestionAnswerFileUploadDto from "../../dtos/question-set-instance-answers/question-answer-file-upload-dto";
import NestedQuestionAnswerFileUploadRequest from "../../requests/answer-capture/nested-question-answer-file-upload-request";
import QuestionAnswerFileUploadRequest from "../../requests/answer-capture/question-answer-file-upload-request";
import { Response } from "../../responses/common/response-response";

export const useFilterFiles = (
    isNestedQuestion: boolean,
    questionSetInstanceAnswerEntityId: number | null,
    fileUploaded: boolean
): UseQueryResult<FileDto[], HTTPError> =>
    isNestedQuestion
        ? useFilterNestedAnswerFiles(questionSetInstanceAnswerEntityId, fileUploaded)
        : useFilterAnswerFiles(questionSetInstanceAnswerEntityId, fileUploaded);

export const useDownloadFile = (
    isNestedQuestion: boolean,
    questionSetInstanceAnswerEntityId: number | null
): UseQueryResult<FileDownloadDto, HTTPError> =>
    isNestedQuestion
        ? useDownloadNestedAnswerFile(questionSetInstanceAnswerEntityId)
        : useDownloadAnswerFile(questionSetInstanceAnswerEntityId);

export const useUploadFiles = (
    isNestedQuestion: boolean
): UseMutationResult<Response<boolean>, HTTPError, QuestionAnswerFileUploadDto> =>
    isNestedQuestion ? useUploadNestedAnswerFiles() : useUploadAnswerFiles();

export const useSoftDeleteFile = (
    isNestedQuestion: boolean
): UseMutationResult<Response<boolean>, HTTPError, number> =>
    isNestedQuestion ? useSoftDeleteNestedAnswerFile() : useSoftDeleteAnswerFile();

const useFilterAnswerFiles = (
    questionSetInstanceAnswerId: number | null,
    fileUploaded: boolean
): UseQueryResult<FileDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterAnswerFiles", questionSetInstanceAnswerId],
        () => filterAnswerFiles(url.baseUrl, questionSetInstanceAnswerId!),
        {
            enabled: questionSetInstanceAnswerId != null && fileUploaded,
            select: FileDto.constructFromQuestionSetInstanceAnswerFileDetailsResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const useFilterNestedAnswerFiles = (
    questionSetInstanceAnswerStagingId: number | null,
    fileUploaded: boolean
): UseQueryResult<FileDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterNestedAnswerFiles", questionSetInstanceAnswerStagingId],
        () => filterNestedAnswerFiles(url.baseUrl, questionSetInstanceAnswerStagingId!),
        {
            enabled: questionSetInstanceAnswerStagingId != null && fileUploaded,
            select: FileDto.constructFromQuestionSetInstanceAnswerStagingFileDetailsResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const useDownloadAnswerFile = (
    questionSetInstanceAnswerFileId: number | null
): UseQueryResult<FileDownloadDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["downloadAnswerFile", questionSetInstanceAnswerFileId],
        () => downloadAnswerFile(url.baseUrl, questionSetInstanceAnswerFileId!),
        {
            enabled: questionSetInstanceAnswerFileId != null,
            select: FileDownloadDto.constructFromFileDetailsResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const useDownloadNestedAnswerFile = (
    questionSetInstanceAnswerStagingFileId: number | null
): UseQueryResult<FileDownloadDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["downloadNestedAnswerFile", questionSetInstanceAnswerStagingFileId],
        () => downloadNestedAnswerFile(url.baseUrl, questionSetInstanceAnswerStagingFileId!),
        {
            enabled: questionSetInstanceAnswerStagingFileId != null,
            select: FileDownloadDto.constructFromFileDetailsResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const useUploadAnswerFiles = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    QuestionAnswerFileUploadDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: QuestionAnswerFileUploadDto) => {
        const request =
            QuestionAnswerFileUploadRequest.constructFromQuestionAnswerFileUploadDto(mutationKey);

        return uploadAnswerFiles(url.baseUrl, request);
    });
};

const useUploadNestedAnswerFiles = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    QuestionAnswerFileUploadDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: QuestionAnswerFileUploadDto) => {
        const request =
            NestedQuestionAnswerFileUploadRequest.constructFromQuestionAnswerFileUploadDto(
                mutationKey
            );

        return uploadNestedAnswerFiles(url.baseUrl, request);
    });
};

const useSoftDeleteAnswerFile = (): UseMutationResult<Response<boolean>, HTTPError, number> => {
    const url = useUrl();

    return useMutation((questionSetInstanceAnswerFileId: number) =>
        softDeleteAnswerFile(url.baseUrl, questionSetInstanceAnswerFileId)
    );
};

const useSoftDeleteNestedAnswerFile = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    number
> => {
    const url = useUrl();

    return useMutation((questionSetInstanceAnswerStagingFileId: number) =>
        softDeleteNestedAnswerFile(url.baseUrl, questionSetInstanceAnswerStagingFileId)
    );
};
