import { ReactElement, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import {
    ChecklistAnswers,
    ChecklistQuestions,
    Common,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsLabelBlue,
    DetailsValue,
    EndAlignedDiv,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import {
    alternativeQuestionsTextsColumnNames,
    associatedQuestionSetsToQuestionColumnNames,
    indicatorThresholdsColumnNames,
    nestedQuestionColumnNames,
    nodesColumnNames,
} from "../../../core/utilities/dataTableColumns";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isMutationLoading,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { defaultPaginationDto, PaginationDto } from "../../../domain/dtos/common/pagination-dto";
import QuestionImportance from "../../../domain/enums/questions/question-importance";
import { hasRoleTypeInGroup, QuestionRoleGroup } from "../../../domain/enums/Roles";
import { emptySearchQuestionAssociationRequest } from "../../../domain/requests/questions/search-question-association-request";
import { useDeleteAlternativeQuestionText } from "../../../domain/viewmodels/questions/delete-alternative-question-text-viewmodel";
import { useGetQuestionDetails } from "../../../domain/viewmodels/questions/view-question-details-viewmodel";
import SbCheckField from "../../atoms/input/SbCheckField";
import { CreateLink, EditLink, OrderLink, ViewLink } from "../../atoms/SbLink";
import { CreateLabelToDetailRows } from "../../molecules/CreateLabelToDetailRows";
import { SbAccordion } from "../../molecules/SbAccordion";
import {
    CheckboxConfirmationRow,
    ConfirmationRow,
    TextConfirmationRow,
} from "../../organisms/ActionConfirmation";
import { DataTable } from "../../organisms/DataTable";

const StyledCenteredDiv = styled.div`
    padding-left: ${(props) => props.theme.padding.xl};
`;

const ViewQuestionContainer = (): JSX.Element => {
    const [taxonomyNodesPaginationDto, setTaxonomyNodesPaginationDto] =
        useState<PaginationDto>(defaultPaginationDto);
    const [alternativeQuestionTextPaginationDto, setAlternativeQuestionTextPaginationDto] =
        useState<PaginationDto>(defaultPaginationDto);
    const [nestedQuestionsPaginationDto, setNestedQuestionsPaginationDto] =
        useState<PaginationDto>(defaultPaginationDto);
    const [questionSetsPaginationDto, setQuestionSetsPaginationDto] =
        useState<PaginationDto>(defaultPaginationDto);

    const menu = useMenu();

    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const toast = useToast();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });
    const auth = useAuth();

    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";
    const questionId = Number(useParams().questionId);

    const searchRequest = {
        ...emptySearchQuestionAssociationRequest(),
        questionId: questionId,
    };

    const questionDetails = useGetQuestionDetails(
        questionId,
        nestedQuestionsPaginationDto,
        taxonomyNodesPaginationDto,
        alternativeQuestionTextPaginationDto,
        questionSetsPaginationDto,
        searchRequest
    );
    const questionDetailsResponseData = questionDetails[0].data;
    const nestedQuestionsResponseData = questionDetails[1].data;
    const associatedTaxonomyNodesResponseData = questionDetails[2].data;
    const alternativeQuestionTextsResponseData = questionDetails[3].data;
    const associatedQuestionSets = questionDetails[4].data;

    const deleteAlternativeQuestionText = useDeleteAlternativeQuestionText();

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Questions);

        if (success) {
            toast.addToast(createSuccessToastProps([t(messageKey)]));

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    useLoader(
        areQueriesLoading(questionDetails) || isMutationLoading(deleteAlternativeQuestionText),
        ViewQuestionContainer
    );

    const navigateToEditNestedQuestionTrigger = (nestedQuestionId: number): void => {
        const params = [
            createNavigateSearchParameter("nestedQuestionId", nestedQuestionId.toString()),
        ];

        navigateSearch(
            `${getPath(AccordionTitles.Questions)}/update-nested-question-trigger-selection`,
            params
        );
    };

    const navigateToNestedQuestion = (nestedQuestionId: number): void => {
        const nestedRow = nestedQuestionsResponseData!.rows.find(
            (x) => x.metadata === nestedQuestionId
        );
        const questionId = nestedRow?.columns.find((x) => x.metadata === "ID")?.value;
        if (nestedRow && questionId) {
            navigate(`${getPath(AccordionTitles.Questions)}/${questionId}`);
        }
    };

    const navigateToDissociateQuestion = (questionSetId: number): void => {
        const params = [
            createNavigateSearchParameter("questionId", questionId.toString()),
            createNavigateSearchParameter("questionSetId", questionSetId.toString()),
        ];

        navigateSearch(`${getPath(AccordionTitles.QuestionSets)}/dissociate`, params);
    };

    const navigateToAssociatedQuestionSet = (questionSetId: number): void =>
        navigate(`${getPath(AccordionTitles.QuestionSets)}/${questionSetId}`);

    const handleDissociateTaxonomyNode = (nodeId: number): void => {
        const params = [createNavigateSearchParameter("nodeId", nodeId.toString())];

        navigateSearch(
            `${getPath(AccordionTitles.Questions)}/${questionId}/dissociate-taxonomy-node`,
            params
        );
    };

    const handleDeleteAlternativeQuestionText = (questionTextId: number): void => {
        deleteAlternativeQuestionText.mutate(questionTextId, {
            onSuccess: async () => {
                toast.addToast(
                    createSuccessToastProps([t("SuccessfullyDeletedAlternativeQuestionText")])
                );
                queryClient.invalidateQueries(["getAlternativeQuestionTexts"]);
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    const buildLinks = (): ReactElement<typeof Link> => {
        return (
            <>
                {hasRoleTypeInGroup(auth.userRoles, QuestionRoleGroup.WriteRoles) &&
                    buildEditLink()}
                {buildQuestionsContainerLink()}
                {buildCreateLink()}
            </>
        );
    };

    const buildQuestionsContainerLink = (): ReactElement<typeof Link> => (
        <ViewLink label={t("Questions")} navigateTo={`${getPath(AccordionTitles.Questions)}`} />
    );
    const buildEditLink = (): ReactElement<typeof Link> => (
        <EditLink navigateTo={`${getPath(AccordionTitles.Questions)}/${questionId}/edit`} />
    );

    const buildCreateLink = (): ReactElement<typeof Link> => (
        <CreateLink
            label={t("CreateNew", { keyPrefix: Common })}
            navigateTo={`${getPath(AccordionTitles.Questions)}/create`}
        />
    );

    const confirmationRows = (): ConfirmationRow<any>[] => [
        new CheckboxConfirmationRow(
            t("ARMonitoring", { keyPrefix: Common }),
            questionDetailsResponseData!.isARMonitoring || false
        ),
        new TextConfirmationRow(t("Type"), questionDetailsResponseData!.questionType.name || "-"),
        new TextConfirmationRow(
            t("QuestionText"),
            questionDetailsResponseData!.questionTextDto.text || "-"
        ),
        new TextConfirmationRow(
            t("Importance"),
            QuestionImportance[questionDetailsResponseData!.questionImportance] || "-"
        ),
        new TextConfirmationRow(
            t("Category"),
            questionDetailsResponseData!.questionCategory.name || "-"
        ),
        new TextConfirmationRow(
            t("AnswerType"),
            questionDetailsResponseData!.answerTypeDto.name || "-"
        ),
        new TextConfirmationRow(
            t("IsAggregate"),
            questionDetailsResponseData!.isAggregate.toString() || "-"
        ),
    ];

    const renderQuestionDetails = (): JSX.Element => (
        <ContentContainer>
            {CreateLabelToDetailRows(confirmationRows(), 2, 10)}

            <EndAlignedDiv>{buildLinks()}</EndAlignedDiv>
        </ContentContainer>
    );

    const renderAssociatedQuestionSets = (): JSX.Element => (
        <SbAccordion title={t("AssociatedQuestionSets")}>
            <DataTable
                columns={associatedQuestionSetsToQuestionColumnNames}
                rows={associatedQuestionSets!.rows}
                keyPrefix={ChecklistQuestions}
                deleteItem={navigateToDissociateQuestion}
                linkItem={navigateToAssociatedQuestionSet}
                totalItems={associatedQuestionSets!.recordCount}
                paginationDto={questionSetsPaginationDto}
                setPaginationDto={setQuestionSetsPaginationDto}
            />
        </SbAccordion>
    );

    const renderAnsweringOptions = (): JSX.Element => {
        const predefinedAnswerResponses =
            questionDetailsResponseData!.answerTypeDto!.predefinedAnswerDtos;

        return (
            <>
                <SbAccordion title={t("PredefinedAnswers")}>
                    <Row>
                        <Col md="1">
                            <DetailsLabel>&nbsp;</DetailsLabel>
                            <StyledCenteredDiv>
                                {predefinedAnswerResponses.map((x) => (
                                    <SbCheckField
                                        type={"checkbox"}
                                        defaultSelected={
                                            questionDetailsResponseData!.answerTypeDto
                                                .answerFlagMode === x.answerFlagMode
                                        }
                                        disabled
                                        label={" "}
                                        name={`AnswerCheckbox ${x.answerFlagMode}`}
                                    />
                                ))}
                            </StyledCenteredDiv>
                        </Col>
                        <Col md="auto">
                            <DetailsLabelBlue>
                                {t("DefaultPredefinedAnswerTextValue", {
                                    keyPrefix: ChecklistAnswers,
                                })}
                            </DetailsLabelBlue>
                            {predefinedAnswerResponses.map((x) => (
                                <DetailsValue>{x.predefinedAnswerTextDto.textValue}</DetailsValue>
                            ))}
                        </Col>
                    </Row>
                </SbAccordion>
                <SectionVerticalSpace />
            </>
        );
    };

    const renderIndicatorThresholds = (): JSX.Element => {
        const answerThresholdValueResponses =
            questionDetailsResponseData!.answerThresholdValueResponseRows;

        return (
            <>
                <SbAccordion title={t("ThresholdIndicator", { keyPrefix: ChecklistAnswers })}>
                    <DataTable
                        rows={answerThresholdValueResponses}
                        keyPrefix={ChecklistAnswers}
                        columns={indicatorThresholdsColumnNames}
                    />
                </SbAccordion>
                <SectionVerticalSpace />
            </>
        );
    };

    const renderNestedQuestions = (): JSX.Element => (
        <SbAccordion title={t("NestedQuestions")}>
            <EndAlignedDiv>
                {nestedQuestionsResponseData!.rows.length > 0 && (
                    <OrderLink
                        label={t("OrderQuestions")}
                        navigateTo={`${getPath(
                            AccordionTitles.Questions
                        )}/${questionId}/order-nested-questions`}
                    />
                )}
                <CreateLink
                    label={t("AssociateChildQuestion")}
                    navigateTo={`${getPath(
                        AccordionTitles.Questions
                    )}/${questionId}/associate-child-question`}
                />
            </EndAlignedDiv>
            <DataTable
                noResultsMessage={`${t("NoNestedQuestionsFound")}`}
                keyPrefix={ChecklistQuestions}
                columns={nestedQuestionColumnNames}
                rows={nestedQuestionsResponseData!.rows}
                linkItem={navigateToNestedQuestion}
                totalItems={nestedQuestionsResponseData!.recordCount}
                paginationDto={nestedQuestionsPaginationDto}
                setPaginationDto={setNestedQuestionsPaginationDto}
                editItem={
                    hasRoleTypeInGroup(auth.userRoles, QuestionRoleGroup.EditAssociateRoles)
                        ? navigateToEditNestedQuestionTrigger
                        : undefined
                }
                deleteItem={(nestedQuestionId: number): void => {
                    const params = [
                        createNavigateSearchParameter("questionId", questionId.toString()),
                    ];

                    navigateSearch(
                        `${getPath(
                            AccordionTitles.Questions
                        )}/${nestedQuestionId}/dissociate-nested-question`,
                        params
                    );
                }}
            />
        </SbAccordion>
    );

    const navigateToViewTaxonomyNodeDetails = (nodeId: number): void =>
        navigate(`${getPath(AccordionTitles.VisualTree)}/${nodeId}`);

    const renderAssociatedTaxonomyNodes = (): JSX.Element => (
        <SbAccordion title={t("Taxonomies", { keyPrefix: Common })}>
            <EndAlignedDiv>
                <CreateLink
                    label={t("AssignTaxonomyNode")}
                    navigateTo={`${getPath(
                        AccordionTitles.Questions
                    )}/${questionId}/associate-taxonomy-node`}
                />
            </EndAlignedDiv>
            <DataTable
                noResultsMessage={`${t("NoTaxonNodesAssociated")}`}
                keyPrefix={ChecklistQuestions}
                columns={nodesColumnNames}
                rows={associatedTaxonomyNodesResponseData!.rows}
                deleteItem={handleDissociateTaxonomyNode}
                linkItem={navigateToViewTaxonomyNodeDetails}
                totalItems={associatedTaxonomyNodesResponseData!.recordCount}
                paginationDto={taxonomyNodesPaginationDto}
                setPaginationDto={setTaxonomyNodesPaginationDto}
            />
        </SbAccordion>
    );

    const renderAlternativeQuestionTexts = (): JSX.Element => (
        <SbAccordion title={t("AlternativeQuestionTexts")}>
            <EndAlignedDiv>
                <CreateLink
                    label={t("AddAlternativeQuestionText")}
                    navigateTo={`${getPath(
                        AccordionTitles.Questions
                    )}/${questionId}/add-alternative-question-text`}
                />
            </EndAlignedDiv>
            <DataTable
                noResultsMessage={`${t("QuestionsNoAlternativeFound")}`}
                keyPrefix={ChecklistQuestions}
                columns={alternativeQuestionsTextsColumnNames}
                rows={alternativeQuestionTextsResponseData!.rows}
                deleteItem={handleDeleteAlternativeQuestionText}
                totalItems={alternativeQuestionTextsResponseData!.recordCount}
                paginationDto={alternativeQuestionTextPaginationDto}
                setPaginationDto={setAlternativeQuestionTextPaginationDto}
            />
        </SbAccordion>
    );

    return (
        <>
            {areQueriesSuccessful(questionDetails) && (
                <>
                    <PageHeading>{t("DetailsTitle")}</PageHeading>
                    <PageSubHeading>{t("HeaderHelpTextQuestionDetails")}</PageSubHeading>
                    <SectionVerticalSpace />

                    {renderQuestionDetails()}
                    <SectionVerticalSpace />

                    {renderAssociatedQuestionSets()}
                    <SectionVerticalSpace />

                    {questionDetailsResponseData!.answerTypeDto!.predefinedAnswerDtos.length > 0 &&
                        renderAnsweringOptions()}

                    {questionDetailsResponseData!.answerThresholdValueResponses.length > 0 &&
                        renderIndicatorThresholds()}

                    {renderNestedQuestions()}
                    <SectionVerticalSpace />

                    {renderAssociatedTaxonomyNodes()}
                    <SectionVerticalSpace />

                    {renderAlternativeQuestionTexts()}
                    <SectionVerticalSpace />
                </>
            )}
        </>
    );
};

export default ViewQuestionContainer;
