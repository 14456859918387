import { useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import { getRole, getRoleUsers } from "../../../data/services/users/roles-service";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { PaginationDto } from "../../dtos/common/pagination-dto";
import {
    RoleDto,
    RoleUserDto,
    toRoleDtoFromResponse,
    toRoleUserDto,
} from "../../dtos/users/role-dto";
import { createPaginationRequestFromDto } from "../../requests/common/pagination-request";
import { createFilterRoleUsersRequest } from "../../requests/users/filter-role-users-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { RoleUsersResponse } from "../../responses/users/role-response";

export const useGetRoleDetails = (
    roleId: number,
    paginationDto: PaginationDto
): [
    UseQueryResult<RoleDto, HTTPError>,
    UseQueryResult<PaginatedTableDto<RoleUserDto>, HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const queries = useQueries({
        queries: [
            {
                queryKey: ["getRoleDetails", roleId],
                queryFn: () => getRole(url.baseUrl, roleId),
                select: toRoleDtoFromResponse,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getRoleUsers", roleId, paginationDto],
                queryFn: () =>
                    getRoleUsers(
                        url.originalBaseUrl,
                        createFilterRoleUsersRequest(
                            createPaginationRequestFromDto(paginationDto),
                            roleId
                        )
                    ),
                keepPreviousData: true,
                select: transformToDataTableRows,
                onError: errorResponseToDisplayHandler,
            },
        ],
    });

    return queries as [
        UseQueryResult<RoleDto, HTTPError>,
        UseQueryResult<PaginatedTableDto<RoleUserDto>, HTTPError>,
    ];
};

const transformToDataTableRows = (
    response: Response<PaginationResponse<RoleUsersResponse>>
): PaginatedTableDto<RoleUserDto> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((user) => ({
        metadata: toRoleUserDto(user),
        columns: [
            { value: user.email.toString(), type: DataTableColumnTypes.Text },
            { value: user.firstName.toString(), type: DataTableColumnTypes.Text },
            { value: user.lastName.toString(), type: DataTableColumnTypes.Text },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
