import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import { getQuestionSets } from "../../../data/services/question-set/question-set-service";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { FilterQuestionSetDto } from "../../dtos/question-sets/filter-question-set-dto";
import { createFilterQuestionSetRequestFromDto } from "../../requests/question-set/filter-question-set-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { QuestionSetResponse } from "../../responses/question-sets/question-set-response";

export const useGetQuestionSets = (
    dto: FilterQuestionSetDto
): UseQueryResult<PaginatedTableDto<number>, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getQuestionSets", dto],
        () => getQuestionSets(url.baseUrl, createFilterQuestionSetRequestFromDto(dto)),
        {
            select: transformToDataTableRows,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const transformToDataTableRows = (
    response: Response<PaginationResponse<QuestionSetResponse>>
): PaginatedTableDto<number> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((questionSet) => ({
        metadata: questionSet.questionSetId,
        columns: [
            {
                // Name
                value: questionSet.name.toString(),
                type: DataTableColumnTypes.Link,
            },
            {
                // Process
                value: questionSet.process?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                // Schedule Description
                value: questionSet.questionSetDefaultScheduleResponse?.scheduleDescription?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                // Questions Count
                value: questionSet.activeQuestionsCount.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                // Last Modified by
                value: questionSet.modifiedByUserFullName.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                // Last Modified Date
                value: toDateAndTimeFormat(questionSet.dateModifiedUtc.toString()),
                type: DataTableColumnTypes.Text,
            },
            {
                // Checklists Count
                value: questionSet.activeChecklistsCount.toString(),
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
