import { PeriodType } from "../../enums/PeriodType";

export interface EditQuestionScheduleDto {
    setIntervalDefaults: boolean;
    questionSetId: number;
    questionIds: number[];
    requiredByTimes: string[];
    openIntervalsBeforeDueTime: number[];
    openPeriodTypesBeforeDueTime: PeriodType[];
    escalationL1IntervalsAfterDueTime: number[];
    escalationL1PeriodTypesAfterDueTime: PeriodType[];
    escalationL2IntervalsAfterEscalationL1: (number | null)[];
    escalationL2PeriodTypesAfterEscalationL1: (PeriodType | null)[];
    escalationL3IntervalsAfterEscalationL2: (number | null)[];
    escalationL3PeriodTypesAfterEscalationL2: (PeriodType | null)[];
}

export const createEditQuestionScheduleDto = (
    setIntervalDefaults: boolean,
    questionSetId: number,
    questionIds: number[],
    requiredByTimes: string[],
    openIntervalsBeforeDueTime: number[],
    openPeriodTypesBeforeDueTime: PeriodType[],
    escalationL1IntervalsAfterDueTime: number[],
    escalationL1PeriodTypesAfterDueTime: PeriodType[],
    escalationL2IntervalsAfterEscalationL1: number[],
    escalationL2PeriodTypesAfterEscalationL1: PeriodType[],
    escalationL3IntervalsAfterEscalationL2: number[],
    escalationL3PeriodTypesAfterEscalationL2: PeriodType[]
): EditQuestionScheduleDto => ({
    setIntervalDefaults: setIntervalDefaults,
    questionSetId: questionSetId,
    questionIds: questionIds,
    requiredByTimes: requiredByTimes,
    openIntervalsBeforeDueTime: openIntervalsBeforeDueTime,
    openPeriodTypesBeforeDueTime: openPeriodTypesBeforeDueTime,
    escalationL1IntervalsAfterDueTime: escalationL1IntervalsAfterDueTime,
    escalationL1PeriodTypesAfterDueTime: escalationL1PeriodTypesAfterDueTime,
    escalationL2IntervalsAfterEscalationL1: escalationL2IntervalsAfterEscalationL1,
    escalationL2PeriodTypesAfterEscalationL1: escalationL2PeriodTypesAfterEscalationL1,
    escalationL3IntervalsAfterEscalationL2: escalationL3IntervalsAfterEscalationL2,
    escalationL3PeriodTypesAfterEscalationL2: escalationL3PeriodTypesAfterEscalationL2,
});

export const getDefaultEditQuestionScheduleDto = (
    questionSetId: number
): EditQuestionScheduleDto => {
    return createEditQuestionScheduleDto(
        false,
        questionSetId,
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        []
    );
};
