import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { createQuestionSet } from "../../../data/services/question-set/question-set-service";
import CreateQuestionSetDto from "../../dtos/question-sets/create-question-set-dto";
import { QuestionSetScheduleRequest } from "../../requests/question-set/create-question-set-schedule-request";
import { Response } from "../../responses/common/response-response";

export const useCreateQuestionSet = (
    questionSetScheduleRequest: QuestionSetScheduleRequest
): UseMutationResult<Response<number>, HTTPError, CreateQuestionSetDto> => {
    const url = useUrl();

    return useMutation((dto: CreateQuestionSetDto) => {
        const request = {
            name: dto.name,
            description: dto.description,
            process: dto.process,
            isARMonitoring: dto.arMonitoring,
            questionSetScheduleRequest: questionSetScheduleRequest,
        };

        return createQuestionSet(url.baseUrl, request);
    });
};
