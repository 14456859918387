export class ActionItemInstanceFileUploadDto {
    actionItemId: number;
    file: File;
    fileBase64String: string;

    public constructor(actionItemId: number, file: File, fileBase64String: string) {
        this.actionItemId = actionItemId;
        this.file = file;
        this.fileBase64String = fileBase64String;
    }
}
