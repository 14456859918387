import { CreateRaciSetUserDto } from "../../dtos/actions/create-raci-set-user-dto";
import RaciRelationship from "../../enums/action-items/RaciRelationship";

export class CreateRaciSetUserRequest {
    userId: number;
    raciRelationship: RaciRelationship;

    public constructor(dto: CreateRaciSetUserDto) {
        const { userId, raciRelationship } = dto;

        this.userId = userId;
        this.raciRelationship = raciRelationship;
    }

    public static toCreateRaciSetUserRequests = (
        response: CreateRaciSetUserDto[]
    ): CreateRaciSetUserRequest[] => response.map((x) => new CreateRaciSetUserRequest(x));
}
