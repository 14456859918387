export default class FilterReportSummariesDto {
    startDateUtc: Date | null;
    endDateUtc: Date | null;
    userId: number | null;

    public constructor(
        startDateUtc: Date | null,
        endDateUtc: Date | null,
        userId: number | null | undefined
    ) {
        this.startDateUtc = startDateUtc;
        this.endDateUtc = endDateUtc;
        this.userId = userId ?? null;
    }
}
