import { ActionItemTypeResponse } from "../../responses/action-items/action-item-type-response";
import { Response } from "../../responses/common/response-response";
import { createNodeTypeValueDto, NodeTypeValueDto } from "../hierarchy/node-type-value-dto";

export interface ActionItemTypeDto extends NodeTypeValueDto {
    nodeId: number;
}

export const createActionItemTypeDto = (
    nodeId: number,
    nodeTypeValueId: number,
    value: string
): ActionItemTypeDto => ({
    nodeId: nodeId,
    nodeTypeValueId: nodeTypeValueId,
    value: value,
});

export const createActionItemTypeDtoFromResponse = (
    response: ActionItemTypeResponse
): ActionItemTypeDto => ({
    ...response,
    ...createNodeTypeValueDto(response),
});

export const toActionItemTypeDtos = (
    response: Response<ActionItemTypeResponse[]>
): ActionItemTypeDto[] => response.data?.map((x) => createActionItemTypeDtoFromResponse(x));

export const allActionItemTypeOption = createActionItemTypeDto(0, 0, "All");
