import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
    ActionItems,
    ActionItemTypes,
    ActionPlans,
    Common,
} from "../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../core/hooks/loaderManager";
import {
    createErrorToastProps,
    createSuccessToastProps,
    useToast,
} from "../../core/store/toast-context";
import {
    DetailsLabel,
    DetailsValue,
    LargeVerticalSpace,
    StyledActionItemSectionContainer,
    StyledBorderedSection,
} from "../../core/theme/global-styles";
import { getBase64FileString } from "../../core/utilities/file-helper";
import {
    areMutationsLoading,
    areQueriesLoading,
    areQueriesSuccessful,
} from "../../core/utilities/responseStateHelper";
import queryClient from "../../data/query-client";
import { ActionItemInstanceFileUploadDto } from "../../domain/dtos/action-items/action-item-instance-file-upload-dto";
import { ActionItemImportance } from "../../domain/enums/action-items/ActionItemImportance";
import {
    useDownloadActionItemFile,
    useDownloadQuestionSetInstanceAnswerFile,
    useGetMyActionItemDetails,
    useRemoveActionItemFile,
    useUploadActionItemFile,
} from "../../domain/viewmodels/action-items/my-action-item-details-viewmodel";
import { SbLink } from "../atoms/SbLink";
import { FileGallery } from "../organisms/FileGallery";
import SbModal from "./SbModal";

const StyledTitleText = styled.div`
    font-size: ${(props) => props.theme.font.xl};
    font-weight: bold;
    color: ${(props) => props.theme.palette.secondary};
    padding-bottom: ${(props) => props.theme.padding.lg};
`;

const StyledRow = styled(Row)`
    border-bottom: ${(props) => props.theme.padding.xxs + " solid " + props.theme.palette.grey};
    padding-top: ${(props) => props.theme.padding.sm};
    padding-bottom: ${(props) => props.theme.padding.xs};
`;

const ActionItemDetails: React.FC<{
    actionItemId: number;
    actionItemInstanceId: number;
    isFinalizedItem: boolean;
}> = ({ actionItemId, actionItemInstanceId, isFinalizedItem }) => {
    const [downloadActionItemInstanceFileId, setDownloadActionItemInstanceFileId] = useState<
        number | null
    >(null);
    const [
        downloadQuestionSetInstanceAnswerFileDetailsId,
        setDownloadQuestionSetInstanceAnswerFileDetailsId,
    ] = useState<number | null>(null);
    const [removeActionItemFileId, setRemoveActionItemFileId] = useState<number | null>(null);
    const [
        showDeleteActionItemFileConfirmationModal,
        setShowDeleteActionItemFileConfirmationModal,
    ] = useState(false);

    const toast = useToast();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });

    const getMyActionItemDetails = useGetMyActionItemDetails(actionItemId, actionItemInstanceId);

    const actionItemDetailsResponseData = getMyActionItemDetails[0].data;
    const associatedRaciSetUsersResponseData = getMyActionItemDetails[1].data;
    const getAdditionalFieldValuesResponseData = getMyActionItemDetails[2].data;
    const actionItemFilesResponseDate = getMyActionItemDetails[3].data;

    const uploadActionItemFile = useUploadActionItemFile();
    const downloadActionItemFile = useDownloadActionItemFile(downloadActionItemInstanceFileId);
    const downloadQuestionSetInstanceAnswerFile = useDownloadQuestionSetInstanceAnswerFile(
        downloadQuestionSetInstanceAnswerFileDetailsId
    );
    const removeActionItemFile = useRemoveActionItemFile();

    useLoader(
        areQueriesLoading(getMyActionItemDetails) ||
            areMutationsLoading([uploadActionItemFile, removeActionItemFile]),
        ActionItemDetails
    );

    const onUploadActionItemFile = async (
        event: React.ChangeEvent<HTMLInputElement>
    ): Promise<void> => {
        const { files } = event.target;
        const selectedFiles = files as FileList;
        const file = selectedFiles?.[0];

        const fileBase64String = await getBase64FileString(file);

        if (fileBase64String === null) {
            toast.addToast(createErrorToastProps([t("FailedToReadFile")]));
        } else {
            uploadActionItemFile.mutate(
                new ActionItemInstanceFileUploadDto(actionItemId, file, fileBase64String!),
                {
                    onSuccess: async () => {
                        toast.addToast(
                            createSuccessToastProps([
                                t("EvidenceSuccessfullyUploaded", { keyPrefix: ActionPlans }),
                            ])
                        );
                        queryClient.refetchQueries(["getActionItemInstanceFiles", actionItemId]);
                    },
                    onError: errorResponseToDisplayHandler,
                }
            );
        }

        event.target.value = "";
    };

    const onDownloadActionItemFile = (fileId: number): void =>
        setDownloadActionItemInstanceFileId(fileId);

    const onDownloadQuestionSetInstanceAnswerFile = (fileDetailsId: number): void =>
        setDownloadQuestionSetInstanceAnswerFileDetailsId(fileDetailsId);

    const onDeleteActionItemFile = (fileId: number): void => {
        setShowDeleteActionItemFileConfirmationModal(true);
        setRemoveActionItemFileId(fileId);
    };

    const onRemoveFile = (): void => {
        removeActionItemFile.mutate(removeActionItemFileId!, {
            onSuccess: async () => {
                toast.addToast(createSuccessToastProps([t("EvidenceRemovedFromActionItem")]));
                queryClient.refetchQueries(["getActionItemInstanceFiles", actionItemId]);
            },
        });
    };

    const renderAssociatedRaciSetUsersDetailLabels = (): JSX.Element => (
        <>
            {associatedRaciSetUsersResponseData &&
                associatedRaciSetUsersResponseData?.map((x) => {
                    return <DetailsLabel>{t(x.label)}</DetailsLabel>;
                })}
        </>
    );

    const renderAssociatedRaciSetUsersDetailValues = (): JSX.Element => (
        <>
            {associatedRaciSetUsersResponseData &&
                associatedRaciSetUsersResponseData?.map((x) => {
                    return <DetailsValue>{x.value || "-"}</DetailsValue>;
                })}
        </>
    );

    const renderAdditionalInformationDetailLabels = (): JSX.Element => (
        <>
            {getAdditionalFieldValuesResponseData &&
                getAdditionalFieldValuesResponseData?.map((x) => {
                    return (
                        <DetailsLabel>{x.actionItemDynamicFieldDetailsDto.fieldName}</DetailsLabel>
                    );
                })}
        </>
    );

    const renderExternalSystemInformation = (): JSX.Element => (
        <>
            <StyledRow>
                <Col md="2">
                    <DetailsLabel>{t("SourceSystemName")}</DetailsLabel>
                </Col>
                <Col md="10">
                    <DetailsValue>{actionItemDetailsResponseData!.externalSystemName}</DetailsValue>
                </Col>
            </StyledRow>
            <StyledRow>
                <Col md="2">
                    <DetailsLabel>{t("ReferenceRelationshipName")}</DetailsLabel>
                </Col>
                <Col md="10">
                    <DetailsValue>
                        {actionItemDetailsResponseData!.externalSystemReferenceRelationshipName}
                    </DetailsValue>
                </Col>
            </StyledRow>
            <StyledRow>
                <Col md="2">
                    <DetailsLabel>{t("RelationshipLinkID")}</DetailsLabel>
                </Col>
                <Col md="10">
                    <DetailsValue>
                        <SbLink
                            variant={"primary"}
                            label={actionItemDetailsResponseData!.externalSystemReferenceId}
                            navigateTo={actionItemDetailsResponseData!.externalSystemLinkUrl}
                        />
                    </DetailsValue>
                </Col>
            </StyledRow>
        </>
    );

    const renderAdditionalInformationDetailValues = (): JSX.Element => (
        <>
            {getAdditionalFieldValuesResponseData &&
                getAdditionalFieldValuesResponseData?.map((x) => {
                    return <DetailsValue>{x.value || "-"}</DetailsValue>;
                })}
        </>
    );

    const renderActionItemDetails = (): JSX.Element => (
        <>
            <StyledRow>
                <Col md="2">{renderAssociatedRaciSetUsersDetailLabels()}</Col>
                <Col md="10">{renderAssociatedRaciSetUsersDetailValues()}</Col>
            </StyledRow>
            <StyledRow>
                <Col md="2">
                    <DetailsLabel>
                        {t("ActionItemType", { keyPrefix: ActionItemTypes })}
                    </DetailsLabel>
                    <DetailsLabel>{t("ActionItemSubType")}</DetailsLabel>
                    <DetailsLabel>{t("ActionItemUrgency")}</DetailsLabel>
                </Col>
                <Col md="10">
                    <DetailsValue>
                        {actionItemDetailsResponseData!.actionItemTypeDto.value}
                    </DetailsValue>
                    <DetailsValue>
                        {actionItemDetailsResponseData!.actionItemSubTypeDto.value}
                    </DetailsValue>
                    <DetailsValue>
                        {ActionItemImportance[
                            actionItemDetailsResponseData!.actionItemImportance
                        ].toString()}
                    </DetailsValue>
                </Col>
            </StyledRow>
            <StyledRow>
                <Col md="2">
                    <DetailsLabel>{t("RemedialAction")}</DetailsLabel>
                </Col>
                <Col md="10">
                    <DetailsValue>{actionItemDetailsResponseData!.requiredOutcome}</DetailsValue>
                </Col>
            </StyledRow>
            {actionItemDetailsResponseData!.externalSystemName && renderExternalSystemInformation()}
            <StyledRow>
                <Col md="2">{renderAdditionalInformationDetailLabels()}</Col>
                <Col md="10">{renderAdditionalInformationDetailValues()}</Col>
            </StyledRow>
        </>
    );

    const renderAttachedEvidence = (): JSX.Element => (
        <>
            <StyledBorderedSection>
                <FileGallery
                    files={actionItemFilesResponseDate!}
                    filesLabel={`${t("ActionItemFiles")}`}
                    uploadLabel={`${t("AttachEvidence")}`}
                    onUploadFile={isFinalizedItem ? undefined : onUploadActionItemFile}
                    onDownloadFile={onDownloadActionItemFile}
                    fileDownloadData={downloadActionItemFile.data!}
                    onRemoveFile={onDeleteActionItemFile}
                />
            </StyledBorderedSection>
            <StyledBorderedSection>
                <FileGallery
                    files={actionItemDetailsResponseData!.questionSetInstanceAnswerFileDtos}
                    filesLabel={t("QuestionSetInstanceAnswerFiles") as string}
                    onDownloadFile={onDownloadQuestionSetInstanceAnswerFile}
                    fileDownloadData={downloadQuestionSetInstanceAnswerFile.data!}
                />
            </StyledBorderedSection>
        </>
    );

    return areQueriesSuccessful(getMyActionItemDetails) ? (
        <StyledActionItemSectionContainer>
            <SbModal
                title={t("RemoveActionItemFile")}
                body={
                    t("RemoveActionPlanFileConfirmationText", {
                        keyPrefix: ActionPlans,
                    }) as string
                }
                primaryButtonLabel={`${t("Yes", { keyPrefix: Common })}`}
                secondaryButtonLabel={`${t("No", { keyPrefix: Common })}`}
                onPrimaryButtonClicked={onRemoveFile}
                isVisible={showDeleteActionItemFileConfirmationModal}
                updateIsVisible={setShowDeleteActionItemFileConfirmationModal}
            />

            <StyledTitleText>{t("ActionItemDetails")}</StyledTitleText>
            {renderActionItemDetails()}
            <LargeVerticalSpace />

            <StyledTitleText>{t("AttachedEvidence")}</StyledTitleText>
            {renderAttachedEvidence()}
        </StyledActionItemSectionContainer>
    ) : (
        <></>
    );
};

export default ActionItemDetails;
