import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
} from "../common/base-pagination-request";
import {
    buildWizardNotificationHistorySearchParameters,
    SearchWizardNotificationHistoryRequest,
} from "./search-wizard-notification-history-request";

export interface FilterWizardNotificationHistoryRequest
    extends SearchWizardNotificationHistoryRequest,
        BasePaginationRequest {}

export const createFilterWizardNotificationHistoryRequest = (
    basePaginationRequest: BasePaginationRequest,
    searchWizardNotificationHistoryRequest: SearchWizardNotificationHistoryRequest
): FilterWizardNotificationHistoryRequest => ({
    pageNumber: basePaginationRequest.pageNumber,
    pageSize: basePaginationRequest.pageSize,
    userId: searchWizardNotificationHistoryRequest.userId,
});

export const buildFilterSearchParameters = (
    request: FilterWizardNotificationHistoryRequest
): URLSearchParams => {
    const searchParams = buildBasePaginationSearchParameters(request);
    buildWizardNotificationHistorySearchParameters(request, searchParams);

    return searchParams;
};
