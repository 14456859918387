import { TFunction } from "i18next";
import { Common } from "../../core/constants/translation-namespace";
import { translateText } from "./translate";

export const translateWithFallback = (t: TFunction, value: string, keyPrefix?: string): string => {
    if (!value) return "";

    let translatedText = translateText(t, value, keyPrefix);

    return value === translatedText && !t(value, { keyPrefix: Common })?.includes(`${Common}.`)
        ? t(value, { keyPrefix: Common })
        : translatedText;
};
