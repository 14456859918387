import { FilterNonWorkingDaySetRequest } from "./filter-non-working-day-sets-request";

export interface SearchNonWorkingDaySetRequest {
    nonWorkingDaySetName: string | null;
    nonWorkingDayName: string | null;
    date: string | null;
}

export const emptySearchNonWorkingDaySetRequest = (): SearchNonWorkingDaySetRequest => ({
    nonWorkingDaySetName: null,
    nonWorkingDayName: null,
    date: null,
});

const SearchParameters = {
    nonWorkingDaySetName: "nonWorkingDaySetName",
    nonWorkingDayName: "nonWorkingDayName",
    date: "date",
};

export const buildSearchNonWorkingDaySetSearchParameters = (
    request: FilterNonWorkingDaySetRequest,
    searchParams: URLSearchParams
): URLSearchParams => {
    if (request.nonWorkingDaySetName) {
        searchParams.set(SearchParameters.nonWorkingDaySetName, request.nonWorkingDaySetName);
    }

    if (request.nonWorkingDayName) {
        searchParams.set(SearchParameters.nonWorkingDayName, request.nonWorkingDayName);
    }

    if (request.date) {
        searchParams.set(SearchParameters.date, request.date);
    }

    return searchParams;
};
