import { MaxIntValue } from "../../../../core/constants/numerical";

export enum DayOrdinal {
    None = 0,
    Last = MaxIntValue,
    First = 1,
    Second = 2,
    Third = 3,
    Fourth = 4,
    Fifth = 5,
    Sixth = 6,
    Seventh = 7,
    Eighth = 8,
    Ninth = 9,
    Tenth = 10,
    Eleventh = 11,
    Twelfth = 12,
    Thirteenth = 13,
    Fourteenth = 14,
    Fifteenth = 15,
    Sixteenth = 16,
    Seventeenth = 17,
    Eighteenth = 18,
    Nineteenth = 19,
    Twentieth = 20,
    TwentyFirst = 21,
    TwentySecond = 22,
    TwentyThird = 23,
    TwentyFourth = 24,
    TwentyFifth = 25,
    TwentySixth = 26,
    TwentySeventh = 27,
    TwentyEighth = 28,
    TwentyNinth = 29,
    Thirtieth = 30,
    ThirtyFirst = 31,
}
