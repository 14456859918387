export enum ActionInstanceStatus {
    New,
    Pending,
    Overdue,
    Cancelled,
    Completed,
    Due,
    Open,
    OnHold,
    Unpublished,
}
