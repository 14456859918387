import BaseQuestionCaptureResponse from "../../responses/answer-capture/base-question-capture-response";
import AnswerTypeDto from "../answer-types/answer-type-dto";
import BaseQuestionTextDto from "./base-question-text-dto";
import NestedQuestionCaptureDto from "./nested-question-capture-dto";

export default class BaseQuestionCaptureDto {
    canTriggerActionItem: boolean;
    questionId: number;
    questionTextDto: BaseQuestionTextDto;
    answerTypeDto: AnswerTypeDto;
    nestedQuestionCaptureDtos: NestedQuestionCaptureDto[] | null;

    public constructor(
        response: BaseQuestionCaptureResponse,
        nestedQuestionCaptureDtos: NestedQuestionCaptureDto[] | null
    ) {
        this.canTriggerActionItem = response.canTriggerActionItem;
        this.questionId = response.questionTextResponse.questionId;
        this.questionTextDto = new BaseQuestionTextDto(response.questionTextResponse);
        this.answerTypeDto = new AnswerTypeDto(response.answerTypeResponse);
        this.nestedQuestionCaptureDtos = nestedQuestionCaptureDtos;
    }
}
