import { CreateReferentialLinkTypeRequest } from "../../../domain/requests/referential-link-types/create-referential-link-type-request";
import { EditReferentialLinkTypeRequest } from "../../../domain/requests/referential-link-types/edit-referential-link-type-request";
import {
    buildFilterReferentialLinkTypeSearchParameters,
    FilterReferentialLinkTypeRequest,
} from "../../../domain/requests/referential-link-types/filter-referential-link-type-request";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import { ReferentialLinkTypeResponse } from "../../../domain/responses/referential-links/referential-link-type-response";
import client from "../../http-client";

const route = "referentiallinktype";

export const getReferentialLinkTypes = async (
    prefixUrl: string,
    request: FilterReferentialLinkTypeRequest
): Promise<Response<PaginationResponse<ReferentialLinkTypeResponse>>> => {
    const searchParams = buildFilterReferentialLinkTypeSearchParameters(request);

    return await client
        .get(`${route}/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getAllReferentialLinkTypes = async (
    prefixUrl: string
): Promise<Response<ReferentialLinkTypeResponse[]>> =>
    await client
        .get(`${route}/referential-link-types`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const getReferentialLinkTypeDetails = async (
    prefixUrl: string,
    referentialLinkTypeId: number
): Promise<Response<ReferentialLinkTypeResponse>> =>
    await client
        .get(`${route}/${referentialLinkTypeId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const createReferentialLinkType = async (
    prefixUrl: string,
    request: CreateReferentialLinkTypeRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/create`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const editReferentialLinkType = async (
    prefixUrl: string,
    request: EditReferentialLinkTypeRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/edit`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const deleteReferentialLinkType = async (
    prefixUrl: string,
    referentialLinkTypeId: number
): Promise<Response<boolean>> =>
    await client
        .delete(`${route}/delete/${referentialLinkTypeId}`, {
            prefixUrl: prefixUrl,
        })
        .json();
