import { AssociateRaciParticipantDto } from "./associate-raci-participant-dto";

export class DissociateRaciParticipantDto extends AssociateRaciParticipantDto {
    raciSetId: number;

    public constructor(raciSetId: number, dto: AssociateRaciParticipantDto) {
        const { userId, actionItemId, raciRelationship } = dto;

        super(userId, actionItemId, raciRelationship);
        this.raciSetId = raciSetId;
    }
}
