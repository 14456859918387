import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { createAlternativeQuestionText } from "../../../data/services/questions/question-service";
import { CreateQuestionTextDto } from "../../dtos/questions/create-question-text-dto";
import { createCreateAlternativeQuestionTextRequest } from "../../requests/questions/create-alternative-question-text-request";
import { Response } from "../../responses/common/response-response";

export const useCreateAlternativeQuestionText = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    CreateQuestionTextDto
> => {
    const url = useUrl();

    return useMutation((dto: CreateQuestionTextDto) => {
        const request = createCreateAlternativeQuestionTextRequest(dto);

        return createAlternativeQuestionText(url.baseUrl, request);
    });
};
