export interface CreateReferentialLinkRequest {
    referentialLinkTypeId: number;
    referentialLinkValue: string;
}

export const createCreateReferentialLinkRequest = (
    referentialLinkTypeId: number,
    referentialLinkValue: string
): CreateReferentialLinkRequest => ({
    referentialLinkTypeId: referentialLinkTypeId,
    referentialLinkValue: referentialLinkValue,
});
