import { Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { SectionVerticalSpace, StyledFormLabel } from "../../core/theme/global-styles";
import SbLabelText from "../atoms/SbLabelText";

const StyledFormControl = styled(Form.Control)`
    background-color: ${(props) => props.theme.palette.purewhite};
    color: ${(props) => props.theme.palette.black};
`;

export const SbFileUpload: React.FC<{
    label: string;
    onUploadFile: React.ChangeEventHandler<HTMLInputElement>;
}> = ({ label, onUploadFile }) => (
    <>
        <Form.Group as={Row} className="mb-3">
            <StyledFormLabel column sm={2}>
                <SbLabelText label={label} />
            </StyledFormLabel>
            <Col sm={4}>
                <StyledFormControl name="file" type="file" onChange={onUploadFile} />
            </Col>
        </Form.Group>
        <SectionVerticalSpace />
    </>
);
