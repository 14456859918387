import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
    createBasePaginationRequest,
} from "../common/base-pagination-request";

export interface FilterReviewReportSetFileRequest extends BasePaginationRequest {
    reviewReportSetId: number;
}

export const createFilterReviewReportSetFileRequest = (
    reviewReportSetId: number,
    paginationDto: BasePaginationDto
): FilterReviewReportSetFileRequest => ({
    reviewReportSetId: reviewReportSetId,
    ...createBasePaginationRequest(paginationDto.pageNumber, paginationDto.pageSize),
});

export const buildFilterSearchParameters = (
    request: FilterReviewReportSetFileRequest
): URLSearchParams => {
    const searchParams = buildBasePaginationSearchParameters(request);

    searchParams.set("reviewReportSetId", request.reviewReportSetId.toString());

    return searchParams;
};
