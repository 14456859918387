import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import { getReferentialLinkTypes } from "../../../data/services/referential-link-types/referential-link-type-service";
import { Response } from "../../../domain/responses/common/response-response";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import BaseReferentialLinkTypeDto from "../../dtos/referential-links/base-referential-link-type-dto";
import { FilterReferentialLinkTypeDto } from "../../dtos/referential-links/filter-referential-link-type-dto";
import { createFilterReferentialLinkTypeRequestFromDto } from "../../requests/referential-link-types/filter-referential-link-type-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { ReferentialLinkTypeResponse } from "../../responses/referential-links/referential-link-type-response";

export const useGetReferentialLinkTypes = (
    filterDto: FilterReferentialLinkTypeDto
): UseQueryResult<PaginatedTableDto<BaseReferentialLinkTypeDto>, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getReferentialLinkTypes", filterDto],
        () =>
            getReferentialLinkTypes(
                url.baseUrl,
                createFilterReferentialLinkTypeRequestFromDto(filterDto)
            ),
        {
            keepPreviousData: true,
            select: transformToDataTableRows,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const transformToDataTableRows = (
    response: Response<PaginationResponse<ReferentialLinkTypeResponse>>
): PaginatedTableDto<BaseReferentialLinkTypeDto> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((referentialLinkType) => {
        return {
            metadata: new BaseReferentialLinkTypeDto(
                referentialLinkType.referentialLinkTypeId,
                referentialLinkType.name
            ),
            columns: [
                {
                    value: referentialLinkType.name,
                    type: DataTableColumnTypes.Link,
                },
                {
                    value: referentialLinkType.referentialLinkCount?.toString(),
                    type: DataTableColumnTypes.Text,
                },
            ],
        };
    });

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
