import React from "react";
import { Button, Card } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import styled from "styled-components";
import { EndAlignedDiv, StyledBoldSpan, StyledSpan } from "../../core/theme/global-styles";
import { ImageLibraryViewResponse } from "../../domain/responses/image-library/image-library-view-response";

const StyledButton = styled(Button)<any>`
    background-color: ${(props) => props.theme.palette.purewhite};
    color: ${(props) => props.theme.palette.danger};
    border: 1px solid ${(props) => props.theme.palette.purewhite};
    &:hover {
        background-color: ${(props) => props.theme.palette.danger};
        color: ${(props) => props.theme.palette.purewhite};
        border: 1px solid ${(props) => props.theme.palette.danger};
    }
`;

const StyledCard = styled(Card)`
    color: ${(props) => props.theme.palette.white};
    align: center;
    width: 320px;
    height: 400px;
    min-height: 1px;
    padding-top: ${(props) => props.theme.padding.lg};
    padding-left: ${(props) => props.theme.padding.lg};
    padding-right: ${(props) => props.theme.padding.lg};
    margin-left: ${(props) => props.theme.padding.lg};
    margin-bottom: ${(props) => props.theme.padding.lg};
    font-color: ${(props) => props.theme.palette.black};
    box-shadow: 0 3px 6px ${(props) => props.theme.palette.grey};
    display: block;
    overflow: hidden;
`;

const StyledCardImg = styled(Card.Img)``;

export const CardGallery: React.FC<{
    rows: ImageLibraryViewResponse[] | undefined;
    deleteImage: (fileDetailsId: number) => void;
}> = ({ rows, deleteImage }) => {
    return (
        <Row xs={2} md={5}>
            {rows &&
                rows.map((row, index) => (
                    <StyledCard key={index} id={row.fileDetailsId.toString()}>
                        <EndAlignedDiv>
                            <StyledButton
                                variant="danger"
                                type="button"
                                onClick={() => deleteImage(row.fileDetailsId)}
                            >
                                X
                            </StyledButton>
                        </EndAlignedDiv>
                        <StyledCardImg
                            src={row.fileImagePath}
                            alt="Image Not Available"
                            width="25%"
                            height="70%"
                        />
                        <Card.Body>
                            <Card.Text>
                                <StyledBoldSpan>File Name </StyledBoldSpan>
                                <StyledSpan>{row.fileName}</StyledSpan>
                            </Card.Text>
                        </Card.Body>
                    </StyledCard>
                ))}
        </Row>
    );
};
