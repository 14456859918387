import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import {
    ActionItems,
    ChecklistAnswers,
    Common,
} from "../../../core/constants/translation-namespace";
import { EndAlignedDiv, SectionContainer } from "../../../core/theme/global-styles";
import { getEnumsForType } from "../../../core/utilities/enum-helper";
import FilterUserQuestionsDto from "../../../domain/dtos/answer-capture/filter-user-questions-dto";
import BaseChecklistDto from "../../../domain/dtos/checklists/base-checklist-dto";
import {
    createDefaultAllReactSelectDto,
    createReactSelectDto,
    ReactSelectDropdownDto,
    ReactSelectDto,
} from "../../../domain/dtos/common/react-select-dto";
import { NodeDescriptionDetailsDto } from "../../../domain/dtos/hierarchy/node-description-details-dto";
import DataStatus from "../../../domain/enums/common/DataStatus";
import InstanceStatus from "../../../domain/enums/questions/instance-status";
import { ResetButton, SearchButton } from "../../atoms/SbButton";
import { translateWithFallback } from "../../helpers/translate-with-common-fallback";
import { FilterSelect } from "../../molecules/FilterSelect";
import { SbAccordion } from "../../molecules/SbAccordion";

const defaultAnswerStatusDto = createDefaultAllReactSelectDto<InstanceStatus>();
const answerStatusDropdownDto = [
    defaultAnswerStatusDto,
    createReactSelectDto(InstanceStatus.Due, InstanceStatus[InstanceStatus.Due]),
    createReactSelectDto(InstanceStatus.Overdue, InstanceStatus[InstanceStatus.Overdue]),
    createReactSelectDto(InstanceStatus.Pending, InstanceStatus[InstanceStatus.Pending]),
];

const defaultDataStatusDto = createDefaultAllReactSelectDto<DataStatus>();
const dataStatusDropdownDto = [
    defaultDataStatusDto,
    ...getEnumsForType(DataStatus).map((x) => createReactSelectDto(x, DataStatus[x])),
];

class AnswerCaptureFilterInput {
    nodesReactSelectDto: ReactSelectDto<NodeDescriptionDetailsDto | null>;
    checklistsReactSelectDto: ReactSelectDto<BaseChecklistDto | null>;
    answerStatusReactSelectDto: ReactSelectDto<InstanceStatus | null> = defaultAnswerStatusDto;
    dataStatusReactSelectDto: ReactSelectDto<DataStatus | null> = defaultDataStatusDto;

    constructor(
        nodesReactSelectDto: ReactSelectDto<NodeDescriptionDetailsDto | null>,
        checklistsReactSelectDto: ReactSelectDto<BaseChecklistDto | null>,
        answerStatusReactSelectDto: ReactSelectDto<InstanceStatus | null> | null = null,
        dataStatusReactSelectDto: ReactSelectDto<DataStatus | null> | null = null
    ) {
        this.nodesReactSelectDto = nodesReactSelectDto;
        this.checklistsReactSelectDto = checklistsReactSelectDto;
        this.answerStatusReactSelectDto = answerStatusReactSelectDto ?? defaultAnswerStatusDto;
        this.dataStatusReactSelectDto = dataStatusReactSelectDto ?? defaultDataStatusDto;
    }
}

export const AnswerCaptureFilter: React.FC<{
    nodesDropdownDto: ReactSelectDropdownDto<NodeDescriptionDetailsDto>;
    checklistsDropdownDto: ReactSelectDropdownDto<BaseChecklistDto>;
    nodeIdSearchParam: number | null;
    instanceStatusSearchParam: InstanceStatus | null;
    dataStatusSearchParam: DataStatus | null;
    onActionClicked: (_: FilterUserQuestionsDto) => void;
}> = ({
    nodesDropdownDto,
    checklistsDropdownDto,
    nodeIdSearchParam,
    instanceStatusSearchParam,
    dataStatusSearchParam,
    onActionClicked,
}) => {
    useEffect(() => {
        const nodesReactSelectDto = nodeIdSearchParam
            ? nodesDropdownDto.values.find((x) => x.value.nodeId === nodeIdSearchParam) ?? null
            : null;

        const instanceStatusReactSelectDto = instanceStatusSearchParam
            ? answerStatusDropdownDto.find((x) => x.value === instanceStatusSearchParam) ?? null
            : null;

        const dataStatusReactSelectDto = dataStatusSearchParam
            ? dataStatusDropdownDto.find((x) => x.value === dataStatusSearchParam) ?? null
            : null;

        if (
            nodesReactSelectDto !== null ||
            instanceStatusReactSelectDto !== null ||
            dataStatusReactSelectDto !== null
        ) {
            const searchedParamAnswerCaptureFilterInput = new AnswerCaptureFilterInput(
                nodesReactSelectDto ?? nodesDropdownDto.defaultValue,
                checklistsDropdownDto.defaultValue,
                instanceStatusReactSelectDto,
                dataStatusReactSelectDto
            );

            setAnswerCaptureFilterInput(searchedParamAnswerCaptureFilterInput);

            const filterUserQuestionsDto = new FilterUserQuestionsDto(
                nodesReactSelectDto ? nodeIdSearchParam : null,
                null,
                instanceStatusReactSelectDto ? instanceStatusSearchParam : null,
                dataStatusReactSelectDto ? dataStatusSearchParam : null
            );

            onActionClicked(filterUserQuestionsDto);
        } else {
            setAnswerCaptureFilterInput(defaultAnswerCaptureFilterInput);
        }
    }, []);

    const defaultAnswerCaptureFilterInput = new AnswerCaptureFilterInput(
        nodesDropdownDto.defaultValue,
        checklistsDropdownDto.defaultValue
    );

    const { t } = useTranslation("translation", { keyPrefix: ChecklistAnswers });
    const [answerCaptureFilterInput, setAnswerCaptureFilterInput] =
        useState<AnswerCaptureFilterInput>();

    const onNodeSelected = (
        option: SingleValue<ReactSelectDto<NodeDescriptionDetailsDto | null>>
    ): void => {
        setAnswerCaptureFilterInput({
            ...answerCaptureFilterInput!,
            nodesReactSelectDto: option!,
        });
    };

    const onChecklistSelected = (
        option: SingleValue<ReactSelectDto<BaseChecklistDto | null>>
    ): void => {
        setAnswerCaptureFilterInput({
            ...answerCaptureFilterInput!,
            checklistsReactSelectDto: option!,
        });
    };

    const onAnswerStatusSelected = (
        option: SingleValue<ReactSelectDto<InstanceStatus | null>>
    ): void => {
        setAnswerCaptureFilterInput({
            ...answerCaptureFilterInput!,
            answerStatusReactSelectDto: option!,
        });
    };

    const onDataStatusSelected = (option: SingleValue<ReactSelectDto<DataStatus | null>>): void => {
        setAnswerCaptureFilterInput({
            ...answerCaptureFilterInput!,
            dataStatusReactSelectDto: option!,
        });
    };

    const onSearchClicked = (): void => {
        const {
            nodesReactSelectDto,
            checklistsReactSelectDto,
            answerStatusReactSelectDto,
            dataStatusReactSelectDto,
        } = answerCaptureFilterInput!;

        const filterUserQuestionsDto = new FilterUserQuestionsDto(
            nodesReactSelectDto.value?.nodeId ?? null,
            checklistsReactSelectDto.value?.checklistId ?? null,
            answerStatusReactSelectDto.value ?? null,
            dataStatusReactSelectDto.value ?? null
        );

        onActionClicked(filterUserQuestionsDto);
    };

    const onResetClicked = (): void => {
        setAnswerCaptureFilterInput(defaultAnswerCaptureFilterInput);
        onActionClicked(FilterUserQuestionsDto.emptyFilterUserQuestionsDto);
    };

    return (
        <SbAccordion title={t("SearchFilters") as string}>
            {answerCaptureFilterInput && (
                <>
                    <SectionContainer>
                        <Row>
                            <Col md={3}>
                                <FilterSelect
                                    name={"functions"}
                                    onChange={(
                                        option: SingleValue<
                                            ReactSelectDto<NodeDescriptionDetailsDto | null>
                                        >
                                    ) => onNodeSelected(option)}
                                    value={answerCaptureFilterInput!.nodesReactSelectDto}
                                    items={nodesDropdownDto.values}
                                    label={t("Functions") as string}
                                    searchable
                                    clearable={false}
                                    itemDisplayText={(
                                        option: ReactSelectDto<NodeDescriptionDetailsDto | null>
                                    ) => option.label} //TODO: Add translations for dynamic data
                                />
                            </Col>
                            <Col md={3}>
                                <FilterSelect
                                    name={"checklist"}
                                    onChange={(
                                        option: SingleValue<ReactSelectDto<BaseChecklistDto | null>>
                                    ) => onChecklistSelected(option)}
                                    items={checklistsDropdownDto.values}
                                    value={answerCaptureFilterInput!.checklistsReactSelectDto}
                                    label={"Checklists"}
                                    searchable
                                    clearable={false}
                                    itemDisplayText={
                                        (option: ReactSelectDto<BaseChecklistDto | null>) =>
                                            option.label //TODO: Add translations for dynamic data
                                    }
                                />
                            </Col>
                            <Col md={3}>
                                <FilterSelect
                                    name={"answerStatus"}
                                    onChange={(
                                        option: SingleValue<ReactSelectDto<InstanceStatus | null>>
                                    ) => onAnswerStatusSelected(option)}
                                    items={answerStatusDropdownDto}
                                    value={answerCaptureFilterInput!.answerStatusReactSelectDto}
                                    label={t("AnswerStatus") as string}
                                    searchable
                                    clearable={false}
                                    itemDisplayText={(
                                        item: ReactSelectDto<InstanceStatus | null>
                                    ) => {
                                        if (item.value === null) {
                                            return t(item.label, { keyPrefix: Common });
                                        }

                                        return t(item.label, { keyPrefix: ActionItems });
                                    }}
                                />
                            </Col>
                            <Col md={3}>
                                <FilterSelect
                                    name={"data"}
                                    onChange={(
                                        option: SingleValue<ReactSelectDto<DataStatus | null>>
                                    ) => onDataStatusSelected(option)}
                                    items={dataStatusDropdownDto}
                                    value={answerCaptureFilterInput!.dataStatusReactSelectDto}
                                    label={
                                        t("AnswerCaptureDataDisplay", {
                                            keyPrefix: ChecklistAnswers,
                                        }) as string
                                    }
                                    searchable
                                    clearable={false}
                                    itemDisplayText={(item: ReactSelectDto<DataStatus | null>) =>
                                        translateWithFallback(t, item.label, Common)
                                    }
                                />
                            </Col>
                        </Row>
                    </SectionContainer>
                    <EndAlignedDiv>
                        <SearchButton onClick={onSearchClicked} />
                        <ResetButton onClick={onResetClicked} />
                    </EndAlignedDiv>
                </>
            )}
        </SbAccordion>
    );
};
