enum AnswerValueType {
    Integer = 1,
    Decimal = 2,
    DateTime = 3,
    Boolean = 4,
    Text = 5,
    List = 6,
    TextArea = 7,
}

export default AnswerValueType;
