import {
    AnswerTypeSearchDto,
    AssuranceReviewSearchDto,
    SearchDto,
} from "../../dtos/common/search-dto";

export interface SearchRequest {
    searchText: string | null;
    maxSearchResults: number | null;
}

export interface AnswerTypeSearchRequest extends SearchRequest {
    questionTypeId: number | null;
}

export const createSearchRequest = (
    searchText: string | null,
    maxSearchResults: number | null
): SearchRequest => ({
    searchText,
    maxSearchResults,
});

export const createAnswerTypeSearchRequest = (
    searchText: string | null,
    questionTypeId: number | null,
    maxSearchResults: number | null
): AnswerTypeSearchRequest => ({
    searchText,
    questionTypeId,
    maxSearchResults,
});

export const createSearchRequestFromDto = (dto: SearchDto): SearchRequest => ({
    searchText: dto.searchText,
    maxSearchResults: dto.maxSearchResults,
});

export const createAnswerTypeSearchRequestFromDto = (
    dto: AnswerTypeSearchDto
): AnswerTypeSearchRequest => ({
    searchText: dto.searchText,
    questionTypeId: dto.questionTypeId,
    maxSearchResults: dto.maxSearchResults,
});

const SearchParameters = {
    searchText: "searchText",
    maxSearchResults: "maxSearchResults",
};

const AnswerTypeSearchParameters = {
    searchText: "searchText",
    questionTypeId: "questionTypeId",
    maxSearchResults: "maxSearchResults",
};

export const buildSearchRequestSearchParameters = (request: SearchRequest): URLSearchParams => {
    const searchParams = new URLSearchParams();
    if (request.searchText) {
        searchParams.set(SearchParameters.searchText, request.searchText);
    }

    if (request.maxSearchResults) {
        searchParams.set(SearchParameters.maxSearchResults, request.maxSearchResults.toString());
    }

    return searchParams;
};

export const buildAnswerTypeSearchRequestSearchParameters = (
    request: AnswerTypeSearchRequest
): URLSearchParams => {
    const searchParams = new URLSearchParams();
    if (request.searchText) {
        searchParams.set(AnswerTypeSearchParameters.searchText, request.searchText);
    }

    if (request.questionTypeId) {
        searchParams.set(
            AnswerTypeSearchParameters.questionTypeId,
            request.questionTypeId.toString()
        );
    }

    if (request.maxSearchResults) {
        searchParams.set(
            AnswerTypeSearchParameters.maxSearchResults,
            request.maxSearchResults.toString()
        );
    }

    return searchParams;
};

export interface AssuranceReviewSearchRequest {
    searchText: string | null;
    assuranceReviewId: number | null;
}

export const createAssuranceReviewSearchRequest = (
    searchText: string | null,
    assuranceReviewId: number | null
): AssuranceReviewSearchRequest => ({
    searchText,
    assuranceReviewId,
});

export const createAssuranceReviewSearchRequestFromDto = (
    dto: AssuranceReviewSearchDto
): AssuranceReviewSearchRequest => ({
    searchText: dto.searchText,
    assuranceReviewId: dto.assuranceReviewId,
});

const AssuranceReviewSearchParameters = {
    searchText: "searchText",
    assuranceReviewId: "assuranceReviewId",
};

export const buildAssuranceReviewSearchRequestSearchParameters = (
    request: AssuranceReviewSearchRequest
): URLSearchParams => {
    const searchParams = new URLSearchParams();
    if (request.searchText) {
        searchParams.set(AssuranceReviewSearchParameters.searchText, request.searchText);
    }

    if (request.assuranceReviewId) {
        searchParams.set(
            AssuranceReviewSearchParameters.assuranceReviewId,
            request.assuranceReviewId.toString()
        );
    }

    return searchParams;
};
