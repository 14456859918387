import { createBasePaginationDto, defaultBasePaginationDto } from "../common/base-pagination-dto";

export interface FilterUserAssignedActionItemsDto {
    actionItemId: string | null;
    name: string | null;
    actionInstanceStatusId: number | null;
    pageNumber: number;
    pageSize: number;
}

export const createFilterUserAssignedActionItemsDto = (
    actionItemId: string | null,
    name: string | null,
    actionInstanceStatusId: number | null,
    pageNumber: number,
    pageSize: number
): FilterUserAssignedActionItemsDto => ({
    actionItemId: actionItemId,
    name: name,
    actionInstanceStatusId: actionInstanceStatusId,
    ...createBasePaginationDto(pageNumber, pageSize),
});

export const defaultFilterUserAssignedActionItemsDto = (): FilterUserAssignedActionItemsDto => {
    const { pageNumber, pageSize } = defaultBasePaginationDto;
    return createFilterUserAssignedActionItemsDto(null, null, null, pageNumber, pageSize);
};
