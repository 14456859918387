export enum ReviewTypeFields {
    Responsible = "Responsible",
    Accountable = "Accountable",
    RootCause = "Root Cause",
    AssociatedRisk = "Associated Risk",
    TrainingOrCoaching = "Training / Coaching",
    TrainingOrCoachingWithoutSpaces = "training/coaching",
    AgreedActions = "Agreed Actions",
    ScopeExclusion = "Scope Exclusion ",
    BusinessUnitOverView = "Business Unit Overview",
    SummaryOfTestObjectives = "Summary Of Test Objectives ",
    Finding = "Finding",
    ActionItemDueDate = "Action Item Due Date",
}
