import { AddQuestionSetQuestionCommentDto } from "../../dtos/review-reports/add-question-set-question-comment-dto";

export class AddQuestionSetQuestionCommentRequest {
    questionSetInstanceId: number;
    questionSetInstanceAnswerId: number;
    commentText: string;

    public constructor(addQuestionSetQuestionCommentDto: AddQuestionSetQuestionCommentDto) {
        this.questionSetInstanceId = addQuestionSetQuestionCommentDto.questionSetInstanceId;
        this.questionSetInstanceAnswerId =
            addQuestionSetQuestionCommentDto.questionSetInstanceAnswerId;
        this.commentText = addQuestionSetQuestionCommentDto.commentText;
    }
}
