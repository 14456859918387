import { AssociateLinkedNodeTypeDto } from "../../dtos/hierarchy/associate-linked-node-type-dto";
import {
    createDisassociateLinkedNodeTypeRequest,
    DisassociateLinkedNodeTypeRequest,
} from "./disassociate-linked-node-type-request";

export interface AssociateLinkedNodeTypeRequest extends DisassociateLinkedNodeTypeRequest {
    isRequired: boolean;
}

export const createAssociateLinkedNodeTypeRequest = (
    dto: AssociateLinkedNodeTypeDto
): AssociateLinkedNodeTypeRequest => ({
    ...createDisassociateLinkedNodeTypeRequest(dto),
    isRequired: dto.isRequired,
});
