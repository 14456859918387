import { DefaultTheme } from "styled-components";

export const globalTheme: DefaultTheme = {
    padding: {
        navbar: "50px",
        drawerOpen: "256px",
        drawerClosed: "100px",
        xxs: "1px",
        xs: "2px",
        sm: "4px",
        md: "8px",
        lg: "16px",
        xl: "32px",
        xxl: "64px",
        xxxl: "140px",
    },
    palette: {
        primary: "#0033a1",
        primaryVariant: "#5c54c4",
        secondary: "#676a6c",
        secondaryVariant: "#b150c5",
        warning: "#f0ad4e",
        transparentWarning: "rgb(255, 165, 0, 0.45)",
        danger: "#d9534f",
        black: "#000000",
        white: "#f1f1f2",
        pink: "#b150c5",
        purple: "#5c54c4",
        blue: "#c4e3f3",
        grey: "#00000029",
        disableGrey: "#E9ECEF",
        purewhite: "#fff",
        importDangerBackground: "#f8d7da",
        hierarchyNode: {
            defaultNodePath: "#c3c3c3",
            defaultNode: "#fff",
            trainingNode: "#6ED96E",
            collapsedNode: "lightsteelblue",
        },
        info: "#ccecf8",
        advance: "#00A1E0",
        success: "#00af43",
        actionInstanceStatus: {
            pending: "#A8A8A8",
            overdue: "#be0a0a",
            cancelled: "#6a6a6a",
            completed: "#005e00",
            due: "#0033a1",
            open: "#ffbf00",
            onhold: "#e025c4",
        },
        shadowColour: "#00000029",
        dividerColour: "#efefef",
        highlightColour: "#e8bb49",
        instanceStatus: {
            completed: "#2EB82E",
            overdue: "#D80000",
            due: "#2570E0",
            notDue: "#A8A8A8",
        },
        theme: {
            standardBankPrimary: "#0033a1",
            iCapturePrimary: "#54b9d3",
            libertyPrimary: "#0033a1",
        },
    },
    font: {
        family: "",
        sm: "8px",
        md: "14px",
        lg: "16px",
        xl: "18px",
    },
    dimensions: {
        height: {
            xxs: "1px",
            xs: "2px",
            sm: "4px",
            md: "8px",
            lg: "16px",
            xl: "39px",
            xxl: "64px",
            xxxl: "150px",
        },
        width: {
            xxs: "1px",
            xs: "2px",
            sm: "4px",
            md: "8px",
            lg: "16px",
            xl: "32px",
            xxl: "60px",
            xxxl: "80px",
            xxxxl: "200px",
            xxxxxl: "306px",
        },
    },
    customPadding: {
        checkbox: "23px",
        saveAnswersButtonProd: "0",
        saveAnswersButtonNonProd: "45px",
    },
};
