import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY,
        extensions: [reactPlugin],
        disableTelemetry: process.env.REACT_APP_ENV === "local",
        disableAjaxTracking: true,
    },
});

appInsights.loadAppInsights();

export { reactPlugin, appInsights };
