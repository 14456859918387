import { UserWizardNotificationUpdateStatusRequest } from "../../../domain/requests/wizards/user-wizard-notification-update-status-request";
import { Response } from "../../../domain/responses/common/response-response";
import { WizardCardResponse } from "../../../domain/responses/wizards/wizard-card-response";
import client from "../../http-client";

const route = "wizards";

export const getWizardNotification = async (
    prefixUrl: string,
    userId: number
): Promise<Response<WizardCardResponse[]>> =>
    await client
        .get(`${route}/wizard-notification/${userId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const getLatestWizardNotification = async (
    prefixUrl: string,
    userId: number
): Promise<Response<WizardCardResponse[]>> =>
    await client
        .get(`${route}/latest/${userId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const editWizardNotificationStatus = async (
    prefixUrl: string,
    request: UserWizardNotificationUpdateStatusRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/update-wizard-notification-status`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();
