import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { createActionPlan } from "../../../data/services/actions/action-plans-service";
import { CreateActionPlanDto } from "../../dtos/actions/create-action-plan-dto";
import { CreateActionPlanRequest } from "../../requests/actions/create-action-plan-request";
import { Response } from "../../responses/common/response-response";

export const useCreateActionPlan = (): UseMutationResult<
    Response<number>,
    HTTPError,
    CreateActionPlanDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: CreateActionPlanDto) => {
        const request = new CreateActionPlanRequest(mutationKey);

        return createActionPlan(url.baseUrl, request);
    });
};
