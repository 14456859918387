import React, { ReactElement, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Common, Nodes, NonWorkingDays } from "../../../core/constants/translation-namespace";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
} from "../../../core/utilities/responseStateHelper";
import { NonWorkingDayDto } from "../../../domain/dtos/non-working-days/non-working-day-dto";
import { hasRoleTypeInGroup, NonWorkingDayRoleGroup } from "../../../domain/enums/Roles";
import { useGetNonWorkingDaySet } from "../../../domain/viewmodels/non-working-days/associated-non-working-day-set-node-viewmodel";
import { useGetNonWorkingDays } from "../../../domain/viewmodels/non-working-days/view-non-working-days-viewmodel";
import { CreateLink, EditLink, ViewLink } from "../../atoms/SbLink";
import { SbAccordion } from "../../molecules/SbAccordion";
import { DataTable } from "../../organisms/DataTable";

const ViewNonWorkingDaySetContainer: React.FC = () => {
    const menu = useMenu();
    const toast = useToast();
    const navigate = useNavigate();
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const auth = useAuth();
    const { t } = useTranslation("translation", { keyPrefix: NonWorkingDays });

    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";
    const nonWorkingDaySetId = Number(useParams().nonWorkingDaySetId);

    const getNonWorkingDays = useGetNonWorkingDays(nonWorkingDaySetId);
    const nonWorkingDaysResponseData = getNonWorkingDays[0].data;
    const nonWorkingDaySetNodeResponseData = getNonWorkingDays[1].data;

    const getNonWorkingDaySet = useGetNonWorkingDaySet(nonWorkingDaySetId);
    const name = getNonWorkingDaySet[0].data?.name;

    useLoader(
        areQueriesLoading(getNonWorkingDays) || areQueriesLoading(getNonWorkingDaySet),
        ViewNonWorkingDaySetContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.NonWorkingDay);

        if (success) {
            toast.addToast(createSuccessToastProps([t(messageKey)]));

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    const buildLinks = (): ReactElement<typeof Link> => {
        return (
            <>
                {hasRoleTypeInGroup(auth.userRoles, NonWorkingDayRoleGroup.WriteRoles) &&
                    buildEditLink()}
                {buildSetsLink()}
            </>
        );
    };

    const buildEditLink = (): ReactElement<typeof Link> => (
        <EditLink
            navigateTo={`${getPath(DrawerTitles.NonWorkingDay)}/${nonWorkingDaySetId}/edit`}
        />
    );

    const buildSetsLink = (): ReactElement<typeof Link> => (
        <ViewLink
            label={t("NonWorkingDaySets")}
            navigateTo={`${getPath(DrawerTitles.NonWorkingDay)}`}
        />
    );

    const navigateToDeleteNonWorkingDay = (metadata: NonWorkingDayDto): void => {
        const { nonWorkingDayId, date, name } = metadata;

        navigate(
            `${getPath(DrawerTitles.NonWorkingDay)}/${nonWorkingDayId}/delete-non-working-day`,
            {
                state: { date: date, name: name },
            }
        );
    };

    const navigateToEditNonWorkingDay = (metadata: NonWorkingDayDto): void => {
        const { nonWorkingDayId, date, name } = metadata;

        navigate(`${getPath(DrawerTitles.NonWorkingDay)}/${nonWorkingDayId}/edit-non-working-day`, {
            state: { date: date, name: name },
        });
    };

    const buildCreateLink = (): ReactElement<typeof Link> => (
        <CreateLink
            label={t("AssignToNode", { keyPrefix: Nodes })}
            navigateTo={`${getPath(
                DrawerTitles.NonWorkingDay
            )}/${nonWorkingDaySetId}/associate-node`}
        />
    );

    const navigateToViewNodeDetails = (nodeId: number): void =>
        navigate(`${getPath(AccordionTitles.VisualTree)}/${nodeId}`);

    return (
        <>
            <PageHeading>{t("NonWorkingDaySetDetailsTitle")}</PageHeading>
            <PageSubHeading>{t("HeaderHelpTextDetails")}</PageSubHeading>
            <SectionVerticalSpace />

            <ContentContainer>
                <Row>
                    <Col md="auto">
                        <DetailsLabel>{t("Name", { keyPrefix: Common })}</DetailsLabel>
                    </Col>
                    <Col md="auto">
                        <DetailsValue>{name}</DetailsValue>
                    </Col>
                </Row>

                <EndAlignedDiv>{buildLinks()}</EndAlignedDiv>
            </ContentContainer>
            <LargeVerticalSpace />

            <SbAccordion title={t("NonWorkingDayList")}>
                {hasRoleTypeInGroup(auth.userRoles, NonWorkingDayRoleGroup.WriteRoles) && (
                    <EndAlignedDiv>
                        <CreateLink
                            label={t("AddNewNonWorkingDay")}
                            navigateTo={`${getPath(
                                DrawerTitles.NonWorkingDay
                            )}/${nonWorkingDaySetId}/create-non-working-day`}
                        />
                    </EndAlignedDiv>
                )}
                {areQueriesSuccessful(getNonWorkingDays) &&
                    areQueriesSuccessful(getNonWorkingDaySet) && (
                        <DataTable
                            rows={nonWorkingDaysResponseData!.rows}
                            editItem={
                                hasRoleTypeInGroup(
                                    auth.userRoles,
                                    NonWorkingDayRoleGroup.WriteRoles
                                )
                                    ? navigateToEditNonWorkingDay
                                    : undefined
                            }
                            deleteItem={
                                hasRoleTypeInGroup(
                                    auth.userRoles,
                                    NonWorkingDayRoleGroup.WriteRoles
                                )
                                    ? navigateToDeleteNonWorkingDay
                                    : undefined
                            }
                        />
                    )}
            </SbAccordion>

            <LargeVerticalSpace />

            <SbAccordion title={t("AssignedNodes", { keyPrefix: Nodes })}>
                {hasRoleTypeInGroup(auth.userRoles, NonWorkingDayRoleGroup.WriteRoles) && (
                    <EndAlignedDiv>{buildCreateLink()}</EndAlignedDiv>
                )}
                {areQueriesSuccessful(getNonWorkingDays) &&
                    areQueriesSuccessful(getNonWorkingDaySet) && (
                        <DataTable
                            noResultsMessage={t("NoNodesAssigned", {
                                keyPrefix: Nodes,
                            }).toString()}
                            rows={nonWorkingDaySetNodeResponseData!.rows}
                            linkItem={navigateToViewNodeDetails}
                        />
                    )}
            </SbAccordion>
        </>
    );
};

export default ViewNonWorkingDaySetContainer;
