export interface SearchQuestionAssociationRequest {
    questionId: number | null;
}

export const emptySearchQuestionAssociationRequest = (): SearchQuestionAssociationRequest => ({
    questionId: null,
});

const SearchParameters = {
    questionId: "questionId",
};

export const buildSearchQuestionAssociationSearchParameters = (
    request: SearchQuestionAssociationRequest,
    searchParams: URLSearchParams
): URLSearchParams => {
    if (request.questionId != null) {
        searchParams.set(SearchParameters.questionId, request.questionId.toString());
    }

    return searchParams;
};
