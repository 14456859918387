import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { getReviewReportPdf } from "../../../data/services/review-reports/review-reports-pdf-service";
import { FileDownloadDto } from "../../dtos/file-storage/file-download-dto";
import { ReviewReportPdfDto } from "../../dtos/review-reports/review-report-pdf-dto";
import { ReviewReportPdfRequest } from "../../requests/review-reports/review-report-pdf-request";

export const useGetReviewReportPdf = (
    dto: ReviewReportPdfDto
): UseQueryResult<FileDownloadDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getReviewReportPdf", dto],
        () => getReviewReportPdf(url.baseUrl, new ReviewReportPdfRequest(dto)),
        {
            select: FileDownloadDto.constructFromBaseFileDetailsResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};
