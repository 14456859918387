import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Common, Nodes } from "../../../core/constants/translation-namespace";
import { HierarchyMenuType } from "../../../core/hierarchyMenuType";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    ContentContainer,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import { useFilterHierarchyTypes } from "../../../domain/viewmodels/hierarchy/text-search-node-viewmodel";
import SbTabs, { SbTabItemProps } from "../../molecules/SbTabs";
import NodeHierarchyContainer from "./NodeHierarchyContainer";
import TextSearchContainer from "./TextSearchContainer";

const SearchContainer: React.FC = () => {
    const menu = useMenu();
    const { t } = useTranslation("translation", { keyPrefix: Nodes });

    const filterHierarchyTypes = useFilterHierarchyTypes();

    useLoader(isQueryLoading(filterHierarchyTypes), SearchContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.Search);
    }, []);

    const buildSbTabItemProps = (): SbTabItemProps[] => {
        const textSearchContainer = (
            <TextSearchContainer
                mode={"hierarchy"}
                hierarchyTypes={filterHierarchyTypes.data!}
            />
        );

        const nodeHierarchyContainer = (
            <NodeHierarchyContainer
                menuType={HierarchyMenuType.Details | HierarchyMenuType.ViewUsers}
                hierarchyTypes={filterHierarchyTypes.data!}
            />
        );

        return [
            new SbTabItemProps(t("TextSearch", { keyPrefix: Common }), textSearchContainer, true),
            new SbTabItemProps(t("VisualSearch", { keyPrefix: Common }), nodeHierarchyContainer),
        ];
    };

    return (
        <>
            <PageHeading>{t("SearchNodeTitle")}</PageHeading>
            <PageSubHeading>{t("HeaderHelpText")}</PageSubHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(filterHierarchyTypes) && (
                <ContentContainer>
                    <SbTabs values={buildSbTabItemProps()} />
                </ContentContainer>
            )}
        </>
    );
};

export default SearchContainer;
