import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ChecklistQuestions } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import {
    ContentContainer,
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { orderQuestionsColumnNames } from "../../../core/utilities/dataTableColumns";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import {
    defaultFilterQuestionSetDto,
    FilterQuestionSetDto,
} from "../../../domain/dtos/question-sets/filter-question-set-dto";
import { Response } from "../../../domain/responses/common/response-response";
import {
    useGetQuestionSetDetails,
    useOrderQuestionSetQuestions,
} from "../../../domain/viewmodels/question-set/order-question-set-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import DraggableDataTable from "../../organisms/DraggableDataTable";

interface SearchParams {
    questionSetId: number | null;
}

const createSearchParams = (questionSetId: number | null): SearchParams => ({
    questionSetId: questionSetId,
});

const defaultSearchParams: SearchParams = createSearchParams(null);

const OrderQuestionsContainer: React.FC = () => {
    const questionSetId = Number(useParams().questionSetId);

    const orderedQuestionSetQuestions = useRef<number[]>([]);

    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const [searchParams] = useState<SearchParams>(defaultSearchParams);
    const [filterDto, setFilterDto] = useState<FilterQuestionSetDto>(defaultFilterQuestionSetDto);
    const questionSetQuestion = useGetQuestionSetDetails(questionSetId, filterDto);
    const orderQuestionSetQuestions = useOrderQuestionSetQuestions(questionSetId);

    useLoader(
        isQueryLoading(questionSetQuestion) || isMutationLoading(orderQuestionSetQuestions),
        OrderQuestionsContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.QuestionSets);

        setFilterDto({
            ...filterDto,
            pageNumber: 1,
            pageSize: 1000,
            sortByColumn: "OrderIndex",
            ...searchParams,
        });
    }, []);

    const handleOrderQuestionSetQuestions = (): void => {
        const orderedQuestionSetQuestionIds = orderedQuestionSetQuestions.current;
        if (!orderedQuestionSetQuestionIds.length) {
            return;
        }

        orderQuestionSetQuestions.mutate(orderedQuestionSetQuestionIds, {
            onSuccess: async (_: Response<boolean>) => {
                const params = [
                    createNavigateSearchParameter("success", "true"),
                    createNavigateSearchParameter(
                        "messageKey",
                        "OrderQuestionsInQuestionSetSuccessMessage"
                    ),
                ];

                navigateSearch(`${getPath(AccordionTitles.QuestionSets)}/${questionSetId}`, params);
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    return (
        <>
            <PageHeading>{t("QuestionSortOrder")}</PageHeading>
            <PageSubHeading>{t("HeaderHelpTextOrderQuestions")}</PageSubHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(questionSetQuestion) && (
                <>
                    <SectionVerticalSpace />
                    <ContentContainer>
                        <DraggableDataTable
                            keyPrefix={ChecklistQuestions}
                            columns={orderQuestionsColumnNames}
                            rows={questionSetQuestion.data?.rows}
                            onOrderChanged={(orderedQuestionSetQuestionIds: number[]): void => {
                                orderedQuestionSetQuestions.current = orderedQuestionSetQuestionIds;
                            }}
                            noResultsMessage={"No Associated Questions"}
                        />
                    </ContentContainer>

                    <LargeVerticalSpace />
                    <EndAlignedDiv>
                        <SaveButton type="button" onClick={handleOrderQuestionSetQuestions} />
                        <CancelButton onClick={() => navigate(-1)} />
                    </EndAlignedDiv>
                </>
            )}
        </>
    );
};

export default OrderQuestionsContainer;
