import { Response } from "../../responses/common/response-response";
import { UserResponse } from "../../responses/users/user-response";
import { BaseUserDto, createBaseUserDtoFromResponse } from "./base-user-dto";

export interface UserDto extends BaseUserDto {
    createdByEmailAddress: string;
    dateCreatedUtc: Date;
    modifiedByEmailAddress: string;
    dateModifiedUtc: Date;
    isLockedOut: boolean;
    isDeleted: boolean;
    timeZoneNodeTypeValueId: number | null;
}

export const createUserDto = (response: UserResponse): UserDto => ({
    ...response,
    ...createBaseUserDtoFromResponse(response),
});

export const createUserDtoFromResponse = (response: Response<UserResponse>): UserDto => ({
    ...createUserDto(response.data),
});
