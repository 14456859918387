import { FileDownloadDto } from "../../domain/dtos/file-storage/file-download-dto";

export const DownloadFile = (FileDownloadDto: FileDownloadDto): void => {
    const base64String = `data:${FileDownloadDto?.fileType};base64,${FileDownloadDto?.file}`;

    const hyperlinkTag = document.createElement("a");
    hyperlinkTag.href = base64String;
    hyperlinkTag.download = FileDownloadDto?.fileName;
    hyperlinkTag.click();
};
