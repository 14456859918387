import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    dissociateNestedQuestion,
    getNestedQuestionDetailsById,
} from "../../../data/services/questions/question-service";
import { NestedQuestionDto } from "../../dtos/questions/nested-question-dto";
import { Response } from "../../responses/common/response-response";
import { NestedQuestionResponse } from "../../responses/questions/nested-question-response";

export const useGetNestedQuestionDetailsById = (
    nestedQuestionId: number
): UseQueryResult<NestedQuestionDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getNestedQuestionDetailsById", nestedQuestionId],
        () => getNestedQuestionDetailsById(url.baseUrl, nestedQuestionId),
        {
            select: toNestedQuestionDto,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const toNestedQuestionDto = (response: Response<NestedQuestionResponse>): NestedQuestionDto =>
    new NestedQuestionDto(response.data);

export const useDissociateNestedQuestion = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    number
> => {
    const url = useUrl();

    return useMutation((mutationKey: number) => {
        return dissociateNestedQuestion(url.baseUrl, mutationKey);
    });
};
