import { ReactElement, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaArrowCircleLeft } from "react-icons/fa";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { ChecklistAnswers } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { predefinedAnswerTextColumns } from "../../../core/utilities/dataTableColumns";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { areQueriesLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { defaultPaginationDto, PaginationDto } from "../../../domain/dtos/common/pagination-dto";
import AnswerFlagMode from "../../../domain/enums/answer-types/answer-flag-mode";
import { Response } from "../../../domain/responses/common/response-response";
import {
    useDeletePredefinedAnswerText,
    usePredefinedAnswerDetails,
} from "../../../domain/viewmodels/answer-types/view-predefined-answer-viewmodel";
import FormattedString from "../../atoms/FormattedString";
import { CreateLink, EditLink, SbLink } from "../../atoms/SbLink";
import { TextTitledPanel } from "../../molecules/SbPanel";
import { DataTable } from "../../organisms/DataTable";

const ViewPredefinedAnswerContainer: React.FC = () => {
    const menu = useMenu();
    const toast = useToast();
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistAnswers });

    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";
    const answerTypeId = Number(useParams().answerTypeId);
    const predefinedAnswerId = Number(useParams().predefinedAnswerId);

    const [predefinedAnswerTextPaginationDto, setPredefinedAnswerTextPaginationDto] =
        useState<PaginationDto>(defaultPaginationDto);

    const getPredefinedAnswerDetails = usePredefinedAnswerDetails(
        predefinedAnswerId,
        predefinedAnswerTextPaginationDto
    );

    const deletePredefinedAnswerText = useDeletePredefinedAnswerText();

    const predefinedAnswerDetailsResponse = getPredefinedAnswerDetails[0];
    const predefinedAnswerDetailsResponseData = predefinedAnswerDetailsResponse.data;
    const predefinedAnswerTextResponse = getPredefinedAnswerDetails[1];
    const predefinedAnswerTextResponseData = predefinedAnswerTextResponse.data;

    useLoader(areQueriesLoading(getPredefinedAnswerDetails), ViewPredefinedAnswerContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.AnswerTypes);

        if (success) {
            toast.addToast(createSuccessToastProps([t(messageKey)]));

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    const buildCreateLink = (): ReactElement<typeof Link> => (
        <CreateLink
            label={t("AddPredefinedAnswerText")}
            navigateTo={getCreatePredefinedAnswerTextNavigateTo()}
        />
    );

    const getCreatePredefinedAnswerTextNavigateTo = (): string => {
        return `${getPath(AccordionTitles.AnswerTypes)}/${answerTypeId}${getPath(
            AccordionTitles.AnswerTypePredefinedAnswers
        )}/${predefinedAnswerId}${getPath(AccordionTitles.AnswerTypePredefinedAnswerTexts)}/create`;
    };

    const buildLinks = (): ReactElement<typeof Link> => (
        <>
            <EditLink
                navigateTo={`${getPath(AccordionTitles.AnswerTypes)}/${answerTypeId}${getPath(
                    AccordionTitles.AnswerTypePredefinedAnswers
                )}/${predefinedAnswerId}/edit`}
            />
            <SbLink
                label={t("AnswerType")}
                navigateTo={`${getPath(AccordionTitles.AnswerTypes)}/${answerTypeId}`}
                variant={"primary"}
                icon={FaArrowCircleLeft}
            />
        </>
    );

    const handleDeletePredefinedAnswerText = (predefinedAnswerTextId: number): void => {
        deletePredefinedAnswerText.mutate(predefinedAnswerTextId, {
            onSuccess: async (_: Response<boolean>) => {
                queryClient.refetchQueries([
                    "filterPredefinedAnswerTexts",
                    predefinedAnswerId,
                    predefinedAnswerTextPaginationDto,
                ]);
                toast.addToast(
                    createSuccessToastProps([t("SuccessfullyRemovedPredefinedAnswerText")])
                );
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    return (
        <>
            <PageHeading>{t("PredefinedAnswerDetails")}</PageHeading>

            <SectionVerticalSpace />

            {isQuerySuccessful(predefinedAnswerDetailsResponse) && (
                <ContentContainer>
                    <Row>
                        <Col md="auto">
                            <DetailsLabel>{t("AnswerFlagMode")}</DetailsLabel>
                            <DetailsLabel>{t("DefaultPredefinedAnswerTextValue")}</DetailsLabel>
                            {predefinedAnswerDetailsResponseData!.tooltipDescription && (
                                <DetailsLabel>
                                    {t("PredefinedAnswerTooltipDescription")}
                                </DetailsLabel>
                            )}
                        </Col>
                        <Col md="auto">
                            <DetailsValue>
                                {predefinedAnswerDetailsResponseData?.answerFlagMode
                                    ? AnswerFlagMode[
                                          predefinedAnswerDetailsResponseData?.answerFlagMode!
                                      ]
                                    : "-"}
                            </DetailsValue>
                            <DetailsValue>
                                {predefinedAnswerDetailsResponseData!.predefinedAnswerTextDto
                                    .textValue || "-"}
                            </DetailsValue>
                            {predefinedAnswerDetailsResponseData!.tooltipDescription && (
                                <DetailsValue>
                                    <FormattedString
                                        rawString={
                                            predefinedAnswerDetailsResponseData!.tooltipDescription
                                        }
                                    />
                                </DetailsValue>
                            )}
                        </Col>
                    </Row>

                    <EndAlignedDiv>{buildLinks()}</EndAlignedDiv>
                </ContentContainer>
            )}

            {isQuerySuccessful(predefinedAnswerTextResponse) && (
                <>
                    <LargeVerticalSpace />
                    <TextTitledPanel title={t("PredefinedAnswerTexts")}>
                        <EndAlignedDiv>{buildCreateLink()}</EndAlignedDiv>

                        <DataTable
                            columns={predefinedAnswerTextColumns}
                            rows={predefinedAnswerTextResponseData!.rows}
                            deleteItem={handleDeletePredefinedAnswerText}
                            noResultsMessage={`${t("NoPredefinedAnswersFound")}`}
                            totalItems={predefinedAnswerTextResponseData!.recordCount}
                            paginationDto={predefinedAnswerTextPaginationDto}
                            setPaginationDto={setPredefinedAnswerTextPaginationDto}
                        />
                    </TextTitledPanel>
                </>
            )}
        </>
    );
};

export default ViewPredefinedAnswerContainer;
