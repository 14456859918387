import { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RiDeleteBinFill } from "react-icons/ri";
import { VscChromeClose } from "react-icons/vsc";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ChecklistQuestions, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import {
    DetailsLabel,
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import {
    useCancelQuestionSetInstance,
    useGetCancelQuestionSetDetails,
} from "../../../domain/viewmodels/pending-adhocs/view-pending-adhocs-viewmodel";
import { SbButton } from "../../atoms/SbButton";
import { TextTitledPanel } from "../../molecules/SbPanel";
import { StyledTableDataLabel } from "../../organisms/ActionConfirmation";

const CancelPendingAdhocsContainer: React.FC = () => {
    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const [urlSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const questionSetInstanceId = Number(useParams().questionSetInstanceId);
    const questionSetId = Number(urlSearchParams.get("questionSetId"));

    const getCancelQuestionSetDetails = useGetCancelQuestionSetDetails(questionSetId);
    const cancelQuestionSetInstance = useCancelQuestionSetInstance();

    useLoader(
        isQueryLoading(getCancelQuestionSetDetails) || isMutationLoading(cancelQuestionSetInstance),
        CancelPendingAdhocsContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Checklists);
        menu.changeActiveDrawerItem(DrawerTitles.PendingAdhocs);
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        cancelQuestionSetInstance.mutate(questionSetInstanceId, {
            onSuccess: async () => {
                const params = [
                    createNavigateSearchParameter("success", "true"),
                    createNavigateSearchParameter(
                        "messageKey",
                        "SuccessfullyCancelledQuestionSetInstance"
                    ),
                ];

                navigateSearch(`${getPath(DrawerTitles.PendingAdhocs)}`, params);
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    return (
        <>
            <PageHeading>{t("CancelQuestionSetInstance")}</PageHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(getCancelQuestionSetDetails) && (
                <TextTitledPanel title={t("CancelQuestionSetInstanceConfirmation")}>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={2}>
                                <StyledTableDataLabel>
                                    <DetailsLabel>{t("Name")}</DetailsLabel>
                                </StyledTableDataLabel>
                            </Col>
                            <Col md={4}>{getCancelQuestionSetDetails.data}</Col>
                        </Row>
                        <EndAlignedDiv>
                            <SbButton
                                variant="danger"
                                type="submit"
                                label={t("Cancel", { keyPrefix: Common })}
                                icon={RiDeleteBinFill}
                            />
                            <SbButton
                                variant="secondary"
                                type="button"
                                label={t("Back", { keyPrefix: Common })}
                                icon={VscChromeClose}
                                onClick={() => navigate(-1)}
                            />
                        </EndAlignedDiv>
                    </Form>
                </TextTitledPanel>
            )}
        </>
    );
};

export default CancelPendingAdhocsContainer;
