import { NotificationStatuses } from "./enums";

export const getNotificationStatus = (item: number): string => {
    switch (item) {
        case NotificationStatuses.Unseen:
            return "Unseen";
        case NotificationStatuses.Dismissed:
            return "Dismissed";
        case NotificationStatuses.DontShowAgain:
            return "Don't Show Again";
        case NotificationStatuses.Finished:
            return "Finished";
        default:
            return "";
    }
};
