import { BaseQuestionSetReferentialLinkTypeResponse } from "../../responses/question-sets/base-question-set-referential-link-type-response";
import BaseReferentialLinkTypeReferentialLinkDto from "../referential-links/base-referential-link-type-referential-link-dto";
import BaseQuestionSetDto from "./base-question-set-dto";

export default class BaseQuestionSetReferentialLinkTypeDto {
    questionSetDto: BaseQuestionSetDto;
    referentialLinkTypeReferentialLinkDtos: BaseReferentialLinkTypeReferentialLinkDto[];

    public constructor(response: BaseQuestionSetReferentialLinkTypeResponse) {
        this.questionSetDto = new BaseQuestionSetDto(response.questionSetResponse);
        this.referentialLinkTypeReferentialLinkDtos =
            BaseReferentialLinkTypeReferentialLinkDto.toBaseReferentialLinkTypeReferentialLinkDto(
                response.referentialLinkTypeReferentialLinkResponses
            );
    }
}
