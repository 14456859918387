import { Col, Form, Row } from "react-bootstrap";
import { StyledFormLabel, StyledFormLeftAlignedLabel } from "../../../core/theme/global-styles";
import SbTextArea, { SbTextAreaProps } from "../../atoms/input/SbTextArea";
import SbLabelText from "../../atoms/SbLabelText";
import { BaseSbFormGroupProps } from "../../props/input/InputFieldProps";

interface SbFormTextAreaGroupProps extends BaseSbFormGroupProps, SbTextAreaProps {
    expand?: boolean;
    textAlign?: "left" | "right";
}

const SbFormTextAreaGroup: React.FC<SbFormTextAreaGroupProps> = ({
    name,
    rows,
    maxLength,
    placeholder,
    value,
    defaultValue,
    label,
    required,
    disabled,
    expand,
    textAlign,
}) => (
    <Form.Group as={Row} className="mb-3">
        {textAlign === "left" ? (
            <StyledFormLeftAlignedLabel column sm={2}>
                <SbLabelText label={label} required={required} />
            </StyledFormLeftAlignedLabel>
        ) : (
            <StyledFormLabel column sm={2}>
                <SbLabelText label={label} required={required} />
            </StyledFormLabel>
        )}
        <Col sm={expand ? 10 : 4}>
            <SbTextArea
                name={name}
                key={name}
                rows={rows}
                maxLength={maxLength}
                placeholder={placeholder}
                defaultValue={defaultValue}
                value={value}
                required={required}
                disabled={disabled}
            />
        </Col>
    </Form.Group>
);

export default SbFormTextAreaGroup;
