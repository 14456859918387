import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { ChecklistAnswers } from "../../../core/constants/translation-namespace";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { TableRow } from "../../../core/utilities/customTypes";
import {
    answerSummaryColumnNames,
    answerSummaryNestedAnswersColumnNames,
} from "../../../core/utilities/dataTableColumns";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { areQueriesLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import { AnswerDetailsDto } from "../../../domain/dtos/answer-summary/answer-details-dto";
import { AnswerSummaryType } from "../../../domain/enums/answer-summary/AnswerSummaryType";
import {
    useDownloadQuestionSetInstanceAnswerFile,
    useGetAnswerDetails,
} from "../../../domain/viewmodels/answer-summary/answer-summary-viewmodel";
import { DataTable } from "../../organisms/DataTable";

const AnswerDetailsContainer: React.FC = () => {
    const menu = useMenu();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistAnswers });
    const [urlSearchParams] = useSearchParams();

    const [downloadQuestionSetInstanceAnswerFileId, setDownloadQuestionSetInstanceAnswerFileId] =
        useState<number | null>(null);
    const [, setRows] = useState<TableRow<number>[]>();

    const instanceStatus = Number(urlSearchParams.get("instanceStatus"));
    const nodeId = Number(urlSearchParams.get("nodeId"));
    const isTraining = urlSearchParams.get("isTraining") as string;
    const answerSummaryType = Number(urlSearchParams.get("answerSummaryType"));

    const getAnswerDetails = useGetAnswerDetails(
        new AnswerDetailsDto(instanceStatus, nodeId, null, answerSummaryType, isTraining)
    );

    const downloadQuestionSetInstanceAnswerFile = useDownloadQuestionSetInstanceAnswerFile(
        downloadQuestionSetInstanceAnswerFileId
    );

    const getAnswerDetailsResponseData = getAnswerDetails.data;

    useLoader(
        areQueriesLoading([getAnswerDetails, downloadQuestionSetInstanceAnswerFile]),
        AnswerDetailsContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Checklists);
        menu.changeActiveDrawerItem(DrawerTitles.TeamAnswerSummary);
    }, []);

    const onDownloadFile = (fileId: number): void =>
        setDownloadQuestionSetInstanceAnswerFileId(fileId);

    return (
        <>
            <PageHeading>
                {answerSummaryType === AnswerSummaryType.Team
                    ? t("TeamAnswerDetails")
                    : t("AnswerDetails")}
            </PageHeading>
            <PageSubHeading>{t("ViewYourTeamsAnswersHere")}</PageSubHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(getAnswerDetails) && (
                <DataTable
                    columns={answerSummaryColumnNames}
                    rows={getAnswerDetailsResponseData!.rows}
                    totalItems={getAnswerDetailsResponseData!.recordCount}
                    nestedColumnNames={answerSummaryNestedAnswersColumnNames}
                    attachedFilesLabel={"Attached Evidence"}
                    downloadAttachedFile={onDownloadFile}
                    attachedFileDownloadData={downloadQuestionSetInstanceAnswerFile.data!}
                    setRows={setRows}
                />
            )}
        </>
    );
};

export default AnswerDetailsContainer;
