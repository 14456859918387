import { AssociateDissociateReferentialLinkToActionItemDto } from "../../dtos/actions/associate-dissociate-referential-link-to-action-item-dto";

export class AssociateDissociateReferentialLinkToActionItemRequest {
    actionItemId: number;
    referentialLinkIds: number[];

    public constructor(dto: AssociateDissociateReferentialLinkToActionItemDto) {
        const { actionItemId, referentialLinkIds } = dto;

        this.actionItemId = actionItemId;
        this.referentialLinkIds = referentialLinkIds;
    }
}
