import {
    useMutation,
    UseMutationResult,
    useQueries,
    useQuery,
    UseQueryResult,
} from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { TableRow } from "../../../core/utilities/customTypes";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import { getAllAggregationTypes } from "../../../data/services/aggregation-types/aggregation-types-service";
import { getAllAnswerThresholdOperators } from "../../../data/services/answer-threshold-operators/answer-threshold-operators-service";
import { lookupQuestionCategories } from "../../../data/services/lookup/lookup-service";
import { getAllQuestionImportance } from "../../../data/services/question-importance/question-importance-service";
import { getAllQuestionTypes } from "../../../data/services/question-types/question-types-service";
import {
    associateChildQuestion,
    filterQuestions,
    getQuestionDetails,
} from "../../../data/services/questions/question-service";
import {
    AggregationTypeDto,
    toAggregationTypeDto,
} from "../../dtos/aggregation-type/aggregation-type-dto";
import {
    AnswerThresholdOperatorsDto,
    toAnswerThresholdOperatorsDto,
} from "../../dtos/answer-threshold-operators/answer-threshold-operators-dto";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { SearchDto } from "../../dtos/common/search-dto";
import {
    QuestionCategoryDto,
    toQuestionCategoryDtos,
} from "../../dtos/question-categories/question-category-dto";
import {
    QuestionImportanceDto,
    toQuestionImportanceDto,
} from "../../dtos/question-importance/question-importance-dto";
import { QuestionTypeDto, toQuestionTypeDto } from "../../dtos/question-type/question-type-dto";
import { AssociateChildQuestionDto } from "../../dtos/questions/associate-child-question-dto";
import { FilterQuestionsDto } from "../../dtos/questions/filter-questions-dto";
import {
    createQuestionDetailsDto,
    QuestionDetailsDto,
} from "../../dtos/questions/question-details-dto";
import { createSearchRequestFromDto } from "../../requests/common/search-request";
import { AssociateChildQuestionRequest } from "../../requests/questions/associate-child-question-request";
import { createFilterQuestionsRequestFromDto } from "../../requests/questions/filter-questions-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { NestedQuestionResponse } from "../../responses/questions/nested-question-response";
import { QuestionDetailsResponse } from "../../responses/questions/question-details-response";
import { QuestionResponse } from "../../responses/questions/question-response";

export const useGetQuestionDropdownValues = (): [
    UseQueryResult<QuestionTypeDto[], HTTPError>,
    UseQueryResult<QuestionImportanceDto[], HTTPError>,
    UseQueryResult<AggregationTypeDto[], HTTPError>,
    UseQueryResult<AnswerThresholdOperatorsDto[], HTTPError>,
] => {
    const url = useUrl();

    const queries = useQueries({
        queries: [
            {
                queryKey: ["getAllQuestionTypes"],
                queryFn: () => getAllQuestionTypes(url.baseUrl),
                select: toQuestionTypeDto,
            },
            {
                queryKey: ["getAllQuestionImportance"],
                queryFn: () => getAllQuestionImportance(url.baseUrl),
                select: toQuestionImportanceDto,
            },
            {
                queryKey: ["getAllAggregationTypes"],
                queryFn: () => getAllAggregationTypes(url.baseUrl),
                select: toAggregationTypeDto,
            },
            {
                queryKey: ["getAllAnswerThresholdOperators"],
                queryFn: () => getAllAnswerThresholdOperators(url.baseUrl),
                select: toAnswerThresholdOperatorsDto,
            },
        ],
    });

    return queries as [
        UseQueryResult<QuestionTypeDto[], HTTPError>,
        UseQueryResult<QuestionImportanceDto[], HTTPError>,
        UseQueryResult<AggregationTypeDto[], HTTPError>,
        UseQueryResult<AnswerThresholdOperatorsDto[], HTTPError>,
    ];
};

export const useLookupQuestionCategories = (
    searchDto: SearchDto
): UseQueryResult<QuestionCategoryDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["lookupQuestionCategories", searchDto],
        () => lookupQuestionCategories(url.baseUrl, createSearchRequestFromDto(searchDto)),
        {
            select: toQuestionCategoryDtos,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useGetQuestions = (
    questionId: number,
    filterQuestionDto: FilterQuestionsDto
): [
    UseQueryResult<QuestionDetailsDto, HTTPError>,
    UseQueryResult<PaginatedTableDto<number>, HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const queries = useQueries({
        queries: [
            {
                queryKey: ["getQuestionDetails", questionId],
                queryFn: () => getQuestionDetails(url.baseUrl, questionId),
                select: toQuestionDetailsDto,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["filterQuestions", filterQuestionDto],
                queryFn: () =>
                    filterQuestions(
                        url.baseUrl,
                        createFilterQuestionsRequestFromDto(filterQuestionDto)
                    ),
                keepPreviousData: true,
                select: transformToDataTableRows,
                onError: errorResponseToDisplayHandler,
            },
        ],
    });

    return queries as [
        UseQueryResult<QuestionDetailsDto, HTTPError>,
        UseQueryResult<PaginatedTableDto<number>, HTTPError>,
    ];
};

export const useAssociateChildQuestion = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    AssociateChildQuestionDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: AssociateChildQuestionDto) => {
        return associateChildQuestion(url.baseUrl, new AssociateChildQuestionRequest(mutationKey));
    });
};

const transformToDataTableRows = (
    response: Response<PaginationResponse<QuestionResponse>>
): PaginatedTableDto<number> => {
    const rows = response.data.results!.map((question) => {
        return {
            metadata: question.questionId,
            nestedRows: question.childQuestionResponses?.length
                ? transformToNestedQuestionsRows(question.childQuestionResponses)
                : undefined,
            columns: [
                {
                    //ID
                    value: question.questionId.toString(),
                    type: DataTableColumnTypes.Link,
                },
                {
                    //Question Text
                    value: question.questionText.text,
                    type: DataTableColumnTypes.Text,
                },
                {
                    //Question Type
                    value: question.questionType.name,
                    type: DataTableColumnTypes.Text,
                },
                {
                    //Category name
                    value: question.questionCategory.name,
                    type: DataTableColumnTypes.Text,
                },
                {
                    //Answer Type
                    value: question.answerTypeResponse.name,
                    type: DataTableColumnTypes.Text,
                },
                {
                    // Nested questions
                    value:
                        question.childQuestionResponses && question.childQuestionResponses.length
                            ? "Show/Hide"
                            : "None",
                    type: DataTableColumnTypes.DisplayNestedTableButton,
                    metadata: "NestedQuestions",
                },
                {
                    //Trigger When Parent Desirable check box
                    value: "",
                    type: DataTableColumnTypes.Check,
                    metadata: "TriggerWhenParentDesirable",
                },
            ],
        };
    });

    return {
        numberOfPages: response.data.pageCount,
        pageSize: response.data.pageSize,
        currentPage: response.data.currentPage,
        recordCount: response.data.recordCount,
        rows: rows,
    };
};

const transformToNestedQuestionsRows = (
    nestedQuestions: NestedQuestionResponse[]
): TableRow<number>[] => {
    const rows = nestedQuestions.map((nestedQuestion) => ({
        metadata: nestedQuestion.nestedQuestionId,
        columns: [
            {
                // Order Index
                value: nestedQuestion.orderIndex?.toString(),
                type: DataTableColumnTypes.Text,
                metadata: "OrderIndex",
            },
            {
                // ID
                value: nestedQuestion.questionId.toString(),
                type: DataTableColumnTypes.Link,
                metadata: "ID",
            },
            {
                // Question Text
                value: nestedQuestion.questionText?.text,
                type: DataTableColumnTypes.Text,
                metadata: "QuestionText",
            },
            {
                // Category
                value: nestedQuestion.questionCategory?.name,
                type: DataTableColumnTypes.Text,
                metadata: "Category",
            },
            {
                // Type
                value: nestedQuestion.questionType?.name,
                type: DataTableColumnTypes.Text,
                metadata: "Type",
            },
            {
                // Answer Type
                value: nestedQuestion.answerTypeResponse?.name,
                type: DataTableColumnTypes.Text,
                metadata: "AnswerType",
            },
            {
                // Render Mode
                value: nestedQuestion.renderInline ? "Inline" : "Default",
                type: DataTableColumnTypes.Text,
                metadata: "RenderMode",
            },
            {
                // Parent Question Answer Trigger
                value:
                    nestedQuestion.parentQuestionAnswerTrigger?.toString() === ""
                        ? "No"
                        : nestedQuestion.parentQuestionAnswerTrigger?.toString(),
                type: DataTableColumnTypes.Text,
                metadata: "ParentQuestionAnswerTrigger",
            },
        ],
    }));

    return rows;
};

const toQuestionDetailsDto = (response: Response<QuestionDetailsResponse>): QuestionDetailsDto =>
    createQuestionDetailsDto(response);
