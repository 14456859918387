import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { AccordionLinks } from "../../core/constants/translation-namespace";
import { useMenu } from "../../core/store/menu-context";
import { ChildItem } from "../../core/utilities/customTypes";
import { getIcon } from "../../core/utilities/getIcon";
import AccordionLink from "../atoms/AccordionLink";

const StyledAccordionItem = styled(Accordion.Item)`
    background-color: transparent;
    border-style: none;
`;

interface StyledAccordionHeaderProps {
    readonly $active?: boolean;
    readonly $drawerOpen: boolean;
}

const StyledAccordionHeader = styled(Accordion.Header)<StyledAccordionHeaderProps>`
    .accordion-button {
        width: 100%;
        padding-left: ${(props) => props.$drawerOpen && props.theme.padding.lg};
        padding-top: ${(props) => props.theme.padding.lg};
        padding-bottom: ${(props) => props.theme.padding.lg};
        display: flex;
        align-items: center;
        justify-content: ${(props) => (props.$drawerOpen ? "flex-start" : "center")};
        background-color: transparent;
        color: ${(props) => props.theme.palette.white};
        background-image: ${(props) =>
            props.$active &&
            "linear-gradient(to right, rgba(177, 80, 197, 0), rgba(177, 80, 197, 1) 50%)"};
    }

    .accordion-button::after {
        background-image: none;
        margin: 0;
        width: 0;
    }

    .accordion-button:focus {
        background-color: transparent;
    }

    .accordion-button:hover {
        background-color: ${(props) => props.theme.palette.pink};
    }

    .accordion-button:not(.collapsed) {
        background-color: ${(props) => props.theme.palette.pink};
    }
`;

const StyledAccordionBody = styled(Accordion.Body)`
    background-image: linear-gradient(to right, rgba(177, 80, 197, 0), rgba(177, 80, 197, 1) 50%);
    padding: 0;
`;

const StyledText = styled.div`
    padding-left: ${(props) => props.theme.padding.sm};
`;

const StyledRightIcon = styled.div`
    flex: 1;
    text-align: right;
`;

const DrawerAccordion: React.FC<{
    title: string;
    active: boolean;
    accordionLinks: ChildItem[];
}> = ({ title, active, accordionLinks }) => {
    const menu = useMenu();
    const { t } = useTranslation("translation", { keyPrefix: AccordionLinks });

    return (
        <StyledAccordionItem eventKey={title}>
            <StyledAccordionHeader
                $active={active}
                $drawerOpen={menu.drawerOpen}
                onClick={() => {
                    menu.changeActiveAccordion(menu.activeAccordion === title ? "close" : title);
                }}
            >
                {getIcon(title, menu.drawerOpen ? "16px" : "32px")}
                {menu.drawerOpen && <StyledText>{t(title)}</StyledText>}
                {menu.drawerOpen && (
                    <StyledRightIcon>
                        {menu.activeAccordion === title
                            ? getIcon("Accordion Open")
                            : getIcon("Accordion Closed")}
                    </StyledRightIcon>
                )}
            </StyledAccordionHeader>
            <StyledAccordionBody>
                {accordionLinks.map((accordionLink) => (
                    <AccordionLink
                        key={accordionLink.title}
                        drawerItemTitle={title}
                        title={accordionLink.title}
                        active={accordionLink.isActive}
                        isNewSection={accordionLink.isNewSection}
                    />
                ))}
            </StyledAccordionBody>
        </StyledAccordionItem>
    );
};

export default DrawerAccordion;
