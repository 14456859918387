import { FeatureDto } from "../../dtos/tenants/feature-dto";

export interface FeatureRequest {
    featureId: number;
    name: string;
}

export const toFeatureRequest = (feature: FeatureDto): FeatureRequest => ({
    featureId: feature.featureId,
    name: feature.name,
});
