import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import { ChecklistQuestions, Common } from "../../../core/constants/translation-namespace";
import {
    ContentContainer,
    EndAlignedDiv,
    SectionContainer,
} from "../../../core/theme/global-styles";
import { dataDropdownItems } from "../../../core/utilities/filterDropdownItems";
import {
    ReactSelectDto,
    valuesToReactSelectDtos,
} from "../../../domain/dtos/common/react-select-dto";
import { ResetButton, SearchButton } from "../../atoms/SbButton";
import { FilterInput } from "../../molecules/FilterInput";
import { FilterSelect } from "../../molecules/FilterSelect";

export const PendingAdhocsFilter: React.FC<{
    node: string | null;
    changeNode: (node: string) => void;
    checklistName: string | null;
    changeChecklistName: (checklistName: string) => void;
    referentialLinks: string | null;
    changeReferentialLinks: (referentialLinks: string) => void;
    triggeredFor: string | null;
    changeTriggeredFor: (triggeredFor: string) => void;
    triggeredBy: string | null;
    changeTriggeredBy: (triggeredBy: string) => void;
    data: ReactSelectDto<string> | null;
    changeData: (option: SingleValue<ReactSelectDto<string>>) => void;
    search: () => void;
    resetFilter: () => void;
}> = ({
    node,
    changeNode,
    checklistName,
    changeChecklistName,
    referentialLinks,
    changeReferentialLinks,
    triggeredFor,
    changeTriggeredFor,
    triggeredBy,
    changeTriggeredBy,
    data,
    changeData,
    search,
    resetFilter,
}) => {
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });
    return (
        <ContentContainer>
            <SectionContainer>
                <Row>
                    <Col>
                        <FilterInput
                            title={t("Node")}
                            name={"node"}
                            type={"text"}
                            onChangeEventHandler={changeNode}
                            value={node ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("ChecklistName")}
                            name={"checklistName"}
                            type={"text"}
                            onChangeEventHandler={changeChecklistName}
                            value={checklistName ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("ReferentialLinks")}
                            name={"referentialLinks"}
                            type={"text"}
                            onChangeEventHandler={changeReferentialLinks}
                            value={referentialLinks ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("TriggeredFor")}
                            name={"triggeredFor"}
                            type={"text"}
                            onChangeEventHandler={changeTriggeredFor}
                            value={triggeredFor ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("TriggeredBy")}
                            name={"triggeredBy"}
                            type={"text"}
                            onChangeEventHandler={changeTriggeredBy}
                            value={triggeredBy ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterSelect
                            name={"data"}
                            label={`${t("Data")}`}
                            placeholderText={""}
                            searchable
                            clearable={false}
                            items={valuesToReactSelectDtos(dataDropdownItems)}
                            itemDisplayText={(option: ReactSelectDto<string>) =>
                                t(option.label, { keyPrefix: Common })
                            }
                            onChange={changeData}
                            value={data}
                        />
                    </Col>
                </Row>
            </SectionContainer>
            <EndAlignedDiv>
                <SearchButton onClick={search} />
                <ResetButton onClick={resetFilter} />
            </EndAlignedDiv>
        </ContentContainer>
    );
};
