export class RemoveResolveReviewFindingDto {
    questionSetInstanceAnswerId: number;
    questionSetInstanceReviewId: number;
    comment: string;

    public constructor(
        questionSetInstanceAnswerId: number,
        questionSetInstanceReviewId: number,
        comment: string
    ) {
        this.questionSetInstanceAnswerId = questionSetInstanceAnswerId;
        this.questionSetInstanceReviewId = questionSetInstanceReviewId;
        this.comment = comment;
    }
}
