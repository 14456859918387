import { QuestionImportanceResponse } from "../../../domain/responses/question-importance/question-importance-response";
import client from "../../http-client";

const route = "questionimportance";

export const getAllQuestionImportance = async (
    prefixUrl: string
): Promise<QuestionImportanceResponse[]> =>
    await client
        .get(route, {
            prefixUrl: prefixUrl,
        })
        .json();
