import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SingleValue } from "react-select";
import styled from "styled-components";
import { Nodes } from "../../../core/constants/translation-namespace";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    createNavigateToForSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import { nameColumnNames, nodesColumnNames } from "../../../core/utilities/dataTableColumns";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import { defaultPaginationDto, PaginationDto } from "../../../domain/dtos/common/pagination-dto";
import { BaseNodeDetailsDto } from "../../../domain/dtos/hierarchy/base-node-details-dto";
import { HierarchyTypeDto } from "../../../domain/dtos/hierarchy/hierarchy-type-dto";
import { NodeTypeDto } from "../../../domain/dtos/hierarchy/node-type-dto";
import {
    useFilterNodesWithPath,
    useFilterNodeTypes,
    useFilterNodeTypeValuesToTableRows,
} from "../../../domain/viewmodels/hierarchy/text-search-node-viewmodel";
import { SbRibbon } from "../../atoms/SbRibbon";
import { RadioSelectProps } from "../../molecules/DataTableAction";
import { DataTable } from "../../organisms/DataTable";
import { NodeFilter } from "../../organisms/filters/NodeFilter";

const StyledDiv = styled.div`
    margin-top: ${(props) => props.theme.padding.lg};
`;

const allHierarchyTypesItem: HierarchyTypeDto = {
    hierarchyTypeId: -1,
    name: "All",
};

const allNodeTypesItem: NodeTypeDto = {
    nodeTypeId: -1,
    name: "All",
    hierarchyTypeId: -1,
    isSystemRoot: false,
    containsTimeZoneCode: false,
};

const TextSearchContainer: React.FC<{
    mode: "select" | "hierarchy";
    hierarchyTypes: HierarchyTypeDto[];
    selectNode?: (metadata: BaseNodeDetailsDto) => void;
    selectedNode?: (metadata: BaseNodeDetailsDto) => boolean;
    radioSelect?: RadioSelectProps;
}> = ({ mode, hierarchyTypes, selectNode, selectedNode, radioSelect }) => {
    const [nodesPaginatedDto, setNodesPaginatedDto] = useState<PaginationDto>(defaultPaginationDto);
    const [nodeTypeValuesPaginationDto, setNodeTypeValuesPaginationDto] =
        useState<PaginationDto>(defaultPaginationDto);

    const [selectedHierarchyTypeId, setSelectedHierarchyTypeId] = useState<number | null>(null);
    const [selectedHierarchyType, setSelectedHierarchyType] = useState<HierarchyTypeDto | null>(
        null
    );
    const [selectedNodeType, setSelectedNodeType] = useState<NodeTypeDto | null>(null);
    const [selectedNodeTypeValueId, setSelectedNodeTypeValueIdId] = useState<number | null>(null);

    const [searchText, setSearchText] = useState<string | null>(null);
    const [searchSelected, setSearchSelected] = useState(false);

    const menu = useMenu();
    const navigate = useNavigate();

    const { t } = useTranslation("translation", { keyPrefix: Nodes });

    const filterNodeTypes = useFilterNodeTypes(selectedHierarchyTypeId);
    const filterNodeTypeValues = useFilterNodeTypeValuesToTableRows(
        selectedHierarchyTypeId,
        selectedNodeType?.nodeTypeId ?? null,
        searchText,
        nodeTypeValuesPaginationDto
    );
    const filterNodesWithPath = useFilterNodesWithPath(
        searchSelected,
        nodesPaginatedDto,
        selectedNodeTypeValueId ?? null,
        mode
    );

    const [showNodes, setShowNodes] = useState<boolean>(false);

    useLoader(isQueryLoading(filterNodesWithPath), TextSearchContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.Search);
    }, []);

    const resetSearchSelected = (): void => {
        if (searchSelected) {
            setSearchSelected(false);
        }
    };

    const onHierarchyTypeChanged = (option: SingleValue<HierarchyTypeDto>): void => {
        setSelectedHierarchyTypeId(option?.hierarchyTypeId ?? null);
        setSelectedHierarchyType(option);
        setSelectedNodeType(null);
        setSelectedNodeTypeValueIdId(null);
        resetSearchSelected();
        setSearchText(null);
        setShowNodes(false);
    };

    const onNodeTypeChanged = (option: SingleValue<NodeTypeDto>): void => {
        setSelectedNodeType(option ?? null);
        setSelectedNodeTypeValueIdId(null);
        resetSearchSelected();
        setSearchText(null);
        setShowNodes(false);
    };

    const buildRibbon = (): JSX.Element => <SbRibbon size={"small"} label={"Training"} />;

    const buildHierarchyTypeItems: () => HierarchyTypeDto[] = () =>
        [allHierarchyTypesItem].concat(hierarchyTypes);

    const buildNodeTypeItems = (): NodeTypeDto[] =>
        [allNodeTypesItem].concat(filterNodeTypes.data!);

    const setDefaultSelectedHierarchyTypeItem = (): HierarchyTypeDto => {
        if (selectedHierarchyTypeId == null) {
            setSelectedHierarchyTypeId(
                !hierarchyTypes.length ? 0 : hierarchyTypes[0].hierarchyTypeId
            );
            setSelectedHierarchyType(
                !hierarchyTypes.length ? allHierarchyTypesItem : hierarchyTypes[0]
            );
        }

        return hierarchyTypes[0];
    };

    const setDefaultSelectedNodeTypeItem = (): NodeTypeDto => {
        if (selectedNodeType == null) {
            setSelectedNodeType(allNodeTypesItem);
        }

        return allNodeTypesItem;
    };

    const navigateToCreateNode = (nodeDetails: BaseNodeDetailsDto): void =>
        navigate(`${getPath(AccordionTitles.VisualTree)}/${nodeDetails.nodeId}/create`);

    const navigateToVisualTree = (baseNodeDetailsDto: BaseNodeDetailsDto): void => {
        const params = [
            createNavigateSearchParameter(
                "viewInHierarchyId",
                baseNodeDetailsDto.nodeId.toString()
            ),
        ];

        navigate(createNavigateToForSearch(getPath(AccordionTitles.VisualTree), params));
    };

    const onNodeTypeValueSelected = (nodeTypeValueId: number): void => {
        setShowNodes(false);
        setSelectedNodeTypeValueIdId(nodeTypeValueId ?? null);
        resetSearchSelected();
        setSearchSelected(true);
        setShowNodes(true);
    };

    const changeSearchText = (searchString: string): void => {
        setSearchText(searchString);
        setShowNodes(false);
    };

    return (
        <>
            <StyledDiv>
                <NodeFilter
                    hierarchyType={selectedHierarchyType}
                    hierarchyTypes={buildHierarchyTypeItems()}
                    changeHierarchyType={onHierarchyTypeChanged}
                    defaultSelectedHierarchyType={setDefaultSelectedHierarchyTypeItem()}
                    hierarchyLevel={selectedNodeType}
                    hierarchyLevels={buildNodeTypeItems()}
                    changeHierarchyLevel={onNodeTypeChanged}
                    defaultSelectedHierarchyLevel={setDefaultSelectedNodeTypeItem()}
                    isFilterNodeTypesLoading={isQueryLoading(filterNodeTypes)}
                    searchValue={searchText}
                    changeSearchValue={changeSearchText}
                />

                {
                    <DataTable
                        columns={nameColumnNames}
                        rows={filterNodeTypeValues.data?.rows}
                        totalItems={filterNodeTypeValues.data?.recordCount}
                        paginationDto={nodeTypeValuesPaginationDto}
                        setPaginationDto={setNodeTypeValuesPaginationDto}
                        searchItem={onNodeTypeValueSelected}
                        noResultsMessage={t("NoNodesFound")}
                        isLoading={isQueryLoading(filterNodeTypeValues)}
                        isSuccessful={isQuerySuccessful(filterNodeTypeValues)}
                    />
                }

                {showNodes &&
                    filterNodesWithPath.data != undefined && ( // TODO: No Results state
                        <>
                            <hr />
                            {mode === "hierarchy" ? (
                                <DataTable
                                    columns={nodesColumnNames}
                                    keyPrefix={Nodes}
                                    rows={filterNodesWithPath.data?.rows}
                                    addChild={navigateToCreateNode}
                                    viewInHierarchy={navigateToVisualTree}
                                    ribbon={buildRibbon()}
                                    totalItems={filterNodesWithPath.data!.recordCount}
                                    paginationDto={nodesPaginatedDto}
                                    setPaginationDto={setNodesPaginatedDto}
                                />
                            ) : (
                                <DataTable
                                    columns={nodesColumnNames}
                                    keyPrefix={Nodes}
                                    rows={filterNodesWithPath.data?.rows}
                                    selectItem={selectNode}
                                    selectedItem={selectedNode}
                                    radioSelect={radioSelect}
                                    ribbon={buildRibbon()}
                                    totalItems={filterNodesWithPath.data!.recordCount}
                                    paginationDto={nodesPaginatedDto}
                                    setPaginationDto={setNodesPaginatedDto}
                                />
                            )}
                        </>
                    )}
            </StyledDiv>
        </>
    );
};

export default TextSearchContainer;
