import { AssociateDissociateQuestionToQuestionSetRequest } from "../../../domain/requests/question-set/associate-dissociate-question-to-question-set-request";
import { AssociateDissociateReferentialLinkTypeToQuestionSetRequest } from "../../../domain/requests/question-set/associate-dissociate-referential-link-type-to-question-set-request";
import { AssociateNewQuestionToQuestionSetRequest } from "../../../domain/requests/question-set/associate-new-question-to-question-set-request";
import { CreateQuestionSetRequest } from "../../../domain/requests/question-set/create-question-set-request";
import { DissociateQuestionSetQuestionRequest } from "../../../domain/requests/question-set/dissociate-question-set-question-request";
import { EditQuestionScheduleRequest } from "../../../domain/requests/question-set/edit-question-schedule-request";
import { EditQuestionSetRequest } from "../../../domain/requests/question-set/edit-question-set-request";
import {
    buildFilterQuestionSetSearchParameters,
    FilterQuestionSetRequest,
} from "../../../domain/requests/question-set/filter-question-set-request";
import { QuestionSetQuestionsOrderRequest } from "../../../domain/requests/question-set/order-question-set-questions-request";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import { BaseQuestionSetReferentialLinkTypeResponse } from "../../../domain/responses/question-sets/base-question-set-referential-link-type-response";
import { QuestionSetQuestionResponse } from "../../../domain/responses/question-sets/question-set-question-response";
import { QuestionSetResponse } from "../../../domain/responses/question-sets/question-set-response";
import client from "../../http-client";

const route = "questionsets";

export const getQuestionSets = async (
    prefixUrl: string,
    request: FilterQuestionSetRequest
): Promise<Response<PaginationResponse<QuestionSetResponse>>> => {
    const searchParams = buildFilterQuestionSetSearchParameters(request);

    return await client
        .get(`${route}/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getQuestionSetDetails = async (
    prefixUrl: string,
    questionSetId: number
): Promise<Response<QuestionSetResponse>> =>
    await client
        .get(`${route}/${questionSetId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const filterAssociatedQuestions = async (
    prefixUrl: string,
    request: FilterQuestionSetRequest
): Promise<Response<PaginationResponse<QuestionSetQuestionResponse>>> => {
    const searchParams = buildFilterQuestionSetSearchParameters(request);
    return await client
        .get(`${route}/associated-questions`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getAssociatedReferentialLinkTypes = async (
    prefixUrl: string,
    request: FilterQuestionSetRequest
): Promise<Response<PaginationResponse<BaseQuestionSetReferentialLinkTypeResponse>>> => {
    const searchParams = buildFilterQuestionSetSearchParameters(request);
    return await client
        .get(`${route}/associated-referential-link-types`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const associateReferentialLinkType = async (
    prefixUrl: string,
    request: AssociateDissociateReferentialLinkTypeToQuestionSetRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/associate-referential-link-type`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const dissociateReferentialLinkType = async (
    prefixUrl: string,
    request: AssociateDissociateReferentialLinkTypeToQuestionSetRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/dissociate-referential-link-type`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const cloneQuestionSet = async (
    prefixUrl: string,
    questionSetId: number
): Promise<Response<QuestionSetResponse>> =>
    await client
        .post(`${route}/clone/${questionSetId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const deleteQuestionSet = async (
    prefixUrl: string,
    questionSetId: number
): Promise<Response<boolean>> =>
    await client
        .delete(`${route}/delete/${questionSetId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const editQuestionSet = async (
    prefixUrl: string,
    request: EditQuestionSetRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/edit`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const createQuestionSet = async (
    prefixUrl: string,
    request: CreateQuestionSetRequest
): Promise<Response<number>> =>
    await client
        .post(`${route}/create`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const editQuestionSchedule = async (
    prefixUrl: string,
    request: EditQuestionScheduleRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/edit-question-schedule`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const dissociateQuestionSetQuestion = async (
    prefixUrl: string,
    request: DissociateQuestionSetQuestionRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/dissociate-question-set-question`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const orderQuestionSetQuestions = async (
    prefixUrl: string,
    request: QuestionSetQuestionsOrderRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/order-questions`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const associateExistingQuestion = async (
    prefixUrl: string,
    request: AssociateDissociateQuestionToQuestionSetRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/associate-existing-question`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const associateNewQuestion = async (
    prefixUrl: string,
    request: AssociateNewQuestionToQuestionSetRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/associate-new-question`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();
