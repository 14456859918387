import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Roles } from "../../../core/constants/translation-namespace";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { roleColumnNames } from "../../../core/utilities/dataTableColumns";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import { RoleWithUserCountDto } from "../../../domain/dtos/users/role-dto";
import { useGetRolesWithUserCount } from "../../../domain/viewmodels/users/view-roles-viewmodel";
import { DataTable } from "../../organisms/DataTable";

const RolesAdminContainer: React.FC = () => {
    const menu = useMenu();
    const navigate = useNavigate();
    const { t } = useTranslation("translation", { keyPrefix: Roles });

    const getRolesWithUserCount = useGetRolesWithUserCount();

    useLoader(isQueryLoading(getRolesWithUserCount), RolesAdminContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.UserManagement, AccordionTitles.Roles);
    }, []);

    const viewRole = (metadata: RoleWithUserCountDto): void => {
        navigate(`${getPath(AccordionTitles.Roles)}/${metadata.roleId}`);
    };

    return (
        <>
            <PageHeading>{t("Roles")}</PageHeading>
            <PageSubHeading>{t("HeaderHelpText")}</PageSubHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(getRolesWithUserCount) && (
                <DataTable
                    columns={roleColumnNames}
                    keyPrefix={Roles}
                    rows={getRolesWithUserCount?.data?.rows}
                    viewItem={viewRole}
                />
            )}
        </>
    );
};

export default RolesAdminContainer;
