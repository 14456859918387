import React from "react";
import styled from "styled-components";
import loaderImage from "../../core/images/logo_loading_spinner.gif";
import { useLoaderProvider } from "../../core/store/loader-context";

const StyledOverlayDiv = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: #c0c0c0;
    opacity: 0.5;
`;

const StyledLoaderDiv = styled.div`
    position: absolute;
    top: 35%;
    left: 50%;
    text-align: center;
    width: 120px;
    height: 120px;
    background-image: url(${loaderImage});
    background-size: contain;
`;

export const Loader: React.FC = () => {
    const loaderProvider = useLoaderProvider();

    return loaderProvider.isLoading ? (
        <StyledOverlayDiv>
            <StyledLoaderDiv />
        </StyledOverlayDiv>
    ) : (
        <></>
    );
};
