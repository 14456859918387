import AnswerFlagMode from "../../enums/answer-types/answer-flag-mode";
import BasePredefinedAnswerResponse from "../../responses/predefined-answers/base-predefined-answers-response";

export default class BasePredefinedAnswerDto {
    predefinedAnswerId: number;
    answerTypeId: number;
    answerFlagMode: AnswerFlagMode | null;
    tooltipDescription: string | null;
    intValue: number | null;
    boolValue: boolean | null;
    dateValueUtc: string | null;
    decimalValue: number | null;

    public constructor(response: BasePredefinedAnswerResponse) {
        const {
            predefinedAnswerId,
            answerTypeId,
            tooltipDescription,
            intValue,
            boolValue,
            dateValueUtc,
            decimalValue,
            answerFlagMode,
        } = response;

        this.predefinedAnswerId = predefinedAnswerId;
        this.answerTypeId = answerTypeId;
        this.answerFlagMode = answerFlagMode;
        this.tooltipDescription = tooltipDescription;
        this.intValue = intValue;
        this.boolValue = boolValue;
        this.dateValueUtc = dateValueUtc;
        this.decimalValue = decimalValue;
    }
}
