enum InstanceStatus {
    Pending,
    Cancelled,
    Rescheduled,
    Completed,
    Overdue,
    LockedOut,
    Due,
}

export default InstanceStatus;
