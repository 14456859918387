import QuestionCaptureResponse from "../../responses/answer-capture/question-capture-response";
import SavedQuestionCaptureResponse from "../../responses/answer-capture/saved-question-capture-response";
import QuestionSetInstanceAnswerFileDetailsResponse from "../../responses/question-set-instance-answers/question-set-instance-answer-file-details-response";
import { FileDto } from "../file-storage/file-dto";
import BaseQuestionAnswerDto from "./base-question-answer-dto";
import QuestionAnswerInstanceDto from "./question-answer-instance-dto";

export default class QuestionAnswerDto extends BaseQuestionAnswerDto {
    questionSetInstanceAnswerFileDtos: FileDto[];

    constructor(
        questionSetInstanceAnswerId: number,
        questionId: number,
        parentQuestionId: number | null,
        questionSetInstanceAnswerFileDetailsResponses: QuestionSetInstanceAnswerFileDetailsResponse[]
    ) {
        super(questionSetInstanceAnswerId, questionId, parentQuestionId);

        this.questionSetInstanceAnswerFileDtos =
            FileDto.constructFromQuestionSetInstanceAnswerFileDetailsResponses(
                questionSetInstanceAnswerFileDetailsResponses
            );
    }

    public static constructFromSavedAnswer = (
        questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        savedQuestionCaptureResponse: SavedQuestionCaptureResponse
    ): QuestionAnswerDto => {
        const { questionSetInstanceAnswerId, questionId, parentQuestionId } =
            questionAnswerInstanceDto;

        const {
            questionSetInstanceAnswerFileDetailsResponses,
            predefinedAnswerId,
            textValue,
            intValue,
            decimalValue,
            boolValue,
            commentText,
            questionReviewComment,
            actionItemDueDateLocal,
        } = savedQuestionCaptureResponse;

        const questionAnswerDto = new QuestionAnswerDto(
            questionSetInstanceAnswerId,
            questionId,
            parentQuestionId,
            questionSetInstanceAnswerFileDetailsResponses
        );

        questionAnswerDto.textValue = textValue;
        questionAnswerDto.intValue = intValue;
        questionAnswerDto.decimalValue = decimalValue;
        questionAnswerDto.boolValue = boolValue;
        questionAnswerDto.predefinedAnswerId = predefinedAnswerId;
        questionAnswerDto.actionItemDueDateTimeLocal = actionItemDueDateLocal
            ? new Date(actionItemDueDateLocal)
            : null;
        questionAnswerDto.commentText = commentText;
        questionAnswerDto.reviewCommentText = questionReviewComment;

        return questionAnswerDto;
    };

    // TODO: Improvement - Have this accept a list of SavedQuestionCaptureResponses instead
    public static constructFromQuestionCaptureResponses = (
        questionCaptureResponses: QuestionCaptureResponse[]
    ): QuestionAnswerDto[] =>
        questionCaptureResponses.flatMap((x) =>
            x.savedQuestionCaptureResponse
                ? QuestionAnswerDto.constructFromSavedAnswer(
                      new QuestionAnswerInstanceDto(
                          x.questionSetInstanceAnswerId,
                          x.questionTextResponse.questionId,
                          null
                      ),
                      x.savedQuestionCaptureResponse
                  )
                : []
        );
}
