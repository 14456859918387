import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
} from "../common/base-pagination-request";

export interface FilterAssociatedActionItemsRequest extends BasePaginationRequest {
    actionPlanId: number;
}

export const createFilterAssociatedActionItemsRequest = (
    paginationRequest: BasePaginationRequest,
    actionPlanId: number
): FilterAssociatedActionItemsRequest => ({
    ...paginationRequest,
    actionPlanId: actionPlanId,
});

export const buildAssociatedActionItemsSearchParameters = (
    request: FilterAssociatedActionItemsRequest
): URLSearchParams => {
    const searchParams = buildBasePaginationSearchParameters(request);

    searchParams.set("actionPlanId", request.actionPlanId.toString());

    return searchParams;
};
