import { EditQuestionSetInstanceReviewRatingDto } from "../../dtos/review-reports/edit-question-set-instance-review-rating-dto";
import ReviewRating from "../../enums/review-reports/review-ratings";

export class EditQuestionSetInstanceReviewRatingRequest {
    questionSetInstanceId: number;
    reviewRating: ReviewRating;

    public constructor(
        editQuestionSetInstanceReviewRatingDto: EditQuestionSetInstanceReviewRatingDto
    ) {
        this.questionSetInstanceId = editQuestionSetInstanceReviewRatingDto.questionSetInstanceId;
        this.reviewRating = editQuestionSetInstanceReviewRatingDto.reviewRating;
    }
}
