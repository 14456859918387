import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { ActionInstanceStatus } from "../../enums/action-items/ActionInstanceStatus";
import { MyActionItemResponse } from "../../responses/action-items/my-action-item-response";
import { ActionItemInstanceCommentNotificationDto } from "./action-item-instance-comment-notifications";

export interface MyActionItemsDto {
    actionItemId: number;
    actionItemInstanceId: number;
    name: string;
    description: string;
    startDateLocal: string | null;
    dueDateLocal: string | null;
    actionInstanceStatus: ActionInstanceStatus;
    isExtended: boolean;
    isOnHold: boolean;
    actionItemInstanceCommentNotificationDtos: ActionItemInstanceCommentNotificationDto[];
}

export const toMyActionItemsDtos = (
    myActionItemResponse: MyActionItemResponse
): MyActionItemsDto => ({
    actionItemId: myActionItemResponse.actionItemId,
    actionItemInstanceId: myActionItemResponse.actionItemInstanceId,
    name: myActionItemResponse.name,
    description: myActionItemResponse.description,
    startDateLocal: myActionItemResponse.startDateUtc
        ? toDateAndTimeFormat(myActionItemResponse.startDateUtc.toString())
        : null,
    dueDateLocal: myActionItemResponse.dueDateUtc
        ? toDateAndTimeFormat(myActionItemResponse.dueDateUtc.toString())
        : null,
    actionInstanceStatus: myActionItemResponse.actionInstanceStatus,
    isExtended: myActionItemResponse.isExtended,
    isOnHold: myActionItemResponse.isOnHold,
    actionItemInstanceCommentNotificationDtos:
        myActionItemResponse.actionItemInstanceCommentNotificationResponses, // TODO: map to dto
});
