import React from "react";
import styled, { css } from "styled-components";

interface StyledRightRibbonProps {
    readonly $size: string;
}

// https://codepen.io/Paulie-D/pen/gpgYvZ
const StyledRightRibbon = styled.div<StyledRightRibbonProps>`
    background: ${(props) => props.theme.palette.warning};
    color: white;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(30%) translateY(0%) rotate(45deg);
    transform-origin: top left;

    :before,
    :after {
        content: "";
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: ${(props) => props.theme.palette.warning};
    }

    :before {
        right: 100%;
    }

    :after {
        left: 100%;
    }

    ${({ $size }) =>
        $size === "small" &&
        css`
            font-size: ${(props) => props.theme.font.sm};
        `}
    ${({ $size }) =>
        $size === "large" &&
        css`
            font-size: ${(props) => props.theme.font.lg};
        `};
`;

export const SbRibbon: React.FC<{ size: "small" | "large"; label: string }> = ({ size, label }) => {
    return <StyledRightRibbon $size={size}>{label}</StyledRightRibbon>;
};
