import { CreateActionItemCustomNotificationDto } from "../../dtos/actions/create-action-item-custom-notification-dto";
import { NotificationTriggersPeriodType } from "../../enums/action-items/NotificationTriggersPeriodType";

export class CreateActionItemCustomNotificationRequest {
    firstPreDueDateNotificationValue: number;
    firstNotificationPeriodType: NotificationTriggersPeriodType;
    secondPreDueDateNotificationValue: number;
    secondNotificationPeriodType: NotificationTriggersPeriodType;
    thirdPreDueDateNotificationValue: number;
    thirdNotificationPeriodType: NotificationTriggersPeriodType;
    firstPostDueDateNotificationValue: number;
    fourthNotificationPeriodType: NotificationTriggersPeriodType;
    secondPostDueDateNotificationValue: number;
    fifthNotificationPeriodType: NotificationTriggersPeriodType;
    thirdPostDueDateNotificationValue: number;
    sixthNotificationPeriodType: NotificationTriggersPeriodType;

    public constructor(dto: CreateActionItemCustomNotificationDto) {
        const {
            firstPreDueDateNotificationValue,
            firstNotificationPeriodType,
            secondPreDueDateNotificationValue,
            secondNotificationPeriodType,
            thirdPreDueDateNotificationValue,
            thirdNotificationPeriodType,
            firstPostDueDateNotificationValue,
            fourthNotificationPeriodType,
            secondPostDueDateNotificationValue,
            fifthNotificationPeriodType,
            thirdPostDueDateNotificationValue,
            sixthNotificationPeriodType,
        } = dto;

        this.firstPreDueDateNotificationValue = firstPreDueDateNotificationValue;
        this.firstNotificationPeriodType = firstNotificationPeriodType;
        this.secondPreDueDateNotificationValue = secondPreDueDateNotificationValue;
        this.secondNotificationPeriodType = secondNotificationPeriodType;
        this.thirdPreDueDateNotificationValue = thirdPreDueDateNotificationValue;
        this.thirdNotificationPeriodType = thirdNotificationPeriodType;
        this.firstPostDueDateNotificationValue = firstPostDueDateNotificationValue;
        this.fourthNotificationPeriodType = fourthNotificationPeriodType;
        this.secondPostDueDateNotificationValue = secondPostDueDateNotificationValue;
        this.fifthNotificationPeriodType = fifthNotificationPeriodType;
        this.thirdPostDueDateNotificationValue = thirdPostDueDateNotificationValue;
        this.thirdPostDueDateNotificationValue = thirdPostDueDateNotificationValue;
        this.sixthNotificationPeriodType = sixthNotificationPeriodType;
    }
}
