import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { EndAlignedDiv } from "../../../core/theme/global-styles";
import { getNullableString } from "../../../core/utilities/string-helper";
import { BaseSbFieldProps } from "../../props/input/InputFieldProps";
import SbLabelText from "../SbLabelText";

const StyledTextAreaDiv = styled.div`
    display: block;
    flex-grow: 1;
`;

export interface SbTextAreaProps extends BaseSbFieldProps {
    rows?: number;
    maxLength?: number;
    placeholder?: string;
    value?: string;
    defaultValue?: string | null;
    onChange?: (inputText: string | null) => void;
}

const SbTextArea: React.FC<SbTextAreaProps> = ({
    name,
    rows,
    maxLength,
    placeholder,
    value,
    defaultValue,
    onChange,
    required,
    disabled,
}) => {
    const [count, setCount] = useState(maxLength);

    const getCount = (valueLength: number, maxLength: number): void => {
        setCount(maxLength - valueLength);
    };

    useEffect(() => {
        if (value && maxLength) {
            getCount(value.length, maxLength);
        } else if (defaultValue && maxLength) {
            getCount(defaultValue.length, maxLength);
        }
    }, []);

    const buildKeyBasedOnDefaultValue = (): string | undefined => {
        if (!defaultValue) {
            return undefined;
        }

        if (defaultValue.length > 10) {
            return `${defaultValue.substring(0, 10)}${defaultValue.length}`;
        }

        return defaultValue;
    };

    const onInputChanged = (inputText: string): void => {
        maxLength && getCount(inputText.length, maxLength);

        onChange && onChange(getNullableString(inputText));
    };

    return (
        <StyledTextAreaDiv>
            <Form.Control
                as="textarea"
                name={name}
                rows={rows}
                maxLength={maxLength}
                onChange={(event) => onInputChanged(event.target.value)}
                key={buildKeyBasedOnDefaultValue()}
                defaultValue={defaultValue ?? undefined}
                placeholder={placeholder}
                required={required}
                disabled={disabled}
                value={value}
            />
            <EndAlignedDiv>{count && <SbLabelText label={count.toString()} />}</EndAlignedDiv>
        </StyledTextAreaDiv>
    );
};

export default SbTextArea;
