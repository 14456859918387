import { ActionInstanceStatus } from "../../enums/action-items/ActionInstanceStatus";
import { AnswerDetailsResponse } from "../../responses/answer-summary/answer-details-response";
import QuestionSetInstanceAnswerFileDownloadResponse from "../../responses/answer-summary/question-set-instance-answer-file-download-response";
import { Response } from "../../responses/common/response-response";
import { ReviewTypeFieldsValuesDto } from "./review-type-fields-value-dto";

export class AnswerDetailsDto {
    questionSetInstanceAnswerId: number;
    questionSetInstanceReviewId: number;
    questionSetInstanceId: number;
    nestedQuestionParentQuestionSetInstanceAnswerId: number | null;
    userFullName: string;
    checklistName: string;
    questionText: string;
    answerValue: string;
    comment: string;
    openDateTimeUtc: Date;
    dueDateTimeUtc: Date;
    checklistTimeZoneId: string;
    answerTypeName: string;
    instanceStatus: ActionInstanceStatus;
    childAnswerDetails: AnswerDetailsDto[];
    dueDateTimeInChecklistTimeZone: Date;
    openDateTimeInChecklistTimeZone: Date;
    statusDateInChecklistTimeZone: Date;
    actionItemDueDate: Date;
    attachedEvidence: QuestionSetInstanceAnswerFileDownloadResponse[];
    questionCategoryName: string;
    reviewTypeFieldsValues: ReviewTypeFieldsValuesDto[];

    public constructor(answerDetailsResponse: AnswerDetailsResponse) {
        this.questionSetInstanceAnswerId = answerDetailsResponse.questionSetInstanceAnswerId;
        this.questionSetInstanceReviewId = answerDetailsResponse.questionSetInstanceReviewId;
        this.questionSetInstanceId = answerDetailsResponse.questionSetInstanceId;
        this.nestedQuestionParentQuestionSetInstanceAnswerId =
            answerDetailsResponse.nestedQuestionParentQuestionSetInstanceAnswerId;
        this.userFullName = answerDetailsResponse.userFullName;
        this.checklistName = answerDetailsResponse.checklistName;
        this.questionText = answerDetailsResponse.questionText;
        this.answerValue = answerDetailsResponse.answerValue;
        this.comment = answerDetailsResponse.comment;
        this.openDateTimeUtc = answerDetailsResponse.openDateTimeUtc;
        this.dueDateTimeUtc = answerDetailsResponse.dueDateTimeUtc;
        this.checklistTimeZoneId = answerDetailsResponse.checklistTimeZoneId;
        this.answerTypeName = answerDetailsResponse.answerTypeName;
        this.instanceStatus = answerDetailsResponse.instanceStatus;
        this.childAnswerDetails = answerDetailsResponse.childAnswerDetails;
        this.dueDateTimeInChecklistTimeZone = answerDetailsResponse.dueDateTimeInChecklistTimeZone;
        this.openDateTimeInChecklistTimeZone =
            answerDetailsResponse.openDateTimeInChecklistTimeZone;
        this.statusDateInChecklistTimeZone = answerDetailsResponse.statusDateInChecklistTimeZone;
        this.actionItemDueDate = answerDetailsResponse.actionItemDueDate;
        this.attachedEvidence = answerDetailsResponse.attachedEvidence;
        this.questionCategoryName = answerDetailsResponse.questionCategoryName;
        this.reviewTypeFieldsValues = answerDetailsResponse.reviewTypeFieldsValues;
    }

    public static constructFromResponse = (
        response: Response<AnswerDetailsResponse>
    ): AnswerDetailsDto => new AnswerDetailsDto(response.data);
}
