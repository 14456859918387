import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import { EnterKey } from "../../../core/constants/KeyboardKeys";
import { Audits } from "../../../core/constants/translation-namespace";
import useKeyPress from "../../../core/hooks/keyPress";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { securityAuditColumnNames } from "../../../core/utilities/dataTableColumns";
import { auditSecurityColumnToProperty } from "../../../core/utilities/dataTableColumnToProperty";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import {
    defaultFilterAuditsSecurityLoginDto,
    FilterAuditsSecurityLoginDto,
} from "../../../domain/dtos/audits/filter-audits-security-login-dto";
import { defaultPaginationDto, PaginationDto } from "../../../domain/dtos/common/pagination-dto";
import { ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import { useGetAuditSecurityLogins } from "../../../domain/viewmodels/audits/audits-security-login-viewmodel";
import { DataTable } from "../../organisms/DataTable";
import { SecurityAuditFilter } from "../../organisms/filters/SecurityAuditFilter";

interface SearchParams {
    username: string | null;
    startDate: string | null;
    endDate: string | null;
    ipAddress: string | null;
    source: string | null;
    wasSuccessful: string | null;
}

const createSearchParams = (
    username: string | null,
    startDate: string | null,
    endDate: string | null,
    ipAddress: string | null,
    source: string | null,
    wasSuccessful: string | null
): SearchParams => ({
    username: username,
    startDate: startDate,
    endDate: endDate,
    ipAddress: ipAddress,
    source: source,
    wasSuccessful: wasSuccessful,
});

const defaultSearchParams: SearchParams = createSearchParams(null, null, null, null, null, null);

const AuditsSecurityLoginContainer: React.FC = () => {
    const [paginationDto, setPaginationDto] = useState<PaginationDto>(defaultPaginationDto);
    const [searchParams, setSearchParams] = useState<SearchParams>(defaultSearchParams);
    const [filterDto, setFilterDto] = useState<FilterAuditsSecurityLoginDto>(
        defaultFilterAuditsSecurityLoginDto
    );

    const menu = useMenu();
    const { t } = useTranslation("translation", { keyPrefix: Audits });

    const getAuditSecurityLogins = useGetAuditSecurityLogins(filterDto);
    useLoader(isQueryLoading(getAuditSecurityLogins), AuditsSecurityLoginContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Audits, AccordionTitles.SecurityAuditLog);
    }, []);

    const sortBy = (column: string): void => {
        setPaginationDto({
            ...paginationDto,
            sortByColumn: auditSecurityColumnToProperty[column],
            sortByDescending: !paginationDto.sortByDescending,
        });
    };

    const changeIpAddress = (ipAddress: string): void => {
        setSearchParams({
            ...searchParams,
            ipAddress: ipAddress !== "" ? ipAddress : null,
        });
    };

    const changeSource = (source: string): void => {
        setSearchParams({
            ...searchParams,
            source: source !== "" ? source : null,
        });
    };

    const changeStartDate = (startDate: string): void => {
        setSearchParams({
            ...searchParams,
            startDate: startDate !== "" ? startDate : null,
        });
    };

    const changeEndDate = (endDate: string): void => {
        setSearchParams({
            ...searchParams,
            endDate: endDate !== "" ? endDate : null,
        });
    };

    const changeUserName = (userName: string): void => {
        setSearchParams({
            ...searchParams,
            username: userName !== "" ? userName : null,
        });
    };

    const changeWasSuccessful = (option: SingleValue<ReactSelectDto<string>>): void => {
        setSearchParams({
            ...searchParams,
            wasSuccessful: option?.value ?? null,
        });
    };

    const search = (): void => {
        setFilterDto({ ...filterDto, pageNumber: 1, ...searchParams });
    };

    const resetFilter = (): void => {
        setSearchParams(defaultSearchParams);

        setFilterDto({ ...filterDto, ...defaultSearchParams });
    };

    useKeyPress(EnterKey, search, searchParams);

    return (
        <>
            <PageHeading>{t("SecurityAuditLogs")}</PageHeading>
            <PageSubHeading>{t("HelperTextSecurityAudit")}</PageSubHeading>
            <SectionVerticalSpace />

            <SecurityAuditFilter
                ipAddress={searchParams.ipAddress}
                source={searchParams.source}
                startDate={searchParams.startDate}
                endDate={searchParams.endDate}
                userName={searchParams.username}
                changeIpAddress={changeIpAddress}
                changeSource={changeSource}
                changeStartDate={changeStartDate}
                changeEndDate={changeEndDate}
                changeUserName={changeUserName}
                wasSuccessful={searchParams.wasSuccessful}
                changeWasSuccessful={changeWasSuccessful}
                search={search}
                resetFilter={resetFilter}
            />
            <SectionVerticalSpace />

            {isQuerySuccessful(getAuditSecurityLogins) && (
                <DataTable
                    columns={securityAuditColumnNames}
                    keyPrefix={Audits}
                    rows={getAuditSecurityLogins.data!.rows}
                    sortBy={sortBy}
                    totalItems={getAuditSecurityLogins.data!.recordCount}
                    paginationDto={filterDto}
                    setPaginationDto={setFilterDto}
                />
            )}
        </>
    );
};

export default AuditsSecurityLoginContainer;
