import { useEffect } from "react";
import { useMenu } from "../../core/store/menu-context";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../core/utilities/enums";

const ImportContainer: React.FC<{ route: string }> = ({ route }) => {
    const menu = useMenu();

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Import);
    }, []);

    return (
        <>
            <h2>Placeholder text for route:</h2>
            <h2>{route}</h2>
        </>
    );
};

export default ImportContainer;
