import { FilterQuestionsDto } from "../../dtos/questions/filter-questions-dto";
import {
    buildPaginationSearchParameters,
    createPaginationRequest,
    PaginationRequest,
} from "../common/pagination-request";
import { buildQuestionsSearchParameters, SearchQuestionsRequest } from "./search-questions-request";

export interface FilterQuestionsRequest extends SearchQuestionsRequest, PaginationRequest {}

export const createFilterQuestionsRequestFromDto = (
    dto: FilterQuestionsDto
): FilterQuestionsRequest => {
    const { pageNumber, pageSize, sortByColumn, sortByDescending } = dto;

    return {
        ...dto,
        ...createPaginationRequest(pageNumber, pageSize, sortByColumn, sortByDescending),
    };
};

export const buildFilterSearchParameters = (request: FilterQuestionsRequest): URLSearchParams => {
    const searchParams = buildPaginationSearchParameters(request);
    buildQuestionsSearchParameters(request, searchParams);

    return searchParams;
};
