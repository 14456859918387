import { useNavigate } from "react-router-dom";
import { useAuth } from "../store/auth-context";
import { DrawerTitles } from "./enums";
import { Feature } from "./Features";
import { getPath } from "./getPath";

export const useSessionStorageNavigation = (): (() => void) => {
    const navigate = useNavigate();
    const { isAdmin } = useAuth();

    return () => {
        const homeScreenPreference = localStorage.getItem("homeScreenPreference");
        const lastUsedUrl = localStorage.getItem("lastUsedUrl");

        let pathToNavigate = "/dashboard";

        if (homeScreenPreference === Feature[Feature.ActionTracker] && !isAdmin) {
            pathToNavigate = getPath(DrawerTitles.MyActionSummary);
        }

        if (homeScreenPreference === Feature[Feature.CODAC] && !isAdmin) {
            pathToNavigate = getPath(DrawerTitles.AnswerSummary);
        }

        if (lastUsedUrl) {
            pathToNavigate = lastUsedUrl;
            localStorage.removeItem("lastUsedUrl");
        }

        navigate(pathToNavigate);
    };
};
