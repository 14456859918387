import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { EnterKey } from "../../../core/constants/KeyboardKeys";
import { Email } from "../../../core/constants/translation-namespace";
import useKeyPress from "../../../core/hooks/keyPress";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { emailQueueColumnNames } from "../../../core/utilities/dataTableColumns";
import { emailQueueColumnToProperty } from "../../../core/utilities/dataTableColumnToProperty";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import {
    BasePaginationDto,
    defaultBasePaginationDto,
} from "../../../domain/dtos/common/base-pagination-dto";
import { createPaginationDto } from "../../../domain/dtos/common/pagination-dto";
import {
    defaultFilterEmailQueueDto,
    FilterEmailQueueDto,
} from "../../../domain/dtos/email-queue/filter-email-queue-dto";
import {
    emptySearchEmailQueueDto,
    SearchEmailQueueDto,
} from "../../../domain/dtos/email-queue/search-email-queue-dto";
import { useFilterEmailQueue } from "../../../domain/viewmodels/email-queue/view-email-queue-viewmodel";
import { DataTable } from "../../organisms/DataTable";
import { EmailQueueFilter } from "../../organisms/filters/EmailQueueFilter";

const EmailQueueContainer: React.FC = () => {
    const [searchParams, setSearchParams] = useState<SearchEmailQueueDto>(
        emptySearchEmailQueueDto()
    );
    const [filterDto, setFilterDto] = useState<FilterEmailQueueDto>(defaultFilterEmailQueueDto);
    const [paginationDto, setPaginationDto] = useState<BasePaginationDto>(defaultBasePaginationDto);

    const menu = useMenu();

    const toast = useToast();
    const [urlSearchParams] = useSearchParams();

    const success = urlSearchParams.get("success") === "true" ? true : false;

    const { t } = useTranslation("translation", { keyPrefix: Email });

    const filterEmailQueue = useFilterEmailQueue(
        filterDto,
        createPaginationDto(
            paginationDto.pageNumber,
            paginationDto.pageSize,
            filterDto.sortByColumn,
            filterDto.sortByDescending
        )
    );

    const filterEmailQueueResponseData = filterEmailQueue.data;

    useLoader(isQueryLoading(filterEmailQueue), EmailQueueContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.EmailQueue);

        if (success) {
            toast.addToast(createSuccessToastProps([t("EmailWillBeResent")]));
        }
    }, []);

    const changeSubject = (subject: string): void => {
        setSearchParams({ ...searchParams, subject: subject ? subject : null });
    };

    const changeRecipients = (recipients: string): void => {
        setSearchParams({ ...searchParams, recipients: recipients ? recipients : null });
    };

    const changeDateSentFrom = (dateSentFrom: string): void => {
        setSearchParams({ ...searchParams, dateSentFrom: dateSentFrom ? dateSentFrom : null });
    };

    const changeDateSentTo = (dateSentTo: string): void => {
        setSearchParams({ ...searchParams, dateSentTo: dateSentTo ? dateSentTo : null });
    };

    const changeFailedToSend = (name: string, failedToSend: boolean): void => {
        setSearchParams({ ...searchParams, failedToSend: failedToSend ?? false });
    };

    const sortBy = (column: string): void => {
        setFilterDto({
            ...filterDto,
            sortByColumn: emailQueueColumnToProperty[column],
            sortByDescending: !filterDto.sortByDescending,
        });
    };

    const search = (): void => {
        setPaginationDto({
            ...paginationDto,
            pageNumber: 1,
        });
        setFilterDto({ ...filterDto, ...searchParams });
    };

    const resetFilter = (): void => {
        setSearchParams(emptySearchEmailQueueDto());
        setFilterDto(defaultFilterEmailQueueDto);
    };

    useKeyPress(EnterKey, search, searchParams);

    return (
        <>
            <PageHeading>System Message Log</PageHeading>
            <PageSubHeading>
                System log for all automatically generated system notifications that are emailed to
                users
            </PageSubHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(filterEmailQueue) && (
                <>
                    <EmailQueueFilter
                        subject={searchParams.subject}
                        changeSubject={changeSubject}
                        recipients={searchParams.recipients}
                        changeRecipients={changeRecipients}
                        dateSentFrom={searchParams.dateSentFrom}
                        changeDateSentFrom={changeDateSentFrom}
                        dateSentTo={searchParams.dateSentTo}
                        failedToSend={searchParams.failedToSend}
                        changeDateSentTo={changeDateSentTo}
                        changeFailedToSend={changeFailedToSend}
                        search={search}
                        resetFilter={resetFilter}
                    />
                    <SectionVerticalSpace />

                    <DataTable
                        columns={emailQueueColumnNames}
                        rows={filterEmailQueueResponseData!.rows}
                        totalItems={filterEmailQueueResponseData!.recordCount}
                        sortBy={sortBy}
                        paginationDto={paginationDto}
                        setPaginationDto={setPaginationDto}
                        isCheckDisabled={true}
                    />
                </>
            )}
        </>
    );
};

export default EmailQueueContainer;
