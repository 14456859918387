import { useMutation, UseMutationResult, useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import {
    filterActionItemDynamicFields,
    orderActionItemDynamicFields,
} from "../../../data/services/action-items/action-item-dynamic-fields-service";
import { getActionItemTypes } from "../../../data/services/action-items/action-item-types-service";
import {
    ActionItemTypeDto,
    toActionItemTypeDtos,
} from "../../dtos/action-items/action-item-type-dto";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import AnswerValueType from "../../enums/answer-types/answer-value-type";
import { createFilterActionItemDynamicFieldsRequest } from "../../requests/action-items/filter-action-item-dynamic-fields-request";
import { createOrderActionItemDynamicFieldRequest } from "../../requests/action-items/order-action-item-dynamic-field-request";
import { ActionItemDynamicFieldDetailsResponse } from "../../responses/action-items/action-item-dynamic-field-details-response";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";

export const useGetActionItemDynamicFieldData = (
    actionItemTypeNodeId: number | null
): [
    UseQueryResult<ActionItemTypeDto[], HTTPError>,
    UseQueryResult<PaginatedTableDto<number>, HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getActionItemTypes"],
                queryFn: () => getActionItemTypes(url.baseUrl),
                select: toActionItemTypeDtos,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["filterActionItemDynamicFields", actionItemTypeNodeId],
                queryFn: () =>
                    filterActionItemDynamicFields(
                        url.baseUrl,
                        createFilterActionItemDynamicFieldsRequest(
                            null,
                            actionItemTypeNodeId,
                            1,
                            1000,
                            "OrderIndex"
                        )
                    ),
                enabled: actionItemTypeNodeId !== null,
                select: toDataTableRows,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<ActionItemTypeDto[], HTTPError>,
        UseQueryResult<PaginatedTableDto<number>, HTTPError>,
    ];
};

export const useOrderActionItemDynamicFields = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    number[]
> => {
    const url = useUrl();

    return useMutation((actionItemDynamicFieldIds: number[]) => {
        const request = actionItemDynamicFieldIds.map((x, index) =>
            createOrderActionItemDynamicFieldRequest(x, ++index)
        );

        return orderActionItemDynamicFields(url.baseUrl, request);
    });
};

const toDataTableRows = (
    response: Response<PaginationResponse<ActionItemDynamicFieldDetailsResponse>>
): PaginatedTableDto<number> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((x) => ({
        metadata: x.actionItemDynamicFieldId,
        columns: [
            {
                value: x.orderIndex.toString(),
                type: DataTableColumnTypes.Text,
                metadata: "Index",
            },
            {
                value: x.fieldName,
                type: DataTableColumnTypes.Text,
            },
            {
                value: x.actionItemTypeResponse.value.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: AnswerValueType[x.valueType].toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: x.isRequired.toString(),
                type: DataTableColumnTypes.Check,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
