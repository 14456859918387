import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { dissociateReferentialLinkType } from "../../../data/services/question-set/question-set-service";
import { createAssociateDissociateReferentialLinkTypeToQuestionSetRequest } from "../../requests/question-set/associate-dissociate-referential-link-type-to-question-set-request";
import { Response } from "../../responses/common/response-response";

interface DissociateParameters {
    questionSetId: number;
    referentialLinkTypeId: number;
}

export const useDissociateReferentialLinkType = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    DissociateParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: DissociateParameters) => {
        const request = createAssociateDissociateReferentialLinkTypeToQuestionSetRequest(
            mutationKey.questionSetId,
            mutationKey.referentialLinkTypeId
        );

        return dissociateReferentialLinkType(url.baseUrl, request);
    });
};
