import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import styled from "styled-components";
import { ChecklistQuestions, Common } from "../../core/constants/translation-namespace";
import { maxContentWidthSelectStyle } from "../../core/theme/global-styles";
import { MonthsOfTheYearOptions } from "../../core/utilities/QuestionSetValues";
import { ReactSelectDto } from "../../domain/dtos/common/react-select-dto";
import { MonthOfTheYear } from "../../domain/enums/questions/question-sets/MonthOfTheYear";
import { NumberInputBox } from "../atoms/input/NumberInputBox";
import { SbSelect } from "../atoms/input/SbSelect";
import { translateWithFallback } from "../helpers/translate-with-common-fallback";

const StyledDivFormField = styled.div`
    display: inline-block;
    padding-top: ${(props) => props.theme.padding.md};
    text-align: right;
    vertical-align: middle;
    margin-bottom: 0;
    box-sizing: border-box;
    padding-right: ${(props) => props.theme.padding.sm};
`;

export const OnceOffSchedule: React.FC<{
    handleMonthOfTheYearChange: (item: SingleValue<ReactSelectDto<MonthOfTheYear>>) => void;
    handleDayOfTheMonthChange: React.ChangeEventHandler<HTMLInputElement>;
    handleYearChange: React.ChangeEventHandler<HTMLInputElement>;
    dayOfTheMonth?: number;
    monthOfTheYear?: string;
    year?: number;
}> = ({
    handleMonthOfTheYearChange,
    handleDayOfTheMonthChange,
    handleYearChange,
    dayOfTheMonth,
    monthOfTheYear,
    year,
}) => {
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    return (
        <>
            <Form.Group className="mb">
                <StyledDivFormField>{t("Day")}</StyledDivFormField>
                <StyledDivFormField>
                    <NumberInputBox
                        handleNumberInputChange={handleDayOfTheMonthChange}
                        defaultValue={dayOfTheMonth}
                    ></NumberInputBox>
                </StyledDivFormField>
            </Form.Group>
            <Form.Group className="mb">
                <StyledDivFormField>{t("MonthOfYear")}</StyledDivFormField>
                <StyledDivFormField>
                    <SbSelect
                        styles={maxContentWidthSelectStyle}
                        name={"monthsOfTheYear"}
                        placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                        searchable
                        clearable
                        items={MonthsOfTheYearOptions}
                        onChange={handleMonthOfTheYearChange}
                        itemLabel={(option: ReactSelectDto<MonthOfTheYear>) =>
                            translateWithFallback(
                                t,
                                MonthOfTheYear[option.value],
                                ChecklistQuestions
                            )
                        }
                        itemValue={(option: ReactSelectDto<MonthOfTheYear>) =>
                            option.value.toString()
                        }
                        defaultSelectedItem={MonthsOfTheYearOptions.find(
                            (x) => x.value.toString() === monthOfTheYear
                        )}
                    />
                </StyledDivFormField>
            </Form.Group>
            <StyledDivFormField>{t("Year")}</StyledDivFormField>
            <StyledDivFormField>
                <NumberInputBox
                    handleNumberInputChange={handleYearChange}
                    defaultValue={year}
                ></NumberInputBox>
            </StyledDivFormField>
        </>
    );
};
