import { ReactElement, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaPlay } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { SingleValue } from "react-select";
import { ReportName } from "../../../core/constants/string";
import {
    ChecklistQuestions,
    Common,
    ErrorMessages,
    Reports,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { ToastVariant, useToast } from "../../../core/store/toast-context";
import {
    EndAlignedDiv,
    maxContentWidthSelectStyle,
    StyledBorderedSection,
    StyledFormLabel,
    StyledVariantText,
} from "../../../core/theme/global-styles";
import { getEnumsForType } from "../../../core/utilities/enum-helper";
import { DrawerTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { areMutationsLoading } from "../../../core/utilities/responseStateHelper";
import AdhocQuestionSetAssignmentScheduleDto from "../../../domain/dtos/checklists/adhoc-question-set-assignment-schedule-dto";
import IntervalOverridesDto from "../../../domain/dtos/checklists/interval-overrides-dto";
import TriggerAdhocQuestionSetDto from "../../../domain/dtos/checklists/trigger-adhoc-question-set-dto";
import { createReactSelectDto, ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import BaseReferentialLinkDto from "../../../domain/dtos/referential-links/base-referential-link-dto";
import NodeReferentialLinksDto from "../../../domain/dtos/referential-links/node-referential-links-dto";
import { BaseUserDto } from "../../../domain/dtos/users/base-user-dto";
import AppliesToUsers from "../../../domain/enums/checklists/AppliesToUsers";
import { PeriodType } from "../../../domain/enums/PeriodType";
import InstanceStatus from "../../../domain/enums/questions/instance-status";
import { isResponse } from "../../../domain/responses/common/response-response";
import {
    buildAdhocChecklistNotificationDto,
    buildIntervalOverridesDto,
    useFilterReviewReportSetIdByReportName,
    useTriggerAdhocQuestionSet,
} from "../../../domain/viewmodels/trigger-adhocs/trigger-adhocs-viewmodel";
import { SbSelect } from "../../atoms/input/SbSelect";
import { InfoAlert, SbAlert } from "../../atoms/SbAlert";
import { SbButton } from "../../atoms/SbButton";
import { TextLink } from "../../atoms/SbLink";
import SbFormDateTimeFieldGroup from "../../molecules/input/SbFormDateTimeFieldGroup";
import SbFormNumberBoxGroup from "../../molecules/input/SbFormNumberBoxGroup";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import { ComponentTitledPanel } from "../../molecules/SbPanel";
import ReferentialLinksSelectGroup from "../../organisms/trigger-adhocs/ReferentialLinksSelectGroup";

const TriggerQuestionSetContainer: React.FC<{
    adhocQuestionSetAssignmentScheduleDto: AdhocQuestionSetAssignmentScheduleDto;
    functionUserDtos?: BaseUserDto[];
    nodeId: number;
    arMonitoring: boolean;
    nodeReferentialLinksResponseData: NodeReferentialLinksDto[] | undefined;
    nodeAdhocIntervalOverrideResponseData: IntervalOverridesDto | undefined;
}> = ({
    adhocQuestionSetAssignmentScheduleDto,
    functionUserDtos,
    nodeId,
    arMonitoring,
    nodeReferentialLinksResponseData,
    nodeAdhocIntervalOverrideResponseData,
}) => {
    const [duplicateTriggered, setDuplicateTriggered] = useState("");
    const [reviewReportSetId, setReviewReportSetId] = useState(0);
    const [triggerUser, setTriggerUser] = useState("");

    const {
        questionSetAssignmentScheduleId,
        checklistAssignmentChecklistDto,
        questionSetReferentialLinkTypeDto,
    } = adhocQuestionSetAssignmentScheduleDto;

    const checklistAssignmentDto = checklistAssignmentChecklistDto.checklistAssignmentDto;
    const referentialLinkTypeReferentialLinkDtos =
        questionSetReferentialLinkTypeDto.referentialLinkTypeReferentialLinkDtos;

    const allowAdhocIntervalOverride = checklistAssignmentDto.allowAdhocIntervalOverride;
    const allowCustomNotifications = checklistAssignmentDto.allowCustomNotifications;
    const specifyReferentialLinks = referentialLinkTypeReferentialLinkDtos.length > 0;

    const referentialLinkTypeLinkMap = useRef<Map<number, number | null>>(
        new Map(
            referentialLinkTypeReferentialLinkDtos.map((x) => [
                x.referentialLinkTypeDto.referentialLinkTypeId,
                null,
            ])
        )
    );

    const createReferentialLinkTypeLinkMap = useRef<Map<number, string | null>>(
        new Map(
            referentialLinkTypeReferentialLinkDtos.map((x) => [
                x.referentialLinkTypeDto.referentialLinkTypeId,
                null,
            ])
        )
    );

    const functionUserId = useRef<number | null>(null);
    const openBeforeDuePeriodType = useRef<PeriodType | null>(null);

    const toast = useToast();
    const auth = useAuth();

    const navigate = useNavigate();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const triggerAdhocQuestionSet = useTriggerAdhocQuestionSet();
    const filterReviewReportSetIdByReportName = useFilterReviewReportSetIdByReportName();

    const isTeamLeadView = functionUserDtos !== undefined;

    const showFunctionUsers =
        isTeamLeadView && checklistAssignmentDto.appliesToUsers === AppliesToUsers.Each;

    const triggerForAuthUser = !isTeamLeadView;

    useLoader(
        areMutationsLoading([triggerAdhocQuestionSet, filterReviewReportSetIdByReportName]),
        TriggerQuestionSetContainer
    );

    const onReferentialLinkSelected = (
        option: SingleValue<BaseReferentialLinkDto>,
        referentialLinkTypeId: number
    ): void => {
        if (option!.referentialLinkId === 0) {
            createReferentialLinkTypeLinkMap.current.set(referentialLinkTypeId, option!.value);
        } else {
            referentialLinkTypeLinkMap.current.set(
                referentialLinkTypeId,
                option!.referentialLinkId
            );
        }
    };

    const createNewReferentialLinkSelectValue = (searchText: string): BaseReferentialLinkDto =>
        new BaseReferentialLinkDto(0, searchText);

    const buildPanelTitle = (): ReactElement<HTMLTableRowElement[]> => (
        <Row>
            <Col>
                <StyledVariantText $variant="primary">
                    <strong>{t("Checklist")}: </strong>
                    {
                        adhocQuestionSetAssignmentScheduleDto.checklistAssignmentChecklistDto
                            .checklistDto.name
                    }
                </StyledVariantText>
            </Col>
            <Col>
                <StyledVariantText $variant="primary">
                    <strong>{t("QuestionSet")}: </strong>
                    {
                        adhocQuestionSetAssignmentScheduleDto.questionSetReferentialLinkTypeDto
                            .questionSetDto.name
                    }
                </StyledVariantText>
            </Col>
        </Row>
    );

    const renderReferentialLinkTypes = (): ReactElement<HTMLDivElement> => (
        <StyledBorderedSection>
            <InfoAlert message={t("AdhocTriggerReferentialLinkInstructions")} />

            {questionSetReferentialLinkTypeDto.referentialLinkTypeReferentialLinkDtos.map(
                (x, index) => (
                    <ReferentialLinksSelectGroup
                        onReferentialLinkSelected={onReferentialLinkSelected}
                        index={index}
                        baseReferentialLinkTypeReferentialLinkDto={x}
                        createNewReferentialLinkSelectValue={createNewReferentialLinkSelectValue}
                    />
                )
            )}
        </StyledBorderedSection>
    );

    const renderOverRides = (): ReactElement<HTMLDivElement> => (
        <StyledBorderedSection>
            <SbFormDateTimeFieldGroup
                name="dueDate"
                label={t("DueDate", { keyPrefix: Common })}
                required
            />

            <SbFormNumberBoxGroup
                name="openBeforeDueValue"
                label={t("OpenBefore", { keyPrefix: Common })}
                required
            >
                <SbSelect
                    name={"openBeforeDuePeriodType"}
                    items={getEnumsForType(PeriodType).map((x) =>
                        createReactSelectDto(x, PeriodType[x])
                    )}
                    styles={maxContentWidthSelectStyle}
                    placeholderText={`${t("PleaseSelectPeriodType")}`}
                    searchable
                    clearable={false}
                    itemLabel={(option) => t(option.label, { keyPrefix: Common })}
                    onChange={(option: SingleValue<ReactSelectDto<PeriodType>>) => {
                        openBeforeDuePeriodType.current = option!.value;
                    }}
                    required
                />
            </SbFormNumberBoxGroup>
        </StyledBorderedSection>
    );

    const renderCustomNotifications = (): ReactElement<HTMLDivElement> => (
        <StyledBorderedSection>
            <InfoAlert message={t("AdhocChecklistCustomNotificationTriggers")} />

            <SbFormNumberBoxGroup
                name="daysNotificationOne"
                label={t("Notify", { keyPrefix: Common })}
                defaultValue={0}
                required
            >
                <StyledFormLabel>{t("DaysNotificationPeriod")}</StyledFormLabel>
            </SbFormNumberBoxGroup>

            <SbFormNumberBoxGroup
                name="daysNotificationTwo"
                label={t("Notify", { keyPrefix: Common })}
                defaultValue={0}
                required
            >
                <StyledFormLabel>{t("DaysNotificationPeriod")}</StyledFormLabel>
            </SbFormNumberBoxGroup>

            <SbFormNumberBoxGroup
                name="hoursNotification"
                label={t("Notify", { keyPrefix: Common })}
                defaultValue={0}
                required
            >
                <StyledFormLabel>{t("HoursNotificationPeriod")}</StyledFormLabel>
            </SbFormNumberBoxGroup>
        </StyledBorderedSection>
    );

    const renderFunctionUsers = (): ReactElement<HTMLDivElement> => (
        <StyledBorderedSection>
            <SbFormSelectFieldGroup
                name={"functionUsers"}
                label={t("FunctionUser", { keyPrefix: Common })}
                items={functionUserDtos}
                placeholderText={`${t("PleaseSelectUser", { keyPrefix: Common })}`}
                onChange={(option: SingleValue<BaseUserDto>) => {
                    functionUserId.current = option!.userId;
                }}
                searchable
                clearable={false}
                itemDisplayText={(option: BaseUserDto) => option.fullName}
                required
            />
        </StyledBorderedSection>
    );

    const onTriggerAdhocQuestionSet = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        setDuplicateTriggered("");
        setTriggerUser("");

        const formData = new FormData(event.currentTarget);

        const intervalOverridesDto = buildIntervalOverridesDto(
            formData,
            openBeforeDuePeriodType.current,
            allowAdhocIntervalOverride
        );
        const adhocChecklistNotificationDto = buildAdhocChecklistNotificationDto(
            formData,
            allowCustomNotifications
        );
        const referentialLinks = specifyReferentialLinks
            ? Array.from(referentialLinkTypeLinkMap.current.values())
            : null;

        const createdReferentialLinks = specifyReferentialLinks
            ? createReferentialLinkTypeLinkMap.current
            : null;

        let dto: TriggerAdhocQuestionSetDto;

        if (arMonitoring) {
            const nodeReferentialLinks =
                nodeReferentialLinksResponseData?.map((x) => x.referentialLinkId) ?? null;

            dto = new TriggerAdhocQuestionSetDto(
                questionSetAssignmentScheduleId,
                auth.userId!,
                nodeAdhocIntervalOverrideResponseData ?? null,
                null,
                nodeReferentialLinks,
                null,
                nodeId
            );
        } else {
            dto = new TriggerAdhocQuestionSetDto(
                questionSetAssignmentScheduleId,
                triggerForAuthUser ? auth.userId! : functionUserId.current,
                intervalOverridesDto,
                adhocChecklistNotificationDto,
                referentialLinks,
                createdReferentialLinks,
                nodeId
            );
        }

        triggerAdhocQuestionSet.mutate(dto, {
            onSuccess: async () => {
                toast.addToast({
                    messages: [t("SuccessfullyTriggeredAdhocQuestionSet")],
                    variant: ToastVariant.success,
                });

                navigate(getPath(DrawerTitles.AnswerCapture));
            },
            onError: async (error) => {
                const errorClone = error?.response?.clone();
                errorResponseToDisplayHandler(error);

                const response = await errorClone?.json();
                if (response && !isResponse(response)) {
                    return;
                }

                let errors: string[] = [];
                response?.errors?.forEach((e: string) => {
                    errors.push(e);
                });

                if (
                    errors.includes(
                        t("CannotDuplicateChecklistTriggeredAndPending", {
                            keyPrefix: ErrorMessages,
                        })
                    )
                ) {
                    setDuplicateTriggered(InstanceStatus.Pending.toString());

                    return;
                }

                if (
                    errors.includes(
                        t("CannotDuplicateChecklistTriggeredAndSubmitted", {
                            keyPrefix: ErrorMessages,
                        })
                    )
                ) {
                    let reportNameReferentialLinkType = "";
                    questionSetReferentialLinkTypeDto.referentialLinkTypeReferentialLinkDtos.map(
                        (x) => {
                            if (x.referentialLinkTypeDto.name === ReportName) {
                                reportNameReferentialLinkType = `referentialLinkType_${x.referentialLinkTypeDto.referentialLinkTypeId}`;
                            }
                        }
                    );

                    let reportName = formData.get(reportNameReferentialLinkType) as string;

                    if (arMonitoring) {
                        const reportNameRefLink = nodeReferentialLinksResponseData?.filter(
                            (x) => x.referentialLinkTypeName === ReportName
                        );

                        if (reportNameRefLink && reportNameRefLink.length > 0) {
                            reportName = reportNameRefLink[0].referentialLinkValue;
                        }
                    }

                    filterReviewReportSetIdByReportName.mutate(reportName, {
                        onSuccess: async (response) => {
                            setReviewReportSetId(response.data!);
                            setDuplicateTriggered(InstanceStatus.Completed.toString());
                        },
                    });

                    return;
                }

                const latestErrorIndex = errors.length - 1;
                const latestErrorText = errors[latestErrorIndex];

                if (
                    latestErrorText.includes(
                        t("CannotDuplicateChecklistTriggeredAndPendingAndNotTriggeredByUser", {
                            keyPrefix: ErrorMessages,
                        })
                    )
                ) {
                    setDuplicateTriggered(InstanceStatus.Pending.toString());

                    const triggerUserStartIndex = latestErrorText.indexOf(":") + 1;
                    const triggerUserEndIndex = latestErrorText.length;

                    setTriggerUser(
                        latestErrorText.substring(triggerUserStartIndex, triggerUserEndIndex)
                    );
                }
            },
        });
    };

    const buildDuplicateAlertText = (): string => {
        if (duplicateTriggered === InstanceStatus.Pending.toString() && triggerUser) {
            return `${t("CannotDuplicateChecklistTriggeredAndPendingAndNotTriggeredByUser", {
                keyPrefix: ErrorMessages,
            })} ${triggerUser}`;
        }

        if (duplicateTriggered === InstanceStatus.Pending.toString()) {
            return t("CannotDuplicateChecklistTriggeredAndPending", { keyPrefix: ErrorMessages });
        }

        return t("CannotDuplicateChecklistTriggeredAndSubmitted", { keyPrefix: ErrorMessages });
    };

    const buildDuplicateAlertLink = (): ReactElement<typeof Link> => {
        if (duplicateTriggered === InstanceStatus.Pending.toString()) {
            return (
                <>
                    <TextLink
                        navigateTo={`${getPath(DrawerTitles.PendingAdhocs)}`}
                        label={t("ClickHere", { keyPrefix: Common })}
                    />
                    <span> {t("ChecklistViewCancel", { keyPrefix: Reports })}</span>
                </>
            );
        }

        return (
            <>
                <TextLink
                    navigateTo={`${getPath(DrawerTitles.Reviews)}/${reviewReportSetId}`}
                    label={t("ClickHere", { keyPrefix: Common })}
                />
                <span> {t("ChecklistViewCancel", { keyPrefix: Reports })}</span>
            </>
        );
    };

    const renderChecklistDetails = (): ReactElement<HTMLDivElement> => (
        <>
            {specifyReferentialLinks && renderReferentialLinkTypes()}

            {allowAdhocIntervalOverride && renderOverRides()}

            {allowCustomNotifications && renderCustomNotifications()}

            {showFunctionUsers && renderFunctionUsers()}
        </>
    );

    return (
        <ComponentTitledPanel component={buildPanelTitle()}>
            <Form onSubmit={onTriggerAdhocQuestionSet}>
                <>
                    {!arMonitoring && renderChecklistDetails()}

                    {duplicateTriggered && (
                        <SbAlert
                            variant={"warning"}
                            text={buildDuplicateAlertText()}
                            textLink={triggerUser ? undefined : buildDuplicateAlertLink}
                        />
                    )}

                    <EndAlignedDiv>
                        <SbButton
                            variant="primary"
                            type={"submit"}
                            label={t("Trigger")}
                            icon={FaPlay}
                        />
                    </EndAlignedDiv>
                </>
            </Form>
        </ComponentTitledPanel>
    );
};

export default TriggerQuestionSetContainer;
