import { TriggeringDate } from "../../enums/notification-triggers/triggering-date";
import { PeriodType } from "../../enums/PeriodType";

export class EditNotificationTriggerDto {
    notificationTriggerId: number;
    triggeringDate: TriggeringDate;
    triggerTimeDeltaIntervalPeriodType: PeriodType;
    sendWindowIntervalPeriodType: PeriodType;
    formData: FormData;

    public constructor(
        notificationTriggerId: number,
        triggeringDate: TriggeringDate,
        triggerTimeDeltaIntervalPeriodType: PeriodType,
        sendWindowIntervalPeriodType: PeriodType,
        formData: FormData
    ) {
        this.notificationTriggerId = notificationTriggerId;
        this.triggeringDate = triggeringDate;
        this.triggerTimeDeltaIntervalPeriodType = triggerTimeDeltaIntervalPeriodType;
        this.sendWindowIntervalPeriodType = sendWindowIntervalPeriodType;
        this.formData = formData;
    }
}
