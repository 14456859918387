import { FilterChecklistsDto } from "../../dtos/checklists/filter-checklists-dto";
import {
    buildPaginationSearchParameters,
    createPaginationRequest,
    PaginationRequest,
} from "../common/pagination-request";

export interface FilterChecklistsRequest extends PaginationRequest {
    name: string | null;
    modifiedByUserFullName: string | null;
    isTraining: boolean | null;
}

export const createFilterChecklistsRequestFromDto = (
    dto: FilterChecklistsDto
): FilterChecklistsRequest => {
    const { pageNumber, pageSize, sortByColumn, sortByDescending } = dto;

    return {
        ...dto,
        ...createPaginationRequest(pageNumber, pageSize, sortByColumn, sortByDescending),
    };
};

const SearchParameters = {
    name: "name",
    modifiedByUserFullName: "modifiedByUserFullName",
    isTraining: "isTraining",
};

export const buildFilterChecklistSearchParameters = (
    request: FilterChecklistsRequest
): URLSearchParams => {
    const searchParams = buildPaginationSearchParameters(request);
    if (request.name) {
        searchParams.set(SearchParameters.name, request.name);
    }

    if (request.modifiedByUserFullName) {
        searchParams.set(SearchParameters.modifiedByUserFullName, request.modifiedByUserFullName);
    }

    if (request.isTraining != null) {
        searchParams.set(SearchParameters.isTraining, request.isTraining.toString());
    }

    return searchParams;
};
