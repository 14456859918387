import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { Common } from "../../core/constants/translation-namespace";
import { createNavigateSearchParameter, useNavigateSearch } from "../../core/hooks/navigateSearch";
import {
    ToastNavigationProps,
    toastTitles,
    ToastVariant,
    useToast,
} from "../../core/store/toast-context";
import { translateText } from "../helpers/translate";

const StyledToastContainer = styled(ToastContainer)`
    margin-top: ${(props) => props.theme.padding.md};
`;

interface StyledToastProps {
    $variant: ToastVariant;
    $navigable?: boolean;
}

const StyledToast = styled(Toast)<StyledToastProps>`
    cursor: ${(props) => (props.$navigable === true ? "pointer" : "default")};

    ${({ $variant }) =>
        $variant === ToastVariant.success &&
        css`
            background-color: ${(props) => props.theme.palette.success};
            color: ${(props) => props.theme.palette.purewhite};
        `}

    ${({ $variant }) =>
        $variant === ToastVariant.warning &&
        css`
            background-color: ${(props) => props.theme.palette.warning};
        `} 

    ${({ $variant }) =>
        $variant === ToastVariant.danger &&
        css`
            background-color: ${(props) => props.theme.palette.danger};
            color: ${(props) => props.theme.palette.purewhite};
        `}
`;

export const SbToast: React.FC = () => {
    const toast = useToast();
    const navigateSearch = useNavigateSearch();
    const { t } = useTranslation("translation");

    const getToastHeader = (value: string): string => {
        return translateText(t, value, Common);
    };

    const onToastClicked = (
        navigationProps: ToastNavigationProps | undefined
    ): void | undefined => {
        if (!navigationProps) {
            return undefined;
        }

        const params = navigationProps.navigateSearchParameters.map((x) =>
            createNavigateSearchParameter(x.variableName, x.value)
        );

        navigateSearch(navigationProps.navigatePath, params);
    };

    return toast.isVisible ? (
        <StyledToastContainer className="position-fixed" position={"top-end"}>
            {toast.toasts?.map((x) => (
                <StyledToast
                    $variant={x.props.variant}
                    $navigable={x.props.navigationProps !== undefined}
                    key={x.id}
                    onClose={() => {
                        toast.dismissToast(x.id);
                    }}
                    show={toast.isVisible}
                    onClick={() => onToastClicked(x.props.navigationProps)}
                    delay={4000}
                    autohide={x.props.autoHide !== false}
                >
                    <Toast.Header closeButton={x.props.autoHide === false}>
                        <strong className="me-auto">
                            {getToastHeader(toastTitles.get(x.props.variant)!.toString())}
                        </strong>
                    </Toast.Header>
                    {x.props.messages.map((x, index) => (
                        <Toast.Body key={index}>{x}</Toast.Body>
                    ))}
                </StyledToast>
            ))}
        </StyledToastContainer>
    ) : (
        <></>
    );
};
