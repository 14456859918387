import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { getNodeTypeValueDetails } from "../../../data/services/hierarchy/node-type-values-service";
import {
    createNodeTypeValueDetailsDto,
    NodeTypeValueDetailsDto,
} from "../../dtos/hierarchy/node-type-value-details-dto";

export const useGetNodeTypeValueDetails = (
    nodeTypeValueId: number
): UseQueryResult<NodeTypeValueDetailsDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getNodeTypeValueDetails"],
        () => getNodeTypeValueDetails(url.baseUrl, nodeTypeValueId),
        {
            select: createNodeTypeValueDetailsDto,
            onError: errorResponseToDisplayHandler,
        }
    );
};
