import { ReactSelectDto } from "../../domain/dtos/common/react-select-dto";
import { PeriodType } from "../../domain/enums/PeriodType";
import { DayOrdinal } from "../../domain/enums/questions/question-sets/DayOrdinalNumber";
import { DaysOfWeek } from "../../domain/enums/questions/question-sets/DaysOfTheWeek";
import { FrequencyType } from "../../domain/enums/questions/question-sets/FrequencyType";
import { MonthOfTheYear } from "../../domain/enums/questions/question-sets/MonthOfTheYear";
import { NonWorkingDayRolloverType } from "../../domain/enums/questions/question-sets/NonWorkingDayRolloverType";

export const FrequencyOptions: ReactSelectDto<FrequencyType>[] = [
    { label: "Daily", value: FrequencyType.Daily },
    { label: "Weekly", value: FrequencyType.Weekly },
    { label: "Monthly", value: FrequencyType.Monthly },
    { label: "Yearly", value: FrequencyType.Yearly },
    { label: "OnceOff", value: FrequencyType.OnceOff },
    { label: "Adhoc", value: FrequencyType.Adhoc },
];

export const PeriodTypeOptions: ReactSelectDto<PeriodType>[] = [
    { label: "Hours", value: PeriodType.Hours },
    { label: "Days", value: PeriodType.Days },
];

export const NonWorkingDayRolloverOptions: ReactSelectDto<NonWorkingDayRolloverType>[] = [
    { label: "None", value: NonWorkingDayRolloverType.None },
    { label: "Next day", value: NonWorkingDayRolloverType.NextDay },
    { label: "Next working weekday", value: NonWorkingDayRolloverType.NextWorkingWeekday },
    {
        label: "Next working weekday incl Saturday",
        value: NonWorkingDayRolloverType.NextWorkingWeekdayInclSaturday,
    },
    { label: "Previous day", value: NonWorkingDayRolloverType.PreviousDay },
    { label: "Previous working weekday", value: NonWorkingDayRolloverType.PreviousWorkingWeekday },
    {
        label: "Previous working weekday incl Saturday",
        value: NonWorkingDayRolloverType.PreviousWorkingWeekdayInclSaturday,
    },
];

export const DaysOfTheWeekOptions: ReactSelectDto<DaysOfWeek>[] = [
    { label: "None", value: DaysOfWeek.None },
    { label: "Monday", value: DaysOfWeek.Monday },
    { label: "Tuesday", value: DaysOfWeek.Tuesday },
    { label: "Wednesday", value: DaysOfWeek.Wednesday },
    { label: "Thursday", value: DaysOfWeek.Thursday },
    { label: "Friday", value: DaysOfWeek.Friday },
    { label: "Saturday", value: DaysOfWeek.Saturday },
    { label: "Sunday", value: DaysOfWeek.Sunday },
    { label: "Weekday", value: DaysOfWeek.Weekday },
    { label: "Weekday Including Saturday", value: DaysOfWeek.WeekdayInclSaturday },
    { label: "Day", value: DaysOfWeek.Day },
    { label: "All", value: DaysOfWeek.All },
    { label: "Any", value: DaysOfWeek.Any },
];

export const DayOrdinalOptions: ReactSelectDto<DayOrdinal>[] = [
    { label: "Last", value: DayOrdinal.Last },
    { label: "1st", value: DayOrdinal.First },
    { label: "2nd", value: DayOrdinal.Second },
    { label: "3rd", value: DayOrdinal.Third },
    { label: "4th", value: DayOrdinal.Fourth },
    { label: "5th", value: DayOrdinal.Fifth },
    { label: "6th", value: DayOrdinal.Sixth },
    { label: "7th", value: DayOrdinal.Seventh },
    { label: "8th", value: DayOrdinal.Eighth },
    { label: "9th", value: DayOrdinal.Ninth },
    { label: "10th", value: DayOrdinal.Tenth },
    { label: "11th", value: DayOrdinal.Eleventh },
    { label: "12th", value: DayOrdinal.Twelfth },
    { label: "13th", value: DayOrdinal.Thirteenth },
    { label: "14th", value: DayOrdinal.Fourteenth },
    { label: "15th", value: DayOrdinal.Fifteenth },
    { label: "16th", value: DayOrdinal.Sixteenth },
    { label: "17th", value: DayOrdinal.Seventeenth },
    { label: "18th", value: DayOrdinal.Eighteenth },
    { label: "19th", value: DayOrdinal.Nineteenth },
    { label: "20th", value: DayOrdinal.Twentieth },
    { label: "21st", value: DayOrdinal.TwentyFirst },
    { label: "22nd", value: DayOrdinal.TwentySecond },
    { label: "23rd", value: DayOrdinal.TwentyThird },
    { label: "24th", value: DayOrdinal.TwentyFourth },
    { label: "25th", value: DayOrdinal.TwentyFifth },
    { label: "26th", value: DayOrdinal.TwentySixth },
    { label: "27th", value: DayOrdinal.TwentySeventh },
    { label: "28th", value: DayOrdinal.TwentyEighth },
    { label: "29th", value: DayOrdinal.TwentyNinth },
    { label: "30th", value: DayOrdinal.Thirtieth },
    { label: "31st", value: DayOrdinal.ThirtyFirst },
];

export const MonthsOfTheYearOptions: ReactSelectDto<MonthOfTheYear>[] = [
    { label: "January", value: MonthOfTheYear.January },
    { label: "February", value: MonthOfTheYear.February },
    { label: "March", value: MonthOfTheYear.March },
    { label: "April", value: MonthOfTheYear.April },
    { label: "May", value: MonthOfTheYear.May },
    { label: "June", value: MonthOfTheYear.June },
    { label: "July", value: MonthOfTheYear.July },
    { label: "August", value: MonthOfTheYear.August },
    { label: "September", value: MonthOfTheYear.September },
    { label: "October", value: MonthOfTheYear.October },
    { label: "November", value: MonthOfTheYear.November },
    { label: "December", value: MonthOfTheYear.December },
];

export const calculateMinutes = (fromNumber: number, fromDescription: string): number => {
    switch (fromDescription) {
        case "Days":
            return fromNumber * 24 * 60;
        case "Hours":
            return fromNumber * 60;
        default:
            return 0;
    }
};
