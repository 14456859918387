import { ReactNode, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import styled from "styled-components";
import { SectionVerticalSpace } from "../../core/theme/global-styles";

export const StyledTabsWrap = styled.div`
    & .nav-link {
        color: ${(props) => props.theme.palette.black};
    }

    & .nav-link.active {
        background: ${(props) => props.theme.palette.purple};
        color: ${(props) => props.theme.palette.purewhite};
    }
`;

interface SbTabsProps {
    values: SbTabItemProps[];
}

export class SbTabItemProps {
    title: string;
    childComponent: ReactNode;
    isDefaultSelected: boolean;

    public constructor(title: string, childComponent: ReactNode, isDefaultSelected?: boolean) {
        this.title = title;
        this.childComponent = childComponent;
        this.isDefaultSelected = isDefaultSelected ? isDefaultSelected : false;
    }
}

export const SbTabs: React.FC<SbTabsProps> = ({ values }) => {
    const [activeTabEventKey, setActiveTabEventKey] = useState(
        values.findIndex((x) => x.isDefaultSelected)
    );

    return (
        <StyledTabsWrap>
            <Tabs
                defaultActiveKey={activeTabEventKey}
                onSelect={(eventKey: string | null) => {
                    setActiveTabEventKey(Number(eventKey));
                }}
            >
                {values.map((x, index) => (
                    <Tab key={index} eventKey={index} title={x.title}>
                        {index === activeTabEventKey && (
                            <>
                                <SectionVerticalSpace />
                                {x.childComponent}
                            </>
                        )}
                    </Tab>
                ))}
            </Tabs>
        </StyledTabsWrap>
    );
};

export default SbTabs;
