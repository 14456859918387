import IntervalOverridesDto from "./interval-overrides-dto";

export default class NodeChecklistDefaultValuesDto {
    nodeId: number;
    intervalOverridesDto: IntervalOverridesDto | null;
    referentialLinks: number[] | null;
    createdReferentialLinks: [number, string][] | null;

    public constructor(
        nodeId: number,
        intervalOverridesDto: IntervalOverridesDto | null,
        referentialLinks: (number | null)[] | null,
        createdReferentialLinks: Map<number, string | null> | null
    ) {
        this.nodeId = nodeId;
        this.intervalOverridesDto = intervalOverridesDto;

        const filteredReferentialLinks = referentialLinks
            ? (referentialLinks.filter((x) => x !== null) as number[])
            : null;
        this.referentialLinks = filteredReferentialLinks?.length ? filteredReferentialLinks : null;

        const filteredCreatedReferentialLinks = createdReferentialLinks
            ? (Array.from(createdReferentialLinks.entries()).filter((x) => x[1] !== null) as [
                  number,
                  string,
              ][])
            : null;

        this.createdReferentialLinks = filteredCreatedReferentialLinks?.length
            ? filteredCreatedReferentialLinks
            : null;
    }
}
