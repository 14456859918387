import CreateQuestionSetActionDto from "./create-question-set-action-dto";

export interface AssociateChecklistNodeDto {
    checklistId: number;
    nodeId: number;
    nodeTypeValueIds: number[];
    timeZoneNodeTypeValueId: number | null;
    appliesToUsers: string;
    userId: number;
    formData: FormData;
    createQuestionSetActionDtos: CreateQuestionSetActionDto[];
}

export const createAssociateChecklistNodeDto = (
    checklistId: number,
    nodeId: number,
    nodeTypeValueIds: number[],
    timeZoneNodeTypeValueId: number | null,
    appliesToUsers: string,
    userId: number,
    formData: FormData,
    createQuestionSetActionDtos: CreateQuestionSetActionDto[]
): AssociateChecklistNodeDto => ({
    checklistId: checklistId,
    nodeId: nodeId,
    nodeTypeValueIds: nodeTypeValueIds,
    timeZoneNodeTypeValueId: timeZoneNodeTypeValueId,
    appliesToUsers: appliesToUsers,
    userId: userId,
    formData: formData,
    createQuestionSetActionDtos: createQuestionSetActionDtos,
});
