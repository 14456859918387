export default class ValidateHierarchyImportDto {
    hierarchyTypeId: number;
    file: File;
    fileBase64String: string;

    public constructor(hierarchyTypeId: number, file: File, fileBase64String: string) {
        this.hierarchyTypeId = hierarchyTypeId;
        this.file = file;
        this.fileBase64String = fileBase64String;
    }
}
