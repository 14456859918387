export const helpItemAdminTranslationKeys = [
    "UserGuide",
    "ActionTrackerUserGuide",
    "AdhocChecklists",
    "BUSAUserGuide",
    "TrainingVideos",
    "ProductSpecialistOverview",
];

export const helpItemTenantTranslationKeys = [
    "ViewLatestUpdate",
    "UpdatesHistory",
    "UserGuide",
    "ActionTrackerUserGuide",
    "AdhocChecklists",
    "BUSAUserGuide",
    "TrainingVideos",
    "ProductSpecialistOverview",
];

export const supportItemTranslationKeys = ["LogTicketOnRemedy", "RemedyGuide"];

export const profileItemTranslationKeys = ["Profile", "LogOff"];

export const tenantItems = ["Standard Bank", "Liberty"];
