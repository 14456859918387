export class AddQuestionSetQuestionCommentDto {
    questionSetInstanceId: number;
    questionSetInstanceAnswerId: number;
    commentText: string;

    public constructor(
        questionSetInstanceId: number,
        questionSetInstanceAnswerId: number,
        commentText: string
    ) {
        this.questionSetInstanceId = questionSetInstanceId;
        this.questionSetInstanceAnswerId = questionSetInstanceAnswerId;
        this.commentText = commentText;
    }
}
