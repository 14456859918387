import { ReactElement, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ChecklistQuestions, Nodes } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import { NoDataStateDiv } from "../../../core/theme/global-styles";
import { nameColumnNames } from "../../../core/utilities/dataTableColumns";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { defaultPaginationDto, PaginationDto } from "../../../domain/dtos/common/pagination-dto";
import { ChecklistRoleGroup, hasRoleTypeInGroup } from "../../../domain/enums/Roles";
import { useDissociateChecklistFromNodeByChecklistId } from "../../../domain/viewmodels/checklists/dissociate-node-viewmodel";
import { useGetAssignedChecklistsToNode } from "../../../domain/viewmodels/hierarchy/assigned-checklists-to-node-viewmodel";
import { CancelButton, DissociateButton } from "../../atoms/SbButton";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import { ActionConfirmationFragment } from "../../organisms/ActionConfirmationFragment";
import { DataTable } from "../../organisms/DataTable";

const AssignedChecklistsToNodeContainer: React.FC<{
    isChecklistAssignable: boolean;
    nodeId: number | null;
}> = ({ isChecklistAssignable, nodeId }) => {
    const [assignedChecklistsPaginationDto, setAssignedChecklistsPaginationDto] =
        useState<PaginationDto>(defaultPaginationDto);
    const [dissociateChecklistId, setDissociateChecklistId] = useState<number | null>(null);
    const [showDissociateConfirmation, setShowDissociateConfirmation] = useState<boolean>(false);

    const toast = useToast();
    const auth = useAuth();
    const menu = useMenu();
    const { t } = useTranslation("translation", { keyPrefix: Nodes });
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const assignedChecklistToNode = useGetAssignedChecklistsToNode(
        nodeId,
        assignedChecklistsPaginationDto,
        isChecklistAssignable
    );
    const dissociateChecklistFromNodeByChecklistId = useDissociateChecklistFromNodeByChecklistId();

    useLoader(
        isQueryLoading(assignedChecklistToNode) ||
            isMutationLoading(dissociateChecklistFromNodeByChecklistId),
        AssignedChecklistsToNodeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.VisualTree);
    }, []);

    const onDissociateChecklist = (): void => {
        dissociateChecklistFromNodeByChecklistId.mutate(
            {
                checklistId: dissociateChecklistId!,
                nodeId: nodeId!,
            },
            {
                onSuccess: async () => {
                    setDissociateChecklistId(null);
                    setShowDissociateConfirmation(false);
                    toast.addToast(
                        createSuccessToastProps([
                            t("SuccessfullyDissociatedChecklistFromNode", { keyPrefix: Nodes }),
                        ])
                    );
                    queryClient.refetchQueries([
                        "filterChecklistsByNode",
                        nodeId,
                        assignedChecklistsPaginationDto,
                    ]);
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    const buildConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow(
            t("ChecklistName", {
                keyPrefix: ChecklistQuestions,
            }),
            assignedChecklistToNode
                .data!.rows.find((x) => x.metadata === dissociateChecklistId)
                ?.columns.map((x) => x.value)[0]
        ),
    ];

    const buildDissociateButton = (): ReactElement<typeof Button> => (
        <DissociateButton onClick={onDissociateChecklist} />
    );

    const buildCancelButton = (): ReactElement<typeof Button> => (
        <CancelButton
            onClick={() => {
                setShowDissociateConfirmation(false);
                setDissociateChecklistId(null);
            }}
        />
    );

    return (
        <>
            {!isChecklistAssignable && (
                <NoDataStateDiv> {"This node is not Checklist assignable"} </NoDataStateDiv>
            )}

            {nodeId != null &&
                isQuerySuccessful(assignedChecklistToNode) &&
                isChecklistAssignable &&
                !showDissociateConfirmation && (
                    <DataTable
                        columns={nameColumnNames}
                        rows={assignedChecklistToNode.data!.rows}
                        deleteItem={
                            hasRoleTypeInGroup(auth.userRoles, ChecklistRoleGroup.WriteRoles)
                                ? (checklistId: number) => {
                                      setDissociateChecklistId(checklistId);
                                      setShowDissociateConfirmation(true);
                                  }
                                : undefined
                        }
                        totalItems={assignedChecklistToNode.data!.recordCount}
                        paginationDto={assignedChecklistsPaginationDto}
                        setPaginationDto={setAssignedChecklistsPaginationDto}
                        noResultsMessage={"No assigned checklists"}
                    />
                )}

            {showDissociateConfirmation && (
                <>
                    <ActionConfirmationFragment
                        panelTitle={t("DissociateChecklistNodeConfirmationDisplay", {
                            keyPrefix: ChecklistQuestions,
                        })}
                        rows={buildConfirmationRows()}
                        primaryButton={buildDissociateButton()}
                        cancelButton={buildCancelButton()}
                        areQueriesSuccessful={isQuerySuccessful(assignedChecklistToNode)}
                        isNested={true}
                    />
                </>
            )}
        </>
    );
};

export default AssignedChecklistsToNodeContainer;
