import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import {
    softDeleteUser,
    unDeleteUser as undeleteUser,
} from "../../../data/services/users/users-service";
import { Response } from "../../responses/common/response-response";

export const useSoftDeleteUser = (): UseMutationResult<Response<boolean>, HTTPError, number> => {
    const url = useUrl();

    return useMutation((userId: number) => softDeleteUser(url.baseUrl, userId));
};

export const useUndeleteUser = (): UseMutationResult<Response<boolean>, HTTPError, number> => {
    const url = useUrl();

    return useMutation((userId: number) => undeleteUser(url.baseUrl, userId));
};
