import { Response } from "../../../domain/responses/common/response-response";
import BaseFileDetailsResponse from "../../../domain/responses/file-storage/base-file-details-response";
import client from "../../http-client";

const route = "filedetails";

export const downloadFile = async (
    prefixUrl: string,
    fileDetailsId: number
): Promise<Response<BaseFileDetailsResponse>> => {
    const searchParams = new URLSearchParams();

    searchParams.set("fileDetailsId", fileDetailsId.toString());

    return await client
        .get(`${route}/download`, {
            prefixUrl: prefixUrl,
            searchParams: searchParams,
        })
        .json();
};
