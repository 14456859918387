import { ActionItemInstanceCommentDto } from "../../dtos/action-items/action-item-instance-comment-dto";

export class ActionItemInstanceCommentRequest {
    actionItemInstanceId: number;
    comment: string;

    public constructor(dto: ActionItemInstanceCommentDto) {
        this.actionItemInstanceId = dto.actionItemInstanceId;
        this.comment = dto.comment;
    }
}
