import { getNullableString } from "../../../core/utilities/string-helper";
import AnswerValueType from "../../enums/answer-types/answer-value-type";
import CommentType from "../../enums/answer-types/comment-type";
import QuestionAnswerInstanceDto from "./question-answer-instance-dto";

export default class BaseQuestionAnswerDto extends QuestionAnswerInstanceDto {
    textValue: string | null;
    intValue: number | null;
    decimalValue: number | null;
    boolValue: boolean | null;
    dateTimeValueLocal: Date | null;
    predefinedAnswerId: number | null;
    actionItemDueDateTimeLocal: Date | null;
    commentType: CommentType | null;
    commentText: string | null;
    reviewCommentText: string | null;

    constructor(
        questionSetInstanceAnswerId: number,
        questionId: number,
        parentQuestionId: number | null,
        textValue: string | null = null,
        intValue: number | null = null,
        decimalValue: number | null = null,
        boolValue: boolean | null = null,
        dateTimeValueLocal: Date | null = null,
        predefinedAnswerId: number | null = null,
        actionItemDueDateTimeLocal: Date | null = null,
        commentType: CommentType | null = null,
        commentText: string | null = null,
        reviewCommentText: string | null = null
    ) {
        super(questionSetInstanceAnswerId, questionId, parentQuestionId);

        this.textValue = textValue;
        this.intValue = intValue;
        this.decimalValue = decimalValue;
        this.boolValue = boolValue;
        this.dateTimeValueLocal = dateTimeValueLocal;
        this.predefinedAnswerId = predefinedAnswerId;
        this.actionItemDueDateTimeLocal = actionItemDueDateTimeLocal;
        this.commentType = commentType;
        this.commentText = commentText;
        this.reviewCommentText = reviewCommentText;
    }

    public static constructFromQuestionAnswerInstanceDto(
        questionAnswerInstanceDto: QuestionAnswerInstanceDto
    ): BaseQuestionAnswerDto {
        const { questionSetInstanceAnswerId, questionId, parentQuestionId } =
            questionAnswerInstanceDto;

        return new BaseQuestionAnswerDto(questionSetInstanceAnswerId, questionId, parentQuestionId);
    }

    public static constructForMutuallyExclusiveAnswer(
        questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        predefinedAnswerId: number
    ): BaseQuestionAnswerDto {
        const baseQuestionAnswerDto =
            this.constructFromQuestionAnswerInstanceDto(questionAnswerInstanceDto);
        baseQuestionAnswerDto!.predefinedAnswerId = predefinedAnswerId;

        return baseQuestionAnswerDto;
    }

    public static constructForInputValueAnswer<TType>(
        questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        answerValueType: AnswerValueType,
        answerValue: TType | null
    ): BaseQuestionAnswerDto {
        switch (answerValueType) {
            case AnswerValueType.Integer:
                return this.constructForIntegerAnswer(
                    questionAnswerInstanceDto,
                    answerValue as number | null
                );

            case AnswerValueType.Decimal:
                return this.constructForDecimalAnswer(
                    questionAnswerInstanceDto,
                    answerValue as number | null
                );

            case AnswerValueType.DateTime:
                return this.constructForDateTimeValueLocal(
                    questionAnswerInstanceDto,
                    answerValue as Date | null
                );

            case AnswerValueType.Boolean:
                return this.constructForCheckboxAnswer(
                    questionAnswerInstanceDto,
                    answerValue as boolean | null
                );

            case (AnswerValueType.Text, AnswerValueType.TextArea):
                return this.constructForTextAnswer(
                    questionAnswerInstanceDto,
                    answerValue as string | null
                );
            default:
                return this.constructFromQuestionAnswerInstanceDto(questionAnswerInstanceDto);
        }
    }

    public static constructForIntegerAnswer(
        questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        integerAnswer: number | null
    ): BaseQuestionAnswerDto {
        const baseQuestionAnswerDto =
            this.constructFromQuestionAnswerInstanceDto(questionAnswerInstanceDto);
        baseQuestionAnswerDto.intValue = integerAnswer;

        return baseQuestionAnswerDto;
    }

    public static constructForDecimalAnswer(
        questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        decimalValue: number | null
    ): BaseQuestionAnswerDto {
        const baseQuestionAnswerDto =
            this.constructFromQuestionAnswerInstanceDto(questionAnswerInstanceDto);
        baseQuestionAnswerDto.decimalValue = decimalValue;

        return baseQuestionAnswerDto;
    }

    public static constructForDateTimeValueLocal(
        questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        dateTimeValueLocal: Date | null
    ): BaseQuestionAnswerDto {
        const baseQuestionAnswerDto =
            this.constructFromQuestionAnswerInstanceDto(questionAnswerInstanceDto);
        baseQuestionAnswerDto.dateTimeValueLocal = dateTimeValueLocal;

        return baseQuestionAnswerDto;
    }

    public static constructForCheckboxAnswer(
        questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        boolValue: boolean | null
    ): BaseQuestionAnswerDto {
        const baseQuestionAnswerDto =
            this.constructFromQuestionAnswerInstanceDto(questionAnswerInstanceDto);
        baseQuestionAnswerDto.boolValue = boolValue;

        return baseQuestionAnswerDto;
    }

    public static constructForTextAnswer(
        questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        textValue: string | null
    ): BaseQuestionAnswerDto {
        const baseQuestionAnswerDto =
            this.constructFromQuestionAnswerInstanceDto(questionAnswerInstanceDto);
        baseQuestionAnswerDto.setTextValue(textValue);

        return baseQuestionAnswerDto;
    }

    public static constructForComment(
        questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        commentText: string | null
    ): BaseQuestionAnswerDto {
        const baseQuestionAnswerDto =
            this.constructFromQuestionAnswerInstanceDto(questionAnswerInstanceDto);
        baseQuestionAnswerDto.setCommentText(commentText);

        return baseQuestionAnswerDto;
    }

    public static constructForReviewComment(
        questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        commentText: string | null
    ): BaseQuestionAnswerDto {
        const baseQuestionAnswerDto =
            this.constructFromQuestionAnswerInstanceDto(questionAnswerInstanceDto);
        baseQuestionAnswerDto.setReviewCommentText(commentText);

        return baseQuestionAnswerDto;
    }

    public static constructForActionItemDate(
        questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        date: Date
    ): BaseQuestionAnswerDto {
        const baseQuestionAnswerDto =
            this.constructFromQuestionAnswerInstanceDto(questionAnswerInstanceDto);
        baseQuestionAnswerDto.actionItemDueDateTimeLocal = date;

        return baseQuestionAnswerDto;
    }

    public static constructFromSavedNestedAnswers(
        questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        commentText: string | null
    ): BaseQuestionAnswerDto {
        const baseQuestionAnswerDto =
            this.constructFromQuestionAnswerInstanceDto(questionAnswerInstanceDto);
        baseQuestionAnswerDto.setCommentText(commentText);

        return baseQuestionAnswerDto;
    }

    public setAnswerValueForInputValueAnswer<TType>(
        answerValueType: AnswerValueType,
        answerValue: TType | null
    ): void {
        switch (answerValueType) {
            case AnswerValueType.Integer:
                this.intValue = answerValue as number | null;
                break;

            case AnswerValueType.Decimal:
                this.decimalValue = answerValue as number | null;
                break;

            case AnswerValueType.DateTime:
                this.dateTimeValueLocal = answerValue as Date | null;
                break;

            case AnswerValueType.Boolean:
                this.boolValue = answerValue as boolean | null;
                break;

            case (AnswerValueType.Text, AnswerValueType.TextArea):
                this.setTextValue(answerValue as string | null);
                break;
        }
    }

    public setTextValue(textValue: string | null): void {
        this.textValue = getNullableString(textValue);
    }

    public setCommentText(commentText: string | null): void {
        this.commentText = getNullableString(commentText);
    }

    public setReviewCommentText(commentText: string | null): void {
        this.reviewCommentText = getNullableString(commentText);
    }

    public isTopLevelQuestion(): boolean {
        return this.parentQuestionId === null;
    }

    public isNotAnswered(): boolean {
        return (
            this.textValue === null &&
            this.intValue === null &&
            this.decimalValue === null &&
            this.boolValue === null &&
            this.dateTimeValueLocal === null &&
            this.predefinedAnswerId === null &&
            this.actionItemDueDateTimeLocal === null &&
            this.commentType === null &&
            this.commentText === null &&
            this.reviewCommentText === null
        );
    }
}
