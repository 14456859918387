import React, { ReactElement, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ChecklistAnswers } from "../../../core/constants/translation-namespace";
import {
    StyledBoldVariantText,
    StyledBorderedSection,
    StyledInlineDiv,
} from "../../../core/theme/global-styles";
import QuestionAnswerInstanceDto from "../../../domain/dtos/answer-capture/question-answer-instance-dto";
import { useAnswerCapture } from "../../../domain/stores/answer-capture-context";
import { useOnTriggerActionItemSubmit } from "../../../domain/viewmodels/answer-capture/question-capture-trigger-action-item-viewmodel";
import SbDateTimeField from "../../atoms/input/SbDateTimeField";
import { CancelButton, ConfirmButton } from "../../atoms/SbButton";
import SbLabelText from "../../atoms/SbLabelText";

const QuestionCaptureTriggerActionItem: React.FC<{
    questionAnswerInstanceDto: QuestionAnswerInstanceDto;
    requiredByParent: boolean;
    onCancelClicked: () => void;
}> = ({ questionAnswerInstanceDto, requiredByParent, onCancelClicked }) => {
    const [selectedDate, setSelectedDate] = useState<Date | null>();
    const [manuallyTriggeringActionItem, setManuallyTriggeringActionItem] = useState(false);

    const { t } = useTranslation("translation", { keyPrefix: ChecklistAnswers });
    const answerCapture = useAnswerCapture();
    const onTriggerActionItemSubmit = useOnTriggerActionItemSubmit();

    useEffect(() => {
        let date =
            answerCapture.getQuestionAnswer(questionAnswerInstanceDto)?.actionItemDueDateTimeLocal;

        if (!date) {
            date = new Date();
            date.setDate(
                date.getDate() +
                    Number(process.env.REACT_APP_DEFAULT_ACTION_ITEM_DUE_DATE_DAYS_FROM_TODAY)
            );
        }

        setSelectedDate(date);
    }, [questionAnswerInstanceDto]);

    useEffect(() => {
        if (manuallyTriggeringActionItem && answerCapture.triggeredQuestionActionItemSuccessfully) {
            setManuallyTriggeringActionItem(false);
            onCancelClicked();
        }
    }, [manuallyTriggeringActionItem, answerCapture.triggeredQuestionActionItemSuccessfully]);

    const onDateChanged = (inputDate: Date): void => {
        answerCapture.updateQuestionAnswerDtosForActionItemDate(
            questionAnswerInstanceDto,
            inputDate
        );

        setSelectedDate(inputDate);
    };

    const onSubmit = (): void => {
        if (selectedDate) {
            setManuallyTriggeringActionItem(true);

            onTriggerActionItemSubmit(
                questionAnswerInstanceDto.questionSetInstanceAnswerId,
                selectedDate
            );
        }
    };

    const buildTitleText = (): string =>
        requiredByParent
            ? t("TriggerActionItemDueDateConfirmation")
            : t("TriggerActionItemConfirmation");

    const buildTriggerActionItemContent = (): ReactElement<HTMLDivElement> => (
        <StyledInlineDiv>
            <Col sm={2}>
                <SbDateTimeField
                    name={"actionItemDateTime"}
                    required
                    onInputChange={onDateChanged}
                    defaultValue={selectedDate}
                />
            </Col>

            {!requiredByParent && (
                <>
                    <CancelButton onClick={onCancelClicked} />
                    <ConfirmButton type={"button"} onClick={onSubmit} />
                </>
            )}
        </StyledInlineDiv>
    );

    return (
        <StyledBorderedSection>
            <StyledBoldVariantText $variant="secondary">
                <SbLabelText label={buildTitleText()} required={requiredByParent} />
            </StyledBoldVariantText>
            {buildTriggerActionItemContent()}
        </StyledBorderedSection>
    );
};

export default QuestionCaptureTriggerActionItem;
