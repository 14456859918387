import { ReactElement, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import {
    Common,
    HierarchyTypeTreeView,
    Nodes,
    UserManagement,
    Users,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import { maxContentWidthSelectStyle, NoDataStateDiv } from "../../../core/theme/global-styles";
import { nameColumnNames } from "../../../core/utilities/dataTableColumns";
import {
    areMutationsLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { BaseUserDto } from "../../../domain/dtos/users/base-user-dto";
import { hasRoleTypeInGroup, UserRoleGroup } from "../../../domain/enums/Roles";
import {
    useAssignUserToNode,
    useFilterAssignedUsersToNode,
    useFilterUsers,
} from "../../../domain/viewmodels/hierarchy/assign-users-to-node-viewmodel";
import {
    createUserNodeAssociationParameters,
    useDissociateUser,
} from "../../../domain/viewmodels/hierarchy/dissociate-user-viewmodel";
import { SbSelect } from "../../atoms/input/SbSelect";
import { CancelButton, CreateIconButton, DissociateButton, SbButton } from "../../atoms/SbButton";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import { ActionConfirmationFragment } from "../../organisms/ActionConfirmationFragment";
import { DataTable } from "../../organisms/DataTable";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { useMenu } from "../../../core/store/menu-context";

const AssignedUsersToNodeContainer: React.FC<{
    selectedNodeIdToViewAssignedUsers: number | null;
}> = ({ selectedNodeIdToViewAssignedUsers }) => {
    const [userSearchText, setUserSearchText] = useState<string | null>(null);
    const [assigningUser, setAssigningUser] = useState<BaseUserDto | null>(null);
    const [removingUserId, setRemovingUserId] = useState<number | null>(null);
    const [showAssignUserConfirmation, setShowAssignUserConfirmation] = useState(false);
    const [showRemoveUserConfirmation, setShowRemoveUserConfirmation] = useState(false);

    const auth = useAuth();
    const toast = useToast();
    const menu = useMenu();
    const { t } = useTranslation("translation", { keyPrefix: Nodes });
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const filterAssignedUsersToNode = useFilterAssignedUsersToNode(
        selectedNodeIdToViewAssignedUsers
    );
    const filterUsers = useFilterUsers(1, 1000, userSearchText); // TODO: Introduce paginated select

    const assignUserToNode = useAssignUserToNode();
    const dissociateUserFromNode = useDissociateUser();

    useLoader(
        isQueryLoading(filterAssignedUsersToNode) ||
            areMutationsLoading([assignUserToNode, dissociateUserFromNode]),
        AssignedUsersToNodeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.VisualTree);
    }, []);

    const onConfirmUserAssignment = (): void =>
        assignUserToNode.mutate(
            createUserNodeAssociationParameters(
                selectedNodeIdToViewAssignedUsers!,
                assigningUser!.userId
            ),
            {
                onSuccess: () => onUserAssignmentOrRemovalSuccess(true),
                onError: errorResponseToDisplayHandler,
            }
        );

    const onConfirmUserRemoval = (): void =>
        dissociateUserFromNode.mutate(
            createUserNodeAssociationParameters(
                selectedNodeIdToViewAssignedUsers!,
                removingUserId!
            ),
            {
                onSuccess: () => onUserAssignmentOrRemovalSuccess(false),
                onError: errorResponseToDisplayHandler,
            }
        );

    const onUserAssignmentOrRemovalSuccess = async (isAssignment: boolean): Promise<void> => {
        setAssigningUser(null);
        setRemovingUserId(null);
        setUserSearchText(null);
        setShowAssignUserConfirmation(false);
        setShowRemoveUserConfirmation(false);

        if (isAssignment) {
            toast.addToast(
                createSuccessToastProps([t("AssignUserToNodeSuccessMessage", { keyPrefix: UserManagement })])
            );
        } else {
            toast.addToast(
                createSuccessToastProps([
                    t("RemovedUserFromNodeSuccessMessage", { keyPrefix: UserManagement }),
                ])
            );
        }

        queryClient.refetchQueries([
            "filterAssignedUsersToNode",
            selectedNodeIdToViewAssignedUsers!,
        ]);
    };

    const buildAssignConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow(
            t("UserName", {
                keyPrefix: Users,
            }),
            assigningUser?.fullName
        ),
    ];

    const buildDissociateConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow(
            t("UserName", {
                keyPrefix: Users,
            }),
            filterAssignedUsersToNode
                .data!.usersTableData.rows.find((x) => x.metadata === removingUserId)
                ?.columns.map((x) => x.value)[0]
        ),
    ];

    const buildDissociateButton = (): ReactElement<typeof Button> => (
        <DissociateButton onClick={onConfirmUserRemoval} />
    );

    const buildAssignButton = (): ReactElement<typeof Button> => (
        <SbButton
            onClick={onConfirmUserAssignment}
            variant={"primary"}
            type={"button"}
            label={t("Yes", { keyPrefix: Common })}
        />
    );

    const buildNoButton = (): ReactElement<typeof Button> => (
        <SbButton
            onClick={() => {
                setShowAssignUserConfirmation(false);
                setShowRemoveUserConfirmation(false);
            }}
            variant={"secondary"}
            type={"button"}
            label={t("No", { keyPrefix: Common })}
        />
    );

    const buildCancelButton = (): ReactElement<typeof Button> => (
        <CancelButton
            onClick={() => {
                setShowAssignUserConfirmation(false);
                setShowRemoveUserConfirmation(false);
            }}
        />
    );

    return (
        <>
            {selectedNodeIdToViewAssignedUsers != null &&
                isQuerySuccessful(filterAssignedUsersToNode) &&
                !showAssignUserConfirmation &&
                !showRemoveUserConfirmation && (
                    <>
                        {hasRoleTypeInGroup(auth.userRoles, UserRoleGroup.AssignRoles) &&
                            filterAssignedUsersToNode.data!.isNodeUserAssignable && (
                                <Row>
                                    <Col>
                                        <SbSelect
                                            styles={maxContentWidthSelectStyle}
                                            name={"users"}
                                            placeholderText={`${t("SearchForUser", {
                                                keyPrefix: Users,
                                            })}`}
                                            searchable
                                            clearable
                                            items={filterUsers?.data}
                                            loading={
                                                filterUsers.isLoading && filterUsers.isFetching
                                            }
                                            itemLabel={(user: BaseUserDto) =>
                                                `${user.fullName} - ${user.emailAddress}`
                                            }
                                            itemValue={(user: BaseUserDto) =>
                                                user.userId.toString()
                                            }
                                            value={assigningUser}
                                            onSearchTextChanged={(text: string) =>
                                                setUserSearchText(text)
                                            }
                                            onChange={(userOption: SingleValue<BaseUserDto>) =>
                                                setAssigningUser(userOption)
                                            }
                                        />
                                    </Col>
                                    <Col sm="auto">
                                        <CreateIconButton
                                            type={"button"}
                                            label={t("AssignUser", {
                                                keyPrefix: HierarchyTypeTreeView,
                                            })}
                                            onClick={() => {
                                                if (assigningUser == null) {
                                                    // TODO: Error scenario
                                                } else {
                                                    setShowAssignUserConfirmation(true);
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            )}
                        {filterAssignedUsersToNode.data!.usersTableData.rows.length ? (
                            <DataTable
                                columns={nameColumnNames}
                                rows={filterAssignedUsersToNode.data!.usersTableData.rows}
                                deleteItem={(userId: number) => {
                                    setRemovingUserId(userId);

                                    setShowRemoveUserConfirmation(true);
                                }}
                            />
                        ) : (
                            <NoDataStateDiv>
                                {t("NoUsers", {
                                    keyPrefix: HierarchyTypeTreeView,
                                })}
                            </NoDataStateDiv>
                        )}
                    </>
                )}

            {showAssignUserConfirmation && (
                <>
                    <ActionConfirmationFragment
                        panelTitle={t("AreYouSureYouWantToAssignUser", {
                            keyPrefix: HierarchyTypeTreeView,
                        })}
                        rows={buildAssignConfirmationRows()}
                        primaryButton={buildAssignButton()}
                        cancelButton={buildNoButton()}
                        areQueriesSuccessful={isQuerySuccessful(filterAssignedUsersToNode)}
                        isNested={true}
                    />
                </>
            )}

            {showRemoveUserConfirmation && (
                <>
                    <ActionConfirmationFragment
                        panelTitle={t("AreYouSureYouWantToRemoveUser", {
                            keyPrefix: HierarchyTypeTreeView,
                        })}
                        rows={buildDissociateConfirmationRows()}
                        primaryButton={buildDissociateButton()}
                        cancelButton={buildCancelButton()}
                        areQueriesSuccessful={isQuerySuccessful(filterAssignedUsersToNode)}
                        isNested={true}
                    />
                </>
            )}
        </>
    );
};

export default AssignedUsersToNodeContainer;
