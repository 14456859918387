import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { getNotificationTriggerDetails } from "../../../data/services/notification-triggers/notification-triggers-service";
import { NotificationTriggerDto } from "../../dtos/notification-triggers/notification-trigger-dto";

export const useGetNotificationTriggerDetails = (
    notificationTriggerId: number
): UseQueryResult<NotificationTriggerDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getActionItemDynamicFieldDetails", notificationTriggerId],
        () => getNotificationTriggerDetails(url.baseUrl, notificationTriggerId),
        {
            select: NotificationTriggerDto.constructFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};
