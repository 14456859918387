import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import { filterReferentialLinks } from "../../../data/services/referential-link-types/referential-link-service";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { FilterReferentialLinkTypeDto } from "../../dtos/referential-links/filter-referential-link-type-dto";
import { createFilterReferentialLinkTypeRequestFromDto } from "../../requests/referential-link-types/filter-referential-link-type-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { ReferentialLinkResponse } from "../../responses/referential-links/referential-link-response";

export const useFilterReferentialLinks = (
    filterDto: FilterReferentialLinkTypeDto
): UseQueryResult<PaginatedTableDto<number>, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterReferentialLinks", filterDto],
        () =>
            filterReferentialLinks(
                url.baseUrl,
                createFilterReferentialLinkTypeRequestFromDto(filterDto)
            ),
        {
            keepPreviousData: true,
            select: transformToDataTableRows,
            onError: errorResponseToDisplayHandler,
            enabled: filterDto.referentialLinkTypeId != null,
        }
    );
};

const transformToDataTableRows = (
    response: Response<PaginationResponse<ReferentialLinkResponse>>
): PaginatedTableDto<number> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results.map((referentialLink) => ({
        metadata: referentialLink.referentialLinkId,
        columns: [
            {
                value: referentialLink.value,
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
