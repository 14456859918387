import { ExtendActionItemDto } from "../../dtos/action-items/extend-action-item-dto";

export class ExtendActionItemRequest {
    actionItemInstanceId: number;
    comment: string;
    dueDate: Date;

    public constructor(dto: ExtendActionItemDto) {
        this.actionItemInstanceId = dto.actionItemInstanceId;
        this.comment = dto.comment;
        this.dueDate = dto.dueDate;
    }
}
