import { DissociateNodeToActionItemDto } from "../../dtos/actions/dissociate-node-to-action-item-dto";

export class DissociateNodeToActionItemRequest {
    actionItemId: number;
    nodeId: number;

    public constructor(dto: DissociateNodeToActionItemDto) {
        const { actionItemId, nodeId } = dto;

        this.actionItemId = actionItemId;
        this.nodeId = nodeId;
    }
}
