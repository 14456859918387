import { ReactElement, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ChecklistQuestions, Nodes } from "../../../core/constants/translation-namespace";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    maxContentWidthSelectStyle,
    PageHeading,
    SectionVerticalSpace,
    StyledFormLabel,
} from "../../../core/theme/global-styles";
import { convertUtcDateToLocalDate } from "../../../core/utilities/date-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import { BaseUserDto, createBaseUserDto } from "../../../domain/dtos/users/base-user-dto";
import { CreateUserNodeSuspensionDto } from "../../../domain/dtos/users/suspend-user-node-dto";
import { useGetUserDetails } from "../../../domain/viewmodels/hierarchy/dissociate-user-viewmodel";
import { useFilterUsers } from "../../../domain/viewmodels/hierarchy/node-details-viewmodel";
import {
    useCancelSuspendUserFomNode,
    useSuspendUserFomNode,
    useUserNodeSuspensionDetails,
} from "../../../domain/viewmodels/users/suspend-user-from-all-nodes-viewmodel";
import { SbSelect } from "../../atoms/input/SbSelect";
import { SbAlert } from "../../atoms/SbAlert";
import { BackButton, RemoveButton, SaveButton } from "../../atoms/SbButton";
import SbLabelText from "../../atoms/SbLabelText";
import SbFormDateTimeFieldGroup from "../../molecules/input/SbFormDateTimeFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";
import { ConfirmationDetailsProps } from "./UserDetailsContainer";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";

export interface UserNodeSuspensionConfirmationProps extends ConfirmationDetailsProps {
    isSuspended: boolean;
}

const SuspendUserFromAllNodesContainer: React.FC = () => {
    const menu = useMenu();
    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();
    const { t } = useTranslation("translation", { keyPrefix: Nodes });
    const userId = Number(useParams().userId);
    const [userSearchText, setUserSearchText] = useState<string | null>(null);
    const getUserNodeSuspensionDetails = useUserNodeSuspensionDetails(userId);
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const user = useGetUserDetails(
        getUserNodeSuspensionDetails!.data?.secondaryUserId
            ? getUserNodeSuspensionDetails!.data?.secondaryUserId
            : userId
    );

    const userDetails = createBaseUserDto(
        Number(user.data?.userId),
        "",
        "",
        user.data?.emailAddress!,
        user.data?.fullName!,
        ""
    );

    const filterUsers = useFilterUsers(1, 1000, userSearchText);
    const suspendUserNode = useSuspendUserFomNode();
    const cancelUserNodeSuspension = useCancelSuspendUserFomNode();

    useLoader(
        isMutationLoading(suspendUserNode) || isMutationLoading(cancelUserNodeSuspension),
        SuspendUserFromAllNodesContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.VisualTree);
    }, []);

    const handleUserSuspendNode = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        suspendUserNode.mutate(new CreateUserNodeSuspensionDto(userId, formData), {
            onSuccess: onSuspendUserNodeSuccess,
            onError: errorResponseToDisplayHandler
        });
    };

    const handleCancelUserSuspendNode = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        cancelUserNodeSuspension.mutate(getUserNodeSuspensionDetails.data?.userNodeSuspensionId!, {
            onSuccess: onCancelUserNodeSuspensionSuccess,
            onError: errorResponseToDisplayHandler
        });
    };

    const onSuspendUserNodeSuccess = async (): Promise<void> => {
        const params = [
            createNavigateSearchParameter("success", "true"),
            createNavigateSearchParameter("messageKey", "UserNodeSuspendedSuccess"),
        ];

        navigateSearch(`${getPath(AccordionTitles.Users)}/${userId}`, params);
    };

    const onCancelUserNodeSuspensionSuccess = async (): Promise<void> => {
        const params = [
            createNavigateSearchParameter("success", "true"),
            createNavigateSearchParameter("messageKey", "UserNodeSuspensionCancelationSuccess"),
        ];

        navigateSearch(`${getPath(AccordionTitles.Users)}/${userId}`, params);
    };

    const buildWarningAlert = (): ReactElement<HTMLDivElement> =>
        getUserNodeSuspensionDetails!.data?.userNodeSuspensionId ? (
            <SbAlert variant={"warning"} text={t("UserNodeSuspensionCancelationDelay")} />
        ) : (
            <SbAlert variant={"warning"} text={t("UserNodeSuspensionNotification")} />
        );

    return (
        <>
            <PageHeading>{t("SuspendUserFromAllNodes")}</PageHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(getUserNodeSuspensionDetails) && (
                <>
                    {buildWarningAlert()}

                    <TextTitledPanel title={t("Suspend", { keyPrefix: ChecklistQuestions })}>
                        <Form
                            onSubmit={
                                getUserNodeSuspensionDetails!.data?.userNodeSuspensionId
                                    ? handleCancelUserSuspendNode
                                    : handleUserSuspendNode
                            }
                        >
                            <SbFormDateTimeFieldGroup
                                name={"suspensionStartDate"}
                                label={t("SuspendFrom")}
                                required
                                excludeTime
                                disabled={
                                    getUserNodeSuspensionDetails!.data?.suspensionStartDate
                                        ? true
                                        : false
                                }
                                defaultValue={
                                    getUserNodeSuspensionDetails!.data?.suspensionStartDate
                                        ? convertUtcDateToLocalDate(
                                              getUserNodeSuspensionDetails!.data.suspensionStartDate
                                          )
                                        : new Date(new Date().toLocaleDateString())
                                }
                            />

                            <SbFormDateTimeFieldGroup
                                name={"suspensionEndDate"}
                                label={t("SuspendTo")}
                                required
                                excludeTime
                                disabled={
                                    getUserNodeSuspensionDetails!.data?.suspensionEndDate
                                        ? true
                                        : false
                                }
                                defaultValue={
                                    getUserNodeSuspensionDetails!.data?.suspensionEndDate
                                        ? convertUtcDateToLocalDate(
                                              getUserNodeSuspensionDetails!.data.suspensionEndDate
                                          )
                                        : new Date(new Date().toLocaleDateString())
                                }
                            />
                            <Form.Group as={Row} className="mb-3">
                                <StyledFormLabel column sm={2}>
                                    <SbLabelText
                                        label={t("SecondaryUser", { keyPrefix: Nodes })!}
                                    />
                                </StyledFormLabel>

                                <Col sm={4}>
                                    <SbSelect
                                        name={"secondaryUser"}
                                        placeholderText={t("SearchForUser", { keyPrefix: Nodes })!}
                                        searchable
                                        clearable={false}
                                        disabled={
                                            getUserNodeSuspensionDetails!.data?.userNodeSuspensionId
                                                ? true
                                                : false
                                        }
                                        items={filterUsers?.data}
                                        loading={isQueryLoading(getUserNodeSuspensionDetails)}
                                        styles={maxContentWidthSelectStyle}
                                        itemLabel={(option: BaseUserDto) =>
                                            `${option.fullName} - ${option.emailAddress}`
                                        }
                                        itemValue={(option: BaseUserDto) =>
                                            getUserNodeSuspensionDetails!.data?.secondaryUserId
                                                ? getUserNodeSuspensionDetails!.data?.secondaryUserId!.toString()
                                                : option.userId.toString()
                                        }
                                        onSearchTextChanged={(text: string) =>
                                            setUserSearchText(text)
                                        }
                                        defaultSelectedItem={
                                            getUserNodeSuspensionDetails!.data?.secondaryUserId
                                                ? userDetails
                                                : null
                                        }
                                    />
                                </Col>
                            </Form.Group>
                            <EndAlignedDiv>
                                {getUserNodeSuspensionDetails.data?.userNodeSuspensionId ? (
                                    <RemoveButton type={"submit"} />
                                ) : (
                                    <SaveButton
                                        type={"submit"}
                                        label={`${t("Suspend", { keyPrefix: ChecklistQuestions })}`}
                                    />
                                )}
                                <BackButton
                                    onClick={() =>
                                        navigate(`${getPath(AccordionTitles.Users)}/${userId}`)
                                    }
                                />
                            </EndAlignedDiv>
                        </Form>
                    </TextTitledPanel>
                </>
            )}
        </>
    );
};

export default SuspendUserFromAllNodesContainer;
