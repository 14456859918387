import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import { Common } from "../../../core/constants/translation-namespace";
import { useAuth } from "../../../core/store/auth-context";
import {
    ContentContainer,
    EndAlignedDiv,
    SectionContainer,
} from "../../../core/theme/global-styles";
import { ReviewRatingOptions } from "../../../core/utilities/ReviewReportValues";
import { ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import { createSearchDto, SearchDto } from "../../../domain/dtos/common/search-dto";
import { NodeTypeValueDto } from "../../../domain/dtos/hierarchy/node-type-value-dto";
import ReviewRating from "../../../domain/enums/review-reports/review-ratings";
import { hasRoleTypeInGroup, MonitoringRoles } from "../../../domain/enums/Roles";
import { ResetButton, SearchButton } from "../../atoms/SbButton";
import { FilterInput } from "../../molecules/FilterInput";
import { FilterSelect } from "../../molecules/FilterSelect";

export const ReviewReportHistoryFilter: React.FC<{
    reportSetName: string | null;
    changeReportSetName: (reportSetName: string) => void;
    reviewRating: ReactSelectDto<ReviewRating>;
    changeReviewRating: (item: SingleValue<ReactSelectDto<ReviewRating>>) => void;
    startDate: string | null;
    changeStartDate: (startDate: string) => void;
    endDate: string | null;
    changeEndDate: (endDate: string) => void;
    search: () => void;
    resetFilter: () => void;
    changeCountry: (item: SingleValue<NodeTypeValueDto>) => void;
    country: NodeTypeValueDto | null;
    countries: NodeTypeValueDto[] | null;
    setCountrySearchTextChanged: React.Dispatch<React.SetStateAction<SearchDto>>;
}> = ({
    reportSetName,
    changeReportSetName,
    reviewRating,
    changeReviewRating,
    startDate,
    changeStartDate,
    endDate,
    changeEndDate,
    search,
    resetFilter,
    changeCountry,
    country,
    countries,
    setCountrySearchTextChanged,
}) => {
    const { t } = useTranslation("translation");
    const auth = useAuth();

    return (
        <ContentContainer>
            <SectionContainer>
                <Row>
                    <Col>
                        <FilterInput
                            title={t("Name", { keyPrefix: Common })}
                            name={"reportSetName"}
                            type={"text"}
                            onChangeEventHandler={changeReportSetName}
                            value={reportSetName ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterSelect
                            name={"reviewRating"}
                            label={t("Rating", { keyPrefix: Common })}
                            placeholderText={t("All", { keyPrefix: Common })}
                            searchable
                            clearable={false}
                            items={ReviewRatingOptions}
                            itemDisplayText={(option: ReactSelectDto<ReviewRating>) => option.label}
                            onChange={changeReviewRating}
                            value={reviewRating}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("StartDate", { keyPrefix: Common })}
                            name={"startDate"}
                            type={"date"}
                            onChangeEventHandler={changeStartDate}
                            value={startDate ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("EndDate", { keyPrefix: Common })}
                            name={"endDate"}
                            type={"date"}
                            onChangeEventHandler={changeEndDate}
                            value={endDate ?? ""}
                        />
                    </Col>
                    {hasRoleTypeInGroup(
                        auth.userRoles,
                        MonitoringRoles.ElevatedMonitoringRoles
                    ) && (
                        <Col>
                            <FilterSelect
                                name={"country"}
                                label={t("Country", { keyPrefix: Common })}
                                placeholderText={t("All", { keyPrefix: Common })}
                                searchable
                                clearable={false}
                                items={countries ?? []}
                                itemDisplayText={(option: NodeTypeValueDto) => option.value}
                                onChange={changeCountry}
                                value={country}
                                onSearchTextChanged={(text: string) =>
                                    setCountrySearchTextChanged(createSearchDto(text, null))
                                }
                            />
                        </Col>
                    )}
                </Row>
            </SectionContainer>
            <EndAlignedDiv>
                <SearchButton onClick={search} />
                <ResetButton onClick={resetFilter} />
            </EndAlignedDiv>
        </ContentContainer>
    );
};
