import { keyframes } from "styled-components";

export const openDrawer = keyframes`
  from {
    width: 100px;
  }

  to {
    width: 200px;
  }
`;

export const closeDrawer = keyframes`
from {
  width: 200px;
}

to {
  width: 100px;
}
`;
