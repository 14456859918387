export interface BaseFilterNodeHierarchyRequest {
    depth: number | null;
}

const SearchParameters = {
    depth: "depth",
};

export const buildBaseFilterNodeHierarchySearchParameters = (
    request: BaseFilterNodeHierarchyRequest
): URLSearchParams => {
    const searchParams = new URLSearchParams();

    if (request.depth) {
        searchParams.set(SearchParameters.depth, request.depth.toString());
    }

    return searchParams;
};
