export default class LinkableNodesRequest {
    nodeId: number;
    nodeTypeId: number | null;

    public constructor(nodeId: number, nodeTypeId: number | null) {
        this.nodeId = nodeId;
        this.nodeTypeId = nodeTypeId;
    }

    public buildSearchParameters(): URLSearchParams {
        const searchParams = new URLSearchParams();

        searchParams.set("nodeId", this.nodeId.toString());
        if (this.nodeTypeId) {
            searchParams.set("nodeTypeId", this.nodeTypeId.toString());
        }

        return searchParams;
    }
}
