import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { StyledVariantText } from "../../../core/theme/global-styles";
import IndicatorAnswerDto from "../../../domain/dtos/answer-capture/indicator-answer-dto";
import QuestionAnswerDetailsDto from "../../../domain/dtos/answer-capture/question-answer-details-dto";
import QuestionAnswerInstanceDto from "../../../domain/dtos/answer-capture/question-answer-instance-dto";
import AnswerTypeDto from "../../../domain/dtos/answer-types/answer-type-dto";
import PredefinedAnswerDto from "../../../domain/dtos/predefined-answers/predefined-answer-dto";
import AnswerFlagMode from "../../../domain/enums/answer-types/answer-flag-mode";
import { useAnswerCapture } from "../../../domain/stores/answer-capture-context";
import {
    AddCommentButton,
    ReviewQuestionButton,
    TriggerActionItemButton,
    ViewQuestionDetailsButton,
} from "../../atoms/SbButton";
import QuestionAnswerDetails from "./question-answer-details";
import QuestionCaptureComment from "./question-capture-comment";
import QuestionCaptureInput from "./question-capture-input";
import QuestionCaptureReviewComment from "./question-capture-review-comment";
import QuestionCaptureTriggerActionItem from "./question-capture-trigger-action-item";

const StyledQuestionDetailsDiv = styled.div`
    display: flex;
    margin-bottom: ${(props) => props.theme.padding.lg};
`;

const StyledQuestionTextDiv = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: center;
    flex-direction: column;
`;

const StyledAnswersDiv = styled.div`
    display: flex;
    white-space: nowrap;
    margin-bottom: ${(props) => props.theme.padding.sm};
`;

const StyledButtonActionsDiv = styled.div`
    display: flex;
    justify-content: space-between;
    column-gap: ${(props) => props.theme.padding.sm};
    white-space: nowrap;
`;

const AnswerCaptureDetailsAndActions: React.FC<{
    questionText: string;
    isNestedQuestion: boolean;
    canTriggerActionItem: boolean;
    questionAnswerInstanceDto: QuestionAnswerInstanceDto;
    answerTypeDto: AnswerTypeDto;
    isOverdue: boolean;
    answerFlagMode: AnswerFlagMode | null;
    questionAnswerDetailsDto?: QuestionAnswerDetailsDto;
    viewDetailsClicked?: () => void;
    onMutuallyExclusiveAnswerClicked: (_: PredefinedAnswerDto) => void;
    onIndicatorAnswerChanged: <TType>(_: IndicatorAnswerDto<TType>) => void;
    children: React.ReactNode;
}> = ({
    questionText,
    isNestedQuestion,
    canTriggerActionItem,
    questionAnswerInstanceDto,
    answerTypeDto,
    isOverdue,
    answerFlagMode,
    questionAnswerDetailsDto,
    viewDetailsClicked,
    onMutuallyExclusiveAnswerClicked,
    onIndicatorAnswerChanged,
    children,
}) => {
    const [triggerActionItem, setTriggerActionItem] = useState<boolean>(false);
    const [showViewDetails, setShowViewDetails] = useState<boolean>(false);
    const [showReviewComment, setShowReviewComment] = useState<boolean>(false);
    const [addComment, setAddComment] = useState<boolean>(false);

    //This button will be disabled until its functionality is enhanced
    const isReviewQuestionsButtonEnabled = false;

    const answerCapture = useAnswerCapture();

    useEffect(() => {
        setShowReviewComment(!answerCapture.createdQuestionReviewCommentSuccessfully);
    }, [answerCapture.createdQuestionReviewCommentSuccessfully]);

    const onTriggerActionItemClicked = (): void => setTriggerActionItem(!triggerActionItem);

    const onViewDetailsClicked = (): void => {
        setShowViewDetails(!showViewDetails);

        viewDetailsClicked && viewDetailsClicked();
    };

    const onReviewCommentClicked = (): void => setShowReviewComment(!showReviewComment);

    const onAddCommentClicked = (): void => setAddComment(!showAddComment);

    const isAnswerUndesirable = answerFlagMode === AnswerFlagMode.Undesirable;

    const addCommentRequired = isOverdue || isAnswerUndesirable;
    const showAddComment = addCommentRequired || addComment;
    const actionItemRequired = canTriggerActionItem && isAnswerUndesirable;
    const showTriggerActionItem = actionItemRequired || triggerActionItem;

    return (
        <>
            <StyledQuestionDetailsDiv>
                <StyledQuestionTextDiv>
                    <StyledVariantText $variant="secondary">{questionText}</StyledVariantText>
                </StyledQuestionTextDiv>
                <div>
                    <StyledAnswersDiv>
                        <QuestionCaptureInput
                            answerTypeDto={answerTypeDto}
                            questionAnswerInstanceDto={questionAnswerInstanceDto}
                            onMutuallyExclusiveAnswerClicked={onMutuallyExclusiveAnswerClicked}
                            onIndicatorAnswerChanged={onIndicatorAnswerChanged}
                        />
                    </StyledAnswersDiv>

                    <StyledButtonActionsDiv>
                        {canTriggerActionItem && (
                            <TriggerActionItemButton onClick={onTriggerActionItemClicked} />
                        )}

                        {!isNestedQuestion && (
                            <ViewQuestionDetailsButton onClick={onViewDetailsClicked} />
                        )}

                        {isReviewQuestionsButtonEnabled && (
                            <ReviewQuestionButton onClick={onReviewCommentClicked} />
                        )}

                        <AddCommentButton onClick={onAddCommentClicked} />
                    </StyledButtonActionsDiv>
                </div>
            </StyledQuestionDetailsDiv>

            {children}

            {showTriggerActionItem && (
                <QuestionCaptureTriggerActionItem
                    questionAnswerInstanceDto={questionAnswerInstanceDto}
                    requiredByParent={actionItemRequired}
                    onCancelClicked={() => setTriggerActionItem(false)}
                />
            )}

            {showViewDetails && (
                <QuestionAnswerDetails questionAnswerDetailsDto={questionAnswerDetailsDto} />
            )}

            {showReviewComment && (
                <QuestionCaptureReviewComment
                    questionAnswerInstanceDto={questionAnswerInstanceDto}
                    onCancelClicked={() => setShowReviewComment(false)}
                />
            )}

            {showAddComment && (
                <QuestionCaptureComment
                    questionAnswerInstanceDto={questionAnswerInstanceDto}
                    required={addCommentRequired}
                />
            )}
        </>
    );
};

export default AnswerCaptureDetailsAndActions;
