import { ActionInstanceOnHoldStatus } from "../../enums/action-items/ActionInstanceOnHoldStatus";
import RaciRelationship from "../../enums/action-items/RaciRelationship";
import { ActionInstanceStatusDto } from "../actions/action-instance-status-dto";
import {
    defaultFilterActionItemsDto,
    FilterActionItemDto,
} from "../actions/filter-action-items-dto";
import { ReactSelectDto } from "../common/react-select-dto";

export interface FilterMyActionItemsDto extends FilterActionItemDto {
    actionInstanceOnHoldStatus: ReactSelectDto<ActionInstanceOnHoldStatus>;
    raciRelationship: ReactSelectDto<RaciRelationship>;
    actionItemInstanceId: number | null;
}

export const createFilterMyActionItemsDto = (
    actionInstanceOnHoldStatus: ReactSelectDto<ActionInstanceOnHoldStatus>,
    raciRelationship: ReactSelectDto<RaciRelationship>,
    actionItemInstanceId: number | null,
    filterActionItemsDto: FilterActionItemDto
): FilterMyActionItemsDto => ({
    actionInstanceOnHoldStatus: actionInstanceOnHoldStatus,
    raciRelationship: raciRelationship,
    actionItemInstanceId: actionItemInstanceId,
    ...filterActionItemsDto,
});

export const urlPopulatedFilterMyActionItemsDto = (
    actionInstanceStatus: ActionInstanceStatusDto,
    actionInstanceOnHoldStatus: ReactSelectDto<ActionInstanceOnHoldStatus>,
    raciRelationship: ReactSelectDto<RaciRelationship>,
    actionItemInstanceId: number | null
): FilterMyActionItemsDto => {
    let filterActionItemDto = defaultFilterActionItemsDto;
    filterActionItemDto.actionInstanceStatusId = actionInstanceStatus.actionInstanceStatusId;
    return createFilterMyActionItemsDto(
        actionInstanceOnHoldStatus,
        raciRelationship,
        actionItemInstanceId,
        filterActionItemDto
    );
};
