import { HTTPError } from "ky";
import { ReactElement, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { SuspendNodeEvent } from "../../../core/constants/application-insights-events";
import {
    ChecklistQuestions,
    Common,
    Hierarchy,
    Nodes,
    NodeTypeValues,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import {
    createSuspendParams,
    useGetNodeDetails,
    useSuspendNode,
} from "../../../domain/viewmodels/hierarchy/suspend-node-viewmodel";
import { SbAlert } from "../../atoms/SbAlert";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import SbFormCheckFieldGroup from "../../molecules/input/SbFormCheckFieldGroup";
import SbFormDateTimeFieldGroup from "../../molecules/input/SbFormDateTimeFieldGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const SuspendNodeContainer: React.FC = () => {
    const menu = useMenu();
    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: Nodes });
    const nodeId = Number(useParams().nodeId);

    const getNodeDetails = useGetNodeDetails(nodeId);
    const suspendNode = useSuspendNode();

    const nodeDetailsResponseData = getNodeDetails.data?.node;

    useLoader(
        isQueryLoading(getNodeDetails) || isMutationLoading(suspendNode),
        SuspendNodeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.VisualTree);
    }, []);

    const handleSuspendNode = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);

        suspendNode.mutate(createSuspendParams(nodeId, formData), {
            onSuccess: onSuspendNodeSuccess,
            onError: (error: HTTPError) => {
                trackAppInsightsException(
                    auth.email,
                    window.location.href,
                    SuspendNodeEvent,
                    error
                );
                errorResponseToDisplayHandler(error);
            },
        });
    };

    const onSuspendNodeSuccess = async (): Promise<void> => {
        trackAppInsightsEvent(auth.email, window.location.href, SuspendNodeEvent);

        const params = [
            createNavigateSearchParameter("success", "true"),
            createNavigateSearchParameter("messageKey", "NodeSuspendedSuccess"),
        ];

        navigateSearch(`${getPath(AccordionTitles.VisualTree)}/${nodeId}`, params);
    };

    const buildWarningAlert = (): ReactElement<HTMLDivElement> => (
        <SbAlert variant={"warning"} text={t("SuspendNodeConfirmation")} />
    );

    return (
        <>
            <PageHeading>{t("SuspendNode")}</PageHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(getNodeDetails) && (
                <>
                    {buildWarningAlert()}

                    <TextTitledPanel title={t("Suspend", { keyPrefix: ChecklistQuestions })}>
                        <Form onSubmit={handleSuspendNode}>
                            <SbFormTextFieldGroup
                                name={"node"}
                                label={t("Node")}
                                type={"text"}
                                required
                                disabled
                                value={nodeDetailsResponseData!.nodeId.toString()}
                            />

                            <SbFormTextFieldGroup
                                name={"hierarchyLevel"}
                                label={t("HierarchyLevel", { keyPrefix: Hierarchy })}
                                type={"text"}
                                required
                                disabled
                                value={nodeDetailsResponseData!.nodeType.name}
                            />

                            <SbFormTextFieldGroup
                                name={"hierarchyLevelValue"}
                                label={t("NodeTypeValue", { keyPrefix: NodeTypeValues })}
                                type={"text"}
                                required
                                disabled
                                value={nodeDetailsResponseData!.nodeTypeValue?.value}
                            />

                            <SbFormCheckFieldGroup
                                fieldLabel={t("IsTrainingDisplay", { keyPrefix: Common })}
                                type={"checkbox"}
                                values={[
                                    {
                                        name: "isTraining",
                                        label: undefined,
                                        disabled: true,
                                    },
                                ]}
                            />

                            <SbFormCheckFieldGroup
                                fieldLabel={t("IsSuspended")}
                                type={"checkbox"}
                                values={[
                                    {
                                        name: "isSuspended",
                                        label: undefined,
                                        disabled: true,
                                    },
                                ]}
                            />

                            <SbFormDateTimeFieldGroup
                                name={"suspendFrom"}
                                label={t("SuspendFrom")}
                            />

                            <SbFormDateTimeFieldGroup name={"suspendTo"} label={t("SuspendTo")} />

                            <EndAlignedDiv>
                                <SaveButton
                                    type={"submit"}
                                    label={`${t("Suspend", { keyPrefix: ChecklistQuestions })}`}
                                />
                                <CancelButton onClick={() => navigate(-1)} />
                            </EndAlignedDiv>
                        </Form>
                    </TextTitledPanel>
                </>
            )}
        </>
    );
};

export default SuspendNodeContainer;
