import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    getReviewReportSetDetails,
    unpublishReviewReportSet,
} from "../../../data/services/review-reports/review-reports-service";
import { Response } from "../../../domain/responses/common/response-response";
import { ReviewReportSetDto } from "../../dtos/review-reports/review-report-set-dto";

export const useGetReviewReportSetDetails = (
    reviewReportSetId: number
): UseQueryResult<ReviewReportSetDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getReviewReportSetDetails", reviewReportSetId],
        () => getReviewReportSetDetails(url.baseUrl, reviewReportSetId),
        {
            keepPreviousData: true,
            select: ReviewReportSetDto.constructFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useUnpublishReviewReportSet = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    number
> => {
    const url = useUrl();

    return useMutation((mutationKey: number) => {
        return unpublishReviewReportSet(url.baseUrl, mutationKey);
    });
};
