import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    editNotificationTrigger,
    getNotificationTriggerDetails,
} from "../../../data/services/notification-triggers/notification-triggers-service";
import { EditNotificationTriggerDto } from "../../dtos/notification-triggers/edit-notification-trigger-dto";
import { NotificationTriggerDto } from "../../dtos/notification-triggers/notification-trigger-dto";
import { EditNotificationTriggerRequest } from "../../requests/notification-triggers/edit-notification-trigger-request";
import { Response } from "../../responses/common/response-response";

export const useGetNotificationTriggerDetails = (
    notificationTriggerId: number
): UseQueryResult<NotificationTriggerDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getActionItemDynamicFieldDetails", notificationTriggerId],
        () => getNotificationTriggerDetails(url.baseUrl, notificationTriggerId),
        {
            select: NotificationTriggerDto.constructFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useEditNotificationTrigger = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    EditNotificationTriggerDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: EditNotificationTriggerDto) => {
        const {
            notificationTriggerId,
            triggeringDate,
            triggerTimeDeltaIntervalPeriodType,
            sendWindowIntervalPeriodType,
            formData,
        } = mutationKey;

        const request = new EditNotificationTriggerRequest(
            notificationTriggerId,
            formData.get("name") as string,
            formData.get("triggerBefore") === "on",
            triggeringDate,
            Number(formData.get("triggerTimeDeltaIntervalValue")),
            triggerTimeDeltaIntervalPeriodType,
            Number(formData.get("sendWindowIntervalValue")),
            sendWindowIntervalPeriodType
        );

        return editNotificationTrigger(url.baseUrl, request);
    });
};
