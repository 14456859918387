import { BaseQuestionSetResponse } from "../../responses/question-sets/base-question-set-response";

export default class BaseQuestionSetDto {
    questionSetId: number;
    name: string;
    description: string;
    process: string;

    public constructor(response: BaseQuestionSetResponse) {
        const { questionSetId, name, description, process } = response;

        this.questionSetId = questionSetId;
        this.name = name;
        this.description = description;
        this.process = process;
    }
}
