import { AssociateRaciParticipantDto } from "../../dtos/actions/associate-raci-participant-dto";
import RaciRelationship from "../../enums/action-items/RaciRelationship";

export class AssociateRaciParticipantRequest {
    userId: number;
    actionItemId: number;
    raciRelationship: RaciRelationship;

    public constructor(dto: AssociateRaciParticipantDto) {
        const { userId, actionItemId, raciRelationship } = dto;

        this.userId = userId;
        this.actionItemId = actionItemId;
        this.raciRelationship = raciRelationship;
    }
}
