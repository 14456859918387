import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EnterKey } from "../../../core/constants/KeyboardKeys";
import { ChecklistQuestions, Common } from "../../../core/constants/translation-namespace";
import useKeyPress from "../../../core/hooks/keyPress";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { questionSetColumnNames } from "../../../core/utilities/dataTableColumns";
import { questionSetColumnToProperty } from "../../../core/utilities/dataTableColumnToProperty";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import {
    defaultFilterQuestionSetDto,
    FilterQuestionSetDto,
} from "../../../domain/dtos/question-sets/filter-question-set-dto";
import { hasRoleTypeInGroup, QuestionSetRoleGroup } from "../../../domain/enums/Roles";
import { useGetQuestionSets } from "../../../domain/viewmodels/question-set/question-set-viewmodel";
import { CreateLink } from "../../atoms/SbLink";
import { DataTable } from "../../organisms/DataTable";
import { QuestionSetsFilter } from "../../organisms/filters/QuestionSetsFilter";

interface SearchParams {
    questionSetName: string | null;
    description: string | null;
    questionText: string | null;
    processName: string | null;
    questionSetId: number | null;
}

const createSearchParams = (
    questionSetName: string | null,
    description: string | null,
    questionText: string | null,
    processName: string | null,
    questionSetId: number | null
): SearchParams => ({
    questionSetName: questionSetName,
    description: description,
    questionText: questionText,
    processName: processName,
    questionSetId: questionSetId,
});

const defaultSearchParams: SearchParams = createSearchParams(null, null, null, null, null);

const QuestionSetsContainer: React.FC = () => {
    const [searchParams, setSearchParams] = useState<SearchParams>(defaultSearchParams);
    const [filterDto, setFilterDto] = useState<FilterQuestionSetDto>(defaultFilterQuestionSetDto);

    const menu = useMenu();
    const toast = useToast();
    const navigate = useNavigate();
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });
    const auth = useAuth();

    const getQuestionSets = useGetQuestionSets(filterDto);

    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";

    useLoader(isQueryLoading(getQuestionSets), QuestionSetsContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.QuestionSets);

        if (success) {
            toast.addToast(createSuccessToastProps([t(messageKey)]));

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    const sortBy = (column: string): void => {
        setFilterDto({
            ...filterDto,
            sortByColumn: questionSetColumnToProperty[column],
            sortByDescending: !filterDto.sortByDescending,
        });
    };

    const updateQuestionSetNameFilterValue = (questionSetName: string): void => {
        setSearchParams({
            ...searchParams,
            questionSetName: questionSetName !== "" ? questionSetName : null,
        });
    };

    const updateQuestionSetDescriptionFilterValue = (description: string): void => {
        setSearchParams({
            ...searchParams,
            description: description !== "" ? description : null,
        });
    };

    const updateQuestionSetQuestionTextFilterValue = (questionText: string): void => {
        setSearchParams({
            ...searchParams,
            questionText: questionText !== "" ? questionText : null,
        });
    };

    const updateQuestionSetProcessNameFilterValue = (processName: string): void => {
        setSearchParams({
            ...searchParams,
            processName: processName !== "" ? processName : null,
        });
    };

    const search = (): void => {
        setFilterDto({ ...filterDto, pageNumber: 1, ...searchParams });
    };

    const resetFilter = (): void => {
        setSearchParams(defaultSearchParams);
        setFilterDto({ ...filterDto, ...defaultSearchParams });
    };

    const navigateToViewQuestionSetDetails = (id: number): void =>
        navigate(`${getPath(AccordionTitles.QuestionSets)}/${id}`);

    const navigateToEditQuestionSet = (id: number): void =>
        navigate(`${getPath(AccordionTitles.QuestionSets)}/${id}/edit`);

    const deleteQuestionSetItem = (id: number): void => {
        navigate(`${getPath(AccordionTitles.QuestionSets)}/${id}/delete`);
    };

    useKeyPress(EnterKey, search, searchParams);

    return (
        <>
            <PageHeading>{t("QuestionSetTitle")}</PageHeading>
            <SectionVerticalSpace />

            <QuestionSetsFilter
                questionSetName={searchParams.questionSetName}
                questionSetNameFilterValue={updateQuestionSetNameFilterValue}
                description={searchParams.description}
                descriptionFilterValue={updateQuestionSetDescriptionFilterValue}
                questionText={searchParams.questionText}
                questionTextFilterValue={updateQuestionSetQuestionTextFilterValue}
                processName={searchParams.processName}
                processNameFilterValue={updateQuestionSetProcessNameFilterValue}
                search={search}
                resetFilter={resetFilter}
            />
            <SectionVerticalSpace />

            <EndAlignedDiv>
                <CreateLink
                    label={t("CreateNew", { keyPrefix: Common })}
                    navigateTo={`${getPath(AccordionTitles.QuestionSets)}/create`}
                />
            </EndAlignedDiv>
            <SectionVerticalSpace />
            {isQuerySuccessful(getQuestionSets) && (
                <DataTable
                    columns={questionSetColumnNames}
                    rows={getQuestionSets.data!.rows}
                    keyPrefix={ChecklistQuestions}
                    viewItem={navigateToViewQuestionSetDetails}
                    editItem={
                        hasRoleTypeInGroup(auth.userRoles, QuestionSetRoleGroup.WriteRoles)
                            ? navigateToEditQuestionSet
                            : undefined
                    }
                    deleteItem={
                        hasRoleTypeInGroup(auth.userRoles, QuestionSetRoleGroup.WriteRoles)
                            ? deleteQuestionSetItem
                            : undefined
                    }
                    linkItem={navigateToViewQuestionSetDetails}
                    sortBy={sortBy}
                    totalItems={getQuestionSets.data!.recordCount}
                    paginationDto={filterDto}
                    setPaginationDto={setFilterDto}
                />
            )}
        </>
    );
};

export default QuestionSetsContainer;
