import { SearchRequest } from "./search-request";

export interface HierarchySearchRequest extends SearchRequest {
    hierarchyTypeId: number;
}

export const createHierarchySearchRequest = (
    hierarchyTypeId: number,
    searchText: string | null,
    maxSearchResults: number | null
): HierarchySearchRequest => ({
    hierarchyTypeId,
    searchText,
    maxSearchResults,
});

const SearchParameters = {
    hierarchyTypeId: "hierarchyTypeId",
    searchText: "searchText",
    maxSearchResults: "maxSearchResults",
};

export const buildHierarchySearchRequestSearchParameters = (
    request: HierarchySearchRequest
): URLSearchParams => {
    const searchParams = new URLSearchParams();

    searchParams.set(SearchParameters.hierarchyTypeId, request.hierarchyTypeId.toString());

    if (request.searchText) {
        searchParams.set(SearchParameters.searchText, request.searchText);
    }

    if (request.maxSearchResults) {
        searchParams.set(SearchParameters.maxSearchResults, request.maxSearchResults.toString());
    }

    return searchParams;
};
