import { HTTPError } from "ky";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { EditQuestionEvent } from "../../../core/constants/application-insights-events";
import {
    ChecklistAnswers,
    ChecklistQuestions,
    Common,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { getEnumsForType } from "../../../core/utilities/enum-helper";
import {
    AccordionTitles,
    AnswerThresholdIndicatorEnum,
    DrawerTitles,
    NavbarTitles,
} from "../../../core/utilities/enums";
import {
    areQueriesLoading,
    isMutationLoading,
    isQueryLoading,
} from "../../../core/utilities/responseStateHelper";
import { AggregationTypeDto } from "../../../domain/dtos/aggregation-type/aggregation-type-dto";
import { AnswerThresholdOperatorsDto } from "../../../domain/dtos/answer-threshold-operators/answer-threshold-operators-dto";
import AnswerTypeDto from "../../../domain/dtos/answer-types/answer-type-dto";
import { createReactSelectDto, ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import {
    AnswerTypeSearchDto,
    createAnswerTypeSearchDto,
    defaultAnswerTypeSearchDto,
    defaultSearchDto,
    SearchDto,
} from "../../../domain/dtos/common/search-dto";
import { QuestionCategoryDto } from "../../../domain/dtos/question-categories/question-category-dto";
import { QuestionTypeDto } from "../../../domain/dtos/question-type/question-type-dto";
import AnswerFlagMode from "../../../domain/enums/answer-types/answer-flag-mode";
import { ARMonitoringDefaults } from "../../../domain/enums/questions/ar-monitoring-defaults";
import QuestionImportance from "../../../domain/enums/questions/question-importance";
import { QuestionTypes } from "../../../domain/enums/questions/question-types";
import { useEditQuestion } from "../../../domain/viewmodels/questions/edit-question-viewmodel";
import {
    useARMonitoringLookupAnswerTypes,
    useGetQuestionDropdownValues,
    useLookupAnswerTypes,
    useLookupQuestionCategories,
} from "../../../domain/viewmodels/questions/view-question-dropdown-values-viewmodel";
import { useGetQuestionDetails } from "../../../domain/viewmodels/questions/view-question-viewmodel";
import { SbAlert } from "../../atoms/SbAlert";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import { translateText } from "../../helpers/translate";
import SbFormCheckFieldGroup from "../../molecules/input/SbFormCheckFieldGroup";
import SbFormNumberBoxGroup from "../../molecules/input/SbFormNumberBoxGroup";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import SbFormTextAreaGroup from "../../molecules/input/SbFormTextAreaGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const questionImportanceReactSelectDtos = getEnumsForType(QuestionImportance).map((x) =>
    createReactSelectDto(x, QuestionImportance[x])
);

const EditQuestionContainer: React.FC = () => {
    const questionId = Number(useParams().questionId);

    const [questionTypeId, setQuestionTypeId] = useState<number>();
    const [questionCategoryId, setQuestionCategoryId] = useState<number>();
    const [questionImportanceId, setQuestionImportanceId] = useState<number>();
    const [answerTypeId, setAnswerTypeId] = useState<number>();
    const [aggregationTypeId, setAggregationTypeId] = useState<number>();
    const [redAnswerThresholdValueId, setRedAnswerThresholdValueId] = useState<number | null>();
    const [redAnswerThresholdOperatorId, setRedAnswerThresholdOperatorId] = useState<number>();
    const [amberAnswerThresholdValueId, setAmberAnswerThresholdValueId] = useState<number | null>();
    const [amberAnswerThresholdOperatorId, setAmberAnswerThresholdOperatorId] = useState<number>();
    const [desirableAnswer, setDesirableAnswer] = useState<string | null>();
    const [undesirableAnswer, setUndesirableAnswer] = useState<string | null>();
    const [neutralAnswer, setNeutralAnswer] = useState<string | null>();
    const [answerTypeName, setAnswerTypeName] = useState("");
    const [aggregate, setAggregate] = useState(false);
    const [questionWeightings, setQuestionWeightings] = useState(false);
    const [answerTypeSearchDto, setAnswerTypeSearchDto] = useState<AnswerTypeSearchDto>(
        defaultAnswerTypeSearchDto
    );
    const arMonitoringAnswerTypes = useARMonitoringLookupAnswerTypes(
        createAnswerTypeSearchDto(ARMonitoringDefaults.Monitoring, QuestionTypes.Control, null)
    );

    const menu = useMenu();
    const auth = useAuth();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const lookupAnswerTypes = useLookupAnswerTypes(answerTypeSearchDto);
    const lookupAnswerTypesData = lookupAnswerTypes.data!;
    const arMonitoringAnswerTypesData = arMonitoringAnswerTypes.data!;

    const questionDetails = useGetQuestionDetails(questionId);

    const [searchDto, setSearchDto] = useState<SearchDto>(defaultSearchDto);
    const lookupQuestionCategories = useLookupQuestionCategories(searchDto);
    const lookupQuestionCategoriesData = lookupQuestionCategories.data!;

    const editQuestion = useEditQuestion();
    const getQuestionDropdownValues = useGetQuestionDropdownValues();

    const questionTypes = getQuestionDropdownValues[0].data!;
    const aggregationTypes = getQuestionDropdownValues[2].data!;
    const answerThresholdOperators = getQuestionDropdownValues[3].data!;

    const isARMonitoring = questionDetails.data?.isARMonitoring;

    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    useLoader(
        isQueryLoading(questionDetails) || isMutationLoading(editQuestion),
        EditQuestionContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Questions);
    }, []);

    useEffect(() => {
        if (questionDetails.status === "success" && questionDetails.fetchStatus === "idle") {
            const initialQuestionTypeId = questionDetails.data!.questionType.questionTypeId;

            onQuestionTypeSelected(questionDetails.data!.questionType);
            onQuestionCategorySelected(questionDetails.data!.questionCategory);
            setQuestionImportanceId(questionDetails.data!.questionImportance);
            onAnswerTypeSelected(questionDetails.data!.answerTypeDto);

            setAnswerTypeSearchDto(
                createAnswerTypeSearchDto(
                    questionDetails.data!.answerTypeDto.name,
                    questionDetails.data!.questionTypeId,
                    null
                )
            );

            if (initialQuestionTypeId === QuestionTypes.Control) {
                setQuestionWeightings(questionDetails.data!.questionWeightings.length > 0);
            }

            if (initialQuestionTypeId === QuestionTypes.Indicator) {
                setAggregate(questionDetails.data!.isAggregate);
                onAggregationTypeSelected(questionDetails.data!.aggregationType);

                const defaultThresholdOperatorId = 1;

                const redResponse = questionDetails.data!.answerThresholdValueResponses.find(
                    (response) =>
                        response.answerThresholdIndicatorId == AnswerThresholdIndicatorEnum.Red
                );
                setRedAnswerThresholdValueId(
                    redResponse ? redResponse.answerThresholdValueId : null
                );
                setRedAnswerThresholdOperatorId(
                    redResponse ? redResponse.answerThresholdOperatorId : defaultThresholdOperatorId
                );

                const amberResponse = questionDetails.data!.answerThresholdValueResponses.find(
                    (response) =>
                        response.answerThresholdIndicatorId == AnswerThresholdIndicatorEnum.Amber
                );
                setAmberAnswerThresholdValueId(
                    amberResponse ? amberResponse.answerThresholdValueId : null
                );
                setAmberAnswerThresholdOperatorId(
                    amberResponse
                        ? amberResponse.answerThresholdOperatorId
                        : defaultThresholdOperatorId
                );
            }
        }
    }, [questionDetails.fetchStatus]);

    const arMonitoringCheckValues = [
        {
            name: "arMonitoring",
            label: "",
            disabled: true,
            defaultSelected: isARMonitoring ? true : false,
        },
    ];

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        editQuestion.mutate(
            {
                questionId: questionId,
                questionTypeId: questionTypeId!,
                answerTypeId: answerTypeId!,
                answerTypeName: answerTypeName,
                questionCategoryId: questionCategoryId!,
                questionImportanceId: questionImportanceId!,
                aggregationTypeId: aggregationTypeId || 1,
                redAnswerThresholdValueId: redAnswerThresholdValueId || null,
                redAnswerThresholdOperatorId: redAnswerThresholdOperatorId || null,
                amberAnswerThresholdValueId: amberAnswerThresholdValueId || null,
                amberAnswerThresholdOperatorId: amberAnswerThresholdOperatorId || null,
                formData: new FormData(event.currentTarget),
            },
            {
                onSuccess: async () => {
                    trackAppInsightsEvent(auth.email, window.location.href, EditQuestionEvent);
                    navigate(-1);
                },
                onError: (error: HTTPError) => {
                    trackAppInsightsException(
                        auth.email,
                        window.location.href,
                        EditQuestionEvent,
                        error
                    );
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    };

    const onQuestionTypeSelected = (option: SingleValue<QuestionTypeDto>): void => {
        setQuestionTypeId(option ? option.questionTypeId : undefined);
        setAnswerTypeSearchDto({
            ...answerTypeSearchDto,
            questionTypeId: option ? option.questionTypeId : null,
        });
    };

    const onQuestionCategorySelected = (option: SingleValue<QuestionCategoryDto>): void =>
        setQuestionCategoryId(option!.questionCategoryId);

    const onQuestionImportanceSelected = (
        option: SingleValue<ReactSelectDto<QuestionImportance>>
    ): void => setQuestionImportanceId(option!.value);

    const answerTextToDisplayForFlagMode = (
        option: SingleValue<AnswerTypeDto>,
        flagMode: AnswerFlagMode
    ): string => {
        let answerToDisplay = "";

        const desirablePredefinedAnswers = option!.predefinedAnswerDtos.filter(
            (x) => x.answerFlagMode === flagMode
        );

        desirablePredefinedAnswers.forEach(
            (x) =>
                (answerToDisplay = `${answerToDisplay} ${answerToDisplay.length > 0 ? "|" : ""} ${
                    x.predefinedAnswerTextDto.textValue
                }`)
        );

        return answerToDisplay.trim();
    };

    const onAnswerTypeSelected = (option: SingleValue<AnswerTypeDto>): void => {
        if (option == null) {
            setAnswerTypeSearchDto(
                createAnswerTypeSearchDto(null, questionDetails.data!.questionTypeId, null)
            );
        }
        setAnswerTypeId(option ? option.answerTypeId : undefined);
        setAnswerTypeName(option ? option.name : "");

        const desirableAnswer =
            option && answerTextToDisplayForFlagMode(option, AnswerFlagMode.Desirable);
        const undesirableAnswer =
            option && answerTextToDisplayForFlagMode(option, AnswerFlagMode.Undesirable);
        const neutralAnswer =
            option && answerTextToDisplayForFlagMode(option, AnswerFlagMode.Neutral);

        setDesirableAnswer(
            desirableAnswer && desirableAnswer.length > 0 ? desirableAnswer : undefined
        );
        setUndesirableAnswer(
            undesirableAnswer && undesirableAnswer.length > 0 ? undesirableAnswer : undefined
        );
        setNeutralAnswer(neutralAnswer && neutralAnswer.length > 0 ? neutralAnswer : undefined);
    };

    const onAggregationTypeSelected = (option: SingleValue<AggregationTypeDto>): void => {
        option && setAggregationTypeId(option!.aggregationTypeId);
    };

    const onRedAnswerThresholdOperatorSelected = (
        option: SingleValue<AnswerThresholdOperatorsDto>
    ): void => {
        setRedAnswerThresholdOperatorId(option!.answerThresholdOperatorId);
    };

    const onAmberAnswerThresholdOperatorSelected = (
        option: SingleValue<AnswerThresholdOperatorsDto>
    ): void => {
        setAmberAnswerThresholdOperatorId(option!.answerThresholdOperatorId);
    };

    const handleAggregateChange = (_: string, checked: boolean): void => setAggregate(checked);

    const handleQuestionWeightingsChange = (_: string, checked: boolean): void => {
        setQuestionWeightings(checked);
    };

    const getQuestionWeightingValue = (predefinedAnswerId: number): string => {
        const associatedQuestionWeightingResponse = questionDetails.data!.questionWeightings.filter(
            (x) => x.predefinedAnswerId == predefinedAnswerId
        )[0];
        return associatedQuestionWeightingResponse &&
            associatedQuestionWeightingResponse.value != null
            ? associatedQuestionWeightingResponse.value.toString()
            : "";
    };

    const renderAnswerType = (): 0 | undefined | JSX.Element => {
        if (questionTypeId) {
            return (
                <SbFormSelectFieldGroup
                    name={"answerType"}
                    label={t("AnswerType", { keyPrefix: ChecklistAnswers })}
                    placeholderText={t("LookupSearchPlaceholderText", { keyPrefix: Common })!}
                    searchable
                    clearable
                    required
                    items={isARMonitoring ? arMonitoringAnswerTypesData : lookupAnswerTypesData}
                    itemDisplayText={(option: AnswerTypeDto) => option.name}
                    onChange={onAnswerTypeSelected}
                    loading={areQueriesLoading([lookupAnswerTypes, arMonitoringAnswerTypes])}
                    onSearchTextChanged={(searchText: string) => {
                        setAnswerTypeSearchDto({
                            ...answerTypeSearchDto,
                            searchText: searchText,
                        });
                    }}
                    defaultSelectedItem={
                        answerTypeId
                            ? lookupAnswerTypesData?.filter(
                                  (x) => x.answerTypeId === answerTypeId
                              )[0]
                            : null
                    }
                    disabled={isARMonitoring || false}
                />
            );
        }
    };

    const renderControlContent = (): JSX.Element => (
        <>
            {renderAnswerType()}
            {desirableAnswer && (
                <SbFormTextFieldGroup
                    name="desirablePredefinedAnswer"
                    label={t("DesirableAnswers", { keyPrefix: ChecklistAnswers })}
                    type="text"
                    disabled
                    value={desirableAnswer}
                />
            )}
            {undesirableAnswer && (
                <SbFormTextFieldGroup
                    name="undesirablePredefinedAnswer"
                    label={t("UndesirableAnswers", { keyPrefix: ChecklistAnswers })}
                    type="text"
                    disabled
                    value={undesirableAnswer}
                />
            )}
            {neutralAnswer && (
                <SbFormTextFieldGroup
                    name="neutralPredefinedAnswer"
                    label={t("NeutralAnswers", { keyPrefix: ChecklistAnswers })}
                    type="text"
                    disabled
                    value={neutralAnswer}
                />
            )}
            <SbFormCheckFieldGroup
                fieldLabel={t("EnableQuestionWeightings")}
                type="checkbox"
                values={[
                    {
                        name: "QuestionWeighting",
                        onChangeHandler: handleQuestionWeightingsChange,
                        defaultSelected: questionWeightings,
                    },
                ]}
            />
        </>
    );

    const renderNumberValueInput = (threshold: string): JSX.Element => (
        <SbFormTextFieldGroup
            name={threshold + "Number"}
            label={t("TheValue", { keyPrefix: ChecklistAnswers })}
            type="number"
            defaultValue={
                threshold === "red"
                    ? questionDetails.data!.answerThresholdValueResponses[0]?.intValue?.toString()
                    : questionDetails.data!.answerThresholdValueResponses[1]?.intValue?.toString()
            }
        />
    );

    const renderDecimalValueInput = (threshold: string): JSX.Element => (
        <SbFormTextFieldGroup
            name={threshold + "Decimal"}
            label={t("TheValue", { keyPrefix: ChecklistAnswers })}
            type="text"
            children={"00.00"}
            defaultValue={
                threshold === "red"
                    ? questionDetails.data!.answerThresholdValueResponses[0]?.decimalValue?.toString()
                    : questionDetails.data!.answerThresholdValueResponses[1]?.decimalValue?.toString()
            }
        />
    );

    const renderIndicatorContent = (): JSX.Element => (
        <>
            <SbFormCheckFieldGroup
                fieldLabel={t("IsAggregate")}
                type="checkbox"
                values={[
                    {
                        name: "Aggregate",
                        onChangeHandler: handleAggregateChange,
                        defaultSelected: aggregate,
                    },
                ]}
            />

            {renderAnswerType()}

            {aggregate && answerTypeName === "Number" && (
                <SbFormSelectFieldGroup
                    name={"aggregationType"}
                    label={t("AggregationType")}
                    placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                    searchable
                    clearable
                    items={aggregationTypes}
                    itemDisplayText={(option: AggregationTypeDto) => option.name} //TODO: Add translations for dynamic data
                    onChange={onAggregationTypeSelected}
                    defaultSelectedItem={questionDetails.data!.aggregationType}
                />
            )}

            <SbFormSelectFieldGroup
                name={"redAnswerThresholdOperators"}
                label={t("RedThreshold")}
                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                searchable
                clearable
                items={answerThresholdOperators}
                itemDisplayText={(option: AnswerThresholdOperatorsDto) =>
                    translateText(t, option.name, ChecklistAnswers)
                }
                onChange={onRedAnswerThresholdOperatorSelected}
                variant={"danger"}
                children={
                    answerTypeName === "Number"
                        ? renderNumberValueInput("red")
                        : renderDecimalValueInput("red")
                }
                defaultSelectedItem={
                    questionDetails.data!.answerThresholdValueResponses[0] &&
                    answerThresholdOperators.filter(
                        (answerThresholdOperator) =>
                            answerThresholdOperator.answerThresholdOperatorId ===
                            questionDetails.data!.answerThresholdValueResponses[0]
                                .answerThresholdOperatorId
                    )[0]
                }
            />

            <SbFormSelectFieldGroup
                name={"amberAnswerThresholdOperators"}
                label={t("AmberThreshold")}
                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                searchable
                clearable
                items={answerThresholdOperators}
                itemDisplayText={(option: AnswerThresholdOperatorsDto) =>
                    translateText(t, option.name, ChecklistAnswers)
                }
                onChange={onAmberAnswerThresholdOperatorSelected}
                variant={"warning"}
                children={
                    answerTypeName === "Number"
                        ? renderNumberValueInput("amber")
                        : renderDecimalValueInput("amber")
                }
                defaultSelectedItem={
                    questionDetails.data!.answerThresholdValueResponses[1] &&
                    answerThresholdOperators.filter(
                        (answerThresholdOperator) =>
                            answerThresholdOperator.answerThresholdOperatorId ===
                            questionDetails.data!.answerThresholdValueResponses[1]
                                .answerThresholdOperatorId
                    )[0]
                }
            />
        </>
    );

    const renderQuestionWeightingsContent = (): JSX.Element => (
        <TextTitledPanel title={t("QuestionWeightings")}>
            <SbAlert variant={"primary"} text={t("QuestionWeightingsHelperText")} />
            {answerTypeName && renderPredefinedAnswerValues()}
        </TextTitledPanel>
    );

    const renderPredefinedAnswerValues = (): JSX.Element => {
        const predefinedAnswers = lookupAnswerTypesData?.filter(
            (x) => x.answerTypeId === answerTypeId
        )[0].predefinedAnswerDtos;
        return (
            <>
                {predefinedAnswers?.map((x) => {
                    return (
                        <SbFormNumberBoxGroup
                            name={`predefinedAnswerId${x.predefinedAnswerId.toString()}`}
                            label={x.predefinedAnswerTextDto.textValue}
                            required={false}
                            defaultValue={Number(getQuestionWeightingValue(x.predefinedAnswerId))}
                        />
                    );
                })}
            </>
        );
    };

    return (
        <>
            <PageHeading>{t("EditQuestion")}</PageHeading>
            <PageSubHeading>{t("HeaderHelpTextQuestionEdit")}</PageSubHeading>
            <SectionVerticalSpace />
            {questionDetails.status === "success" && questionDetails.fetchStatus === "idle" && (
                <TextTitledPanel title={t("EditQuestion")}>
                    <Form onSubmit={handleSubmit}>
                        <SbFormCheckFieldGroup
                            fieldLabel={t("ARMonitoring", { keyPrefix: Common })}
                            type={"checkbox"}
                            values={arMonitoringCheckValues}
                        />

                        <SbFormSelectFieldGroup
                            name={"questionType"}
                            label={t("QuestionType")}
                            placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                            searchable
                            clearable
                            required
                            items={questionTypes}
                            itemDisplayText={(option: QuestionTypeDto) =>
                                translateText(t, option.name, ChecklistQuestions)
                            }
                            onChange={onQuestionTypeSelected}
                            defaultSelectedItem={questionDetails.data!.questionType}
                            disabled={isARMonitoring || false}
                        />

                        <SbFormSelectFieldGroup
                            name={"questionCategory"}
                            label={t("QuestionCategory")}
                            placeholderText={
                                t("LookupSearchPlaceholderText", { keyPrefix: Common })!
                            }
                            searchable
                            clearable
                            required
                            items={lookupQuestionCategoriesData}
                            itemDisplayText={(option: QuestionCategoryDto) => option.name}
                            onChange={onQuestionCategorySelected}
                            loading={isQueryLoading(lookupQuestionCategories)}
                            onSearchTextChanged={(searchText: string) =>
                                setSearchDto({ searchText: searchText, maxSearchResults: null })
                            }
                            defaultSelectedItem={questionDetails.data!.questionCategory}
                            disabled={
                                isARMonitoring &&
                                questionDetails.data!.questionCategory.name.trim() ===
                                    ARMonitoringDefaults.ARMonitoring
                                    ? true
                                    : false
                            }
                        />

                        <SbFormSelectFieldGroup
                            name={"questionImportance"}
                            label={t("QuestionImportance")}
                            placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                            searchable
                            clearable
                            required
                            items={getEnumsForType(QuestionImportance).map((x) =>
                                createReactSelectDto(x, QuestionImportance[x])
                            )}
                            itemDisplayText={(option: ReactSelectDto<QuestionImportance>) =>
                                t(QuestionImportance[option.value], { keyPrefix: Common })
                            }
                            onChange={onQuestionImportanceSelected}
                            defaultSelectedItem={questionImportanceReactSelectDtos.find(
                                (x) => x.value === questionDetails.data.questionImportance
                            )}
                        />

                        <SbFormTextAreaGroup
                            name="questionText"
                            label={t("QuestionText")}
                            required
                            rows={4}
                            maxLength={4000}
                            defaultValue={questionDetails.data!.questionTextDto.text}
                        />

                        {questionTypeId === QuestionTypes.Control && renderControlContent()}

                        {questionTypeId === QuestionTypes.Indicator && renderIndicatorContent()}

                        {questionWeightings && renderQuestionWeightingsContent()}

                        <LargeVerticalSpace />

                        <EndAlignedDiv>
                            <SaveButton type="submit" />
                            <CancelButton onClick={() => navigate(-1)} />
                        </EndAlignedDiv>
                    </Form>
                </TextTitledPanel>
            )}
        </>
    );
};

export default EditQuestionContainer;
