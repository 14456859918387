import { HTTPError } from "ky";
import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { DeleteQuestionSetEvent } from "../../../core/constants/application-insights-events";
import { ChecklistQuestions, Common, Users } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import {
    useDeleteQuestionSet,
    useGetQuestionSetDetails,
} from "../../../domain/viewmodels/question-set/delete-question-set-viewmodel";
import { DeleteButton } from "../../atoms/SbButton";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import { ActionConfirmationFragment } from "../../organisms/ActionConfirmationFragment";

const DeleteQuestionSetContainer: React.FC = () => {
    const questionSetId = Number(useParams().questionSetId);

    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const navigateSearch = useNavigateSearch();
    const menu = useMenu();
    const auth = useAuth();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const questionSetDetails = useGetQuestionSetDetails(questionSetId);
    const deleteQuestionSet = useDeleteQuestionSet();
    const questionSetDetailsResponseData = questionSetDetails.data;

    useLoader(
        isQueryLoading(questionSetDetails) || isMutationLoading(deleteQuestionSet),
        DeleteQuestionSetContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.QuestionSets);
    }, []);

    const buildDeleteButton = (): ReactElement<typeof Button> => (
        <DeleteButton onClick={() => handleDeleteQuestionSet(questionSetId)} />
    );

    const handleDeleteQuestionSet = (questionSetId: number): void => {
        deleteQuestionSet.mutate(
            {
                questionSetId: questionSetId,
            },
            {
                onSuccess: async () => {
                    trackAppInsightsEvent(auth.email, window.location.href, DeleteQuestionSetEvent);
                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "messageKey",
                            "DeleteQuestionSetSuccessMessage"
                        ),
                    ];

                    navigateSearch(`${getPath(AccordionTitles.QuestionSets)}`, params);
                },
                onError: (error: HTTPError) => {
                    trackAppInsightsException(
                        auth.email,
                        window.location.href,
                        DeleteQuestionSetEvent,
                        error
                    );
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    };

    const buildConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow(t("Name"), questionSetDetailsResponseData?.name),
        new TextConfirmationRow(
            t("Description", { keyPrefix: Common }),
            questionSetDetailsResponseData?.description
        ),
        new TextConfirmationRow(t("Process"), questionSetDetailsResponseData?.process),
        new TextConfirmationRow(
            t("CreatedByUserEmail", { keyPrefix: Users }),
            questionSetDetailsResponseData?.createdByUserName
        ),
        new TextConfirmationRow(
            t("DateCreated", { keyPrefix: Common }),
            questionSetDetailsResponseData?.dateCreatedLocal
        ),
        new TextConfirmationRow(
            t("ModifiedByUserEmail", { keyPrefix: Users }),
            questionSetDetailsResponseData?.modifiedByUserName
        ),
        new TextConfirmationRow(
            t("DateLastModified", { keyPrefix: Common }),
            questionSetDetailsResponseData?.dateModifiedLocal
        ),
    ];

    return (
        <>
            {isQuerySuccessful(questionSetDetails) && (
                <ActionConfirmationFragment
                    pageHeading={t("QuestionSetTitle")}
                    panelTitle={t("DeleteQuestionSetConfirmation")}
                    rows={buildConfirmationRows()}
                    primaryButton={buildDeleteButton()}
                    areQueriesSuccessful={isQuerySuccessful(questionSetDetails)}
                />
            )}
        </>
    );
};

export default DeleteQuestionSetContainer;
