import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ChecklistQuestions } from "../../core/constants/translation-namespace";
import { NumberInputBox } from "../atoms/input/NumberInputBox";
import SbFormCheckFieldGroup from "../molecules/input/SbFormCheckFieldGroup";

const StyledDivFormField = styled.div`
    display: inline-block;
    padding-top: ${(props) => props.theme.padding.md};
    text-align: right;
    vertical-align: middle;
    margin-bottom: 0;
    box-sizing: border-box;
    padding-right: ${(props) => props.theme.padding.sm};
`;

export const WeeklySchedule: React.FC<{
    handleNumberOfWeeksChange: React.ChangeEventHandler<HTMLInputElement>;
    handleWeeklyScheduleDescriptionChange: (_name: string, _checked: boolean) => void;
    checkedMondayScheduleDescription: boolean;
    checkedTuesdayScheduleDescription: boolean;
    checkedWednesdayScheduleDescription: boolean;
    checkedThursdayScheduleDescription: boolean;
    checkedFridayScheduleDescription: boolean;
    checkedSaturdayScheduleDescription: boolean;
    checkedSundayScheduleDescription: boolean;
    numberOfWeeks?: number;
}> = ({
    handleNumberOfWeeksChange,
    handleWeeklyScheduleDescriptionChange,
    checkedMondayScheduleDescription,
    checkedTuesdayScheduleDescription,
    checkedWednesdayScheduleDescription,
    checkedThursdayScheduleDescription,
    checkedFridayScheduleDescription,
    checkedSaturdayScheduleDescription,
    checkedSundayScheduleDescription,
    numberOfWeeks,
}) => {
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    return (
        <>
            <Form.Group className="mb-3">
                {t("RecurEvery")}{" "}
                <NumberInputBox
                    handleNumberInputChange={handleNumberOfWeeksChange}
                    defaultValue={numberOfWeeks}
                />
                &nbsp;{" "}
                {t("WeeksOnPluralOptional", { keyPrefix: ChecklistQuestions })?.toLocaleLowerCase()}
            </Form.Group>
            <Form.Group className="mb">
                <StyledDivFormField>
                    <SbFormCheckFieldGroup
                        fieldLabel=""
                        type="checkbox"
                        values={[
                            {
                                name: "monday",
                                label: `${t("Monday")}`,
                                defaultSelected: checkedMondayScheduleDescription,
                                onChangeHandler: handleWeeklyScheduleDescriptionChange,
                            },
                        ]}
                    />
                </StyledDivFormField>
                <StyledDivFormField>
                    <SbFormCheckFieldGroup
                        fieldLabel=""
                        type="checkbox"
                        values={[
                            {
                                name: "tuesday",
                                label: `${t("Tuesday")}`,
                                defaultSelected: checkedTuesdayScheduleDescription,
                                onChangeHandler: handleWeeklyScheduleDescriptionChange,
                            },
                        ]}
                    />
                </StyledDivFormField>
                <StyledDivFormField>
                    <SbFormCheckFieldGroup
                        fieldLabel=""
                        type="checkbox"
                        values={[
                            {
                                name: "wednesday",
                                label: `${t("Wednesday")}`,
                                defaultSelected: checkedWednesdayScheduleDescription,
                                onChangeHandler: handleWeeklyScheduleDescriptionChange,
                            },
                        ]}
                    />
                </StyledDivFormField>
                <StyledDivFormField>
                    <SbFormCheckFieldGroup
                        fieldLabel=""
                        type="checkbox"
                        values={[
                            {
                                name: "thursday",
                                label: `${t("Thursday")}`,
                                defaultSelected: checkedThursdayScheduleDescription,
                                onChangeHandler: handleWeeklyScheduleDescriptionChange,
                            },
                        ]}
                    />
                </StyledDivFormField>
            </Form.Group>

            <StyledDivFormField>
                <SbFormCheckFieldGroup
                    fieldLabel=""
                    type="checkbox"
                    values={[
                        {
                            name: "friday",
                            label: `${t("Friday")}`,
                            defaultSelected: checkedFridayScheduleDescription,
                            onChangeHandler: handleWeeklyScheduleDescriptionChange,
                        },
                    ]}
                />
            </StyledDivFormField>
            <StyledDivFormField>
                <SbFormCheckFieldGroup
                    fieldLabel=""
                    type="checkbox"
                    values={[
                        {
                            name: "saturday",
                            label: `${t("Saturday")}`,
                            defaultSelected: checkedSaturdayScheduleDescription,
                            onChangeHandler: handleWeeklyScheduleDescriptionChange,
                        },
                    ]}
                />
            </StyledDivFormField>
            <StyledDivFormField>
                <SbFormCheckFieldGroup
                    fieldLabel=""
                    type="checkbox"
                    values={[
                        {
                            name: "sunday",
                            label: `${t("Sunday")}`,
                            defaultSelected: checkedSundayScheduleDescription,
                            onChangeHandler: handleWeeklyScheduleDescriptionChange,
                        },
                    ]}
                />
            </StyledDivFormField>
        </>
    );
};
