import { PeriodType } from "../../enums/PeriodType";

export class BaseIntervalDto {
    value: number;
    periodType: PeriodType;

    public constructor(value: number, periodType: PeriodType) {
        this.value = value;
        this.periodType = periodType;
    }
}
