import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { ChecklistQuestions, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { AssociateReferentialLinkTypeDto } from "../../../domain/dtos/checklists/associate-referential-link-type-dto";
import {
    useCreateAssociateReferentialLink,
    useGetAllAssociateReferentialLinkTypes,
} from "../../../domain/viewmodels/common/create-associate-referential-link-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const CreateReferentialLinkContainer: React.FC = () => {
    const [referentialLinkTypeId, setReferentialLinkTypeId] = useState<number>(0);

    const menu = useMenu();
    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const checklistId = Number(useParams().checklistId);
    const actionItemId = Number(useParams().actionItemId);

    const getAllAssociateReferentialLinkTypes = useGetAllAssociateReferentialLinkTypes();
    const createReferentialLink = useCreateAssociateReferentialLink();

    const { t } = useTranslation("translation");

    useLoader(
        isQueryLoading(getAllAssociateReferentialLinkTypes) ||
            isMutationLoading(createReferentialLink),
        CreateReferentialLinkContainer
    );

    useEffect(() => {
        if (checklistId) {
            menu.changeActiveNavbarItem(NavbarTitles.Admin);
            menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Checklists);
        }

        if (actionItemId) {
            menu.changeActiveNavbarItem(NavbarTitles.ActionTracker);
            menu.changeActiveDrawerItem(DrawerTitles.ActionItems);
        }
    }, []);

    const changeReferentialLinkType = (
        option: SingleValue<AssociateReferentialLinkTypeDto>
    ): void => setReferentialLinkTypeId(option!.referentialLinkTypeId);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        createReferentialLink.mutate(
            {
                referentialLinkTypeId: referentialLinkTypeId,
                formData: new FormData(event.currentTarget),
            },
            {
                onSuccess: async () => {
                    queryClient.invalidateQueries(["getReferentialLinks", referentialLinkTypeId]);

                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "messageKey",
                            "AddedReferentialLinkSuccessMessage"
                        ),
                    ];

                    if (checklistId) {
                        navigateSearch(
                            `${getPath(
                                AccordionTitles.Checklists
                            )}/${checklistId}/associate-referential-links`,
                            params
                        );
                    }

                    if (actionItemId) {
                        navigateSearch(
                            `${getPath(
                                DrawerTitles.ActionItems
                            )}/${actionItemId}/associate-referential-links`,
                            params
                        );
                    }
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    return (
        <>
            <PageHeading>
                {t("ReferentialLinkCreateTitle", { keyPrefix: ChecklistQuestions })}
            </PageHeading>
            <SectionVerticalSpace />

            <TextTitledPanel
                title={t("ReferentialLinkCreateTitle", { keyPrefix: ChecklistQuestions })}
            >
                <Form onSubmit={handleSubmit}>
                    {isQuerySuccessful(getAllAssociateReferentialLinkTypes) && (
                        <SbFormSelectFieldGroup
                            name={"referentialLinkType"}
                            label={t("CreateReferentialLinkType", {
                                keyPrefix: ChecklistQuestions,
                            })}
                            placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                            searchable
                            clearable
                            required
                            items={getAllAssociateReferentialLinkTypes.data}
                            itemDisplayText={
                                (option: AssociateReferentialLinkTypeDto) => option.name //TODO: Add translations for dynamic data
                            }
                            onChange={changeReferentialLinkType}
                        />
                    )}

                    <SbFormTextFieldGroup
                        name="referentialLinkValue"
                        label={t("Value", { keyPrefix: Common })}
                        type="text"
                        maxLength={150}
                        minLength={2}
                        required
                    />

                    <EndAlignedDiv>
                        <SaveButton type="submit" />
                        <CancelButton onClick={() => navigate(-1)} />
                    </EndAlignedDiv>
                </Form>
            </TextTitledPanel>
        </>
    );
};

export default CreateReferentialLinkContainer;
