import React from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaFileUpload } from "react-icons/fa";
import styled from "styled-components";
import { Common, Hierarchy } from "../../core/constants/translation-namespace";
import { SectionVerticalSpace } from "../../core/theme/global-styles";
import HierarchyImportHeaderDto from "../../domain/dtos/data-import/hierarchy-import-header-dto";
import { SbAlert } from "../atoms/SbAlert";
import { SbButton } from "../atoms/SbButton";
import { TextTitledPanel } from "../molecules/SbPanel";

const StyledTable = styled(Table)`
    background-color: white;
`;

const StyledGridP = styled.p`
    color: ${(props) => props.theme.palette.primary};
    font-weight: bold;
    text-align: left;
`;

const StyledDangerTr = styled.tr`
    background-color: ${(props) => props.theme.palette.importDangerBackground};
`;

const StyledDangerTd = styled.td`
    color: ${(props) => props.theme.palette.danger};
`;

const ContentContainer = styled.div`
    position: relative;
    padding: ${(props) => props.theme.padding.lg};
    background-color: white;
    border-radius: ${(props) => props.theme.padding.sm};
`;

const ScrollDiv = styled.div`
    overflow: scroll;
    max-height: 400px;
`;

export const HierarchyImportTable: React.FC<{
    headerMetadata: HierarchyImportHeaderDto[];
    onProcess: () => void;
}> = ({ headerMetadata, onProcess }) => {
    const { t } = useTranslation("translation", { keyPrefix: Hierarchy });

    let hasColumnErrors = headerMetadata?.some((column) => column.error !== null);
    let hasRowErrors = headerMetadata?.some((column) =>
        column.rows.some((rows) => rows.error !== null)
    );

    let rows = headerMetadata?.flatMap((x) => x.rows?.filter((y) => y.error === null));
    let rowsWithErrors = headerMetadata?.flatMap((x) => x.rows?.filter((y) => y.error !== null));

    return (
        <TextTitledPanel title={t("NodesToBeImported")}>
            <ContentContainer>
                {hasColumnErrors ||
                    (hasRowErrors && (
                        <SbAlert variant="danger" text={t("HierarchyImportValidationError")} />
                    ))}
                <ScrollDiv>
                    <StyledTable hover borderless>
                        {headerMetadata && (
                            <tr>
                                <th></th>
                                {headerMetadata.map((column) => (
                                    <th key={column.columnId}>
                                        <StyledGridP>{column.name}</StyledGridP>
                                    </th>
                                ))}
                            </tr>
                        )}

                        <tbody>
                            <tr>
                                <td>{t("NodeTypeId")}</td>
                                {headerMetadata?.map((column) => <td>{column.nodeTypeId}</td>)}
                            </tr>

                            <tr>
                                <td>{t("LinkedNodeTypeId")}</td>
                                {headerMetadata?.map((column) => (
                                    <td>{column.linkedNodeTypeId}</td>
                                ))}
                            </tr>

                            {hasColumnErrors && (
                                <tr>
                                    <td>{t("Error", { keyPrefix: Common })}</td>
                                    {headerMetadata?.map((column) => (
                                        <tr>
                                            <StyledDangerTd>{column.error}</StyledDangerTd>
                                        </tr>
                                    ))}
                                </tr>
                            )}

                            {!hasColumnErrors && !hasRowErrors && (
                                <>
                                    <SectionVerticalSpace />
                                    {rows.map((row) => (
                                        <tr>
                                            <td></td>
                                            {headerMetadata.map((col) => {
                                                const theRow = col.rows.find(
                                                    (x) => x.rowId == row.rowId
                                                );
                                                return <td>{theRow?.valueString}</td>;
                                            })}
                                        </tr>
                                    ))}
                                </>
                            )}

                            {hasRowErrors && (
                                <>
                                    {rowsWithErrors.map((row) => (
                                        <StyledDangerTr>
                                            <td></td>
                                            {headerMetadata.map((col) => {
                                                const theRow = col.rows.find(
                                                    (x) => x.rowId == row.rowId
                                                );
                                                return theRow?.error !== null ? (
                                                    <StyledDangerTd>{theRow?.error}</StyledDangerTd>
                                                ) : (
                                                    <td>{theRow?.value}</td>
                                                );
                                            })}
                                        </StyledDangerTr>
                                    ))}
                                </>
                            )}
                        </tbody>
                    </StyledTable>
                </ScrollDiv>
                <SectionVerticalSpace />
                {!hasColumnErrors && !hasRowErrors && (
                    <SbButton
                        variant={"primary"}
                        type={"button"}
                        label={t("Import", { keyPrefix: Common })}
                        icon={FaFileUpload}
                        onClick={onProcess}
                    />
                )}
            </ContentContainer>
        </TextTitledPanel>
    );
};
