import { Accordion } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import styled from "styled-components";
import { closeDrawer, openDrawer } from "../../core/animations/drawer";
import logoExpanded from "../../core/images/logo_expanded.png";
import logoMinimised from "../../core/images/logo_minimised.png";
import { useAuth } from "../../core/store/auth-context";
import { useMenu } from "../../core/store/menu-context";
import { useSessionStorageNavigation } from "../../core/utilities/session-storage-navigation";
import DrawerLink from "../atoms/DrawerLink";
import DrawerToggle from "../atoms/DrawerToggle";
import DrawerAccordion from "../molecules/DrawerAccordion";

interface StyledContainerProps {
    readonly $drawerOpen: boolean;
    readonly $animate: boolean;
}

const StyledContainer = styled(Container)<StyledContainerProps>`
    font-family: ${(props) => props.theme.font.family};
    position: fixed;
    z-index: 1;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100%;
    width: ${(props) =>
        props.$drawerOpen ? props.theme.padding.drawerOpen : props.theme.padding.drawerClosed};
    animation: ${(props) => props.$animate && (props.$drawerOpen ? openDrawer : closeDrawer)} 0.3s
        linear;
    background-image: linear-gradient(
        ${(props) => props.theme.palette.primary},
        ${(props) => props.theme.palette.pink}
    );
`;

interface StyledImageProps {
    readonly $drawerOpen: boolean;
}

const StyledImage = styled(Image)<StyledImageProps>`
    height: ${(props) => props.theme.padding.navbar};
    padding-top: ${(props) => props.$drawerOpen && props.theme.padding.lg};
    padding-left: ${(props) => props.theme.padding.lg};
    padding-right: ${(props) => props.theme.padding.lg};
    padding-bottom: ${(props) => props.theme.padding.sm};
    margin-left: ${(props) => !props.$drawerOpen && props.theme.padding.lg};
`;

const StyledDivider = styled.div`
    height: ${(props) => props.theme.padding.xs};
    margin-left: ${(props) => props.theme.padding.lg};
    margin-right: ${(props) => props.theme.padding.lg};
    margin-bottom: ${(props) => props.theme.padding.lg};
    background-color: ${(props) => props.theme.palette.white};
`;

const StyledDrawerItemContainer = styled.div`
    width: 100%;
    color: ${(props) => props.theme.palette.white};
`;

const Drawer: React.FC = () => {
    const menu = useMenu();
    const auth = useAuth();
    const sessionStorageNavigation = useSessionStorageNavigation();

    return (
        <StyledContainer $animate={menu.animate} $drawerOpen={menu.drawerOpen}>
            <StyledImage
                $drawerOpen={menu.drawerOpen}
                src={menu.drawerOpen ? logoExpanded : logoMinimised}
                alt="logo"
                onClick={() => {
                    sessionStorageNavigation();
                }}
            />
            {auth.userName && (
                <>
                    <StyledDivider />
                    <StyledDrawerItemContainer>
                        <Accordion activeKey={menu.activeAccordion}>
                            {menu.drawerItems.map((drawerItem) => {
                                if (drawerItem.children) {
                                    return (
                                        <DrawerAccordion
                                            key={drawerItem.title}
                                            title={drawerItem.title}
                                            active={drawerItem.isActive}
                                            accordionLinks={drawerItem.children}
                                        />
                                    );
                                }
                                return (
                                    <DrawerLink
                                        key={drawerItem.title}
                                        title={drawerItem.title}
                                        active={drawerItem.isActive}
                                    />
                                );
                            })}
                        </Accordion>
                    </StyledDrawerItemContainer>
                    <StyledDivider />
                    <DrawerToggle />
                </>
            )}
        </StyledContainer>
    );
};

export default Drawer;
