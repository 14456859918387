import { HTTPError } from "ky";
import { ReactElement, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { DeleteNodeTypeValueEvent } from "../../../core/constants/application-insights-events";
import { Common, NodeTypes, NodeTypeValues } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { LargeVerticalSpace } from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { nameColumnName } from "../../../core/utilities/dataTableColumns";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isMutationLoading,
} from "../../../core/utilities/responseStateHelper";
import { defaultPaginationDto, PaginationDto } from "../../../domain/dtos/common/pagination-dto";
import {
    useDeleteNodeTypeValue,
    useGetNodeTypeValueDetails,
} from "../../../domain/viewmodels/hierarchy/delete-node-type-value-viewmodel";
import { DeleteButton } from "../../atoms/SbButton";
import { TextTitledPanel } from "../../molecules/SbPanel";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import {
    ActionConfirmationFragment,
    AlertProps,
    createErrorAlertProps,
} from "../../organisms/ActionConfirmationFragment";
import { DataTable } from "../../organisms/DataTable";

const DeleteNodeTypeValueContainer: React.FC = () => {
    const [paginationDto, setPaginationDto] = useState<PaginationDto>(defaultPaginationDto);

    const menu = useMenu();
    const auth = useAuth();
    const navigateSearch = useNavigateSearch();
    const [urlSearchParams] = useSearchParams();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: NodeTypeValues });

    const nodeTypeId = Number(urlSearchParams.get("nodeTypeId"));
    const nodeTypeValueId = Number(useParams().nodeTypeValueId);

    const getNodeTypeValueDetails = useGetNodeTypeValueDetails(nodeTypeValueId, paginationDto);
    const deleteNodeTypeValue = useDeleteNodeTypeValue();

    const nodeTypeValueDetailsResponseData = getNodeTypeValueDetails[0].data;
    const canDeleteNodeTypeValueResponseData = getNodeTypeValueDetails[1].data;
    const nodesResponseData = getNodeTypeValueDetails[2].data;

    useLoader(
        areQueriesLoading(getNodeTypeValueDetails) || isMutationLoading(deleteNodeTypeValue),
        DeleteNodeTypeValueContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.VisualTree);
    }, []);

    const handleDeleteNodeTypeValue = (): void => {
        deleteNodeTypeValue.mutate(nodeTypeValueId, {
            onSuccess: onDeleteNodeTypeValueSuccess,
            onError: (error: HTTPError) => {
                trackAppInsightsException(
                    auth.email,
                    window.location.href,
                    DeleteNodeTypeValueEvent,
                    error
                );
                errorResponseToDisplayHandler(error);
            },
        });
    };

    const onDeleteNodeTypeValueSuccess = async (): Promise<void> => {
        trackAppInsightsEvent(auth.email, window.location.href, DeleteNodeTypeValueEvent);

        const params = [
            createNavigateSearchParameter("success", "true"),
            createNavigateSearchParameter("messageKey", "DeleteHierarchyLevelValueSuccessMessage"),
        ];

        navigateSearch(`${getPath(AccordionTitles.VisualStructure)}/${nodeTypeId}`, params);
    };

    const buildValidationAlert = (): AlertProps | null => {
        if (!canDeleteNodeTypeValueResponseData || canDeleteNodeTypeValueResponseData.data) {
            return null;
        }

        return createErrorAlertProps(canDeleteNodeTypeValueResponseData!.errors);
    };

    const buildDeleteButton = (): ReactElement<typeof Button> => (
        <DeleteButton
            disabled={canDeleteNodeTypeValueResponseData?.data === false}
            onClick={handleDeleteNodeTypeValue}
        />
    );

    const buildAssociatedNodes = (): ReactElement<HTMLDivElement> => {
        if (nodesResponseData!.recordCount <= 0) {
            return <></>;
        }

        return (
            <>
                <LargeVerticalSpace />
                <TextTitledPanel title={t("ListOfNodesAssociatedWithThisValue")}>
                    <DataTable
                        columns={nameColumnName}
                        rows={nodesResponseData!.rows}
                        totalItems={nodesResponseData!.recordCount}
                        paginationDto={paginationDto}
                        setPaginationDto={setPaginationDto}
                    />
                </TextTitledPanel>
            </>
        );
    };

    const buildConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow(
            t("NodeType", { keyPrefix: NodeTypes }),
            nodeTypeValueDetailsResponseData?.nodeType.name
        ),
        new TextConfirmationRow(
            t("Code", { keyPrefix: Common }),
            nodeTypeValueDetailsResponseData?.code
        ),
        new TextConfirmationRow(
            t("Value", { keyPrefix: Common }),
            nodeTypeValueDetailsResponseData?.value
        ),
        new TextConfirmationRow(
            t("Description", { keyPrefix: Common }),
            nodeTypeValueDetailsResponseData?.description
        ),
    ];

    return (
        <>
            <ActionConfirmationFragment
                pageHeading={t("NodeTypeValueDeleteTitle")}
                panelTitle={t("DeleteNodeTypeValueConfirmation")}
                alert={buildValidationAlert()}
                rows={buildConfirmationRows()}
                primaryButton={buildDeleteButton()}
                areQueriesSuccessful={areQueriesSuccessful(getNodeTypeValueDetails)}
            />

            {areQueriesSuccessful(getNodeTypeValueDetails) && buildAssociatedNodes()}
        </>
    );
};

export default DeleteNodeTypeValueContainer;
