import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ChecklistQuestions, Reports } from "../../../core/constants/translation-namespace";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import { PageHeading, SectionVerticalSpace } from "../../../core/theme/global-styles";
import { reviewReportSetColumnNames } from "../../../core/utilities/dataTableColumns";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import { defaultPaginationDto, PaginationDto } from "../../../domain/dtos/common/pagination-dto";
import {
    hasRoleTypeInGroup,
    QuestionSetInstanceReviewRoleGroup,
} from "../../../domain/enums/Roles";
import { useFilerReviewReportSets } from "../../../domain/viewmodels/review-reports/view-review-report-sets-viewmodel";
import { DataTable } from "../../organisms/DataTable";

const ReviewsContainer: React.FC = () => {
    const [paginationDto, setPaginationDto] = useState<PaginationDto>(defaultPaginationDto);

    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const menu = useMenu();
    const toast = useToast();
    const navigate = useNavigate();
    const auth = useAuth();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const reviewReportSets = useFilerReviewReportSets(paginationDto);

    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";
    const reviewReportName = urlSearchParams.get("reviewReportName");

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Checklists);
        menu.changeActiveDrawerItem(DrawerTitles.Reviews);

        if (success) {
            toast.addToast(
                createSuccessToastProps([
                    reviewReportName
                        ? `${t(messageKey)} - ${reviewReportName}`
                        : `${t(messageKey)}`,
                ])
            );

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    useLoader(isQueryLoading(reviewReportSets), ReviewsContainer);

    return (
        <>
            <PageHeading>{t("ReviewReportsTitle")}</PageHeading>

            <SectionVerticalSpace />

            {isQuerySuccessful(reviewReportSets) && (
                <>
                    <DataTable
                        rows={reviewReportSets.data!.rows}
                        keyPrefix={Reports}
                        columns={reviewReportSetColumnNames}
                        viewItem={(reviewReportSetId: number) => {
                            navigate(`${getPath(DrawerTitles.Reviews)}/${reviewReportSetId}`);
                        }}
                        deleteItem={
                            hasRoleTypeInGroup(
                                auth.userRoles,
                                QuestionSetInstanceReviewRoleGroup.DeleteRoles
                            )
                                ? (reviewReportSetId) => {
                                      navigate(
                                          `${getPath(
                                              DrawerTitles.Reviews
                                          )}/${reviewReportSetId}/delete-review-report-set`
                                      );
                                  }
                                : undefined
                        }
                        totalItems={reviewReportSets.data!.recordCount}
                        paginationDto={paginationDto}
                        setPaginationDto={setPaginationDto}
                    />
                </>
            )}
        </>
    );
};

export default ReviewsContainer;
