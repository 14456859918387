import React from "react";
import Row from "react-bootstrap/Row";
import { AnswerSummaryType } from "../../domain/enums/answer-summary/AnswerSummaryType";
import { AnswerSummaryResponse } from "../../domain/responses/answer-summary/answer-summary-response";
import { AnswerSummaryCard } from "../molecules/AnswerSummaryCard";

export const AnswerSummaryCardGallery: React.FC<{
    answerSummaryCards: AnswerSummaryResponse[] | undefined;
    answerSummaryType: AnswerSummaryType;
}> = ({ answerSummaryCards, answerSummaryType }) => {
    return (
        <Row>
            {answerSummaryCards &&
                answerSummaryCards.map((answerSummaryCard, index) => (
                    <AnswerSummaryCard
                        key={index}
                        answerSummary={answerSummaryCard}
                        answerSummaryType={answerSummaryType}
                    />
                ))}
        </Row>
    );
};
