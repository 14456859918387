import { ReviewReportPdfDto } from "../../dtos/review-reports/review-report-pdf-dto";

export class ReviewReportPdfRequest {
    questionSetInstanceId: number | null;
    reviewReportSetId: number;

    public constructor(dto: ReviewReportPdfDto) {
        this.questionSetInstanceId = dto.questionSetInstanceId;
        this.reviewReportSetId = dto.reviewReportSetId;
    }
}

export const buildFilterReviewReportPdfParameters = (
    request: ReviewReportPdfRequest
): URLSearchParams => {
    const searchParams = new URLSearchParams();

    if (request.questionSetInstanceId) {
        searchParams.set("questionSetInstanceId", request.questionSetInstanceId.toString());
    }

    if (request.reviewReportSetId) {
        searchParams.set("reviewReportSetId", request.reviewReportSetId.toString());
    }

    return searchParams;
};
