import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
} from "../common/base-pagination-request";

export default class FilterReportSummariesRequest extends BasePaginationRequest {
    startDateUtc: Date;
    endDateUtc: Date;
    userId: number;

    public constructor(
        startDateUtc: Date,
        endDateUtc: Date,
        userId: number,
        paginationDto: BasePaginationDto
    ) {
        const { pageNumber, pageSize } = paginationDto;
        super(pageNumber, pageSize);

        this.startDateUtc = startDateUtc;
        this.endDateUtc = endDateUtc;
        this.userId = userId;
    }

    public buildSearchParameters(): URLSearchParams {
        const searchParams = buildBasePaginationSearchParameters(this);

        searchParams.set("startDateUtc", this.startDateUtc.toISOString());
        searchParams.set("endDateUtc", this.endDateUtc.toISOString());
        searchParams.set("userId", this.userId.toString());

        return searchParams;
    }
}
