import ReviewRating from "../../enums/review-reports/review-ratings";
import ReviewStatus from "../../enums/review-reports/review-status";
import ReviewReportSetHistoryResponse from "../../responses/review-reports/review-report-set-history-response";

class ReviewReportSetHistoryDto {
    reviewReportSetId: number;
    reviewReportName: string;
    reviewRating: ReviewRating;
    reviewStatus: ReviewStatus;
    datePublishedUtc: Date | null;
    reviewReportSetFileId: number | null;

    public constructor(response: ReviewReportSetHistoryResponse) {
        this.reviewReportSetId = response.reviewReportSetId;
        this.reviewReportName = response.reviewReportName;
        this.reviewRating = response.reviewRating;
        this.reviewStatus = response.reviewStatus;
        this.datePublishedUtc = response.datePublishedUtc;
        this.reviewReportSetFileId = response.reviewReportSetFileId;
    }
}

export default ReviewReportSetHistoryDto;
