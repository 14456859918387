import IndicatorQuestionAnswerDto from "../../dtos/answer-capture/indicator-question-answer-dto";

export default class IndicatorQuestionAnswerRequest {
    questionId: number;
    textValue: string | null;
    intValue: number | null;
    decimalValue: number | null;
    boolValue: boolean | null;
    dateTimeValueLocal: Date | null;

    public constructor(
        questionId: number,
        textValue: string | null = null,
        intValue: number | null = null,
        decimalValue: number | null = null,
        boolValue: boolean | null = null,
        dateTimeValueLocal: Date | null = null
    ) {
        this.questionId = questionId;
        this.textValue = textValue;
        this.intValue = intValue;
        this.decimalValue = decimalValue;
        this.boolValue = boolValue;
        this.dateTimeValueLocal = dateTimeValueLocal;
    }

    public static constructFromDto(
        dto: IndicatorQuestionAnswerDto
    ): IndicatorQuestionAnswerRequest {
        const { questionId, textValue, intValue, decimalValue, boolValue, dateTimeValueLocal } =
            dto;

        return new IndicatorQuestionAnswerRequest(
            questionId,
            textValue,
            intValue,
            decimalValue,
            boolValue,
            dateTimeValueLocal
        );
    }

    public buildSearchParameters(): URLSearchParams {
        const searchParams = new URLSearchParams();

        searchParams.set("questionId", this.questionId.toString());

        if (this.textValue) {
            searchParams.set("textValue", this.textValue.toString());
        }

        if (this.intValue !== null) {
            searchParams.set("intValue", this.intValue.toString());
        }

        if (this.decimalValue !== null) {
            searchParams.set("decimalValue", this.decimalValue.toString());
        }

        if (this.boolValue !== null) {
            searchParams.set("boolValue", this.boolValue.toString());
        }

        if (this.dateTimeValueLocal) {
            searchParams.set("dateTimeValueLocal", this.dateTimeValueLocal.toISOString());
        }

        return searchParams;
    }
}
