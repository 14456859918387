import { Response } from "../../responses/common/response-response";
import { ConversationTokenResponse } from "../../responses/nala/ConversationTokenResponse";

export class ConversationTokenDto {
    conversationId: string;
    token: string;
    expiresInSeconds: number;

    public constructor(response: ConversationTokenResponse) {
        this.conversationId = response.conversationId;
        this.token = response.token;
        this.expiresInSeconds = response.expiresInSeconds;
    }
}

export const toConversationTokenDto = (
    response: Response<ConversationTokenResponse>
): ConversationTokenDto => new ConversationTokenDto(response.data);
