import { RaciSetUserResponse } from "../../responses/actions/raci-set-user-response";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { BaseUserDto, createBaseUserDtoFromResponse } from "../users/base-user-dto";

export interface RaciSetUserDto extends BaseUserDto {
    raciRelationship: number;
}

export const createRaciSetUserDto = (response: RaciSetUserResponse): RaciSetUserDto => ({
    raciRelationship: response.raciRelationship,
    ...createBaseUserDtoFromResponse(response),
});

export const toRaciSetUserDtos = (response: RaciSetUserResponse[]): RaciSetUserDto[] =>
    response?.map((x) => createRaciSetUserDto(x));

export const toRaciSetUserDtosFromPaginatedResponse = (
    response: Response<PaginationResponse<RaciSetUserResponse>>
): RaciSetUserDto[] => response?.data.results.map((x) => createRaciSetUserDto(x));
