import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import RiskLevel from "../../enums/associated-risks/risk-level";
import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
} from "../common/base-pagination-request";

export class FilterAssociatedRiskRequest extends BasePaginationRequest {
    riskLevel: RiskLevel;

    public constructor(riskLevel: RiskLevel, basePaginationDto: BasePaginationDto) {
        super(basePaginationDto.pageNumber, basePaginationDto.pageSize);
        this.riskLevel = riskLevel;
    }
}

export const buildFilterAssociatedRiskParameters = (
    request: FilterAssociatedRiskRequest
): URLSearchParams => {
    const searchParams = buildBasePaginationSearchParameters(request);

    if (request.riskLevel) {
        searchParams.set("riskLevel", request.riskLevel.toString());
    }

    return searchParams;
};
