import RaciRelationship from "../../enums/action-items/RaciRelationship";

export class AssociateDissociateAgreedActionRaciUserDto {
    userId: number;
    agreedActionId: number;
    raciRelationship: RaciRelationship;

    public constructor(userId: number, agreedActionId: number, raciRelationship: RaciRelationship) {
        this.userId = userId;
        this.agreedActionId = agreedActionId;
        this.raciRelationship = raciRelationship;
    }
}
