import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ChecklistQuestions } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { useDissociateReferentialLinkType } from "../../../domain/viewmodels/question-set/dissociate-referential-link-type-viewmodel";
import { useGetReferentialLinkTypeDetails } from "../../../domain/viewmodels/referential-link-types/view-referential-link-type-viewmodel";
import { DissociateButton } from "../../atoms/SbButton";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import { ActionConfirmationFragment } from "../../organisms/ActionConfirmationFragment";

const DissociateQuestionSetReferentialLinkTypeContainer: React.FC = () => {
    const referentialLinkTypeId = Number(useParams().referentialLinkTypeId);
    const questionSetId = Number(useParams().questionSetId);

    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const navigateSearch = useNavigateSearch();
    const menu = useMenu();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const getReferentialLinkTypeDetails = useGetReferentialLinkTypeDetails(referentialLinkTypeId);
    const dissociateReferentialLinkType = useDissociateReferentialLinkType();
    const referentialLinkTypeDetailsData = getReferentialLinkTypeDetails.data;

    useLoader(
        isQueryLoading(getReferentialLinkTypeDetails) ||
            isMutationLoading(dissociateReferentialLinkType),
        DissociateQuestionSetReferentialLinkTypeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.QuestionSets);
    }, []);

    const buildDeleteButton = (): ReactElement<typeof Button> => (
        <DissociateButton onClick={() => handleDissociateQuestionSetReferentialLinkType()} />
    );

    const handleDissociateQuestionSetReferentialLinkType = (): void => {
        dissociateReferentialLinkType.mutate(
            {
                questionSetId: questionSetId,
                referentialLinkTypeId: referentialLinkTypeId,
            },
            {
                onSuccess: async () => {
                    queryClient.invalidateQueries(["getAssociatedReferentialLinks"]);

                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "messageKey",
                            "DissociateReferentialLinkTypeSuccess"
                        ),
                    ];

                    navigateSearch(
                        `${getPath(AccordionTitles.QuestionSets)}/${questionSetId}`,
                        params
                    );
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    const buildConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow(t("ReferentialLinkType"), referentialLinkTypeDetailsData?.name),
    ];

    return (
        <>
            {isQuerySuccessful(getReferentialLinkTypeDetails) && (
                <ActionConfirmationFragment
                    pageHeading={t("DissociateReferentialLinkType")}
                    panelTitle={t("DissociateReferentialLinkTypeConfirmation")}
                    rows={buildConfirmationRows()}
                    primaryButton={buildDeleteButton()}
                    areQueriesSuccessful={isQuerySuccessful(getReferentialLinkTypeDetails)}
                />
            )}
        </>
    );
};

export default DissociateQuestionSetReferentialLinkTypeContainer;
