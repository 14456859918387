import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaPlusCircle } from "react-icons/fa";
import { IoCheckmarkDoneCircleOutline, IoCloseSharp } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { SingleValue } from "react-select";
import styled from "styled-components";
import { AllowedExtensionsForActionPlan } from "../../../core/constants/FileExtension";
import {
    ActionItems,
    ActionPlans,
    ChecklistAnswers,
    ChecklistQuestions,
    Common,
    Reports,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    createErrorToastProps,
    createSuccessToastProps,
    useToast,
} from "../../../core/store/toast-context";
import {
    ContentContainer,
    EndAlignedDiv,
    maxContentWidthSelectStyle,
    PageHeading,
    SectionVerticalSpace,
    SmallVerticalSpace,
    StyledFormLabel,
    StyledFormLabelNoMargin,
    StyledFormLeftAlignedLabel,
    StyledVariantText,
} from "../../../core/theme/global-styles";
import { TableRow } from "../../../core/utilities/customTypes";
import { questionSetInstanceAnswerFileColumnNames } from "../../../core/utilities/dataTableColumns";
import { getEnumsForType } from "../../../core/utilities/enum-helper";
import { DataTableColumnTypes, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getBase64FileString } from "../../../core/utilities/file-helper";
import { DownloadFile } from "../../../core/utilities/FileDownload";
import {
    areMutationsLoading,
    areQueriesLoading,
    areQueriesSuccessful,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import AssociatedRiskDto from "../../../domain/dtos/associated-risks/associated-risk-dto";
import { AssociatedRiskGroupDto } from "../../../domain/dtos/associated-risks/associated-risk-group-dto";
import {
    BasePaginationDto,
    createBasePaginationDto,
} from "../../../domain/dtos/common/base-pagination-dto";
import { defaultPaginationDto } from "../../../domain/dtos/common/pagination-dto";
import { createReactSelectDto, ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import QuestionAnswerFileUploadDto from "../../../domain/dtos/question-set-instance-answers/question-answer-file-upload-dto";
import QuestionSetInstanceAnswerFileDto from "../../../domain/dtos/question-set-instance-answers/question-set-instance-answer-file-dto";
import { AddQuestionSetQuestionCommentDto } from "../../../domain/dtos/review-reports/add-question-set-question-comment-dto";
import { AgreedActionDto } from "../../../domain/dtos/review-reports/agreed-action-dto";
import { AgreedActionRaciUserDto } from "../../../domain/dtos/review-reports/agreed-action-raci-set-user-dto";
import { AssociateDissociateAgreedActionRaciUserDto } from "../../../domain/dtos/review-reports/associate-agreed-action-raci-user-dto";
import { EditAgreedActionDto } from "../../../domain/dtos/review-reports/edit-agreed-action-dto";
import { EditReviewReportDetailsDto } from "../../../domain/dtos/review-reports/edit-review-report-details-dto";
import { EditReviewTypeFieldValueDto } from "../../../domain/dtos/review-reports/edit-review-type-field-value-dto";
import { QuestionSetInstanceReviewAssociatedRiskDto } from "../../../domain/dtos/review-reports/question-set-instance-review-associated-risk-dto";
import { QuestionSetQuestionCommentDto } from "../../../domain/dtos/review-reports/question-set-question-comment-dto";
import { RemoveResolveAgreedActionDto } from "../../../domain/dtos/review-reports/remove-resolve-agreed-action-dto";
import { BaseUserDto } from "../../../domain/dtos/users/base-user-dto";
import RaciRelationship from "../../../domain/enums/action-items/RaciRelationship";
import RiskLevel from "../../../domain/enums/associated-risks/risk-level";
import AgreedActionStatus from "../../../domain/enums/review-reports/agreed-action-status";
import ReviewStatus from "../../../domain/enums/review-reports/review-status";
import { ReviewTypeFields } from "../../../domain/enums/review-reports/ReviewTypeFields";
import { Response } from "../../../domain/responses/common/response-response";
import { useFilterAssociatedRisks } from "../../../domain/viewmodels/associated-risks/view-associated-risks-viewmodel";
import {
    useLookupMaxAgreedActions,
    useLookupMaxAssociatedRisks,
} from "../../../domain/viewmodels/hierarchy/text-search-node-viewmodel";
import {
    useAddQuestionSetQuestionComment,
    useAssociateAgreedActionRaciUser,
    useCreateAgreedAction,
    useDissociateAgreedActionRaciUser,
    useDownloadQuestionSetInstanceAnswerFile,
    useEditReviewReportDetails,
    useFilterQuestionSetInstanceAnswerFiles,
    useGetFilterUserDetails,
    useGetQuestionSetInstanceAnswerComment,
    useGetQuestionSetInstanceAnswerDetails,
    useGetQuestionSetInstanceReviewDetails,
    useGetSingleQuestionSetInstanceDetails,
    useRemoveAgreedAction,
    useResolveAgreedAction,
    useSoftDeleteQuestionSetInstanceAnswerFile,
    useUploadQuestionSetInstanceAnswerFiles,
} from "../../../domain/viewmodels/review-reports/view-question-set-instance-review-viewmodel";
import { CheckboxFieldProps } from "../../atoms/input/SbCheckField";
import { SbSelect } from "../../atoms/input/SbSelect";
import SbTextArea from "../../atoms/input/SbTextArea";
import {
    BackButton,
    RemoveButton,
    ResolveButton,
    SaveButton,
    SbButton,
} from "../../atoms/SbButton";
import SbLabelText from "../../atoms/SbLabelText";
import SbTooltip from "../../atoms/SbTooltip";
import SbTooltipCheckField from "../../atoms/SbTooltipCheckField";
import SbTooltipSelect from "../../atoms/SbTooltipSelect";
import { translateText } from "../../helpers/translate";
import { CreateLabelToDetailRows } from "../../molecules/CreateLabelToDetailRows";
import SbFormTextAreaGroup from "../../molecules/input/SbFormTextAreaGroup";
import { SbAccordion } from "../../molecules/SbAccordion";
import SbModal from "../../molecules/SbModal";
import { ComponentPanel } from "../../molecules/SbPanel";
import { ConfirmationRow, TextConfirmationRow } from "../../organisms/ActionConfirmation";
import { AgreedActionsForm } from "../../organisms/AgreedActionsForm";
import { DataTable } from "../../organisms/DataTable";
import { QuestionSetQuestionComments } from "../../organisms/QuestionSetQuestionComments";

const StyledErrorDiv = styled.div`
    color: ${(props) => props.theme.palette.danger};
    padding-top: 5px;
`;

const StyledRemoveIcon = styled.div`
    color: ${(props) => props.theme.palette.danger};
    cursor: pointer;
`;

const StyledCenterAlignedCol = styled(Col)`
    align-content: center;
`;

const StyledFormControl = styled(Form.Control)`
    width: 300px;
    background-color: ${(props) => props.theme.palette.purewhite};
    color: ${(props) => props.theme.palette.black};
`;

const StyledResolvedDiv = styled.div`
    color: ${(props) => props.theme.palette.success};
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ViewReviewFindingContainer: React.FC = () => {
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });
    const navigate = useNavigate();
    const menu = useMenu();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const toast = useToast();
    const auth = useAuth();
    const questionSetInstanceAnswerId = Number(useParams().questionSetInstanceAnswerId);

    const [userSearchText, setUserSearchText] = useState<string | null>(null);
    const [questionSetInstanceId, setQuestionSetInstanceId] = useState<number | null>(null);
    const [agreedActions, setAgreedActions] = useState<AgreedActionDto[]>([]);
    const [openAgreedActions, setOpenAgreedActions] = useState<number>(0);
    const [removeResolveComment, setRemoveResolveComment] = useState<string>("");
    const [comment, setComment] = useState<string>("");
    const [raciUserMap, setRaciUserMap] = useState<Map<number, number>>(new Map());
    const [raciRelationshipMap, setRaciRelationshipMap] = useState<Map<number, RaciRelationship>>(
        new Map()
    );
    const [associatedRiskGroups, setAssociatedRiskGroups] = useState<AssociatedRiskGroupDto[]>([]);
    const [associatedRisksPaginationDto] = useState<BasePaginationDto>(
        createBasePaginationDto(1, 1000)
    );
    const [attachedEvidencePaginationDto, setAttachedEvidencePaginationDto] =
        useState<BasePaginationDto>(defaultPaginationDto);
    const [
        questionSetInstanceAnswerFileIdForDownload,
        setQuestionSetInstanceAnswerFileIdForDownload,
    ] = useState<number | null>(null);
    const [questionSetInstanceAnswerFileIdForDelete, setQuestionSetInstanceAnswerFileIdForDelete] =
        useState<number | null>(null);
    const [showDeleteFileConfirmationModal, setShowDeleteFileConfirmationModal] =
        useState<boolean>(false);
    const [defaultPrimaryAssociatedRisk, setDefaultPrimaryAssociatedRisk] =
        useState<QuestionSetInstanceReviewAssociatedRiskDto>(
            QuestionSetInstanceReviewAssociatedRiskDto.createDefaultQuestionSetInstanceReviewAssociatedDto(
                RiskLevel.Primary
            )
        );
    const [defaultSecondaryAssociatedRisk, setDefaultSecondaryAssociatedRisk] =
        useState<QuestionSetInstanceReviewAssociatedRiskDto>(
            QuestionSetInstanceReviewAssociatedRiskDto.createDefaultQuestionSetInstanceReviewAssociatedDto(
                RiskLevel.Secondary
            )
        );

    const questionSetInstanceAnswerDetails = useGetQuestionSetInstanceAnswerDetails(
        questionSetInstanceAnswerId
    );
    const questionSetInstanceDetails =
        useGetSingleQuestionSetInstanceDetails(questionSetInstanceId);
    const questionSetInstanceReviewDetails = useGetQuestionSetInstanceReviewDetails(
        questionSetInstanceAnswerId
    );
    const questionSetInstanceAnswerComment = useGetQuestionSetInstanceAnswerComment(
        questionSetInstanceAnswerId
    );
    const userList = useGetFilterUserDetails(associatedRisksPaginationDto, userSearchText);
    const primaryAssociatedRisks = useFilterAssociatedRisks(
        RiskLevel.Primary,
        associatedRisksPaginationDto
    );
    const secondaryAssociatedRisks = useFilterAssociatedRisks(
        RiskLevel.Secondary,
        associatedRisksPaginationDto
    );
    const tertiaryAssociatedRisks = useFilterAssociatedRisks(
        RiskLevel.Tertiary,
        associatedRisksPaginationDto
    );
    const maxAgreedActions = useLookupMaxAgreedActions();
    const maxAssociatedRisks = useLookupMaxAssociatedRisks();

    const questionSetInstanceAnswerFiles = useFilterQuestionSetInstanceAnswerFiles(
        questionSetInstanceAnswerId,
        attachedEvidencePaginationDto
    );
    const uploadQuestionSetInstanceAnswerFiles = useUploadQuestionSetInstanceAnswerFiles();
    const downloadQuestionSetInstanceAnswerFile = useDownloadQuestionSetInstanceAnswerFile(
        questionSetInstanceAnswerFileIdForDownload
    );
    const softDeleteQuestionSetInstanceAnswerFile = useSoftDeleteQuestionSetInstanceAnswerFile();

    const questionSetInstanceAnswerDetailsData = questionSetInstanceAnswerDetails?.data;
    const questionSetInstanceDetailsData = questionSetInstanceDetails.data;
    const questionSetInstanceAnswerCommentData = questionSetInstanceAnswerComment.data;
    const questionSetInstanceReviewData = questionSetInstanceReviewDetails.data;
    const primaryAssociatedRisksData = primaryAssociatedRisks.data;
    const secondaryAssociatedRisksData = secondaryAssociatedRisks.data;
    const tertiaryAssociatedRisksData = tertiaryAssociatedRisks.data;
    const maxAgreedActionsData = maxAgreedActions.data;
    const maxAssociatedRisksData = maxAssociatedRisks.data;

    const associateAgreedActionRaciUser = useAssociateAgreedActionRaciUser();
    const dissociateAgreedActionRaciUser = useDissociateAgreedActionRaciUser();
    const editReviewReportDetails = useEditReviewReportDetails();
    const addQuestionSetQuestionComment = useAddQuestionSetQuestionComment();

    const createAgreedAction = useCreateAgreedAction();
    const removeAgreedAction = useRemoveAgreedAction();
    const resolveAgreedAction = useResolveAgreedAction();

    useLoader(
        areQueriesLoading([
            questionSetInstanceAnswerDetails,
            questionSetInstanceDetails,
            questionSetInstanceReviewDetails,
            questionSetInstanceAnswerComment,
            primaryAssociatedRisks,
            secondaryAssociatedRisks,
            tertiaryAssociatedRisks,
            questionSetInstanceAnswerFiles,
            downloadQuestionSetInstanceAnswerFile,
            maxAgreedActions,
            maxAssociatedRisks,
        ]) ||
            areMutationsLoading([
                associateAgreedActionRaciUser,
                dissociateAgreedActionRaciUser,
                editReviewReportDetails,
                addQuestionSetQuestionComment,
                createAgreedAction,
                removeAgreedAction,
                resolveAgreedAction,
                uploadQuestionSetInstanceAnswerFiles,
                softDeleteQuestionSetInstanceAnswerFile,
            ]),
        ViewReviewFindingContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Checklists);
        menu.changeActiveDrawerItem(DrawerTitles.Reviews);
    }, []);

    useEffect(() => {
        setQuestionSetInstanceId(
            questionSetInstanceAnswerDetailsData?.questionSetInstanceId ?? null
        );
    }, [questionSetInstanceAnswerDetails?.data]);

    useEffect(() => {
        let agreedActionsArray = [
            ...(questionSetInstanceReviewDetails?.data?.agreedActionDtos ?? []),
        ];
        setAgreedActions(agreedActionsArray);

        let openAgreedActionsCount = 0;
        agreedActionsArray.map((agreedAction) => {
            if (agreedAction.agreedActionStatusId == AgreedActionStatus.Open) {
                openAgreedActionsCount = openAgreedActionsCount + 1;
            }
        });

        setOpenAgreedActions(openAgreedActionsCount);
        setAssociatedRiskGroups([
            ...(questionSetInstanceReviewDetails?.data?.associatedRiskGroupDtos ?? []),
        ]);
    }, [questionSetInstanceReviewDetails?.data]);

    useEffect(() => {
        setDefaultPrimaryAssociatedRisk({
            ...defaultPrimaryAssociatedRisk,
            questionSetInstanceReviewId:
                questionSetInstanceReviewDetails?.data?.questionSetInstanceReviewId ?? -1,
        });
        setDefaultSecondaryAssociatedRisk({
            ...defaultSecondaryAssociatedRisk,
            questionSetInstanceReviewId:
                questionSetInstanceReviewDetails?.data?.questionSetInstanceReviewId ?? -1,
        });
    }, [questionSetInstanceReviewDetails?.data]);

    useEffect(() => {
        if (downloadQuestionSetInstanceAnswerFile.data) {
            DownloadFile(downloadQuestionSetInstanceAnswerFile.data);
        }
    }, [
        downloadQuestionSetInstanceAnswerFile.data?.fileName,
        downloadQuestionSetInstanceAnswerFile.data?.file,
    ]);

    const toAssociatedRaciParticipantsToDataTableRows = (
        response: AgreedActionRaciUserDto[]
    ): TableRow<number>[] =>
        response!.map((agreedActionRaciUser) => ({
            metadata: agreedActionRaciUser.userId,
            columns: [
                {
                    value: `${agreedActionRaciUser.userDto.firstName} ${agreedActionRaciUser.userDto.lastName} - ${agreedActionRaciUser.userDto.emailAddress}`,
                    type: DataTableColumnTypes.Text,
                },
                {
                    value: RaciRelationship[agreedActionRaciUser.raciRelationship].toString(),
                    type: DataTableColumnTypes.Text,
                },
            ],
        }));

    const getAgreedActionRaciUserRows = (agreedAction: AgreedActionDto): TableRow<number>[] => {
        let raciUsers = agreedAction.agreedActionRaciUserDtos;

        return raciUsers ? toAssociatedRaciParticipantsToDataTableRows(raciUsers) : [];
    };

    const handleRemovingRaciParticipant = (userId: number, agreedAction: AgreedActionDto): void => {
        const raciUser = agreedAction.agreedActionRaciUserDtos.find((x) => x.userId == userId);

        if (raciUser) {
            dissociateAgreedActionRaciUser.mutate(
                new AssociateDissociateAgreedActionRaciUserDto(
                    userId,
                    agreedAction.agreedActionId,
                    raciUser.raciRelationship
                ),
                {
                    onSuccess: async (_: Response<boolean>) => {
                        toast.addToast(
                            createSuccessToastProps([
                                t("SuccessfullyDissasociatedRaciParticipantFromFinding", {
                                    keyPrefix: Reports,
                                }),
                            ])
                        );
                        questionSetInstanceDetails.refetch();
                        questionSetInstanceReviewDetails.refetch();
                    },
                    onError: errorResponseToDisplayHandler,
                }
            );
        }
    };

    const handleAddingRaciParticipant = (agreedAction: AgreedActionDto): void => {
        const userId = raciUserMap.get(agreedAction.questionSetInstanceReviewId);
        const raciRelationship =
            raciRelationshipMap.get(agreedAction.questionSetInstanceReviewId) ??
            RaciRelationship.Responsible;

        if (userId) {
            agreedAction.questionSetInstanceReviewId &&
                associateAgreedActionRaciUser.mutate(
                    new AssociateDissociateAgreedActionRaciUserDto(
                        userId,
                        agreedAction.agreedActionId,
                        raciRelationship
                    ),
                    {
                        onSuccess: async (_: Response<boolean>) => {
                            toast.addToast(
                                createSuccessToastProps([
                                    t("SuccessfullyAssignedRaciParticipantToFinding", {
                                        keyPrefix: Reports,
                                    }),
                                ])
                            );
                            await questionSetInstanceReviewDetails.refetch();
                        },
                        onError: errorResponseToDisplayHandler,
                    }
                );
        }
    };

    const handleSubmitForComments = (): void => {
        if (comment) {
            addQuestionSetQuestionComment.mutate(
                new AddQuestionSetQuestionCommentDto(
                    questionSetInstanceId!,
                    questionSetInstanceAnswerId,
                    comment!
                ),
                {
                    onSuccess: async (_: Response<boolean>) => {
                        toast.addToast(
                            createSuccessToastProps([
                                t("CommentSuccessfullySubmitted", { keyPrefix: Reports }),
                            ])
                        );
                        await questionSetInstanceDetails.refetch();
                        setComment("");
                    },
                    onError: errorResponseToDisplayHandler,
                }
            );
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        const rootCause = formData.get(ReviewTypeFields.RootCause) as string;
        const trainingOrCoaching = formData.get(ReviewTypeFields.TrainingOrCoaching) as string;
        const findingText = formData.get(ReviewTypeFields.Finding) as string;

        let editReviewReportDetailsDto: EditReviewReportDetailsDto;
        const editReviewTypeFieldsDtos: EditReviewTypeFieldValueDto[] = [];
        const editAgreedActionDtos: EditAgreedActionDto[] = [];

        editReviewTypeFieldsDtos.push(
            new EditReviewTypeFieldValueDto(ReviewTypeFields.RootCause, rootCause)
        );

        editReviewTypeFieldsDtos.push(
            new EditReviewTypeFieldValueDto(ReviewTypeFields.TrainingOrCoaching, trainingOrCoaching)
        );

        agreedActions.map((agreedAction) => {
            const agreedActionValue = formData.get(
                `${ReviewTypeFields.AgreedActions}${agreedAction.agreedActionId}`
            ) as string;

            const actionItemDueDate = new Date(
                formData.get(
                    `${ReviewTypeFields.ActionItemDueDate}${agreedAction.agreedActionId}`
                ) as string
            );

            editAgreedActionDtos.push(
                new EditAgreedActionDto(
                    agreedAction.agreedActionId,
                    actionItemDueDate,
                    agreedActionValue
                )
            );
        });

        editReviewReportDetailsDto = new EditReviewReportDetailsDto(
            questionSetInstanceId!,
            questionSetInstanceAnswerId,
            questionSetInstanceReviewData!.questionSetInstanceReviewId,
            findingText,
            editAgreedActionDtos,
            editReviewTypeFieldsDtos,
            associatedRiskGroups
        );

        if (
            hasDuplicates(
                associatedRiskGroups.map((x) => x.secondaryAssociatedRisk.associatedRiskId)
            )
        ) {
            toast.addToast(
                createErrorToastProps([
                    t("AssociatedRiskDuplicateErrorValidation", { keyPrefix: Reports }),
                ])
            );
            return;
        }

        editReviewReportDetails.mutate(editReviewReportDetailsDto, {
            onSuccess: async (_: Response<boolean>) => {
                toast.addToast(
                    createSuccessToastProps([t("DetailsSaved", { keyPrefix: Reports })])
                );
                await questionSetInstanceReviewDetails.refetch();
                await questionSetInstanceAnswerDetails.refetch();
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    const getComments = (): QuestionSetQuestionCommentDto[] | null =>
        questionSetInstanceDetailsData?.questionSetQuestionCommentDtos.filter(
            (x) => x.questionSetInstanceAnswerId === questionSetInstanceAnswerId
        ) ?? null;

    const confirmationRows = (): ConfirmationRow<any>[] => [
        new TextConfirmationRow(
            t("Checklist"),
            questionSetInstanceAnswerDetailsData?.checklistName
        ),
        new TextConfirmationRow(
            t("Reviewer", { keyPrefix: Reports }),
            questionSetInstanceAnswerDetailsData?.userFullName
        ),
        new TextConfirmationRow(
            t("TestObjective", { keyPrefix: Reports }),
            questionSetInstanceAnswerDetailsData?.questionText
        ),
        new TextConfirmationRow(
            t("Answer", { keyPrefix: ChecklistAnswers }),
            questionSetInstanceAnswerDetailsData?.answerValue
        ),
    ];

    const isRemoveAssociatedRiskGroupDisabled = (): boolean => associatedRiskGroups.length <= 1;
    const isMaxAgreedActions = (): boolean => agreedActions.length >= maxAgreedActionsData!;
    const isMaxAssociatedRisks = (): boolean =>
        associatedRiskGroups.length >= maxAssociatedRisksData!;
    const isFindingStatusValidToAddAgreedActionAndAssociatedRisk = (): boolean =>
        questionSetInstanceReviewData
            ? questionSetInstanceReviewData.reviewStatus == ReviewStatus.InProgress ||
              questionSetInstanceReviewData.reviewStatus == ReviewStatus.Review
            : false;

    const hasDuplicates = (associatedRiskIds: number[]) => {
        return new Set(associatedRiskIds).size < associatedRiskIds.length;
    };

    const addAgreedActionComponent = (): void => {
        let newAgreedActionDto = {
            agreedActionId: -1,
            questionSetInstanceReviewId: questionSetInstanceReviewData!.questionSetInstanceReviewId,
            agreedActionValue: "",
            agreedActionStatusId: AgreedActionStatus.Open,
            agreedActionRaciUserDtos: [],
            actionItemDueDate: new Date(),
        };

        createAgreedAction.mutate(newAgreedActionDto.questionSetInstanceReviewId, {
            onSuccess: async (_: Response<number>) => {
                await questionSetInstanceReviewDetails.refetch();
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    const addAssociatedRiskGroupComponent = (): void => {
        let newAssociatedRiskGroupDto = {
            primaryAssociatedRisk: defaultPrimaryAssociatedRisk,
            secondaryAssociatedRisk: defaultSecondaryAssociatedRisk,
            tertiaryAssociatedRisks: [],
        };

        associatedRiskGroups.push(newAssociatedRiskGroupDto);
        setAssociatedRiskGroups([...associatedRiskGroups]);
    };

    const getRootCauseDefaultValue = (): string => {
        return (
            questionSetInstanceReviewData?.reviewTypeFieldsValues.find((y) =>
                y.reviewTypeField.fieldName
                    ?.toLowerCase()
                    .includes(ReviewTypeFields.RootCause.toLowerCase())
            )?.value ??
            questionSetInstanceAnswerDetailsData?.childAnswerDetails.find((y) =>
                y.questionText?.toLowerCase().includes(ReviewTypeFields.RootCause.toLowerCase())
            )?.comment ??
            ""
        );
    };

    const getTrainingOrCoachingDefaultValue = (): string => {
        return (
            questionSetInstanceReviewData?.reviewTypeFieldsValues.find(
                (y) =>
                    y.reviewTypeField.fieldName
                        ?.toLowerCase()
                        .includes(ReviewTypeFields.TrainingOrCoaching.toLowerCase()) ||
                    y.reviewTypeField.fieldName
                        ?.toLowerCase()
                        .includes(ReviewTypeFields.TrainingOrCoachingWithoutSpaces.toLowerCase())
            )?.value ??
            questionSetInstanceAnswerDetailsData?.childAnswerDetails.find(
                (y) =>
                    y.questionText
                        ?.toLowerCase()
                        .includes(ReviewTypeFields.TrainingOrCoaching.toLowerCase()) ||
                    y.questionText
                        ?.toLowerCase()
                        .includes(ReviewTypeFields.TrainingOrCoachingWithoutSpaces.toLowerCase())
            )?.comment ??
            ""
        );
    };

    const removeAgreedActionComponent = (agreedAction: AgreedActionDto): void => {
        if (openAgreedActions <= 1) {
            return;
        }

        removeAgreedAction.mutate(
            new RemoveResolveAgreedActionDto(
                questionSetInstanceId!,
                questionSetInstanceAnswerId,
                removeResolveComment,
                agreedAction.agreedActionId
            ),
            {
                onSuccess: async (_: Response<boolean>) => {
                    await questionSetInstanceReviewDetails.refetch();
                    await questionSetInstanceDetails.refetch();
                    setRemoveResolveComment("");
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    const resolveAgreedActionComponent = (agreedAction: AgreedActionDto): void => {
        if (openAgreedActions <= 1) {
            return;
        }

        resolveAgreedAction.mutate(
            new RemoveResolveAgreedActionDto(
                questionSetInstanceId!,
                questionSetInstanceAnswerId,
                removeResolveComment,
                agreedAction.agreedActionId
            ),
            {
                onSuccess: async (_: Response<boolean>) => {
                    await questionSetInstanceReviewDetails.refetch();
                    await questionSetInstanceDetails.refetch();
                    setRemoveResolveComment("");
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    const removeAssociateRiskGroupComponent = (
        associatedRiskGroup: AssociatedRiskGroupDto
    ): void => {
        if (isRemoveAssociatedRiskGroupDisabled()) {
            return;
        }

        const index = associatedRiskGroups.indexOf(associatedRiskGroup);

        associatedRiskGroups.splice(index, 1);
        setAssociatedRiskGroups([...associatedRiskGroups]);
    };

    const replaceSelectedRaciUser = (
        raciUser: SingleValue<BaseUserDto>,
        agreedAction: AgreedActionDto
    ) => {
        if (raciUser) {
            setRaciUserMap(
                (prevMap) =>
                    new Map(prevMap.set(agreedAction.questionSetInstanceReviewId, raciUser.userId))
            );
        }
    };

    const replaceSelectedRaciRelationship = (
        raciRelationship: SingleValue<ReactSelectDto<RaciRelationship>>,
        agreedAction: AgreedActionDto
    ) => {
        if (raciRelationship) {
            setRaciRelationshipMap(
                (prevMap) =>
                    new Map(
                        prevMap.set(
                            agreedAction.questionSetInstanceReviewId,
                            raciRelationship.value
                        )
                    )
            );
        }
    };

    const getDefaultPrimaryRiskSelectedItem = (
        associatedRiskGroupDto: AssociatedRiskGroupDto
    ): AssociatedRiskDto | undefined => {
        return primaryAssociatedRisksData?.find(
            (x) =>
                x.associatedRiskId == associatedRiskGroupDto.primaryAssociatedRisk.associatedRiskId
        );
    };

    const getDefaultSecondaryRiskSelectedItem = (
        associatedRiskGroupDto: AssociatedRiskGroupDto
    ): AssociatedRiskDto | undefined => {
        return secondaryAssociatedRisksData?.find(
            (x) =>
                x.associatedRiskId ==
                associatedRiskGroupDto.secondaryAssociatedRisk.associatedRiskId
        );
    };

    const getSecondaryRiskItems = (
        associatedRiskGroupDto: AssociatedRiskGroupDto
    ): AssociatedRiskDto[] | undefined => {
        return secondaryAssociatedRisksData?.filter(
            (x) =>
                x.parentAssociatedRiskId ==
                associatedRiskGroupDto.primaryAssociatedRisk.associatedRiskId
        );
    };

    const getAssociatedTertiaryRiskItems = (associatedRiskGroupDto: AssociatedRiskGroupDto) =>
        tertiaryAssociatedRisksData?.filter(
            (associatedRisk) =>
                associatedRiskGroupDto.secondaryAssociatedRisk.associatedRiskId ==
                associatedRisk.parentAssociatedRiskId
        ) ?? [];

    const getTertiaryRiskItemFieldProps = (
        associatedRiskGroupDto: AssociatedRiskGroupDto
    ): CheckboxFieldProps[] => {
        const associatedRiskIds = new Set(
            associatedRiskGroupDto.tertiaryAssociatedRisks.map(
                ({ associatedRiskId }) => associatedRiskId
            )
        );

        return (
            getAssociatedTertiaryRiskItems(associatedRiskGroupDto).map((associatedRisk) => ({
                name: associatedRisk.name,
                label: associatedRisk.name,
                defaultSelected: associatedRiskIds.has(associatedRisk.associatedRiskId),
            })) ?? []
        );
    };

    const onUploadFile = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        const { files } = event.target;
        const selectedFiles = files as FileList;
        const file = selectedFiles?.[0];

        const fileBase64String = await getBase64FileString(file);

        if (fileBase64String === null) {
            toast.addToast(
                createErrorToastProps([t("FailedToReadFile", { keyPrefix: ActionPlans })])
            );
        } else {
            uploadQuestionSetInstanceAnswerFiles.mutate(
                new QuestionAnswerFileUploadDto(
                    questionSetInstanceAnswerId!,
                    file,
                    fileBase64String
                ),
                {
                    onSuccess: async () => {
                        toast.addToast(
                            createSuccessToastProps([
                                t("EvidenceSuccessfullyUploaded", { keyPrefix: ActionPlans }),
                            ])
                        );

                        queryClient.refetchQueries([
                            "filterQuestionSetInstanceAnswerFiles",
                            questionSetInstanceAnswerId,
                        ]);
                    },
                    onError: errorResponseToDisplayHandler,
                }
            );
        }

        event.target.value = "";
    };

    const onDownloadFile = (metadata: QuestionSetInstanceAnswerFileDto): void =>
        setQuestionSetInstanceAnswerFileIdForDownload(metadata.questionSetInstanceAnswerFileId);

    const onDeleteItem = (metadata: QuestionSetInstanceAnswerFileDto): void => {
        setQuestionSetInstanceAnswerFileIdForDelete(metadata.questionSetInstanceAnswerFileId);
        setShowDeleteFileConfirmationModal(true);
    };

    const onRemoveFile = () =>
        softDeleteQuestionSetInstanceAnswerFile.mutate(questionSetInstanceAnswerFileIdForDelete!, {
            onSuccess: async () => {
                toast.addToast(
                    createSuccessToastProps([
                        t("EvidenceSuccessfullyRemoved", { keyPrefix: Common }),
                    ])
                );

                queryClient.refetchQueries([
                    "filterQuestionSetInstanceAnswerFiles",
                    questionSetInstanceAnswerId,
                ]);
            },
            onError: errorResponseToDisplayHandler,
        });

    const onPrimaryAssociatedRiskChange = (
        option: SingleValue<AssociatedRiskDto>,
        associatedRiskGroupDto: AssociatedRiskGroupDto
    ) => {
        associatedRiskGroupDto.primaryAssociatedRisk = {
            ...option!,
            questionSetInstanceReviewId:
                associatedRiskGroupDto.primaryAssociatedRisk.questionSetInstanceReviewId,
        };

        associatedRiskGroupDto.secondaryAssociatedRisk = defaultSecondaryAssociatedRisk;
        associatedRiskGroupDto.tertiaryAssociatedRisks = [];

        setAssociatedRiskGroups([...associatedRiskGroups]);
    };

    const onSecondaryAssociatedRiskChange = (
        option: SingleValue<AssociatedRiskDto>,
        associatedRiskGroupDto: AssociatedRiskGroupDto
    ) => {
        associatedRiskGroupDto.secondaryAssociatedRisk = {
            ...option!,
            questionSetInstanceReviewId:
                associatedRiskGroupDto.secondaryAssociatedRisk.questionSetInstanceReviewId,
        };

        associatedRiskGroupDto.tertiaryAssociatedRisks = [];

        setAssociatedRiskGroups([...associatedRiskGroups]);
    };

    const renderQuestionDetails = (): JSX.Element => (
        <ContentContainer>{CreateLabelToDetailRows(confirmationRows(), 2, 10)}</ContentContainer>
    );

    const renderRaciParticipantsForm = (agreedAction: AgreedActionDto): JSX.Element => {
        return (
            <Form.Group as={Row} className="mb-3">
                <StyledFormLeftAlignedLabel column sm={2}>
                    <SbLabelText label={`${t("RaciParticipants", { keyPrefix: Common })}:`} />
                </StyledFormLeftAlignedLabel>
                <Col md={8}>
                    <Form.Group as={Row} className="mb-3">
                        <Col md={3}>
                            <SbSelect
                                name={"raciRelationship"}
                                styles={maxContentWidthSelectStyle}
                                placeholderText={`${t("PleaseSelect", { keyPrefix: Common })}`}
                                searchable={false}
                                clearable={false}
                                defaultSelectedItem={createReactSelectDto(
                                    RaciRelationship.Responsible,
                                    RaciRelationship[RaciRelationship.Responsible]
                                )}
                                items={getEnumsForType(RaciRelationship).map((x) =>
                                    createReactSelectDto(x, RaciRelationship[x])
                                )}
                                itemLabel={(option: ReactSelectDto<RaciRelationship>) =>
                                    t(option.label, { keyPrefix: ActionItems })
                                }
                                itemValue={(option: ReactSelectDto<RaciRelationship>) =>
                                    option.value.toString()
                                }
                                onChange={(option) => {
                                    replaceSelectedRaciRelationship(option, agreedAction);
                                }}
                            />
                        </Col>
                        <Col md={7}>
                            <SbSelect
                                name={"raciUser"}
                                styles={maxContentWidthSelectStyle}
                                placeholderText={`${t("PleaseSelect", { keyPrefix: Common })}`}
                                searchable
                                clearable
                                loading={userList.isFetching}
                                items={userList.data}
                                itemLabel={(option: BaseUserDto) =>
                                    `${option.firstName} ${option.lastName} - ${option.emailAddress}`
                                }
                                itemValue={(option: BaseUserDto) => option.userId.toString()}
                                onSearchTextChanged={(text: string) => setUserSearchText(text)}
                                onChange={(option) => {
                                    replaceSelectedRaciUser(option, agreedAction);
                                }}
                            />
                        </Col>
                        <Col md={"auto"}>
                            <SbButton
                                label={t("Assign", { keyPrefix: Common })}
                                variant={"primary"}
                                type={"button"}
                                onClick={() => {
                                    handleAddingRaciParticipant(agreedAction);
                                }}
                            />
                        </Col>
                    </Form.Group>

                    <DataTable
                        rows={getAgreedActionRaciUserRows(agreedAction)}
                        deleteItem={(userId: number) => {
                            handleRemovingRaciParticipant(userId, agreedAction);
                        }}
                    />
                </Col>
            </Form.Group>
        );
    };

    const getTertiaryTooltipByName = (
        associatedRiskGroupDto: AssociatedRiskGroupDto,
        name: string
    ) =>
        getAssociatedTertiaryRiskItems(associatedRiskGroupDto).find((x) => x.name == name)
            ?.tooltip ?? "";

    const handleTertiarySelection = (
        associatedRiskGroupDto: AssociatedRiskGroupDto,
        name: string
    ) => {
        const associatedRisk = getAssociatedTertiaryRiskItems(associatedRiskGroupDto).find(
            (x) => x.name == name
        );

        if (!associatedRisk) {
            return;
        }

        const questionSetInstanceReviewAssociatedRisk = {
            ...associatedRisk!,
            questionSetInstanceReviewId: questionSetInstanceReviewData!.questionSetInstanceReviewId,
        };
        associatedRiskGroupDto.tertiaryAssociatedRisks.push(
            questionSetInstanceReviewAssociatedRisk
        );
    };

    const renderTertiaryAssociatedRisks = (
        associatedRiskGroupDto: AssociatedRiskGroupDto
    ): JSX.Element => (
        <Row>
            {getTertiaryRiskItemFieldProps(associatedRiskGroupDto).map((x) => (
                <Col md={4}>
                    <SbTooltipCheckField
                        name={x.name}
                        type={"checkbox"}
                        label={x.label}
                        defaultSelected={x.defaultSelected}
                        disabled={x.disabled}
                        onChangeHandler={(name: string) =>
                            handleTertiarySelection(associatedRiskGroupDto, name)
                        }
                        tooltip={getTertiaryTooltipByName(associatedRiskGroupDto, x.name)}
                    />
                </Col>
            ))}
        </Row>
    );

    const renderAssociatedRisks = () => {
        return associatedRiskGroups.map((associatedRiskGroupDto) => {
            return (
                <Row>
                    <Col md={11}>
                        <ComponentPanel>
                            <Row>
                                <StyledCenterAlignedCol md={"auto"}>
                                    <StyledFormLabelNoMargin>
                                        <SbLabelText label={t("Primary", { keyPrefix: Reports })} />
                                    </StyledFormLabelNoMargin>
                                </StyledCenterAlignedCol>

                                <Col md={3}>
                                    <SbTooltipSelect
                                        name={"PrimaryRisk"}
                                        placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                                        defaultSelectedItem={getDefaultPrimaryRiskSelectedItem(
                                            associatedRiskGroupDto
                                        )}
                                        searchable
                                        clearable={false}
                                        items={primaryAssociatedRisksData}
                                        itemLabel={(option: AssociatedRiskDto) => option.name}
                                        onChange={(option: SingleValue<AssociatedRiskDto>) => {
                                            onPrimaryAssociatedRiskChange(
                                                option,
                                                associatedRiskGroupDto
                                            );
                                        }}
                                        value={
                                            associatedRiskGroupDto.primaryAssociatedRisk.name != ""
                                                ? associatedRiskGroupDto.primaryAssociatedRisk
                                                : undefined
                                        }
                                        tooltip={
                                            associatedRiskGroupDto.primaryAssociatedRisk.tooltip
                                        }
                                    />
                                </Col>
                                <StyledCenterAlignedCol md={"auto"}>
                                    <StyledFormLabelNoMargin>
                                        <SbLabelText
                                            label={t("Secondary", { keyPrefix: Reports })}
                                        />
                                    </StyledFormLabelNoMargin>
                                </StyledCenterAlignedCol>

                                <Col md={3}>
                                    <SbTooltipSelect
                                        name={"SecondaryRisk"}
                                        placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                                        defaultSelectedItem={getDefaultSecondaryRiskSelectedItem(
                                            associatedRiskGroupDto
                                        )}
                                        searchable
                                        clearable={false}
                                        items={getSecondaryRiskItems(associatedRiskGroupDto)}
                                        itemLabel={(option: AssociatedRiskDto) => option.name}
                                        onChange={(option: SingleValue<AssociatedRiskDto>) => {
                                            onSecondaryAssociatedRiskChange(
                                                option,
                                                associatedRiskGroupDto
                                            );
                                        }}
                                        value={
                                            associatedRiskGroupDto.secondaryAssociatedRisk.name !=
                                            ""
                                                ? associatedRiskGroupDto.secondaryAssociatedRisk
                                                : undefined
                                        }
                                        disabled={
                                            associatedRiskGroupDto.primaryAssociatedRisk
                                                .associatedRiskId == -1
                                        }
                                        tooltip={
                                            associatedRiskGroupDto.secondaryAssociatedRisk.tooltip
                                        }
                                    />
                                </Col>
                            </Row>
                            <SectionVerticalSpace />
                            <Row>
                                <Col md={"auto"}>
                                    <StyledFormLabel>
                                        <SbLabelText
                                            label={t("Tertiary", { keyPrefix: Reports })}
                                        />
                                    </StyledFormLabel>
                                </Col>
                                <Col>{renderTertiaryAssociatedRisks(associatedRiskGroupDto)}</Col>
                            </Row>
                        </ComponentPanel>
                    </Col>
                    <StyledCenterAlignedCol md={1}>
                        {!isRemoveAssociatedRiskGroupDisabled() && (
                            <StyledRemoveIcon>
                                <IoCloseSharp
                                    onClick={() =>
                                        removeAssociateRiskGroupComponent(associatedRiskGroupDto)
                                    }
                                />
                            </StyledRemoveIcon>
                        )}
                    </StyledCenterAlignedCol>
                    <SectionVerticalSpace />
                </Row>
            );
        });
    };

    const renderAgreedActions = () => {
        return agreedActions.map((agreedAction, index) => {
            return (
                <>
                    <SbAccordion
                        isCollapsedOnLoad
                        title={`${t("AgreedActions", {
                            keyPrefix: Reports,
                        })} - ${index + 1}`}
                        boldTitle
                        secondaryVariant
                    >
                        <ComponentPanel>
                            <Row>
                                <AgreedActionsForm agreedAction={agreedAction} />
                            </Row>
                            <Row>{renderRaciParticipantsForm(agreedAction)}</Row>
                        </ComponentPanel>
                        <SectionVerticalSpace />

                        {agreedAction.agreedActionStatusId == AgreedActionStatus.Resolved ? (
                            <EndAlignedDiv>
                                <StyledResolvedDiv>
                                    <IoCheckmarkDoneCircleOutline size={18} />
                                    Resolved
                                </StyledResolvedDiv>
                            </EndAlignedDiv>
                        ) : (
                            <>
                                {openAgreedActions > 1 && (
                                    <>
                                        <StyledFormLabel>
                                            <SbLabelText
                                                label={t("RemoveResolveComment", {
                                                    keyPrefix: Reports,
                                                })}
                                            />
                                        </StyledFormLabel>
                                        <SbTextArea
                                            key={agreedAction.agreedActionId}
                                            name={`Comment ${agreedAction.agreedActionId}`}
                                            maxLength={500}
                                            onChange={(commentText) => {
                                                setRemoveResolveComment(commentText ?? "");
                                            }}
                                        />
                                        <SmallVerticalSpace />
                                    </>
                                )}

                                <EndAlignedDiv>
                                    <RemoveButton
                                        type="button"
                                        onClick={() => {
                                            removeAgreedActionComponent(agreedAction);
                                        }}
                                        disabled={openAgreedActions <= 1}
                                    />
                                    <ResolveButton
                                        type="button"
                                        onClick={() => {
                                            resolveAgreedActionComponent(agreedAction);
                                        }}
                                        disabled={openAgreedActions <= 1}
                                    />
                                </EndAlignedDiv>

                                {openAgreedActions <= 1 && (
                                    <EndAlignedDiv>
                                        <StyledErrorDiv>
                                            {t("OneAgreedActionPerFindingRequired", {
                                                keyPrefix: Reports,
                                            })}
                                        </StyledErrorDiv>
                                    </EndAlignedDiv>
                                )}
                            </>
                        )}
                    </SbAccordion>
                    <SectionVerticalSpace />
                </>
            );
        });
    };

    const renderDetails = (): JSX.Element => (
        <SbAccordion title={t("Details", { keyPrefix: Common })} isCollapsedOnLoad>
            {questionSetInstanceAnswerDetailsData?.answerValue?.toString() ===
                ReviewTypeFields.Finding && (
                <Row>
                    <SbFormTextAreaGroup
                        name={ReviewTypeFields.Finding}
                        label={translateText(t, ReviewTypeFields.Finding, Reports)}
                        required
                        maxLength={3000}
                        defaultValue={questionSetInstanceAnswerCommentData?.text}
                        expand
                        textAlign={"left"}
                    />
                </Row>
            )}
            <SectionVerticalSpace />

            {renderAgreedActions()}
            <SectionVerticalSpace />

            <EndAlignedDiv>
                <SbButton
                    variant="primary"
                    label={t("AddActionItem", { keyPrefix: ActionItems })}
                    type={"button"}
                    onClick={addAgreedActionComponent}
                    disabled={
                        isMaxAgreedActions() ||
                        !isFindingStatusValidToAddAgreedActionAndAssociatedRisk()
                    }
                />
            </EndAlignedDiv>
            {isMaxAgreedActions() && (
                <EndAlignedDiv>
                    <StyledErrorDiv>
                        {t("MaxAgreedActionsReached", { keyPrefix: Reports })}
                    </StyledErrorDiv>
                </EndAlignedDiv>
            )}
            <SectionVerticalSpace />

            <Row>
                <SbFormTextAreaGroup
                    name={ReviewTypeFields.RootCause}
                    label={translateText(t, ReviewTypeFields.RootCause, Reports)}
                    required
                    maxLength={3000}
                    defaultValue={getRootCauseDefaultValue()}
                    expand
                    textAlign={"left"}
                />
            </Row>

            <Row>
                <SbFormTextAreaGroup
                    name={ReviewTypeFields.TrainingOrCoaching}
                    label={translateText(t, ReviewTypeFields.TrainingOrCoaching, Reports)}
                    required
                    maxLength={3000}
                    defaultValue={getTrainingOrCoachingDefaultValue()}
                    expand
                    textAlign={"left"}
                />
            </Row>

            <Row>
                <Col md={"auto"}>
                    <StyledFormLabel>
                        <SbLabelText
                            label={translateText(t, ReviewTypeFields.AssociatedRisk, Reports)}
                        />
                    </StyledFormLabel>
                </Col>
                <Col md={"auto"}>
                    <SbTooltip tooltip={t("AssociatedRiskTooltip", { keyPrefix: Reports })} />
                </Col>
            </Row>
            <SectionVerticalSpace />

            {renderAssociatedRisks()}

            <>{associatedRiskGroups.length <= 0 && addAssociatedRiskGroupComponent()}</>

            <SectionVerticalSpace />

            <EndAlignedDiv>
                <SbButton
                    variant="primary"
                    label={t("AddRisk", { keyPrefix: Reports })}
                    type={"button"}
                    onClick={addAssociatedRiskGroupComponent}
                    disabled={
                        isMaxAssociatedRisks() ||
                        !isFindingStatusValidToAddAgreedActionAndAssociatedRisk()
                    }
                />
            </EndAlignedDiv>
            {isMaxAssociatedRisks() && (
                <EndAlignedDiv>
                    <StyledErrorDiv>
                        {t("MaxAssociatedRisksReached", { keyPrefix: Reports })}
                    </StyledErrorDiv>
                </EndAlignedDiv>
            )}
            <SectionVerticalSpace />
        </SbAccordion>
    );

    const renderComments = (): JSX.Element => (
        <SbAccordion title={t("Comments", { keyPrefix: ActionItems })} isCollapsedOnLoad>
            <Row>
                <QuestionSetQuestionComments
                    comments={getComments}
                    metadata={questionSetInstanceAnswerId}
                    authUserId={auth.userId!}
                />
            </Row>
            <Row>
                <Col md={1}>
                    <StyledFormLabel>
                        <SbLabelText label={translateText(t, "Comments", ActionItems)} />
                    </StyledFormLabel>
                </Col>
                <Col md={10}>
                    <SbTextArea
                        name={"Comment"}
                        maxLength={1500}
                        onChange={(commentText) => {
                            setComment(commentText ?? "");
                        }}
                        value={comment}
                    />
                </Col>
                <Col md={"auto"}>
                    <SbButton
                        variant={"primary"}
                        type="button"
                        label={t("Add", { keyPrefix: Common })}
                        icon={FaPlusCircle}
                        iconSize="lg"
                        onClick={handleSubmitForComments}
                    />
                </Col>
            </Row>
        </SbAccordion>
    );

    const renderAttachedEvidence = (): JSX.Element => (
        <SbAccordion title={t("AttachedEvidence", { keyPrefix: ActionItems })} isCollapsedOnLoad>
            {isQuerySuccessful(questionSetInstanceAnswerFiles) && (
                <>
                    <SectionVerticalSpace />
                    <StyledFormControl name="file" type="file" onChange={onUploadFile} />
                    <StyledVariantText $variant="secondary">
                        ({AllowedExtensionsForActionPlan.toString().replaceAll(",", " | ")})
                    </StyledVariantText>

                    <DataTable
                        rows={questionSetInstanceAnswerFiles.data!.rows}
                        columns={questionSetInstanceAnswerFileColumnNames}
                        downloadFileItem={onDownloadFile}
                        deleteItem={onDeleteItem}
                        totalItems={questionSetInstanceAnswerFiles.data!.recordCount}
                        paginationDto={attachedEvidencePaginationDto}
                        setPaginationDto={setAttachedEvidencePaginationDto}
                        noResultsMessage={t("NoRecordsFound", { keyPrefix: Common })}
                    />
                </>
            )}
        </SbAccordion>
    );

    return (
        <>
            <PageHeading>{t("ReviewFindingsDetail")}</PageHeading>
            <SectionVerticalSpace />

            {areQueriesSuccessful([
                questionSetInstanceAnswerDetails,
                questionSetInstanceDetails,
                questionSetInstanceReviewDetails,
                questionSetInstanceAnswerComment,
                primaryAssociatedRisks,
                secondaryAssociatedRisks,
                tertiaryAssociatedRisks,
                maxAgreedActions,
                maxAssociatedRisks,
            ]) && (
                <Form onSubmit={handleSubmit}>
                    <SbModal
                        title={`${t("DeleteAttachment", { keyPrefix: Common })}`}
                        body={`${t("DeleteAttachmentConfirmation", { keyPrefix: Common })}`}
                        primaryButtonLabel={`${t("Yes", { keyPrefix: Common })}`}
                        secondaryButtonLabel={`${t("No", { keyPrefix: Common })}`}
                        onPrimaryButtonClicked={onRemoveFile}
                        isVisible={showDeleteFileConfirmationModal}
                        updateIsVisible={(visible) => setShowDeleteFileConfirmationModal(visible)}
                    />

                    {renderQuestionDetails()}
                    <SectionVerticalSpace />

                    {renderDetails()}
                    <SectionVerticalSpace />

                    {renderComments()}
                    <SectionVerticalSpace />

                    {renderAttachedEvidence()}
                    <SectionVerticalSpace />

                    <EndAlignedDiv>
                        <BackButton onClick={() => navigate(-1)} />
                        <SaveButton type={"submit"} />
                    </EndAlignedDiv>
                </Form>
            )}
        </>
    );
};

export default ViewReviewFindingContainer;
