import { HTTPError } from "ky";
import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IconType } from "react-icons";
import { FaLevelDownAlt, FaLevelUpAlt } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    DemoteChecklistEvent,
    PromoteChecklistEvent,
} from "../../../core/constants/application-insights-events";
import { ChecklistQuestions, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { isMutationLoading } from "../../../core/utilities/responseStateHelper";
import { useToggleChecklistPromoteDemote } from "../../../domain/viewmodels/checklists/toggle-checklist-viewmodel";
import { SbButton } from "../../atoms/SbButton";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import {
    ActionConfirmationFragment,
    AlertProps,
    createPrimaryAlertProps,
    createWarningAlertProps,
} from "../../organisms/ActionConfirmationFragment";

export interface PromoteDemoteConfirmationProps {
    name: string;
    description: string;
    isTraining: boolean;
}

const PromoteDemoteChecklistContainer: React.FC = () => {
    const menu = useMenu();
    const location = useLocation();
    const navigate = useNavigate();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const checklistId = Number(useParams().checklistId);
    const { name, description, isTraining } = location.state as PromoteDemoteConfirmationProps; // TODO: Fetching Checklist data on this page vs passing it through from the previous page

    const toggleChecklistPromoteDemote = useToggleChecklistPromoteDemote();

    useLoader(isMutationLoading(toggleChecklistPromoteDemote), PromoteDemoteChecklistContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Checklists);
    }, []);

    const getPageHeading = (): string => getPromoteDemoteText();
    const getPanelTitle = (): string => getPromoteDemoteText();
    const getButtonLabel = (): string => getPromoteDemoteText();
    const getPromoteDemoteText = (): string => (isTraining ? t("Promote") : t("Demote"));
    const getTrainingStatus = (): string => (isTraining ? "off" : "on");

    const getButtonIcon = (): IconType => (isTraining ? FaLevelUpAlt : FaLevelDownAlt);

    const handleToggleChecklistPromoteDemote = (): void => {
        const eventType = isTraining ? PromoteChecklistEvent : DemoteChecklistEvent;

        toggleChecklistPromoteDemote.mutate(
            { checklistId: checklistId, isTraining: !isTraining },
            {
                onSuccess: async () => {
                    trackAppInsightsEvent(auth.email, window.location.href, eventType);
                    navigate(-1);
                },
                onError: (error: HTTPError) => {
                    trackAppInsightsException(auth.email, window.location.href, eventType, error);
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    };

    const buildDeleteButton = (): ReactElement<typeof Button> => (
        <SbButton
            variant={isTraining ? "primary" : "warning"}
            type="button"
            label={getButtonLabel()}
            icon={getButtonIcon()}
            onClick={handleToggleChecklistPromoteDemote}
        />
    );

    const buildConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow(t("Name"), name),
        new TextConfirmationRow(t("Description", { keyPrefix: Common }), description),
    ];

    const buildAlert = (): AlertProps => {
        const messages = [
            `${t("PromoteChecklistAssignmentConfirmation", {
                promoteDemoteText: getPromoteDemoteText(),
                trainingStatus: getTrainingStatus(),
            })}`,
        ];
        return isTraining ? createPrimaryAlertProps(messages) : createWarningAlertProps(messages);
    };

    return (
        <ActionConfirmationFragment
            pageHeading={getPageHeading()}
            panelTitle={getPanelTitle()}
            alert={buildAlert()}
            rows={buildConfirmationRows()}
            primaryButton={buildDeleteButton()}
        />
    );
};

export default PromoteDemoteChecklistContainer;
