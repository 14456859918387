import { Formik } from "formik";
import { HTTPError } from "ky";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";
import { CreateChecklistEvent } from "../../../core/constants/application-insights-events";
import { ChecklistQuestions, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
    StyledHiddenOverflowFormikForm,
} from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { convertBooleanStringToBoolean } from "../../../core/utilities/convertToBoolean";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { CreateChecklistDto } from "../../../domain/dtos/checklists/create-checklist-dto";
import { Response } from "../../../domain/responses/common/response-response";
import { useCreateChecklist } from "../../../domain/viewmodels/checklists/create-checklist-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import { SbRibbon } from "../../atoms/SbRibbon";
import SbFormikCheckFieldGroup from "../../molecules/input/SbFormikCheckFieldGroup";
import SbFormikTextAreaGroup from "../../molecules/input/SbFormikTextAreaGroup";
import SbFormikTextFieldGroup from "../../molecules/input/SbFormikTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const CreateChecklistContainer: React.FC = () => {
    const [isTraining, setIsTraining] = useState(false);

    const menu = useMenu();
    const navigate = useNavigate();
    const auth = useAuth();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const createChecklist = useCreateChecklist();

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Checklists);
    }, []);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Checklists);
    }, []);

    const handleIsTrainingChange = (event: React.ChangeEvent<HTMLFormElement>): void => {
        setIsTraining(convertBooleanStringToBoolean(event.target.checked));
    };

    const handleSubmit = (dto: CreateChecklistDto): void => {
        createChecklist.mutate(dto, {
            onSuccess: async (response: Response<number>) => {
                trackAppInsightsEvent(auth.email, window.location.href, CreateChecklistEvent);
                navigate(`${getPath(AccordionTitles.Checklists)}/${response.data}`);
            },

            onError: (error: HTTPError) => {
                trackAppInsightsException(
                    auth.email,
                    window.location.href,
                    CreateChecklistEvent,
                    error
                );
                errorResponseToDisplayHandler(error);
            },
        });
    };

    const createChecklistSchema = (): object => {
        return object({
            name: string().max(100, "Must be 100 characters or less").required("Name is required"),
            description: string().max(250, "Must be 250 characters or less"),
        });
    };

    return (
        <>
            <PageHeading>{t("ChecklistCreateTitle")}</PageHeading>
            <PageSubHeading>{t("ChecklistCreateHeaderHelpText")}</PageSubHeading>
            <SectionVerticalSpace />

            <TextTitledPanel title={t("CreateChecklist")}>
                <Formik
                    initialValues={new CreateChecklistDto("", "", false)}
                    onSubmit={handleSubmit}
                    validationSchema={createChecklistSchema}
                >
                    {({ handleChange, errors, touched }) => (
                        <StyledHiddenOverflowFormikForm>
                            {isTraining && (
                                <SbRibbon
                                    size={"large"}
                                    label={t("Training", { keyPrefix: Common })}
                                />
                            )}

                            <SbFormikTextFieldGroup
                                name="name"
                                label={t("Name")}
                                required
                                onFormikChange={handleChange}
                                type="text"
                                error={errors.name}
                                touched={touched.name}
                            />

                            <SbFormikTextAreaGroup
                                name="description"
                                label={t("Description", { keyPrefix: Common })}
                                rows={4}
                                maxLength={250}
                                onFormikChange={handleChange}
                                error={errors.description}
                                touched={touched.description}
                            />

                            <SbFormikCheckFieldGroup
                                label={`${t("ChecklistAssignmentIsTrainingDisplay")}?`}
                                type="checkbox"
                                values={[
                                    {
                                        name: "isTraining",
                                        onFormikChange: (
                                            event: React.ChangeEvent<HTMLFormElement>
                                        ) => {
                                            handleChange(event), handleIsTrainingChange(event);
                                        },
                                    },
                                ]}
                            />

                            <EndAlignedDiv>
                                <SaveButton type="submit" />
                                <CancelButton onClick={() => navigate(-1)} />
                            </EndAlignedDiv>
                        </StyledHiddenOverflowFormikForm>
                    )}
                </Formik>
            </TextTitledPanel>
        </>
    );
};

export default CreateChecklistContainer;
