import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { HierarchyTypeResponse } from "../../responses/hierarchy/hierarchy-type-response";

export interface HierarchyTypeDto {
    hierarchyTypeId: number;
    name: string;
}

export const createHierarchyTypeDto = (response: HierarchyTypeResponse): HierarchyTypeDto => ({
    ...response,
});

export const toHierarchyTypeDtos = (
    response: Response<PaginationResponse<HierarchyTypeResponse>>
): HierarchyTypeDto[] => response.data.results.map((x) => createHierarchyTypeDto(x));

export const toLookupHierarchyTypeDtos = (
    response: Response<HierarchyTypeResponse[]>
): HierarchyTypeDto[] => response.data.map((x) => createHierarchyTypeDto(x));
