import { useMutation, UseMutationResult, useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    getChecklistAssignmentDetails,
    promoteDemoteChecklistAssignment,
} from "../../../data/services/checklist-assignment/checklist-assignment-service";
import { getChecklistDetails } from "../../../data/services/checklists/checklist-service";
import { getNodeDetails } from "../../../data/services/hierarchy/nodes-service";
import {
    ChecklistAssignmentDetailsDto,
    createChecklistAssignmentDetailsDto,
} from "../../dtos/checklist-assignment/checklist-assignment-details-dto";
import { ChecklistDto, createChecklistDto } from "../../dtos/checklists/checklist-dto";
import { createNodeDetailsDto, NodeDetailsDto } from "../../dtos/hierarchy/node-details-dto";
import { createPromoteDemoteChecklistAssignmentRequest } from "../../requests/checklist-assignment/promote-demote-checklist-assignment-request";
import { Response } from "../../responses/common/response-response";

export const useGetPromoteDemoteChecklistAssignmentDetails = (
    checklistAssignmentId: number,
    checklistId: number,
    nodeId: number
): [
    UseQueryResult<ChecklistAssignmentDetailsDto, HTTPError>,
    UseQueryResult<ChecklistDto, HTTPError>,
    UseQueryResult<NodeDetailsDto, HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getChecklistAssignmentDetails", checklistAssignmentId],
                queryFn: () => getChecklistAssignmentDetails(url.baseUrl, checklistAssignmentId),
                select: createChecklistAssignmentDetailsDto,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getChecklist", checklistId],
                queryFn: () => getChecklistDetails(url.baseUrl, checklistId),
                select: createChecklistDto,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getNodeDetails", nodeId],
                queryFn: () => getNodeDetails(url.baseUrl, nodeId),
                select: createNodeDetailsDto,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<ChecklistAssignmentDetailsDto, HTTPError>,
        UseQueryResult<ChecklistDto, HTTPError>,
        UseQueryResult<NodeDetailsDto, HTTPError>,
    ];
};

interface PromoteDemoteParameters {
    checklistAssignmentId: number;
    isTraining: boolean;
}

export const usePromoteDemoteChecklistAssignment = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    PromoteDemoteParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: PromoteDemoteParameters) => {
        const request = createPromoteDemoteChecklistAssignmentRequest(
            mutationKey.checklistAssignmentId,
            mutationKey.isTraining
        );

        return promoteDemoteChecklistAssignment(url.baseUrl, request);
    });
};
