import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { toggleChecklistPromoteDemote } from "../../../data/services/checklists/checklist-service";
import { Response } from "../../responses/common/response-response";

interface ToggleParameters {
    checklistId: number;
    isTraining: boolean;
}

export const useToggleChecklistPromoteDemote = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    ToggleParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: ToggleParameters) => {
        const request = {
            checklistId: mutationKey.checklistId,
            isTraining: mutationKey.isTraining,
        };

        return toggleChecklistPromoteDemote(url.baseUrl, request);
    });
};
