import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Hierarchy } from "../../../core/constants/translation-namespace";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import { useFilterHierarchyTypes } from "../../../domain/viewmodels/hierarchy/hierarchy-actions-viewmodel";
import NodeHierarchyContainer from "./NodeHierarchyContainer";

const VisualTreeContainer: React.FC = () => {
    const menu = useMenu();
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const toast = useToast();
    const { t } = useTranslation("translation", { keyPrefix: Hierarchy });

    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";
    const viewInHierarchyId = Number(urlSearchParams.get("viewInHierarchyId"));

    const filterHierarchyTypes = useFilterHierarchyTypes();

    useLoader(isQueryLoading(filterHierarchyTypes), VisualTreeContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.VisualTree);

        if (success) {
            toast.addToast(createSuccessToastProps([t(messageKey)]));

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    return (
        <>
            {isQuerySuccessful(filterHierarchyTypes) && (
                <NodeHierarchyContainer
                    hierarchyTypes={filterHierarchyTypes.data!}
                    viewInHierarchyId={viewInHierarchyId}
                />
            )}
        </>
    );
};

export default VisualTreeContainer;
