import getHierarchyImportValue from "../../../core/utilities/HierarchyImportValues";
import { HierarchyImportRowResponse } from "../../responses/data-import/hierarchy-import-row-response";

export default class HierarchyImportRowDto {
    userEmail: string;
    rowId: number;
    columnId: number;
    value: string;
    error: string | null;
    legacyId: number | null;
    code: string;
    valueString: string;

    public constructor(response: HierarchyImportRowResponse) {
        this.userEmail = response.userEmail;
        this.rowId = response.rowId;
        this.columnId = response.columnId;
        this.value = response.value;
        this.error = response.error;
        this.legacyId = response.legacyId;
        this.code = response.code;
        this.valueString = getHierarchyImportValue(response);
    }

    public static constructFromResponses = (
        responses: HierarchyImportRowResponse[]
    ): HierarchyImportRowDto[] => responses.map((x) => new HierarchyImportRowDto(x));
}
