import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { NodeTypeResponse } from "../../responses/hierarchy/node-type-response";

export interface NodeTypeDto {
    nodeTypeId: number;
    name: string;
    hierarchyTypeId: number;
    isSystemRoot: boolean;
    containsTimeZoneCode: boolean;
}

export const createNodeTypeDto = (response: NodeTypeResponse): NodeTypeDto => ({
    ...response,
});

export const toNodeTypeDtos = (response: NodeTypeResponse[] | null): NodeTypeDto[] | null =>
    response?.map((x) => createNodeTypeDto(x)) ?? null;

export const toNodeTypeDtosFromResponse = (response: Response<NodeTypeResponse[]>): NodeTypeDto[] =>
    response.data.map((x) => createNodeTypeDto(x));

export const toNodeTypeDtosFromPaginatedResponse = (
    response: Response<PaginationResponse<NodeTypeResponse>>
): NodeTypeDto[] => response.data.results.map((x) => createNodeTypeDto(x));
