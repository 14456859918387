import { useMutation, UseMutationResult, useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { TableRow } from "../../../core/utilities/customTypes";
import { AccordionTitles, DataTableColumnTypes } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    editChecklistAssignment,
    GetApplicableHierarchyLevelValues,
    getChecklistAssignmentDetails,
} from "../../../data/services/checklist-assignment/checklist-assignment-service";
import { getChecklistDetails } from "../../../data/services/checklists/checklist-service";
import { getChecklistAssignmentNodeTypeValues } from "../../../data/services/hierarchy/node-type-values-service";
import { getNodeDetails } from "../../../data/services/hierarchy/nodes-service";
import {
    ChecklistAssignmentDetailsDto,
    createChecklistAssignmentDetailsDto,
} from "../../dtos/checklist-assignment/checklist-assignment-details-dto";
import EditChecklistAssignmentChecklistDto from "../../dtos/checklist-assignment/edit-checklist-assignment-checklist-dto";
import EditChecklistAssignmentDto from "../../dtos/checklist-assignment/edit-checklist-assignment-dto";
import { createNodeDetailsDto, NodeDetailsDto } from "../../dtos/hierarchy/node-details-dto";
import EditChecklistAssignmentRequest from "../../requests/checklist-assignment/edit-checklist-assignment-request";
import { ChecklistAssignmentNodeTypeValueResponse } from "../../responses/checklist-assignment/checklist-assignment-node-type-value-response";
import { ChecklistResponse } from "../../responses/checklists/checklist-response";
import { Response } from "../../responses/common/response-response";
import { NodeTypeValueDetailsResponse } from "../../responses/hierarchy/node-type-value-details-response";

export const useGetEditChecklistAssignmentDetails = (
    checklistAssignmentId: number,
    nodeId: number,
    checklistId: number
): [
    UseQueryResult<ChecklistAssignmentDetailsDto, HTTPError>,
    UseQueryResult<TableRow<number>[], HTTPError>,
    UseQueryResult<number[], HTTPError>,
    UseQueryResult<EditChecklistAssignmentChecklistDto, HTTPError>,
    UseQueryResult<NodeDetailsDto, HTTPError>,
] => {
    const url = useUrl();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getChecklistAssignmentDetails", checklistAssignmentId],
                queryFn: () => getChecklistAssignmentDetails(url.baseUrl, checklistAssignmentId),
                select: createChecklistAssignmentDetailsDto,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getChecklistAssignmentNodeTypeValues", nodeId],
                queryFn: () => getChecklistAssignmentNodeTypeValues(url.baseUrl, nodeId),
                select: (response: Response<NodeTypeValueDetailsResponse[]>) =>
                    transformToDataTableRows(response, navigate),
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getApplicableHierarchyLevelValues", checklistAssignmentId],
                queryFn: () =>
                    GetApplicableHierarchyLevelValues(url.baseUrl, checklistAssignmentId),
                select: (response: Response<ChecklistAssignmentNodeTypeValueResponse[]>) =>
                    transformToApplicableHierarchyLevelValues(response),
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getChecklistDetails", checklistId],
                queryFn: () => getChecklistDetails(url.baseUrl, checklistId),
                select: (
                    response: Response<ChecklistResponse>
                ): EditChecklistAssignmentChecklistDto =>
                    new EditChecklistAssignmentChecklistDto(
                        response.data.hasAdhoc,
                        response.data.name
                    ),
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getNodeDetails", nodeId],
                queryFn: () => getNodeDetails(url.baseUrl, nodeId),
                select: createNodeDetailsDto,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<ChecklistAssignmentDetailsDto, HTTPError>,
        UseQueryResult<TableRow<number>[], HTTPError>,
        UseQueryResult<number[], HTTPError>,
        UseQueryResult<EditChecklistAssignmentChecklistDto, HTTPError>,
        UseQueryResult<NodeDetailsDto, HTTPError>,
    ];
};

const transformToDataTableRows = (
    response: Response<NodeTypeValueDetailsResponse[]>,
    navigate: NavigateFunction
): TableRow<number>[] => {
    const responseData = response.data;

    return responseData.map((x) => ({
        metadata: x.nodeTypeValueId,
        showSelectAction: true,
        columns: [
            {
                value: x.nodeType.name,
                type: DataTableColumnTypes.Link,
                linkItemAction: () => {
                    navigate(
                        `${getPath(AccordionTitles.VisualStructure)}/${x.nodeType.nodeTypeId}`
                    );
                },
                metadata: "Node Type",
            },
            {
                value: x.value,
                type: DataTableColumnTypes.Link,
                linkItemAction: () => {
                    navigate(
                        `${getPath(AccordionTitles.VisualStructureValues)}/${x.nodeTypeValueId}`
                    );
                },
                metadata: "Node Type Value",
            },
        ],
    }));
};

const transformToApplicableHierarchyLevelValues = (
    response: Response<ChecklistAssignmentNodeTypeValueResponse[]>
): number[] => {
    const responseData = response.data;

    let nodeTypeValueIds: number[] = [];

    responseData.map((x) => nodeTypeValueIds.push(x.nodeTypeValueResponse.nodeTypeValueId));

    return nodeTypeValueIds;
};

export const useEditChecklistAssignment = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    EditChecklistAssignmentDto
> => {
    const url = useUrl();

    return useMutation((dto: EditChecklistAssignmentDto) =>
        editChecklistAssignment(url.baseUrl, EditChecklistAssignmentRequest.constructFromDto(dto))
    );
};
