import PredefinedAnswerTextLanguageResponse from "../../responses/predefined-answers/predefined-answer-text-language-response";

export default class PredefinedAnswerTextLanguageDto {
    languageId: number | null;
    name: string;

    public constructor(response: PredefinedAnswerTextLanguageResponse) {
        const { languageId, name } = response;

        this.languageId = languageId;
        this.name = name;
    }
}
