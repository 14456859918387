import { AssociateDissociateAgreedActionRaciUserDto } from "../../dtos/review-reports/associate-agreed-action-raci-user-dto";
import RaciRelationship from "../../enums/action-items/RaciRelationship";

export class AssociateDissociateAgreedActionRaciUserRequest {
    userId: number;
    agreedActionId: number;
    raciRelationship: RaciRelationship;

    public constructor(
        associateDissociateAgreedActionRaciUserDto: AssociateDissociateAgreedActionRaciUserDto
    ) {
        this.userId = associateDissociateAgreedActionRaciUserDto.userId;
        this.agreedActionId = associateDissociateAgreedActionRaciUserDto.agreedActionId;
        this.raciRelationship = associateDissociateAgreedActionRaciUserDto.raciRelationship;
    }
}
