export const Users = "user.users";
export const Profile = "user.profile";
export const UserManagement = "user.userManage";
export const Tenants = "user.tenant";
export const Roles = "user.roles";
export const Email = "user.email";
export const Common = "common.common";
export const AccordionLinks = "accordion.links";
export const NavbarDropdowns = "navbar.dropdowns";
export const Audits = "audit.audits";
export const ChecklistQuestions = "checklists.questions";
export const ChecklistAnswers = "checklists.answers";
export const Hierarchy = "hierarchy.hierarchy";
export const HierarchyTypes = "hierarchy.hierarchyTypes";
export const Nodes = "hierarchy.nodes";
export const NodeTypes = "hierarchy.nodeTypes";
export const NodeTypeValues = "hierarchy.nodeTypeValues";
export const NonWorkingDays = "hierarchy.nonWorkingDays";
export const ActionItems = "actionItems.actionItems";
export const ActionItemTypes = "actionItems.actionItemType";
export const HierarchyTypeTreeView = "hierarchy.hierarchyTreeView";
export const ActionPlans = "actionPlans.actionPlan";
export const Languages = "static.languages";
export const Reports = "reports.reports";
export const Home = "home.home";
export const ErrorMessages = "common.errorMessages";
