import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { createChecklist } from "../../../data/services/checklists/checklist-service";
import { CreateChecklistDto } from "../../dtos/checklists/create-checklist-dto";
import { Response } from "../../responses/common/response-response";

export const useCreateChecklist = (): UseMutationResult<
    Response<number>,
    HTTPError,
    CreateChecklistDto
> => {
    const url = useUrl();

    return useMutation((dto: CreateChecklistDto) => {
        const request = {
            name: dto.name,
            description: dto.description,
            isTraining: dto.isTraining,
        };

        return createChecklist(url.baseUrl, request);
    });
};
