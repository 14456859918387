import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    editHierarchyType,
    getHierarchyTypeDetails,
} from "../../../data/services/hierarchy/hierarchy-types-service";
import {
    createHierarchyTypeDetailsDto,
    HierarchyTypeDetailsDto,
} from "../../dtos/hierarchy/hierarchy-type-details-dto";
import {
    HierarchyAssignable,
    HierarchyAssignableLabels,
} from "../../enums/hierarchy/HierarchyAssignable";
import { createEditHierarchyTypeRequest } from "../../requests/hierarchy/edit-hierarchy-type-request";
import { Response } from "../../responses/common/response-response";

export const useGetHierarchyTypeDetails = (
    hierarchyTypeId: number
): UseQueryResult<HierarchyTypeDetailsDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getHierarchyTypeDetails", hierarchyTypeId],
        () => getHierarchyTypeDetails(url.baseUrl, hierarchyTypeId),
        {
            select: createHierarchyTypeDetailsDto,
            onError: errorResponseToDisplayHandler,
        }
    );
};

interface EditParameters {
    hierarchyTypeId: number;
    formData: FormData;
}

export const createEditParameters = (
    hierarchyTypeId: number,
    formData: FormData
): EditParameters => ({
    hierarchyTypeId: hierarchyTypeId,
    formData: formData,
});

export const useEditHierarchyType = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    EditParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: EditParameters) => {
        const { hierarchyTypeId, formData } = mutationKey;

        const request = createEditHierarchyTypeRequest(
            hierarchyTypeId,
            formData.get("name")! as string,
            Array.from(HierarchyAssignableLabels.keys()).filter(
                (x) => formData.get(HierarchyAssignable[x]) === "on"
            )
        );

        return editHierarchyType(url.baseUrl, request);
    });
};
