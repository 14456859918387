import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import {
    processHierarchyImport,
    validateHierarchyImport,
} from "../../../data/services/data-import/data-import-service";
import ProcessHierarchyImportDto from "../../dtos/data-import/process-hierarchy-import-dto";
import ValidateHierarchyImportDto from "../../dtos/data-import/validate-hierarchy-import-dto";
import ProcessHierarchyImportRequest from "../../requests/data-import/process-hierarchy-import-request";
import ValidateHierarchyImportRequest from "../../requests/data-import/validate-hierarchy-import-request";
import FileDetailsUploadRequest from "../../requests/file-storage/file-details-upload-request";
import { Response } from "../../responses/common/response-response";
import ValidateHierarchyImportResponse from "../../responses/data-import/validate-hierarchy-import-response";

export const useValidateHierarchyImport = (): UseMutationResult<
    Response<ValidateHierarchyImportResponse>,
    HTTPError,
    ValidateHierarchyImportDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: ValidateHierarchyImportDto) => {
        const validateHierarchyImportRequest = new ValidateHierarchyImportRequest(
            mutationKey.hierarchyTypeId,
            new FileDetailsUploadRequest(mutationKey.file, mutationKey.fileBase64String)
        );

        return validateHierarchyImport(url.baseUrl, validateHierarchyImportRequest);
    });
};

export const useProcessHierarchyImport = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    ProcessHierarchyImportDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: ProcessHierarchyImportDto) => {
        const processHierarchyImportRequest = new ProcessHierarchyImportRequest(mutationKey);

        return processHierarchyImport(url.baseUrl, processHierarchyImportRequest);
    });
};
