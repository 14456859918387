import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { editWizardNotificationStatus } from "../../../data/services/wizards/wizard-notification-service";
import { Response } from "../../../domain/responses/common/response-response";

interface EditParameters {
    userId: number;
    wizardId: number;
    notificationStatus: number;
}

export const useEditWizardNotificationStatus = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    EditParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: EditParameters) => {
        const request = {
            userId: mutationKey.userId,
            wizardId: mutationKey.wizardId,
            notificationStatus: mutationKey.notificationStatus,
        };

        return editWizardNotificationStatus(url.originalBaseUrl, request);
    });
};
