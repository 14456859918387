import RaciRelationship from "../../enums/action-items/RaciRelationship";

export class AssociateRaciParticipantDto {
    userId: number;
    actionItemId: number;
    raciRelationship: RaciRelationship;

    public constructor(userId: number, actionItemId: number, raciRelationship: RaciRelationship) {
        this.userId = userId;
        this.actionItemId = actionItemId;
        this.raciRelationship = raciRelationship;
    }
}
