import { Response } from "../../responses/common/response-response";
import { LinkableNodeResponse } from "../../responses/hierarchy/linkable-node-response";

export class LinkableNodeDto {
    relativeNodeId: number;
    descendantNodeTypeId: number;
    ancestorsLinkedNodeId: number;
    descendantsLinkedNodeTypeId: number;
    descendantsLinkedNodeTypeName: string;
    descendantsLinkedNodeId: number;
    descendantsLinkedNodeIsRequired: boolean;
    descendantsLinkedNodeTypeValueId: number;
    descendantsLinkedNodeTypeValue: string;

    public constructor(response: LinkableNodeResponse) {
        this.relativeNodeId = response.relativeNodeId;
        this.descendantNodeTypeId = response.descendantNodeTypeId;
        this.ancestorsLinkedNodeId = response.ancestorsLinkedNodeId;
        this.descendantsLinkedNodeTypeId = response.descendantsLinkedNodeTypeId;
        this.descendantsLinkedNodeTypeName = response.descendantsLinkedNodeTypeName;
        this.descendantsLinkedNodeId = response.descendantsLinkedNodeId;
        this.descendantsLinkedNodeIsRequired = response.descendantsLinkedNodeIsRequired;
        this.descendantsLinkedNodeTypeValueId = response.descendantsLinkedNodeTypeValueId;
        this.descendantsLinkedNodeTypeValue = response.descendantsLinkedNodeTypeValue;
    }

    public static constructFromLinkableNodeResponses = (
        response: Response<LinkableNodeResponse[]>
    ): LinkableNodeDto[] => response.data?.map((x) => new LinkableNodeDto(x));
}
