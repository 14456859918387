import QuestionImportance from "../../enums/questions/question-importance";
import { QuestionCategoryWithQuestionCountResponse } from "../../responses/question-category/question-category-with-question-count-response";
import { QuestionTextResponse } from "../../responses/question-text/question-text-response";
import { QuestionTypeResponse } from "../../responses/question-type/question-type-response";
import { NestedQuestionResponse } from "../../responses/questions/nested-question-response";
import BaseAnswerTypeDto from "../answer-types/base-answer-type-dto";
import { QuestionDto } from "./question-dto";

export class NestedQuestionDto {
    nestedQuestionId: number;
    questionId: number;
    orderIndex: number;
    renderInline: boolean;
    questionTypeId: number;
    answerTypeId: number;
    questionCategoryId: number;
    questionImportance: QuestionImportance;
    isDeleted: boolean;
    dateCreated: Date;
    dateModified: Date;
    createdByUserFullName: string;
    createdByUserId: number;
    modifiedByUserFullName: string;
    modifiedByUserId: number;
    isAggregate: boolean;
    aggregationTypeId: number;
    questionText: QuestionTextResponse;
    questionType: QuestionTypeResponse;
    questionCategory: QuestionCategoryWithQuestionCountResponse;
    answerTypeDto: BaseAnswerTypeDto;
    parentQuestionAnswerTrigger: object;
    triggerWhenParentDesirable: boolean;
    parentQuestionDto: QuestionDto;

    public constructor(response: NestedQuestionResponse) {
        const {
            nestedQuestionId,
            questionId,
            orderIndex,
            renderInline,
            questionTypeId,
            answerTypeId,
            questionCategoryId,
            questionImportance,
            isDeleted,
            dateCreated,
            dateModified,
            createdByUserFullName,
            createdByUserId,
            modifiedByUserFullName,
            modifiedByUserId,
            isAggregate,
            aggregationTypeId,
            questionText,
            questionType,
            questionCategory,
            answerTypeResponse,
            parentQuestionAnswerTrigger,
            triggerWhenParentDesirable,
            parentQuestionResponse,
        } = response;

        this.nestedQuestionId = nestedQuestionId;
        this.questionId = questionId;
        this.orderIndex = orderIndex;
        this.renderInline = renderInline;
        this.questionTypeId = questionTypeId;
        this.answerTypeId = answerTypeId;
        this.questionCategoryId = questionCategoryId;
        this.questionImportance = questionImportance;
        this.isDeleted = isDeleted;
        this.dateCreated = dateCreated;
        this.dateModified = dateModified;
        this.createdByUserFullName = createdByUserFullName;
        this.createdByUserId = createdByUserId;
        this.modifiedByUserFullName = modifiedByUserFullName;
        this.modifiedByUserId = modifiedByUserId;
        this.isAggregate = isAggregate;
        this.aggregationTypeId = aggregationTypeId;
        this.questionText = questionText;
        this.questionType = questionType;
        this.questionCategory = questionCategory;
        this.answerTypeDto = answerTypeResponse;
        this.parentQuestionAnswerTrigger = parentQuestionAnswerTrigger;
        this.triggerWhenParentDesirable = triggerWhenParentDesirable;
        this.parentQuestionDto = new QuestionDto(parentQuestionResponse);
    }

    public static toNestedQuestionDtos = (
        responses: NestedQuestionResponse[] | null
    ): NestedQuestionDto[] | null => responses?.map((x) => new NestedQuestionDto(x)) ?? null;
}
