import { Form as FormikForm } from "formik";
import { Form, Popover } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled, { css, DefaultTheme } from "styled-components";

export const StyledMenuItemLink = styled(Link)`
    text-decoration: none;
    color: ${(props) => props.theme.palette.purewhite};
    --bs-accordion-active-color: ${(props) => props.theme.palette.purewhite};

    &:hover,
    &:active {
        color: ${(props) => props.theme.palette.purewhite};
        background-color: ${(props) => props.theme.palette.secondaryVariant};
    }
`;

export const PageHeading = styled.h3`
    color: ${(props) => props.theme.palette.primary};
`;

export const PageSubHeading = styled.h6`
    color: ${(props) => props.theme.palette.secondary};
    font-weight: normal;
`;

export const SectionVerticalSpace = styled.div`
    height: ${(props) => props.theme.padding.xl};
`;

export const SectionContainer = styled.div`
    margin-bottom: ${(props) => props.theme.padding.xl};
`;

export const SmallVerticalSpace = styled.div`
    height: ${(props) => props.theme.padding.sm};
`;

export const LargeVerticalSpace = styled.div`
    height: ${(props) => props.theme.padding.lg};
`;

export const HorizontalForm = styled(Form)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const DetailsLabel = styled.h6`
    color: ${(props) => props.theme.palette.secondary};
    font-weight: bold;
`;

export const DetailsLabelBlue = styled.h6`
    color: ${(props) => props.theme.palette.primary};
    font-weight: bold;
`;

export const DetailsValue = styled.h6`
    color: ${(props) => props.theme.palette.secondary};
    overflow-wrap: break-word;
`;

export const DetailsValueBlue = styled.h6`
    color: ${(props) => props.theme.palette.primary};
    overflow-wrap: break-word;
`;

export const DetailsValueBlueSmall = styled.h6`
    color: ${(props) => props.theme.palette.primary};
    overflow-wrap: break-word;
    font-weight: normal;
`;

export const DetailsValueCentered = styled.h6`
    color: ${(props) => props.theme.palette.secondary};
    align-items: center;
`;

export const EndAlignedDiv = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: ${(props) => props.theme.padding.md};
`;

export const StartAlignedDiv = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: ${(props) => props.theme.padding.md};
`;

export const ContentContainer = styled.div`
    overflow: hidden;
    position: relative;
    padding: ${(props) => props.theme.padding.lg};
    background-color: white;
    border-radius: ${(props) => props.theme.padding.sm};
`;

export const StyledFormLabel = styled(Form.Label)`
    text-align: right;
    font-weight: 600;
    color: ${(props) => props.theme.palette.secondary};
    margin: 0;
`;

export const StyledFormLabelNoMargin = styled(Form.Label)`
    text-align: right;
    font-weight: 600;
    color: ${(props) => props.theme.palette.secondary};
    margin: 0;
`;

export const StyledFormLeftAlignedLabel = styled(Form.Label)`
    text-align: left;
    font-weight: 600;
    color: ${(props) => props.theme.palette.secondary};
`;

export const NoDataStateDiv = styled.div`
    color: ${(props) => props.theme.palette.secondary};
    font-style: italic;
    margin-left: ${(props) => props.theme.padding.lg};
`;

export const StyledBoldSpan = styled.span`
    color: ${(props) => props.theme.palette.black};
    font-weight: bold;
`;

export const StyledSpan = styled.span`
    color: ${(props) => props.theme.palette.black};
`;

export const StyledFilterTitle = styled.p`
    color: ${(props) => props.theme.palette.secondary};
    font-weight: bold;
    text-align: left;
`;

export const maxContentWidthSelectStyle = {
    menu: ({ ...css }) => ({
        ...css,
        width: "max-content",
        minWidth: "100%",
    }),
};

export const smallFontSelectStyle = {
    menu: ({ ...css }) => ({
        ...css,
        width: "max-content",
        minWidth: "100%",
        fontSize: "12px",
    }),
    control: ({ ...css }) => ({
        ...css,
        width: "max-content",
        minWidth: "100%",
        fontSize: "12px",
    }),
};

export const disabledSelectStyles = {
    control: ({ ...css }) => ({
        ...css,
        background: "#E9ECEF",
        pointerEvents: "none",
    }),
    singleValue: ({ ...css }) => ({
        ...css,
        color: "black",
    }),
};

export const StyledHiddenOverflowForm = styled(Form)`
    overflow: hidden;
    position: relative;
`;

export const StyledHiddenOverflowFormikForm = styled(FormikForm)`
    overflow: hidden;
    position: relative;
`;

export const StyledActionItemSectionContainer = styled(Form)`
    border-radius: ${(props) => props.theme.padding.sm};
    border-width: ${(props) => props.theme.padding.xxs};
    border-style: solid;
    border-color: ${(props) => props.theme.palette.grey};
    padding-left: ${(props) => props.theme.padding.lg};
    padding-right: ${(props) => props.theme.padding.lg};
    padding-top: ${(props) => props.theme.padding.xl};
    padding-bottom: ${(props) => props.theme.padding.lg};
`;

export interface StyledVariantTextProps {
    readonly $variant: "primary" | "secondary";
}

export const StyledVariantText = styled.p<StyledVariantTextProps>`
    ${({ $variant }) =>
        $variant === "primary" &&
        css`
            color: ${(props) => props.theme.palette.primary};
        `}

    ${({ $variant }) =>
        $variant === "secondary" &&
        css`
            color: ${(props) => props.theme.palette.secondary};
        `}
`;

export const StyledBoldVariantText = styled(StyledVariantText)<StyledVariantTextProps>`
    font-weight: bold;
`;

export interface StyledDisabledDivProps {
    readonly $disabled?: boolean;
}

export const StyledDisabledDiv = styled.div<StyledDisabledDivProps>`
    pointer-events: ${(props) => (props.$disabled ? "none" : "auto")};
    opacity: ${(props) => (props.$disabled ? "0.7" : "1")};
`;

export interface StyledQuestionSetCaptureContainerProps {
    readonly $isOverdue: boolean;
}

export interface StyledQuestionCaptureContainerProps
    extends StyledQuestionSetCaptureContainerProps,
        StyledDisabledDivProps {}

export const StyledQuestionCaptureContainer = styled(
    StyledDisabledDiv
)<StyledQuestionCaptureContainerProps>`
    margin: ${(props) => props.theme.padding.lg} 0 ${(props) => props.theme.padding.lg} 0;
    padding: ${(props) => props.theme.padding.lg};
    border: 1px solid;
    border-radius: 0.5rem;
    border-width: 0 0 0 10px;
    border-color: ${(props) =>
        props.$isOverdue ? props.theme.palette.danger : props.theme.palette.primaryVariant};
    box-shadow: 0px 2px 6px ${(props) => props.theme.palette.shadowColour};
    overflow: hidden;
    position: relative;
`;

export const StyledBorderedSection = styled.div`
    border: 1px solid;
    border-radius: 0.5rem;
    border-color: ${(props) => props.theme.palette.dividerColour};
    padding: ${(props) => props.theme.padding.lg};
    margin-bottom: ${(props) => props.theme.padding.md};
`;

export const StyledQuestionCaptureHeader = styled(StyledBoldVariantText)`
    padding-bottom: ${(props) => props.theme.padding.lg};
    margin-bottom: ${(props) => props.theme.padding.lg};
    border-bottom: 1px solid ${(props) => props.theme.palette.dividerColour};
`;

export const StyledInlineDiv = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: ${(props) => props.theme.padding.md};
`;

export interface StyledColorDivProps {
    readonly $color: string;
}

export const StyledColorDiv = styled.div<StyledColorDivProps>`
    width: ${(props) => props.theme.padding.lg};
    background-color: ${(props) => props.$color};
`;

export const StyledPopover = styled(Popover)`
    max-width: 50%;
    padding: ${(props) => props.theme.padding.sm};
    background-color: ${(props) => props.theme.palette.primary};
    color: ${(props) => props.theme.palette.white};

    .popover-arrow {
        display: none !important;
    }
`;

export interface StyledDisabledProps {
    readonly $disabledStyling?: boolean;
}

export const StyledErrorDiv = styled.div`
    color: ${(props) => props.theme.palette.danger};
`;

export const getErrorStyle = (themeContext: DefaultTheme): React.CSSProperties => ({
    border: `0.01rem solid ${themeContext.palette.danger}`,
});

export const getSelectErrorStyle = (themeContext: DefaultTheme) => ({
    control: ({ ...css }) => ({
        ...css,
        border: `0.01rem solid ${themeContext.palette.danger}`,
    }),
    singleValue: ({ ...css }) => ({
        ...css,
    }),
});

export const StyledRequiredStar = styled.div`
    display: inline;
    cursor: pointer;
    height: 25px;
    width: 25px;
    margin: 2px;
    color: red;
`;
