import { BasePaginationDto, createBasePaginationDto } from "../common/base-pagination-dto";

export interface UsersInRoleDto extends BasePaginationDto {
    userId: string | null;
    roleIds: number[] | null;
    emailAddress: string | null;
}

export const createUsersInRoleUserDto = (
    userId: string | null,
    roleIds: number[] | null,
    emailAddress: string | null,
    pageNumber: number,
    pageSize: number
): UsersInRoleDto => ({
    userId: userId,
    roleIds: roleIds,
    emailAddress: emailAddress,
    ...createBasePaginationDto(pageNumber, pageSize),
});
