import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ActionItems, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import AnswerValueType from "../../../domain/enums/answer-types/answer-value-type";
import {
    useDeleteActionItemDynamicField,
    useGetActionItemDynamicFieldDetails,
} from "../../../domain/viewmodels/action-items/delete-action-item-dynamic-field-viewmodel";
import { DeleteButton } from "../../atoms/SbButton";
import {
    CheckboxConfirmationRow,
    ConfirmationRow,
    TextConfirmationRow,
} from "../../organisms/ActionConfirmation";
import { ActionConfirmationFragment } from "../../organisms/ActionConfirmationFragment";

const DeleteActionItemDynamicFieldContainer: React.FC = () => {
    const actionItemDynamicFieldId = Number(useParams().actionItemDynamicFieldId);

    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const getActionItemDynamicFieldDetails =
        useGetActionItemDynamicFieldDetails(actionItemDynamicFieldId);
    const deleteActionItemDynamicField = useDeleteActionItemDynamicField();

    const actionItemDynamicFieldDetailsData = getActionItemDynamicFieldDetails.data;

    useLoader(
        isQueryLoading(getActionItemDynamicFieldDetails) ||
            isMutationLoading(deleteActionItemDynamicField),
        DeleteActionItemDynamicFieldContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.ActionTracker);
        menu.changeActiveDrawerItem(DrawerTitles.ActionPlans);
    }, []);

    const handleDeleteActionItemDynamicField = (): void => {
        deleteActionItemDynamicField.mutate(actionItemDynamicFieldId, {
            onSuccess: onDeleteActionItemDynamicFieldSuccess,
            onError: errorResponseToDisplayHandler,
        });
    };

    const onDeleteActionItemDynamicFieldSuccess = async (): Promise<void> => {
        const params = [
            createNavigateSearchParameter("success", "true"),
            createNavigateSearchParameter(
                "messageKey",
                "SuccessfullyDeletedActionItemDynamicField"
            ),
        ];

        navigateSearch(`${getPath(DrawerTitles.ActionItemDynamicFields)}`, params);
    };

    const buildDeleteButton = (): ReactElement<typeof Button> => (
        <DeleteButton onClick={() => handleDeleteActionItemDynamicField()} />
    );

    const buildConfirmationRows = (): ConfirmationRow<any>[] => [
        new TextConfirmationRow(t("FieldName"), actionItemDynamicFieldDetailsData?.fieldName),
        new TextConfirmationRow(
            t("ActionItemType"),
            actionItemDynamicFieldDetailsData?.actionItemTypeDto.value
        ),
        new CheckboxConfirmationRow(
            t("IsRequired", { keyPrefix: Common }),
            actionItemDynamicFieldDetailsData?.isRequired
        ),
        new TextConfirmationRow(
            t("ValueType", { keyPrefix: Common }),
            actionItemDynamicFieldDetailsData?.valueType &&
                AnswerValueType[actionItemDynamicFieldDetailsData.valueType]
        ),
        new TextConfirmationRow(
            t("ListData", { keyPrefix: Common }),
            actionItemDynamicFieldDetailsData?.listData
        ),
    ];

    return (
        <ActionConfirmationFragment
            pageHeading={t("ActionItemDynamicFieldDelete")}
            panelTitle={t("DeleteActionItemDynamicFieldConfirmation")}
            rows={buildConfirmationRows()}
            primaryButton={buildDeleteButton()}
            areQueriesSuccessful={isQuerySuccessful(getActionItemDynamicFieldDetails)}
        />
    );
};

export default DeleteActionItemDynamicFieldContainer;
