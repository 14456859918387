import { RemoveResolveAgreedActionDto } from "../../dtos/review-reports/remove-resolve-agreed-action-dto";

export class RemoveResolveAgreedActionRequest {
    questionSetInstanceId: number;
    questionSetInstanceAnswerId: number;
    commentText: string;
    agreedActionId: number;

    public constructor(dto: RemoveResolveAgreedActionDto) {
        this.questionSetInstanceId = dto.questionSetInstanceId;
        this.questionSetInstanceAnswerId = dto.questionSetInstanceAnswerId;
        this.commentText = dto.commentText;
        this.agreedActionId = dto.agreedActionId;
    }
}
