export default interface BasePaginatedDto {
    numberOfPages: number;
    pageSize: number;
    currentPage: number;
    recordCount: number;
}

export const createBasePaginatedDto = (
    numberOfPages: number,
    pageSize: number,
    currentPage: number,
    recordCount: number
): BasePaginatedDto => ({
    numberOfPages: numberOfPages,
    pageSize: pageSize,
    currentPage: currentPage,
    recordCount: recordCount,
});
