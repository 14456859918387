import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useAuth } from "../../../core/store/auth-context";
import { useUrl } from "../../../core/store/url-context";
import { lockoutUser, resetUserLockout } from "../../../data/services/users/users-service";
import { Response } from "../../responses/common/response-response";

export const useToggleUserLockout = (
    isLockedOut: boolean
): UseMutationResult<Response<boolean>, HTTPError, number> => {
    const url = useUrl();
    const auth = useAuth();

    // If admin is impersonating, use the admin url
    const urlToUse = auth.isImpersonating ? url.baseUrl : url.originalBaseUrl;

    return useMutation((userId: number) =>
        isLockedOut ? resetUserLockout(urlToUse, userId) : lockoutUser(urlToUse, userId)
    );
};
