import {
    buildFilterUsersInRoleSearchParameters,
    UsersInRoleRequest,
} from "../../../domain/requests/dashboard/users-in-role-request";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import { UsersInRoleResponse } from "../../../domain/responses/dashboard/users-in-role-response";
import client from "../../http-client";

const route = "dashboard";

export const getUsersInRole = async (
    prefixUrl: string,
    request: UsersInRoleRequest
): Promise<Response<PaginationResponse<UsersInRoleResponse>>> => {
    const searchParams = buildFilterUsersInRoleSearchParameters(request);

    return await client
        .get(`${route}/filter-users-in-role`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};
