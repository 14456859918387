import { Form, Formik } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { object, string } from "yup";
import { ChecklistQuestions } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import BaseReferentialLinkTypeDto from "../../../domain/dtos/referential-links/base-referential-link-type-dto";
import { useEditReferentialLinkType } from "../../../domain/viewmodels/referential-link-types/edit-referential-link-type-viewmodel";
import { useGetReferentialLinkTypeDetails } from "../../../domain/viewmodels/referential-link-types/view-referential-link-type-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import SbFormikTextFieldGroup from "../../molecules/input/SbFormikTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const EditReferentialLinkTypeContainer: React.FC = () => {
    const menu = useMenu();
    const navigate = useNavigate();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const referentialLinkTypeId = Number(useParams().referentialLinkTypeId);

    const editReferentialLinkType = useEditReferentialLinkType();
    const getReferentialLinkTypeDetails = useGetReferentialLinkTypeDetails(referentialLinkTypeId);

    const getReferentialLinkTypeDetailsData = getReferentialLinkTypeDetails.data;

    useLoader(
        isQueryLoading(getReferentialLinkTypeDetails) || isMutationLoading(editReferentialLinkType),
        EditReferentialLinkTypeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.ReferentialLinkTypes);
    }, []);

    const handleSubmit = (dto: BaseReferentialLinkTypeDto): void => {
        editReferentialLinkType.mutate(dto, {
            onSuccess: async () =>
                navigate(
                    `${getPath(AccordionTitles.ReferentialLinkTypes)}/${referentialLinkTypeId}`
                ),
            onError: errorResponseToDisplayHandler,
        });
    };

    const editReferentialLinkTypeSchema = (): object => {
        return object({
            name: string()
                .max(50, "Must be 50 characters or less")
                .min(2, "Must be 2 characters or more")
                .required("Name is required"),
        });
    };

    return (
        <>
            <PageHeading>{t("EditReferentialLinkType")}</PageHeading>
            <SectionVerticalSpace />
            {isQuerySuccessful(getReferentialLinkTypeDetails) && (
                <TextTitledPanel title={t("EditReferentialLinkType")}>
                    <Formik
                        initialValues={
                            new BaseReferentialLinkTypeDto(
                                referentialLinkTypeId,
                                getReferentialLinkTypeDetailsData!.name
                            )
                        }
                        onSubmit={handleSubmit}
                        validationSchema={editReferentialLinkTypeSchema}
                    >
                        {({ handleChange, errors, touched }) => (
                            <Form>
                                <SbFormikTextFieldGroup
                                    name="name"
                                    label={t("Name")}
                                    type="text"
                                    defaultValue={getReferentialLinkTypeDetailsData?.name}
                                    required
                                    onFormikChange={handleChange}
                                    error={errors.name}
                                    touched={touched.name}
                                />

                                <EndAlignedDiv>
                                    <SaveButton type="submit" />
                                    <CancelButton onClick={() => navigate(-1)} />
                                </EndAlignedDiv>
                            </Form>
                        )}
                    </Formik>
                </TextTitledPanel>
            )}
        </>
    );
};

export default EditReferentialLinkTypeContainer;
