export default class AdhocChecklistNotificationDto {
    firstNotificationTriggerDayValue: number;
    secondNotificationTriggerDayValue: number;
    thirdNotificationTriggerHourValue: number;

    public constructor(
        firstNotificationTriggerDayValue: number,
        secondNotificationTriggerDayValue: number,
        thirdNotificationTriggerHourValue: number
    ) {
        this.firstNotificationTriggerDayValue = firstNotificationTriggerDayValue;
        this.secondNotificationTriggerDayValue = secondNotificationTriggerDayValue;
        this.thirdNotificationTriggerHourValue = thirdNotificationTriggerHourValue;
    }
}
