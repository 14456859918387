import { ReactElement, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
    ChecklistQuestions,
    Common,
    HierarchyTypes,
} from "../../../core/constants/translation-namespace";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { nameColumnNames } from "../../../core/utilities/dataTableColumns";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
} from "../../../core/utilities/responseStateHelper";
import { defaultPaginationDto, PaginationDto } from "../../../domain/dtos/common/pagination-dto";
import { HierarchyAssignableLabels } from "../../../domain/enums/hierarchy/HierarchyAssignable";
import {
    hasRoleTypeInGroup,
    HierarchyTypeRoleGroup,
    NodeTypeRoleGroup,
} from "../../../domain/enums/Roles";
import { useGetHierarchyTypeDetails } from "../../../domain/viewmodels/hierarchy/hierarchy-type-details-viewmodel";
import { EditLink, ViewLink } from "../../atoms/SbLink";
import { TextTitledPanel } from "../../molecules/SbPanel";
import { DataTable } from "../../organisms/DataTable";

const HierarchyTypeDetailsContainer: React.FC = () => {
    const [paginationDto, setPaginationDto] = useState<PaginationDto>(defaultPaginationDto);

    const menu = useMenu();
    const toast = useToast();
    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const auth = useAuth();
    const { t } = useTranslation("translation", { keyPrefix: HierarchyTypes });

    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";
    const hierarchyTypeId = Number(useParams().hierarchyTypeId);

    const getHierarchyTypeDetails = useGetHierarchyTypeDetails(hierarchyTypeId, paginationDto);

    const hierarchyTypeDetailsResponseData = getHierarchyTypeDetails[0].data;
    const nodeTypesResponseData = getHierarchyTypeDetails[1].data;

    useLoader(areQueriesLoading(getHierarchyTypeDetails), HierarchyTypeDetailsContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.Admin);

        if (success) {
            toast.addToast(createSuccessToastProps([t(messageKey)]));

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    const navigateToNodeTypeDetails = (nodeTypeId: number): void => {
        navigate(`${getPath(AccordionTitles.VisualStructure)}/${nodeTypeId}`);
    };

    const navigateToEditNodeType = (nodeTypeId: number): void => {
        const params = [
            createNavigateSearchParameter("hierarchyTypeId", hierarchyTypeId.toString()),
        ];

        navigateSearch(`${getPath(AccordionTitles.VisualStructure)}/${nodeTypeId}/edit`, params);
    };

    const navigateToDeleteNodeType = (nodeTypeId: number): void => {
        const params = [
            createNavigateSearchParameter("hierarchyTypeId", hierarchyTypeId.toString()),
        ];

        navigateSearch(`${getPath(AccordionTitles.VisualStructure)}/${nodeTypeId}/delete`, params);
    };

    const buildAssociationsList = (): string => {
        const hierarchyAssignables = hierarchyTypeDetailsResponseData!.hierarchyAssignables;

        return hierarchyAssignables.length
            ? hierarchyAssignables.map((x) => HierarchyAssignableLabels.get(x)).join(", ")
            : "-";
    };

    const buildLinks = (): ReactElement<typeof Link> => (
        <>
            {hasRoleTypeInGroup(auth.userRoles, HierarchyTypeRoleGroup.WriteRoles) && (
                <EditLink
                    navigateTo={`${getPath(AccordionTitles.Admin)}/${hierarchyTypeId}/edit`}
                />
            )}
            <ViewLink
                label={t("HierarchyTypes")}
                navigateTo={`${getPath(AccordionTitles.Admin)}`}
            />
        </>
    );

    return (
        <>
            <PageHeading>{t("HierarchyTypeDetails")}</PageHeading>
            <SectionVerticalSpace />

            {areQueriesSuccessful(getHierarchyTypeDetails) && (
                <>
                    <ContentContainer>
                        <Row>
                            <Col md="auto">
                                <DetailsLabel>{t("Name", { keyPrefix: Common })}</DetailsLabel>
                                <DetailsLabel>{t("HierarchyAssignableFlag")}</DetailsLabel>
                            </Col>
                            <Col md="auto">
                                <DetailsValue>
                                    {hierarchyTypeDetailsResponseData!.name}
                                </DetailsValue>
                                <DetailsValue>{buildAssociationsList()}</DetailsValue>
                            </Col>
                        </Row>

                        <EndAlignedDiv>{buildLinks()}</EndAlignedDiv>
                    </ContentContainer>
                    <SectionVerticalSpace />

                    <TextTitledPanel title={t("ListOfNodeTypesAssociatedWithThisHierarchyType")}>
                        <DataTable
                            keyPrefix={ChecklistQuestions}
                            columns={nameColumnNames}
                            rows={nodeTypesResponseData!.rows}
                            viewItem={navigateToNodeTypeDetails}
                            editItem={
                                hasRoleTypeInGroup(auth.userRoles, NodeTypeRoleGroup.WriteRoles)
                                    ? navigateToEditNodeType
                                    : undefined
                            }
                            deleteItem={
                                hasRoleTypeInGroup(auth.userRoles, NodeTypeRoleGroup.WriteRoles)
                                    ? navigateToDeleteNodeType
                                    : undefined
                            }
                            totalItems={nodeTypesResponseData!.recordCount}
                            setPaginationDto={setPaginationDto}
                            paginationDto={paginationDto}
                        />
                    </TextTitledPanel>
                </>
            )}
        </>
    );
};

export default HierarchyTypeDetailsContainer;
