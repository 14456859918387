import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { downloadFile } from "../../../data/services/file-details/file-details-service";
import { FileDownloadDto } from "../../dtos/file-storage/file-download-dto";

export const useDownloadQuestionSetInstanceAnswerFile = (
    fileDetailsId: number | null
): UseQueryResult<FileDownloadDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["downloadActionItemInstanceFile", fileDetailsId],
        () => downloadFile(url.baseUrl, fileDetailsId!),
        {
            enabled: fileDetailsId != null,
            select: FileDownloadDto.constructFromBaseFileDetailsResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};
