export interface AssociateDissociateChecklistTaxonomyNodeDto {
    checklistId: number;
    nodeId: number;
}

export const createAssociateDissociateChecklistTaxonomyNodeDto = (
    checklistId: number,
    nodeId: number
): AssociateDissociateChecklistTaxonomyNodeDto => ({
    checklistId: checklistId,
    nodeId: nodeId,
});
