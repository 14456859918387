import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import FilterReportSummariesRequest from "./filter-report-summaries-request";

export default class FilterChecklistReportSummariesRequest extends FilterReportSummariesRequest {
    checklistId: number;
    nodeId: number;

    public constructor(
        startDateUtc: Date,
        endDateUtc: Date,
        nodeId: number,
        userId: number,
        checklistId: number,
        paginationDto: BasePaginationDto
    ) {
        super(startDateUtc, endDateUtc, userId, paginationDto);

        this.checklistId = checklistId;
        this.nodeId = nodeId;
    }

    public buildSearchParameters(): URLSearchParams {
        const searchParams = super.buildSearchParameters();

        searchParams.set("nodeId", this.nodeId.toString());
        searchParams.set("checklistId", this.checklistId.toString());

        return searchParams;
    }
}
