import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { deleteWizardCard } from "../../../data/services/wizards/wizard-cards-service";
import { Response } from "../../responses/common/response-response";

export const useDeleteWizardCard = (): UseMutationResult<Response<boolean>, HTTPError, number> => {
    const url = useUrl();

    return useMutation((cardId: number) => deleteWizardCard(url.originalBaseUrl, cardId));
};
