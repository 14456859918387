import { AnswerThresholdOperatorsResponse } from "../../../domain/responses/answer-threshold-operators/answer-threshold-operators-response";
import client from "../../http-client";

const route = "answerthreshold";

export const getAllAnswerThresholdOperators = async (
    prefixUrl: string
): Promise<AnswerThresholdOperatorsResponse[]> =>
    await client
        .get(route, {
            prefixUrl: prefixUrl,
        })
        .json();
