export interface OrderActionItemDynamicFieldRequest {
    actionItemDynamicFieldId: number;
    orderIndex: number;
}

export const createOrderActionItemDynamicFieldRequest = (
    actionItemDynamicFieldId: number,
    orderIndex: number
): OrderActionItemDynamicFieldRequest => ({
    actionItemDynamicFieldId: actionItemDynamicFieldId,
    orderIndex: orderIndex,
});
