import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { NodeTypeValueResponse } from "../../responses/hierarchy/node-type-value-response";

export interface NodeTypeValueDto {
    nodeTypeValueId: number;
    value: string;
}

export const createNodeTypeValueDto = (response: NodeTypeValueResponse): NodeTypeValueDto => ({
    ...response,
});

export const toNodeTypeValueDtos = (
    response: Response<NodeTypeValueResponse[]>
): NodeTypeValueDto[] => response.data?.map((x) => createNodeTypeValueDto(x));

export const toNodeTypeValueDtosFromPaginatedResponse = (
    response: Response<PaginationResponse<NodeTypeValueResponse>>
): NodeTypeValueDto[] => response.data?.results.map((x) => createNodeTypeValueDto(x));
