import { CreateNodeTypeValueRequest } from "./create-node-type-value-request";
import {
    createNodeTypeValueModificationRequest,
    NodeTypeValueModificationRequest,
} from "./node-type-value-modification-request";

export interface CreateChildNodeRequest extends NodeTypeValueModificationRequest {
    parentNodeId: number;
    childNodeTypeId: number;
    isTraining: boolean;
    linkedNodeId: number | null;
}

export const createCreateChildNodeRequest = (
    parentNodeId: number,
    childNodeTypeId: number,
    isTraining: boolean | null,
    linkedNodeId: number | null,
    nodeTypeValueId: number | null,
    createNodeTypeValueRequest: CreateNodeTypeValueRequest | null
): CreateChildNodeRequest => ({
    parentNodeId: parentNodeId,
    childNodeTypeId: childNodeTypeId,
    isTraining: isTraining ?? false,
    linkedNodeId: linkedNodeId,
    ...createNodeTypeValueModificationRequest(nodeTypeValueId, createNodeTypeValueRequest),
});
