import { Response } from "../../../domain/responses/common/response-response";
import { LanguageResponse } from "../../../domain/responses/language/language-response";
import client from "../../http-client";

const route = "languages";

export const getLanguages = async (prefixUrl: string): Promise<Response<LanguageResponse[]>> =>
    await client
        .get(route, {
            prefixUrl: prefixUrl,
        })
        .json();

export const getDefaultLanguage = async (prefixUrl: string): Promise<Response<LanguageResponse>> =>
    await client
        .get(`${route}/default`, {
            prefixUrl: prefixUrl,
        })
        .json();
