import { CreateReferentialLinkRequest } from "../../../domain/requests/referential-link-types/create-referential-link-request";
import {
    buildFilterReferentialLinkTypeSearchParameters,
    FilterReferentialLinkTypeRequest,
} from "../../../domain/requests/referential-link-types/filter-referential-link-type-request";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import { ReferentialLinkResponse } from "../../../domain/responses/referential-links/referential-link-response";
import client from "../../http-client";

const route = "referentiallink";

export const filterReferentialLinks = async (
    prefixUrl: string,
    request: FilterReferentialLinkTypeRequest
): Promise<Response<PaginationResponse<ReferentialLinkResponse>>> => {
    // FilterReferentialLinkTypeRequest is used when filtering both referential links and referntial link types
    // since both endpoints require the same request values
    const searchParams = buildFilterReferentialLinkTypeSearchParameters(request);

    return await client
        .get(`${route}/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getReferentialLinks = async (
    prefixUrl: string,
    referentialLinkTypeId: number
): Promise<Response<ReferentialLinkResponse[]>> =>
    await client
        .get(`${route}/${referentialLinkTypeId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const createReferentialLink = async (
    prefixUrl: string,
    request: CreateReferentialLinkRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/create`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const softDeleteReferentialLink = async (
    prefixUrl: string,
    referentialLinkId: number
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/delete/${referentialLinkId}`, {
            prefixUrl: prefixUrl,
        })
        .json();
