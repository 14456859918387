import AnswerCategory from "../../enums/answer-types/answer-category";
import AnswerFlagMode from "../../enums/answer-types/answer-flag-mode";
import AnswerRenderMode from "../../enums/answer-types/answer-render-mode";
import AnswerValueType from "../../enums/answer-types/answer-value-type";
import BaseAnswerTypeResponse from "../../responses/answer-types/base-answer-type-response";
import { Response } from "../../responses/common/response-response";

export default class BaseAnswerTypeDto {
    answerTypeId: number;
    name: string;
    answerCategory: AnswerCategory;
    answerValueType: AnswerValueType | null;
    answerRenderMode: AnswerRenderMode | null;
    answerFlagMode: AnswerFlagMode | null;

    public constructor(response: BaseAnswerTypeResponse) {
        const {
            answerTypeId,
            name,
            answerCategory,
            answerValueType,
            answerRenderMode,
            answerFlagMode,
        } = response;

        this.answerTypeId = answerTypeId;
        this.name = name;
        this.answerCategory = answerCategory;
        this.answerValueType = answerValueType;
        this.answerRenderMode = answerRenderMode;
        this.answerFlagMode = answerFlagMode;
    }

    public static constructFromResponse = (
        response: Response<BaseAnswerTypeResponse>
    ): BaseAnswerTypeDto => new BaseAnswerTypeDto(response.data);

    public static constructBaseFromResponses = (
        response: Response<BaseAnswerTypeResponse[]>
    ): BaseAnswerTypeDto[] => {
        const responseData = response.data;

        return responseData.map((x) => new BaseAnswerTypeDto(x));
    };
}
