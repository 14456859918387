import AdhocChecklistNotificationDto from "../../dtos/checklists/adhoc-checklist-notification-dto";

export default class AdhocChecklistNotificationRequest {
    firstNotificationTriggerDayValue: number;
    secondNotificationTriggerDayValue: number;
    thirdNotificationTriggerHourValue: number;

    public constructor(dto: AdhocChecklistNotificationDto) {
        const {
            firstNotificationTriggerDayValue,
            secondNotificationTriggerDayValue,
            thirdNotificationTriggerHourValue,
        } = dto;

        this.firstNotificationTriggerDayValue = firstNotificationTriggerDayValue;
        this.secondNotificationTriggerDayValue = secondNotificationTriggerDayValue;
        this.thirdNotificationTriggerHourValue = thirdNotificationTriggerHourValue;
    }
}
