import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { editReferentialLinkType } from "../../../data/services/referential-link-types/referential-link-type-service";
import BaseReferentialLinkTypeDto from "../../dtos/referential-links/base-referential-link-type-dto";
import { Response } from "../../responses/common/response-response";

export const useEditReferentialLinkType = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    BaseReferentialLinkTypeDto
> => {
    const url = useUrl();

    return useMutation((dto: BaseReferentialLinkTypeDto) => {
        const request = {
            referentialLinkTypeId: dto.referentialLinkTypeId,
            referentialLinkTypeName: dto.name,
        };

        return editReferentialLinkType(url.baseUrl, request);
    });
};
