import { HierarchyImportRowResponse } from "../../domain/responses/data-import/hierarchy-import-row-response";

const getHierarchyImportValue = (row: HierarchyImportRowResponse): string => {
    const value = row?.value !== null ? `${row?.value}` : "";
    const code = row?.code !== null ? ` | ${row?.code}` : "";
    const userEmail = row?.userEmail !== null ? ` | ${row?.userEmail}` : "";
    const legacyId = row?.legacyId !== null ? ` | ${row?.legacyId}` : "";

    return `${value}${code}${userEmail}${legacyId}`;
};

export default getHierarchyImportValue;
