import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import { FilterReviewReportSetHistoryDto } from "../../dtos/review-reports/filter-review-report-set-history-dto";
import ReviewRating from "../../enums/review-reports/review-ratings";
import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
    createBasePaginationRequest,
} from "../common/base-pagination-request";

export interface FilterReviewReportSetHistoryRequest extends BasePaginationRequest {
    name: string | null;
    reviewRating: ReviewRating | null;
    startDate: string | null;
    endDate: string | null;
    country: string | null;
}

export const createFilterReviewReportSetRequest = (
    dto: FilterReviewReportSetHistoryDto,
    paginationDto: BasePaginationDto
): FilterReviewReportSetHistoryRequest => ({
    name: dto.name,
    reviewRating: dto.reviewRating?.value ?? null,
    startDate: dto.startDate,
    endDate: dto.endDate,
    country: dto.country?.value ?? null,
    ...createBasePaginationRequest(paginationDto.pageNumber, paginationDto.pageSize),
});

export const buildFilterSearchParameters = (
    request: FilterReviewReportSetHistoryRequest
): URLSearchParams => {
    const searchParams = buildBasePaginationSearchParameters(request);

    if (request.name) {
        searchParams.set("name", request.name);
    }

    if (request.reviewRating) {
        searchParams.set("reviewRating", request.reviewRating.toString());
    }

    if (request.startDate) {
        searchParams.set("startDate", request.startDate.toString());
    }

    if (request.endDate) {
        searchParams.set("endDate", request.endDate.toString());
    }

    if (request.country) {
        searchParams.set("country", request.country.toString());
    }

    return searchParams;
};
