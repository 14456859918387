import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import {
    ChecklistQuestions,
    Common,
    HierarchyTypes,
} from "../../../core/constants/translation-namespace";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { nameColumnNames } from "../../../core/utilities/dataTableColumns";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import { defaultPaginationDto, PaginationDto } from "../../../domain/dtos/common/pagination-dto";
import { hasRoleTypeInGroup, HierarchyTypeRoleGroup } from "../../../domain/enums/Roles";
import { useFilterHierarchyTypes } from "../../../domain/viewmodels/hierarchy/hierarchy-types-viewmodel";
import { CreateLink } from "../../atoms/SbLink";
import { DataTable } from "../../organisms/DataTable";

const HierarchyTypesContainer: React.FC = () => {
    const [paginationDto, setPaginationDto] = useState<PaginationDto>(defaultPaginationDto);

    const menu = useMenu();
    const navigate = useNavigate();
    const auth = useAuth();
    const { t } = useTranslation("translation", { keyPrefix: HierarchyTypes });

    const filterHierarchyTypes = useFilterHierarchyTypes(paginationDto);

    const filterHierarchyTypesResponseData = filterHierarchyTypes.data;

    useLoader(isQueryLoading(filterHierarchyTypes), HierarchyTypesContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.Admin);
    }, []);

    const navigateToHierarchyTypeDetails = (hierarchyTypeId: number): void => {
        navigate(`${getPath(AccordionTitles.Admin)}/${hierarchyTypeId}`);
    };

    const navigateToEditHierarchyType = (hierarchyTypeId: number): void => {
        navigate(`${getPath(AccordionTitles.Admin)}/${hierarchyTypeId}/edit`);
    };

    const buildCreateNewLink = (): ReactElement<typeof Link> => {
        if (hasRoleTypeInGroup(auth.userRoles, HierarchyTypeRoleGroup.WriteRoles)) {
            return (
                <>
                    <EndAlignedDiv>
                        <CreateLink
                            label={t("CreateNew", { keyPrefix: Common })}
                            navigateTo={`${getPath(AccordionTitles.Admin)}/create`}
                        />
                    </EndAlignedDiv>
                    <LargeVerticalSpace />
                </>
            );
        }

        return <></>;
    };

    return (
        <>
            <PageHeading>{t("HierarchyTypes")}</PageHeading>
            <PageSubHeading>{t("HeaderHelpText")}</PageSubHeading>
            <SectionVerticalSpace />

            {buildCreateNewLink()}

            {isQuerySuccessful(filterHierarchyTypes) && (
                <>
                    <DataTable
                        columns={nameColumnNames}
                        keyPrefix={ChecklistQuestions}
                        rows={filterHierarchyTypesResponseData!.rows}
                        viewItem={navigateToHierarchyTypeDetails}
                        editItem={
                            hasRoleTypeInGroup(auth.userRoles, HierarchyTypeRoleGroup.WriteRoles)
                                ? navigateToEditHierarchyType
                                : undefined
                        }
                        totalItems={filterHierarchyTypesResponseData!.recordCount}
                        setPaginationDto={setPaginationDto}
                        paginationDto={paginationDto}
                    />
                </>
            )}
        </>
    );
};

export default HierarchyTypesContainer;
