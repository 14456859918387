import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { getTenantDetails } from "../../../data/services/tenants/tenant-service";
import { TenantDetailsDto, toTenantDetailsDto } from "../../dtos/tenants/tenant-details-dto";

export const useGetTenantDetails = (
    tenantId: number
): UseQueryResult<TenantDetailsDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(["getTenantDetails"], () => getTenantDetails(url.originalBaseUrl, tenantId), {
        select: toTenantDetailsDto,
        onError: errorResponseToDisplayHandler,
    });
};
