import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    cancelActionItem,
    getActionItemDetails,
} from "../../../data/services/actions/action-items-service";
import { ActionItemDetailsDto } from "../../dtos/actions/action-item-details-dto";
import { CancelActionItemRequest } from "../../requests/actions/cancel-action-item-request";
import { Response } from "../../responses/common/response-response";

export const useGetActionItemDetails = (
    actionItemId: number
): UseQueryResult<ActionItemDetailsDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getActionItemDetails", actionItemId],
        () => getActionItemDetails(url.baseUrl, actionItemId),
        {
            select: ActionItemDetailsDto.constructFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useCancelActionItem = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    CancelActionItemRequest
> => {
    const url = useUrl();

    return useMutation((request: CancelActionItemRequest) =>
        cancelActionItem(url.baseUrl, request)
    );
};
