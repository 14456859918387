import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import {
    RoleResponse,
    RoleUsersResponse,
    RoleWithUserCountResponse,
} from "../../responses/users/role-response";

export interface RoleDto {
    roleId: number;
    name: string;
}

export const toRoleDto = (response: RoleResponse): RoleDto => ({
    roleId: response.roleId,
    name: response.name,
});

export const toRoleDtoFromResponse = (response: Response<RoleResponse>): RoleDto => {
    const responseData = response.data;

    return {
        roleId: responseData.roleId,
        name: responseData.name,
    };
};

export const toRoleDtos = (response: Response<PaginationResponse<RoleResponse>>): RoleDto[] =>
    response.data.results.map((x) => toRoleDto(x));

export interface RoleWithUserCountDto extends RoleDto {
    userCount: number;
}

export const toRoleWithUserCountDto = (role: RoleWithUserCountResponse): RoleWithUserCountDto => ({
    roleId: role.roleId,
    name: role.name,
    userCount: role.userCount,
});

export const toRoleWithUserCountDtos = (
    roles: RoleWithUserCountResponse[]
): RoleWithUserCountDto[] => roles.map((x) => toRoleWithUserCountDto(x));

export interface RoleUserDto {
    userId: number;
    firstName: string;
    lastName: string;
    email: string;
}

export const toRoleUserDto = (roleUser: RoleUsersResponse): RoleUserDto => ({
    userId: roleUser.userId,
    firstName: roleUser.firstName,
    lastName: roleUser.lastName,
    email: roleUser.email,
});

export const toRoleUserDtos = (roles: RoleUsersResponse[]): RoleUserDto[] =>
    roles.map((x) => toRoleUserDto(x));
