import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    deleteAnswerType,
    getAnswerTypeDetails,
} from "../../../data/services/answer-types/answer-types-service";
import { Response } from "../../../domain/responses/common/response-response";
import BaseAnswerTypeDto from "../../dtos/answer-types/base-answer-type-dto";

export const useGetAnswerTypeDetails = (
    answerTypeId: number
): UseQueryResult<BaseAnswerTypeDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getAnswerTypeDetails", answerTypeId],
        () => getAnswerTypeDetails(url.baseUrl, answerTypeId),
        {
            select: BaseAnswerTypeDto.constructFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useDeleteAnswerType = (): UseMutationResult<Response<boolean>, HTTPError, number> => {
    const url = useUrl();

    return useMutation((answerTypeId: number) => deleteAnswerType(url.baseUrl, answerTypeId));
};
