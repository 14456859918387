import AnswerCategory from "../../enums/answer-types/answer-category";
import {
    CreateAnswerTypeRequest,
    createCreateAnswerTypeRequest,
} from "./create-answer-type-request";

export interface EditAnswerTypeRequest extends CreateAnswerTypeRequest {
    answerTypeId: number;
}

export const createEditAnswerTypeRequest = (
    answerTypeId: number,
    name: string,
    answerCategoryId: number | null,
    answerRenderModeId: number | null,
    answerFlagModeId: number | null,
    answerValueTypeId: number | null
): EditAnswerTypeRequest => {
    if (answerCategoryId == AnswerCategory.MutuallyExclusive) {
        return {
            answerTypeId,
            ...createCreateAnswerTypeRequest(
                name,
                answerCategoryId,
                answerRenderModeId,
                answerFlagModeId,
                null
            ),
        };
    }
    if (answerCategoryId == AnswerCategory.InputValue) {
        return {
            answerTypeId,
            ...createCreateAnswerTypeRequest(name, answerCategoryId, null, null, answerValueTypeId),
        };
    }

    return {
        answerTypeId,
        name: "",
        answerCategory: null,
        answerRenderMode: null,
        answerFlagMode: null,
        answerValueType: null,
    };
};
