import {
    allActionInstanceStatusOption,
    allActionItemImportanceOption,
    allActionItemSubTypeOption,
} from "../../../presentation/templates/actions/ActionItemsContainer";
import RaciRelationship from "../../enums/action-items/RaciRelationship";
import { ActionInstanceStatusDto } from "../actions/action-instance-status-dto";
import { ActionItemImportanceDto } from "../actions/action-item-importance-dto";
import {
    createSearchActionItemsDto,
    SearchActionItemsDto,
} from "../actions/search-action-items-dto";
import { ReactSelectDto } from "../common/react-select-dto";
import { ActionItemTypeDto, allActionItemTypeOption } from "./action-item-type-dto";

export interface SearchFinalizedActionItemsDto extends SearchActionItemsDto {
    raciRelationship: ReactSelectDto<RaciRelationship>;
}

const createSearchFinalizedActionItemsDto = (
    raciRelationship: ReactSelectDto<RaciRelationship>,
    actionItemId: string | null,
    name: string | null,
    actionItemTypeNodeId: number | null,
    actionItemType: ActionItemTypeDto,
    actionItemSubTypeNodeId: number | null,
    actionItemSubType: ActionItemTypeDto,
    raciParticipant: string | null,
    actionItemImportanceId: number | null,
    actionItemImportance: ActionItemImportanceDto,
    actionInstanceStatusId: number | null,
    actionInstanceStatus: ActionInstanceStatusDto,
    dueDateFrom: Date | null,
    dueDateTo: Date | null
): SearchFinalizedActionItemsDto => ({
    raciRelationship: raciRelationship,
    ...createSearchActionItemsDto(
        actionItemId,
        name,
        actionItemTypeNodeId,
        actionItemType,
        actionItemSubTypeNodeId,
        actionItemSubType,
        raciParticipant,
        actionItemImportanceId,
        actionItemImportance,
        actionInstanceStatusId,
        actionInstanceStatus,
        dueDateFrom,
        dueDateTo
    ),
});

export const emptySearchFinalizedActionItemsDto = (): SearchFinalizedActionItemsDto =>
    createSearchFinalizedActionItemsDto(
        {
            value: 0,
            label: "All",
        },
        null,
        null,
        null,
        allActionItemTypeOption,
        null,
        allActionItemSubTypeOption,
        null,
        null,
        allActionItemImportanceOption,
        null,
        allActionInstanceStatusOption,
        null,
        null
    );
