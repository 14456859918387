import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaClone, FaLink } from "react-icons/fa";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { EnterKey } from "../../../core/constants/KeyboardKeys";
import { ActionItems, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useKeyPress from "../../../core/hooks/keyPress";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { filterActionItemsColumnNames } from "../../../core/utilities/dataTableColumns";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isMutationLoading,
} from "../../../core/utilities/responseStateHelper";
import { getNullableString } from "../../../core/utilities/string-helper";
import {
    ActionItemTypeDto,
    createActionItemTypeDto,
} from "../../../domain/dtos/action-items/action-item-type-dto";
import {
    ActionInstanceStatusDto,
    createActionInstanceStatusDto,
} from "../../../domain/dtos/actions/action-instance-status-dto";
import {
    ActionItemImportanceDto,
    createActionItemImportanceDto,
} from "../../../domain/dtos/actions/action-item-importance-dto";
import {
    defaultFilterActionItemsDto,
    FilterActionItemDto,
} from "../../../domain/dtos/actions/filter-action-items-dto";
import {
    BasePaginationDto,
    defaultBasePaginationDto,
} from "../../../domain/dtos/common/base-pagination-dto";
import { ActionItemSearchMode } from "../../../domain/enums/action-items/ActionItemSearchMode";
import {
    createAssociateParameters,
    useAssociateActionItem,
    useFilterAssociateActionItems,
} from "../../../domain/viewmodels/actions/view-associate-action-items-viewmodel";
import { CancelButton, SbButton } from "../../atoms/SbButton";
import { RadioSelectProps } from "../../molecules/DataTableAction";
import { DataTable } from "../../organisms/DataTable";
import { ActionItemsFilter } from "../../organisms/filters/ActionItemsFilter";

interface SearchParams {
    actionItemId: string | null;
    name: string | null;
    actionItemTypeNodeId: number | null;
    actionItemType: ActionItemTypeDto;
    actionItemSubTypeNodeId: number | null;
    actionItemSubType: ActionItemTypeDto;
    raciParticipant: string | null;
    actionItemImportanceId: number | null;
    actionItemImportance: ActionItemImportanceDto;
    actionInstanceStatusId: number | null;
    actionInstanceStatus: ActionInstanceStatusDto;
    dueDateFrom: Date | null;
    dueDateTo: Date | null;
}

const createSearchParams = (
    actionItemId: string | null,
    name: string | null,
    actionItemTypeNodeId: number | null,
    actionItemType: ActionItemTypeDto,
    actionItemSubTypeNodeId: number | null,
    actionItemSubType: ActionItemTypeDto,
    raciParticipant: string | null,
    actionItemImportanceId: number | null,
    actionItemImportance: ActionItemImportanceDto,
    actionInstanceStatusId: number | null,
    actionInstanceStatus: ActionInstanceStatusDto,
    dueDateFrom: Date | null,
    dueDateTo: Date | null
): SearchParams => ({
    actionItemId: actionItemId,
    name: name,
    actionItemTypeNodeId: actionItemTypeNodeId,
    actionItemType: actionItemType,
    actionItemSubTypeNodeId: actionItemSubTypeNodeId,
    actionItemSubType: actionItemSubType,
    raciParticipant: raciParticipant,
    actionItemImportanceId: actionItemImportanceId,
    actionItemImportance: actionItemImportance,
    actionInstanceStatusId: actionInstanceStatusId,
    actionInstanceStatus: actionInstanceStatus,
    dueDateFrom: dueDateFrom,
    dueDateTo: dueDateTo,
});

const allActionItemTypeOption = createActionItemTypeDto(0, 0, "All");
const allActionItemSubTypeOption = createActionItemTypeDto(0, 0, "All");
const allActionItemImportanceOption = createActionItemImportanceDto(0, "All");
const allActionInstanceStatusOption = createActionInstanceStatusDto(-1, "All");

const defaultSearchParams: SearchParams = createSearchParams(
    null,
    null,
    null,
    allActionItemTypeOption,
    null,
    allActionItemSubTypeOption,
    null,
    null,
    allActionItemImportanceOption,
    null,
    allActionInstanceStatusOption,
    null,
    null
);

const AssociateActionItemsContainer: React.FC = () => {
    const [searchParams, setSearchParams] = useState<SearchParams>(defaultSearchParams);
    const [filterDto, setFilterDto] = useState<FilterActionItemDto>(defaultFilterActionItemsDto);
    const [paginationDto, setPaginationDto] = useState<BasePaginationDto>(defaultBasePaginationDto);
    const [selectedActionItems, setSelectedActionItems] = useState<number[]>([]);

    const menu = useMenu();
    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();
    const [actionPlanSearchParams] = useSearchParams();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });

    const actionPlanId = Number(useParams().actionPlanId);
    const actionItemSearchMode = Number(actionPlanSearchParams.get("actionItemSearchMode"));

    const getFilterAssociateActionItems = useFilterAssociateActionItems(filterDto, paginationDto);
    const filterActionItemsResponseData = getFilterAssociateActionItems[0].data;
    const actionItemTypesResponseData = getFilterAssociateActionItems[1].data;
    const actionItemSubTypesResponseData = getFilterAssociateActionItems[2].data;
    const actionItemsImportanceResponseData = getFilterAssociateActionItems[3].data;
    const actionInstanceStatusResponseData = getFilterAssociateActionItems[4].data;
    const associateActionItem = useAssociateActionItem();

    useLoader(
        areQueriesLoading(getFilterAssociateActionItems) || isMutationLoading(associateActionItem),
        AssociateActionItemsContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.ActionTracker);
        menu.changeActiveDrawerItem(DrawerTitles.ActionPlans);
    }, []);

    const changeActionItemName = (name: string): void => {
        setSearchParams({ ...searchParams, name: getNullableString(name) });
    };

    const onChangeActionTypeValue = (option: SingleValue<ActionItemTypeDto>): void => {
        setSearchParams({
            ...searchParams,
            actionItemTypeNodeId: option!.nodeId !== 0 ? option!.nodeId : null,
            actionItemType: option!,
        });
    };

    const onChangeActionItemImportance = (option: SingleValue<ActionItemImportanceDto>): void => {
        setSearchParams({
            ...searchParams,
            actionItemImportanceId:
                option!.actionItemImportanceId !== 0 ? option!.actionItemImportanceId : null,
            actionItemImportance: option!,
        });
    };

    const onChangeActionInstanceStatus = (option: SingleValue<ActionInstanceStatusDto>): void => {
        setSearchParams({
            ...searchParams,
            actionInstanceStatusId:
                option!.actionInstanceStatusId !== -1 ? option!.actionInstanceStatusId : null,
            actionInstanceStatus: option!,
        });
    };

    const onChangeActionSubTypeValue = (option: SingleValue<ActionItemTypeDto>): void => {
        setSearchParams({
            ...searchParams,
            actionItemSubTypeNodeId: option!.nodeId !== 0 ? option!.nodeId : null,
            actionItemSubType: option!,
        });
    };

    const changeRaciParticipant = (raciParticipant: string): void => {
        setSearchParams({
            ...searchParams,
            raciParticipant: getNullableString(raciParticipant),
        });
    };

    // TODO: Should rather be accepting a number type
    const changeActionItemId = (actionItemId: string): void => {
        setSearchParams({
            ...searchParams,
            actionItemId: getNullableString(actionItemId),
        });
    };

    const changeDueDateTo = (dueDateTo: Date): void => {
        setSearchParams({
            ...searchParams,
            dueDateTo: dueDateTo,
        });
    };

    const changeDueDateFrom = (dueDateFrom: Date): void => {
        setSearchParams({
            ...searchParams,
            dueDateFrom: dueDateFrom,
        });
    };
    const search = (): void => {
        setPaginationDto({
            ...paginationDto,
            pageNumber: 1,
        });
        setFilterDto({ ...filterDto, ...searchParams });
    };

    const resetFilter = (): void => {
        setSearchParams(defaultSearchParams);
        setFilterDto({ ...filterDto, ...defaultSearchParams });
    };

    const selectActionItem = (actionItemId: number): void => {
        const selectedActionItem = actionItemId;
        const selectedActionItemsArr = [...selectedActionItems];

        const index = selectedActionItemsArr.indexOf(selectedActionItem);

        if (index > -1) {
            selectedActionItemsArr.splice(index, 1);
        } else {
            selectedActionItemsArr.push(selectedActionItem);
        }

        setSelectedActionItems(selectedActionItemsArr);
    };

    const selectedActionItem = (actionItemId: number): boolean => {
        const selectedActionItem = actionItemId;
        const selectedActionItemArr = [...selectedActionItems];

        return selectedActionItemArr.includes(selectedActionItem);
    };

    const handleAssociateActionItems = (): void => {
        associateActionItem.mutate(createAssociateParameters(actionPlanId, selectedActionItems), {
            onSuccess: async () => {
                const params = [
                    createNavigateSearchParameter("success", "true"),
                    createNavigateSearchParameter(
                        "messageKey",
                        "SuccessfullyAssociatedActionItemsToActionPlan"
                    ),
                ];

                navigateSearch(`${getPath(DrawerTitles.ActionPlans)}/${actionPlanId}`, params);
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    const handleAssociateCloneActionItem = (): void => {
        const params = [createNavigateSearchParameter("actionPlanId", actionPlanId.toString())];
        const actionItemIdToClone = selectedActionItems[0]; //when this component is used for cloning, the grid check boxes become radio buttons, only allowing one action item to be selected.

        navigateSearch(
            `${getPath(DrawerTitles.ActionItems)}/${actionItemIdToClone}/create`,
            params
        );
    };

    const radioSelect: RadioSelectProps = { type: "radio" };

    useKeyPress(EnterKey, search, searchParams);

    return (
        <>
            <PageHeading>{t("AssociateActionItems")}</PageHeading>
            <SectionVerticalSpace />
            {areQueriesSuccessful(getFilterAssociateActionItems) && (
                <>
                    <ActionItemsFilter
                        actionItemName={searchParams.name}
                        changeActionItemName={changeActionItemName}
                        actionItemType={searchParams.actionItemType}
                        actionItemTypes={[allActionItemTypeOption, ...actionItemTypesResponseData!]}
                        changeActionItemType={onChangeActionTypeValue}
                        actionItemSubType={searchParams.actionItemSubType}
                        actionItemSubTypes={[
                            allActionItemSubTypeOption,
                            ...actionItemSubTypesResponseData!,
                        ]}
                        changeActionItemSubType={onChangeActionSubTypeValue}
                        raciParticipant={searchParams.raciParticipant}
                        changeRaciParticipant={changeRaciParticipant}
                        actionItemId={searchParams.actionItemId}
                        changeActionItemId={changeActionItemId}
                        actionItemImportance={searchParams.actionItemImportance}
                        actionItemImportances={[
                            allActionItemImportanceOption,
                            ...actionItemsImportanceResponseData!,
                        ]}
                        changeActionItemImportance={onChangeActionItemImportance}
                        actionInstanceStatus={searchParams.actionInstanceStatus}
                        actionInstanceStatuses={[
                            allActionInstanceStatusOption,
                            ...actionInstanceStatusResponseData!,
                        ]}
                        changeActionInstanceStatus={onChangeActionInstanceStatus}
                        dueDateFrom={searchParams.dueDateFrom}
                        changeDueDateFrom={changeDueDateFrom}
                        dueDateTo={searchParams.dueDateTo}
                        changeDueDateTo={changeDueDateTo}
                        search={search}
                        resetFilter={resetFilter}
                    />
                    <SectionVerticalSpace />

                    <DataTable
                        keyPrefix={ActionItems}
                        columns={filterActionItemsColumnNames}
                        rows={filterActionItemsResponseData!.rows}
                        noResultsMessage={t("NoActionItemsFound") as string}
                        selectItem={selectActionItem}
                        selectedItem={selectedActionItem}
                        totalItems={filterActionItemsResponseData!.recordCount}
                        radioSelect={
                            actionItemSearchMode === ActionItemSearchMode.AssociateClone
                                ? radioSelect
                                : undefined
                        }
                        paginationDto={paginationDto}
                        setPaginationDto={setPaginationDto}
                    />
                    <LargeVerticalSpace />

                    <EndAlignedDiv>
                        <Col sm={"auto"}>
                            {actionItemSearchMode === ActionItemSearchMode.Associate && (
                                <SbButton
                                    icon={FaLink}
                                    variant={"primary"}
                                    type={"button"}
                                    label={t("AssociateSelected", { keyPrefix: Common })}
                                    onClick={handleAssociateActionItems}
                                    disabled={selectedActionItems.length === 0}
                                />
                            )}
                            {actionItemSearchMode === ActionItemSearchMode.AssociateClone && (
                                <SbButton
                                    icon={FaClone}
                                    variant={"primary"}
                                    type={"button"}
                                    label={t("AssociateClone", { keyPrefix: Common })}
                                    onClick={handleAssociateCloneActionItem}
                                    disabled={selectedActionItems.length === 0}
                                />
                            )}
                        </Col>
                        <Col sm={"auto"}>
                            <CancelButton onClick={() => navigate(-1)} />
                        </Col>
                    </EndAlignedDiv>
                </>
            )}
        </>
    );
};

export default AssociateActionItemsContainer;
