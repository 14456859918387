import {
    buildPaginationSearchParameters,
    PaginationRequest,
} from "../../../domain/requests/common/pagination-request";
import { EditTenantDetailsRequest } from "../../../domain/requests/tenants/edit-tenant-details-request";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import { DisclaimerResponse } from "../../../domain/responses/tenants/disclaimer-response";
import { TenantDetailsResponse } from "../../../domain/responses/tenants/tenant-details-response";
import { TenantResponse } from "../../../domain/responses/tenants/tenant-response";
import { TenantWithUserCountResponse } from "../../../domain/responses/tenants/tenant-with-user-count-response";
import client from "../../http-client";

const route = "tenants";

export const filterTenants = async (
    prefixUrl: string,
    request: PaginationRequest
): Promise<Response<PaginationResponse<TenantResponse>>> => {
    const searchParams = buildPaginationSearchParameters(request);

    return await client
        .get(`${route}/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const filterTenantsWithUserCounts = async (
    prefixUrl: string,
    request: PaginationRequest
): Promise<Response<PaginationResponse<TenantWithUserCountResponse>>> => {
    const searchParams = buildPaginationSearchParameters(request);

    return await client
        .get(`${route}/filter-with-user-counts`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getTenantDetails = async (
    prefixUrl: string,
    tenantId: number
): Promise<Response<TenantDetailsResponse>> =>
    await client
        .get(`${route}/${tenantId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const updateTenantDetails = async (
    prefixUrl: string,
    request: EditTenantDetailsRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/update`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const getDisclaimer = async (
    prefixUrl: string,
    tenantId: number | null
): Promise<Response<DisclaimerResponse>> => {
    const searchParams = new URLSearchParams();
    searchParams.set("tenantId", tenantId?.toString() ?? "");

    return await client
        .get(`${route}/disclaimer`, {
            prefixUrl: prefixUrl,
            searchParams: searchParams,
        })
        .json();
};
