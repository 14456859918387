import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    createPredefinedAnswer,
    getAnswerTypeDetails,
} from "../../../data/services/answer-types/answer-types-service";
import { getDefaultLanguage } from "../../../data/services/language/language-service";
import BaseAnswerTypeDto from "../../dtos/answer-types/base-answer-type-dto";
import { createLanguageDtoFromResponse, LanguageDto } from "../../dtos/language/language-dto";
import { CreatePredefinedAnswerDto } from "../../dtos/predefined-answers/create-predefined-answer-dto";
import { createCreatePredefinedAnswerRequest } from "../../requests/answer-types/create-predefined-answer-request";
import { Response } from "../../responses/common/response-response";

interface CreateParams {
    answerTypeId: number;
    languageId: number;
    formData: FormData;
    flagModeId: number;
}

export const createCreateParams = (
    answerTypeId: number,
    languageId: number,
    formData: FormData,
    flagModeId: number
): CreateParams => ({
    answerTypeId: answerTypeId,
    languageId: languageId,
    formData: formData,
    flagModeId: flagModeId,
});

export const usePredefinedAnswerLookups = (): UseQueryResult<LanguageDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(["getDefaultLanguage"], () => getDefaultLanguage(url.baseUrl), {
        select: createLanguageDtoFromResponse,
        onError: errorResponseToDisplayHandler,
    });
};

export const useCreatePredefinedAnswer = (): UseMutationResult<
    Response<number | null>,
    HTTPError,
    CreatePredefinedAnswerDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: CreatePredefinedAnswerDto) => {
        const { answerTypeId, languageId, formData, flagModeId } = mutationKey;

        const request = createCreatePredefinedAnswerRequest(
            answerTypeId,
            languageId,
            formData.get("value") as string,
            flagModeId,
            formData.get("tooltip") as string
        );

        return createPredefinedAnswer(url.baseUrl, request);
    });
};

export const useGetAnswerTypeDetails = (
    answerTypeId: number
): UseQueryResult<BaseAnswerTypeDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getAnswerTypeDetails", answerTypeId],
        () => getAnswerTypeDetails(url.baseUrl, answerTypeId),
        {
            select: BaseAnswerTypeDto.constructFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};
