import React, { ChangeEventHandler, useContext } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { ThemeContext } from "styled-components";
import { getErrorStyle, StyledErrorDiv, StyledFormLabel } from "../../../core/theme/global-styles";
import SbLabelText from "../../atoms/SbLabelText";

const SbFormikTextFieldGroup: React.FC<{
    name: string;
    label: string;
    type: string;
    defaultValue?: string | null;
    value?: string;
    required?: boolean;
    disabled?: boolean;
    children?: React.ReactNode;
    onFormikChange?: ChangeEventHandler<Element>;
    error?: string;
    touched?: boolean;
}> = ({
    name,
    label,
    type,
    defaultValue,
    value,
    required,
    disabled,
    children,
    onFormikChange,
    error,
    touched,
}) => {
    const themeContext = useContext(ThemeContext);

    return (
        <Form.Group as={Row} className="mb-3">
            <StyledFormLabel column sm={2}>
                <SbLabelText label={label} required={required} />
            </StyledFormLabel>
            <Col sm={4}>
                <Form.Control
                    name={name}
                    type={type}
                    key={defaultValue}
                    defaultValue={defaultValue ?? undefined}
                    style={error && touched ? getErrorStyle(themeContext!) : undefined}
                    value={value}
                    disabled={disabled}
                    onChange={onFormikChange}
                />
                {error && touched && <StyledErrorDiv>{error}</StyledErrorDiv>}
            </Col>
            {children && <Col>{children}</Col>}
        </Form.Group>
    );
};

export default SbFormikTextFieldGroup;
