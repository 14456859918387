import { buildPaginationSearchParameters, PaginationRequest } from "../common/pagination-request";

export interface FilterRoleUsersRequest extends PaginationRequest {
    roleId: number;
}

export const createFilterRoleUsersRequest = (
    paginationRequest: PaginationRequest,
    roleId: number
): FilterRoleUsersRequest => ({
    ...paginationRequest,
    roleId: roleId,
});

export const buildFilterSearchParameters = (request: FilterRoleUsersRequest): URLSearchParams => {
    const searchParams = buildPaginationSearchParameters(request);
    searchParams.set("roleId", request.roleId.toString());

    return searchParams;
};
