export class CreateUserNodeSuspensionDto {
    userId: number;
    suspensionStartDate: Date;
    suspensionEndDate: Date;
    secondaryUserId: number;

    public constructor(userId: number, formData: FormData) {
        this.userId = userId;
        this.suspensionStartDate = new Date(formData.get("suspensionStartDate") as string);
        this.suspensionEndDate = new Date(formData.get("suspensionEndDate") as string);
        this.secondaryUserId = Number(formData.get("secondaryUser") as string);
    }

    public static createUserNodeSuspensionDto = (
        userId: number,
        formData: FormData
    ): CreateUserNodeSuspensionDto => new CreateUserNodeSuspensionDto(userId, formData);
}
