import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { getRoles } from "../../../data/services/users/roles-service";
import { RoleDto, toRoleDtos } from "../../dtos/users/role-dto";
import { createPaginationRequest } from "../../requests/common/pagination-request";

export const useGetRoles = (): UseQueryResult<RoleDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(["getRoles"], () => getRoles(url.baseUrl, createPaginationRequest(1, 100)), {
        select: toRoleDtos,
        onError: errorResponseToDisplayHandler,
    });
};
