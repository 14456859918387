import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
    createBasePaginationRequestFromDto,
} from "../common/base-pagination-request";

export interface FilterPredefinedAnswersRequest extends BasePaginationRequest {
    answerTypeId: number;
}

export const createFilterPredefinedAnswersRequest = (
    paginationDto: BasePaginationDto,
    answerTypeId: number
): FilterPredefinedAnswersRequest => ({
    ...createBasePaginationRequestFromDto(paginationDto),
    answerTypeId: answerTypeId,
});

export const buildFilterPredefinedAnswersSearchParameters = (
    request: FilterPredefinedAnswersRequest
): URLSearchParams => {
    const searchParams = buildBasePaginationSearchParameters(request);

    searchParams.set("answerTypeId", request.answerTypeId.toString());

    return searchParams;
};
