import { CreateWizardCardRequest } from "../../../domain/requests/wizards/create-wizard-card-request";
import { EditWizardCardRequest } from "../../../domain/requests/wizards/edit-wizard-card-request";
import { OrderWizardCardRequest } from "../../../domain/requests/wizards/order-wizard-card-request";
import { Response } from "../../../domain/responses/common/response-response";
import { ImageLibraryViewResponse } from "../../../domain/responses/image-library/image-library-view-response";
import { WizardCardResponse } from "../../../domain/responses/wizards/wizard-card-response";
import client from "../../http-client";

const route = "wizardcardsadmin";

export const getWizardCards = async (
    prefixUrl: string,
    wizardId: number
): Promise<Response<WizardCardResponse[]>> =>
    await client
        .get(`${route}/filter/${wizardId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const filterActiveImagesByWizardId = async (
    prefixUrl: string,
    wizardId: number
): Promise<Response<ImageLibraryViewResponse[]>> => {
    return await client
        .get(`${route}/active-images/${wizardId}`, {
            prefixUrl: prefixUrl,
        })
        .json();
};

export const createWizardCard = async (
    prefixUrl: string,
    request: CreateWizardCardRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/create`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const editWizardCard = async (
    prefixUrl: string,
    request: EditWizardCardRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/edit`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const deleteWizardCard = async (
    prefixUrl: string,
    cardId: number
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/delete/${cardId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const orderWizardCards = async (
    prefixUrl: string,
    request: OrderWizardCardRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/order`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();
