import { TableRow } from "../../../core/utilities/customTypes";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import { UserResponse } from "../../responses/users/user-response";

export interface ListTableDto<TType> {
    rows: TableRow<TType>[];
}

export const toToDataTableUserDetailsDtoRows = (data: UserResponse[]): ListTableDto<number> => {
    const rows = data.map((x) => ({
        metadata: x.userId,
        columns: [
            {
                value: `${x.firstName} ${x.lastName}`,
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return { rows: rows };
};
