import { ReopenFinalizedActionItemDto } from "../../dtos/action-items/reopen-finalized-action-item-dto";

export class ReopenFinalizedActionItemRequest {
    actionItemInstanceId: number;
    comment: string;

    public constructor(dto: ReopenFinalizedActionItemDto) {
        this.actionItemInstanceId = dto.actionItemInstanceId;
        this.comment = dto.comment;
    }
}
