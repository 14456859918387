import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { AuthContextType, useAuth } from "../../../core/store/auth-context";
import { useUrl } from "../../../core/store/url-context";
import { AccordionTitles, DataTableColumnTypes } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { filterNodeTypeValues } from "../../../data/services/hierarchy/node-type-values-service";
import { filterNodeTypes } from "../../../data/services/hierarchy/node-types-service";
import { filterNodesWithPath } from "../../../data/services/hierarchy/nodes-service";
import {
    lookupCountryNodeTypeValues,
    lookupHierarchyTypes,
    lookupMaxAgreedActions,
    lookupMaxAssociatedRisks,
} from "../../../data/services/lookup/lookup-service";
import { createBasePaginationDto } from "../../dtos/common/base-pagination-dto";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { PaginationDto } from "../../dtos/common/pagination-dto";
import { SearchDto } from "../../dtos/common/search-dto";
import {
    BaseNodeDetailsDto,
    createBaseNodeDetailsDto,
} from "../../dtos/hierarchy/base-node-details-dto";
import {
    HierarchyTypeDto,
    toLookupHierarchyTypeDtos,
} from "../../dtos/hierarchy/hierarchy-type-dto";
import {
    NodeTypeDto,
    toNodeTypeDtosFromPaginatedResponse,
} from "../../dtos/hierarchy/node-type-dto";
import { NodeTypeValueDto, toNodeTypeValueDtos } from "../../dtos/hierarchy/node-type-value-dto";
import { hasRoleTypeInGroup, MonitoringRoles, NodeRoleGroup } from "../../enums/Roles";
import { createSearchRequestFromDto } from "../../requests/common/search-request";
import { createFilterNodesRequest } from "../../requests/hierarchy/filter-node-request";
import { createFilterNodeTypeValuesRequest } from "../../requests/hierarchy/filter-node-type-values-request";
import { createFilterNodeTypesRequest } from "../../requests/hierarchy/filter-node-types-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { BaseNodeDetailsResponse } from "../../responses/hierarchy/base-node-details-response";
import { NodeTypeValueResponse } from "../../responses/hierarchy/node-type-value-response";

export const useFilterHierarchyTypes = (): UseQueryResult<HierarchyTypeDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(["filterHierarchyTypes"], () => lookupHierarchyTypes(url.baseUrl), {
        select: toLookupHierarchyTypeDtos,
        onError: errorResponseToDisplayHandler,
    });
};

export const useFilterNodeTypes = (
    hierarchyTypeId: number | null
): UseQueryResult<NodeTypeDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterNodeTypes", hierarchyTypeId],
        () =>
            filterNodeTypes(
                url.baseUrl,
                createFilterNodeTypesRequest(
                    hierarchyTypeId,
                    null,
                    createBasePaginationDto(1, 1000)
                )
            ),
        {
            enabled: hierarchyTypeId != null,
            select: toNodeTypeDtosFromPaginatedResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useFilterNodeTypeValuesToTableRows = (
    hierarchyTypeId: number | null,
    nodeTypeId: number | null,
    nodeTypeValueValue: string | null,
    paginationDto: PaginationDto
): UseQueryResult<PaginatedTableDto<number>, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterNodeTypeValues", hierarchyTypeId, nodeTypeId, nodeTypeValueValue, paginationDto],
        () =>
            filterNodeTypeValues(
                url.baseUrl,
                createFilterNodeTypeValuesRequest(
                    hierarchyTypeId! != -1 ? hierarchyTypeId : null,
                    nodeTypeId! != -1 ? nodeTypeId : null,
                    nodeTypeValueValue?.trim() ?? null,
                    null,
                    paginationDto
                )
            ),
        {
            enabled: hierarchyTypeId != null && nodeTypeId != null,
            select: transformToDataTableRows,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const transformToDataTableRows = (
    response: Response<PaginationResponse<NodeTypeValueResponse>>
): PaginatedTableDto<number> => {
    const rows = response.data.results!.map((nodeTypeValue) => {
        return {
            metadata: nodeTypeValue.nodeTypeValueId,
            columns: [
                {
                    // Name
                    value: nodeTypeValue.value,
                    type: DataTableColumnTypes.Text,
                },
            ],
        };
    });

    return {
        numberOfPages: response.data.pageCount,
        pageSize: response.data.pageSize,
        currentPage: response.data.currentPage,
        recordCount: response.data.recordCount,
        rows: rows,
    };
};

export const useLookupCountryNodeTypeValues = (
    searchDto: SearchDto
): UseQueryResult<NodeTypeValueDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const auth = useAuth();

    return useQuery(
        ["lookupCountryNodeTypeValues", searchDto],
        () => lookupCountryNodeTypeValues(url.baseUrl, createSearchRequestFromDto(searchDto)),
        {
            enabled: hasRoleTypeInGroup(auth.userRoles, MonitoringRoles.ElevatedMonitoringRoles),
            select: toNodeTypeValueDtos,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useLookupMaxAgreedActions = (): UseQueryResult<number, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(["lookupMaxAgreedActions"], () => lookupMaxAgreedActions(url.baseUrl), {
        select: (response) => response.data,
        onError: errorResponseToDisplayHandler,
    });
};

export const useLookupMaxAssociatedRisks = (): UseQueryResult<number, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(["lookupMaxAssociatedRisks"], () => lookupMaxAssociatedRisks(url.baseUrl), {
        select: (response) => response.data,
        onError: errorResponseToDisplayHandler,
    });
};

export const useFilterNodesWithPath = (
    searchSelected: boolean,
    paginationDto: PaginationDto,
    nodeTypeValueId: number | null,
    mode: "select" | "hierarchy"
): UseQueryResult<PaginatedTableDto<BaseNodeDetailsDto>, HTTPError> => {
    const url = useUrl();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const navigate = useNavigate();

    return useQuery(
        ["filterNodesWithPath", nodeTypeValueId, paginationDto],
        () =>
            filterNodesWithPath(
                url.baseUrl,
                createFilterNodesRequest(nodeTypeValueId!, paginationDto)
            ),
        {
            enabled: searchSelected && nodeTypeValueId != null,
            keepPreviousData: true,
            select: (response: Response<PaginationResponse<BaseNodeDetailsResponse>>) =>
                toToDataTableBaseNodeDetailsDtoRows(response.data, auth, navigate, mode),
            onError: errorResponseToDisplayHandler,
        }
    );
};

const toToDataTableBaseNodeDetailsDtoRows = (
    response: PaginationResponse<BaseNodeDetailsResponse>,
    auth: AuthContextType,
    navigate: NavigateFunction,
    mode: "select" | "hierarchy"
): PaginatedTableDto<BaseNodeDetailsDto> => {
    const { pageCount, pageSize, currentPage, recordCount } = response;

    const showAddChildAction = hasRoleTypeInGroup(auth.userRoles, NodeRoleGroup.WriteRoles);

    const rows = response.results.map((x) => ({
        metadata: createBaseNodeDetailsDto(x),
        showAddChildAction: mode === "hierarchy" && showAddChildAction,
        showSelectAction: mode === "select",
        columns: [
            {
                value: x.shortDescription,
                type: DataTableColumnTypes.LinkAndRibbon,
                linkItemAction: (metadata: BaseNodeDetailsDto) =>
                    navigate(`${getPath(AccordionTitles.VisualTree)}/${metadata.nodeId}`),
                showRibbon: x.isTraining,
            },
            {
                value: x.path,
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
