import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { isMutationLoading } from "../../../core/utilities/responseStateHelper";
import { useEditWizard } from "../../../domain/viewmodels/wizards/edit-wizard-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

interface ConfirmationDetailsProps {
    name: string;
}

const EditWizardContainer: React.FC = () => {
    const wizardId = Number(useParams().wizardId);

    const menu = useMenu();
    const navigate = useNavigate();
    const location = useLocation();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const { name } = location.state as ConfirmationDetailsProps;

    const editWizard = useEditWizard();

    useLoader(isMutationLoading(editWizard), EditWizardContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.WizardCards, AccordionTitles.Wizards);
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        editWizard.mutate(
            {
                wizardId: wizardId,
                formData: new FormData(event.currentTarget),
            },
            {
                onSuccess: async () => navigate(-1),
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    return (
        <>
            <PageHeading>Edit Wizard</PageHeading>
            <SectionVerticalSpace />
            <TextTitledPanel title="Edit Wizard">
                <Form onSubmit={handleSubmit}>
                    <SbFormTextFieldGroup
                        name="name"
                        label="Wizard Name"
                        type="text"
                        maxLength={255}
                        defaultValue={name}
                        required
                    />

                    <EndAlignedDiv>
                        <SaveButton type="submit" />
                        <CancelButton onClick={() => navigate(-1)} />
                    </EndAlignedDiv>
                </Form>
            </TextTitledPanel>
        </>
    );
};

export default EditWizardContainer;
