import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaAnchor } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { SingleValue } from "react-select";
import styled from "styled-components";
import { ChecklistQuestions, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    SectionVerticalSpace,
    StyledFilterTitle,
} from "../../../core/theme/global-styles";
import { isMutationLoading, isQueryLoading } from "../../../core/utilities/responseStateHelper";
import {
    AssuranceReviewSearchDto,
    createAssuranceReviewSearchDto,
    defaultAssuranceReviewSearchDto,
} from "../../../domain/dtos/common/search-dto";
import { AssuranceReviewDto } from "../../../domain/dtos/review-reports/assurance-review-dto";
import { createLinkReviewReportSetWithAssuranceReviewDto } from "../../../domain/dtos/review-reports/link-review-report-set-assurance-review-dto";
import { Response } from "../../../domain/responses/common/response-response";
import {
    useLinkReviewReportSetWithAssuranceReview,
    useLookupAssuranceReviews,
} from "../../../domain/viewmodels/review-reports/link-review-report-with-assurance-review-viewmodel";
import { SbSelect } from "../../atoms/input/SbSelect";
import { SbAlert } from "../../atoms/SbAlert";
import { SbButton } from "../../atoms/SbButton";
import { SbAccordion } from "../../molecules/SbAccordion";
const StyledRow = styled(Row)`
    padding-bottom: ${(props) => props.theme.padding.md};
`;
export const LinkReviewReportWithAssuranceReviewContainer: React.FC<{
    assuranceReviewDto?: AssuranceReviewDto | null;
}> = ({ assuranceReviewDto }) => {
    const reviewReportSetId = Number(useParams().reviewReportSetId);

    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const toast = useToast();
    const [assuranceReview, setAssuranceReview] = useState<AssuranceReviewDto | null>(null);
    const [searchDto, setSearchDto] = useState<AssuranceReviewSearchDto>(
        defaultAssuranceReviewSearchDto
    );
    const [showValidationMessage, setShowValidationMessage] = useState<boolean>(false);

    const lookupAssuranceReviews = useLookupAssuranceReviews(searchDto);
    const lookupAssuranceReviewsData = lookupAssuranceReviews.data!;

    const linkAssuranceReview = useLinkReviewReportSetWithAssuranceReview();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    useLoader(isMutationLoading(linkAssuranceReview), LinkReviewReportWithAssuranceReviewContainer);

    useEffect(() => {
        if (assuranceReviewDto) {
            onAssuranceReviewSelected({
                assuranceReviewId: assuranceReviewDto.assuranceReviewId!,
                assuranceReviewName: assuranceReviewDto.assuranceReviewName!,
            });
        }
    }, [assuranceReviewDto, setAssuranceReview]);

    const onAssuranceReviewSelected = (option: SingleValue<AssuranceReviewDto>): void =>
        setAssuranceReview(option!);

    const onLinkAssuranceReview = () => {
        if (assuranceReview === null) {
            setShowValidationMessage(true);
            return;
        } else {
            setShowValidationMessage(false);
        }

        linkAssuranceReview.mutate(
            createLinkReviewReportSetWithAssuranceReviewDto(
                reviewReportSetId,
                assuranceReview?.assuranceReviewId!,
                assuranceReview?.assuranceReviewName!
            ),
            {
                onSuccess: async (_: Response<boolean>) => {
                    toast.addToast(
                        createSuccessToastProps([
                            "Successfully linked review report with assurance review",
                        ])
                    );
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    return (
        <>
            <SbAccordion title={t("LinkAssuranceReview")}>
                {showValidationMessage && (
                    <>
                        <SbAlert variant="warning" text={t("LinkAssuranceReviewRequired")} />
                    </>
                )}

                <StyledFilterTitle>{t("LinkAssuranceReviewSearch")}</StyledFilterTitle>
                <StyledRow>
                    <Col md={6}>
                        <SbSelect
                            name={"assuranceReviewName"}
                            placeholderText={
                                t("LookupSearchPlaceholderText", { keyPrefix: Common })!
                            }
                            searchable
                            clearable
                            loading={isQueryLoading(lookupAssuranceReviews)}
                            items={lookupAssuranceReviewsData}
                            onSearchTextChanged={(text: string) => {
                                setSearchDto(createAssuranceReviewSearchDto(text, null));
                            }}
                            itemLabel={(option: AssuranceReviewDto) => option.assuranceReviewName!}
                            itemValue={(option: AssuranceReviewDto) =>
                                option.assuranceReviewId!.toString()
                            }
                            onChange={(option: SingleValue<AssuranceReviewDto>) =>
                                onAssuranceReviewSelected(option)
                            }
                            value={assuranceReview}
                        />
                    </Col>
                    <Col md={2}>
                        <SbButton
                            variant="primary"
                            type={"button"}
                            hoverVariant="border"
                            label={t("Link", { keyPrefix: Common })}
                            onClick={() => onLinkAssuranceReview()}
                            icon={FaAnchor}
                            iconSize="lg"
                        />
                    </Col>
                </StyledRow>
                <SectionVerticalSpace />
                <ContentContainer>
                    <Row>
                        <Col>
                            <DetailsLabel>{t("LinkedAssuranceReviewName")}</DetailsLabel>
                            <DetailsLabel>
                                <i>{t("LinkedAssuranceReviewId")}</i>
                            </DetailsLabel>
                        </Col>
                        <Col>
                            <DetailsValue>
                                {assuranceReview?.assuranceReviewName ?? "-"}
                            </DetailsValue>
                            <DetailsValue>
                                <i>{assuranceReview?.assuranceReviewId ?? "-"}</i>
                            </DetailsValue>
                        </Col>
                    </Row>
                </ContentContainer>
            </SbAccordion>
        </>
    );
};

export default LinkReviewReportWithAssuranceReviewContainer;
