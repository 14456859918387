import NodeChecklistDefaultValuesDto from "../../dtos/checklists/node-checklist-default-values-dto";
import {
    createCreateReferentialLinkRequest,
    CreateReferentialLinkRequest,
} from "../referential-link-types/create-referential-link-request";
import IntervalOverridesRequest from "./interval-overrides-request";

export default class NodeChecklistDefaultValuesRequest {
    nodeId: number;
    intervalOverridesRequest: IntervalOverridesRequest | null;
    referentialLinks: number[] | null;
    createReferentialLinkRequests: CreateReferentialLinkRequest[] | null;

    public constructor(dto: NodeChecklistDefaultValuesDto) {
        const { nodeId, intervalOverridesDto, referentialLinks, createdReferentialLinks } = dto;

        this.nodeId = nodeId;

        this.intervalOverridesRequest = intervalOverridesDto
            ? new IntervalOverridesRequest(intervalOverridesDto)
            : null;

        this.referentialLinks = referentialLinks;

        this.createReferentialLinkRequests =
            createdReferentialLinks?.map((x) => createCreateReferentialLinkRequest(x[0], x[1])) ??
            null;
    }
}
