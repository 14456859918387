import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useAuth } from "../../../core/store/auth-context";
import { useUrl } from "../../../core/store/url-context";
import { editProfile } from "../../../data/services/users/users-service";
import { EditProfileDto } from "../../dtos/profile/edit-profile-dto";
import { Response } from "../../responses/common/response-response";

export const useEditProfile = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    EditProfileDto
> => {
    const url = useUrl();
    const auth = useAuth();

    // If admin is impersonating, use the admin url
    const urlToUse = auth.isImpersonating ? url.originalBaseUrl : url.baseUrl;

    return useMutation((editProfileDto: EditProfileDto) => {
        return editProfile(urlToUse, editProfileDto);
    });
};
