import { ReactElement, useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import {
    Common,
    HierarchyTypes,
    HierarchyTypeTreeView,
} from "../../../core/constants/translation-namespace";
import { HierarchyMenuType } from "../../../core/hierarchyMenuType";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    maxContentWidthSelectStyle,
    PageHeading,
    SectionVerticalSpace,
    StyledFormLabel,
    StyledRequiredStar,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import { HierarchyTypeDto } from "../../../domain/dtos/hierarchy/hierarchy-type-dto";
import { useFilterHierarchyTypes } from "../../../domain/viewmodels/hierarchy/hierarchy-actions-viewmodel";
import { useFilterNodeTypeHierarchy } from "../../../domain/viewmodels/hierarchy/visual-structure-viewmodel";
import { SbSelect } from "../../atoms/input/SbSelect";
import HierarchyTreeContainer, {
    createDataManagementProps,
    DataManagementProps,
} from "../../hierarchy/HierarchyTreeContainer";
import { ComponentTitledPanel } from "../../molecules/SbPanel";

const VisualStructureContainer: React.FC = () => {
    const menu = useMenu();
    const { t } = useTranslation("translation", { keyPrefix: HierarchyTypeTreeView });

    const [hierarchyTypeId, setHierarchyTypeId] = useState<number | null>(null);

    const filterHierarchyTypes = useFilterHierarchyTypes();
    const filterHierarchyTypesData = filterHierarchyTypes.data!;
    const filterNodeTypeHierarchy = useFilterNodeTypeHierarchy(hierarchyTypeId);

    useLoader(isQueryLoading(filterHierarchyTypes), VisualStructureContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.VisualStructure);
    }, []);

    const selectedHierarchyTypeId = useRef<number | null>(null);

    const setDefaultSelectedHierarchyTypeItem = (): HierarchyTypeDto => {
        if (selectedHierarchyTypeId.current == null) {
            selectedHierarchyTypeId.current = !filterHierarchyTypesData.length
                ? 0
                : filterHierarchyTypesData[0].hierarchyTypeId;

            if (filterHierarchyTypesData.length) {
                onHierarchyTypeChanged(filterHierarchyTypesData[0].hierarchyTypeId);
            }
        }

        return filterHierarchyTypesData[0];
    };

    const onHierarchyTypeSelected = (option: SingleValue<HierarchyTypeDto>): void => {
        onHierarchyTypeChanged(option!.hierarchyTypeId);
    };

    const onHierarchyTypeChanged = (hierarchyTypeId: number): void =>
        setHierarchyTypeId(hierarchyTypeId);

    const buildDataManagementProps = (): DataManagementProps =>
        createDataManagementProps(
            filterNodeTypeHierarchy.data,
            isQueryLoading(filterNodeTypeHierarchy),
            isQuerySuccessful(filterNodeTypeHierarchy)
        );

    const buildFilterComponent = (): ReactElement<HTMLFormElement> => {
        return (
            <>
                <Form.Group as={Row}>
                    <StyledFormLabel column sm={"auto"}>
                        {t("HierarchyType", { keyPrefix: HierarchyTypes })}
                        <StyledRequiredStar>*</StyledRequiredStar>
                    </StyledFormLabel>
                    <Col md={"3"}>
                        <SbSelect
                            name={"hierarchyTypes"}
                            styles={maxContentWidthSelectStyle}
                            placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                            searchable
                            clearable={false}
                            items={filterHierarchyTypes.data!}
                            defaultSelectedItem={setDefaultSelectedHierarchyTypeItem()}
                            onChange={onHierarchyTypeSelected}
                            itemLabel={(option: HierarchyTypeDto) => option.name}
                            itemValue={(option: HierarchyTypeDto) => option.name}
                        />
                    </Col>
                </Form.Group>
            </>
        );
    };

    return (
        <>
            <PageHeading>{t("NodeTypeHierarchyTreeView")}</PageHeading>
            <SectionVerticalSpace />
            {isQuerySuccessful(filterHierarchyTypes) && (
                <ComponentTitledPanel component={buildFilterComponent()}>
                    <HierarchyTreeContainer
                        focusedPathEnabled={false}
                        depth={null}
                        dataManagement={buildDataManagementProps()}
                        dataDetailsBaseNavigationUrl={getPath(AccordionTitles.VisualStructure)}
                        menuType={HierarchyMenuType.Details | HierarchyMenuType.Edit}
                        leafDataManagement={null}
                    />
                </ComponentTitledPanel>
            )}
        </>
    );
};

export default VisualStructureContainer;
