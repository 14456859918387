import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { AccordionLinks } from "../../core/constants/translation-namespace";
import { useMenu } from "../../core/store/menu-context";
import { StyledMenuItemLink } from "../../core/theme/global-styles";
import { getPath } from "../../core/utilities/getPath";

interface StyledAccordionLinkProps {
    readonly $active?: boolean;
    readonly $drawerOpen?: boolean;
}

const StyledAccordionLink = styled(StyledMenuItemLink)<StyledAccordionLinkProps>`
    width: 100%;
    padding-left: ${(props) => props.$drawerOpen && props.theme.padding.xl};
    padding-top: ${(props) => props.theme.padding.md};
    padding-bottom: ${(props) => props.theme.padding.md};
    display: flex;
    align-items: center;
    justify-content: ${(props) => (props.$drawerOpen ? "flex-start" : "center")};
    text-align: center;
    color: white;
    font-weight: ${(props) => props.$active && "bold"};

    border-right: ${(props) =>
        props.$active && props.theme.padding.sm + " solid " + props.theme.palette.white};
`;

const StyledDivider = styled.div`
    height: ${(props) => props.theme.padding.xxs};
    margin-left: ${(props) => props.theme.padding.lg};
    margin-right: ${(props) => props.theme.padding.lg};
    margin-bottom: ${(props) => props.theme.padding.md};
    margin-top: ${(props) => props.theme.padding.md};
    background-color: ${(props) => props.theme.palette.white};
`;

const StyledText = styled.div``;

const AccordionLink: React.FC<{
    drawerItemTitle: string;
    title: string;
    active: boolean;
    isNewSection?: boolean | undefined;
}> = ({ title, active, isNewSection }) => {
    const menu = useMenu();
    const { t } = useTranslation("translation", { keyPrefix: AccordionLinks });

    return (
        <>
            {isNewSection && <StyledDivider />}
            <StyledAccordionLink $active={active} $drawerOpen={menu.drawerOpen} to={getPath(title)}>
                <StyledText>{t(title)}</StyledText>
            </StyledAccordionLink>
        </>
    );
};

export default AccordionLink;
