import ProcessHierarchyImportRequest from "../../../domain/requests/data-import/process-hierarchy-import-request";
import ValidateHierarchyImportRequest from "../../../domain/requests/data-import/validate-hierarchy-import-request";
import { Response } from "../../../domain/responses/common/response-response";
import ValidateHierarchyImportResponse from "../../../domain/responses/data-import/validate-hierarchy-import-response";
import client from "../../http-client";

const route = "dataimport";

export const validateHierarchyImport = async (
    prefixUrl: string,
    request: ValidateHierarchyImportRequest
): Promise<Response<ValidateHierarchyImportResponse>> =>
    await client
        .post(`${route}/validate`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const processHierarchyImport = async (
    prefixUrl: string,
    request: ProcessHierarchyImportRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/process`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();
