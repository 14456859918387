import { AssociatedRiskGroupResponse } from "../../responses/associated-risks/associated-risk-group-response";
import { QuestionSetInstanceReviewAssociatedRiskDto } from "../review-reports/question-set-instance-review-associated-risk-dto";

export class AssociatedRiskGroupDto {
    primaryAssociatedRisk: QuestionSetInstanceReviewAssociatedRiskDto;
    secondaryAssociatedRisk: QuestionSetInstanceReviewAssociatedRiskDto;
    tertiaryAssociatedRisks: QuestionSetInstanceReviewAssociatedRiskDto[];

    public constructor(response: AssociatedRiskGroupResponse) {
        this.primaryAssociatedRisk = response.primaryRiskResponse;
        this.secondaryAssociatedRisk = response.secondaryRiskResponse;
        this.tertiaryAssociatedRisks = response.tertiaryRiskResponses;
    }

    public static toAssociatedRiskGroupDtos = (
        responses: AssociatedRiskGroupResponse[]
    ): AssociatedRiskGroupDto[] => responses.map((x) => new AssociatedRiskGroupDto(x));
}
