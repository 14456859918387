import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
} from "../common/base-pagination-request";
import {
    buildHierarchyTypesSearchParameters,
    SearchHierarchyTypesRequest,
} from "./search-hierarchy-types-request";

export interface FilterHierarchyTypesRequest
    extends SearchHierarchyTypesRequest,
        BasePaginationRequest {}

export const createFilterHierarchyTypesRequest = (
    paginationRequest: BasePaginationRequest,
    searchRequest: SearchHierarchyTypesRequest
): FilterHierarchyTypesRequest => ({
    pageNumber: paginationRequest.pageNumber,
    pageSize: paginationRequest.pageSize,
    hierarchyAssignableFlag: searchRequest.hierarchyAssignableFlag,
});

export const buildFilterSearchParameters = (
    request: FilterHierarchyTypesRequest
): URLSearchParams => {
    const searchParams = buildBasePaginationSearchParameters(request);
    buildHierarchyTypesSearchParameters(request, searchParams);

    return searchParams;
};
