import ChecklistReportSummaryResponse from "../../../domain/requests/reports/checklist-report-summary-response";
import FilterChecklistReportSummariesRequest from "../../../domain/requests/reports/filter-checklist-report-summaries-request";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import ChecklistReportDetailsResponse from "../../../domain/responses/reports/checklist-report-details-response";
import FileContentResponse from "../../../domain/responses/reports/file-content-response";
import client from "../../http-client";

const route = "checklistreports";

export const filterChecklistReportSummaries = async (
    prefixUrl: string,
    request: FilterChecklistReportSummariesRequest
): Promise<Response<PaginationResponse<ChecklistReportSummaryResponse>>> => {
    const searchParams = request.buildSearchParameters();

    return await client
        .get(`${route}/filter`, {
            prefixUrl: prefixUrl,
            searchParams: searchParams,
        })
        .json();
};

export const getChecklistReportDetails = async (
    prefixUrl: string,
    questionSetInstanceId: number
): Promise<Response<ChecklistReportDetailsResponse>> =>
    await client
        .get(`${route}/${questionSetInstanceId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const downloadChecklistExcelReport = async (
    prefixUrl: string,
    questionSetInstanceId: number
): Promise<Response<FileContentResponse>> =>
    await client
        .get(`${route}/export/${questionSetInstanceId}`, {
            prefixUrl: prefixUrl,
        })
        .json();
