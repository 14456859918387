import { Response } from "../../../domain/responses/common/response-response";
import { ConversationTokenResponse } from "../../../domain/responses/nala/ConversationTokenResponse";
import client from "../../http-client";

const route = "nala";

export const createNalaConversationToken = async (
    prefixUrl: string
): Promise<Response<ConversationTokenResponse>> => {
    return await client
        .get(`${route}`, {
            prefixUrl: prefixUrl,
        })
        .json();
};
