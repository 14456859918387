import { localDateSerialiserReplacer } from "../../../core/utilities/serialiser";
import {
    buildFilterAdhocChecklistSearchParameters,
    FilterAdhocChecklistRequest,
} from "../../../domain/requests/checklists/filter-adhoc-checklists-request";
import FilterByNodeWithARMonitoringRequest from "../../../domain/requests/checklists/filter-by-node-with-ar-monitoring-request";
import NodeChecklistDefaultValuesRequest from "../../../domain/requests/checklists/node-checklist-default-values-request";
import TriggerAdhocQuestionSetRequest from "../../../domain/requests/checklists/trigger-adhoc-question-set-request";
import { AdhocQuestionSetAssignmentScheduleResponse } from "../../../domain/responses/checklists/adhoc-question-set-assignment-schedule-response";
import { AdhocQuestionSetInstanceResponse } from "../../../domain/responses/checklists/adhoc-question-set-instance-response";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import { ReferentialLinkTypeResponse } from "../../../domain/responses/referential-links/referential-link-type-response";
import client from "../../http-client";

const route = "adhocchecklists";

export const filterAdhocQuestionSetSchedules = async (
    prefixUrl: string,
    request: FilterByNodeWithARMonitoringRequest
): Promise<Response<PaginationResponse<AdhocQuestionSetAssignmentScheduleResponse>>> => {
    const searchParams = request.buildFilterByNodeWithARMonitoringSearchParameters();

    return await client
        .get(`${route}/schedules`, {
            prefixUrl: prefixUrl,
            searchParams: searchParams,
        })
        .json();
};

export const triggerAdhocQuestionSet = async (
    prefixUrl: string,
    request: TriggerAdhocQuestionSetRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/trigger`, {
            prefixUrl: prefixUrl,
            body: JSON.stringify(request, localDateSerialiserReplacer(["startDate", "dueDate"])),
        })
        .json();

export const saveNodeChecklistDefaultValues = async (
    prefixUrl: string,
    request: NodeChecklistDefaultValuesRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/save-node-checklist-default-values`, {
            prefixUrl: prefixUrl,
            body: JSON.stringify(request, localDateSerialiserReplacer(["startDate", "dueDate"])),
        })
        .json();

export const filterPendingAdhocChecklists = async (
    prefixUrl: string,
    request: FilterAdhocChecklistRequest
): Promise<Response<PaginationResponse<AdhocQuestionSetInstanceResponse>>> => {
    const searchParams = buildFilterAdhocChecklistSearchParameters(request);

    return await client
        .get(`${route}/pending-adhoc-checklists/filter`, {
            prefixUrl: prefixUrl,
            searchParams: searchParams,
        })
        .json();
};

export const cancelQuestionSetInstance = async (
    prefixUrl: string,
    questionSetInstanceId: number
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/cancel/${questionSetInstanceId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const filterARMonitoringReferentialLinkTypes = async (
    prefixUrl: string
): Promise<Response<ReferentialLinkTypeResponse[]>> =>
    await client
        .get(`${route}/filter-ar-monitoring-referential-link-types`, {
            prefixUrl: prefixUrl,
        })
        .json();
