import { ReactNode } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { StyledFormLabel } from "../../../core/theme/global-styles";
import SbNumberBox, { SbNumberBoxProps } from "../../atoms/input/SbNumberBox";
import SbLabelText from "../../atoms/SbLabelText";
import { BaseSbFormGroupProps } from "../../props/input/InputFieldProps";

interface SbFormNumberBoxGroupProps extends BaseSbFormGroupProps, SbNumberBoxProps {
    children?: ReactNode;
}

const SbFormNumberBoxGroup: React.FC<SbFormNumberBoxGroupProps> = ({
    label,
    name,
    value,
    defaultValue,
    placeholder,
    minValue,
    maxValue,
    onInputChange: onChangeEventHandler,
    required,
    children,
    disabledStyling,
}) => (
    <Form.Group as={Row} className="mb-3">
        <StyledFormLabel column sm={2}>
            <SbLabelText label={label} required={required} />
        </StyledFormLabel>
        <Col sm={4}>
            <SbNumberBox
                name={name}
                value={value}
                defaultValue={defaultValue}
                placeholder={placeholder}
                minValue={minValue && minValue}
                maxValue={maxValue && maxValue}
                onInputChange={onChangeEventHandler}
                required={required}
                disabledStyling={disabledStyling}
            />
        </Col>
        {children && <Col sm={4}>{children}</Col>}
    </Form.Group>
);

export default SbFormNumberBoxGroup;
