const SearchParameters = {
    nodeId: "nodeId",
};

export const buildFilterChecklistAssignmentNodeTypeValuesSearchParameters = (
    nodeId: number | null
): URLSearchParams => {
    const searchParams = new URLSearchParams();
    if (nodeId) {
        searchParams.set(SearchParameters.nodeId, nodeId.toString());
    }

    return searchParams;
};
