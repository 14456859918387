import { Response } from "../../../domain/responses/common/response-response";
import { TimeZoneDetailsResponse } from "../../../domain/responses/timezone/time-zone-details-response";
import client from "../../http-client";

const route = "timezones";

export const getTimeZones = async (
    prefixUrl: string
): Promise<Response<TimeZoneDetailsResponse[]>> =>
    await client
        .get(route, {
            prefixUrl: prefixUrl,
        })
        .json();

export const getTimeZonesFromNodeTypeValues = async (
    prefixUrl: string
): Promise<Response<TimeZoneDetailsResponse[]>> =>
    await client
        .get(`${route}/node-type-values-time-zones`, {
            prefixUrl: prefixUrl,
        })
        .json();
