import {
    formatApiUtcDateInTimeZone,
    toDateAndTimeFormat,
} from "../../../core/utilities/date-helper";
import InstanceStatus from "../../enums/questions/instance-status";
import NestedQuestionCaptureResponse from "../../responses/answer-capture/nested-question-capture-response";
import QuestionCaptureResponse from "../../responses/answer-capture/question-capture-response";
import TimeZoneDto from "../timezone/time-zone-dto";
import BaseQuestionCaptureDto from "./base-question-capture-dto";
import NestedQuestionCaptureDto from "./nested-question-capture-dto";

export default class QuestionCaptureDto extends BaseQuestionCaptureDto {
    questionSetInstanceAnswerId: number;
    questionSetQuestionId: number | null;
    formattedOpenDateTimeUtc: string;
    formattedDueDateTimeUtc: string;
    formattedOpenDateTimeChecklistTimeZone: string;
    formattedDueDateTimeChecklistTimeZone: string;
    status: InstanceStatus;
    isEnabled: boolean;
    questionSetName: string;
    processName: string;
    isProcessQuestion: boolean;
    questionSetInstanceId: number;
    checklistTimeZoneDto: TimeZoneDto;
    isTraining: boolean;

    public constructor(response: QuestionCaptureResponse) {
        const nestedQuestionCaptureDtos = QuestionCaptureDto.toNestedQuestionCaptureDtos(
            response.nestedQuestionCaptureResponses
        );

        super(response, nestedQuestionCaptureDtos);

        const {
            questionSetInstanceAnswerId,
            questionSetQuestionId,
            openDateTimeUtc,
            dueDateTimeUtc,
            status,
            isEnabled,
            questionSetName,
            processName,
            isProcessQuestion,
            questionSetInstanceId,
            checklistTimeZoneResponse,
            isTraining,
        } = response;

        this.questionSetInstanceAnswerId = questionSetInstanceAnswerId;
        this.questionSetQuestionId = questionSetQuestionId;
        this.formattedOpenDateTimeUtc = toDateAndTimeFormat(openDateTimeUtc.toString());
        this.formattedDueDateTimeUtc = toDateAndTimeFormat(dueDateTimeUtc.toString());
        this.status = status;
        this.isEnabled = isEnabled;
        this.questionSetName = questionSetName;
        this.processName = processName;
        this.isProcessQuestion = isProcessQuestion;
        this.questionSetInstanceId = questionSetInstanceId;
        this.checklistTimeZoneDto = new TimeZoneDto(checklistTimeZoneResponse);
        this.formattedOpenDateTimeChecklistTimeZone = formatApiUtcDateInTimeZone(
            openDateTimeUtc,
            undefined,
            this.checklistTimeZoneDto.ianaTimeZoneId
        );
        this.formattedDueDateTimeChecklistTimeZone = formatApiUtcDateInTimeZone(
            dueDateTimeUtc,
            undefined,
            this.checklistTimeZoneDto.ianaTimeZoneId
        );
        this.isTraining = isTraining;
    }

    public static toQuestionCaptureDtos = (
        responses: QuestionCaptureResponse[]
    ): QuestionCaptureDto[] => responses.map((x) => new QuestionCaptureDto(x));

    private static toNestedQuestionCaptureDtos = (
        nestedQuestionCaptureResponses: NestedQuestionCaptureResponse[] | null
    ): NestedQuestionCaptureDto[] | null => {
        if (!nestedQuestionCaptureResponses || !nestedQuestionCaptureResponses.length) {
            return null;
        }

        const nestedQuestionCaptureDtos: NestedQuestionCaptureDto[] = [];

        nestedQuestionCaptureResponses.forEach((nestedQuestionCaptureResponse) => {
            const nestedChildQuestionCaptureDtos = this.toNestedQuestionCaptureDtos(
                nestedQuestionCaptureResponse.nestedQuestionCaptureResponses
            );

            const nestedQuestionCaptureDto = new NestedQuestionCaptureDto(
                nestedQuestionCaptureResponse,
                nestedChildQuestionCaptureDtos
            );

            nestedQuestionCaptureDtos.push(nestedQuestionCaptureDto);
        });

        return nestedQuestionCaptureDtos;
    };
}
