import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionItems } from "../../../core/constants/translation-namespace";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { filterActionItemsAssignedToNodeColumnNames } from "../../../core/utilities/dataTableColumns";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import { ActionItemEvidenceDto } from "../../../domain/dtos/actions/action-item-evidence-dto";
import { createFilterActionItemsDto } from "../../../domain/dtos/actions/filter-action-items-dto";
import {
    BasePaginationDto,
    defaultBasePaginationDto,
} from "../../../domain/dtos/common/base-pagination-dto";
import { ActionItemRoleGroup, hasRoleTypeInGroup } from "../../../domain/enums/Roles";
import { useFilterActionItems } from "../../../domain/viewmodels/hierarchy/assigned-action-items-to-node-viewmodel";
import { DataTable } from "../../organisms/DataTable";
import { useMenu } from "../../../core/store/menu-context";

const AssignedActionItemsToNodeContainer: React.FC<{ nodeId: number | null }> = ({ nodeId }) => {
    const [paginationDto, setPaginationDto] = useState<BasePaginationDto>(defaultBasePaginationDto);

    const auth = useAuth();
    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });

    const filterDto = createFilterActionItemsDto(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        nodeId
    );

    const filterActionItems = useFilterActionItems(filterDto, paginationDto);

    useLoader(isQueryLoading(filterActionItems), AssignedActionItemsToNodeContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.VisualTree);
    }, []);

    const navigateToViewActionItem = (dto: ActionItemEvidenceDto): void => {
        const params = [
            createNavigateSearchParameter(
                "actionItemInstanceId",
                dto.actionItemInstanceId.toString()
            ),
        ];

        navigateSearch(`${getPath(DrawerTitles.ActionItems)}/${dto.actionItemId}`, params);
    };

    return (
        <>
            {isQuerySuccessful(filterActionItems) && nodeId !== null && (
                <DataTable
                    keyPrefix={ActionItems}
                    columns={filterActionItemsAssignedToNodeColumnNames}
                    rows={filterActionItems.data!.rows}
                    noResultsMessage={t("NoActionItemsFound") as string}
                    totalItems={filterActionItems.data!.recordCount}
                    viewItem={
                        hasRoleTypeInGroup(auth.userRoles, ActionItemRoleGroup.ReadRoles)
                            ? navigateToViewActionItem
                            : undefined
                    }
                    paginationDto={paginationDto}
                    setPaginationDto={setPaginationDto}
                />
            )}
        </>
    );
};

export default AssignedActionItemsToNodeContainer;
