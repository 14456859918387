import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { softDeleteReferentialLink } from "../../../data/services/referential-link-types/referential-link-service";
import { Response } from "../../responses/common/response-response";

export const useSoftDeleteReferentialLink = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    number
> => {
    const url = useUrl();

    return useMutation((referentialLinkId: number) =>
        softDeleteReferentialLink(url.baseUrl, referentialLinkId)
    );
};
