import { TFunction } from "i18next";
import { ActionItems, Common, Reports } from "../../../core/constants/translation-namespace";
import { TableRow } from "../../../core/utilities/customTypes";
import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import { wrapText } from "../../../core/utilities/string-helper";
import AnswerFlagMode from "../../enums/answer-types/answer-flag-mode";
import ChecklistReportDetailLevel from "../../enums/reports/checklist-report-detail-level";
import ChecklistReportDetailsResponse from "../../responses/reports/checklist-report-details-response";
import QuestionAnswersReportResponse from "../../responses/reports/question-answers-report-response";
import { FileDto } from "../file-storage/file-dto";

export default class ChecklistReportDetailsDto {
    level: ChecklistReportDetailLevel;
    questionSetInstanceId: number;
    checklistDetailsMap: Map<string, string>;
    referentialLinkDetailsMap: Map<string, string>;
    questionAnswersReportListTableDto: TableRow<number>[];

    public constructor(response: ChecklistReportDetailsResponse, translatorFn: TFunction) {
        const {
            level,
            questionSetInstanceId,
            checklist,
            path,
            completedDateUtc,
            referentialLinksReportResponses,
            questionAnswersReportResponses,
        } = response;

        this.level = level;
        this.questionSetInstanceId = questionSetInstanceId;

        this.checklistDetailsMap = new Map<string, string>([
            [translatorFn("Checklist", { keyPrefix: Reports }), checklist],
            [translatorFn("Path", { keyPrefix: Common }), path],
            [
                translatorFn("CompletedDate", { keyPrefix: Reports }),
                toDateAndTimeFormat(completedDateUtc.toString()),
            ],
        ]);

        this.referentialLinkDetailsMap = new Map<string, string>();
        referentialLinksReportResponses.map((x) =>
            this.referentialLinkDetailsMap.set(x.referentialLinkType, x.referentialLinkValue)
        );

        this.questionAnswersReportListTableDto = transformToDataTableRows(
            questionAnswersReportResponses,
            translatorFn
        );
    }
}

const transformToDataTableRows = (
    responses: QuestionAnswersReportResponse[],
    translatorFn: TFunction
): TableRow<number>[] => {
    const wrapIndex: number = 25;
    const rows = responses.map((x, index) => {
        return {
            metadata: index,
            nestedRows:
                x.nestedQuestionAnswersReportResponses &&
                x.nestedQuestionAnswersReportResponses.length > 0
                    ? transformToDataTableRows(x.nestedQuestionAnswersReportResponses, translatorFn)
                    : undefined,
            attachedFiles:
                x.fileDetailsResponses && x.fileDetailsResponses.length > 0
                    ? FileDto.constructFromBaseFileDetailsResponses(x.fileDetailsResponses)
                    : undefined,
            columns: [
                {
                    value: x.question,
                    type: DataTableColumnTypes.Text,
                },
                {
                    value: x.answer,
                    type: DataTableColumnTypes.Text,
                },
                {
                    value: x.desirability ? AnswerFlagMode[x.desirability] : "",
                    type: DataTableColumnTypes.Text,
                },
                {
                    value: x.comment,
                    type: DataTableColumnTypes.Text,
                },
                {
                    value: toDateAndTimeFormat(x.dueDateUtc.toString()),
                    type: DataTableColumnTypes.Text,
                },
                {
                    value: toDateAndTimeFormat(x.completedDateUtc.toString()),
                    type: DataTableColumnTypes.Text,
                },
                {
                    value:
                        x.completedBy.length > wrapIndex
                            ? wrapText(x.completedBy, wrapIndex)
                            : x.completedBy,
                    type: DataTableColumnTypes.Text,
                },
                {
                    value:
                        x.fileDetailsResponses && x.fileDetailsResponses.length > 0
                            ? "Show/Hide"
                            : translatorFn("NoEvidenceAttached", { keyPrefix: ActionItems }),
                    type: DataTableColumnTypes.DisplayAttachedFilesButton,
                    metadata: "AttachedFiles",
                },
                {
                    value:
                        x.nestedQuestionAnswersReportResponses &&
                        x.nestedQuestionAnswersReportResponses.length > 0
                            ? "Show/Hide"
                            : "None",
                    type: DataTableColumnTypes.DisplayNestedTableButton,
                },
            ],
        };
    });

    return rows;
};
