import CreateQuestionSetActionDto from "../../dtos/checklists/create-question-set-action-dto";

export default class CreateQuestionSetActionRequest {
    questionSetId: number;
    calendarEntry: boolean;
    review: boolean;
    startDate: Date;
    endDate: Date;

    public constructor(dto: CreateQuestionSetActionDto) {
        const { questionSetId, calendarEntry, review, startDate, endDate } = dto;

        this.questionSetId = questionSetId;
        this.calendarEntry = calendarEntry;
        this.review = review;
        this.startDate = startDate;
        this.endDate = endDate;
    }

    public static constructFromCreateQuestionSetActionDtos = (
        dtos: CreateQuestionSetActionDto[]
    ): CreateQuestionSetActionRequest[] => dtos.map((x) => new CreateQuestionSetActionRequest(x));
}
