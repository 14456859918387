import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Common, NonWorkingDays } from "../../../core/constants/translation-namespace";
import {
    ContentContainer,
    EndAlignedDiv,
    SectionContainer,
} from "../../../core/theme/global-styles";
import { ResetButton, SearchButton } from "../../atoms/SbButton";
import { FilterInput } from "../../molecules/FilterInput";

export const NonWorkingDaySetsFilter: React.FC<{
    nonWorkingDaySetName: string | null;
    changeNonWorkingDaySetName: (nonWorkingDaySetName: string) => void;
    nonWorkingDayName: string | null;
    changeNonWorkingDayName: (nonWorkingDayName: string) => void;
    date: string | null;
    changeDate: (date: string) => void;
    search: () => void;
    resetFilter: () => void;
}> = ({
    nonWorkingDaySetName,
    changeNonWorkingDaySetName,
    nonWorkingDayName,
    changeNonWorkingDayName,
    date,
    changeDate,
    search,
    resetFilter,
}) => {
    const { t } = useTranslation("translation", { keyPrefix: NonWorkingDays });
    return (
        <ContentContainer>
            <SectionContainer>
                <Row>
                    <Col>
                        <FilterInput
                            title={t("Name", { keyPrefix: Common })}
                            name={"Name"}
                            type={"text"}
                            onChangeEventHandler={changeNonWorkingDaySetName}
                            value={nonWorkingDaySetName ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("NonWorkingDay")}
                            name={"nonWorkingDayName"}
                            type={"text"}
                            onChangeEventHandler={changeNonWorkingDayName}
                            value={nonWorkingDayName ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("Date", { keyPrefix: Common })}
                            name={"date"}
                            type={"date"}
                            onChangeEventHandler={changeDate}
                            value={date ?? ""}
                        />
                    </Col>
                </Row>
            </SectionContainer>
            <EndAlignedDiv>
                <SearchButton onClick={search} />
                <ResetButton onClick={resetFilter} />
            </EndAlignedDiv>
        </ContentContainer>
    );
};
