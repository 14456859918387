import { ChecklistAssignmentResponse } from "../../responses/checklists/checklist-assignment-response";

export interface AssociatedChecklistNodeDto {
    nodeId: number;
    checklistAssignmentId: number;
}

export const createAssociatedChecklistNodeDto = (
    response: ChecklistAssignmentResponse
): AssociatedChecklistNodeDto => ({
    nodeId: response.nodeId,
    checklistAssignmentId: response.checklistAssignmentId,
});
