import { HTTPError } from "ky";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SingleValue } from "react-select";
import { CreateQuestionEvent } from "../../../core/constants/application-insights-events";
import {
    ChecklistAnswers,
    ChecklistQuestions,
    Common,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import {
    AccordionTitles,
    AggregationTypes,
    DrawerTitles,
    NavbarTitles,
} from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    isMutationLoading,
    isQueryLoading,
} from "../../../core/utilities/responseStateHelper";
import { AggregationTypeDto } from "../../../domain/dtos/aggregation-type/aggregation-type-dto";
import { AnswerThresholdOperatorsDto } from "../../../domain/dtos/answer-threshold-operators/answer-threshold-operators-dto";
import AnswerTypeDto from "../../../domain/dtos/answer-types/answer-type-dto";
import BaseAnswerTypeDto from "../../../domain/dtos/answer-types/base-answer-type-dto";
import {
    AnswerTypeSearchDto,
    createAnswerTypeSearchDto,
    defaultAnswerTypeSearchDto,
    defaultSearchDto,
    SearchDto,
} from "../../../domain/dtos/common/search-dto";
import PredefinedAnswerDto from "../../../domain/dtos/predefined-answers/predefined-answer-dto";
import { QuestionCategoryDto } from "../../../domain/dtos/question-categories/question-category-dto";
import { QuestionImportanceDto } from "../../../domain/dtos/question-importance/question-importance-dto";
import { QuestionTypeDto } from "../../../domain/dtos/question-type/question-type-dto";
import AnswerFlagMode from "../../../domain/enums/answer-types/answer-flag-mode";
import { ARMonitoringDefaults } from "../../../domain/enums/questions/ar-monitoring-defaults";
import QuestionImportance from "../../../domain/enums/questions/question-importance";
import { QuestionTypes } from "../../../domain/enums/questions/question-types";
import { useCreateQuestion } from "../../../domain/viewmodels/questions/create-question-viewmodel";
import {
    useARMonitoringLookupAnswerTypes,
    useGetQuestionDropdownValues,
    useLookupAnswerTypes,
    useLookupQuestionCategories,
} from "../../../domain/viewmodels/questions/view-question-dropdown-values-viewmodel";
import { SbAlert } from "../../atoms/SbAlert";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import { translateText } from "../../helpers/translate";
import SbFormCheckFieldGroup from "../../molecules/input/SbFormCheckFieldGroup";
import SbFormNumberBoxGroup from "../../molecules/input/SbFormNumberBoxGroup";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import SbFormTextAreaGroup from "../../molecules/input/SbFormTextAreaGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const CreateQuestionContainer: React.FC = () => {
    const [questionTypeId, setQuestionTypeId] = useState<number>();
    const [questionCategoryId, setQuestionCategoryId] = useState<number>();
    const [questionImportanceId, setQuestionImportanceId] = useState<number>();
    const [answerTypeId, setAnswerTypeId] = useState<number>();
    const [aggregationTypeId, setAggregationTypeId] = useState<number>();
    const [redAnswerThresholdOperatorId, setRedAnswerThresholdOperatorId] = useState<number>();
    const [amberAnswerThresholdOperatorId, setAmberAnswerThresholdOperatorId] = useState<number>();
    const [desirableAnswer, setDesirableAnswer] = useState<string | null>();
    const [undesirableAnswer, setUndesirableAnswer] = useState<string | null>();
    const [neutralAnswer, setNeutralAnswer] = useState<string | null>();
    const [answerTypeName, setAnswerTypeName] = useState("");
    const [aggregate, setAggregate] = useState(false);
    const [questionWeightings, setQuestionWeightings] = useState(false);
    const [arMonitoring, setARMonitoring] = useState(false);
    const [searchDto, setSearchDto] = useState<SearchDto>(defaultSearchDto);
    const [questionWeightingPredefinedAnswers, setQuestionWeightingPredefinedAnswers] = useState<
        PredefinedAnswerDto[]
    >([]);

    const [answerTypeSearchDto, setAnswerTypeSearchDto] = useState<AnswerTypeSearchDto>(
        defaultAnswerTypeSearchDto
    );

    const menu = useMenu();
    const navigate = useNavigate();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation");

    const createQuestion = useCreateQuestion();
    const getQuestionDropdownValues = useGetQuestionDropdownValues();

    const questionTypes = getQuestionDropdownValues[0].data!;
    const questionImportance = getQuestionDropdownValues[1].data!;
    const aggregationTypes = getQuestionDropdownValues[2].data!;
    const answerThresholdOperators = getQuestionDropdownValues[3].data!;

    const lookupQuestionCategories = useLookupQuestionCategories(searchDto);
    const lookupQuestionCategoriesData = lookupQuestionCategories.data!;
    const lookupAnswerTypes = useLookupAnswerTypes(answerTypeSearchDto);
    const lookupAnswerTypesData = lookupAnswerTypes.data!;

    const arMonitoringAnswerTypes = useARMonitoringLookupAnswerTypes(
        createAnswerTypeSearchDto(ARMonitoringDefaults.Monitoring, QuestionTypes.Control, null)
    );
    const arMonitoringAnswerTypesData = arMonitoringAnswerTypes.data!;

    useLoader(isMutationLoading(createQuestion), CreateQuestionContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Questions);
    }, []);

    useEffect(() => {
        if (arMonitoring) {
            const arMonitoringQuestionType = questionTypes.filter(
                (x) => x.questionTypeId === QuestionTypes.Control
            )[0];

            onQuestionTypeSelected(arMonitoringQuestionType);

            const arMonitoringQuestionImportance = questionImportance.filter(
                (x) => x.questionImportanceId === QuestionImportance.High
            )[0];

            onQuestionImportanceSelected(arMonitoringQuestionImportance);

            const arMonitoringAnswerType = arMonitoringAnswerTypesData.filter(
                (x) => x.name.trim() === ARMonitoringDefaults.Monitoring
            )[0];

            onAnswerTypeSelected(arMonitoringAnswerType);
        } else {
            onQuestionTypeSelected(null);
            onQuestionImportanceSelected(null);
            onAnswerTypeSelected(null);
        }
    }, [arMonitoring]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        createQuestion.mutate(
            {
                questionTypeId: questionTypeId!,
                answerTypeId: answerTypeId!,
                answerTypeName: answerTypeName,
                questionCategoryId: questionCategoryId!,
                questionImportanceId: questionImportanceId!,
                aggregationTypeId: aggregationTypeId || AggregationTypes.Default,
                redAnswerThresholdOperatorId: redAnswerThresholdOperatorId || null,
                amberAnswerThresholdOperatorId: amberAnswerThresholdOperatorId || null,
                formData: new FormData(event.currentTarget),
            },
            {
                onSuccess: async (response) => {
                    trackAppInsightsEvent(auth.email, window.location.href, CreateQuestionEvent);
                    navigate(`${getPath(AccordionTitles.Questions)}/${response.data!}`);
                },
                onError: (error: HTTPError) => {
                    trackAppInsightsException(
                        auth.email,
                        window.location.href,
                        CreateQuestionEvent,
                        error
                    );
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    };

    const onQuestionTypeSelected = (option: SingleValue<QuestionTypeDto>): void => {
        setQuestionTypeId(option ? option.questionTypeId : undefined);
        setAnswerTypeSearchDto(
            createAnswerTypeSearchDto(null, option ? option.questionTypeId : null, null)
        );
    };

    const onQuestionCategorySelected = (option: SingleValue<QuestionCategoryDto>): void =>
        setQuestionCategoryId(option ? option.questionCategoryId : undefined);

    const onQuestionImportanceSelected = (option: SingleValue<QuestionImportanceDto>): void =>
        setQuestionImportanceId(option ? option.questionImportanceId : undefined);

    const onAnswerTypeSelected = (option: SingleValue<AnswerTypeDto>): void => {
        setAnswerTypeId(option ? option.answerTypeId : undefined);
        setAnswerTypeName(option ? option.name : "");
        setQuestionWeightingPredefinedAnswers(option ? option.predefinedAnswerDtos : []);

        const desirableAnswer =
            option && answerTextToDisplayForFlagMode(option, AnswerFlagMode.Desirable);
        const undesirableAnswer =
            option && answerTextToDisplayForFlagMode(option, AnswerFlagMode.Undesirable);
        const neutralAnswer =
            option && answerTextToDisplayForFlagMode(option, AnswerFlagMode.Neutral);

        setDesirableAnswer(
            desirableAnswer && desirableAnswer.length > 0 ? desirableAnswer : undefined
        );
        setUndesirableAnswer(
            undesirableAnswer && undesirableAnswer.length > 0 ? undesirableAnswer : undefined
        );
        setNeutralAnswer(neutralAnswer && neutralAnswer.length > 0 ? neutralAnswer : undefined);
    };

    const onAggregationTypeSelected = (option: SingleValue<AggregationTypeDto>): void =>
        setAggregationTypeId(option ? option.aggregationTypeId : undefined);

    const onRedAnswerThresholdOperatorSelected = (
        option: SingleValue<AnswerThresholdOperatorsDto>
    ): void =>
        setRedAnswerThresholdOperatorId(option ? option.answerThresholdOperatorId : undefined);

    const onAmberAnswerThresholdOperatorSelected = (
        option: SingleValue<AnswerThresholdOperatorsDto>
    ): void =>
        setAmberAnswerThresholdOperatorId(option ? option.answerThresholdOperatorId : undefined);

    const handleAggregateChange = (_: string, checked: boolean): void => setAggregate(checked);

    const handleQuestionWeightingsChange = (_: string, checked: boolean): void => {
        setQuestionWeightings(checked);
    };

    const answerTextToDisplayForFlagMode = (
        option: SingleValue<AnswerTypeDto>,
        flagMode: AnswerFlagMode
    ): string => {
        let answerToDisplay = "";

        const desirablePredefinedAnswers = option!.predefinedAnswerDtos.filter(
            (x) => x.answerFlagMode === flagMode
        );

        desirablePredefinedAnswers.forEach(
            (x) =>
                (answerToDisplay = `${answerToDisplay} ${answerToDisplay.length > 0 ? "|" : ""} ${
                    x.predefinedAnswerTextDto.textValue
                }`)
        );

        return answerToDisplay.trim();
    };

    const handleARMonitoringChange = (_: string, checked: boolean): void => {
        setARMonitoring(checked);
        setQuestionWeightings(false);
    };

    const arMonitoringCheckValues = [
        {
            name: "arMonitoring",
            label: "",
            onChangeHandler: handleARMonitoringChange,
        },
    ];

    const renderAnswerType = (): JSX.Element | undefined => {
        if (questionTypeId) {
            return (
                <SbFormSelectFieldGroup
                    name={"answerType"}
                    label={t("AnswerType", { keyPrefix: ChecklistAnswers })}
                    placeholderText={t("LookupSearchPlaceholderText", { keyPrefix: Common })!}
                    searchable
                    clearable
                    required
                    items={arMonitoring ? arMonitoringAnswerTypesData : lookupAnswerTypesData}
                    itemDisplayText={(option: BaseAnswerTypeDto) => option.name}
                    onChange={onAnswerTypeSelected}
                    loading={areQueriesLoading([lookupAnswerTypes, arMonitoringAnswerTypes])}
                    onSearchTextChanged={(searchText: string) => {
                        setAnswerTypeSearchDto({
                            ...answerTypeSearchDto,
                            searchText: searchText,
                        });
                    }}
                    defaultSelectedItem={
                        questionTypeId === QuestionTypes.Control && arMonitoring
                            ? arMonitoringAnswerTypesData.filter(
                                  (x) => x.name.trim() === ARMonitoringDefaults.Monitoring
                              )[0]
                            : null
                    }
                    disabled={arMonitoring}
                />
            );
        }
    };

    const renderControlContent = (): JSX.Element => (
        <>
            {renderAnswerType()}
            {desirableAnswer && (
                <SbFormTextFieldGroup
                    name="desirablePredefinedAnswer"
                    label={t("DesirableAnswers", { keyPrefix: ChecklistAnswers })}
                    type="text"
                    disabled
                    value={desirableAnswer}
                />
            )}
            {undesirableAnswer && (
                <SbFormTextFieldGroup
                    name="undesirablePredefinedAnswer"
                    label={t("UndesirableAnswers", { keyPrefix: ChecklistAnswers })}
                    type="text"
                    disabled
                    value={undesirableAnswer}
                />
            )}
            {neutralAnswer && (
                <SbFormTextFieldGroup
                    name="neutralPredefinedAnswer"
                    label={t("NeutralAnswers", { keyPrefix: ChecklistAnswers })}
                    type="text"
                    disabled
                    value={neutralAnswer}
                />
            )}
            <SbFormCheckFieldGroup
                fieldLabel={t("EnableQuestionWeightings", { keyPrefix: ChecklistQuestions })}
                type="checkbox"
                values={[
                    {
                        name: "QuestionWeighting",
                        onChangeHandler: handleQuestionWeightingsChange,
                    },
                ]}
            />
        </>
    );

    const renderPredefinedAnswerValues = (): JSX.Element => {
        return (
            <>
                {questionWeightingPredefinedAnswers.map((x) => {
                    return (
                        <SbFormNumberBoxGroup
                            name={`predefinedAnswerId${x.predefinedAnswerId.toString()}`}
                            label={x.predefinedAnswerTextDto.textValue}
                            required={false}
                        />
                    );
                })}
            </>
        );
    };

    const renderQuestionWeightingsContent = (): JSX.Element => (
        <TextTitledPanel title={t("QuestionWeightings", { keyPrefix: ChecklistQuestions })}>
            <SbAlert
                variant={"primary"}
                text={t("QuestionWeightingsHelperText", { keyPrefix: ChecklistQuestions })}
            />
            {answerTypeName && renderPredefinedAnswerValues()}
        </TextTitledPanel>
    );

    const renderNumberValueInput = (threshold: string): JSX.Element => (
        <SbFormTextFieldGroup
            name={threshold + "Number"}
            label={t("TheValue", { keyPrefix: ChecklistAnswers })}
            type="number"
        />
    );

    const renderDecimalValueInput = (threshold: string): JSX.Element => (
        <SbFormTextFieldGroup
            name={threshold + "Decimal"}
            label={t("TheValue", { keyPrefix: ChecklistAnswers })}
            type="text"
            children={"00.00"}
        />
    );

    const renderIndicatorContent = (): JSX.Element => (
        <>
            <SbFormCheckFieldGroup
                fieldLabel={t("IsAggregate", { keyPrefix: ChecklistQuestions })}
                type="checkbox"
                values={[
                    {
                        name: "Aggregate",
                        onChangeHandler: handleAggregateChange,
                    },
                ]}
            />

            {renderAnswerType()}

            {aggregate && answerTypeName === "Number" && (
                <SbFormSelectFieldGroup
                    name={"aggregationType"}
                    label={t("AggregationType", { keyPrefix: ChecklistQuestions })}
                    placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                    searchable
                    clearable
                    items={aggregationTypes}
                    itemDisplayText={(option: AggregationTypeDto) => option.name} //TODO: Add translations for dynamic data
                    onChange={onAggregationTypeSelected}
                />
            )}

            <SbFormSelectFieldGroup
                name={"redAnswerThresholdOperators"}
                label={t("RedThreshold", { keyPrefix: ChecklistQuestions })}
                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                searchable
                clearable
                items={answerThresholdOperators}
                itemDisplayText={(option: AnswerThresholdOperatorsDto) =>
                    translateText(t, option.name, ChecklistAnswers)
                }
                onChange={onRedAnswerThresholdOperatorSelected}
                variant={"danger"}
                children={
                    answerTypeName === "Number"
                        ? renderNumberValueInput("red")
                        : renderDecimalValueInput("red")
                }
            />

            <SbFormSelectFieldGroup
                name={"amberAnswerThresholdOperators"}
                label={t("AmberThreshold", { keyPrefix: ChecklistQuestions })}
                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                searchable
                clearable
                items={answerThresholdOperators}
                itemDisplayText={(option: AnswerThresholdOperatorsDto) =>
                    translateText(t, option.name, ChecklistAnswers)
                }
                onChange={onAmberAnswerThresholdOperatorSelected}
                variant={"warning"}
                children={
                    answerTypeName === "Number"
                        ? renderNumberValueInput("amber")
                        : renderDecimalValueInput("amber")
                }
            />
        </>
    );

    return (
        <>
            <PageHeading>
                {t("QuestionsCreateTitle", { keyPrefix: ChecklistQuestions })}
            </PageHeading>
            <PageSubHeading>
                {t("HeaderHelpTextQuestionCreate", { keyPrefix: ChecklistQuestions })}
            </PageSubHeading>
            <SectionVerticalSpace />
            <TextTitledPanel title={t("CreateQuestion", { keyPrefix: ChecklistQuestions })}>
                <Form onSubmit={handleSubmit}>
                    <SbFormCheckFieldGroup
                        fieldLabel={t("ARMonitoring", { keyPrefix: Common })}
                        type={"checkbox"}
                        values={arMonitoringCheckValues}
                    />

                    <SbFormSelectFieldGroup
                        name={"questionType"}
                        label={t("QuestionType", { keyPrefix: ChecklistQuestions })}
                        placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                        searchable
                        clearable
                        required
                        items={questionTypes}
                        itemDisplayText={(option: QuestionTypeDto) =>
                            translateText(t, option.name, ChecklistQuestions)
                        }
                        onChange={onQuestionTypeSelected}
                        defaultSelectedItem={
                            arMonitoring
                                ? questionTypes.filter(
                                      (x) => x.questionTypeId === QuestionTypes.Control
                                  )[0]
                                : null
                        }
                        disabled={arMonitoring}
                    />

                    <SbFormSelectFieldGroup
                        name={"questionCategory"}
                        label={t("QuestionCategory", { keyPrefix: ChecklistQuestions })}
                        placeholderText={t("LookupSearchPlaceholderText", { keyPrefix: Common })!}
                        searchable
                        clearable
                        required
                        items={lookupQuestionCategoriesData}
                        itemDisplayText={(option: QuestionCategoryDto) => option.name}
                        onChange={onQuestionCategorySelected}
                        loading={isQueryLoading(lookupQuestionCategories)}
                        onSearchTextChanged={(searchText: string) =>
                            setSearchDto({ searchText: searchText, maxSearchResults: null })
                        }
                    />

                    <SbFormSelectFieldGroup
                        name={"questionImportance"}
                        label={t("QuestionImportance", { keyPrefix: ChecklistQuestions })}
                        placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                        searchable
                        clearable
                        required
                        items={questionImportance}
                        itemDisplayText={(option: QuestionImportanceDto) =>
                            t(QuestionImportance[option.questionImportanceId], {
                                keyPrefix: Common,
                            })
                        }
                        onChange={onQuestionImportanceSelected}
                        defaultSelectedItem={
                            arMonitoring
                                ? questionImportance.filter(
                                      (x) => x.questionImportanceId === QuestionImportance.High
                                  )[0]
                                : null
                        }
                    />

                    <SbFormTextAreaGroup
                        name="questionText"
                        label={t("QuestionText", { keyPrefix: ChecklistQuestions })}
                        required
                        rows={4}
                        maxLength={4000}
                    />

                    {questionTypeId === QuestionTypes.Control && renderControlContent()}

                    {questionTypeId === QuestionTypes.Indicator && renderIndicatorContent()}

                    {questionWeightings && renderQuestionWeightingsContent()}

                    <LargeVerticalSpace />

                    <EndAlignedDiv>
                        <SaveButton type="submit" />
                        <CancelButton onClick={() => navigate(-1)} />
                    </EndAlignedDiv>
                </Form>
            </TextTitledPanel>
        </>
    );
};

export default CreateQuestionContainer;
