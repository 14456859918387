import { Accordion } from "react-bootstrap";
import styled, { css } from "styled-components";

const StyledBoldedSpan = styled.span`
    font-weight: bold;
`;

export const StyledAccordionHeader = styled(Accordion.Header)<StyledAccordionHeaderProps>`
    .accordion-button {
        background-color: ${(props) =>
            props.$secondaryVariant ? props.theme.palette.blue : "transparent"};
        color: ${(props) =>
            props.$secondaryVariant ? props.theme.palette.secondary : props.theme.palette.primary};
    }

    .accordion-button:focus {
        background-color: ${(props) =>
            props.$secondaryVariant ? props.theme.palette.blue : "transparent"};
        color: ${(props) =>
            props.$secondaryVariant ? props.theme.palette.secondary : props.theme.palette.primary};
    }

    .accordion-button:hover {
        background-color: ${(props) =>
            props.$secondaryVariant ? props.theme.palette.blue : "transparent"};
        color: ${(props) =>
            props.$secondaryVariant ? props.theme.palette.secondary : props.theme.palette.primary};
    }

    .accordion-button:not(.collapsed) {
        background-color: ${(props) =>
            props.$secondaryVariant ? props.theme.palette.blue : "transparent"};
        color: ${(props) =>
            props.$secondaryVariant ? props.theme.palette.secondary : props.theme.palette.primary};
    }
`;

interface StyledAccordionBodyProps {
    $maxHeight: string | undefined;
}

interface StyledAccordionHeaderProps {
    $secondaryVariant: boolean | undefined;
}

const StyledAccordionBody = styled(Accordion.Body)<StyledAccordionBodyProps>`
    ${({ $maxHeight }) =>
        $maxHeight &&
        css`
            max-height: ${$maxHeight};
            overflow: auto;
        `}
`;

export const SbAccordion: React.FC<{
    title: string;
    children: React.ReactNode;
    isCollapsedOnLoad?: boolean;
    maxHeight?: string;
    boldTitle?: boolean;
    secondaryVariant?: boolean;
}> = ({ title, children, isCollapsedOnLoad, maxHeight, boldTitle, secondaryVariant }) => {
    return (
        <Accordion defaultActiveKey={[isCollapsedOnLoad && isCollapsedOnLoad ? "" : "0"]}>
            <Accordion.Item eventKey="0">
                <StyledAccordionHeader $secondaryVariant={secondaryVariant}>
                    {boldTitle ? <StyledBoldedSpan>{title}</StyledBoldedSpan> : title}
                </StyledAccordionHeader>
                <StyledAccordionBody $maxHeight={maxHeight}>{children}</StyledAccordionBody>
            </Accordion.Item>
        </Accordion>
    );
};
