import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useAuth } from "../../../core/store/auth-context";
import { useUrl } from "../../../core/store/url-context";
import { filterTenants } from "../../../data/services/tenants/tenant-service";
import { TenantDto, toTenantDtos } from "../../dtos/tenants/tenant-dto";
import { createPaginationRequest } from "../../requests/common/pagination-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { TenantResponse } from "../../responses/tenants/tenant-response";

export const useFilterTenants = (): UseQueryResult<TenantDto[], HTTPError> => {
    const url = useUrl();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterTenants"],
        () => filterTenants(url.originalBaseUrl, createPaginationRequest(1, 1000)),
        {
            select: transformToTenantDtos,
            enabled: auth.isAdmin && auth.userName !== "",
            onError: errorResponseToDisplayHandler,
        }
    );
};

const transformToTenantDtos = (
    response: Response<PaginationResponse<TenantResponse>>
): TenantDto[] => toTenantDtos(response.data.results);
