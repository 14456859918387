import { HierarchyAssignable } from "../../enums/hierarchy/HierarchyAssignable";

export interface CreateHierarchyTypeRequest {
    name: string;
    hierarchyAssignables: HierarchyAssignable[];
}

export const createCreateHierarchyTypeRequest = (
    name: string,
    hierarchyAssignables: HierarchyAssignable[]
): CreateHierarchyTypeRequest => ({
    name: name,
    hierarchyAssignables: hierarchyAssignables,
});
