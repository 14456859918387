import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { AccordionTitles, DataTableColumnTypes } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { filterAnswerTypes } from "../../../data/services/answer-types/answer-types-service";
import { Response } from "../../../domain/responses/common/response-response";
import { FilterAnswerTypesDto } from "../../dtos/answer-types/filter-answer-types-dto";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import AnswerCategory from "../../enums/answer-types/answer-category";
import { createFilterAnswerTypesRequestFromDto } from "../../requests/answer-types/filter-answer-types-request";
import AnswerTypeQuestionCountResponse from "../../responses/answer-types/answer-type-question-count-response";
import { PaginationResponse } from "../../responses/common/pagination-response";

export const useFilterAnswerTypes = (
    dto: FilterAnswerTypesDto
): UseQueryResult<PaginatedTableDto<number>, HTTPError> => {
    const url = useUrl();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterAnswerTypes", dto],
        () => filterAnswerTypes(url.baseUrl, createFilterAnswerTypesRequestFromDto(dto)),
        {
            select: (response: Response<PaginationResponse<AnswerTypeQuestionCountResponse>>) =>
                toDataTableRows(response.data, navigate),
            onError: errorResponseToDisplayHandler,
        }
    );
};

const toDataTableRows = (
    response: PaginationResponse<AnswerTypeQuestionCountResponse>,
    navigate: NavigateFunction
): PaginatedTableDto<number> => {
    const { pageCount, pageSize, currentPage, recordCount } = response;

    const rows = response.results!.map((answerType) => {
        return {
            metadata: answerType.answerTypeId,
            columns: [
                {
                    value: answerType.name,
                    type: DataTableColumnTypes.Link,
                    linkItemAction: (answerTypeId: number) => {
                        navigate(`${getPath(AccordionTitles.AnswerTypes)}/${answerTypeId}`);
                    },
                },
                {
                    value: AnswerCategory[answerType.answerCategory!],
                    type: DataTableColumnTypes.Text,
                },
                {
                    value: answerType.questionCount.toString(),
                    type: DataTableColumnTypes.Text,
                },
            ],
        };
    });

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
