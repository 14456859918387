import ValidateHierarchyImportResponse from "../../responses/data-import/validate-hierarchy-import-response";
import HierarchyImportDto from "./hierarchy-import-dto";
import HierarchyImportHeaderDto from "./hierarchy-import-header-dto";

export default class ProcessHierarchyImportDto {
    hierarchyTypeId: Number;
    hierarchyImportRequest: HierarchyImportDto;
    headerMetadata: HierarchyImportHeaderDto[];

    public constructor(validateHierarchyImportResponse: ValidateHierarchyImportResponse) {
        this.hierarchyTypeId = validateHierarchyImportResponse.hierarchyTypeId!;
        this.hierarchyImportRequest = validateHierarchyImportResponse.hierarchyImportResponse;
        this.headerMetadata = HierarchyImportHeaderDto.constructFromResponses(
            validateHierarchyImportResponse.hierarchyImportProcessResponse.headerMetadata
        );
    }
}
