import { HTTPError } from "ky";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaLink } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { AssociateTaxonomyNodeToChecklistEvent } from "../../../core/constants/application-insights-events";
import {
    ChecklistQuestions,
    Common,
    HierarchyTypeTreeView,
    Nodes,
} from "../../../core/constants/translation-namespace";
import { HierarchyMenuType } from "../../../core/hierarchyMenuType";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
} from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import {
    AccordionTitles,
    DrawerTitles,
    HierarchyAssignableFlag,
    NavbarTitles,
} from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    isMutationLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { createAssociateDissociateChecklistTaxonomyNodeDto } from "../../../domain/dtos/checklists/associate-dissociate-checklist-taxonomy-node-dto";
import { BaseNodeDetailsDto } from "../../../domain/dtos/hierarchy/base-node-details-dto";
import {
    useAssociateTaxonomyNode,
    useGetAssociateTaxonomyNodeDetails,
} from "../../../domain/viewmodels/checklists/associate-taxonomy-node-viewmodel";
import { useGetHierarchyTypesByHierarchyAssignableFlag } from "../../../domain/viewmodels/hierarchy/hierarchy-types-by-hierarchy-assignable-flag-viewmodel";
import { CancelButton, SbButton } from "../../atoms/SbButton";
import { RadioSelectProps } from "../../molecules/DataTableAction";
import SbTabs, { SbTabItemProps } from "../../molecules/SbTabs";
import NodeHierarchyContainer from "../hierarchy/NodeHierarchyContainer";
import TextSearchContainer from "../hierarchy/TextSearchContainer";

const AssociateChecklistTaxonomyNodeContainer: React.FC = () => {
    const [selectedTaxonomyNode, setSelectedTaxonomyNode] = useState<number>();

    const navigate = useNavigate();
    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: Nodes });

    const checklistId = Number(useParams().checklistId);

    const getHierarchyTypesByHierarchyAssignableFlag =
        useGetHierarchyTypesByHierarchyAssignableFlag(HierarchyAssignableFlag.Question);
    const getAssociateTaxonomyNodeDetails = useGetAssociateTaxonomyNodeDetails(checklistId);
    const associateTaxonomyNode = useAssociateTaxonomyNode();

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Checklists);
    }, []);

    useLoader(
        areQueriesLoading([
            getAssociateTaxonomyNodeDetails,
            getHierarchyTypesByHierarchyAssignableFlag,
        ]) || isMutationLoading(associateTaxonomyNode),
        AssociateChecklistTaxonomyNodeContainer
    );

    const selectTextSearchNode = (metadata: BaseNodeDetailsDto): void =>
        setSelectedTaxonomyNode(metadata.nodeId);

    const selectVisualSearchNode = (nodeId: number): void => setSelectedTaxonomyNode(nodeId);

    const selectedNode = (metadata: BaseNodeDetailsDto): boolean => {
        const taxonomyNode = metadata.nodeId;

        return taxonomyNode === selectedTaxonomyNode;
    };

    const radioSelect: RadioSelectProps = {
        type: "radio",
        label: `${t("SelectNode", { keyPrefix: HierarchyTypeTreeView })}`,
    };

    const handleAssociateTaxonomyNode = (): void => {
        associateTaxonomyNode.mutate(
            createAssociateDissociateChecklistTaxonomyNodeDto(checklistId, selectedTaxonomyNode!),
            {
                onSuccess: async () => {
                    trackAppInsightsEvent(
                        auth.email,
                        window.location.href,
                        AssociateTaxonomyNodeToChecklistEvent
                    );

                    queryClient.invalidateQueries(["getAssociatedTaxonomyNodes"]);

                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "messageKey",
                            "SuccessfullyAssociatedTaxonomyNodeToChecklist"
                        ),
                    ];

                    navigateSearch(`${getPath(AccordionTitles.Checklists)}/${checklistId}`, params);
                },
                onError: (error: HTTPError) => {
                    trackAppInsightsException(
                        auth.email,
                        window.location.href,
                        AssociateTaxonomyNodeToChecklistEvent,
                        error
                    );
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    };

    const buildSbTabItemProps = (): SbTabItemProps[] => {
        const textSearchContainer = (
            <TextSearchContainer
                mode={"select"}
                selectNode={selectTextSearchNode}
                selectedNode={selectedNode}
                radioSelect={radioSelect}
                hierarchyTypes={getHierarchyTypesByHierarchyAssignableFlag.data!}
            />
        );

        const nodeHierarchyContainer = (
            <NodeHierarchyContainer
                menuType={
                    HierarchyMenuType.Select |
                    HierarchyMenuType.Details |
                    HierarchyMenuType.ViewUsers
                }
                selectNode={selectVisualSearchNode}
                hierarchyTypes={getHierarchyTypesByHierarchyAssignableFlag.data!}
            />
        );

        return [
            new SbTabItemProps(t("TextSearch", { keyPrefix: Common }), textSearchContainer, true),
            new SbTabItemProps(t("VisualSearch", { keyPrefix: Common }), nodeHierarchyContainer),
        ];
    };

    return (
        <>
            <PageHeading>{t("AssociateNode")}</PageHeading>
            <LargeVerticalSpace />

            {isQuerySuccessful(getAssociateTaxonomyNodeDetails) && (
                <>
                    <ContentContainer>
                        <Row>
                            <Col md="auto">
                                <DetailsLabel>
                                    {t("Checklist", { keyPrefix: ChecklistQuestions })}
                                </DetailsLabel>
                            </Col>
                            <Col md="auto">
                                <DetailsValue>{getAssociateTaxonomyNodeDetails.data}</DetailsValue>
                            </Col>
                        </Row>
                    </ContentContainer>
                    <LargeVerticalSpace />
                </>
            )}

            {isQuerySuccessful(getHierarchyTypesByHierarchyAssignableFlag) && (
                <ContentContainer>
                    <SbTabs values={buildSbTabItemProps()} />
                </ContentContainer>
            )}
            <LargeVerticalSpace />

            <EndAlignedDiv>
                <Col sm={"auto"}>
                    <SbButton
                        icon={FaLink}
                        variant={"primary"}
                        type={"button"}
                        label={t("Associate", { keyPrefix: Common })}
                        onClick={handleAssociateTaxonomyNode}
                        disabled={!selectedTaxonomyNode}
                    />
                </Col>
                <Col sm={"auto"}>
                    <CancelButton onClick={() => navigate(-1)} />
                </Col>
            </EndAlignedDiv>
        </>
    );
};

export default AssociateChecklistTaxonomyNodeContainer;
