import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
    createBasePaginationRequest,
    createBasePaginationRequestFromDto,
} from "../common/base-pagination-request";

export interface FilterByNodeTypeRequest extends BasePaginationRequest {
    nodeTypeId: number;
}

export const createFilterByNodeTypeRequest = (
    pageNumber: number,
    pageSize: number,
    nodeTypeId: number
): FilterByNodeTypeRequest => ({
    ...createBasePaginationRequest(pageNumber, pageSize),
    nodeTypeId: nodeTypeId,
});

export const createFilterByNodeTypeRequestFromDto = (
    basePaginationDto: BasePaginationDto,
    nodeTypeId: number
): FilterByNodeTypeRequest => ({
    ...createBasePaginationRequestFromDto(basePaginationDto),
    nodeTypeId: nodeTypeId,
});

export const buildFilterByNodeTypeSearchParameters = (
    request: FilterByNodeTypeRequest
): URLSearchParams => {
    const searchParams = buildBasePaginationSearchParameters(request);

    searchParams.set("nodeTypeId", request.nodeTypeId.toString());

    return searchParams;
};
