import AnswerTypeResponse from "../../responses/answer-types/answer-type-response";
import { Response } from "../../responses/common/response-response";
import PredefinedAnswerDto from "../predefined-answers/predefined-answer-dto";
import BaseAnswerTypeDto from "./base-answer-type-dto";

export default class AnswerTypeDto extends BaseAnswerTypeDto {
    predefinedAnswerDtos: PredefinedAnswerDto[];

    public constructor(response: AnswerTypeResponse) {
        super(response);

        this.predefinedAnswerDtos = PredefinedAnswerDto.toPredefinedAnswerDtos(
            response.predefinedAnswerResponses
        );
    }

    public static constructFromResponses = (
        response: Response<AnswerTypeResponse[]>
    ): AnswerTypeDto[] => response.data.map((x) => new AnswerTypeDto(x));
}
