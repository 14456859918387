import { ActionItemNodeAssociationMode } from "../../enums/action-items/ActionItemNodeAssociationMode";

export interface SearchActionItemNodeAssociationDto {
    actionItemId: number | null;
    actionItemNodeAssociationMode: ActionItemNodeAssociationMode | null;
}

export const emptySearchActionItemNodeAssociationDto = (): SearchActionItemNodeAssociationDto => ({
    actionItemId: null,
    actionItemNodeAssociationMode: null,
});

const SearchParameters = {
    actionItemId: "actionItemId",
    actionItemNodeAssociationMode: "actionItemNodeAssociationMode",
};

export const buildSearchActionItemNodeAssociationSearchParameters = (
    request: SearchActionItemNodeAssociationDto,
    searchParams: URLSearchParams
): URLSearchParams => {
    if (request.actionItemId != null) {
        searchParams.set(SearchParameters.actionItemId, request.actionItemId.toString());
    }

    if (request.actionItemNodeAssociationMode != null) {
        searchParams.set(
            SearchParameters.actionItemNodeAssociationMode,
            request.actionItemNodeAssociationMode.toString()
        );
    }

    return searchParams;
};
