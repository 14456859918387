import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaUnlink } from "react-icons/fa";
import { useParams, useSearchParams } from "react-router-dom";
import {
    ChecklistQuestions,
    Common,
    Hierarchy,
    NodeTypeValues,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { createAssociateDissociateQuestionTaxonomyNodeDto } from "../../../domain/dtos/questions/associate-dissociate-question-taxonomy-node-dto";
import {
    useDissociateTaxonomyNode,
    useGetTaxonomyNodeDetails,
} from "../../../domain/viewmodels/questions/dissociate-taxonomy-node-viewmodel";
import { SbButton } from "../../atoms/SbButton";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import { ActionConfirmationFragment } from "../../organisms/ActionConfirmationFragment";

const DissociateQuestionTaxonomyNodeContainer: React.FC = () => {
    const menu = useMenu();
    const [urlSearchParams] = useSearchParams();
    const navigateSearch = useNavigateSearch();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const questionId = Number(useParams().questionId);
    const nodeId = Number(urlSearchParams.get("nodeId"));

    const getDissociateTaxonomyNodeDetails = useGetTaxonomyNodeDetails(nodeId);
    const dissociateTaxonomyNode = useDissociateTaxonomyNode();

    const dissociateTaxonomyNodeDetailsResponseData = getDissociateTaxonomyNodeDetails.data;

    useLoader(
        isQueryLoading(getDissociateTaxonomyNodeDetails) ||
            isMutationLoading(dissociateTaxonomyNode),
        DissociateQuestionTaxonomyNodeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Questions);
    }, []);

    const buildDissociateButton = (): ReactElement<typeof Button> => (
        <SbButton
            variant="danger"
            type="button"
            label={t("Dissociate", { keyPrefix: Common })}
            icon={FaUnlink}
            onClick={() => handleDissociateTaxonomyNode()}
        />
    );

    const handleDissociateTaxonomyNode = (): void => {
        dissociateTaxonomyNode.mutate(
            createAssociateDissociateQuestionTaxonomyNodeDto(questionId, nodeId),
            {
                onSuccess: async () => {
                    queryClient.invalidateQueries(["getAssociatedTaxonomyNodes"]);

                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "messageKey",
                            "SuccessfullyDissociatedTaxonomyNodeFromQuestion"
                        ),
                    ];

                    navigateSearch(`${getPath(AccordionTitles.Questions)}/${questionId}`, params);
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    const buildConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow(
            t("HierarchyLevel", { keyPrefix: Hierarchy }),
            dissociateTaxonomyNodeDetailsResponseData?.nodeTypeName
        ),
        new TextConfirmationRow(
            t("NodeTypeValue", { keyPrefix: NodeTypeValues }),
            dissociateTaxonomyNodeDetailsResponseData?.nodeTypeValueValue
        ),
        new TextConfirmationRow(
            t("Path", { keyPrefix: Common }),
            dissociateTaxonomyNodeDetailsResponseData?.path
        ),
    ];

    return isQuerySuccessful(getDissociateTaxonomyNodeDetails) ? (
        <ActionConfirmationFragment
            pageHeading={t("DissociateQuestionTaxonomyNode")}
            panelTitle={t("DissociateQuestionTaxonomyNodeConfirmationText")}
            rows={buildConfirmationRows()}
            primaryButton={buildDissociateButton()}
        />
    ) : (
        <></>
    );
};

export default DissociateQuestionTaxonomyNodeContainer;
