export default class EditScheduleDto {
    questionSetAssignmentScheduleId: number;
    checklistAssignmentId: number;
    activeDateNoTimeZone: Date;
    expiryDateNoTimeZone: Date;
    effectChangesIncludingToday: boolean;

    public constructor(
        questionSetAssignmentScheduleId: number,
        checklistAssignmentId: number,
        activeDateNoTimeZone: Date,
        expiryDateNoTimeZone: Date,
        effectChangesIncludingToday: boolean
    ) {
        this.questionSetAssignmentScheduleId = questionSetAssignmentScheduleId;
        this.checklistAssignmentId = checklistAssignmentId;
        this.activeDateNoTimeZone = activeDateNoTimeZone;
        this.expiryDateNoTimeZone = expiryDateNoTimeZone;
        this.effectChangesIncludingToday = effectChangesIncludingToday;
    }
}
