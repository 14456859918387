import QuestionAnswerFileUploadDto from "../../dtos/question-set-instance-answers/question-answer-file-upload-dto";
import FileDetailsUploadRequest from "../file-storage/file-details-upload-request";
import BaseQuestionAnswerFileUploadRequest from "./base-question-answer-file-upload-request";

export default class QuestionAnswerFileUploadRequest extends BaseQuestionAnswerFileUploadRequest {
    questionSetInstanceAnswerId: number;

    public constructor(
        questionSetInstanceAnswerId: number,
        fileDetailsUploadRequests: FileDetailsUploadRequest[]
    ) {
        super(fileDetailsUploadRequests);

        this.questionSetInstanceAnswerId = questionSetInstanceAnswerId;
    }

    public static constructFromQuestionAnswerFileUploadDto = (
        dto: QuestionAnswerFileUploadDto
    ): QuestionAnswerFileUploadRequest => {
        const { questionSetInstanceAnswerEntityId } = dto;

        return new QuestionAnswerFileUploadRequest(questionSetInstanceAnswerEntityId, [
            FileDetailsUploadRequest.constructFromFileUploadDto(dto),
        ]);
    };
}
