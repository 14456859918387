import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Home } from "../../core/constants/translation-namespace";
import { useAuth } from "../../core/store/auth-context";
import { AcceptButton } from "../atoms/SbButton";

const CookiesFooter = styled.footer`
    bottom: 0;
    position: sticky;
    z-index: 100;
    border: ${(props) => props.theme.padding.xs + " solid " + props.theme.palette.primary};
    background-color: ${(props) => props.theme.palette.white};
    text-align: center;
`;

const StyledCookiesRow = styled(Row)`
    --bs-gutter-x: 0;
`;

const StyledCookiesMessage = styled(Col)`
    color: ${(props) => props.theme.palette.secondary};
    font-size: ${(props) => props.theme.font.xl};
    padding-top: ${(props) => props.theme.padding.sm};
    padding-bottom: ${(props) => props.theme.padding.sm};
    align-self: center;
`;

const StyledAcceptButton = styled(Col)`
    padding-top: ${(props) => props.theme.padding.sm};
    padding-bottom: ${(props) => props.theme.padding.sm};
`;

const CookiesBanner: React.FC = () => {
    const auth = useAuth();

    const { t } = useTranslation("translation", { keyPrefix: Home });

    const handleCookiesAcceptedButtonClicked = (): void => {
        auth.updateIsCookiesAccepted(true);
        localStorage.setItem("isCookiesAccepted", "true");
    };

    return (
        <>
            {!auth.isCookiesAccepted ? (
                <CookiesFooter>
                    <StyledCookiesRow>
                        <StyledCookiesMessage md={11}>
                            {t("CookiesBannerMessage", { keyPrefix: Home })}
                        </StyledCookiesMessage>
                        <StyledAcceptButton md={1}>
                            <AcceptButton
                                type={"button"}
                                onClick={handleCookiesAcceptedButtonClicked}
                            />
                        </StyledAcceptButton>
                    </StyledCookiesRow>
                </CookiesFooter>
            ) : (
                <></>
            )}
        </>
    );
};

export default CookiesBanner;
