import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { Users } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { AssociateDissociateUserNodeDto } from "../../../domain/dtos/users/associate-dissociate-user-node-dto";
import {
    useDissociateUser,
    useGetUserDetails,
} from "../../../domain/viewmodels/users/dissociate-user-node-viewmodel";
import { DissociateButton } from "../../atoms/SbButton";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import { ActionConfirmationFragment } from "../../organisms/ActionConfirmationFragment";

const DissociateUserNodeContainer: React.FC = () => {
    const userId = Number(useParams().userId);

    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const [urlSearchParams] = useSearchParams();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: Users });

    const nodeId = Number(urlSearchParams.get("nodeId"));

    const getUserDetails = useGetUserDetails(userId);
    const userDetailsResponseData = getUserDetails.data;
    const dissociateUser = useDissociateUser();

    useLoader(
        isQueryLoading(getUserDetails) || isMutationLoading(dissociateUser),
        DissociateUserNodeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.UserManagement, AccordionTitles.Users);
    }, []);

    const buildDissociateButton = (): ReactElement<typeof Button> => (
        <DissociateButton onClick={handleDissociateNode} />
    );

    const handleDissociateNode = (): void => {
        dissociateUser.mutate(new AssociateDissociateUserNodeDto(nodeId!, userId!), {
            onSuccess: async () => {
                queryClient.invalidateQueries(["getAssociatedUserNodesFilter"]);

                const params = [
                    createNavigateSearchParameter("success", "true"),
                    createNavigateSearchParameter(
                        "messageKey",
                        "SuccessfullyDissociatedUserFromNode"
                    ),
                ];

                navigateSearch(`${getPath(AccordionTitles.Users)}/${userId}`, params);
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    const buildConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow(
            t("UserName"),
            `${userDetailsResponseData?.firstName} ${userDetailsResponseData?.lastName}`
        ),
    ];

    return isQuerySuccessful(getUserDetails) ? (
        <ActionConfirmationFragment
            pageHeading={t("DissociateUserNode")}
            panelTitle={t("DissociateNodeUserConfirmationDisplay")}
            rows={buildConfirmationRows()}
            primaryButton={buildDissociateButton()}
        />
    ) : (
        <></>
    );
};

export default DissociateUserNodeContainer;
