import GenerateQuestionAnswerActionItemDto from "../../dtos/action-items/generate-question-answer-action-item-dto";

export default class GenerateQuestionAnswerActionItemRequest {
    questionSetInstanceAnswerId: number;
    dateTimeValueLocal: Date;

    public constructor(questionSetInstanceAnswerId: number, dateTimeValueLocal: Date) {
        this.questionSetInstanceAnswerId = questionSetInstanceAnswerId;
        this.dateTimeValueLocal = dateTimeValueLocal;
    }

    public static constructFromDto = (
        dto: GenerateQuestionAnswerActionItemDto
    ): GenerateQuestionAnswerActionItemRequest => {
        const { questionSetInstanceAnswerId, dateTimeValueLocal } = dto;

        return new GenerateQuestionAnswerActionItemRequest(
            questionSetInstanceAnswerId,
            dateTimeValueLocal
        );
    };
}
