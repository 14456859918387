export interface SearchHierarchyImportDto {
    fileName: string | null;
    dateTimeFrom: Date | null;
    dateTimeTo: Date | null;
    createdBy: string | null;
}

export const createSearchHierarchyImportDto = (
    fileName: string | null,
    dateTimeFrom: Date | null,
    dateTimeTo: Date | null,
    createdBy: string | null
): SearchHierarchyImportDto => ({
    fileName: fileName,
    dateTimeFrom: dateTimeFrom,
    dateTimeTo: dateTimeTo,
    createdBy: createdBy,
});

export const emptySearchHierarchyImportDto = (): SearchHierarchyImportDto =>
    createSearchHierarchyImportDto(null, null, null, null);
