import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Common } from "../../../core/constants/translation-namespace";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { wizardsColumnNames } from "../../../core/utilities/dataTableColumns";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import { defaultPaginationDto, PaginationDto } from "../../../domain/dtos/common/pagination-dto";
import { WizardDto } from "../../../domain/dtos/wizards/wizard-dto";
import { useGetWizards } from "../../../domain/viewmodels/wizards/view-wizards-viewmodel";
import { CreateLink } from "../../atoms/SbLink";
import { DataTable } from "../../organisms/DataTable";

const WizardsContainer: React.FC = () => {
    const [paginationDto, setPaginationDto] = useState<PaginationDto>(defaultPaginationDto);

    const menu = useMenu();
    const navigate = useNavigate();
    const { t } = useTranslation("translation", { keyPrefix: Common });

    const getWizards = useGetWizards(paginationDto);

    useLoader(isQueryLoading(getWizards), WizardsContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.WizardCards, AccordionTitles.Wizards);
    }, []);

    const navigateToEditWizard = (metadata: WizardDto): void => {
        const { wizardId, name } = metadata;

        navigate(`${getPath(AccordionTitles.Wizards)}/${wizardId}/edit`, {
            state: { name: name },
        });
    };

    const navigateToViewWizard = (metadata: WizardDto): void => {
        const { wizardId } = metadata;

        navigate(`${getPath(AccordionTitles.Wizards)}/${wizardId}`);
    };

    return (
        <>
            <PageHeading>{t("Wizards")}</PageHeading>
            <SectionVerticalSpace />
            <EndAlignedDiv>
                <CreateLink
                    label={t("CreateNew")}
                    navigateTo={`${getPath(AccordionTitles.Wizards)}/create`}
                />
            </EndAlignedDiv>
            <LargeVerticalSpace />
            {isQuerySuccessful(getWizards) && (
                <DataTable
                    keyPrefix={Common}
                    columns={wizardsColumnNames}
                    rows={getWizards.data!.rows}
                    linkItem={navigateToViewWizard}
                    viewItem={navigateToViewWizard}
                    editItem={navigateToEditWizard}
                    totalItems={getWizards.data!.recordCount}
                    paginationDto={paginationDto}
                    setPaginationDto={setPaginationDto}
                />
            )}
        </>
    );
};

export default WizardsContainer;
