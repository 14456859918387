import { FilterActionItemDto } from "../../dtos/actions/filter-action-items-dto";
import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
    createBasePaginationRequest,
} from "../common/base-pagination-request";

export interface FilterActionItemsRequest extends BasePaginationRequest {
    actionItemId: string | null;
    name: string | null;
    actionItemTypeNodeId: number | null;
    actionItemSubTypeNodeId: number | null;
    raciParticipant: string | null;
    actionItemImportanceId: number | null;
    actionInstanceStatusId: number | null;
    dueDateFrom: Date | null;
    dueDateTo: Date | null;
    actionItemNodeId: number | null;
}

export const createFilterActionItemsRequest = (
    filterActionItemsDto: FilterActionItemDto,
    paginationDto: BasePaginationDto
): FilterActionItemsRequest => ({
    ...filterActionItemsDto,
    ...createBasePaginationRequest(paginationDto.pageNumber, paginationDto.pageSize),
});

export const buildFilterSearchActionItemsParameters = (
    request: FilterActionItemsRequest
): URLSearchParams => {
    const searchParams = buildBasePaginationSearchParameters(request);

    if (request.name) {
        searchParams.set("name", request.name);
    }

    if (request.actionItemTypeNodeId) {
        searchParams.set("actionItemTypeNodeId", request.actionItemTypeNodeId.toString());
    }

    if (request.actionItemSubTypeNodeId) {
        searchParams.set("actionItemSubTypeNodeId", request.actionItemSubTypeNodeId.toString());
    }

    if (request.raciParticipant) {
        searchParams.set("raciParticipant", request.raciParticipant);
    }

    if (request.actionItemImportanceId) {
        searchParams.set("actionItemImportanceId", request.actionItemImportanceId.toString());
    }

    if (request.actionItemId) {
        searchParams.set("actionItemId", request.actionItemId);
    }

    if (request.actionInstanceStatusId !== null && request.actionInstanceStatusId >= 0) {
        searchParams.set("actionInstanceStatusId", request.actionInstanceStatusId.toString());
    }

    if (request.dueDateFrom) {
        searchParams.set("dueDateFrom", request.dueDateFrom.toLocaleString());
    }

    if (request.dueDateTo) {
        searchParams.set("dueDateTo", request.dueDateTo.toLocaleString());
    }

    if (request.actionItemNodeId) {
        searchParams.set("actionItemNodeId", request.actionItemNodeId.toString());
    }

    return searchParams;
};
