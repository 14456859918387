import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { editWizard } from "../../../data/services/wizards/wizards-service";
import { Response } from "../../responses/common/response-response";

interface EditParameters {
    wizardId: number;
    formData: FormData;
}

export const useEditWizard = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    EditParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: EditParameters) => {
        const formData = mutationKey.formData;

        const request = {
            wizardId: mutationKey.wizardId,
            wizardName: formData.get("name")! as string,
        };

        return editWizard(url.originalBaseUrl, request);
    });
};
