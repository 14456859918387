import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
    createBasePaginationRequestFromDto,
} from "../common/base-pagination-request";

export interface FilterNodeTypeValuesRequest extends BasePaginationRequest {
    nodeTypeId: number | null;
    hierarchyTypeId: number | null;
    value: string | null;
    containsTimeZoneCode: boolean | null;
}

export const createFilterNodeTypeValuesRequest = (
    hierarchyTypeId: number | null,
    nodeTypeId: number | null,
    value: string | null,
    containsTimeZoneCode: boolean | null,
    basePaginationDto: BasePaginationDto
): FilterNodeTypeValuesRequest => ({
    hierarchyTypeId: hierarchyTypeId,
    nodeTypeId: nodeTypeId,
    value: value,
    containsTimeZoneCode: containsTimeZoneCode,
    ...createBasePaginationRequestFromDto(basePaginationDto),
});

export const buildFilterSearchParameters = (
    request: FilterNodeTypeValuesRequest
): URLSearchParams => {
    const searchParams = buildBasePaginationSearchParameters(request);

    if (request.hierarchyTypeId) {
        searchParams.set("hierarchyTypeId", request.hierarchyTypeId.toString());
    }

    if (request.nodeTypeId) {
        searchParams.set("nodeTypeId", request.nodeTypeId.toString());
    }

    if (request.value) {
        searchParams.set("value", request.value);
    }

    if (request.containsTimeZoneCode) {
        searchParams.set("containsTimeZoneCode", request.containsTimeZoneCode.toString());
    }

    return searchParams;
};
