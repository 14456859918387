import { CreateActionItemDynamicFieldTextRequest } from "../../../domain/requests/action-items/create-action-item-dynamic-field-text-request";
import {
    buildFilterActionItemDynamicFieldTextsSearchParameters,
    FilterActionItemDynamicFieldTextsRequest,
} from "../../../domain/requests/action-items/filter-action-item-dynamic-field-texts-request";
import { ActionItemDynamicFieldTextResponse } from "../../../domain/responses/action-items/action-item-dynamic-field-text-response";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import client from "../../http-client";

const route = "actionitemdynamicfieldtexts";

export const filterActionItemDynamicFieldTexts = async (
    prefixUrl: string,
    request: FilterActionItemDynamicFieldTextsRequest
): Promise<Response<PaginationResponse<ActionItemDynamicFieldTextResponse>>> => {
    const searchParams = buildFilterActionItemDynamicFieldTextsSearchParameters(request);

    return await client
        .get(`${route}/filter`, {
            prefixUrl: prefixUrl,
            searchParams: searchParams,
        })
        .json();
};

export const createActionItemDynamicFieldText = async (
    prefixUrl: string,
    request: CreateActionItemDynamicFieldTextRequest
): Promise<Response<number | null>> =>
    await client
        .post(`${route}/create`, {
            prefixUrl: prefixUrl,
            body: JSON.stringify(request),
        })
        .json();

export const deleteActionItemDynamicFieldText = async (
    prefixUrl: string,
    actionItemDynamicFieldTextId: number
): Promise<Response<boolean>> =>
    await client
        .delete(`${route}/delete/${actionItemDynamicFieldTextId}`, {
            prefixUrl: prefixUrl,
        })
        .json();
