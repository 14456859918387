import { useMutation, UseMutationResult, useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { NavigateFunction, useNavigate } from "react-router";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { TableRow } from "../../../core/utilities/customTypes";
import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { AccordionTitles, DataTableColumnTypes } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { PeriodTypeOptions } from "../../../core/utilities/QuestionSetValues";
import { extractCoreTime } from "../../../core/utilities/string-helper";
import {
    cancelAndRescheduleChecklistAssignment,
    editQuestionSchedule,
    GetApplicableHierarchyLevelValues,
    getChecklistAssignmentDetails,
} from "../../../data/services/checklist-assignment/checklist-assignment-service";
import { getChecklistDetails } from "../../../data/services/checklists/checklist-service";
import { getNodeDetails } from "../../../data/services/hierarchy/nodes-service";
import {
    ChecklistAssignmentDetailsDto,
    createChecklistAssignmentDetailsDto,
} from "../../dtos/checklist-assignment/checklist-assignment-details-dto";
import EditChecklistAssignmentQuestionScheduleDto from "../../dtos/checklist-assignment/edit-checklist-assignment-question-schedule-dto";
import EditChecklistAssignmentQuestionSchedulesDto from "../../dtos/checklist-assignment/edit-checklist-assignment-question-schedules-dto";
import { ChecklistDto, createChecklistDto } from "../../dtos/checklists/checklist-dto";
import { ListTableDto } from "../../dtos/common/list-table-dto";
import { createNodeDetailsDto, NodeDetailsDto } from "../../dtos/hierarchy/node-details-dto";
import { PeriodType } from "../../enums/PeriodType";
import EditChecklistAssignmentQuestionSchedulesRequest from "../../requests/checklist-assignment/edit-checklist-assignment-question-schedules-request";
import { ChecklistAssignmentDetailsResponse } from "../../responses/checklist-assignment/checklist-assignment-details-response";
import { ChecklistAssignmentNodeTypeValueResponse } from "../../responses/checklist-assignment/checklist-assignment-node-type-value-response";
import { QuestionAssignmentScheduleResponse } from "../../responses/checklists/question-assignment-schedule-response";
import { QuestionSetAssignmentScheduleResponse } from "../../responses/checklists/question-set-assignment-schedule-response";
import { Response } from "../../responses/common/response-response";

export const useGetChecklistAssignmentDetails = (
    checklistAssignmentId: number,
    checklistId: number,
    nodeId: number
): [
    UseQueryResult<ChecklistAssignmentDetailsDto, HTTPError>,
    UseQueryResult<ChecklistDto, HTTPError>,
    UseQueryResult<NodeDetailsDto, HTTPError>,
    UseQueryResult<ListTableDto<number>, HTTPError>,
    UseQueryResult<ListTableDto<number>, HTTPError>,
] => {
    const url = useUrl();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getChecklistAssignmentDetails", checklistAssignmentId],
                queryFn: () => getChecklistAssignmentDetails(url.baseUrl, checklistAssignmentId),
                select: createChecklistAssignmentDetailsDto,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getChecklist", checklistId],
                queryFn: () => getChecklistDetails(url.baseUrl, checklistId),
                select: createChecklistDto,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getNodeDetails", nodeId],
                queryFn: () => getNodeDetails(url.baseUrl, nodeId),
                select: createNodeDetailsDto,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getChecklistAssignmentDetails", checklistAssignmentId],
                queryFn: () => getChecklistAssignmentDetails(url.baseUrl, checklistAssignmentId),
                select: (response: Response<ChecklistAssignmentDetailsResponse>) =>
                    transformToDataTableRows(response, navigate),
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getApplicableHierarchyLevelValues", checklistAssignmentId],
                queryFn: () =>
                    GetApplicableHierarchyLevelValues(url.baseUrl, checklistAssignmentId),
                select: (response: Response<ChecklistAssignmentNodeTypeValueResponse[]>) =>
                    transformToApplicableHierarchyLevelValuesDataTableRows(response, navigate),
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<ChecklistAssignmentDetailsDto, HTTPError>,
        UseQueryResult<ChecklistDto, HTTPError>,
        UseQueryResult<NodeDetailsDto, HTTPError>,
        UseQueryResult<ListTableDto<number>, HTTPError>,
        UseQueryResult<ListTableDto<number>, HTTPError>,
    ];
};

const transformToDataTableRows = (
    response: Response<ChecklistAssignmentDetailsResponse>,
    navigate: NavigateFunction
): ListTableDto<number> => {
    const responseData = response.data;

    const rows = responseData.questionSetAssignmentScheduleResponses?.map((x) => {
        return {
            metadata: x.questionSetAssignmentScheduleId,
            isNestedRowsDisplayed: x.questionAssignmentScheduleResponses.length > 0,
            nestedRows:
                x.questionAssignmentScheduleResponses.length > 0
                    ? transformToNestedQuestionsRows(
                          x.questionSetAssignmentScheduleId,
                          x.questionAssignmentScheduleResponses,
                          navigate
                      )
                    : [],
            showOverrideAction: true,
            columns: [
                {
                    value: x.questionSetName,
                    type: DataTableColumnTypes.Link,
                    linkItemAction: () => {
                        navigate(`${getPath(AccordionTitles.QuestionSets)}/${x.questionSetId}`);
                    },
                    metadata: "Name",
                },
                {
                    value: toDateAndTimeFormat(responseData.activeDateNoTimeZone.toString()),
                    type: DataTableColumnTypes.Text,
                    metadata: "ActiveDateNoTimeZone",
                },
                {
                    value:
                        responseData.expiryDateNoTimeZone === null
                            ? ""
                            : toDateAndTimeFormat(responseData.expiryDateNoTimeZone.toString()),
                    type: DataTableColumnTypes.Text,
                    metadata: "Expiry Date",
                },
                {
                    value: x.scheduleDescription,
                    type: DataTableColumnTypes.Text,
                    metadata: "Schedule Description",
                },
                {
                    value: getViewableFromValue(x),
                    type: DataTableColumnTypes.Text,
                    metadata: "Viewable from",
                },
                {
                    value: extractCoreTime(x.baseStartTimeOfDay),
                    type: DataTableColumnTypes.Text,
                    metadata: "Base Start Time of Day",
                },
                {
                    value: x.viewableReminderRequired.toString(),
                    type: DataTableColumnTypes.Check,
                    metadata: "Send reminder when",
                },
                {
                    value: x.isDefaultSchedule ? "Default" : "Overridden",
                    type: DataTableColumnTypes.Text,
                    metadata: "Schedule",
                },
            ],
        };
    });

    return { rows: rows };
};

const numberOfMinutesInADay = 1440;
const numberOfMinutesInAnHour = 60;

const getViewableFromValue = (questionSetSchedule: QuestionSetAssignmentScheduleResponse): string =>
    `${
        questionSetSchedule!.viewableMinutesBeforeBaseStart >= numberOfMinutesInADay
            ? questionSetSchedule!.viewableMinutesBeforeBaseStart / numberOfMinutesInADay
            : questionSetSchedule!.viewableMinutesBeforeBaseStart / numberOfMinutesInAnHour
    } ${
        questionSetSchedule!.viewableMinutesBeforeBaseStart >= numberOfMinutesInADay
            ? PeriodTypeOptions.find((x) => x.value === PeriodType.Days)!.label
            : PeriodTypeOptions.find((x) => x.value === PeriodType.Hours)!.label
    } before`;

const transformToNestedQuestionsRows = (
    questionSetAssignmentScheduleId: number,
    nestedQuestions: QuestionAssignmentScheduleResponse[],
    navigate: NavigateFunction
): TableRow<number>[] => {
    const rows = nestedQuestions.map((x) => ({
        parentId: questionSetAssignmentScheduleId,
        metadata: x.questionAssignmentScheduleId,
        columns: [
            {
                value: x.questionText,
                type: DataTableColumnTypes.Link,
                linkItemAction: () => {
                    navigate(`${getPath(AccordionTitles.Questions)}/${x.questionId}`);
                },
                metadata: "QuestionText",
            },
            {
                value: x.orderIndex.toString(),
                type: DataTableColumnTypes.Text,
                metadata: "Order Index",
            },
            {
                value:
                    x.openIntervalBeforeDueTime &&
                    `${x.openIntervalBeforeDueTime.value.toString()}`,
                type: DataTableColumnTypes.PeriodInput,
                periodTypeValue: `${
                    PeriodTypeOptions.find(
                        (y) => y.value === x.openIntervalBeforeDueTime.periodType
                    )?.label
                }`,
                metadata: "OpenBeforeDue",
            },
            {
                value: extractCoreTime(x.requiredByTime),
                type: DataTableColumnTypes.TimeInput,
                metadata: "DueByTime",
            },
            {
                value:
                    x.escalationL1IntervalAfterDueTime &&
                    `${x.escalationL1IntervalAfterDueTime.value.toString().trim()}`,
                periodTypeValue:
                    x.escalationL1IntervalAfterDueTime &&
                    `${
                        PeriodTypeOptions.find(
                            (y) => y.value === x.escalationL1IntervalAfterDueTime.periodType
                        )?.label
                    }`,
                type: DataTableColumnTypes.PeriodInput,
                metadata: "EL1",
            },
            {
                value:
                    x.escalationL2IntervalAfterEscalationL1 &&
                    `${x.escalationL2IntervalAfterEscalationL1.value.toString().trim()}`,
                periodTypeValue:
                    x.escalationL2IntervalAfterEscalationL1 &&
                    `${
                        PeriodTypeOptions.find(
                            (y) => y.value === x.escalationL2IntervalAfterEscalationL1.periodType
                        )?.label
                    }`,
                type: DataTableColumnTypes.PeriodInput,
                metadata: "EL2",
            },
            {
                value:
                    x.escalationL3IntervalAfterEscalationL2 &&
                    `${x.escalationL3IntervalAfterEscalationL2.value.toString()}`,
                periodTypeValue:
                    x.escalationL3IntervalAfterEscalationL2 &&
                    `${
                        PeriodTypeOptions.find(
                            (y) => y.value === x.escalationL3IntervalAfterEscalationL2.periodType
                        )?.label
                    }`,
                type: DataTableColumnTypes.PeriodInput,
                metadata: "EL3",
            },
        ],
    }));

    return rows;
};

const transformToApplicableHierarchyLevelValuesDataTableRows = (
    response: Response<ChecklistAssignmentNodeTypeValueResponse[]>,
    navigate: NavigateFunction
): ListTableDto<number> => {
    const responseData = response.data;

    const rows = responseData.map((x) => {
        return {
            metadata: x.checklistAssignmentId,
            columns: [
                {
                    value: x.nodeTypeValueResponse.value,
                    type: DataTableColumnTypes.Link,
                    linkItemAction: () => {
                        navigate(
                            `${getPath(AccordionTitles.VisualStructureValues)}/${
                                x.nodeTypeValueResponse.nodeTypeValueId
                            }`
                        );
                    },
                    metadata: "Node Type Value",
                },
                {
                    value: x.nodeTypeResponse.name,
                    type: DataTableColumnTypes.Link,
                    linkItemAction: () => {
                        navigate(
                            `${getPath(AccordionTitles.VisualStructure)}/${
                                x.nodeTypeResponse.nodeTypeId
                            }`
                        );
                    },
                    metadata: "Node Type",
                },
            ],
        };
    });

    return { rows: rows };
};

export const useCancelAndRescheduleChecklistAssignment = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    number
> => {
    const url = useUrl();

    return useMutation((checklistAssignmentId: number) =>
        cancelAndRescheduleChecklistAssignment(url.baseUrl, checklistAssignmentId)
    );
};

interface EditQuestionScheduleParameters {
    editChecklistAssignmentQuestionSchedulesDto: EditChecklistAssignmentQuestionSchedulesDto;
    editChecklistAssignmentQuestionScheduleDtos: EditChecklistAssignmentQuestionScheduleDto[];
}

export const useEditQuestionSchedule = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    EditQuestionScheduleParameters
> => {
    const url = useUrl();

    return useMutation((params: EditQuestionScheduleParameters) => {
        const request = EditChecklistAssignmentQuestionSchedulesRequest.constructFromDtos(
            params.editChecklistAssignmentQuestionSchedulesDto,
            params.editChecklistAssignmentQuestionScheduleDtos
        );

        return editQuestionSchedule(url.baseUrl, request);
    });
};
