import { WizardResponse } from "../../responses/wizards/wizard-response";

export interface WizardDto {
    wizardId: number;
    name: string;
}

export const createWizardDto = (wizardResponse: WizardResponse): WizardDto => ({
    wizardId: wizardResponse.wizardId,
    name: wizardResponse.name,
});
