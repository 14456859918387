import {
    convertUtcDateToLocalDate,
    toDateAndTimeFormat,
} from "../../../core/utilities/date-helper";
import { ActionInstanceStatus } from "../../enums/action-items/ActionInstanceStatus";
import { ActionInstanceDetailsResponse } from "../../responses/actions/action-instance-details-response";
import { Response } from "../../responses/common/response-response";

export class ActionInstanceDetailsDto {
    actionItemInstanceId: number;
    actionInstanceStatus: ActionInstanceStatus;
    startDateLocal: Date;
    dueDateLocal: Date;
    formattedStartDateLocal: string;
    formattedDueDateLocal: string;
    isExtended: boolean;
    isReopened: boolean;
    isOnHold: boolean;
    placeOnHoldsRemaining: number;
    canEditActionItemInstance?: boolean;

    public constructor(response: ActionInstanceDetailsResponse) {
        const {
            actionItemInstanceId,
            actionInstanceStatus,
            startDateUtc,
            dueDateUtc,
            isExtended,
            isReopened,
            isOnHold,
            placeOnHoldsRemaining,
            canEditActionItemInstance,
        } = response;

        this.actionItemInstanceId = actionItemInstanceId;
        this.actionInstanceStatus = actionInstanceStatus;
        this.startDateLocal = convertUtcDateToLocalDate(startDateUtc);
        this.dueDateLocal = convertUtcDateToLocalDate(dueDateUtc);
        this.formattedStartDateLocal = toDateAndTimeFormat(startDateUtc.toString());
        this.formattedDueDateLocal = toDateAndTimeFormat(dueDateUtc.toString());
        this.isExtended = isExtended;
        this.isReopened = isReopened;
        this.isOnHold = isOnHold;
        this.placeOnHoldsRemaining = placeOnHoldsRemaining;
        this.canEditActionItemInstance = canEditActionItemInstance;
    }

    public static constructFromResponse = (
        response: Response<ActionInstanceDetailsResponse>
    ): ActionInstanceDetailsDto => new ActionInstanceDetailsDto(response.data);
}
