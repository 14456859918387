import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { getWizardDetails } from "../../../data/services/wizards/wizards-service";
import { Response } from "../../../domain/responses/common/response-response";
import { toWizardDetailDto, WizardDetailDto } from "../../dtos/wizards/wizard-detail-dto";
import { WizardResponse } from "../../responses/wizards/wizard-response";

export const useGetWizardDetails = (
    wizardId: number
): UseQueryResult<WizardDetailDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getWizardDetails", wizardId],
        () => getWizardDetails(url.originalBaseUrl, wizardId),
        {
            select: transformToWizardDetailDto,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const transformToWizardDetailDto = (data: Response<WizardResponse>): WizardDetailDto => {
    const responseData = data.data;

    return toWizardDetailDto(responseData);
};
