import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import styled from "styled-components";
import { WizardCardDto } from "../../domain/dtos/wizards/wizard-card-dto";
import { ImageLibraryViewResponse } from "../../domain/responses/image-library/image-library-view-response";
import { WizardCard } from "../molecules/WizardCard";

const StyledList = styled.div`
    width: 100%;
`;

export const WizardCards: React.FC<{
    onDragEnd: (result: DropResult) => void;
    wizardCards?: WizardCardDto[];
    editWizardCard: (metadata: WizardCardDto) => void;
    deleteWizardCard: (metadata: WizardCardDto) => void;
    getImageLibraryImage: (fileDetailsId: number) => ImageLibraryViewResponse | undefined;
    sent: boolean;
}> = ({ onDragEnd, wizardCards, editWizardCard, deleteWizardCard, getImageLibraryImage, sent }) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided): JSX.Element => (
                    <StyledList {...provided.droppableProps} ref={provided.innerRef}>
                        {wizardCards?.map((wizardCard, index) => (
                            <WizardCard
                                key={index}
                                index={index}
                                wizardCard={wizardCard}
                                editWizardCard={editWizardCard}
                                deleteWizardCard={deleteWizardCard}
                                getImageLibraryImage={getImageLibraryImage}
                                sent={sent}
                            />
                        ))}
                        {provided.placeholder}
                    </StyledList>
                )}
            </Droppable>
        </DragDropContext>
    );
};
