import RaciRelationship from "../../enums/action-items/RaciRelationship";
import { BaseUserDto } from "../users/base-user-dto";
import { RaciSetUserDto } from "./raci-set-user-dto";

export class RaciParticipantDto {
    index: number;
    raciRelationship: RaciRelationship;
    userDto: BaseUserDto | null;
    isDisabled: boolean;

    public constructor(
        index: number,
        raciRelationship: RaciRelationship,
        userDto: BaseUserDto | null = null,
        isDisabled: boolean
    ) {
        this.index = index;
        this.raciRelationship = raciRelationship;
        this.userDto = userDto;
        this.isDisabled = isDisabled;
    }

    public static toRaciParticipantDtos = (dtos: RaciSetUserDto[]): RaciParticipantDto[] => {
        const raciParticipantDtos: RaciParticipantDto[] = [];
        let responsibleCount = 0;
        let accountableCount = 0;

        dtos
            ?.sort((a, b) => (a.raciRelationship > b.raciRelationship ? 1 : -1))
            .map((x, index) => {
                let isDisabled = false;
                if (x.raciRelationship === RaciRelationship.Responsible) {
                    responsibleCount = responsibleCount + 1;
                    if (responsibleCount === 1) {
                        isDisabled = true;
                    }
                }

                if (x.raciRelationship === RaciRelationship.Accountable) {
                    accountableCount = accountableCount + 1;
                    if (accountableCount === 1) {
                        isDisabled = true;
                    }
                }

                raciParticipantDtos.push(
                    new RaciParticipantDto(index + 1, x.raciRelationship, x, isDisabled)
                );
            });

        return raciParticipantDtos;
    };
}
