import RaciRelationship from "../../enums/action-items/RaciRelationship";
import { AgreedActionRaciUserResponse } from "../../responses/review-reports/agreed-action-raci-set-user-response";
import { BaseUserDto } from "../users/base-user-dto";

export class AgreedActionRaciUserDto {
    agreedActionId: number;
    userId: number;
    raciRelationship: RaciRelationship;
    userDto: BaseUserDto;

    public constructor(agreedActionRaciUserResponse: AgreedActionRaciUserResponse) {
        this.agreedActionId = agreedActionRaciUserResponse.agreedActionId;
        this.userId = agreedActionRaciUserResponse.userId;
        this.raciRelationship = agreedActionRaciUserResponse.raciRelationship;
        this.userDto = agreedActionRaciUserResponse.userResponse;
    }

    public static toAgreedActionRaciUserDtos = (
        responses: AgreedActionRaciUserResponse[]
    ): AgreedActionRaciUserDto[] => responses?.map((x) => new AgreedActionRaciUserDto(x));
}
