export class EditAgreedActionDto {
    agreedActionId: number;
    actionItemDueDate: Date;
    agreedActionValue: string;

    public constructor(agreedActionId: number, actionItemDueDate: Date, agreedActionValue: string) {
        this.agreedActionId = agreedActionId;
        this.actionItemDueDate = actionItemDueDate;
        this.agreedActionValue = agreedActionValue;
    }
}
