import { AuditChangelogResponse } from "../../responses/audits/audit-change-log-response";
import { Response } from "../../responses/common/response-response";

export interface AuditChangelogDto {
    changelogId: number;
    username: string;
    actionType: string;
    tableName: string;
    date: string;
    time: string;
    oldValues: string;
    newValues: string;
    updatedColumns: string;
    entityKey: number;
}

export const createAuditChangelogDto = (
    response: Response<AuditChangelogResponse>
): AuditChangelogDto => {
    const responseData = response.data;

    return {
        changelogId: responseData.changelogId,
        username: responseData.username,
        actionType: responseData.actionType,
        tableName: responseData.tableName,
        date: responseData.date,
        time: responseData.time,
        oldValues: responseData.oldValues,
        newValues: responseData.newValues,
        updatedColumns: responseData.updatedColumns,
        entityKey: responseData.entityKey,
    };
};
