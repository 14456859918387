import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { reviewChecklist } from "../../../data/services/checklists/checklist-service";
import { Response } from "../../responses/common/response-response";

export const useReviewChecklist = (): UseMutationResult<Response<boolean>, HTTPError, number> => {
    const url = useUrl();

    return useMutation((checklistId: number) => reviewChecklist(url.originalBaseUrl, checklistId));
};
