export interface SearchEmailQueueDto {
    subject: string | null;
    recipients: string | null;
    dateSentFrom: string | null;
    dateSentTo: string | null;
    failedToSend: boolean;
}

export const createSearchEmailQueueDto = (
    subject: string | null,
    recipients: string | null,
    dateSentFrom: string | null,
    dateSentTo: string | null,
    failedToSend: boolean
): SearchEmailQueueDto => ({
    subject: subject,
    recipients: recipients,
    dateSentFrom: dateSentFrom,
    dateSentTo: dateSentTo,
    failedToSend: failedToSend,
});

export const emptySearchEmailQueueDto = (): SearchEmailQueueDto =>
    createSearchEmailQueueDto(null, null, null, null, false);
