import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { getNodeDetails } from "../../../data/services/hierarchy/nodes-service";
import { dissociateTaxonomyNode } from "../../../data/services/questions/question-service";
import {
    createDissociateNodeDetailsDto,
    DissociateNodeDetailsDto,
} from "../../dtos/questions/dissociate-node-details-dto";
import {
    AssociateDissociateQuestionTaxonomyNodeRequest,
    createAssociateDissociateQuestionTaxonomyNodeRequest,
} from "../../requests/questions/associate-dissociate-question-taxonomy-node-request";
import { Response } from "../../responses/common/response-response";

export const useGetTaxonomyNodeDetails = (
    nodeId: number
): UseQueryResult<DissociateNodeDetailsDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(["getNodeDetails", nodeId], () => getNodeDetails(url.baseUrl, nodeId), {
        select: createDissociateNodeDetailsDto,
        onError: errorResponseToDisplayHandler,
    });
};

export const useDissociateTaxonomyNode = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    AssociateDissociateQuestionTaxonomyNodeRequest
> => {
    const url = useUrl();

    return useMutation((mutationKey: AssociateDissociateQuestionTaxonomyNodeRequest) => {
        const request = createAssociateDissociateQuestionTaxonomyNodeRequest(
            mutationKey.questionId,
            mutationKey.nodeId
        );

        return dissociateTaxonomyNode(url.baseUrl, request);
    });
};
