import { HTTPError } from "ky";
import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { DeleteNodeTypeEvent } from "../../../core/constants/application-insights-events";
import { Common, HierarchyTypes, NodeTypes } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isMutationLoading,
} from "../../../core/utilities/responseStateHelper";
import {
    useDeleteNodeType,
    useGetNodeTypeDetails,
} from "../../../domain/viewmodels/hierarchy/delete-node-type-viewmodel";
import { DeleteButton } from "../../atoms/SbButton";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import {
    ActionConfirmationFragment,
    AlertProps,
    createErrorAlertProps,
} from "../../organisms/ActionConfirmationFragment";

const DeleteNodeTypeContainer: React.FC = () => {
    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const [urlSearchParams] = useSearchParams();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: NodeTypes });

    const nodeTypeId = Number(useParams().nodeTypeId);
    const hierarchyTypeId = Number(urlSearchParams.get("hierarchyTypeId"));

    const getNodeTypeDetails = useGetNodeTypeDetails(nodeTypeId);
    const deleteNodeType = useDeleteNodeType();

    const nodeTypeDetailsResponseData = getNodeTypeDetails[0].data;
    const canDeleteNodeTypeResponseData = getNodeTypeDetails[1].data;

    useLoader(
        areQueriesLoading(getNodeTypeDetails) || isMutationLoading(deleteNodeType),
        DeleteNodeTypeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.VisualTree);
    }, []);

    const handleDeleteNodeType = (): void => {
        deleteNodeType.mutate(nodeTypeId, {
            onSuccess: onDeleteNodeTypeSuccess,
            onError: (error: HTTPError) => {
                trackAppInsightsException(
                    auth.email,
                    window.location.href,
                    DeleteNodeTypeEvent,
                    error
                );
                errorResponseToDisplayHandler(error);
            },
        });
    };

    const onDeleteNodeTypeSuccess = async (): Promise<void> => {
        trackAppInsightsEvent(auth.email, window.location.href, DeleteNodeTypeEvent);

        const params = [
            createNavigateSearchParameter("success", "true"),
            createNavigateSearchParameter("messageKey", "DeleteHierarchyLevelSuccessMessage"),
        ];

        navigateSearch(`${getPath(AccordionTitles.Admin)}/${hierarchyTypeId}`, params);
    };

    const buildValidationAlert = (): AlertProps | null => {
        if (!canDeleteNodeTypeResponseData || canDeleteNodeTypeResponseData.data) {
            return null;
        }

        return createErrorAlertProps(canDeleteNodeTypeResponseData!.errors);
    };

    const buildDeleteButton = (): ReactElement<typeof Button> => (
        <DeleteButton
            disabled={!canDeleteNodeTypeResponseData?.data}
            onClick={handleDeleteNodeType}
        />
    );

    const buildConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow(
            t("HierarchyType", { keyPrefix: HierarchyTypes }),
            nodeTypeDetailsResponseData?.hierarchyType.name
        ),
        new TextConfirmationRow(
            t("Name", { keyPrefix: Common }),
            nodeTypeDetailsResponseData?.name
        ),
    ];

    return (
        <ActionConfirmationFragment
            pageHeading={t("NodeTypeDeleteTitle")}
            panelTitle={t("DeleteNodeTypeConfirmation")}
            rows={buildConfirmationRows()}
            alert={buildValidationAlert()}
            primaryButton={buildDeleteButton()}
            areQueriesSuccessful={areQueriesSuccessful(getNodeTypeDetails)}
        />
    );
};

export default DeleteNodeTypeContainer;
