import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EnterKey } from "../../../core/constants/KeyboardKeys";
import { ActionItems, ActionPlans, Common } from "../../../core/constants/translation-namespace";
import useKeyPress from "../../../core/hooks/keyPress";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { actionPlanColumnNames } from "../../../core/utilities/dataTableColumns";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import {
    defaultFilterActionPlansDto,
    FilterActionPlansDto,
} from "../../../domain/dtos/actions/filter-action-plans-dto";
import {
    BasePaginationDto,
    defaultBasePaginationDto,
} from "../../../domain/dtos/common/base-pagination-dto";
import { useFilterActionPlans } from "../../../domain/viewmodels/actions/action-plans-viewmodel";
import { CreateLink } from "../../atoms/SbLink";
import { DataTable } from "../../organisms/DataTable";
import { ActionPlanFilter } from "../../organisms/filters/ActionPlanFilter";

interface SearchParams {
    name: string | null;
    description: string | null;
}

const createSearchParams = (name: string | null, description: string | null): SearchParams => ({
    name: name,
    description: description,
});

const defaultSearchParams: SearchParams = createSearchParams(null, null);

const ActionPlansContainer: React.FC = () => {
    const [searchParams, setSearchParams] = useState<SearchParams>(defaultSearchParams);
    const [filterDto, setFilterDto] = useState<FilterActionPlansDto>(defaultFilterActionPlansDto);
    const [paginationDto, setPaginationDto] = useState<BasePaginationDto>(defaultBasePaginationDto);

    const menu = useMenu();
    const navigate = useNavigate();
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const toast = useToast();
    const { t } = useTranslation("translation", { keyPrefix: ActionPlans });

    const filterActionPlans = useFilterActionPlans(filterDto, paginationDto);

    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";

    useLoader(isQueryLoading(filterActionPlans), ActionPlansContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.ActionTracker);
        menu.changeActiveDrawerItem(DrawerTitles.ActionPlans);

        if (success) {
            toast.addToast(createSuccessToastProps([t(messageKey)]));

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    const changeActionPlanName = (name: string): void => {
        setSearchParams({ ...searchParams, name: name ? name : null });
    };

    const changeActionPlanDescription = (description: string): void => {
        setSearchParams({ ...searchParams, description: description ? description : null });
    };

    const search = (): void => {
        setPaginationDto({
            ...paginationDto,
            pageNumber: 1,
        });
        setFilterDto({ ...filterDto, ...searchParams });
    };

    const resetFilter = (): void => {
        setSearchParams(defaultSearchParams);
        setFilterDto({ ...filterDto, ...defaultSearchParams });
    };

    const navigateToDeleteActionPlan = (actionPlanId: number): void =>
        navigate(`${getPath(DrawerTitles.ActionPlans)}/${actionPlanId}/delete`);

    const navigateToViewActionPlan = (actionPlanId: number): void =>
        navigate(`${getPath(DrawerTitles.ActionPlans)}/${actionPlanId}`);

    const navigateToEditActionPlan = (actionPlanId: number): void =>
        navigate(`${getPath(DrawerTitles.ActionPlans)}/${actionPlanId}/edit`);

    useKeyPress(EnterKey, search, searchParams);

    return (
        <>
            <PageHeading>{t("ActionPlanTitle")}</PageHeading>
            <PageSubHeading>{t("ActionPlanHeaderHelpText")}</PageSubHeading>
            <SectionVerticalSpace />

            <ActionPlanFilter
                actionPlanName={searchParams.name}
                changeActionPlanName={changeActionPlanName}
                actionPlanDescription={searchParams.description}
                changeActionPlanDescription={changeActionPlanDescription}
                search={search}
                resetFilter={resetFilter}
            />
            <SectionVerticalSpace />

            <EndAlignedDiv>
                <CreateLink
                    label={t("CreateNew", { keyPrefix: Common })}
                    navigateTo={`${getPath(DrawerTitles.ActionPlans)}/create`}
                />
            </EndAlignedDiv>
            <LargeVerticalSpace />

            {isQuerySuccessful(filterActionPlans) && (
                <DataTable
                    keyPrefix={ActionItems}
                    columns={actionPlanColumnNames}
                    rows={filterActionPlans.data!.rows}
                    viewItem={navigateToViewActionPlan}
                    editItem={navigateToEditActionPlan}
                    deleteItem={navigateToDeleteActionPlan}
                    totalItems={filterActionPlans.data!.recordCount}
                    paginationDto={paginationDto}
                    setPaginationDto={setPaginationDto}
                />
            )}
        </>
    );
};

export default ActionPlansContainer;
