import { useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import {
    getAssociatedNodes,
    getAssociatedQuestionSets,
    getAssociatedReferentialLinks,
    getAssociatedTaxonomyNodes,
    getChecklistDetails,
} from "../../../data/services/checklists/checklist-service";
import { FilterAssociatedNodesDto } from "../../../domain/dtos/checklist-assignment/filter-associated-nodes-dto";
import {
    AssociatedChecklistNodeDto,
    createAssociatedChecklistNodeDto,
} from "../../dtos/checklists/associated-checklist-node-dto";
import { ChecklistDto, createChecklistDto } from "../../dtos/checklists/checklist-dto";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { PaginationDto } from "../../dtos/common/pagination-dto";
import { createFilterChecklistAssociationRequest } from "../../requests/checklists/filter-checklist-association-request";
import { SearchChecklistAssociationRequest } from "../../requests/checklists/search-checklist-association-request";
import { createPaginationRequestFromDto } from "../../requests/common/pagination-request";
import { AssociatedChecklistQuestionSetResponse } from "../../responses/checklists/associated-checklist-question-set-response";
import { ChecklistAssignmentResponse } from "../../responses/checklists/checklist-assignment-response";
import { ChecklistReferentialLinkResponse } from "../../responses/checklists/checklist-referential-link-response";
import { ChecklistTaxonomyLinksResponse } from "../../responses/checklists/checklist-taxonomy-links-response";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";

export const useGetChecklistDetails = (
    checklistId: number,
    questionSetsPaginationDto: PaginationDto,
    nodesPaginationDto: PaginationDto,
    filterDto: FilterAssociatedNodesDto,
    referentialLinksPaginationDto: PaginationDto,
    taxonomyNodesPaginationDto: PaginationDto,
    searchRequest: SearchChecklistAssociationRequest
): [
    UseQueryResult<ChecklistDto, HTTPError>,
    UseQueryResult<PaginatedTableDto<number>, HTTPError>,
    UseQueryResult<PaginatedTableDto<AssociatedChecklistNodeDto>, HTTPError>,
    UseQueryResult<PaginatedTableDto<number>, HTTPError>,
    UseQueryResult<PaginatedTableDto<number>, HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getChecklist", checklistId],
                queryFn: () => getChecklistDetails(url.baseUrl, checklistId),
                select: createChecklistDto,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: [
                    "getAssociatedQuestionSets",
                    questionSetsPaginationDto,
                    searchRequest,
                    filterDto,
                ],
                queryFn: () =>
                    getAssociatedQuestionSets(
                        url.baseUrl,
                        createFilterChecklistAssociationRequest(
                            createPaginationRequestFromDto(questionSetsPaginationDto),
                            searchRequest,
                            filterDto.checklistAssignmentId,
                            filterDto.nodeSearchText
                        )
                    ),
                keepPreviousData: true,
                select: transformToQuestionSetsDataTableRows,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getAssociatedNodes", nodesPaginationDto, searchRequest, filterDto],
                queryFn: () =>
                    getAssociatedNodes(
                        url.baseUrl,
                        createFilterChecklistAssociationRequest(
                            createPaginationRequestFromDto(nodesPaginationDto),
                            searchRequest,
                            filterDto.checklistAssignmentId,
                            filterDto.nodeSearchText
                        )
                    ),
                keepPreviousData: true,
                select: transformToNodesDataTableRows,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: [
                    "getAssociatedReferentialLinks",
                    referentialLinksPaginationDto,
                    searchRequest,
                    filterDto,
                ],
                queryFn: () =>
                    getAssociatedReferentialLinks(
                        url.baseUrl,
                        createFilterChecklistAssociationRequest(
                            createPaginationRequestFromDto(referentialLinksPaginationDto),
                            searchRequest,
                            filterDto.checklistAssignmentId,
                            filterDto.nodeSearchText
                        )
                    ),
                keepPreviousData: true,
                select: transformToReferentialLinksDataTableRows,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: [
                    "getAssociatedTaxonomyNodes",
                    taxonomyNodesPaginationDto,
                    searchRequest,
                    filterDto,
                ],
                queryFn: () =>
                    getAssociatedTaxonomyNodes(
                        url.baseUrl,
                        createFilterChecklistAssociationRequest(
                            createPaginationRequestFromDto(taxonomyNodesPaginationDto),
                            searchRequest,
                            filterDto.checklistAssignmentId,
                            filterDto.nodeSearchText
                        )
                    ),
                keepPreviousData: true,
                select: transformToTaxonomyNodesDataTableRows,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<ChecklistDto, HTTPError>,
        UseQueryResult<PaginatedTableDto<number>, HTTPError>,
        UseQueryResult<PaginatedTableDto<AssociatedChecklistNodeDto>, HTTPError>,
        UseQueryResult<PaginatedTableDto<number>, HTTPError>,
        UseQueryResult<PaginatedTableDto<number>, HTTPError>,
    ];
};

const transformToQuestionSetsDataTableRows = (
    response: Response<PaginationResponse<AssociatedChecklistQuestionSetResponse>>
): PaginatedTableDto<number> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((questionSet) => ({
        metadata: questionSet.questionSetId,
        columns: [
            {
                // Name
                value: questionSet.name?.toString(),
                type: DataTableColumnTypes.Link,
            },
            {
                // Process
                value: questionSet.process?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                // Schedule Description
                value: questionSet.scheduleDescription?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                // Questions Count
                value: questionSet.activeQuestionsCount?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                // Last Modified by
                value: questionSet.modifiedByUserFullName?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                // Last Modified Date
                value:
                    questionSet.dateModifiedUtc &&
                    toDateAndTimeFormat(questionSet.dateModifiedUtc.toString()),
                type: DataTableColumnTypes.Text,
            },
            {
                // Checklists Count
                value: questionSet.activeChecklistsCount?.toString(),
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};

const transformToNodesDataTableRows = (
    response: Response<PaginationResponse<ChecklistAssignmentResponse>>
): PaginatedTableDto<AssociatedChecklistNodeDto> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((node) => ({
        metadata: createAssociatedChecklistNodeDto(node),
        columns: [
            {
                value: node.checklistAssignmentId?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: `${node.nodeType.name} - ${node.nodeTypeValue?.value}`,
                type: DataTableColumnTypes.Link,
            },
            {
                value: node.path,
                type: DataTableColumnTypes.Text,
            },
            {
                value: node.isEnabled.toString(),
                type: DataTableColumnTypes.Check,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};

const transformToReferentialLinksDataTableRows = (
    response: Response<PaginationResponse<ChecklistReferentialLinkResponse>>
): PaginatedTableDto<number> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((referentialLink) => ({
        metadata: referentialLink.referentialLinkId,
        columns: [
            {
                value: referentialLink.referentialLinkTypeName,
                type: DataTableColumnTypes.Text,
            },
            {
                value: referentialLink.referentialLinkValue,
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};

const transformToTaxonomyNodesDataTableRows = (
    response: Response<PaginationResponse<ChecklistTaxonomyLinksResponse>>
): PaginatedTableDto<number> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((taxonomyNode) => ({
        metadata: taxonomyNode.nodeId,
        columns: [
            {
                value: `${taxonomyNode.nodeType.name} - ${taxonomyNode.nodeTypeValue?.value}`,
                type: DataTableColumnTypes.Link,
            },
            {
                value: taxonomyNode.path,
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
