import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ChecklistQuestions } from "../../../core/constants/translation-namespace";
import {
    ContentContainer,
    EndAlignedDiv,
    SectionContainer,
} from "../../../core/theme/global-styles";
import { ResetButton, SearchButton } from "../../atoms/SbButton";
import { FilterInput } from "../../molecules/FilterInput";

export const ChecklistAssignedNodesFilter: React.FC<{
    checklistAssignmentId: number | null;
    nodeSearchText: string | null;
    checklistAssignmentIdFilterValue: (checklistAssignmentId: string) => void;
    nodeFilterValue: (nodeSearchText: string) => void;
    search: () => void;
    resetFilter: () => void;
}> = ({
    checklistAssignmentId,
    checklistAssignmentIdFilterValue,
    nodeSearchText,
    nodeFilterValue,
    search,
    resetFilter,
}) => {
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });
    return (
        <ContentContainer>
            <SectionContainer>
                <Row>
                    <Col>
                        <FilterInput
                            title={t("ChecklistAssignmentId")}
                            name={"ChecklistAssignmentId"}
                            type={"number"}
                            onChangeEventHandler={checklistAssignmentIdFilterValue}
                            value={(checklistAssignmentId ?? "").toString()}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("Node")}
                            name={"Node"}
                            type={"text"}
                            onChangeEventHandler={nodeFilterValue}
                            value={nodeSearchText ?? ""}
                        />
                    </Col>
                </Row>
            </SectionContainer>
            <EndAlignedDiv>
                <SearchButton onClick={search} />
                <ResetButton onClick={resetFilter} />
            </EndAlignedDiv>
        </ContentContainer>
    );
};
