import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ActionItems, Common, Nodes } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    PageSubHeading,
} from "../../../core/theme/global-styles";
import { DrawerTitles, HierarchyAssignableFlag, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    isMutationLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import { AssociateNodeToActionItemDto } from "../../../domain/dtos/actions/associate-node-to-action-item-dto";
import { ActionItemNodeAssociationMode } from "../../../domain/enums/action-items/ActionItemNodeAssociationMode";
import {
    useAssociateActionItemNode,
    useGetAssociateActionItemDetails,
    useGetUserOrganisationalNodes,
} from "../../../domain/viewmodels/action-items/associate-node-to-action-item-viewmodel";
import { useGetHierarchyTypesByHierarchyAssignableFlag } from "../../../domain/viewmodels/hierarchy/hierarchy-types-by-hierarchy-assignable-flag-viewmodel";
import { SbAlert } from "../../atoms/SbAlert";
import { AssociateButton, CancelButton } from "../../atoms/SbButton";
import { AssignToHierarchyComponent } from "./AssignToHierarchyComponent";

const AssociateActionItemNodeContainer: React.FC = () => {
    const [selectedNode, setSelectedNode] = useState<number>();

    const [urlSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const menu = useMenu();
    const toast = useToast();
    const auth = useAuth();
    const navigateSearch = useNavigateSearch();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: Nodes });

    const actionItemId = Number(useParams().actionItemId);
    const nodeAssociationMode = Number(urlSearchParams.get("nodeAssociationMode"));
    const isNewActionItem = urlSearchParams.get("isNewActionItem") === "true" ? true : false;
    const actionPlanId =
        urlSearchParams.get("actionPlanId") != undefined
            ? Number(urlSearchParams.get("actionPlanId"))
            : undefined;
    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";

    const hierarchyAssignableFlag = (): HierarchyAssignableFlag => {
        switch (nodeAssociationMode) {
            case ActionItemNodeAssociationMode.Organisational:
                return HierarchyAssignableFlag.OrganisationalNodesForActionItems;
            case ActionItemNodeAssociationMode.Geography:
                return HierarchyAssignableFlag.GeographyNodesForActionItems;
            default:
                return HierarchyAssignableFlag.ActionItems;
        }
    };

    const getHierarchyTypesByHierarchyAssignableFlag =
        useGetHierarchyTypesByHierarchyAssignableFlag(hierarchyAssignableFlag());

    const getAssociateActionItemDetails = useGetAssociateActionItemDetails(actionItemId);
    const associateNode = useAssociateActionItemNode();

    const getUserOrganisationalNodes = useGetUserOrganisationalNodes(auth.userId!);

    const actionItemDetailsResponseData = getAssociateActionItemDetails.data;

    useLoader(
        areQueriesLoading([
            getHierarchyTypesByHierarchyAssignableFlag,
            getAssociateActionItemDetails,
            getUserOrganisationalNodes,
        ]) || isMutationLoading(associateNode),
        AssociateActionItemNodeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.ActionTracker);
        menu.changeActiveDrawerItem(DrawerTitles.ActionItems);

        if (success && isNewActionItem) {
            toast.addToast(createSuccessToastProps([t(messageKey, { keyPrefix: ActionItems })]));
        }
    }, []);

    const handleAssociateNode = (): void => {
        switch (nodeAssociationMode) {
            case ActionItemNodeAssociationMode.Organisational:
                return associateNode.mutate(
                    new AssociateNodeToActionItemDto(
                        actionItemId,
                        selectedNode!,
                        ActionItemNodeAssociationMode.Organisational
                    ),
                    {
                        onSuccess: async () => {
                            if (actionPlanId) {
                                navigate(`${getPath(DrawerTitles.ActionPlans)}/${actionPlanId}`);
                            }

                            const params = [
                                createNavigateSearchParameter("success", "true"),
                                createNavigateSearchParameter(
                                    "messageKey",
                                    "SuccessfullyAssociatedOrganisationalNodeToActionItem"
                                ),
                            ];

                            navigateSearch(
                                `${getPath(DrawerTitles.ActionItems)}/${actionItemId}`,
                                params
                            );
                        },
                        onError: errorResponseToDisplayHandler,
                    }
                );
            case ActionItemNodeAssociationMode.Geography:
                return associateNode.mutate(
                    new AssociateNodeToActionItemDto(
                        actionItemId,
                        selectedNode!,
                        ActionItemNodeAssociationMode.Geography
                    ),
                    {
                        onSuccess: async () => {
                            const params = [
                                createNavigateSearchParameter("success", "true"),
                                createNavigateSearchParameter(
                                    "messageKey",
                                    "AssignedActionItemToGeographyNodeSuccessMessage"
                                ),
                            ];

                            navigateSearch(
                                `${getPath(DrawerTitles.ActionItems)}/${actionItemId}`,
                                params
                            );
                        },
                        onError: errorResponseToDisplayHandler,
                    }
                );
            case ActionItemNodeAssociationMode.Taxonomy:
                return associateNode.mutate(
                    new AssociateNodeToActionItemDto(
                        actionItemId,
                        selectedNode!,
                        ActionItemNodeAssociationMode.Taxonomy
                    ),
                    {
                        onSuccess: async () => {
                            const params = [
                                createNavigateSearchParameter("success", "true"),
                                createNavigateSearchParameter(
                                    "messageKey",
                                    "AssignedActionItemToTaxonomyNodeSuccessMessage"
                                ),
                            ];

                            navigateSearch(
                                `${getPath(DrawerTitles.ActionItems)}/${actionItemId}`,
                                params
                            );
                        },
                        onError: errorResponseToDisplayHandler,
                    }
                );
            default:
                return;
        }
    };

    const renderPageHeadings = (): JSX.Element => {
        switch (nodeAssociationMode) {
            case ActionItemNodeAssociationMode.Organisational:
                return (
                    <>
                        <PageHeading>{t("AssociateNode")}</PageHeading>
                        <PageSubHeading>
                            {t("AssociateNodeHelpTextActionItemOrganisational", {
                                keyPrefix: ActionItems,
                            })}
                        </PageSubHeading>
                    </>
                );
            case ActionItemNodeAssociationMode.Geography:
                return (
                    <>
                        <PageHeading>
                            {t("AssociateGeographyNode", {
                                keyPrefix: ActionItems,
                            })}
                        </PageHeading>
                        <PageSubHeading>
                            {t("AssociateGeographyNodeHelpText", {
                                keyPrefix: ActionItems,
                            })}
                        </PageSubHeading>
                    </>
                );
            case ActionItemNodeAssociationMode.Taxonomy:
                return (
                    <>
                        <PageHeading>
                            {t("AssociateTaxonomyTitle", {
                                keyPrefix: ActionItems,
                            })}
                        </PageHeading>
                        <PageSubHeading>
                            {t("AssociateNodeHelpTextActionItemTaxonomy", {
                                keyPrefix: ActionItems,
                            })}
                        </PageSubHeading>
                    </>
                );
            default:
                return <></>;
        }
    };

    const renderActionItemDetails = (): JSX.Element => (
        <ContentContainer>
            <Row>
                <Col md="auto">
                    <DetailsLabel>{t("ActionItem", { keyPrefix: ActionItems })}</DetailsLabel>
                    <DetailsLabel>{t("Description", { keyPrefix: Common })}</DetailsLabel>
                    <DetailsLabel>{t("StartDate", { keyPrefix: ActionItems })}</DetailsLabel>
                    <DetailsLabel>{t("DueDate", { keyPrefix: ActionItems })}</DetailsLabel>
                </Col>
                <Col md="auto">
                    <DetailsValue>{actionItemDetailsResponseData!.name}</DetailsValue>
                    <DetailsValue>{actionItemDetailsResponseData!.description}</DetailsValue>
                    <DetailsValue>
                        {
                            actionItemDetailsResponseData!.actionInstanceDetailsDto
                                .formattedStartDateLocal
                        }
                    </DetailsValue>
                    <DetailsValue>
                        {
                            actionItemDetailsResponseData!.actionInstanceDetailsDto
                                .formattedDueDateLocal
                        }
                    </DetailsValue>
                </Col>
            </Row>
        </ContentContainer>
    );

    return (
        <>
            {renderPageHeadings()}
            <LargeVerticalSpace />

            {isQuerySuccessful(getAssociateActionItemDetails) && (
                <>
                    <SbAlert
                        variant={"primary"}
                        text={t("ActionItemToHierarchyReportingNotificationMessage", {
                            keyPrefix: ActionItems,
                        })}
                    />
                    {renderActionItemDetails()}
                    <LargeVerticalSpace />
                </>
            )}

            <AssignToHierarchyComponent
                isNewActionItem={isNewActionItem}
                nodeAssociationMode={nodeAssociationMode}
                selectedNode={selectedNode}
                setSelectedNode={setSelectedNode}
            />
            <LargeVerticalSpace />

            <EndAlignedDiv>
                <Col sm={"auto"}>
                    <AssociateButton onClick={handleAssociateNode} disabled={!selectedNode} />
                </Col>
                <Col sm={"auto"}>
                    <CancelButton onClick={() => navigate(-1)} />
                </Col>
            </EndAlignedDiv>
        </>
    );
};

export default AssociateActionItemNodeContainer;
