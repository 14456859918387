import { HTTPError } from "ky";
import { useEffect } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaSave } from "react-icons/fa";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
    ActivateChecklistAssignmentEvent,
    SuspendChecklistAssignmentEvent,
} from "../../../core/constants/application-insights-events";
import { ChecklistQuestions, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
    StyledHiddenOverflowForm,
} from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areMutationsLoading,
    areQueriesLoading,
    areQueriesSuccessful,
} from "../../../core/utilities/responseStateHelper";
import { useActivateChecklistAssignment } from "../../../domain/viewmodels/checklist-assignment/activate-checklist-assignment-viewmodel";
import { useGetActivateSuspendChecklistAssignmentDetails } from "../../../domain/viewmodels/checklist-assignment/activate-suspend-checklist-assignment-details-viewmodel";
import { useSuspendChecklistAssignment } from "../../../domain/viewmodels/checklist-assignment/suspend-checklist-assignment-viewmodel";
import { CancelButton, SbButton } from "../../atoms/SbButton";
import { SbRibbon } from "../../atoms/SbRibbon";
import SbFormCheckFieldGroup from "../../molecules/input/SbFormCheckFieldGroup";
import SbFormDateTimeFieldGroup from "../../molecules/input/SbFormDateTimeFieldGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const ActivateSuspendChecklistAssignmentContainer: React.FC = () => {
    const menu = useMenu();
    const [urlSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const auth = useAuth();
    const navigateSearch = useNavigateSearch();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const checklistAssignmentId = Number(useParams().checklistAssignmentId);
    const checklistId = Number(urlSearchParams.get("checklistId"));

    const checklistAssignmentDetails = useGetActivateSuspendChecklistAssignmentDetails(
        checklistAssignmentId,
        checklistId
    );
    const suspendChecklistAssignment = useSuspendChecklistAssignment();
    const activateChecklistAssignment = useActivateChecklistAssignment();

    let checklistAssignmentDetailsResponseData = checklistAssignmentDetails[0].data;

    useLoader(
        areQueriesLoading(checklistAssignmentDetails) ||
            areMutationsLoading([suspendChecklistAssignment, activateChecklistAssignment]),
        ActivateSuspendChecklistAssignmentContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Checklists);
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        if (checklistAssignmentDetailsResponseData!.isEnabled) {
            const formData = new FormData(event.currentTarget);

            suspendChecklistAssignment.mutate(
                { checklistAssignmentId: checklistAssignmentId, formData: formData },
                {
                    onSuccess: async () => {
                        trackAppInsightsEvent(
                            auth.email,
                            window.location.href,
                            SuspendChecklistAssignmentEvent
                        );
                        const params = [
                            createNavigateSearchParameter("success", "true"),
                            createNavigateSearchParameter(
                                "messageKey",
                                "ChecklistAssignmentSuspendSuccess"
                            ),
                            createNavigateSearchParameter(
                                "nodeId",
                                checklistAssignmentDetailsResponseData!.nodeId.toString()
                            ),
                            createNavigateSearchParameter("checklistId", checklistId.toString()),
                        ];

                        navigateSearch(
                            `${getPath(
                                AccordionTitles.Checklists
                            )}/${checklistAssignmentId}/checklist-assignment-details`,
                            params
                        );
                    },
                    onError: (error: HTTPError) => {
                        trackAppInsightsException(
                            auth.email,
                            window.location.href,
                            SuspendChecklistAssignmentEvent,
                            error
                        );
                        errorResponseToDisplayHandler(error);
                    },
                }
            );
        } else {
            activateChecklistAssignment.mutate(checklistAssignmentId, {
                onSuccess: async () => {
                    trackAppInsightsEvent(
                        auth.email,
                        window.location.href,
                        ActivateChecklistAssignmentEvent
                    );
                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "messageKey",
                            "ChecklistAssignmentActivateSuccess"
                        ),
                        createNavigateSearchParameter(
                            "nodeId",
                            checklistAssignmentDetailsResponseData!.nodeId.toString()
                        ),
                        createNavigateSearchParameter("checklistId", checklistId.toString()),
                    ];

                    navigateSearch(
                        `${getPath(
                            AccordionTitles.Checklists
                        )}/${checklistAssignmentId}/checklist-assignment-details`,
                        params
                    );
                },
                onError: (error: HTTPError) => {
                    trackAppInsightsException(
                        auth.email,
                        window.location.href,
                        ActivateChecklistAssignmentEvent,
                        error
                    );
                    errorResponseToDisplayHandler(error);
                },
            });
        }
    };

    return areQueriesSuccessful(checklistAssignmentDetails) ? (
        <>
            <PageHeading>
                {checklistAssignmentDetailsResponseData!.isEnabled
                    ? `${t("SuspendChecklistAssignment")}`
                    : `${t("ActivateChecklistAssignment")}`}
            </PageHeading>
            <SectionVerticalSpace />

            <StyledHiddenOverflowForm onSubmit={handleSubmit}>
                <TextTitledPanel
                    title={
                        checklistAssignmentDetailsResponseData!.isEnabled
                            ? `${t("Suspend")}`
                            : `${t("Activate")}`
                    }
                >
                    {checklistAssignmentDetailsResponseData!.isTraining && (
                        <SbRibbon size={"large"} label={t("Training", { keyPrefix: Common })} />
                    )}

                    <SbFormTextFieldGroup
                        name="checklist"
                        label={t("Checklist")}
                        value={checklistAssignmentDetails[1].data?.name}
                        type="text"
                        disabled
                    />

                    <SbFormTextFieldGroup
                        name="node"
                        label={t("Node")}
                        value={checklistAssignmentDetailsResponseData!.nodeId.toString()}
                        type="text"
                        disabled
                    />

                    <SbFormCheckFieldGroup
                        fieldLabel={t("IsEnabled", { keyPrefix: Common })}
                        type="checkbox"
                        values={[
                            {
                                name: "isEnabled",
                                defaultSelected: checklistAssignmentDetailsResponseData!.isEnabled,
                                disabled: true,
                            },
                        ]}
                    />

                    <SbFormCheckFieldGroup
                        fieldLabel={t("IsTrainingDisplay", { keyPrefix: Common })}
                        type="checkbox"
                        values={[
                            {
                                name: "isTraining",
                                defaultSelected: checklistAssignmentDetailsResponseData!.isTraining,
                                disabled: true,
                            },
                        ]}
                    />

                    {checklistAssignmentDetailsResponseData!.isEnabled && (
                        <>
                            <SbFormDateTimeFieldGroup
                                name="suspendFrom"
                                label={t("SuspendFrom")}
                                defaultValue={new Date()}
                                excludeTime
                            />

                            <SbFormDateTimeFieldGroup name="suspendTo" label={t("SuspendTo")} />
                        </>
                    )}

                    <EndAlignedDiv>
                        <Col sm={"auto"}>
                            <SbButton
                                icon={FaSave}
                                variant={"primary"}
                                type="submit"
                                label={
                                    checklistAssignmentDetailsResponseData!.isEnabled
                                        ? `${t("Suspend")}`
                                        : `${t("Activate")}`
                                }
                            />
                        </Col>
                        <Col sm={"auto"}>
                            <CancelButton onClick={() => navigate(-1)} />
                        </Col>
                    </EndAlignedDiv>
                </TextTitledPanel>
            </StyledHiddenOverflowForm>
        </>
    ) : (
        <></>
    );
};

export default ActivateSuspendChecklistAssignmentContainer;
