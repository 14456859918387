export class RemoveResolveAgreedActionDto {
    questionSetInstanceId: number;
    questionSetInstanceAnswerId: number;
    commentText: string;
    agreedActionId: number;

    public constructor(
        questionSetInstanceId: number,
        questionSetInstanceAnswerId: number,
        commentText: string,
        agreedActionId: number
    ) {
        this.questionSetInstanceId = questionSetInstanceId;
        this.questionSetInstanceAnswerId = questionSetInstanceAnswerId;
        this.commentText = commentText;
        this.agreedActionId = agreedActionId;
    }
}
