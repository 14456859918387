import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { BaseNonWorkingDaySetResponse } from "../../responses/non-working-days/base-non-working-day-set-response";
import { NonWorkingDaySetResponse } from "../../responses/non-working-days/non-working-day-set-response";

export interface NonWorkingDaySetDto {
    nonWorkingDaySetId: number;
    name: string;
}

export const createNonWorkingDaySetDto = (
    nonWorkingDaySetResponse: BaseNonWorkingDaySetResponse
): NonWorkingDaySetDto => ({
    nonWorkingDaySetId: nonWorkingDaySetResponse.nonWorkingDaySetId,
    name: nonWorkingDaySetResponse.name,
});

export const toNonWorkingDaySetDtos = (
    response: Response<PaginationResponse<NonWorkingDaySetResponse>>
): NonWorkingDaySetDto[] => response.data.results?.map((x) => createNonWorkingDaySetDto(x));

export const toNonWorkingDaySetDto = (
    response: Response<BaseNonWorkingDaySetResponse>
): NonWorkingDaySetDto => createNonWorkingDaySetDto(response.data);
