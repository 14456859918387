import { Response } from "../../responses/common/response-response";
import { LanguageResponse } from "../../responses/language/language-response";

export interface LanguageDto {
    languageId: number;
    name: string;
    culture: string;
    isDefault: boolean;
}

export const createLanguageDto = (response: LanguageResponse): LanguageDto => ({ ...response });

export const createLanguageDtoFromResponse = (
    response: Response<LanguageResponse>
): LanguageDto => ({
    ...response.data,
});

export const toLanguageDtos = (response: Response<LanguageResponse[]>): LanguageDto[] =>
    response.data.map((x) => createLanguageDto(x));
