import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import styled from "styled-components";
import { ChecklistQuestions, Common } from "../../../core/constants/translation-namespace";
import {
    ContentContainer,
    EndAlignedDiv,
    SectionContainer,
    StyledFilterTitle,
} from "../../../core/theme/global-styles";
import AnswerTypeDto from "../../../domain/dtos/answer-types/answer-type-dto";
import BaseAnswerTypeDto from "../../../domain/dtos/answer-types/base-answer-type-dto";
import { ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import {
    AnswerTypeSearchDto,
    createAnswerTypeSearchDto,
    createSearchDto,
    SearchDto,
} from "../../../domain/dtos/common/search-dto";
import { QuestionCategoryDto } from "../../../domain/dtos/question-categories/question-category-dto";
import { QuestionTypes } from "../../../domain/enums/questions/question-types";
import { SbSelect } from "../../atoms/input/SbSelect";
import { ResetButton, SearchButton } from "../../atoms/SbButton";
import { FilterInput } from "../../molecules/FilterInput";
import { FilterSelect } from "../../molecules/FilterSelect";

const StyledRow = styled(Row)`
    padding-bottom: ${(props) => props.theme.padding.md};
`;

export const QuestionFilter: React.FC<{
    questionId: number | null;
    changeQuestionId: (questionId: string) => void;
    questionSetName: string | null;
    changeQuestionSetName: (questionSetName: string) => void;
    questionText: string | null;
    changeQuestionText: (questionText: string) => void;
    questionType: ReactSelectDto<QuestionTypes>;
    questionTypes: ReactSelectDto<QuestionTypes>[];
    changeQuestionType: (option: SingleValue<ReactSelectDto<QuestionTypes>>) => void;
    questionCategories: QuestionCategoryDto[];
    changeQuestionCategory: (option: SingleValue<QuestionCategoryDto>) => void;
    answerTypes: AnswerTypeDto[];
    changeAnswerType: (option: SingleValue<AnswerTypeDto>) => void;
    search: () => void;
    resetFilter: () => void;
    areQuestionCategoriesLoading: boolean;
    setQuestionCategorySearchText: React.Dispatch<React.SetStateAction<SearchDto>>;
    areAnswerTypesLoading: boolean;
    setAnswerTypeSearchText: React.Dispatch<React.SetStateAction<AnswerTypeSearchDto>>;
}> = ({
    questionId,
    changeQuestionId,
    questionSetName,
    changeQuestionSetName,
    questionText,
    changeQuestionText,
    questionType,
    questionTypes,
    changeQuestionType,
    questionCategories,
    changeQuestionCategory,
    answerTypes,
    changeAnswerType,
    search,
    resetFilter,
    areQuestionCategoriesLoading,
    setQuestionCategorySearchText,
    areAnswerTypesLoading,
    setAnswerTypeSearchText,
}) => {
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const [selectedQuestionCategory, setSelectedQuestionCategory] =
        useState<QuestionCategoryDto | null>(null);
    const [selectedAnswerType, setSelectedAnswerType] = useState<AnswerTypeDto | null>(null);

    return (
        <ContentContainer>
            <SectionContainer>
                <StyledRow>
                    <Col md={2}>
                        <FilterInput
                            title={t("ID")}
                            name={"questionId"}
                            type={"number"}
                            onChangeEventHandler={changeQuestionId}
                            value={questionId ? questionId.toString() : ""}
                        />
                    </Col>
                    <Col md={5}>
                        <FilterInput
                            title={t("QuestionText")}
                            name={"questionText"}
                            type={"text"}
                            onChangeEventHandler={changeQuestionText}
                            value={questionText ? questionText : ""}
                        />
                    </Col>
                    <Col md={5}>
                        <FilterInput
                            title={t("QuestionSet")}
                            name={"questionSet"}
                            type={"text"}
                            onChangeEventHandler={changeQuestionSetName}
                            value={questionSetName ? questionSetName.toString() : ""}
                        />
                    </Col>
                </StyledRow>
                <StyledRow>
                    <Col md={2}>
                        <FilterSelect
                            name={"questionType"}
                            label={`${t("QuestionType")}`}
                            placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                            searchable
                            clearable
                            items={questionTypes ?? []}
                            itemDisplayText={(option: ReactSelectDto<QuestionTypes>) =>
                                t(option.label)
                            }
                            onChange={changeQuestionType}
                            value={questionType.value && questionType}
                        />
                    </Col>
                    <Col>
                        <Col>
                            <StyledFilterTitle>{`${t("QuestionCategory")}`}</StyledFilterTitle>
                            <SbSelect
                                name={"questionCategory"}
                                placeholderText={
                                    t("LookupSearchPlaceholderText", { keyPrefix: Common })!
                                }
                                searchable
                                clearable
                                loading={areQuestionCategoriesLoading}
                                items={questionCategories}
                                onSearchTextChanged={(text: string) =>
                                    setQuestionCategorySearchText(createSearchDto(text, null))
                                }
                                itemLabel={(option: QuestionCategoryDto) => option.name}
                                itemValue={(option: QuestionCategoryDto) =>
                                    option.questionCategoryId.toString()
                                }
                                onChange={(option: SingleValue<QuestionCategoryDto>) => {
                                    setSelectedQuestionCategory(option);
                                    changeQuestionCategory(option);
                                }}
                                value={selectedQuestionCategory}
                            />
                        </Col>
                    </Col>
                </StyledRow>
                <StyledRow>
                    <Col md={4}>
                        <StyledFilterTitle>{`${t("AnswerType")}`}</StyledFilterTitle>
                        <SbSelect
                            name={"answerType"}
                            placeholderText={
                                t("LookupSearchPlaceholderText", { keyPrefix: Common })!
                            }
                            searchable
                            clearable={false}
                            items={answerTypes}
                            loading={areAnswerTypesLoading}
                            onSearchTextChanged={(text: string) =>
                                setAnswerTypeSearchText(
                                    createAnswerTypeSearchDto(text, questionType.value, null)
                                )
                            }
                            itemLabel={(option: BaseAnswerTypeDto) => option.name}
                            itemValue={(option: BaseAnswerTypeDto) =>
                                option.answerTypeId.toString()
                            }
                            onChange={(option: SingleValue<AnswerTypeDto>) => {
                                setSelectedAnswerType(option);
                                changeAnswerType(option);
                            }}
                            value={selectedAnswerType}
                        />
                    </Col>
                </StyledRow>
            </SectionContainer>
            <EndAlignedDiv>
                <SearchButton onClick={search} />
                <ResetButton
                    onClick={() => {
                        setSelectedQuestionCategory(null);
                        setSelectedAnswerType(null);
                        resetFilter();
                    }}
                />
            </EndAlignedDiv>
        </ContentContainer>
    );
};
