import { createPaginationDto, defaultPaginationDto, PaginationDto } from "../common/pagination-dto";

export interface FilterAuditsChangeLogDto extends PaginationDto {
    tableName: string | null;
    username: string | null;
    startDate: string | null;
    endDate: string | null;
    actionType: string | null;
    entityKey: string | null;
}

export const createFilterAuditsChangeLogDto = (
    tableName: string | null,
    username: string | null,
    startDate: string | null,
    endDate: string | null,
    actionType: string | null,
    entityKey: string | null,
    pageNumber: number,
    pageSize: number,
    sortByColumn: string | null,
    sortByDescending: boolean
): FilterAuditsChangeLogDto => ({
    tableName: tableName,
    username: username,
    startDate: startDate,
    endDate: endDate,
    actionType: actionType,
    entityKey: entityKey,
    ...createPaginationDto(pageNumber, pageSize, sortByColumn, sortByDescending),
});

export const defaultFilterAuditsChangelogDto = (): FilterAuditsChangeLogDto => {
    const { pageNumber, pageSize, sortByColumn, sortByDescending } = defaultPaginationDto;

    return createFilterAuditsChangeLogDto(
        null,
        null,
        null,
        null,
        null,
        null,
        pageNumber,
        pageSize,
        sortByColumn,
        sortByDescending
    );
};
