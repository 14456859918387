export class CreateQuestionTextDto {
    questionId: number;
    questionText: string;
    languageId: number | null;

    public constructor(questionId: number, questionText: string, languageId: number | null) {
        this.questionId = questionId;
        this.questionText = questionText;
        this.languageId = languageId;
    }
}
