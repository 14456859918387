type EnumObject = { [key: string]: number | string };
type EnumObjectEnum<EType extends EnumObject> = EType extends { [key: string]: infer ET | string }
    ? ET
    : never;

export const getEnumsForType = <EType extends EnumObject>(
    enumObject: EType
): EnumObjectEnum<EType>[] =>
    Object.keys(enumObject)
        .filter((key) => Number.isNaN(Number(key)))
        .map((key) => enumObject[key] as EnumObjectEnum<EType>);
