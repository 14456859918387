import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import { filterActionItems } from "../../../data/services/actions/action-items-service";
import { ActionItemEvidenceDto } from "../../dtos/actions/action-item-evidence-dto";
import { FilterActionItemDto } from "../../dtos/actions/filter-action-items-dto";
import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { ActionInstanceStatus } from "../../enums/action-items/ActionInstanceStatus";
import { createFilterActionItemsRequest } from "../../requests/actions/filter-action-items-request";
import { ActionItemResponse } from "../../responses/actions/action-item-response";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";

export const useFilterActionItems = (
    filterActionItemDto: FilterActionItemDto,
    paginationDto: BasePaginationDto
): UseQueryResult<PaginatedTableDto<ActionItemEvidenceDto>, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    return useQuery(
        ["filterActionItems", filterActionItemDto, paginationDto],
        () =>
            filterActionItems(
                url.baseUrl,
                createFilterActionItemsRequest(filterActionItemDto, paginationDto)
            ),
        {
            select: transformToDataTableRows,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const transformToDataTableRows = (
    response: Response<PaginationResponse<ActionItemResponse>>
): PaginatedTableDto<ActionItemEvidenceDto> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((actionItem) => ({
        metadata: new ActionItemEvidenceDto(
            actionItem.actionItemId,
            actionItem.actionItemInstanceId
        ),
        columns: [
            {
                value: actionItem.actionItemId?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: actionItem.name?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: ActionInstanceStatus[actionItem.actionInstanceStatus].toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: toDateAndTimeFormat(actionItem.dueDateUtc.toString()),
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
