import AnswerValueType from "../../enums/answer-types/answer-value-type";
import IndicatorAnswerDto from "./indicator-answer-dto";

export default class IndicatorQuestionAnswerDto {
    questionId: number;
    textValue: string | null;
    intValue: number | null;
    decimalValue: number | null;
    boolValue: boolean | null;
    dateTimeValueLocal: Date | null;

    public constructor(
        questionId: number,
        textValue: string | null = null,
        intValue: number | null = null,
        decimalValue: number | null = null,
        boolValue: boolean | null = null,
        dateTimeValueLocal: Date | null = null
    ) {
        this.questionId = questionId;
        this.textValue = textValue;
        this.intValue = intValue;
        this.decimalValue = decimalValue;
        this.boolValue = boolValue;
        this.dateTimeValueLocal = dateTimeValueLocal;
    }

    public static constructFromIndicatorAnswer<TType>(
        questionId: number,
        dto: IndicatorAnswerDto<TType>
    ): IndicatorQuestionAnswerDto {
        const indicatorQuestionAnswerDto = new IndicatorQuestionAnswerDto(questionId);

        switch (dto.answerValueType) {
            case AnswerValueType.Integer:
                indicatorQuestionAnswerDto.intValue = dto.value as number;
                break;

            case AnswerValueType.Decimal:
                indicatorQuestionAnswerDto.decimalValue = dto.value as number;
                break;

            case AnswerValueType.DateTime:
                indicatorQuestionAnswerDto.dateTimeValueLocal = dto.value as Date;
                break;

            case AnswerValueType.Boolean:
                indicatorQuestionAnswerDto.boolValue = dto.value as boolean;
                break;

            case (AnswerValueType.Text, AnswerValueType.TextArea):
                indicatorQuestionAnswerDto.textValue = dto.value as string;
                break;
        }

        return indicatorQuestionAnswerDto;
    }
}
