import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import ImportStatus from "../../enums/hierarchy/ImportStatus";
import { Response } from "../../responses/common/response-response";
import HierarchyImportDetailsResponse from "../../responses/hierarchy/hierarchy-import-details-response";

export default class HierarchyImportDetailsDto {
    createdDate: string;
    completedDate: string;
    hierarchyImportId: number;
    errors: string;
    uploadedFileName: string;
    importStatusValue: string;
    userEmail: string;
    hierarchyTypeId: number;

    public constructor(response: HierarchyImportDetailsResponse) {
        const {
            createdDate,
            completedDate,
            hierarchyImportId,
            errors,
            uploadedFileName,
            importStatus,
            userEmail,
            hierarchyTypeId,
        } = response;

        this.createdDate = toDateAndTimeFormat(createdDate.toString());
        this.completedDate = toDateAndTimeFormat(completedDate?.toString() ?? "");
        this.hierarchyImportId = hierarchyImportId;
        this.errors = errors;
        this.uploadedFileName = uploadedFileName;
        this.importStatusValue = ImportStatus[importStatus];
        this.userEmail = userEmail;
        this.hierarchyTypeId = hierarchyTypeId;
    }

    public static constructFromResponse = (
        response: Response<HierarchyImportDetailsResponse>
    ): HierarchyImportDetailsDto => new HierarchyImportDetailsDto(response.data);
}
