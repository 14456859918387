import { CreateAnswerTypeDto, createCreateAnswerTypeDto } from "./create-answer-type-dto";

export interface EditAnswerTypeDto extends CreateAnswerTypeDto {
    answerTypeId: number;
}

export const createEditAnswerTypeDto = (
    answerTypeId: number,
    formData: FormData,
    answerCategoryId: number,
    renderModeId: number,
    flagModeId: number,
    valueTypeId: number
): EditAnswerTypeDto => ({
    answerTypeId: answerTypeId,
    ...createCreateAnswerTypeDto(formData, answerCategoryId, renderModeId, flagModeId, valueTypeId),
});
