import "bootstrap/dist/css/bootstrap.min.css";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { AuthProvider } from "./core/store/auth-context";
import { MenuProvider } from "./core/store/menu-context";
import { UrlProvider } from "./core/store/url-context";
import { WizardNotificationProvider } from "./core/store/wizard-notification-context";
import { globalTheme } from "./core/theme/global-theme";
import "./i18n";
import App from "./presentation/pages/App";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <ThemeProvider theme={globalTheme}>
        <BrowserRouter>
            <UrlProvider>
                <AuthProvider>
                    <MenuProvider>
                        <WizardNotificationProvider>
                            <App />
                        </WizardNotificationProvider>
                    </MenuProvider>
                </AuthProvider>
            </UrlProvider>
        </BrowserRouter>
    </ThemeProvider>
);
