import { buildFilterChecklistAssignmentNodeTypeValuesSearchParameters } from "../../../domain/requests/checklists/filter-checklist-assignment-node-type-values-request";
import { CreateNodeTypeValueRequest } from "../../../domain/requests/hierarchy/create-node-type-value-request";
import { EditNodeTypeValueRequest } from "../../../domain/requests/hierarchy/edit-node-type-value-request";
import {
    buildFilterSearchParameters,
    FilterNodeTypeValuesRequest,
} from "../../../domain/requests/hierarchy/filter-node-type-values-request";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import { NodeTypeValueDetailsResponse } from "../../../domain/responses/hierarchy/node-type-value-details-response";
import { NodeTypeValueResponse } from "../../../domain/responses/hierarchy/node-type-value-response";
import client from "../../http-client";

const route = "nodetypevalues";

export const filterNodeTypeValues = async (
    prefixUrl: string,
    request: FilterNodeTypeValuesRequest
): Promise<Response<PaginationResponse<NodeTypeValueResponse>>> => {
    const searchParams = buildFilterSearchParameters(request);

    return await client
        .get(`${route}/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getChecklistAssignmentNodeTypeValues = async (
    prefixUrl: string,
    nodeId: number
): Promise<Response<NodeTypeValueDetailsResponse[]>> => {
    const searchParams = buildFilterChecklistAssignmentNodeTypeValuesSearchParameters(nodeId);
    return await client
        .get(`${route}/checklist-assignment-node-type-values`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getNodeTypeValueDetails = async (
    prefixUrl: string,
    nodeTypeValueId: number
): Promise<Response<NodeTypeValueDetailsResponse>> =>
    await client
        .get(`${route}/${nodeTypeValueId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const createNodeTypeValue = async (
    prefixUrl: string,
    request: CreateNodeTypeValueRequest
): Promise<Response<number | null>> =>
    await client
        .post(`${route}/create`, {
            prefixUrl: prefixUrl,
            body: JSON.stringify(request),
        })
        .json();

export const editNodeTypeValue = async (
    prefixUrl: string,
    request: EditNodeTypeValueRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/edit`, {
            prefixUrl: prefixUrl,
            body: JSON.stringify(request),
        })
        .json();

export const getCanDeleteNodeTypeValue = async (
    prefixUrl: string,
    nodeTypeValueId: number
): Promise<Response<boolean>> =>
    await client
        .get(`${route}/can-delete/${nodeTypeValueId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const deleteNodeTypeValue = async (
    prefixUrl: string,
    nodeTypeValueId: number
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/delete/${nodeTypeValueId}`, {
            prefixUrl: prefixUrl,
        })
        .json();
