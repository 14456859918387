import {
    buildSearchActionItemNodeAssociationSearchParameters,
    SearchActionItemNodeAssociationDto,
} from "../../dtos/actions/search-action-item-node-association-dto";
import { PaginationDto } from "../../dtos/common/pagination-dto";
import { buildPaginationSearchParameters } from "../common/pagination-request";

export interface FilterActionItemNodeAssociationRequest
    extends SearchActionItemNodeAssociationDto,
        PaginationDto {}

export const createFilterActionItemNodeAssociationRequest = (
    paginationRequest: PaginationDto,
    searchRequest: SearchActionItemNodeAssociationDto
): FilterActionItemNodeAssociationRequest => ({
    ...paginationRequest,
    actionItemId: searchRequest.actionItemId,
    actionItemNodeAssociationMode: searchRequest.actionItemNodeAssociationMode,
});

export const buildFilterActionItemNodeAssociationSearchParameters = (
    request: FilterActionItemNodeAssociationRequest
): URLSearchParams => {
    const searchParams = buildPaginationSearchParameters(request);
    buildSearchActionItemNodeAssociationSearchParameters(request, searchParams);

    return searchParams;
};
