import { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import {
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import { Response } from "../../../domain/responses/common/response-response";
import {
    useDeleteCheckListFromReview,
    useGetQuestionSetInstanceReviewChecklist,
} from "../../../domain/viewmodels/review-reports/delete-checklist-from-review-viewmodel";
import { CancelButton, DeleteButton } from "../../atoms/SbButton";
import { TextTitledPanel } from "../../molecules/SbPanel";

const DeleteCheckListFromReviewContainer: React.FC = () => {
    const menu = useMenu();
    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();
    const [urlSearchParams] = useSearchParams();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const reviewReportSetId = Number(urlSearchParams.get("reviewReportSetId"));
    const questionSetInstanceId = Number(useParams().questionSetInstanceId);

    const questionSetInstanceReviewChecklist =
        useGetQuestionSetInstanceReviewChecklist(questionSetInstanceId);
    const deletedCheckListFromReview = useDeleteCheckListFromReview();

    useLoader(
        isQueryLoading(questionSetInstanceReviewChecklist) ||
            isMutationLoading(deletedCheckListFromReview),
        DeleteCheckListFromReviewContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Checklists);
        menu.changeActiveDrawerItem(DrawerTitles.Reviews);
    }, []);

    const handleDelete = (): void => {
        deletedCheckListFromReview.mutate(questionSetInstanceId, {
            onSuccess: async (_: Response<boolean>) => {
                const params = [
                    createNavigateSearchParameter("success", "true"),
                    createNavigateSearchParameter(
                        "messageKey",
                        "SuccessfullyDeletedChecklistFromReview"
                    ),
                ];

                navigateSearch(`${getPath(DrawerTitles.Reviews)}/${reviewReportSetId}`, params);
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    return (
        <>
            {isQuerySuccessful(questionSetInstanceReviewChecklist) && (
                <>
                    <PageHeading>Delete checklist from review</PageHeading>

                    <SectionVerticalSpace />

                    <TextTitledPanel title="Are you sure you want to delete this checklist from the review report?">
                        <Form.Group as={Row} className="mb-3">
                            <Col sm={2}>
                                <DetailsLabel>Checklist</DetailsLabel>
                            </Col>
                            <Col sm="auto">
                                <DetailsValue>
                                    {questionSetInstanceReviewChecklist.data!.checklistDto.name}
                                </DetailsValue>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Col sm={2}>
                                <DetailsLabel>Question Set</DetailsLabel>
                            </Col>
                            <Col sm="auto">
                                <DetailsValue>
                                    {questionSetInstanceReviewChecklist.data!.questionSetDto.name}
                                </DetailsValue>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Col sm={2}>
                                <DetailsLabel>Created By</DetailsLabel>
                            </Col>
                            <Col sm="auto">
                                <DetailsValue>
                                    {`${
                                        questionSetInstanceReviewChecklist.data!
                                            .questionSetInstanceDto.adhocTriggerUser.firstName
                                    } ${
                                        questionSetInstanceReviewChecklist.data!
                                            .questionSetInstanceDto.adhocTriggerUser.lastName
                                    }`}
                                </DetailsValue>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Col sm={2}>
                                <DetailsLabel>Path</DetailsLabel>
                            </Col>
                            <Col sm={10}>
                                <DetailsValue>
                                    {questionSetInstanceReviewChecklist.data!.nodeDto.path}
                                </DetailsValue>
                            </Col>
                        </Form.Group>

                        <EndAlignedDiv>
                            <DeleteButton onClick={handleDelete} />
                            <CancelButton onClick={() => navigate(-1)} />
                        </EndAlignedDiv>
                    </TextTitledPanel>
                </>
            )}
        </>
    );
};

export default DeleteCheckListFromReviewContainer;
