import { UsersInRoleDto } from "../../dtos/dashboard/filter-users-in-role-dto";
import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
    createBasePaginationRequest,
} from "../common/base-pagination-request";

export interface UsersInRoleRequest extends BasePaginationRequest {
    userId: string | null;
    roleIds: number[] | null;
    emailAddress: string | null;
}

export const createUsersInRoleRequestFromDto = (dto: UsersInRoleDto): UsersInRoleRequest => {
    const { pageNumber, pageSize } = dto;

    return {
        ...dto,
        ...createBasePaginationRequest(pageNumber, pageSize),
    };
};

export const createTenantAdminOrSpecialistUserRequest = (
    userId: string | null,
    roleIds: number[] | null,
    emailAddress: string | null,
    pageNumber: number,
    pageSize: number
): UsersInRoleRequest => ({
    userId: userId,
    roleIds: roleIds,
    emailAddress: emailAddress,
    ...createBasePaginationRequest(pageNumber, pageSize),
});

export const buildTenantAdminOrSpecialistUserSearchParameters = (
    request: UsersInRoleRequest,
    searchParams: URLSearchParams
): URLSearchParams => {
    if (request.userId) {
        searchParams.set("userId", request.userId);
    }

    if (request.roleIds) {
        request.roleIds.forEach((r) => {
            searchParams.append("roleIds", r.toString());
        });
    }

    if (request.emailAddress) {
        searchParams.set("emailAddress", request.emailAddress);
    }

    return searchParams;
};

export const buildFilterUsersInRoleSearchParameters = (
    request: UsersInRoleRequest
): URLSearchParams => {
    const searchParams = buildBasePaginationSearchParameters(request);

    buildTenantAdminOrSpecialistUserSearchParameters(request, searchParams);

    return searchParams;
};
