import RaciRelationship from "../../enums/action-items/RaciRelationship";
import {
    defaultFilterActionItemsDto,
    FilterActionItemDto,
} from "../actions/filter-action-items-dto";
import { ReactSelectDto } from "../common/react-select-dto";

export interface FilterFinalizedActionItemsDto extends FilterActionItemDto {
    raciRelationship: ReactSelectDto<RaciRelationship>;
}

export const createFilterFinalizedActionItemsDto = (
    raciRelationship: ReactSelectDto<RaciRelationship>,
    filterActionItemsDto: FilterActionItemDto
): FilterFinalizedActionItemsDto => ({
    raciRelationship: raciRelationship,
    ...filterActionItemsDto,
});

export const defaultFilterFinalizedActionItemsDto: FilterFinalizedActionItemsDto =
    createFilterFinalizedActionItemsDto(
        {
            value: 0,
            label: "All",
        },
        defaultFilterActionItemsDto
    );
