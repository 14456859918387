import React from "react";
import styled, { css } from "styled-components";

interface StyledSbProgressDotProps {
    $index: number;
    $activeIndex: number;
}

const StyledSbProgressDot = styled.div<StyledSbProgressDotProps>`
    border-radius: 50%;
    width: ${(props) => props.theme.padding.lg};
    height: ${(props) => props.theme.padding.lg};
    border-width: ${(props) => props.theme.padding.xs};
    border-style: solid;
    border-color: ${(props) => props.theme.palette.primary};
    background-color: ${(props) => props.theme.palette.primary};

    cursor: pointer;

    ${({ $index, $activeIndex }) =>
        $index !== $activeIndex &&
        css`
            background-color: transparent;
        `}
`;

export const SbProgressDot: React.FC<{
    index: number;
    activeIndex: number;
    onActiveIndexChange: (index: number) => void;
}> = ({ index, activeIndex, onActiveIndexChange }) => (
    <StyledSbProgressDot
        $index={index}
        $activeIndex={activeIndex}
        onClick={() => onActiveIndexChange(index)}
    />
);
