import {
    allActionInstanceStatusOption,
    allActionItemImportanceOption,
    allActionItemSubTypeOption,
} from "../../../presentation/templates/actions/ActionItemsContainer";
import { ActionItemTypeDto, allActionItemTypeOption } from "../action-items/action-item-type-dto";
import { ActionInstanceStatusDto } from "./action-instance-status-dto";
import { ActionItemImportanceDto } from "./action-item-importance-dto";

export interface SearchActionItemsDto {
    actionItemId: string | null;
    name: string | null;
    actionItemTypeNodeId: number | null;
    actionItemType: ActionItemTypeDto;
    actionItemSubTypeNodeId: number | null;
    actionItemSubType: ActionItemTypeDto;
    raciParticipant: string | null;
    actionItemImportanceId: number | null;
    actionItemImportance: ActionItemImportanceDto;
    actionInstanceStatusId: number | null;
    actionInstanceStatus: ActionInstanceStatusDto;
    dueDateFrom: Date | null;
    dueDateTo: Date | null;
}

export const createSearchActionItemsDto = (
    actionItemId: string | null,
    name: string | null,
    actionItemTypeNodeId: number | null,
    actionItemType: ActionItemTypeDto,
    actionItemSubTypeNodeId: number | null,
    actionItemSubType: ActionItemTypeDto,
    raciParticipant: string | null,
    actionItemImportanceId: number | null,
    actionItemImportance: ActionItemImportanceDto,
    actionInstanceStatusId: number | null,
    actionInstanceStatus: ActionInstanceStatusDto,
    dueDateFrom: Date | null,
    dueDateTo: Date | null
): SearchActionItemsDto => ({
    actionItemId: actionItemId,
    name: name,
    actionItemTypeNodeId: actionItemTypeNodeId,
    actionItemType: actionItemType,
    actionItemSubTypeNodeId: actionItemSubTypeNodeId,
    actionItemSubType: actionItemSubType,
    raciParticipant: raciParticipant,
    actionItemImportanceId: actionItemImportanceId,
    actionItemImportance: actionItemImportance,
    actionInstanceStatusId: actionInstanceStatusId,
    actionInstanceStatus: actionInstanceStatus,
    dueDateFrom: dueDateFrom,
    dueDateTo: dueDateTo,
});

export const emptySearchActionItemsDto = (): SearchActionItemsDto =>
    createSearchActionItemsDto(
        null,
        null,
        null,
        allActionItemTypeOption,
        null,
        allActionItemSubTypeOption,
        null,
        null,
        allActionItemImportanceOption,
        null,
        allActionInstanceStatusOption,
        null,
        null
    );
