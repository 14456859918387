import { EditQuestionScheduleDto } from "../../dtos/question-sets/edit-question-schedule-dto";
import { PeriodType } from "../../enums/PeriodType";

export interface EditQuestionScheduleRequest {
    setIntervalDefaults: boolean;
    questionSetId: number;
    questionIds: number[];
    requiredByTimes: string[];
    openIntervalsBeforeDueTime: number[];
    openPeriodTypesBeforeDueTime: PeriodType[];
    escalationL1IntervalsAfterDueTime: number[];
    escalationL1PeriodTypesAfterDueTime: PeriodType[];
    escalationL2IntervalsAfterEscalationL1: (number | null)[];
    escalationL2PeriodTypesAfterEscalationL1: (PeriodType | null)[];
    escalationL3IntervalsAfterEscalationL2: (number | null)[];
    escalationL3PeriodTypesAfterEscalationL2: (PeriodType | null)[];
}

export const createEditQuestionScheduleRequest = (
    dto: EditQuestionScheduleDto
): EditQuestionScheduleRequest => ({ ...dto });
