import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { QuestionSetInstanceReviewResponse } from "../../responses/review-reports/question-set-instance-review-response";

export class QuestionSetInstanceReviewChecklistDto {
    questionSetInstanceId: number;
    checklistName: string;

    public constructor(response: QuestionSetInstanceReviewResponse) {
        this.questionSetInstanceId = response.questionSetInstanceId;
        this.checklistName = response.checklistResponse.name;
    }

    public static constructFromResponse = (
        response: Response<PaginationResponse<QuestionSetInstanceReviewResponse>>
    ): QuestionSetInstanceReviewChecklistDto[] =>
        response.data.results!.map((x) => new QuestionSetInstanceReviewChecklistDto(x));
}
