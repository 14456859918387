import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { filterNodeHierarchyByType } from "../../../data/services/hierarchy/hierarchy-service";
import {
    filterNodeHierarchy,
    filterNodeHierarchyUpwards,
    getNodeDetails,
} from "../../../data/services/hierarchy/nodes-service";
import { lookupHierarchyNodesPath } from "../../../data/services/lookup/lookup-service";
import { createNodeDetailsDto, NodeDetailsDto } from "../../dtos/hierarchy/node-details-dto";
import {
    NodeSubTreeUpwardDto,
    toNodeSubTreeUpwardDtos,
} from "../../dtos/hierarchy/node-sub-tree-upward-dto";
import { createHierarchySearchRequest } from "../../requests/common/hierachy-search-request";
import { createFilterNodeHierarchyByTypeRequest } from "../../requests/hierarchy/filter-node-hierarchy-by-type-request";
import { createFilterNodeHierarchyRequest } from "../../requests/hierarchy/filter-node-hierarchy-request";
import { Response } from "../../responses/common/response-response";
import {
    NodeHierarchyDescendantResponse,
    NodeHierarchyResponse,
} from "../../responses/hierarchy/node-hierarchy-response";

export const useLookupHierarchyNodesPath = (
    hierarchyTypeId: number | null,
    searchText: string | null
): UseQueryResult<NodeSubTreeUpwardDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["lookupHierarchyNodesPath", hierarchyTypeId, searchText],
        () =>
            lookupHierarchyNodesPath(
                url.baseUrl,
                createHierarchySearchRequest(hierarchyTypeId!, searchText, null)
            ),
        {
            enabled: hierarchyTypeId != null,
            select: toNodeSubTreeUpwardDtos,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useFilterNodeHierarchyByType = (
    hierarchyTypeId: number | null,
    depth: number,
    condition: boolean
): UseQueryResult<NodeHierarchyDescendantResponse, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterNodeHierarchyByType", hierarchyTypeId, depth],
        () =>
            filterNodeHierarchyByType(
                url.baseUrl,
                createFilterNodeHierarchyByTypeRequest(hierarchyTypeId!, depth)
            ),
        {
            enabled: hierarchyTypeId != null && hierarchyTypeId !== 0 && condition,
            select: toNodeHierarchyDescendantResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useFilterNodeHierarchy = (
    rootNodeId: number | null,
    depth: number,
    fetchCondition: boolean
): UseQueryResult<NodeHierarchyDescendantResponse, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterNodeHierarchy", rootNodeId, depth],
        () =>
            filterNodeHierarchy(url.baseUrl, createFilterNodeHierarchyRequest(rootNodeId!, depth)),
        {
            enabled: rootNodeId !== null && fetchCondition,
            select: toNodeHierarchyDescendantResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useFilterNodeHierarchyUpwards = (
    rootNodeId: number | null
): UseQueryResult<NodeHierarchyDescendantResponse, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterNodeHierarchyUpwards", rootNodeId],
        () =>
            filterNodeHierarchyUpwards(
                url.baseUrl,
                createFilterNodeHierarchyRequest(rootNodeId!, null)
            ),
        {
            enabled: rootNodeId !== null,
            select: toNodeHierarchyDescendantResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const toNodeHierarchyDescendantResponse = (
    response: Response<NodeHierarchyResponse>
): NodeHierarchyDescendantResponse => response.data.tree;

export const useGetNodeDetails = (
    nodeId: number | null
): UseQueryResult<NodeDetailsDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(["getNodeDetails", nodeId], () => getNodeDetails(url.baseUrl, nodeId!), {
        enabled: nodeId !== null,
        select: createNodeDetailsDto,
        onError: errorResponseToDisplayHandler,
    });
};
