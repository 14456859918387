import { useMutation, UseMutationResult, useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import {
    deleteActionItemDynamicFieldText,
    filterActionItemDynamicFieldTexts,
} from "../../../data/services/action-items/action-item-dynamic-field-texts-service";
import { getActionItemDynamicFieldDetails } from "../../../data/services/action-items/action-item-dynamic-fields-service";
import {
    ActionItemDynamicFieldDetailsDto,
    createActionItemDynamicFieldDetailsDto,
} from "../../dtos/action-items/action-item-dynamic-field-details-dto";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { PaginationDto } from "../../dtos/common/pagination-dto";
import { createFilterActionItemDynamicFieldTextsRequest } from "../../requests/action-items/filter-action-item-dynamic-field-texts-request";
import { ActionItemDynamicFieldTextResponse } from "../../responses/action-items/action-item-dynamic-field-text-response";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";

export const useGetActionItemDynamicFieldDetails = (
    actionItemDynamicFieldId: number,
    dynamicFieldTextPaginationDto: PaginationDto
): [
    UseQueryResult<ActionItemDynamicFieldDetailsDto, HTTPError>,
    UseQueryResult<PaginatedTableDto<number>, HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getActionItemDynamicFieldDetails", actionItemDynamicFieldId],
                queryFn: () =>
                    getActionItemDynamicFieldDetails(url.baseUrl, actionItemDynamicFieldId),
                select: createActionItemDynamicFieldDetailsDto,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: [
                    "filterActionItemDynamicFieldTexts",
                    actionItemDynamicFieldId,
                    dynamicFieldTextPaginationDto,
                ],
                queryFn: () =>
                    filterActionItemDynamicFieldTexts(
                        url.baseUrl,
                        createFilterActionItemDynamicFieldTextsRequest(
                            actionItemDynamicFieldId,
                            dynamicFieldTextPaginationDto,
                            false
                        )
                    ),
                select: toDataTableRows,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<ActionItemDynamicFieldDetailsDto, HTTPError>,
        UseQueryResult<PaginatedTableDto<number>, HTTPError>,
    ];
};

export const useDeleteActionItemDynamicFieldText = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    number
> => {
    const url = useUrl();

    return useMutation((actionItemDynamicFieldTextId: number) =>
        deleteActionItemDynamicFieldText(url.baseUrl, actionItemDynamicFieldTextId)
    );
};

const toDataTableRows = (
    response: Response<PaginationResponse<ActionItemDynamicFieldTextResponse>>
): PaginatedTableDto<number> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((x) => ({
        metadata: x.actionItemDynamicFieldTextId,
        columns: [
            {
                value: x.languageResponse.name,
                type: DataTableColumnTypes.Text,
            },
            {
                value: x.text,
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
