import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaBook, FaFlag, FaList, FaListOl } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { ActionItems, Common } from "../../../core/constants/translation-namespace";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
} from "../../../core/utilities/responseStateHelper";
import {
    ActionItemTypeDto,
    allActionItemTypeOption,
} from "../../../domain/dtos/action-items/action-item-type-dto";
import {
    FilterMyActionItemsDto,
    urlPopulatedFilterMyActionItemsDto,
} from "../../../domain/dtos/action-items/filter-my-action-items-dto";
import {
    emptySearchMyActionItemsDto,
    SearchMyActionItemsDto,
    urlPopulatedSearchMyActionItemsDto,
} from "../../../domain/dtos/action-items/search-my-action-items-dto";
import {
    ActionInstanceStatusDto,
    createActionInstanceStatusDto,
} from "../../../domain/dtos/actions/action-instance-status-dto";
import {
    BasePaginationDto,
    defaultBasePaginationDto,
} from "../../../domain/dtos/common/base-pagination-dto";
import { ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import { ActionInstanceOnHoldStatus } from "../../../domain/enums/action-items/ActionInstanceOnHoldStatus";
import { ActionInstanceStatus } from "../../../domain/enums/action-items/ActionInstanceStatus";
import RaciRelationship from "../../../domain/enums/action-items/RaciRelationship";
import { useFilterMyActionItems } from "../../../domain/viewmodels/action-items/view-my-action-items-viewmodel";
import { SbLink } from "../../atoms/SbLink";
import { ActionItemsList } from "../../organisms/ActionItemsList";
import { MyActionItemsFilter } from "../../organisms/filters/MyActionItemsFilter";
import {
    allActionInstanceStatusOption,
    allActionItemSubTypeOption,
} from "../actions/ActionItemsContainer";

import { EnterKey } from "../../../core/constants/KeyboardKeys";
import useKeyPress from "../../../core/hooks/keyPress";
import useLoader from "../../../core/hooks/loaderManager";
import {
    ActionInstanceOnHoldStatusOptions,
    ActionInstanceStatusOptions,
    RaciRelationshipOptions,
} from "../../../core/utilities/ActionItemValues";
import { getNullableString } from "../../../core/utilities/string-helper";

const MyActionItemsContainer: React.FC = () => {
    const menu = useMenu();
    const [urlSearchParams] = useSearchParams();

    const actionInstanceStatusId = Number(
        urlSearchParams.get("actionInstanceStatusId")
    ) as ActionInstanceStatus;
    const actionInstanceOnHoldStatus = Number(
        urlSearchParams.get("actionInstanceOnHoldStatus")
    ) as ActionInstanceOnHoldStatus;
    const raciRelationship = Number(urlSearchParams.get("raciRelationship")) as RaciRelationship;
    const actionItemInstanceIdSearchParameter = urlSearchParams.get("actionItemInstanceId");
    const parsedActionItemInstanceIdSearchParameter = actionItemInstanceIdSearchParameter
        ? Number(actionItemInstanceIdSearchParameter)
        : null;

    const actionInstanceOnHoldStatusSearchParameter = ActionInstanceOnHoldStatusOptions.filter(
        (x) => x.value === actionInstanceOnHoldStatus
    )[0];
    const raciRelationshipSearchParameter = RaciRelationshipOptions.filter(
        (x) => x.value === raciRelationship
    )[0];
    const actionInstanceStatus = ActionInstanceStatusOptions.filter(
        (x) => x.value === actionInstanceStatusId
    )[0];

    let actionInstanceStatusSearchParameter = allActionInstanceStatusOption;
    if (actionInstanceStatusId != ActionInstanceStatus.New) {
        actionInstanceStatusSearchParameter = createActionInstanceStatusDto(
            actionInstanceStatus.value,
            actionInstanceStatus.label
        );
    }

    const [searchParams, setSearchParams] = useState<SearchMyActionItemsDto>(
        urlPopulatedSearchMyActionItemsDto(
            actionInstanceStatusSearchParameter,
            actionInstanceOnHoldStatusSearchParameter,
            raciRelationshipSearchParameter
        )
    );
    const [filterDto, setFilterDto] = useState<FilterMyActionItemsDto>(
        urlPopulatedFilterMyActionItemsDto(
            actionInstanceStatusSearchParameter,
            actionInstanceOnHoldStatusSearchParameter,
            raciRelationshipSearchParameter,
            parsedActionItemInstanceIdSearchParameter
        )
    );
    const [paginationDto, setPaginationDto] = useState<BasePaginationDto>(defaultBasePaginationDto);

    const { t } = useTranslation("translation", { keyPrefix: ActionItems });

    const filterMyActionItems = useFilterMyActionItems(filterDto, paginationDto);
    const filterMyActionItemsResponseData = filterMyActionItems[0].data;
    const actionItemTypesResponseData = filterMyActionItems[1].data;
    const actionItemSubTypesResponseData = filterMyActionItems[2].data;
    const actionInstanceStatusResponseData = filterMyActionItems[3].data;

    useLoader(areQueriesLoading(filterMyActionItems), MyActionItemsContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.ActionTracker);
        menu.changeActiveDrawerItem(DrawerTitles.MyActionItems);
    }, []);

    const changeRaciRelationship = (
        option: SingleValue<ReactSelectDto<RaciRelationship>>
    ): void => {
        setSearchParams({
            ...searchParams,
            raciRelationship: option!,
        });
    };

    const changeActionInstanceOnHoldStatus = (
        option: SingleValue<ReactSelectDto<ActionInstanceOnHoldStatus>>
    ): void => {
        setSearchParams({
            ...searchParams,
            actionInstanceOnHoldStatus: option!,
        });
    };

    const changeActionItemName = (name: string): void => {
        setSearchParams({ ...searchParams, name: name ? name : null });
    };

    const changeActionTypeValue = (option: SingleValue<ActionItemTypeDto>): void => {
        setSearchParams({
            ...searchParams,
            actionItemTypeNodeId: option!.nodeId !== 0 ? option!.nodeId : null,
            actionItemType: option!,
        });
    };

    const changeActionInstanceStatus = (option: SingleValue<ActionInstanceStatusDto>): void => {
        setSearchParams({
            ...searchParams,
            actionInstanceStatusId:
                option!.actionInstanceStatusId !== 0 ? option!.actionInstanceStatusId : null,
            actionInstanceStatus: option!,
        });
    };

    const filterActionInstanceStatuses = (
        actionInstanceStatusResponseData: ActionInstanceStatusDto[] | undefined
    ): ActionInstanceStatusDto[] | [] => {
        if (actionInstanceStatusResponseData) {
            return actionInstanceStatusResponseData.filter(
                (actionInstanceStatus) =>
                    actionInstanceStatus.actionInstanceStatusId === ActionInstanceStatus.Pending ||
                    actionInstanceStatus.actionInstanceStatusId === ActionInstanceStatus.Open ||
                    actionInstanceStatus.actionInstanceStatusId === ActionInstanceStatus.Due ||
                    actionInstanceStatus.actionInstanceStatusId === ActionInstanceStatus.Overdue
            );
        }

        return [];
    };

    const changeActionSubTypeValue = (option: SingleValue<ActionItemTypeDto>): void => {
        setSearchParams({
            ...searchParams,
            actionItemSubTypeNodeId: option!.nodeId !== 0 ? option!.nodeId : null,
            actionItemSubType: option!,
        });
    };

    // TODO: Should rather be accepting a number type
    const changeActionItemId = (actionItemId: string): void => {
        setSearchParams({
            ...searchParams,
            actionItemId: getNullableString(actionItemId),
        });
    };

    const changeDueDateTo = (dueDateTo: Date): void => {
        setSearchParams({
            ...searchParams,
            dueDateTo: dueDateTo,
        });
    };

    const changeDueDateFrom = (dueDateFrom: Date): void => {
        setSearchParams({
            ...searchParams,
            dueDateFrom: dueDateFrom,
        });
    };

    const search = (): void => {
        setPaginationDto({
            ...paginationDto,
            pageNumber: 1,
        });
        setFilterDto({ ...filterDto, ...searchParams });
    };

    const resetFilter = (): void => {
        setSearchParams(emptySearchMyActionItemsDto());
        setFilterDto({ ...filterDto, ...emptySearchMyActionItemsDto() });
    };

    useKeyPress(EnterKey, search, searchParams);

    return (
        <>
            <PageHeading>{t("ListOfMyActionItems")}</PageHeading>
            <PageSubHeading>{t("HeaderHelpText")}</PageSubHeading>
            <SectionVerticalSpace />

            {areQueriesSuccessful(filterMyActionItems) && (
                <>
                    <MyActionItemsFilter
                        changeRaciRelationship={changeRaciRelationship}
                        raciRelationship={searchParams.raciRelationship}
                        changeActionInstanceOnHoldStatus={changeActionInstanceOnHoldStatus}
                        actionInstanceOnHoldStatus={searchParams.actionInstanceOnHoldStatus}
                        actionItemName={searchParams.name}
                        changeActionItemName={changeActionItemName}
                        actionItemType={searchParams.actionItemType}
                        actionItemTypes={[allActionItemTypeOption, ...actionItemTypesResponseData!]}
                        changeActionItemType={changeActionTypeValue}
                        actionItemSubType={searchParams.actionItemSubType}
                        actionItemSubTypes={[
                            allActionItemSubTypeOption,
                            ...actionItemSubTypesResponseData!,
                        ]}
                        changeActionItemSubType={changeActionSubTypeValue}
                        actionItemId={searchParams.actionItemId}
                        changeActionItemId={changeActionItemId}
                        actionInstanceStatus={searchParams.actionInstanceStatus}
                        actionInstanceStatuses={[
                            allActionInstanceStatusOption,
                            ...filterActionInstanceStatuses(actionInstanceStatusResponseData!),
                        ]}
                        changeActionInstanceStatus={changeActionInstanceStatus}
                        dueDateFrom={searchParams.dueDateFrom}
                        changeDueDateFrom={changeDueDateFrom}
                        dueDateTo={searchParams.dueDateTo}
                        changeDueDateTo={changeDueDateTo}
                        search={search}
                        resetFilter={resetFilter}
                    />
                    <LargeVerticalSpace />

                    <EndAlignedDiv>
                        <SbLink
                            variant="primary"
                            icon={FaFlag}
                            label={t("NewActionItem", { keyPrefix: Common })}
                            navigateTo={`${getPath(DrawerTitles.ActionItems)}/create`}
                        />
                        <SbLink
                            variant="primary"
                            icon={FaBook}
                            label={t("CreateNewActionPlan")}
                            navigateTo={`${getPath(DrawerTitles.ActionPlans)}/create`}
                        />
                        <SbLink
                            variant="primary"
                            icon={FaList}
                            label={t("MyCreatedActionItems")}
                            navigateTo={`${getPath(DrawerTitles.ActionItems)}`}
                        />
                        <SbLink
                            variant="primary"
                            icon={FaListOl}
                            label={t("MyCreatedActionPlans")}
                            navigateTo={`${getPath(DrawerTitles.ActionPlans)}`}
                        />
                    </EndAlignedDiv>
                    <LargeVerticalSpace />

                    <ActionItemsList
                        title={t("YourActiveActionItems")}
                        actionItems={filterMyActionItemsResponseData!.results}
                        recordCount={filterMyActionItemsResponseData!.recordCount}
                        paginationDto={paginationDto}
                        setPaginationDto={setPaginationDto}
                    />
                </>
            )}
        </>
    );
};

export default MyActionItemsContainer;
