import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ActionPlans, Common, Users } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import {
    useGetActionPlanDetails,
    useSoftDeleteActionPlan,
} from "../../../domain/viewmodels/actions/delete-action-plan-viewmodel";
import { DeleteButton } from "../../atoms/SbButton";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import {
    ActionConfirmationFragment,
    AlertProps,
    createErrorAlertProps,
} from "../../organisms/ActionConfirmationFragment";

const DeleteActionPlanContainer: React.FC = () => {
    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ActionPlans });

    const actionPlanId = Number(useParams().actionPlanId);

    const getActionPlanDetails = useGetActionPlanDetails(actionPlanId);
    const softDeleteActionPlan = useSoftDeleteActionPlan();

    const actionPlanDetailsResponseData = getActionPlanDetails.data;

    useLoader(
        isQueryLoading(getActionPlanDetails) || isMutationLoading(softDeleteActionPlan),
        DeleteActionPlanContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.ActionTracker);
        menu.changeActiveDrawerItem(DrawerTitles.ActionPlans);
    }, []);

    const deleteActionPlan = (actionPlanId: number): void => {
        softDeleteActionPlan.mutate(actionPlanId, {
            onSuccess: async () => {
                const params = [
                    createNavigateSearchParameter("success", "true"),
                    createNavigateSearchParameter("messageKey", "DeleteActionPlanSuccessMessage"),
                ];

                navigateSearch(`${getPath(DrawerTitles.ActionPlans)}`, params);
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    const buildValidationAlert = (): AlertProps | null => {
        if (!actionPlanDetailsResponseData || actionPlanDetailsResponseData.actionItemCount === 0) {
            return null;
        }

        return createErrorAlertProps([t("CannotDeleteActionPlan")]);
    };

    const buildDeleteButton = (): ReactElement<typeof Button> => (
        <DeleteButton
            disabled={
                isQuerySuccessful(getActionPlanDetails) &&
                actionPlanDetailsResponseData!.actionItemCount > 0
            }
            onClick={() => deleteActionPlan(actionPlanId)}
        />
    );

    const buildConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow(
            t("Name", { keyPrefix: Common }),
            actionPlanDetailsResponseData?.name
        ),
        new TextConfirmationRow(
            t("Description", { keyPrefix: Common }),
            actionPlanDetailsResponseData?.description
        ),
        new TextConfirmationRow(
            t("CreatedByUserEmail", { keyPrefix: Users }),
            actionPlanDetailsResponseData?.createdByUserName
        ),
        new TextConfirmationRow(
            t("DateCreated", { keyPrefix: Common }),
            actionPlanDetailsResponseData?.dateCreatedLocal
        ),
        new TextConfirmationRow(
            t("ModifiedByUserEmail", { keyPrefix: Users }),
            actionPlanDetailsResponseData?.modifiedByUserName
        ),
        new TextConfirmationRow(
            t("DateLastModified", { keyPrefix: Common }),
            actionPlanDetailsResponseData?.dateModifiedLocal
        ),
    ];

    return (
        <ActionConfirmationFragment
            pageHeading={t("ActionPlanDelete")}
            pageSubHeading={`${t("HeaderHelpTextActionPlanDelete")}`}
            panelTitle={t("DeleteActionPlanConfirmation")}
            rows={buildConfirmationRows()}
            primaryButton={buildDeleteButton()}
            alert={buildValidationAlert()}
            areQueriesSuccessful={isQuerySuccessful(getActionPlanDetails)}
        />
    );
};

export default DeleteActionPlanContainer;
