import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { NotificationStatuses } from "../../../core/utilities/enums";
import { WizardNotificationHistoryResponse } from "../../responses/wizards/wizard-notification-history-response";
import { toWizardCardDto, WizardCardDto } from "./wizard-card-dto";

export interface WizardNotificationHistoryDto {
    wizardId: number;
    wizardName: string;
    dateCreatedLocal: string;
    notificationStatus: NotificationStatuses;
    wizardCards: WizardCardDto[];
}

export const toWizardNotificationHistoryDto = (
    wizardNotificationHistoryResponse: WizardNotificationHistoryResponse
): WizardNotificationHistoryDto => ({
    wizardId: wizardNotificationHistoryResponse.wizardId,
    wizardName: wizardNotificationHistoryResponse.wizardName,
    dateCreatedLocal: toDateAndTimeFormat(
        wizardNotificationHistoryResponse.dateCreatedUtc.toString()
    ),
    notificationStatus: wizardNotificationHistoryResponse.notificationStatus,
    wizardCards: wizardNotificationHistoryResponse.wizardCards.map((wizardCard) =>
        toWizardCardDto(wizardCard)
    ),
});
