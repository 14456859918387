import { useState } from "react";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import {
    EndAlignedDiv,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import { defaultPaginationDto, PaginationDto } from "../../../domain/dtos/common/pagination-dto";
import { useGetWizardNotificationHistory } from "../../../domain/viewmodels/wizards/view-wizard-notification-history-viewmodel";
import Paginator from "../../atoms/Paginator";
import { WizardNotificationHistoryGallery } from "../../organisms/WizardNotificationHistoryGallery";

const WizardNotificationHistoryContainer: React.FC = () => {
    const auth = useAuth();

    const [paginationDto, setPaginationDto] = useState<PaginationDto>(defaultPaginationDto);

    const getWizardNotificationHistory = useGetWizardNotificationHistory(paginationDto, {
        userId: auth.userId!,
    });

    useLoader(isQueryLoading(getWizardNotificationHistory), WizardNotificationHistoryContainer);

    return (
        <>
            <PageHeading>History of Updates</PageHeading>
            <PageSubHeading>
                Click on any tile to view the details of the updates released on Control First. A
                red icon indicates that you have not yet viewed the release update.
            </PageSubHeading>
            <SectionVerticalSpace />
            {isQuerySuccessful(getWizardNotificationHistory) && (
                <>
                    <WizardNotificationHistoryGallery
                        wizardNotifications={getWizardNotificationHistory.data!.results}
                    />
                    <EndAlignedDiv>
                        <Paginator
                            totalItems={getWizardNotificationHistory.data!.recordCount}
                            paginationDto={paginationDto}
                            setPaginationProps={setPaginationDto}
                        />
                    </EndAlignedDiv>
                </>
            )}
        </>
    );
};

export default WizardNotificationHistoryContainer;
