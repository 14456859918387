import { AssociatedRiskGroupDto } from "../associated-risks/associated-risk-group-dto";
import { EditAgreedActionDto } from "./edit-agreed-action-dto";
import { EditReviewTypeFieldValueDto } from "./edit-review-type-field-value-dto";

export class EditReviewReportDetailsDto {
    questionSetInstanceId: number;
    questionSetInstanceAnswerId: number;
    questionSetInstanceReviewId: number;
    commentText: string;
    editReviewTypeFieldValues: EditReviewTypeFieldValueDto[];
    editAgreedActions: EditAgreedActionDto[];
    associatedRiskGroups: AssociatedRiskGroupDto[];

    public constructor(
        questionSetInstanceId: number,
        questionSetInstanceAnswerId: number,
        questionSetInstanceReviewId: number,
        commentText: string,
        editAgreedActionDtos: EditAgreedActionDto[],
        editReviewTypeFieldValueDtos: EditReviewTypeFieldValueDto[],
        associatedRiskGroupDtos: AssociatedRiskGroupDto[]
    ) {
        this.questionSetInstanceId = questionSetInstanceId;
        this.questionSetInstanceAnswerId = questionSetInstanceAnswerId;
        this.questionSetInstanceReviewId = questionSetInstanceReviewId;
        this.commentText = commentText;
        this.editAgreedActions = editAgreedActionDtos;
        this.editReviewTypeFieldValues = editReviewTypeFieldValueDtos;
        this.associatedRiskGroups = associatedRiskGroupDtos;
    }
}
