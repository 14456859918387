import { EventInput } from "@fullcalendar/core";
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { DefaultTheme } from "styled-components";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useAuth } from "../../../core/store/auth-context";
import { useUrl } from "../../../core/store/url-context";
import { filterActionItemEvents } from "../../../data/services/action-items/action-item-events-service";
import { createActionItemInstanceComment } from "../../../data/services/actions/action-items-service";
import ActionItemEventsDto from "../../dtos/action-items/action-item-events-dto";
import { ActionItemInstanceCommentDto } from "../../dtos/action-items/action-item-instance-comment-dto";
import BaseActionItemEventDto from "../../dtos/action-items/base-action-item-event-dto";
import FilterActionItemEventsDto from "../../dtos/action-items/filter-action-item-events-dto";
import { ActionInstanceStatus } from "../../enums/action-items/ActionInstanceStatus";
import { ActionItemInstanceCommentRequest } from "../../requests/action-items/action-item-instance-comment-request";
import FilterActionItemEventsRequest from "../../requests/action-items/filter-action-item-events-request";
import { ActionItemEventsResponse } from "../../responses/action-items/action-item-events-response";
import { Response } from "../../responses/common/response-response";

export const useFilterActionItemEvents = (
    dto?: FilterActionItemEventsDto
): UseQueryResult<BaseActionItemEventDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const auth = useAuth();

    // If admin is impersonating, use the admin url
    const urlToUse = auth.isImpersonating ? url.baseUrl : url.originalBaseUrl;

    return useQuery(
        ["filterActionItemEvents", dto],
        () =>
            filterActionItemEvents(urlToUse, FilterActionItemEventsRequest.constructFromDto(dto!)),
        {
            enabled: dto !== undefined,
            select: toBaseActionItemEventDto,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useCreateActionItemInstanceComment = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    ActionItemInstanceCommentDto
> => {
    const url = useUrl();

    return useMutation((dto: ActionItemInstanceCommentDto) =>
        createActionItemInstanceComment(url.baseUrl, new ActionItemInstanceCommentRequest(dto))
    );
};

export const transformToEventInput = (
    eventInput: EventInput,
    themeContext: DefaultTheme
): EventInput => {
    const isActionItemInstanceEvent = eventInput.urgency !== undefined;
    eventInput.isActionItemInstanceEvent = isActionItemInstanceEvent;

    if (isActionItemInstanceEvent) {
        eventInput.id = eventInput.actionItemInstanceId;
        eventInput.urgency = eventInput.urgency;
    } else {
        eventInput.id = eventInput.questionSetActionId;
    }

    eventInput.title = eventInput.title;
    eventInput.start = eventInput.startDateUtc;
    eventInput.end = eventInput.endDateUtc;
    eventInput.startDateLocalString = eventInput.startDateLocalString;
    eventInput.endDateLocalString = eventInput.endDateLocalString;

    const actionInstanceStatus = eventInput.status as ActionInstanceStatus;
    eventInput.status = actionInstanceStatus;
    eventInput.color = getEventColour(actionInstanceStatus, themeContext);

    eventInput.statusString = eventInput.statusString;
    eventInput.type = eventInput.type;
    eventInput.raciParticipation = eventInput.raciParticipation;
    eventInput.raciParticipationShort = eventInput.raciParticipationShort;

    return eventInput;
};

export const getEventColour = (
    status: ActionInstanceStatus,
    themeContext: DefaultTheme
): string => {
    switch (status) {
        case ActionInstanceStatus.Pending:
            return themeContext!.palette.secondary;
        case ActionInstanceStatus.OnHold:
            return themeContext!.palette.secondaryVariant;
        case ActionInstanceStatus.Overdue:
            return themeContext!.palette.danger;
        default:
            return themeContext!.palette.primary;
    }
};

const toBaseActionItemEventDto = (
    response: Response<ActionItemEventsResponse>
): BaseActionItemEventDto[] => {
    const dto = new ActionItemEventsDto(response.data);

    return [...dto.actionItemInstanceEventDtos, ...dto.questionSetActionEventDtos];
};
