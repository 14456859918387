import { FilterAuditsChangeLogDto } from "../../dtos/audits/filter-audits-change-log-dto";
import {
    buildPaginationSearchParameters,
    createPaginationRequest,
    PaginationRequest,
} from "../common/pagination-request";

export interface FilterAuditsChangeLogRequest extends PaginationRequest {
    tableName: string | null;
    username: string | null;
    startDate: string | null;
    endDate: string | null;
    actionType: string | null;
    entityKey: string | null;
}

export const createFilterAuditsChangeLogRequestFromDto = (
    dto: FilterAuditsChangeLogDto
): FilterAuditsChangeLogRequest => {
    const { pageNumber, pageSize, sortByColumn, sortByDescending } = dto;

    return {
        ...dto,
        ...createPaginationRequest(pageNumber, pageSize, sortByColumn, sortByDescending),
    };
};

export const buildFilterAuditsChangeLogSearchParameters = (
    request: FilterAuditsChangeLogRequest
): URLSearchParams => {
    const searchParams = buildPaginationSearchParameters(request);

    if (request.tableName) {
        searchParams.set("tableName", request.tableName);
    }

    if (request.username) {
        searchParams.set("username", request.username);
    }

    if (request.startDate) {
        searchParams.set("startDate", request.startDate);
    }

    if (request.endDate) {
        searchParams.set("endDate", request.endDate);
    }

    if (request.actionType) {
        searchParams.set("actionType", request.actionType);
    }

    if (request.entityKey) {
        searchParams.set("entityKey", request.entityKey);
    }

    return searchParams;
};
