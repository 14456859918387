import { appInsights } from "../application-insights/application-insights-service";

export const getCommonTrackingProperties = (userEmail: string, currentUrl: string) => {
    return {
        currentUrl,
        userEmail,
    };
};

export const trackAppInsightsEvent = (
    userEmail: string,
    currentUrl: string,
    eventName: string,
): void => {
    appInsights.trackEvent(
        { name: eventName },
        {
            ...getCommonTrackingProperties(userEmail, currentUrl),
        }
    );
};

export const trackAppInsightsException = (
    userEmail: string,
    currentUrl: string,
    eventName: string,
    error: Error
): void => {
    appInsights.trackException(
        { exception: new Error(error.message) },
        {
            name: eventName,
            ...getCommonTrackingProperties(userEmail, currentUrl),
        }
    );
};
