import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { getSecrets } from "../../../data/services/secrets/secrets-service";
import { createSecretsDtoFromResponse, SecretsDto } from "../../dtos/secrets/secrets-dto";
import { ControlFirstTenantIdentifier } from "../../enums/common/TenantIdentifier";

export const useSecrets = (
    identifier: ControlFirstTenantIdentifier
): UseQueryResult<SecretsDto, HTTPError> => {
    // const url = useUrl(); // TODO: This is empty string when component gets mounted, not sure how to mitigate it, but REACT_APP_API_URL_ADMIN will do for now
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getSecrets", identifier],
        () => getSecrets(process.env.REACT_APP_API_URL_ADMIN!, identifier),
        {
            select: createSecretsDtoFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};
