import { useQueries, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { TableRow } from "../../../core/utilities/customTypes";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import { PeriodTypeOptions } from "../../../core/utilities/QuestionSetValues";
import { getAllAggregationTypes } from "../../../data/services/aggregation-types/aggregation-types-service";
import { getAllAnswerThresholdOperators } from "../../../data/services/answer-threshold-operators/answer-threshold-operators-service";
import { lookupQuestionCategories } from "../../../data/services/lookup/lookup-service";
import { getAllQuestionImportance } from "../../../data/services/question-importance/question-importance-service";
import {
    filterAssociatedQuestions,
    getAssociatedReferentialLinkTypes,
    getQuestionSetDetails,
} from "../../../data/services/question-set/question-set-service";
import { getAllQuestionTypes } from "../../../data/services/question-types/question-types-service";
import { filterQuestions } from "../../../data/services/questions/question-service";
import {
    AggregationTypeDto,
    toAggregationTypeDto,
} from "../../dtos/aggregation-type/aggregation-type-dto";
import {
    AnswerThresholdOperatorsDto,
    toAnswerThresholdOperatorsDto,
} from "../../dtos/answer-threshold-operators/answer-threshold-operators-dto";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { SearchDto } from "../../dtos/common/search-dto";
import {
    QuestionCategoryDto,
    toQuestionCategoryDtos,
} from "../../dtos/question-categories/question-category-dto";
import {
    QuestionImportanceDto,
    toQuestionImportanceDto,
} from "../../dtos/question-importance/question-importance-dto";
import { FilterQuestionSetDto } from "../../dtos/question-sets/filter-question-set-dto";
import {
    createQuestionSetDetailsDto,
    QuestionSetDetailsDto,
} from "../../dtos/question-sets/question-set-details-dto";
import { QuestionTypeDto, toQuestionTypeDto } from "../../dtos/question-type/question-type-dto";
import { FilterQuestionsDto } from "../../dtos/questions/filter-questions-dto";
import { PeriodType } from "../../enums/PeriodType";
import { createSearchRequestFromDto } from "../../requests/common/search-request";
import { createFilterQuestionSetRequestFromDto } from "../../requests/question-set/filter-question-set-request";
import { createFilterQuestionsRequestFromDto } from "../../requests/questions/filter-questions-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { BaseQuestionSetReferentialLinkTypeResponse } from "../../responses/question-sets/base-question-set-referential-link-type-response";
import { QuestionSetQuestionResponse } from "../../responses/question-sets/question-set-question-response";
import { NestedQuestionResponse } from "../../responses/questions/nested-question-response";
import { QuestionResponse } from "../../responses/questions/question-response";
import { BaseReferentialLinkTypeReferentialLinkResponse } from "../../responses/referential-links/base-referential-link-type-referential-link-response";

export const useGetQuestionSetDetails = (
    questionSetId: number,
    questionSetQuestionsPaginationDto: FilterQuestionSetDto,
    referentialLinksPaginationDto: FilterQuestionSetDto
): [
    UseQueryResult<QuestionSetDetailsDto, HTTPError>,
    UseQueryResult<PaginatedTableDto<number>, HTTPError>,
    UseQueryResult<PaginatedTableDto<number>, HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getQuestionSetDetails", questionSetId],
                queryFn: () => getQuestionSetDetails(url.baseUrl, questionSetId),
                select: createQuestionSetDetailsDto,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: [
                    "getQuestionSetQuestions",
                    questionSetQuestionsPaginationDto,
                    questionSetId,
                ],
                queryFn: () =>
                    filterAssociatedQuestions(
                        url.baseUrl,
                        createFilterQuestionSetRequestFromDto({
                            ...questionSetQuestionsPaginationDto,
                            questionSetId,
                        })
                    ),
                select: transformToQuestionSetQuestionsDataTableRows,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: [
                    "getAssociatedReferentialLinkTypes",
                    referentialLinksPaginationDto,
                    questionSetId,
                ],
                queryFn: () =>
                    getAssociatedReferentialLinkTypes(
                        url.baseUrl,
                        createFilterQuestionSetRequestFromDto({
                            ...referentialLinksPaginationDto,
                            questionSetId,
                        })
                    ),
                keepPreviousData: true,
                select: transformToReferentialLinkTypesDataTableRows,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<QuestionSetDetailsDto, HTTPError>,
        UseQueryResult<PaginatedTableDto<number>, HTTPError>,
        UseQueryResult<PaginatedTableDto<number>, HTTPError>,
    ];
};

export const useFilterQuestions = (
    filterQuestionsDto: FilterQuestionsDto
): UseQueryResult<PaginatedTableDto<number>, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterQuestions", filterQuestionsDto],
        () => filterQuestions(url.baseUrl, createFilterQuestionsRequestFromDto(filterQuestionsDto)),
        {
            select: transformToExistingQuestionsDataTableRows,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useGetQuestionDropdownValues = (): [
    UseQueryResult<QuestionTypeDto[], HTTPError>,
    UseQueryResult<QuestionImportanceDto[], HTTPError>,
    UseQueryResult<AggregationTypeDto[], HTTPError>,
    UseQueryResult<AnswerThresholdOperatorsDto[], HTTPError>,
] => {
    const url = useUrl();

    const queries = useQueries({
        queries: [
            {
                queryKey: ["getAllQuestionTypes"],
                queryFn: () => getAllQuestionTypes(url.baseUrl),
                select: toQuestionTypeDto,
            },
            {
                queryKey: ["getAllQuestionImportance"],
                queryFn: () => getAllQuestionImportance(url.baseUrl),
                select: toQuestionImportanceDto,
            },
            {
                queryKey: ["getAllAggregationTypes"],
                queryFn: () => getAllAggregationTypes(url.baseUrl),
                select: toAggregationTypeDto,
            },
            {
                queryKey: ["getAllAnswerThresholdOperators"],
                queryFn: () => getAllAnswerThresholdOperators(url.baseUrl),
                select: toAnswerThresholdOperatorsDto,
            },
        ],
    });

    return queries as [
        UseQueryResult<QuestionTypeDto[], HTTPError>,
        UseQueryResult<QuestionImportanceDto[], HTTPError>,
        UseQueryResult<AggregationTypeDto[], HTTPError>,
        UseQueryResult<AnswerThresholdOperatorsDto[], HTTPError>,
    ];
};

const transformToReferentialLinkTypesDataTableRows = (
    response: Response<PaginationResponse<BaseQuestionSetReferentialLinkTypeResponse>>
): PaginatedTableDto<number> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    let rows: TableRow<number>[] = [];
    const results = responseData.results;

    results.forEach((result: BaseQuestionSetReferentialLinkTypeResponse) => {
        rows.push(
            result.referentialLinkTypeReferentialLinkResponses.map(
                (res: BaseReferentialLinkTypeReferentialLinkResponse) => ({
                    metadata: res.referentialLinkTypeResponse.referentialLinkTypeId,
                    columns: [
                        {
                            value: res.referentialLinkTypeResponse.name,
                            type: DataTableColumnTypes.Text,
                        },
                    ],
                })
            )[0]
        );
    });

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};

const transformToQuestionSetQuestionsDataTableRows = (
    response: Response<PaginationResponse<QuestionSetQuestionResponse>>
): PaginatedTableDto<number> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((questionSetQuestion) => ({
        metadata: questionSetQuestion.questionId,
        nestedRows:
            questionSetQuestion.childQuestionResponses.length > 0
                ? transformToNestedQuestionsRows(questionSetQuestion.childQuestionResponses)
                : undefined,
        columns: [
            {
                // Order Index
                value: questionSetQuestion.orderIndex.toString(),
                type: DataTableColumnTypes.Text,
                metadata: "OrderIndex",
            },
            {
                // ID
                value: questionSetQuestion.questionId.toString(),
                type: DataTableColumnTypes.Link,
                metadata: "ID",
            },
            {
                // Answer Type
                value: questionSetQuestion.answerTypeResponse.name,
                type: DataTableColumnTypes.Text,
                metadata: "AnswerType",
            },
            {
                // Questions Text
                value: questionSetQuestion.questionText.toString(),
                type: DataTableColumnTypes.Text,
                metadata: "QuestionText",
            },
            {
                // Open before due
                value:
                    questionSetQuestion.openIntervalBeforeDueTime &&
                    `${questionSetQuestion.openIntervalBeforeDueTime.value.toString()}`,
                type: DataTableColumnTypes.PeriodInput,
                periodTypeValue: `${
                    PeriodTypeOptions.find(
                        (x) => x.value === questionSetQuestion.openIntervalBeforeDueTime.periodType
                    )?.label
                }`,
                metadata: "OpenBeforeDue",
            },
            {
                // Due by time
                value: questionSetQuestion.requiredByTime
                    ? questionSetQuestion.requiredByTime.toString()
                    : "",
                type: DataTableColumnTypes.TimeInput,
                metadata: "DueByTime",
            },
            {
                // Escalation L1 Time
                value:
                    questionSetQuestion.escalationL1IntervalAfterDueTime &&
                    `${questionSetQuestion.escalationL1IntervalAfterDueTime.value
                        .toString()
                        .trim()}`,
                periodTypeValue:
                    questionSetQuestion.escalationL1IntervalAfterDueTime &&
                    `${
                        PeriodTypeOptions.find(
                            (x) =>
                                x.value ===
                                questionSetQuestion.escalationL1IntervalAfterDueTime.periodType
                        )?.label
                    }`,
                type: DataTableColumnTypes.PeriodInput,
                metadata: "EL1",
            },
            {
                // Escalation L2 Time
                value:
                    questionSetQuestion.escalationL2IntervalAfterEscalationL1 &&
                    `${questionSetQuestion.escalationL2IntervalAfterEscalationL1.value
                        .toString()
                        .trim()}`,
                periodTypeValue:
                    questionSetQuestion.escalationL2IntervalAfterEscalationL1 &&
                    `${
                        PeriodTypeOptions.find(
                            (x) =>
                                x.value ===
                                questionSetQuestion.escalationL2IntervalAfterEscalationL1.periodType
                        )?.label
                    }`,
                type: DataTableColumnTypes.PeriodInput,
                metadata: "EL2",
            },
            {
                // Escalation L3 Time
                value:
                    questionSetQuestion.escalationL3IntervalAfterEscalationL2 &&
                    `${questionSetQuestion.escalationL3IntervalAfterEscalationL2.value.toString()}`,
                periodTypeValue:
                    questionSetQuestion.escalationL3IntervalAfterEscalationL2 &&
                    `${
                        PeriodTypeOptions.find(
                            (x) =>
                                x.value ===
                                questionSetQuestion.escalationL3IntervalAfterEscalationL2.periodType
                        )?.label
                    }`,
                type: DataTableColumnTypes.PeriodInput,
                metadata: "EL3",
            },
            {
                // Nested questions
                value: questionSetQuestion.childQuestionResponses.length > 0 ? "Show/Hide" : "None",
                type: DataTableColumnTypes.DisplayNestedTableButton,
                metadata: "NestedQuestions",
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};

const transformToExistingQuestionsDataTableRows = (
    response: Response<PaginationResponse<QuestionResponse>>
): PaginatedTableDto<number> => {
    const rows = response.data!.results.map((question) => ({
        metadata: question.questionId,
        nestedRows:
            question.childQuestionResponses && question.childQuestionResponses.length > 0
                ? transformToNestedQuestionsRows(question.childQuestionResponses)
                : undefined,
        columns: [
            {
                // ID
                value: question.questionId.toString(),
                type: DataTableColumnTypes.Link,
                metadata: "ID",
            },
            {
                // Questions Text
                value: question.questionText.text.toString(),
                type: DataTableColumnTypes.Text,
                metadata: "QuestionText",
            },
            {
                // Questions Type
                value: question.questionType.name.toString(),
                type: DataTableColumnTypes.Text,
                metadata: "QuestionType",
            },
            {
                // Question Category
                value: question.questionCategory.name.toString(),
                type: DataTableColumnTypes.Text,
                metadata: "QuestionCategory",
            },
            {
                // Answer Type
                value: question.answerTypeResponse.name,
                type: DataTableColumnTypes.Text,
                metadata: "AnswerType",
            },
            {
                // Open before due
                value: "1",
                type: DataTableColumnTypes.PeriodInput,
                periodTypeValue: `${
                    PeriodTypeOptions.find((x) => x.value === PeriodType.Hours)?.label
                }`,
                metadata: "OpenBeforeDue",
            },
            {
                // Due by time
                value: "01:00",
                type: DataTableColumnTypes.TimeInput,
                metadata: "DueByTime",
            },
            {
                // Escalation L1 Time
                value: "1",
                type: DataTableColumnTypes.PeriodInput,
                periodTypeValue: `${
                    PeriodTypeOptions.find((x) => x.value === PeriodType.Hours)?.label
                }`,
                metadata: "EL1",
            },
            {
                // Nested questions
                value:
                    question.childQuestionResponses && question.childQuestionResponses.length > 0
                        ? "Show/Hide"
                        : "None",
                type: DataTableColumnTypes.DisplayNestedTableButton,
                metadata: "NestedQuestions",
            },
        ],
    }));

    return {
        numberOfPages: response.data.pageCount,
        pageSize: response.data.pageSize,
        currentPage: response.data.currentPage,
        recordCount: response.data.recordCount,
        rows: rows,
    };
};

const transformToNestedQuestionsRows = (
    nestedQuestions: NestedQuestionResponse[]
): TableRow<number>[] => {
    const rows = nestedQuestions.map((nestedQuestion) => ({
        metadata: nestedQuestion.nestedQuestionId,
        columns: [
            {
                // Order Index
                value: nestedQuestion.orderIndex?.toString(),
                type: DataTableColumnTypes.Text,
                metadata: "OrderIndex",
            },
            {
                // ID
                value: nestedQuestion.questionId.toString(),
                type: DataTableColumnTypes.Link,
                metadata: "ID",
            },
            {
                // Question Text
                value: nestedQuestion.questionText?.text,
                type: DataTableColumnTypes.Text,
                metadata: "QuestionText",
            },
            {
                // Category
                value: nestedQuestion.questionCategory?.name,
                type: DataTableColumnTypes.Text,
                metadata: "Category",
            },
            {
                // Type
                value: nestedQuestion.questionType?.name,
                type: DataTableColumnTypes.Text,
                metadata: "Type",
            },
            {
                // Answer Type
                value: nestedQuestion.answerTypeResponse?.name,
                type: DataTableColumnTypes.Text,
                metadata: "AnswerType",
            },
            {
                // Render Mode
                value: nestedQuestion.renderInline ? "Inline" : "Default",
                type: DataTableColumnTypes.Text,
                metadata: "RenderMode",
            },
            {
                // Parent Question Answer Trigger
                value:
                    nestedQuestion.parentQuestionAnswerTrigger?.toString() === ""
                        ? "No"
                        : nestedQuestion.parentQuestionAnswerTrigger?.toString(),
                type: DataTableColumnTypes.Text,
                metadata: "ParentQuestionAnswerTrigger",
            },
        ],
    }));

    return rows;
};

export const useLookupQuestionCategories = (
    searchDto: SearchDto
): UseQueryResult<QuestionCategoryDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["lookupQuestionCategories", searchDto],
        () => lookupQuestionCategories(url.baseUrl, createSearchRequestFromDto(searchDto)),
        {
            select: toQuestionCategoryDtos,
            onError: errorResponseToDisplayHandler,
        }
    );
};
