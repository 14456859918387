export const auditDropdownItems: string[] = ["Insert", "Update", "Delete"];

export const dataDropdownItems: string[] = ["All", "Live", "Training"];

export const bitDropdownItems: string[] = ["True", "False"];

export const actionItemDynamicFieldValueTypeBooleanDropdownItems: string[] = [
    "Not Set",
    "True",
    "False",
];
