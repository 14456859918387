import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { filterNodeTypeHierarchy } from "../../../data/services/hierarchy/node-types-service";
import { createFilterNodeTypeHierarchyRequest } from "../../requests/hierarchy/filter-node-type-hierarchy-request";
import { Response } from "../../responses/common/response-response";
import {
    NodeHierarchyDescendantResponse,
    NodeHierarchyResponse,
} from "../../responses/hierarchy/node-hierarchy-response";

export const useFilterNodeTypeHierarchy = (
    hierarchyTypeId: number | null
): UseQueryResult<NodeHierarchyDescendantResponse, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterNodeTypeHierarchy", hierarchyTypeId],
        () =>
            filterNodeTypeHierarchy(
                url.baseUrl,
                createFilterNodeTypeHierarchyRequest(hierarchyTypeId!)
            ),
        {
            enabled: hierarchyTypeId != null,
            select: toNodeHierarchyDescendantResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const toNodeHierarchyDescendantResponse = (
    response: Response<NodeHierarchyResponse>
): NodeHierarchyDescendantResponse => response.data.tree;
