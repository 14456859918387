import { Response } from "../../responses/common/response-response";
import { FeatureResponse } from "../../responses/tenants/feature-response";

export interface FeatureDto {
    featureId: number;
    name: string;
}

export const toFeatureDto = (feature: FeatureResponse): FeatureDto => ({
    featureId: feature.featureId,
    name: feature.name,
});

export const toFeatureDtos = (features: FeatureResponse[]): FeatureDto[] =>
    features.map((x) => toFeatureDto(x));

export const transformToFeatureDtos = (response: Response<FeatureResponse[]>): FeatureDto[] =>
    toFeatureDtos(response.data);
