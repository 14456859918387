import ReviewRating from "../../enums/review-reports/review-ratings";
import { EditReviewReportSetTypeFieldsValueDto } from "./edit-review-report-set-type-field-value-dto";

export class EditReviewReportSetDto {
    editReviewReportSetFieldsValueDtos: EditReviewReportSetTypeFieldsValueDto[];
    reviewReportSetId: number;
    reviewRating: ReviewRating;

    public constructor(
        editReviewReportSetFieldsValueDtos: EditReviewReportSetTypeFieldsValueDto[],
        reviewReportSetId: number,
        reviewRating: ReviewRating
    ) {
        this.editReviewReportSetFieldsValueDtos = editReviewReportSetFieldsValueDtos;
        this.reviewReportSetId = reviewReportSetId;
        this.reviewRating = reviewRating;
    }
}
