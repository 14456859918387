import { ReactSelectDto } from "../../domain/dtos/common/react-select-dto";

export enum Feature {
    CODAC,
    ActionTracker,
    RMPLink,
}

export const FeatureOptions: ReactSelectDto<Feature>[] = [
    { label: "CheckList", value: Feature.CODAC },
    { label: "ActionTracker", value: Feature.ActionTracker },
];
