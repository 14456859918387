import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
    createBasePaginationRequest,
} from "../common/base-pagination-request";

export interface FilterQuestionSetInstanceAnswerFileRequest extends BasePaginationRequest {
    questionSetInstanceAnswerId: number;
}

export const createFilterQuestionSetInstanceAnswerFileRequest = (
    questionSetInstanceAnswerId: number,
    paginationDto: BasePaginationDto
): FilterQuestionSetInstanceAnswerFileRequest => ({
    questionSetInstanceAnswerId: questionSetInstanceAnswerId,
    ...createBasePaginationRequest(paginationDto.pageNumber, paginationDto.pageSize),
});

export const buildFilterSearchParameters = (
    request: FilterQuestionSetInstanceAnswerFileRequest
): URLSearchParams => {
    const searchParams = buildBasePaginationSearchParameters(request);

    searchParams.set("questionSetInstanceAnswerId", request.questionSetInstanceAnswerId.toString());

    return searchParams;
};
