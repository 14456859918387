import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import { ChecklistQuestions, Common, Users } from "../../../core/constants/translation-namespace";
import {
    ContentContainer,
    EndAlignedDiv,
    SectionContainer,
} from "../../../core/theme/global-styles";
import { ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import DataStatus from "../../../domain/enums/common/DataStatus";
import { ResetButton, SearchButton } from "../../atoms/SbButton";
import { FilterInput } from "../../molecules/FilterInput";
import { FilterSelect } from "../../molecules/FilterSelect";

export const ChecklistsFilter: React.FC<{
    checklistName: string | null;
    changeChecklistName: (checklistName: string) => void;
    modifiedByUser: string | null;
    changeModifiedByUser: (modifiedByUser: string) => void;
    dataStatus: ReactSelectDto<DataStatus>;
    dataStatuses: ReactSelectDto<DataStatus>[];
    changeDataStatus: (option: SingleValue<ReactSelectDto<DataStatus>>) => void;
    search: () => void;
    resetFilter: () => void;
}> = ({
    checklistName,
    changeChecklistName,
    modifiedByUser,
    changeModifiedByUser,
    dataStatus,
    dataStatuses,
    changeDataStatus: changeDataStatus,
    search,
    resetFilter,
}) => {
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const dataLabel = (): string => {
        return t("Data");
    };
    return (
        <ContentContainer>
            <SectionContainer>
                <Row>
                    <Col>
                        <FilterInput
                            title={t("ChecklistName")}
                            name={"checklistName"}
                            type={"text"}
                            onChangeEventHandler={changeChecklistName}
                            value={checklistName ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("ModifiedByUsername", { keyPrefix: Users })}
                            name={"modifiedByUser"}
                            type={"text"}
                            onChangeEventHandler={changeModifiedByUser}
                            value={modifiedByUser ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterSelect
                            name={"data"}
                            label={dataLabel()}
                            placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                            searchable
                            clearable
                            items={dataStatuses ?? []}
                            itemDisplayText={(option: ReactSelectDto<DataStatus>) =>
                                t(option.label, { keyPrefix: Common })
                            }
                            onChange={changeDataStatus}
                            value={dataStatus.value != null ? dataStatus : null}
                        />
                    </Col>
                </Row>
            </SectionContainer>
            <EndAlignedDiv>
                <SearchButton onClick={search} />
                <ResetButton onClick={resetFilter} />
            </EndAlignedDiv>
        </ContentContainer>
    );
};
