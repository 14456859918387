import { NotificationTriggersPeriodType } from "../../enums/action-items/NotificationTriggersPeriodType";

export class CreateActionItemCustomNotificationDto {
    firstPreDueDateNotificationValue: number;
    firstNotificationPeriodType: NotificationTriggersPeriodType;
    secondPreDueDateNotificationValue: number;
    secondNotificationPeriodType: NotificationTriggersPeriodType;
    thirdPreDueDateNotificationValue: number;
    thirdNotificationPeriodType: NotificationTriggersPeriodType;
    firstPostDueDateNotificationValue: number;
    fourthNotificationPeriodType: NotificationTriggersPeriodType;
    secondPostDueDateNotificationValue: number;
    fifthNotificationPeriodType: NotificationTriggersPeriodType;
    thirdPostDueDateNotificationValue: number;
    sixthNotificationPeriodType: NotificationTriggersPeriodType;

    public constructor(formData: FormData) {
        this.firstPreDueDateNotificationValue = Number(
            formData.get("firstPreDueDateNotificationValue") as string
        );
        this.firstNotificationPeriodType = Number(
            formData.get("firstNotificationPeriodType") as string
        );
        this.secondPreDueDateNotificationValue = Number(
            formData.get("secondPreDueDateNotificationValue") as string
        );
        this.secondNotificationPeriodType = Number(
            formData.get("secondNotificationPeriodType") as string
        );
        this.thirdPreDueDateNotificationValue = Number(
            formData.get("thirdPreDueDateNotificationValue") as string
        );
        this.thirdNotificationPeriodType = Number(
            formData.get("thirdNotificationPeriodType") as string
        );
        this.firstPostDueDateNotificationValue = Number(
            formData.get("firstPostDueDateNotificationValue") as string
        );
        this.fourthNotificationPeriodType = Number(
            formData.get("fourthNotificationPeriodType") as string
        );
        this.secondPostDueDateNotificationValue = Number(
            formData.get("secondPostDueDateNotificationValue") as string
        );
        this.fifthNotificationPeriodType = Number(
            formData.get("fifthNotificationPeriodType") as string
        );
        this.thirdPostDueDateNotificationValue = Number(
            formData.get("thirdPostDueDateNotificationValue") as string
        );
        this.sixthNotificationPeriodType = Number(
            formData.get("sixthNotificationPeriodType") as string
        );
    }
}
