import { EditActionPlanDto } from "../../dtos/actions/edit-action-plan-dto";
import { CreateActionPlanRequest } from "./create-action-plan-request";

export class EditActionPlanRequest extends CreateActionPlanRequest {
    actionPlanId: number;

    public constructor(dto: EditActionPlanDto) {
        super(dto);

        this.actionPlanId = dto.actionPlanId;
    }
}
