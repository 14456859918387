import { PaginationResponse } from "../../responses/common/pagination-response";
import BasePaginatedDto, { createBasePaginatedDto } from "./base-paginated-dto";

export default interface PaginatedDto<TType> extends BasePaginatedDto {
    results: TType[];
}

export const createPaginatedDtoFromResponse = <RType, DType>(
    response: PaginationResponse<RType>,
    dtos: DType[]
): PaginatedDto<DType> => {
    const { currentPage, pageSize, pageCount, recordCount } = response;

    return {
        results: dtos,
        ...createBasePaginatedDto(currentPage, pageSize, pageCount, recordCount),
    };
};
