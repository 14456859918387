import { CreateQuestionWeightingsRequest } from "../questions/create-question-weightings-request";
import { QuestionScheduleBaseRequest } from "./question-schedule-base-request";

export class NewQuestionSetQuestionRequest extends QuestionScheduleBaseRequest {
    orderIndex: number;
    createQuestionRequest: CreateQuestionRequest;

    public constructor(
        orderIndex: number,
        questionSetId: number,
        questionId: number,
        dueMinutesFromBaseStart: number,
        openDeltaMinutes: number,
        escalationL1MinutesFromBaseStart: number,
        escalationL2MinutesFromBaseStart: number | null,
        escalationL3MinutesFromBaseStart: number | null,
        isDefaultOpenDelta: boolean,
        createQuestionRequest: CreateQuestionRequest
    ) {
        super(
            questionSetId,
            questionId,
            dueMinutesFromBaseStart,
            openDeltaMinutes,
            escalationL1MinutesFromBaseStart,
            escalationL2MinutesFromBaseStart,
            escalationL3MinutesFromBaseStart,
            isDefaultOpenDelta
        );
        this.orderIndex = orderIndex;
        this.createQuestionRequest = createQuestionRequest;
    }
}

export class CreateQuestionRequest {
    languageId: number;
    questionText: string;
    questionTypeId: number;
    answerTypeId: number;
    questionCategoryId: number;
    questionImportance: number;
    isAggregate: boolean;
    aggregationTypeId: number;
    isARMonitoring: boolean;
    answerThresholdValues: CreateAnswerThresholdValueRequest[];
    questionWeightingRequests: CreateQuestionWeightingsRequest[];

    public constructor(
        languageId: number,
        questionText: string,
        questionTypeId: number,
        answerTypeId: number,
        questionCategoryId: number,
        questionImportanceId: number,
        isAggregate: boolean,
        aggregationTypeId: number,
        isARMonitoring: boolean,
        answerThresholdValues: CreateAnswerThresholdValueRequest[],
        questionWeightingRequests: CreateQuestionWeightingsRequest[]
    ) {
        this.languageId = languageId;
        this.questionText = questionText;
        this.questionTypeId = questionTypeId;
        this.answerTypeId = answerTypeId;
        this.questionCategoryId = questionCategoryId;
        this.questionImportance = questionImportanceId;
        this.isAggregate = isAggregate;
        this.aggregationTypeId = aggregationTypeId;
        this.isARMonitoring = isARMonitoring;
        this.answerThresholdValues = answerThresholdValues;
        this.questionWeightingRequests = questionWeightingRequests;
    }
}

export class CreateAnswerThresholdValueRequest {
    intValue?: number;
    dateValue?: Date;
    decimalValue?: number;
    answerThresholdIndicator: number;
    answerThresholdOperator: number;

    public constructor(
        answerThresholdIndicator: number,
        answerThresholdOperator: number,
        intValue?: number,
        dateValue?: Date,
        decimalValue?: number
    ) {
        this.intValue = intValue;
        this.dateValue = dateValue;
        this.decimalValue = decimalValue;
        this.answerThresholdIndicator = answerThresholdIndicator;
        this.answerThresholdOperator = answerThresholdOperator;
    }
}
