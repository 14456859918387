import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { isMutationLoading } from "../../../core/utilities/responseStateHelper";
import { useUploadImage } from "../../../domain/viewmodels/image-library/upload-image-library-viewmodel";
import { CancelButton, SbButton } from "../../atoms/SbButton";
import { TextTitledPanel } from "../../molecules/SbPanel";

const StyledBoldSpan = styled.span`
    color: ${(props) => props.theme.palette.black};
    font-weight: bold;
`;

const StyledFormControl = styled(Form.Control)`
    width: 300px;
    background-color: ${(props) => props.theme.palette.purewhite};
    color: ${(props) => props.theme.palette.black};
`;

const ImageLibraryFileUploadContainer: React.FC = () => {
    const menu = useMenu();
    const navigate = useNavigate();
    const { t } = useTranslation("translation", { keyPrefix: Common });
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const uploadImage = useUploadImage();

    useLoader(isMutationLoading(uploadImage), ImageLibraryFileUploadContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.WizardCards, AccordionTitles.ImageLibrary);
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);

        uploadImage.mutate(formData, {
            onSuccess: async () => navigate(-1),
            onError: errorResponseToDisplayHandler,
        });
    };

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <PageHeading>{t("UploadImageTitle")}</PageHeading>
                <SectionVerticalSpace />
                <TextTitledPanel title={t("UploadImageTitle")}>
                    <SectionVerticalSpace />
                    <Form.Group controlId="formFile" className="mb-4">
                        <StyledBoldSpan>{t("SelectFile")}</StyledBoldSpan>
                        <StyledFormControl name="file" type="file" required={true} />
                    </Form.Group>
                    <EndAlignedDiv>
                        <SbButton variant="primary" type="submit" label={t("Upload")} />
                        <CancelButton onClick={() => navigate(-1)} />
                    </EndAlignedDiv>
                </TextTitledPanel>
            </Form>
        </>
    );
};

export default ImageLibraryFileUploadContainer;
