import { Response } from "../../responses/common/response-response";
import { TenantDetailsResponse } from "../../responses/tenants/tenant-details-response";
import { TenantFeatureDto } from "./tenant-feature-dto";

export interface TenantDetailsDto {
    tenantId: number;
    name: string;
    description: string | null;
    connectionString: string;
    auditConnectionString: string;
    workerConnectionString: string;
    workerAuditConnectionString: string;
    baseUrl: string;
    theme: string | null;
    features: TenantFeatureDto[];
}

export const toTenantDetailsDto = (
    tenantDetails: Response<TenantDetailsResponse>
): TenantDetailsDto => {
    const tenantDetailsData = tenantDetails.data;

    return {
        tenantId: tenantDetailsData.tenantId,
        name: tenantDetailsData.name,
        description: tenantDetailsData.description,
        connectionString: tenantDetailsData.connectionString,
        auditConnectionString: tenantDetailsData.auditConnectionString,
        workerConnectionString: tenantDetailsData.workerConnectionString,
        workerAuditConnectionString: tenantDetailsData.workerAuditConnectionString,
        baseUrl: tenantDetailsData.baseUrl,
        theme: tenantDetailsData.theme,
        features: tenantDetailsData.features,
    };
};
