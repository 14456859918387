import { AssociateChecklistNodeRequest } from "../../../domain/requests/checklists/associate-checklist-node-request";
import { AssociateDissociateChecklistTaxonomyNodeRequest } from "../../../domain/requests/checklists/associate-dissociate-checklist-taxonomy-node-request";
import { AssociateDissociateQuestionSetToChecklistRequest } from "../../../domain/requests/checklists/associate-dissociate-question-set-to-checklist-request";
import { AssociateDissociateReferentialLinkToChecklistRequest } from "../../../domain/requests/checklists/associate-dissociate-referential-link-to-checklist-request";
import { AssociateNewQuestionSetToChecklistRequest } from "../../../domain/requests/checklists/create-and-associate-question-set-request";
import { CreateChecklistRequest } from "../../../domain/requests/checklists/create-checklist-request";
import { EditChecklistRequest } from "../../../domain/requests/checklists/edit-checklist-request";
import {
    buildFilterChecklistAssociationSearchParameters,
    FilterChecklistAssociationRequest,
} from "../../../domain/requests/checklists/filter-checklist-association-request";
import {
    buildFilterChecklistSearchParameters,
    FilterChecklistsRequest,
} from "../../../domain/requests/checklists/filter-checklists-request";
import { ToggleChecklistRequest } from "../../../domain/requests/checklists/toggle-checklist-request";
import {
    buildFilterByNodeSearchParameters,
    FilterByNodeRequest,
} from "../../../domain/requests/common/filter-by-node-request";
import { DissociateChecklistFromNodeRequest } from "../../../domain/requests/hierarchy/dissociate-checklist-from-node-request";
import { AssociatedChecklistQuestionSetResponse } from "../../../domain/responses/checklists/associated-checklist-question-set-response";
import { BaseChecklistResponse } from "../../../domain/responses/checklists/base-checklist-response";
import { ChecklistAssignmentResponse } from "../../../domain/responses/checklists/checklist-assignment-response";
import { ChecklistReferentialLinkResponse } from "../../../domain/responses/checklists/checklist-referential-link-response";
import { ChecklistResponse } from "../../../domain/responses/checklists/checklist-response";
import { ChecklistTaxonomyLinksResponse } from "../../../domain/responses/checklists/checklist-taxonomy-links-response";
import { LibraryChecklistResponse } from "../../../domain/responses/checklists/library-checklist-response";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import client from "../../http-client";

const route = "checklists";

export const filterChecklists = async (
    prefixUrl: string,
    request: FilterChecklistsRequest
): Promise<Response<PaginationResponse<ChecklistResponse>>> => {
    const searchParams = buildFilterChecklistSearchParameters(request);

    return await client
        .get(`${route}/filter`, {
            prefixUrl: prefixUrl,
            searchParams: searchParams,
        })
        .json();
};

export const filterLibraryChecklists = async (
    prefixUrl: string,
    request: FilterChecklistsRequest
): Promise<Response<PaginationResponse<LibraryChecklistResponse>>> => {
    const searchParams = buildFilterChecklistSearchParameters(request);

    return await client
        .get(`${route}/filter-library-checklists`, {
            prefixUrl: prefixUrl,
            searchParams: searchParams,
        })
        .json();
};

export const filterAvailableUserChecklists = async (
    prefixUrl: string,
    userId: number
): Promise<Response<BaseChecklistResponse[]>> => {
    const searchParams = new URLSearchParams();
    searchParams.set("userId", userId.toString());

    return await client
        .get(`${route}/user-checklists/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const filterApplicableChecklists = async (
    prefixUrl: string,
    nodeId: number
): Promise<Response<BaseChecklistResponse[]>> => {
    const searchParams = new URLSearchParams();
    searchParams.set("nodeId", nodeId.toString());

    return await client
        .get(`${route}/filter-applicable-checklists`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getChecklistDetails = async (
    prefixUrl: string,
    checkListId: number
): Promise<Response<ChecklistResponse>> =>
    await client
        .get(`${route}/${checkListId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const createChecklist = async (
    prefixUrl: string,
    request: CreateChecklistRequest
): Promise<Response<number>> =>
    await client
        .post(`${route}/create`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const editChecklist = async (
    prefixUrl: string,
    request: EditChecklistRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/edit`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const softDeleteChecklist = async (
    prefixUrl: string,
    checklistId: number
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/delete/${checklistId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const cloneChecklist = async (
    prefixUrl: string,
    checkListId: number
): Promise<Response<ChecklistResponse>> =>
    await client
        .post(`${route}/clone-checklist/${checkListId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const cloneChecklistAndQuestionSets = async (
    prefixUrl: string,
    checkListId: number
): Promise<Response<ChecklistResponse>> =>
    await client
        .post(`${route}/clone-checklist-and-question-sets/${checkListId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const toggleChecklistPromoteDemote = async (
    prefixUrl: string,
    request: ToggleChecklistRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/promote-demote`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const reviewChecklist = async (
    prefixUrl: string,
    checklistId: number
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/review/${checklistId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const filterChecklistsByNode = async (
    prefixUrl: string,
    request: FilterByNodeRequest
): Promise<Response<PaginationResponse<ChecklistResponse>>> => {
    const searchParams = buildFilterByNodeSearchParameters(request);

    return await client
        .get(`${route}/checklist-nodes/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getAssociatedQuestionSets = async (
    prefixUrl: string,
    request: FilterChecklistAssociationRequest
): Promise<Response<PaginationResponse<AssociatedChecklistQuestionSetResponse>>> => {
    const searchParams = buildFilterChecklistAssociationSearchParameters(request);

    return await client
        .get(`${route}/associated-question-sets`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getAssociatedAdhocQuestionSets = async (
    prefixUrl: string,
    request: FilterChecklistAssociationRequest
): Promise<Response<PaginationResponse<AssociatedChecklistQuestionSetResponse>>> => {
    const searchParams = buildFilterChecklistAssociationSearchParameters(request);

    return await client
        .get(`${route}/associated-adhoc-question-sets`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getAssociatedNodes = async (
    prefixUrl: string,
    request: FilterChecklistAssociationRequest
): Promise<Response<PaginationResponse<ChecklistAssignmentResponse>>> => {
    const searchParams = buildFilterChecklistAssociationSearchParameters(request);

    return await client
        .get(`${route}/associated-nodes`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getAssociatedReferentialLinks = async (
    prefixUrl: string,
    request: FilterChecklistAssociationRequest
): Promise<Response<PaginationResponse<ChecklistReferentialLinkResponse>>> => {
    const searchParams = buildFilterChecklistAssociationSearchParameters(request);

    return await client
        .get(`${route}/associated-referential-links`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getAssociatedTaxonomyNodes = async (
    prefixUrl: string,
    request: FilterChecklistAssociationRequest
): Promise<Response<PaginationResponse<ChecklistTaxonomyLinksResponse>>> => {
    const searchParams = buildFilterChecklistAssociationSearchParameters(request);

    return await client
        .get(`${route}/associated-taxonomy-nodes`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const dissociateReferentialLink = async (
    prefixUrl: string,
    request: AssociateDissociateReferentialLinkToChecklistRequest
): Promise<Response<boolean>> =>
    await client
        .delete(`${route}/dissociate-referential-link`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const associateChecklistReferentialLink = async (
    prefixUrl: string,
    request: AssociateDissociateReferentialLinkToChecklistRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/associate-referential-link`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const associateExistingQuestionSet = async (
    prefixUrl: string,
    request: AssociateDissociateQuestionSetToChecklistRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/associate-existing-question-set`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const dissociateQuestionSet = async (
    prefixUrl: string,
    request: AssociateDissociateQuestionSetToChecklistRequest
): Promise<Response<boolean>> =>
    await client
        .delete(`${route}/dissociate-question-set`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const createAndAssociateQuestionSet = async (
    prefixUrl: string,
    request: AssociateNewQuestionSetToChecklistRequest
): Promise<Response<number>> =>
    await client
        .post(`${route}/associate-new-question-set`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const dissociateTaxonomyNode = async (
    prefixUrl: string,
    request: AssociateDissociateChecklistTaxonomyNodeRequest
): Promise<Response<boolean>> =>
    await client
        .delete(`${route}/dissociate-taxonomy-node`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const associateTaxonomyNode = async (
    prefixUrl: string,
    request: AssociateDissociateChecklistTaxonomyNodeRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/associate-taxonomy-node`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const dissociateNode = async (
    prefixUrl: string,
    checklistAssignmentId: number
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/dissociate-node/${checklistAssignmentId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const dissociateChecklistFromNodeByChecklistId = async (
    prefixUrl: string,
    request: DissociateChecklistFromNodeRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/dissociate-checklist-from-node-by-checklist-and-node-id`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const associateNode = async (
    prefixUrl: string,
    request: AssociateChecklistNodeRequest
): Promise<Response<number | null>> =>
    await client
        .post(`${route}/associate-node`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();
