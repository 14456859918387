import ActionItemInstanceFileDownloadResponse from "../../responses/action-items/action-item-instance-file-download-response";
import ActionPlanFileDownloadResponse from "../../responses/actions/action-plan-file-download-response";
import QuestionSetInstanceAnswerFileDownloadResponse from "../../responses/answer-summary/question-set-instance-answer-file-download-response";
import { Response } from "../../responses/common/response-response";
import BaseFileDetailsResponse from "../../responses/file-storage/base-file-details-response";
import QuestionSetInstanceAnswerFileDetailsResponse from "../../responses/question-set-instance-answers/question-set-instance-answer-file-details-response";
import QuestionSetInstanceAnswerStagingFileDetailsResponse from "../../responses/question-set-instance-answers/question-set-instance-answer-staging-file-details-response";
import ReviewReportSetFileDownloadResponse from "../../responses/review-reports/review-report-set-file-download-response";

// TODO: File DTO using FileDownloadResponses to be constructed - we are either returning data we do not need here (Downloaded File Contents), or we are making use of a single-object-fits-all (populating file contents per scenario on the back-end) - this makes this object less maintainable and its state unknown
export class FileDto {
    fileId: number;
    fileName: string;

    public constructor(fileId: number, fileName: string) {
        this.fileId = fileId;
        this.fileName = fileName;
    }

    public static constructFromBaseFileDetailsResponses = (
        responses: BaseFileDetailsResponse[]
    ): FileDto[] => responses.map((x) => new FileDto(x.fileDetailId, x.fileName));

    public static constructFromActionPlanFileDownloadResponses = (
        responses: Response<ActionPlanFileDownloadResponse[]>
    ): FileDto[] => responses!.data.map((x) => new FileDto(x.actionPlanFileId, x.fileName));

    public static constructFromActionItemInstanceFileDownloadResponses = (
        responses: Response<ActionItemInstanceFileDownloadResponse[]>
    ): FileDto[] => responses!.data.map((x) => new FileDto(x.actionItemInstanceFileId, x.fileName));

    public static constructFromQuestionSetInstanceAnswerFileDownloadResponses = (
        responses: QuestionSetInstanceAnswerFileDownloadResponse[]
    ): FileDto[] =>
        responses!.map((x) => new FileDto(x.questionSetInstanceAnswerFileId, x.fileName));

    public static constructFromReviewReportSetFileDownloadResponses = (
        responses: Response<ReviewReportSetFileDownloadResponse[]>
    ): FileDto[] => responses!.data.map((x) => new FileDto(x.reviewReportSetFileId, x.fileName));

    public static constructFromQuestionSetInstanceAnswerFileDetailsResponse = (
        response: Response<QuestionSetInstanceAnswerFileDetailsResponse[]>
    ): FileDto[] =>
        FileDto.constructFromQuestionSetInstanceAnswerFileDetailsResponses(response.data!);

    public static constructFromQuestionSetInstanceAnswerStagingFileDetailsResponse = (
        response: Response<QuestionSetInstanceAnswerStagingFileDetailsResponse[]>
    ): FileDto[] =>
        FileDto.constructFromQuestionSetInstanceAnswerStagingFileDetailsResponses(response.data!);

    public static constructFromQuestionSetInstanceAnswerFileDetailsResponses = (
        responses: QuestionSetInstanceAnswerFileDetailsResponse[]
    ): FileDto[] =>
        responses.map((x) => new FileDto(x.questionSetInstanceAnswerFileId, x.fileName));

    public static constructFromQuestionSetInstanceAnswerStagingFileDetailsResponses = (
        responses: QuestionSetInstanceAnswerStagingFileDetailsResponse[]
    ): FileDto[] =>
        responses.map((x) => new FileDto(x.questionSetInstanceAnswerStagingFileId, x.fileName));
}
