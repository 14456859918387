import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { createHierarchyType } from "../../../data/services/hierarchy/hierarchy-types-service";
import {
    HierarchyAssignable,
    HierarchyAssignableLabels,
} from "../../enums/hierarchy/HierarchyAssignable";
import { createCreateHierarchyTypeRequest } from "../../requests/hierarchy/create-hierarchy-type-request";
import { Response } from "../../responses/common/response-response";

export const useCreateHierarchyType = (): UseMutationResult<
    Response<number | null>,
    HTTPError,
    FormData
> => {
    const url = useUrl();

    return useMutation((formData: FormData) => {
        const request = createCreateHierarchyTypeRequest(
            formData.get("name")! as string,
            Array.from(HierarchyAssignableLabels.keys()).filter(
                (x) => formData.get(HierarchyAssignable[x]) === "on"
            )
        );

        return createHierarchyType(url.baseUrl, request);
    });
};
