import { Response } from "../../responses/common/response-response";
import { DisclaimerResponse } from "../../responses/tenants/disclaimer-response";

export class DisclaimerDto {
    name: string;
    imageLogo: string | null;
    disclaimer: string | null;

    public constructor(response: DisclaimerResponse) {
        this.name = response.name;
        this.imageLogo = response.imageLogo;
        this.disclaimer = response.disclaimer;
    }

    public static constructFromResponse = (response: Response<DisclaimerResponse>): DisclaimerDto =>
        new DisclaimerDto(response.data);
}
