import { ReactElement } from "react";
import { Variant } from "react-bootstrap/esm/types";
import { IconType } from "react-icons";
import { FaExclamationTriangle } from "react-icons/fa";
import { MdError } from "react-icons/md";
import { PageHeading, PageSubHeading, SectionVerticalSpace } from "../../core/theme/global-styles";
import { SbAlert } from "../atoms/SbAlert";
import { ActionConfirmation, ActionConfirmationProps, ConfirmationRow } from "./ActionConfirmation";

export class AlertProps {
    variant: Variant;
    messages: string[] | undefined;
    icon?: IconType;

    public constructor(variant: Variant, messages: string[] | undefined, icon?: IconType) {
        this.variant = variant;
        this.messages = messages;
        this.icon = icon;
    }
}

export const createErrorAlertProps = (messages: string[] | undefined): AlertProps =>
    new AlertProps("danger", messages, MdError);

export const createWarningAlertProps = (messages: string[] | undefined): AlertProps =>
    new AlertProps("warning", messages, FaExclamationTriangle);

export const createPrimaryAlertProps = (
    messages: string[] | undefined,
    icon?: IconType
): AlertProps => new AlertProps("primary", messages, icon);

interface ActionConfirmationFragmentProps<TRowType, TRow extends ConfirmationRow<TRowType>>
    extends ActionConfirmationProps<TRowType, TRow> {
    pageHeading?: string;
    pageSubHeading?: string;
    areQueriesSuccessful?: boolean;
    alert?: AlertProps | null;
    isNested?: boolean;
}

export const ActionConfirmationFragment = <TRowType, TRow extends ConfirmationRow<TRowType>>({
    pageHeading,
    pageSubHeading,
    panelTitle,
    alert,
    rows,
    areQueriesSuccessful,
    primaryButton,
    cancelButton,
    isNested,
}: ActionConfirmationFragmentProps<TRowType, TRow>): JSX.Element => {
    const buildAlert = (): ReactElement<HTMLDivElement> | null => {
        if (!alert?.messages) {
            return null;
        }

        const { variant, messages } = alert;

        return <SbAlert variant={variant} text={messages.join("\n")} />;
    };

    return (
        <>
            {pageHeading && <PageHeading>{pageHeading}</PageHeading>}
            {pageSubHeading && <PageSubHeading>{pageSubHeading}</PageSubHeading>}
            {pageHeading || (pageSubHeading && <SectionVerticalSpace />)}

            {(areQueriesSuccessful == undefined || areQueriesSuccessful) && (
                <>
                    {buildAlert()}

                    <ActionConfirmation
                        panelTitle={panelTitle}
                        rows={rows}
                        primaryButton={primaryButton}
                        cancelButton={cancelButton}
                        isNested={isNested}
                    />
                </>
            )}
        </>
    );
};
