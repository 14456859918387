import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Common, NonWorkingDays } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { isMutationLoading } from "../../../core/utilities/responseStateHelper";
import { useGetNonWorkingDaySet } from "../../../domain/viewmodels/non-working-days/associated-non-working-day-set-node-viewmodel";
import { useDeleteNonWorkingDaySet } from "../../../domain/viewmodels/non-working-days/delete-non-working-day-set-viewmodel";
import { DeleteButton } from "../../atoms/SbButton";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import { ActionConfirmationFragment } from "../../organisms/ActionConfirmationFragment";

const DeleteNonWorkingDaySetContainer: React.FC = () => {
    const menu = useMenu();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: NonWorkingDays });

    const nonWorkingDaySetId = Number(useParams().nonWorkingDaySetId);

    const getNonWorkingDaySet = useGetNonWorkingDaySet(nonWorkingDaySetId);
    const name = getNonWorkingDaySet[0].data?.name;

    const deleteNonWorkingDaySet = useDeleteNonWorkingDaySet();

    useLoader(isMutationLoading(deleteNonWorkingDaySet), DeleteNonWorkingDaySetContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.NonWorkingDay);
    }, []);

    const handleDeleteNonWorkingDaySet = (nonWorkingDaySetId: number): void => {
        deleteNonWorkingDaySet.mutate(nonWorkingDaySetId, {
            onSuccess: async () => navigate(-1),
            onError: errorResponseToDisplayHandler,
        });
    };

    const buildDeleteButton = (): ReactElement<typeof Button> => (
        <DeleteButton onClick={() => handleDeleteNonWorkingDaySet(nonWorkingDaySetId)} />
    );

    const buildConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow(t("Name", { keyPrefix: Common }), name),
    ];

    return (
        <ActionConfirmationFragment
            pageHeading={t("NonWorkingDaySetDeleteTitle")}
            panelTitle={t("DeleteNonWorkingDaySetConfirmation")}
            rows={buildConfirmationRows()}
            primaryButton={buildDeleteButton()}
        />
    );
};

export default DeleteNonWorkingDaySetContainer;
