import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import { ChecklistQuestions, Common } from "../../../core/constants/translation-namespace";
import {
    ContentContainer,
    EndAlignedDiv,
    SectionContainer,
} from "../../../core/theme/global-styles";
import { AssociateReferentialLinkTypeDto } from "../../../domain/dtos/checklists/associate-referential-link-type-dto";
import { ResetButton, SearchButton } from "../../atoms/SbButton";
import { FilterInput } from "../../molecules/FilterInput";
import { FilterSelect } from "../../molecules/FilterSelect";

export const AssociateReferentialLinksFilter: React.FC<{
    referentialLinkTypes: AssociateReferentialLinkTypeDto[];
    referentialLinkTypeName: string | null;
    changeReferentialLinkType: (option: SingleValue<AssociateReferentialLinkTypeDto>) => void;
    referentialLinkValue: string | null;
    changeReferentialLinkValue: (referentialLinkValue: string) => void;
    search: () => void;
    resetFilter: () => void;
}> = ({
    referentialLinkTypes,
    referentialLinkTypeName,
    changeReferentialLinkType,
    referentialLinkValue,
    changeReferentialLinkValue,
    search,
    resetFilter,
}) => {
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });
    return (
        <ContentContainer>
            <SectionContainer>
                <Row>
                    <Col>
                        <FilterSelect
                            name={"linkType"}
                            label={`${t("LinkType")}`}
                            placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                            searchable
                            clearable
                            items={referentialLinkTypes}
                            itemDisplayText={
                                (option: AssociateReferentialLinkTypeDto) => option.name //TODO: Add translations for dynamic data
                            }
                            onChange={changeReferentialLinkType}
                            value={
                                referentialLinkTypeName != null
                                    ? referentialLinkTypes.find(
                                          (option: AssociateReferentialLinkTypeDto) =>
                                              option.name == referentialLinkTypeName
                                      )
                                    : null
                            }
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("Value", { keyPrefix: Common })}
                            name={"referentialLink"}
                            type={"text"}
                            onChangeEventHandler={changeReferentialLinkValue}
                            value={referentialLinkValue ?? ""}
                        />
                    </Col>
                </Row>
            </SectionContainer>
            <EndAlignedDiv>
                <SearchButton onClick={search} />
                <ResetButton onClick={resetFilter} />
            </EndAlignedDiv>
        </ContentContainer>
    );
};
