import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { getNullableFormField } from "../../../core/utilities/formDataHelper";
import { createActionItemDynamicField } from "../../../data/services/action-items/action-item-dynamic-fields-service";
import { getActionItemTypes } from "../../../data/services/action-items/action-item-types-service";
import {
    ActionItemTypeDto,
    toActionItemTypeDtos,
} from "../../dtos/action-items/action-item-type-dto";
import { CreateActionItemDynamicFieldDto } from "../../dtos/action-items/create-action-item-dynamic-field-dto";
import { createCreateActionItemDynamicFieldRequest } from "../../requests/action-items/create-action-item-dynamic-field-request";
import { Response } from "../../responses/common/response-response";

export const useGetActionItemTypes = (): UseQueryResult<ActionItemTypeDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(["getActionItemTypes"], () => getActionItemTypes(url.baseUrl), {
        select: toActionItemTypeDtos,
        onError: errorResponseToDisplayHandler,
    });
};

export const useCreateActionItemDynamicField = (): UseMutationResult<
    Response<number | null>,
    HTTPError,
    CreateActionItemDynamicFieldDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: CreateActionItemDynamicFieldDto) => {
        const { actionItemTypeNodeId, valueType, formData } = mutationKey;

        const request = createCreateActionItemDynamicFieldRequest(
            getNullableFormField(formData.get("fieldName") as string),
            actionItemTypeNodeId,
            valueType,
            getNullableFormField(formData.get("listData") as string),
            formData.get("isRequired") === "on"
        );

        return createActionItemDynamicField(url.baseUrl, request);
    });
};
