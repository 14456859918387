import { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RiDeleteBinFill } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import { ActionItems, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import {
    DetailsLabel,
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import { ActionItemImportance } from "../../../domain/enums/action-items/ActionItemImportance";
import {
    useCancelActionItem,
    useGetActionItemDetails,
} from "../../../domain/viewmodels/actions/cancel-action-item-viewmodel";
import { CancelButton, SbButton } from "../../atoms/SbButton";
import { TextTitledPanel } from "../../molecules/SbPanel";
import { StyledTableDataLabel } from "../../organisms/ActionConfirmation";

const CancelActionItemContainer: React.FC = () => {
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });
    const menu = useMenu();
    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const actionItemId = Number(useParams().actionItemId);

    const getActionItemDetails = useGetActionItemDetails(actionItemId);
    const cancelActionItem = useCancelActionItem();

    const actionItemDetailsResponseData = getActionItemDetails.data;

    useLoader(
        isQueryLoading(getActionItemDetails) || isMutationLoading(cancelActionItem),
        CancelActionItemContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.ActionTracker);
        menu.changeActiveDrawerItem(DrawerTitles.ActionItems);
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        cancelActionItem.mutate(
            {
                actionItemInstanceId:
                    actionItemDetailsResponseData!.actionInstanceDetailsDto.actionItemInstanceId!,
                comment: formData.get("comment") as string,
            },
            {
                onSuccess: async () => {
                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "messageKey",
                            "SuccessfullyCancelledActionItem"
                        ),
                    ];

                    navigateSearch(`${getPath(DrawerTitles.ActionItems)}`, params);
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    return (
        <>
            <PageHeading>{t("CancelItem")}</PageHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(getActionItemDetails) && (
                <>
                    <TextTitledPanel title={t("CancelConfirmation")}>
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col md={2}>
                                    <StyledTableDataLabel>
                                        <DetailsLabel>
                                            {t("Name", { keyPrefix: Common })}
                                        </DetailsLabel>
                                    </StyledTableDataLabel>
                                </Col>
                                <Col md={4}>{actionItemDetailsResponseData?.name}</Col>
                            </Row>
                            <Row>
                                <Col md={2}>
                                    <StyledTableDataLabel>
                                        <DetailsLabel>
                                            {t("Description", { keyPrefix: Common })}
                                        </DetailsLabel>
                                    </StyledTableDataLabel>
                                </Col>
                                <Col md={4}>{actionItemDetailsResponseData?.description}</Col>
                            </Row>
                            <Row>
                                <Col md={2}>
                                    <StyledTableDataLabel>
                                        <DetailsLabel>
                                            {t("Urgency", { keyPrefix: Common })}
                                        </DetailsLabel>
                                    </StyledTableDataLabel>
                                </Col>
                                <Col md={4}>
                                    {ActionItemImportance[
                                        actionItemDetailsResponseData?.actionItemImportance!
                                    ].toString()}
                                </Col>
                            </Row>

                            <Row>
                                <Col md={2}>
                                    <StyledTableDataLabel>
                                        <DetailsLabel>{t("Comment")}*</DetailsLabel>
                                    </StyledTableDataLabel>
                                </Col>
                                <Col md={4}>
                                    <Form.Control
                                        as="textarea"
                                        name={"comment"}
                                        rows={4}
                                        maxLength={3000}
                                    />
                                </Col>
                            </Row>
                            <EndAlignedDiv>
                                <SbButton
                                    variant="danger"
                                    type="submit"
                                    label="Cancel Item"
                                    icon={RiDeleteBinFill}
                                />
                                <CancelButton onClick={() => navigate(-1)} />
                            </EndAlignedDiv>
                        </Form>
                    </TextTitledPanel>
                </>
            )}
        </>
    );
};

export default CancelActionItemContainer;
