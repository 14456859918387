import { useState } from "react";
import { Modal } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import styled from "styled-components";
import { useErrorResponseToDisplayHandler } from "../../core/hooks/errorResponseToDisplayHandler";
import { useAuth } from "../../core/store/auth-context";
import { EndAlignedDiv, LargeVerticalSpace } from "../../core/theme/global-styles";
import { NotificationStatuses } from "../../core/utilities/enums";
import queryClient from "../../data/query-client";
import { WizardCardDto } from "../../domain/dtos/wizards/wizard-card-dto";
import { ImageLibraryViewResponse } from "../../domain/responses/image-library/image-library-view-response";
import { useEditWizardNotificationStatus } from "../../domain/viewmodels/wizards/edit-wizard-notification-status-viewmodel";
import { SbButton } from "../atoms/SbButton";
import { SbProgressDot } from "../atoms/SbProgressDot";
import SbFormCheckFieldGroup from "../molecules/input/SbFormCheckFieldGroup";

const StyledModal = styled(Modal)`
    --bs-modal-width: 720px;
`;

const StyledImage = styled(Image)`
    width: 100%;
    height: 360px;
`;

const StyledHeading = styled.h2`
    color: ${(props) => props.theme.palette.primary};
`;

const StyledDescription = styled.p`
    font-size: ${(props) => props.theme.font.lg};
`;

const StyledSbProgressDotContainer = styled.div`
    display: flex;
    gap: ${(props) => props.theme.padding.md};
`;

const StyledRightAlign = styled.div`
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
    padding-right: ${(props) => props.theme.padding.lg};
`;

export const WizardCardsModal: React.FC<{
    tenant: boolean;
    show: boolean;
    updateShow: (show: boolean) => void;
    wizardCards: WizardCardDto[];
    getImageLibraryImage: (fileDetailsId: number) => ImageLibraryViewResponse | undefined;
    activeIndex: number;
    onActiveIndexChange: (index: number) => void;
    isFinished: boolean;
    wizardNotificationStatus?: number;
}> = ({
    tenant,
    show,
    updateShow,
    wizardCards,
    getImageLibraryImage,
    activeIndex,
    onActiveIndexChange,
    isFinished,
    wizardNotificationStatus,
}) => {
    const auth = useAuth();
    const [dontShowAgainChecked, setDontShowAgainChecked] = useState(false);
    const editWizardNotificationStatus = useEditWizardNotificationStatus();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const handleDontShowAgainChange = (_: string, checked: boolean): void =>
        setDontShowAgainChecked(checked);

    const onWizardNotificationStatusChange = (notificationStatus: number): void => {
        if (!dontShowAgainChecked && wizardNotificationStatus === notificationStatus) {
            updateShow(false);
            return;
        }

        return editWizardNotificationStatus.mutate(
            {
                userId: auth.userId!,
                wizardId: wizardCards[0].wizardId,
                notificationStatus: dontShowAgainChecked
                    ? NotificationStatuses.DontShowAgain
                    : notificationStatus,
            },
            {
                onSuccess: async () => {
                    updateShow(false);
                    queryClient.invalidateQueries(["getWizardNotificationHistory"]);
                },
                onError: async (error) => {
                    updateShow(false);
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    };

    return (
        <StyledModal show={show} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                <StyledImage
                    src={
                        tenant
                            ? wizardCards && wizardCards[activeIndex].fileImagePath
                            : wizardCards &&
                              getImageLibraryImage(wizardCards[activeIndex].fileDetailsId)
                                  ?.fileImagePath
                    }
                    alt="wizard card img"
                />
                <LargeVerticalSpace />
                <StyledHeading>{wizardCards && wizardCards[activeIndex].heading}</StyledHeading>
                <hr />
                <StyledDescription>
                    {wizardCards && wizardCards[activeIndex].description}
                </StyledDescription>
                <EndAlignedDiv>
                    <SbButton
                        variant="transparent"
                        color="black"
                        hoverVariant="border"
                        type={"button"}
                        label="EXIT"
                        onClick={
                            tenant
                                ? () =>
                                      onWizardNotificationStatusChange(
                                          NotificationStatuses.Dismissed
                                      )
                                : () => updateShow(false)
                        }
                    />
                    <SbButton
                        variant="info"
                        color="primary"
                        hoverVariant="border"
                        type={"button"}
                        label="PREVIOUS"
                        onClick={() => onActiveIndexChange(activeIndex - 1)}
                        disabled={activeIndex <= 0}
                    />
                    <SbButton
                        variant="advance"
                        hoverVariant="border"
                        type={"button"}
                        label="NEXT"
                        onClick={() => onActiveIndexChange(activeIndex + 1)}
                        disabled={wizardCards && activeIndex >= wizardCards.length - 1}
                    />
                    {isFinished && (
                        <SbButton
                            variant="success"
                            type={"button"}
                            hoverVariant="border"
                            label="FINISH"
                            onClick={
                                tenant
                                    ? () =>
                                          onWizardNotificationStatusChange(
                                              NotificationStatuses.Finished
                                          )
                                    : () => updateShow(false)
                            }
                        />
                    )}
                </EndAlignedDiv>
                {wizardCards && (
                    <StyledSbProgressDotContainer>
                        {wizardCards.map((wizardCard, index) => (
                            <SbProgressDot
                                key={index}
                                index={index}
                                activeIndex={activeIndex}
                                onActiveIndexChange={onActiveIndexChange}
                            />
                        ))}
                    </StyledSbProgressDotContainer>
                )}
                <StyledRightAlign>
                    <SbFormCheckFieldGroup
                        fieldLabel=""
                        type="checkbox"
                        values={[
                            {
                                name: "doNotShowAgain",
                                label: "Do not show again",
                                onChangeHandler: handleDontShowAgainChange,
                            },
                        ]}
                    />
                </StyledRightAlign>
            </Modal.Body>
        </StyledModal>
    );
};
