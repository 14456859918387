import { AuthenticationRequestDto } from "../../dtos/auth/authentication-request-dto";

export class AuthenticationRequest {
    email: string;
    password: string;

    public constructor(dto: AuthenticationRequestDto) {
        this.email = dto.email;
        this.password = dto.password;
    }
}
