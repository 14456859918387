import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Common } from "../../../core/constants/translation-namespace";
import {
    ContentContainer,
    EndAlignedDiv,
    SectionContainer,
} from "../../../core/theme/global-styles";
import { ResetButton, SearchButton } from "../../atoms/SbButton";
import { FilterInput } from "../../molecules/FilterInput";

export const ActionPlanFilter: React.FC<{
    actionPlanName: string | null;
    changeActionPlanName: (actionPlanName: string) => void;
    actionPlanDescription: string | null;
    changeActionPlanDescription: (actionPlanDescription: string) => void;
    search: () => void;
    resetFilter: () => void;
}> = ({
    actionPlanName,
    changeActionPlanName,
    actionPlanDescription,
    changeActionPlanDescription,
    search,
    resetFilter,
}) => {
    const { t } = useTranslation("translation", { keyPrefix: Common });
    return (
        <ContentContainer>
            <SectionContainer>
                <Row>
                    <Col>
                        <FilterInput
                            title={t("Name")}
                            name={"actionPlanName"}
                            type={"text"}
                            onChangeEventHandler={changeActionPlanName}
                            value={actionPlanName ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("Description")}
                            name={"actionPlanDescription"}
                            type={"text"}
                            onChangeEventHandler={changeActionPlanDescription}
                            value={actionPlanDescription ?? ""}
                        />
                    </Col>
                    <Col></Col>
                    <Col></Col>
                </Row>
            </SectionContainer>
            <EndAlignedDiv>
                <SearchButton onClick={search} />
                <ResetButton onClick={resetFilter} />
            </EndAlignedDiv>
        </ContentContainer>
    );
};
