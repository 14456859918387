import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    createActionItemInstanceComment,
    getActionItemInstanceComments,
} from "../../../data/services/actions/action-items-service";
import { ActionItemInstanceCommentDetailsDto } from "../../dtos/action-items/action-item-instance-comment-details-dto";
import { ActionItemInstanceCommentDto } from "../../dtos/action-items/action-item-instance-comment-dto";
import { ActionItemInstanceCommentRequest } from "../../requests/action-items/action-item-instance-comment-request";
import { Response } from "../../responses/common/response-response";

export const useGetActionItemInstanceComments = (
    actionItemInstanceId: number
): UseQueryResult<ActionItemInstanceCommentDetailsDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getActionItemInstanceComments", actionItemInstanceId],
        () => getActionItemInstanceComments(url.baseUrl, actionItemInstanceId),
        {
            select: ActionItemInstanceCommentDetailsDto.constructFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useCreateActionItemInstanceComment = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    ActionItemInstanceCommentDto
> => {
    const url = useUrl();

    return useMutation((dto: ActionItemInstanceCommentDto) =>
        createActionItemInstanceComment(url.baseUrl, new ActionItemInstanceCommentRequest(dto))
    );
};
