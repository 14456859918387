import React from "react";
import { IconType } from "react-icons";
import styled, { css } from "styled-components";

interface StyledActionButtonContainerProps {
    readonly $disabled: boolean;
}

const StyledActionButtonContainerWrapper = styled.div<StyledActionButtonContainerProps>`
    ${({ $disabled }) =>
        $disabled &&
        css`
            cursor: not-allowed;
        `}
`;

const StyledActionButtonContainer = styled.div<StyledActionButtonContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${(props) => props.theme.padding.md};
  flex-direction: row;
  margin-left: ${(props) => props.theme.padding.sm};
  margin-right: ${(props) => props.theme.padding.sm}};
  ${({ $disabled }) =>
      $disabled &&
      css`
          pointer-events: none;
      `}
  ${({ $disabled }) =>
      !$disabled &&
      css`
          cursor: pointer;
      `}
`;

interface StyledIconDivProps {
    readonly $variant: string;
}

const StyledIconDiv = styled.div<StyledIconDivProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ $variant }) =>
        $variant === "primary" &&
        css`
            color: ${(props) => props.theme.palette.primary};
        `}
    ${({ $variant }) =>
        $variant === "danger" &&
        css`
            color: ${(props) => props.theme.palette.danger};
        `};
`;

const StyledTextDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.palette.primary};
`;

export const SbActionButton: React.FC<{
    variant: "primary" | "danger";
    text: string;
    icon: IconType;
    clickHandler: ((item: any) => void) | undefined;
    disabled: boolean;
}> = ({ variant, text, icon, clickHandler, disabled }) => (
    <StyledActionButtonContainerWrapper $disabled={disabled}>
        <StyledActionButtonContainer onClick={clickHandler} $disabled={disabled}>
            <StyledIconDiv $variant={variant}>{React.createElement(icon)}</StyledIconDiv>
            <StyledTextDiv>{text}</StyledTextDiv>
        </StyledActionButtonContainer>
    </StyledActionButtonContainerWrapper>
);
