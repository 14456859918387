import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
    createBasePaginationRequestFromDto,
} from "../common/base-pagination-request";

export interface FilterPredefinedAnswerTextsRequest extends BasePaginationRequest {
    predefinedAnswerId: number;
}

export const createFilterPredefinedAnswerTextsRequest = (
    paginationDto: BasePaginationDto,
    predefinedAnswerId: number
): FilterPredefinedAnswerTextsRequest => ({
    ...createBasePaginationRequestFromDto(paginationDto),
    predefinedAnswerId: predefinedAnswerId,
});

export const buildFilterPredefinedAnswerTextsSearchParameters = (
    request: FilterPredefinedAnswerTextsRequest
): URLSearchParams => {
    const searchParams = buildBasePaginationSearchParameters(request);

    searchParams.set("predefinedAnswerId", request.predefinedAnswerId.toString());

    return searchParams;
};
