export const DissociateChecklistFromNodeEvent = "Dissociate Checklist From Node";
export const DissociateNodeFromChecklistEvent = "Dissociate Node From Checklist";
export const ActivateNodeEvent = "Activate Node";
export const SuspendNodeEvent = "Suspend Node";
export const CreateHierarchyTypeEvent = "Create Hierarchy Type";
export const CreateNodeEvent = "Create Node";
export const SoftDeleteNodeEvent = "Soft Delete Node";
export const CreateNodeTypeEvent = "Create Node Type";
export const DeleteNodeTypeEvent = "Delete Node Type";
export const DeleteNodeTypeValueEvent = "Delete Node Type Value";
export const DemoteNodeEvent = "Demote Node";
export const PromoteNodeEvent = "Promote Node";
export const DissociateUserFromNodeEvent = "Dissociate User From Node";
export const EditHierarchyTypeEvent = "Edit Hierarchy Type";
export const EditNodeEvent = "Edit Node";
export const EditNodeTypeEvent = "Edit Node Type";
export const EditNodeTypeValueEvent = "Edit Node Type Value";
export const MoveNodeEvent = "Move Node";
export const AssociateChildNodeTypeEvent = "Associate Child Node Type";
export const DissociateChildNodeTypeEvent = "Dissociate Child Node Type";
export const AssociateLinkedNodeTypeEvent = "Associate Linked Node Type";
export const DissociateLinkedNodeTypeEvent = "Dissociate Linked Node Type";
export const ValidateHierarchyImportEvent = "Validate Hierarchy Import";
export const ProcessHierarchyImportEvent = "Process Hierarchy Import";
export const AssociateNodeToChecklistEvent = "Associate Node To Checklist";
export const AssociateTaxonomyNodeToChecklistEvent = "Associate Taxonomy Node To Checklist";
export const DissociateTaxonomyNodeFromChecklistEvent = "Dissociate Taxonomy Node From Checklist";
export const AssociateQuestionSetToChecklistEvent = "Associate Question Set To Checklist";
export const DissociateQuestionSetFromChecklistEvent = "Dissociate Question Set From Checklist";
export const AssociateRefLinkToChecklistEvent = "Associate Ref Link To Checklist";
export const DissociateRefLinkFromChecklistEvent = "Dissociate Ref Link From Checklist";
export const CreateChecklistEvent = "Create Checklist";
export const DeleteChecklistEvent = "Delete Checklist";
export const EdithecklistEvent = "Edit Checklist";
export const PromoteChecklistEvent = "Promote Checklist";
export const DemoteChecklistEvent = "Demote Checklist";
export const ReviewChecklistEvent = "Review Checklist";
export const CloneChecklistEvent = "Clone Checklist";
export const CloneChecklistAndQuestionSestEvent = "Clone Checklist and Question Sets";
export const SuspendChecklistAssignmentEvent = "Suspend Checklist Assignment";
export const ActivateChecklistAssignmentEvent = "Activate Checklist Assignment";
export const EditChecklistAssignmentEvent = "Edit Checklist Assignment";
export const EditQuestionSetScheduleEvent = "Edit Question Set Schedule";
export const PromoteChecklistAssignmentEvent = "Promote Checklist Assignment";
export const DemoteChecklistAssignmentEvent = "Demote Checklist Assignment";
export const CancelAndRescheduleChecklistAssignmentEvent =
    "Cancel And Reschedule Checklist Assignment";
export const EditChecklistAssignmentQuestionScheduleEvent =
    "Edit Checklist Assignment Question Schedule";
export const CreateAndAssociateQuestionSetToChecklistEvent =
    "Create And Associate Question Set To Checklist";
export const CreateQuestionSetEvent = "Create Question Set";
export const DeleteQuestionSetEvent = "Delete Question Set";
export const EditQuestionSetEvent = "Edit Question Set";
export const CloneQuestionSet = "Clone Question Set";
export const AssociateExistingQuestionToQuestionSetEvent = "Associate Existing Question To Question Set";
export const EditQuestionSetQuestionScheduleEvent = "Edit Question Set's Question Schedule";
export const DissociateQuestionSetQuestion = "Dissociate Question Set Question";
export const AssociateNewQuestionToQuestionSetEvent = "Associate New Question To Question Set";
export const AssociateRefLinkToQuestionSetEvent = "Associate Ref Link To Question Set";
export const AssociateNestedQuestionEvent = "Associate Nested Question";
export const DissociateNestedQuestionEvent = "Dissociate Nested Question";
export const CreateQuestionEvent = "Create Question";
export const DeleteQuestionEvent = "Delete Question";
export const EditNestedQuestionTriggerEvent = "Edit Nested Question Trigger";
export const EditQuestionEvent = "Edit Question";
