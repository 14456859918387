import { QuestionTypeResponse } from "../../responses/question-type/question-type-response";

export interface QuestionTypeDto {
    questionTypeId: number;
    name: string;
}

export const createQuestionTypeDto = (
    questionTypeResponse: QuestionTypeResponse
): QuestionTypeDto => ({
    questionTypeId: questionTypeResponse.questionTypeId,
    name: questionTypeResponse.name,
});

export const toQuestionTypeDto = (data: QuestionTypeResponse[]): QuestionTypeDto[] =>
    data.map((questionType) => createQuestionTypeDto(questionType));
