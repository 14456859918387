import { Response } from "../../../domain/responses/common/response-response";
import { RoleRestrictedNodeResponse } from "../../../domain/responses/hierarchy/role-restricted-node-response";
import client from "../../http-client";

const route = "rolerestrictednodes";

export const filterRoleRestrictedNodesByNodeTypeValueId = async (
    prefixUrl: string,
    nodeTypeValueId: number
): Promise<Response<RoleRestrictedNodeResponse[]>> => {
    return await client
        .get(`${route}/filter-by-node-type-value-id/${nodeTypeValueId}`, {
            prefixUrl: prefixUrl,
        })
        .json();
};
