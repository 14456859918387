import { useState } from "react";
import styled from "styled-components";
import local from "../../core/images/local.gif";
import { useMenu } from "../../core/store/menu-context";
import { getIcon } from "../../core/utilities/getIcon";

const StyledCenter = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledDrawerToggle = styled.div`
    height: ${(props) => props.theme.padding.xl};
    width: ${(props) => props.theme.padding.xl};
    background-color: ${(props) => props.theme.palette.white};
    border-radius: 64%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.palette.primary};
    cursor: pointer;
`;

const DrawerToggle: React.FC = () => {
    const menu = useMenu();
    const [activateEasterEgg, setActivateEasterEgg] = useState(0);
    const environment = process.env.REACT_APP_ENV;

    const onDrawerToggle = (): void => {
        menu.toggleDrawer(menu.drawerOpen);
        setActivateEasterEgg(activateEasterEgg + 1);
    };

    const renderDrawerToggle = (): JSX.Element => {
        if (activateEasterEgg >= 4 && environment !== "prod") {
            return (
                <>
                    <img src={local} alt="cato" height={32} width={32} />
                </>
            );
        } else {
            return <>{menu.drawerOpen ? getIcon("Drawer Open") : getIcon("Drawer Closed")}</>;
        }
    };

    return (
        <StyledCenter>
            <StyledDrawerToggle onClick={() => onDrawerToggle()}>
                {renderDrawerToggle()}
            </StyledDrawerToggle>
        </StyledCenter>
    );
};

export default DrawerToggle;
