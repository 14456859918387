import AnswerValueType from "../../enums/answer-types/answer-value-type";

export interface CreateActionItemDynamicFieldRequest {
    fieldName: string | null;
    actionItemTypeNodeId: number;
    valueType: AnswerValueType;
    listData: string | null;
    isRequired: boolean;
}

export const createCreateActionItemDynamicFieldRequest = (
    fieldName: string | null,
    actionItemTypeNodeId: number,
    valueType: AnswerValueType,
    listData: string | null,
    isRequired: boolean
): CreateActionItemDynamicFieldRequest => ({
    fieldName: fieldName,
    actionItemTypeNodeId: actionItemTypeNodeId,
    valueType: valueType,
    listData: listData,
    isRequired: isRequired,
});
