import {
    useMutation,
    UseMutationResult,
    useQueries,
    useQuery,
    UseQueryResult,
} from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    downloadActionPlanFile,
    editActionPlan,
    filterActionPlanFiles,
    getActionPlanDetails,
    removeActionPlanFile,
    uploadActionPlanFile,
} from "../../../data/services/actions/action-plans-service";
import { ActionPlanDetailsDto } from "../../dtos/actions/action-plan-details-dto";
import { EditActionPlanDto } from "../../dtos/actions/edit-action-plan-dto";
import { FileDownloadDto } from "../../dtos/file-storage/file-download-dto";
import { FileDto } from "../../dtos/file-storage/file-dto";
import { EditActionPlanRequest } from "../../requests/actions/edit-action-plan-request";
import { createUploadActionPlanFileRequest } from "../../requests/actions/upload-action-plan-file-request";
import FileDetailsUploadRequest from "../../requests/file-storage/file-details-upload-request";
import { Response } from "../../responses/common/response-response";

export const useGetActionPlanDetails = (
    actionPlanId: number
): [UseQueryResult<ActionPlanDetailsDto, HTTPError>, UseQueryResult<FileDto[], HTTPError>] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getActionPlanDetails", actionPlanId],
                queryFn: () => getActionPlanDetails(url.baseUrl, actionPlanId),
                select: ActionPlanDetailsDto.constructFromResponse,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["filterActionPlanFiles", actionPlanId],
                queryFn: () => filterActionPlanFiles(url.baseUrl, actionPlanId),
                select: FileDto.constructFromActionPlanFileDownloadResponses,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [UseQueryResult<ActionPlanDetailsDto, HTTPError>, UseQueryResult<FileDto[], HTTPError>];
};

export const useEditActionPlan = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    EditActionPlanDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: EditActionPlanDto) => {
        const request = new EditActionPlanRequest(mutationKey);

        return editActionPlan(url.baseUrl, request);
    });
};

interface UploadFileParameters {
    actionPlanId: number;
    file: File;
    fileBase64String: string;
}

export const useUploadActionPlanFile = (): UseMutationResult<
    Response<number | null>,
    HTTPError,
    UploadFileParameters
> => {
    const url = useUrl();

    return useMutation(
        async (mutationKey: UploadFileParameters): Promise<Response<number | null>> => {
            const uploadActionPlanFileRequest = createUploadActionPlanFileRequest(
                mutationKey.actionPlanId,
                new FileDetailsUploadRequest(mutationKey.file, mutationKey.fileBase64String)
            );

            return uploadActionPlanFile(url.baseUrl, uploadActionPlanFileRequest);
        }
    );
};

export const useRemoveActionPlanFile = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    number
> => {
    const url = useUrl();

    return useMutation((mutationKey: number) => removeActionPlanFile(url.baseUrl, mutationKey));
};

export const useDownloadActionPlanFile = (
    actionPlanFileId: number | null
): UseQueryResult<FileDownloadDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["downloadActionPlanFile", actionPlanFileId!],
        () => downloadActionPlanFile(url.baseUrl, actionPlanFileId!),
        {
            enabled: actionPlanFileId != null,
            select: FileDownloadDto.constructFromActionPlanFileDownloadResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};
