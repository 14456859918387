import { HTTPError } from "ky";
import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaSave } from "react-icons/fa";
import { useParams, useSearchParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { EditChecklistAssignmentEvent } from "../../../core/constants/application-insights-events";
import { ChecklistQuestions, Common, Nodes } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
    StyledHiddenOverflowForm,
} from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { convertToggleValueToBoolean } from "../../../core/utilities/convertToBoolean";
import { assignNodeValuesColumnNames } from "../../../core/utilities/dataTableColumns";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isMutationLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import EditChecklistAssignmentDto from "../../../domain/dtos/checklist-assignment/edit-checklist-assignment-dto";
import {
    createReactSelectDto,
    ReactSelectDto,
    valuesToReactSelectDtos,
} from "../../../domain/dtos/common/react-select-dto";
import { ChecklistRoleGroup, hasRoleTypeInGroup } from "../../../domain/enums/Roles";
import {
    useEditChecklistAssignment,
    useGetEditChecklistAssignmentDetails,
} from "../../../domain/viewmodels/checklist-assignment/edit-checklist-assignment-viewmodel";
import { SbButton } from "../../atoms/SbButton";
import { SbRibbon } from "../../atoms/SbRibbon";
import { translateText } from "../../helpers/translate";
import SbFormCheckFieldGroup from "../../molecules/input/SbFormCheckFieldGroup";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { SbAccordion } from "../../molecules/SbAccordion";
import { TextTitledPanel } from "../../molecules/SbPanel";
import { DataTable } from "../../organisms/DataTable";

const appliesToUsersOptions: string[] = ["Any", "Each"];

const EditChecklistAssignmentContainer: React.FC = () => {
    const [selectedAppliesToUser, setSelectedAppliesToUser] = useState<string>("");
    const [selectedNodeTypeValues, setSelectedNodeTypeValues] = useState<number[]>([]);
    const [isTraining, setIsTraining] = useState(false);

    const menu = useMenu();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const auth = useAuth();
    const { t } = useTranslation("translation");

    const [urlSearchParams] = useSearchParams();
    const navigateSearch = useNavigateSearch();

    const checklistAssignmentId = Number(useParams().checklistAssignmentId);
    const nodeId = Number(urlSearchParams.get("nodeId"));
    const checklistId = Number(urlSearchParams.get("checklistId"));

    const getEditChecklistAssignmentDetails = useGetEditChecklistAssignmentDetails(
        checklistAssignmentId,
        nodeId,
        checklistId
    );

    const checklistAssignmentDetailsResponseData = getEditChecklistAssignmentDetails[0].data!;
    const availableNodeTypeValuesResponseData = getEditChecklistAssignmentDetails[1].data!;
    const defaultNodeTypeValuesDetails = getEditChecklistAssignmentDetails[2];
    const checklistDetailsResponseData = getEditChecklistAssignmentDetails[3].data!;
    const nodeDetailsResponseData = getEditChecklistAssignmentDetails[4].data!;

    const editChecklistAssignment = useEditChecklistAssignment();

    useLoader(
        areQueriesLoading(getEditChecklistAssignmentDetails) ||
            isMutationLoading(editChecklistAssignment),
        EditChecklistAssignmentContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Checklists);
    }, []);

    useEffect(() => {
        if (isQuerySuccessful(getEditChecklistAssignmentDetails[0])) {
            setIsTraining(checklistAssignmentDetailsResponseData.isTraining);
        }
        if (isQuerySuccessful(defaultNodeTypeValuesDetails)) {
            setSelectedNodeTypeValues(defaultNodeTypeValuesDetails.data!);
        }
    }, [
        getEditChecklistAssignmentDetails[0].fetchStatus,
        defaultNodeTypeValuesDetails.fetchStatus,
    ]);

    const onAppliesToUserSelected = (item: SingleValue<{ value: string; label: string }>): void => {
        item && setSelectedAppliesToUser(item.value);
    };

    const handleIsTrainingChange = (_: string, checked: boolean): void => {
        setIsTraining(checked);
    };

    const selectNodeTypeValue = (nodeTypeValueId: number): void => {
        const selectedNodeTypeValue = nodeTypeValueId;
        const selectedNodeTypeValueArr = [...selectedNodeTypeValues];

        const index = selectedNodeTypeValueArr.indexOf(selectedNodeTypeValue);

        if (index > -1) {
            selectedNodeTypeValueArr.splice(index, 1);
        } else {
            selectedNodeTypeValueArr.push(selectedNodeTypeValue);
        }

        setSelectedNodeTypeValues(selectedNodeTypeValueArr);
    };

    const checkSelectedNodeTypeValues = (nodeTypeValueId: number): boolean => {
        const selectedNodeTypeValue = nodeTypeValueId;
        const selectedNodeTypeValueArr = [...selectedNodeTypeValues];

        return selectedNodeTypeValueArr.includes(selectedNodeTypeValue);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);

        editChecklistAssignment.mutate(
            new EditChecklistAssignmentDto(
                selectedAppliesToUser === "Any" ? 0 : 1,
                checklistAssignmentId,
                selectedNodeTypeValues,
                convertToggleValueToBoolean(formData.get("changesEffectiveToday")! as string),
                convertToggleValueToBoolean(formData.get("isTraining")! as string),
                convertToggleValueToBoolean(formData.get("allowActionItemTrigger")! as string),
                convertToggleValueToBoolean(formData.get("allowAdhocIntervalOverride")! as string),
                convertToggleValueToBoolean(formData.get("allowCustomNotifications")! as string),
                convertToggleValueToBoolean(formData.get("allowFileAttachment")! as string)
            ),
            {
                onSuccess: async () => {
                    trackAppInsightsEvent(
                        auth.email,
                        window.location.href,
                        EditChecklistAssignmentEvent
                    );
                    const params = [
                        createNavigateSearchParameter("nodeId", nodeId.toString()),
                        createNavigateSearchParameter("checklistId", checklistId.toString()),
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "messageKey",
                            "SuccessfullyUpdatedChecklistAssignment"
                        ),
                    ];

                    navigateSearch(
                        `${getPath(
                            AccordionTitles.Checklists
                        )}/${checklistAssignmentId}/checklist-assignment-details`,
                        params
                    );
                },
                onError: (error: HTTPError) => {
                    trackAppInsightsException(
                        auth.email,
                        window.location.href,
                        EditChecklistAssignmentEvent,
                        error
                    );
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    };

    return areQueriesSuccessful(getEditChecklistAssignmentDetails) ? (
        <>
            <PageHeading>{t("EditAssignment", { keyPrefix: Common })}</PageHeading>
            <SectionVerticalSpace />

            <StyledHiddenOverflowForm onSubmit={handleSubmit}>
                <TextTitledPanel title={t("EditAssignment", { keyPrefix: Common })}>
                    {isTraining && (
                        <SbRibbon size={"large"} label={t("Training", { keyPrefix: Common })} />
                    )}

                    <SbFormTextFieldGroup
                        name={"checklist"}
                        label={t("Checklist", { keyPrefix: ChecklistQuestions })}
                        type={"text"}
                        defaultValue={checklistDetailsResponseData.name}
                        disabled
                    />

                    <SbFormTextFieldGroup
                        name={"node"}
                        label={t("Node", { keyPrefix: ChecklistQuestions })}
                        type={"text"}
                        defaultValue={nodeDetailsResponseData.node.nodeTypeValue!.value!}
                        disabled
                    />

                    <SbFormCheckFieldGroup
                        fieldLabel={t("ChangesEffectiveToday", { keyPrefix: ChecklistQuestions })}
                        type="checkbox"
                        values={[
                            {
                                name: "changesEffectiveToday",
                            },
                        ]}
                    />
                    {hasRoleTypeInGroup(auth.userRoles, ChecklistRoleGroup.EditAssignmentRoles) && (
                        <>
                            <SbFormSelectFieldGroup
                                name={"appliesToUsers"}
                                label={t("AppliesToUsers", { keyPrefix: ChecklistQuestions })}
                                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                                searchable
                                clearable
                                required
                                items={valuesToReactSelectDtos(appliesToUsersOptions)}
                                itemDisplayText={(option: ReactSelectDto<string>) =>
                                    translateText(t, option.label, Common)
                                }
                                onChange={onAppliesToUserSelected}
                                defaultSelectedItem={createReactSelectDto(
                                    checklistAssignmentDetailsResponseData.appliesToUsers,
                                    checklistAssignmentDetailsResponseData.appliesToUsers
                                )}
                            />

                            <SbFormCheckFieldGroup
                                fieldLabel={t("AllowActionItemTrigger", {
                                    keyPrefix: ChecklistQuestions,
                                })}
                                type="checkbox"
                                values={[
                                    {
                                        name: "allowActionItemTrigger",
                                        defaultSelected:
                                            checklistAssignmentDetailsResponseData.allowActionItemTrigger,
                                    },
                                ]}
                            />

                            {checklistDetailsResponseData.hasAdhoc && (
                                <>
                                    <SbFormCheckFieldGroup
                                        fieldLabel={t("AllowAdhocIntervalOverride", {
                                            keyPrefix: ChecklistQuestions,
                                        })}
                                        type="checkbox"
                                        values={[
                                            {
                                                name: "allowAdhocIntervalOverride",
                                                defaultSelected:
                                                    checklistAssignmentDetailsResponseData.allowAdhocIntervalOverride,
                                            },
                                        ]}
                                    />
                                    <SbFormCheckFieldGroup
                                        fieldLabel={t("AllowCustomNotifications", {
                                            keyPrefix: ChecklistQuestions,
                                        })}
                                        type="checkbox"
                                        values={[
                                            {
                                                name: "allowCustomNotifications",
                                                defaultSelected:
                                                    checklistAssignmentDetailsResponseData.allowCustomNotifications,
                                            },
                                        ]}
                                    />
                                </>
                            )}

                            <SbFormCheckFieldGroup
                                fieldLabel={t("IsTrainingDisplay", { keyPrefix: Common })}
                                type="checkbox"
                                values={[
                                    {
                                        name: "isTraining",
                                        onChangeHandler: handleIsTrainingChange,
                                        defaultSelected: isTraining,
                                    },
                                ]}
                            />

                            <SbFormCheckFieldGroup
                                fieldLabel={t("AllowFIleAttachment", { keyPrefix: Common })}
                                type="checkbox"
                                values={[
                                    {
                                        name: "allowFileAttachment",
                                        defaultSelected:
                                            checklistAssignmentDetailsResponseData.allowFileAttachment,
                                    },
                                ]}
                            />
                        </>
                    )}

                    <SbAccordion title={t("ApplicableFunctions", { keyPrefix: Common })}>
                        <DataTable
                            noResultsMessage={`${t("NoNodeTypeValuesFound", { keyPrefix: Nodes })}`}
                            columns={assignNodeValuesColumnNames}
                            rows={availableNodeTypeValuesResponseData}
                            selectItem={selectNodeTypeValue}
                            selectedItem={checkSelectedNodeTypeValues}
                        />
                    </SbAccordion>
                    <SectionVerticalSpace />

                    <EndAlignedDiv>
                        <Col sm={"auto"}>
                            <SbButton
                                icon={FaSave}
                                variant={"primary"}
                                type="submit"
                                label={t("Save", { keyPrefix: Common })}
                            />
                        </Col>
                    </EndAlignedDiv>
                </TextTitledPanel>
            </StyledHiddenOverflowForm>
        </>
    ) : (
        <></>
    );
};

export default EditChecklistAssignmentContainer;
