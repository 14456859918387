import AppliesToUsers from "../../enums/checklists/AppliesToUsers";
import CreateQuestionSetActionRequest from "./create-question-set-action-request";

export interface AssociateChecklistNodeRequest {
    checklistId: number;
    nodeId: number;
    nodeTypeValueIds: number[];
    timeZoneNodeTypeValueId: number | null;
    appliesToUsers: AppliesToUsers;
    activeDateTimeLocal: Date;
    expiryDateTimeLocal?: Date;
    isTraining: boolean;
    allowActionItemTrigger: boolean;
    allowAdhocIntervalOverride: boolean;
    allowCustomNotifications: boolean;
    allowFileAttachment: boolean;
    userId: number;
    createQuestionSetActionRequests: CreateQuestionSetActionRequest[];
}

export const createAssociateChecklistNodeRequest = (
    checklistId: number,
    nodeId: number,
    nodeTypeValueIds: number[],
    timeZoneNodeTypeValueId: number | null,
    appliesToUsers: AppliesToUsers,
    activeDateTimeLocal: Date,
    isTraining: boolean,
    allowActionItemTrigger: boolean,
    allowAdhocIntervalOverride: boolean,
    allowCustomNotifications: boolean,
    allowFileAttachment: boolean,
    userId: number,
    createQuestionSetActionRequests: CreateQuestionSetActionRequest[],
    expiryDateTimeLocal?: Date
): AssociateChecklistNodeRequest => ({
    checklistId: checklistId,
    nodeId: nodeId,
    nodeTypeValueIds: nodeTypeValueIds,
    timeZoneNodeTypeValueId: timeZoneNodeTypeValueId,
    appliesToUsers: appliesToUsers,
    activeDateTimeLocal: activeDateTimeLocal,
    expiryDateTimeLocal: expiryDateTimeLocal,
    isTraining: isTraining,
    allowActionItemTrigger: allowActionItemTrigger,
    allowAdhocIntervalOverride: allowAdhocIntervalOverride,
    allowCustomNotifications: allowCustomNotifications,
    allowFileAttachment: allowFileAttachment,
    userId: userId,
    createQuestionSetActionRequests: createQuestionSetActionRequests,
});
