import { createPaginationDto, defaultPaginationDto, PaginationDto } from "../common/pagination-dto";

export interface FilterQuestionsDto extends PaginationDto {
    questionId: number | null;
    questionSetName: string | null;
    questionText: string | null;
    questionTypeId: number | null;
    questionCategoryId: number | null;
    answerTypeId: number | null;
}

export const createFilterQuestionsDto = (
    questionId: number | null,
    questionSetName: string | null,
    questionText: string | null,
    questionTypeId: number | null,
    questionCategoryId: number | null,
    answerTypeId: number | null,
    pageNumber: number,
    pageSize: number,
    sortByColumn: string | null,
    sortByDescending: boolean
): FilterQuestionsDto => ({
    questionId: questionId,
    questionSetName: questionSetName,
    questionText: questionText,
    questionTypeId: questionTypeId,
    questionCategoryId: questionCategoryId,
    answerTypeId: answerTypeId,
    ...createPaginationDto(pageNumber, pageSize, sortByColumn, sortByDescending),
});

export const defaultFilterQuestionsDto = (): FilterQuestionsDto => {
    const { pageNumber, pageSize, sortByColumn, sortByDescending } = defaultPaginationDto;

    return createFilterQuestionsDto(
        null,
        null,
        null,
        null,
        null,
        null,
        pageNumber,
        pageSize,
        sortByColumn,
        sortByDescending
    );
};
