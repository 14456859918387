import { createPaginationDto, defaultPaginationDto, PaginationDto } from "../common/pagination-dto";

export interface FilterAssociatedNodesDto extends PaginationDto {
    checklistAssignmentId: number | null;
    nodeSearchText: string | null;
}

export const createFilterAssociatedNodesDto = (
    checklistAssignmentId: number | null,
    nodeSearchText: string | null,
    pageNumber: number,
    pageSize: number,
    sortByColumn: string | null,
    sortByDescending: boolean
): FilterAssociatedNodesDto => ({
    checklistAssignmentId: checklistAssignmentId,
    nodeSearchText: nodeSearchText,
    ...createPaginationDto(pageNumber, pageSize, sortByColumn, sortByDescending),
});

export const defaultFilterAssociatedNodesDto = (): FilterAssociatedNodesDto => {
    const { pageNumber, pageSize, sortByColumn, sortByDescending } = defaultPaginationDto;

    return createFilterAssociatedNodesDto(
        null,
        null,
        pageNumber,
        pageSize,
        sortByColumn,
        sortByDescending
    );
};
