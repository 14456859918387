import React, { useEffect, useState } from "react";
import useLoader from "../../../core/hooks/loaderManager";
import {
    EndAlignedDiv,
    LargeVerticalSpace,
    NoDataStateDiv,
} from "../../../core/theme/global-styles";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import {
    BasePaginationDto,
    defaultBasePaginationDto,
} from "../../../domain/dtos/common/base-pagination-dto";
import { BaseNodeDetailsDto } from "../../../domain/dtos/hierarchy/base-node-details-dto";
import {
    useFilterAdhocQuestionSetSchedules,
    useFilterARMonitoringReferentialLinkTypes,
    useFilterUsersByNode,
    useGetNodeChecklistDefaultValuesData,
} from "../../../domain/viewmodels/trigger-adhocs/trigger-adhocs-viewmodel";
import Paginator from "../../atoms/Paginator";
import FunctionNodesSelector, {
    FunctionNodesSelectorTitleProps,
} from "../../organisms/trigger-adhocs/FunctionNodesSelector";
import TriggerQuestionSetContainer from "./TriggerQuestionSetContainer";

interface TriggerAdhocsSectionContainerProps extends FunctionNodesSelectorTitleProps {
    nodes: BaseNodeDetailsDto[];
    teamLeadView?: boolean;
}

const TriggerAdhocsSectionContainer: React.FC<TriggerAdhocsSectionContainerProps> = ({
    nodes,
    teamLeadView,
    panelTitle,
    infoAlertMessage,
    selectFieldLabel,
}) => {
    const [arMonitoring, setARMonitoring] = useState(false);
    const [paginationDto, setPaginationDto] = useState<BasePaginationDto>(defaultBasePaginationDto);
    const [nodeId, setNodeId] = useState<number | null>(null);
    const [nodeChecklistDefaultValuesDisabled, setNodeChecklistDefaultValuesDisabled] =
        useState(true);

    const isTeamLeadView = teamLeadView ?? false;

    const filterAdhocQuestionSetSchedules = useFilterAdhocQuestionSetSchedules(
        paginationDto,
        nodeId,
        arMonitoring
    );

    const filterUsersByNode = useFilterUsersByNode(nodeId, isTeamLeadView);

    const filterARMonitoringReferentialLinkTypes =
        useFilterARMonitoringReferentialLinkTypes(nodeId);

    const getNodeChecklistDefaultValuesData = useGetNodeChecklistDefaultValuesData(
        nodeId,
        arMonitoring
    );

    const nodeReferentialLinksResponseData = getNodeChecklistDefaultValuesData[0].data;
    const nodeAdhocIntervalOverrideResponseData = getNodeChecklistDefaultValuesData[1].data;

    const filterAdhocQuestionSetSchedulesResponseData = filterAdhocQuestionSetSchedules.data;

    useLoader(
        areQueriesLoading([
            filterAdhocQuestionSetSchedules,
            filterUsersByNode,
            ...getNodeChecklistDefaultValuesData,
        ]),
        TriggerAdhocsSectionContainer
    );

    useEffect(() => {
        if (!arMonitoring) {
            setNodeChecklistDefaultValuesDisabled(true);
        }

        queryClient.refetchQueries(["getNodeReferentialLinks", nodeId]);
        queryClient.refetchQueries(["getNodeAdhocIntervalOverride", nodeId]);

        queryClient.refetchQueries([
            "filterAdhocQuestionSetSchedules",
            nodeId,
            arMonitoring,
            paginationDto,
        ]);

        setPaginationDto({
            ...paginationDto,
            pageNumber: 1,
        });
    }, [arMonitoring]);

    const queriesSuccessful =
        areQueriesSuccessful([filterAdhocQuestionSetSchedules]) &&
        isTeamLeadView === isQuerySuccessful(filterUsersByNode);

    const onNodeSelected = (nodeId: number): void => {
        setNodeId(nodeId);
        setARMonitoring(false);

        setPaginationDto({
            ...paginationDto,
            pageNumber: 1,
        });
    };

    const handleARMonitoringChange = (_: string, checked: boolean): void =>
        setARMonitoring(checked);

    const arMonitoringCheckValues = [
        {
            name: "arMonitoring",
            label: "",
            onChangeHandler: handleARMonitoringChange,
        },
    ];

    return (
        <>
            <FunctionNodesSelector
                panelTitle={panelTitle}
                infoAlertMessage={infoAlertMessage}
                selectFieldLabel={selectFieldLabel}
                nodes={nodes}
                onNodeSelected={onNodeSelected}
                arMonitoringCheckValues={arMonitoringCheckValues}
                arMonitoring={arMonitoring}
                arMonitoringReferentialLinkWithTypeDto={filterARMonitoringReferentialLinkTypes.data}
                nodeChecklistDefaultValuesDisabled={nodeChecklistDefaultValuesDisabled}
                setNodeChecklistDefaultValuesDisabled={setNodeChecklistDefaultValuesDisabled}
                selectedNodeId={nodeId}
                nodeReferentialLinksResponseData={nodeReferentialLinksResponseData}
                nodeAdhocIntervalOverrideResponseData={nodeAdhocIntervalOverrideResponseData}
            />

            {queriesSuccessful && (
                <>
                    {filterAdhocQuestionSetSchedulesResponseData!.results.map((x, index) => (
                        <React.Fragment key={index}>
                            <LargeVerticalSpace />
                            <TriggerQuestionSetContainer
                                adhocQuestionSetAssignmentScheduleDto={x}
                                functionUserDtos={filterUsersByNode.data}
                                nodeId={nodeId!}
                                arMonitoring={arMonitoring}
                                nodeReferentialLinksResponseData={nodeReferentialLinksResponseData}
                                nodeAdhocIntervalOverrideResponseData={
                                    nodeAdhocIntervalOverrideResponseData
                                }
                            />
                        </React.Fragment>
                    ))}
                    <LargeVerticalSpace />

                    {filterAdhocQuestionSetSchedulesResponseData!.recordCount ? (
                        <EndAlignedDiv>
                            <Paginator
                                totalItems={
                                    filterAdhocQuestionSetSchedulesResponseData!.recordCount
                                }
                                paginationDto={paginationDto}
                                setPaginationProps={setPaginationDto}
                            />
                        </EndAlignedDiv>
                    ) : (
                        <NoDataStateDiv>{"No results found"}</NoDataStateDiv>
                    )}
                </>
            )}
        </>
    );
};

export default TriggerAdhocsSectionContainer;
