import { AnswerDetailsDto } from "../../dtos/answer-summary/answer-details-dto";
import { AnswerSummaryType } from "../../enums/answer-summary/AnswerSummaryType";
import InstanceStatus from "../../enums/questions/instance-status";

export class AnswerDetailsRequest {
    instanceStatus: InstanceStatus;
    nodeId: number;
    completedDateTime: Date | null;
    answerSummaryType: AnswerSummaryType;
    isTraining: string;

    public constructor(dto: AnswerDetailsDto) {
        this.instanceStatus = dto.instanceStatus;
        this.nodeId = dto.nodeId;
        this.completedDateTime = dto.completedDateTime;
        this.answerSummaryType = dto.answerSummaryType;
        this.isTraining = dto.isTraining;
    }

    public static buildAnswerDetailsSearchParameters = (
        request: AnswerDetailsRequest
    ): URLSearchParams => {
        const searchParams = new URLSearchParams();

        searchParams.set("instanceStatus", request.instanceStatus.toString());
        searchParams.set("nodeId", request.nodeId.toString());
        searchParams.set("answerSummaryType", request.answerSummaryType.toString());
        searchParams.set("isTraining", request.isTraining.toString());

        return searchParams;
    };
}
