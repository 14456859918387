import { useEffect, useState } from "react";
import BaseQuestionAnswerDto from "../../../domain/dtos/answer-capture/base-question-answer-dto";
import IndicatorAnswerDto from "../../../domain/dtos/answer-capture/indicator-answer-dto";
import QuestionAnswerInstanceDto from "../../../domain/dtos/answer-capture/question-answer-instance-dto";
import AnswerTypeDto from "../../../domain/dtos/answer-types/answer-type-dto";
import AnswerValueType from "../../../domain/enums/answer-types/answer-value-type";
import { useAnswerCapture } from "../../../domain/stores/answer-capture-context";
import SbCheckField from "../../atoms/input/SbCheckField";
import SbDateTimeField from "../../atoms/input/SbDateTimeField";
import SbDecimalField from "../../atoms/input/SbDecimalField";
import SbNumberBox from "../../atoms/input/SbNumberBox";
import SbTextArea from "../../atoms/input/SbTextArea";
import SbTextField from "../../atoms/input/SbTextField";

const AnswerCaptureInputValueInput: React.FC<{
    answerTypeDto: AnswerTypeDto;
    questionAnswerInstanceDto: QuestionAnswerInstanceDto;
    onIndicatorAnswerChanged: <TType>(_: IndicatorAnswerDto<TType>) => void;
}> = ({ answerTypeDto, questionAnswerInstanceDto, onIndicatorAnswerChanged }) => {
    const { answerValueType } = answerTypeDto;

    const [savedQuestionAnswerDto, setSavedQuestionAnswerDto] = useState<
        BaseQuestionAnswerDto | undefined
    >();

    const answerCapture = useAnswerCapture();

    useEffect(() => {
        if (savedQuestionAnswerDto) {
            return;
        }

        const savedAnswer = answerCapture.getQuestionAnswer(questionAnswerInstanceDto);
        setSavedQuestionAnswerDto(savedAnswer);

        if (savedAnswer) {
            switch (answerValueType) {
                case AnswerValueType.Integer:
                    onIndicatorAnswerChanged(
                        new IndicatorAnswerDto(AnswerValueType.Integer, savedAnswer.intValue)
                    );
                    break;

                case AnswerValueType.Decimal:
                    onIndicatorAnswerChanged(
                        new IndicatorAnswerDto(AnswerValueType.Decimal, savedAnswer.decimalValue)
                    );
                    break;

                case AnswerValueType.DateTime:
                    onIndicatorAnswerChanged(
                        new IndicatorAnswerDto(
                            AnswerValueType.DateTime,
                            savedAnswer.dateTimeValueLocal
                        )
                    );
                    break;

                case AnswerValueType.Boolean:
                    onIndicatorAnswerChanged(
                        new IndicatorAnswerDto(AnswerValueType.Boolean, savedAnswer.boolValue)
                    );
                    break;

                case (AnswerValueType.Text, AnswerValueType.TextArea):
                    onIndicatorAnswerChanged(
                        new IndicatorAnswerDto(AnswerValueType.Text, savedAnswer.textValue)
                    );
                    break;
            }
        }
    }, [questionAnswerInstanceDto.getKey()]);

    const onInputValueAnswerChanged = <TType,>(answerValue: TType): void => {
        onIndicatorAnswerChanged(new IndicatorAnswerDto(answerValueType!, answerValue));

        answerCapture.updateQuestionAnswerDtosForInputValueAnswer(
            questionAnswerInstanceDto,
            answerValueType!,
            answerValue
        );
    };

    const onNumberValueAnswerChanged = (input: number | null): void =>
        onInputValueAnswerChanged(input);

    const onBooleanValueAnswerChanged = (_: string, checked: boolean): void =>
        onInputValueAnswerChanged(checked);

    const onTextValueAnswerChanged = (input: string | null): void =>
        onInputValueAnswerChanged(input);

    switch (answerValueType) {
        case AnswerValueType.Integer:
            return (
                <SbNumberBox
                    name={"integerAnswer"}
                    placeholder={"Please enter a value"}
                    defaultValue={savedQuestionAnswerDto?.intValue}
                    onInputChange={onNumberValueAnswerChanged}
                />
            );

        case AnswerValueType.Decimal:
            return (
                <SbDecimalField
                    name={"decimalAnswer"}
                    placeholder={"0.00"}
                    defaultValue={savedQuestionAnswerDto?.decimalValue}
                    onInputChange={onNumberValueAnswerChanged}
                />
            );

        case AnswerValueType.DateTime:
            return (
                <SbDateTimeField
                    name={"dateAnswer"}
                    defaultValue={savedQuestionAnswerDto?.dateTimeValueLocal}
                    onInputChange={onInputValueAnswerChanged}
                />
            );

        case AnswerValueType.Boolean:
            return (
                <SbCheckField
                    name={"checkboxAnswer"}
                    type={"checkbox"}
                    defaultSelected={savedQuestionAnswerDto?.boolValue}
                    onChangeHandler={onBooleanValueAnswerChanged}
                />
            );

        case AnswerValueType.Text:
            return (
                <SbTextField
                    name={"textAnswer"}
                    type={"text"}
                    defaultValue={savedQuestionAnswerDto?.textValue}
                    onTextChanged={onTextValueAnswerChanged}
                />
            );

        case AnswerValueType.TextArea:
            return (
                <SbTextArea
                    name={"answerTextArea"}
                    rows={4}
                    maxLength={3000}
                    placeholder={"Please enter answer"}
                    defaultValue={savedQuestionAnswerDto?.textValue}
                    onChange={onTextValueAnswerChanged}
                />
            );
        default:
            return <></>;
    }
};

export default AnswerCaptureInputValueInput;
