import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    deleteCheckListFromReview,
    getQuestionSetInstanceReviewChecklist,
} from "../../../data/services/review-reports/review-reports-service";
import { QuestionSetInstanceReviewDto } from "../../dtos/review-reports/question-set-instance-review-dto";
import { Response } from "../../responses/common/response-response";

export const useGetQuestionSetInstanceReviewChecklist = (
    questionSetInstanceId: number
): UseQueryResult<QuestionSetInstanceReviewDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getQuestionSetInstanceReviewChecklist", questionSetInstanceId],
        () => getQuestionSetInstanceReviewChecklist(url.baseUrl, questionSetInstanceId),
        {
            keepPreviousData: true,
            select: QuestionSetInstanceReviewDto.constructFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useDeleteCheckListFromReview = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    number
> => {
    const url = useUrl();

    return useMutation((mutationKey: number) => {
        return deleteCheckListFromReview(url.baseUrl, mutationKey);
    });
};
