import { CancelMyActionItemDto } from "../../dtos/action-items/cancel-my-action-item-dto";
import { CancelActionItemDto } from "../../dtos/actions/cancel-action-item-dto";

export class CancelActionItemRequest {
    actionItemInstanceId: number;
    comment: string;

    public constructor(dto: CancelActionItemDto | CancelMyActionItemDto) {
        const { actionItemInstanceId, comment } = dto;

        this.actionItemInstanceId = actionItemInstanceId;
        this.comment = comment;
    }
}
