import React from "react";
import { Col, Row } from "react-bootstrap";
import {
    DetailsLabel,
    DetailsValue,
    StyledBorderedSection,
} from "../../../core/theme/global-styles";
import QuestionAnswerDetailsDto from "../../../domain/dtos/answer-capture/question-answer-details-dto";

const QuestionAnswerDetails: React.FC<{
    questionAnswerDetailsDto: QuestionAnswerDetailsDto | undefined;
}> = ({ questionAnswerDetailsDto }) => {
    const buildLabelValueMap = (): Map<string, string> => {
        const {
            questionImportance,
            questionCategory,
            path,
            assignedUserEmailsDelimitered,
            escalationLevelThreeUserEmailsDelimitered,
            escalationLevelTwoUserEmailsDelimitered,
            escalationLevelOneUserEmailsDelimitered,
        } = questionAnswerDetailsDto!;

        return new Map<string, string>([
            ["Path", path],
            ["Assigned Users", assignedUserEmailsDelimitered],
            ["Escalation Level 1 Users", escalationLevelOneUserEmailsDelimitered],
            ["Escalation Level 2 Users", escalationLevelTwoUserEmailsDelimitered],
            ["Escalation Level 3 Users", escalationLevelThreeUserEmailsDelimitered],
            ["Question Category", questionCategory],
            ["Question Importance", questionImportance],
        ]);
    };

    return (
        <>
            {questionAnswerDetailsDto ? (
                <StyledBorderedSection>
                    {Array.from(buildLabelValueMap().entries()).map((x, index) => (
                        <Row key={index}>
                            <Col md="2">
                                <DetailsLabel>{x[0]}</DetailsLabel>
                            </Col>
                            <Col md="10">
                                <DetailsValue>{x[1]}</DetailsValue>
                            </Col>
                        </Row>
                    ))}
                </StyledBorderedSection>
            ) : (
                <></>
            )}
        </>
    );
};

export default QuestionAnswerDetails;
