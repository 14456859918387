import { EditNestedQuestionTriggerSelectionDto } from "../../dtos/questions/edit-nested-question-trigger-selection-dto";

export class EditNestedQuestionTriggerSelectionRequest {
    nestedQuestionId: number;
    triggerWhenParentDesirable: boolean;

    public constructor(dto: EditNestedQuestionTriggerSelectionDto) {
        this.nestedQuestionId = dto.nestedQuestionId;
        this.triggerWhenParentDesirable = dto.triggerWhenParentDesirable;
    }
}
