export interface AssociateDissociateChecklistTaxonomyNodeRequest {
    checklistId: number;
    nodeId: number;
}

export const createAssociateDissociateChecklistTaxonomyNodeRequest = (
    checklistId: number,
    nodeId: number
): AssociateDissociateChecklistTaxonomyNodeRequest => ({
    checklistId: checklistId,
    nodeId: nodeId,
});
