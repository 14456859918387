import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import { filterReviewReportSetHistory } from "../../../data/services/review-reports/review-reports-history-service";
import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { FilterReviewReportSetHistoryDto } from "../../dtos/review-reports/filter-review-report-set-history-dto";
import ReviewReportSetHistoryDto from "../../dtos/review-reports/review-report-set-history-dto";
import { toRatingLabel } from "../../enums/review-reports/review-ratings";
import ReviewStatus from "../../enums/review-reports/review-status";
import { createFilterReviewReportSetRequest } from "../../requests/review-reports/filter-review-report-set-history-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import ReviewReportSetHistoryResponse from "../../responses/review-reports/review-report-set-history-response";

export const useFilterReviewReportSetsHistory = (
    basePaginationDto: BasePaginationDto,
    filterReviewReportSetHistoryDto: FilterReviewReportSetHistoryDto
): UseQueryResult<PaginatedTableDto<ReviewReportSetHistoryDto>, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterReviewReportSetHistory", basePaginationDto, filterReviewReportSetHistoryDto],
        () =>
            filterReviewReportSetHistory(
                url.baseUrl,
                createFilterReviewReportSetRequest(
                    filterReviewReportSetHistoryDto,
                    basePaginationDto
                )
            ),
        {
            keepPreviousData: true,
            select: transformToDataTableRows,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const transformToDataTableRows = (
    response: Response<PaginationResponse<ReviewReportSetHistoryResponse>>
): PaginatedTableDto<ReviewReportSetHistoryDto> => {
    const rows = response.data.results!.map((reviewReportSet) => {
        return {
            metadata: new ReviewReportSetHistoryDto(reviewReportSet),
            columns: [
                {
                    value: reviewReportSet.reviewReportName.toString(),
                    type: DataTableColumnTypes.Text,
                },
                {
                    value: toRatingLabel(reviewReportSet.reviewRating),
                    type: DataTableColumnTypes.Text,
                },
                {
                    value: ReviewStatus[reviewReportSet.reviewStatus].toString(),
                    type: DataTableColumnTypes.Text,
                },
                {
                    value: reviewReportSet.datePublishedUtc
                        ? toDateAndTimeFormat(reviewReportSet.datePublishedUtc.toString())
                        : "",
                    type: DataTableColumnTypes.Text,
                },
            ],
        };
    });

    return {
        numberOfPages: response.data.pageCount,
        pageSize: response.data.pageSize,
        currentPage: response.data.currentPage,
        recordCount: response.data.recordCount,
        rows: rows,
    };
};
