import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import ChecklistType from "../../enums/checklists/ChecklistType";
import { ChecklistResponse } from "../../responses/checklists/checklist-response";
import { Response } from "../../responses/common/response-response";

export interface ChecklistDto {
    checklistId: number;
    name: string;
    description: string;
    checklistType: ChecklistType;
    dateCreatedLocal: string;
    dateModifiedLocal: string;
    modifiedByUserName: string;
    createdByUserName: string;
    createdByUserFullName: string;
    modifiedByUserFullName: string;
    isTraining: boolean;
    lastReviewedDateTimeLocal: string;
    lastReviewedByUserName: string;
}

export const createChecklistDto = (response: Response<ChecklistResponse>): ChecklistDto => {
    const responseData = response.data;

    return createChecklistDtoFromResponse(responseData);
};

export const createChecklistDtoFromResponse = (response: ChecklistResponse): ChecklistDto => {
    return {
        checklistId: response.checklistId,
        name: response.name,
        description: response.description,
        checklistType: response.checklistType,
        dateCreatedLocal: toDateAndTimeFormat(response.dateCreatedUtc.toString()),
        dateModifiedLocal: toDateAndTimeFormat(response.dateModifiedUtc.toString()),
        modifiedByUserName: response.modifiedByUserName,
        createdByUserName: response.createdByUserName,
        createdByUserFullName: response.createdByUserFullName,
        modifiedByUserFullName: response.modifiedByUserFullName,
        isTraining: response.isTraining,
        lastReviewedDateTimeLocal: toDateAndTimeFormat(
            response.lastReviewedDateTimeUtc?.toString() ?? ""
        ),
        lastReviewedByUserName: response.lastReviewedByUserName,
    };
};
