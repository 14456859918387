import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { ActionInstanceCommentStatus } from "../../enums/action-items/ActionInstanceCommentStatus";
import { ActionItemInstanceCommentResponse } from "../../responses/action-items/action-item-instance-comment-response";
import { Response } from "../../responses/common/response-response";
import { UserResponse } from "../../responses/users/user-response";
import { createUserDto, UserDto } from "../users/user-dto";

export class ActionItemInstanceCommentDetailsDto {
    actionItemInstanceCommentId: number;
    commentDateTimeLocal: string;
    commentText: string;
    actionItemInstanceId: number;
    actionInstanceCommentStatus: ActionInstanceCommentStatus;
    userDto: UserDto;

    public constructor(
        actionItemInstanceCommentId: number,
        commentDateTimeUtc: Date,
        commentText: string,
        actionItemInstanceId: number,
        actionInstanceCommentStatus: ActionInstanceCommentStatus,
        userResponse: UserResponse
    ) {
        this.actionItemInstanceCommentId = actionItemInstanceCommentId;
        this.commentDateTimeLocal = toDateAndTimeFormat(commentDateTimeUtc.toString());
        this.commentText = commentText;
        this.actionItemInstanceId = actionItemInstanceId;
        this.actionInstanceCommentStatus = actionInstanceCommentStatus;
        this.userDto = createUserDto(userResponse);
    }

    public static constructFromResponse = (
        response: Response<ActionItemInstanceCommentResponse[]>
    ): ActionItemInstanceCommentDetailsDto[] => {
        const responseData = response.data;

        return responseData.map(
            (x) =>
                new ActionItemInstanceCommentDetailsDto(
                    x.actionItemInstanceCommentId,
                    x.commentDateTimeUtc,
                    x.commentText,
                    x.actionItemInstanceId,
                    x.actionInstanceCommentStatus,
                    x.userResponse
                )
        );
    };
}
