import { Col, Form, Row } from "react-bootstrap";
import { StyledFormLabel } from "../../../core/theme/global-styles";
import SbLabelText from "../../atoms/SbLabelText";
import { SbLink } from "../../atoms/SbLink";

const SbFormSbLinkFieldGroup: React.FC<{
    label: string;
    value: string;
    navigateToUrl: string;
}> = ({ label, value, navigateToUrl }) => {
    return (
        <Form.Group as={Row} className="mb-3">
            <StyledFormLabel FormLabel column sm={2}>
                <SbLabelText label={label} required />
            </StyledFormLabel>
            <Col sm={4}>
                <SbLink variant="primary" label={value} navigateTo={navigateToUrl} />
            </Col>
        </Form.Group>
    );
};

export default SbFormSbLinkFieldGroup;
