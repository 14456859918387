import { localDateSerialiserReplacer } from "../../../core/utilities/serialiser";
import EditChecklistAssignmentQuestionSchedulesRequest from "../../../domain/requests/checklist-assignment/edit-checklist-assignment-question-schedules-request";
import EditChecklistAssignmentQuestionSetScheduleRequest from "../../../domain/requests/checklist-assignment/edit-checklist-assignment-question-set-schedule-request";
import EditChecklistAssignmentRequest from "../../../domain/requests/checklist-assignment/edit-checklist-assignment-request";
import { PromoteDemoteChecklistAssignmentRequest } from "../../../domain/requests/checklist-assignment/promote-demote-checklist-assignment-request";
import { SuspendChecklistAssignmentRequest } from "../../../domain/requests/checklist-assignment/suspend-checklist-assignment-request";
import { ChecklistAssignmentDetailsResponse } from "../../../domain/responses/checklist-assignment/checklist-assignment-details-response";
import { ChecklistAssignmentNodeTypeValueResponse } from "../../../domain/responses/checklist-assignment/checklist-assignment-node-type-value-response";
import InheritedChecklistAssignmentResponse from "../../../domain/responses/checklist-assignment/inherited-checklist-assignment-response";
import { Response } from "../../../domain/responses/common/response-response";
import client from "../../http-client";

const route = "checklistassignment";

export const getChecklistAssignmentDetails = async (
    prefixUrl: string,
    checklistAssignmentId: number
): Promise<Response<ChecklistAssignmentDetailsResponse>> =>
    await client
        .get(`${route}/${checklistAssignmentId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const suspendChecklistAssignment = async (
    prefixUrl: string,
    request: SuspendChecklistAssignmentRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/suspend`, {
            body: JSON.stringify(
                request,
                localDateSerialiserReplacer(["suspensionDateTimeFrom", "suspensionDateTimeTo"])
            ),
            prefixUrl: prefixUrl,
        })
        .json();

export const activateChecklistAssignment = async (
    prefixUrl: string,
    checklistAssignmentId: number
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/activate/${checklistAssignmentId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const promoteDemoteChecklistAssignment = async (
    prefixUrl: string,
    request: PromoteDemoteChecklistAssignmentRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/promote-demote`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const cancelAndRescheduleChecklistAssignment = async (
    prefixUrl: string,
    checklistAssignmentId: number
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/cancel-and-reschedule/${checklistAssignmentId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const editSchedule = async (
    prefixUrl: string,
    request: EditChecklistAssignmentQuestionSetScheduleRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/edit-schedule`, {
            body: JSON.stringify(
                request,
                localDateSerialiserReplacer(["activeDateNoTimeZone", "expiryDateNoTimeZone"])
            ),
            prefixUrl: prefixUrl,
        })
        .json();

export const editQuestionSchedule = async (
    prefixUrl: string,
    request: EditChecklistAssignmentQuestionSchedulesRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/edit-question-schedule`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const GetApplicableHierarchyLevelValues = async (
    prefixUrl: string,
    checklistAssignmentId: number
): Promise<Response<ChecklistAssignmentNodeTypeValueResponse[]>> =>
    await client
        .get(`${route}/applicable-hierarchy-level-values/${checklistAssignmentId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const getInheritedChecklistAssignments = async (
    prefixUrl: string,
    nodeId: number
): Promise<Response<InheritedChecklistAssignmentResponse[]>> => {
    const searchParams = new URLSearchParams();
    searchParams.set("nodeId", nodeId.toString());

    return await client
        .get(`${route}/inherited`, {
            prefixUrl: prefixUrl,
            searchParams: searchParams,
        })
        .json();
};

export const editChecklistAssignment = async (
    prefixUrl: string,
    request: EditChecklistAssignmentRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/edit`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();
