import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { createWizardCard } from "../../../data/services/wizards/wizard-cards-service";
import { Response } from "../../responses/common/response-response";

interface CreateParameters {
    wizardId: number;
    fileDetailsId: number;
    formData: FormData;
}

export const useCreateWizardCard = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    CreateParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: CreateParameters) => {
        const formData = mutationKey.formData;

        const request = {
            heading: formData.get("heading")! as string,
            description: formData.get("description")! as string,
            wizardId: mutationKey.wizardId,
            fileDetailsId: mutationKey.fileDetailsId,
        };

        return createWizardCard(url.originalBaseUrl, request);
    });
};
