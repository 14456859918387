import { CreateNodeTypeValueRequest } from "./create-node-type-value-request";

export interface NodeTypeValueModificationRequest {
    nodeTypeValueId: number | null;
    createNodeTypeValueRequest: CreateNodeTypeValueRequest | null;
}

export const createNodeTypeValueModificationRequest = (
    nodeTypeValueId: number | null,
    createNodeTypeValueRequest: CreateNodeTypeValueRequest | null
): NodeTypeValueModificationRequest => ({
    nodeTypeValueId: nodeTypeValueId,
    createNodeTypeValueRequest: createNodeTypeValueRequest,
});
