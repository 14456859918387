import { EscalationLevel } from "../../enums/hierarchy/EscalationLevel";
import { HierarchyAssignable } from "../../enums/hierarchy/HierarchyAssignable";

export interface CreateNodeTypeRequest {
    name: string;
    hierarchyTypeId: number;
    parentNodeTypeId: number;
    containsTimeZoneCode: boolean;
    hierarchyAssignables: HierarchyAssignable[];
    escalationLevels: EscalationLevel[];
}

export const createCreateNodeTypeRequest = (
    name: string,
    hierarchyTypeId: number,
    parentNodeTypeId: number,
    containsTimeZoneCode: boolean,
    hierarchyAssignables: HierarchyAssignable[],
    escalationLevels: EscalationLevel[]
): CreateNodeTypeRequest => ({
    name: name,
    hierarchyTypeId: hierarchyTypeId,
    parentNodeTypeId: parentNodeTypeId,
    containsTimeZoneCode: containsTimeZoneCode,
    hierarchyAssignables: hierarchyAssignables,
    escalationLevels: escalationLevels,
});
