import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Tenants } from "../../../core/constants/translation-namespace";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { tenantManagementColumnNames } from "../../../core/utilities/dataTableColumns";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import { useFilterTenantsWithUserCounts } from "../../../domain/viewmodels/tenants/view-tenants-viewmodel";
import { DataTable } from "../../organisms/DataTable";

const TenantManagementContainer: React.FC = () => {
    const menu = useMenu();
    const navigate = useNavigate();
    const { t } = useTranslation("translation", { keyPrefix: Tenants });

    const filterTenants = useFilterTenantsWithUserCounts();

    useLoader(isQueryLoading(filterTenants), TenantManagementContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.TenantManagement);
    }, []);

    return (
        <>
            <PageHeading>{t("TenantManagement")}</PageHeading>
            <PageSubHeading>{t("HeaderHelpText")}.</PageSubHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(filterTenants) && (
                <DataTable
                    columns={tenantManagementColumnNames}
                    keyPrefix={Tenants}
                    rows={filterTenants.data}
                    viewItem={(tenantId) =>
                        navigate(`${getPath(DrawerTitles.TenantManagement)}/${tenantId}`)
                    }
                    editItem={(id: number) => navigate(`/tenants/${id}/edit`)}
                />
            )}
        </>
    );
};

export default TenantManagementContainer;
