import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { getWizardCards } from "../../../data/services/wizards/wizard-cards-service";
import { Response } from "../../../domain/responses/common/response-response";
import { toWizardCardDto, WizardCardDto } from "../../dtos/wizards/wizard-card-dto";
import { WizardCardResponse } from "../../responses/wizards/wizard-card-response";

export const useGetWizardCards = (wizardId: number): UseQueryResult<WizardCardDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getWizardCards", wizardId],
        () => getWizardCards(url.originalBaseUrl, wizardId),
        {
            keepPreviousData: true,
            select: transformToWizardCardDto,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const transformToWizardCardDto = (data: Response<WizardCardResponse[]>): WizardCardDto[] => {
    const responseData = data.data;

    return responseData.map((wizardCard) => toWizardCardDto(wizardCard));
};
