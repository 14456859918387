import {
    useMutation,
    UseMutationResult,
    useQueries,
    useQuery,
    UseQueryResult,
} from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    cancelActionItem,
    completeActionItem,
    dissociateNode,
    downloadActionItemInstanceFile,
    extendActionItem,
    filterAssociatedRaciSetUsers,
    getActionItemDetails,
    getActionItemInstanceFiles,
    getAdditionalFieldValues,
    placeOnHold,
    reactivateOnHold,
    removeActionItemInstanceFile,
    uploadActionItemInstanceFile,
} from "../../../data/services/actions/action-items-service";
import { downloadFile } from "../../../data/services/file-details/file-details-service";
import { ActionItemInstanceFileUploadDto } from "../../dtos/action-items/action-item-instance-file-upload-dto";
import { CancelMyActionItemDto } from "../../dtos/action-items/cancel-my-action-item-dto";
import { CompleteMyActionItemDto } from "../../dtos/action-items/complete-my-action-item-dto";
import { ExtendActionItemDto } from "../../dtos/action-items/extend-action-item-dto";
import { PlaceOnHoldActionItemDto } from "../../dtos/action-items/place-on-hold-action-item-dto";
import { ReactivateOnHoldActionItemDto } from "../../dtos/action-items/reactivate-on-hold-action-item-dto";
import { ActionItemDetailsDto } from "../../dtos/actions/action-item-details-dto";
import { ActionItemDynamicFieldValueDto } from "../../dtos/actions/action-item-dynamic-field-values-dto";
import { ActionItemRaciSetUserDto } from "../../dtos/actions/action-item-raci-set-user-dto";
import { DissociateNodeToActionItemDto } from "../../dtos/actions/dissociate-node-to-action-item-dto";
import { FileDownloadDto } from "../../dtos/file-storage/file-download-dto";
import { FileDto } from "../../dtos/file-storage/file-dto";
import { CompleteActionItemRequest } from "../../requests/action-items/complete-action-item-request";
import { ExtendActionItemRequest } from "../../requests/action-items/extend-action-item-request";
import { PlaceOnHoldActionItemRequest } from "../../requests/action-items/place-on-hold-action-item-request";
import { ReactivateOnHoldActionItemRequest } from "../../requests/action-items/reactivate-on-hold-action-item-request";
import { createActionItemInstanceFileUploadRequest } from "../../requests/actions/action-item-instance-file-upload-request";
import { CancelActionItemRequest } from "../../requests/actions/cancel-action-item-request";
import { DissociateNodeToActionItemRequest } from "../../requests/actions/dissociate-node-to-action-item-request";
import { createFilterAssociatedRaciSetUsersRequest } from "../../requests/actions/filter-associated-raci-set-users-request";
import { createBasePaginationRequest } from "../../requests/common/base-pagination-request";
import FileDetailsUploadRequest from "../../requests/file-storage/file-details-upload-request";
import { Response } from "../../responses/common/response-response";

export const useGetMyActionItemDetails = (
    actionItemId: number,
    actionItemInstanceId: number
): [
    UseQueryResult<ActionItemDetailsDto, HTTPError>,
    UseQueryResult<ActionItemRaciSetUserDto[], HTTPError>,
    UseQueryResult<ActionItemDynamicFieldValueDto[], HTTPError>,
    UseQueryResult<FileDto[], HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getActionItemDetails", actionItemId],
                queryFn: () => getActionItemDetails(url.baseUrl, actionItemId),
                select: ActionItemDetailsDto.constructFromResponse,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["filterAssociatedRaciSetUsers", actionItemId],
                queryFn: () =>
                    filterAssociatedRaciSetUsers(
                        url.baseUrl,
                        createFilterAssociatedRaciSetUsersRequest(
                            createBasePaginationRequest(1, 1000),
                            actionItemId
                        )
                    ),
                keepPreviousData: true,
                enabled: actionItemId != null,
                select: ActionItemRaciSetUserDto.constructFromPaginatedResponse,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getAdditionalFieldValues", actionItemId],
                queryFn: () => getAdditionalFieldValues(url.baseUrl, actionItemId),
                select: ActionItemDynamicFieldValueDto.toActionItemDynamicFieldValueDtos,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getActionItemInstanceFiles", actionItemId],
                queryFn: () => getActionItemInstanceFiles(url.baseUrl, actionItemId),
                select: FileDto.constructFromActionItemInstanceFileDownloadResponses,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<ActionItemDetailsDto, HTTPError>,
        UseQueryResult<ActionItemRaciSetUserDto[], HTTPError>,
        UseQueryResult<ActionItemDynamicFieldValueDto[], HTTPError>,
        UseQueryResult<FileDto[], HTTPError>,
    ];
};

export const usePlaceOnHold = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    PlaceOnHoldActionItemDto
> => {
    const url = useUrl();

    return useMutation((dto: PlaceOnHoldActionItemDto) =>
        placeOnHold(url.baseUrl, new PlaceOnHoldActionItemRequest(dto))
    );
};

export const useReactivateOnHold = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    ReactivateOnHoldActionItemDto
> => {
    const url = useUrl();

    return useMutation((dto: ReactivateOnHoldActionItemDto) =>
        reactivateOnHold(url.baseUrl, new ReactivateOnHoldActionItemRequest(dto))
    );
};

export const useCompleteMyActionItem = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    CompleteMyActionItemDto
> => {
    const url = useUrl();

    return useMutation((dto: CompleteMyActionItemDto) =>
        completeActionItem(url.baseUrl, new CompleteActionItemRequest(dto))
    );
};

export const useCancelMyActionItem = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    CancelMyActionItemDto
> => {
    const url = useUrl();

    return useMutation((dto: CancelMyActionItemDto) =>
        cancelActionItem(url.baseUrl, new CancelActionItemRequest(dto))
    );
};

export const useExtendMyActionItem = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    ExtendActionItemDto
> => {
    const url = useUrl();

    return useMutation((dto: ExtendActionItemDto) =>
        extendActionItem(url.baseUrl, new ExtendActionItemRequest(dto))
    );
};

export const useUploadActionItemFile = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    ActionItemInstanceFileUploadDto
> => {
    const url = useUrl();

    return useMutation(
        async (mutationKey: ActionItemInstanceFileUploadDto): Promise<Response<boolean>> => {
            const actionItemInstanceFileUploadRequest = createActionItemInstanceFileUploadRequest(
                mutationKey.actionItemId,
                new FileDetailsUploadRequest(mutationKey.file, mutationKey.fileBase64String)
            );

            return uploadActionItemInstanceFile(url.baseUrl, actionItemInstanceFileUploadRequest);
        }
    );
};

export const useDownloadActionItemFile = (
    actionItemInstanceFileId: number | null
): UseQueryResult<FileDownloadDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["downloadActionItemInstanceFile", actionItemInstanceFileId],
        () => downloadActionItemInstanceFile(url.baseUrl, actionItemInstanceFileId!),
        {
            enabled: actionItemInstanceFileId != null,
            select: FileDownloadDto.constructFromActionItemInstanceFileDownloadResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useDownloadQuestionSetInstanceAnswerFile = (
    fileDetailsId: number | null
): UseQueryResult<FileDownloadDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["downloadActionItemInstanceFile", fileDetailsId],
        () => downloadFile(url.baseUrl, fileDetailsId!),
        {
            enabled: fileDetailsId != null,
            select: FileDownloadDto.constructFromBaseFileDetailsResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useRemoveActionItemFile = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    number
> => {
    const url = useUrl();

    return useMutation((mutationKey: number) =>
        removeActionItemInstanceFile(url.baseUrl, mutationKey)
    );
};

export const useDissociateActionItemNode = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    DissociateNodeToActionItemDto
> => {
    const url = useUrl();

    return useMutation((dto: DissociateNodeToActionItemDto) => {
        const request = new DissociateNodeToActionItemRequest(dto);

        return dissociateNode(url.baseUrl, request);
    });
};
