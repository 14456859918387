import { ReferentialLinkTypeResponse } from "../../responses/referential-links/referential-link-type-response";
import BaseReferentialLinkDto from "./base-referential-link-dto";
import BaseReferentialLinkTypeDto from "./base-referential-link-type-dto";

export default class ARMonitoringReferentialLinkWithTypeDto {
    referentialLinkTypeDto: BaseReferentialLinkTypeDto;
    referentialLinkDtos: BaseReferentialLinkDto[];

    public constructor(response: ReferentialLinkTypeResponse) {
        const { referentialLinkTypeId, name, referentialLinkResponses } = response;

        this.referentialLinkTypeDto = new BaseReferentialLinkTypeDto(referentialLinkTypeId, name);
        this.referentialLinkDtos =
            BaseReferentialLinkDto.toBaseReferentialLinkDtos(referentialLinkResponses);
    }

    public static toARMonitoringReferentialLinkWithTypeDto = (
        responses: ReferentialLinkTypeResponse[]
    ): ARMonitoringReferentialLinkWithTypeDto[] =>
        responses.map((x) => new ARMonitoringReferentialLinkWithTypeDto(x));
}
