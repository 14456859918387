import { Form } from "react-bootstrap";

export interface CheckboxProps extends CheckboxFieldProps {
    type: "checkbox" | "radio" | "switch";
}

export interface CheckboxFieldProps {
    name: string;
    label?: string;
    defaultSelected?: boolean | null;
    disabled?: boolean;
    onChangeHandler?: (name: string, checked: boolean) => void;
}

// TODO: This to replace usages of Form.Check
const SbCheckField: React.FC<CheckboxProps> = ({
    type,
    name,
    label,
    defaultSelected,
    disabled,
    onChangeHandler,
}) => (
    <Form.Check
        type={type}
        key={name + (defaultSelected?.toString() ?? "")}
        name={name}
        label={label}
        defaultChecked={defaultSelected ?? undefined}
        disabled={disabled}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onChangeHandler && onChangeHandler(event.target.name, event.target.checked)
        }
    />
);

export default SbCheckField;
