import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ChecklistAnswers } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import AnswerCategory from "../../../domain/enums/answer-types/answer-category";
import {
    useDeleteAnswerType,
    useGetAnswerTypeDetails,
} from "../../../domain/viewmodels/answer-types/delete-answer-type-viewmodel";
import { DeleteButton } from "../../atoms/SbButton";
import { ConfirmationRow, TextConfirmationRow } from "../../organisms/ActionConfirmation";
import { ActionConfirmationFragment } from "../../organisms/ActionConfirmationFragment";

const DeleteAnswerTypeContainer: React.FC = () => {
    const answerTypeId = Number(useParams().answerTypeId);

    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistAnswers });

    const getAnswerTypeDetails = useGetAnswerTypeDetails(answerTypeId);
    const deleteAnswerType = useDeleteAnswerType();

    const getAnswerTypeDetailsData = getAnswerTypeDetails.data;

    useLoader(
        isQueryLoading(getAnswerTypeDetails) || isMutationLoading(deleteAnswerType),
        DeleteAnswerTypeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.AnswerTypes);
    }, []);

    const handleDeleteAnswerType = (): void => {
        deleteAnswerType.mutate(answerTypeId, {
            onSuccess: onDeleteAnswerTypeSuccess,
            onError: errorResponseToDisplayHandler,
        });
    };

    const onDeleteAnswerTypeSuccess = async (): Promise<void> => {
        const params = [
            createNavigateSearchParameter("success", "true"),
            createNavigateSearchParameter("messageKey", "SuccessfullyDeletedAnswerType"),
        ];

        navigateSearch(`${getPath(AccordionTitles.AnswerTypes)}`, params);
    };

    const buildDeleteButton = (): ReactElement<typeof Button> => (
        <DeleteButton onClick={() => handleDeleteAnswerType()} />
    );

    const buildConfirmationRows = (): ConfirmationRow<any>[] => [
        new TextConfirmationRow(t("Name"), getAnswerTypeDetailsData?.name),
        new TextConfirmationRow(
            t("AnswerCategory"),
            getAnswerTypeDetailsData?.answerCategory
                ? AnswerCategory[getAnswerTypeDetailsData.answerCategory]
                : undefined
        ),
    ];

    return (
        <ActionConfirmationFragment
            pageHeading={t("DeleteAnswerType")}
            panelTitle={t("DeleteAnswerTypeConfirmation")}
            rows={buildConfirmationRows()}
            primaryButton={buildDeleteButton()}
            areQueriesSuccessful={isQuerySuccessful(getAnswerTypeDetails)}
        />
    );
};

export default DeleteAnswerTypeContainer;
