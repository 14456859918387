import { Response } from "../../responses/common/response-response";
import { ReferentialLinkTypeResponse } from "../../responses/referential-links/referential-link-type-response";

export default class BaseReferentialLinkTypeDto {
    referentialLinkTypeId: number;
    name: string;

    public constructor(referentialLinkTypeId: number, name: string) {
        this.referentialLinkTypeId = referentialLinkTypeId;
        this.name = name;
    }

    public static constructFromResponse = (
        response: Response<ReferentialLinkTypeResponse>
    ): BaseReferentialLinkTypeDto => {
        const { referentialLinkTypeId, name } = response.data;

        return new BaseReferentialLinkTypeDto(referentialLinkTypeId, name);
    };
}
