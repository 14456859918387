import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { useUrl } from "../../../core/store/url-context";
import { convertToggleValueToBoolean } from "../../../core/utilities/convertToBoolean";
import { createQuestion } from "../../../data/services/questions/question-service";
import { QuestionTypes } from "../../enums/questions/question-types";

import { HTTPError } from "ky";
import { AnswerThresholdIndicatorEnum } from "../../../core/utilities/enums";
import { CreateAnswerThresholdValueRequest } from "../../requests/questions/create-question-request";
import { CreateQuestionWeightingsRequest } from "../../requests/questions/create-question-weightings-request";
import { Response } from "../../responses/common/response-response";

interface CreateParameters {
    questionTypeId: number;
    answerTypeId: number;
    answerTypeName: string | null;
    questionCategoryId: number;
    questionImportanceId: number;
    aggregationTypeId: number;
    redAnswerThresholdOperatorId: number | null;
    amberAnswerThresholdOperatorId: number | null;
    formData: FormData;
}

export const useCreateQuestion = (): UseMutationResult<
    Response<number>,
    HTTPError,
    CreateParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: CreateParameters) => {
        const formData = mutationKey.formData;

        const request = {
            languageId: 1, // TODO: find out where this comes from
            questionText: formData.get("questionText")! as string,
            questionTypeId: mutationKey.questionTypeId,
            answerTypeId: mutationKey.answerTypeId,
            questionCategoryId: mutationKey.questionCategoryId,
            questionImportance: mutationKey.questionImportanceId,
            isAggregate: convertToggleValueToBoolean(formData.get("Aggregate")! as string),
            aggregationTypeId: mutationKey.aggregationTypeId,
            isARMonitoring: convertToggleValueToBoolean(formData.get("arMonitoring")! as string),
            answerThresholdValues:
                mutationKey.questionTypeId === QuestionTypes.Indicator
                    ? createAnswerThresholdValues(
                          formData,
                          mutationKey.answerTypeName,
                          mutationKey.redAnswerThresholdOperatorId,
                          mutationKey.amberAnswerThresholdOperatorId
                      )
                    : [],
            mutateQuestionWeightingRequests:
                mutationKey.questionTypeId === QuestionTypes.Control &&
                convertToggleValueToBoolean(formData.get("QuestionWeighting")! as string)
                    ? createQuestionWeightings(formData)
                    : [],
        };

        return createQuestion(url.baseUrl, request);
    });
};

const createAnswerThresholdValues = (
    formData: FormData,
    answerTypeName: string | null,
    redAnswerThresholdOperatorId: number | null,
    amberAnswerThresholdOperatorId: number | null
): CreateAnswerThresholdValueRequest[] => {
    let createAnswerThresholdValueRequest: CreateAnswerThresholdValueRequest[] = [];

    if (redAnswerThresholdOperatorId) {
        createAnswerThresholdValueRequest.push({
            intValue: answerTypeName === "Number" ? Number(formData.get("redNumber")) : undefined,
            decimalValue:
                answerTypeName !== "Number" ? Number(formData.get("redDecimal")) : undefined,
            answerThresholdIndicator: AnswerThresholdIndicatorEnum.Red,
            answerThresholdOperator: redAnswerThresholdOperatorId!,
        });
    }

    if (amberAnswerThresholdOperatorId) {
        createAnswerThresholdValueRequest.push({
            intValue: answerTypeName === "Number" ? Number(formData.get("amberNumber")) : undefined,
            decimalValue:
                answerTypeName !== "Number" ? Number(formData.get("amberDecimal")) : undefined,
            answerThresholdIndicator: AnswerThresholdIndicatorEnum.Amber,
            answerThresholdOperator: amberAnswerThresholdOperatorId!,
        });
    }

    return createAnswerThresholdValueRequest;
};

const createQuestionWeightings = (formData: FormData): CreateQuestionWeightingsRequest[] => {
    let questionWeightings: CreateQuestionWeightingsRequest[] = [];
    formData.forEach((value, key) => {
        if (key.includes("predefinedAnswerId")) {
            const predefinedAnswerId = key.split("predefinedAnswerId")[1];
            questionWeightings.push(
                new CreateQuestionWeightingsRequest(
                    Number(predefinedAnswerId),
                    value != "" ? Number(value) : undefined
                )
            );
        }
    });
    return questionWeightings;
};
