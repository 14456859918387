import {
    buildSearchActionItemAssociationSearchParameters,
    SearchActionItemAssociationDto,
} from "../../dtos/actions/search-action-item-association-dto";
import { PaginationDto } from "../../dtos/common/pagination-dto";
import { buildPaginationSearchParameters } from "../common/pagination-request";

export interface FilterActionItemAssociationRequest
    extends SearchActionItemAssociationDto,
        PaginationDto {}

export const createFilterActionItemAssociationRequest = (
    paginationDto: PaginationDto,
    searchDto: SearchActionItemAssociationDto
): FilterActionItemAssociationRequest => ({
    ...paginationDto,
    actionItemId: searchDto.actionItemId,
});

export const buildFilterActionItemAssociationSearchParameters = (
    request: FilterActionItemAssociationRequest
): URLSearchParams => {
    const searchParams = buildPaginationSearchParameters(request);
    buildSearchActionItemAssociationSearchParameters(request, searchParams);

    return searchParams;
};
