import { ActionItemImportanceResponse } from "../../responses/actions/action-item-importance-response";
import { Response } from "../../responses/common/response-response";

export interface ActionItemImportanceDto {
    actionItemImportanceId: number;
    name: string;
}

export const createActionItemImportanceDto = (
    actionItemImportanceId: number,
    name: string
): ActionItemImportanceDto => ({
    actionItemImportanceId: actionItemImportanceId,
    name: name,
});

export const createActionItemImportanceDtoFromResponse = (
    response: ActionItemImportanceResponse
): ActionItemImportanceDto => ({
    ...response,
});

export const toActionItemImportanceDtos = (
    response: Response<ActionItemImportanceResponse[]>
): ActionItemImportanceDto[] =>
    response.data?.map((x) => createActionItemImportanceDtoFromResponse(x));
