import { buildPaginationSearchParameters, PaginationRequest } from "../common/pagination-request";
import {
    buildSearchQuestionAssociationSearchParameters,
    SearchQuestionAssociationRequest,
} from "./search-question-association-request";

export interface FilterQuestionAssociationRequest
    extends SearchQuestionAssociationRequest,
        PaginationRequest {}

export const CreateQuestionAssociationSearchRequest = (
    paginationRequest: PaginationRequest,
    searchRequest: SearchQuestionAssociationRequest
): FilterQuestionAssociationRequest => ({
    ...paginationRequest,
    questionId: searchRequest.questionId,
});

export const buildFilterQuestionAssociationSearchParameters = (
    request: FilterQuestionAssociationRequest
): URLSearchParams => {
    const searchParams = buildPaginationSearchParameters(request);
    buildSearchQuestionAssociationSearchParameters(request, searchParams);

    return searchParams;
};
