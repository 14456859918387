import { ReactElement, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, Path, useParams, useSearchParams } from "react-router-dom";
import { ActionItems, Common, Languages } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    createNavigateToForSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { alternativeActionItemDynamicFieldTextsColumns } from "../../../core/utilities/dataTableColumns";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    isMutationLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { defaultPaginationDto, PaginationDto } from "../../../domain/dtos/common/pagination-dto";
import AnswerValueType from "../../../domain/enums/answer-types/answer-value-type";
import { Response } from "../../../domain/responses/common/response-response";
import {
    useDeleteActionItemDynamicFieldText,
    useGetActionItemDynamicFieldDetails,
} from "../../../domain/viewmodels/action-items/action-item-dynamic-field-details-viewmodel";
import SbCheckField from "../../atoms/input/SbCheckField";
import { CreateLink, EditLink, ViewLink } from "../../atoms/SbLink";
import { TextTitledPanel } from "../../molecules/SbPanel";
import { DataTable } from "../../organisms/DataTable";

const ActionItemDynamicFieldDetailsContainer: React.FC = () => {
    const [dynamicFieldTextPaginationDto, setDynamicFieldTextPaginationDto] =
        useState<PaginationDto>(defaultPaginationDto);

    const menu = useMenu();
    const toast = useToast();
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const actionItemDynamicFieldId = Number(useParams().actionItemDynamicFieldId);
    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";

    const getActionItemDynamicFieldDetails = useGetActionItemDynamicFieldDetails(
        actionItemDynamicFieldId,
        dynamicFieldTextPaginationDto
    );
    const deleteActionItemDynamicFieldText = useDeleteActionItemDynamicFieldText();

    const actionItemDynamicFieldDetailsResponse = getActionItemDynamicFieldDetails[0];
    const actionItemDynamicFieldDetailsResponseData = actionItemDynamicFieldDetailsResponse.data;
    const actionItemDynamicFieldTextsResponse = getActionItemDynamicFieldDetails[1];
    const actionItemDynamicFieldTextsResponseData = actionItemDynamicFieldTextsResponse.data;

    useLoader(
        areQueriesLoading(getActionItemDynamicFieldDetails) ||
            isMutationLoading(deleteActionItemDynamicFieldText),
        ActionItemDynamicFieldDetailsContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.ActionTracker);
        menu.changeActiveDrawerItem(DrawerTitles.ActionItemDynamicFields);

        if (success) {
            toast.addToast(createSuccessToastProps([t(messageKey)]));

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    const handleDeleteActionItemDynamicFieldText = (actionItemDynamicFieldTextId: number): void => {
        deleteActionItemDynamicFieldText.mutate(actionItemDynamicFieldTextId, {
            onSuccess: async (_: Response<boolean>) => {
                queryClient.refetchQueries([
                    "filterActionItemDynamicFieldTexts",
                    actionItemDynamicFieldId,
                    dynamicFieldTextPaginationDto,
                ]);

                toast.addToast(
                    createSuccessToastProps([
                        t("RemoveAlternativeActionItemDynamicFieldTextSuccessMessage"),
                    ])
                );
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    const buildLinks = (): ReactElement<typeof Link> => (
        <>
            <EditLink
                navigateTo={`${getPath(
                    DrawerTitles.ActionItemDynamicFields
                )}/${actionItemDynamicFieldId}/edit`}
            />
            <CreateLink
                label={t("CreateNew", { keyPrefix: Common })}
                navigateTo={`${getPath(DrawerTitles.ActionItemDynamicFields)}/create`}
            />
            <ViewLink
                label={t("ActionItemDynamicFields")}
                navigateTo={`${getPath(DrawerTitles.ActionItemDynamicFields)}`}
            />
        </>
    );

    const buildCreateActionItemDynamicFieldTextNavigateTo = (): Partial<Path> => {
        const params = [
            createNavigateSearchParameter(
                "actionItemDynamicFieldId",
                actionItemDynamicFieldId.toString()
            ),
        ];

        return createNavigateToForSearch(
            `${getPath(DrawerTitles.ActionItemDynamicFieldTexts)}/create`,
            params
        );
    };

    return (
        <>
            <PageHeading>{t("ActionItemDynamicFieldDetailsTitle")}</PageHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(actionItemDynamicFieldDetailsResponse) && (
                <ContentContainer>
                    <Row>
                        <Col md="auto">
                            <DetailsLabel>{t("FieldName")}</DetailsLabel>
                            <DetailsLabel>{t("ActionItemType")}</DetailsLabel>
                            <DetailsLabel>{t("ValueType", { keyPrefix: Common })}</DetailsLabel>
                            <DetailsLabel>{t("IsRequired", { keyPrefix: Common })}</DetailsLabel>
                            <DetailsLabel>{t("ListData", { keyPrefix: Common })}</DetailsLabel>
                        </Col>
                        <Col md="auto">
                            <DetailsValue>
                                {actionItemDynamicFieldDetailsResponseData!.fieldName}
                            </DetailsValue>
                            <DetailsValue>
                                {actionItemDynamicFieldDetailsResponseData!.actionItemTypeDto.value}
                            </DetailsValue>
                            <DetailsValue>
                                {
                                    AnswerValueType[
                                        actionItemDynamicFieldDetailsResponseData!.valueType
                                    ]
                                }
                            </DetailsValue>
                            <SbCheckField
                                type={"checkbox"}
                                name={"isRequired"}
                                defaultSelected={
                                    actionItemDynamicFieldDetailsResponseData!.isRequired
                                }
                            />
                            <DetailsValue>
                                {actionItemDynamicFieldDetailsResponseData!.listData ?? "-"}
                            </DetailsValue>
                        </Col>
                    </Row>

                    <EndAlignedDiv>{buildLinks()}</EndAlignedDiv>
                </ContentContainer>
            )}

            {isQuerySuccessful(actionItemDynamicFieldTextsResponse) && (
                <>
                    <LargeVerticalSpace />
                    <TextTitledPanel title={t("AlternativeActionItemDynamicFieldTexts")}>
                        <EndAlignedDiv>
                            <CreateLink
                                label={t("AddAlternativeDynamicFieldText")}
                                navigateTo={buildCreateActionItemDynamicFieldTextNavigateTo()}
                            />
                        </EndAlignedDiv>

                        <DataTable
                            keyPrefix={ActionItems}
                            columns={alternativeActionItemDynamicFieldTextsColumns}
                            rows={actionItemDynamicFieldTextsResponseData!.rows}
                            deleteItem={handleDeleteActionItemDynamicFieldText}
                            noResultsMessage={`${t("NoAlternativeLanguageTextFound", {
                                keyPrefix: Languages,
                            })}`} // TODO: Update to a more appropriate message
                            totalItems={actionItemDynamicFieldTextsResponseData!.recordCount}
                            paginationDto={dynamicFieldTextPaginationDto}
                            setPaginationDto={setDynamicFieldTextPaginationDto}
                        />
                    </TextTitledPanel>
                </>
            )}
        </>
    );
};

export default ActionItemDynamicFieldDetailsContainer;
