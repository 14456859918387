import { TFunction } from "i18next";
import { MaxIntValue } from "../../../core/constants/numerical";
import {
    ActionItems,
    ActionItemTypes,
    ChecklistQuestions,
    Common,
    HierarchyTypes,
    NonWorkingDays,
    Users,
} from "../../../core/constants/translation-namespace";

export enum HierarchyAssignable {
    None = 0,
    NonWorkingDaySet = 1,
    Question = 2,
    User = 4,
    Checklist = 8,
    ChecklistInstances = 16,
    ActionItems = 32,
    ActionItemTypes = 64,
    TrainableHierarchy = 128,
    OrganisationalNodesForActionItems = 256,
    GeographyNodesForActionItems = 512,
    All = MaxIntValue,
}

export const HierarchyAssignableLabels = new Map<HierarchyAssignable, string>([
    [HierarchyAssignable.NonWorkingDaySet, "Non Working Day Set"],
    [HierarchyAssignable.Question, "Question"],
    [HierarchyAssignable.User, "User"],
    [HierarchyAssignable.Checklist, "Checklist"],
    [HierarchyAssignable.ChecklistInstances, "Checklist Instances"],
    [HierarchyAssignable.ActionItems, "Action Items"],
    [HierarchyAssignable.ActionItemTypes, "Action Item Types"],
    [HierarchyAssignable.TrainableHierarchy, "Trainable Hierarchy"],
    [
        HierarchyAssignable.OrganisationalNodesForActionItems,
        "Organisational Nodes For Action Items",
    ],
    [HierarchyAssignable.GeographyNodesForActionItems, "Geography Nodes For Action Items"],
    [HierarchyAssignable.All, "All"],
]);

export const translateHierarchyAssignableLabel = (t: TFunction, label: string): string => {
    if (!label) return "";
    const enumValue = getHierarchyAssignableEnumValue(label);
    let keyPrefix = "";

    switch (enumValue) {
        case HierarchyAssignable.NonWorkingDaySet:
            keyPrefix = NonWorkingDays;
            break;
        case HierarchyAssignable.Question:
            keyPrefix = ChecklistQuestions;
            break;
        case HierarchyAssignable.User:
            keyPrefix = Users;
            break;
        case HierarchyAssignable.Checklist:
        case HierarchyAssignable.ChecklistInstances:
        case HierarchyAssignable.TrainableHierarchy:
        case HierarchyAssignable.OrganisationalNodesForActionItems:
        case HierarchyAssignable.GeographyNodesForActionItems:
            keyPrefix = HierarchyTypes;
            break;
        case HierarchyAssignable.ActionItems:
            keyPrefix = ActionItems;
            break;
        case HierarchyAssignable.ActionItemTypes:
            keyPrefix = ActionItemTypes;
            break;
        default:
            keyPrefix = Common;
            break;
    }

    return keyPrefix === ActionItemTypes
        ? t("ActionItemType", { keyPrefix: keyPrefix })
        : t(HierarchyAssignable[enumValue], { keyPrefix: keyPrefix });
};

const getHierarchyAssignableEnumValue = (label: string): HierarchyAssignable => {
    switch (label) {
        case "Non Working Day Set":
            return HierarchyAssignable.NonWorkingDaySet;
        case "Question":
            return HierarchyAssignable.Question;
        case "User":
            return HierarchyAssignable.User;
        case "Checklist":
            return HierarchyAssignable.Checklist;
        case "Checklist Instances":
            return HierarchyAssignable.ChecklistInstances;
        case "Action Items":
            return HierarchyAssignable.ActionItems;
        case "Action Item Types":
            return HierarchyAssignable.ActionItemTypes;
        case "Trainable Hierarchy":
            return HierarchyAssignable.TrainableHierarchy;
        case "Organisational Nodes For Action Items":
            return HierarchyAssignable.OrganisationalNodesForActionItems;
        case "Geography Nodes For Action Items":
            return HierarchyAssignable.GeographyNodesForActionItems;
        default:
            return HierarchyAssignable.All;
    }
};
