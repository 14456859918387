import { Col, Form, Row } from "react-bootstrap";
import { StyledFormLabel } from "../../../core/theme/global-styles";
import SbTextField, { SbTextFieldProps } from "../../atoms/input/SbTextField";
import SbLabelText from "../../atoms/SbLabelText";
import { BaseSbFormGroupProps } from "../../props/input/InputFieldProps";

interface SbFormTextFieldGroupProps extends BaseSbFormGroupProps, SbTextFieldProps {
    type: string;
    children?: React.ReactNode;
}

const SbFormTextFieldGroup: React.FC<SbFormTextFieldGroupProps> = ({
    name,
    type,
    label,
    value,
    defaultValue,
    required,
    disabled,
    children,
    maxLength,
    minLength,
    onTextChanged,
}) => (
    <Form.Group as={Row} className="mb-3">
        <StyledFormLabel column sm={2}>
            <SbLabelText label={label} required={required} />
        </StyledFormLabel>
        <Col sm={4}>
            <SbTextField
                name={name}
                type={type}
                defaultValue={defaultValue}
                value={value}
                required={required}
                disabled={disabled}
                onTextChanged={onTextChanged}
                maxLength={maxLength}
                minLength={minLength}
            />
        </Col>
        {children && <Col>{children}</Col>}
    </Form.Group>
);

export default SbFormTextFieldGroup;
