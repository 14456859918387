import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { lookupHierarchyTypes } from "../../../data/services/lookup/lookup-service";
import {
    HierarchyTypeDto,
    toLookupHierarchyTypeDtos,
} from "../../dtos/hierarchy/hierarchy-type-dto";

export const useFilterHierarchyTypes = (): UseQueryResult<HierarchyTypeDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(["filterHierarchyTypes"], () => lookupHierarchyTypes(url.baseUrl), {
        select: toLookupHierarchyTypeDtos,
        onError: errorResponseToDisplayHandler,
    });
};
