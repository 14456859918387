import {
    buildFilterNodeHierarchyByTypeSearchParameters,
    FilterNodeHierarchyByTypeRequest,
} from "../../../domain/requests/hierarchy/filter-node-hierarchy-by-type-request";
import { Response } from "../../../domain/responses/common/response-response";
import { NodeHierarchyResponse } from "../../../domain/responses/hierarchy/node-hierarchy-response";
import client from "../../http-client";

const route = "hierarchy";

export const filterNodeHierarchyByType = async (
    prefixUrl: string,
    request: FilterNodeHierarchyByTypeRequest
): Promise<Response<NodeHierarchyResponse>> => {
    const searchParams = buildFilterNodeHierarchyByTypeSearchParameters(request);

    return await client
        .get(`${route}/hierarchy-by-type`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};
