import React, { ChangeEventHandler, useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { ThemeContext } from "styled-components";
import {
    EndAlignedDiv,
    getErrorStyle,
    StyledErrorDiv,
    StyledFormLabel,
} from "../../../core/theme/global-styles";
import SbLabelText from "../../atoms/SbLabelText";

const SbFormikTextAreaGroup: React.FC<{
    name: string;
    label: string;
    required?: boolean;
    rows?: number;
    maxLength?: number;
    defaultValue?: string;
    onFormikChange?: ChangeEventHandler<Element>;
    error?: string;
    touched?: boolean;
}> = ({ name, label, required, rows, maxLength, defaultValue, onFormikChange, error, touched }) => {
    const [count, setCount] = useState(maxLength);

    const themeContext = useContext(ThemeContext);

    const getCount = (valueLength: number, maxLength: number): void => {
        setCount(maxLength - valueLength);
    };

    useEffect(() => {
        defaultValue && maxLength && getCount(defaultValue.length, maxLength);
    }, []);

    return (
        <Form.Group as={Row} className="mb-3">
            <StyledFormLabel column sm={2}>
                <SbLabelText label={label} required={required} />
            </StyledFormLabel>
            <Col sm={4}>
                <Form.Control
                    as="textarea"
                    name={name}
                    style={error && touched ? getErrorStyle(themeContext!) : undefined}
                    rows={rows}
                    maxLength={maxLength}
                    onChange={(event) => {
                        maxLength && getCount(event.target.value.length, maxLength);
                        onFormikChange && onFormikChange(event);
                    }}
                    defaultValue={defaultValue}
                />
                {count && (
                    <EndAlignedDiv>
                        <SbLabelText label={count.toString()} />
                    </EndAlignedDiv>
                )}
                {error && touched && <StyledErrorDiv>{error}</StyledErrorDiv>}
            </Col>
        </Form.Group>
    );
};

export default SbFormikTextAreaGroup;
