import { createPaginationDto, defaultPaginationDto, PaginationDto } from "../common/pagination-dto";

export interface FilterNonWorkingDaySetDto extends PaginationDto {
    nonWorkingDaySetName: string | null;
    nonWorkingDayName: string | null;
    date: string | null;
}

export const createFilterNonWorkingDaySetDto = (
    nonWorkingDaySetName: string | null,
    nonWorkingDayName: string | null,
    date: string | null,
    pageNumber: number,
    pageSize: number,
    sortByColumn: string | null,
    sortByDescending: boolean
): FilterNonWorkingDaySetDto => ({
    nonWorkingDaySetName: nonWorkingDaySetName,
    nonWorkingDayName: nonWorkingDayName,
    date: date,
    ...createPaginationDto(pageNumber, pageSize, sortByColumn, sortByDescending),
});

export const defaultFilterNonWorkingDaySetDto = (): FilterNonWorkingDaySetDto => {
    const { pageNumber, pageSize, sortByColumn, sortByDescending } = defaultPaginationDto;

    return createFilterNonWorkingDaySetDto(
        null,
        null,
        null,
        pageNumber,
        pageSize,
        sortByColumn,
        sortByDescending
    );
};
