import { transformLocalDateToUtcDate } from "./date-helper";

export const getNullableFormField = (formFieldData: string): string | null =>
    formFieldData !== "" ? formFieldData : null;

export const getDateFormFieldInUtc = (formFieldData: string): Date => {
    const date = new Date(formFieldData);

    return transformLocalDateToUtcDate(date);
};
