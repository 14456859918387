import { OverlayTrigger } from "react-bootstrap";
import { FaCircleInfo } from "react-icons/fa6";
import styled from "styled-components";
import { StyledPopover } from "../../core/theme/global-styles";

const StyledIconDiv = styled.div`
    color: ${(props) => props.theme.palette.primary};
`;

const SbTooltip: React.FC<{
    tooltip: string;
}> = ({ tooltip }) => {
    return (
        <OverlayTrigger overlay={<StyledPopover>{tooltip}</StyledPopover>} placement={"top-start"}>
            <StyledIconDiv>
                <FaCircleInfo />
            </StyledIconDiv>
        </OverlayTrigger>
    );
};

export default SbTooltip;
