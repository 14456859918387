import { DailyScheduleOption } from "../../enums/questions/question-sets/DailyScheduleOptions";
import { DayOrdinal } from "../../enums/questions/question-sets/DayOrdinalNumber";
import { DaysOfWeek } from "../../enums/questions/question-sets/DaysOfTheWeek";
import { FrequencyType } from "../../enums/questions/question-sets/FrequencyType";
import { MonthlyScheduleOption } from "../../enums/questions/question-sets/MonthlyScheduleOption";
import { MonthOfTheYear } from "../../enums/questions/question-sets/MonthOfTheYear";
import { NonWorkingDayRolloverType } from "../../enums/questions/question-sets/NonWorkingDayRolloverType";
import { WeeklyScheduleOption } from "../../enums/questions/question-sets/WeeklyScheduleOption";
import { YearlyScheduleOption } from "../../enums/questions/question-sets/YearlyScheduleOption";

export interface QuestionSetScheduleRequest {
    frequencyType: FrequencyType;
    baseStartTimeOfDay: string;
    viewableMinutesBeforeBaseStart: number;
    dayOfWeek: DaysOfWeek;
    dayOfMonth: number;
    dayOrdinal: DayOrdinal;
    monthsOfTheYearInterval: number;
    monthOfTheYear: MonthOfTheYear;
    yearInterval: number;
    year: number;
    interval: number;
    maxOccurrences: number | null;
    nonWorkingDayRolloverType: NonWorkingDayRolloverType;
    scheduleDescription: string;
    viewableReminderRequired: boolean;
    isLockoutEnabled: boolean;
    lockoutMinutesAfterDue: number;
    dailyScheduleOption: DailyScheduleOption;
    numberOfDays: number;
    numberOfWeeks: number;
    weeklyScheduleOptions: WeeklyScheduleOption[];
    monthlyScheduleOption: MonthlyScheduleOption;
    yearlyScheduleOption: YearlyScheduleOption;
}

const initWeeklyOptions: WeeklyScheduleOption[] = [];

export const CreateQuestionSetSchedule = (): QuestionSetScheduleRequest => ({
    frequencyType: FrequencyType.Daily,
    baseStartTimeOfDay: "00:00",
    viewableMinutesBeforeBaseStart: 60,
    dayOfWeek: DaysOfWeek.Monday,
    dayOfMonth: 1,
    dayOrdinal: DayOrdinal.First,
    monthsOfTheYearInterval: 1,
    monthOfTheYear: MonthOfTheYear.January,
    yearInterval: 1,
    year: 2022,
    interval: 0,
    maxOccurrences: null,
    nonWorkingDayRolloverType: NonWorkingDayRolloverType.None,
    scheduleDescription: "",
    viewableReminderRequired: false,
    isLockoutEnabled: false,
    lockoutMinutesAfterDue: 60,
    dailyScheduleOption: DailyScheduleOption.EveryXDay,
    numberOfDays: 1,
    numberOfWeeks: 1,
    weeklyScheduleOptions: initWeeklyOptions,
    monthlyScheduleOption: MonthlyScheduleOption.DayXOfEveryXmonths,
    yearlyScheduleOption: YearlyScheduleOption.OnXX,
});
