import { useMutation, UseMutationResult, useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { getNullableFormField } from "../../../core/utilities/formDataHelper";
import {
    editActionItemDynamicField,
    getActionItemDynamicFieldDetails,
} from "../../../data/services/action-items/action-item-dynamic-fields-service";
import { getActionItemTypes } from "../../../data/services/action-items/action-item-types-service";
import {
    ActionItemDynamicFieldDetailsDto,
    createActionItemDynamicFieldDetailsDto,
} from "../../dtos/action-items/action-item-dynamic-field-details-dto";
import {
    ActionItemTypeDto,
    toActionItemTypeDtos,
} from "../../dtos/action-items/action-item-type-dto";
import { EditActionItemDynamicFieldDto } from "../../dtos/action-items/edit-action-item-dynamic-field-dto";
import { createEditActionItemDynamicFieldRequest } from "../../requests/action-items/edit-action-item-dynamic-field-request";
import { Response } from "../../responses/common/response-response";

export const useGetActionItemDynamicFieldDetails = (
    actionItemDynamicFieldId: number
): [
    UseQueryResult<ActionItemTypeDto[], HTTPError>,
    UseQueryResult<ActionItemDynamicFieldDetailsDto, HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getActionItemTypes"],
                queryFn: () => getActionItemTypes(url.baseUrl),
                select: toActionItemTypeDtos,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getActionItemDynamicFieldDetails", actionItemDynamicFieldId],
                queryFn: () =>
                    getActionItemDynamicFieldDetails(url.baseUrl, actionItemDynamicFieldId),
                select: createActionItemDynamicFieldDetailsDto,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<ActionItemTypeDto[], HTTPError>,
        UseQueryResult<ActionItemDynamicFieldDetailsDto, HTTPError>,
    ];
};

export const useEditActionItemDynamicField = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    EditActionItemDynamicFieldDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: EditActionItemDynamicFieldDto) => {
        const { actionItemDynamicFieldId, actionItemTypeNodeId, valueType, formData } = mutationKey;

        const request = createEditActionItemDynamicFieldRequest(
            actionItemDynamicFieldId,
            getNullableFormField(formData.get("fieldName") as string),
            actionItemTypeNodeId,
            valueType,
            getNullableFormField(formData.get("listData") as string),
            formData.get("isRequired") === "on"
        );

        return editActionItemDynamicField(url.baseUrl, request);
    });
};
