import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    createNodeType,
    getNodeTypeDetails,
} from "../../../data/services/hierarchy/node-types-service";
import {
    createNodeTypeDetailsDto,
    NodeTypeDetailsDto,
} from "../../dtos/hierarchy/node-type-details-dto";
import { EscalationLevel, EscalationLevelLabels } from "../../enums/hierarchy/EscalationLevel";
import {
    HierarchyAssignable,
    HierarchyAssignableLabels,
} from "../../enums/hierarchy/HierarchyAssignable";
import { createCreateNodeTypeRequest } from "../../requests/hierarchy/create-node-type-request";
import { Response } from "../../responses/common/response-response";

export const useGetNodeTypeDetails = (
    nodeTypeId: number
): UseQueryResult<NodeTypeDetailsDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getNodeTypeDetails", nodeTypeId],
        () => getNodeTypeDetails(url.baseUrl, nodeTypeId),
        {
            select: createNodeTypeDetailsDto,
            onError: errorResponseToDisplayHandler,
        }
    );
};

interface CreateParameters {
    hierarchyTypeId: number;
    parentNodeTypeId: number;
    formData: FormData;
}

export const createCreateParameters = (
    hierarchyTypeId: number,
    parentNodeTypeId: number,
    formData: FormData
): CreateParameters => ({
    hierarchyTypeId: hierarchyTypeId,
    parentNodeTypeId: parentNodeTypeId,
    formData: formData,
});

export const useCreateNodeType = (): UseMutationResult<
    Response<number | null>,
    HTTPError,
    CreateParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: CreateParameters) => {
        const { hierarchyTypeId, parentNodeTypeId, formData } = mutationKey;

        const request = createCreateNodeTypeRequest(
            formData.get("name")! as string,
            hierarchyTypeId,
            parentNodeTypeId,
            formData.get("containsTimeZoneCode") === "on",
            Array.from(HierarchyAssignableLabels.keys()).filter(
                (x) => formData.get(HierarchyAssignable[x]) === "on"
            ),
            Array.from(EscalationLevelLabels.keys()).filter(
                (x) => formData.get(EscalationLevel[x]) === "on"
            )
        );

        return createNodeType(url.baseUrl, request);
    });
};
