import { Col } from "react-bootstrap";
import { StyledFilterTitle } from "../../core/theme/global-styles";
import SbDateTimeField from "../atoms/input/SbDateTimeField";
import { SbFormDateTimeFieldGroupProps } from "./input/SbFormDateTimeFieldGroup";

const DateTimeFilter: React.FC<SbFormDateTimeFieldGroupProps> = ({
    name,
    value,
    defaultValue,
    excludeTime,
    onInputChange,
    required,
    disabled,
    label,
}) => (
    <Col>
        <StyledFilterTitle>{label}</StyledFilterTitle>
        <SbDateTimeField
            name={name}
            value={value}
            defaultValue={defaultValue}
            excludeTime={excludeTime}
            onInputChange={onInputChange}
            required={required}
            disabled={disabled}
        />
    </Col>
);

export default DateTimeFilter;
