import { Response } from "../../responses/common/response-response";
import PredefinedAnswerResponse from "../../responses/predefined-answers/predefined-answer-response";
import BasePredefinedAnswerDto from "./base-predefined-answer-dto";
import PredefinedAnswerTextDto from "./predefined-answer-text-dto";

export default class PredefinedAnswerDto extends BasePredefinedAnswerDto {
    predefinedAnswerTextDto: PredefinedAnswerTextDto;

    public constructor(response: PredefinedAnswerResponse) {
        super(response);

        this.predefinedAnswerTextDto = new PredefinedAnswerTextDto(
            response.predefinedAnswerTextResponse
        );
    }

    public static constructFromResponse = (
        response: Response<PredefinedAnswerResponse>
    ): PredefinedAnswerDto => new PredefinedAnswerDto(response.data);

    public static toPredefinedAnswerDtos = (
        responses: PredefinedAnswerResponse[]
    ): PredefinedAnswerDto[] => responses.map((x) => new PredefinedAnswerDto(x));
}
