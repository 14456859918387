import {
    BaseFilterNodeHierarchyRequest,
    buildBaseFilterNodeHierarchySearchParameters,
} from "./base-filter-node-hierarchy-request";

export interface FilterNodeHierarchyRequest extends BaseFilterNodeHierarchyRequest {
    rootNodeId: number;
}

export const createFilterNodeHierarchyRequest = (
    rootNodeId: number,
    depth: number | null
): FilterNodeHierarchyRequest => ({
    rootNodeId: rootNodeId,
    depth: depth,
});

const SearchParameters = {
    rootNodeId: "rootNodeId",
};

export const buildFilterNodeHierarchySearchParameters = (
    request: FilterNodeHierarchyRequest
): URLSearchParams => {
    const searchParams = buildBaseFilterNodeHierarchySearchParameters(request);

    searchParams.set(SearchParameters.rootNodeId, request.rootNodeId.toString());

    return searchParams;
};
