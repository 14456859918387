import { ReactElement, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
    AccordionLinks,
    ChecklistAnswers,
    ChecklistQuestions,
    Common,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { predefinedAnswerColumns } from "../../../core/utilities/dataTableColumns";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    isMutationLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { defaultPaginationDto, PaginationDto } from "../../../domain/dtos/common/pagination-dto";
import AnswerCategory from "../../../domain/enums/answer-types/answer-category";
import AnswerFlagMode from "../../../domain/enums/answer-types/answer-flag-mode";
import AnswerValueType from "../../../domain/enums/answer-types/answer-value-type";
import { Response } from "../../../domain/responses/common/response-response";
import {
    useDeletePredefinedAnswer,
    useGetAnswerTypeDetails,
} from "../../../domain/viewmodels/answer-types/view-answer-type-viewmodel";
import { CreateLink, EditLink, ViewLink } from "../../atoms/SbLink";
import { TextTitledPanel } from "../../molecules/SbPanel";
import { DataTable } from "../../organisms/DataTable";

const ViewAnswerTypeContainer: React.FC = () => {
    const menu = useMenu();
    const toast = useToast();
    const navigate = useNavigate();
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistAnswers });
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";
    const answerTypeId = Number(useParams().answerTypeId);

    const [predefinedAnswerPaginationDto, setPredefinedAnswerPaginationDto] =
        useState<PaginationDto>(defaultPaginationDto);

    const getAnswerTypeDetails = useGetAnswerTypeDetails(
        answerTypeId,
        predefinedAnswerPaginationDto
    );
    const deletePredefinedAnswer = useDeletePredefinedAnswer();

    const answerTypeDetailsResponse = getAnswerTypeDetails[0];
    const answerTypeDetailsResponseData = answerTypeDetailsResponse.data;
    const predefinedAnswerResponse = getAnswerTypeDetails[1];
    const predefinedAnswerResponseData = predefinedAnswerResponse.data;

    useLoader(
        areQueriesLoading(getAnswerTypeDetails) || isMutationLoading(deletePredefinedAnswer),
        ViewAnswerTypeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.AnswerTypes);

        if (success) {
            toast.addToast(createSuccessToastProps([t(messageKey)]));

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    const handleDeletePredefinedAnswer = (predefinedAnswerId: number): void => {
        deletePredefinedAnswer.mutate(predefinedAnswerId, {
            onSuccess: async (_: Response<boolean>) => {
                queryClient.refetchQueries([
                    "filterPredefinedAnswers",
                    answerTypeId,
                    predefinedAnswerPaginationDto,
                ]);
                toast.addToast(
                    createSuccessToastProps([t("SuccessfullyRemovedPredefinedAnswerText")])
                );
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    const buildLinks = (): ReactElement<typeof Link> => (
        <>
            <EditLink navigateTo={`${getPath(AccordionTitles.AnswerTypes)}/${answerTypeId}/edit`} />
            <ViewLink
                label={t("AnswerTypeTitle")}
                navigateTo={`${getPath(AccordionTitles.AnswerTypes)}`}
            />
        </>
    );

    const buildCreateLink = (): ReactElement<typeof Link> => (
        <>
            <CreateLink
                label={t("AddPredefinedAnswer")}
                navigateTo={`${getPath(AccordionTitles.AnswerTypes)}/${answerTypeId}${getPath(
                    AccordionTitles.AnswerTypePredefinedAnswers
                )}/create`}
            />
        </>
    );

    const navigateToViewPredefinedAnswerDetails = (predefinedAnswerId: number): void => {
        navigate(
            `${getPath(AccordionTitles.AnswerTypes)}/${answerTypeId}${getPath(
                AccordionTitles.AnswerTypePredefinedAnswers
            )}/${predefinedAnswerId}`
        );
    };

    return (
        <>
            <PageHeading>{t("AnswerTypeDetailsTitle")}</PageHeading>
            <PageSubHeading>{t("HeaderHelpTextAnswerTypeDetails")}</PageSubHeading>

            <SectionVerticalSpace />

            {isQuerySuccessful(answerTypeDetailsResponse) && (
                <ContentContainer>
                    <Row>
                        <Col md="auto">
                            <DetailsLabel>{t("Name")}</DetailsLabel>
                            <DetailsLabel>{t("AnswerCategory")}</DetailsLabel>
                            {answerTypeDetailsResponseData!.answerCategory ==
                                AnswerCategory.MutuallyExclusive && (
                                <DetailsLabel>{t("FlagMode")}</DetailsLabel>
                            )}
                            {answerTypeDetailsResponseData!.answerCategory ==
                                AnswerCategory.InputValue && (
                                <DetailsLabel>{t("ValueType", { keyPrefix: Common })}</DetailsLabel>
                            )}
                        </Col>
                        <Col md="auto">
                            <DetailsValue>
                                {answerTypeDetailsResponseData!.name || "-"}
                            </DetailsValue>
                            <DetailsValue>
                                {answerTypeDetailsResponseData?.answerCategory
                                    ? AnswerCategory[answerTypeDetailsResponseData!.answerCategory!]
                                    : "-"}
                            </DetailsValue>
                            {answerTypeDetailsResponseData!.answerCategory ==
                                AnswerCategory.MutuallyExclusive && (
                                <DetailsValue>
                                    {answerTypeDetailsResponseData?.answerFlagMode
                                        ? AnswerFlagMode[
                                              answerTypeDetailsResponseData!.answerFlagMode!
                                          ]
                                        : "-"}
                                </DetailsValue>
                            )}
                            {answerTypeDetailsResponseData!.answerCategory ==
                                AnswerCategory.InputValue && (
                                <DetailsValue>
                                    {answerTypeDetailsResponseData?.answerValueType
                                        ? AnswerValueType[
                                              answerTypeDetailsResponseData!.answerValueType!
                                          ]
                                        : "-"}
                                </DetailsValue>
                            )}
                        </Col>
                    </Row>

                    <EndAlignedDiv>{buildLinks()}</EndAlignedDiv>
                </ContentContainer>
            )}

            {isQuerySuccessful(predefinedAnswerResponse) &&
                answerTypeDetailsResponseData!.answerCategory ==
                    AnswerCategory.MutuallyExclusive && (
                    <>
                        <LargeVerticalSpace />
                        <TextTitledPanel
                            title={t("AnswerTypePredefinedAnswers", { keyPrefix: AccordionLinks })}
                        >
                            <EndAlignedDiv>{buildCreateLink()}</EndAlignedDiv>

                            <DataTable
                                columns={predefinedAnswerColumns}
                                keyPrefix={ChecklistAnswers}
                                rows={predefinedAnswerResponseData!.rows}
                                viewItem={navigateToViewPredefinedAnswerDetails}
                                deleteItem={handleDeletePredefinedAnswer}
                                noResultsMessage={`${t("NoPredefinedAnswersFound", {
                                    keyPrefix: ChecklistQuestions,
                                })}`}
                                totalItems={predefinedAnswerResponseData!.recordCount}
                                paginationDto={predefinedAnswerPaginationDto}
                                setPaginationDto={setPredefinedAnswerPaginationDto}
                            />
                        </TextTitledPanel>
                    </>
                )}
        </>
    );
};

export default ViewAnswerTypeContainer;
