import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import {
    downloadReviewReportSetFile,
    filterReviewReportSetFiles,
} from "../../../data/services/review-reports/review-reports-file-service";
import { getReviewReportSetDetails } from "../../../data/services/review-reports/review-reports-service";
import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { FileDownloadDto } from "../../dtos/file-storage/file-download-dto";
import { ReviewReportSetDto } from "../../dtos/review-reports/review-report-set-dto";
import ReviewReportSetFileDto from "../../dtos/review-reports/review-report-set-file-dto";
import ReviewStatus from "../../enums/review-reports/review-status";
import { createFilterReviewReportSetFileRequest } from "../../requests/review-reports/filter-review-report-set-file-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import ReviewReportSetFileResponse from "../../responses/review-reports/review-report-set-file-response";

export const useGetReviewReportSetDetails = (
    reviewReportSetId: number
): UseQueryResult<ReviewReportSetDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getReviewReportSetDetails", reviewReportSetId],
        () => getReviewReportSetDetails(url.baseUrl, reviewReportSetId),
        {
            keepPreviousData: true,
            select: ReviewReportSetDto.constructFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useFilterReviewReportSetFiles = (
    reviewReportSetId: number,
    basePaginationDto: BasePaginationDto
): UseQueryResult<PaginatedTableDto<ReviewReportSetFileDto>, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterReviewReportSetFiles", reviewReportSetId],
        () =>
            filterReviewReportSetFiles(
                url.baseUrl,
                createFilterReviewReportSetFileRequest(reviewReportSetId, basePaginationDto)
            ),
        {
            keepPreviousData: true,
            select: transformToDataTableRows,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const transformToDataTableRows = (
    response: Response<PaginationResponse<ReviewReportSetFileResponse>>
): PaginatedTableDto<ReviewReportSetFileDto> => {
    const rows = response.data.results!.map((reviewReportSetFile: ReviewReportSetFileResponse) => {
        return {
            metadata: new ReviewReportSetFileDto(reviewReportSetFile),
            showDownloadFileAction: reviewReportSetFile.reviewReportSetFileId !== null,
            columns: [
                {
                    value: reviewReportSetFile.version,
                    type: DataTableColumnTypes.Text,
                },
                {
                    value: reviewReportSetFile.fileName,
                    type: DataTableColumnTypes.Text,
                },
                {
                    value: ReviewStatus[reviewReportSetFile.reviewStatus].toString(),
                    type: DataTableColumnTypes.Text,
                },
                {
                    value: reviewReportSetFile.datePublishedUtc
                        ? toDateAndTimeFormat(reviewReportSetFile.datePublishedUtc.toString())
                        : "",
                    type: DataTableColumnTypes.Text,
                },
            ],
        };
    });

    return {
        numberOfPages: response.data.pageCount,
        pageSize: response.data.pageSize,
        currentPage: response.data.currentPage,
        recordCount: response.data.recordCount,
        rows: rows,
    };
};

export const useDownloadReviewReportSetFile = (
    reviewReportSetFileId: number | null
): UseQueryResult<FileDownloadDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["downloadReviewReportSetFile", reviewReportSetFileId],
        () => downloadReviewReportSetFile(url.baseUrl, reviewReportSetFileId!),
        {
            enabled: reviewReportSetFileId != null,
            select: FileDownloadDto.constructFromReviewReportSetFileDownloadResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};
