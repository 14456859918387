import { IntervalOverridesResponse } from "../../../domain/responses/checklists/interval-overrides-response";
import { Response } from "../../../domain/responses/common/response-response";
import client from "../../http-client";

const route = "nodeadhocintervaloverrides";

export const getNodeAdhocIntervalOverride = async (
    prefixUrl: string,
    nodeId: number
): Promise<Response<IntervalOverridesResponse>> =>
    await client
        .get(`${route}/${nodeId}`, {
            prefixUrl: prefixUrl,
        })
        .json();
