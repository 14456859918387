import CreateQuestionSetInstanceAnswerReviewDto from "../../dtos/question-set-instance-answers/create-question-set-instance-answer-review-dto";

export default class CreateQuestionSetInstanceAnswerReviewRequest {
    questionSetInstanceAnswerId: number;
    reviewComment: string;

    public constructor(questionSetInstanceAnswerId: number, reviewComment: string) {
        this.questionSetInstanceAnswerId = questionSetInstanceAnswerId;
        this.reviewComment = reviewComment;
    }

    public static constructFromDto = (
        dto: CreateQuestionSetInstanceAnswerReviewDto
    ): CreateQuestionSetInstanceAnswerReviewRequest => {
        const { questionSetInstanceAnswerId, reviewComment } = dto;

        return new CreateQuestionSetInstanceAnswerReviewRequest(
            questionSetInstanceAnswerId,
            reviewComment
        );
    };
}
