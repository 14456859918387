import { createPaginationDto, defaultPaginationDto, PaginationDto } from "../common/pagination-dto";

export interface FilterQuestionSetDto extends PaginationDto {
    questionSetName: string | null;
    description: string | null;
    questionText: string | null;
    processName: string | null;
    questionSetId: number | null;
}

export const createFilterQuestionSetDto = (
    questionSetName: string | null,
    description: string | null,
    questionText: string | null,
    processName: string | null,
    questionSetId: number | null,
    pageNumber: number,
    pageSize: number,
    sortByColumn: string | null,
    sortByDescending: boolean
): FilterQuestionSetDto => ({
    questionSetName: questionSetName,
    description: description,
    questionText: questionText,
    processName: processName,
    questionSetId: questionSetId,
    ...createPaginationDto(pageNumber, pageSize, sortByColumn, sortByDescending),
});

export const defaultFilterQuestionSetDto = (): FilterQuestionSetDto => {
    const { pageNumber, pageSize, sortByColumn, sortByDescending } = defaultPaginationDto;

    return createFilterQuestionSetDto(
        null,
        null,
        null,
        null,
        null,
        pageNumber,
        pageSize,
        sortByColumn,
        sortByDescending
    );
};
