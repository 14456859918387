import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChecklistAnswers } from "../../../core/constants/translation-namespace";
import { StyledBoldVariantText, StyledBorderedSection } from "../../../core/theme/global-styles";
import QuestionAnswerInstanceDto from "../../../domain/dtos/answer-capture/question-answer-instance-dto";
import { useAnswerCapture } from "../../../domain/stores/answer-capture-context";
import SbTextArea from "../../atoms/input/SbTextArea";
import SbLabelText from "../../atoms/SbLabelText";

const QuestionCaptureComment: React.FC<{
    questionAnswerInstanceDto: QuestionAnswerInstanceDto;
    required: boolean;
}> = ({ questionAnswerInstanceDto, required }) => {
    const [savedComment, setSavedComment] = useState<string | null | undefined>();

    const { t } = useTranslation("translation", { keyPrefix: ChecklistAnswers });
    const answerCapture = useAnswerCapture();

    const onCommentChanged = (commentText: string | null): void => {
        answerCapture.updateQuestionAnswerDtosForComment(questionAnswerInstanceDto, commentText);
    };

    useEffect(() => {
        setSavedComment(answerCapture.getQuestionAnswer(questionAnswerInstanceDto)?.commentText);
    }, [questionAnswerInstanceDto.getKey()]);

    return (
        <StyledBorderedSection>
            <StyledBoldVariantText $variant="secondary">
                <SbLabelText
                    //TODO: Update the Portuguese and French translations for AdditionalInformationRequired
                    label={t("AdditionalInformationRequired")}
                    required={required}
                />
            </StyledBoldVariantText>

            <SbTextArea
                name={"questionComment"}
                rows={4}
                maxLength={3000}
                placeholder={"Enter a comment"}
                defaultValue={savedComment}
                required={required}
                onChange={onCommentChanged}
            />
        </StyledBorderedSection>
    );
};

export default QuestionCaptureComment;
