import { Col, Row, Table } from "react-bootstrap";
import { ContentContainer, EndAlignedDiv } from "../../../core/theme/global-styles";
import { ResetButton, SearchButton } from "../../atoms/SbButton";
import DateTimeFilter from "../../molecules/DateTimeFilter";
import { FilterInput } from "../../molecules/FilterInput";

export const HierarchyImportFilter: React.FC<{
    fileName: string | null;
    changeFileName: (subject: string) => void;
    dateTimeFrom: Date | null;
    changeDateTimeFrom: (dateTimeFrom: Date) => void;
    dateTimeTo: Date | null;
    changeDateTimeTo: (dateTimeTo: Date) => void;
    createdBy: string | null;
    changeCreatedBy: (createdBy: string) => void;
    search: () => void;
    resetFilter: () => void;
}> = ({
    fileName,
    changeFileName,
    dateTimeFrom,
    changeDateTimeFrom,
    dateTimeTo,
    changeDateTimeTo,
    createdBy,
    changeCreatedBy,
    search,
    resetFilter,
}) => (
    <ContentContainer>
        <Table>
            <Row>
                <Col>
                    <FilterInput
                        title={"File Name"}
                        name={"fileName"}
                        type={"text"}
                        onChangeEventHandler={changeFileName}
                        value={fileName ?? ""}
                    />
                </Col>
                <Col>
                    <DateTimeFilter
                        label={"Date Time From"}
                        name={"dateTimeFrom"}
                        onInputChange={changeDateTimeFrom}
                        value={dateTimeFrom}
                    />
                </Col>
                <Col>
                    <DateTimeFilter
                        label={"Date Time To"}
                        name={"dateTimeTo"}
                        onInputChange={changeDateTimeTo}
                        value={dateTimeTo}
                    />
                </Col>
                <Col>
                    <FilterInput
                        title={"Created By"}
                        name={"createdBy"}
                        type={"text"}
                        onChangeEventHandler={changeCreatedBy}
                        value={createdBy ?? ""}
                    />
                </Col>
                <Col></Col>
            </Row>
        </Table>
        <EndAlignedDiv>
            <SearchButton onClick={search} />
            <ResetButton onClick={resetFilter} />
        </EndAlignedDiv>
    </ContentContainer>
);
