import { createPaginationDto, defaultPaginationDto, PaginationDto } from "../common/pagination-dto";

export interface FilterQuestionCategoryDto extends PaginationDto {
    questionCategoryName: string | null;
}

export const createFilterQuestionCategoryDto = (
    questionCategoryName: string | null,
    pageNumber: number,
    pageSize: number,
    sortByColumn: string | null,
    sortByDescending: boolean
): FilterQuestionCategoryDto => ({
    questionCategoryName: questionCategoryName,
    ...createPaginationDto(pageNumber, pageSize, sortByColumn, sortByDescending),
});

export const defaultFilterQuestionCategoriesDto = (): FilterQuestionCategoryDto => {
    const { pageNumber, pageSize, sortByColumn, sortByDescending } = defaultPaginationDto;

    return createFilterQuestionCategoryDto(
        null,
        pageNumber,
        pageSize,
        sortByColumn,
        sortByDescending
    );
};
