import {
    buildPaginationSearchParameters,
    PaginationRequest,
} from "../../../domain/requests/common/pagination-request";
import { AddQuestionSetQuestionCommentRequest } from "../../../domain/requests/review-reports/add-question-set-question-comment-request";
import { AssociateDissociateAgreedActionRaciUserRequest } from "../../../domain/requests/review-reports/associate-agreed-action-raci-user-request";
import { EditQuestionSetInstanceReviewRatingRequest } from "../../../domain/requests/review-reports/edit-question-set-instance-review-rating-request";
import { EditReviewReportDetailsRequest } from "../../../domain/requests/review-reports/edit-review-report-details-request";
import { EditReviewReportSetRequest } from "../../../domain/requests/review-reports/edit-review-report-set-request";
import {
    buildFilterQuestionSetInstanceAnswerDetailsRequest,
    FilterQuestionSetInstanceAnswerDetailsRequest,
} from "../../../domain/requests/review-reports/filter-question-set-instance-answer-details-request";
import {
    buildFilterQuestionSetInstanceReviewsSearchParameters,
    FilterQuestionSetInstanceReviewsRequest,
} from "../../../domain/requests/review-reports/filter-question-set-instance-reviews-request";
import { LinkReviewReportSetWithAssuranceReviewRequest } from "../../../domain/requests/review-reports/link-review-report-set-assurance-review-request";
import { PublishReviewReportSetRequest } from "../../../domain/requests/review-reports/publish-review-report-set-request";
import { RemoveResolveAgreedActionRequest } from "../../../domain/requests/review-reports/remove-resolve-agreed-action-request";
import { RemoveResolveReviewFindingRequest } from "../../../domain/requests/review-reports/remove-review-finding-request";
import { AnswerDetailsResponse } from "../../../domain/responses/answer-summary/answer-details-response";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import BaseFileDetailsResponse from "../../../domain/responses/file-storage/base-file-details-response";
import { PrePublishReviewStatusResponse } from "../../../domain/responses/review-reports/pre-publish-review-status-response";
import { QuestionSetInstanceAnswerCommentResponse } from "../../../domain/responses/review-reports/question-set-instance-answer-comment-response";
import { QuestionSetInstanceResponse } from "../../../domain/responses/review-reports/question-set-instance-response";
import { QuestionSetInstanceReviewResponse } from "../../../domain/responses/review-reports/question-set-instance-review-response";
import { ReviewReportSetResponse } from "../../../domain/responses/review-reports/review-report-set-response";
import client from "../../http-client";

const route = "reviewreports";

export const filterReviewReportSets = async (
    prefixUrl: string,
    request: PaginationRequest
): Promise<Response<PaginationResponse<ReviewReportSetResponse>>> => {
    const searchParams = buildPaginationSearchParameters(request);

    return await client
        .get(`${route}/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getReviewReportSetDetails = async (
    prefixUrl: string,
    reviewReportSetId: number
): Promise<Response<ReviewReportSetResponse>> =>
    await client
        .get(`${route}/${reviewReportSetId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const editReviewReportSet = async (
    prefixUrl: string,
    request: EditReviewReportSetRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/edit`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const linkReviewReportSetWithAssuranceReview = async (
    prefixUrl: string,
    request: LinkReviewReportSetWithAssuranceReviewRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/link-assurance-review`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const publishReviewReportSet = async (
    prefixUrl: string,
    request: PublishReviewReportSetRequest
): Promise<Response<BaseFileDetailsResponse>> =>
    await client
        .put(`${route}/publish`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const filterQuestionSetInstanceReviews = async (
    prefixUrl: string,
    request: FilterQuestionSetInstanceReviewsRequest
): Promise<Response<PaginationResponse<QuestionSetInstanceReviewResponse>>> => {
    const searchParams = buildFilterQuestionSetInstanceReviewsSearchParameters(request);

    return await client
        .get(`${route}/question-set-instance-reviews`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getQuestionSetInstanceDetails = async (
    prefixUrl: string,
    questionSetInstanceId: number
): Promise<Response<QuestionSetInstanceResponse>> =>
    await client
        .get(`${route}/question-set-instance-details/${questionSetInstanceId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const filterQuestionSetInstanceAnswerDetails = async (
    prefixUrl: string,
    request: FilterQuestionSetInstanceAnswerDetailsRequest
): Promise<Response<PaginationResponse<AnswerDetailsResponse>>> => {
    const searchParams = buildFilterQuestionSetInstanceAnswerDetailsRequest(request);

    return await client
        .get(`${route}/question-set-instance-answer-details`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getQuestionSetInstanceAnswerDetails = async (
    prefixUrl: string,
    questionSetInstanceAnswerId: number
): Promise<Response<AnswerDetailsResponse>> => {
    return await client
        .get(`${route}/question-set-instance-answer-details/${questionSetInstanceAnswerId}`, {
            prefixUrl: prefixUrl,
        })
        .json();
};

export const validateQuestionSetInstanceAnswerDetails = async (
    prefixUrl: string,
    questionSetInstanceId: number
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/validate-question-set-instance-answer-details/${questionSetInstanceId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const getQuestionSetInstanceReviews = async (
    prefixUrl: string,
    questionSetInstanceId: number
): Promise<Response<QuestionSetInstanceReviewResponse[]>> =>
    await client
        .get(`${route}/question-set-instance-reviews/${questionSetInstanceId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const getQuestionSetInstanceReviewByQuestionSetInstanceAnswerId = async (
    prefixUrl: string,
    questionSetInstanceAnswerId: number
): Promise<Response<QuestionSetInstanceReviewResponse>> =>
    await client
        .get(`${route}/question-set-instance-review-by-answer/${questionSetInstanceAnswerId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const getQuestionSetInstanceAnswerCommentByQuestionSetInstanceAnswerId = async (
    prefixUrl: string,
    questionSetInstanceAnswerId: number
): Promise<Response<QuestionSetInstanceAnswerCommentResponse>> =>
    await client
        .get(
            `${route}/question-set-instance-answer-comment-by-answer/${questionSetInstanceAnswerId}`,
            {
                prefixUrl: prefixUrl,
            }
        )
        .json();

export const createAgreedAction = async (
    prefixUrl: string,
    questionSetInstanceReviewId: number
): Promise<Response<number>> =>
    await client
        .post(`${route}/create-agreed-action/${questionSetInstanceReviewId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const removeAgreedAction = async (
    prefixUrl: string,
    request: RemoveResolveAgreedActionRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/remove-agreed-action`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const resolveAgreedAction = async (
    prefixUrl: string,
    request: RemoveResolveAgreedActionRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/resolve-agreed-action`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const getQuestionSetInstanceReviewChecklist = async (
    prefixUrl: string,
    questionSetInstanceId: number
): Promise<Response<QuestionSetInstanceReviewResponse>> =>
    await client
        .get(`${route}/question-set-instance-review-checklist/${questionSetInstanceId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const deleteReviewReportSet = async (
    prefixUrl: string,
    reviewReportSetId: number
): Promise<Response<boolean>> =>
    await client
        .delete(`${route}/delete-review-report-set/${reviewReportSetId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const deleteCheckListFromReview = async (
    prefixUrl: string,
    questionSetInstanceId: number
): Promise<Response<boolean>> =>
    await client
        .delete(`${route}/delete-checklist-from-review/${questionSetInstanceId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const associateAgreedActionRaciUser = async (
    prefixUrl: string,
    request: AssociateDissociateAgreedActionRaciUserRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/associate-agreed-action-raci-user`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const dissociateAgreedActionRaciUser = async (
    prefixUrl: string,
    request: AssociateDissociateAgreedActionRaciUserRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/dissociate-agreed-action-raci-user`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const addQuestionSetQuestionComment = async (
    prefixUrl: string,
    request: AddQuestionSetQuestionCommentRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/add-question-set-question-comment`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const removeReviewFinding = async (
    prefixUrl: string,
    request: RemoveResolveReviewFindingRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/remove-review-finding`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const resolveReviewFinding = async (
    prefixUrl: string,
    request: RemoveResolveReviewFindingRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/resolve-review-finding`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const checkPrePublishReviewStatus = async (
    prefixUrl: string,
    questionSetInstanceId: number
): Promise<Response<PrePublishReviewStatusResponse>> =>
    await client
        .get(`${route}/check-pre-publish-review-status/${questionSetInstanceId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const submitAndPrePublishItems = async (
    prefixUrl: string,
    questionSetInstanceId: number
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/submit-and-pre-publish-items/${questionSetInstanceId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const submitAndPublishItems = async (
    prefixUrl: string,
    questionSetInstanceId: number
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/submit-and-publish-items/${questionSetInstanceId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const notifyAll = async (
    prefixUrl: string,
    questionSetInstanceId: number
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/notify-all/${questionSetInstanceId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const editQuestionSetInstanceReviewRating = async (
    prefixUrl: string,
    request: EditQuestionSetInstanceReviewRatingRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/edit-question-set-instance-review-rating`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const editReviewReportDetails = async (
    prefixUrl: string,
    request: EditReviewReportDetailsRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/edit-review-report-details`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const filterReviewReportSetIdByReportName = async (
    prefixUrl: string,
    reportName: string
): Promise<Response<number>> =>
    await client
        .get(`${route}/filter-review-report-set-id-by-report-name/${reportName}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const unpublishReviewReportSet = async (
    prefixUrl: string,
    reviewReportSetId: number
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/unpublish/${reviewReportSetId}`, {
            prefixUrl: prefixUrl,
        })
        .json();
