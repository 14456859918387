import { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { ActionItems, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { getEnumsForType } from "../../../core/utilities/enum-helper";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isMutationLoading,
} from "../../../core/utilities/responseStateHelper";
import { ActionItemTypeDto } from "../../../domain/dtos/action-items/action-item-type-dto";
import { createEditActionItemDynamicFieldDto } from "../../../domain/dtos/action-items/edit-action-item-dynamic-field-dto";
import { createReactSelectDto, ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import AnswerValueType from "../../../domain/enums/answer-types/answer-value-type";
import { Response } from "../../../domain/responses/common/response-response";
import {
    useEditActionItemDynamicField,
    useGetActionItemDynamicFieldDetails,
} from "../../../domain/viewmodels/action-items/edit-action-item-dynamic-field-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import SbFormCheckFieldGroup from "../../molecules/input/SbFormCheckFieldGroup";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const EditActionItemDynamicFieldContainer: React.FC = () => {
    const actionItemDynamicFieldId = Number(useParams().actionItemDynamicFieldId);

    const [isListDataRequired, setIsListDataRequired] = useState<boolean>(false);
    const actionItemTypeNodeId = useRef<number | null>(null);
    const valueType = useRef<AnswerValueType | null>(null);

    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });

    const getActionItemDynamicFieldDetails =
        useGetActionItemDynamicFieldDetails(actionItemDynamicFieldId);
    const editActionItemDynamicField = useEditActionItemDynamicField();

    const actionItemTypesResponse = getActionItemDynamicFieldDetails[0];
    const actionItemDynamicFieldDetailsResponse = getActionItemDynamicFieldDetails[1];

    const actionItemDynamicFieldDetailsResponseData = actionItemDynamicFieldDetailsResponse.data;

    useLoader(
        areQueriesLoading(getActionItemDynamicFieldDetails) ||
            isMutationLoading(editActionItemDynamicField),
        EditActionItemDynamicFieldContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.ActionTracker);
        menu.changeActiveDrawerItem(DrawerTitles.ActionItemDynamicFields);
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        editActionItemDynamicField.mutate(
            createEditActionItemDynamicFieldDto(
                actionItemDynamicFieldId,
                actionItemTypeNodeId.current!,
                valueType.current!,
                new FormData(event.currentTarget)
            ),
            {
                onSuccess: async (_: Response<boolean>) => {
                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "messageKey",
                            "SuccessfullyUpdatedActionItemDynamicField"
                        ),
                    ];

                    navigateSearch(
                        `${getPath(
                            DrawerTitles.ActionItemDynamicFields
                        )}/${actionItemDynamicFieldId}`,
                        params
                    );
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    const setDefaultActionItemType = (): ActionItemTypeDto => {
        const actionItemTypeDto = actionItemDynamicFieldDetailsResponseData!.actionItemTypeDto;

        if (!actionItemTypeNodeId.current) {
            actionItemTypeNodeId.current = actionItemTypeDto.nodeId;
        }

        return actionItemTypeDto;
    };

    const setDefaultValueType = (): ReactSelectDto<AnswerValueType> => {
        const responseValueType = actionItemDynamicFieldDetailsResponseData!.valueType;

        if (!valueType.current) {
            valueType.current = responseValueType;
        }

        return createReactSelectDto(responseValueType, AnswerValueType[responseValueType]);
    };

    return (
        <>
            <PageHeading>{t("EditActionItemDynamicField")}</PageHeading>
            <SectionVerticalSpace />

            {areQueriesSuccessful(getActionItemDynamicFieldDetails) && (
                <TextTitledPanel title={t("Edit", { keyPrefix: Common })}>
                    <Form onSubmit={handleSubmit}>
                        <SbFormTextFieldGroup
                            name={"fieldName"}
                            maxLength={100}
                            label={t("FieldName")}
                            type={"text"}
                            defaultValue={actionItemDynamicFieldDetailsResponseData!.fieldName}
                            required
                        />

                        <SbFormSelectFieldGroup
                            name={"actionItemType"}
                            label={t("ActionItemType")}
                            searchable
                            clearable={false}
                            required
                            items={actionItemTypesResponse.data!}
                            itemDisplayText={(option: ActionItemTypeDto) => option.value} //TODO: Add translations for dynamic data
                            defaultSelectedItem={setDefaultActionItemType()}
                            onChange={(option: SingleValue<ActionItemTypeDto>) => {
                                actionItemTypeNodeId.current = option!.nodeId;
                            }}
                        />

                        <SbFormSelectFieldGroup
                            name={"valueType"}
                            label={t("ValueType", { keyPrefix: Common })}
                            searchable
                            clearable={false}
                            required
                            items={getEnumsForType(AnswerValueType).map((x) =>
                                createReactSelectDto(x, AnswerValueType[x])
                            )}
                            itemDisplayText={(option: ReactSelectDto<AnswerValueType>) =>
                                t(AnswerValueType[option.value], { keyPrefix: Common })
                            }
                            defaultSelectedItem={setDefaultValueType()}
                            onChange={(option: SingleValue<ReactSelectDto<AnswerValueType>>) => {
                                valueType.current = option!.value;
                                setIsListDataRequired(option!.value === AnswerValueType.List);
                            }}
                        />

                        <SbFormTextFieldGroup
                            name={"listData"}
                            label={t("ListData", { keyPrefix: Common })}
                            type={"text"}
                            defaultValue={actionItemDynamicFieldDetailsResponseData!.listData}
                            required={isListDataRequired}
                        />

                        <SbFormCheckFieldGroup
                            fieldLabel={t("IsRequired", { keyPrefix: Common })}
                            type="checkbox"
                            values={[
                                {
                                    name: "isRequired",
                                    defaultSelected:
                                        actionItemDynamicFieldDetailsResponseData!.isRequired,
                                },
                            ]}
                        />

                        <EndAlignedDiv>
                            <SaveButton type="submit" />
                            <CancelButton onClick={() => navigate(-1)} />
                        </EndAlignedDiv>
                    </Form>
                </TextTitledPanel>
            )}
        </>
    );
};

export default EditActionItemDynamicFieldContainer;
