import FileDetailsUploadRequest from "../file-storage/file-details-upload-request";

export interface ActionItemInstanceFileUploadRequest {
    actionItemId: Number;
    fileDetailsUploadRequest: FileDetailsUploadRequest;
}

export const createActionItemInstanceFileUploadRequest = (
    actionItemId: Number,
    uploadFileDetailsRequest: FileDetailsUploadRequest
): ActionItemInstanceFileUploadRequest => ({
    actionItemId: actionItemId,
    fileDetailsUploadRequest: uploadFileDetailsRequest,
});
