import { HTTPError } from "ky";
import React, { ReactElement, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { TextSpan } from "typescript";
import {
    AssociateChildNodeTypeEvent,
    AssociateLinkedNodeTypeEvent,
    DissociateChildNodeTypeEvent,
    DissociateLinkedNodeTypeEvent,
} from "../../../core/constants/application-insights-events";
import {
    ChecklistQuestions,
    Common,
    ErrorMessages,
    Hierarchy,
    HierarchyTypes,
    NodeTypes,
    NodeTypeValues,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    LargeVerticalSpace,
    maxContentWidthSelectStyle,
    NoDataStateDiv,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import {
    linkedNodeTypeColumnNames,
    nameColumnNames,
} from "../../../core/utilities/dataTableColumns";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areMutationsLoading,
    areQueriesLoading,
    areQueriesPartiallySuccessful,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import {
    BasePaginationDto,
    createBasePaginationDto,
    defaultBasePaginationDto,
} from "../../../domain/dtos/common/base-pagination-dto";
import { createAssociateDisassociateNodeTypeDto } from "../../../domain/dtos/hierarchy/associate-disassociate-node-type-dto";
import { createAssociateLinkedNodeTypeDto } from "../../../domain/dtos/hierarchy/associate-linked-node-type-dto";
import { createDisassociateLinkedNodeTypeDto } from "../../../domain/dtos/hierarchy/disassociate-linked-node-type-dto";
import { LinkedNodeTypeDto } from "../../../domain/dtos/hierarchy/linked-node-type-dto";
import { NodeTypeDto } from "../../../domain/dtos/hierarchy/node-type-dto";
import { hasRoleTypeInGroup, NodeTypeRoleGroup, Role } from "../../../domain/enums/Roles";
import {
    useAssociateChildNodeType,
    useAssociateLinkedNodeType,
    useDisassociateChildNodeType,
    useDisassociateLinkedNodeType,
    useGetNodeTypeData,
} from "../../../domain/viewmodels/hierarchy/node-type-details-viewmodel";
import { SbSelect } from "../../atoms/input/SbSelect";
import { SbAlert } from "../../atoms/SbAlert";
import { SbButton } from "../../atoms/SbButton";
import { EditLink, SbLink, ViewLink } from "../../atoms/SbLink";
import { SbAccordion } from "../../molecules/SbAccordion";
import { DataTable } from "../../organisms/DataTable";
import { NodeTypeValuesFilter } from "../../organisms/filters/NodeTypeValuesFilter";

const NodeTypeDetailsContainer: React.FC = () => {
    const [childNodeTypesPaginationDto, setChildNodeTypesPaginationDto] =
        useState<BasePaginationDto>(defaultBasePaginationDto);
    const [linkedNodeTypesPaginationDto, setLinkedNodeTypesPaginationDto] =
        useState<BasePaginationDto>(defaultBasePaginationDto);
    const [nodeTypeValuesPaginationDto, setNodeTypeValuesPaginationDto] =
        useState<BasePaginationDto>(defaultBasePaginationDto);

    const [intermediateNodeTypeValueSearchValue, setIntermediateNodeTypeValueSearchValue] =
        useState<string | null>(null);
    const [nodeTypeValueSearchValue, setNodeTypeValueSearchValue] = useState<string | null>(null);
    const [childNodeTypeToAssociate, setChildNodeTypeToAssociate] = useState<NodeTypeDto | null>(
        null
    );

    const [linkedNodeTypeToAssociate, setLinkedNodeTypeToAssociate] = useState<NodeTypeDto | null>(
        null
    );
    const [isRequiredValue, setIsRequiredValue] = useState<boolean>(false);

    const menu = useMenu();
    const auth = useAuth();
    const navigate = useNavigate();
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const navigateSearch = useNavigateSearch();
    const toast = useToast();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";
    const nodeTypeId = Number(useParams().nodeTypeId);

    const getNodeTypeData = useGetNodeTypeData(
        nodeTypeId,
        nodeTypeValueSearchValue,
        nodeTypeValuesPaginationDto,
        childNodeTypesPaginationDto,
        linkedNodeTypesPaginationDto
    );

    const associateChildNodeType = useAssociateChildNodeType();
    const disassociateChildNodeType = useDisassociateChildNodeType();

    const associateLinkedNodeType = useAssociateLinkedNodeType();
    const disassociateLinkedNodeType = useDisassociateLinkedNodeType();

    const getNodeTypeDetails = getNodeTypeData[0];
    const getNodeTypeValues = getNodeTypeData[1];
    const getChildNodeTypes = getNodeTypeData[2];
    const getLinkableChildNodeTypes = getNodeTypeData[3];
    const getLinkedNodeTypes = getNodeTypeData[4];
    const filterNodeTypes = getNodeTypeData[5];

    const nodeTypeDetailsResponseData = getNodeTypeDetails.data;
    const nodeTypeValuesResponseData = getNodeTypeValues.data;

    const { t } = useTranslation("translation");

    useLoader(
        areQueriesLoading(getNodeTypeData) ||
            areMutationsLoading([
                associateChildNodeType,
                disassociateChildNodeType,
                associateLinkedNodeType,
                disassociateLinkedNodeType,
            ]),
        NodeTypeDetailsContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.VisualStructure);

        if (success) {
            toast.addToast(createSuccessToastProps([t(messageKey, { keyPrefix: NodeTypes })]));

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    // Event Handlers
    // Related Values
    const onResetNodeTypeValuesFilter = (): void => {
        setIntermediateNodeTypeValueSearchValue(null);
        searchNodeTypeValues(null);
    };

    const searchNodeTypeValues = (nodeTypeValueValue: string | null): void => {
        setNodeTypeValueSearchValue(nodeTypeValueValue);
        setNodeTypeValuesPaginationDto(
            createBasePaginationDto(1, nodeTypeValuesPaginationDto.pageSize)
        );
    };

    const navigateToEditNodeTypeValue = (nodeTypeValueId: number): void => {
        navigate(`${getPath(AccordionTitles.VisualStructureValues)}/${nodeTypeValueId}/edit`);
    };

    const navigateToDeleteNodeTypeValue = (nodeTypeValueId: number): void => {
        const params = [createNavigateSearchParameter("nodeTypeId", nodeTypeId.toString())];

        navigateSearch(
            `${getPath(AccordionTitles.VisualStructureValues)}/${nodeTypeValueId}/delete`,
            params
        );
    };

    // Child Links
    const onAssociateChildNodeType = (): void => {
        if (!childNodeTypeToAssociate) {
            return;
        }

        const params = createAssociateDisassociateNodeTypeDto(
            nodeTypeId,
            childNodeTypeToAssociate.nodeTypeId
        );

        associateChildNodeType.mutate(params, {
            onSuccess: () =>
                onAssociateDisassociateChildNodeTypeSuccess(
                    t("AssociateHierarchyLevelSuccessMessage", { keyPrefix: NodeTypes }),
                    AssociateChildNodeTypeEvent
                ),
            onError: (error: HTTPError) => {
                trackAppInsightsException(
                    auth.email,
                    window.location.href,
                    AssociateChildNodeTypeEvent,
                    error
                );
                errorResponseToDisplayHandler(error);
            },
        });
    };

    const onDisassociateChildNodeType = (childNodeTypeId: number): void => {
        const params = createAssociateDisassociateNodeTypeDto(nodeTypeId, childNodeTypeId);

        disassociateChildNodeType.mutate(params, {
            onSuccess: () =>
                onAssociateDisassociateChildNodeTypeSuccess(
                    t("DissociateHierarchyLevelSuccessMessage", { keyPrefix: NodeTypes }),
                    DissociateChildNodeTypeEvent
                ),
            onError: (error: HTTPError) => {
                trackAppInsightsException(
                    auth.email,
                    window.location.href,
                    DissociateChildNodeTypeEvent,
                    error
                );
                errorResponseToDisplayHandler(error);
            },
        });
    };

    const onAssociateDisassociateChildNodeTypeSuccess = async (
        message: string,
        eventType: any
    ): Promise<void> => {
        trackAppInsightsEvent(auth.email, window.location.href, eventType);

        queryClient.refetchQueries([
            "filterChildNodeTypes",
            nodeTypeId,
            childNodeTypesPaginationDto,
        ]);

        queryClient.refetchQueries(["filterLinkableChildNodeTypes", nodeTypeId]);

        setChildNodeTypeToAssociate(null);

        toast.addToast(createSuccessToastProps([message]));
    };

    // Linked Hierarchies
    const onAssociateLinkedNodeType = (): void => {
        if (!linkedNodeTypeToAssociate) {
            return;
        }

        associateLinkedNodeType.mutate(
            createAssociateLinkedNodeTypeDto(
                nodeTypeId,
                linkedNodeTypeToAssociate.nodeTypeId,
                isRequiredValue
            ),
            {
                onSuccess: () =>
                    onAssociateDisassociateLinkedNodeTypeSuccess(
                        t("LinkHierarchyLevelSuccessMessage", { keyPrefix: NodeTypes }),
                        AssociateLinkedNodeTypeEvent
                    ),
                onError: (error: HTTPError) => {
                    trackAppInsightsException(
                        auth.email,
                        window.location.href,
                        AssociateLinkedNodeTypeEvent,
                        error
                    );
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    };

    const onDisassociateLinkedNodeType = (linkedNodeTypeDto: LinkedNodeTypeDto): void => {
        const { primaryNodeTypeId, linkedNodeTypeId } = linkedNodeTypeDto;

        const params = createDisassociateLinkedNodeTypeDto(primaryNodeTypeId, linkedNodeTypeId);

        disassociateLinkedNodeType.mutate(params, {
            onSuccess: () =>
                onAssociateDisassociateLinkedNodeTypeSuccess(
                    t("UnlinkHierarchyLevelSuccessMessage", { keyPrefix: NodeTypes }),
                    DissociateLinkedNodeTypeEvent
                ),
            onError: (error: HTTPError) => {
                trackAppInsightsException(
                    auth.email,
                    window.location.href,
                    DissociateLinkedNodeTypeEvent,
                    error
                );
                errorResponseToDisplayHandler(error);
            },
        });
    };

    const onAssociateDisassociateLinkedNodeTypeSuccess = async (
        message: string,
        eventType: any
    ): Promise<void> => {
        trackAppInsightsEvent(auth.email, window.location.href, eventType);

        queryClient.refetchQueries([
            "filterLinkedNodeTypes",
            nodeTypeId,
            linkedNodeTypesPaginationDto,
        ]);

        setLinkedNodeTypeToAssociate(null);
        setIsRequiredValue(false);

        toast.addToast(createSuccessToastProps([message]));
    };

    // UI Building
    // Details
    const hasParentNodeTypes = (): boolean =>
        nodeTypeDetailsResponseData!.parentNodeTypes != null &&
        nodeTypeDetailsResponseData!.parentNodeTypes.length > 0;

    const buildParentNodeTypeLinks = (): (boolean | string | JSX.Element)[][] | undefined =>
        nodeTypeDetailsResponseData!.parentNodeTypes?.map((x, index) => [
            index > 0 && ", ",
            <SbLink
                variant="primary"
                label={x.name}
                navigateTo={`${getPath(AccordionTitles.VisualStructure)}/${x.nodeTypeId}`}
            />,
        ]);

    const buildLinks = (): ReactElement<HTMLLinkElement[]> => (
        <>
            {hasRoleTypeInGroup(auth.userRoles, NodeTypeRoleGroup.WriteRoles) && buildEditLink()}
            {hasRoleTypeInGroup(auth.userRoles, NodeTypeRoleGroup.WriteRoles) && (
                <ViewLink
                    label={t("HierarchyType", { keyPrefix: HierarchyTypes })}
                    navigateTo={`${getPath(AccordionTitles.Admin)}/${
                        nodeTypeDetailsResponseData!.hierarchyTypeId
                    }`}
                />
            )}
        </>
    );

    const buildEditLink = (): ReactElement<HTMLLinkElement> => {
        const showEditLink =
            !nodeTypeDetailsResponseData!.isSystemRoot &&
            hasRoleTypeInGroup(auth.userRoles, NodeTypeRoleGroup.WriteRoles);

        if (!showEditLink) {
            return <></>;
        }

        return (
            <EditLink
                navigateTo={`${getPath(AccordionTitles.VisualStructure)}/${nodeTypeId}/edit`}
            />
        );
    };

    // Related Values
    const buildNodeTypeValues = (): ReactElement<HTMLTableElement> => (
        <>
            <NodeTypeValuesFilter
                nodeTypeValueValue={intermediateNodeTypeValueSearchValue}
                changeNodeTypeValueValue={(nodeTypeValueValue: string) => {
                    setIntermediateNodeTypeValueSearchValue(nodeTypeValueValue);
                }}
                search={() => searchNodeTypeValues(intermediateNodeTypeValueSearchValue)}
                resetFilter={onResetNodeTypeValuesFilter}
            />
            <LargeVerticalSpace />

            <DataTable
                keyPrefix={ChecklistQuestions}
                columns={nameColumnNames}
                rows={nodeTypeValuesResponseData!.rows}
                noResultsMessage={`${t("NoChildNodeTypeValues", { keyPrefix: NodeTypeValues })}`}
                editItem={
                    hasRoleTypeInGroup(auth.userRoles, NodeTypeRoleGroup.WriteRoles)
                        ? navigateToEditNodeTypeValue
                        : undefined
                }
                deleteItem={
                    hasRoleTypeInGroup(auth.userRoles, NodeTypeRoleGroup.WriteRoles)
                        ? navigateToDeleteNodeTypeValue
                        : undefined
                }
                totalItems={nodeTypeValuesResponseData!.recordCount}
                paginationDto={nodeTypeValuesPaginationDto}
                setPaginationDto={setNodeTypeValuesPaginationDto}
            />
        </>
    );

    // Child Links
    const buildAddChild = (): ReactElement<HTMLFormElement> => {
        if (!isQuerySuccessful(getLinkableChildNodeTypes)) {
            return <></>;
        }

        return (
            <Form.Group as={Row} className="mb justify-content-end">
                <Col sm={2}>
                    <SbSelect
                        name={"childNodeTypes"}
                        placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                        items={getLinkableChildNodeTypes.data}
                        itemLabel={(option: NodeTypeDto) => option.name} //TODO: Add translations for dynamic data
                        itemValue={(option: NodeTypeDto) => option.name}
                        searchable
                        styles={maxContentWidthSelectStyle}
                        clearable
                        onChange={(option: SingleValue<NodeTypeDto>) =>
                            setChildNodeTypeToAssociate(option ?? null)
                        }
                        value={childNodeTypeToAssociate}
                    />
                </Col>
                {hasRoleTypeInGroup(auth.userRoles, NodeTypeRoleGroup.WriteRoles) && (
                    <>
                        <Col sm={"auto"}>
                            <SbButton
                                variant={"primary"}
                                type={"button"}
                                label={t("AddAsChild", { keyPrefix: NodeTypes })}
                                onClick={onAssociateChildNodeType}
                            />
                        </Col>
                        <Col sm={"auto"}>{"or"}</Col>
                        <Col sm={"auto"}>
                            <SbButton
                                variant={"primary"}
                                type={"button"}
                                label={t("AddNewChild", { keyPrefix: Common })}
                                onClick={onAddNewChildClicked}
                            />
                        </Col>
                    </>
                )}
            </Form.Group>
        );
    };

    const onAddNewChildClicked = (): void => {
        const params = [createNavigateSearchParameter("parentNodeTypeId", nodeTypeId.toString())];

        navigateSearch(`${getPath(AccordionTitles.VisualStructure)}/create`, params);
    };

    const buildChildNodeTypes = (): ReactElement<HTMLTableElement | TextSpan> => {
        if (!isQuerySuccessful(getChildNodeTypes)) {
            return <></>;
        }

        const childNodeTypesData = getChildNodeTypes.data!;

        return childNodeTypesData.recordCount == 0 ? (
            <NoDataStateDiv>{t("NoChildNodeTypes", { keyPrefix: NodeTypes })}</NoDataStateDiv>
        ) : (
            <DataTable
                columns={nameColumnNames}
                rows={childNodeTypesData.rows}
                deleteItem={
                    hasRoleTypeInGroup(auth.userRoles, NodeTypeRoleGroup.WriteRoles)
                        ? onDisassociateChildNodeType
                        : undefined
                }
                totalItems={childNodeTypesData.recordCount}
                paginationDto={childNodeTypesPaginationDto}
                setPaginationDto={setChildNodeTypesPaginationDto}
            />
        );
    };

    // Linked Hierarchies
    const buildAddAsLinked = (): ReactElement<HTMLFormElement> => {
        if (!isQuerySuccessful(filterNodeTypes)) {
            return <></>;
        }

        return (
            <Form.Group as={Row} className="mb justify-content-end">
                <Col sm={2}>
                    <SbSelect
                        name={"linkedNodeTypes"}
                        placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                        items={filterNodeTypes.data}
                        itemLabel={(option: NodeTypeDto) => option.name} //TODO: Add translations for dynamic data
                        itemValue={(option: NodeTypeDto) => option.name}
                        searchable
                        styles={maxContentWidthSelectStyle}
                        clearable
                        loading={isQueryLoading(filterNodeTypes)}
                        onChange={(option: SingleValue<NodeTypeDto>) =>
                            setLinkedNodeTypeToAssociate(option ?? null)
                        }
                        value={linkedNodeTypeToAssociate}
                    />
                </Col>
                <Col sm={"auto"}>
                    <Form.Check
                        name="isRequired"
                        label={t("IsRequired", { keyPrefix: Common })}
                        checked={isRequiredValue}
                        onChange={() => setIsRequiredValue(!isRequiredValue)}
                    />
                </Col>
                <Col sm={"auto"}>
                    <SbButton
                        variant={"primary"}
                        type={"button"}
                        label={t("AddAsLinked", { keyPrefix: Common })}
                        onClick={onAssociateLinkedNodeType}
                    />
                </Col>
            </Form.Group>
        );
    };

    const buildLinkedNodeTypes = (): ReactElement<HTMLTableElement | TextSpan> => {
        if (!isQuerySuccessful(getLinkedNodeTypes)) {
            return <></>;
        }

        const linkedNodeTypesData = getLinkedNodeTypes.data!;

        return linkedNodeTypesData.recordCount == 0 ? (
            <NoDataStateDiv>{t("NoLinkedNodeTypes", { keyPrefix: NodeTypes })}</NoDataStateDiv>
        ) : (
            <DataTable
                columns={linkedNodeTypeColumnNames}
                rows={linkedNodeTypesData.rows}
                deleteItem={
                    hasRoleTypeInGroup(auth.userRoles, NodeTypeRoleGroup.WriteRoles)
                        ? onDisassociateLinkedNodeType
                        : undefined
                }
                totalItems={linkedNodeTypesData.recordCount}
                paginationDto={linkedNodeTypesPaginationDto}
                setPaginationDto={setLinkedNodeTypesPaginationDto}
            />
        );
    };

    if (!hasRoleTypeInGroup(auth.userRoles, [Role.TenantAdmin, Role.SystemAdmin])) {
        return (
            <>
                <SbAlert
                    variant={"warning"}
                    text={t("ResourcePermissionError", { keyPrefix: ErrorMessages })}
                />
            </>
        );
    }

    return (
        <>
            <PageHeading>{t("NodeTypeDetailsTitle", { keyPrefix: NodeTypes })}</PageHeading>
            <PageSubHeading>{t("HeaderHelpText", { keyPrefix: NodeTypes })}</PageSubHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(getNodeTypeDetails) && (
                <>
                    <ContentContainer>
                        <Row>
                            <Col md="auto">
                                <DetailsLabel>
                                    {t("Name", { keyPrefix: ChecklistQuestions })}
                                </DetailsLabel>
                                <DetailsLabel>
                                    {t("HierarchyType", { keyPrefix: HierarchyTypes })}
                                </DetailsLabel>
                                <DetailsLabel>
                                    {t("ContainsTimeZoneCode", { keyPrefix: Hierarchy })}
                                </DetailsLabel>
                                <DetailsLabel>
                                    {hasParentNodeTypes() &&
                                        t("ParentNodeTypes", { keyPrefix: NodeTypes })}
                                </DetailsLabel>
                            </Col>
                            <Col md="auto">
                                <DetailsValue>{nodeTypeDetailsResponseData!.name}</DetailsValue>
                                <DetailsValue>
                                    <SbLink
                                        variant="primary"
                                        label={nodeTypeDetailsResponseData!.hierarchyType.name}
                                        navigateTo={`${getPath(AccordionTitles.Admin)}/${
                                            nodeTypeDetailsResponseData!.hierarchyTypeId
                                        }`}
                                    />
                                </DetailsValue>
                                <DetailsValue>
                                    <Form.Check
                                        defaultChecked={
                                            nodeTypeDetailsResponseData!.containsTimeZoneCode
                                        }
                                        disabled
                                    />
                                </DetailsValue>
                                <DetailsValue>{buildParentNodeTypeLinks()}</DetailsValue>
                            </Col>
                        </Row>

                        <EndAlignedDiv>{buildLinks()}</EndAlignedDiv>
                    </ContentContainer>
                    <SectionVerticalSpace />
                </>
            )}

            {isQuerySuccessful(getNodeTypeValues) && (
                <>
                    <SbAccordion title={t("ListChildrenNodeTypeValues", { keyPrefix: NodeTypes })}>
                        {buildNodeTypeValues()}
                    </SbAccordion>
                    <SectionVerticalSpace />
                </>
            )}

            {areQueriesPartiallySuccessful([getChildNodeTypes, getLinkableChildNodeTypes]) && (
                <>
                    <SbAccordion
                        title={t("ChildLinksListOfChildLevelsAssociated", { keyPrefix: NodeTypes })}
                    >
                        {buildAddChild()}
                        {buildChildNodeTypes()}
                    </SbAccordion>
                    <SectionVerticalSpace />
                </>
            )}

            {areQueriesPartiallySuccessful([getLinkedNodeTypes, filterNodeTypes]) && (
                <>
                    <SbAccordion title={t("ListLinkedNodeTypes", { keyPrefix: NodeTypes })}>
                        {hasRoleTypeInGroup(auth.userRoles, NodeTypeRoleGroup.WriteRoles) &&
                            buildAddAsLinked()}
                        {buildLinkedNodeTypes()}
                    </SbAccordion>
                </>
            )}
        </>
    );
};

export default NodeTypeDetailsContainer;
