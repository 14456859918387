import CreateQuestionSetInstanceAnswerReviewDto from "../../dtos/question-set-instance-answers/create-question-set-instance-answer-review-dto";
import { useAnswerCapture } from "../../stores/answer-capture-context";

export const useOnQuestionReviewSubmit = (): ((
    questionSetInstanceAnswerId: number,
    commentText: string
) => void) => {
    const answerCapture = useAnswerCapture();

    return (questionSetInstanceAnswerId: number, commentText: string): void => {
        const dto = new CreateQuestionSetInstanceAnswerReviewDto(
            questionSetInstanceAnswerId,
            commentText
        );

        answerCapture.updateCreatingQuestionReviewComment(dto);
    };
};
