import { ReactSelectDto } from "../common/react-select-dto";

export interface SearchPendingAdhocsDto {
    node: string | null;
    checklistName: string | null;
    referentialLinks: string | null;
    triggeredFor: string | null;
    triggeredBy: string | null;
    isTraining: boolean | null;
    data: ReactSelectDto<string> | null;
}

export const createSearchPendingAdhocsDto = (
    node: string | null,
    checklistName: string | null,
    referentialLinks: string | null,
    triggeredFor: string | null,
    triggeredBy: string | null,
    isTraining: boolean | null,
    data: ReactSelectDto<string> | null
): SearchPendingAdhocsDto => ({
    node: node,
    checklistName: checklistName,
    referentialLinks: referentialLinks,
    triggeredFor: triggeredFor,
    triggeredBy: triggeredBy,
    isTraining: isTraining,
    data: data,
});

export const emptySearchPendingAdhocsDto = (): SearchPendingAdhocsDto =>
    createSearchPendingAdhocsDto(null, null, null, null, null, null, {
        value: "All",
        label: "All",
    });
