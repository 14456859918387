import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import { Audits, Common } from "../../../core/constants/translation-namespace";
import {
    ContentContainer,
    EndAlignedDiv,
    SectionContainer,
} from "../../../core/theme/global-styles";
import { auditDropdownItems } from "../../../core/utilities/filterDropdownItems";
import {
    createReactSelectDto,
    ReactSelectDto,
    valuesToReactSelectDtos,
} from "../../../domain/dtos/common/react-select-dto";
import { ResetButton, SearchButton } from "../../atoms/SbButton";
import { FilterInput } from "../../molecules/FilterInput";
import { FilterSelect } from "../../molecules/FilterSelect";

export const AuditFilter: React.FC<{
    tableName: string | null;
    username: string | null;
    startDate: string | null;
    endDate: string | null;
    entityKey: string | null;
    changeTableName: (tableName: string) => void;
    changeUsername: (username: string) => void;
    changeStartDate: (startDate: string) => void;
    changeEndDate: (endDate: string) => void;
    actionType: string | null;
    changeActionType: (option: SingleValue<ReactSelectDto<string>>) => void;
    changeEntityKey: (entityKey: string) => void;
    search: () => void;
    resetFilter: () => void;
}> = ({
    tableName,
    changeTableName,
    username,
    changeUsername,
    startDate,
    changeStartDate,
    endDate,
    changeEndDate,
    actionType,
    changeActionType,
    entityKey,
    changeEntityKey,
    search,
    resetFilter,
}) => {
    const { t } = useTranslation("translation", { keyPrefix: Audits });
    return (
        <ContentContainer>
            <SectionContainer>
                <Row>
                    <Col>
                        <FilterInput
                            title={t("Username")}
                            name={"username"}
                            type={"text"}
                            onChangeEventHandler={changeUsername}
                            value={username ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("TableName")}
                            name={"tableName"}
                            type={"text"}
                            onChangeEventHandler={changeTableName}
                            value={tableName ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("PrimaryKey")}
                            name={"entityKey"}
                            type={"text"}
                            onChangeEventHandler={changeEntityKey}
                            value={entityKey ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("DateFrom")}
                            name={"dateFrom"}
                            type={"date"}
                            onChangeEventHandler={changeStartDate}
                            value={startDate ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("DateTo")}
                            name={"dateTo"}
                            type={"date"}
                            onChangeEventHandler={changeEndDate}
                            value={endDate ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterSelect
                            name={"actionType"}
                            label={t("EventType")!}
                            placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                            searchable
                            clearable
                            items={valuesToReactSelectDtos(auditDropdownItems)}
                            itemDisplayText={(option: ReactSelectDto<string>) =>
                                t(option.value, { keyPrefix: Common })
                            }
                            onChange={changeActionType}
                            value={
                                actionType != null
                                    ? createReactSelectDto(actionType, actionType)
                                    : null
                            }
                        />
                    </Col>
                </Row>
            </SectionContainer>
            <EndAlignedDiv>
                <SearchButton onClick={search} />
                <ResetButton onClick={resetFilter} />
            </EndAlignedDiv>
        </ContentContainer>
    );
};
