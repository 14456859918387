import { useMutation, UseMutationResult, useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { filterHierarchyTypes } from "../../../data/services/hierarchy/hierarchy-types-service";
import { assignUserToNode } from "../../../data/services/hierarchy/nodes-service";
import { getUserDetails } from "../../../data/services/users/users-service";
import { HierarchyTypeDto, toHierarchyTypeDtos } from "../../dtos/hierarchy/hierarchy-type-dto";
import { AssociateDissociateUserNodeDto } from "../../dtos/users/associate-dissociate-user-node-dto";
import { createUserDtoFromResponse, UserDto } from "../../dtos/users/user-dto";
import { createBasePaginationRequest } from "../../requests/common/base-pagination-request";
import { createFilterHierarchyTypesRequest } from "../../requests/hierarchy/filter-hierarchy-types-request";
import { emptySearchHierarchyTypesRequest } from "../../requests/hierarchy/search-hierarchy-types-request";
import { AssociateDissociateUserNodeResponse } from "../../requests/users/associate-dissociate-user-node-request";
import { Response } from "../../responses/common/response-response";

export const useGetUserDetails = (
    userId: number
): [UseQueryResult<UserDto, HTTPError>, UseQueryResult<HierarchyTypeDto[], HTTPError>] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getUserDetails", userId],
                queryFn: () => getUserDetails(url.baseUrl, userId),
                select: createUserDtoFromResponse,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["filterHierarchyTypes"],
                queryFn: () =>
                    filterHierarchyTypes(
                        url.baseUrl,
                        createFilterHierarchyTypesRequest(
                            createBasePaginationRequest(1, 1000),
                            emptySearchHierarchyTypesRequest()
                        )
                    ),
                select: toHierarchyTypeDtos,
            },
        ],
    }) as [UseQueryResult<UserDto, HTTPError>, UseQueryResult<HierarchyTypeDto[], HTTPError>];
};

export const useAssignUserToNode = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    AssociateDissociateUserNodeDto
> => {
    const url = useUrl();

    return useMutation((dto: AssociateDissociateUserNodeDto) =>
        assignUserToNode(url.baseUrl, new AssociateDissociateUserNodeResponse(dto))
    );
};
