import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IconType } from "react-icons";
import { FaLock, FaLockOpen } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Users } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { isMutationLoading } from "../../../core/utilities/responseStateHelper";
import { useToggleUserLockout } from "../../../domain/viewmodels/users/toggle-user-viewmodel";
import { SbButton } from "../../atoms/SbButton";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import { ActionConfirmationFragment } from "../../organisms/ActionConfirmationFragment";
import { ConfirmationDetailsProps } from "./UserDetailsContainer";

export interface UserLockoutConfirmationProps extends ConfirmationDetailsProps {
    isLockedOut: boolean;
}

const ToggleUserLockoutContainer: React.FC = () => {
    const menu = useMenu();
    const location = useLocation();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: Users });

    const userId = Number(useParams().userId);
    const { username, isLockedOut } = location.state as UserLockoutConfirmationProps; // TODO: Fetching Checklist data on this page vs passing it through from the previous page

    const toggleUserLockout = useToggleUserLockout(isLockedOut);

    useLoader(isMutationLoading(toggleUserLockout), ToggleUserLockoutContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.UserManagement, AccordionTitles.Users);
    }, []);

    const getPageHeading = (): string => (isLockedOut ? t("Unlock") : t("UserLockoutTitle"));

    const getPanelTitle = (): string =>
        isLockedOut ? t("UnlockConfirmation") : t("LockoutUserConfirmation");

    const getButtonLabel = (): string => (isLockedOut ? t("Unlock") : t("Lockout"));

    const getButtonIcon = (): IconType => (isLockedOut ? FaLockOpen : FaLock);

    const buildConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow(t("UserName"), username),
    ];

    const handleToggleUserLockout = (): void => {
        toggleUserLockout.mutate(userId, {
            onSuccess: async () => navigate(-1),
            onError: errorResponseToDisplayHandler,
        });
    };

    const buildDeleteButton = (): ReactElement<typeof Button> => (
        <SbButton
            variant="primary"
            type="button"
            label={getButtonLabel()}
            icon={getButtonIcon()}
            onClick={handleToggleUserLockout}
        />
    );

    return (
        <ActionConfirmationFragment
            pageHeading={getPageHeading()}
            panelTitle={getPanelTitle()}
            rows={buildConfirmationRows()}
            primaryButton={buildDeleteButton()}
        />
    );
};

export default ToggleUserLockoutContainer;
