import { HTTPError } from "ky";
import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { DeleteChecklistEvent } from "../../../core/constants/application-insights-events";
import { ChecklistQuestions, Common, Users } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import { useSoftDeleteChecklist } from "../../../domain/viewmodels/checklists/delete-checklist-viewmodel";
import { useGetChecklist } from "../../../domain/viewmodels/checklists/view-checklist-viewmodel";
import { DeleteButton } from "../../atoms/SbButton";
import {
    CheckboxConfirmationRow,
    ConfirmationRow,
    TextConfirmationRow,
} from "../../organisms/ActionConfirmation";
import { ActionConfirmationFragment } from "../../organisms/ActionConfirmationFragment";

const DeleteChecklistContainer: React.FC = () => {
    const menu = useMenu();
    const navigate = useNavigate();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const checklistId = Number(useParams().checklistId);

    const checklistDetailsResponse = useGetChecklist(checklistId);
    const softDeleteChecklist = useSoftDeleteChecklist();

    const checklistDetailsResponseData = checklistDetailsResponse.data;

    useLoader(
        isQueryLoading(checklistDetailsResponse) || isMutationLoading(softDeleteChecklist),
        DeleteChecklistContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Checklists);
    }, []);

    const buildDeleteButton = (): ReactElement<typeof Button> => (
        <DeleteButton onClick={() => deleteChecklist(checklistId)} />
    );

    const deleteChecklist = (checklistId: number): void => {
        softDeleteChecklist.mutate(checklistId, {
            onSuccess: async () => {
                trackAppInsightsEvent(auth.email, window.location.href, DeleteChecklistEvent);
                navigate(-1);
            },
            onError: (error: HTTPError) => {
                trackAppInsightsException(
                    auth.email,
                    window.location.href,
                    DeleteChecklistEvent,
                    error
                );
                errorResponseToDisplayHandler(error);
            },
        });
    };

    const buildConfirmationRows = (): ConfirmationRow<any>[] => [
        new TextConfirmationRow(t("Name"), checklistDetailsResponseData?.name),
        new TextConfirmationRow(
            t("Description", { keyPrefix: Common }),
            checklistDetailsResponseData?.description
        ),
        new TextConfirmationRow(
            t("ChecklistType"),
            checklistDetailsResponseData?.checklistType.toString()
        ),
        new TextConfirmationRow(
            t("CreatedByUserEmail", { keyPrefix: Users }),
            checklistDetailsResponseData?.createdByUserName
        ),
        new TextConfirmationRow(
            t("DateCreated", { keyPrefix: Common }),
            checklistDetailsResponseData?.dateCreatedLocal
        ),
        new TextConfirmationRow(
            t("ModifiedByUserEmail", { keyPrefix: Users }),
            checklistDetailsResponseData?.modifiedByUserName
        ),
        new TextConfirmationRow(
            t("DateLastModified", { keyPrefix: Common }),
            checklistDetailsResponseData?.dateModifiedLocal
        ),
        new CheckboxConfirmationRow(
            t("ChecklistAssignmentIsTrainingDisplay"),
            checklistDetailsResponseData?.isTraining
        ),
    ];

    return (
        <>
            <ActionConfirmationFragment
                pageHeading={t("ChecklistDeleteTitle")}
                panelTitle={t("DeleteChecklistConfirmation")}
                rows={buildConfirmationRows()}
                primaryButton={buildDeleteButton()}
                areQueriesSuccessful={isQuerySuccessful(checklistDetailsResponse)}
            />
        </>
    );
};

export default DeleteChecklistContainer;
