import { createPaginationDto, defaultPaginationDto, PaginationDto } from "../common/pagination-dto";

export interface FilterAuditsSecurityLoginDto extends PaginationDto {
    username: string | null;
    startDate: string | null;
    endDate: string | null;
    ipAddress: string | null;
    source: string | null;
    wasSuccessful: string | null;
}

export const createFilterAuditsSecurityLoginDto = (
    username: string | null,
    startDate: string | null,
    endDate: string | null,
    ipAddress: string | null,
    source: string | null,
    wasSuccessful: string | null,
    pageNumber: number,
    pageSize: number,
    sortByColumn: string | null,
    sortByDescending: boolean
): FilterAuditsSecurityLoginDto => ({
    username: username,
    startDate: startDate,
    endDate: endDate,
    ipAddress: ipAddress,
    source: source,
    wasSuccessful: wasSuccessful,
    ...createPaginationDto(pageNumber, pageSize, sortByColumn, sortByDescending),
});

export const defaultFilterAuditsSecurityLoginDto = (): FilterAuditsSecurityLoginDto => {
    const { pageNumber, pageSize, sortByColumn, sortByDescending } = defaultPaginationDto;

    return createFilterAuditsSecurityLoginDto(
        null,
        null,
        null,
        null,
        null,
        null,
        pageNumber,
        pageSize,
        sortByColumn,
        sortByDescending
    );
};
