import { Response } from "../../responses/common/response-response";
import { AssignedUsersToNodeResponse } from "../../responses/hierarchy/assigned-users-to-node-response";
import { ListTableDto, toToDataTableUserDetailsDtoRows } from "../common/list-table-dto";

export interface AssignedUsersToNodeDto {
    isNodeUserAssignable: boolean;
    usersTableData: ListTableDto<number>;
}

export const createAssignedUsersToNodeDto = (
    response: Response<AssignedUsersToNodeResponse>
): AssignedUsersToNodeDto => {
    const responseData = response.data;

    return {
        isNodeUserAssignable: responseData.isNodeUserAssignable,
        usersTableData: toToDataTableUserDetailsDtoRows(responseData.users),
    };
};
