import { Col, Form, Row } from "react-bootstrap";
import { StyledFormLabel, StyledFormLeftAlignedLabel } from "../../../core/theme/global-styles";
import SbDateTimeField, { SbDateTimeFieldProps } from "../../atoms/input/SbDateTimeField";
import SbLabelText from "../../atoms/SbLabelText";
import { BaseSbFormGroupProps } from "../../props/input/InputFieldProps";

export interface SbFormDateTimeFieldGroupProps extends BaseSbFormGroupProps, SbDateTimeFieldProps {
    textAlign?: "left" | "right";
}

const SbFormDateTimeFieldGroup: React.FC<SbFormDateTimeFieldGroupProps> = ({
    name,
    value,
    defaultValue,
    excludeTime,
    required,
    disabled,
    label,
    textAlign,
}) => (
    <Form.Group as={Row} className="mb-3">
        {textAlign === "left" ? (
            <StyledFormLeftAlignedLabel column sm={2}>
                <SbLabelText label={label} required={required} />
            </StyledFormLeftAlignedLabel>
        ) : (
            <StyledFormLabel column sm={2}>
                <SbLabelText label={label} required={required} />
            </StyledFormLabel>
        )}
        <Col sm={4}>
            <SbDateTimeField
                name={name}
                value={value}
                defaultValue={defaultValue}
                excludeTime={excludeTime}
                required={required}
                disabled={disabled}
            />
        </Col>
    </Form.Group>
);

export default SbFormDateTimeFieldGroup;
