import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
} from "../../../domain/requests/common/base-pagination-request";
import { UploadImageRequest } from "../../../domain/requests/image-library/upload-image-request";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import { ImageLibraryViewResponse } from "../../../domain/responses/image-library/image-library-view-response";
import client from "../../http-client";

const route = "imagelibrary";

export const filterActiveImages = async (
    prefixUrl: string,
    request: BasePaginationRequest
): Promise<Response<PaginationResponse<ImageLibraryViewResponse>>> => {
    const searchParams = buildBasePaginationSearchParameters(request);
    return await client
        .get(`${route}/active-images/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getAllActiveImageNames = async (
    prefixUrl: string
): Promise<Response<ImageLibraryViewResponse[]>> => {
    return await client
        .get(`${route}/all-active-image-names`, {
            prefixUrl: prefixUrl,
        })
        .json();
};

export const softDeleteImage = async (
    prefixUrl: string,
    fileDetailsId: number
): Promise<Response<boolean>> => {
    const searchParams = new URLSearchParams();

    searchParams.set("fileDetailsId", fileDetailsId.toString());

    return await client
        .put(`${route}/delete`, {
            prefixUrl: prefixUrl,
            searchParams: searchParams,
        })
        .json();
};
export const uploadImage = async (
    prefixUrl: string,
    request: UploadImageRequest
): Promise<Response<number | null>> =>
    await client
        .post(`${route}/upload`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();
