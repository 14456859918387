import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useAuth } from "../../../core/store/auth-context";
import { useUrl } from "../../../core/store/url-context";
import { createNalaConversationToken } from "../../../data/services/nala/nala-service";
import {
    ConversationTokenDto,
    toConversationTokenDto,
} from "../../dtos/nala/conversation-token-dto";

export const useGetCreateNalaConversationToken = (): UseQueryResult<
    ConversationTokenDto,
    HTTPError
> => {
    const url = useUrl();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["createNalaConversationToken"],
        () => createNalaConversationToken(url.baseUrl),
        {
            enabled: auth.userId !== null && !auth.isAdmin && auth.tenantId == 1,
            select: toConversationTokenDto,
            onError: errorResponseToDisplayHandler,
        }
    );
};
