import { TriggeringDate } from "../../enums/notification-triggers/triggering-date";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { NotificationTriggerResponse } from "../../responses/notification-triggers/notification-trigger-response";
import { IntervalDto } from "../common/interval-dto";

export class NotificationTriggerDto {
    notificationTriggerId: number;
    name: string;
    triggerBefore: boolean;
    triggeringDate: TriggeringDate;
    triggerTimeDeltaIntervalDto: IntervalDto;
    sendWindowIntervalDto: IntervalDto;

    public constructor(response: NotificationTriggerResponse) {
        const {
            notificationTriggerId,
            name,
            triggerBefore,
            triggeringDate,
            triggerTimeDeltaIntervalResponse,
            sendWindowIntervalResponse,
        } = response;

        this.notificationTriggerId = notificationTriggerId;
        this.name = name;
        this.triggerBefore = triggerBefore;
        this.triggeringDate = triggeringDate;
        this.triggerTimeDeltaIntervalDto = new IntervalDto(triggerTimeDeltaIntervalResponse);
        this.sendWindowIntervalDto = new IntervalDto(sendWindowIntervalResponse);
    }

    public static constructFromResponse = (
        response: Response<NotificationTriggerResponse>
    ): NotificationTriggerDto => new NotificationTriggerDto(response.data);

    public static toNotificationTriggersDto = (
        response: PaginationResponse<NotificationTriggerResponse>
    ): NotificationTriggerDto[] => response.results!.map((x) => new NotificationTriggerDto(x));
}
