import { Col } from "react-bootstrap";
import { StyledFilterTitle } from "../../../core/theme/global-styles";
import SbNumberBox from "../../atoms/input/SbNumberBox";

export const FilterNumberInput: React.FC<{
    title: string;
    name: string;
    onChangeEventHandler: (value: string) => void;
    value?: string;
    minValue?: number;
    maxValue?: number;
}> = ({ title, name, onChangeEventHandler, value, minValue, maxValue }) => (
    <Col>
        <StyledFilterTitle>{title}</StyledFilterTitle>
        <SbNumberBox
            name={name}
            onInputChange={(value: number | null) => {
                onChangeEventHandler(value ? value.toString() : "");
            }}
            value={value}
            minValue={minValue && minValue}
            maxValue={maxValue && maxValue}
        />
    </Col>
);
