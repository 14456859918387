import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
} from "../common/base-pagination-request";

export class FilterQuestionSetInstanceAnswerDetailsRequest extends BasePaginationRequest {
    questionSetInstanceId: number;

    public constructor(questionSetInstanceId: number, basePaginationDto: BasePaginationDto) {
        super(basePaginationDto.pageNumber, basePaginationDto.pageSize);
        this.questionSetInstanceId = questionSetInstanceId;
    }
}

export const buildFilterQuestionSetInstanceAnswerDetailsRequest = (
    request: FilterQuestionSetInstanceAnswerDetailsRequest
): URLSearchParams => {
    const searchParams = buildBasePaginationSearchParameters(request);

    if (request.questionSetInstanceId) {
        searchParams.set("questionSetInstanceId", request.questionSetInstanceId.toString());
    }

    return searchParams;
};
