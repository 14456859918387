import { keyframes } from "styled-components";

export const shrinkStyledContentContainer = keyframes`
  from {
    padding-left: 100px;
  }

  to {
    padding-left: 200px;
  }
`;

export const growStyledContentContainer = keyframes`
from {
  padding-left: 200px;
}

to {
  padding-left: 100px;
}
`;
