import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useAuth } from "../../../core/store/auth-context";
import { useUrl } from "../../../core/store/url-context";
import { getAuthenticationProviders } from "../../../data/services/tenants/authentication-provider-service";
import { getTimeZonesFromNodeTypeValues } from "../../../data/services/timezones/time-zones-service";
import { getRoles } from "../../../data/services/users/roles-service";
import { createAdminUser, createTenantUser } from "../../../data/services/users/users-service";
import {
    AuthenticationProviderDto,
    toAuthenticationProviderDtos,
} from "../../dtos/tenants/authentication-provider-dto";
import TimeZoneDetailsDto from "../../dtos/timezone/time-zone-details-dto";
import { RoleDto, toRoleDtos } from "../../dtos/users/role-dto";
import { createPaginationRequest } from "../../requests/common/pagination-request";
import { CreateAdminUserRequest } from "../../requests/users/create-admin-user-request";
import { CreateTenantUserRequest } from "../../requests/users/create-tenant-user-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { RoleResponse } from "../../responses/users/role-response";

interface CreateAdminUserParameters {
    formData: FormData;
    roles: number[];
}

interface CreateTenantUserParameters extends CreateAdminUserParameters {
    timeZoneCountry: string;
    authenticationTypeId: number;
}

export const useGetCreateUserRoleDetails = (): UseQueryResult<RoleDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(["getRoles"], () => getRoles(url.baseUrl, createPaginationRequest(1, 1000)), {
        select: transformToRoleDtos,
        onError: errorResponseToDisplayHandler,
    });
};

export const useGetAuthenticationProviders = (): UseQueryResult<
    AuthenticationProviderDto[],
    HTTPError
> => {
    const url = useUrl();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(["getAuthenticationProviders"], () => getAuthenticationProviders(url.baseUrl), {
        enabled: auth.isImpersonating,
        select: toAuthenticationProviderDtos,
        onError: errorResponseToDisplayHandler,
    });
};

export const useGetTimeZonesFromNodeTypeValues = (): UseQueryResult<
    TimeZoneDetailsDto[],
    HTTPError
> => {
    const url = useUrl();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getTimeZonesFromNodeTypeValues"],
        () => getTimeZonesFromNodeTypeValues(url.baseUrl),
        {
            enabled: auth.isImpersonating,
            select: TimeZoneDetailsDto.toTimeZoneDetailsDtos,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useCreateAdminUser = (): UseMutationResult<
    Response<number>,
    HTTPError,
    CreateAdminUserParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: CreateAdminUserParameters) => {
        const request: CreateAdminUserRequest = buildCreateAdminRequest(mutationKey);

        return createAdminUser(url.originalBaseUrl, request);
    });
};

export const useCreateTenantUser = (): UseMutationResult<
    Response<number>,
    HTTPError,
    CreateTenantUserParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: CreateTenantUserParameters) => {
        const request = buildCreateTenantRequest(mutationKey);

        return createTenantUser(url.baseUrl, request);
    });
};

const transformToRoleDtos = (response: Response<PaginationResponse<RoleResponse>>): RoleDto[] =>
    toRoleDtos(response);

const buildCreateAdminRequest = (
    mutationKey: CreateAdminUserParameters
): CreateAdminUserRequest => {
    const formData = mutationKey.formData;

    return {
        emailAddress: formData.get("emailAddress")! as string,
        password: formData.get("password")! as string,
        firstName: formData.get("firstName")! as string,
        lastName: formData.get("lastName")! as string,
        roles: mutationKey.roles,
    };
};

const buildCreateTenantRequest = (
    mutationKey: CreateTenantUserParameters
): CreateTenantUserRequest => {
    return {
        ...buildCreateAdminRequest(mutationKey),
        timeZoneCountry: mutationKey.timeZoneCountry,
        authenticationProviderId: mutationKey.authenticationTypeId,
    };
};
