export const getNullableString = (value: string | null): string | null =>
    value !== "" ? value : null;

export const truncateString = (
    text: string,
    truncateLength: number,
    includeEllipsis: boolean = false
): string => {
    if (text.length <= truncateLength) {
        return text;
    }

    const truncatedText = text.slice(0, truncateLength);

    if (includeEllipsis) {
        return truncatedText.concat("...");
    }

    return truncatedText;
};

export const toBoolean = (value: string): boolean => {
    const truthy: string[] = ["true", "1"];

    return truthy.includes(value.toLowerCase());
};

export const extractCoreTime = (value: string | null): string => {
    if (!value || !value.includes(":")) {
        return "";
    }

    return value.substring(0, value.lastIndexOf(":"));
};

export const wrapText = (text: string, wrapIndex: number): string => {
    const length = text.length;
    const iterations = Math.ceil(length / wrapIndex);
    let newText = "";
    for (let i = 0; i < iterations; i++) {
        newText += text.substring(wrapIndex * i, wrapIndex * (i + 1)) + "\n";
    }

    return newText;
};
