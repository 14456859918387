import { Form, Table } from "react-bootstrap";
import styled from "styled-components";
import { EndAlignedDiv } from "../../core/theme/global-styles";
import SbTextArea from "../atoms/input/SbTextArea";
import { SbButton } from "../atoms/SbButton";

const StyledNestedTd = styled.th`
    font-weight: normal;
`;

export const StyledTable = styled(Table)`
    background-color: white;
`;

const StyledNestedDetailsDiv = styled.div`
    border-radius: 0.5rem;
    border: ${(props) => props.theme.padding.sm} solid ${(props) => props.theme.palette.blue};
    background-color: ${(props) => props.theme.palette.blue};
`;

export const NestedRemoveOrResolveForm: React.FC<{
    metadata: any;
    handleDeleteOrResolveItemComment: (
        event: React.FormEvent<HTMLFormElement>,
        metadata: any
    ) => void;
    inputFieldName: string;
    buttonLabel: string;
}> = ({ metadata, handleDeleteOrResolveItemComment, inputFieldName, buttonLabel }) => {
    return (
        <StyledNestedTd colSpan={11}>
            <StyledTable>
                <StyledNestedDetailsDiv>
                    <Form
                        onSubmit={(event: React.FormEvent<HTMLFormElement>): void =>
                            handleDeleteOrResolveItemComment(event, metadata)
                        }
                    >
                        <SbTextArea
                            name={`${inputFieldName} ${metadata}`}
                            rows={1}
                            maxLength={500}
                        />
                        <EndAlignedDiv>
                            <SbButton variant={"primary"} type="submit" label={`${buttonLabel}`} />
                        </EndAlignedDiv>
                    </Form>
                </StyledNestedDetailsDiv>
            </StyledTable>
        </StyledNestedTd>
    );
};
