import { AssociateDissociateQuestionToQuestionSetDto } from "../../dtos/question-sets/associate-dissociate-question-to-question-set-dto";
import { ExistingQuestionSetQuestionRequest } from "./existing-question-set-question-request";

export interface AssociateDissociateQuestionToQuestionSetRequest {
    questionScheduleBaseQuestions: ExistingQuestionSetQuestionRequest[];
}

export const createAssociateDissociateQuestionToQuestionSetRequest = (
    dto: AssociateDissociateQuestionToQuestionSetDto
): AssociateDissociateQuestionToQuestionSetRequest => {
    return { ...dto };
};
