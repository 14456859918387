import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ChecklistQuestions, Common } from "../../core/constants/translation-namespace";
import { NumberInputBox } from "../atoms/input/NumberInputBox";

const StyledDivFormField = styled.div`
    display: inline-block;
    padding-top: ${(props) => props.theme.padding.md};
    text-align: right;
    vertical-align: middle;
    margin-bottom: 0;
    box-sizing: border-box;
    padding-right: ${(props) => props.theme.padding.md};
`;

export const DailySchedule: React.FC<{
    handleDailyScheduleDescriptionChange: React.ChangeEventHandler<HTMLInputElement>;
    selectedDailyScheduleDescription: string;
    handleNumberOfDaysChange: React.ChangeEventHandler<HTMLInputElement>;
    numberOfDaysValue?: number;
}> = ({
    handleDailyScheduleDescriptionChange,
    selectedDailyScheduleDescription,
    handleNumberOfDaysChange,
    numberOfDaysValue,
}) => {
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });
    const [daysLabel, setDaysLabel] = React.useState<string>("Days");

    const updateDaysLabel = (value: string): void => {
        if (value && Number(value) === 1) {
            setDaysLabel("Day");
        } else {
            setDaysLabel("Days");
        }
    };
    return (
        <>
            <Form.Group className="mb">
                <StyledDivFormField>
                    <Form.Check
                        type="radio"
                        name="everyXDays"
                        label={t("Every")}
                        defaultChecked={selectedDailyScheduleDescription === "everyXDays"}
                        checked={selectedDailyScheduleDescription === "everyXDays"}
                        onChange={handleDailyScheduleDescriptionChange}
                    />
                </StyledDivFormField>
                <StyledDivFormField>
                    <NumberInputBox
                        handleNumberInputChange={(e) => {
                            handleNumberOfDaysChange;
                            updateDaysLabel(e.target.value);
                        }}
                        defaultValue={numberOfDaysValue}
                    />
                    &nbsp; {t(daysLabel, { keyPrefix: Common })?.toLocaleLowerCase()}
                </StyledDivFormField>
            </Form.Group>

            <Form.Group className="mb">
                <StyledDivFormField>
                    <Form.Check
                        type="radio"
                        name="everyWeekday"
                        label={t("EveryWeekday")}
                        defaultChecked={selectedDailyScheduleDescription === "everyWeekday"}
                        checked={selectedDailyScheduleDescription === "everyWeekday"}
                        onChange={handleDailyScheduleDescriptionChange}
                    />
                </StyledDivFormField>
            </Form.Group>
            <Form.Group className="mb">
                <StyledDivFormField>
                    <Form.Check
                        type="radio"
                        name="everyWeekday+Saturday"
                        label={t("EveryWeekdayPlusSaturday")}
                        defaultChecked={
                            selectedDailyScheduleDescription === "everyWeekday+Saturday"
                        }
                        checked={selectedDailyScheduleDescription === "everyWeekday+Saturday"}
                        onChange={handleDailyScheduleDescriptionChange}
                    />
                </StyledDivFormField>
            </Form.Group>
        </>
    );
};
