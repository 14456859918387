import { BaseIntervalDto } from "../../dtos/common/base-interval-dto";
import { PeriodType } from "../../enums/PeriodType";

export class IntervalRequest {
    value: number;
    periodType: PeriodType;

    constructor(value: number, periodType: PeriodType) {
        this.value = value;
        this.periodType = periodType;
    }

    public static constructFromDto = (dto: BaseIntervalDto): IntervalRequest => {
        const { value, periodType } = dto;

        return new IntervalRequest(value, periodType);
    };
}
