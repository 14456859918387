import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    editAnswerType,
    getAnswerTypeDetails,
} from "../../../data/services/answer-types/answer-types-service";
import BaseAnswerTypeDto from "../../dtos/answer-types/base-answer-type-dto";
import { EditAnswerTypeDto } from "../../dtos/answer-types/edit-answer-type-dto";
import { createEditAnswerTypeRequest } from "../../requests/answer-types/edit-answer-type-request";
import { Response } from "../../responses/common/response-response";

export const useGetAnswerTypeDetails = (
    answerTypeId: number
): UseQueryResult<BaseAnswerTypeDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getAnswerTypeDetails"],
        () => getAnswerTypeDetails(url.baseUrl, answerTypeId),
        {
            select: BaseAnswerTypeDto.constructFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useEditAnswerType = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    EditAnswerTypeDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: EditAnswerTypeDto) => {
        const { answerTypeId, formData, answerCategoryId, renderModeId, flagModeId, valueTypeId } =
            mutationKey;
        const request = createEditAnswerTypeRequest(
            answerTypeId,
            formData.get("name") as string,
            answerCategoryId,
            renderModeId,
            flagModeId,
            valueTypeId
        );

        return editAnswerType(url.baseUrl, request);
    });
};
