export interface LinkReviewReportSetWithAssuranceReviewDto {
    reviewReportSetId: number;
    assuranceReviewId: number;
    assuranceReviewName: string;
}

export const createLinkReviewReportSetWithAssuranceReviewDto = (
    reviewReportSetId: number,
    assuranceReviewId: number,
    assuranceReviewName: string
): LinkReviewReportSetWithAssuranceReviewDto => ({
    reviewReportSetId: reviewReportSetId,
    assuranceReviewId: assuranceReviewId,
    assuranceReviewName: assuranceReviewName,
});
