import { ControlFirstTenantIdentifier } from "../../../domain/enums/common/TenantIdentifier";
import { Response } from "../../../domain/responses/common/response-response";
import { SecretsResponse } from "../../../domain/responses/secrets/secrets-response";
import client from "../../http-client";

export const getSecrets = async (
    prefixUrl: string,
    identifier: ControlFirstTenantIdentifier
): Promise<Response<SecretsResponse>> => {
    const searchParams = new URLSearchParams();
    searchParams.set("identifier", identifier?.toString() ?? null);

    return await client
        .get("secrets", {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};
