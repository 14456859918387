import { useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { AuthContextType, useAuth } from "../../../core/store/auth-context";
import { useUrl } from "../../../core/store/url-context";
import { AccordionTitles, DataTableColumnTypes } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { getHierarchyTypeDetails } from "../../../data/services/hierarchy/hierarchy-types-service";
import { filterNodeTypes } from "../../../data/services/hierarchy/node-types-service";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { PaginationDto } from "../../dtos/common/pagination-dto";
import {
    createHierarchyTypeDetailsDto,
    HierarchyTypeDetailsDto,
} from "../../dtos/hierarchy/hierarchy-type-details-dto";
import { hasRoleTypeInGroup, NodeTypeRoleGroup } from "../../enums/Roles";
import { createFilterNodeTypesRequest } from "../../requests/hierarchy/filter-node-types-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { NodeTypeResponse } from "../../responses/hierarchy/node-type-response";

export const useGetHierarchyTypeDetails = (
    hierarchyTypeId: number,
    paginationDto: PaginationDto
): [
    UseQueryResult<HierarchyTypeDetailsDto, HTTPError>,
    UseQueryResult<PaginatedTableDto<number>, HTTPError>,
] => {
    const url = useUrl();
    const auth = useAuth();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getHierarchyTypeDetails", hierarchyTypeId],
                queryFn: () => getHierarchyTypeDetails(url.baseUrl, hierarchyTypeId),
                select: createHierarchyTypeDetailsDto,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["filterNodeTypes", hierarchyTypeId, paginationDto],
                queryFn: () =>
                    filterNodeTypes(
                        url.baseUrl,
                        createFilterNodeTypesRequest(hierarchyTypeId, null, paginationDto)
                    ),
                keepPreviousData: true,
                select: (response: Response<PaginationResponse<NodeTypeResponse>>) =>
                    toToDataTableRows(response.data, auth, navigate),
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<HierarchyTypeDetailsDto, HTTPError>,
        UseQueryResult<PaginatedTableDto<number>, HTTPError>,
    ];
};

const toToDataTableRows = (
    response: PaginationResponse<NodeTypeResponse>,
    auth: AuthContextType,
    navigate: NavigateFunction
): PaginatedTableDto<number> => {
    const { pageCount, pageSize, currentPage, recordCount } = response;

    const showModificationActions = (response: NodeTypeResponse): boolean =>
        !response.isSystemRoot && hasRoleTypeInGroup(auth.userRoles, NodeTypeRoleGroup.WriteRoles);

    const rows = response.results.map((x) => ({
        metadata: x.nodeTypeId,
        showEditAction: showModificationActions(x),
        showDeleteAction: showModificationActions(x),
        columns: [
            {
                value: x.name,
                type: DataTableColumnTypes.Link,
                linkItemAction: (nodeTypeId: number) =>
                    navigate(`${getPath(AccordionTitles.VisualStructure)}/${nodeTypeId}`),
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
