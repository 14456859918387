import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
} from "../../../domain/requests/common/base-pagination-request";
import { EditNotificationTriggerRequest } from "../../../domain/requests/notification-triggers/edit-notification-trigger-request";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import { NotificationTriggerResponse } from "../../../domain/responses/notification-triggers/notification-trigger-response";
import client from "../../http-client";

const route = "notificationtriggers";

export const filterNotificationTriggers = async (
    prefixUrl: string,
    request: BasePaginationRequest
): Promise<Response<PaginationResponse<NotificationTriggerResponse>>> => {
    const searchParams = buildBasePaginationSearchParameters(request);

    return await client
        .get(`${route}/filter`, {
            prefixUrl: prefixUrl,
            searchParams: searchParams,
        })
        .json();
};

export const getNotificationTriggerDetails = async (
    prefixUrl: string,
    notificationTriggerId: number
): Promise<Response<NotificationTriggerResponse>> =>
    await client
        .get(`${route}/${notificationTriggerId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const editNotificationTrigger = async (
    prefixUrl: string,
    request: EditNotificationTriggerRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/edit`, {
            prefixUrl: prefixUrl,
            body: JSON.stringify(request),
        })
        .json();
