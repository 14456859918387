import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SingleValue } from "react-select";
import { EnterKey } from "../../../core/constants/KeyboardKeys";
import { ActionItems } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useKeyPress from "../../../core/hooks/keyPress";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    ContentContainer,
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { actionItemDynamicFieldColumns } from "../../../core/utilities/dataTableColumns";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import {
    areQueriesLoading,
    isMutationLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import {
    ActionItemTypeDto,
    allActionItemTypeOption,
} from "../../../domain/dtos/action-items/action-item-type-dto";
import { Response } from "../../../domain/responses/common/response-response";
import {
    useGetActionItemDynamicFieldData,
    useOrderActionItemDynamicFields,
} from "../../../domain/viewmodels/action-items/order-action-item-dynamic-fields-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import DraggableDataTable from "../../organisms/DraggableDataTable";
import { ActionItemDynamicFieldsFilter } from "../../organisms/filters/ActionItemDynamicFieldsFilter";

interface SearchParams {
    actionItemTypeNodeId: number | null;
    actionItemType: ActionItemTypeDto;
}

const createSearchParams = (
    actionItemTypeNodeId: number | null,
    actionItemType: ActionItemTypeDto
): SearchParams => ({
    actionItemTypeNodeId: actionItemTypeNodeId,
    actionItemType: actionItemType,
});

const defaultSearchParams: SearchParams = createSearchParams(null, allActionItemTypeOption);

const OrderActionItemDynamicFieldsContainer: React.FC = () => {
    const [searchParams, setSearchParams] = useState<SearchParams>(defaultSearchParams);
    const [actionItemTypeNodeIdFilter, setActionItemTypeNodeIdFilter] = useState<number | null>(
        null
    );

    const orderedActionItemDynamicFields = useRef<number[]>([]);

    const menu = useMenu();
    const toast = useToast();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });

    const getActionItemDynamicFieldData = useGetActionItemDynamicFieldData(
        actionItemTypeNodeIdFilter
    );
    const orderActionItemDynamicFields = useOrderActionItemDynamicFields();

    const actionItemTypesResponse = getActionItemDynamicFieldData[0];
    const actionItemDynamicFieldResponse = getActionItemDynamicFieldData[1];

    const actionItemTypesResponseData = actionItemTypesResponse.data;
    const actionItemDynamicFieldResponseData = actionItemDynamicFieldResponse.data;

    useLoader(
        areQueriesLoading(getActionItemDynamicFieldData) ||
            isMutationLoading(orderActionItemDynamicFields),
        OrderActionItemDynamicFieldsContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.ActionTracker);
        menu.changeActiveDrawerItem(DrawerTitles.ActionItemDynamicFields);
    }, []);

    const onChangeNodeTypeValue = (option: SingleValue<ActionItemTypeDto>): void => {
        setSearchParams({
            ...searchParams,
            actionItemTypeNodeId: option!.nodeId !== 0 ? option!.nodeId : null,
            actionItemType: option!,
        });
    };

    const search = (): void => {
        setActionItemTypeNodeIdFilter(searchParams.actionItemTypeNodeId);
    };

    const resetFilter = (): void => {
        setSearchParams(defaultSearchParams);
        setActionItemTypeNodeIdFilter(defaultSearchParams.actionItemTypeNodeId);
    };

    const handleOrderActionItemDynamicFields = (): void => {
        const orderedActionItemDynamicFieldIds = orderedActionItemDynamicFields.current;
        if (!orderedActionItemDynamicFieldIds.length) {
            return;
        }

        orderActionItemDynamicFields.mutate(orderedActionItemDynamicFieldIds, {
            onSuccess: async (_: Response<boolean>) => {
                toast.addToast(
                    createSuccessToastProps([t("ActionItemTypeWasSuccessfullyUpdated")])
                );

                queryClient.refetchQueries([
                    "filterActionItemDynamicFields",
                    actionItemTypeNodeIdFilter,
                ]);
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    useKeyPress(EnterKey, search, searchParams);

    return (
        <>
            <PageHeading>{t("ActionItemDynamicFieldOrdering")}</PageHeading>
            <PageSubHeading>{t("ActionItemTypesHeaderHelperText")}</PageSubHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(actionItemTypesResponse) && (
                <ActionItemDynamicFieldsFilter
                    actionItemType={searchParams.actionItemType}
                    actionItemTypes={[allActionItemTypeOption, ...actionItemTypesResponseData!]}
                    changeActionItemType={onChangeNodeTypeValue}
                    search={search}
                    resetFilter={resetFilter}
                />
            )}

            {isQuerySuccessful(actionItemDynamicFieldResponse) && (
                <>
                    <SectionVerticalSpace />
                    <ContentContainer>
                        <DraggableDataTable
                            keyPrefix={ActionItems}
                            columns={actionItemDynamicFieldColumns}
                            rows={actionItemDynamicFieldResponseData!.rows}
                            onOrderChanged={(orderedActionItemDynamicFieldIds: number[]): void => {
                                orderedActionItemDynamicFields.current =
                                    orderedActionItemDynamicFieldIds;
                            }}
                            noResultsMessage={`${t("NoActionItemDynamicFieldsFound")}`}
                        />
                    </ContentContainer>

                    <LargeVerticalSpace />
                    <EndAlignedDiv>
                        <SaveButton type="button" onClick={handleOrderActionItemDynamicFields} />
                        <CancelButton onClick={() => navigate(-1)} />
                    </EndAlignedDiv>
                </>
            )}
        </>
    );
};

export default OrderActionItemDynamicFieldsContainer;
