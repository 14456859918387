export default class FilterActionItemEventsDto {
    dateOnlyRangeStart: Date;
    dateOnlyRangeEnd: Date;

    public constructor(dateOnlyRangeStart: Date, dateOnlyRangeEnd: Date) {
        this.dateOnlyRangeStart = dateOnlyRangeStart;
        this.dateOnlyRangeEnd = dateOnlyRangeEnd;
    }

    // public updateDateRange(dateOnlyRangeStart: Date, dateOnlyRangeEnd: Date): void {
    //     this.dateOnlyRangeStart = dateOnlyRangeStart;
    //     this.dateOnlyRangeEnd = dateOnlyRangeEnd;
    // }
}
