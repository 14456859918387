import { useMutation, UseMutationResult, useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import {
    deleteNodeTypeValue,
    getCanDeleteNodeTypeValue,
    getNodeTypeValueDetails,
} from "../../../data/services/hierarchy/node-type-values-service";
import { filterNodes } from "../../../data/services/hierarchy/nodes-service";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { PaginationDto } from "../../dtos/common/pagination-dto";
import { createValidationResponseDto, ValidationResponseDto } from "../../dtos/common/response-dto";
import {
    createNodeTypeValueDetailsDto,
    NodeTypeValueDetailsDto,
} from "../../dtos/hierarchy/node-type-value-details-dto";
import { createFilterNodesRequest } from "../../requests/hierarchy/filter-node-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { BaseNodeResponse } from "../../responses/hierarchy/base-node-response";

export const useGetNodeTypeValueDetails = (
    nodeTypeValueId: number,
    paginationDto: PaginationDto
): [
    UseQueryResult<NodeTypeValueDetailsDto, HTTPError>,
    UseQueryResult<ValidationResponseDto<boolean>, HTTPError>,
    UseQueryResult<PaginatedTableDto<null>, HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getNodeTypeValueDetails", nodeTypeValueId],
                queryFn: () => getNodeTypeValueDetails(url.baseUrl, nodeTypeValueId),
                select: createNodeTypeValueDetailsDto,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getCanDeleteNodeTypeValue", nodeTypeValueId],
                queryFn: () => getCanDeleteNodeTypeValue(url.baseUrl, nodeTypeValueId),
                select: createValidationResponseDto,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["filterNodes", nodeTypeValueId, paginationDto],
                queryFn: () =>
                    filterNodes(
                        url.baseUrl,
                        createFilterNodesRequest(nodeTypeValueId, paginationDto)
                    ),
                keepPreviousData: true,
                select: toToDataTableRows,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<NodeTypeValueDetailsDto, HTTPError>,
        UseQueryResult<ValidationResponseDto<boolean>, HTTPError>,
        UseQueryResult<PaginatedTableDto<null>, HTTPError>,
    ];
};

export const useDeleteNodeTypeValue = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    number
> => {
    const url = useUrl();

    return useMutation((nodeTypeValueId: number) =>
        deleteNodeTypeValue(url.baseUrl, nodeTypeValueId)
    );
};

const toToDataTableRows = (
    response: Response<PaginationResponse<BaseNodeResponse>>
): PaginatedTableDto<null> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results.map((x) => ({
        columns: [
            {
                value: x.shortDescription,
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
