import { MaxIntValue } from "../../../core/constants/numerical";

export enum EscalationLevel {
    None = 0,
    One = 1,
    Two = 2,
    Three = 4,
    All = MaxIntValue,
}

export const EscalationLevelLabels = new Map<EscalationLevel, string>([
    [EscalationLevel.One, "One"],
    [EscalationLevel.Two, "Two"],
    [EscalationLevel.Three, "Three"],
    [EscalationLevel.All, "All"],
]);
