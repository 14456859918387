import { PaginationDto } from "../../dtos/common/pagination-dto";
import { FilterHierarchyImportDto } from "../../dtos/hierarchy/filter-hierarchy-import-dto";
import {
    buildPaginationSearchParameters,
    createPaginationRequestFromDto,
    PaginationRequest,
} from "../common/pagination-request";

export interface FilterHierarchyImportRequest extends PaginationRequest {
    fileName: string | null;
    dateTimeFrom: Date | null;
    dateTimeTo: Date | null;
    createdBy: string | null;
}

export const createFilterHierarchyImportRequestFromDto = (
    filterDto: FilterHierarchyImportDto,
    paginationDto: PaginationDto
): FilterHierarchyImportRequest => ({
    fileName: filterDto.fileName,
    dateTimeFrom: filterDto.dateTimeFrom,
    dateTimeTo: filterDto.dateTimeTo,
    createdBy: filterDto.createdBy,
    ...createPaginationRequestFromDto(paginationDto),
});

export const buildFilterHierarchyImportSearchParameters = (
    request: FilterHierarchyImportRequest
): URLSearchParams => {
    const searchParams = buildPaginationSearchParameters(request);

    if (request.fileName) {
        searchParams.set("fileName", request.fileName);
    }

    if (request.dateTimeFrom) {
        searchParams.set("dateTimeFrom", request.dateTimeFrom.toLocaleDateString());
    }

    if (request.dateTimeTo) {
        searchParams.set("dateTimeTo", request.dateTimeTo.toLocaleDateString());
    }

    if (request.createdBy) {
        searchParams.set("createdBy", request.createdBy);
    }

    return searchParams;
};
