export interface AssociateDissociateReferentialLinkTypeToQuestionSetRequest {
    questionSetId: number;
    referentialLinkTypeId: number;
}

export const createAssociateDissociateReferentialLinkTypeToQuestionSetRequest = (
    questionSetId: number,
    referentialLinkTypeId: number
): AssociateDissociateReferentialLinkTypeToQuestionSetRequest => ({
    questionSetId: questionSetId,
    referentialLinkTypeId: referentialLinkTypeId,
});
