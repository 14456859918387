export class CreateChecklistDto {
    name: string;
    description: string;
    isTraining: boolean;

    public constructor(name: string, description: string, isTraining: boolean) {
        this.name = name;
        this.description = description;
        this.isTraining = isTraining;
    }
}
