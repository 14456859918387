import { Response } from "../../responses/common/response-response";
import { NodeSubTreeUpwardResponse } from "../../responses/hierarchy/node-sub-tree-upward-response";

export interface NodeSubTreeUpwardDto {
    subRootNodeId: number;
    nodeId: number;
    childNodeId: number | null;
    hierarchyLevel: number;
    pathIds: string;
    path: string;
}

export const toNodeSubTreeUpwardDto = (
    nodeSubTreeUpwardResponse: NodeSubTreeUpwardResponse
): NodeSubTreeUpwardDto => ({
    subRootNodeId: nodeSubTreeUpwardResponse.subRootNodeId,
    nodeId: nodeSubTreeUpwardResponse.nodeId,
    childNodeId: nodeSubTreeUpwardResponse.childNodeId,
    hierarchyLevel: nodeSubTreeUpwardResponse.hierarchyLevel,
    pathIds: nodeSubTreeUpwardResponse.pathIds,
    path: nodeSubTreeUpwardResponse.path,
});

export const toNodeSubTreeUpwardDtos = (
    response: Response<NodeSubTreeUpwardResponse[]>
): NodeSubTreeUpwardDto[] => response.data?.map((x) => toNodeSubTreeUpwardDto(x));
