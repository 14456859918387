import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { BaseUserResponse } from "../../responses/users/base-user-response";
import { UserDetailsResponse } from "../../responses/users/user-details-response";

export interface BaseUserDto {
    userId: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
    fullName: string;
    phoneNumber: string;
}

export const createBaseUserDto = (
    userId: number,
    firstName: string,
    lastName: string,
    emailAddress: string,
    fullName: string,
    phoneNumber: string
): BaseUserDto => ({
    userId: userId,
    firstName: firstName,
    lastName: lastName,
    emailAddress: emailAddress,
    fullName: fullName,
    phoneNumber: phoneNumber,
});

export const createBaseUserDtoFromDetailsResponse = (
    response: Response<UserDetailsResponse>
): BaseUserDto => ({
    userId: response.data.userId,
    firstName: response.data.firstName,
    lastName: response.data.lastName,
    emailAddress: response.data.emailAddress,
    fullName: response.data.fullName,
    phoneNumber: response.data.phoneNumber,
});

export const createBaseUserDtoFromResponse = (response: BaseUserResponse): BaseUserDto => ({
    ...response,
    fullName: `${response.firstName} ${response.lastName}`,
});

export const toBaseUserDtos = (responses: BaseUserResponse[]): BaseUserDto[] =>
    responses.map((x) => createBaseUserDtoFromResponse(x));

export const toBaseUserDtosFromResponse = (response: Response<BaseUserResponse[]>): BaseUserDto[] =>
    toBaseUserDtos(response.data);

export const createBaseUserDtoFromUserResponse = (response: BaseUserResponse): BaseUserDto =>
    createBaseUserDtoFromResponse(response);

export const toBaseUserDtosFromPaginatedUserResponse = (
    response: Response<PaginationResponse<BaseUserResponse>>
): BaseUserDto[] => response.data.results.map((x) => createBaseUserDtoFromUserResponse(x));
