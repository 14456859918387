import { CreateAnswerTypeRequest } from "../../../domain/requests/answer-types/create-answer-type-request";
import { CreatePredefinedAnswerRequest } from "../../../domain/requests/answer-types/create-predefined-answer-request";
import { CreatePredefinedAnswerTextRequest } from "../../../domain/requests/answer-types/create-predefined-answer-text-request";
import { EditAnswerTypeRequest } from "../../../domain/requests/answer-types/edit-answer-type-request";
import { EditPredefinedAnswerRequest } from "../../../domain/requests/answer-types/edit-predefined-answer-request";
import {
    buildFilterAnswerTypesSearchParameters,
    FilterAnswerTypesRequest,
} from "../../../domain/requests/answer-types/filter-answer-types-request";
import {
    buildFilterPredefinedAnswerTextsSearchParameters,
    FilterPredefinedAnswerTextsRequest,
} from "../../../domain/requests/answer-types/filter-predefined-answer-text-request";
import {
    buildFilterPredefinedAnswersSearchParameters,
    FilterPredefinedAnswersRequest,
} from "../../../domain/requests/answer-types/filter-predefined-answers-request";
import AnswerTypeQuestionCountResponse from "../../../domain/responses/answer-types/answer-type-question-count-response";
import BaseAnswerTypeResponse from "../../../domain/responses/answer-types/base-answer-type-response";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import PredefinedAnswerResponse from "../../../domain/responses/predefined-answers/predefined-answer-response";
import { PredefinedAnswerTextResponse } from "../../../domain/responses/predefined-answers/predefined-answer-text-response";
import client from "../../http-client";

const route = "answertypes";

export const filterAnswerTypes = async (
    prefixUrl: string,
    request: FilterAnswerTypesRequest
): Promise<Response<PaginationResponse<AnswerTypeQuestionCountResponse>>> => {
    const searchParams = buildFilterAnswerTypesSearchParameters(request);

    return await client
        .get(`${route}/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getAnswerTypeDetails = async (
    prefixUrl: string,
    answerTypeId: number
): Promise<Response<BaseAnswerTypeResponse>> =>
    await client
        .get(`${route}/${answerTypeId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const editAnswerType = async (
    prefixUrl: string,
    request: EditAnswerTypeRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/edit`, {
            prefixUrl: prefixUrl,
            body: JSON.stringify(request),
        })
        .json();

export const createAnswerType = async (
    prefixUrl: string,
    request: CreateAnswerTypeRequest
): Promise<Response<number | null>> =>
    await client
        .post(`${route}/create`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const deleteAnswerType = async (
    prefixUrl: string,
    answerTypeId: number
): Promise<Response<boolean>> =>
    await client
        .delete(`${route}/delete/${answerTypeId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const filterPredefinedAnswers = async (
    prefixUrl: string,
    request: FilterPredefinedAnswersRequest
): Promise<Response<PaginationResponse<PredefinedAnswerResponse>>> => {
    const searchParams = buildFilterPredefinedAnswersSearchParameters(request);

    return await client
        .get(`${route}/filter-predefined-answers`, {
            prefixUrl: prefixUrl,
            searchParams: searchParams,
        })
        .json();
};

export const deletePredefinedAnswer = async (
    prefixUrl: string,
    predefinedAnswerId: number
): Promise<Response<boolean>> =>
    await client
        .delete(`${route}/predefined-answer-delete/${predefinedAnswerId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const getPredefinedAnswerDetails = async (
    prefixUrl: string,
    predefinedAnswerId: number
): Promise<Response<PredefinedAnswerResponse>> =>
    await client
        .get(`${route}/predefined-answer/${predefinedAnswerId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const createPredefinedAnswer = async (
    prefixUrl: string,
    request: CreatePredefinedAnswerRequest
): Promise<Response<number | null>> =>
    await client
        .post(`${route}/create-predefined-answer`, {
            prefixUrl: prefixUrl,
            body: JSON.stringify(request),
        })
        .json();

export const filterPredefinedAnswerTexts = async (
    prefixUrl: string,
    request: FilterPredefinedAnswerTextsRequest
): Promise<Response<PaginationResponse<PredefinedAnswerTextResponse>>> => {
    const searchParams = buildFilterPredefinedAnswerTextsSearchParameters(request);

    return await client
        .get(`${route}/filter-predefined-answer-texts`, {
            prefixUrl: prefixUrl,
            searchParams: searchParams,
        })
        .json();
};

export const deletePredefinedAnswerText = async (
    prefixUrl: string,
    predefinedAnswerTextId: number
): Promise<Response<boolean>> =>
    await client
        .delete(`${route}/predefined-answer-text-delete/${predefinedAnswerTextId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const createPredefinedAnswerText = async (
    prefixUrl: string,
    request: CreatePredefinedAnswerTextRequest
): Promise<Response<number | null>> =>
    await client
        .post(`${route}/create-predefined-answer-text`, {
            prefixUrl: prefixUrl,
            body: JSON.stringify(request),
        })
        .json();

export const editPredefinedAnswer = async (
    prefixUrl: string,
    request: EditPredefinedAnswerRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/edit-predefined-answer`, {
            prefixUrl: prefixUrl,
            body: JSON.stringify(request),
        })
        .json();
