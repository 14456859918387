import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isResponse, Response } from "../../domain/responses/common/response-response";
import { translateText } from "../../presentation/helpers/translate";
import { ErrorMessages } from "../constants/translation-namespace";
import {
    createErrorToastProps,
    createUnauthorisedToastProps,
    useToast,
} from "../store/toast-context";

export const useErrorResponseToDisplayHandler = (): ((error: any) => Promise<void>) => {
    const toast = useToast();
    const { t } = useTranslation("translation");

    useEffect(() => {
        return () => {
            toast.dismissAndClear();
        };
    }, []);

    return async <TData>(error: any): Promise<void> => {
        if (error.response?.status === 403) {
            toast.addToast(
                createUnauthorisedToastProps([
                    t("ResourcePermissionError", { keyPrefix: ErrorMessages }),
                ])
            );

            return;
        }

        if (error.name === "TimeoutError") {
            toast.addToast(
                createErrorToastProps(
                    ["A timeout has occurred. Please try again, or refresh the page."],
                    true
                )
            );

            return;
        }

        const response: Response<TData> = await error?.response?.json();
        if (response && !isResponse(response)) {
            toast.addToast(createErrorToastProps(["Something went wrong"], true));

            return;
        }

        let errors: string[] = [];
        response?.errors?.forEach((e) => {
            errors.push(translateText(t, e, ErrorMessages));
        });

        toast.addToast(createErrorToastProps(errors));
    };
};
