import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { isMutationLoading } from "../../../core/utilities/responseStateHelper";
import { useUndeleteUser } from "../../../domain/viewmodels/users/delete-user-viewmodel";
import { SbButton } from "../../atoms/SbButton";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import { ActionConfirmationFragment } from "../../organisms/ActionConfirmationFragment";
import { ConfirmationDetailsProps } from "./UserDetailsContainer";

const UndeleteUserContainer: React.FC = () => {
    const menu = useMenu();
    const location = useLocation();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const userId = Number(useParams().userId);
    const { username } = location.state as ConfirmationDetailsProps; // TODO: Fetching Checklist data on this page vs passing it through from the previous page

    const undeleteUser = useUndeleteUser();

    useLoader(isMutationLoading(undeleteUser), UndeleteUserContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.UserManagement, AccordionTitles.Users);
    }, []);

    const handleUndeleteUser = (): void => {
        undeleteUser.mutate(userId, {
            onSuccess: async () => navigate(-1),
            onError: errorResponseToDisplayHandler,
        });
    };

    const buildUndeleteButton = (): ReactElement<typeof Button> => (
        <SbButton
            variant="primary"
            type="button"
            label="Undelete"
            icon={FaCheck}
            onClick={handleUndeleteUser}
        />
    );

    const buildConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow("Username", username),
    ];

    return (
        <ActionConfirmationFragment
            pageHeading={"User Undelete"}
            panelTitle={"Are you sure you want to undelete this user?"}
            rows={buildConfirmationRows()}
            primaryButton={buildUndeleteButton()}
        />
    );
};

export default UndeleteUserContainer;
