import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { deleteReferentialLinkType } from "../../../data/services/referential-link-types/referential-link-type-service";
import { Response } from "../../responses/common/response-response";

export const useDeleteReferentialLinkType = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    number
> => {
    const url = useUrl();

    return useMutation((referentialLinkTypeId: number) =>
        deleteReferentialLinkType(url.baseUrl, referentialLinkTypeId)
    );
};
