import {
    AnswerThresholdIndicatorEnum,
    AnswerThresholdOperatorEnum,
} from "../../../core/utilities/enums";
import QuestionImportance from "../../enums/questions/question-importance";

export interface EditQuestionRequest {
    questionId: number;
    questionTypeId: number;
    answerTypeId: number;
    questionCategoryId: number;
    questionImportance: QuestionImportance;
    isAggregate: boolean;
    aggregationTypeId: number;
    questionText: string;
    answerThresholdValues: EditAnswerThresholdValueRequest[];
    mutateQuestionWeightingRequests: EditQuestionWeightingsRequest[];
}

export interface EditAnswerThresholdValueRequest {
    answerThresholdValueId: number | null;
    intValue?: number;
    dateValue?: Date;
    decimalValue?: number;
    answerThresholdIndicator: AnswerThresholdIndicatorEnum;
    answerThresholdOperator: AnswerThresholdOperatorEnum;
}

export class EditQuestionWeightingsRequest {
    predefinedAnswerId: number;
    value?: number;

    constructor(predefinedAnswerId: number, value?: number) {
        this.predefinedAnswerId = predefinedAnswerId;
        this.value = value;
    }
}
