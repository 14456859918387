import { AssociateNonWorkingDaySetNodeDto } from "../../dtos/non-working-days/associate-non-working-day-set-node-dto";

export class AssociateNonWorkingDaySetNodeRequest {
    nodeId: number;
    nonWorkingDaySetId: number;

    public constructor(dto: AssociateNonWorkingDaySetNodeDto) {
        this.nodeId = dto.nodeId;
        this.nonWorkingDaySetId = dto.nonWorkingDaySetId;
    }
}
