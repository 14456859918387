export default class CreateQuestionSetDto {
    name: string;
    description: string;
    process: string;
    arMonitoring: boolean;

    public constructor(name: string, description: string, process: string, arMonitoring: boolean) {
        this.name = name;
        this.description = description;
        this.process = process;
        this.arMonitoring = arMonitoring;
    }
}
