import RaciRelationship from "../../enums/action-items/RaciRelationship";
import { ActionItemSummaryResponse } from "../../responses/action-items/action-item-summary-response";
import { Response } from "../../responses/common/response-response";

export class ActionItemSummaryDto {
    onHoldCount: number;
    overdueCount: number;
    dueCount: number;
    openCount: number;
    pendingCount: number;
    actionItemCount: number;
    raciRelationship: RaciRelationship;

    public constructor(response: ActionItemSummaryResponse) {
        this.onHoldCount = response.onHoldCount;
        this.overdueCount = response.overdueCount;
        this.dueCount = response.dueCount;
        this.openCount = response.openCount;
        this.pendingCount = response.pendingCount;
        this.actionItemCount = response.actionItemCount;
        this.raciRelationship = response.raciRelationship;
    }

    public static constructFromActionItemSummaryResponses = (
        response: Response<ActionItemSummaryResponse[]>
    ): ActionItemSummaryDto[] => response.data?.map((x) => new ActionItemSummaryDto(x));
}
