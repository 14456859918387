import { Col, Row } from "react-bootstrap";
import styled, { css } from "styled-components";
import { useAuth } from "../../core/store/auth-context";
import { DetailsLabel, DetailsValue } from "../../core/theme/global-styles";
import { ActionItemInstanceCommentDetailsDto } from "../../domain/dtos/action-items/action-item-instance-comment-details-dto";
import { ActionInstanceCommentStatus } from "../../domain/enums/action-items/ActionInstanceCommentStatus";
import SbActionItemCommentStatusIconLabel, {
    StyledActionItemCommentStatusProps,
} from "../atoms/SbActionItemCommentStatusIconLabel";

const StyledCommentRow = styled(Row)`
    padding: ${(props) => props.theme.padding.sm};
`;

const StyledLeftCommentCol = styled(Col)`
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    padding-right: ${(props) => props.theme.padding.lg};
`;

const StyledCenterCommentRightCol = styled(Col)`
    display: flex;
    justify-content: flex-end;
`;

const StyledRightCommentCol = styled(Col)`
    display: flex;
    flex-direction: column;
    padding-left: ${(props) => props.theme.padding.lg};
`;

const MySpeechBubble = styled.div<StyledActionItemCommentStatusProps>`
    display: inline-block;
    padding: ${(props) => props.theme.padding.md};
    position: relative;
    border-radius: ${(props) => props.theme.padding.md};
    box-shadow: 0 3px 6px ${(props) => props.theme.palette.grey};
    border: 2px solid ${(props) => props.theme.palette.actionInstanceStatus.cancelled};

    :before {
        border-right: 10px solid ${(props) => props.theme.palette.actionInstanceStatus.cancelled};
        border-top: 10px solid ${(props) => props.theme.palette.actionInstanceStatus.cancelled};
    }

    ${({ $actionInstanceCommentStatus }) =>
        ($actionInstanceCommentStatus === ActionInstanceCommentStatus.ExtendedComment ||
            $actionInstanceCommentStatus === ActionInstanceCommentStatus.CancelledComment) &&
        css`
            border: 2px solid ${(props) => props.theme.palette.actionInstanceStatus.overdue};

            :before {
                border-right: 10px solid
                    ${(props) => props.theme.palette.actionInstanceStatus.overdue};
                border-top: 10px solid
                    ${(props) => props.theme.palette.actionInstanceStatus.overdue};
            }
        `}
    ${({ $actionInstanceCommentStatus }) =>
        $actionInstanceCommentStatus === ActionInstanceCommentStatus.OnHoldComment &&
        css`
            border: 2px solid ${(props) => props.theme.palette.actionInstanceStatus.onhold};

            :before {
                border-right: 10px solid
                    ${(props) => props.theme.palette.actionInstanceStatus.onhold};
                border-top: 10px solid ${(props) => props.theme.palette.actionInstanceStatus.onhold};
            }
        `}
    ${({ $actionInstanceCommentStatus }) =>
        ($actionInstanceCommentStatus === ActionInstanceCommentStatus.CompletedOverdueComment ||
            $actionInstanceCommentStatus === ActionInstanceCommentStatus.CompletedComment) &&
        css`
            border: 2px solid ${(props) => props.theme.palette.actionInstanceStatus.completed};

            :before {
                border-right: 10px solid
                    ${(props) => props.theme.palette.actionInstanceStatus.completed};
                border-top: 10px solid
                    ${(props) => props.theme.palette.actionInstanceStatus.completed};
            }
        `}

    :before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 10px solid transparent;
        border-bottom: 10px solid transparent;
        left: -21px;
        top: 6px;
    }
`;

const SpeechBubble = styled.div<StyledActionItemCommentStatusProps>`
    display: flex;
    flex-direction: column;
    padding: ${(props) => props.theme.padding.md};
    position: relative;
    border-radius: ${(props) => props.theme.padding.md};
    box-shadow: 0 3px 6px ${(props) => props.theme.palette.grey};
    justify-content: flex-start;
    border: 2px solid ${(props) => props.theme.palette.actionInstanceStatus.cancelled};

    :before {
        border-left: 10px solid ${(props) => props.theme.palette.actionInstanceStatus.cancelled};
        border-top: 10px solid ${(props) => props.theme.palette.actionInstanceStatus.cancelled};
    }

    ${({ $actionInstanceCommentStatus }) =>
        ($actionInstanceCommentStatus === ActionInstanceCommentStatus.ExtendedComment ||
            $actionInstanceCommentStatus === ActionInstanceCommentStatus.CancelledComment) &&
        css`
            border: 2px solid ${(props) => props.theme.palette.actionInstanceStatus.overdue};

            :before {
                border-left: 10px solid
                    ${(props) => props.theme.palette.actionInstanceStatus.overdue};
                border-top: 10px solid
                    ${(props) => props.theme.palette.actionInstanceStatus.overdue};
            }
        `}
    ${({ $actionInstanceCommentStatus }) =>
        $actionInstanceCommentStatus === ActionInstanceCommentStatus.OnHoldComment &&
        css`
            border: 2px solid ${(props) => props.theme.palette.actionInstanceStatus.onhold};

            :before {
                border-left: 10px solid
                    ${(props) => props.theme.palette.actionInstanceStatus.onhold};
                border-top: 10px solid ${(props) => props.theme.palette.actionInstanceStatus.onhold};
            }
        `}
    ${({ $actionInstanceCommentStatus }) =>
        ($actionInstanceCommentStatus === ActionInstanceCommentStatus.CompletedOverdueComment ||
            $actionInstanceCommentStatus === ActionInstanceCommentStatus.CompletedComment) &&
        css`
            border: 2px solid ${(props) => props.theme.palette.actionInstanceStatus.completed};

            :before {
                border-left: 10px solid
                    ${(props) => props.theme.palette.actionInstanceStatus.completed};
                border-top: 10px solid
                    ${(props) => props.theme.palette.actionInstanceStatus.completed};
            }
        `}

    :before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-right: 10px solid transparent;
        border-bottom: 10px solid transparent;
        right: -21px;
        top: 6px;
    }
`;

export const DetailsValuePrimary = styled.h6`
    color: ${(props) => props.theme.palette.primary};
    overflow-wrap: break-word;
`;

const ActionItemComment: React.FC<{ comment: ActionItemInstanceCommentDetailsDto }> = ({
    comment,
}) => {
    const auth = useAuth();

    const isMyComment = auth.userId! === comment.userDto.userId;

    return (
        <StyledCommentRow>
            {isMyComment ? (
                <StyledLeftCommentCol md="2">
                    <DetailsLabel>{`${comment.userDto.firstName} ${comment.userDto.lastName}`}</DetailsLabel>
                    <DetailsValue>{comment.commentDateTimeLocal}</DetailsValue>
                </StyledLeftCommentCol>
            ) : (
                <StyledLeftCommentCol md="2" />
            )}

            {isMyComment ? (
                <Col md="8">
                    <MySpeechBubble
                        $actionInstanceCommentStatus={comment.actionInstanceCommentStatus}
                    >
                        <SbActionItemCommentStatusIconLabel
                            actionInstanceCommentStatus={comment.actionInstanceCommentStatus}
                        />
                        {comment.actionInstanceCommentStatus ===
                        ActionInstanceCommentStatus.UserComment ? (
                            <DetailsValuePrimary>{comment.commentText}</DetailsValuePrimary>
                        ) : (
                            <DetailsValue>{comment.commentText}</DetailsValue>
                        )}
                    </MySpeechBubble>
                </Col>
            ) : (
                <StyledCenterCommentRightCol md="8">
                    <SpeechBubble
                        $actionInstanceCommentStatus={comment.actionInstanceCommentStatus}
                    >
                        <SbActionItemCommentStatusIconLabel
                            actionInstanceCommentStatus={comment.actionInstanceCommentStatus}
                        />
                        {comment.actionInstanceCommentStatus ===
                        ActionInstanceCommentStatus.UserComment ? (
                            <DetailsValuePrimary>{comment.commentText}</DetailsValuePrimary>
                        ) : (
                            <DetailsValue>{comment.commentText}</DetailsValue>
                        )}
                    </SpeechBubble>
                </StyledCenterCommentRightCol>
            )}

            {isMyComment ? (
                <StyledRightCommentCol md="2" />
            ) : (
                <StyledRightCommentCol md="2">
                    <DetailsLabel>{`${comment.userDto.firstName} ${comment.userDto.lastName}`}</DetailsLabel>
                    <DetailsValue>{comment.commentDateTimeLocal}</DetailsValue>
                </StyledRightCommentCol>
            )}
        </StyledCommentRow>
    );
};

export default ActionItemComment;
