import RiskLevel from "../../enums/associated-risks/risk-level";
import { QuestionSetInstanceReviewAssociatedRiskResponse } from "../../responses/review-reports/question-set-instance-review-associated-risk-response";

export class QuestionSetInstanceReviewAssociatedRiskDto {
    questionSetInstanceReviewId: number;
    associatedRiskId: number;
    riskLevel: RiskLevel;
    name: string;
    tooltip: string;
    parentAssociatedRiskId: number | null;

    public constructor(response: QuestionSetInstanceReviewAssociatedRiskResponse) {
        this.questionSetInstanceReviewId = response.questionSetInstanceReviewId;
        this.associatedRiskId = response.associatedRiskId;
        this.riskLevel = response.riskLevel;
        this.name = response.name;
        this.tooltip = response.tooltip;
        this.parentAssociatedRiskId = response.associatedRiskId;
    }

    public static toQuestionSetInstanceReviewAssociatedDtos = (
        responses: QuestionSetInstanceReviewAssociatedRiskResponse[]
    ): QuestionSetInstanceReviewAssociatedRiskDto[] =>
        responses.map((x) => new QuestionSetInstanceReviewAssociatedRiskDto(x));

    public static createDefaultQuestionSetInstanceReviewAssociatedDto = (
        riskLevel: RiskLevel
    ): QuestionSetInstanceReviewAssociatedRiskDto => ({
        questionSetInstanceReviewId: -1,
        associatedRiskId: -1,
        riskLevel: riskLevel,
        name: "",
        tooltip: "",
        parentAssociatedRiskId: null,
    });
}
