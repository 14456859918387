import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
    createBasePaginationRequest,
    createBasePaginationRequestFromDto,
} from "./base-pagination-request";

export interface FilterByNodeRequest extends BasePaginationRequest {
    nodeId: number;
}

export const createFilterByNodeRequest = (
    nodeId: number,
    pageNumber: number,
    pageSize: number
): FilterByNodeRequest => ({
    nodeId: nodeId,
    ...createBasePaginationRequest(pageNumber, pageSize),
});

export const createFilterByNodeRequestFromPaginationDto = (
    nodeId: number,
    paginationDto: BasePaginationDto
): FilterByNodeRequest => ({
    nodeId: nodeId,
    ...createBasePaginationRequestFromDto(paginationDto),
});

export const buildFilterByNodeSearchParameters = (
    request: FilterByNodeRequest
): URLSearchParams => {
    const searchParams = buildBasePaginationSearchParameters(request);

    searchParams.set("nodeId", request.nodeId.toString());

    return searchParams;
};
