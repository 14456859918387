import { LinkedNodeTypeResponse } from "../../responses/hierarchy/linked-node-type-response";
import { createNodeTypeDto, NodeTypeDto } from "./node-type-dto";

export interface LinkedNodeTypeDto {
    primaryNodeTypeId: number;
    linkedNodeTypeId: number;
    isRequired: boolean;
    nodeType: NodeTypeDto;
}

export const createLinkedNodeTypeDto = (response: LinkedNodeTypeResponse): LinkedNodeTypeDto => ({
    ...response,
    nodeType: createNodeTypeDto(response.nodeType),
});
