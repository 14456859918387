import { DayOrdinal } from "../../enums/questions/question-sets/DayOrdinalNumber";
import { DaysOfWeek } from "../../enums/questions/question-sets/DaysOfTheWeek";
import { FrequencyType } from "../../enums/questions/question-sets/FrequencyType";
import { MonthOfTheYear } from "../../enums/questions/question-sets/MonthOfTheYear";
import { NonWorkingDayRolloverType } from "../../enums/questions/question-sets/NonWorkingDayRolloverType";
import { QuestionSetScheduleBaseResponse } from "../../responses/question-sets/question-set-schedule-base-response";

export class QuestionSetScheduleBaseDto {
    frequencyType: FrequencyType;
    baseStartTimeOfDay: string;
    viewableMinutesBeforeBaseStart: number;
    viewableTimeBeforeBaseStart: number;
    viewableIntervalBeforeBaseStart: number;
    daysOfWeek: DaysOfWeek;
    dayOfMonth: number;
    dayOrdinal: DayOrdinal | null;
    monthOfYear: MonthOfTheYear;
    year: number;
    interval: number;
    maxOccurrences: number;
    nonWorkingDayRolloverType: NonWorkingDayRolloverType;
    scheduleDescription: string;
    isLockoutEnabled: boolean;
    lockoutMinutesAfterDue: number;
    viewableReminderRequired: boolean;

    public constructor(
        frequencyType: FrequencyType,
        baseStartTimeOfDay: string,
        viewableMinutesBeforeBaseStart: number,
        viewableTimeBeforeBaseStart: number,
        viewableIntervalBeforeBaseStart: number,
        daysOfWeek: DaysOfWeek,
        dayOfMonth: number,
        dayOrdinal: DayOrdinal | null,
        monthOfYear: MonthOfTheYear,
        year: number,
        interval: number,
        maxOccurrences: number,
        nonWorkingDayRolloverType: NonWorkingDayRolloverType,
        scheduleDescription: string,
        isLockoutEnabled: boolean,
        lockoutMinutesAfterDue: number,
        viewableReminderRequired: boolean
    ) {
        this.frequencyType = frequencyType;
        this.baseStartTimeOfDay = baseStartTimeOfDay;
        this.viewableMinutesBeforeBaseStart = viewableMinutesBeforeBaseStart;
        this.viewableTimeBeforeBaseStart = viewableTimeBeforeBaseStart;
        this.viewableIntervalBeforeBaseStart = viewableIntervalBeforeBaseStart;
        this.daysOfWeek = daysOfWeek;
        this.dayOfMonth = dayOfMonth;
        this.dayOrdinal = dayOrdinal;
        this.monthOfYear = monthOfYear;
        this.year = year;
        this.interval = interval;
        this.maxOccurrences = maxOccurrences;
        this.nonWorkingDayRolloverType = nonWorkingDayRolloverType;
        this.scheduleDescription = scheduleDescription;
        this.isLockoutEnabled = isLockoutEnabled;
        this.lockoutMinutesAfterDue = lockoutMinutesAfterDue;
        this.viewableReminderRequired = viewableReminderRequired;
    }

    public static constructFromResponse = (
        response: QuestionSetScheduleBaseResponse
    ): QuestionSetScheduleBaseDto =>
        new QuestionSetScheduleBaseDto(
            response.frequencyType,
            response.baseStartTimeOfDay,
            response.viewableMinutesBeforeBaseStart,
            response.viewableTimeBeforeBaseStart,
            response.viewableIntervalBeforeBaseStart,
            response.daysOfWeek,
            response.dayOfMonth,
            response.dayOrdinal,
            response.monthOfYear,
            response.year,
            response.interval,
            response.maxOccurrences,
            response.nonWorkingDayRolloverType,
            response.scheduleDescription,
            response.isLockoutEnabled,
            response.lockoutMinutesAfterDue,
            response.viewableReminderRequired
        );
}
