import { useEffect } from "react";

/**
 * useKeyPress
 * @param {string} key - the name of the key to respond to, compared against event.key
 * @param {function} action - the action to perform on key press
 * @param {any} dependencies - the dependencies needed
 */
const useKeyPress = (key: string, action: Function, dependencies: any): void => {
    useEffect(() => {
        const onKeyup = (e: any): void => e.key === key && action();

        window.addEventListener("keyup", onKeyup);

        return (): void => window.removeEventListener("keyup", onKeyup);
    }, [dependencies]);
};

export default useKeyPress;
