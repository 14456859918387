import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    filterActiveImages,
    getAllActiveImageNames,
} from "../../../data/services/image-library/image-library-service";
import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import PaginatedDto from "../../dtos/common/paginated-dto";
import { createImageLibraryDto, ImageLibraryDto } from "../../dtos/image-library/image-library-dto";

import { filterActiveImagesByWizardId } from "../../../data/services/wizards/wizard-cards-service";
import { createBasePaginationRequestFromDto } from "../../requests/common/base-pagination-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { ImageLibraryViewResponse } from "../../responses/image-library/image-library-view-response";

export const useFilterActiveImages = (
    paginationDto: BasePaginationDto
): UseQueryResult<PaginatedDto<ImageLibraryDto>, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterActiveImages", paginationDto],
        () => filterActiveImages(url.baseUrl, createBasePaginationRequestFromDto(paginationDto)),
        {
            select: transformPaginatedResponseToImageLibraryDto,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useFilterActiveImagesByWizardId = (
    wizardId: number
): UseQueryResult<ImageLibraryDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterActiveImagesByWizardId", wizardId],
        () => filterActiveImagesByWizardId(url.baseUrl, wizardId),
        {
            select: transformListResponseToImageLibraryDto,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useGetAllActiveImageNames = (): UseQueryResult<ImageLibraryDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(["getAllActiveImageNames"], () => getAllActiveImageNames(url.baseUrl), {
        select: transformListResponseToImageLibraryDto,
        onError: errorResponseToDisplayHandler,
    });
};

const transformPaginatedResponseToImageLibraryDto = (
    data: Response<PaginationResponse<ImageLibraryViewResponse>>
): PaginatedDto<ImageLibraryDto> => {
    const responseData = data.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const results = responseData.results.map((image) => createImageLibraryDto(image));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        results: results,
    };
};

const transformListResponseToImageLibraryDto = (
    data: Response<ImageLibraryViewResponse[]>
): ImageLibraryDto[] => {
    const responseData = data.data;

    return responseData.map((image) => createImageLibraryDto(image));
};
