import { useMutation, UseMutationResult, useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import {
    deletePredefinedAnswer,
    filterPredefinedAnswers,
    getAnswerTypeDetails,
} from "../../../data/services/answer-types/answer-types-service";
import BaseAnswerTypeDto from "../../dtos/answer-types/base-answer-type-dto";

import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { PaginationDto } from "../../dtos/common/pagination-dto";
import AnswerFlagMode from "../../enums/answer-types/answer-flag-mode";
import { createFilterPredefinedAnswersRequest } from "../../requests/answer-types/filter-predefined-answers-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import PredefinedAnswerResponse from "../../responses/predefined-answers/predefined-answer-response";

export const useGetAnswerTypeDetails = (
    answerTypeId: number,
    predefinedAnswerPaginationDto: PaginationDto
): [
    UseQueryResult<BaseAnswerTypeDto, HTTPError>,
    UseQueryResult<PaginatedTableDto<number>, HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getAnswerTypeDetails"],
                queryFn: () => getAnswerTypeDetails(url.baseUrl, answerTypeId),
                select: BaseAnswerTypeDto.constructFromResponse,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["filterPredefinedAnswers", answerTypeId, predefinedAnswerPaginationDto],
                queryFn: () =>
                    filterPredefinedAnswers(
                        url.baseUrl,
                        createFilterPredefinedAnswersRequest(
                            predefinedAnswerPaginationDto,
                            answerTypeId
                        )
                    ),
                select: toDataTableRows,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<BaseAnswerTypeDto, HTTPError>,
        UseQueryResult<PaginatedTableDto<number>, HTTPError>,
    ];
};

const toDataTableRows = (
    response: Response<PaginationResponse<PredefinedAnswerResponse>>
): PaginatedTableDto<number> => {
    const responseData = response.data;

    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((x) => ({
        metadata: x.predefinedAnswerId,
        columns: [
            {
                value: x.answerFlagMode ? AnswerFlagMode[x.answerFlagMode!] : "None",
                type: DataTableColumnTypes.Text,
            },
            {
                value: x.predefinedAnswerTextResponse.textValue,
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};

export const useDeletePredefinedAnswer = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    number
> => {
    const url = useUrl();

    return useMutation((predefinedAnswerId: number) =>
        deletePredefinedAnswer(url.baseUrl, predefinedAnswerId)
    );
};
