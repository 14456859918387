import { RequireAuth } from "../store/auth-context";
import { AccordionTitles, DrawerTitles } from "../utilities/enums";
import { getPath } from "../utilities/getPath";

import ActionItemDynamicFieldDetailsContainer from "../../presentation/templates/action-items/ActionItemDynamicFieldDetailsContainer";
import ActionItemDynamicFieldsContainer from "../../presentation/templates/action-items/ActionItemDynamicFieldsContainer";
import AssociateActionItemNodeContainer from "../../presentation/templates/action-items/AssociateActionItemNodeContainer";
import CreateActionItemDynamicFieldContainer from "../../presentation/templates/action-items/CreateActionItemDynamicFieldContainer";
import CreateActionItemDynamicFieldTextContainer from "../../presentation/templates/action-items/CreateActionItemDynamicFieldTextContainer";
import DeleteActionItemDynamicFieldContainer from "../../presentation/templates/action-items/DeleteActionItemDynamicFieldContainer";
import DissociateActionItemRaciParticipantContainer from "../../presentation/templates/action-items/DissociateActionItemRaciParticipantContainer";
import EditActionItemDynamicFieldContainer from "../../presentation/templates/action-items/EditActionItemDynamicFieldContainer";
import FinalisedActionItemsContainer from "../../presentation/templates/action-items/FinalisedActionItemsContainer";
import MyActionCalendarContainer from "../../presentation/templates/action-items/MyActionItemsCalendarContainer";
import MyActionItemsContainer from "../../presentation/templates/action-items/MyActionItemsContainer";
import MyActionSummaryContainer from "../../presentation/templates/action-items/MyActionSummaryContainer";
import OrderActionItemDynamicFieldsContainer from "../../presentation/templates/action-items/OrderActionItemDynamicFieldsContainer";
import ActionItemDetailsContainer from "../../presentation/templates/actions/ActionItemDetailsContainer";
import ActionItemsContainer from "../../presentation/templates/actions/ActionItemsContainer";
import ActionPlanDetailsContainer from "../../presentation/templates/actions/ActionPlanDetailsContainer";
import ActionPlansContainer from "../../presentation/templates/actions/ActionPlansContainer";
import AssociateActionItemsContainer from "../../presentation/templates/actions/AssociateActionItemsContainer";
import CancelActionItemContainer from "../../presentation/templates/actions/CancelActionItemContainer";
import CloneActionItemContainer from "../../presentation/templates/actions/CloneActionItemContainer";
import CreateActionItemContainer from "../../presentation/templates/actions/CreateActionItemContainer";
import CreateActionPlanContainer from "../../presentation/templates/actions/CreateActionPlanContainer";
import DeleteActionPlanContainer from "../../presentation/templates/actions/DeleteActionPlanContainer";
import EditActionItemContainer from "../../presentation/templates/actions/EditActionItemContainer";
import EditActionPlanContainer from "../../presentation/templates/actions/EditActionPlanContainer";
import AnswerCaptureContainer from "../../presentation/templates/answer-capture/AnswerCaptureContainer";
import AnswerDetailsContainer from "../../presentation/templates/answer-summary/AnswerDetailsContainer";
import AnswerSummaryContainer from "../../presentation/templates/answer-summary/AnswerSummaryContainer";
import TeamAnswerSummaryContainer from "../../presentation/templates/answer-summary/TeamAnswerSummaryContainer";
import AnswerTypesContainer from "../../presentation/templates/answer-types/AnswerTypesContainer";
import CreateAnswerTypeContainer from "../../presentation/templates/answer-types/CreateAnswerTypeContainer";
import CreatePredefinedAnswerContainer from "../../presentation/templates/answer-types/CreatePredefinedAnswerContainer";
import CreatePredefinedAnswerTextContainer from "../../presentation/templates/answer-types/CreatePredefinedAnswerTextContainer";
import DeleteAnswerTypeContainer from "../../presentation/templates/answer-types/DeleteAnswerTypeContainer";
import EditAnswerTypeContainer from "../../presentation/templates/answer-types/EditAnswerTypeContainer";
import EditPredefinedAnswerContainer from "../../presentation/templates/answer-types/EditPredefinedAnswerContainer";
import ViewAnswerTypeContainer from "../../presentation/templates/answer-types/ViewAnswerTypeContainer";
import ViewPredefinedAnswerContainer from "../../presentation/templates/answer-types/ViewPredefinedAnswerContainer";
import AuditsChangelogContainer from "../../presentation/templates/audits/AuditsChangelogContainer";
import AuditsSecurityLoginContainer from "../../presentation/templates/audits/AuditsSecurityLoginContainer";
import ActivateSuspendChecklistAssignmentContainer from "../../presentation/templates/checklist-assignment/ActivateSuspendChecklistAssignmentContainer";
import EditChecklistAssignmentContainer from "../../presentation/templates/checklist-assignment/EditChecklistAssignmentContainer";
import EditScheduleContainer from "../../presentation/templates/checklist-assignment/EditScheduleContainer";
import PromoteDemoteChecklistAssignmentContainer from "../../presentation/templates/checklist-assignment/PromoteDemoteChecklistAssignmentContainer";
import ViewChecklistAssignmentContainer from "../../presentation/templates/checklist-assignment/ViewChecklistAssignmentContainer";
import AssignNodeTypeValueContainer from "../../presentation/templates/checklists/AssignNodeTypeValueContainer";
import AssociateChecklistTaxonomyNodeContainer from "../../presentation/templates/checklists/AssociateChecklistTaxonomyNodeContainer";
import AssociateNodeContainer from "../../presentation/templates/checklists/AssociateNodeContainer";
import AssociateQuestionSetContainer from "../../presentation/templates/checklists/AssociateQuestionSetContainer";
import AssociateReferentialLinkContainer from "../../presentation/templates/checklists/AssociateReferentialLinkContainer";
import ChecklistsContainer from "../../presentation/templates/checklists/ChecklistsContainer";
import CreateChecklistContainer from "../../presentation/templates/checklists/CreateChecklistContainer";
import CreateReferentialLinkContainer from "../../presentation/templates/checklists/CreateReferentialLinkContainer";
import DeleteChecklistContainer from "../../presentation/templates/checklists/DeleteChecklistContainer";
import DissociateChecklistTaxonomyNodeContainer from "../../presentation/templates/checklists/DissociateChecklistTaxonomyNodeContainer";
import DissociateNodeContainer from "../../presentation/templates/checklists/DissociateNodeContainer";
import DissociateQuestionSetContainer from "../../presentation/templates/checklists/DissociateQuestionSetContainer";
import EditChecklistContainer from "../../presentation/templates/checklists/EditChecklistContainer";
import PromoteDemoteChecklistContainer from "../../presentation/templates/checklists/PromoteDemoteChecklistContainer";
import ReviewChecklistContainer from "../../presentation/templates/checklists/ReviewChecklistContainer";
import ViewChecklistContainer from "../../presentation/templates/checklists/ViewChecklistContainer";
import DashboardContainer from "../../presentation/templates/DashboardContainer";
import EmailQueueContainer from "../../presentation/templates/email-queue/EmailQueueContainer";
import EmailQueueDetailsContainer from "../../presentation/templates/email-queue/EmailQueueDetailsContainer";
import CreateHierarchyImportContainer from "../../presentation/templates/hierarchy-import/CreateHierarchyImportContainer";
import HierarchyImportContainer from "../../presentation/templates/hierarchy-import/HierarchyImportContainer";
import HierarchyImportDetailsContainer from "../../presentation/templates/hierarchy-import/HierarchyImportDetailsContainer";
import ActivateNodeContainer from "../../presentation/templates/hierarchy/ActivateNodeContainer";
import CreateHierarchyTypeContainer from "../../presentation/templates/hierarchy/CreateHierarchyTypeContainer";
import CreateNodeContainer from "../../presentation/templates/hierarchy/CreateNodeContainer";
import CreateNodeTypeContainer from "../../presentation/templates/hierarchy/CreateNodeTypeContainer";
import DeleteNodeTypeContainer from "../../presentation/templates/hierarchy/DeleteNodeTypeContainer";
import DeleteNodeTypeValueContainer from "../../presentation/templates/hierarchy/DeleteNodeTypeValueContainer";
import DemoteNodeContainer from "../../presentation/templates/hierarchy/DemoteNodeContainer";
import DissociateUserContainer from "../../presentation/templates/hierarchy/DissociateUserContainer";
import EditHierarchyTypeContainer from "../../presentation/templates/hierarchy/EditHierarchyTypeContainer";
import EditNodeContainer from "../../presentation/templates/hierarchy/EditNodeContainer";
import EditNodeTypeContainer from "../../presentation/templates/hierarchy/EditNodeTypeContainer";
import EditNodeTypeValueContainer from "../../presentation/templates/hierarchy/EditNodeTypeValueContainer";
import HierarchyTypeDetailsContainer from "../../presentation/templates/hierarchy/HierarchyTypeDetailsContainer";
import HierarchyTypesContainer from "../../presentation/templates/hierarchy/HierarchyTypesContainer";
import MoveNodeContainer from "../../presentation/templates/hierarchy/MoveNodeContainer";
import NodeDetailsContainer from "../../presentation/templates/hierarchy/NodeDetailsContainer";
import NodeTypeDetailsContainer from "../../presentation/templates/hierarchy/NodeTypeDetailsContainer";
import NodeTypeValueDetailsContainer from "../../presentation/templates/hierarchy/NodeTypeValueDetailsContainer";
import PromoteNodeContainer from "../../presentation/templates/hierarchy/PromoteNodeContainer";
import SearchContainer from "../../presentation/templates/hierarchy/SearchContainer";
import SoftDeleteNodeContainer from "../../presentation/templates/hierarchy/SoftDeleteNodeContainer";
import SuspendNodeContainer from "../../presentation/templates/hierarchy/SuspendNodeContainer";
import VisualStructureContainer from "../../presentation/templates/hierarchy/VisualStructureContainer";
import VisualTreeContainer from "../../presentation/templates/hierarchy/VisualTreeContainer";
import ImageLibraryContainer from "../../presentation/templates/image-library/ImageLibraryContainer";
import ImageLibraryFileUploadContainer from "../../presentation/templates/image-library/ImageLibraryFileUploadContainer";
import ImportContainer from "../../presentation/templates/ImportContainer";
import AssociateNonWorkingDaySetNodeContainer from "../../presentation/templates/non-working-days/AssociateNonWorkingDaySetNodeContainer";
import CreateNonWorkingDayContainer from "../../presentation/templates/non-working-days/CreateNonWorkingDayContainer";
import CreateNonWorkingDaySetContainer from "../../presentation/templates/non-working-days/CreateNonWorkingDaySetContainer";
import DeleteNonWorkingDayContainer from "../../presentation/templates/non-working-days/DeleteNonWorkingDayContainer";
import DeleteNonWorkingDaySetContainer from "../../presentation/templates/non-working-days/DeleteNonWorkingDaySetContainer";
import EditNonWorkingDayContainer from "../../presentation/templates/non-working-days/EditNonWorkingDayContainer";
import EditNonWorkingDaySetContainer from "../../presentation/templates/non-working-days/EditNonWorkingDaySetContainer";
import NonWorkingDaySetsContainer from "../../presentation/templates/non-working-days/NonWorkingDaySetsContainer";
import ViewNonWorkingDaySetContainer from "../../presentation/templates/non-working-days/ViewNonWorkingDaySetContainer";
import EditNotificationTriggerContainer from "../../presentation/templates/notification-triggers/EditNotificationTriggerContainer";
import NotificationTriggerDetailsContainer from "../../presentation/templates/notification-triggers/NotificationTriggerDetailsContainer";
import NotificationTriggersContainer from "../../presentation/templates/notification-triggers/NotificationTriggersContainer";
import CancelPendingAdhocsContainer from "../../presentation/templates/pending-adhocs/CancelPendingAdhocsContainer";
import PendingAdhocsContainer from "../../presentation/templates/pending-adhocs/PendingAdhocsContainer";
import ProfileContainer from "../../presentation/templates/ProfileContainer";
import CreateQuestionCategoryContainer from "../../presentation/templates/question-categories/CreateQuestionCategoryContainer";
import DeleteQuestionCategoryContainer from "../../presentation/templates/question-categories/DeleteQuestionCategoryContainer";
import EditQuestionCategoryContainer from "../../presentation/templates/question-categories/EditQuestionCategoryContainer";
import QuestionCategoriesContainer from "../../presentation/templates/question-categories/QuestionCategoriesContainer";
import ViewQuestionCategoryContainer from "../../presentation/templates/question-categories/ViewQuestionCategoryContainer";
import CreateQuestionSetContainer from "../../presentation/templates/question-sets/CreateQuestionSetContainer";
import DeleteQuestionSetContainer from "../../presentation/templates/question-sets/DeleteQuestionSetContainer";
import DissociateQuestionSetReferentialLinkTypeContainer from "../../presentation/templates/question-sets/DissociateQuestionSetReferentialLinkTypeContainer";
import EditQuestionSetContainer from "../../presentation/templates/question-sets/EditQuestionSetContainer";
import OrderQuestionsContainer from "../../presentation/templates/question-sets/OrderQuestionsContainer";
import QuestionSetsContainer from "../../presentation/templates/question-sets/QuestionSetsContainer";
import ViewQuestionSetContainer from "../../presentation/templates/question-sets/ViewQuestionSetContainer";
import AssociateChildQuestionContainer from "../../presentation/templates/questions/AssociateChildQuestionContainer";
import AssociateQuestionTaxonomyNodeContainer from "../../presentation/templates/questions/AssociateQuestionTaxonomyNodeContainer";
import CreateAlternativeQuestionTextContainer from "../../presentation/templates/questions/CreateAlternativeQuestionTextContainer";
import CreateQuestionContainer from "../../presentation/templates/questions/CreateQuestionContainer";
import DeleteQuestionContainer from "../../presentation/templates/questions/DeleteQuestionContainer";
import DissociateNestedQuestionContainer from "../../presentation/templates/questions/DissociateNestedQuestionContainer";
import DissociateQuestionTaxonomyNodeContainer from "../../presentation/templates/questions/DissociateQuestionTaxonomyNodeContainer";
import EditNestedQuestionTriggerSelectionContainer from "../../presentation/templates/questions/EditNestedQuestionTriggerSelectionContainer";
import EditQuestionContainer from "../../presentation/templates/questions/EditQuestionContainer";
import OrderNestedQuestionsContainer from "../../presentation/templates/questions/OrderNestedQuestionsContainer";
import QuestionsContainer from "../../presentation/templates/questions/QuestionsContainer";
import ViewQuestionContainer from "../../presentation/templates/questions/ViewQuestionContainer";
import CreateReferentialLinkTypeContainer from "../../presentation/templates/referential-link-types/CreateReferentialLinkTypeContainer";
import DeleteReferentialLinkTypeContainer from "../../presentation/templates/referential-link-types/DeleteReferentialLinkTypeContainer";
import EditReferentialLinkTypeContainer from "../../presentation/templates/referential-link-types/EditReferentialLinkTypeContainer";
import ReferentialLinkTypesContainer from "../../presentation/templates/referential-link-types/ReferentialLinkTypesContainer";
import ViewReferentialLinkTypeContainer from "../../presentation/templates/referential-link-types/ViewReferentialLinkTypeContainer";
import CompletedAnswersContainer from "../../presentation/templates/reports/CompletedAnswersContainer";
import ReportsContainer from "../../presentation/templates/ReportsContainer";
import DeleteCheckListFromReviewContainer from "../../presentation/templates/review-reports/DeleteCheckListFromReviewContainer";
import DeleteReviewReportSetContainer from "../../presentation/templates/review-reports/DeleteReviewReportSetContainer";
import PreviewItemsContainer from "../../presentation/templates/review-reports/PreviewItemsContainer";
import QuestionSetInstanceReviewContainer from "../../presentation/templates/review-reports/QuestionSetInstanceReviewContainer";
import ReviewsContainer from "../../presentation/templates/review-reports/ReviewsContainer";
import ReviewsHistoryContainer from "../../presentation/templates/review-reports/ReviewsHistoryContainer";
import ReviewsHistoryFileDownloadContainer from "../../presentation/templates/review-reports/ReviewsHistoryFileDownloadContainer";
import UnpublishReviewReportSetContainer from "../../presentation/templates/review-reports/UnpublishReviewReportSetContainer";
import ViewReviewFindingContainer from "../../presentation/templates/review-reports/ViewReviewFindingContainer";
import ViewReviewReportSetContainer from "../../presentation/templates/review-reports/ViewReviewReportSetContainer";
import SimpleReportsContainer from "../../presentation/templates/SimpleReportsContainer";
import EditTenantContainer from "../../presentation/templates/tenants/EditTenantContainer";
import TenantDetailsContainer from "../../presentation/templates/tenants/TenantDetailsContainer";
import TenantManagementContainer from "../../presentation/templates/tenants/TenantManagementContainer";
import TriggerAdhocsContainer from "../../presentation/templates/trigger-adhocs/TriggerAdhocsContainer";
import AssociateUserNodeContainer from "../../presentation/templates/users/AssociateUserNodeContainer";
import CreateUserContainer from "../../presentation/templates/users/CreateUserContainer";
import DeleteUserContainer from "../../presentation/templates/users/DeleteUserContainer";
import DissociateUserNodeContainer from "../../presentation/templates/users/DissociateUserNodeContainer";
import EditUserContainer from "../../presentation/templates/users/EditUserContainer";
import RoleAdminDetailContainer from "../../presentation/templates/users/RoleAdminDetailContainer";
import RolesAdminContainer from "../../presentation/templates/users/RolesAdminContainer";
import SuspendUserFromAllNodesContainer from "../../presentation/templates/users/SuspendUserFromAllNodesContainer";
import ToggleUserLockoutContainer from "../../presentation/templates/users/ToggleUserLockoutContainer";
import UndeleteUserContainer from "../../presentation/templates/users/UndeleteUserContainer";
import ViewUserContainer from "../../presentation/templates/users/UserDetailsContainer";
import UsersContainer from "../../presentation/templates/users/UsersContainer";
import CreateWizardCardContainer from "../../presentation/templates/wizards/CreateWizardCardContainer";
import CreateWizardContainer from "../../presentation/templates/wizards/CreateWizardContainer";
import DeleteWizardCardContainer from "../../presentation/templates/wizards/DeleteWizardCardContainer";
import EditWizardCardContainer from "../../presentation/templates/wizards/EditWizardCardContainer";
import EditWizardContainer from "../../presentation/templates/wizards/EditWizardContainer";
import PublishWizardContainer from "../../presentation/templates/wizards/PublishWizardContainer";
import ViewWizardContainer from "../../presentation/templates/wizards/ViewWizardContainer";
import WizardNotificationHistoryContainer from "../../presentation/templates/wizards/WizardNotificationHistoryContainer";
import WizardsContainer from "../../presentation/templates/wizards/WizardsContainer";

export const AuthenticatedRoutes = [
    {
        path: `${getPath("Wizard Notification History")}/:userId`,
        element: (
            <RequireAuth>
                <WizardNotificationHistoryContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(DrawerTitles.TeamAnswerSummary),
        element: (
            <RequireAuth>
                <TeamAnswerSummaryContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(DrawerTitles.Profile),
        element: (
            <RequireAuth>
                <ProfileContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.AnswerSummary)}/answer-details`,
        element: (
            <RequireAuth>
                <AnswerDetailsContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(DrawerTitles.AnswerSummary),
        element: (
            <RequireAuth>
                <AnswerSummaryContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(DrawerTitles.AnswerCapture),
        element: (
            <RequireAuth>
                <AnswerCaptureContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(DrawerTitles.CompletedAnswers),
        element: (
            <RequireAuth>
                <CompletedAnswersContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(DrawerTitles.TriggerAdhocs),
        element: (
            <RequireAuth>
                <TriggerAdhocsContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(DrawerTitles.PendingAdhocs),
        element: (
            <RequireAuth>
                <PendingAdhocsContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.PendingAdhocs)}/:questionSetInstanceId/cancel`,
        element: (
            <RequireAuth>
                <CancelPendingAdhocsContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(DrawerTitles.Reviews),
        element: (
            <RequireAuth>
                <ReviewsContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.Reviews)}/:reviewReportSetId`,
        element: (
            <RequireAuth>
                <ViewReviewReportSetContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(
            DrawerTitles.Reviews
        )}/questionSetInstanceAnswer/:questionSetInstanceAnswerId`,
        element: (
            <RequireAuth>
                <ViewReviewFindingContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.Reviews)}/question-set-instance-review`,
        element: (
            <RequireAuth>
                <QuestionSetInstanceReviewContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.Reviews)}/preview-items`,
        element: (
            <RequireAuth>
                <PreviewItemsContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(
            DrawerTitles.Reviews
        )}/:questionSetInstanceId/delete-checklist-from-review`,
        element: (
            <RequireAuth>
                <DeleteCheckListFromReviewContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.Reviews)}/:reviewReportSetId/delete-review-report-set`,
        element: (
            <RequireAuth>
                <DeleteReviewReportSetContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.Reviews)}/:reviewReportSetId/unpublish`,
        element: (
            <RequireAuth>
                <UnpublishReviewReportSetContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(DrawerTitles.ReviewsHistory),
        element: (
            <RequireAuth>
                <ReviewsHistoryContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.ReviewsHistory)}/:reviewReportSetId/download-file`,
        element: (
            <RequireAuth>
                <ReviewsHistoryFileDownloadContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(DrawerTitles.SimpleReports),
        element: (
            <RequireAuth>
                <SimpleReportsContainer route={getPath(DrawerTitles.SimpleReports)} />
            </RequireAuth>
        ),
    },
    {
        path: getPath(DrawerTitles.Reports),
        element: (
            <RequireAuth>
                <ReportsContainer route={getPath(DrawerTitles.Reports)} />
            </RequireAuth>
        ),
    },
    {
        path: getPath(DrawerTitles.MyActionCalendar),
        element: (
            <RequireAuth>
                <MyActionCalendarContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(DrawerTitles.MyActionSummary),
        element: (
            <RequireAuth>
                <MyActionSummaryContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(DrawerTitles.MyActionItems),
        element: (
            <RequireAuth>
                <MyActionItemsContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(DrawerTitles.FinalizedActionItems),
        element: (
            <RequireAuth>
                <FinalisedActionItemsContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(DrawerTitles.NonWorkingDay),
        element: (
            <RequireAuth>
                <NonWorkingDaySetsContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.NonWorkingDay)}/create`,
        element: (
            <RequireAuth>
                <CreateNonWorkingDaySetContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.NonWorkingDay)}/:nonWorkingDaySetId`,
        element: (
            <RequireAuth>
                <ViewNonWorkingDaySetContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.NonWorkingDay)}/:nonWorkingDaySetId/associate-node`,
        element: (
            <RequireAuth>
                <AssociateNonWorkingDaySetNodeContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.NonWorkingDay)}/:nonWorkingDaySetId/edit`,
        element: (
            <RequireAuth>
                <EditNonWorkingDaySetContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.NonWorkingDay)}/:nonWorkingDaySetId/delete`,
        element: (
            <RequireAuth>
                <DeleteNonWorkingDaySetContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.NonWorkingDay)}/:nonWorkingDaySetId/create-non-working-day`,
        element: (
            <RequireAuth>
                <CreateNonWorkingDayContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.NonWorkingDay)}/:nonWorkingDayId/delete-non-working-day`,
        element: (
            <RequireAuth>
                <DeleteNonWorkingDayContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.NonWorkingDay)}/:nonWorkingDayId/edit-non-working-day`,
        element: (
            <RequireAuth>
                <EditNonWorkingDayContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(DrawerTitles.TenantManagement),
        element: (
            <RequireAuth>
                <TenantManagementContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.TenantManagement)}/:tenantId`,
        element: (
            <RequireAuth>
                <TenantDetailsContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(AccordionTitles.Checklists),
        element: (
            <RequireAuth>
                <ChecklistsContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Checklists)}/:checklistId`,
        element: (
            <RequireAuth>
                <ViewChecklistContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Checklists)}/:checklistId/edit`,
        element: (
            <RequireAuth>
                <EditChecklistContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Checklists)}/create`,
        element: (
            <RequireAuth>
                <CreateChecklistContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Checklists)}/:checklistId/delete`,
        element: (
            <RequireAuth>
                <DeleteChecklistContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Checklists)}/:checklistId/promote`,
        element: (
            <RequireAuth>
                <PromoteDemoteChecklistContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Checklists)}/:checklistId/demote`,
        element: (
            <RequireAuth>
                <PromoteDemoteChecklistContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Checklists)}/:checklistId/review`,
        element: (
            <RequireAuth>
                <ReviewChecklistContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Checklists)}/:checklistId/associate-referential-links`,
        element: (
            <RequireAuth>
                <AssociateReferentialLinkContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Checklists)}/:checklistId/create-referential-link`,
        element: (
            <RequireAuth>
                <CreateReferentialLinkContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Checklists)}/:checklistId/associate-question-sets`,
        element: (
            <RequireAuth>
                <AssociateQuestionSetContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Checklists)}/:checklistId/dissociate-question-sets`,
        element: (
            <RequireAuth>
                <DissociateQuestionSetContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Checklists)}/:checklistId/dissociate-taxonomy-node`,
        element: (
            <RequireAuth>
                <DissociateChecklistTaxonomyNodeContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Checklists)}/:checklistId/associate-taxonomy-node`,
        element: (
            <RequireAuth>
                <AssociateChecklistTaxonomyNodeContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Checklists)}/:checklistId/dissociate-node`,
        element: (
            <RequireAuth>
                <DissociateNodeContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Checklists)}/:checklistId/associate-node`,
        element: (
            <RequireAuth>
                <AssociateNodeContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Checklists)}/:checklistId/assign-node-type-value`,
        element: (
            <RequireAuth>
                <AssignNodeTypeValueContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(
            AccordionTitles.Checklists
        )}/:checklistAssignmentId/checklist-assignment-details`,
        element: (
            <RequireAuth>
                <ViewChecklistAssignmentContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(
            AccordionTitles.Checklists
        )}/:checklistAssignmentId/activate-suspend-checklist-assignment`,
        element: (
            <RequireAuth>
                <ActivateSuspendChecklistAssignmentContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(
            AccordionTitles.Checklists
        )}/:checklistAssignmentId/promote-demote-checklist-assignment`,
        element: (
            <RequireAuth>
                <PromoteDemoteChecklistAssignmentContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Checklists)}/:checklistAssignmentId/edit-schedule`,
        element: (
            <RequireAuth>
                <EditScheduleContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Checklists)}/:checklistAssignmentId/edit-assignment`,
        element: (
            <RequireAuth>
                <EditChecklistAssignmentContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(AccordionTitles.QuestionSets),
        element: (
            <RequireAuth>
                <QuestionSetsContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.QuestionSets)}/:questionSetId`,
        element: (
            <RequireAuth>
                <ViewQuestionSetContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.QuestionSets)}/:questionSetId/edit`,
        element: (
            <RequireAuth>
                <EditQuestionSetContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Questions)}/:questionId/dissociate-taxonomy-node`,
        element: (
            <RequireAuth>
                <DissociateQuestionTaxonomyNodeContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Questions)}/:questionId/associate-taxonomy-node`,
        element: (
            <RequireAuth>
                <AssociateQuestionTaxonomyNodeContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Questions)}/:nestedQuestionId/dissociate-nested-question`,
        element: (
            <RequireAuth>
                <DissociateNestedQuestionContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.QuestionSets)}/create`,
        element: (
            <RequireAuth>
                <CreateQuestionSetContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.QuestionSets)}/:questionSetId/delete`,
        element: (
            <RequireAuth>
                <DeleteQuestionSetContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(
            AccordionTitles.QuestionSets
        )}/:questionSetId/dissociate-referential-link/:referentialLinkTypeId`,
        element: (
            <RequireAuth>
                <DissociateQuestionSetReferentialLinkTypeContainer />
            </RequireAuth>
        ),
    },

    {
        path: `${getPath(AccordionTitles.QuestionSets)}/:questionSetId/order`,
        element: (
            <RequireAuth>
                <OrderQuestionsContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(AccordionTitles.Questions),
        element: (
            <RequireAuth>
                <QuestionsContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Questions)}/:questionId`,
        element: (
            <RequireAuth>
                <ViewQuestionContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Questions)}/create`,
        element: (
            <RequireAuth>
                <CreateQuestionContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Questions)}/:questionId/edit`,
        element: (
            <RequireAuth>
                <EditQuestionContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Questions)}/:questionId/delete`,
        element: (
            <RequireAuth>
                <DeleteQuestionContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Questions)}/update-nested-question-trigger-selection`,
        element: (
            <RequireAuth>
                <EditNestedQuestionTriggerSelectionContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(AccordionTitles.QuestionCategories),
        element: (
            <RequireAuth>
                <QuestionCategoriesContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.QuestionCategories)}/:questionCategoryId`,
        element: (
            <RequireAuth>
                <ViewQuestionCategoryContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.QuestionCategories)}/create`,
        element: (
            <RequireAuth>
                <CreateQuestionCategoryContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.QuestionCategories)}/:questionCategoryId/edit`,
        element: (
            <RequireAuth>
                <EditQuestionCategoryContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.QuestionCategories)}/:questionCategoryId/delete`,
        element: (
            <RequireAuth>
                <DeleteQuestionCategoryContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Questions)}/:questionId/add-alternative-question-text`,
        element: (
            <RequireAuth>
                <CreateAlternativeQuestionTextContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(AccordionTitles.AnswerTypes),
        element: (
            <RequireAuth>
                <AnswerTypesContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.AnswerTypes)}/:answerTypeId`,
        element: (
            <RequireAuth>
                <ViewAnswerTypeContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.AnswerTypes)}/create`,
        element: (
            <RequireAuth>
                <CreateAnswerTypeContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.AnswerTypes)}/:answerTypeId/edit`,
        element: (
            <RequireAuth>
                <EditAnswerTypeContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.AnswerTypes)}/:answerTypeId/delete`,
        element: (
            <RequireAuth>
                <DeleteAnswerTypeContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.AnswerTypes)}/:answerTypeId${getPath(
            AccordionTitles.AnswerTypePredefinedAnswers
        )}/create`,
        element: (
            <RequireAuth>
                <CreatePredefinedAnswerContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.AnswerTypes)}/:answerTypeId${getPath(
            AccordionTitles.AnswerTypePredefinedAnswers
        )}/:predefinedAnswerId`,
        element: (
            <RequireAuth>
                <ViewPredefinedAnswerContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.AnswerTypes)}/:answerTypeId${getPath(
            AccordionTitles.AnswerTypePredefinedAnswers
        )}/:predefinedAnswerId${getPath(AccordionTitles.AnswerTypePredefinedAnswerTexts)}/create`,
        element: (
            <RequireAuth>
                <CreatePredefinedAnswerTextContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.AnswerTypes)}/:answerTypeId${getPath(
            AccordionTitles.AnswerTypePredefinedAnswers
        )}/:predefinedAnswerId/edit`,
        element: (
            <RequireAuth>
                <EditPredefinedAnswerContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(AccordionTitles.ReferentialLinkTypes),
        element: (
            <RequireAuth>
                <ReferentialLinkTypesContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.ReferentialLinkTypes)}/create`,
        element: (
            <RequireAuth>
                <CreateReferentialLinkTypeContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.ReferentialLinkTypes)}/:referentialLinkTypeId/edit`,
        element: (
            <RequireAuth>
                <EditReferentialLinkTypeContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.ReferentialLinkTypes)}/:referentialLinkTypeId/delete`,
        element: (
            <RequireAuth>
                <DeleteReferentialLinkTypeContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.ReferentialLinkTypes)}/:referentialLinkTypeId`,
        element: (
            <RequireAuth>
                <ViewReferentialLinkTypeContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(AccordionTitles.Import),
        element: (
            <RequireAuth>
                <ImportContainer route={getPath(AccordionTitles.Import)} />
            </RequireAuth>
        ),
    },
    {
        path: getPath(AccordionTitles.VisualTree),
        element: (
            <RequireAuth>
                <VisualTreeContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(AccordionTitles.Search),
        element: (
            <RequireAuth>
                <SearchContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(AccordionTitles.VisualStructure),
        element: (
            <RequireAuth>
                <VisualStructureContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(AccordionTitles.HierarchyImport),
        element: (
            <RequireAuth>
                <HierarchyImportContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.HierarchyImport)}/:hierarchyImportId`,
        element: (
            <RequireAuth>
                <HierarchyImportDetailsContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.HierarchyImport)}/create`,
        element: (
            <RequireAuth>
                <CreateHierarchyImportContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(AccordionTitles.Users),
        element: (
            <RequireAuth>
                <UsersContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(AccordionTitles.Roles),
        element: (
            <RequireAuth>
                <RolesAdminContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(AccordionTitles.AuditLog),
        element: (
            <RequireAuth>
                <AuditsChangelogContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(AccordionTitles.SecurityAuditLog),
        element: (
            <RequireAuth>
                <AuditsSecurityLoginContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.Dashboard)}`,
        element: (
            <RequireAuth>
                <DashboardContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(DrawerTitles.EmailQueue),
        element: (
            <RequireAuth>
                <EmailQueueContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.EmailQueue)}/:emailQueueId`,
        element: (
            <RequireAuth>
                <EmailQueueDetailsContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(AccordionTitles.ImageLibrary),
        element: (
            <RequireAuth>
                <ImageLibraryContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.ImageLibrary)}/upload`,
        element: (
            <RequireAuth>
                <ImageLibraryFileUploadContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(AccordionTitles.Wizards),
        element: (
            <RequireAuth>
                <WizardsContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(DrawerTitles.ActionPlans),
        element: (
            <RequireAuth>
                <ActionPlansContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.ActionPlans)}/create`,
        element: (
            <RequireAuth>
                <CreateActionPlanContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.ActionPlans)}/:actionPlanId/delete`,
        element: (
            <RequireAuth>
                <DeleteActionPlanContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.ActionPlans)}/:actionPlanId/edit`,
        element: (
            <RequireAuth>
                <EditActionPlanContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.ActionPlans)}/:actionPlanId`,
        element: (
            <RequireAuth>
                <ActionPlanDetailsContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.ActionItems)}/create`,
        element: (
            <RequireAuth>
                <CreateActionItemContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.ActionItems)}/:actionItemId/create`,
        element: (
            <RequireAuth>
                <CloneActionItemContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.ActionPlans)}/:actionPlanId/associate-action-items`,
        element: (
            <RequireAuth>
                <AssociateActionItemsContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(DrawerTitles.ActionItems),
        element: (
            <RequireAuth>
                <ActionItemsContainer />
            </RequireAuth>
        ),
    },

    {
        path: `${getPath(DrawerTitles.ActionItems)}/:actionItemId/cancel`,
        element: (
            <RequireAuth>
                <CancelActionItemContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.ActionItems)}/:actionItemId`,
        element: (
            <RequireAuth>
                <ActionItemDetailsContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.ActionItems)}/:actionItemId/edit`,
        element: (
            <RequireAuth>
                <EditActionItemContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.ActionItems)}/:actionItemId/associate-referential-links`,
        element: (
            <RequireAuth>
                <AssociateReferentialLinkContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.ActionItems)}/:actionItemId/create-referential-link`,
        element: (
            <RequireAuth>
                <CreateReferentialLinkContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.ActionItems)}/:actionItemId/associate-node`,
        element: (
            <RequireAuth>
                <AssociateActionItemNodeContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.ActionItems)}/:raciSetId/dissociate-raci-participant`,
        element: (
            <RequireAuth>
                <DissociateActionItemRaciParticipantContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Wizards)}/create`,
        element: (
            <RequireAuth>
                <CreateWizardContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Wizards)}/:wizardId/edit`,
        element: (
            <RequireAuth>
                <EditWizardContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Wizards)}/:wizardId/publish`,
        element: (
            <RequireAuth>
                <PublishWizardContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Wizards)}/:wizardId`,
        element: (
            <RequireAuth>
                <ViewWizardContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Wizards)}/:wizardId/create-wizard-card`,
        element: (
            <RequireAuth>
                <CreateWizardCardContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Wizards)}/:wizardCardId/edit-wizard-card`,
        element: (
            <RequireAuth>
                <EditWizardCardContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Wizards)}/:wizardCardId/delete-wizard-card`,
        element: (
            <RequireAuth>
                <DeleteWizardCardContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Users)}/:userId`,
        element: (
            <RequireAuth>
                <ViewUserContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Roles)}/:roleId`,
        element: (
            <RequireAuth>
                <RoleAdminDetailContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.TenantManagement)}/:tenantId/edit`,
        element: (
            <RequireAuth>
                <EditTenantContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Users)}/:userId/edit`,
        element: (
            <RequireAuth>
                <EditUserContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Users)}/:userId/delete`,
        element: (
            <RequireAuth>
                <DeleteUserContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Users)}/:userId/undelete`,
        element: (
            <RequireAuth>
                <UndeleteUserContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Users)}/:userId/lockout`,
        element: (
            <RequireAuth>
                <ToggleUserLockoutContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Users)}/:userId/reset-lockout`,
        element: (
            <RequireAuth>
                <ToggleUserLockoutContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Users)}/:userId/suspend-user-node`,
        element: (
            <RequireAuth>
                <SuspendUserFromAllNodesContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Users)}/:userId/reset-suspend-user-node`,
        element: (
            <RequireAuth>
                <SuspendUserFromAllNodesContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Users)}/create`,
        element: (
            <RequireAuth>
                <CreateUserContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Users)}/:userId/associate-node`,
        element: (
            <RequireAuth>
                <AssociateUserNodeContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Users)}/:userId/dissociate-node`,
        element: (
            <RequireAuth>
                <DissociateUserNodeContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.VisualTree)}/:nodeId`,
        element: (
            <RequireAuth>
                <NodeDetailsContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.VisualTree)}/:nodeId/edit`,
        element: (
            <RequireAuth>
                <EditNodeContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.VisualTree)}/:nodeId/create`,
        element: (
            <RequireAuth>
                <CreateNodeContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.VisualTree)}/move`,
        element: (
            <RequireAuth>
                <MoveNodeContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.VisualTree)}/dissociate-user`,
        element: (
            <RequireAuth>
                <DissociateUserContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.VisualTree)}/:nodeId/delete`,
        element: (
            <RequireAuth>
                <SoftDeleteNodeContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Questions)}/:questionId`,
        element: (
            <RequireAuth>
                <ViewQuestionContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Questions)}/create`,
        element: (
            <RequireAuth>
                <CreateQuestionContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Questions)}/:questionId/edit`,
        element: (
            <RequireAuth>
                <EditQuestionContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Questions)}/:questionId/delete`,
        element: (
            <RequireAuth>
                <DeleteQuestionContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Questions)}/:parentQuestionId/order-nested-questions`,
        element: (
            <RequireAuth>
                <OrderNestedQuestionsContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Questions)}/:parentQuestionId/associate-child-question`,
        element: (
            <RequireAuth>
                <AssociateChildQuestionContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.VisualStructure)}/:nodeTypeId`,
        element: (
            <RequireAuth>
                <NodeTypeDetailsContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.VisualStructure)}/:nodeTypeId/edit`,
        element: (
            <RequireAuth>
                <EditNodeTypeContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.VisualStructure)}/create`,
        element: (
            <RequireAuth>
                <CreateNodeTypeContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.VisualStructure)}/:nodeTypeId/delete`,
        element: (
            <RequireAuth>
                <DeleteNodeTypeContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.VisualStructureValues)}/:nodeTypeValueId`,
        element: (
            <RequireAuth>
                <NodeTypeValueDetailsContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.VisualStructureValues)}/:nodeTypeValueId/edit`,
        element: (
            <RequireAuth>
                <EditNodeTypeValueContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.VisualStructureValues)}/:nodeTypeValueId/delete`,
        element: (
            <RequireAuth>
                <DeleteNodeTypeValueContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(AccordionTitles.Admin),
        element: (
            <RequireAuth>
                <HierarchyTypesContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Admin)}/:hierarchyTypeId`,
        element: (
            <RequireAuth>
                <HierarchyTypeDetailsContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Admin)}/:hierarchyTypeId/edit`,
        element: (
            <RequireAuth>
                <EditHierarchyTypeContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.Admin)}/create`,
        element: (
            <RequireAuth>
                <CreateHierarchyTypeContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.VisualTree)}/:nodeId/promote`,
        element: (
            <RequireAuth>
                <PromoteNodeContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.VisualTree)}/:nodeId/demote`,
        element: (
            <RequireAuth>
                <DemoteNodeContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.VisualTree)}/:nodeId/activate`,
        element: (
            <RequireAuth>
                <ActivateNodeContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(AccordionTitles.VisualTree)}/:nodeId/suspend`,
        element: (
            <RequireAuth>
                <SuspendNodeContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.ActionItemDynamicFields)}`,
        element: (
            <RequireAuth>
                <ActionItemDynamicFieldsContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.ActionItemDynamicFields)}/create`,
        element: (
            <RequireAuth>
                <CreateActionItemDynamicFieldContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.ActionItemDynamicFields)}/:actionItemDynamicFieldId`,
        element: (
            <RequireAuth>
                <ActionItemDynamicFieldDetailsContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.ActionItemDynamicFieldTexts)}/create`,
        element: (
            <RequireAuth>
                <CreateActionItemDynamicFieldTextContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.ActionItemDynamicFields)}/order`,
        element: (
            <RequireAuth>
                <OrderActionItemDynamicFieldsContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.ActionItemDynamicFields)}/:actionItemDynamicFieldId/delete`,
        element: (
            <RequireAuth>
                <DeleteActionItemDynamicFieldContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.ActionItemDynamicFields)}/:actionItemDynamicFieldId/edit`,
        element: (
            <RequireAuth>
                <EditActionItemDynamicFieldContainer />
            </RequireAuth>
        ),
    },
    {
        path: getPath(DrawerTitles.NotificationTriggers),
        element: (
            <RequireAuth>
                <NotificationTriggersContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.NotificationTriggers)}/:notificationTriggerId`,
        element: (
            <RequireAuth>
                <NotificationTriggerDetailsContainer />
            </RequireAuth>
        ),
    },
    {
        path: `${getPath(DrawerTitles.NotificationTriggers)}/:notificationTriggerId/edit`,
        element: (
            <RequireAuth>
                <EditNotificationTriggerContainer />
            </RequireAuth>
        ),
    },
];
