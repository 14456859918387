import AnswerCategory from "../../enums/answer-types/answer-category";
import AnswerFlagMode from "../../enums/answer-types/answer-flag-mode";
import AnswerRenderMode from "../../enums/answer-types/answer-render-mode";
import AnswerValueType from "../../enums/answer-types/answer-value-type";

export interface CreateAnswerTypeRequest {
    name: string;
    answerCategory: AnswerCategory | null;
    answerRenderMode: AnswerRenderMode | null;
    answerFlagMode: AnswerFlagMode | null;
    answerValueType: AnswerValueType | null;
}

export const createCreateAnswerTypeRequest = (
    name: string,
    answerCategoryId: number | null,
    renderModeId: number | null,
    flagModeId: number | null,
    valueTypeId: number | null
): CreateAnswerTypeRequest => {
    if (answerCategoryId == AnswerCategory.MutuallyExclusive) {
        return {
            name: name,
            answerCategory: answerCategoryId,
            answerRenderMode: renderModeId,
            answerFlagMode: flagModeId,
            answerValueType: null,
        };
    }
    if (answerCategoryId == AnswerCategory.InputValue) {
        return {
            name: name,
            answerCategory: answerCategoryId,
            answerRenderMode: null,
            answerFlagMode: null,
            answerValueType: valueTypeId,
        };
    }

    return {
        name: "",
        answerCategory: null,
        answerRenderMode: null,
        answerFlagMode: null,
        answerValueType: null,
    };
};
