import { AnswerThresholdOperatorsResponse } from "../../responses/answer-threshold-operators/answer-threshold-operators-response";

export interface AnswerThresholdOperatorsDto {
    answerThresholdOperatorId: number;
    name: string;
}

export const createAnswerThresholdOperatorsDto = (
    answerThresholdOperatorsResponse: AnswerThresholdOperatorsResponse
): AnswerThresholdOperatorsDto => ({
    answerThresholdOperatorId: answerThresholdOperatorsResponse.answerThresholdOperatorId,
    name: answerThresholdOperatorsResponse.name,
});

export const toAnswerThresholdOperatorsDto = (
    data: AnswerThresholdOperatorsResponse[]
): AnswerThresholdOperatorsDto[] =>
    data.map((answerThresholdOperator) =>
        createAnswerThresholdOperatorsDto(answerThresholdOperator)
    );
