import { ReferentialLinkResponse } from "../../responses/referential-links/referential-link-response";

export interface AssociateReferentialLinkDto {
    referentialLinkId: number;
    referentialLinkTypeName: string;
    value: string;
}

export const toAssociateReferentialLinkDto = (
    referentialLinkResponse: ReferentialLinkResponse
): AssociateReferentialLinkDto => ({
    ...referentialLinkResponse,
});
