import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { TableRow } from "../../../core/utilities/customTypes";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import { filterTenantsWithUserCounts } from "../../../data/services/tenants/tenant-service";
import { createPaginationRequest } from "../../requests/common/pagination-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { TenantWithUserCountResponse } from "../../responses/tenants/tenant-with-user-count-response";

export const useFilterTenantsWithUserCounts = (): UseQueryResult<TableRow<number>[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterTenantsWithUserCounts"],
        () => filterTenantsWithUserCounts(url.originalBaseUrl, createPaginationRequest(1, 1000)),
        {
            select: transformToDataTableRows,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const transformToDataTableRows = (
    response: Response<PaginationResponse<TenantWithUserCountResponse>>
): TableRow<number>[] => {
    const responseData = response.data;

    return responseData.results!.map((tenant) => ({
        metadata: tenant.tenantId,
        columns: [
            { value: tenant.name.toString(), type: DataTableColumnTypes.Text },
            { value: tenant.baseUrl.toString(), type: DataTableColumnTypes.Text },
            { value: tenant.theme.toString(), type: DataTableColumnTypes.Text },
            { value: tenant.usersCount.toString(), type: DataTableColumnTypes.Text },
        ],
    }));
};
