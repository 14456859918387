import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { StyledFormLabel } from "../../core/theme/global-styles";
import SbLabelText from "./SbLabelText";

const StyledInput = styled.input`
    width: ${(props) => props.theme.dimensions.width.xxxxxl};
    height: ${(props) => props.theme.dimensions.height.xl};
    background-color: ${(props) => props.theme.palette.purewhite};
    background-image: none;
    border: ${(props) => props.theme.padding.xxs} solid ${(props) => props.theme.palette.grey};
    border-radius: 0.5rem;
    color: inherit;
    transition: border-color .15s
    font-size: ${(props) => props.theme.palette.grey};
    text-align: center; 
`;

const SbFormTimeFieldGroup: React.FC<{
    label: string;
    name: string;
    defaultValue?: string;
    value?: string;
    required?: boolean;
    disabled?: boolean;
    children?: React.ReactNode;
    handleTimeChange?: React.ChangeEventHandler<HTMLInputElement>;
}> = ({ label, name, defaultValue, value, required, disabled, children, handleTimeChange }) => (
    <Form.Group as={Row} className="mb-3">
        <StyledFormLabel column sm={2}>
            <SbLabelText label={label} required={required} />
        </StyledFormLabel>
        <Col sm={4}>
            <StyledInput
                name={name}
                className="form-control"
                type={"time"}
                defaultValue={defaultValue}
                value={value}
                required={required}
                disabled={disabled}
                onChange={handleTimeChange}
            />
        </Col>
        {children && <Col>{children}</Col>}
    </Form.Group>
);

export default SbFormTimeFieldGroup;
