import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { editChecklist } from "../../../data/services/checklists/checklist-service";
import { Response } from "../../responses/common/response-response";
import { EditChecklistDto } from "../../dtos/checklists/edit-checklist-dto";

interface EditParameters {
    checklistId: number;
    dto: EditChecklistDto;
}

export const useEditChecklist = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    EditParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: EditParameters) => {

        const request = {
            checklistId: mutationKey.checklistId,
            name: mutationKey.dto.name,
            description: mutationKey.dto.description,
        };

        return editChecklist(url.baseUrl, request);
    });
};
