import React from "react";
import { OverlayTrigger } from "react-bootstrap";
import styled from "styled-components";
import { StyledPopover } from "../../core/theme/global-styles";
import { SbSelect, SbSelectProps } from "./input/SbSelect";

const StyledDiv = styled.div``;

export interface TooltipSelectProps<TType> extends SbSelectProps<TType> {
    tooltip: string;
}

const SbTooltipSelect: React.FC<TooltipSelectProps<any>> = ({
    tooltip,
    styles,
    name,
    placeholderText,
    searchable,
    clearable,
    loading,
    disabled,
    items,
    defaultSelectedItem,
    itemLabel,
    itemValue,
    onChange,
    value,
    onSearchTextChanged,
}) => {
    return (
        <OverlayTrigger
            overlay={tooltip != "" ? <StyledPopover>{tooltip}</StyledPopover> : <></>}
            placement={"top-start"}
        >
            <StyledDiv>
                <SbSelect
                    name={name}
                    placeholderText={placeholderText}
                    defaultSelectedItem={defaultSelectedItem}
                    searchable={searchable}
                    clearable={clearable}
                    items={items}
                    itemLabel={itemLabel}
                    onChange={onChange}
                    value={value}
                    styles={styles}
                    loading={loading}
                    disabled={disabled}
                    itemValue={itemValue}
                    onSearchTextChanged={onSearchTextChanged}
                />
            </StyledDiv>
        </OverlayTrigger>
    );
};

export default SbTooltipSelect;
