import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    associateTaxonomyNode,
    getChecklistDetails,
} from "../../../data/services/checklists/checklist-service";
import { AssociateDissociateChecklistTaxonomyNodeDto } from "../../dtos/checklists/associate-dissociate-checklist-taxonomy-node-dto";
import { createAssociateDissociateChecklistTaxonomyNodeRequest } from "../../requests/checklists/associate-dissociate-checklist-taxonomy-node-request";
import { ChecklistResponse } from "../../responses/checklists/checklist-response";
import { Response } from "../../responses/common/response-response";

export const useGetAssociateTaxonomyNodeDetails = (
    checklistId: number
): UseQueryResult<string, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getChecklist", checklistId],
        () => getChecklistDetails(url.baseUrl, checklistId),
        {
            select: createChecklistDto,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const createChecklistDto = (response: Response<ChecklistResponse>): string => response.data.name;

export const useAssociateTaxonomyNode = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    AssociateDissociateChecklistTaxonomyNodeDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: AssociateDissociateChecklistTaxonomyNodeDto) => {
        const request = createAssociateDissociateChecklistTaxonomyNodeRequest(
            mutationKey.checklistId,
            mutationKey.nodeId
        );

        return associateTaxonomyNode(url.baseUrl, request);
    });
};
