export interface CreateActionItemDynamicFieldTextRequest {
    actionItemDynamicFieldId: number;
    languageId: number;
    text: string;
}

export const createCreateActionItemDynamicFieldTextRequest = (
    actionItemDynamicFieldId: number,
    languageId: number,
    text: string
): CreateActionItemDynamicFieldTextRequest => ({
    actionItemDynamicFieldId: actionItemDynamicFieldId,
    languageId: languageId,
    text: text,
});
