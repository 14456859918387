import { Response } from "../../responses/common/response-response";
import { NodeDescriptionDetailsResponse } from "../../responses/hierarchy/node-description-details-response";
import { BaseNodeDto, createBaseNodeDto } from "./base-node-dto";

export interface NodeDescriptionDetailsDto extends BaseNodeDto {
    shortDescriptionWithParent: string;
}

export const createNodeDescriptionDetailsDto = (
    response: NodeDescriptionDetailsResponse
): NodeDescriptionDetailsDto => ({
    ...createBaseNodeDto(response),
    shortDescriptionWithParent: response.shortDescriptionWithParent,
});

export const createNodeDescriptionDetailsDtoFromResponse = (
    response: Response<NodeDescriptionDetailsResponse>
): NodeDescriptionDetailsDto => createNodeDescriptionDetailsDto(response.data);
