import { ReactElement } from "react";
import { Accordion, Card } from "react-bootstrap";
import styled from "styled-components";

const StyledAccordionHeader = styled(Accordion)`
    background-color: ${(props) => props.theme.palette.purewhite};
`;

const StyledCard = styled(Card)`
    overflow: visible;
`;

const StyledSpan = styled.span`
    color: ${(props) => props.theme.palette.primary};
`;

export const TextTitledPanel: React.FC<{
    title: string;
    children: React.ReactNode;
}> = ({ title, children }) => (
    <Accordion defaultActiveKey="0">
        <StyledCard>
            <StyledAccordionHeader as={Card.Header} variant="link">
                <StyledSpan>{title}</StyledSpan>
            </StyledAccordionHeader>
            <Accordion.Collapse eventKey="0">
                <Card.Body>{children}</Card.Body>
            </Accordion.Collapse>
        </StyledCard>
    </Accordion>
);

export const ComponentTitledPanel: React.FC<{
    component: ReactElement;
    children: React.ReactNode;
}> = ({ component, children }) => (
    <Accordion defaultActiveKey="0">
        <StyledCard>
            <StyledAccordionHeader as={Card.Header} variant="link">
                {component}
            </StyledAccordionHeader>
            <Accordion.Collapse eventKey="0">
                <Card.Body>{children}</Card.Body>
            </Accordion.Collapse>
        </StyledCard>
    </Accordion>
);

export const ComponentPanel: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => (
    <Accordion defaultActiveKey="0" className="column">
        <Card>
            <Accordion.Collapse eventKey="0">
                <Card.Body>{children}</Card.Body>
            </Accordion.Collapse>
        </Card>
    </Accordion>
);
