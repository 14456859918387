export interface ReactSelectDto<TType> {
    value: TType;
    label: string;
}

export const createReactSelectDto = <TType>(
    value: TType,
    label: string
): ReactSelectDto<TType> => ({
    value: value,
    label: label,
});

export const createReactSelectDtoWithValue = <TType>(option: TType): ReactSelectDto<TType> => ({
    value: option,
    label: String(option),
});

export const valuesToReactSelectDtos = <TType>(options: TType[]): ReactSelectDto<TType>[] =>
    options.map((option) => createReactSelectDtoWithValue(option));

export const createDefaultAllReactSelectDto = <TType>(): ReactSelectDto<TType | null> =>
    createReactSelectDto(null, "All");

export interface ReactSelectDropdownDto<TType> {
    defaultValue: ReactSelectDto<TType | null>;
    values: ReactSelectDto<TType>[];
}

export const createReactSelectDropdownDto = <TType>(
    values: ReactSelectDto<TType>[]
): ReactSelectDropdownDto<TType> => ({
    defaultValue: createDefaultAllReactSelectDto<TType>(),
    values: values,
});
