import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { convertToggleValueToBoolean } from "../../../core/utilities/convertToBoolean";
import { AnswerThresholdIndicatorEnum } from "../../../core/utilities/enums";
import { editQuestion } from "../../../data/services/questions/question-service";
import { QuestionTypes } from "../../enums/questions/question-types";
import {
    EditAnswerThresholdValueRequest,
    EditQuestionWeightingsRequest,
} from "../../requests/questions/edit-question-request";
import { Response } from "../../responses/common/response-response";

interface EditParameters {
    questionId: number;
    questionTypeId: number;
    answerTypeId: number;
    answerTypeName: string | null;
    questionCategoryId: number;
    questionImportanceId: number;
    aggregationTypeId: number;
    redAnswerThresholdValueId: number | null;
    redAnswerThresholdOperatorId: number | null;
    amberAnswerThresholdValueId: number | null;
    amberAnswerThresholdOperatorId: number | null;
    formData: FormData;
}

export const useEditQuestion = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    EditParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: EditParameters) => {
        const formData = mutationKey.formData;

        const request = {
            questionId: mutationKey.questionId,
            questionText: formData.get("questionText")! as string,
            questionTypeId: mutationKey.questionTypeId,
            answerTypeId: mutationKey.answerTypeId,
            questionCategoryId: mutationKey.questionCategoryId,
            questionImportance: mutationKey.questionImportanceId,
            isAggregate: convertToggleValueToBoolean(formData.get("Aggregate")! as string),
            aggregationTypeId: mutationKey.aggregationTypeId,
            answerThresholdValues:
                mutationKey.questionTypeId === QuestionTypes.Indicator
                    ? editAnswerThresholdValues(
                          formData,
                          mutationKey.answerTypeName,
                          mutationKey.redAnswerThresholdValueId,
                          mutationKey.redAnswerThresholdOperatorId,
                          mutationKey.amberAnswerThresholdValueId,
                          mutationKey.amberAnswerThresholdOperatorId
                      )
                    : [],
            mutateQuestionWeightingRequests:
                mutationKey.questionTypeId === QuestionTypes.Control &&
                convertToggleValueToBoolean(formData.get("QuestionWeighting")! as string)
                    ? editQuestionWeightings(formData)
                    : [],
        };

        return editQuestion(url.baseUrl, request);
    });
};

const editAnswerThresholdValues = (
    formData: FormData,
    answerTypeName: string | null,
    redAnswerThresholdValueId: number | null,
    redAnswerThresholdOperatorId: number | null,
    amberAnswerThresholdValueId: number | null,
    amberAnswerThresholdOperatorId: number | null
): EditAnswerThresholdValueRequest[] => {
    let editAnswerThresholdValueRequest: EditAnswerThresholdValueRequest[] = [];

    if (redAnswerThresholdOperatorId) {
        editAnswerThresholdValueRequest.push({
            answerThresholdValueId: redAnswerThresholdValueId,
            intValue: answerTypeName === "Number" ? Number(formData.get("redNumber")) : undefined,
            decimalValue:
                answerTypeName !== "Number" ? Number(formData.get("redDecimal")) : undefined,
            answerThresholdIndicator: AnswerThresholdIndicatorEnum.Red,
            answerThresholdOperator: redAnswerThresholdOperatorId!,
        });
    }

    if (amberAnswerThresholdOperatorId) {
        editAnswerThresholdValueRequest.push({
            answerThresholdValueId: amberAnswerThresholdValueId,
            intValue: answerTypeName === "Number" ? Number(formData.get("amberNumber")) : undefined,
            decimalValue:
                answerTypeName !== "Number" ? Number(formData.get("amberDecimal")) : undefined,
            answerThresholdIndicator: AnswerThresholdIndicatorEnum.Amber,
            answerThresholdOperator: amberAnswerThresholdOperatorId!,
        });
    }

    return editAnswerThresholdValueRequest;
};

const editQuestionWeightings = (formData: FormData): EditQuestionWeightingsRequest[] => {
    let questionWeightings: EditQuestionWeightingsRequest[] = [];
    formData.forEach((value, key) => {
        if (key.includes("predefinedAnswerId")) {
            const predefinedAnswerId = key.split("predefinedAnswerId")[1];
            questionWeightings.push(
                new EditQuestionWeightingsRequest(
                    Number(predefinedAnswerId),
                    value != "" ? Number(value) : undefined
                )
            );
        }
    });
    return questionWeightings;
};
