import { AssociateNodeToActionItemDto } from "../../dtos/actions/associate-node-to-action-item-dto";
import { ActionItemNodeAssociationMode } from "../../enums/action-items/ActionItemNodeAssociationMode";

export class AssociateNodeToActionItemRequest {
    actionItemId: number;
    nodeId: number;
    nodeAssociationMode: ActionItemNodeAssociationMode;

    public constructor(dto: AssociateNodeToActionItemDto) {
        const { actionItemId, nodeId, nodeAssociationMode } = dto;

        this.actionItemId = actionItemId;
        this.nodeId = nodeId;
        this.nodeAssociationMode = nodeAssociationMode;
    }
}
