import QuestionSetInstanceAnswerFileResponse from "../../responses/question-set-instance-answers/question-set-instance-answer-file-response";

class QuestionSetInstanceAnswerFileDto {
    questionSetInstanceAnswerFileId: number;
    questionSetInstanceAnswerId: number;
    fileName: string;
    dateUploadedUtc: Date | null;

    public constructor(response: QuestionSetInstanceAnswerFileResponse) {
        this.questionSetInstanceAnswerFileId = response.questionSetInstanceAnswerFileId;
        this.questionSetInstanceAnswerId = response.questionSetInstanceAnswerId;
        this.fileName = response.fileName;
        this.dateUploadedUtc = response.dateUploadedUtc;
    }
}

export default QuestionSetInstanceAnswerFileDto;
