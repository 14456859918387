import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { createAndAssociateQuestionSet } from "../../../data/services/checklists/checklist-service";
import CreateQuestionSetDto from "../../dtos/question-sets/create-question-set-dto";
import { createAssociateNewQuestionSetToChecklistRequest } from "../../requests/checklists/create-and-associate-question-set-request";
import { createCreateQuestionSetRequest } from "../../requests/question-set/create-question-set-request";
import { QuestionSetScheduleRequest } from "../../requests/question-set/create-question-set-schedule-request";
import { Response } from "../../responses/common/response-response";

interface CreateAndAssociateParameters {
    checklistId: number;
    dto: CreateQuestionSetDto;
}

export const useCreateAndAssociateQuestionSet = (
    questionSetScheduleRequest: QuestionSetScheduleRequest
): UseMutationResult<Response<number>, HTTPError, CreateAndAssociateParameters> => {
    const url = useUrl();

    return useMutation((mutationKey: CreateAndAssociateParameters) => {
        const dto = mutationKey.dto;

        let createQuestionSetRequests = [];
        const createQuestionSetRequest = createCreateQuestionSetRequest(
            dto.name,
            dto.description,
            dto.process,
            dto.arMonitoring,
            questionSetScheduleRequest
        );

        createQuestionSetRequests.push(createQuestionSetRequest);

        const request = createAssociateNewQuestionSetToChecklistRequest(
            mutationKey.checklistId,
            createQuestionSetRequest
        );

        return createAndAssociateQuestionSet(url.baseUrl, request);
    });
};
