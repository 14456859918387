import FilterUserQuestionsDto from "../../dtos/answer-capture/filter-user-questions-dto";
import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import DataStatus from "../../enums/common/DataStatus";
import InstanceStatus from "../../enums/questions/instance-status";
import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
    createBasePaginationRequestFromDto,
} from "../common/base-pagination-request";

export interface FilterUserQuestionsRequest extends BasePaginationRequest {
    nodeId: number | null;
    answerStatus: InstanceStatus | null;
    checklistId: number | null;
    isTraining: boolean | null;
}

export const createFilterUserQuestionsRequest = (
    filterUserQuestionsDto: FilterUserQuestionsDto,
    paginationDto: BasePaginationDto
): FilterUserQuestionsRequest => {
    const { nodeId, answerStatus, checklistId, dataStatus } = filterUserQuestionsDto;

    return {
        nodeId: nodeId,
        answerStatus: answerStatus,
        checklistId: checklistId,
        isTraining: dataStatus ? dataStatus === DataStatus.Training : null,
        ...createBasePaginationRequestFromDto(paginationDto),
    };
};

export const buildFilterUserQuestionsSearchParameters = (
    request: FilterUserQuestionsRequest
): URLSearchParams => {
    const searchParams = buildBasePaginationSearchParameters(request);

    if (request.nodeId) {
        searchParams.set("nodeId", request.nodeId.toString());
    }

    if (request.answerStatus) {
        searchParams.set("answerStatus", request.answerStatus.toString());
    }

    if (request.checklistId) {
        searchParams.set("checklistId", request.checklistId.toString());
    }

    if (request.isTraining != null) {
        searchParams.set("isTraining", request.isTraining.toString());
    }

    return searchParams;
};
