import { ExistingQuestionSetQuestionDto } from "./existing-question-set-question-dto";

export interface AssociateDissociateQuestionToQuestionSetDto {
    questionScheduleBaseQuestions: ExistingQuestionSetQuestionDto[];
}

export const createAssociateDissociateQuestionToQuestionSetDto = (
    questionScheduleBaseQuestions: ExistingQuestionSetQuestionDto[]
): AssociateDissociateQuestionToQuestionSetDto => ({
    questionScheduleBaseQuestions: questionScheduleBaseQuestions,
});
