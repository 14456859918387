import { useMutation, UseMutationResult, useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { getAllQuestionImportance } from "../../../data/services/question-importance/question-importance-service";
import {
    editTriggerSelectionForNestedQuestion,
    getNestedQuestionDetailsById,
} from "../../../data/services/questions/question-service";
import {
    QuestionImportanceDto,
    toQuestionImportanceDto,
} from "../../dtos/question-importance/question-importance-dto";
import { EditNestedQuestionTriggerSelectionDto } from "../../dtos/questions/edit-nested-question-trigger-selection-dto";
import { NestedQuestionDto } from "../../dtos/questions/nested-question-dto";
import { EditNestedQuestionTriggerSelectionRequest } from "../../requests/questions/edit-nested-question-trigger-selection-request";
import { Response } from "../../responses/common/response-response";
import { NestedQuestionResponse } from "../../responses/questions/nested-question-response";

export const useGetNestedQuestionDetailsById = (
    nestedQuestionId: number
): [
    UseQueryResult<NestedQuestionDto, HTTPError>,
    UseQueryResult<QuestionImportanceDto[], HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const queries = useQueries({
        queries: [
            {
                queryKey: ["getNestedQuestionDetailsById", nestedQuestionId],
                queryFn: () => getNestedQuestionDetailsById(url.baseUrl, nestedQuestionId),
                select: toNestedQuestionDto,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getAllQuestionImportance"],
                queryFn: () => getAllQuestionImportance(url.baseUrl),
                select: toQuestionImportanceDto,
            },
        ],
    });

    return queries as [
        UseQueryResult<NestedQuestionDto, HTTPError>,
        UseQueryResult<QuestionImportanceDto[], HTTPError>,
    ];
};

const toNestedQuestionDto = (response: Response<NestedQuestionResponse>): NestedQuestionDto =>
    new NestedQuestionDto(response.data);

export const useEditTriggerSelectionForNestedQuestion = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    EditNestedQuestionTriggerSelectionDto
> => {
    const url = useUrl();
    return useMutation((mutationKey: EditNestedQuestionTriggerSelectionDto) => {
        const request = new EditNestedQuestionTriggerSelectionRequest(mutationKey);
        return editTriggerSelectionForNestedQuestion(url.baseUrl, request);
    });
};
