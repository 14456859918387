export interface AssociateDissociateQuestionTaxonomyNodeDto {
    questionId: number;
    nodeId: number;
}

export const createAssociateDissociateQuestionTaxonomyNodeDto = (
    questionId: number,
    nodeId: number
): AssociateDissociateQuestionTaxonomyNodeDto => ({
    questionId: questionId,
    nodeId: nodeId,
});
