import { useMutation, UseMutationResult, useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    getCanSoftDeleteNode,
    getNodeDetails,
    softDeleteNode,
} from "../../../data/services/hierarchy/nodes-service";
import { createValidationResponseDto, ValidationResponseDto } from "../../dtos/common/response-dto";
import { createNodeDetailsDto, NodeDetailsDto } from "../../dtos/hierarchy/node-details-dto";
import { Response } from "../../responses/common/response-response";

export const useGetNodeDetails = (
    nodeId: number
): [
    UseQueryResult<NodeDetailsDto, HTTPError>,
    UseQueryResult<ValidationResponseDto<boolean>, HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getNodeDetails", nodeId],
                queryFn: () => getNodeDetails(url.baseUrl, nodeId),
                select: createNodeDetailsDto,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getCanSoftDeleteNode", nodeId],
                queryFn: () => getCanSoftDeleteNode(url.baseUrl, nodeId),
                select: createValidationResponseDto,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<NodeDetailsDto, HTTPError>,
        UseQueryResult<ValidationResponseDto<boolean>, HTTPError>,
    ];
};

export const useSoftDeleteNode = (): UseMutationResult<Response<boolean>, HTTPError, number> => {
    const url = useUrl();

    return useMutation((nodeId: number) => softDeleteNode(url.baseUrl, nodeId));
};
