import AppliesToUsers from "../../enums/checklists/AppliesToUsers";

export default class EditChecklistAssignmentDto {
    appliesToUsers: AppliesToUsers;
    checklistAssignmentId: number;
    nodeTypeValueIds: number[];
    applyChangesToday: boolean;
    isTraining: boolean;
    allowActionItemTrigger: boolean;
    allowAdhocIntervalOverride: boolean;
    allowCustomNotifications: boolean;
    allowFileAttachment: boolean;

    public constructor(
        appliesToUsers: AppliesToUsers,
        checklistAssignmentId: number,
        nodeTypeValueIds: number[],
        applyChangesToday: boolean,
        isTraining: boolean,
        allowActionItemTrigger: boolean,
        allowAdhocIntervalOverride: boolean,
        allowCustomNotifications: boolean,
        allowFileAttachment: boolean
    ) {
        this.appliesToUsers = appliesToUsers;
        this.checklistAssignmentId = checklistAssignmentId;
        this.nodeTypeValueIds = nodeTypeValueIds;
        this.applyChangesToday = applyChangesToday;
        this.isTraining = isTraining;
        this.allowActionItemTrigger = allowActionItemTrigger;
        this.allowAdhocIntervalOverride = allowAdhocIntervalOverride;
        this.allowCustomNotifications = allowCustomNotifications;
        this.allowFileAttachment = allowFileAttachment;
    }
}
