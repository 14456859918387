import { useMutation, UseMutationResult, useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { getRoles } from "../../../data/services/users/roles-service";
import { getUserDetails, updateUserDetails } from "../../../data/services/users/users-service";
import { RoleDto, toRoleDtos } from "../../dtos/users/role-dto";
import { EditUserDetailsDto, toEditUserDetailsDto } from "../../dtos/users/user-details-dto";
import { createPaginationRequest } from "../../requests/common/pagination-request";
import { Response } from "../../responses/common/response-response";
import { UserDetailsResponse } from "../../responses/users/user-details-response";

interface UpdateDetailsParameters {
    userId: number;
    formData: FormData;
    roles: RoleDto[];
}

export const useGetUserDetails = (
    userId: number
): [
    UseQueryResult<RoleDto[], HTTPError>,
    UseQueryResult<Response<UserDetailsResponse>, HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const detailQueries = useQueries({
        queries: [
            {
                queryKey: ["getRoles"],
                queryFn: () => getRoles(url.baseUrl, createPaginationRequest(1, 1000)),
                select: toRoleDtos,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getUserDetails", userId],
                queryFn: () => getUserDetails(url.baseUrl, userId),
                onError: errorResponseToDisplayHandler,
            },
        ],
    });

    return detailQueries as [
        UseQueryResult<RoleDto[], HTTPError>,
        UseQueryResult<Response<UserDetailsResponse>, HTTPError>,
    ];
};

export const transformToEditUserDto = (
    userDetailsResponse: UserDetailsResponse,
    allRoles: RoleDto[]
): EditUserDetailsDto => toEditUserDetailsDto(userDetailsResponse, allRoles);

export const useUpdateUserDetails = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    UpdateDetailsParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: UpdateDetailsParameters) => {
        const formData = mutationKey.formData;

        const request = {
            userId: mutationKey.userId,
            emailAddress: formData.get("emailAddress")! as string,
            firstName: formData.get("firstName")! as string,
            lastName: formData.get("lastName")! as string,
            roles: mutationKey.roles
                .filter((x) => formData.get(x.name) === "on")
                .map((y) => y.roleId),
        };

        return updateUserDetails(url.baseUrl, request);
    });
};
