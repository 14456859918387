import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ChecklistQuestions } from "../../../core/constants/translation-namespace";
import {
    ContentContainer,
    EndAlignedDiv,
    SectionContainer,
} from "../../../core/theme/global-styles";
import { ResetButton, SearchButton } from "../../atoms/SbButton";
import { FilterInput } from "../../molecules/FilterInput";

export const ReferentialLinkTypesFilter: React.FC<{
    referentialLinkTypeName: string | null;
    changeReferentialLinkTypeName: (nonWorkingDaySetName: string) => void;
    referentialLinkValue: string | null;
    changeReferentialLinkValue: (nonWorkingDayName: string) => void;
    search: () => void;
    resetFilter: () => void;
}> = ({
    referentialLinkTypeName,
    changeReferentialLinkTypeName,
    referentialLinkValue,
    changeReferentialLinkValue,
    search,
    resetFilter,
}) => {
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });
    return (
        <ContentContainer>
            <SectionContainer>
                <Row>
                    <Col>
                        <FilterInput
                            title={t("Name")}
                            name={"Name"}
                            type={"text"}
                            onChangeEventHandler={changeReferentialLinkTypeName}
                            value={referentialLinkTypeName ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("ReferentialLink")}
                            name={"referentialLink"}
                            type={"text"}
                            onChangeEventHandler={changeReferentialLinkValue}
                            value={referentialLinkValue ?? ""}
                        />
                    </Col>
                </Row>
            </SectionContainer>
            <EndAlignedDiv>
                <SearchButton onClick={search} />
                <ResetButton onClick={resetFilter} />
            </EndAlignedDiv>
        </ContentContainer>
    );
};
