export class OrderNestedQuestionsRequest {
    parentQuestionId: number;
    questions: QuestionsOrderRequest[];

    public constructor(parentQuestionId: number, questions: QuestionsOrderRequest[]) {
        this.parentQuestionId = parentQuestionId;
        this.questions = questions;
    }
}

export class QuestionsOrderRequest {
    questionId: number;
    orderIndex: number;

    public constructor(questionId: number, orderIndex: number) {
        this.questionId = questionId;
        this.orderIndex = orderIndex;
    }
}
