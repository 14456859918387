import { EditReviewReportSetDto } from "../../dtos/review-reports/edit-review-report-set-dto";
import ReviewRating from "../../enums/review-reports/review-ratings";
import { EditReviewReportSetTypeFieldsValueRequest } from "./edit-review-report-set-type-field-value-request";

export class EditReviewReportSetRequest {
    editReviewReportSetFieldsValueRequests: EditReviewReportSetTypeFieldsValueRequest[];
    reviewReportSetId: number;
    reviewRating: ReviewRating;

    public constructor(dto: EditReviewReportSetDto) {
        this.editReviewReportSetFieldsValueRequests = dto.editReviewReportSetFieldsValueDtos;
        this.reviewReportSetId = dto.reviewReportSetId;
        this.reviewRating = dto.reviewRating;
    }
}
