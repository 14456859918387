export interface QuestionSetQuestionsOrderRequest {
    questionSetId: number;
    questions: QuestionsOrderRequest[];
}

export const createQuestionSetQuestionsOrderRequest = (
    questionSetId: number,
    questions: QuestionsOrderRequest[]
): QuestionSetQuestionsOrderRequest => ({
    questionSetId: questionSetId,
    questions: questions,
});

export interface QuestionsOrderRequest {
    questionId: number;
    orderIndex: number;
}

export const createQuestionsOrderRequest = (
    questionId: number,
    orderIndex: number
): QuestionsOrderRequest => ({
    questionId: questionId,
    orderIndex: orderIndex,
});
