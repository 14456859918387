import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { getChecklistDetails } from "../../../data/services/checklists/checklist-service";
import { ChecklistDto, createChecklistDto } from "../../dtos/checklists/checklist-dto";

export const useGetChecklist = (checklistId: number): UseQueryResult<ChecklistDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getChecklist", checklistId],
        () => getChecklistDetails(url.baseUrl, checklistId),
        { select: createChecklistDto, onError: errorResponseToDisplayHandler }
    );
};
