import { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { AccordionLinks, ActionItems, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { actionItemDynamicFieldTextsColumns } from "../../../core/utilities/dataTableColumns";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    isMutationLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import { defaultPaginationDto, PaginationDto } from "../../../domain/dtos/common/pagination-dto";
import { LanguageDto } from "../../../domain/dtos/language/language-dto";
import { Response } from "../../../domain/responses/common/response-response";
import {
    createCreateParams,
    useCreateActionItemDynamicFieldText,
    useGetActionItemDynamicFieldTextData,
} from "../../../domain/viewmodels/action-items/create-action-item-dynamic-field-text-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";
import { DataTable } from "../../organisms/DataTable";

const CreateActionItemDynamicFieldTextContainer: React.FC = () => {
    const [filterDynamicTextsPaginationDto, setFilterDynamicTextsPaginationDto] =
        useState<PaginationDto>(defaultPaginationDto);
    const languageId = useRef<number | null>(null);

    const [urlSearchParams] = useSearchParams();
    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const actionItemDynamicFieldId = Number(urlSearchParams.get("actionItemDynamicFieldId"));

    const getActionItemDynamicFieldTextData = useGetActionItemDynamicFieldTextData(
        actionItemDynamicFieldId,
        filterDynamicTextsPaginationDto
    );
    const createActionItemDynamicFieldText = useCreateActionItemDynamicFieldText();

    const filterActionItemDynamicFieldTextsResponse = getActionItemDynamicFieldTextData[0];
    const filterActionItemDynamicFieldTextsResponseData =
        filterActionItemDynamicFieldTextsResponse.data;
    const languagesResponse = getActionItemDynamicFieldTextData[1];
    const languagesResponseData = languagesResponse.data;

    const { t } = useTranslation("translation", { keyPrefix: ActionItems });

    useLoader(
        areQueriesLoading(getActionItemDynamicFieldTextData) ||
            isMutationLoading(createActionItemDynamicFieldText),
        CreateActionItemDynamicFieldTextContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.ActionTracker);
        menu.changeActiveDrawerItem(DrawerTitles.ActionItemDynamicFields);
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        createActionItemDynamicFieldText.mutate(
            createCreateParams(
                actionItemDynamicFieldId,
                languageId.current!,
                new FormData(event.currentTarget)
            ),
            {
                onSuccess: async (_: Response<number | null>) => {
                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "messageKey",
                            "AddAlternativeDynamicFieldTextSuccessMessage"
                        ),
                    ];

                    navigateSearch(
                        `${getPath(
                            DrawerTitles.ActionItemDynamicFields
                        )}/${actionItemDynamicFieldId}`,
                        params
                    );
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    return (
        <>
            <PageHeading>{t("EditActionItemDynamicFieldTexts")}</PageHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(filterActionItemDynamicFieldTextsResponse) && (
                <TextTitledPanel
                    title={t("ActionItemDynamicFieldTexts", { keyPrefix: AccordionLinks })}
                >
                    <DataTable
                        keyPrefix={ActionItems}
                        columns={actionItemDynamicFieldTextsColumns}
                        rows={filterActionItemDynamicFieldTextsResponseData!.rows}
                        totalItems={filterActionItemDynamicFieldTextsResponseData!.recordCount}
                        paginationDto={filterDynamicTextsPaginationDto}
                        setPaginationDto={setFilterDynamicTextsPaginationDto}
                    />
                </TextTitledPanel>
            )}

            {isQuerySuccessful(languagesResponse) && (
                <>
                    <LargeVerticalSpace />
                    <TextTitledPanel title={t("AddAlternativeDynamicFieldText")}>
                        <Form onSubmit={handleSubmit}>
                            <SbFormSelectFieldGroup
                                name={"language"}
                                label={t("Language")}
                                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                                searchable
                                clearable={false}
                                required
                                items={languagesResponseData!}
                                itemDisplayText={(option: LanguageDto) => option.name} //TODO: Add translations for dynamic data
                                onChange={(option: SingleValue<LanguageDto>) => {
                                    languageId.current = option!.languageId;
                                }}
                            />

                            <SbFormTextFieldGroup
                                name={"alternativeFieldName"}
                                maxLength={100}
                                label={t("AlternativeFieldName")}
                                type={"text"}
                                required
                            />

                            <EndAlignedDiv>
                                <SaveButton type="submit" />
                                <CancelButton onClick={() => navigate(-1)} />
                            </EndAlignedDiv>
                        </Form>
                    </TextTitledPanel>
                </>
            )}
        </>
    );
};

export default CreateActionItemDynamicFieldTextContainer;
