import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Roles, Users } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isMutationLoading,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { EditUserDetailsDto } from "../../../domain/dtos/users/user-details-dto";
import { hasRoleTypeInGroup, Role } from "../../../domain/enums/Roles";
import { splitCamelCase } from "../../../domain/helpers/split-camel-case/spilt-camel-case";
import {
    transformToEditUserDto,
    useGetUserDetails,
    useUpdateUserDetails,
} from "../../../domain/viewmodels/users/edit-user-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import SbFormCheckFieldGroup from "../../molecules/input/SbFormCheckFieldGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const EditUserContainer: React.FC = () => {
    const userId = Number(useParams().userId);

    const [editUserDto, setEditUserDto] = useState<EditUserDetailsDto | null>(null);

    const menu = useMenu();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: Users });
    const auth = useAuth();

    const userDetails = useGetUserDetails(userId);
    const updateUserDetails = useUpdateUserDetails();

    useLoader(
        areQueriesLoading(userDetails) || isMutationLoading(updateUserDetails),
        EditUserContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.UserManagement, AccordionTitles.Users);
    }, []);

    useEffect(() => {
        if (areQueriesSuccessful(userDetails)) {
            setEditUserDto(transformToEditUserDto(userDetails[1].data!.data, userDetails[0].data!));
        }
    }, [userDetails[0].fetchStatus, userDetails[1].fetchStatus]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        updateUserDetails.mutate(
            {
                userId: userId,
                formData: new FormData(event.currentTarget),
                roles: userDetails[0].data!,
            },
            {
                onSuccess: async () => {
                    navigate(-1);
                    queryClient.invalidateQueries(["getRoles", "getUserDetails"]);
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    return (
        <>
            <PageHeading>{t("UserEditTitle")}</PageHeading>
            <SectionVerticalSpace />

            {editUserDto && (
                <TextTitledPanel title={t("UserEditTitle")}>
                    <Form onSubmit={handleSubmit}>
                        <SbFormTextFieldGroup
                            name="emailAddress"
                            maxLength={100}
                            label={t("EmailAddress")}
                            type="text"
                            required
                            defaultValue={editUserDto!.emailAddress}
                        />

                        <SbFormTextFieldGroup
                            name="firstName"
                            label={t("FirstName")}
                            minLength={2}
                            maxLength={50}
                            type="text"
                            required
                            defaultValue={editUserDto!.firstName}
                        />

                        <SbFormTextFieldGroup
                            name="lastName"
                            label={t("LastName")}
                            type="text"
                            minLength={2}
                            maxLength={50}
                            required
                            defaultValue={editUserDto!.lastName}
                        />

                        {/* TODO: Dropdown */}

                        {hasRoleTypeInGroup(auth.userRoles, [Role.SystemAdmin]) && (
                            <SbFormCheckFieldGroup
                                fieldLabel={t("Roles", { keyPrefix: Roles })}
                                type="checkbox"
                                values={
                                    editUserDto!.roles.map((x) => ({
                                        name: x.name,
                                        label: splitCamelCase(x.name),
                                        defaultSelected: x.isActive,
                                    })) ?? []
                                }
                            />
                        )}

                        <EndAlignedDiv>
                            <SaveButton type="submit" />
                            <CancelButton onClick={() => navigate(-1)} />
                        </EndAlignedDiv>
                    </Form>
                </TextTitledPanel>
            )}
        </>
    );
};

export default EditUserContainer;
