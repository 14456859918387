import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { softDeleteChecklist } from "../../../data/services/checklists/checklist-service";
import { Response } from "../../responses/common/response-response";

export const useSoftDeleteChecklist = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    number
> => {
    const url = useUrl();

    return useMutation((checklistId: number) => softDeleteChecklist(url.baseUrl, checklistId));
};
