import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { truncateString } from "../../../core/utilities/string-helper";
import { ActionInstanceStatus } from "../../enums/action-items/ActionInstanceStatus";
import RaciRelationship from "../../enums/action-items/RaciRelationship";
import BaseActionItemEventResponse from "../../responses/action-items/base-action-item-event-response";

export default class BaseActionItemEventDto {
    title: string;
    startDateUtc: Date;
    endDateUtc: Date;
    startDateLocalString: string;
    endDateLocalString: string;
    status: ActionInstanceStatus;
    statusString: string;
    type: string;
    raciParticipation: string;
    raciParticipationShort: string;

    public constructor(response: BaseActionItemEventResponse) {
        const { title, startDateUtc, endDateUtc, status, type, raciParticipation } = response;

        this.title = title;
        this.startDateUtc = startDateUtc;
        this.endDateUtc = endDateUtc;

        this.startDateLocalString = toDateAndTimeFormat(startDateUtc.toString());
        this.endDateLocalString = toDateAndTimeFormat(endDateUtc.toString());
        this.status = status;
        this.statusString = ActionInstanceStatus[status];
        this.type = type;

        let raciParticipations = raciParticipation.map((x) => RaciRelationship[x]);
        this.raciParticipation = raciParticipations.join(", ");
        this.raciParticipationShort = raciParticipations
            .map((x) => truncateString(x, 1))
            .join(", ");
    }
}
