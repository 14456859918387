import {
    useMutation,
    UseMutationResult,
    useQueries,
    useQuery,
    UseQueryResult,
} from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { NestedDetails, TableRow } from "../../../core/utilities/customTypes";
import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import {
    downloadAnswerFile,
    filterQuestionSetInstanceAnswerFiles,
    softDeleteAnswerFile,
    uploadAnswerFiles,
} from "../../../data/services/question-set-instance-answers/question-set-instance-answer-files-service";
import {
    addQuestionSetQuestionComment,
    associateAgreedActionRaciUser,
    createAgreedAction,
    dissociateAgreedActionRaciUser,
    editQuestionSetInstanceReviewRating,
    editReviewReportDetails,
    filterQuestionSetInstanceAnswerDetails,
    filterQuestionSetInstanceReviews,
    getQuestionSetInstanceAnswerCommentByQuestionSetInstanceAnswerId,
    getQuestionSetInstanceAnswerDetails,
    getQuestionSetInstanceDetails,
    getQuestionSetInstanceReviewByQuestionSetInstanceAnswerId,
    getQuestionSetInstanceReviews,
    notifyAll,
    removeAgreedAction,
    removeReviewFinding,
    resolveAgreedAction,
    resolveReviewFinding,
    submitAndPublishItems,
    validateQuestionSetInstanceAnswerDetails,
} from "../../../data/services/review-reports/review-reports-service";
import { filterUsers } from "../../../data/services/users/users-service";
import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { FileDownloadDto } from "../../dtos/file-storage/file-download-dto";
import QuestionAnswerFileUploadDto from "../../dtos/question-set-instance-answers/question-answer-file-upload-dto";
import QuestionSetInstanceAnswerFileDto from "../../dtos/question-set-instance-answers/question-set-instance-answer-file-dto";
import { AddQuestionSetQuestionCommentDto } from "../../dtos/review-reports/add-question-set-question-comment-dto";
import { AnswerDetailsDto } from "../../dtos/review-reports/answer-details-dto";
import { AssociateDissociateAgreedActionRaciUserDto } from "../../dtos/review-reports/associate-agreed-action-raci-user-dto";
import { EditQuestionSetInstanceReviewRatingDto } from "../../dtos/review-reports/edit-question-set-instance-review-rating-dto";
import { EditReviewReportDetailsDto } from "../../dtos/review-reports/edit-review-report-details-dto";
import { QuestionSetInstanceAnswerCommentDto } from "../../dtos/review-reports/question-set-instance-answer-comment-dto";
import { QuestionSetInstanceDto } from "../../dtos/review-reports/question-set-instance-dto";
import { QuestionSetInstanceReviewChecklistDto } from "../../dtos/review-reports/question-set-instance-review-checklist-dto";
import { QuestionSetInstanceReviewDto } from "../../dtos/review-reports/question-set-instance-review-dto";
import { RemoveResolveAgreedActionDto } from "../../dtos/review-reports/remove-resolve-agreed-action-dto";
import { RemoveResolveReviewFindingDto } from "../../dtos/review-reports/remove-review-finding-dto";
import {
    BaseUserDto,
    toBaseUserDtosFromPaginatedUserResponse,
} from "../../dtos/users/base-user-dto";
import InstanceStatus from "../../enums/questions/instance-status";
import { ReviewTypeFields } from "../../enums/review-reports/ReviewTypeFields";
import QuestionAnswerFileUploadRequest from "../../requests/answer-capture/question-answer-file-upload-request";
import { createPaginationRequest } from "../../requests/common/pagination-request";
import { createFilterQuestionSetInstanceAnswerFileRequest } from "../../requests/question-set-instance-answers/filter-question-set-instance-answer-file-request";
import { AddQuestionSetQuestionCommentRequest } from "../../requests/review-reports/add-question-set-question-comment-request";
import { AssociateDissociateAgreedActionRaciUserRequest } from "../../requests/review-reports/associate-agreed-action-raci-user-request";
import { EditQuestionSetInstanceReviewRatingRequest } from "../../requests/review-reports/edit-question-set-instance-review-rating-request";
import { EditReviewReportDetailsRequest } from "../../requests/review-reports/edit-review-report-details-request";
import { FilterQuestionSetInstanceAnswerDetailsRequest } from "../../requests/review-reports/filter-question-set-instance-answer-details-request";
import { FilterQuestionSetInstanceReviewsRequest } from "../../requests/review-reports/filter-question-set-instance-reviews-request";
import { RemoveResolveAgreedActionRequest } from "../../requests/review-reports/remove-resolve-agreed-action-request";
import { RemoveResolveReviewFindingRequest } from "../../requests/review-reports/remove-review-finding-request";
import { createFilterUsersSearchRequest } from "../../requests/users/filter-users-request";
import { AnswerDetailsResponse } from "../../responses/answer-summary/answer-details-response";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import QuestionSetInstanceAnswerFileResponse from "../../responses/question-set-instance-answers/question-set-instance-answer-file-response";

export const useGetQuestionSetInstanceDetails = (
    questionSetInstanceId: number,
    answerDetailsBasePaginationDto: BasePaginationDto,
    reviewReportSetId: number
): [
    UseQueryResult<QuestionSetInstanceDto, HTTPError>,
    UseQueryResult<PaginatedTableDto<number>, HTTPError>,
    UseQueryResult<QuestionSetInstanceReviewDto[], HTTPError>,
    UseQueryResult<QuestionSetInstanceReviewChecklistDto[], HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const queries = useQueries({
        queries: [
            {
                queryKey: ["getQuestionSetInstanceDetails", questionSetInstanceId],
                queryFn: () => getQuestionSetInstanceDetails(url.baseUrl, questionSetInstanceId),
                select: QuestionSetInstanceDto.constructFromResponse,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: [
                    "filterQuestionSetInstanceAnswerDetails",
                    questionSetInstanceId,
                    answerDetailsBasePaginationDto,
                ],
                queryFn: () =>
                    filterQuestionSetInstanceAnswerDetails(
                        url.baseUrl,
                        new FilterQuestionSetInstanceAnswerDetailsRequest(
                            questionSetInstanceId,
                            answerDetailsBasePaginationDto
                        )
                    ),
                select: transformToDataTableRows,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getQuestionSetInstanceReviews", questionSetInstanceId],
                queryFn: () => getQuestionSetInstanceReviews(url.baseUrl, questionSetInstanceId),
                select: QuestionSetInstanceReviewDto.fromResponseToQuestionSetInstanceReviewDtos,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["filterQuestionSetInstanceReviews", reviewReportSetId],
                queryFn: () =>
                    filterQuestionSetInstanceReviews(
                        url.baseUrl,
                        new FilterQuestionSetInstanceReviewsRequest(
                            reviewReportSetId,
                            createPaginationRequest(1, 50)
                        )
                    ),
                select: QuestionSetInstanceReviewChecklistDto.constructFromResponse,
                onError: errorResponseToDisplayHandler,
            },
        ],
    });

    return queries as [
        UseQueryResult<QuestionSetInstanceDto, HTTPError>,
        UseQueryResult<PaginatedTableDto<number>, HTTPError>,
        UseQueryResult<QuestionSetInstanceReviewDto[], HTTPError>,
        UseQueryResult<QuestionSetInstanceReviewChecklistDto[], HTTPError>,
    ];
};

export const useGetQuestionSetInstanceAnswerDetails = (
    questionSetInstanceAnswerId: number
): UseQueryResult<AnswerDetailsDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getQuestionSetInstanceAnswerDetails", questionSetInstanceAnswerId],
        () => getQuestionSetInstanceAnswerDetails(url.baseUrl, questionSetInstanceAnswerId),
        {
            select: AnswerDetailsDto.constructFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useGetSingleQuestionSetInstanceDetails = (
    questionSetInstanceId: number | null
): UseQueryResult<QuestionSetInstanceDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getQuestionSetInstanceDetails", questionSetInstanceId],
        () => getQuestionSetInstanceDetails(url.baseUrl, questionSetInstanceId!),
        {
            select: QuestionSetInstanceDto.constructFromResponse,
            onError: errorResponseToDisplayHandler,
            enabled: questionSetInstanceId != null,
        }
    );
};

export const useGetFilterUserDetails = (
    userBasePaginationDto: BasePaginationDto,
    searchText: string | null
): UseQueryResult<BaseUserDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterUsers", userBasePaginationDto, searchText],
        () =>
            filterUsers(
                url.baseUrl,
                createFilterUsersSearchRequest(
                    searchText,
                    userBasePaginationDto.pageNumber,
                    userBasePaginationDto.pageSize
                )
            ),
        {
            select: toBaseUserDtosFromPaginatedUserResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useGetQuestionSetInstanceReviewDetails = (
    questionSetInstanceAnswerId: number
): UseQueryResult<QuestionSetInstanceReviewDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getQuestionSetInstanceReviewByQuestionSetInstanceAnswerId", questionSetInstanceAnswerId],
        () =>
            getQuestionSetInstanceReviewByQuestionSetInstanceAnswerId(
                url.baseUrl,
                questionSetInstanceAnswerId
            ),
        {
            select: QuestionSetInstanceReviewDto.constructFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useGetQuestionSetInstanceAnswerComment = (
    questionSetInstanceAnswerId: number
): UseQueryResult<QuestionSetInstanceAnswerCommentDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        [
            "getQuestionSetInstanceAnswerCommentByQuestionSetInstanceAnswerId",
            questionSetInstanceAnswerId,
        ],
        () =>
            getQuestionSetInstanceAnswerCommentByQuestionSetInstanceAnswerId(
                url.baseUrl,
                questionSetInstanceAnswerId
            ),
        {
            select: QuestionSetInstanceAnswerCommentDto.constructFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useAssociateAgreedActionRaciUser = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    AssociateDissociateAgreedActionRaciUserDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: AssociateDissociateAgreedActionRaciUserDto) => {
        return associateAgreedActionRaciUser(
            url.baseUrl,
            new AssociateDissociateAgreedActionRaciUserRequest(mutationKey)
        );
    });
};

export const useDissociateAgreedActionRaciUser = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    AssociateDissociateAgreedActionRaciUserDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: AssociateDissociateAgreedActionRaciUserDto) => {
        return dissociateAgreedActionRaciUser(
            url.baseUrl,
            new AssociateDissociateAgreedActionRaciUserRequest(mutationKey)
        );
    });
};

export const useAddQuestionSetQuestionComment = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    AddQuestionSetQuestionCommentDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: AddQuestionSetQuestionCommentDto) => {
        return addQuestionSetQuestionComment(
            url.baseUrl,
            new AddQuestionSetQuestionCommentRequest(mutationKey)
        );
    });
};

export const useEditReviewReportDetails = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    EditReviewReportDetailsDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: EditReviewReportDetailsDto) => {
        return editReviewReportDetails(
            url.baseUrl,
            new EditReviewReportDetailsRequest(mutationKey)
        );
    });
};

export const useCreateAgreedAction = (): UseMutationResult<Response<number>, HTTPError, number> => {
    const url = useUrl();

    return useMutation((questionSetInstanceReviewId: number) => {
        return createAgreedAction(url.baseUrl, questionSetInstanceReviewId);
    });
};

export const useRemoveAgreedAction = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    RemoveResolveAgreedActionDto
> => {
    const url = useUrl();

    return useMutation((dto: RemoveResolveAgreedActionDto) => {
        return removeAgreedAction(url.baseUrl, new RemoveResolveAgreedActionRequest(dto));
    });
};

export const useResolveAgreedAction = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    RemoveResolveAgreedActionDto
> => {
    const url = useUrl();

    return useMutation((dto: RemoveResolveAgreedActionDto) => {
        return resolveAgreedAction(url.baseUrl, new RemoveResolveAgreedActionRequest(dto));
    });
};

export const useRemoveReviewFinding = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    RemoveResolveReviewFindingDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: RemoveResolveReviewFindingDto) => {
        return removeReviewFinding(url.baseUrl, new RemoveResolveReviewFindingRequest(mutationKey));
    });
};

export const useResolveReviewFinding = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    RemoveResolveReviewFindingDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: RemoveResolveReviewFindingDto) => {
        return resolveReviewFinding(
            url.baseUrl,
            new RemoveResolveReviewFindingRequest(mutationKey)
        );
    });
};

export const useSubmitAndPublishItems = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    number
> => {
    const url = useUrl();

    return useMutation((mutationKey: number) => {
        return submitAndPublishItems(url.baseUrl, mutationKey);
    });
};

export const useNotifyAll = (): UseMutationResult<Response<boolean>, HTTPError, number> => {
    const url = useUrl();

    return useMutation((mutationKey: number) => {
        return notifyAll(url.baseUrl, mutationKey);
    });
};

export const useEditQuestionSetInstanceReviewRating = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    EditQuestionSetInstanceReviewRatingDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: EditQuestionSetInstanceReviewRatingDto) => {
        return editQuestionSetInstanceReviewRating(
            url.baseUrl,
            new EditQuestionSetInstanceReviewRatingRequest(mutationKey)
        );
    });
};

export const useFilterQuestionSetInstanceAnswerFiles = (
    questionSetInstanceAnswerId: number,
    basePaginationDto: BasePaginationDto
): UseQueryResult<PaginatedTableDto<QuestionSetInstanceAnswerFileDto>, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterQuestionSetInstanceAnswerFiles", questionSetInstanceAnswerId],
        () =>
            filterQuestionSetInstanceAnswerFiles(
                url.baseUrl,
                createFilterQuestionSetInstanceAnswerFileRequest(
                    questionSetInstanceAnswerId,
                    basePaginationDto
                )
            ),
        {
            keepPreviousData: true,
            select: transformToQuestionSetInstanceAnswerFileDataTableRows,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useValidateQuestionSetInstanceAnswerDetails = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    number
> => {
    const url = useUrl();

    return useMutation((questionSetInstanceId: number) => {
        return validateQuestionSetInstanceAnswerDetails(url.baseUrl, questionSetInstanceId);
    });
};

const transformToDataTableRows = (
    response: Response<PaginationResponse<AnswerDetailsResponse>>
): PaginatedTableDto<number> => {
    const rows = response.data!.results.map((answerDetails) => {
        return {
            metadata: answerDetails.questionSetInstanceAnswerId,
            secondaryMetadata: answerDetails.questionSetInstanceReviewId,
            nestedRows:
                answerDetails.childAnswerDetails && answerDetails.childAnswerDetails.length > 0
                    ? transformToNestedDataTableRows(answerDetails.childAnswerDetails)
                    : undefined,
            nestedDetails: toNestedFormDetails(answerDetails),
            columns: [
                {
                    //Checklist
                    value: answerDetails.checklistName?.toString(),
                    type: DataTableColumnTypes.Text,
                },
                {
                    //Reviewer
                    value: answerDetails.userFullName?.toString(),
                    type: DataTableColumnTypes.Text,
                },
                {
                    //Test objective
                    value: answerDetails.questionText?.toString(),
                    type: DataTableColumnTypes.Text,
                },
                {
                    //Answer
                    value: answerDetails.answerValue?.toString(),
                    type: DataTableColumnTypes.Text,
                },
                {
                    //Nested Answers
                    value:
                        answerDetails.childAnswerDetails &&
                        answerDetails.childAnswerDetails.length > 0
                            ? "Nested Answers"
                            : "None",
                    type: DataTableColumnTypes.DisplayNestedTableButton,
                },
            ],
        };
    });

    return {
        numberOfPages: response.data.pageCount,
        pageSize: response.data.pageSize,
        currentPage: response.data.currentPage,
        recordCount: response.data.recordCount,
        rows: rows,
    };
};

const toNestedFormDetails = (answerDetails: AnswerDetailsResponse): NestedDetails[] => {
    let nestedDetails: NestedDetails[] = [];

    answerDetails.reviewTypeFieldsValues.map((x) => {
        nestedDetails.push({
            id: x.reviewTypeFieldsId,
            value: x.value,
            label: x.reviewTypeField.fieldName,
            inputType: x.reviewTypeField.inputType,
        });
    });

    nestedDetails.push({
        value: answerDetails.comment?.toString(),
        label: ReviewTypeFields.Finding,
        inputType:
            answerDetails.answerValue?.toString() === ReviewTypeFields.Finding
                ? "textarea"
                : undefined,
    });

    return nestedDetails;
};

const transformToNestedDataTableRows = (response: AnswerDetailsResponse[]): TableRow<number>[] => {
    const rows = response!.map((answerDetails) => {
        return {
            metadata: answerDetails.questionSetInstanceAnswerId,
            columns: [
                {
                    //Checklist
                    value: answerDetails.checklistName?.toString(),
                    type: DataTableColumnTypes.Text,
                },
                {
                    //Reviewer
                    value: answerDetails.userFullName?.toString(),
                    type: DataTableColumnTypes.Text,
                },
                {
                    //Test objective
                    value: answerDetails.questionText?.toString(),
                    type: DataTableColumnTypes.Text,
                },
                {
                    //Answer
                    value: answerDetails.answerValue?.toString(),
                    type: DataTableColumnTypes.Text,
                },
                {
                    //Finding
                    value: answerDetails.comment?.toString(),
                    type: DataTableColumnTypes.Text,
                },
                {
                    //Nested Answers
                    value:
                        answerDetails.childAnswerDetails &&
                        answerDetails.childAnswerDetails.length > 0
                            ? "Yes"
                            : "None",
                    type: DataTableColumnTypes.DisplayNestedTableButton,
                },
                {
                    //Attached evidence
                    value:
                        answerDetails.attachedEvidence && answerDetails.attachedEvidence.length > 0
                            ? "Yes"
                            : "None",
                    type: DataTableColumnTypes.Text,
                },
                {
                    //Open DateTime
                    value: toDateAndTimeFormat(answerDetails.openDateTimeUtc.toString()),
                    type: DataTableColumnTypes.Text,
                },
                {
                    //Action item due date
                    value: toDateAndTimeFormat(answerDetails.dueDateTimeUtc.toString()),
                    type: DataTableColumnTypes.Text,
                },
                {
                    //Answer Status
                    value: InstanceStatus[answerDetails.instanceStatus],
                    type: DataTableColumnTypes.Text,
                },
            ],
        };
    });

    return rows;
};

const transformToQuestionSetInstanceAnswerFileDataTableRows = (
    response: Response<PaginationResponse<QuestionSetInstanceAnswerFileResponse>>
): PaginatedTableDto<QuestionSetInstanceAnswerFileDto> => {
    const rows = response.data.results!.map((response: QuestionSetInstanceAnswerFileResponse) => {
        return {
            metadata: new QuestionSetInstanceAnswerFileDto(response),
            showDownloadFileAction: response.questionSetInstanceAnswerFileId !== null,
            showDeleteAction: response.questionSetInstanceAnswerFileId !== null,
            columns: [
                {
                    value: response.fileName,
                    type: DataTableColumnTypes.Text,
                },
                {
                    value: response.dateUploadedUtc
                        ? toDateAndTimeFormat(response.dateUploadedUtc.toString())
                        : "",
                    type: DataTableColumnTypes.Text,
                },
            ],
        };
    });

    return {
        numberOfPages: response.data.pageCount,
        pageSize: response.data.pageSize,
        currentPage: response.data.currentPage,
        recordCount: response.data.recordCount,
        rows: rows,
    };
};

export const useUploadQuestionSetInstanceAnswerFiles = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    QuestionAnswerFileUploadDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: QuestionAnswerFileUploadDto) => {
        const request =
            QuestionAnswerFileUploadRequest.constructFromQuestionAnswerFileUploadDto(mutationKey);

        return uploadAnswerFiles(url.baseUrl, request);
    });
};

export const useDownloadQuestionSetInstanceAnswerFile = (
    questionSetInstanceAnswerFileId: number | null
): UseQueryResult<FileDownloadDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["downloadAnswerFile", questionSetInstanceAnswerFileId],
        () => downloadAnswerFile(url.baseUrl, questionSetInstanceAnswerFileId!),
        {
            enabled: questionSetInstanceAnswerFileId != null,
            select: FileDownloadDto.constructFromFileDetailsResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useSoftDeleteQuestionSetInstanceAnswerFile = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    number
> => {
    const url = useUrl();

    return useMutation((questionSetInstanceAnswerFileId: number) =>
        softDeleteAnswerFile(url.baseUrl, questionSetInstanceAnswerFileId)
    );
};
