import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { createAnswerType } from "../../../data/services/answer-types/answer-types-service";
import { CreateAnswerTypeDto } from "../../dtos/answer-types/create-answer-type-dto";
import { createCreateAnswerTypeRequest } from "../../requests/answer-types/create-answer-type-request";
import { Response } from "../../responses/common/response-response";

export const useCreateAnswerType = (): UseMutationResult<
    Response<number | null>,
    HTTPError,
    CreateAnswerTypeDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: CreateAnswerTypeDto) => {
        const { formData, answerCategoryId, renderModeId, flagModeId, valueTypeId } = mutationKey;

        const request = createCreateAnswerTypeRequest(
            formData.get("name") as string,
            answerCategoryId,
            renderModeId,
            flagModeId,
            valueTypeId
        );

        return createAnswerType(url.baseUrl, request);
    });
};
