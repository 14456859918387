import { useMutation, UseMutationResult, useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import {
    createActionItemDynamicFieldText,
    filterActionItemDynamicFieldTexts,
} from "../../../data/services/action-items/action-item-dynamic-field-texts-service";
import { getLanguages } from "../../../data/services/language/language-service";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { PaginationDto } from "../../dtos/common/pagination-dto";
import { LanguageDto, toLanguageDtos } from "../../dtos/language/language-dto";
import { createCreateActionItemDynamicFieldTextRequest } from "../../requests/action-items/create-action-item-dynamic-field-text-request";
import { createFilterActionItemDynamicFieldTextsRequest } from "../../requests/action-items/filter-action-item-dynamic-field-texts-request";
import { ActionItemDynamicFieldTextResponse } from "../../responses/action-items/action-item-dynamic-field-text-response";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";

export const useGetActionItemDynamicFieldTextData = (
    actionItemDynamicFieldId: number,
    filterDynamicTextsPaginationDto: PaginationDto
): [
    UseQueryResult<PaginatedTableDto<null>, HTTPError>,
    UseQueryResult<LanguageDto[], HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: [
                    "filterActionItemDynamicFieldTexts",
                    actionItemDynamicFieldId,
                    filterDynamicTextsPaginationDto,
                ],
                queryFn: () =>
                    filterActionItemDynamicFieldTexts(
                        url.baseUrl,
                        createFilterActionItemDynamicFieldTextsRequest(
                            actionItemDynamicFieldId,
                            filterDynamicTextsPaginationDto
                        )
                    ),
                keepPreviousData: true,
                select: toDataTableRows,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getLanguages"],
                queryFn: () => getLanguages(url.baseUrl),
                select: toLanguageDtos,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<PaginatedTableDto<null>, HTTPError>,
        UseQueryResult<LanguageDto[], HTTPError>,
    ];
};

interface CreateParams {
    actionItemDynamicFieldId: number;
    languageId: number;
    formData: FormData;
}

export const createCreateParams = (
    actionItemDynamicFieldId: number,
    languageId: number,
    formData: FormData
): CreateParams => ({
    actionItemDynamicFieldId: actionItemDynamicFieldId,
    languageId: languageId,
    formData: formData,
});

export const useCreateActionItemDynamicFieldText = (): UseMutationResult<
    Response<number | null>,
    HTTPError,
    CreateParams
> => {
    const url = useUrl();

    return useMutation((mutationKey: CreateParams) => {
        const { actionItemDynamicFieldId, languageId, formData } = mutationKey;

        const request = createCreateActionItemDynamicFieldTextRequest(
            actionItemDynamicFieldId,
            languageId,
            formData.get("alternativeFieldName") as string
        );

        return createActionItemDynamicFieldText(url.baseUrl, request);
    });
};

const toDataTableRows = (
    response: Response<PaginationResponse<ActionItemDynamicFieldTextResponse>>
): PaginatedTableDto<null> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((x) => ({
        columns: [
            {
                value: x.languageResponse.name,
                type: DataTableColumnTypes.Text,
            },
            {
                value: x.text,
                type: DataTableColumnTypes.Text,
            },
            {
                value: x.languageResponse.isDefault?.toString(),
                type: DataTableColumnTypes.Check,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
