import { ReactElement, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { ActionItems, Common } from "../../../core/constants/translation-namespace";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import { TriggeringDate } from "../../../domain/enums/notification-triggers/triggering-date";
import { hasRoleTypeInGroup, NotificationTriggerRoleGroup } from "../../../domain/enums/Roles";
import { useGetNotificationTriggerDetails } from "../../../domain/viewmodels/notification-triggers/notification-trigger-details-viewmodel";
import SbCheckField from "../../atoms/input/SbCheckField";
import { EditLink, ViewLink } from "../../atoms/SbLink";

const NotificationTriggerDetailsContainer: React.FC = () => {
    const menu = useMenu();
    const toast = useToast();
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });
    const auth = useAuth();

    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";
    const notificationTriggerId = Number(useParams().notificationTriggerId);

    const getNotificationTriggerDetails = useGetNotificationTriggerDetails(notificationTriggerId);

    const notificationTriggerDetailsData = getNotificationTriggerDetails.data;

    useLoader(isQueryLoading(getNotificationTriggerDetails), NotificationTriggerDetailsContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.ActionTracker);
        menu.changeActiveDrawerItem(DrawerTitles.NotificationTriggers);

        if (success) {
            toast.addToast(createSuccessToastProps([t(messageKey)]));

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    const buildLinks = (): ReactElement<typeof Link> => (
        <>
            {hasRoleTypeInGroup(auth.userRoles, NotificationTriggerRoleGroup.WriteRoles) && (
                <EditLink
                    navigateTo={`${getPath(
                        DrawerTitles.NotificationTriggers
                    )}/${notificationTriggerId}/edit`}
                />
            )}

            <ViewLink
                label={t("NotificationTriggers")}
                navigateTo={`${getPath(DrawerTitles.NotificationTriggers)}`}
            />
        </>
    );

    return (
        <>
            <PageHeading>{t("NotificationTriggerDetails")}</PageHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(getNotificationTriggerDetails) && (
                <ContentContainer>
                    <Row>
                        <Col md="auto">
                            <DetailsLabel>{t("Name", { keyPrefix: Common })}</DetailsLabel>
                            <DetailsLabel>{t("ShouldTriggerBefore")}</DetailsLabel>
                            <DetailsLabel>{t("TriggerTimeInterval")}</DetailsLabel>
                            <DetailsLabel>{t("TriggerDate")}</DetailsLabel>
                            <DetailsLabel>{t("SendWindowInterval")}</DetailsLabel>
                        </Col>
                        <Col md="auto">
                            <DetailsValue>{notificationTriggerDetailsData!.name}</DetailsValue>
                            <SbCheckField
                                type={"checkbox"}
                                name={"triggerBefore"}
                                defaultSelected={notificationTriggerDetailsData!.triggerBefore}
                            />
                            <DetailsValue>
                                {
                                    notificationTriggerDetailsData!.triggerTimeDeltaIntervalDto
                                        .timeSpan
                                }
                            </DetailsValue>
                            <DetailsValue>
                                {TriggeringDate[notificationTriggerDetailsData!.triggeringDate]}
                            </DetailsValue>
                            <DetailsValue>
                                {notificationTriggerDetailsData!.sendWindowIntervalDto.timeSpan}
                            </DetailsValue>
                        </Col>
                    </Row>

                    <EndAlignedDiv>{buildLinks()}</EndAlignedDiv>
                </ContentContainer>
            )}
        </>
    );
};

export default NotificationTriggerDetailsContainer;
