import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ChecklistQuestions } from "../../../core/constants/translation-namespace";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    ContentContainer,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import { useFilterFunctionUserNodes } from "../../../domain/viewmodels/trigger-adhocs/trigger-adhocs-viewmodel";
import SbTabs, { SbTabItemProps } from "../../molecules/SbTabs";
import TriggerAdhocsSectionContainer from "./TriggerAdhocsSectionContainer";

const TriggerAdhocsContainer: React.FC = () => {
    const menu = useMenu();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const filterFunctionUserNodes = useFilterFunctionUserNodes();

    const filterFunctionUserNodesResponseData = filterFunctionUserNodes.data;

    useLoader(isQueryLoading(filterFunctionUserNodes), TriggerAdhocsContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Checklists);
        menu.changeActiveDrawerItem(DrawerTitles.TriggerAdhocs);
    }, []);

    const buildSbTabItemProps = (): SbTabItemProps[] => {
        const triggerAdhocForMeComponent = (
            <TriggerAdhocsSectionContainer
                panelTitle={t("AssignedNodes")}
                infoAlertMessage={t("SelectNodeTrigger")}
                selectFieldLabel={t("AssignedNode")}
                nodes={filterFunctionUserNodesResponseData!.assignedFunctionNodes}
            />
        );

        const triggerAdhocForAnotherUserComponent = (
            <TriggerAdhocsSectionContainer
                panelTitle={t("TeamFunctionNodes")}
                infoAlertMessage={t("SelectInheritedNode")}
                selectFieldLabel={t("FunctionNode")}
                nodes={filterFunctionUserNodesResponseData!.teamFunctionNodes}
                teamLeadView
            />
        );

        return [
            new SbTabItemProps(t("TriggerForMe"), triggerAdhocForMeComponent, true),
            new SbTabItemProps(t("TriggerForAnotherUser"), triggerAdhocForAnotherUserComponent),
        ];
    };

    return (
        <>
            <PageHeading>{t("TriggerAdhocChecklists")}</PageHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(filterFunctionUserNodes) && (
                <ContentContainer>
                    <SbTabs values={buildSbTabItemProps()} />
                </ContentContainer>
            )}
        </>
    );
};

export default TriggerAdhocsContainer;
