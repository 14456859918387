import AnswerFlagMode from "../../enums/answer-types/answer-flag-mode";

export interface EditPredefinedAnswerRequest {
    predefinedAnswerId: number;
    flagMode: AnswerFlagMode | null;
    tooltipDescription: string | null;
}

export const createEditPredefinedAnswerRequest = (
    predefinedAnswerId: number,
    flagMode: AnswerFlagMode | null,
    tooltipDescription: string | null
): EditPredefinedAnswerRequest => ({
    predefinedAnswerId: predefinedAnswerId,
    flagMode: flagMode,
    tooltipDescription: tooltipDescription,
});
