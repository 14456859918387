export interface PromoteDemoteChecklistAssignmentRequest {
    checklistAssignmentId: number;
    isTraining: boolean;
}

export const createPromoteDemoteChecklistAssignmentRequest = (
    checklistAssignmentId: number,
    isTraining: boolean
): PromoteDemoteChecklistAssignmentRequest => ({
    checklistAssignmentId,
    isTraining,
});
