import NestedQuestionAnswerFileUploadRequest from "../../../domain/requests/answer-capture/nested-question-answer-file-upload-request";
import QuestionAnswerFileUploadRequest from "../../../domain/requests/answer-capture/question-answer-file-upload-request";
import {
    buildFilterSearchParameters,
    FilterQuestionSetInstanceAnswerFileRequest,
} from "../../../domain/requests/question-set-instance-answers/filter-question-set-instance-answer-file-request";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import BaseFileDetailsResponse from "../../../domain/responses/file-storage/base-file-details-response";
import QuestionSetInstanceAnswerFileDetailsResponse from "../../../domain/responses/question-set-instance-answers/question-set-instance-answer-file-details-response";
import QuestionSetInstanceAnswerFileResponse from "../../../domain/responses/question-set-instance-answers/question-set-instance-answer-file-response";
import QuestionSetInstanceAnswerStagingFileDetailsResponse from "../../../domain/responses/question-set-instance-answers/question-set-instance-answer-staging-file-details-response";
import client from "../../http-client";

const route = "questionsetinstanceanswerfiles";

export const filterAnswerFiles = async (
    prefixUrl: string,
    questionSetInstanceAnswerId: number
): Promise<Response<QuestionSetInstanceAnswerFileDetailsResponse[]>> => {
    const searchParams = new URLSearchParams();

    searchParams.set("questionSetInstanceAnswerId", questionSetInstanceAnswerId.toString());

    return await client
        .get(`${route}/files`, {
            prefixUrl: `${prefixUrl}`,
            searchParams: searchParams,
        })
        .json();
};

export const filterNestedAnswerFiles = async (
    prefixUrl: string,
    questionSetInstanceAnswerStagingId: number
): Promise<Response<QuestionSetInstanceAnswerStagingFileDetailsResponse[]>> => {
    const searchParams = new URLSearchParams();

    searchParams.set(
        "questionSetInstanceAnswerStagingId",
        questionSetInstanceAnswerStagingId.toString()
    );

    return await client
        .get(`${route}/nested-files`, {
            prefixUrl: `${prefixUrl}`,
            searchParams: searchParams,
        })
        .json();
};

export const filterQuestionSetInstanceAnswerFiles = async (
    prefixUrl: string,
    request: FilterQuestionSetInstanceAnswerFileRequest
): Promise<Response<PaginationResponse<QuestionSetInstanceAnswerFileResponse>>> => {
    const searchParams = buildFilterSearchParameters(request);

    return await client
        .get(`${route}/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const uploadAnswerFiles = async (
    prefixUrl: string,
    request: QuestionAnswerFileUploadRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/upload-files`, {
            prefixUrl: `${prefixUrl}`,
            body: JSON.stringify(request),
        })
        .json();

export const uploadNestedAnswerFiles = async (
    prefixUrl: string,
    request: NestedQuestionAnswerFileUploadRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/upload-nested-files`, {
            prefixUrl: `${prefixUrl}`,
            body: JSON.stringify(request),
        })
        .json();

export const downloadAnswerFile = async (
    prefixUrl: string,
    questionSetInstanceAnswerFileId: number
): Promise<Response<BaseFileDetailsResponse>> =>
    await client
        .get(`${route}/download-file/${questionSetInstanceAnswerFileId}`, {
            prefixUrl: `${prefixUrl}`,
        })
        .json();

export const downloadNestedAnswerFile = async (
    prefixUrl: string,
    questionSetInstanceAnswerStagingFileId: number
): Promise<Response<BaseFileDetailsResponse>> =>
    await client
        .get(`${route}/download-nested-file/${questionSetInstanceAnswerStagingFileId}`, {
            prefixUrl: `${prefixUrl}`,
        })
        .json();

export const softDeleteAnswerFile = async (
    prefixUrl: string,
    questionSetInstanceAnswerFileId: number
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/soft-delete-file/${questionSetInstanceAnswerFileId}`, {
            prefixUrl: `${prefixUrl}`,
        })
        .json();

export const softDeleteNestedAnswerFile = async (
    prefixUrl: string,
    questionSetInstanceAnswerStagingFileId: number
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/soft-delete-nested-file/${questionSetInstanceAnswerStagingFileId}`, {
            prefixUrl: `${prefixUrl}`,
        })
        .json();
