import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { FaSort } from "react-icons/fa";
import styled from "styled-components";
import { Column, TableRow } from "../../core/utilities/customTypes";
import { translateText } from "../helpers/translate";
import { buildNoResultsView, buildResultsView, StyledGridP, StyledTable } from "./DataTable";

interface StyledTableRowProps {
    readonly $isDragging?: boolean;
}

const StyledTableRow = styled.tr<StyledTableRowProps>`
    background-color: ${(props) => (props.$isDragging ? "lightblue" : "white")};
    display: ${(props) => (props.$isDragging ? "table" : "")};
`;

interface DraggableTableProps<TType> {
    columns?: Column<TType>[];
    rows: TableRow<TType>[] | undefined;
    keyPrefix?: string;
    onOrderChanged: (_: TType[]) => void;
    noResultsMessage?: string | undefined;
}

const DraggableDataTable = <TType,>({
    columns,
    rows,
    keyPrefix,
    onOrderChanged,
    noResultsMessage,
}: DraggableTableProps<TType>): JSX.Element => {
    const reorderData = (startIndex: number, endIndex: number): void => {
        const newData = [...rows!];
        const [movedRow] = newData.splice(startIndex, 1);
        newData.splice(endIndex, 0, movedRow);

        for (let i = 0; i < newData.length; i++) {
            const row = newData[i];
            for (let j = 0; j < row.columns.length; j++) {
                const column = row.columns[j];
                if (column.metadata === "Index") {
                    column.value = (i + 1).toString();
                }
            }
        }

        rows = newData;
    };
    const { t } = useTranslation("translation");
    const handleDragEnd = (result: DropResult): void => {
        const { source, destination } = result;
        if (!destination) {
            return;
        }

        reorderData(source.index, destination.index);

        const metadataPresent = rows?.some((x) => x.metadata);
        if (metadataPresent) {
            onOrderChanged(rows!.map((x) => x.metadata!));
        }
    };
    const translate = (value?: string): string => {
        return translateText(t, value ?? "", keyPrefix);
    };

    return (
        <>
            <StyledTable hover borderless>
                {columns && (
                    <thead>
                        <tr>
                            {[null, ...columns].map((column) => (
                                <th key={column?.value}>
                                    <StyledGridP>{translate(column?.value)}</StyledGridP>
                                </th>
                            ))}
                        </tr>
                    </thead>
                )}

                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="drag-drop-table">
                        {(provided, _) => (
                            <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                {rows?.map((row, index) => {
                                    return (
                                        <Draggable
                                            draggableId={index.toString()}
                                            index={index}
                                            key={index}
                                        >
                                            {(provided, snapshot) => (
                                                <StyledTableRow
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    $isDragging={snapshot.isDragging}
                                                    key={index}
                                                    ref={provided.innerRef}
                                                >
                                                    <td>
                                                        <FaSort />
                                                    </td>
                                                    {buildResultsView(row, false, t)}
                                                </StyledTableRow>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </tbody>
                        )}
                    </Droppable>
                </DragDropContext>
            </StyledTable>

            {buildNoResultsView(rows, noResultsMessage)}
        </>
    );
};

export default DraggableDataTable;
