import { useMutation, UseMutationResult, useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { convertToggleValueToBoolean } from "../../../core/utilities/convertToBoolean";
import {
    associateNode,
    getChecklistDetails,
} from "../../../data/services/checklists/checklist-service";
import { filterHierarchyTypes } from "../../../data/services/hierarchy/hierarchy-types-service";
import { AssociateChecklistNodeDto } from "../../dtos/checklists/associate-checklist-node-dto";
import { HierarchyTypeDto, toHierarchyTypeDtos } from "../../dtos/hierarchy/hierarchy-type-dto";
import { createAssociateChecklistNodeRequest } from "../../requests/checklists/associate-checklist-node-request";
import CreateQuestionSetActionRequest from "../../requests/checklists/create-question-set-action-request";
import { createBasePaginationRequest } from "../../requests/common/base-pagination-request";
import { createFilterHierarchyTypesRequest } from "../../requests/hierarchy/filter-hierarchy-types-request";
import { emptySearchHierarchyTypesRequest } from "../../requests/hierarchy/search-hierarchy-types-request";
import { ChecklistResponse } from "../../responses/checklists/checklist-response";
import { Response } from "../../responses/common/response-response";

export const useGetAssociatedNodeAndHierarchyDetails = (
    checklistId: number
): [UseQueryResult<string, HTTPError>, UseQueryResult<HierarchyTypeDto[], HTTPError>] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getChecklist", checklistId],
                queryFn: () => getChecklistDetails(url.baseUrl, checklistId),
                select: createChecklistDto,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["filterHierarchyTypes"],
                queryFn: () =>
                    filterHierarchyTypes(
                        url.baseUrl,
                        createFilterHierarchyTypesRequest(
                            createBasePaginationRequest(1, 1000),
                            emptySearchHierarchyTypesRequest()
                        )
                    ),
                select: toHierarchyTypeDtos,
            },
        ],
    }) as [UseQueryResult<string, HTTPError>, UseQueryResult<HierarchyTypeDto[], HTTPError>];
};

const createChecklistDto = (response: Response<ChecklistResponse>): string => response.data.name;

export const useAssociateNode = (): UseMutationResult<
    Response<number | null>,
    HTTPError,
    AssociateChecklistNodeDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: AssociateChecklistNodeDto) => {
        const formData = mutationKey.formData;

        const request = createAssociateChecklistNodeRequest(
            mutationKey.checklistId,
            mutationKey.nodeId,
            mutationKey.nodeTypeValueIds,
            mutationKey.timeZoneNodeTypeValueId,
            mutationKey.appliesToUsers === "Any" ? 0 : 1,
            new Date(formData.get("activeDate")! as string),
            convertToggleValueToBoolean(formData.get("isTraining")! as string),
            convertToggleValueToBoolean(formData.get("allowActionItemTrigger")! as string),
            convertToggleValueToBoolean(formData.get("allowAdhocIntervalOverride")! as string),
            convertToggleValueToBoolean(formData.get("allowCustomNotifications")! as string),
            convertToggleValueToBoolean(formData.get("allowFileAttachment")! as string),
            mutationKey.userId,
            CreateQuestionSetActionRequest.constructFromCreateQuestionSetActionDtos(
                mutationKey.createQuestionSetActionDtos
            ),
            new Date(formData.get("expiryDate")! as string)
        );

        return associateNode(url.baseUrl, request);
    });
};
