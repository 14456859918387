import TriggerAdhocQuestionSetDto from "../../dtos/checklists/trigger-adhoc-question-set-dto";
import {
    createCreateReferentialLinkRequest,
    CreateReferentialLinkRequest,
} from "../referential-link-types/create-referential-link-request";
import AdhocChecklistNotificationRequest from "./adhoc-checklist-notification-request";
import IntervalOverridesRequest from "./interval-overrides-request";

export default class TriggerAdhocQuestionSetRequest {
    questionSetAssignmentScheduleId: number;
    functionUserId: number | null;
    intervalOverridesRequest: IntervalOverridesRequest | null;
    adhocChecklistNotificationRequest: AdhocChecklistNotificationRequest | null;
    referentialLinks: number[] | null;
    createReferentialLinkRequests: CreateReferentialLinkRequest[] | null;
    assignedNodeId: number;

    public constructor(dto: TriggerAdhocQuestionSetDto) {
        const {
            questionSetAssignmentScheduleId,
            functionUserId,
            intervalOverridesDto,
            adhocChecklistNotificationDto,
            referentialLinks,
            createdReferentialLinks,
            assignedNodeId,
        } = dto;

        this.questionSetAssignmentScheduleId = questionSetAssignmentScheduleId;
        this.functionUserId = functionUserId;
        this.intervalOverridesRequest = intervalOverridesDto
            ? new IntervalOverridesRequest(intervalOverridesDto)
            : null;
        this.adhocChecklistNotificationRequest = adhocChecklistNotificationDto
            ? new AdhocChecklistNotificationRequest(adhocChecklistNotificationDto)
            : null;
        this.referentialLinks = referentialLinks;
        this.createReferentialLinkRequests =
            createdReferentialLinks?.map((x) => createCreateReferentialLinkRequest(x[0], x[1])) ??
            null;
        this.assignedNodeId = assignedNodeId;
    }
}
