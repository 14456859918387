import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useAuth } from "../../../core/store/auth-context";
import { useUrl } from "../../../core/store/url-context";
import { getDisclaimer } from "../../../data/services/tenants/tenant-service";
import { DisclaimerDto } from "../../dtos/tenants/disclaimer-dto";

export const useGetDisclaimer = (
    tenantId: number | null
): UseQueryResult<DisclaimerDto, HTTPError> => {
    const url = useUrl();
    const auth = useAuth();

    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getDisclaimer", tenantId],
        () => getDisclaimer(url.originalBaseUrl, tenantId),
        {
            enabled: auth.userId !== null,
            select: DisclaimerDto.constructFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};
