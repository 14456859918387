import { ReactElement, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
    ActionItems,
    ChecklistQuestions,
    Common,
    Reports,
} from "../../../core/constants/translation-namespace";
import useLoader from "../../../core/hooks/loaderManager";
import {
    LargeVerticalSpace,
    SectionVerticalSpace,
    StyledBorderedSection,
    StyledFormLabel,
    StyledVariantText,
} from "../../../core/theme/global-styles";
import { TableRow } from "../../../core/utilities/customTypes";
import { checklistReportDetailsColumnNames } from "../../../core/utilities/dataTableColumns";
import { DownloadFile } from "../../../core/utilities/FileDownload";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import ChecklistReportDetailsDto from "../../../domain/dtos/reports/checklist-report-details-dto";
import ChecklistReportDetailLevel from "../../../domain/enums/reports/checklist-report-detail-level";
import { useDownloadQuestionSetInstanceAnswerFile } from "../../../domain/viewmodels/reports/checklist-report-details-viewmodel";
import SbModal, { ModalSize } from "../../molecules/SbModal";
import { DataTable } from "../../organisms/DataTable";

const ChecklistReportDetailsContainer: React.FC<{
    checklistReportDetailsDto: ChecklistReportDetailsDto;
    onExportClicked: () => void;
    isVisible: boolean;
    updateIsVisible: (_: boolean) => void;
}> = ({ checklistReportDetailsDto, isVisible, updateIsVisible, onExportClicked }) => {
    const [rows, setRows] = useState<TableRow<number>[]>(
        checklistReportDetailsDto.questionAnswersReportListTableDto
    );

    const [
        downloadQuestionSetInstanceAnswerFileDetailsId,
        setDownloadQuestionSetInstanceAnswerFileDetailsId,
    ] = useState<number | null>(null);

    const { t } = useTranslation("translation", { keyPrefix: Reports });

    const downloadQuestionSetInstanceAnswerFile = useDownloadQuestionSetInstanceAnswerFile(
        downloadQuestionSetInstanceAnswerFileDetailsId
    );

    useLoader(
        isQueryLoading(downloadQuestionSetInstanceAnswerFile),
        ChecklistReportDetailsContainer
    );

    useEffect(() => {
        if (isQuerySuccessful(downloadQuestionSetInstanceAnswerFile)) {
            DownloadFile(downloadQuestionSetInstanceAnswerFile.data!);
        }
    }, [downloadQuestionSetInstanceAnswerFile.fetchStatus]);

    const handleSetRows = (tableRows: TableRow<number>[]): void => {
        let updatedRowsData = rows ? rows.slice() : [];

        updatedRowsData.map((updatedRow) => {
            tableRows.map((tableRow) => {
                if (updatedRow.metadata === tableRow.metadata) {
                    updatedRow.isAttachedFilesDisplayed = tableRow.isAttachedFilesDisplayed;
                }
            });
        });

        setRows(updatedRowsData);
    };

    const onDownloadFile = (fileDetailsId: number): void =>
        setDownloadQuestionSetInstanceAnswerFileDetailsId(fileDetailsId);

    const buildTitle = (): string => {
        let reportTitle = "";

        switch (checklistReportDetailsDto.level) {
            case ChecklistReportDetailLevel.All:
                reportTitle = t("ChecklistAnswersReport");
                break;

            case ChecklistReportDetailLevel.AdhocOnly:
                reportTitle = t("AdhocChecklistReport");
                break;

            default:
                break;
        }

        return reportTitle;
    };

    const buildLabelValues = (map: Map<string, string>): ReactElement<HTMLTableRowElement>[] =>
        Array.from(map.entries()).map((x, index) => (
            <Row key={index}>
                <Col sm={4}>
                    <StyledFormLabel>{x[0]}</StyledFormLabel>
                </Col>
                <Col>
                    <StyledVariantText $variant="secondary">{x[1]}</StyledVariantText>
                </Col>
            </Row>
        ));

    const buildModalBody = (): ReactElement<HTMLDivElement> => (
        <>
            <StyledBorderedSection>
                <h5>
                    <StyledVariantText $variant="secondary">{t("Checklist")}</StyledVariantText>
                </h5>
                {buildLabelValues(checklistReportDetailsDto.checklistDetailsMap)}
            </StyledBorderedSection>
            <LargeVerticalSpace />

            {checklistReportDetailsDto.referentialLinkDetailsMap.size > 0 && (
                <>
                    <StyledBorderedSection>
                        <h5>
                            <StyledVariantText $variant="secondary">
                                {t("ReferentialLinks", { keyPrefix: ChecklistQuestions })}
                            </StyledVariantText>
                        </h5>
                        {buildLabelValues(checklistReportDetailsDto.referentialLinkDetailsMap)}
                    </StyledBorderedSection>
                    <SectionVerticalSpace />
                </>
            )}

            <DataTable
                rows={rows}
                setRows={handleSetRows}
                columns={checklistReportDetailsColumnNames}
                nestedColumnNames={checklistReportDetailsColumnNames}
                attachedFilesLabel={t("AttachedEvidence", { keyPrefix: ActionItems }) as string}
                downloadAttachedFile={onDownloadFile}
            />
        </>
    );

    return (
        <SbModal
            title={buildTitle()}
            body={buildModalBody()}
            size={ModalSize.Large}
            primaryButtonLabel={t("Export", { keyPrefix: Common }) as string}
            onPrimaryButtonClicked={onExportClicked}
            isVisible={isVisible}
            updateIsVisible={updateIsVisible}
        />
    );
};

export default ChecklistReportDetailsContainer;
