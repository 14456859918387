import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import {
    filterAssociatedQuestions,
    orderQuestionSetQuestions,
} from "../../../data/services/question-set/question-set-service";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { FilterQuestionSetDto } from "../../dtos/question-sets/filter-question-set-dto";
import { createFilterQuestionSetRequestFromDto } from "../../requests/question-set/filter-question-set-request";
import {
    createQuestionSetQuestionsOrderRequest,
    createQuestionsOrderRequest,
    QuestionsOrderRequest,
} from "../../requests/question-set/order-question-set-questions-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { QuestionSetQuestionResponse } from "../../responses/question-sets/question-set-question-response";

export const useGetQuestionSetDetails = (
    questionSetId: number,
    dto: FilterQuestionSetDto
): UseQueryResult<PaginatedTableDto<number>, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterQuestionSetQuestions", dto],
        () =>
            filterAssociatedQuestions(
                url.baseUrl,
                createFilterQuestionSetRequestFromDto({ ...dto, questionSetId })
            ),
        {
            select: transformToDataTableRows,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useOrderQuestionSetQuestions = (
    questionSetId: number
): UseMutationResult<Response<boolean>, HTTPError, number[]> => {
    const url = useUrl();

    return useMutation((questionSetQuestionIds: number[]) => {
        const questionsOrder = questionSetQuestionIds.map(
            (x, index): QuestionsOrderRequest => createQuestionsOrderRequest(x, ++index)
        );

        const request = createQuestionSetQuestionsOrderRequest(questionSetId, questionsOrder);

        return orderQuestionSetQuestions(url.baseUrl, request);
    });
};

const transformToDataTableRows = (
    response: Response<PaginationResponse<QuestionSetQuestionResponse>>
): PaginatedTableDto<number> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((questionSetQuestion) => ({
        metadata: questionSetQuestion.questionId,
        columns: [
            {
                // Order Index
                value: questionSetQuestion.orderIndex.toString(),
                type: DataTableColumnTypes.Text,
                metadata: "Index",
            },
            {
                // Question Id
                value: questionSetQuestion.questionId.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                // Question Type
                value: questionSetQuestion.questionType,
                type: DataTableColumnTypes.Text,
            },
            {
                // Questions Text
                value: questionSetQuestion.questionText.toString(),
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
