import { Response } from "../../responses/common/response-response";
import { BaseNodeDetailsResponse } from "../../responses/hierarchy/base-node-details-response";
import { BaseNodeDto, createBaseNodeDto } from "./base-node-dto";

export interface BaseNodeDetailsDto extends BaseNodeDto {
    path: string;
    isTraining: boolean;
}

export const createBaseNodeDetailsDto = (
    response: BaseNodeDetailsResponse
): BaseNodeDetailsDto => ({
    ...createBaseNodeDto(response),
    path: response.path,
    isTraining: response.isTraining,
});

export const toBaseNodeDetailsDtosFromResponse = (
    responses: Response<BaseNodeDetailsResponse[]>
): BaseNodeDetailsDto[] => toBaseNodeDetailsDtos(responses.data);

export const toBaseNodeDetailsDtos = (responses: BaseNodeDetailsResponse[]): BaseNodeDetailsDto[] =>
    responses.map((x) => createBaseNodeDetailsDto(x));
