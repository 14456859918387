import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { getWizardNotificationHistory } from "../../../data/services/wizards/wizard-notification-history-service";
import { Response } from "../../../domain/responses/common/response-response";
import PaginatedDto from "../../dtos/common/paginated-dto";
import { PaginationDto } from "../../dtos/common/pagination-dto";
import {
    toWizardNotificationHistoryDto,
    WizardNotificationHistoryDto,
} from "../../dtos/wizards/wizard-notification-history-dto";
import { createBasePaginationRequestFromDto } from "../../requests/common/base-pagination-request";
import { createFilterWizardNotificationHistoryRequest } from "../../requests/wizards/filter-wizard-notification-history-request";
import { SearchWizardNotificationHistoryRequest } from "../../requests/wizards/search-wizard-notification-history-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { WizardNotificationHistoryResponse } from "../../responses/wizards/wizard-notification-history-response";

export const useGetWizardNotificationHistory = (
    paginationDto: PaginationDto,
    searchRequest: SearchWizardNotificationHistoryRequest
): UseQueryResult<PaginatedDto<WizardNotificationHistoryDto>, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getWizardNotificationHistory", paginationDto, searchRequest],
        () =>
            getWizardNotificationHistory(
                url.originalBaseUrl,
                createFilterWizardNotificationHistoryRequest(
                    createBasePaginationRequestFromDto(paginationDto),
                    searchRequest
                )
            ),
        {
            keepPreviousData: true,
            select: transformToWizardNotificationHistoryDto,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const transformToWizardNotificationHistoryDto = (
    data: Response<PaginationResponse<WizardNotificationHistoryResponse>>
): PaginatedDto<WizardNotificationHistoryDto> => {
    const responseData = data.data;

    const results = responseData.results.map((wizardNotificationHistoryResponse) =>
        toWizardNotificationHistoryDto(wizardNotificationHistoryResponse)
    );

    return {
        numberOfPages: responseData.pageCount,
        pageSize: responseData.pageSize,
        currentPage: responseData.currentPage,
        recordCount: responseData.recordCount,
        results: results,
    };
};
