export interface MoveNodeRequest {
    candidateNodeId: number;
    destinationNodeId: number;
}

export const createMoveNodeRequest = (
    candidateNodeId: number,
    destinationNodeId: number
): MoveNodeRequest => ({
    candidateNodeId: candidateNodeId,
    destinationNodeId: destinationNodeId,
});
