export interface AssociateActionItemToActionPlanRequest {
    actionPlanId: number;
    actionItemIds: number[];
}

export const createAssociateActionItemToActionPlanRequest = (
    actionPlanId: number,
    actionItemIds: number[]
): AssociateActionItemToActionPlanRequest => ({
    actionPlanId: actionPlanId,
    actionItemIds: actionItemIds,
});
