import { PaginationDto } from "../../dtos/common/pagination-dto";
import { FilterEmailQueueDto } from "../../dtos/email-queue/filter-email-queue-dto";
import {
    buildPaginationSearchParameters,
    createPaginationRequestFromDto,
    PaginationRequest,
} from "../common/pagination-request";

export interface FilterEmailQueueRequest extends PaginationRequest {
    subject: string | null;
    recipients: string | null;
    dateSentFrom: string | null;
    dateSentTo: string | null;
    failedToSend: boolean;
}

export const createFilterEmailQueueRequestFromDto = (
    filterDto: FilterEmailQueueDto,
    paginationDto: PaginationDto
): FilterEmailQueueRequest => ({
    subject: filterDto.subject,
    recipients: filterDto.recipients,
    dateSentFrom: filterDto.dateSentFrom,
    dateSentTo: filterDto.dateSentTo,
    failedToSend: filterDto.failedToSend,
    ...createPaginationRequestFromDto(paginationDto),
});

export const buildFilterEmailQueueSearchParameters = (
    request: FilterEmailQueueRequest
): URLSearchParams => {
    const searchParams = buildPaginationSearchParameters(request);

    if (request.subject) {
        searchParams.set("subject", request.subject);
    }

    if (request.recipients) {
        searchParams.set("recipients", request.recipients);
    }

    if (request.dateSentFrom) {
        searchParams.set("dateSentFrom", request.dateSentFrom);
    }

    if (request.dateSentTo) {
        searchParams.set("dateSentTo", request.dateSentTo);
    }

    if (request.failedToSend) {
        searchParams.set("failedToSend", String(request.failedToSend));
    }

    return searchParams;
};
