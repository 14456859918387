export interface AssociateDissociateQuestionTaxonomyNodeRequest {
    questionId: number;
    nodeId: number;
}

export const createAssociateDissociateQuestionTaxonomyNodeRequest = (
    questionId: number,
    nodeId: number
): AssociateDissociateQuestionTaxonomyNodeRequest => ({
    questionId: questionId,
    nodeId: nodeId,
});
