import React from "react";
import { useTranslation } from "react-i18next";
import { FaBan, FaBell, FaCheck, FaExclamationTriangle } from "react-icons/fa";
import { VscChromeClose } from "react-icons/vsc";
import styled, { css } from "styled-components";
import { ActionItems } from "../../core/constants/translation-namespace";
import { ActionInstanceStatus } from "../../domain/enums/action-items/ActionInstanceStatus";
import { translateText } from "../helpers/translate";
import { StyledStatusProps } from "../molecules/ActionItemCard";

const StyledSbActionItemStatusIconLabelContainer = styled.div<StyledStatusProps>`
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: ${(props) => props.theme.padding.md};

    ${({ $actionInstanceStatus }) =>
        $actionInstanceStatus === ActionInstanceStatus.Pending &&
        css`
            color: ${(props) => props.theme.palette.actionInstanceStatus.onhold};
        `}
    ${({ $actionInstanceStatus }) =>
        $actionInstanceStatus === ActionInstanceStatus.Overdue &&
        css`
            color: ${(props) => props.theme.palette.actionInstanceStatus.overdue};
        `}
    ${({ $actionInstanceStatus }) =>
        $actionInstanceStatus === ActionInstanceStatus.Cancelled &&
        css`
            color: ${(props) => props.theme.palette.actionInstanceStatus.cancelled};
        `}
    ${({ $actionInstanceStatus }) =>
        $actionInstanceStatus === ActionInstanceStatus.Completed &&
        css`
            color: ${(props) => props.theme.palette.actionInstanceStatus.completed};
        `}
    ${({ $actionInstanceStatus }) =>
        $actionInstanceStatus === ActionInstanceStatus.Due &&
        css`
            color: ${(props) => props.theme.palette.actionInstanceStatus.due};
        `}
    ${({ $actionInstanceStatus }) =>
        $actionInstanceStatus === ActionInstanceStatus.Open &&
        css`
            color: ${(props) => props.theme.palette.actionInstanceStatus.open};
        `}
`;

const StyledStatusLabel = styled.div`
    font-weight: bold;
    font-size: ${(props) => props.theme.font.xl};
`;

const SbActionItemStatusIconLabel: React.FC<{
    actionInstanceStatus: ActionInstanceStatus;
}> = ({ actionInstanceStatus }) => {
    const { t } = useTranslation("translation");

    const getActionItemStatus = (actionInstanceStatus: ActionInstanceStatus): string => {
        return actionInstanceStatus
            ? translateText(t, ActionInstanceStatus[actionInstanceStatus], ActionItems)
            : "";
    };

    const renderActionItemIcon = (
        actionInstanceStatus: ActionInstanceStatus
    ): JSX.Element | undefined => {
        switch (actionInstanceStatus) {
            case ActionInstanceStatus.Pending:
                return <FaBan size={24} />;
            case ActionInstanceStatus.Overdue:
                return <FaExclamationTriangle size={24} />;
            case ActionInstanceStatus.Cancelled:
                return <VscChromeClose size={24} />;
            case ActionInstanceStatus.Completed:
                return <FaCheck size={24} />;
            case ActionInstanceStatus.Due:
                return <FaBell size={24} />;
            case ActionInstanceStatus.Open:
                return <FaBell size={24} />;
            default:
                return;
        }
    };

    return (
        <StyledSbActionItemStatusIconLabelContainer $actionInstanceStatus={actionInstanceStatus}>
            {renderActionItemIcon(actionInstanceStatus)}
            <StyledStatusLabel>{getActionItemStatus(actionInstanceStatus)}</StyledStatusLabel>
        </StyledSbActionItemStatusIconLabelContainer>
    );
};

export default SbActionItemStatusIconLabel;
