export interface SearchChecklistAssociationRequest {
    checklistId: number | null;
    checklistAssignmentId: number | null;
    nodeSearchText: string | null;
}

export const emptySearchChecklistAssociationRequest = (): SearchChecklistAssociationRequest => ({
    checklistId: null,
    checklistAssignmentId: null,
    nodeSearchText: null,
});

const SearchParameters = {
    checklistId: "checklistId",
    checklistAssignmentId: "checklistAssignmentId",
    nodeSearchText: "nodeSearchText",
};

export const buildSearchChecklistAssociationSearchParameters = (
    request: SearchChecklistAssociationRequest,
    searchParams: URLSearchParams
): URLSearchParams => {
    if (request.checklistId) {
        searchParams.set(SearchParameters.checklistId, request.checklistId.toString());
    }
    if (request.checklistAssignmentId) {
        searchParams.set("checklistAssignmentId", request.checklistAssignmentId.toString());
    }
    if (request.nodeSearchText) {
        searchParams.set("nodeSearchText", request.nodeSearchText);
    }

    return searchParams;
};
