import InheritedChecklistAssignmentResponse from "../../responses/checklist-assignment/inherited-checklist-assignment-response";
import { Response } from "../../responses/common/response-response";
import BaseChecklistDto from "../checklists/base-checklist-dto";

export default class InheritedChecklistAssignmentDto extends BaseChecklistDto {
    checklistAssignmentId: number;
    isInherited: boolean;

    public constructor(response: InheritedChecklistAssignmentResponse) {
        super(response);

        const { checklistAssignmentId, isInherited } = response;

        this.checklistAssignmentId = checklistAssignmentId;
        this.isInherited = isInherited;
    }

    public static constructFromInheritedChecklistAssignmentResponse = (
        response: Response<InheritedChecklistAssignmentResponse[]>
    ): InheritedChecklistAssignmentDto[] =>
        response.data.map((x) => new InheritedChecklistAssignmentDto(x));
}
