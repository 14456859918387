import { DetailsValueBlue, DetailsValueBlueSmall } from "../../core/theme/global-styles";

const FormattedString: React.FC<{
    rawString?: string;
    isSmallDetailsValue?: boolean;
    isDetailsValue?: boolean;
}> = ({ rawString, isSmallDetailsValue, isDetailsValue }) => {
    return (
        <>
            {isDetailsValue
                ? isSmallDetailsValue
                    ? rawString
                          ?.split(/\r?\n/)
                          .map((y, index) => (
                              <DetailsValueBlueSmall key={index}>{y}</DetailsValueBlueSmall>
                          ))
                    : rawString
                          ?.split(/\r?\n/)
                          .map((y, index) => <DetailsValueBlue key={index}>{y}</DetailsValueBlue>)
                : rawString?.split(/\r?\n/).map((y, index) => <div key={index}>{y}</div>)}
        </>
    );
};

export default FormattedString;
