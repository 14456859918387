import { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { growNavbar, shrinkNavbar } from "../../core/animations/navbar";
import { Common } from "../../core/constants/translation-namespace";
import useLoader from "../../core/hooks/loaderManager";
import { useAuth } from "../../core/store/auth-context";
import { useMenu } from "../../core/store/menu-context";
import { useUrl } from "../../core/store/url-context";
import { useWizardNotification } from "../../core/store/wizard-notification-context";
import { NavbarTitles } from "../../core/utilities/enums";
import {
    helpItemAdminTranslationKeys,
    helpItemTenantTranslationKeys,
    profileItemTranslationKeys,
    supportItemTranslationKeys,
} from "../../core/utilities/navbarDropdownItems";
import { isQueryLoading, isQuerySuccessful } from "../../core/utilities/responseStateHelper";
import { TenantDto } from "../../domain/dtos/tenants/tenant-dto";
import { useFilterTenants } from "../../domain/viewmodels/common/navbar-viewmodel";
import { useGetLatestWizardNotification } from "../../domain/viewmodels/wizards/view-wizard-notification-viewmodel";
import { DropdownItem, NavbarDropdown } from "../atoms/NavbarDropdown";
import NavbarLink from "../atoms/NavbarLink";
import NavbarTab from "../atoms/NavbarTab";

interface StyledContainerProps {
    readonly $drawerOpen: boolean;
    readonly $animate: boolean;
}

const StyledContainer = styled(Container)<StyledContainerProps>`
    font-family: ${(props) => props.theme.font.family};
    position: absolute;
    z-index: 1;
    height: ${(props) => props.theme.padding.navbar};
    padding-left: ${(props) =>
        props.$drawerOpen ? props.theme.padding.drawerOpen : props.theme.padding.drawerClosed};
    padding-right: 0;
    animation: ${(props) =>
            props.$animate ? (props.$drawerOpen ? shrinkNavbar : growNavbar) : undefined}
        0.3s linear;
    background-color: ${(props) => props.theme.palette.white};
`;

const StyledRow = styled(Row)`
    display: flex;
    height: 100%;
    padding-top: ${(props) => props.theme.padding.xxs};
    padding-bottom: ${(props) => props.theme.padding.xxs};
    margin-left: ${(props) => props.theme.padding.lg};
    margin-right: ${(props) => props.theme.padding.lg};
    border-bottom: ${(props) => props.theme.padding.xs + " solid " + props.theme.palette.primary};
    justify-content: space-between;
`;

const StyledLeftAlignedContainer = styled(Col)`
    height: 100%;
    display: flex;
    flex-direction: row;
`;

const StyledRightAlignedContainer = styled(Col)`
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const Navbar: React.FC = () => {
    const menu = useMenu();
    const auth = useAuth();
    const url = useUrl();
    const navigate = useNavigate();

    const filterTenants = useFilterTenants();

    const { t } = useTranslation("translation");

    const getLatestWizardCards = useGetLatestWizardNotification(auth.userId);
    const wizardNotification = useWizardNotification();

    useLoader(isQueryLoading(filterTenants), Navbar);

    useEffect(() => {
        url.updateBaseUrlToCurrentLocation();
    }, []);

    const onTenantDropdownItemClicked = (item: TenantDto): void => {
        const isImpersonating = item.baseUrl !== url.originalBaseUrl;
        url.updateBaseUrl(item.baseUrl);
        auth.updateTenantId(item.tenantId);
        auth.updateIsImpersonating(isImpersonating);

        navigate("/dashboard");
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveAccordion("");
        menu.changeActiveDrawerItem("");

        localStorage.setItem("impersonatedTenant", item.tenantId.toString());
        localStorage.setItem("isImpersonating", isImpersonating.toString());
        localStorage.setItem("impersonatedBaseUrl", item.baseUrl);
    };

    const displayTenantsDropdown = (): JSX.Element | undefined => {
        if (auth.isAdmin && isQuerySuccessful(filterTenants)) {
            const noneItem: TenantDto = {
                tenantId: 0,
                name: "None",
                baseUrl: url.originalBaseUrl,
            };

            return (
                <DropdownItem
                    items={[noneItem].concat(filterTenants.data!)}
                    displayItemText={(item: TenantDto) => item.name}
                    onItemClicked={onTenantDropdownItemClicked}
                />
            );
        }
    };

    const displayLatestWizardCard = (): JSX.Element | undefined => {
        if (isQuerySuccessful(getLatestWizardCards) && getLatestWizardCards.data!.length > 0) {
            wizardNotification.onWizardCardsFetch(getLatestWizardCards.data!);
            wizardNotification.updateShow(true);

            return <></>;
        }
    };

    return (
        <StyledContainer fluid $animate={menu.animate} $drawerOpen={menu.drawerOpen}>
            <StyledRow>
                {auth.userName ? (
                    <>
                        <StyledLeftAlignedContainer sm="auto">
                            {displayTenantsDropdown()}
                            {menu.navbarItems.map((navbarItem) => (
                                <NavbarTab
                                    key={navbarItem.title}
                                    title={navbarItem.title}
                                    active={navbarItem.isActive}
                                />
                            ))}
                        </StyledLeftAlignedContainer>
                        <StyledRightAlignedContainer sm="auto">
                            <NavbarLink title={t("BackToRMP", { keyPrefix: Common })} />
                            <NavbarDropdown
                                hasIcon
                                text={NavbarTitles.Help}
                                items={
                                    auth.tenantId
                                        ? helpItemTenantTranslationKeys
                                        : helpItemAdminTranslationKeys
                                }
                                checkForLatestWizardNotification={() => displayLatestWizardCard()}
                            />
                            <NavbarDropdown
                                hasIcon
                                text={NavbarTitles.Support}
                                items={supportItemTranslationKeys}
                            />
                            <NavbarDropdown
                                hasIcon={false}
                                text={auth.userName}
                                items={profileItemTranslationKeys}
                            />
                        </StyledRightAlignedContainer>
                    </>
                ) : (
                    <></>
                )}
            </StyledRow>
        </StyledContainer>
    );
};

export default Navbar;
