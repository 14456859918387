export interface FilterEmailQueueDto {
    subject: string | null;
    recipients: string | null;
    dateSentFrom: string | null;
    dateSentTo: string | null;
    failedToSend: boolean;
    sortByColumn: string | null;
    sortByDescending: boolean;
}

export const createFilterEmailQueueDto = (
    subject: string | null,
    recipients: string | null,
    dateSentFrom: string | null,
    dateSentTo: string | null,
    failedToSend: boolean,
    sortByColumn: string | null,
    sortByDescending: boolean
): FilterEmailQueueDto => ({
    subject: subject,
    recipients: recipients,
    dateSentFrom: dateSentFrom,
    dateSentTo: dateSentTo,
    failedToSend: failedToSend,
    sortByColumn: sortByColumn,
    sortByDescending: sortByDescending,
});

export const defaultFilterEmailQueueDto = (): FilterEmailQueueDto =>
    createFilterEmailQueueDto(null, null, null, null, false, null, false);
