import { HTTPError } from "ky";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { EditNodeTypeEvent } from "../../../core/constants/application-insights-events";
import {
    Common,
    Hierarchy,
    HierarchyTypes,
    NodeTypes,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import {
    EscalationLevel,
    EscalationLevelLabels,
} from "../../../domain/enums/hierarchy/EscalationLevel";
import {
    HierarchyAssignable,
    HierarchyAssignableLabels,
    translateHierarchyAssignableLabel,
} from "../../../domain/enums/hierarchy/HierarchyAssignable";
import {
    createEditParameters,
    useEditNodeType,
    useGetNodeTypeDetails,
} from "../../../domain/viewmodels/hierarchy/edit-node-type-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import SbFormCheckFieldGroup from "../../molecules/input/SbFormCheckFieldGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const EditNodeTypeContainer: React.FC = () => {
    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const [urlSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: NodeTypes });

    const nodeTypeId = Number(useParams().nodeTypeId);
    const hierarchyTypeId = Number(urlSearchParams.get("hierarchyTypeId"));

    const getNodeTypeDetails = useGetNodeTypeDetails(nodeTypeId);
    const editNodeType = useEditNodeType();

    const nodeTypeDetailsResponseData = getNodeTypeDetails.data;

    useLoader(
        isQueryLoading(getNodeTypeDetails) || isMutationLoading(editNodeType),
        EditNodeTypeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.VisualStructure);
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const params = createEditParameters(
            nodeTypeId,
            getNodeTypeDetails.data!.hierarchyType.hierarchyTypeId,
            new FormData(event.currentTarget)
        );

        editNodeType.mutate(params, {
            onSuccess: async () => {
                trackAppInsightsEvent(auth.email, window.location.href, EditNodeTypeEvent);

                const params = [
                    createNavigateSearchParameter("success", "true"),
                    createNavigateSearchParameter(
                        "messageKey",
                        "UpdateHierarchyLevelSuccessMessage"
                    ),
                ];

                navigateSearch(`${getPath(AccordionTitles.Admin)}/${hierarchyTypeId}`, params);
            },
            onError: (error: HTTPError) => {
                trackAppInsightsException(
                    auth.email,
                    window.location.href,
                    EditNodeTypeEvent,
                    error
                );
                errorResponseToDisplayHandler(error);
            },
        });
    };

    return (
        <>
            <PageHeading>{t("NodeTypeEditTitle")}</PageHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(getNodeTypeDetails) && (
                <TextTitledPanel title={t("EditNodeType")}>
                    <Form onSubmit={handleSubmit}>
                        <SbFormTextFieldGroup
                            name="name"
                            maxLength={50}
                            label={t("Name", { keyPrefix: Common })}
                            type="text"
                            required
                            defaultValue={nodeTypeDetailsResponseData!.name}
                        />

                        <SbFormTextFieldGroup
                            name="hierarchyType"
                            label={t("HierarchyType", { keyPrefix: HierarchyTypes })}
                            type="text"
                            required
                            disabled
                            value={nodeTypeDetailsResponseData!.hierarchyType.name}
                        />

                        <SbFormCheckFieldGroup
                            fieldLabel={t("HierarchyAssignableFlag", { keyPrefix: HierarchyTypes })}
                            type="checkbox"
                            values={Array.from(HierarchyAssignableLabels.entries()).map((x) => ({
                                name: HierarchyAssignable[x[0]],
                                label: translateHierarchyAssignableLabel(t, x[1]),
                                defaultSelected:
                                    nodeTypeDetailsResponseData!.hierarchyAssignables.some(
                                        (y) => y == x[0]
                                    ),
                            }))}
                        />

                        <SbFormCheckFieldGroup
                            fieldLabel={t("EscalationLevelEnumFlag", { keyPrefix: HierarchyTypes })}
                            type="checkbox"
                            values={Array.from(EscalationLevelLabels.entries()).map((x) => ({
                                name: EscalationLevel[x[0]],
                                label: `${t(x[1], { keyPrefix: Common })}`,
                                defaultSelected: nodeTypeDetailsResponseData!.escalationLevels.some(
                                    (y) => y == x[0]
                                ),
                            }))}
                        />

                        <SbFormCheckFieldGroup
                            fieldLabel={t("ContainsTimeZoneCode", { keyPrefix: Hierarchy })}
                            type="checkbox"
                            values={[
                                {
                                    name: "containsTimeZoneCode",
                                    label: undefined,
                                    defaultSelected:
                                        nodeTypeDetailsResponseData!.containsTimeZoneCode,
                                },
                            ]}
                        />

                        <EndAlignedDiv>
                            <SaveButton type="submit" />
                            <CancelButton onClick={() => navigate(-1)} />
                        </EndAlignedDiv>
                    </Form>
                </TextTitledPanel>
            )}
        </>
    );
};

export default EditNodeTypeContainer;
