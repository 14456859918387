import { HierarchyAssignableFlag } from "../../../core/utilities/enums";

export interface SearchHierarchyTypesRequest {
    hierarchyAssignableFlag: HierarchyAssignableFlag | null;
}

export const emptySearchHierarchyTypesRequest = (): SearchHierarchyTypesRequest => ({
    hierarchyAssignableFlag: null,
});

const SearchParameters = {
    hierarchyAssignableFlag: "hierarchyAssignableFlag",
};

export const buildHierarchyTypesSearchParameters = (
    request: SearchHierarchyTypesRequest,
    searchParams: URLSearchParams
): URLSearchParams => {
    if (request.hierarchyAssignableFlag) {
        searchParams.set(
            SearchParameters.hierarchyAssignableFlag.toString(),
            request.hierarchyAssignableFlag.toString()
        );
    }

    return searchParams;
};
