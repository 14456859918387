import { HTTPError } from "ky";
import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { DissociateNestedQuestionEvent } from "../../../core/constants/application-insights-events";
import { ChecklistQuestions } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import {
    useDissociateNestedQuestion,
    useGetNestedQuestionDetailsById,
} from "../../../domain/viewmodels/questions/dissociate-nested-question-viewmodel";
import { DissociateButton } from "../../atoms/SbButton";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import { ActionConfirmationFragment } from "../../organisms/ActionConfirmationFragment";

const DissociateNestedQuestionContainer: React.FC = () => {
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const navigateSearch = useNavigateSearch();
    const [urlSearchParams] = useSearchParams();
    const menu = useMenu();
    const auth = useAuth();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const questionId = urlSearchParams.get("questionId") ?? "";
    const nestedQuestionId = Number(useParams().nestedQuestionId);

    const dissociateNestedQuestion = useDissociateNestedQuestion();
    const nestedQuestionDetails = useGetNestedQuestionDetailsById(nestedQuestionId);

    useLoader(
        isQueryLoading(nestedQuestionDetails) || isMutationLoading(dissociateNestedQuestion),
        DissociateNestedQuestionContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.QuestionSets);
    }, []);

    const buildDeleteButton = (): ReactElement<typeof Button> => (
        <DissociateButton onClick={handleDissociateNestedQuestion} />
    );

    const handleDissociateNestedQuestion = (): void => {
        dissociateNestedQuestion.mutate(nestedQuestionId, {
            onSuccess: async () => {
                trackAppInsightsEvent(
                    auth.email,
                    window.location.href,
                    DissociateNestedQuestionEvent
                );
                const params = [
                    createNavigateSearchParameter("success", "true"),
                    createNavigateSearchParameter(
                        "messageKey",
                        "DissociateNestedQuestionSuccessMessage"
                    ),
                ];

                navigateSearch(`${getPath(AccordionTitles.Questions)}/${questionId}`, params);
            },
            onError: (error: HTTPError) => {
                trackAppInsightsException(
                    auth.email,
                    window.location.href,
                    DissociateNestedQuestionEvent,
                    error
                );
                errorResponseToDisplayHandler(error);
            },
        });
    };

    const buildConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow(t("QuestionText"), nestedQuestionDetails.data?.questionText.text),
        new TextConfirmationRow(t("Type"), nestedQuestionDetails.data?.questionType.name),
        new TextConfirmationRow(t("AnswerType"), nestedQuestionDetails.data?.answerTypeDto.name),
    ];

    return (
        <>
            {isQuerySuccessful(nestedQuestionDetails) && (
                <ActionConfirmationFragment
                    pageHeading={t("DissociateNestedQuestionDisplay")}
                    panelTitle={t("DissociateNestedQuestionConfirmationDisplay")}
                    rows={buildConfirmationRows()}
                    primaryButton={buildDeleteButton()}
                    areQueriesSuccessful={isQuerySuccessful(nestedQuestionDetails)}
                />
            )}
        </>
    );
};

export default DissociateNestedQuestionContainer;
