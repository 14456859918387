import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaPlus, FaTrash } from "react-icons/fa";
import { SingleValue } from "react-select";
import { ActionItems, Common } from "../../core/constants/translation-namespace";
import {
    maxContentWidthSelectStyle,
    SectionVerticalSpace,
    StyledFormLabel,
} from "../../core/theme/global-styles";
import { getEnumsForType } from "../../core/utilities/enum-helper";
import { RaciParticipantDto } from "../../domain/dtos/actions/raci-participant-dto";
import { createReactSelectDto, ReactSelectDto } from "../../domain/dtos/common/react-select-dto";
import { BaseUserDto } from "../../domain/dtos/users/base-user-dto";
import RaciRelationship from "../../domain/enums/action-items/RaciRelationship";
import { SbSelect } from "../atoms/input/SbSelect";
import { SbButton } from "../atoms/SbButton";
import SbLabelText from "../atoms/SbLabelText";

export const RaciParticipant: React.FC<{
    useDefaults?: boolean;
    raciParticipants: RaciParticipantDto[];
    userList: BaseUserDto[];
    isLoading: boolean;
    onAddRaciParticipant: () => void;
    onRemoveRaciParticipant: (index: number) => void;
    onUpdateRaciParticipant: (index: number, raciRelationship: RaciRelationship) => void;
    setUserSearchText: React.Dispatch<React.SetStateAction<string | null>>;
    authUserDto: BaseUserDto;
}> = ({
    useDefaults,
    raciParticipants,
    userList,
    isLoading,
    onAddRaciParticipant,
    onRemoveRaciParticipant,
    onUpdateRaciParticipant,
    setUserSearchText,
    authUserDto,
}) => {
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });
    return (
        <>
            <SectionVerticalSpace />
            {useDefaults && (
                <>
                    <Form.Group as={Row} className="mb-3">
                        <StyledFormLabel column sm={2}>
                            <SbLabelText label={t("Responsible")} required />
                        </StyledFormLabel>

                        <Col md={2}>
                            <SbSelect
                                name={"responsibleRelationship"}
                                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                                searchable={false}
                                clearable={false}
                                disabled
                                styles={maxContentWidthSelectStyle}
                                defaultSelectedItem={createReactSelectDto(
                                    RaciRelationship.Responsible,
                                    RaciRelationship[RaciRelationship.Responsible]
                                )}
                                items={getEnumsForType(RaciRelationship).map((x) =>
                                    createReactSelectDto(x, RaciRelationship[x])
                                )}
                                itemLabel={(option: ReactSelectDto<RaciRelationship>) =>
                                    t(RaciRelationship[option.value])
                                }
                                itemValue={(option: ReactSelectDto<RaciRelationship>) =>
                                    option.value.toString()
                                }
                            />
                        </Col>
                        <Col md={4}>
                            <SbSelect
                                name={"responsibleUser"}
                                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                                searchable
                                clearable={false}
                                styles={maxContentWidthSelectStyle}
                                loading={isLoading}
                                items={userList}
                                itemLabel={(option: BaseUserDto) =>
                                    `${option.fullName} - ${option.emailAddress}`
                                }
                                itemValue={(option: BaseUserDto) => option.userId.toString()}
                                onSearchTextChanged={(text: string) => setUserSearchText(text)}
                                defaultSelectedItem={authUserDto}
                            />
                        </Col>
                        <Col md={2}>
                            <SbButton variant="danger" label="" icon={FaTrash} type={"button"} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <StyledFormLabel column sm={2}>
                            <SbLabelText label={t("Accountable")} required />
                        </StyledFormLabel>

                        <Col md={2}>
                            <SbSelect
                                name={"accountableRelationship"}
                                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                                searchable={false}
                                clearable={false}
                                disabled
                                styles={maxContentWidthSelectStyle}
                                defaultSelectedItem={createReactSelectDto(
                                    RaciRelationship.Accountable,
                                    RaciRelationship[RaciRelationship.Accountable]
                                )}
                                items={getEnumsForType(RaciRelationship).map((x) =>
                                    createReactSelectDto(x, RaciRelationship[x])
                                )}
                                itemLabel={(option: ReactSelectDto<RaciRelationship>) =>
                                    t(RaciRelationship[option.value])
                                }
                                itemValue={(option: ReactSelectDto<RaciRelationship>) =>
                                    option.value.toString()
                                }
                            />
                        </Col>
                        <Col md={4}>
                            <SbSelect
                                name={"accountableUser"}
                                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                                searchable
                                clearable={false}
                                items={userList}
                                loading={isLoading}
                                styles={maxContentWidthSelectStyle}
                                itemLabel={(option: BaseUserDto) =>
                                    `${option.fullName} - ${option.emailAddress}`
                                }
                                itemValue={(option: BaseUserDto) => option.userId.toString()}
                                onSearchTextChanged={(text: string) => setUserSearchText(text)}
                            />
                        </Col>
                        <Col md={2}>
                            <SbButton variant="danger" label="" icon={FaTrash} type={"button"} />
                        </Col>
                    </Form.Group>
                </>
            )}

            {raciParticipants &&
                raciParticipants.map((raciParticipant) => (
                    <Form.Group as={Row} className="mb-3">
                        <StyledFormLabel column sm={2}>
                            <SbLabelText
                                label={t(RaciRelationship[raciParticipant.raciRelationship])}
                                required
                            />
                        </StyledFormLabel>

                        <Col md={2}>
                            <SbSelect
                                name={`raciRelationship${raciParticipant.index}`}
                                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                                searchable={false}
                                clearable={false}
                                styles={maxContentWidthSelectStyle}
                                defaultSelectedItem={createReactSelectDto(
                                    raciParticipant.raciRelationship,
                                    RaciRelationship[raciParticipant.raciRelationship]
                                )}
                                items={getEnumsForType(RaciRelationship).map((x) =>
                                    createReactSelectDto(x, RaciRelationship[x])
                                )}
                                itemLabel={(option: ReactSelectDto<RaciRelationship>) =>
                                    t(RaciRelationship[option.value])
                                }
                                itemValue={(option: ReactSelectDto<RaciRelationship>) =>
                                    option.value.toString()
                                }
                                onChange={(option: SingleValue<ReactSelectDto<RaciRelationship>>) =>
                                    onUpdateRaciParticipant(raciParticipant.index!, option!.value)
                                }
                            />
                        </Col>
                        <Col md={4}>
                            <SbSelect
                                name={`userId${raciParticipant.index}`}
                                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                                searchable
                                clearable={false}
                                loading={isLoading}
                                items={userList}
                                itemLabel={(option: BaseUserDto) =>
                                    `${option.fullName} - ${option.emailAddress}`
                                }
                                itemValue={(option: BaseUserDto) => option.userId.toString()}
                                onSearchTextChanged={(text: string) => setUserSearchText(text)}
                                defaultSelectedItem={raciParticipant.userDto}
                                styles={maxContentWidthSelectStyle}
                            />
                        </Col>
                        <Col md={2}>
                            <SbButton
                                variant="danger"
                                label=""
                                icon={FaTrash}
                                type={"button"}
                                onClick={() => onRemoveRaciParticipant(raciParticipant.index!)}
                            />
                        </Col>
                    </Form.Group>
                ))}

            <Form.Group as={Row} className="mb-3">
                <Col md={2}></Col>
                <Col md={3}></Col>
                <Col md={3}></Col>
                <Col md={1}>
                    <SbButton
                        variant="primary"
                        label=""
                        icon={FaPlus}
                        type={"button"}
                        onClick={onAddRaciParticipant}
                    />
                </Col>
            </Form.Group>
        </>
    );
};
