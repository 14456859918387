import ReviewStatus from "../../enums/review-reports/review-status";
import ReviewReportSetFileResponse from "../../responses/review-reports/review-report-set-file-response";

class ReviewReportSetFileDto {
    reviewReportSetFileId: number;
    reviewReportSetId: number;
    version: string;
    fileName: string;
    reviewStatus: ReviewStatus;
    datePublishedUtc: Date | null;

    public constructor(response: ReviewReportSetFileResponse) {
        this.reviewReportSetFileId = response.reviewReportSetFileId;
        this.reviewReportSetId = response.reviewReportSetId;
        this.version = response.version;
        this.fileName = response.fileName;
        this.reviewStatus = response.reviewStatus;
        this.datePublishedUtc = response.datePublishedUtc;
    }
}

export default ReviewReportSetFileDto;
