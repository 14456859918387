import { useMutation, UseMutationResult, useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    getActionItemSubTypesByActionItemTypeNodeId,
    getActionItemTypes,
} from "../../../data/services/action-items/action-item-types-service";
import { getActionItemImportance } from "../../../data/services/actions/action-item-importance-service";
import {
    createActionItem,
    getAdditionalFields,
} from "../../../data/services/actions/action-items-service";
import { filterUsers } from "../../../data/services/users/users-service";
import { ActionItemDynamicFieldDetailsDto } from "../../dtos/action-items/action-item-dynamic-field-details-dto";
import {
    ActionItemTypeDto,
    toActionItemTypeDtos,
} from "../../dtos/action-items/action-item-type-dto";
import { toActionItemDynamicFieldDetailsDtos } from "../../dtos/actions/action-item-dynamic-fields-dto";
import {
    ActionItemImportanceDto,
    toActionItemImportanceDtos,
} from "../../dtos/actions/action-item-importance-dto";
import { CreateActionItemDto } from "../../dtos/actions/create-action-item-dto";
import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import {
    BaseUserDto,
    toBaseUserDtosFromPaginatedUserResponse,
} from "../../dtos/users/base-user-dto";
import { CreateActionItemRequest } from "../../requests/actions/create-action-item-request";
import { createFilterUsersSearchRequest } from "../../requests/users/filter-users-request";
import { ActionItemResponse } from "../../responses/actions/action-item-response";
import { Response } from "../../responses/common/response-response";

export const useCreateActionItem = (): UseMutationResult<
    Response<ActionItemResponse>,
    HTTPError,
    CreateActionItemDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: CreateActionItemDto) => {
        const request = new CreateActionItemRequest(mutationKey);

        return createActionItem(url.baseUrl, request);
    });
};

export const useGetActionItemFields = (
    actionItemTypeNodeId: number | null,
    paginationDto: BasePaginationDto,
    searchText: string | null
): [
    UseQueryResult<ActionItemTypeDto[], HTTPError>,
    UseQueryResult<ActionItemTypeDto[], HTTPError>,
    UseQueryResult<ActionItemImportanceDto[], HTTPError>,
    UseQueryResult<ActionItemDynamicFieldDetailsDto[], HTTPError>,
    UseQueryResult<BaseUserDto[], HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getActionItemTypes"],
                queryFn: () => getActionItemTypes(url.baseUrl),
                select: toActionItemTypeDtos,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getActionItemSubTypesByActionItemTypeNodeId", actionItemTypeNodeId],
                enabled: actionItemTypeNodeId != null,
                queryFn: () =>
                    getActionItemSubTypesByActionItemTypeNodeId(url.baseUrl, actionItemTypeNodeId!),
                select: toActionItemTypeDtos,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getActionItemImportance"],
                queryFn: () => getActionItemImportance(url.baseUrl),
                select: toActionItemImportanceDtos,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getAdditionalFields", actionItemTypeNodeId],
                enabled: actionItemTypeNodeId != null,
                queryFn: () => getAdditionalFields(url.baseUrl, actionItemTypeNodeId!),
                select: toActionItemDynamicFieldDetailsDtos,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["filterUsers", paginationDto, searchText],
                queryFn: () =>
                    filterUsers(
                        url.baseUrl,
                        createFilterUsersSearchRequest(
                            searchText!,
                            paginationDto.pageNumber,
                            paginationDto.pageSize
                        )
                    ),
                select: toBaseUserDtosFromPaginatedUserResponse,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<ActionItemTypeDto[], HTTPError>,
        UseQueryResult<ActionItemTypeDto[], HTTPError>,
        UseQueryResult<ActionItemImportanceDto[], HTTPError>,
        UseQueryResult<ActionItemDynamicFieldDetailsDto[], HTTPError>,
        UseQueryResult<BaseUserDto[], HTTPError>,
    ];
};
