import { useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useAuth } from "../../../core/store/auth-context";
import { useUrl } from "../../../core/store/url-context";
import { getLanguages } from "../../../data/services/language/language-service";
import { getUserFeatures } from "../../../data/services/tenants/feature-service";
import { getTimeZonesFromNodeTypeValues } from "../../../data/services/timezones/time-zones-service";
import { getUserClaim, getUserDetails } from "../../../data/services/users/users-service";
import { LanguageDto, toLanguageDtos } from "../../dtos/language/language-dto";
import { FeatureDto, transformToFeatureDtos } from "../../dtos/tenants/feature-dto";
import TimeZoneDetailsDto from "../../dtos/timezone/time-zone-details-dto";
import { toUserClaimDto, UserClaimDto } from "../../dtos/users/user-claim-dto";
import { toViewUserDetailsDto, ViewUserDetailsDto } from "../../dtos/users/user-details-dto";

export const useGetProfile = (
    userId: number,
    isAdmin: boolean
): [
    UseQueryResult<LanguageDto[], HTTPError>,
    UseQueryResult<ViewUserDetailsDto, HTTPError>,
    UseQueryResult<UserClaimDto, HTTPError>,
    UseQueryResult<TimeZoneDetailsDto[], HTTPError>,
    UseQueryResult<FeatureDto[], HTTPError>,
] => {
    const url = useUrl();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    // If admin is impersonating, use the admin url
    const urlToUse = auth.isImpersonating ? url.originalBaseUrl : url.baseUrl;

    return useQueries({
        queries: [
            {
                queryKey: ["getLanguages"],
                queryFn: () => getLanguages(urlToUse),
                select: toLanguageDtos,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getUserDetails", userId],
                queryFn: () => getUserDetails(urlToUse, userId),
                select: toViewUserDetailsDto,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getUserClaim", userId],
                queryFn: () => getUserClaim(urlToUse, userId),
                select: toUserClaimDto,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getTimeZonesFromNodeTypeValues"],
                queryFn: () => getTimeZonesFromNodeTypeValues(urlToUse),
                select: TimeZoneDetailsDto.toTimeZoneDetailsDtos,
                onError: errorResponseToDisplayHandler,
                enabled: !isAdmin,
            },
            {
                queryKey: ["getUserFeatures"],
                queryFn: () => getUserFeatures(url.originalBaseUrl),
                select: transformToFeatureDtos,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<LanguageDto[], HTTPError>,
        UseQueryResult<ViewUserDetailsDto, HTTPError>,
        UseQueryResult<UserClaimDto, HTTPError>,
        UseQueryResult<TimeZoneDetailsDto[], HTTPError>,
        UseQueryResult<FeatureDto[], HTTPError>,
    ];
};
