import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Common, Tenants } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isMutationLoading,
} from "../../../core/utilities/responseStateHelper";
import { FeatureDto } from "../../../domain/dtos/tenants/feature-dto";
import {
    useGetTenantDetails,
    useUpdateTenantDetails,
} from "../../../domain/viewmodels/tenants/edit-tenant-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import SbFormCheckFieldGroup from "../../molecules/input/SbFormCheckFieldGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const EditTenantContainer: React.FC = () => {
    const tenantId = Number(useParams().tenantId);

    const menu = useMenu();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: Tenants });

    const updateTenantDetails = useUpdateTenantDetails();
    const getTenantDetails = useGetTenantDetails(tenantId);

    const features = getTenantDetails[0].data;
    const tenantDetails = getTenantDetails[1].data;

    useLoader(
        areQueriesLoading(getTenantDetails) || isMutationLoading(updateTenantDetails),
        EditTenantContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.TenantManagement);
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);

        let tenantFeatures: FeatureDto[] = [];

        features!.forEach((x) => {
            const feature = formData.get(`${x.name}`);
            feature && tenantFeatures.push(x);
        });

        updateTenantDetails.mutate(
            {
                tenantId: tenantId,
                formData: new FormData(event.currentTarget),
                features: tenantFeatures,
            },
            {
                onSuccess: async () =>
                    navigate(`${getPath(DrawerTitles.TenantManagement)}/${tenantId}`),
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    return (
        <>
            <PageHeading>{t("TenantEditTitle")}</PageHeading>
            <SectionVerticalSpace />

            {areQueriesSuccessful(getTenantDetails) && (
                <TextTitledPanel title={t("EditTenant")}>
                    <Form onSubmit={handleSubmit}>
                        <SbFormTextFieldGroup
                            name="name"
                            label={t("Name", { keyPrefix: Common })}
                            type="text"
                            required
                            maxLength={50}
                            defaultValue={tenantDetails!.name}
                        />

                        <SbFormTextFieldGroup
                            name="description"
                            label={t("Description", { keyPrefix: Common })}
                            type="text"
                            maxLength={500}
                            defaultValue={tenantDetails!.description ?? ""}
                        />

                        <SbFormTextFieldGroup
                            name="connectionString"
                            label={t("WebConnectionString")}
                            type="text"
                            required
                            maxLength={500}
                            defaultValue={tenantDetails!.connectionString}
                        />

                        <SbFormTextFieldGroup
                            name="auditConnectionString"
                            label={t("WebAuditConnectionString")}
                            type="text"
                            maxLength={500}
                            required
                            defaultValue={tenantDetails!.auditConnectionString}
                        />

                        <SbFormTextFieldGroup
                            name="workerConnectionString"
                            label={t("WorkerConnectionString")}
                            type="text"
                            maxLength={500}
                            required
                            defaultValue={tenantDetails!.workerConnectionString}
                        />

                        <SbFormTextFieldGroup
                            name="workerAuditConnectionString"
                            label={t("WorkerAuditConnectionString")}
                            type="text"
                            maxLength={500}
                            required
                            defaultValue={tenantDetails!.workerAuditConnectionString}
                        />

                        <SbFormTextFieldGroup
                            name="baseUrl"
                            label={t("BaseURL")}
                            type="text"
                            maxLength={50}
                            required
                            defaultValue={tenantDetails!.baseUrl}
                        />

                        <SbFormTextFieldGroup
                            name="theme"
                            label={t("CSSTheme")}
                            maxLength={50}
                            type="text"
                            defaultValue={tenantDetails!.theme ?? ""}
                        />

                        <SbFormCheckFieldGroup
                            fieldLabel={t("Features")}
                            type="checkbox"
                            values={
                                features!.map((x) => ({
                                    name: x.name,
                                    label: x.name,
                                    defaultSelected: tenantDetails!.features.find(
                                        (y) => y.featureId === x.featureId
                                    )
                                        ? true
                                        : false,
                                })) ?? []
                            }
                        />

                        <EndAlignedDiv>
                            <SaveButton type="submit" />
                            <CancelButton onClick={() => navigate(-1)} />
                        </EndAlignedDiv>
                    </Form>
                </TextTitledPanel>
            )}
        </>
    );
};

export default EditTenantContainer;
