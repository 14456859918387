import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { QuestionSetQuestionCommentResponse } from "../../responses/review-reports/question-set-question-comment-response";
import { BaseUserDto } from "../users/base-user-dto";

export class QuestionSetQuestionCommentDto {
    questionSetQuestionCommentId: number;
    userId: number;
    questionSetInstanceId: number;
    questionSetInstanceAnswerId: number;
    commentDateTimeLocal: string;
    commentText: string;
    userDto: BaseUserDto;

    public constructor(questionSetQuestionCommentResponse: QuestionSetQuestionCommentResponse) {
        this.questionSetQuestionCommentId =
            questionSetQuestionCommentResponse.questionSetQuestionCommentId;
        this.questionSetInstanceId = questionSetQuestionCommentResponse.questionSetInstanceId;
        this.questionSetInstanceAnswerId =
            questionSetQuestionCommentResponse.questionSetInstanceAnswerId;
        this.commentDateTimeLocal = questionSetQuestionCommentResponse.commentDateTimeUtc
            ? toDateAndTimeFormat(questionSetQuestionCommentResponse.commentDateTimeUtc.toString())
            : "";
        this.commentText = questionSetQuestionCommentResponse.commentText;
        this.userId = questionSetQuestionCommentResponse.userId;
        this.userDto = questionSetQuestionCommentResponse.userResponse;
    }

    public static toQuestionSetQuestionCommentDtoDtos = (
        response: QuestionSetQuestionCommentResponse[]
    ): QuestionSetQuestionCommentDto[] =>
        response?.map((x) => new QuestionSetQuestionCommentDto(x));
}
