import { useMutation, UseMutationResult, useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { convertToggleValueToBoolean } from "../../../core/utilities/convertToBoolean";
import { TableRow } from "../../../core/utilities/customTypes";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import {
    associateNode,
    getAssociatedAdhocQuestionSets,
    getChecklistDetails,
} from "../../../data/services/checklists/checklist-service";
import {
    filterNodeTypeValues,
    getChecklistAssignmentNodeTypeValues,
} from "../../../data/services/hierarchy/node-type-values-service";
import { Response } from "../../../domain/responses/common/response-response";
import { AssociateChecklistNodeDto } from "../../dtos/checklists/associate-checklist-node-dto";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { PaginationDto } from "../../dtos/common/pagination-dto";
import {
    NodeTypeValueDto,
    toNodeTypeValueDtosFromPaginatedResponse,
} from "../../dtos/hierarchy/node-type-value-dto";
import { createAssociateChecklistNodeRequest } from "../../requests/checklists/associate-checklist-node-request";
import CreateQuestionSetActionRequest from "../../requests/checklists/create-question-set-action-request";
import { createFilterChecklistAssociationRequest } from "../../requests/checklists/filter-checklist-association-request";
import { SearchChecklistAssociationRequest } from "../../requests/checklists/search-checklist-association-request";
import { createBasePaginationRequest } from "../../requests/common/base-pagination-request";
import { createPaginationRequestFromDto } from "../../requests/common/pagination-request";
import { createFilterNodeTypeValuesRequest } from "../../requests/hierarchy/filter-node-type-values-request";
import { AssociatedChecklistQuestionSetResponse } from "../../responses/checklists/associated-checklist-question-set-response";
import { ChecklistResponse } from "../../responses/checklists/checklist-response";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { NodeTypeValueDetailsResponse } from "../../responses/hierarchy/node-type-value-details-response";

export const useGetAssignNodeTypeValueDetails = (
    nodeId: number,
    checklistId: number,
    questionSetsPaginationDto: PaginationDto,
    searchRequest: SearchChecklistAssociationRequest
): [
    UseQueryResult<NodeTypeValueDto[], HTTPError>,
    UseQueryResult<TableRow<number>[], HTTPError>,
    UseQueryResult<boolean, HTTPError>,
    UseQueryResult<PaginatedTableDto<number>, HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["filterNodeTypeValues"],
                queryFn: () =>
                    filterNodeTypeValues(
                        url.baseUrl,
                        createFilterNodeTypeValuesRequest(
                            null,
                            null,
                            null,
                            true,
                            createBasePaginationRequest(1, 1000)
                        )
                    ),
                keepPreviousData: true,
                select: toNodeTypeValueDtosFromPaginatedResponse,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getChecklistAssignmentNodeTypeValues", nodeId],
                queryFn: () => getChecklistAssignmentNodeTypeValues(url.baseUrl, nodeId),
                select: transformToDataTableRows,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getChecklistDetails", checklistId],
                queryFn: () => getChecklistDetails(url.baseUrl, checklistId),
                select: (response: Response<ChecklistResponse>): boolean => response.data.hasAdhoc,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: [
                    "getAssociatedAdhocQuestionSets",
                    questionSetsPaginationDto,
                    searchRequest,
                ],
                queryFn: () =>
                    getAssociatedAdhocQuestionSets(
                        url.baseUrl,
                        createFilterChecklistAssociationRequest(
                            createPaginationRequestFromDto(questionSetsPaginationDto),
                            searchRequest,
                            null,
                            null
                        )
                    ),
                keepPreviousData: true,
                select: transformToQuestionSetsDataTableRows,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<NodeTypeValueDto[], HTTPError>,
        UseQueryResult<TableRow<number>[], HTTPError>,
        UseQueryResult<boolean, HTTPError>,
        UseQueryResult<PaginatedTableDto<number>, HTTPError>,
    ];
};

const transformToDataTableRows = (
    response: Response<NodeTypeValueDetailsResponse[]>
): TableRow<number>[] => {
    const responseData = response.data;

    return responseData.map((nodeTypeValueDetail) => ({
        metadata: nodeTypeValueDetail.nodeTypeValueId,
        showSelectAction: true,
        columns: [
            {
                value: nodeTypeValueDetail.fullName,
                type: DataTableColumnTypes.Text,
            },
            {
                value: nodeTypeValueDetail.value,
                type: DataTableColumnTypes.Text,
            },
        ],
    }));
};

const transformToQuestionSetsDataTableRows = (
    response: Response<PaginationResponse<AssociatedChecklistQuestionSetResponse>>
): PaginatedTableDto<number> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((questionSet) => ({
        metadata: questionSet.questionSetId,
        columns: [
            {
                // Name
                value: questionSet.name?.toString(),
                type: DataTableColumnTypes.Link,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};

export const useAssociateNode = (): UseMutationResult<
    Response<number | null>,
    HTTPError,
    AssociateChecklistNodeDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: AssociateChecklistNodeDto) => {
        const formData = mutationKey.formData;

        const request = createAssociateChecklistNodeRequest(
            mutationKey.checklistId,
            mutationKey.nodeId,
            mutationKey.nodeTypeValueIds,
            mutationKey.timeZoneNodeTypeValueId,
            mutationKey.appliesToUsers === "Any" ? 0 : 1,
            new Date(formData.get("activeDate")! as string),
            convertToggleValueToBoolean(formData.get("isTraining")! as string),
            convertToggleValueToBoolean(formData.get("allowActionItemTrigger")! as string),
            convertToggleValueToBoolean(formData.get("allowAdhocIntervalOverride")! as string),
            convertToggleValueToBoolean(formData.get("allowCustomNotifications")! as string),
            convertToggleValueToBoolean(formData.get("allowFileAttachment")! as string),
            mutationKey.userId,
            CreateQuestionSetActionRequest.constructFromCreateQuestionSetActionDtos(
                mutationKey.createQuestionSetActionDtos
            ),
            new Date(formData.get("expiryDate")! as string)
        );

        return associateNode(url.baseUrl, request);
    });
};
