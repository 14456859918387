export default class CreateQuestionSetActionDto {
    questionSetId: number;
    calendarEntry: boolean;
    review: boolean;
    startDate: Date;
    endDate: Date;

    public constructor(
        questionSetId: number,
        calendarEntry: boolean,
        review: boolean,
        startDate: Date,
        endDate: Date
    ) {
        this.questionSetId = questionSetId;
        this.calendarEntry = calendarEntry;
        this.review = review;
        this.startDate = startDate;
        this.endDate = endDate;
    }
}
