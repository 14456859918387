import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Common } from "../../../core/constants/translation-namespace";
import {
    ContentContainer,
    EndAlignedDiv,
    SectionContainer,
} from "../../../core/theme/global-styles";
import { ResetButton, SearchButton } from "../../atoms/SbButton";
import { FilterInput } from "../../molecules/FilterInput";

export const QuestionCategoriesFilter: React.FC<{
    questionCategoryName: string | null;
    changeQuestionCategoryName: (questionCategoryName: string) => void;
    search: () => void;
    resetFilter: () => void;
}> = ({ questionCategoryName, changeQuestionCategoryName, search, resetFilter }) => {
    const { t } = useTranslation("translation", { keyPrefix: Common });
    return (
        <ContentContainer>
            <SectionContainer>
                <Row>
                    <Col>
                        <FilterInput
                            title={t("Name")}
                            name={"Name"}
                            type={"text"}
                            onChangeEventHandler={changeQuestionCategoryName}
                            value={questionCategoryName ?? ""}
                        />
                    </Col>
                </Row>
            </SectionContainer>
            <EndAlignedDiv>
                <SearchButton onClick={search} />
                <ResetButton onClick={resetFilter} />
            </EndAlignedDiv>
        </ContentContainer>
    );
};
