import ReactWebChat, { createDirectLine } from "botframework-webchat";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Common } from "../../../core/constants/translation-namespace";
import { useAuth } from "../../../core/store/auth-context";
import LocalStorageHelper from "../../../core/utilities/local-storage-helper";
import { isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import { useGetCreateNalaConversationToken } from "../../../domain/viewmodels/nala/nala-viewmodel";

const NalaChatboxImg = styled.img`
    cursor: pointer;
    border: none;
    position: fixed;
    z-index: 2242000;
    bottom: 10px;
    left: 15px;
    -webkit-filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
    width: 90px;
    height: 80px;
    padding-left: 0;
`;

const NalaChatboxDiv = styled.div`
    width: 384px;
    background-color: #fff;
    position: fixed;
    z-index: 2243000;
    bottom: 80px;
    left: 120px;
    border-radius: 0.35rem;
    box-shadow: 10px 10px 28px 0 #00000029;
`;

const TerminateNalaButton = styled.button`
    float: right;
    font-size: 1.5rem;
    font-weight: 500;
    color: #fff;
    opacity: 0.9;
    background-color: transparent;
    border: 0;
    height: fit-content;
    padding: 0 15px 0 0;

    &:focus {
        outline: none;
    }
`;

const NalaHeaderDiv = styled.div`
    width: 100%;
    display: flex;
    background-color: #1836a5;
    color: #fff;
    height: 80px;
    border-radius: 0.35rem 0.35rem 0 0;
    justify-content: space-between;
`;

const NalaHeaderEdgeDiv = styled.div`
    width: 50px;
`;

const NalaHeaderTextDiv = styled.div`
    font-size: 1.5rem;
    font-weight: 500;
    align-self: center;
`;

const AskNalaDiv = styled.div`
    padding: 0.35rem;
    max-height: 60vh;
    height: 30rem;
`;

const WelcomeMessagePopupDiv = styled.div`
    cursor: pointer;
    background-color: #fff;
    color: #0a2240;
    -webkit-filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
    border-radius: 4px;
    max-width: 320px;
    min-width: 260px;
    width: auto;
    padding: 0.5rem 1rem 0.5rem 0.75rem;
    height: auto;
    line-break: loose;
    position: fixed;
    bottom: 20px;
    left: 120px;
    z-index: 2242000;

    &::before {
        content: "";
        position: absolute;
        bottom: 10%;
        right: 100%;
        height: 0;
        border-right: 10px solid #fff;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
    }
`;

const WelcomeMessageSpan = styled.span``;

const AskNala: React.FC = () => {
    const auth = useAuth();
    const getConversationToken = useGetCreateNalaConversationToken();
    const { t } = useTranslation("translation", { keyPrefix: Common });

    const [conversationToken, setConversationToken] = useState<undefined | string>(undefined);
    const [nalaChatboxVisibility, setNalaChatboxVisibility] = useState<boolean>(false);
    const [nalaWelcomeMessagePopupVisible, setNalaWelcomeMessagePopupVisible] =
        useState<boolean>(false);

    const styleOptions = {
        bubbleBorderRadius: 5.6,
        bubbleFromUserBorderRadius: 5.6,
        backgroundColor: "#fff",
        bubbleTextColor: "#0a2240",
        bubbleFromUserTextColor: "#0a2240",
        bubbleBackground: "#f0f0f0",
        bubbleFromUserBackground: "#f0f0f0",
        bubbleBorderWidth: 0,
        bubbleFromUserBorderWidth: 0,
        sendBoxTextColor: "#3a4e66",
        sendBoxBackground: "#fff",
        sendBoxBorderTop: "solid 1px #0a2240",
        sendBoxButtonColor: "#0a2240",
        sendBoxButtonColorOnFocus: "#3a4e66",
        sendBoxButtonColorOnHover: "#3a4e66",
        subtle: "#0a2240",
        notificationText: "#0a2240",
    };

    useEffect(() => {
        setConversationToken(getConversationToken.data?.token);
    }, [getConversationToken]);

    const directLine = useMemo(
        () =>
            conversationToken &&
            createDirectLine({
                token: conversationToken,
            }),
        [conversationToken]
    );

    const showNalaChatbox = (): void => {
        setNalaChatboxVisibility(true);
        hideNalaWelcomeMessagePopup();
    };

    const hideNalaChatbox = (): void => {
        setNalaChatboxVisibility(false);
        setConversationToken(undefined);
    };

    const showNalaWelcomeMessagePopup = (): void => {
        setNalaWelcomeMessagePopupVisible(true);
    };

    const hideNalaWelcomeMessagePopup = (): void => {
        setNalaWelcomeMessagePopupVisible(false);
    };

    return (
        <>
            {auth.userName && isQuerySuccessful(getConversationToken) && (
                <>
                    {nalaChatboxVisibility && (
                        <NalaChatboxDiv>
                            <NalaHeaderDiv>
                                <NalaHeaderEdgeDiv />
                                <NalaHeaderTextDiv>{t("AskNala")}</NalaHeaderTextDiv>
                                <NalaHeaderEdgeDiv>
                                    <TerminateNalaButton onClick={() => hideNalaChatbox()}>
                                        ×
                                    </TerminateNalaButton>
                                </NalaHeaderEdgeDiv>
                            </NalaHeaderDiv>
                            <AskNalaDiv>
                                <ReactWebChat
                                    directLine={directLine}
                                    styleOptions={styleOptions}
                                    locale={LocalStorageHelper.getLanguageCulture()}
                                />
                            </AskNalaDiv>
                        </NalaChatboxDiv>
                    )}
                    <NalaChatboxImg
                        src="/images/Nala_head.png"
                        alt="..."
                        id="nala"
                        onClick={() => showNalaChatbox()}
                        onMouseOver={() => !nalaChatboxVisibility && showNalaWelcomeMessagePopup()}
                        onMouseLeave={() => hideNalaWelcomeMessagePopup()}
                    />
                    {nalaWelcomeMessagePopupVisible && (
                        <WelcomeMessagePopupDiv>
                            <WelcomeMessageSpan>{t("AskNalaWelcomeMessage")}</WelcomeMessageSpan>
                        </WelcomeMessagePopupDiv>
                    )}
                </>
            )}
        </>
    );
};

export default AskNala;
