import { useMutation, UseMutationResult, useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { getNullableFormField } from "../../../core/utilities/formDataHelper";
import { getFeatures } from "../../../data/services/tenants/feature-service";
import {
    getTenantDetails,
    updateTenantDetails,
} from "../../../data/services/tenants/tenant-service";
import { FeatureDto, transformToFeatureDtos } from "../../dtos/tenants/feature-dto";
import { TenantDetailsDto, toTenantDetailsDto } from "../../dtos/tenants/tenant-details-dto";
import { FeatureRequest, toFeatureRequest } from "../../requests/tenants/feature-request";
import { Response } from "../../responses/common/response-response";

interface UpdateDetailsParameters {
    tenantId: number;
    formData: FormData;
    features: FeatureDto[];
}

export const useGetTenantDetails = (
    tenantId: number
): [UseQueryResult<FeatureDto[], HTTPError>, UseQueryResult<TenantDetailsDto, HTTPError>] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const detailQueries = useQueries({
        queries: [
            {
                queryKey: ["getFeatures"],
                queryFn: () => getFeatures(url.originalBaseUrl),
                select: transformToFeatureDtos,
            },
            {
                queryKey: ["getTenantDetails", tenantId],
                queryFn: () => getTenantDetails(url.originalBaseUrl, tenantId),
                onError: errorResponseToDisplayHandler,
                select: toTenantDetailsDto,
            },
        ],
    });

    return detailQueries as [
        UseQueryResult<FeatureDto[], HTTPError>,
        UseQueryResult<TenantDetailsDto, HTTPError>,
    ];
};

export const useUpdateTenantDetails = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    UpdateDetailsParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: UpdateDetailsParameters) => {
        const formData = mutationKey.formData;

        const description: string | null = getNullableFormField(
            formData.get("description") as string
        );
        const theme: string | null = getNullableFormField(formData.get("theme") as string);

        const request = {
            tenantId: mutationKey.tenantId,
            name: formData.get("name")! as string,
            description: description,
            connectionString: formData.get("connectionString")! as string,
            auditConnectionString: formData.get("auditConnectionString")! as string,
            workerConnectionString: formData.get("workerConnectionString")! as string,
            workerAuditConnectionString: formData.get("workerAuditConnectionString")! as string,
            baseUrl: formData.get("baseUrl")! as string,
            theme: theme,
            features: buildFeatures(mutationKey.features, formData),
        };

        return updateTenantDetails(url.originalBaseUrl, request);
    });
};

const buildFeatures = (features: FeatureDto[], formData: FormData): FeatureRequest[] =>
    features.filter((x) => formData.get(x.name) === "on").map((y) => toFeatureRequest(y));
