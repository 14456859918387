import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
} from "../common/base-pagination-request";

export default class FilterByNodeWithARMonitoringRequest extends BasePaginationRequest {
    nodeId: number;
    isARMonitoring: boolean;

    public constructor(
        nodeId: number,
        isARMonitoring: boolean,
        basePaginationRequest: BasePaginationRequest
    ) {
        const { pageNumber, pageSize } = basePaginationRequest;
        super(pageNumber, pageSize);

        this.nodeId = nodeId;
        this.isARMonitoring = isARMonitoring;
    }

    public static createFilterByNodeWithARMonitoringRequest = (
        nodeId: number,
        isARMonitoring: boolean,
        basePaginationRequest: BasePaginationRequest
    ): FilterByNodeWithARMonitoringRequest =>
        new FilterByNodeWithARMonitoringRequest(nodeId, isARMonitoring, basePaginationRequest);

    public buildFilterByNodeWithARMonitoringSearchParameters = (): URLSearchParams => {
        const searchParams = buildBasePaginationSearchParameters(this);

        searchParams.set("nodeId", this.nodeId.toString());
        searchParams.set("isARMonitoring", this.isARMonitoring.toString());

        return searchParams;
    };
}
