import {
    BaseFilterNodeHierarchyRequest as BaseFilterHierarchyRequest,
    buildBaseFilterNodeHierarchySearchParameters,
} from "./base-filter-node-hierarchy-request";

export interface FilterNodeHierarchyByTypeRequest extends BaseFilterHierarchyRequest {
    hierarchyTypeId: number;
}

export const createFilterNodeHierarchyByTypeRequest = (
    hierarchyTypeId: number,
    depth: number
): FilterNodeHierarchyByTypeRequest => ({
    hierarchyTypeId: hierarchyTypeId,
    depth: depth,
});

const SearchParameters = {
    hierarchyTypeId: "hierarchyTypeId",
};

export const buildFilterNodeHierarchyByTypeSearchParameters = (
    request: FilterNodeHierarchyByTypeRequest
): URLSearchParams => {
    const searchParams = buildBaseFilterNodeHierarchySearchParameters(request);

    if (request.hierarchyTypeId) {
        searchParams.set(SearchParameters.hierarchyTypeId, request.hierarchyTypeId.toString());
    }

    return searchParams;
};
