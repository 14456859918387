import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { ChecklistAnswers, Common } from "../../../core/constants/translation-namespace";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    EndAlignedDiv,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { answerTypeColumns } from "../../../core/utilities/dataTableColumns";
import { getEnumsForType } from "../../../core/utilities/enum-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import {
    defaultFilterAnswerTypesDto,
    FilterAnswerTypesDto,
} from "../../../domain/dtos/answer-types/filter-answer-types-dto";
import { createReactSelectDto, ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import AnswerCategory from "../../../domain/enums/answer-types/answer-category";
import { AnswerTypeRoleGroup, hasRoleTypeInGroup } from "../../../domain/enums/Roles";
import { useFilterAnswerTypes } from "../../../domain/viewmodels/answer-types/view-answer-types-viewmodel";
import { CreateLink } from "../../atoms/SbLink";
import { DataTable } from "../../organisms/DataTable";
import { AnswerTypesFilter } from "../../organisms/filters/AnswerTypesFilter";

interface SearchParams {
    name: string | null;
    answerCategoryId: number | null;
}

const createSearchParams = (
    name: string | null,
    answerCategoryId: number | null
): SearchParams => ({
    name: name,
    answerCategoryId: answerCategoryId,
});

const defaultSearchParams = createSearchParams(null, null);

const answerCategoriesArray = getEnumsForType(AnswerCategory).map((x) =>
    createReactSelectDto(x, AnswerCategory[x])
);

const AnswerTypesContainer: React.FC = () => {
    const [filterAnswerTypesDto, setFilterAnswerTypesDto] = useState<FilterAnswerTypesDto>(
        defaultFilterAnswerTypesDto
    );
    const [searchParams, setSearchParams] = useState<SearchParams>(defaultSearchParams);

    const menu = useMenu();
    const navigate = useNavigate();
    const filterAnswerTypes = useFilterAnswerTypes(filterAnswerTypesDto);
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const toast = useToast();
    const filterAnswerTypesData = filterAnswerTypes.data;
    const { t } = useTranslation("translation", { keyPrefix: ChecklistAnswers });
    const auth = useAuth();

    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";

    useLoader(isQueryLoading(filterAnswerTypes), AnswerTypesContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.AnswerTypes);

        if (success) {
            toast.addToast(createSuccessToastProps([t(messageKey)]));

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    const navigateToViewAnswerTypeDetails = (answerTypeId: number): void => {
        navigate(`${getPath(AccordionTitles.AnswerTypes)}/${answerTypeId}`);
    };

    const navigateToEditAnswerType = (answerTypeId: number): void =>
        navigate(`${getPath(AccordionTitles.AnswerTypes)}/${answerTypeId}/edit`);

    const navigateToDeleteAnswerType = (answerTypeId: number): void =>
        navigate(`${getPath(AccordionTitles.AnswerTypes)}/${answerTypeId}/delete`);

    const changeAnswerTypeName = (answerTypeName: string): void =>
        setSearchParams({
            ...searchParams,
            name: answerTypeName !== "" ? answerTypeName : null,
        });

    const changeAnswerCategories = (option: SingleValue<ReactSelectDto<AnswerCategory>>): void =>
        setSearchParams({
            ...searchParams,
            answerCategoryId: option?.value ?? null,
        });

    const search = (): void =>
        setFilterAnswerTypesDto({
            ...filterAnswerTypesDto,
            pageNumber: 1,
            ...searchParams,
        });

    const resetFilter = (): void => {
        setFilterAnswerTypesDto(defaultFilterAnswerTypesDto);
        setSearchParams(createSearchParams(null, null));
    };

    return (
        <>
            <PageHeading>{t("AnswerTypes")}</PageHeading>
            <PageSubHeading>{t("AnswerTypeHeaderHelpText")}</PageSubHeading>
            <SectionVerticalSpace />

            <AnswerTypesFilter
                answerTypeName={searchParams.name}
                changeAnswerTypeName={changeAnswerTypeName}
                answerCategory={createReactSelectDto(
                    searchParams.answerCategoryId!,
                    AnswerCategory[searchParams.answerCategoryId!]
                )}
                answerCategories={answerCategoriesArray}
                changeAnswerCategories={changeAnswerCategories}
                search={search}
                resetFilter={resetFilter}
            />
            <SectionVerticalSpace />

            {hasRoleTypeInGroup(auth.userRoles, AnswerTypeRoleGroup.WriteRoles) && (
                <>
                    <EndAlignedDiv>
                        <CreateLink
                            label={t("CreateNew", { keyPrefix: Common })}
                            navigateTo={`${getPath(AccordionTitles.AnswerTypes)}/create`}
                        />
                    </EndAlignedDiv>
                    <SectionVerticalSpace />
                </>
            )}

            {isQuerySuccessful(filterAnswerTypes) && (
                <DataTable
                    keyPrefix={ChecklistAnswers}
                    columns={answerTypeColumns}
                    rows={filterAnswerTypesData!.rows}
                    viewItem={navigateToViewAnswerTypeDetails}
                    editItem={
                        hasRoleTypeInGroup(auth.userRoles, AnswerTypeRoleGroup.WriteRoles)
                            ? navigateToEditAnswerType
                            : undefined
                    }
                    deleteItem={
                        hasRoleTypeInGroup(auth.userRoles, AnswerTypeRoleGroup.WriteRoles)
                            ? navigateToDeleteAnswerType
                            : undefined
                    }
                    totalItems={filterAnswerTypesData!.recordCount}
                    paginationDto={filterAnswerTypesDto}
                    setPaginationDto={setFilterAnswerTypesDto}
                />
            )}
        </>
    );
};

export default AnswerTypesContainer;
