import { HTTPError } from "ky";
import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaUnlink } from "react-icons/fa";
import { useParams, useSearchParams } from "react-router-dom";
import { DissociateTaxonomyNodeFromChecklistEvent } from "../../../core/constants/application-insights-events";
import {
    ChecklistQuestions,
    Common,
    Hierarchy,
    NodeTypeValues,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { createAssociateDissociateChecklistTaxonomyNodeDto } from "../../../domain/dtos/checklists/associate-dissociate-checklist-taxonomy-node-dto";
import {
    useDissociateTaxonomyNode,
    useGetDissociateTaxonomyNodeDetails,
} from "../../../domain/viewmodels/checklists/dissociate-taxonomy-node-viewmodel";
import { SbButton } from "../../atoms/SbButton";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import { ActionConfirmationFragment } from "../../organisms/ActionConfirmationFragment";

const DissociateChecklistTaxonomyNodeContainer: React.FC = () => {
    const menu = useMenu();
    const [urlSearchParams] = useSearchParams();
    const navigateSearch = useNavigateSearch();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation");

    const checklistId = Number(useParams().checklistId);
    const nodeId = Number(urlSearchParams.get("nodeId"));

    const getDissociateTaxonomyNodeDetails = useGetDissociateTaxonomyNodeDetails(nodeId);
    const dissociateTaxonomyNode = useDissociateTaxonomyNode();

    const dissociateTaxonomyNodeDetailsResponseData = getDissociateTaxonomyNodeDetails.data;

    useLoader(
        isQueryLoading(getDissociateTaxonomyNodeDetails) ||
            isMutationLoading(dissociateTaxonomyNode),
        DissociateChecklistTaxonomyNodeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Checklists);
    }, []);

    useEffect(() => {
        isQueryLoading(getDissociateTaxonomyNodeDetails) ||
            isMutationLoading(dissociateTaxonomyNode);
    }, [getDissociateTaxonomyNodeDetails, dissociateTaxonomyNode]);

    const buildDissociateButton = (): ReactElement<typeof Button> => (
        <SbButton
            variant="danger"
            type="button"
            label={t("Dissociate", { keyPrefix: Common })}
            icon={FaUnlink}
            onClick={() => handleDissociateTaxonomyNode()}
        />
    );

    const handleDissociateTaxonomyNode = (): void => {
        dissociateTaxonomyNode.mutate(
            createAssociateDissociateChecklistTaxonomyNodeDto(checklistId, nodeId),
            {
                onSuccess: async () => {
                    trackAppInsightsEvent(
                        auth.email,
                        window.location.href,
                        DissociateTaxonomyNodeFromChecklistEvent
                    );

                    queryClient.invalidateQueries(["getAssociatedTaxonomyNodes"]);

                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "messageKey",
                            "SuccessfullyDissociatedTaxonomyNodeFromChecklist"
                        ),
                    ];

                    navigateSearch(`${getPath(AccordionTitles.Checklists)}/${checklistId}`, params);
                },
                onError: (error: HTTPError) => {
                    trackAppInsightsException(
                        auth.email,
                        window.location.href,
                        DissociateTaxonomyNodeFromChecklistEvent,
                        error
                    );
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    };

    const buildConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow(
            t("HierarchyLevel", { keyPrefix: Hierarchy }),
            dissociateTaxonomyNodeDetailsResponseData?.nodeTypeName
        ),
        new TextConfirmationRow(
            t("NodeTypeValue", { keyPrefix: NodeTypeValues }),
            dissociateTaxonomyNodeDetailsResponseData?.nodeTypeValueValue
        ),
        new TextConfirmationRow(
            t("Path", { keyPrefix: Common }),
            dissociateTaxonomyNodeDetailsResponseData?.path
        ),
    ];

    return isQuerySuccessful(getDissociateTaxonomyNodeDetails) ? (
        <ActionConfirmationFragment
            pageHeading={t("DissociateChecklistTaxonomyNode", { keyPrefix: ChecklistQuestions })}
            panelTitle={t("DissociateQuestionTaxonomyNodeConfirmationText", {
                keyPrefix: ChecklistQuestions,
            })}
            rows={buildConfirmationRows()}
            primaryButton={buildDissociateButton()}
        />
    ) : (
        <></>
    );
};

export default DissociateChecklistTaxonomyNodeContainer;
