import React, { useContext, useState } from "react";
import GenerateQuestionAnswerActionItemDto from "../dtos/action-items/generate-question-answer-action-item-dto";
import BaseQuestionAnswerDto from "../dtos/answer-capture/base-question-answer-dto";
import NestedQuestionAnswerDto from "../dtos/answer-capture/nested-question-answer-dto";
import QuestionAnswerDto from "../dtos/answer-capture/question-answer-dto";
import QuestionAnswerInstanceDto from "../dtos/answer-capture/question-answer-instance-dto";
import CreateQuestionSetInstanceAnswerReviewDto from "../dtos/question-set-instance-answers/create-question-set-instance-answer-review-dto";
import AnswerValueType from "../enums/answer-types/answer-value-type";

interface AnswerCaptureContextType {
    // Answers
    hasAnsweredQuestions: boolean;
    getQuestionAnswerValues: () => BaseQuestionAnswerDto[];
    getQuestionAnswer: (_: QuestionAnswerInstanceDto) => BaseQuestionAnswerDto | undefined;
    updateQuestionAnswerDtosForMutuallyExclusiveAnswer: (
        _questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        _predefinedAnswerId: number
    ) => void;
    updateQuestionAnswerDtosForInputValueAnswer: <TType>(
        _questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        _answerValueType: AnswerValueType,
        _answerValue: TType | null
    ) => void;
    updateQuestionAnswerDtosForComment: (
        _questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        _commentText: string | null
    ) => void;
    updateQuestionAnswerDtosForReviewComment: (
        _questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        _commentText: string | null
    ) => void;
    updateQuestionAnswerDtosForActionItemDate: (
        _questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        _date: Date
    ) => void;

    // Saved
    updateQuestionAnswerDtosFromSavedAnswers: (
        _questionAnswerDtos: QuestionAnswerDto[],
        _nestedQuestionAnswerDtos: NestedQuestionAnswerDto[]
    ) => void;

    // Review
    creatingQuestionReviewComment: CreateQuestionSetInstanceAnswerReviewDto | null;
    updateCreatingQuestionReviewComment: (_: CreateQuestionSetInstanceAnswerReviewDto) => void;
    createdQuestionReviewCommentSuccessfully: boolean;
    updateCreatedQuestionReviewCommentSuccessfully: (_: boolean) => void;

    // Action Item
    triggeringQuestionActionItem: GenerateQuestionAnswerActionItemDto | null;
    updateTriggeringQuestionActionItem: (_: GenerateQuestionAnswerActionItemDto) => void;
    triggeredQuestionActionItemSuccessfully: boolean;
    updateTriggeredQuestionActionItemSuccessfully: (_: boolean) => void;

    // Maintenance
    clearState: () => void;
}

const AnswerCaptureContext = React.createContext<AnswerCaptureContextType>({
    // Answers
    hasAnsweredQuestions: false,
    getQuestionAnswerValues: () => [],
    getQuestionAnswer: (_: QuestionAnswerInstanceDto) => undefined,
    updateQuestionAnswerDtosForMutuallyExclusiveAnswer: (
        _questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        _predefinedAnswerId: number
    ) => {},
    updateQuestionAnswerDtosForInputValueAnswer: <TType,>(
        _questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        _answerValueType: AnswerValueType,
        _answerValue: TType | null
    ) => {},
    updateQuestionAnswerDtosForActionItemDate: (
        _questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        _date: Date
    ) => {},
    updateQuestionAnswerDtosForReviewComment: (
        _questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        _commentText: string | null
    ) => {},
    updateQuestionAnswerDtosForComment: (
        _questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        _commentText: string | null
    ) => {},

    // Saved
    updateQuestionAnswerDtosFromSavedAnswers: (
        _questionAnswerDtos: QuestionAnswerDto[],
        _nestedQuestionAnswerDtos: NestedQuestionAnswerDto[]
    ) => {},

    // Review
    creatingQuestionReviewComment: null,
    updateCreatingQuestionReviewComment: (_: CreateQuestionSetInstanceAnswerReviewDto) => {},
    createdQuestionReviewCommentSuccessfully: true,
    updateCreatedQuestionReviewCommentSuccessfully: (_: boolean) => {},

    // Action Item
    triggeringQuestionActionItem: null,
    updateTriggeringQuestionActionItem: (_: GenerateQuestionAnswerActionItemDto) => {},
    triggeredQuestionActionItemSuccessfully: true,
    updateTriggeredQuestionActionItemSuccessfully: (_: boolean) => {},

    // Maintenance
    clearState: () => {},
});

export const AnswerCaptureProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const [questionAnswerDtos, setQuestionAnswerDtos] = useState<
        Map<string, BaseQuestionAnswerDto>
    >(new Map<string, BaseQuestionAnswerDto>());
    const [hasAnsweredQuestions, setHasAnsweredQuestions] = useState<boolean>(false);
    const [creatingQuestionReviewComment, setCreatingQuestionReviewComment] =
        useState<CreateQuestionSetInstanceAnswerReviewDto | null>(null);
    const [createdQuestionReviewCommentSuccessfully, setCreatedQuestionReviewCommentSuccessfully] =
        useState(true);
    const [triggeringQuestionActionItem, setTriggeringQuestionActionItem] =
        useState<GenerateQuestionAnswerActionItemDto | null>(null);
    const [triggeredQuestionActionItemSuccessfully, setTriggeredQuestionActionItemSuccessfully] =
        useState(true);

    const updateQuestionAnswerDtosForMutuallyExclusiveAnswer = (
        questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        predefinedAnswerId: number
    ): void => {
        const existingAnswer = getQuestionAnswer(questionAnswerInstanceDto);
        if (existingAnswer === undefined) {
            const questionAnswerDto = BaseQuestionAnswerDto.constructForMutuallyExclusiveAnswer(
                questionAnswerInstanceDto,
                predefinedAnswerId
            );

            updateQuestionAnswerDtosForNewAnswer(questionAnswerInstanceDto, questionAnswerDto);
        } else {
            const updatingAnswer = assignOriginalToUpdatingAnswer(
                questionAnswerInstanceDto,
                existingAnswer
            );

            updatingAnswer.predefinedAnswerId = predefinedAnswerId;

            updateQuestionAnswerDtosExistingNewAnswer(
                questionAnswerInstanceDto,
                updatingAnswer,
                existingAnswer
            );
        }
    };

    const updateQuestionAnswerDtosForInputValueAnswer = <TType,>(
        questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        answerValueType: AnswerValueType,
        answerValue: TType | null
    ): void => {
        const existingAnswer = getQuestionAnswer(questionAnswerInstanceDto);
        if (existingAnswer === undefined) {
            const questionAnswerDto = BaseQuestionAnswerDto.constructForInputValueAnswer(
                questionAnswerInstanceDto,
                answerValueType,
                answerValue
            );

            updateQuestionAnswerDtosForNewAnswer(questionAnswerInstanceDto, questionAnswerDto);
        } else {
            const updatingAnswer = assignOriginalToUpdatingAnswer(
                questionAnswerInstanceDto,
                existingAnswer
            );

            updatingAnswer.setAnswerValueForInputValueAnswer(answerValueType, answerValue);

            handleNullableQuestionAnswerUpdate(
                questionAnswerInstanceDto,
                updatingAnswer,
                existingAnswer
            );
        }
    };

    const updateQuestionAnswerDtosForComment = (
        questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        commentText: string | null
    ): void => {
        const existingAnswer = getQuestionAnswer(questionAnswerInstanceDto);
        if (existingAnswer === undefined) {
            const questionAnswerDto = BaseQuestionAnswerDto.constructForComment(
                questionAnswerInstanceDto,
                commentText
            );

            updateQuestionAnswerDtosForNewAnswer(questionAnswerInstanceDto, questionAnswerDto);
        } else {
            const updatingAnswer = assignOriginalToUpdatingAnswer(
                questionAnswerInstanceDto,
                existingAnswer
            );

            updatingAnswer.setCommentText(commentText);

            handleNullableQuestionAnswerUpdate(
                questionAnswerInstanceDto,
                updatingAnswer,
                existingAnswer
            );
        }
    };

    const updateQuestionAnswerDtosForReviewComment = (
        questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        commentText: string | null
    ): void => {
        const existingAnswer = getQuestionAnswer(questionAnswerInstanceDto);
        if (existingAnswer === undefined) {
            const questionAnswerDto = BaseQuestionAnswerDto.constructForReviewComment(
                questionAnswerInstanceDto,
                commentText
            );

            updateQuestionAnswerDtosForNewAnswer(questionAnswerInstanceDto, questionAnswerDto);
        } else {
            const updatingAnswer = assignOriginalToUpdatingAnswer(
                questionAnswerInstanceDto,
                existingAnswer
            );

            updatingAnswer.setReviewCommentText(commentText);

            handleNullableQuestionAnswerUpdate(
                questionAnswerInstanceDto,
                updatingAnswer,
                existingAnswer
            );
        }
    };

    const updateQuestionAnswerDtosForActionItemDate = (
        questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        date: Date
    ): void => {
        const existingAnswer = getQuestionAnswer(questionAnswerInstanceDto);
        if (existingAnswer === undefined) {
            const questionAnswerDto = BaseQuestionAnswerDto.constructForActionItemDate(
                questionAnswerInstanceDto,
                date
            );

            updateQuestionAnswerDtosForNewAnswer(questionAnswerInstanceDto, questionAnswerDto);
        } else {
            const updatingAnswer = assignOriginalToUpdatingAnswer(
                questionAnswerInstanceDto,
                existingAnswer
            );

            updatingAnswer.actionItemDueDateTimeLocal = date;

            updateQuestionAnswerDtosExistingNewAnswer(
                questionAnswerInstanceDto,
                updatingAnswer,
                existingAnswer
            );
        }
    };

    const updateQuestionAnswerDtosFromSavedAnswers = (
        questionAnswerDtos: QuestionAnswerDto[],
        nestedQuestionAnswerDtos: NestedQuestionAnswerDto[]
    ): void => {
        const savedAnswers = new Map<string, BaseQuestionAnswerDto>();

        questionAnswerDtos.forEach((x) => {
            const { questionSetInstanceAnswerId, questionId, parentQuestionId } = x;
            const questionAnswerInstanceDto = new QuestionAnswerInstanceDto(
                questionSetInstanceAnswerId,
                questionId,
                parentQuestionId
            );

            savedAnswers.set(questionAnswerInstanceDto.getKey(), x);
        });

        nestedQuestionAnswerDtos.forEach((x) => {
            const { questionSetInstanceAnswerId, questionId, parentQuestionId } = x;
            const questionAnswerInstanceDto = new QuestionAnswerInstanceDto(
                questionSetInstanceAnswerId,
                questionId,
                parentQuestionId
            );

            savedAnswers.set(questionAnswerInstanceDto.getKey(), x);
        });

        setQuestionAnswerDtos(savedAnswers);
        setHasAnsweredQuestions(savedAnswers.size > 0);
    };

    const updateCreatedQuestionReviewCommentSuccessfully = (isSuccessful: boolean): void =>
        setCreatedQuestionReviewCommentSuccessfully(isSuccessful);

    const updateCreatingQuestionReviewComment = (
        dto: CreateQuestionSetInstanceAnswerReviewDto
    ): void => setCreatingQuestionReviewComment(dto);

    const updateTriggeredQuestionActionItemSuccessfully = (isSuccessful: boolean): void =>
        setTriggeredQuestionActionItemSuccessfully(isSuccessful);

    const updateTriggeringQuestionActionItem = (dto: GenerateQuestionAnswerActionItemDto): void =>
        setTriggeringQuestionActionItem(dto);

    const assignOriginalToUpdatingAnswer = (
        questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        existingAnswer: BaseQuestionAnswerDto
    ): BaseQuestionAnswerDto => {
        const updatingAnswer =
            BaseQuestionAnswerDto.constructFromQuestionAnswerInstanceDto(questionAnswerInstanceDto);

        Object.assign(updatingAnswer, existingAnswer);

        return updatingAnswer;
    };

    const handleNullableQuestionAnswerUpdate = (
        questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        updatedAnswer: BaseQuestionAnswerDto,
        existingAnswer: BaseQuestionAnswerDto
    ): void => {
        if (updatedAnswer.isNotAnswered()) {
            setQuestionAnswerDtos((prev) => {
                prev.delete(questionAnswerInstanceDto.getKey());

                setHasAnsweredQuestions(prev.size > 0);

                return prev;
            });

            return;
        }

        updateQuestionAnswerDtosExistingNewAnswer(
            questionAnswerInstanceDto,
            updatedAnswer,
            existingAnswer
        );
    };

    const updateQuestionAnswerDtosForNewAnswer = (
        questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        newAnswer: BaseQuestionAnswerDto
    ): void => {
        setQuestionAnswerDtos((prev) => prev.set(questionAnswerInstanceDto.getKey(), newAnswer));
        setHasAnsweredQuestions(true);
    };

    const updateQuestionAnswerDtosExistingNewAnswer = (
        questionAnswerInstanceDto: QuestionAnswerInstanceDto,
        updatedAnswer: BaseQuestionAnswerDto,
        existingAnswer: BaseQuestionAnswerDto
    ): void => {
        setQuestionAnswerDtos((prev) =>
            prev.set(questionAnswerInstanceDto.getKey(), {
                ...existingAnswer,
                ...updatedAnswer,
            } as BaseQuestionAnswerDto)
        );
    };

    const getQuestionAnswerValues = (): BaseQuestionAnswerDto[] =>
        Array.from(questionAnswerDtos.entries()).map((x) => x[1]);

    const getQuestionAnswer = (
        questionAnswerInstanceDto: QuestionAnswerInstanceDto
    ): BaseQuestionAnswerDto | undefined =>
        questionAnswerDtos.get(questionAnswerInstanceDto.getKey());

    const clearState = (): void => {
        setQuestionAnswerDtos(new Map<string, BaseQuestionAnswerDto>());
        setHasAnsweredQuestions(false);
        setCreatingQuestionReviewComment(null);
        setTriggeringQuestionActionItem(null);
        setCreatedQuestionReviewCommentSuccessfully(true);
        setTriggeredQuestionActionItemSuccessfully(true);
    };

    const value = {
        hasAnsweredQuestions,
        getQuestionAnswerValues,
        getQuestionAnswer,
        updateQuestionAnswerDtosForMutuallyExclusiveAnswer,
        updateQuestionAnswerDtosForInputValueAnswer,
        updateQuestionAnswerDtosForComment,
        updateQuestionAnswerDtosForReviewComment,
        updateQuestionAnswerDtosForActionItemDate,
        updateQuestionAnswerDtosFromSavedAnswers,
        creatingQuestionReviewComment,
        updateCreatingQuestionReviewComment,
        createdQuestionReviewCommentSuccessfully,
        updateCreatedQuestionReviewCommentSuccessfully,
        triggeringQuestionActionItem,
        updateTriggeringQuestionActionItem,
        triggeredQuestionActionItemSuccessfully,
        updateTriggeredQuestionActionItemSuccessfully,
        clearState,
    };

    return <AnswerCaptureContext.Provider value={value}>{children}</AnswerCaptureContext.Provider>;
};

export const useAnswerCapture = (): AnswerCaptureContextType => useContext(AnswerCaptureContext);
