import { CreateQuestionCategoryRequest } from "../../../domain/requests/question-categories/create-question-category-request";
import { EditQuestionCategoryRequest } from "../../../domain/requests/question-categories/edit-question-category-request";
import {
    buildFilterSearchParameters,
    FilterQuestionCategoryRequest,
} from "../../../domain/requests/question-categories/filter-question-category-request";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import { QuestionCategoryResponse } from "../../../domain/responses/question-category/question-category-response";
import { QuestionCategoryWithQuestionCountResponse } from "../../../domain/responses/question-category/question-category-with-question-count-response";
import client from "../../http-client";

const route = "questioncategories";

export const filterQuestionCategories = async (
    prefixUrl: string,
    request: FilterQuestionCategoryRequest
): Promise<Response<PaginationResponse<QuestionCategoryWithQuestionCountResponse>>> => {
    const searchParams = buildFilterSearchParameters(request);

    return await client
        .get(`${route}/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getQuestionCategoryDetails = async (
    prefixUrl: string,
    questionCategoryId: number
): Promise<Response<QuestionCategoryResponse>> =>
    await client
        .get(`${route}/${questionCategoryId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const createQuestionCategory = async (
    prefixUrl: string,
    request: CreateQuestionCategoryRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/create`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const editQuestionCategory = async (
    prefixUrl: string,
    request: EditQuestionCategoryRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/edit`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const deleteQuestionCategory = async (
    prefixUrl: string,
    questionCategoryId: number
): Promise<Response<boolean>> =>
    await client
        .delete(`${route}/delete/${questionCategoryId}`, {
            prefixUrl: prefixUrl,
        })
        .json();
