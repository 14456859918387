import { SuspendRequest } from "./suspend-request";

export interface SuspendChecklistAssignmentRequest extends SuspendRequest {
    checklistAssignmentId: number;
}

export const createSuspendChecklistAssignmentRequest = (
    checklistAssignmentId: number,
    suspensionDateTimeFrom: Date,
    suspensionDateTimeTo: Date
): SuspendChecklistAssignmentRequest => ({
    checklistAssignmentId,
    suspensionDateTimeFrom,
    suspensionDateTimeTo,
});
