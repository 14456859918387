import AppliesToUsers from "../../enums/checklists/AppliesToUsers";
import { BaseChecklistAssignmentResponse } from "../../responses/checklist-assignment/base-checklist-assignment-response";

export default class BaseChecklistAssignmentDto {
    checklistAssignmentId: number;
    checklistId: number;
    nodeId: number;
    isEnabled: boolean;
    isTraining: boolean;
    appliesToUsers: AppliesToUsers;
    allowActionItemTrigger: boolean;
    allowAdhocIntervalOverride: boolean;
    allowCustomNotifications: boolean;
    allowFileAttachment: boolean;

    public constructor(response: BaseChecklistAssignmentResponse) {
        const {
            checklistAssignmentId,
            checklistId,
            nodeId,
            isEnabled,
            isTraining,
            appliesToUsers,
            allowActionItemTrigger,
            allowAdhocIntervalOverride,
            allowCustomNotifications,
            allowFileAttachment,
        } = response;

        this.checklistAssignmentId = checklistAssignmentId;
        this.checklistId = checklistId;
        this.nodeId = nodeId;
        this.isEnabled = isEnabled;
        this.isTraining = isTraining;
        this.appliesToUsers = appliesToUsers;
        this.allowActionItemTrigger = allowActionItemTrigger;
        this.allowAdhocIntervalOverride = allowAdhocIntervalOverride;
        this.allowCustomNotifications = allowCustomNotifications;
        this.allowFileAttachment = allowFileAttachment;
    }
}
