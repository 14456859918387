import { HTTPError } from "ky";
import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { DissociateNodeFromChecklistEvent } from "../../../core/constants/application-insights-events";
import { ChecklistQuestions, Common, Nodes } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isMutationLoading,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import {
    useDissociateNode,
    useGetDissociateNodeDetails,
} from "../../../domain/viewmodels/checklists/dissociate-node-viewmodel";
import { DissociateButton } from "../../atoms/SbButton";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import { ActionConfirmationFragment } from "../../organisms/ActionConfirmationFragment";

const DissociateNodeContainer: React.FC = () => {
    const menu = useMenu();
    const [urlSearchParams] = useSearchParams();
    const navigateSearch = useNavigateSearch();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: Nodes });

    const checklistId = Number(useParams().checklistId);
    const nodeId = Number(urlSearchParams.get("nodeId"));
    const checklistAssignmentId = Number(urlSearchParams.get("checklistAssignmentId"));

    const getDissociateNodeDetails = useGetDissociateNodeDetails(checklistId, nodeId);
    const dissociateNode = useDissociateNode();

    useLoader(
        areQueriesLoading(getDissociateNodeDetails) || isMutationLoading(dissociateNode),
        DissociateNodeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Checklists);
    }, []);

    const buildDissociateButton = (): ReactElement<typeof Button> => (
        <DissociateButton onClick={handleDissociateNode} />
    );

    const handleDissociateNode = (): void => {
        dissociateNode.mutate(checklistAssignmentId, {
            onSuccess: async () => {
                trackAppInsightsEvent(
                    auth.email,
                    window.location.href,
                    DissociateNodeFromChecklistEvent
                );
                queryClient.invalidateQueries(["getAssociatedNodes"]);

                const params = [
                    createNavigateSearchParameter("success", "true"),
                    createNavigateSearchParameter(
                        "messageKey",
                        "SuccessfullyDissociatedNodeFromChecklist"
                    ),
                ];

                navigateSearch(`${getPath(AccordionTitles.Checklists)}/${checklistId}`, params);
            },
            onError: (error: HTTPError) => {
                trackAppInsightsException(
                    auth.email,
                    window.location.href,
                    DissociateNodeFromChecklistEvent,
                    error
                );
                errorResponseToDisplayHandler(error);
            },
        });
    };

    const buildConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow(t("Name", { keyPrefix: Common }), getDissociateNodeDetails[0].data),
        new TextConfirmationRow(
            t("Node", { keyPrefix: Nodes }),
            getDissociateNodeDetails[1].data?.path
        ),
    ];

    return areQueriesSuccessful(getDissociateNodeDetails) ? (
        <ActionConfirmationFragment
            pageHeading={t("DissociateChecklistNode", { keyPrefix: ChecklistQuestions })}
            panelTitle={t("DissociateChecklistNodeConfirmationDisplay", {
                keyPrefix: ChecklistQuestions,
            })}
            rows={buildConfirmationRows()}
            primaryButton={buildDissociateButton()}
        />
    ) : (
        <></>
    );
};

export default DissociateNodeContainer;
