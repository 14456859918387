import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import { Reports } from "../../../core/constants/translation-namespace";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    ContentContainer,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { checklistReportSummaryColumnNames } from "../../../core/utilities/dataTableColumns";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { DownloadFile } from "../../../core/utilities/FileDownload";
import { areQueriesLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import BaseChecklistDto from "../../../domain/dtos/checklists/base-checklist-dto";
import {
    BasePaginationDto,
    defaultBasePaginationDto,
} from "../../../domain/dtos/common/base-pagination-dto";
import { BaseNodeDetailsDto } from "../../../domain/dtos/hierarchy/base-node-details-dto";
import FilterChecklistReportSummariesDto from "../../../domain/dtos/reports/filter-checklist-report-summaries-dto";
import { BaseUserDto } from "../../../domain/dtos/users/base-user-dto";
import {
    useDownloadChecklistExcelReport,
    useFilterApplicableChecklists,
    useFilterChecklistReportSummaries,
    useFilterUsersBelowNode,
    useGetChecklistReportDetails,
    useGetFunctionUserNodes,
} from "../../../domain/viewmodels/reports/completed-answers-viewmodel";
import { DataTable } from "../../organisms/DataTable";
import ChecklistReportSummaryFilter from "../../organisms/filters/ChecklistReportSummaryFilter";
import ChecklistReportDetailsContainer from "./ChecklistReportDetailsContainer";

const CompletedAnswersContainer: React.FC = () => {
    const [filterChecklistReportSummariesDto, setFilterChecklistReportSummariesDto] =
        useState<FilterChecklistReportSummariesDto>(
            FilterChecklistReportSummariesDto.emptyFilterChecklistReportSummariesDto
        );
    const [paginationDto, setPaginationDto] = useState<BasePaginationDto>(defaultBasePaginationDto);
    const [questionSetInstanceId, setQuestionSetInstanceId] = useState<number | null>(null);
    const [showDetails, setShowDetails] = useState<boolean>(true);
    const [exportClicked, setExportClicked] = useState<boolean>(false);
    const [resetClicked, setResetClicked] = useState<boolean>(false);

    const menu = useMenu();
    const { t } = useTranslation("translation", { keyPrefix: Reports });

    const getFunctionUserNodes = useGetFunctionUserNodes();
    const filterUsersBelowNode = useFilterUsersBelowNode(
        filterChecklistReportSummariesDto?.nodeId ?? null
    );
    const filterApplicableChecklists = useFilterApplicableChecklists(
        filterChecklistReportSummariesDto?.nodeId ?? null
    );
    const filterChecklistReportSummaries = useFilterChecklistReportSummaries(
        filterChecklistReportSummariesDto,
        paginationDto
    );
    const getChecklistReportDetails = useGetChecklistReportDetails(questionSetInstanceId);
    const downloadChecklistExcelReport = useDownloadChecklistExcelReport(
        questionSetInstanceId,
        exportClicked
    );

    const filterChecklistReportSummariesData = filterChecklistReportSummaries.data;

    useLoader(
        areQueriesLoading([
            getFunctionUserNodes,
            filterUsersBelowNode,
            filterApplicableChecklists,
            filterChecklistReportSummaries,
            getChecklistReportDetails,
            downloadChecklistExcelReport,
        ]),
        CompletedAnswersContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Checklists);
        menu.changeActiveDrawerItem(DrawerTitles.CompletedAnswers);
    }, []);

    useEffect(() => {
        if (exportClicked && isQuerySuccessful(downloadChecklistExcelReport)) {
            DownloadFile(downloadChecklistExcelReport.data!);

            setExportClicked(false);
        }
    }, [downloadChecklistExcelReport]);

    useEffect(() => {
        if (resetClicked) {
            const { startDateUtc, endDateUtc, nodeId, userId, checklistId } =
                filterChecklistReportSummariesDto;

            const isValidSearchDto =
                startDateUtc !== null &&
                endDateUtc !== null &&
                nodeId !== null &&
                userId !== null &&
                checklistId !== null;

            if (isValidSearchDto) {
                setResetClicked(false);
            }
        }
    }, [filterChecklistReportSummariesDto, resetClicked]);

    const onFromDateChanged = (fromDate: Date): void => {
        setFilterChecklistReportSummariesDto((prev) => ({
            ...prev,
            startDateUtc: fromDate,
        }));
    };

    const onToDateChanged = (startDate: Date): void => {
        setFilterChecklistReportSummariesDto((prev) => ({
            ...prev,
            endDateUtc: startDate,
        }));
    };

    const onFunctionNodeChanged = (dto: SingleValue<BaseNodeDetailsDto>): void => {
        setFilterChecklistReportSummariesDto((prev) => ({
            ...prev,
            nodeId: dto!.nodeId,
            nodeDto: dto!,
            userId: null,
            userDto: null,
            checklistId: null,
            checklistDto: null,
        }));
    };

    const onUserChanged = (dto: SingleValue<BaseUserDto>): void => {
        setFilterChecklistReportSummariesDto((prev) => ({
            ...prev,
            userId: dto!.userId,
            userDto: dto!,
        }));
    };

    const onChecklistChanged = (dto: SingleValue<BaseChecklistDto>): void => {
        setFilterChecklistReportSummariesDto((prev) => ({
            ...prev,
            checklistId: dto!.checklistId,
            checklistDto: dto!,
        }));
    };

    const onResetClicked = (): void => {
        setFilterChecklistReportSummariesDto(
            FilterChecklistReportSummariesDto.emptyFilterChecklistReportSummariesDto
        );

        setResetClicked(true);
    };

    const onViewClicked = (questionSetInstanceId: number): void => {
        setQuestionSetInstanceId(questionSetInstanceId);
        setShowDetails(true);
    };

    const onExportClicked = (): void => {
        setExportClicked(true);
    };

    return (
        <>
            <PageHeading>{t("Reports")}</PageHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(getChecklistReportDetails) && (
                <ChecklistReportDetailsContainer
                    checklistReportDetailsDto={getChecklistReportDetails.data!}
                    onExportClicked={onExportClicked}
                    isVisible={showDetails}
                    updateIsVisible={setShowDetails}
                />
            )}

            {isQuerySuccessful(getFunctionUserNodes) && (
                <ChecklistReportSummaryFilter
                    functionNodes={getFunctionUserNodes.data!}
                    users={filterUsersBelowNode.data}
                    checklists={filterApplicableChecklists.data}
                    filterChecklistReportSummariesDto={filterChecklistReportSummariesDto}
                    onFromDateChanged={onFromDateChanged}
                    onToDateChanged={onToDateChanged}
                    onFunctionNodeChanged={onFunctionNodeChanged}
                    onUserChanged={onUserChanged}
                    onChecklistChanged={onChecklistChanged}
                    onResetClicked={onResetClicked}
                />
            )}

            {isQuerySuccessful(filterChecklistReportSummaries) && !resetClicked && (
                <>
                    <SectionVerticalSpace />
                    <ContentContainer>
                        <DataTable
                            rows={filterChecklistReportSummariesData!.rows}
                            columns={checklistReportSummaryColumnNames}
                            noResultsMessage={t("NoReportItemsFound") as string}
                            viewItem={onViewClicked}
                            paginationDto={paginationDto}
                            setPaginationDto={setPaginationDto}
                            totalItems={filterChecklistReportSummariesData!.recordCount}
                        />
                    </ContentContainer>
                </>
            )}
        </>
    );
};

export default CompletedAnswersContainer;
