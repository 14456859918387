import RaciRelationship from "../../enums/action-items/RaciRelationship";
import { RaciSetUserResponse } from "../../responses/actions/raci-set-user-response";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";

export class ActionItemRaciSetUserDto {
    label: string;
    value: string;

    public constructor(label: string, value: string) {
        this.label = label;
        this.value = value;
    }

    public static constructFromPaginatedResponse = (
        response: Response<PaginationResponse<RaciSetUserResponse>>
    ): ActionItemRaciSetUserDto[] => {
        let responsible = { label: "Responsible", value: "" };
        let accountable = { label: "Accountable", value: "" };
        let consulted = { label: "Consulted", value: "" };
        let informed = { label: "Informed", value: "" };

        const responseData = response.data;

        responseData.results!.map((x) => {
            switch (x.raciRelationship) {
                case RaciRelationship.Responsible:
                    return (responsible.value = `${responsible.value}${
                        responsible.value !== "" ? ", " : ""
                    } ${x.isDeleted ? "(inactive)" : ""} ${x.firstName} ${x.lastName} (${
                        x.emailAddress
                    })`);
                case RaciRelationship.Accountable:
                    return (accountable.value = `${accountable.value}${
                        accountable.value !== "" ? ", " : ""
                    } ${x.isDeleted ? "(inactive)" : ""} ${x.firstName} ${x.lastName} (${
                        x.emailAddress
                    })`);
                case RaciRelationship.Consulted:
                    return (consulted.value = `${consulted.value}${
                        consulted.value !== "" ? ", " : ""
                    } ${x.isDeleted ? "(inactive)" : ""} ${x.firstName} ${x.lastName} (${
                        x.emailAddress
                    })`);
                case RaciRelationship.Informed:
                    return (informed.value = `${informed.value}${
                        informed.value !== "" ? ", " : ""
                    } ${x.isDeleted ? "(inactive)" : ""} ${x.firstName} ${x.lastName} (${
                        x.emailAddress
                    })`);
                default:
                    return;
            }
        });

        const actionItemRaciSetUserDtos = [
            new ActionItemRaciSetUserDto(responsible.label, responsible.value),
            new ActionItemRaciSetUserDto(accountable.label, accountable.value),
            new ActionItemRaciSetUserDto(consulted.label, consulted.value),
            new ActionItemRaciSetUserDto(informed.label, informed.value),
        ];

        return actionItemRaciSetUserDtos;
    };
}
