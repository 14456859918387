import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import styled from "styled-components";
import {
    growStyledContentContainer,
    shrinkStyledContentContainer,
} from "../../core/animations/styledContentContainer";
import { useAuth } from "../../core/store/auth-context";
import { isQuerySuccessful } from "../../core/utilities/responseStateHelper";
import queryClient from "../../data/query-client";
import { useGetDisclaimer } from "../../domain/viewmodels/tenants/disclaimer-viewmodel";
import { StyledContentContainerProps } from "../pages/App";

const StyledFooterContainer = styled(Container)<StyledContentContainerProps>`
    margin: 0;
    position: relative;
    width: 100%;
    bottom: 0;
    z-index: 0;
    padding-left: ${(props) =>
        props.$drawerOpen ? props.theme.padding.drawerOpen : props.theme.padding.drawerClosed};
    padding-right: 0px;
    animation: ${(props) =>
            props.$animate
                ? props.$drawerOpen
                    ? shrinkStyledContentContainer
                    : growStyledContentContainer
                : undefined}
        0.3s linear;
    background-image: linear-gradient(
        180deg,
        ${(props) => props.theme.palette.primary} -600%,
        ${(props) => props.theme.palette.pink} 100%
    );
`;

const StyledRow = styled(Row)`
    margin: 0;
    padding-right: 0;
`;

const StyledContentCol = styled(Col)`
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
`;

const StyledImgCol = styled(Col)`
    display: flex;
    align-items: right;
    justify-content: right;
    margin: 0;
    padding-right: 0;
`;

const StyledImage = styled(Image)`
    height: 70px;
    width: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
`;

const StyledSpan = styled.span`
    font-size: 16px;
    color: #fff;
`;

const StyledBoldSpan = styled.span`
    font-size: 16px;
    color: #fff;
    font-weight: bold;
`;

const CenterHeading = styled.p`
    font-size: 16px;
    color: #fff;
    text-align: center;
`;

const Footer: React.FC<{ animate: boolean; draweropen: boolean }> = ({ animate, draweropen }) => {
    const auth = useAuth();

    const disclaimerDetails = useGetDisclaimer(auth.tenantId);

    const environment = process.env.REACT_APP_ENV;
    const isProductionEnvironment = environment?.toUpperCase() === "PROD";

    useEffect(() => {
        queryClient.refetchQueries(["getDisclaimer", auth.tenantId]);
    }, [auth.tenantId]);

    return (
        <>
            <StyledFooterContainer fluid $animate={animate} $drawerOpen={draweropen}>
                <StyledRow>
                    <StyledContentCol>
                        <StyledSpan>
                            {"© "} {new Date().getFullYear()}
                        </StyledSpan>
                        {isQuerySuccessful(disclaimerDetails) && (
                            <>
                                <StyledBoldSpan>{` ${disclaimerDetails.data?.name} `}</StyledBoldSpan>
                                <StyledSpan>{disclaimerDetails.data?.disclaimer ?? ""}</StyledSpan>
                            </>
                        )}
                    </StyledContentCol>
                    {isQuerySuccessful(disclaimerDetails) &&
                    disclaimerDetails.data?.imageLogo !== null ? (
                        <StyledImgCol md="auto">
                            <StyledImage
                                src={`/images/${disclaimerDetails.data?.imageLogo}`}
                                alt={`${disclaimerDetails.data?.name} img`}
                            />
                        </StyledImgCol>
                    ) : (
                        <StyledImgCol />
                    )}
                </StyledRow>
                {!isProductionEnvironment && (
                    <StyledRow>
                        <StyledContentCol>
                            <CenterHeading>
                                <StyledSpan>{"Environment: "}</StyledSpan>
                                <StyledBoldSpan>{environment}</StyledBoldSpan>
                            </CenterHeading>
                        </StyledContentCol>
                    </StyledRow>
                )}
            </StyledFooterContainer>
        </>
    );
};

export default Footer;
