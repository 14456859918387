import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { getEmailQueueDetails } from "../../../data/services/email-queue/email-queue-service";
import { EmailQueueDetailsDto } from "../../dtos/email-queue/email-queue-details-dto";

export const useGetEmailQueueDetails = (
    emailQueueId: number
): UseQueryResult<EmailQueueDetailsDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getEmailQueueDetails", emailQueueId],
        () => getEmailQueueDetails(url.baseUrl, emailQueueId),
        {
            select: EmailQueueDetailsDto.constructFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};
