import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { convertToggleValueToBoolean } from "../../../core/utilities/convertToBoolean";
import { AggregationTypes } from "../../../core/utilities/enums";
import { calculateMinutes, PeriodTypeOptions } from "../../../core/utilities/QuestionSetValues";
import {
    associateExistingQuestion,
    associateNewQuestion,
} from "../../../data/services/question-set/question-set-service";
import { AssociateDissociateQuestionToQuestionSetDto } from "../../dtos/question-sets/associate-dissociate-question-to-question-set-dto";
import { PeriodType } from "../../enums/PeriodType";
import { QuestionTypes } from "../../enums/questions/question-types";
import { createAssociateDissociateQuestionToQuestionSetRequest } from "../../requests/question-set/associate-dissociate-question-to-question-set-request";
import { AssociateNewQuestionToQuestionSetRequest } from "../../requests/question-set/associate-new-question-to-question-set-request";
import {
    CreateAnswerThresholdValueRequest,
    CreateQuestionRequest,
    NewQuestionSetQuestionRequest,
} from "../../requests/question-set/new-question-set-question-request";
import { CreateQuestionWeightingsRequest } from "../../requests/questions/create-question-weightings-request";
import { Response } from "../../responses/common/response-response";

const numberOfMillisecondsInAMinute = 60000;
const arbitraryDateValue = "01 Jan 0000";

export class AssociateNewQuestionParams {
    questionSetId: number;
    questionTypeId: number;
    answerTypeId: number;
    answerTypeName: string;
    questionCategoryId: number;
    questionImportanceId: number;
    aggregationTypeId: number | null;
    redAnswerThresholdOperatorId: number;
    amberAnswerThresholdOperatorId: number;
    isAggregate: boolean;
    baseStartTimeOfDay: string;
    isARMonitoring: boolean;
    formData: FormData;

    public constructor(
        questionSetId: number,
        questionTypeId: number,
        answerTypeId: number,
        answerTypeName: string,
        questionCategoryId: number,
        questionImportanceId: number,
        aggregationTypeId: number | null,
        redAnswerThresholdOperatorId: number,
        amberAnswerThresholdOperatorId: number,
        isAggregate: boolean,
        baseStartTimeOfDay: string,
        isARMonitoring: boolean,
        formData: FormData
    ) {
        this.questionSetId = questionSetId;
        this.questionTypeId = questionTypeId;
        this.questionSetId = questionSetId;
        this.answerTypeId = answerTypeId;
        this.answerTypeName = answerTypeName;
        this.questionCategoryId = questionCategoryId;
        this.questionImportanceId = questionImportanceId;
        this.aggregationTypeId = aggregationTypeId;
        this.redAnswerThresholdOperatorId = redAnswerThresholdOperatorId;
        this.amberAnswerThresholdOperatorId = amberAnswerThresholdOperatorId;
        this.isAggregate = isAggregate;
        this.baseStartTimeOfDay = baseStartTimeOfDay;
        this.isARMonitoring = isARMonitoring;
        this.formData = formData;
    }
}

export const useAssociateExistingQuestionToQuestionSet = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    AssociateDissociateQuestionToQuestionSetDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: AssociateDissociateQuestionToQuestionSetDto) =>
        associateExistingQuestion(
            url.baseUrl,
            createAssociateDissociateQuestionToQuestionSetRequest(mutationKey)
        )
    );
};

export const useAssociateNewQuestionToQuestionSet = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    AssociateNewQuestionParams
> => {
    const url = useUrl();

    return useMutation((mutationKey: AssociateNewQuestionParams) =>
        associateNewQuestion(url.baseUrl, createAssociateNewQuestionRequest(mutationKey))
    );
};

const createAssociateNewQuestionRequest = (
    associateNewQuestionParams: AssociateNewQuestionParams
): AssociateNewQuestionToQuestionSetRequest => {
    const formData = associateNewQuestionParams.formData;

    const openDeltaMinutes = calculateMinutes(
        Number(formData.get("OpenTimeValueForNewQuestion")),
        formData.get("OpenTimePeriodForNewQuestion")?.toString() ??
            PeriodTypeOptions.find((x) => x.value === PeriodType.Hours)!.label
    );

    const dueMinutesFromBaseStart = calculateNumberOfMinutesBetweenTimes(
        formData.get("DueByTimeForNewQuestion")?.toString() ?? "00:00",
        associateNewQuestionParams.baseStartTimeOfDay
    );

    const escalationL1MinutesFromBaseStart = calculateMinutes(
        Number(formData.get("EL1ValueForNewQuestion")),
        formData.get("EL1PeriodForNewQuestion")?.toString() ??
            PeriodTypeOptions.find((x) => x.value === PeriodType.Hours)!.label
    );

    const escalationL2MinutesFromBaseStart =
        formData.get("EL2ValueForNewQuestion")?.toString() === ""
            ? null
            : calculateMinutes(
                  Number(formData.get("EL2ValueForNewQuestion")),
                  formData.get("EL2PeriodForNewQuestion")?.toString() ??
                      PeriodTypeOptions.find((x) => x.value === PeriodType.Hours)!.label
              );

    const escalationL3MinutesFromBaseStart =
        formData.get("EL3ValueForNewQuestion")?.toString() === ""
            ? null
            : calculateMinutes(
                  Number(formData.get("EL3ValueForNewQuestion")),
                  formData.get("EL3PeriodForNewQuestion")?.toString() ??
                      PeriodTypeOptions.find((x) => x.value === PeriodType.Hours)!.label
              );

    const answerThresholdValues = createAnswerThresholdValues(
        formData,
        associateNewQuestionParams.answerTypeName,
        associateNewQuestionParams.redAnswerThresholdOperatorId,
        associateNewQuestionParams.amberAnswerThresholdOperatorId
    );

    const createQuestionRequest = new CreateQuestionRequest(
        1, // TODO: find out where this comes from
        formData.get("questionText")?.toString() ?? "",
        associateNewQuestionParams.questionTypeId,
        associateNewQuestionParams.answerTypeId,
        associateNewQuestionParams.questionCategoryId!,
        associateNewQuestionParams.questionImportanceId!,
        associateNewQuestionParams.isAggregate,
        associateNewQuestionParams.aggregationTypeId || AggregationTypes.Default,
        associateNewQuestionParams.isARMonitoring,
        associateNewQuestionParams.questionTypeId === QuestionTypes.Indicator
            ? answerThresholdValues
            : [],
        associateNewQuestionParams.questionTypeId === QuestionTypes.Control &&
        convertToggleValueToBoolean(formData.get("QuestionWeighting")! as string)
            ? createQuestionWeightings(formData)
            : []
    );

    return new AssociateNewQuestionToQuestionSetRequest([
        new NewQuestionSetQuestionRequest(
            0,
            associateNewQuestionParams.questionSetId,
            0,
            dueMinutesFromBaseStart,
            openDeltaMinutes,
            escalationL1MinutesFromBaseStart + dueMinutesFromBaseStart,
            escalationL2MinutesFromBaseStart
                ? escalationL2MinutesFromBaseStart +
                  escalationL1MinutesFromBaseStart +
                  dueMinutesFromBaseStart
                : null,
            escalationL3MinutesFromBaseStart && escalationL2MinutesFromBaseStart
                ? escalationL3MinutesFromBaseStart +
                  escalationL2MinutesFromBaseStart +
                  escalationL1MinutesFromBaseStart +
                  dueMinutesFromBaseStart
                : null,
            true,
            createQuestionRequest
        ),
    ]);
};

const createQuestionWeightings = (formData: FormData): CreateQuestionWeightingsRequest[] => {
    let questionWeightings: CreateQuestionWeightingsRequest[] = [];
    formData.forEach((value, key) => {
        if (key.includes("predefinedAnswerId")) {
            const predefinedAnswerId = key.split("predefinedAnswerId")[1];
            questionWeightings.push(
                new CreateQuestionWeightingsRequest(
                    Number(predefinedAnswerId),
                    value != "" ? Number(value) : undefined
                )
            );
        }
    });

    return questionWeightings;
};

const calculateNumberOfMinutesBetweenTimes = (
    timeOneStringValue: string | undefined,
    timeTwoStringValue: string | undefined
): number => {
    if (!timeOneStringValue || !timeTwoStringValue) {
        return 0;
    }

    const timeOne = new Date(`${arbitraryDateValue} ${timeOneStringValue}`);
    const timeTwo = new Date(`${arbitraryDateValue} ${timeTwoStringValue}`);

    return Math.abs(
        Math.round((timeTwo.getTime() - timeOne.getTime()) / numberOfMillisecondsInAMinute)
    );
};

const createAnswerThresholdValues = (
    formData: FormData,
    answerTypeName: string | null,
    redAnswerThresholdOperatorId: number | null,
    amberAnswerThresholdOperatorId: number | null
): CreateAnswerThresholdValueRequest[] => [
    {
        intValue: answerTypeName === "Number" ? Number(formData.get("redNumber")) : undefined,
        decimalValue: answerTypeName !== "Number" ? Number(formData.get("redDecimal")) : undefined,
        answerThresholdIndicator: 1,
        answerThresholdOperator: redAnswerThresholdOperatorId!,
    },
    {
        intValue: answerTypeName === "Number" ? Number(formData.get("amberNumber")) : undefined,
        decimalValue:
            answerTypeName !== "Number" ? Number(formData.get("amberDecimal")) : undefined,
        answerThresholdIndicator: 2,
        answerThresholdOperator: amberAnswerThresholdOperatorId!,
    },
];
