import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { AuthContextType, useAuth } from "../../../core/store/auth-context";
import { useUrl } from "../../../core/store/url-context";
import { AccordionTitles, DataTableColumnTypes } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { filterHierarchyTypes } from "../../../data/services/hierarchy/hierarchy-types-service";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { PaginationDto } from "../../dtos/common/pagination-dto";
import { hasRoleTypeInGroup, HierarchyTypeRoleGroup } from "../../enums/Roles";
import { createBasePaginationRequestFromDto } from "../../requests/common/base-pagination-request";
import { createFilterHierarchyTypesRequest } from "../../requests/hierarchy/filter-hierarchy-types-request";
import { emptySearchHierarchyTypesRequest } from "../../requests/hierarchy/search-hierarchy-types-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { HierarchyTypeResponse } from "../../responses/hierarchy/hierarchy-type-response";

export const useFilterHierarchyTypes = (
    paginationDto: PaginationDto
): UseQueryResult<PaginatedTableDto<number>, HTTPError> => {
    const url = useUrl();
    const auth = useAuth();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterHierarchyTypes", paginationDto],
        () =>
            filterHierarchyTypes(
                url.baseUrl,
                createFilterHierarchyTypesRequest(
                    createBasePaginationRequestFromDto(paginationDto),
                    emptySearchHierarchyTypesRequest()
                )
            ),
        {
            keepPreviousData: true,
            select: (response: Response<PaginationResponse<HierarchyTypeResponse>>) =>
                toToDataTableRows(response.data, auth, navigate),
            onError: errorResponseToDisplayHandler,
        }
    );
};

const toToDataTableRows = (
    response: PaginationResponse<HierarchyTypeResponse>,
    auth: AuthContextType,
    navigate: NavigateFunction
): PaginatedTableDto<number> => {
    const { pageCount, pageSize, currentPage, recordCount } = response;

    const showEditAction = hasRoleTypeInGroup(auth.userRoles, HierarchyTypeRoleGroup.WriteRoles);

    const rows = response.results.map((x) => ({
        metadata: x.hierarchyTypeId,
        showEditAction: showEditAction,
        columns: [
            {
                value: x.name,
                type: DataTableColumnTypes.Link,
                linkItemAction: (hierarchyTypeId: number) =>
                    navigate(`${getPath(AccordionTitles.Admin)}/${hierarchyTypeId}`),
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
