import {
    useMutation,
    UseMutationResult,
    useQueries,
    useQuery,
    UseQueryResult,
} from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    getActionItemSubTypesByActionItemTypeNodeId,
    getActionItemTypes,
} from "../../../data/services/action-items/action-item-types-service";
import {
    createActionItem,
    filterAssociatedRaciSetUsers,
    getActionItemDetails,
    getAdditionalFields,
    getAdditionalFieldValues,
} from "../../../data/services/actions/action-items-service";
import { filterNotificationTriggers } from "../../../data/services/notification-triggers/notification-triggers-service";
import { filterUsers } from "../../../data/services/users/users-service";
import {
    ActionItemTypeDto,
    toActionItemTypeDtos,
} from "../../dtos/action-items/action-item-type-dto";
import { ActionItemDetailsDto } from "../../dtos/actions/action-item-details-dto";
import { ActionItemDynamicFieldValueDto } from "../../dtos/actions/action-item-dynamic-field-values-dto";
import {
    ActionItemDynamicFieldDetailsDto,
    toActionItemDynamicFieldDetailsDtos,
} from "../../dtos/actions/action-item-dynamic-fields-dto";
import { CreateActionItemDto } from "../../dtos/actions/create-action-item-dto";
import {
    RaciSetUserDto,
    toRaciSetUserDtosFromPaginatedResponse,
} from "../../dtos/actions/raci-set-user-dto";
import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import { PaginationDto } from "../../dtos/common/pagination-dto";
import { NotificationTriggerDto } from "../../dtos/notification-triggers/notification-trigger-dto";
import {
    BaseUserDto,
    toBaseUserDtosFromPaginatedUserResponse,
} from "../../dtos/users/base-user-dto";
import { CreateActionItemRequest } from "../../requests/actions/create-action-item-request";
import { createFilterAssociatedRaciSetUsersRequest } from "../../requests/actions/filter-associated-raci-set-users-request";
import { createBasePaginationRequestFromDto } from "../../requests/common/base-pagination-request";
import { createFilterUsersSearchRequest } from "../../requests/users/filter-users-request";
import { ActionItemResponse } from "../../responses/actions/action-item-response";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { NotificationTriggerResponse } from "../../responses/notification-triggers/notification-trigger-response";

export const useGetActionItemDetails = (
    actionItemId: number,
    actionItemTypeNodeId: number | null,
    basePaginationDto: BasePaginationDto
): [
    UseQueryResult<ActionItemTypeDto[], HTTPError>,
    UseQueryResult<ActionItemTypeDto[], HTTPError>,
    UseQueryResult<ActionItemDetailsDto, HTTPError>,
    UseQueryResult<ActionItemDynamicFieldValueDto[], HTTPError>,
    UseQueryResult<ActionItemDynamicFieldDetailsDto[], HTTPError>,
    UseQueryResult<NotificationTriggerDto[], HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getActionItemTypes"],
                queryFn: () => getActionItemTypes(url.baseUrl),
                select: toActionItemTypeDtos,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getActionItemSubTypesByActionItemId", actionItemTypeNodeId],
                enabled: actionItemTypeNodeId != null,
                queryFn: () =>
                    getActionItemSubTypesByActionItemTypeNodeId(url.baseUrl, actionItemTypeNodeId!),
                select: toActionItemTypeDtos,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getActionItemDetails", actionItemId],
                queryFn: () => getActionItemDetails(url.baseUrl, actionItemId!),
                select: ActionItemDetailsDto.constructFromResponse,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getAdditionalFieldValues", actionItemId],
                queryFn: () => getAdditionalFieldValues(url.baseUrl, actionItemId!),
                select: ActionItemDynamicFieldValueDto.toActionItemDynamicFieldValueDtos,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getAdditionalFields", actionItemTypeNodeId],
                enabled: actionItemTypeNodeId != null,
                queryFn: () => getAdditionalFields(url.baseUrl, actionItemTypeNodeId!),
                select: toActionItemDynamicFieldDetailsDtos,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["filterNotificationTriggers", basePaginationDto],
                queryFn: () =>
                    filterNotificationTriggers(
                        url.baseUrl,
                        createBasePaginationRequestFromDto(basePaginationDto)
                    ),
                select: (response: Response<PaginationResponse<NotificationTriggerResponse>>) =>
                    toNotificationTriggersDto(response.data),
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<ActionItemTypeDto[], HTTPError>,
        UseQueryResult<ActionItemTypeDto[], HTTPError>,
        UseQueryResult<ActionItemDetailsDto, HTTPError>,
        UseQueryResult<ActionItemDynamicFieldValueDto[], HTTPError>,
        UseQueryResult<ActionItemDynamicFieldDetailsDto[], HTTPError>,
        UseQueryResult<NotificationTriggerDto[], HTTPError>,
    ];
};

export const useFilterAssociatedRaciSetUsers = (
    actionItemId: number,
    raciParticipantsPaginationDto: BasePaginationDto
): UseQueryResult<RaciSetUserDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterAssociatedRaciSetUsers", raciParticipantsPaginationDto, actionItemId],
        () =>
            filterAssociatedRaciSetUsers(
                url.baseUrl,
                createFilterAssociatedRaciSetUsersRequest(
                    raciParticipantsPaginationDto,
                    actionItemId
                )
            ),
        {
            keepPreviousData: true,
            enabled: actionItemId != null,
            select: toRaciSetUserDtosFromPaginatedResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useCreateActionItem = (): UseMutationResult<
    Response<ActionItemResponse>,
    HTTPError,
    CreateActionItemDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: CreateActionItemDto) => {
        const request = new CreateActionItemRequest(mutationKey);

        return createActionItem(url.baseUrl, request);
    });
};

export const useFilterUsers = (
    paginationDto: PaginationDto,
    searchText: string | null
): UseQueryResult<BaseUserDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterUsers", paginationDto, searchText],
        () =>
            filterUsers(
                url.baseUrl,
                createFilterUsersSearchRequest(
                    searchText,
                    paginationDto.pageNumber,
                    paginationDto.pageSize
                )
            ),
        {
            select: toBaseUserDtosFromPaginatedUserResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const toNotificationTriggersDto = (
    response: PaginationResponse<NotificationTriggerResponse>
): NotificationTriggerDto[] => response.results!.map((x) => new NotificationTriggerDto(x));
