import ReviewRating from "../../enums/review-reports/review-ratings";
import ReviewStatus from "../../enums/review-reports/review-status";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { QuestionSetInstanceReviewResponse } from "../../responses/review-reports/question-set-instance-review-response";
import { AssociatedRiskGroupDto } from "../associated-risks/associated-risk-group-dto";
import { ChecklistDto, createChecklistDtoFromResponse } from "../checklists/checklist-dto";
import { NodeDto } from "../hierarchy/node-dto";
import {
    createQuestionSetDetailsDtoFromResponse,
    QuestionSetDetailsDto,
} from "../question-sets/question-set-details-dto";
import { AgreedActionDto } from "./agreed-action-dto";
import { QuestionSetInstanceDto } from "./question-set-instance-dto";
import { ReviewTypeFieldsValuesDto } from "./review-type-fields-value-dto";

export class QuestionSetInstanceReviewDto {
    questionSetInstanceReviewId: number;
    questionSetInstanceAnswerId: number;
    questionSetInstanceId: number;
    reviewRating: ReviewRating;
    reviewStatus: ReviewStatus;
    checklistDto: ChecklistDto;
    nodeDto: NodeDto;
    questionSetInstanceDto: QuestionSetInstanceDto;
    questionSetDto: QuestionSetDetailsDto;
    reviewTypeFieldsValues: ReviewTypeFieldsValuesDto[];
    agreedActionDtos: AgreedActionDto[];
    associatedRiskGroupDtos: AssociatedRiskGroupDto[];
    comment: string;

    public constructor(questionSetInstanceReviewResponse: QuestionSetInstanceReviewResponse) {
        this.questionSetInstanceReviewId =
            questionSetInstanceReviewResponse.questionSetInstanceReviewId;
        this.questionSetInstanceAnswerId =
            questionSetInstanceReviewResponse.questionSetInstanceAnswerId;
        this.questionSetInstanceId = questionSetInstanceReviewResponse.questionSetInstanceId;
        this.reviewRating = questionSetInstanceReviewResponse.reviewRating;
        this.reviewStatus = questionSetInstanceReviewResponse.reviewStatus;
        this.checklistDto =
            questionSetInstanceReviewResponse.checklistResponse &&
            createChecklistDtoFromResponse(questionSetInstanceReviewResponse.checklistResponse);
        this.nodeDto = questionSetInstanceReviewResponse.nodeResponse;
        this.questionSetInstanceDto = new QuestionSetInstanceDto(
            questionSetInstanceReviewResponse.questionSetInstanceResponse
        );
        this.questionSetDto = createQuestionSetDetailsDtoFromResponse(
            questionSetInstanceReviewResponse.questionSetResponse
        );
        this.reviewTypeFieldsValues = questionSetInstanceReviewResponse.reviewTypeFieldsValues;
        this.agreedActionDtos = AgreedActionDto.toAgreedActionDtos(
            questionSetInstanceReviewResponse.agreedActionResponses
        );
        this.associatedRiskGroupDtos = AssociatedRiskGroupDto.toAssociatedRiskGroupDtos(
            questionSetInstanceReviewResponse.associatedRiskGroupResponses
        );
        this.comment = questionSetInstanceReviewResponse.comment;
    }

    public static constructFromResponse = (
        response: Response<QuestionSetInstanceReviewResponse>
    ): QuestionSetInstanceReviewDto => new QuestionSetInstanceReviewDto(response.data);

    public static fromResponseToQuestionSetInstanceReviewDtos = (
        response: Response<QuestionSetInstanceReviewResponse[]>
    ): QuestionSetInstanceReviewDto[] => {
        return response.data!.map((x) => new QuestionSetInstanceReviewDto(x));
    };

    public static fromPaginatedResponsetoQuestionSetInstanceReviewDtos = (
        response: Response<PaginationResponse<QuestionSetInstanceReviewResponse>>
    ): QuestionSetInstanceReviewDto[] => {
        return response.data!.results?.map((x) => new QuestionSetInstanceReviewDto(x));
    };

    public static toQuestionSetInstanceReviewDtos = (
        response: QuestionSetInstanceReviewResponse[]
    ): QuestionSetInstanceReviewDto[] => response!.map((x) => new QuestionSetInstanceReviewDto(x));
}
