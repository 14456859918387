import {
    buildFilterSearchParameters,
    FilterWizardNotificationHistoryRequest,
} from "../../../domain/requests/wizards/filter-wizard-notification-history-request";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import { WizardNotificationHistoryResponse } from "../../../domain/responses/wizards/wizard-notification-history-response";
import client from "../../http-client";

const route = "wizardshistory";

export const getWizardNotificationHistory = async (
    prefixUrl: string,
    request: FilterWizardNotificationHistoryRequest
): Promise<Response<PaginationResponse<WizardNotificationHistoryResponse>>> => {
    const searchParams = buildFilterSearchParameters(request);

    return await client
        .get(`${route}/wizard-notification-history`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};
