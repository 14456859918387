import AnswerValueType from "../../enums/answer-types/answer-value-type";

export default class IndicatorAnswerDto<TType> {
    answerValueType: AnswerValueType;
    value: TType;

    public constructor(answerValueType: AnswerValueType, value: TType) {
        this.answerValueType = answerValueType;
        this.value = value;
    }
}
