import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    associateTaxonomyNode,
    getQuestionDetails,
} from "../../../data/services/questions/question-service";
import { AssociateDissociateQuestionTaxonomyNodeDto } from "../../dtos/questions/associate-dissociate-question-taxonomy-node-dto";
import { createAssociateDissociateQuestionTaxonomyNodeRequest } from "../../requests/questions/associate-dissociate-question-taxonomy-node-request";
import { Response } from "../../responses/common/response-response";
import { QuestionDetailsResponse } from "../../responses/questions/question-details-response";

export const useGetTaxonomyNodeDetails = (
    questionId: number
): UseQueryResult<string, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getQuestionDetails", questionId],
        () => getQuestionDetails(url.baseUrl, questionId),
        {
            select: createQuestionDto,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const createQuestionDto = (response: Response<QuestionDetailsResponse>): string =>
    response.data.questionText.text;

export const useAssociateTaxonomyNode = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    AssociateDissociateQuestionTaxonomyNodeDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: AssociateDissociateQuestionTaxonomyNodeDto) => {
        const request = createAssociateDissociateQuestionTaxonomyNodeRequest(
            mutationKey.questionId,
            mutationKey.nodeId
        );

        return associateTaxonomyNode(url.baseUrl, request);
    });
};
