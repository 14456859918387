import { TimeZoneResponse } from "../../responses/timezone/time-zone-response";
import BaseTimeZoneDto from "./base-time-zone-dto";

export default class TimeZoneDto extends BaseTimeZoneDto {
    ianaTimeZoneId: string;

    public constructor(response: TimeZoneResponse) {
        super(response);

        this.ianaTimeZoneId = response.ianaTimeZoneId;
    }
}
