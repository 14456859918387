import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
} from "../common/base-pagination-request";

export default class FilterAssociatedUserNodesRequest extends BasePaginationRequest {
    userId: number;

    public constructor(userId: number, paginationDto: BasePaginationDto) {
        const { pageNumber, pageSize } = paginationDto;
        super(pageNumber, pageSize);

        this.userId = userId;
    }

    public buildSearchParameters(): URLSearchParams {
        const searchParams = buildBasePaginationSearchParameters(this);

        searchParams.set("userId", this.userId.toString());

        return searchParams;
    }
}
