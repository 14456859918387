import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
} from "../common/base-pagination-request";

export interface FilterAssociatedRaciSetUsersRequest extends BasePaginationRequest {
    actionItemId: number;
}

export const createFilterAssociatedRaciSetUsersRequest = (
    paginationRequest: BasePaginationRequest,
    actionItemId: number
): FilterAssociatedRaciSetUsersRequest => ({
    ...paginationRequest,
    actionItemId: actionItemId,
});

export const buildAssociatedRaciSetUsersSearchParameters = (
    request: FilterAssociatedRaciSetUsersRequest
): URLSearchParams => {
    const searchParams = buildBasePaginationSearchParameters(request);

    searchParams.set("actionItemId", request.actionItemId.toString());

    return searchParams;
};
