import { AggregationTypeResponse } from "../../../domain/responses/aggregation-type/aggregation-type-response";
import client from "../../http-client";

const route = "aggregationtypes";

export const getAllAggregationTypes = async (
    prefixUrl: string
): Promise<AggregationTypeResponse[]> =>
    await client
        .get(route, {
            prefixUrl: prefixUrl,
        })
        .json();
