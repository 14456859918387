import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Common } from "../../core/constants/translation-namespace";
import { useAuth } from "../../core/store/auth-context";
import { useMenu } from "../../core/store/menu-context";
import { StyledMenuItemLink } from "../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../core/utilities/enums";
import { getIcon } from "../../core/utilities/getIcon";
import { getPath } from "../../core/utilities/getPath";

const StyledCol = styled(Col)`
    height: 100%;
    padding: 0;
`;

interface StyledTabProps {
    readonly $active?: boolean;
}

const StyledTab = styled(StyledMenuItemLink)<StyledTabProps>`
    height: 100%;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.palette.primary};
    padding-left: ${(props) => props.theme.padding.lg};
    padding-right: ${(props) => props.theme.padding.lg};

    border-bottom: ${(props) =>
        props.$active
            ? props.theme.padding.sm + " solid " + props.theme.palette.primary
            : props.theme.padding.sm + " solid " + props.theme.palette.white};

    &:hover,
    &:active {
        background-color: ${(props) => props.theme.palette.purple};
    }

    &:focus {
        background-color: ${(props) => props.theme.palette.white};
        color: ${(props) => props.theme.palette.primary};
    }
`;

const StyledText = styled.div`
    padding-left: ${(props) => props.theme.padding.sm};
`;

const NavbarTab: React.FC<{
    title: string;
    active: boolean;
}> = ({ title, active }) => {
    const menu = useMenu();
    const auth = useAuth();
    const { t } = useTranslation("translation", { keyPrefix: Common });

    const buildToPath = (): string => {
        let toPath = "";

        switch (title) {
            case NavbarTitles.MyActionCalendar:
                toPath = getPath(DrawerTitles.MyActionCalendar);
                break;
            case NavbarTitles.ActionTracker:
                toPath = getPath(DrawerTitles.MyActionSummary);
                break;
            case NavbarTitles.Checklists:
                toPath = getPath(DrawerTitles.AnswerSummary);
                break;
            case NavbarTitles.Reports:
                toPath = getPath(DrawerTitles.SimpleReports);
                break;
            case NavbarTitles.Admin:
                toPath =
                    auth.isAdmin && !auth.isImpersonating
                        ? getPath(AccordionTitles.Users)
                        : getPath(AccordionTitles.Checklists);
                break;
        }

        return toPath;
    };

    return (
        <StyledCol sm="auto">
            <StyledTab
                $active={active}
                to={buildToPath()}
                onClick={() => {
                    menu.changeActiveAccordion("");
                    menu.changeActiveNavbarItem(title);
                }}
            >
                {getIcon(title)}
                <StyledText>{t(title)}</StyledText>
            </StyledTab>
        </StyledCol>
    );
};

export default NavbarTab;
