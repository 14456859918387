import ReviewRating from "../../enums/review-reports/review-ratings";
import ReviewStatus from "../../enums/review-reports/review-status";
import { Response } from "../../responses/common/response-response";
import { ReviewReportSetResponse } from "../../responses/review-reports/review-report-set-response";
import { AssuranceReviewDto } from "./assurance-review-dto";
import { ReviewReportSetTypeFieldsValueDto } from "./review-report-set-type-fields-value-dto";

export class ReviewReportSetDto {
    reviewReportSetId: number;
    reviewReportName: string;
    reviewRating: ReviewRating;
    reviewStatus: ReviewStatus;
    latestPublishedVersion: number | null;
    canUnpublish: boolean;
    assuranceReviewDto: AssuranceReviewDto | null;
    reviewReportSetTypeFieldsValueDtos: ReviewReportSetTypeFieldsValueDto[];

    public constructor(response: ReviewReportSetResponse) {
        this.reviewReportSetId = response.reviewReportSetId;
        this.reviewReportName = response.reviewReportName;
        this.reviewRating = response.reviewRating;
        this.reviewStatus = response.reviewStatus;
        this.latestPublishedVersion = response.latestPublishedVersion;
        this.canUnpublish = response.canUnpublish;
        this.assuranceReviewDto = response.assuranceReviewResponse
            ? new AssuranceReviewDto(response.assuranceReviewResponse)
            : null;
        this.reviewReportSetTypeFieldsValueDtos =
            ReviewReportSetTypeFieldsValueDto.toReviewReportSetTypeFieldsValueDtos(
                response.reviewReportSetTypeFieldsValueResponses
            );
    }

    public static constructFromResponse = (
        response: Response<ReviewReportSetResponse>
    ): ReviewReportSetDto => new ReviewReportSetDto(response.data);
}
