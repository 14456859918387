import AnswerValueType from "../../enums/answer-types/answer-value-type";
import { ActionItemDynamicFieldDetailsResponse } from "../../responses/action-items/action-item-dynamic-field-details-response";
import { Response } from "../../responses/common/response-response";
import {
    ActionItemTypeDto,
    createActionItemTypeDtoFromResponse,
} from "../action-items/action-item-type-dto";

export interface ActionItemDynamicFieldDetailsDto {
    actionItemDynamicFieldId: number;
    valueType: AnswerValueType;
    orderIndex: number;
    isRequired: boolean;
    listData: string;
    fieldName: string;
    actionItemTypeDto: ActionItemTypeDto;
}

export const createActionItemDynamicFieldDetailsDto = (
    response: ActionItemDynamicFieldDetailsResponse
): ActionItemDynamicFieldDetailsDto => {
    return {
        ...response,
        actionItemTypeDto: createActionItemTypeDtoFromResponse(response.actionItemTypeResponse),
    };
};

export const toActionItemDynamicFieldDetailsDtos = (
    response: Response<ActionItemDynamicFieldDetailsResponse[]>
): ActionItemDynamicFieldDetailsDto[] =>
    response.data?.map((x) => createActionItemDynamicFieldDetailsDto(x));
