import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { ActionPlanDetailsResponse } from "../../responses/actions/action-plan-details-response";
import { Response } from "../../responses/common/response-response";

export class ActionPlanDetailsDto {
    actionPlanId: number;
    name: string;
    description: string;
    actionItemCount: number;
    createdByUserFullName: string;
    createdByUserName: string;
    dateCreatedLocal: string;
    modifiedByUserFullName: string;
    modifiedByUserName: string;
    dateModifiedLocal: string;

    public constructor(response: ActionPlanDetailsResponse) {
        const {
            actionPlanId,
            name,
            description,
            actionItemCount,
            createdByUserFullName,
            createdByUserName,
            dateCreatedUtc,
            modifiedByUserFullName,
            modifiedByUserName,
            dateModifiedUtc,
        } = response;

        this.actionPlanId = actionPlanId;
        this.name = name;
        this.description = description;
        this.actionItemCount = actionItemCount;
        this.createdByUserFullName = createdByUserFullName;
        this.createdByUserName = createdByUserName;
        this.dateCreatedLocal = toDateAndTimeFormat(dateCreatedUtc.toString());
        this.modifiedByUserFullName = modifiedByUserFullName;
        this.modifiedByUserName = modifiedByUserName;
        this.dateModifiedLocal = toDateAndTimeFormat(dateModifiedUtc.toString());
    }

    public static constructFromResponse = (
        response: Response<ActionPlanDetailsResponse>
    ): ActionPlanDetailsDto => new ActionPlanDetailsDto(response.data);
}
