import { QuestionTypeResponse } from "../../../domain/responses/question-type/question-type-response";
import client from "../../http-client";

const route = "questiontypes";

export const getAllQuestionTypes = async (prefixUrl: string): Promise<QuestionTypeResponse[]> =>
    await client
        .get(route, {
            prefixUrl: prefixUrl,
        })
        .json();
