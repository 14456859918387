import { Response } from "../../responses/common/response-response";
import { AssuranceReviewResponse } from "../../responses/review-reports/assurance-review-response";

export class AssuranceReviewDto {
    assuranceReviewId: number | null;
    assuranceReviewName: string | null;

    public constructor(assuranceReviewResponse: AssuranceReviewResponse) {
        this.assuranceReviewId = assuranceReviewResponse.assuranceReviewId;
        this.assuranceReviewName = assuranceReviewResponse.assuranceReviewName;
    }
    
    public static constructFromResponse = (
        response: Response<AssuranceReviewResponse[]>
    ): AssuranceReviewDto[] => response.data.map((x) => new AssuranceReviewDto(x));
}
