import FilterActionItemEventsDto from "../../dtos/action-items/filter-action-item-events-dto";

export default class FilterActionItemEventsRequest {
    dateOnlyRangeStart: Date;
    dateOnlyRangeEnd: Date;

    public constructor(dateOnlyRangeStart: Date, dateOnlyRangeEnd: Date) {
        this.dateOnlyRangeStart = dateOnlyRangeStart;
        this.dateOnlyRangeEnd = dateOnlyRangeEnd;
    }

    public static constructFromDto = (
        dto: FilterActionItemEventsDto
    ): FilterActionItemEventsRequest => {
        const { dateOnlyRangeStart, dateOnlyRangeEnd } = dto;

        return new FilterActionItemEventsRequest(dateOnlyRangeStart, dateOnlyRangeEnd);
    };

    public buildSearchParameters(): URLSearchParams {
        const searchParams = new URLSearchParams();

        searchParams.set("dateOnlyRangeStart", this.dateOnlyRangeStart.toISOString());
        searchParams.set("dateOnlyRangeEnd", this.dateOnlyRangeEnd.toISOString());

        return searchParams;
    }
}
