import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { dissociateTaxonomyNode } from "../../../data/services/checklists/checklist-service";
import { getNodeDetails } from "../../../data/services/hierarchy/nodes-service";
import {
    createDissociateNodeDetailsDto,
    DissociateNodeDetailsDto,
} from "../../dtos/checklists/dissociate-node-details-dto";
import {
    AssociateDissociateChecklistTaxonomyNodeRequest,
    createAssociateDissociateChecklistTaxonomyNodeRequest,
} from "../../requests/checklists/associate-dissociate-checklist-taxonomy-node-request";
import { Response } from "../../responses/common/response-response";

export const useGetDissociateTaxonomyNodeDetails = (
    nodeId: number
): UseQueryResult<DissociateNodeDetailsDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(["getNodeDetails", nodeId], () => getNodeDetails(url.baseUrl, nodeId), {
        select: createDissociateNodeDetailsDto,
        onError: errorResponseToDisplayHandler,
    });
};

export const useDissociateTaxonomyNode = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    AssociateDissociateChecklistTaxonomyNodeRequest
> => {
    const url = useUrl();

    return useMutation((mutationKey: AssociateDissociateChecklistTaxonomyNodeRequest) => {
        const request = createAssociateDissociateChecklistTaxonomyNodeRequest(
            mutationKey.checklistId,
            mutationKey.nodeId
        );

        return dissociateTaxonomyNode(url.baseUrl, request);
    });
};
