import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { lookupAssuranceReviews } from "../../../data/services/lookup/lookup-service";
import { linkReviewReportSetWithAssuranceReview } from "../../../data/services/review-reports/review-reports-service";
import { AssuranceReviewSearchDto } from "../../dtos/common/search-dto";
import { AssuranceReviewDto } from "../../dtos/review-reports/assurance-review-dto";
import { LinkReviewReportSetWithAssuranceReviewDto } from "../../dtos/review-reports/link-review-report-set-assurance-review-dto";
import { createAssuranceReviewSearchRequestFromDto } from "../../requests/common/search-request";
import { createLinkReviewReportSetWithAssuranceReviewRequest } from "../../requests/review-reports/link-review-report-set-assurance-review-request";
import { Response } from "../../responses/common/response-response";

export const useLookupAssuranceReviews = (
    searchDto: AssuranceReviewSearchDto
): UseQueryResult<AssuranceReviewDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["lookupAssuranceReviews", searchDto],
        () =>
            lookupAssuranceReviews(
                url.baseUrl,
                createAssuranceReviewSearchRequestFromDto(searchDto)
            ),
        {
            enabled: searchDto.searchText?.length !== 0 && searchDto.searchText != null,
            select: AssuranceReviewDto.constructFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useLinkReviewReportSetWithAssuranceReview = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    LinkReviewReportSetWithAssuranceReviewDto
> => {
    const url = useUrl();
    return useMutation((mutationKey: LinkReviewReportSetWithAssuranceReviewDto) => {
        return linkReviewReportSetWithAssuranceReview(
            url.baseUrl,
            createLinkReviewReportSetWithAssuranceReviewRequest(mutationKey)
        );
    });
};
