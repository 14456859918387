import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
    ActionItems,
    Common,
    HierarchyTypeTreeView,
    Nodes,
} from "../../../core/constants/translation-namespace";
import { HierarchyMenuType } from "../../../core/hierarchyMenuType";
import { useAuth } from "../../../core/store/auth-context";
import { ContentContainer, SectionVerticalSpace } from "../../../core/theme/global-styles";
import { nodesColumnNames } from "../../../core/utilities/dataTableColumns";
import { HierarchyAssignableFlag } from "../../../core/utilities/enums";
import { areQueriesSuccessful } from "../../../core/utilities/responseStateHelper";
import { BaseNodeDetailsDto } from "../../../domain/dtos/hierarchy/base-node-details-dto";
import { ActionItemNodeAssociationMode } from "../../../domain/enums/action-items/ActionItemNodeAssociationMode";
import { useGetUserOrganisationalNodes } from "../../../domain/viewmodels/action-items/associate-node-to-action-item-viewmodel";
import { useGetHierarchyTypesByHierarchyAssignableFlag } from "../../../domain/viewmodels/hierarchy/hierarchy-types-by-hierarchy-assignable-flag-viewmodel";
import { SbAlert } from "../../atoms/SbAlert";
import { SbRibbon } from "../../atoms/SbRibbon";
import { RadioSelectProps } from "../../molecules/DataTableAction";
import { StyledTabsWrap } from "../../molecules/SbTabs";
import { DataTable } from "../../organisms/DataTable";
import NodeHierarchyContainer from "../hierarchy/NodeHierarchyContainer";
import TextSearchContainer from "../hierarchy/TextSearchContainer";

interface NodeActionItemProps {
    isNewActionItem: boolean;
    nodeAssociationMode: number;
    selectedNode: number | undefined;
    setSelectedNode: any;
}

export const AssignToHierarchyComponent: React.FC<NodeActionItemProps> = ({
    isNewActionItem,
    nodeAssociationMode,
    selectedNode,
    setSelectedNode,
}) => {
    const auth = useAuth();
    const { t } = useTranslation("translation", { keyPrefix: Nodes });

    const hierarchyAssignableFlag = (): HierarchyAssignableFlag => {
        switch (nodeAssociationMode) {
            case ActionItemNodeAssociationMode.Organisational:
                return HierarchyAssignableFlag.OrganisationalNodesForActionItems;
            case ActionItemNodeAssociationMode.Geography:
                return HierarchyAssignableFlag.GeographyNodesForActionItems;
            default:
                return HierarchyAssignableFlag.ActionItems;
        }
    };

    const getHierarchyTypesByHierarchyAssignableFlag =
        useGetHierarchyTypesByHierarchyAssignableFlag(hierarchyAssignableFlag());
    const getUserOrganisationalNodes = useGetUserOrganisationalNodes(auth.userId!);
    const userOrganisationalNodesData = getUserOrganisationalNodes.data!;
    const radioSelect: RadioSelectProps = {
        type: "radio",
        label: `${t("SelectNode", { keyPrefix: HierarchyTypeTreeView })}`,
    };

    const buildRibbon = (): JSX.Element => <SbRibbon size={"small"} label={"Training"} />;

    const selectTextSearchNode = (metadata: BaseNodeDetailsDto): void =>
        setSelectedNode(metadata.nodeId);

    const selectedTextSearchNode = (metadata: BaseNodeDetailsDto): boolean => {
        const node = metadata.nodeId;
        return node === selectedNode;
    };

    const selectVisualSearchNode = (nodeId: number): void => setSelectedNode(nodeId);

    const renderManualAssociationTab = (): JSX.Element => {
        return (
            <StyledTabsWrap>
                <Tabs defaultActiveKey="textSearch">
                    <Tab eventKey={"textSearch"} title={t("TextSearch", { keyPrefix: Common })}>
                        <TextSearchContainer
                            mode={"select"}
                            selectNode={selectTextSearchNode}
                            selectedNode={selectedTextSearchNode}
                            radioSelect={radioSelect}
                            hierarchyTypes={getHierarchyTypesByHierarchyAssignableFlag.data!}
                        />
                    </Tab>
                    <Tab eventKey={"visualSearch"} title={t("VisualSearch", { keyPrefix: Common })}>
                        <SectionVerticalSpace />
                        <NodeHierarchyContainer
                            menuType={
                                HierarchyMenuType.Select |
                                HierarchyMenuType.Details |
                                HierarchyMenuType.ViewUsers
                            }
                            selectNode={selectVisualSearchNode}
                            hierarchyTypes={getHierarchyTypesByHierarchyAssignableFlag.data!}
                        />
                    </Tab>
                </Tabs>
            </StyledTabsWrap>
        );
    };

    return (
        <>
            {areQueriesSuccessful([
                getHierarchyTypesByHierarchyAssignableFlag,
                getUserOrganisationalNodes,
            ]) && (
                <>
                    {isNewActionItem ? (
                        <StyledTabsWrap>
                            <Tabs defaultActiveKey="auto">
                                <Tab
                                    eventKey={"auto"}
                                    title={t("AutomaticallyAssignToHierarchy", {
                                        keyPrefix: ActionItems,
                                    }).toUpperCase()}
                                >
                                    <SectionVerticalSpace />
                                    <ContentContainer>
                                        {userOrganisationalNodesData!.length > 0 ? (
                                            <DataTable
                                                columns={nodesColumnNames}
                                                keyPrefix={Nodes}
                                                rows={userOrganisationalNodesData!}
                                                radioSelect={radioSelect}
                                                ribbon={buildRibbon()}
                                                selectItem={selectTextSearchNode}
                                                selectedItem={selectedTextSearchNode}
                                            />
                                        ) : (
                                            <SbAlert
                                                variant={"primary"}
                                                text={t("NoApplicableOrganisationalNodeNote", {
                                                    keyPrefix: ActionItems,
                                                })}
                                            />
                                        )}
                                    </ContentContainer>
                                </Tab>
                                <Tab
                                    eventKey={"manual"}
                                    title={t("ManuallyAssignToHierarchy", {
                                        keyPrefix: ActionItems,
                                    }).toUpperCase()}
                                >
                                    <SectionVerticalSpace />
                                    <ContentContainer>
                                        {renderManualAssociationTab()}
                                    </ContentContainer>
                                </Tab>
                            </Tabs>
                        </StyledTabsWrap>
                    ) : (
                        <ContentContainer>{renderManualAssociationTab()}</ContentContainer>
                    )}
                </>
            )}
        </>
    );
};
