import { useMutation, UseMutationResult, useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import { associateActionItemReferentialLink } from "../../../data/services/actions/action-items-service";
import { associateChecklistReferentialLink } from "../../../data/services/checklists/checklist-service";
import { associateReferentialLinkType } from "../../../data/services/question-set/question-set-service";
import { filterReferentialLinks } from "../../../data/services/referential-link-types/referential-link-service";
import { getAllReferentialLinkTypes } from "../../../data/services/referential-link-types/referential-link-type-service";
import { AssociateDissociateReferentialLinkToActionItemDto } from "../../dtos/actions/associate-dissociate-referential-link-to-action-item-dto";
import {
    AssociateReferentialLinkDto,
    toAssociateReferentialLinkDto,
} from "../../dtos/checklists/associate-referential-link-dto";
import {
    AssociateReferentialLinkTypeDto,
    toAssociateReferentialLinkTypeDto,
} from "../../dtos/checklists/associate-referential-link-type-dto";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { FilterReferentialLinkTypeDto } from "../../dtos/referential-links/filter-referential-link-type-dto";
import { AssociateDissociateReferentialLinkToActionItemRequest } from "../../requests/actions/associate-dissociate-referential-link-to-action-item-request";
import { createAssociateDissociateReferentialLinkToChecklistRequest } from "../../requests/checklists/associate-dissociate-referential-link-to-checklist-request";
import { createAssociateDissociateReferentialLinkTypeToQuestionSetRequest } from "../../requests/question-set/associate-dissociate-referential-link-type-to-question-set-request";
import { createFilterReferentialLinkTypeRequestFromDto } from "../../requests/referential-link-types/filter-referential-link-type-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { ReferentialLinkResponse } from "../../responses/referential-links/referential-link-response";

export const useGetAllAssociateReferentialLinks = (
    filterDto: FilterReferentialLinkTypeDto
): [
    UseQueryResult<PaginatedTableDto<AssociateReferentialLinkDto>, HTTPError>,
    UseQueryResult<AssociateReferentialLinkTypeDto[], HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["filterReferentialLinks", filterDto],
                queryFn: () =>
                    filterReferentialLinks(
                        url.baseUrl,
                        createFilterReferentialLinkTypeRequestFromDto(filterDto)
                    ),
                keepPreviousData: true,
                select: transformToDataTableRows,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getAllReferentialLinkTypes"],
                queryFn: () => getAllReferentialLinkTypes(url.baseUrl),
                select: toAssociateReferentialLinkTypeDto,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<PaginatedTableDto<AssociateReferentialLinkDto>, HTTPError>,
        UseQueryResult<AssociateReferentialLinkTypeDto[], HTTPError>,
    ];
};

const transformToDataTableRows = (
    response: Response<PaginationResponse<ReferentialLinkResponse>>
): PaginatedTableDto<AssociateReferentialLinkDto> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((associateReferentialLink) => {
        return {
            metadata: toAssociateReferentialLinkDto(associateReferentialLink),
            showSelectAction: true,
            columns: [
                {
                    value: associateReferentialLink.referentialLinkTypeName,
                    type: DataTableColumnTypes.Text,
                },
                {
                    value: associateReferentialLink.value,
                    type: DataTableColumnTypes.Text,
                },
            ],
        };
    });

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};

interface AssociateParameters {
    referentialLinkIds: number[];
    checklistId: number;
}

interface AssociateQuestionSetParameters {
    questionSetId: number;
    referentialLinkTypeId: number;
}

export const useAssociateChecklistReferentialLink = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    AssociateParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: AssociateParameters) => {
        const request = createAssociateDissociateReferentialLinkToChecklistRequest(
            mutationKey.checklistId,
            mutationKey.referentialLinkIds
        );

        return associateChecklistReferentialLink(url.baseUrl, request);
    });
};

export const useAssociateQuestionSetReferentialLink = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    AssociateQuestionSetParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: AssociateQuestionSetParameters) => {
        const request = createAssociateDissociateReferentialLinkTypeToQuestionSetRequest(
            mutationKey.questionSetId,
            mutationKey.referentialLinkTypeId
        );

        return associateReferentialLinkType(url.baseUrl, request);
    });
};

export const useAssociateActionItemReferentialLink = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    AssociateDissociateReferentialLinkToActionItemDto
> => {
    const url = useUrl();

    return useMutation((dto: AssociateDissociateReferentialLinkToActionItemDto) => {
        const request = new AssociateDissociateReferentialLinkToActionItemRequest(dto);

        return associateActionItemReferentialLink(url.baseUrl, request);
    });
};
