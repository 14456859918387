import { DisassociateLinkedNodeTypeDto } from "../../dtos/hierarchy/disassociate-linked-node-type-dto";

export interface DisassociateLinkedNodeTypeRequest {
    primaryNodeTypeId: number;
    linkedNodeTypeId: number;
}

export const createDisassociateLinkedNodeTypeRequest = (
    dto: DisassociateLinkedNodeTypeDto
): DisassociateLinkedNodeTypeRequest => ({
    ...dto,
});
