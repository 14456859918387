import { useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    getActionItemSubTypes,
    getActionItemTypes,
} from "../../../data/services/action-items/action-item-types-service";
import { getActionInstanceStatus } from "../../../data/services/actions/action-instance-status-service";
import { filterMyActionItems } from "../../../data/services/actions/action-items-service";
import {
    ActionItemTypeDto,
    toActionItemTypeDtos,
} from "../../dtos/action-items/action-item-type-dto";
import { FilterMyActionItemsDto } from "../../dtos/action-items/filter-my-action-items-dto";
import { MyActionItemsDto, toMyActionItemsDtos } from "../../dtos/action-items/my-action-items-dto";
import {
    ActionInstanceStatusDto,
    toActionInstanceStatusDtos,
} from "../../dtos/actions/action-instance-status-dto";
import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import PaginatedDto from "../../dtos/common/paginated-dto";
import { createFilterMyActionItemsRequest } from "../../requests/action-items/filter-my-action-items-request";
import { MyActionItemResponse } from "../../responses/action-items/my-action-item-response";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";

export const useFilterMyActionItems = (
    filterMyActionItemsDto: FilterMyActionItemsDto,
    paginationDto: BasePaginationDto
): [
    UseQueryResult<PaginatedDto<MyActionItemsDto>, HTTPError>,
    UseQueryResult<ActionItemTypeDto[], HTTPError>,
    UseQueryResult<ActionItemTypeDto[], HTTPError>,
    UseQueryResult<ActionInstanceStatusDto[], HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["filterMyActionItems", filterMyActionItemsDto, paginationDto],
                queryFn: () =>
                    filterMyActionItems(
                        url.baseUrl,
                        createFilterMyActionItemsRequest(filterMyActionItemsDto, paginationDto)
                    ),
                keepPreviousData: true,
                select: transformToMyActionItemsDto,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getActionItemTypes"],
                queryFn: () => getActionItemTypes(url.baseUrl),
                select: toActionItemTypeDtos,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getActionItemSubTypes"],
                queryFn: () => getActionItemSubTypes(url.baseUrl),
                select: toActionItemTypeDtos,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getActionInstanceStatus"],
                queryFn: () => getActionInstanceStatus(url.baseUrl),
                select: toActionInstanceStatusDtos,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<PaginatedDto<MyActionItemsDto>, HTTPError>,
        UseQueryResult<ActionItemTypeDto[], HTTPError>,
        UseQueryResult<ActionItemTypeDto[], HTTPError>,
        UseQueryResult<ActionInstanceStatusDto[], HTTPError>,
    ];
};

const transformToMyActionItemsDto = (
    response: Response<PaginationResponse<MyActionItemResponse>>
): PaginatedDto<MyActionItemsDto> => {
    const responseData = response.data;

    const results = responseData.results.map((x) => toMyActionItemsDtos(x));

    return {
        numberOfPages: responseData.pageCount,
        pageSize: responseData.pageSize,
        currentPage: responseData.currentPage,
        recordCount: responseData.recordCount,
        results: results,
    };
};
