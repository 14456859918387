export interface FilterPendingAdhocsDto {
    node: string | null;
    checklistName: string | null;
    referentialLinks: string | null;
    triggeredFor: string | null;
    triggeredBy: string | null;
    isTraining: boolean | null;
}

export const createFilterPendingAdhocsDto = (
    node: string | null,
    checklistName: string | null,
    referentialLinks: string | null,
    triggeredFor: string | null,
    triggeredBy: string | null,
    isTraining: boolean | null
): FilterPendingAdhocsDto => ({
    node: node,
    checklistName: checklistName,
    referentialLinks: referentialLinks,
    triggeredFor: triggeredFor,
    triggeredBy: triggeredBy,
    isTraining: isTraining,
});

export const defaultFilterPendingAdhocsDto: FilterPendingAdhocsDto = createFilterPendingAdhocsDto(
    null,
    null,
    null,
    null,
    null,
    null
);
