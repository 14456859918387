import { ReferentialLinkResponse } from "../../responses/referential-links/referential-link-response";

export default class ReferentialLinkAndTypeValuesDto {
    referentialLinkTypeName: string;
    referentialLinkValue: string;

    public constructor(response: ReferentialLinkResponse) {
        const { referentialLinkTypeName, value } = response;

        this.referentialLinkTypeName = referentialLinkTypeName;
        this.referentialLinkValue = value;
    }

    public static toReferentialLinkAndTypeValuesDto = (
        responses: ReferentialLinkResponse[]
    ): ReferentialLinkAndTypeValuesDto[] =>
        responses.map((x) => new ReferentialLinkAndTypeValuesDto(x));
}
