import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaRedo } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { Common, Reports } from "../../../core/constants/translation-namespace";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { reviewReportSetFileColumnNames } from "../../../core/utilities/dataTableColumns";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { DownloadFile } from "../../../core/utilities/FileDownload";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import {
    BasePaginationDto,
    defaultBasePaginationDto,
} from "../../../domain/dtos/common/base-pagination-dto";
import ReviewReportSetFileDto from "../../../domain/dtos/review-reports/review-report-set-file-dto";
import {
    hasRoleTypeInGroup,
    QuestionSetInstanceReviewRoleGroup,
} from "../../../domain/enums/Roles";
import {
    useDownloadReviewReportSetFile,
    useFilterReviewReportSetFiles,
    useGetReviewReportSetDetails,
} from "../../../domain/viewmodels/review-reports/review-report-set-history-file-actions-viewmodel";
import { SbAlert } from "../../atoms/SbAlert";
import { CancelButton } from "../../atoms/SbButton";
import SbToolTipButton from "../../atoms/SbToolTipButton";
import { TextTitledPanel } from "../../molecules/SbPanel";
import { DataTable } from "../../organisms/DataTable";

const ReviewsHistoryFileDownloadContainer: React.FC = () => {
    const reviewReportSetId = Number(useParams().reviewReportSetId);

    const [basePaginationDto, setBasePaginationDto] =
        useState<BasePaginationDto>(defaultBasePaginationDto);
    const [downloadReviewReportSetFileId, setDownloadReviewReportSetFileId] = useState<
        number | null
    >(null);

    const auth = useAuth();
    const menu = useMenu();
    const navigate = useNavigate();

    const { t } = useTranslation("translation", { keyPrefix: Reports });

    const isInRolesToUnpublish = hasRoleTypeInGroup(
        auth.userRoles,
        QuestionSetInstanceReviewRoleGroup.UnpublishMonitoringRoles
    );

    const reviewReportSetDetails = useGetReviewReportSetDetails(reviewReportSetId);
    const reviewReportSetFiles = useFilterReviewReportSetFiles(
        reviewReportSetId,
        basePaginationDto
    );
    const downloadReviewReportSetFile = useDownloadReviewReportSetFile(
        downloadReviewReportSetFileId
    );

    useLoader(
        areQueriesLoading([
            reviewReportSetDetails,
            reviewReportSetFiles,
            downloadReviewReportSetFile,
        ]),
        ReviewsHistoryFileDownloadContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Checklists);
        menu.changeActiveDrawerItem(DrawerTitles.ReviewsHistory);
    }, []);

    useEffect(() => {
        if (downloadReviewReportSetFile.data) {
            DownloadFile(downloadReviewReportSetFile.data);
        }
    }, [downloadReviewReportSetFile.data?.fileName, downloadReviewReportSetFile.data?.file]);

    const onDownloadFile = (metadata: ReviewReportSetFileDto): void =>
        setDownloadReviewReportSetFileId(metadata.reviewReportSetFileId);

    const navigateToUnpublishReviewReport = (): void => {
        navigate(`${getPath(DrawerTitles.Reviews)}/${reviewReportSetId}/unpublish`);
    };

    return (
        <>
            {areQueriesSuccessful([reviewReportSetDetails, reviewReportSetFiles]) && (
                <>
                    <PageHeading>
                        {`${t("DownloadReviewReportFile")} - ${
                            reviewReportSetDetails.data!.reviewReportName
                        }`}
                    </PageHeading>
                    <SectionVerticalSpace />

                    <SbAlert variant="warning" text={t("UnpublishVersionWarning")} />

                    <TextTitledPanel title={t("DownloadReviewReportFile")}>
                        <SectionVerticalSpace />
                        {isQuerySuccessful(reviewReportSetFiles) && (
                            <DataTable
                                rows={reviewReportSetFiles.data!.rows}
                                columns={reviewReportSetFileColumnNames}
                                downloadFileItem={onDownloadFile}
                                totalItems={reviewReportSetFiles.data!.recordCount}
                                paginationDto={basePaginationDto}
                                setPaginationDto={setBasePaginationDto}
                                noResultsMessage={t("NoRecordsFound", { keyPrefix: Common })}
                            />
                        )}
                    </TextTitledPanel>

                    <SectionVerticalSpace />

                    <EndAlignedDiv>
                        {isInRolesToUnpublish && (
                            <SbToolTipButton
                                onClick={() => navigateToUnpublishReviewReport()}
                                toolTip={
                                    !reviewReportSetDetails.data!.canUnpublish
                                        ? t("UnpublishReportRule")
                                        : undefined
                                }
                                label={t("Unpublish")}
                                icon={FaRedo}
                                disabled={!reviewReportSetDetails.data!.canUnpublish}
                                variant="primary"
                            />
                        )}
                        <CancelButton onClick={() => navigate(-1)} />
                    </EndAlignedDiv>
                </>
            )}
        </>
    );
};

export default ReviewsHistoryFileDownloadContainer;
