export interface ImageLibraryViewResponse {
    fileDetailsId: number;
    fileStoreId: number;
    fileName: string;
    isActive: boolean;
    fileImagePath: string;
}

export const createEmptyImageLibraryViewResponse = {
    fileDetailsId: 0,
    fileStoreId: 0,
    fileName: "",
    isActive: false,
    fileImagePath: "",
};
