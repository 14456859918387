import { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SingleValue } from "react-select";
import { Common, Profile, Users } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { createErrorToastProps, useToast } from "../../../core/store/toast-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areMutationsLoading,
    areQueriesLoading,
    areQueriesSuccessful,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import { AuthenticationProviderDto } from "../../../domain/dtos/tenants/authentication-provider-dto";
import TimeZoneDetailsDto from "../../../domain/dtos/timezone/time-zone-details-dto";
import { hasRoleTypeInGroup, UserRoleGroup } from "../../../domain/enums/Roles";
import { Response } from "../../../domain/responses/common/response-response";
import {
    useCreateAdminUser,
    useCreateTenantUser,
    useGetAuthenticationProviders,
    useGetCreateUserRoleDetails,
    useGetTimeZonesFromNodeTypeValues,
} from "../../../domain/viewmodels/users/create-user-viewmodel";
import { CheckboxFieldProps } from "../../atoms/input/SbCheckField";
import { SbAlert } from "../../atoms/SbAlert";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import SbFormCheckFieldGroup from "../../molecules/input/SbFormCheckFieldGroup";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const CreateUserContainer: React.FC = () => {
    const authenticationType = useRef<AuthenticationProviderDto | null>(null);
    const timeZoneCountry = useRef<string | null>(null);

    const [showPasswordFields, setShowPasswordFields] = useState(true);
    const [roleIdsSelected, setRoleIdsSelected] = useState<number[]>([]);

    const menu = useMenu();
    const { t } = useTranslation("translation", { keyPrefix: Users });
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const auth = useAuth();
    const toast = useToast();

    const getCreateUserRoleDetails = useGetCreateUserRoleDetails();
    const getAuthenticationProviders = useGetAuthenticationProviders();
    const getTimeZonesFromNodeTypeValues = useGetTimeZonesFromNodeTypeValues();

    const createAdminUser = useCreateAdminUser();
    const createTenantUser = useCreateTenantUser();

    useLoader(
        areQueriesLoading([
            getCreateUserRoleDetails,
            getAuthenticationProviders,
            getTimeZonesFromNodeTypeValues,
        ]) || areMutationsLoading([createAdminUser, createTenantUser]),
        CreateUserContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.UserManagement, AccordionTitles.Users);
    }, []);

    useEffect(() => {
        if (isQuerySuccessful(getAuthenticationProviders)) {
            authenticationType.current = getAuthenticationProviders.data?.find((x) => x.isDefault)!;
        }
    }, [getAuthenticationProviders.data]);

    const getAuthenticationTypes = (): CheckboxFieldProps[] => {
        let checkboxFieldProps: CheckboxFieldProps[] = [];
        getAuthenticationProviders.data?.map((x) => {
            checkboxFieldProps.push({
                name: "authenticationType",
                label: x.name,
                defaultSelected: x.isDefault,
                onChangeHandler: () => {
                    authenticationType.current = x;
                    x.isDefault ? setShowPasswordFields(true) : setShowPasswordFields(false);
                },
            });
        });

        return checkboxFieldProps;
    };

    const onRoleCheck = (name: string, checked: boolean): void => {
        if (checked) {
            const roleId = getCreateUserRoleDetails.data!.find(
                (x) => x.roleId.toString() === name
            )?.roleId;
            roleIdsSelected.push(roleId!);
            setRoleIdsSelected(roleIdsSelected);
        } else {
            const index = roleIdsSelected.indexOf(Number(name), 0);
            roleIdsSelected.splice(index, 1);
            setRoleIdsSelected(roleIdsSelected);
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);
        if (formData.get("password") != formData.get("confirmPassword")) {
            toast.addToast(createErrorToastProps(["Please ensure passwords match"]));
            return;
        }
        if (roleIdsSelected.length === 0) {
            toast.addToast(createErrorToastProps(["At least one role is required"]));
            return;
        }

        if (auth.isImpersonating) {
            createTenantUser.mutate(
                {
                    formData: formData,
                    roles: roleIdsSelected,
                    timeZoneCountry: timeZoneCountry.current!,
                    authenticationTypeId: authenticationType.current?.authProviderId!,
                },
                {
                    onSuccess: async (response: Response<number>) =>
                        navigate(`${getPath(AccordionTitles.Users)}/${response.data}`),
                    onError: errorResponseToDisplayHandler,
                }
            );
        } else {
            createAdminUser.mutate(
                {
                    formData: new FormData(event.currentTarget),
                    roles: roleIdsSelected,
                },
                {
                    onSuccess: async (response: Response<number>) =>
                        navigate(`${getPath(AccordionTitles.Users)}/${response.data}`),
                    onError: errorResponseToDisplayHandler,
                }
            );
        }
    };

    return (
        <>
            <PageHeading>{t("UserCreateTitle")}</PageHeading>
            <SectionVerticalSpace />

            {areQueriesSuccessful([getCreateUserRoleDetails]) && (
                <TextTitledPanel title={t("CreateUser")}>
                    {showPasswordFields && (
                        <SbAlert
                            variant="primary"
                            text="The password must be at least 6 characters long, not exceed 100
                                characters, have at least one non-letter or digit character, and at
                                least one uppercase character ('A' - 'Z')"
                        />
                    )}

                    <Form onSubmit={handleSubmit}>
                        {auth.isAdmin && auth.isImpersonating && (
                            <SbFormCheckFieldGroup
                                fieldLabel={t("SelectAuthenticationType", {
                                    keyPrefix: Users,
                                })}
                                type="radio"
                                values={getAuthenticationTypes()}
                            />
                        )}

                        <SbFormTextFieldGroup
                            name="emailAddress"
                            label={t("EmailAddress")}
                            maxLength={100}
                            type="text"
                            required
                        />

                        {showPasswordFields && (
                            <>
                                <SbFormTextFieldGroup
                                    name="password"
                                    label={t("Password", { keyPrefix: "user.password" })}
                                    type="password"
                                    maxLength={100}
                                    minLength={6}
                                    required
                                />
                                <SbFormTextFieldGroup
                                    name="confirmPassword"
                                    label={t("ConfirmPassword", { keyPrefix: "user.password" })}
                                    type="password"
                                    maxLength={100}
                                    minLength={6}
                                    required
                                />
                            </>
                        )}

                        <SbFormTextFieldGroup
                            name="firstName"
                            label={t("FirstName")}
                            type="text"
                            maxLength={50}
                            minLength={2}
                            required
                        />

                        <SbFormTextFieldGroup
                            name="lastName"
                            label={t("LastName")}
                            maxLength={50}
                            minLength={2}
                            type="text"
                            required
                        />

                        {auth.isAdmin && auth.isImpersonating && (
                            <SbFormSelectFieldGroup
                                name={"timeZoneCountry"}
                                label={t("TimeZoneCountry", { keyPrefix: Profile })}
                                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                                searchable
                                clearable
                                required
                                items={getTimeZonesFromNodeTypeValues.data!}
                                itemDisplayText={(option: TimeZoneDetailsDto) => option.displayName}
                                onChange={(option: SingleValue<TimeZoneDetailsDto>) => {
                                    timeZoneCountry.current = option?.timeZoneId ?? null;
                                }}
                            />
                        )}

                        {hasRoleTypeInGroup(auth.userRoles, UserRoleGroup.WriteRoles) &&
                            getCreateUserRoleDetails.data!.map((role, index) => (
                                <SbFormCheckFieldGroup
                                    key={`role${index}`}
                                    fieldLabel={role.name}
                                    type="checkbox"
                                    values={[
                                        {
                                            name: role.roleId.toString(),
                                            onChangeHandler: onRoleCheck,
                                        },
                                    ]}
                                />
                            ))}

                        <EndAlignedDiv>
                            <SaveButton type="submit" />
                            <CancelButton onClick={() => navigate(-1)} />
                        </EndAlignedDiv>
                    </Form>
                </TextTitledPanel>
            )}
        </>
    );
};

export default CreateUserContainer;
