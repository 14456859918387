import {
    buildFilterSearchParameters,
    FilterReviewReportSetHistoryRequest,
} from "../../../domain/requests/review-reports/filter-review-report-set-history-request";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import ReviewReportSetHistoryResponse from "../../../domain/responses/review-reports/review-report-set-history-response";
import client from "../../http-client";

const route = "reviewreportshistory";

export const filterReviewReportSetHistory = async (
    prefixUrl: string,
    request: FilterReviewReportSetHistoryRequest
): Promise<Response<PaginationResponse<ReviewReportSetHistoryResponse>>> => {
    const searchParams = buildFilterSearchParameters(request);

    return await client
        .get(`${route}/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};
