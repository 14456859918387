import { NonWorkingDayResponse } from "../../responses/non-working-days/non-working-day-response";

export interface NonWorkingDayDto {
    nonWorkingDayId: number;
    date: Date;
    name: string;
}

export const toNonWorkingDayDto = (
    nonWorkingDayResponse: NonWorkingDayResponse
): NonWorkingDayDto => ({
    nonWorkingDayId: nonWorkingDayResponse.nonWorkingDayId,
    date: new Date(nonWorkingDayResponse.date),
    name: nonWorkingDayResponse.name,
});
