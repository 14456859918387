import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    editPredefinedAnswer,
    getAnswerTypeDetails,
    getPredefinedAnswerDetails,
} from "../../../data/services/answer-types/answer-types-service";
import BaseAnswerTypeDto from "../../dtos/answer-types/base-answer-type-dto";
import { EditPredefinedAnswerDto } from "../../dtos/predefined-answers/edit-predefined-answer-dto";
import PredefinedAnswerDto from "../../dtos/predefined-answers/predefined-answer-dto";
import { createEditPredefinedAnswerRequest } from "../../requests/answer-types/edit-predefined-answer-request";
import { Response } from "../../responses/common/response-response";

export const useGetPredefinedAnswerDetails = (
    predefinedAnswerId: number
): UseQueryResult<PredefinedAnswerDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getPredefinedAnswerDetails"],
        () => getPredefinedAnswerDetails(url.baseUrl, predefinedAnswerId),
        {
            select: PredefinedAnswerDto.constructFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useEditPredefinedAnswer = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    EditPredefinedAnswerDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: EditPredefinedAnswerDto) => {
        const { predefinedAnswerId, flagMode, formData } = mutationKey;
        const request = createEditPredefinedAnswerRequest(
            predefinedAnswerId,
            flagMode,
            formData.get("tooltip") as string
        );

        return editPredefinedAnswer(url.baseUrl, request);
    });
};

export const useGetAnswerTypeDetails = (
    answerTypeId: number
): UseQueryResult<BaseAnswerTypeDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getAnswerTypeDetails", answerTypeId],
        () => getAnswerTypeDetails(url.baseUrl, answerTypeId),
        {
            select: BaseAnswerTypeDto.constructFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};
