import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { editWizardCard } from "../../../data/services/wizards/wizard-cards-service";
import { Response } from "../../responses/common/response-response";

interface EditParameters {
    wizardCardId: number;
    fileDetailsId: number;
    formData: FormData;
}

export const useEditWizardCard = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    EditParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: EditParameters) => {
        const formData = mutationKey.formData;

        const request = {
            heading: formData.get("heading")! as string,
            description: formData.get("description")! as string,
            wizardCardId: mutationKey.wizardCardId,
            fileDetailsId: mutationKey.fileDetailsId,
        };

        return editWizardCard(url.originalBaseUrl, request);
    });
};
