import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { NavigateFunction, useNavigate } from "react-router";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { DataTableColumnTypes, DrawerTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { filterNotificationTriggers } from "../../../data/services/notification-triggers/notification-triggers-service";
import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { TriggeringDate } from "../../enums/notification-triggers/triggering-date";
import { createBasePaginationRequestFromDto } from "../../requests/common/base-pagination-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { NotificationTriggerResponse } from "../../responses/notification-triggers/notification-trigger-response";

export const useFilterNotificationTriggers = (
    paginationDto: BasePaginationDto
): UseQueryResult<PaginatedTableDto<number>, HTTPError> => {
    const url = useUrl();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterNotificationTriggers", paginationDto],
        () =>
            filterNotificationTriggers(
                url.baseUrl,
                createBasePaginationRequestFromDto(paginationDto)
            ),
        {
            select: (response: Response<PaginationResponse<NotificationTriggerResponse>>) =>
                toDataTableRows(response.data, navigate),
            onError: errorResponseToDisplayHandler,
        }
    );
};

const toDataTableRows = (
    response: PaginationResponse<NotificationTriggerResponse>,
    navigate: NavigateFunction
): PaginatedTableDto<number> => {
    const { pageCount, pageSize, currentPage, recordCount } = response;

    const rows = response.results!.map((x) => ({
        metadata: x.notificationTriggerId,
        columns: [
            {
                value: x.name,
                type: DataTableColumnTypes.Link,
                linkItemAction: (notificationTriggerId: number) => {
                    navigate(
                        `${getPath(DrawerTitles.NotificationTriggers)}/${notificationTriggerId}`
                    );
                },
            },
            {
                value: x.triggerBefore.toString(),
                type: DataTableColumnTypes.Check,
            },
            {
                value: TriggeringDate[x.triggeringDate].toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: x.triggerTimeDeltaIntervalResponse.timeSpan,
                type: DataTableColumnTypes.Text,
            },
            {
                value: x.sendWindowIntervalResponse.timeSpan,
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
