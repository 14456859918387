import { BaseChecklistResponse } from "../../responses/checklists/base-checklist-response";
import { Response } from "../../responses/common/response-response";

export default class BaseChecklistDto {
    checklistId: number;
    name: string;

    public constructor(response: BaseChecklistResponse) {
        const { checklistId, name } = response;

        this.checklistId = checklistId;
        this.name = name;
    }

    public static constructFromBaseChecklistResponse = (
        response: Response<BaseChecklistResponse[]>
    ): BaseChecklistDto[] => response.data.map((x) => new BaseChecklistDto(x));
}
