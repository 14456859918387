import { EscalationLevel } from "../../enums/hierarchy/EscalationLevel";
import { HierarchyAssignable } from "../../enums/hierarchy/HierarchyAssignable";
import { Response } from "../../responses/common/response-response";
import { NodeTypeDetailsResponse } from "../../responses/hierarchy/node-type-details-response";
import { createHierarchyTypeDto, HierarchyTypeDto } from "./hierarchy-type-dto";
import { createNodeTypeDto, NodeTypeDto, toNodeTypeDtos } from "./node-type-dto";

export interface NodeTypeDetailsDto extends NodeTypeDto {
    hierarchyAssignables: HierarchyAssignable[];
    escalationLevels: EscalationLevel[];
    hierarchyType: HierarchyTypeDto;
    parentNodeTypes: NodeTypeDto[] | null;
}

export const createNodeTypeDetailsDto = (
    response: Response<NodeTypeDetailsResponse>
): NodeTypeDetailsDto => {
    const responseData = response.data;

    return {
        ...createNodeTypeDto(responseData),
        hierarchyAssignables: responseData.hierarchyAssignables,
        escalationLevels: responseData.escalationLevels,
        hierarchyType: createHierarchyTypeDto(responseData.hierarchyType),
        parentNodeTypes: toNodeTypeDtos(responseData.parentNodeTypes),
    };
};
