import { HTTPError } from "ky";
import React, { ReactElement, useEffect, useState } from "react";
import { Form, FormGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { EditNodeEvent } from "../../../core/constants/application-insights-events";
import {
    Common,
    Hierarchy,
    HierarchyTypeTreeView,
    Nodes,
    NonWorkingDays,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { hierarchyLevelValueActionsColumnNames } from "../../../core/utilities/dataTableColumns";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import { defaultPaginationDto, PaginationDto } from "../../../domain/dtos/common/pagination-dto";
import { NonWorkingDaySetDto } from "../../../domain/dtos/non-working-days/non-working-day-set-dto";
import {
    useEditNode,
    useGetEditNodeDetails,
} from "../../../domain/viewmodels/hierarchy/edit-node-viewmodel";
import { useFilterNodeTypeValuesToTableRows } from "../../../domain/viewmodels/hierarchy/text-search-node-viewmodel";
import { ErrorAlert } from "../../atoms/SbAlert";
import { CancelButton, SaveButton, SbButton } from "../../atoms/SbButton";
import { SbRibbon } from "../../atoms/SbRibbon";
import { RadioSelectProps } from "../../molecules/DataTableAction";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";
import { DataTable } from "../../organisms/DataTable";

const EditNodeContainer: React.FC = () => {
    const [isCreatingNewNodeTypeValue, setIsCreatingNewNodeTypeValue] = useState(false);
    const [nodeTypeValuesPaginationDto, setNodeTypeValuesPaginationDto] =
        useState<PaginationDto>(defaultPaginationDto);
    const [nodeTypeValueSearchText, setNodeTypeValueSearchText] = useState<string | null>(null);
    const [selectedNodeTypeValueId, setSelectedNodeTypeValueId] = useState<number | null>(null);

    const menu = useMenu();
    const navigate = useNavigate();
    const auth = useAuth();
    const { t } = useTranslation("translation", { keyPrefix: Nodes });
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const nodeId = Number(useParams().nodeId);

    const inheritedNonWorkingDaySetOption = {
        nonWorkingDaySetId: -1,
        name: "No non working day set directly associated, inherits from an ancestor node",
    };

    const getEditNodeDetails = useGetEditNodeDetails(nodeId);
    const nodeDetailsResponseData = getEditNodeDetails[0].data;
    const nodeDetailsNodeResponseData = nodeDetailsResponseData?.node;
    const nonWorkingDaySetsResponseData = getEditNodeDetails[1].data!;

    const filterNodeTypeValues = useFilterNodeTypeValuesToTableRows(
        nodeDetailsResponseData?.node.nodeType.hierarchyTypeId ?? null,
        nodeDetailsResponseData?.node.nodeType.nodeTypeId ?? null,
        nodeTypeValueSearchText,
        nodeTypeValuesPaginationDto
    );

    const performNodeTypeValueSearch = (searchString: string | null): void => {
        setNodeTypeValueSearchText(searchString);
    };

    const editNode = useEditNode();

    useLoader(
        areQueriesLoading([...getEditNodeDetails]) || isMutationLoading(editNode),
        EditNodeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.VisualTree);
    }, []);

    const queriesSuccessful = (): boolean => areQueriesSuccessful([...getEditNodeDetails]);

    const radioSelect: RadioSelectProps = {
        type: "radio",
        label: `${t("SelectNode", { keyPrefix: HierarchyTypeTreeView })}`,
    };

    const selectedTextSearchNode = (nodeTypeValueId: number): boolean => {
        return nodeTypeValueId === selectedNodeTypeValueId;
    };

    useEffect(() => {
        setNodeTypeValueSearchText(nodeDetailsNodeResponseData?.nodeTypeValue.value ?? null);
        setSelectedNodeTypeValueId(
            nodeDetailsNodeResponseData?.nodeTypeValue.nodeTypeValueId ?? null
        );
    }, [getEditNodeDetails[0].data]);

    const buildRibbon = (): ReactElement | false =>
        (nodeDetailsNodeResponseData!.isTraining ||
            nodeDetailsResponseData!.inheritedTrainingFlag) && (
            <SbRibbon size={"large"} label={"Training"} />
        );

    const buildNodeTypeValueFormFields = (): ReactElement<typeof FormGroup> => {
        const toggleIsCreatingNewNodeTypeValue = (): void =>
            setIsCreatingNewNodeTypeValue(!isCreatingNewNodeTypeValue);

        const buildSelectNodeTypeValueFormField = (): ReactElement<typeof FormGroup> => (
            <>
                <SbFormTextFieldGroup
                    name={"searchValue"}
                    label={t("SearchValue", { keyPrefix: Nodes })}
                    type={"text"}
                    value={nodeTypeValueSearchText || ""}
                    onTextChanged={performNodeTypeValueSearch}
                >
                    <SbButton
                        variant="primary"
                        type={"button"}
                        label={t("CreateNew", { keyPrefix: Common })}
                        icon={FaPlus}
                        onClick={toggleIsCreatingNewNodeTypeValue}
                    />
                </SbFormTextFieldGroup>

                {
                    <DataTable
                        columns={hierarchyLevelValueActionsColumnNames}
                        rows={filterNodeTypeValues.data?.rows}
                        totalItems={filterNodeTypeValues.data?.recordCount}
                        paginationDto={nodeTypeValuesPaginationDto}
                        selectItem={(nodeTypeValueId) => {
                            setSelectedNodeTypeValueId(nodeTypeValueId);
                        }}
                        selectedItem={selectedTextSearchNode}
                        radioSelect={radioSelect}
                        setPaginationDto={setNodeTypeValuesPaginationDto}
                        noResultsMessage={t("NoNodesFound")}
                        isLoading={isQueryLoading(filterNodeTypeValues)}
                        isSuccessful={isQuerySuccessful(filterNodeTypeValues)}
                    />
                }
            </>
        );

        const buildCreateNewNodeTypeValueFormFields = (): ReactElement<typeof FormGroup> => (
            <>
                <SbFormTextFieldGroup
                    name="nodeTypeValueValue"
                    label={t("NewHierarchyLevelValue", { keyPrefix: Nodes })}
                    type="text"
                    maxLength={100}
                    required
                >
                    <CancelButton onClick={toggleIsCreatingNewNodeTypeValue} />
                </SbFormTextFieldGroup>

                <SbFormTextFieldGroup
                    name="nodeTypeValueCode"
                    maxLength={50}
                    label={t("Code", { keyPrefix: Common })}
                    type="text"
                />

                <SbFormTextFieldGroup
                    name="nodeTypeValueDescription"
                    maxLength={100}
                    label={t("Description", { keyPrefix: Common })}
                    type="text"
                />
            </>
        );

        return isCreatingNewNodeTypeValue
            ? buildCreateNewNodeTypeValueFormFields()
            : buildSelectNodeTypeValueFormField();
    };

    const buildNonWorkingDaySetFormField = (): ReactElement<typeof FormGroup> | false =>
        nodeDetailsNodeResponseData!.isNonWorkingDaySetAssignable && (
            <>
                <SbFormSelectFieldGroup
                    name={"nonWorkingDaySet"}
                    label={t("NonWorkingDaySet", { keyPrefix: NonWorkingDays })}
                    placeholderText={`${t("SelectNonWorkingDaySet", {
                        keyPrefix: NonWorkingDays,
                    })}`}
                    searchable
                    clearable
                    items={buildNonWorkingDaySetOptions()}
                    defaultSelectedItem={getDefaultSelectedNonWorkingDaySet()}
                    itemDisplayText={(option: NonWorkingDaySetDto) => option.name} //TODO: Add translations for dynamic data
                />

                {nodeDetailsNodeResponseData!.nonWorkingDaySet &&
                    nodeDetailsResponseData!.inheritedNonWorkingDaySet && (
                        <SbFormTextFieldGroup
                            name="inheritedNonWorkingDaySet"
                            label={t("InheritedNonWorkingDaySet", { keyPrefix: Nodes })}
                            type="text"
                            defaultValue={nodeDetailsResponseData!.inheritedNonWorkingDaySet.name}
                            disabled
                        />
                    )}
            </>
        );

    const buildNonWorkingDaySetOptions = (): NonWorkingDaySetDto[] => [
        inheritedNonWorkingDaySetOption,
        ...nonWorkingDaySetsResponseData,
    ];

    const getDefaultSelectedNonWorkingDaySet = (): NonWorkingDaySetDto | undefined => {
        const nonWorkingDaySet = nodeDetailsNodeResponseData!.nonWorkingDaySet;
        const inheritedNonWorkingDaySet = nodeDetailsResponseData!.inheritedNonWorkingDaySet;
        if (!nonWorkingDaySet) {
            return inheritedNonWorkingDaySet ? inheritedNonWorkingDaySetOption : undefined;
        }

        return nonWorkingDaySetsResponseData.find(
            (x) => x.nonWorkingDaySetId == nonWorkingDaySet!.nonWorkingDaySetId
        );
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        editNode.mutate(
            {
                nodeDetails: nodeDetailsNodeResponseData!,
                formData: new FormData(event.currentTarget),
                isCreatingNodeTypeValue: isCreatingNewNodeTypeValue,
                nonWorkingDaySets: nonWorkingDaySetsResponseData,
                selectedNodeTypeValueId: selectedNodeTypeValueId,
            },
            {
                onSuccess: async () => {
                    trackAppInsightsEvent(auth.email, window.location.href, EditNodeEvent);
                    navigate(
                        `${getPath(AccordionTitles.VisualTree)}/${
                            nodeDetailsNodeResponseData!.nodeId
                        }`
                    );
                },

                onError: (error: HTTPError) => {
                    trackAppInsightsException(
                        auth.email,
                        window.location.href,
                        EditNodeEvent,
                        error
                    );
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    };

    return (
        <>
            <PageHeading>{t("NodeEditTitle")}</PageHeading>
            <SectionVerticalSpace />

            {queriesSuccessful() && (
                <>
                    {!nodeDetailsNodeResponseData!.isEditable && (
                        <ErrorAlert
                            messages={[t("CannotEditOrDeleteSystemNode", { keyPrefix: Hierarchy })]}
                        />
                    )}

                    <TextTitledPanel title={t("EditNode")}>
                        {buildRibbon()}

                        <Form onSubmit={handleSubmit}>
                            <fieldset disabled={!nodeDetailsNodeResponseData!.isEditable}>
                                <SbFormTextFieldGroup
                                    name="nodeType"
                                    label={t("HierarchyLevel", { keyPrefix: Hierarchy })}
                                    type="text"
                                    defaultValue={nodeDetailsNodeResponseData!.nodeType.name}
                                    disabled
                                />

                                {buildNodeTypeValueFormFields()}
                                {buildNonWorkingDaySetFormField()}
                            </fieldset>

                            <EndAlignedDiv>
                                <SaveButton
                                    type="submit"
                                    disabled={!nodeDetailsNodeResponseData!.isEditable}
                                />
                                <CancelButton onClick={() => navigate(-1)} />
                            </EndAlignedDiv>
                        </Form>
                    </TextTitledPanel>
                </>
            )}
        </>
    );
};

export default EditNodeContainer;
