import { QuestionImportanceResponse } from "../../responses/question-importance/question-importance-response";

export interface QuestionImportanceDto {
    questionImportanceId: number;
    name: string;
}

export const createQuestionImportanceDto = (
    questionImportanceResponse: QuestionImportanceResponse
): QuestionImportanceDto => ({
    questionImportanceId: questionImportanceResponse.questionImportanceId,
    name: questionImportanceResponse.name,
});

export const toQuestionImportanceDto = (
    data: QuestionImportanceResponse[]
): QuestionImportanceDto[] =>
    data.map((questionImportance) => createQuestionImportanceDto(questionImportance));
