import { CreateQuestionSetRequest } from "../question-set/create-question-set-request";

export interface AssociateNewQuestionSetToChecklistRequest {
    checklistId: number;
    createQuestionSetRequest: CreateQuestionSetRequest;
}

export const createAssociateNewQuestionSetToChecklistRequest = (
    checklistId: number,
    createQuestionSetRequest: CreateQuestionSetRequest
): AssociateNewQuestionSetToChecklistRequest => ({ checklistId, createQuestionSetRequest });
