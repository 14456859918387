import { CreateActionItemDynamicFieldRequest } from "../../../domain/requests/action-items/create-action-item-dynamic-field-request";
import { EditActionItemDynamicFieldRequest } from "../../../domain/requests/action-items/edit-action-item-dynamic-field-request";
import {
    buildFilterActionItemDynamicFieldsSearchParameters,
    FilterActionItemDynamicFieldsRequest,
} from "../../../domain/requests/action-items/filter-action-item-dynamic-fields-request";
import { OrderActionItemDynamicFieldRequest } from "../../../domain/requests/action-items/order-action-item-dynamic-field-request";
import { ActionItemDynamicFieldDetailsResponse } from "../../../domain/responses/action-items/action-item-dynamic-field-details-response";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import client from "../../http-client";

const route = "actionitemdynamicfields";

export const filterActionItemDynamicFields = async (
    prefixUrl: string,
    request: FilterActionItemDynamicFieldsRequest
): Promise<Response<PaginationResponse<ActionItemDynamicFieldDetailsResponse>>> => {
    const searchParams = buildFilterActionItemDynamicFieldsSearchParameters(request);

    return await client
        .get(`${route}/filter`, {
            prefixUrl: prefixUrl,
            searchParams: searchParams,
        })
        .json();
};

export const getActionItemDynamicFieldDetails = async (
    prefixUrl: string,
    actionItemDynamicFieldId: number
): Promise<Response<ActionItemDynamicFieldDetailsResponse>> =>
    await client
        .get(`${route}/${actionItemDynamicFieldId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const createActionItemDynamicField = async (
    prefixUrl: string,
    request: CreateActionItemDynamicFieldRequest
): Promise<Response<number | null>> =>
    await client
        .post(`${route}/create`, {
            prefixUrl: prefixUrl,
            body: JSON.stringify(request),
        })
        .json();

export const editActionItemDynamicField = async (
    prefixUrl: string,
    request: EditActionItemDynamicFieldRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/edit`, {
            prefixUrl: prefixUrl,
            body: JSON.stringify(request),
        })
        .json();

export const orderActionItemDynamicFields = async (
    prefixUrl: string,
    request: OrderActionItemDynamicFieldRequest[]
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/order`, {
            prefixUrl: prefixUrl,
            body: JSON.stringify(request),
        })
        .json();

export const deleteActionItemDynamicField = async (
    prefixUrl: string,
    actionItemDynamicFieldId: number
): Promise<Response<boolean>> =>
    await client
        .delete(`${route}/delete/${actionItemDynamicFieldId}`, {
            prefixUrl: prefixUrl,
        })
        .json();
