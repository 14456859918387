import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { createPredefinedAnswerText } from "../../../data/services/answer-types/answer-types-service";
import { getLanguages } from "../../../data/services/language/language-service";
import { LanguageDto, toLanguageDtos } from "../../dtos/language/language-dto";
import { CreatePredefinedAnswerTextDto } from "../../dtos/predefined-answers/create-predefined-answer-text-dto";
import { createCreatePredefinedAnswerTextRequest } from "../../requests/answer-types/create-predefined-answer-text-request";
import { Response } from "../../responses/common/response-response";

interface CreateParams {
    predefinedAnswerId: number;
    languageId: number;
    formData: FormData;
}

export const createCreateParams = (
    predefinedAnswerId: number,
    languageId: number,
    formData: FormData
): CreateParams => ({
    predefinedAnswerId: predefinedAnswerId,
    languageId: languageId,
    formData: formData,
});

export const useCreatePredefinedAnswerText = (): UseMutationResult<
    Response<number | null>,
    HTTPError,
    CreatePredefinedAnswerTextDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: CreatePredefinedAnswerTextDto) => {
        const { predefinedAnswerId, languageId, formData } = mutationKey;

        const request = createCreatePredefinedAnswerTextRequest(
            predefinedAnswerId,
            languageId,
            formData.get("value") as string
        );

        return createPredefinedAnswerText(url.baseUrl, request);
    });
};

export const useGetLanguageLookups = (): UseQueryResult<LanguageDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(["getLanguages"], () => getLanguages(url.baseUrl), {
        select: toLanguageDtos,
        onError: errorResponseToDisplayHandler,
    });
};
