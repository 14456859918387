import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { filterRoleRestrictedNodesByNodeTypeValueId } from "../../../data/services/hierarchy/role-restricted-nodes-service";
import RoleRestrictedNodeDto from "../../dtos/hierarchy/role-restricted-node-dto";

export const useGetRoleRestrictedNodes = (
    nodeTypeValueId: number
): UseQueryResult<RoleRestrictedNodeDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterRoleRestrictedNodesByNodeTypeValueId"],
        () => filterRoleRestrictedNodesByNodeTypeValueId(url.baseUrl, nodeTypeValueId),
        {
            select: RoleRestrictedNodeDto.constructFromResponses,
            onError: errorResponseToDisplayHandler,
        }
    );
};
