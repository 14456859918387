import { HTTPError } from "ky";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsCheck2Square } from "react-icons/bs";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
    CloneChecklistAndQuestionSestEvent,
    CloneChecklistEvent,
    DissociateRefLinkFromChecklistEvent,
} from "../../../core/constants/application-insights-events";
import { EnterKey } from "../../../core/constants/KeyboardKeys";
import {
    ChecklistQuestions,
    Common,
    Nodes,
    Users,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useKeyPress from "../../../core/hooks/keyPress";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    ContentContainer,
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import {
    associatedNodeColumnNames,
    associatedReferentialLinkColumnNames,
    nodesColumnNames,
    questionSetColumnNames,
} from "../../../core/utilities/dataTableColumns";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isMutationLoading,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import {
    defaultFilterAssociatedNodesDto,
    FilterAssociatedNodesDto,
} from "../../../domain/dtos/checklist-assignment/filter-associated-nodes-dto";
import { AssociatedChecklistNodeDto } from "../../../domain/dtos/checklists/associated-checklist-node-dto";
import { defaultPaginationDto, PaginationDto } from "../../../domain/dtos/common/pagination-dto";
import ChecklistType from "../../../domain/enums/checklists/ChecklistType";
import { ChecklistRoleGroup, hasRoleTypeInGroup } from "../../../domain/enums/Roles";
import { emptySearchChecklistAssociationRequest } from "../../../domain/requests/checklists/search-checklist-association-request";
import { ChecklistResponse } from "../../../domain/responses/checklists/checklist-response";
import { Response } from "../../../domain/responses/common/response-response";
import {
    createCloneParameters,
    useCloneChecklist,
} from "../../../domain/viewmodels/checklists/clone-checklist-viewmodel";
import { useDissociateReferentialLink } from "../../../domain/viewmodels/checklists/dissociate-referential-link-viewmodel";
import { useGetChecklistDetails } from "../../../domain/viewmodels/checklists/view-checklist-details-viewmodel";
import { CloneButton } from "../../atoms/SbButton";
import {
    CreateLink,
    DemoteLink,
    EditLink,
    PromoteLink,
    SbLink,
    ViewLink,
} from "../../atoms/SbLink";
import { SbRibbon } from "../../atoms/SbRibbon";
import { CreateLabelToDetailRows } from "../../molecules/CreateLabelToDetailRows";
import { SbAccordion } from "../../molecules/SbAccordion";
import { ComponentPanel } from "../../molecules/SbPanel";
import {
    CheckboxConfirmationRow,
    ConfirmationRow,
    TextConfirmationRow,
} from "../../organisms/ActionConfirmation";
import { DataTable } from "../../organisms/DataTable";
import { ChecklistAssignedNodesFilter } from "../../organisms/filters/ChecklistAssignedNodesFilter";
import { PromoteDemoteConfirmationProps } from "./PromoteDemoteChecklistContainer";
import { ReviewConfirmationProps } from "./ReviewChecklistContainer";

const ViewChecklistContainer = (): JSX.Element => {
    const [questionSetsPaginationDto, setQuestionSetsPaginationDto] =
        useState<PaginationDto>(defaultPaginationDto);
    const [nodesPaginationDto, setNodesPaginationDto] =
        useState<PaginationDto>(defaultPaginationDto);
    const [referentialLinksPaginationDto, setReferentialLinksPaginationDto] =
        useState<PaginationDto>(defaultPaginationDto);
    const [taxonomyNodesPaginationDto, setTaxonomyNodesPaginationDto] =
        useState<PaginationDto>(defaultPaginationDto);

    interface SearchParams {
        checklistAssignmentId: number | null;
        nodeSearchText: string | null;
    }

    const createSearchParams = (
        checklistAssignmentId: number | null,
        nodeSearchText: string | null
    ): SearchParams => ({
        checklistAssignmentId: checklistAssignmentId,
        nodeSearchText: nodeSearchText,
    });

    const defaultSearchParams: SearchParams = createSearchParams(null, null);

    const menu = useMenu();
    const navigate = useNavigate();
    const [filterDto, setFilterDto] = useState<FilterAssociatedNodesDto>(
        defaultFilterAssociatedNodesDto
    );
    const [searchParams, setSearchParams] = useState<SearchParams>(defaultSearchParams);
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });
    const navigateSearch = useNavigateSearch();
    const toast = useToast();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const auth = useAuth();

    const clonedChecklist = useCloneChecklist();

    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";
    const checklistId = Number(useParams().checklistId);

    const searchRequest = {
        ...emptySearchChecklistAssociationRequest(),
        checklistId: checklistId,
    };

    let checklistDetails = useGetChecklistDetails(
        checklistId,
        questionSetsPaginationDto,
        nodesPaginationDto,
        filterDto,
        referentialLinksPaginationDto,
        taxonomyNodesPaginationDto,
        searchRequest
    );

    const updateChecklistAssingmentIdValue = (checklistAssignmentId: string) => {
        setSearchParams({
            ...searchParams,
            checklistAssignmentId:
                checklistAssignmentId !== "" ? parseInt(checklistAssignmentId) : null,
        });
    };

    const updateNodeValue = (nodeSearchText: string) => {
        setSearchParams({
            ...searchParams,
            nodeSearchText: nodeSearchText !== "" ? nodeSearchText : null,
        });
    };

    const search = () => {
        setFilterDto({ ...filterDto, pageNumber: 1, ...searchParams });
    };

    const resetFilter = async (): Promise<void> => {
        setSearchParams(defaultSearchParams);

        setFilterDto({ ...filterDto, ...defaultSearchParams });
    };

    useKeyPress(EnterKey, search, searchParams);

    const dissociateReferentialLink = useDissociateReferentialLink();

    const checklistDetailsResponseData = checklistDetails[0].data;
    const associatedQuestionSetsResponseData = checklistDetails[1].data;
    const associatedNodesResponseData = checklistDetails[2].data;
    const associatedReferentialLinksResponseData = checklistDetails[3].data;
    const associatedTaxonomyNodesResponseData = checklistDetails[4].data;

    useLoader(
        areQueriesLoading(checklistDetails) || isMutationLoading(dissociateReferentialLink),
        ViewChecklistContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Checklists);

        if (success) {
            toast.addToast(createSuccessToastProps([t(messageKey)]));

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    const handleDissociateQuestionSet = (questionSetId: number): void => {
        const params = [createNavigateSearchParameter("questionSetId", questionSetId.toString())];

        navigateSearch(
            `${getPath(AccordionTitles.Checklists)}/${checklistId}/dissociate-question-sets`,
            params
        );
    };

    const handleDissociateNode = (metadata: AssociatedChecklistNodeDto): void => {
        const params = [
            createNavigateSearchParameter("nodeId", metadata.nodeId.toString()),
            createNavigateSearchParameter(
                "checklistAssignmentId",
                metadata.checklistAssignmentId.toString()
            ),
        ];

        navigateSearch(
            `${getPath(AccordionTitles.Checklists)}/${checklistId}/dissociate-node`,
            params
        );
    };

    const handleDissociateReferentialLink = (referentialLinkId: number): void => {
        let referentialLinkIds = [];
        referentialLinkIds.push(referentialLinkId);

        dissociateReferentialLink.mutate(
            {
                checklistId: checklistId,
                referentialLinkIds: referentialLinkIds,
            },
            {
                onSuccess: async () => {
                    trackAppInsightsEvent(
                        auth.email,
                        window.location.href,
                        DissociateRefLinkFromChecklistEvent
                    );

                    toast.addToast(
                        createSuccessToastProps([t("SuccessfullyDissociatedReferentialLink")])
                    );
                    queryClient.invalidateQueries(["getAssociatedReferentialLinks"]);
                },
                onError: (error: HTTPError) => {
                    trackAppInsightsException(
                        auth.email,
                        window.location.href,
                        DissociateRefLinkFromChecklistEvent,
                        error
                    );
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    };

    const handleDissociateTaxonomyNode = (nodeId: number): void => {
        const params = [createNavigateSearchParameter("nodeId", nodeId.toString())];

        navigateSearch(
            `${getPath(AccordionTitles.Checklists)}/${checklistId}/dissociate-taxonomy-node`,
            params
        );
    };

    const buildLinks = (): ReactElement<typeof Link> => {
        return (
            <>
                {hasRoleTypeInGroup(auth.userRoles, ChecklistRoleGroup.WriteRoles) && (
                    <>
                        {buildReviewLink()}
                        {hasRoleTypeInGroup(auth.userRoles, ChecklistRoleGroup.PromoteRoles) &&
                            buildLockoutLinks()}
                        {buildEditLink()}
                        {buildViewLink()}
                    </>
                )}
                {buildCreateLink()}
                {hasRoleTypeInGroup(auth.userRoles, ChecklistRoleGroup.WriteRoles) && (
                    <>
                        {buildChecklistCloneButton()}
                        {buildChecklistAndQuestionSetsCloneButton()}
                    </>
                )}
            </>
        );
    };

    const buildReviewLink = (): ReactElement<typeof Link> => (
        <SbLink
            variant="primary"
            label={t("Review")}
            icon={BsCheck2Square}
            navigateTo={`${getPath(AccordionTitles.Checklists)}/${checklistId}/review`}
            navigationProps={buildReviewLinkProps()}
        />
    );

    const buildReviewLinkProps = (): ReviewConfirmationProps => ({
        name: checklistDetailsResponseData!.name,
        description: checklistDetailsResponseData!.description,
    });

    const buildLockoutLinks = (): ReactElement<typeof Link> =>
        checklistDetailsResponseData!.isTraining ? (
            <PromoteLink
                navigateTo={`${getPath(AccordionTitles.Checklists)}/${checklistId}/promote`}
                navigationProps={buildPromoteDemoteLinkProps()}
            />
        ) : (
            <DemoteLink
                navigateTo={`${getPath(AccordionTitles.Checklists)}/${checklistId}/demote`}
                navigationProps={buildPromoteDemoteLinkProps()}
            />
        );

    const buildPromoteDemoteLinkProps = (): PromoteDemoteConfirmationProps => ({
        name: checklistDetailsResponseData!.name,
        description: checklistDetailsResponseData!.description,
        isTraining: checklistDetailsResponseData!.isTraining,
    });

    const buildEditLink = (): ReactElement<typeof Link> => (
        <EditLink navigateTo={`${getPath(AccordionTitles.Checklists)}/${checklistId}/edit`} />
    );

    const buildViewLink = (): ReactElement<typeof Link> => (
        <ViewLink label={t("Checklists")} navigateTo={`${getPath(AccordionTitles.Checklists)}`} />
    );

    const buildCreateLink = (): ReactElement<typeof Link> => (
        <CreateLink
            label={t("CreateNew", { keyPrefix: Common })}
            navigateTo={`${getPath(AccordionTitles.Checklists)}/create`}
        />
    );

    const buildChecklistCloneButton = (): ReactElement<typeof Link> => (
        <CloneButton onClick={cloneChecklist} label={`${t("CloneChecklist")}`} />
    );

    const buildChecklistAndQuestionSetsCloneButton = (): ReactElement<typeof Link> => (
        <CloneButton
            onClick={cloneChecklistAndQuestionSets}
            label={`${t("CloneChecklistAndQuestionSets")}`}
        />
    );

    const cloneChecklist = (): void => {
        clonedChecklist.mutate(createCloneParameters(checklistId, false), {
            onSuccess: async (response: Response<ChecklistResponse | null>) => {
                trackAppInsightsEvent(auth.email, window.location.href, CloneChecklistEvent);
                toast.addToast(createSuccessToastProps([t("ChecklistCloneSuccess")]));
                navigate(`${getPath(AccordionTitles.Checklists)}/${response.data?.checklistId}`);
            },
            onError: (error: HTTPError) => {
                trackAppInsightsException(
                    auth.email,
                    window.location.href,
                    CloneChecklistEvent,
                    error
                );
                errorResponseToDisplayHandler(error);
            },
        });
    };

    const cloneChecklistAndQuestionSets = (): void => {
        clonedChecklist.mutate(createCloneParameters(checklistId, true), {
            onSuccess: async (response: Response<ChecklistResponse | null>) => {
                trackAppInsightsEvent(
                    auth.email,
                    window.location.href,
                    CloneChecklistAndQuestionSestEvent
                );
                toast.addToast(createSuccessToastProps([t("ChecklistAndQuestionSetCloneSuccess")]));
                navigate(`${getPath(AccordionTitles.Checklists)}/${response.data?.checklistId}`);
            },
            onError: (error: HTTPError) => {
                trackAppInsightsException(
                    auth.email,
                    window.location.href,
                    CloneChecklistAndQuestionSestEvent,
                    error
                );
                errorResponseToDisplayHandler(error);
            },
        });
    };

    const confirmationRows = (): ConfirmationRow<any>[] => [
        new TextConfirmationRow(t("Name"), checklistDetailsResponseData!.name),
        new TextConfirmationRow(
            t("Description", { keyPrefix: Common }),
            checklistDetailsResponseData!.description
        ),
        new TextConfirmationRow(
            t("ChecklistType"),
            ChecklistType[checklistDetailsResponseData!.checklistType]
        ),
        new TextConfirmationRow(
            t("CreatedByUser", { keyPrefix: Users }),
            checklistDetailsResponseData!.createdByUserFullName || "-"
        ),
        new TextConfirmationRow(
            t("CreatedByUserEmail", { keyPrefix: Users }),
            checklistDetailsResponseData!.createdByUserName || "-"
        ),
        new TextConfirmationRow(
            t("DateCreated", { keyPrefix: Common }),
            checklistDetailsResponseData!.dateCreatedLocal
        ),
        new TextConfirmationRow(
            t("LastModifiedBy", { keyPrefix: Common }),
            checklistDetailsResponseData!.modifiedByUserFullName || "-"
        ),
        new TextConfirmationRow(
            t("ModifiedByUserEmail", { keyPrefix: Users }),
            checklistDetailsResponseData!.modifiedByUserName || "-"
        ),
        new TextConfirmationRow(
            t("DateLastModified", { keyPrefix: Common }),
            checklistDetailsResponseData!.dateModifiedLocal
        ),
        new TextConfirmationRow(
            t("LastReviewedDateTime", { keyPrefix: Common }),
            checklistDetailsResponseData!.lastReviewedDateTimeLocal || "-"
        ),
        new TextConfirmationRow(
            t("LastReviewedByUserName", { keyPrefix: Common }),
            checklistDetailsResponseData!.lastReviewedByUserName || "-"
        ),
        new CheckboxConfirmationRow(
            t("IsTrainingDisplay", { keyPrefix: Common }),
            checklistDetailsResponseData!.isTraining
        ),
    ];

    const renderChecklistDetails = (): JSX.Element => {
        return (
            <>
                <ContentContainer>
                    {checklistDetailsResponseData!.isTraining && (
                        <SbRibbon size={"large"} label={t("Training", { keyPrefix: Common })} />
                    )}

                    {CreateLabelToDetailRows(confirmationRows(), 2, 10)}

                    <EndAlignedDiv>{buildLinks()}</EndAlignedDiv>
                </ContentContainer>
            </>
        );
    };

    const navigateToViewQuestionSetDetails = (questionSetId: number): void =>
        navigate(`${getPath(AccordionTitles.QuestionSets)}/${questionSetId}`);

    const renderAssociatedQuestionSets = (): JSX.Element => (
        <SbAccordion title={t("AssociatedQuestionSets")}>
            <EndAlignedDiv>
                <CreateLink
                    label={t("AssociateQuestionSet")}
                    navigateTo={`${getPath(
                        AccordionTitles.Checklists
                    )}/${checklistId}/associate-question-sets`}
                />
            </EndAlignedDiv>
            <DataTable
                noResultsMessage="No question sets found"
                keyPrefix={ChecklistQuestions}
                columns={questionSetColumnNames}
                rows={associatedQuestionSetsResponseData!.rows}
                deleteItem={handleDissociateQuestionSet}
                linkItem={navigateToViewQuestionSetDetails}
                totalItems={associatedQuestionSetsResponseData!.recordCount}
                paginationDto={questionSetsPaginationDto}
                setPaginationDto={setQuestionSetsPaginationDto}
            />
        </SbAccordion>
    );

    const navigateToViewChecklistAssignmentDetails = (
        metadata: AssociatedChecklistNodeDto
    ): void => {
        const params = [
            createNavigateSearchParameter("nodeId", metadata.nodeId.toString()),
            createNavigateSearchParameter("checklistId", checklistId.toString()),
        ];

        navigateSearch(
            `${getPath(AccordionTitles.Checklists)}/${
                metadata.checklistAssignmentId
            }/checklist-assignment-details`,
            params
        );
    };

    const navigateToViewNodeDetails = (metadata: AssociatedChecklistNodeDto): void =>
        navigate(`${getPath(AccordionTitles.VisualTree)}/${metadata.nodeId}`);

    const renderAssociatedNodes = (): JSX.Element => (
        <SbAccordion title={t("AssignedNodes", { keyPrefix: Nodes })}>
            <SectionVerticalSpace />
            <ComponentPanel>
                <ChecklistAssignedNodesFilter
                    checklistAssignmentId={searchParams.checklistAssignmentId}
                    checklistAssignmentIdFilterValue={updateChecklistAssingmentIdValue}
                    nodeSearchText={searchParams.nodeSearchText}
                    nodeFilterValue={updateNodeValue}
                    search={search}
                    resetFilter={resetFilter}
                />
            </ComponentPanel>
            <SectionVerticalSpace />
            <EndAlignedDiv>
                <CreateLink
                    label={t("AssignToNode", { keyPrefix: Nodes })}
                    navigateTo={`${getPath(
                        AccordionTitles.Checklists
                    )}/${checklistId}/associate-node`}
                />
            </EndAlignedDiv>
            <DataTable
                noResultsMessage="No nodes found"
                keyPrefix={ChecklistQuestions}
                columns={associatedNodeColumnNames}
                rows={associatedNodesResponseData!.rows}
                viewItem={navigateToViewChecklistAssignmentDetails}
                deleteItem={handleDissociateNode}
                linkItem={navigateToViewNodeDetails}
                totalItems={associatedNodesResponseData!.recordCount}
                paginationDto={nodesPaginationDto}
                setPaginationDto={setNodesPaginationDto}
            />
        </SbAccordion>
    );

    const renderAssociatedReferentialLinks = (): JSX.Element => (
        <SbAccordion title={t("ReferentialLinks")}>
            <EndAlignedDiv>
                <CreateLink
                    label={t("AssociateReferentialLink")}
                    navigateTo={`${getPath(
                        AccordionTitles.Checklists
                    )}/${checklistId}/associate-referential-links`}
                />
            </EndAlignedDiv>
            <DataTable
                noResultsMessage={`${t("NoReferentialLinksFound")}`}
                keyPrefix={ChecklistQuestions}
                columns={associatedReferentialLinkColumnNames}
                rows={associatedReferentialLinksResponseData!.rows}
                deleteItem={handleDissociateReferentialLink}
                totalItems={associatedReferentialLinksResponseData!.recordCount}
                paginationDto={referentialLinksPaginationDto}
                setPaginationDto={setReferentialLinksPaginationDto}
            />
        </SbAccordion>
    );

    const navigateToViewTaxonomyNodeDetails = (nodeId: number): void =>
        navigate(`${getPath(AccordionTitles.VisualTree)}/${nodeId}`);

    const renderAssociatedTaxonomyNodes = (): JSX.Element => (
        <SbAccordion title={t("Taxonomies", { keyPrefix: Common })}>
            <EndAlignedDiv>
                <CreateLink
                    label={t("AssignTaxonomyNode")}
                    navigateTo={`${getPath(
                        AccordionTitles.Checklists
                    )}/${checklistId}/associate-taxonomy-node`}
                />
            </EndAlignedDiv>
            <DataTable
                keyPrefix={ChecklistQuestions}
                noResultsMessage="No taxonomy nodes found"
                columns={nodesColumnNames}
                rows={associatedTaxonomyNodesResponseData!.rows}
                deleteItem={handleDissociateTaxonomyNode}
                linkItem={navigateToViewTaxonomyNodeDetails}
                totalItems={associatedTaxonomyNodesResponseData!.recordCount}
                paginationDto={taxonomyNodesPaginationDto}
                setPaginationDto={setTaxonomyNodesPaginationDto}
            />
        </SbAccordion>
    );

    return (
        <>
            {areQueriesSuccessful(checklistDetails) && (
                <>
                    <PageHeading>{t("ChecklistDetailsTitle")}</PageHeading>
                    <SectionVerticalSpace />

                    {renderChecklistDetails()}
                    <SectionVerticalSpace />

                    {renderAssociatedQuestionSets()}
                    <SectionVerticalSpace />

                    {renderAssociatedNodes()}
                    <SectionVerticalSpace />

                    {renderAssociatedReferentialLinks()}
                    <SectionVerticalSpace />

                    {renderAssociatedTaxonomyNodes()}
                </>
            )}
        </>
    );
};

export default ViewChecklistContainer;
