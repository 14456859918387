import {
    buildFilterAssociatedRiskParameters,
    FilterAssociatedRiskRequest,
} from "../../../domain/requests/associated-risks/filter-associated-risk-request";
import {
    buildHierarchySearchRequestSearchParameters,
    HierarchySearchRequest,
} from "../../../domain/requests/common/hierachy-search-request";
import {
    AnswerTypeSearchRequest,
    AssuranceReviewSearchRequest,
    buildAnswerTypeSearchRequestSearchParameters,
    buildAssuranceReviewSearchRequestSearchParameters,
    buildSearchRequestSearchParameters,
    SearchRequest,
} from "../../../domain/requests/common/search-request";
import AnswerTypeResponse from "../../../domain/responses/answer-types/answer-type-response";
import AssociatedRiskResponse from "../../../domain/responses/associated-risks/associated-risk-response";
import { Response } from "../../../domain/responses/common/response-response";
import { HierarchyTypeResponse } from "../../../domain/responses/hierarchy/hierarchy-type-response";
import { NodeSubTreeUpwardResponse } from "../../../domain/responses/hierarchy/node-sub-tree-upward-response";
import { NodeTypeValueResponse } from "../../../domain/responses/hierarchy/node-type-value-response";
import { QuestionCategoryResponse } from "../../../domain/responses/question-category/question-category-response";
import { AssuranceReviewResponse } from "../../../domain/responses/review-reports/assurance-review-response";
import client from "../../http-client";

const route = "lookup";

export const lookupHierarchyTypes = async (
    prefixUrl: string
): Promise<Response<HierarchyTypeResponse[]>> =>
    await client
        .get(`${route}/hierarchy-types`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const lookupHierarchyNodesPath = async (
    prefixUrl: string,
    request: HierarchySearchRequest
): Promise<Response<NodeSubTreeUpwardResponse[]>> => {
    const searchParams = buildHierarchySearchRequestSearchParameters(request);

    return await client
        .get(`${route}/filter-hierarchy-nodes-path`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const lookupQuestionCategories = async (
    prefixUrl: string,
    request: SearchRequest
): Promise<Response<QuestionCategoryResponse[]>> => {
    const searchParams = buildSearchRequestSearchParameters(request);

    return await client
        .get(`${route}/question-categories`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const lookupAnswerTypes = async (
    prefixUrl: string,
    request: AnswerTypeSearchRequest
): Promise<Response<AnswerTypeResponse[]>> => {
    const searchParams = buildAnswerTypeSearchRequestSearchParameters(request);

    return await client
        .get(`${route}/answer-types`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const lookupCountryNodeTypeValues = async (
    prefixUrl: string,
    request: SearchRequest
): Promise<Response<NodeTypeValueResponse[]>> => {
    const searchParams = buildSearchRequestSearchParameters(request);

    return await client
        .get(`${route}/country-node-type-values`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const lookupAssociatedRisksByLevel = async (
    prefixUrl: string,
    request: FilterAssociatedRiskRequest
): Promise<Response<AssociatedRiskResponse[]>> => {
    const searchParams = buildFilterAssociatedRiskParameters(request);
    return await client
        .get(`${route}/associated-risks/filter-by-level`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const lookupMaxAgreedActions = async (prefixUrl: string): Promise<Response<number>> => {
    return await client
        .get(`${route}/max-agreed-actions`, {
            prefixUrl: prefixUrl,
        })
        .json();
};

export const lookupMaxAssociatedRisks = async (prefixUrl: string): Promise<Response<number>> => {
    return await client
        .get(`${route}/max-associated-risks`, {
            prefixUrl: prefixUrl,
        })
        .json();
};

export const lookupMaxReferentialLinkValuesCharacters = async (
    prefixUrl: string
): Promise<Response<number>> => {
    return await client
        .get(`${route}/max-referential-link-values-characters`, {
            prefixUrl: prefixUrl,
        })
        .json();
};

export const lookupAssuranceReviews = async (
    prefixUrl: string,
    request: AssuranceReviewSearchRequest
): Promise<Response<AssuranceReviewResponse[]>> => {
    const searchParams = buildAssuranceReviewSearchRequestSearchParameters(request);
    return await client
        .get(`${route}/assurance-reviews`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};
