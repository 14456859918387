import {
    buildFilterSearchParameters,
    FilterReviewReportSetFileRequest,
} from "../../../domain/requests/review-reports/filter-review-report-set-file-request";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import ReviewReportSetFileDownloadResponse from "../../../domain/responses/review-reports/review-report-set-file-download-response";
import ReviewReportSetFileResponse from "../../../domain/responses/review-reports/review-report-set-file-response";
import client from "../../http-client";

const route = "reviewreportfiles";

export const downloadReviewReportSetFile = async (
    prefixUrl: string,
    reviewReportSetFileId: number
): Promise<Response<ReviewReportSetFileDownloadResponse>> =>
    await client
        .get(`${route}/download-file/${reviewReportSetFileId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const filterReviewReportSetFiles = async (
    prefixUrl: string,
    request: FilterReviewReportSetFileRequest
): Promise<Response<PaginationResponse<ReviewReportSetFileResponse>>> => {
    const searchParams = buildFilterSearchParameters(request);

    return await client
        .get(`${route}/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};
