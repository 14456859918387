import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ChecklistQuestions, Common } from "../../../core/constants/translation-namespace";
import {
    ContentContainer,
    EndAlignedDiv,
    SectionContainer,
} from "../../../core/theme/global-styles";
import { ResetButton, SearchButton } from "../../atoms/SbButton";
import { FilterInput } from "../../molecules/FilterInput";

export const QuestionSetsFilter: React.FC<{
    questionSetName: string | null;
    description: string | null;
    questionText: string | null;
    processName: string | null;
    questionSetNameFilterValue: (questionSetName: string) => void;
    descriptionFilterValue: (description: string) => void;
    questionTextFilterValue: (questionText: string) => void;
    processNameFilterValue: (processName: string) => void;
    search: () => void;
    resetFilter: () => void;
}> = ({
    questionSetName,
    questionSetNameFilterValue,
    description,
    descriptionFilterValue,
    questionText,
    questionTextFilterValue,
    processName,
    processNameFilterValue,
    search,
    resetFilter,
}) => {
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });
    return (
        <ContentContainer>
            <SectionContainer>
                <Row>
                    <Col>
                        <FilterInput
                            title={t("QuestionSetName")}
                            name={"questionSetName"}
                            type={"text"}
                            onChangeEventHandler={questionSetNameFilterValue}
                            value={questionSetName ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("Description", { keyPrefix: Common })}
                            name={"description"}
                            type={"text"}
                            onChangeEventHandler={descriptionFilterValue}
                            value={description ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("QuestionText")}
                            name={"questionText"}
                            type={"text"}
                            onChangeEventHandler={questionTextFilterValue}
                            value={questionText ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("ProcessName")}
                            name={"processName"}
                            type={"text"}
                            onChangeEventHandler={processNameFilterValue}
                            value={processName ?? ""}
                        />
                    </Col>
                </Row>
            </SectionContainer>
            <EndAlignedDiv>
                <SearchButton onClick={search} />
                <ResetButton onClick={resetFilter} />
            </EndAlignedDiv>
        </ContentContainer>
    );
};
