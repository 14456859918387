import { useMutation, UseMutationResult, useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { getNullableFormField } from "../../../core/utilities/formDataHelper";
import { editNode, getNodeDetails } from "../../../data/services/hierarchy/nodes-service";
import { filterNonWorkingDaySets } from "../../../data/services/non-working-days/non-working-day-set-service";
import { createNodeDetailsDto, NodeDetailsDto } from "../../dtos/hierarchy/node-details-dto";
import { NodeDto } from "../../dtos/hierarchy/node-dto";
import {
    NonWorkingDaySetDto,
    toNonWorkingDaySetDtos,
} from "../../dtos/non-working-days/non-working-day-set-dto";
import { CreateNodeTypeValueRequest } from "../../requests/hierarchy/create-node-type-value-request";
import { createFilterNonWorkingDaySetRequest } from "../../requests/non-working-days/filter-non-working-day-sets-request";
import { Response } from "../../responses/common/response-response";

export const useGetEditNodeDetails = (
    nodeId: number
): [
    UseQueryResult<NodeDetailsDto, HTTPError>,
    UseQueryResult<NonWorkingDaySetDto[], HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getNodeDetails", nodeId],
                queryFn: () => getNodeDetails(url.baseUrl, nodeId),
                select: createNodeDetailsDto,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["filterNonWorkingDaySets", 1, 1000, null],
                queryFn: () =>
                    filterNonWorkingDaySets(
                        url.baseUrl,
                        createFilterNonWorkingDaySetRequest(null, null, null, 1, 1000, null, false)
                    ),
                select: toNonWorkingDaySetDtos,
            },
        ],
    }) as [
        UseQueryResult<NodeDetailsDto, HTTPError>,
        UseQueryResult<NonWorkingDaySetDto[], HTTPError>,
    ];
};

export interface EditParameters {
    nodeDetails: NodeDto;
    formData: FormData;
    isCreatingNodeTypeValue: boolean;
    nonWorkingDaySets: NonWorkingDaySetDto[];
    selectedNodeTypeValueId: number | null;
}

export const useEditNode = (): UseMutationResult<Response<boolean>, HTTPError, EditParameters> => {
    const url = useUrl();

    return useMutation((mutationKey: EditParameters) => {
        const {
            formData,
            nodeDetails,
            isCreatingNodeTypeValue,
            nonWorkingDaySets,
            selectedNodeTypeValueId,
        } = mutationKey;

        let nodeTypeValueId: number | null = null;
        let createNodeTypeValueRequest: CreateNodeTypeValueRequest | null = null;

        const selectedNonWorkingDaySetValue = getNullableFormField(
            formData.get("nonWorkingDaySet") as string
        );

        if (isCreatingNodeTypeValue) {
            createNodeTypeValueRequest = {
                nodeTypeId: nodeDetails.nodeType.nodeTypeId,
                value: formData.get("nodeTypeValueValue") as string,
                code: getNullableFormField(formData.get("nodeTypeValueCode") as string),
                description: getNullableFormField(
                    formData.get("nodeTypeValueDescription") as string
                ),
            };
        } else {
            nodeTypeValueId = selectedNodeTypeValueId;
        }

        const nonWorkingDaySetId = getNonWorkingDaySetId(
            selectedNonWorkingDaySetValue,
            nonWorkingDaySets
        );

        const request = {
            nodeId: nodeDetails.nodeId,
            nodeTypeValueId: nodeTypeValueId,
            createNodeTypeValueRequest: createNodeTypeValueRequest,
            nonWorkingDaySetId: nonWorkingDaySetId,
        };

        return editNode(url.baseUrl, request);
    });
};

const getNonWorkingDaySetId = (
    nonWorkingDaySetName: string | null,
    nonWorkingDaySets: NonWorkingDaySetDto[]
): number | null => {
    if (!nonWorkingDaySetName) {
        return null;
    }

    return (
        nonWorkingDaySets.find((x) => x.name == nonWorkingDaySetName)?.nonWorkingDaySetId ?? null
    );
};
