import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { isMutationLoading } from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { ImageLibraryViewResponse } from "../../../domain/responses/image-library/image-library-view-response";
import { useEditWizardCard } from "../../../domain/viewmodels/wizards/edit-wizard-card-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import SbFormTextAreaGroup from "../../molecules/input/SbFormTextAreaGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

interface ConfirmationDetailsProps {
    wizardId: number;
    heading: string;
    description: string;
    initialFileDetailsId: number;
    fileName: string;
    imageLibraryImages: ImageLibraryViewResponse[];
}

const EditWizardCardContainer: React.FC = () => {
    const wizardCardId = Number(useParams().wizardCardId);

    const menu = useMenu();
    const navigate = useNavigate();
    const location = useLocation();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: Common });

    const { wizardId, heading, description, initialFileDetailsId, fileName, imageLibraryImages } =
        location.state as ConfirmationDetailsProps;

    const [fileDetailsId, setFileDetailsId] = useState<number>(initialFileDetailsId);

    const editWizardCard = useEditWizardCard();

    useLoader(isMutationLoading(editWizardCard), EditWizardCardContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.WizardCards, AccordionTitles.Wizards);
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        editWizardCard.mutate(
            {
                wizardCardId: wizardCardId,
                fileDetailsId: fileDetailsId,
                formData: new FormData(event.currentTarget),
            },
            {
                onSuccess: async () => {
                    queryClient.invalidateQueries(["getWizardCards", wizardId]);
                    navigate(-1);
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    const onImageLibraryImageSelected = (option: SingleValue<ImageLibraryViewResponse>): void => {
        setFileDetailsId(option!.fileDetailsId);
    };

    return (
        <>
            <PageHeading>{t("EditWizardCard")}</PageHeading>
            <SectionVerticalSpace />
            <TextTitledPanel title="Edit Wizard Card">
                <Form onSubmit={handleSubmit}>
                    <SbFormTextFieldGroup
                        name="heading"
                        maxLength={100}
                        label="Heading"
                        type="text"
                        defaultValue={heading}
                        required
                    />

                    <SbFormTextAreaGroup
                        name="description"
                        label="Description"
                        defaultValue={description}
                        required
                        maxLength={1000}
                    />

                    <SbFormSelectFieldGroup
                        name={"images"}
                        label={"Images"}
                        placeholderText={t("PleaseSelect")!}
                        searchable
                        clearable={false}
                        items={imageLibraryImages}
                        itemDisplayText={(option: ImageLibraryViewResponse) => option.fileName}
                        onChange={onImageLibraryImageSelected}
                        defaultSelectedItem={
                            imageLibraryImages.filter(
                                (imageLibraryImage) => imageLibraryImage.fileName === fileName
                            )[0]
                        }
                    />

                    <EndAlignedDiv>
                        <SaveButton type="submit" />
                        <CancelButton onClick={() => navigate(-1)} />
                    </EndAlignedDiv>
                </Form>
            </TextTitledPanel>
        </>
    );
};

export default EditWizardCardContainer;
