import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { ChecklistAnswers, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { getEnumsForType } from "../../../core/utilities/enum-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isMutationLoading,
} from "../../../core/utilities/responseStateHelper";
import { createReactSelectDto, ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import { LanguageDto } from "../../../domain/dtos/language/language-dto";
import AnswerFlagMode from "../../../domain/enums/answer-types/answer-flag-mode";
import AnswerRenderMode from "../../../domain/enums/answer-types/answer-render-mode";
import { Response } from "../../../domain/responses/common/response-response";
import {
    createCreateParams,
    useCreatePredefinedAnswer,
    useGetAnswerTypeDetails,
    usePredefinedAnswerLookups,
} from "../../../domain/viewmodels/answer-types/create-predefined-answer-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import { translateText } from "../../helpers/translate";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import SbFormTextAreaGroup from "../../molecules/input/SbFormTextAreaGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const answerFlagModesArray = getEnumsForType(AnswerFlagMode).map((x) =>
    createReactSelectDto(x, AnswerFlagMode[x])
);

const CreatePredefinedAnswerContainer: React.FC = () => {
    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const answerTypeId = Number(useParams().answerTypeId);

    const languageResponse = usePredefinedAnswerLookups();
    const languageResponseData = languageResponse.data;

    const createPredefinedAnswer = useCreatePredefinedAnswer();
    const getAnswerTypeDetails = useGetAnswerTypeDetails(answerTypeId);

    const [flagModeId, setFlagModeId] = useState(0);

    const { t } = useTranslation("translation", { keyPrefix: ChecklistAnswers });

    useLoader(
        areQueriesLoading([languageResponse, getAnswerTypeDetails]) ||
            isMutationLoading(createPredefinedAnswer),
        CreatePredefinedAnswerContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.AnswerTypes);
    }, []);

    const onFlagModeSelected = (option: SingleValue<ReactSelectDto<AnswerFlagMode>>): void =>
        setFlagModeId(option!.value);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        createPredefinedAnswer.mutate(
            createCreateParams(
                answerTypeId,
                languageResponseData!.languageId,
                new FormData(event.currentTarget),
                flagModeId
            ),
            {
                onSuccess: async (_: Response<number | null>) => {
                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "messageKey",
                            "SuccessfullyAddedPredefinedAnswer"
                        ),
                    ];

                    navigateSearch(
                        `${getPath(AccordionTitles.AnswerTypes)}/${answerTypeId}`,
                        params
                    );
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    const languageSelectArray: LanguageDto[] = [languageResponseData!];

    return (
        <>
            <PageHeading>{t("PredefinedAnswer")}</PageHeading>
            <SectionVerticalSpace />

            {areQueriesSuccessful([languageResponse, getAnswerTypeDetails]) && (
                <TextTitledPanel title={t("AddPredefinedAnswer")}>
                    <Form onSubmit={handleSubmit}>
                        <SbFormSelectFieldGroup
                            name={"languageId"}
                            label={t("LanguageName")}
                            placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                            searchable={false}
                            clearable={false}
                            disabled
                            items={languageSelectArray}
                            itemDisplayText={(option: LanguageDto) => option.name} //TODO: Add translations for dynamic data
                            defaultSelectedItem={languageResponseData}
                        />

                        <SbFormTextFieldGroup
                            name="value"
                            maxLength={50}
                            label={t("Value", { keyPrefix: Common })}
                            type="text"
                            required
                        />

                        <SbFormSelectFieldGroup
                            name={"flagModeId"}
                            label={t("FlagMode")}
                            placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                            searchable={false}
                            clearable
                            required
                            items={answerFlagModesArray ?? []}
                            itemDisplayText={(option: ReactSelectDto<AnswerFlagMode>) =>
                                translateText(t, option.label, ChecklistAnswers)
                            }
                            onChange={onFlagModeSelected}
                        />

                        {getAnswerTypeDetails.data!.answerRenderMode ==
                            AnswerRenderMode.DropDown && (
                            <SbFormTextAreaGroup
                                name="tooltip"
                                label={t("Tooltip")}
                                maxLength={1000}
                            />
                        )}

                        <EndAlignedDiv>
                            <SaveButton type="submit" />
                            <CancelButton
                                onClick={() =>
                                    navigate(
                                        `${getPath(AccordionTitles.AnswerTypes)}/${answerTypeId}`
                                    )
                                }
                            />
                        </EndAlignedDiv>
                    </Form>
                </TextTitledPanel>
            )}
        </>
    );
};

export default CreatePredefinedAnswerContainer;
