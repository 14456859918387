import {
    buildFilterEmailQueueSearchParameters,
    FilterEmailQueueRequest,
} from "../../../domain/requests/email-queue/filter-email-queue-request";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import { EmailQueueDetailsResponse } from "../../../domain/responses/email-queue/email-queue-details-response";
import { EmailQueueResponse } from "../../../domain/responses/email-queue/email-queue-response";
import client from "../../http-client";

const route = "emailqueues";

export const filterEmailQueue = async (
    prefixUrl: string,
    request: FilterEmailQueueRequest
): Promise<Response<PaginationResponse<EmailQueueResponse>>> => {
    const searchParams = buildFilterEmailQueueSearchParameters(request);

    return await client
        .get(`${route}/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getEmailQueueDetails = async (
    prefixUrl: string,
    emailQueueId: number
): Promise<Response<EmailQueueDetailsResponse>> =>
    await client
        .get(`${route}/${emailQueueId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const resendEmail = async (
    prefixUrl: string,
    emailQueueId: number
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/resend/${emailQueueId}`, {
            prefixUrl: prefixUrl,
        })
        .json();
