export interface AssociateDissociateQuestionSetToChecklistRequest {
    checklistId: number;
    questionSetIds: number[];
}

export const createAssociateDissociateQuestionSetToChecklistRequest = (
    checklistId: number,
    questionSetIds: number[]
): AssociateDissociateQuestionSetToChecklistRequest => ({
    checklistId: checklistId,
    questionSetIds: questionSetIds,
});
