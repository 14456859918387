import NestedQuestionCaptureResponse from "../../responses/answer-capture/nested-question-capture-response";
import BaseQuestionCaptureDto from "./base-question-capture-dto";

export default class NestedQuestionCaptureDto extends BaseQuestionCaptureDto {
    parentQuestionSetInstanceAnswerId: number;
    parentQuestionId: number;
    renderInline: boolean;
    triggerWhenParentIsDesirable: boolean;

    public constructor(
        response: NestedQuestionCaptureResponse,
        nestedQuestionCaptureDtos: NestedQuestionCaptureDto[] | null
    ) {
        super(response, nestedQuestionCaptureDtos);

        const {
            parentQuestionSetInstanceAnswerId,
            parentQuestionId,
            renderInline,
            triggerWhenParentIsDesirable,
        } = response;

        this.parentQuestionSetInstanceAnswerId = parentQuestionSetInstanceAnswerId;
        this.parentQuestionId = parentQuestionId;
        this.renderInline = renderInline;
        this.triggerWhenParentIsDesirable = triggerWhenParentIsDesirable;
    }
}
