import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import {
    ActionItems,
    ActionItemTypes,
    Common,
} from "../../../core/constants/translation-namespace";
import {
    ContentContainer,
    EndAlignedDiv,
    SectionContainer,
} from "../../../core/theme/global-styles";
import { RaciRelationshipOptions } from "../../../core/utilities/ActionItemValues";
import { ActionItemTypeDto } from "../../../domain/dtos/action-items/action-item-type-dto";
import { ActionInstanceStatusDto } from "../../../domain/dtos/actions/action-instance-status-dto";
import { ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import RaciRelationship from "../../../domain/enums/action-items/RaciRelationship";
import { ResetButton, SearchButton } from "../../atoms/SbButton";
import { translateWithFallback } from "../../helpers/translate-with-common-fallback";
import DateTimeFilter from "../../molecules/DateTimeFilter";
import { FilterInput } from "../../molecules/FilterInput";
import { FilterSelect } from "../../molecules/FilterSelect";
import { SbAccordion } from "../../molecules/SbAccordion";

export const FinalizedActionItemsFilter: React.FC<{
    changeActionInstanceStatus: (item: SingleValue<ActionInstanceStatusDto>) => void;
    actionInstanceStatus: ActionInstanceStatusDto;
    actionInstanceStatuses: ActionInstanceStatusDto[];
    changeRaciRelationship: (item: SingleValue<ReactSelectDto<RaciRelationship>>) => void;
    raciRelationship: ReactSelectDto<RaciRelationship>;
    actionItemTypes: ActionItemTypeDto[];
    changeActionItemType: (option: SingleValue<ActionItemTypeDto>) => void;
    actionItemType: ActionItemTypeDto;
    actionItemSubTypes: ActionItemTypeDto[];
    changeActionItemSubType: (option: SingleValue<ActionItemTypeDto>) => void;
    actionItemSubType: ActionItemTypeDto;
    changeActionItemName: (actionItemName: string) => void;
    actionItemName: string | null;
    changeDueDateFrom: (dueDateFrom: Date) => void;
    dueDateFrom: Date | null;
    changeDueDateTo: (dueDateTo: Date) => void;
    dueDateTo: Date | null;
    search: () => void;
    resetFilter: () => void;
    changeActionItemId: (actionItemId: string) => void;
    actionItemId: string | null;
}> = ({
    changeActionInstanceStatus,
    actionInstanceStatus,
    actionInstanceStatuses,
    changeRaciRelationship,
    raciRelationship,
    actionItemTypes,
    changeActionItemType,
    actionItemType,
    actionItemSubTypes,
    changeActionItemSubType,
    actionItemSubType,
    changeActionItemName,
    actionItemName,
    changeDueDateFrom,
    dueDateFrom,
    changeDueDateTo,
    dueDateTo,
    changeActionItemId,
    actionItemId,
    search,
    resetFilter,
}) => {
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });
    return (
        <SbAccordion title={t("Search", { keyPrefix: Common })} isCollapsedOnLoad>
            <ContentContainer>
                <SectionContainer>
                    <Row>
                        <Col>
                            <FilterSelect
                                name={"actionInstanceStatus"}
                                label={`${t("ActionInstanceStatus")}`}
                                placeholderText={""}
                                searchable
                                clearable={false}
                                items={actionInstanceStatuses}
                                itemDisplayText={(option: ActionInstanceStatusDto) =>
                                    translateWithFallback(t, option.name, ActionItems)
                                }
                                onChange={changeActionInstanceStatus}
                                value={actionInstanceStatus}
                            />
                        </Col>
                        <Col>
                            <FilterSelect
                                name={"raciRelationship"}
                                label={`${t("RaciRelationship")}`}
                                placeholderText={""}
                                searchable
                                clearable={false}
                                items={RaciRelationshipOptions}
                                itemDisplayText={(option: ReactSelectDto<RaciRelationship>) =>
                                    translateWithFallback(t, option.label, ActionItems)
                                }
                                onChange={changeRaciRelationship}
                                value={raciRelationship}
                            />
                        </Col>
                        <Col>
                            <FilterSelect
                                name={"actionItemTypes"}
                                label={`${t("ActionItemType", { keyPrefix: ActionItemTypes })}`}
                                placeholderText={""}
                                searchable
                                clearable={false}
                                items={actionItemTypes}
                                itemDisplayText={(option: ActionItemTypeDto) => option.value} //TODO: Add translations for dynamic data
                                onChange={changeActionItemType}
                                value={actionItemType}
                            />
                        </Col>
                        <Col>
                            <FilterSelect
                                name={"actionItemSubTypes"}
                                label={`${t("ActionItemSubType")}`}
                                placeholderText={""}
                                searchable
                                clearable={false}
                                items={actionItemSubTypes}
                                itemDisplayText={(option: ActionItemTypeDto) => option.value} //TODO: Add translations for dynamic data
                                onChange={changeActionItemSubType}
                                value={actionItemSubType}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FilterInput
                                title={t("ActionItemName")}
                                name={"actionItemName"}
                                type={"text"}
                                onChangeEventHandler={changeActionItemName}
                                value={actionItemName ?? ""}
                            />
                        </Col>
                        <Col>
                            <DateTimeFilter
                                label={t("DueDateFrom")}
                                name={"dueDateFrom"}
                                onInputChange={changeDueDateFrom}
                                value={dueDateFrom}
                            />
                        </Col>
                        <Col>
                            <DateTimeFilter
                                label={t("DueDateTo")}
                                name={"dueDateTo"}
                                onInputChange={changeDueDateTo}
                                value={dueDateTo}
                            />
                        </Col>
                        <Col>
                            <FilterInput
                                title={t("ActionItemId")}
                                name={"actionItemID"}
                                type={"text"}
                                onChangeEventHandler={changeActionItemId}
                                value={actionItemId ?? ""}
                            />
                        </Col>
                    </Row>
                </SectionContainer>
                <EndAlignedDiv>
                    <SearchButton onClick={search} />
                    <ResetButton onClick={resetFilter} />
                </EndAlignedDiv>
            </ContentContainer>
        </SbAccordion>
    );
};
