import ProcessHierarchyImportDto from "../../dtos/data-import/process-hierarchy-import-dto";
import HierarchyImportHeaderRequest from "./hierarchy-import-header-request";
import HierarchyImportRequest from "./hierarchy-import-request";

export default class ProcessHierarchyImportRequest {
    hierarchyTypeId: Number;
    hierarchyImportRequest: HierarchyImportRequest;
    headerMetadata: HierarchyImportHeaderRequest[];

    public constructor(processHierarchyImportDto: ProcessHierarchyImportDto) {
        this.hierarchyTypeId = processHierarchyImportDto.hierarchyTypeId;
        this.hierarchyImportRequest = processHierarchyImportDto.hierarchyImportRequest;
        this.headerMetadata = processHierarchyImportDto.headerMetadata;
    }
}
