import { ReactElement, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaEdit, FaSave } from "react-icons/fa";
import { SingleValue } from "react-select";
import { BusinessUnit, Country, ReportName } from "../../../core/constants/string";
import { ChecklistQuestions, Common, Reports } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { ToastVariant, useToast } from "../../../core/store/toast-context";
import {
    disabledSelectStyles,
    EndAlignedDiv,
    maxContentWidthSelectStyle,
    smallFontSelectStyle,
    StyledBorderedSection,
    StyledFormLabel,
} from "../../../core/theme/global-styles";
import { TableRow } from "../../../core/utilities/customTypes";
import { functionNodesColumns } from "../../../core/utilities/dataTableColumns";
import { convertUtcDateToLocalDate } from "../../../core/utilities/date-helper";
import { getEnumsForType } from "../../../core/utilities/enum-helper";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import IntervalOverridesDto from "../../../domain/dtos/checklists/interval-overrides-dto";
import NodeChecklistDefaultValuesDto from "../../../domain/dtos/checklists/node-checklist-default-values-dto";
import { createReactSelectDto, ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import { BaseNodeDetailsDto } from "../../../domain/dtos/hierarchy/base-node-details-dto";
import ARMonitoringReferentialLinkWithTypeDto from "../../../domain/dtos/referential-links/ar-monitoring-referential-link-with-type-dto";
import BaseReferentialLinkDto from "../../../domain/dtos/referential-links/base-referential-link-dto";
import NodeReferentialLinksDto from "../../../domain/dtos/referential-links/node-referential-links-dto";
import { PeriodType } from "../../../domain/enums/PeriodType";
import {
    buildIntervalOverridesDto,
    useLookupMaxReferentialLinkValuesCharacters,
    useSaveNodeChecklistDefaultValues,
} from "../../../domain/viewmodels/trigger-adhocs/trigger-adhocs-viewmodel";
import { SbSelect } from "../../atoms/input/SbSelect";
import { InfoAlert, SbAlert } from "../../atoms/SbAlert";
import { CancelButton, SbButton } from "../../atoms/SbButton";
import SbLabelText from "../../atoms/SbLabelText";
import SbFormCheckFieldGroup from "../../molecules/input/SbFormCheckFieldGroup";
import SbFormDateTimeFieldGroup from "../../molecules/input/SbFormDateTimeFieldGroup";
import SbFormNumberBoxGroup from "../../molecules/input/SbFormNumberBoxGroup";
import { SbFormCreateOnNoResultsSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";
import { DataTable } from "../DataTable";

export interface FunctionNodesSelectorTitleProps {
    panelTitle: string;
    infoAlertMessage: string;
    selectFieldLabel: string;
}

interface FunctionNodesSelectorProps extends FunctionNodesSelectorTitleProps {
    nodes: BaseNodeDetailsDto[];
    onNodeSelected: (nodeId: number) => void;
    selectedNodeId: number | null;
    arMonitoringCheckValues: {
        name: string;
        label: string;
        onChangeHandler: (_: string, checked: boolean) => void;
    }[];
    arMonitoring: boolean;
    arMonitoringReferentialLinkWithTypeDto: ARMonitoringReferentialLinkWithTypeDto[] | undefined;
    nodeChecklistDefaultValuesDisabled: boolean;
    setNodeChecklistDefaultValuesDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    nodeReferentialLinksResponseData: NodeReferentialLinksDto[] | undefined;
    nodeAdhocIntervalOverrideResponseData: IntervalOverridesDto | undefined;
}

const FunctionNodesSelector: React.FC<FunctionNodesSelectorProps> = ({
    panelTitle,
    infoAlertMessage,
    selectFieldLabel,
    nodes,
    onNodeSelected,
    selectedNodeId,
    arMonitoringCheckValues,
    arMonitoring,
    arMonitoringReferentialLinkWithTypeDto,
    nodeChecklistDefaultValuesDisabled,
    setNodeChecklistDefaultValuesDisabled,
    nodeReferentialLinksResponseData,
    nodeAdhocIntervalOverrideResponseData,
}) => {
    const [selectedNodePath, setSelectedNodePath] = useState<string | null>(null);
    const [searchText, setSearchText] = useState<string | null>(null);
    const openBeforeDuePeriodType = useRef<PeriodType | null>(null);

    const [countryValue, setCountryValue] = useState<string>("");
    const [businessUnitValue, setBusinessUnitValue] = useState<string>("");
    const [reportNameValue, setReportNameValue] = useState<string>("");

    const maxReferentialLinkValuesCharacters = useLookupMaxReferentialLinkValuesCharacters();
    const saveNodeChecklistDefaultValues = useSaveNodeChecklistDefaultValues();

    const maxReferentialLinkValuesCharactersData = maxReferentialLinkValuesCharacters.data;

    const toast = useToast();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation");

    const numberOfNodeLevelsToDisplay = 5;

    useLoader(
        isQueryLoading(maxReferentialLinkValuesCharacters) ||
            isMutationLoading(saveNodeChecklistDefaultValues),
        FunctionNodesSelector
    );

    const selectNode = (nodeId: number, path: string): void => {
        setSelectedNodePath(path);
        onNodeSelected(nodeId);
    };

    const toPathTableRow = (): TableRow<null>[] => {
        return [
            {
                columns: [
                    {
                        value: selectedNodePath!,
                        type: DataTableColumnTypes.Text,
                    },
                ],
            },
        ];
    };

    const getShortenedPath = (fullPath: string): string => {
        const splitPath = fullPath.split("|");

        if (splitPath.length <= numberOfNodeLevelsToDisplay) {
            return fullPath;
        }

        const lastFiveNodes = splitPath.slice(
            Math.max(splitPath.length - numberOfNodeLevelsToDisplay, 1)
        );

        return lastFiveNodes.join(" | ");
    };

    const referentialLinkTypeLinkMap = useRef<Map<number, number | null>>(
        new Map(
            arMonitoringReferentialLinkWithTypeDto &&
                arMonitoringReferentialLinkWithTypeDto.map((x) => [
                    x.referentialLinkTypeDto.referentialLinkTypeId,
                    null,
                ])
        )
    );

    const createReferentialLinkTypeLinkMap = useRef<Map<number, string | null>>(
        new Map(
            arMonitoringReferentialLinkWithTypeDto &&
                arMonitoringReferentialLinkWithTypeDto.map((x) => [
                    x.referentialLinkTypeDto.referentialLinkTypeId,
                    null,
                ])
        )
    );

    const onReferentialLinkSelected = (
        option: SingleValue<BaseReferentialLinkDto>,
        referentialLinkTypeId: number
    ): void => {
        if (option!.referentialLinkId === 0) {
            createReferentialLinkTypeLinkMap.current.set(referentialLinkTypeId, option!.value);
            referentialLinkTypeLinkMap.current.set(referentialLinkTypeId, null);
        } else {
            createReferentialLinkTypeLinkMap.current.set(referentialLinkTypeId, null);
            referentialLinkTypeLinkMap.current.set(
                referentialLinkTypeId,
                option!.referentialLinkId
            );
        }
    };

    const createNewReferentialLinkSelectValue = (searchText: string): BaseReferentialLinkDto =>
        new BaseReferentialLinkDto(0, searchText);

    const onSaveNodeChecklistDefaultValues = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);

        const intervalOverridesDto = buildIntervalOverridesDto(
            formData,
            openBeforeDuePeriodType.current,
            true
        );

        const referentialLinks = Array.from(referentialLinkTypeLinkMap.current.values());

        const createdReferentialLinks = createReferentialLinkTypeLinkMap.current;

        const dto = new NodeChecklistDefaultValuesDto(
            selectedNodeId!,
            intervalOverridesDto,
            referentialLinks.filter((x) => x !== null && x !== undefined),
            createdReferentialLinks
        );

        saveNodeChecklistDefaultValues.mutate(dto, {
            onSuccess: async () => {
                toast.addToast({
                    messages: [
                        t("SuccessfullyUpdatedNodeChecklistDefaultValues", { keyPrefix: Reports }),
                    ],
                    variant: ToastVariant.success,
                });

                queryClient.refetchQueries(["getNodeReferentialLinks", selectedNodeId]);
                queryClient.refetchQueries(["getNodeAdhocIntervalOverride", selectedNodeId]);

                setNodeChecklistDefaultValuesDisabled(true);
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    const getDefaultReferentialLinkValue = (
        referentialLinkTypeName: string
    ): BaseReferentialLinkDto | null | undefined => {
        if (nodeReferentialLinksResponseData) {
            let filteredNodeReferentialLinks = nodeReferentialLinksResponseData.filter(
                (x) => x.referentialLinkTypeName === referentialLinkTypeName
            );

            if (filteredNodeReferentialLinks) {
                onReferentialLinkSelected(
                    new BaseReferentialLinkDto(
                        filteredNodeReferentialLinks[0]?.referentialLinkId,
                        filteredNodeReferentialLinks[0]?.referentialLinkValue
                    ),
                    filteredNodeReferentialLinks[0]?.referentialLinkTypeId
                );

                let referentialLinkValue = filteredNodeReferentialLinks[0]?.referentialLinkValue;

                if (referentialLinkTypeName == Country && countryValue !== referentialLinkValue) {
                    setCountryValue(referentialLinkValue);
                }

                if (
                    referentialLinkTypeName == BusinessUnit &&
                    businessUnitValue !== referentialLinkValue
                ) {
                    setBusinessUnitValue(referentialLinkValue);
                }

                if (
                    referentialLinkTypeName == ReportName &&
                    reportNameValue !== referentialLinkValue
                ) {
                    setReportNameValue(referentialLinkValue);
                }

                return {
                    referentialLinkId: filteredNodeReferentialLinks[0]?.referentialLinkId,
                    value: referentialLinkValue,
                };
            }
        } else {
            return null;
        }
    };

    const getDefaultPeriodTypeValue = (): ReactSelectDto<PeriodType> | null => {
        if (nodeAdhocIntervalOverrideResponseData) {
            openBeforeDuePeriodType.current =
                nodeAdhocIntervalOverrideResponseData!.openBeforeDueIntervalDto.periodType;

            return createReactSelectDto(
                nodeAdhocIntervalOverrideResponseData!.openBeforeDueIntervalDto.periodType,
                PeriodType[
                    nodeAdhocIntervalOverrideResponseData!.openBeforeDueIntervalDto.periodType
                ]
            );
        } else {
            return null;
        }
    };

    const getDefaultOpenBeforeValue = (): string => {
        if (nodeAdhocIntervalOverrideResponseData?.openBeforeDueIntervalDto?.value?.toString()) {
            return nodeAdhocIntervalOverrideResponseData.openBeforeDueIntervalDto.value.toString();
        } else {
            return "";
        }
    };

    const getDefaultDueDateValue = (): Date | null => {
        if (nodeAdhocIntervalOverrideResponseData?.dueDateTimeUtc) {
            return convertUtcDateToLocalDate(nodeAdhocIntervalOverrideResponseData.dueDateTimeUtc);
        } else {
            return null;
        }
    };

    const referentialLinkValuesCount =
        countryValue.length + businessUnitValue.length + reportNameValue.length;

    const renderARMonitoringReferentialLinks = (): ReactElement<HTMLDivElement> => (
        <StyledBorderedSection>
            <InfoAlert message={t("SelectARMonitoringRefLinks", { keyPrefix: Reports })} />

            {arMonitoringReferentialLinkWithTypeDto &&
                arMonitoringReferentialLinkWithTypeDto.map((x, index) => (
                    <SbFormCreateOnNoResultsSelectFieldGroup
                        styles={
                            nodeChecklistDefaultValuesDisabled ? disabledSelectStyles : undefined
                        }
                        key={`referentialLinkType_${x.referentialLinkTypeDto.referentialLinkTypeId} ${selectedNodePath} ${nodeChecklistDefaultValuesDisabled}`}
                        name={`referentialLinkType_${x.referentialLinkTypeDto.referentialLinkTypeId}`}
                        label={x.referentialLinkTypeDto.name}
                        items={x.referentialLinkDtos}
                        placeholderText={"Please select value"}
                        onChange={(option: SingleValue<BaseReferentialLinkDto>) =>
                            onReferentialLinkSelected(
                                option,
                                x.referentialLinkTypeDto.referentialLinkTypeId
                            )
                        }
                        createNewValue={createNewReferentialLinkSelectValue}
                        searchable
                        clearable={false}
                        itemDisplayText={(option: BaseReferentialLinkDto) => option.value}
                        required={true}
                        defaultSelectedItem={
                            !nodeChecklistDefaultValuesDisabled
                                ? getDefaultReferentialLinkValue(x.referentialLinkTypeDto.name)
                                : undefined
                        }
                        value={
                            nodeChecklistDefaultValuesDisabled
                                ? getDefaultReferentialLinkValue(x.referentialLinkTypeDto.name)
                                : undefined
                        }
                    />
                ))}

            {referentialLinkValuesCount > maxReferentialLinkValuesCharactersData! && (
                <SbAlert
                    text={`${t("ReferentialLinkValuesLimit", { keyPrefix: Reports })} ${maxReferentialLinkValuesCharactersData} ${t("Characters", { keyPrefix: Reports })}. ${t("CurrentCount", { keyPrefix: Reports })}: ${referentialLinkValuesCount} ${t("Characters", { keyPrefix: Reports })}`}
                    variant="warning"
                />
            )}
        </StyledBorderedSection>
    );

    const renderOverRides = (): ReactElement<HTMLDivElement> => (
        <StyledBorderedSection>
            <SbFormDateTimeFieldGroup
                key={`due date ${selectedNodePath} ${nodeChecklistDefaultValuesDisabled}`}
                name="dueDate"
                label={t("DueDate", { keyPrefix: Common })}
                required
                disabled={nodeChecklistDefaultValuesDisabled}
                defaultValue={
                    !nodeChecklistDefaultValuesDisabled ? getDefaultDueDateValue() : undefined
                }
                value={nodeChecklistDefaultValuesDisabled ? getDefaultDueDateValue() : undefined}
            />

            <SbFormNumberBoxGroup
                key={`open before due value ${selectedNodePath} ${nodeChecklistDefaultValuesDisabled}`}
                name="openBeforeDueValue"
                label={t("OpenBefore", { keyPrefix: Common })}
                required
                disabledStyling={nodeChecklistDefaultValuesDisabled}
                defaultValue={
                    !nodeChecklistDefaultValuesDisabled ? getDefaultOpenBeforeValue() : undefined
                }
                value={nodeChecklistDefaultValuesDisabled ? getDefaultOpenBeforeValue() : undefined}
            >
                <SbSelect
                    styles={
                        nodeChecklistDefaultValuesDisabled
                            ? disabledSelectStyles
                            : maxContentWidthSelectStyle
                    }
                    name={"openBeforeDuePeriodType"}
                    items={getEnumsForType(PeriodType).map((x) =>
                        createReactSelectDto(x, PeriodType[x])
                    )}
                    placeholderText={`${t("PleaseSelectPeriodType", {
                        keyPrefix: ChecklistQuestions,
                    })}`}
                    searchable
                    clearable={false}
                    itemLabel={(option) => t(option.label, { keyPrefix: Common })}
                    onChange={(option: SingleValue<ReactSelectDto<PeriodType>>) => {
                        openBeforeDuePeriodType.current = option!.value;
                    }}
                    defaultSelectedItem={
                        !nodeChecklistDefaultValuesDisabled
                            ? getDefaultPeriodTypeValue()
                            : undefined
                    }
                    value={
                        nodeChecklistDefaultValuesDisabled ? getDefaultPeriodTypeValue() : undefined
                    }
                    required
                />
            </SbFormNumberBoxGroup>
        </StyledBorderedSection>
    );

    return (
        <TextTitledPanel title={panelTitle}>
            <InfoAlert message={infoAlertMessage} />

            <Form.Group as={Row} className="mb-3">
                <StyledFormLabel column sm={2}>
                    <SbLabelText label={selectFieldLabel} />
                </StyledFormLabel>
                <Col sm={5}>
                    <SbSelect
                        styles={smallFontSelectStyle}
                        name={"nodeSelect"}
                        placeholderText={t("PleaseSelect", { keyPrefix: Common })}
                        searchable
                        clearable={false}
                        items={nodes}
                        onChange={(option: SingleValue<BaseNodeDetailsDto>) =>
                            selectNode(option!.nodeId, option!.path)
                        }
                        itemLabel={(option: SingleValue<BaseNodeDetailsDto>) =>
                            getShortenedPath(option!.path)
                        }
                        itemValue={(option: SingleValue<BaseNodeDetailsDto>) =>
                            getShortenedPath(option!.path)
                        }
                    />
                </Col>
            </Form.Group>

            {selectedNodePath && (
                <SbFormTextFieldGroup
                    type={"text"}
                    name={"search"}
                    label={"Search"}
                    onTextChanged={(searchText: string | null) => setSearchText(searchText)}
                />
            )}

            {selectedNodePath && (
                <DataTable
                    columns={functionNodesColumns}
                    rows={toPathTableRow()}
                    searchText={searchText ?? undefined}
                />
            )}

            {panelTitle ===
                t("AssignedNodes", {
                    keyPrefix: ChecklistQuestions,
                }) &&
                selectedNodePath && (
                    <SbFormCheckFieldGroup
                        key={selectedNodePath}
                        fieldLabel={t("ARMonitoring", { keyPrefix: Common })}
                        type={"checkbox"}
                        values={arMonitoringCheckValues}
                    />
                )}

            {isQuerySuccessful(maxReferentialLinkValuesCharacters) && arMonitoring && (
                <TextTitledPanel
                    title={t("ARMonitoringNodeChecklistDefaultValues", { keyPrefix: Reports })}
                >
                    <Form onSubmit={onSaveNodeChecklistDefaultValues}>
                        {renderARMonitoringReferentialLinks()}
                        {renderOverRides()}

                        <EndAlignedDiv>
                            {nodeChecklistDefaultValuesDisabled ? (
                                <SbButton
                                    key={"edit"}
                                    variant="primary"
                                    type={"button"}
                                    label={t("Edit")}
                                    icon={FaEdit}
                                    onClick={() => setNodeChecklistDefaultValuesDisabled(false)}
                                />
                            ) : (
                                <>
                                    <CancelButton
                                        onClick={() => setNodeChecklistDefaultValuesDisabled(true)}
                                    />
                                    <SbButton
                                        key={"save"}
                                        variant="primary"
                                        type={"submit"}
                                        label={t("Save")}
                                        icon={FaSave}
                                    />
                                </>
                            )}
                        </EndAlignedDiv>
                    </Form>
                </TextTitledPanel>
            )}
        </TextTitledPanel>
    );
};

export default FunctionNodesSelector;
