import { FilterUserAssignedActionItemsDto } from "../../dtos/users/filter-user-actions-dto";
import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
    createBasePaginationRequest,
} from "../common/base-pagination-request";

export interface FilterUserAssignedActionItemsRequest extends BasePaginationRequest {
    actionItemId: string | null;
    name: string | null;
    actionInstanceStatusId: number | null;
}

export const createFilterUserAssignedActionItemsRequest = (
    filterActionItemsDto: FilterUserAssignedActionItemsDto
): FilterUserAssignedActionItemsRequest => ({
    ...filterActionItemsDto,
    ...createBasePaginationRequest(filterActionItemsDto.pageNumber, filterActionItemsDto.pageSize),
});

export const buildFilterSearchUserAssignedActionItemsParameters = (
    request: FilterUserAssignedActionItemsRequest
): URLSearchParams => {
    const searchParams = buildBasePaginationSearchParameters(request);

    if (request.name) {
        searchParams.set("name", request.name);
    }

    if (request.actionItemId) {
        searchParams.set("actionItemId", request.actionItemId);
    }

    if (request.actionInstanceStatusId !== null && request.actionInstanceStatusId >= 0) {
        searchParams.set("actionInstanceStatusId", request.actionInstanceStatusId.toString());
    }

    return searchParams;
};
