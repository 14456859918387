import { PredefinedAnswerTextResponse } from "../../responses/predefined-answers/predefined-answer-text-response";
import PredefinedAnswerTextLanguageDto from "./predefined-answer-text-language-dto";

export default class PredefinedAnswerTextDto {
    predefinedAnswerTextId: number;
    predefinedAnswerId: number;
    textValue: string;
    predefinedAnswerTextLanguageDto: PredefinedAnswerTextLanguageDto;

    public constructor(response: PredefinedAnswerTextResponse) {
        const {
            predefinedAnswerTextId,
            predefinedAnswerId,
            textValue,
            predefinedAnswerTextLanguageResponse,
        } = response;

        this.predefinedAnswerTextId = predefinedAnswerTextId;
        this.predefinedAnswerId = predefinedAnswerId;
        this.textValue = textValue;

        this.predefinedAnswerTextLanguageDto = new PredefinedAnswerTextLanguageDto(
            predefinedAnswerTextLanguageResponse
        );
    }

    public static constructFromResponses = (
        responses: PredefinedAnswerTextResponse[]
    ): PredefinedAnswerTextDto[] => responses.map((x) => new PredefinedAnswerTextDto(x));
}
