import {
    buildFilterHierarchyImportSearchParameters,
    FilterHierarchyImportRequest,
} from "../../../domain/requests/hierarchy/filter-hierarchy-import-request";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import HierarchyImportDetailsResponse from "../../../domain/responses/hierarchy/hierarchy-import-details-response";
import HierarchyImportResponse from "../../../domain/responses/hierarchy/hierarchy-import-response";
import client from "../../http-client";

const route = "hierarchyimports";

export const filterHierarchyImport = async (
    prefixUrl: string,
    request: FilterHierarchyImportRequest
): Promise<Response<PaginationResponse<HierarchyImportResponse>>> => {
    const searchParams = buildFilterHierarchyImportSearchParameters(request);

    return await client
        .get(`${route}/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getHierarchyImportDetails = async (
    prefixUrl: string,
    hierarchyImportId: number
): Promise<Response<HierarchyImportDetailsResponse>> =>
    await client
        .get(`${route}/${hierarchyImportId}`, {
            prefixUrl: prefixUrl,
        })
        .json();
