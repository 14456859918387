export interface DissociateQuestionSetQuestionDetailsDto {
    questionSetId: number;
    questionIds: number[];
}

export const createDissociateQuestionSetQuestionDetailsDto = (
    questionSetId: number,
    questionIds: number[]
): DissociateQuestionSetQuestionDetailsDto => ({
    questionIds: questionIds,
    questionSetId: questionSetId,
});
