import { ReactElement } from "react";
import { Col } from "react-bootstrap";
import { SingleValue } from "react-select";
import { maxContentWidthSelectStyle, StyledFilterTitle } from "../../core/theme/global-styles";
import { SbSelect } from "../atoms/input/SbSelect";

interface SelectProps<TType> {
    name: string;
    label?: string;
    placeholderText?: string;
    searchable: boolean;
    clearable: boolean;
    loading?: boolean;
    disabled?: boolean;
    required?: boolean;
    items: TType[] | undefined;
    defaultSelectedItem?: TType;
    itemDisplayText: (item: TType) => string;
    onChange?: (item: SingleValue<TType>) => void;
    value?: TType | null;
    onSearchTextChanged?: (searchString: string) => void;
    customStyles?: any;
}

export const FilterSelect = <TType,>({
    name,
    label,
    placeholderText,
    searchable,
    clearable,
    loading,
    disabled,
    items,
    defaultSelectedItem,
    itemDisplayText,
    onChange,
    value,
    onSearchTextChanged,
    customStyles,
}: SelectProps<TType>): ReactElement => (
    <Col>
        {label && <StyledFilterTitle>{label}</StyledFilterTitle>}
        <SbSelect
            name={name}
            styles={customStyles ?? maxContentWidthSelectStyle}
            placeholderText={placeholderText}
            searchable={searchable}
            clearable={clearable}
            loading={loading}
            disabled={disabled}
            items={items}
            defaultSelectedItem={defaultSelectedItem}
            onChange={onChange}
            onSearchTextChanged={onSearchTextChanged}
            itemLabel={itemDisplayText}
            itemValue={itemDisplayText}
            value={value}
        />
    </Col>
);
