import { createPaginationDto, defaultPaginationDto, PaginationDto } from "../common/pagination-dto";

export interface FilterUsersDto extends PaginationDto {
    firstName: string | null;
    lastName: string | null;
    emailAddress: string | null;
    isDeleted: boolean | null;
    isLockedOut: boolean | null;
}

export const createFilterUsersDto = (
    firstName: string | null,
    lastName: string | null,
    emailAddress: string | null,
    isDeleted: boolean | null,
    isLockedOut: boolean | null,
    pageNumber: number,
    pageSize: number,
    sortByColumn: string | null,
    sortByDescending: boolean
): FilterUsersDto => ({
    firstName: firstName,
    lastName: lastName,
    emailAddress: emailAddress,
    isDeleted: isDeleted,
    isLockedOut: isLockedOut,
    ...createPaginationDto(pageNumber, pageSize, sortByColumn, sortByDescending),
});

export const defaultFilterUsersDto = (): FilterUsersDto => {
    const { pageNumber, pageSize, sortByColumn, sortByDescending } = defaultPaginationDto;

    return createFilterUsersDto(
        null,
        null,
        null,
        null,
        null,
        pageNumber,
        pageSize,
        sortByColumn,
        sortByDescending
    );
};
