import React, { ReactElement } from "react";
import { Alert } from "react-bootstrap";
import { Variant } from "react-bootstrap/esm/types";
import { IconType } from "react-icons";
import { MdError } from "react-icons/md";
import { Link } from "react-router-dom";
import { EndAlignedDiv } from "../../core/theme/global-styles";

export const SbAlert: React.FC<{
    variant: Variant;
    text: string;
    icon?: IconType;
    link?: () => ReactElement<typeof Link>;
    textLink?: () => ReactElement<typeof Link>;
}> = ({ variant, text, icon, link, textLink }) => (
    <Alert variant={variant}>
        {icon && React.createElement(icon)} {text} {textLink && textLink()}
        {link && <EndAlignedDiv>{link()}</EndAlignedDiv>}
    </Alert>
);

export const ErrorAlert: React.FC<{ messages: string[] }> = ({ messages }) => (
    <SbAlert variant="danger" text={messages.join("\n")} icon={MdError} />
);

export const InfoAlert: React.FC<{ message: string }> = ({ message }) => (
    <SbAlert variant="info" text={message} />
);
