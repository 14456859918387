import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { EnterKey } from "../../../core/constants/KeyboardKeys";
import { ActionItems, Common } from "../../../core/constants/translation-namespace";
import useKeyPress from "../../../core/hooks/keyPress";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { actionItemDynamicFieldColumnsWithActions } from "../../../core/utilities/dataTableColumns";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
} from "../../../core/utilities/responseStateHelper";
import {
    ActionItemTypeDto,
    allActionItemTypeOption,
} from "../../../domain/dtos/action-items/action-item-type-dto";
import {
    defaultFilterActionItemDynamicFieldsDto,
    FilterActionItemDynamicFieldsDto,
} from "../../../domain/dtos/action-items/filter-action-item-dynamic-fields-dto";
import { hasRoleTypeInGroup, Role } from "../../../domain/enums/Roles";
import { useGetActionItemDynamicFieldData } from "../../../domain/viewmodels/action-items/action-item-dynamic-fields-viewmodel";
import { CreateLink, OrderLink } from "../../atoms/SbLink";
import { DataTable } from "../../organisms/DataTable";
import { ActionItemDynamicFieldsFilter } from "../../organisms/filters/ActionItemDynamicFieldsFilter";

interface SearchParams {
    name: string | null;
    actionItemTypeNodeId: number | null;
    actionItemType: ActionItemTypeDto;
}

const createSearchParams = (
    name: string | null,
    actionItemTypeNodeId: number | null,
    actionItemType: ActionItemTypeDto
): SearchParams => ({
    name: name,
    actionItemTypeNodeId: actionItemTypeNodeId,
    actionItemType: actionItemType,
});

const defaultSearchParams: SearchParams = createSearchParams(null, null, allActionItemTypeOption);

const ActionItemDynamicFieldsContainer: React.FC = () => {
    const [searchParams, setSearchParams] = useState<SearchParams>(defaultSearchParams);
    const [filterDto, setFilterDto] = useState<FilterActionItemDynamicFieldsDto>(
        defaultFilterActionItemDynamicFieldsDto
    );

    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const menu = useMenu();
    const toast = useToast();
    const navigate = useNavigate();
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });
    const auth = useAuth();

    const getActionItemDynamicFieldData = useGetActionItemDynamicFieldData(filterDto);
    const actionItemTypesResponseData = getActionItemDynamicFieldData[0].data;
    const actionItemDynamicFieldResponseData = getActionItemDynamicFieldData[1].data;

    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";

    useLoader(areQueriesLoading(getActionItemDynamicFieldData), ActionItemDynamicFieldsContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.ActionTracker);
        menu.changeActiveDrawerItem(DrawerTitles.ActionItemDynamicFields);

        if (success) {
            toast.addToast(createSuccessToastProps([t(messageKey)]));

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    const onChangeName = (name: string): void => {
        setSearchParams({ ...searchParams, name: name ? name : null });
    };

    const onChangeNodeTypeValue = (option: SingleValue<ActionItemTypeDto>): void => {
        setSearchParams({
            ...searchParams,
            actionItemTypeNodeId: option!.nodeId !== 0 ? option!.nodeId : null,
            actionItemType: option!,
        });
    };

    const search = (): void => {
        setFilterDto({ ...filterDto, pageNumber: 1, ...searchParams });
    };

    const resetFilter = (): void => {
        setSearchParams(defaultSearchParams);
        setFilterDto({ ...filterDto, ...defaultSearchParams });
    };

    const navigateToActionItemDynamicFieldDetails = (actionItemDynamicFieldId: number): void =>
        navigate(`${getPath(DrawerTitles.ActionItemDynamicFields)}/${actionItemDynamicFieldId}`);

    const navigateToEditActionItemDynamicField = (actionItemDynamicFieldId: number): void =>
        navigate(
            `${getPath(DrawerTitles.ActionItemDynamicFields)}/${actionItemDynamicFieldId}/edit`
        );

    const navigateToDeleteActionItemDynamicField = (actionItemDynamicFieldId: number): void =>
        navigate(
            `${getPath(DrawerTitles.ActionItemDynamicFields)}/${actionItemDynamicFieldId}/delete`
        );

    useKeyPress(EnterKey, search, searchParams);

    return (
        <>
            <PageHeading>{t("ActionItemDynamicFields")}</PageHeading>
            <PageSubHeading>{t("HeaderHelpText")}</PageSubHeading>
            <SectionVerticalSpace />

            {areQueriesSuccessful(getActionItemDynamicFieldData) && (
                <>
                    <ActionItemDynamicFieldsFilter
                        name={searchParams.name}
                        changeName={onChangeName}
                        actionItemType={searchParams.actionItemType}
                        actionItemTypes={[allActionItemTypeOption, ...actionItemTypesResponseData!]}
                        changeActionItemType={onChangeNodeTypeValue}
                        search={search}
                        resetFilter={resetFilter}
                    />
                    <SectionVerticalSpace />

                    <EndAlignedDiv>
                        {hasRoleTypeInGroup(auth.userRoles, [Role.TenantAdmin]) && (
                            <OrderLink
                                label={t("OrderActionItemTypes")}
                                navigateTo={`${getPath(
                                    DrawerTitles.ActionItemDynamicFields
                                )}/order`}
                            />
                        )}
                        <CreateLink
                            label={t("CreateNew", { keyPrefix: Common })}
                            navigateTo={`${getPath(DrawerTitles.ActionItemDynamicFields)}/create`}
                        />
                    </EndAlignedDiv>
                    <LargeVerticalSpace />

                    <DataTable
                        keyPrefix={ActionItems}
                        columns={actionItemDynamicFieldColumnsWithActions}
                        rows={actionItemDynamicFieldResponseData!.rows}
                        viewItem={navigateToActionItemDynamicFieldDetails}
                        editItem={navigateToEditActionItemDynamicField}
                        deleteItem={navigateToDeleteActionItemDynamicField}
                        totalItems={actionItemDynamicFieldResponseData!.recordCount}
                        paginationDto={filterDto}
                        setPaginationDto={setFilterDto}
                    />
                </>
            )}
        </>
    );
};

export default ActionItemDynamicFieldsContainer;
