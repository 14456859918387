import React, { ChangeEventHandler } from "react";
import { Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { StyledFormLabel } from "../../../core/theme/global-styles";
import SbLabelText from "../../atoms/SbLabelText";

export interface FormikCheckboxFieldProps {
    name: string;
    label?: string;
    defaultSelected?: boolean | null;
    disabled?: boolean;
    onFormikChange?: ChangeEventHandler<Element>;
}

const StyledFormGroup = styled(Form.Group)`
    display: flex;
    align-items: center;
`;

const SbFormikCheckFieldGroup: React.FC<{
    label: string;
    type: "checkbox" | "radio" | "switch";
    values: FormikCheckboxFieldProps[];
    required?: boolean;
    children?: React.ReactNode;
    useAutoColumnWidth?: boolean | null;
}> = ({ label, type, values, required, children, useAutoColumnWidth }) => (
    <StyledFormGroup as={Row} className="mb-3">
        <StyledFormLabel column sm={2}>
            <SbLabelText label={label} required={required} />
        </StyledFormLabel>
        <Col sm={useAutoColumnWidth ? "auto" : 4}>
            {values.map((x) => (
                <Form.Check
                    type={type}
                    key={x.name + (x.defaultSelected?.toString() ?? "")}
                    name={x.name}
                    label={x.label}
                    defaultChecked={x.defaultSelected ?? undefined}
                    disabled={x.disabled}
                    onChange={x.onFormikChange}
                />
            ))}
        </Col>
        {children && <Col>{children}</Col>}
    </StyledFormGroup>
);

export default SbFormikCheckFieldGroup;
