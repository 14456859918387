import { Response } from "../../../domain/responses/common/response-response";
import { ReferentialLinkResponse } from "../../../domain/responses/referential-links/referential-link-response";
import client from "../../http-client";

const route = "nodereferentiallinks";

export const getNodeReferentialLinks = async (
    prefixUrl: string,
    nodeId: number
): Promise<Response<ReferentialLinkResponse[]>> =>
    await client
        .get(`${route}/${nodeId}`, {
            prefixUrl: prefixUrl,
        })
        .json();
