import { QuestionTextResponse } from "../../responses/question-text/question-text-response";

export default class BaseQuestionTextDto {
    questionId: number;
    text: string;

    public constructor(response: QuestionTextResponse) {
        const { questionId, text } = response;

        this.questionId = questionId;
        this.text = text;
    }
}
