import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { lookupAssociatedRisksByLevel } from "../../../data/services/lookup/lookup-service";
import AssociatedRiskDto from "../../dtos/associated-risks/associated-risk-dto";
import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import RiskLevel from "../../enums/associated-risks/risk-level";
import { FilterAssociatedRiskRequest } from "../../requests/associated-risks/filter-associated-risk-request";

export const useFilterAssociatedRisks = (
    riskLevel: RiskLevel,
    associatedRiskBasePaginationDto: BasePaginationDto
): UseQueryResult<AssociatedRiskDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterAssociatedRisksByLevel", riskLevel],
        () =>
            lookupAssociatedRisksByLevel(
                url.baseUrl,
                new FilterAssociatedRiskRequest(riskLevel, associatedRiskBasePaginationDto)
            ),
        {
            select: AssociatedRiskDto.toAssociatedRiskResponses,
            onError: errorResponseToDisplayHandler,
        }
    );
};
