import { convertToggleValueToBoolean } from "../../../core/utilities/convertToBoolean";
import { getNullableFormField } from "../../../core/utilities/formDataHelper";
import { toBoolean } from "../../../core/utilities/string-helper";
import { ActionItemImportance } from "../../enums/action-items/ActionItemImportance";
import AnswerValueType from "../../enums/answer-types/answer-value-type";
import { ActionItemDynamicFieldDetailsDto } from "./action-item-dynamic-fields-dto";
import { CreateEditActionItemDynamicFieldValueDto } from "./create-edit-action-item-dynamic-field-value-dto";

export class EditActionItemDto {
    actionItemId: number;
    name: string;
    description: string;
    actionItemImportance: ActionItemImportance;
    startDate: Date | null;
    dueDate: Date | null;
    isPersonalActionItem: boolean;
    actionItemSubTypeNodeId: number;
    requiredOutcome: string;
    createEditActionItemDynamicFieldValueDto: CreateEditActionItemDynamicFieldValueDto[];

    public constructor(
        formData: FormData,
        actionItemId: number,
        actionItemImportance: ActionItemImportance,
        actionItemSubTypeNodeId: number,
        actionItemDynamicFieldValueDto: ActionItemDynamicFieldDetailsDto[],
        actionItemTypeNodeIdChanged: boolean
    ) {
        this.actionItemId = actionItemId;
        this.name = formData.get("name") as string;
        this.description = formData.get("description") as string;
        this.actionItemImportance = actionItemImportance;

        const startDateFromData = formData.get("startDate") as string;
        this.startDate = getNullableFormField(startDateFromData)
            ? new Date(startDateFromData)
            : null;

        const dueDateFromData = formData.get("dueDate") as string;
        this.dueDate = getNullableFormField(dueDateFromData) ? new Date(dueDateFromData) : null;

        this.isPersonalActionItem = convertToggleValueToBoolean(
            formData.get("isPersonalActionItem")! as string
        );
        this.actionItemSubTypeNodeId = actionItemSubTypeNodeId;
        this.requiredOutcome = formData.get("requiredOutcome") as string;
        this.createEditActionItemDynamicFieldValueDto = [];

        actionItemDynamicFieldValueDto?.map((x) => {
            const actionItemDynamicFieldId = actionItemTypeNodeIdChanged
                ? x.actionItemDynamicFieldId.toString()
                : `existing${x.actionItemDynamicFieldId.toString()}`;

            const actionItemDynamicFieldValue = getNullableFormField(
                formData.get(actionItemDynamicFieldId) as string
            );

            this.createEditActionItemDynamicFieldValueDto?.push({
                actionItemDynamicFieldId: x.actionItemDynamicFieldId,
                intValue:
                    x.valueType == AnswerValueType.Integer && actionItemDynamicFieldValue !== null
                        ? Number(actionItemDynamicFieldValue!)
                        : null,
                boolValue:
                    x.valueType == AnswerValueType.Boolean && actionItemDynamicFieldValue !== null
                        ? toBoolean(actionItemDynamicFieldValue!)
                        : null,
                dateValue:
                    x.valueType == AnswerValueType.DateTime && actionItemDynamicFieldValue !== null
                        ? new Date(actionItemDynamicFieldValue!)
                        : null,
                decimalValue:
                    x.valueType == AnswerValueType.Decimal && actionItemDynamicFieldValue !== null
                        ? Number(actionItemDynamicFieldValue!)
                        : null,
                texArea:
                    x.valueType == AnswerValueType.TextArea && actionItemDynamicFieldValue !== null
                        ? actionItemDynamicFieldValue!
                        : null,
                textValue:
                    (x.valueType == AnswerValueType.Text || x.valueType == AnswerValueType.List) &&
                    actionItemDynamicFieldValue !== null
                        ? actionItemDynamicFieldValue!
                        : null,
            });
        });
    }
}
