import styled from "styled-components";

const StyledSpan = styled.span`
    color: ${(props) => props.theme.palette.primary};
    padding-left: 5px;
    font-weight: bold;
`;

const SbLabelText: React.FC<{
    label: string;
    required?: boolean;
}> = ({ label, required }) => {
    const markRequired = (): boolean | undefined | JSX.Element =>
        required && <StyledSpan>*</StyledSpan>;

    return (
        <>
            {label}
            {markRequired()}
        </>
    );
};

export default SbLabelText;
