import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    checkPrePublishReviewStatus,
    submitAndPrePublishItems,
} from "../../../data/services/review-reports/review-reports-service";
import { PrePublishReviewStatusDto } from "../../dtos/review-reports/pre-publish-review-status-dto";
import { Response } from "../../responses/common/response-response";

export const useCheckPrePublishReviewStatus = (
    questionSetInstanceId: number
): UseQueryResult<PrePublishReviewStatusDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["checkPrePublishReviewStatus", questionSetInstanceId],
        () => checkPrePublishReviewStatus(url.baseUrl, questionSetInstanceId),
        {
            select: PrePublishReviewStatusDto.constructFromResponse,
            onError: errorResponseToDisplayHandler,
            enabled: questionSetInstanceId !== 0,
        }
    );
};

export const useSubmitAndPrePublishItems = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    number
> => {
    const url = useUrl();

    return useMutation((mutationKey: number) => {
        return submitAndPrePublishItems(url.baseUrl, mutationKey);
    });
};
