import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaRedo } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { Reports } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import { Response } from "../../../domain/responses/common/response-response";
import {
    useGetReviewReportSetDetails,
    useUnpublishReviewReportSet,
} from "../../../domain/viewmodels/review-reports/unpublish-review-viewmodel";
import { SbButton } from "../../atoms/SbButton";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import { ActionConfirmationFragment, AlertProps } from "../../organisms/ActionConfirmationFragment";

const UnpublishReviewReportSetContainer: React.FC = () => {
    const reviewReportSetId = Number(useParams().reviewReportSetId);

    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: Reports });

    const unpublishReviewReportSet = useUnpublishReviewReportSet();
    const reviewReportSetDetails = useGetReviewReportSetDetails(reviewReportSetId);

    const reviewReportSetDetailsResponseData = reviewReportSetDetails.data;

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Checklists);
        menu.changeActiveDrawerItem(DrawerTitles.ReviewsHistory);
    }, []);

    useLoader(
        isQueryLoading(reviewReportSetDetails) || isMutationLoading(unpublishReviewReportSet),
        UnpublishReviewReportSetContainer
    );

    const onUnpublishReviewReportSet = (): void => {
        unpublishReviewReportSet.mutate(reviewReportSetId, {
            onSuccess: async (_: Response<boolean>) => {
                const params = [
                    createNavigateSearchParameter("success", "true"),
                    createNavigateSearchParameter(
                        "reviewReportName",
                        reviewReportSetDetails.data!.reviewReportName
                    ),
                    createNavigateSearchParameter("messageKey", "SuccessfullyUnpublishedReview"),
                ];

                navigateSearch(`${getPath(DrawerTitles.Reviews)}`, params);
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    const buildUnpublishButton = (): ReactElement<typeof Button> => (
        <SbButton
            onClick={() => onUnpublishReviewReportSet()}
            variant={"primary"}
            type={"submit"}
            label={t("Unpublish")}
            icon={FaRedo}
        />
    );

    const buildConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow(
            t("ReportName"),
            reviewReportSetDetailsResponseData?.reviewReportName
        ),
    ];

    const alertProps = (): AlertProps => {
        return {
            variant: "warning",
            messages: [t("UnpublishWarning")],
        };
    };

    return (
        <>
            <ActionConfirmationFragment
                pageHeading={t("UnpublishReportTitle")}
                alert={alertProps()}
                panelTitle={t("UnpublishReportConfirmation")}
                rows={buildConfirmationRows()}
                primaryButton={buildUnpublishButton()}
                areQueriesSuccessful={isQuerySuccessful(reviewReportSetDetails)}
            />
        </>
    );
};

export default UnpublishReviewReportSetContainer;
