import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import {
    ActionItems,
    ActionItemTypes,
    Common,
} from "../../../core/constants/translation-namespace";
import {
    ContentContainer,
    EndAlignedDiv,
    SectionContainer,
} from "../../../core/theme/global-styles";
import { ActionItemTypeDto } from "../../../domain/dtos/action-items/action-item-type-dto";
import { ActionInstanceStatusDto } from "../../../domain/dtos/actions/action-instance-status-dto";
import { ActionItemImportanceDto } from "../../../domain/dtos/actions/action-item-importance-dto";
import { ResetButton, SearchButton } from "../../atoms/SbButton";
import { translateWithFallback } from "../../helpers/translate-with-common-fallback";
import DateTimeFilter from "../../molecules/DateTimeFilter";
import { FilterInput } from "../../molecules/FilterInput";
import { FilterSelect } from "../../molecules/FilterSelect";
import { FilterNumberInput } from "../../molecules/input/FilterNumberInput";

export const ActionItemsFilter: React.FC<{
    actionItemName: string | null;
    changeActionItemName: (actionItemName: string) => void;
    actionItemType: ActionItemTypeDto;
    actionItemTypes: ActionItemTypeDto[];
    changeActionItemType: (option: SingleValue<ActionItemTypeDto>) => void;
    actionItemSubType: ActionItemTypeDto;
    actionItemSubTypes: ActionItemTypeDto[];
    changeActionItemSubType: (option: SingleValue<ActionItemTypeDto>) => void;
    raciParticipant: string | null;
    changeRaciParticipant: (raciParticipant: string) => void;
    actionItemId: string | null;
    changeActionItemId: (actionItemId: string) => void;
    actionItemImportance: ActionItemImportanceDto;
    actionItemImportances: ActionItemImportanceDto[];
    changeActionItemImportance: (option: SingleValue<ActionItemImportanceDto>) => void;
    actionInstanceStatus: ActionInstanceStatusDto;
    actionInstanceStatuses: ActionInstanceStatusDto[];
    changeActionInstanceStatus: (option: SingleValue<ActionInstanceStatusDto>) => void;
    dueDateFrom: Date | null;
    changeDueDateFrom: (dueDateFrom: Date) => void;
    dueDateTo: Date | null;
    changeDueDateTo: (dueDateTo: Date) => void;
    search: () => void;
    resetFilter: () => void;
}> = ({
    actionItemName,
    changeActionItemName,
    actionItemType,
    actionItemTypes,
    changeActionItemType,
    actionItemSubType,
    actionItemSubTypes,
    changeActionItemSubType,
    raciParticipant,
    changeRaciParticipant,
    actionItemId,
    changeActionItemId,
    actionItemImportance,
    actionItemImportances,
    changeActionItemImportance,
    actionInstanceStatus,
    actionInstanceStatuses,
    changeActionInstanceStatus,
    dueDateFrom,
    changeDueDateFrom,
    dueDateTo,
    changeDueDateTo,
    search,
    resetFilter,
}) => {
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });
    return (
        <ContentContainer>
            <SectionContainer>
                <Row>
                    <Col>
                        <FilterSelect
                            name={"actionItemTypes"}
                            label={`${t("ActionItemType")}`}
                            placeholderText={""}
                            searchable
                            clearable={false}
                            items={actionItemTypes}
                            itemDisplayText={(option: ActionItemTypeDto) => option.value} //TODO: Add translations for dynamic data
                            onChange={changeActionItemType}
                            value={actionItemType}
                        />
                    </Col>
                    <Col>
                        <FilterSelect
                            name={"actionItemSubTypes"}
                            label={`${t("SubType", { keyPrefix: Common })}`}
                            placeholderText={""}
                            searchable
                            clearable={false}
                            items={actionItemSubTypes}
                            itemDisplayText={(option: ActionItemTypeDto) => option.value} //TODO: Add translations for dynamic data
                            onChange={changeActionItemSubType}
                            value={actionItemSubType}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("Name", { keyPrefix: Common })}
                            name={"actionItemName"}
                            type={"text"}
                            onChangeEventHandler={changeActionItemName}
                            value={actionItemName ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterSelect
                            name={"actionInstanceStatuses"}
                            label={`${t("Status", { keyPrefix: Common })}`}
                            placeholderText={""}
                            searchable
                            clearable={false}
                            items={actionInstanceStatuses}
                            itemDisplayText={(option: ActionInstanceStatusDto) =>
                                translateWithFallback(t, option.name, ActionItems)
                            }
                            onChange={changeActionInstanceStatus}
                            value={actionInstanceStatus}
                        />
                    </Col>
                    <Col>
                        <FilterSelect
                            name={"actionItemImportances"}
                            label={`${t("Importance", { keyPrefix: ActionItemTypes })}`}
                            placeholderText={""}
                            searchable
                            clearable={false}
                            items={actionItemImportances}
                            itemDisplayText={(option: ActionItemImportanceDto) =>
                                translateWithFallback(t, option.name, ActionItems)
                            }
                            onChange={changeActionItemImportance}
                            value={actionItemImportance}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("RaciParticipant")}
                            name={"raciParticipant"}
                            type={"text"}
                            onChangeEventHandler={changeRaciParticipant}
                            value={raciParticipant ?? ""}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FilterNumberInput
                            title={t("ActionItemId")}
                            name={"actionItemId"}
                            onChangeEventHandler={changeActionItemId}
                            value={actionItemId ?? ""}
                            minValue={1}
                        />
                    </Col>
                    <Col>
                        <DateTimeFilter
                            label={t("DueDateFrom")}
                            name={"dueDateFrom"}
                            onInputChange={changeDueDateFrom}
                            value={dueDateFrom}
                        />
                    </Col>
                    <Col>
                        <DateTimeFilter
                            label={t("DueDateTo")}
                            name={"dueDateTo"}
                            onInputChange={changeDueDateTo}
                            value={dueDateTo}
                        />
                    </Col>
                    <Col></Col>
                </Row>
            </SectionContainer>
            <EndAlignedDiv>
                <SearchButton onClick={search} />
                <ResetButton onClick={resetFilter} />
            </EndAlignedDiv>
        </ContentContainer>
    );
};
