import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
    createBasePaginationRequestFromDto,
} from "../common/base-pagination-request";

export interface FilterNodeTypesRequest extends BasePaginationRequest {
    hierarchyTypeId: number | null;
    name: string | null;
}

export const createFilterNodeTypesRequest = (
    hierarchyTypeId: number | null,
    name: string | null,
    paginationDto: BasePaginationDto
): FilterNodeTypesRequest => ({
    hierarchyTypeId: hierarchyTypeId,
    name: name,
    ...createBasePaginationRequestFromDto(paginationDto),
});

export const buildFilterSearchParameters = (request: FilterNodeTypesRequest): URLSearchParams => {
    const searchParams = buildBasePaginationSearchParameters(request);

    if (request.hierarchyTypeId) {
        searchParams.set("hierarchyTypeId", request.hierarchyTypeId.toString());
    }

    if (request.name) {
        searchParams.set("name", request.name);
    }

    return searchParams;
};
