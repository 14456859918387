import React from "react";
import Row from "react-bootstrap/Row";
import { ActionItemSummaryResponse } from "../../domain/responses/action-items/action-item-summary-response";
import { ActionItemSummaryCard } from "../molecules/ActionItemSummaryCard";

export const ActionItemSummaryCardGallery: React.FC<{
    actionItemSummaryCards: ActionItemSummaryResponse[] | undefined;
}> = ({ actionItemSummaryCards }) => {
    return (
        <Row>
            {actionItemSummaryCards &&
                actionItemSummaryCards.map((actionItemSummaryCard, index) => (
                    <ActionItemSummaryCard key={index} actionItemSummary={actionItemSummaryCard} />
                ))}
        </Row>
    );
};
