import { CompleteMyActionItemDto } from "../../dtos/action-items/complete-my-action-item-dto";

export class CompleteActionItemRequest {
    actionItemInstanceId: number;
    comment: string;

    public constructor(dto: CompleteMyActionItemDto) {
        this.actionItemInstanceId = dto.actionItemInstanceId;
        this.comment = dto.comment;
    }
}
