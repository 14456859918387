import {
    BasePaginationDto,
    createBasePaginationDto,
    defaultBasePaginationDto,
} from "./base-pagination-dto";

export interface PaginationDto extends BasePaginationDto {
    sortByColumn: string | null;
    sortByDescending: boolean;
}

export const createPaginationDto = (
    pageNumber: number,
    pageSize: number,
    sortByColumn: string | null,
    sortByDescending: boolean
): PaginationDto => ({
    ...createBasePaginationDto(pageNumber, pageSize),
    sortByColumn: sortByColumn,
    sortByDescending: sortByDescending,
});

export const defaultPaginationDto = createPaginationDto(
    defaultBasePaginationDto.pageNumber,
    defaultBasePaginationDto.pageSize,
    null,
    false
);
