import BaseQuestionAnswerDto from "../../dtos/answer-capture/base-question-answer-dto";
import CommentType from "../../enums/answer-types/comment-type";

export default class QuestionAnswerRequest {
    questionSetInstanceAnswerId: number;
    questionId: number;
    parentQuestionId: number | null;
    textValue: string | null;
    intValue: number | null;
    decimalValue: number | null;
    boolValue: boolean | null;
    dateTimeValueLocal: Date | null;
    predefinedAnswerId: number | null;
    actionItemDueDateTimeLocal: Date | null;
    commentType: CommentType | null;
    commentText: string | null;
    reviewCommentText: string | null;
    nestedQuestionAnswerRequests: QuestionAnswerRequest[] | null;

    // TODO: Improvement - Have QuestionAnswerRequest as a base for Question Answers and Nested Question Answers, for more deterministic/known state on the properties (for example, the way the possible values differ for Question Set Instance Answer Id and Parent Question Id in each)
    public constructor(dto: BaseQuestionAnswerDto, nestedRequests: QuestionAnswerRequest[] | null) {
        const {
            questionSetInstanceAnswerId,
            questionId,
            parentQuestionId,
            textValue,
            intValue,
            decimalValue,
            boolValue,
            dateTimeValueLocal,
            predefinedAnswerId,
            actionItemDueDateTimeLocal,
            commentType,
            commentText,
            reviewCommentText,
        } = dto;

        this.questionSetInstanceAnswerId = questionSetInstanceAnswerId;
        this.questionId = questionId;
        this.parentQuestionId = parentQuestionId;
        this.textValue = textValue;
        this.intValue = intValue;
        this.decimalValue = decimalValue;
        this.boolValue = boolValue;
        this.dateTimeValueLocal = dateTimeValueLocal;
        this.predefinedAnswerId = predefinedAnswerId;
        this.actionItemDueDateTimeLocal = actionItemDueDateTimeLocal;
        this.commentType = commentType;
        this.commentText = commentText;
        this.reviewCommentText = reviewCommentText;
        this.nestedQuestionAnswerRequests = nestedRequests;
    }

    public static constructFromFlattenedDtos = (
        dtos: BaseQuestionAnswerDto[]
    ): QuestionAnswerRequest[] => {
        const result: QuestionAnswerRequest[] = [];

        // TODO: Renaming
        const mapDtoToRequest = (
            parentQuestionAnswerDto: BaseQuestionAnswerDto
        ): QuestionAnswerRequest => {
            const childDtos = dtos.filter(
                (x) =>
                    x.parentQuestionId === parentQuestionAnswerDto.questionId &&
                    x.questionSetInstanceAnswerId ===
                        parentQuestionAnswerDto.questionSetInstanceAnswerId
            );

            return new QuestionAnswerRequest(
                parentQuestionAnswerDto,
                childDtos.length ? childDtos.map(mapDtoToRequest) : null
            );
        };

        dtos.filter((x) => !x.parentQuestionId).forEach((topLevelDto) => {
            result.push(mapDtoToRequest(topLevelDto));
        });

        return result;
    };
}
