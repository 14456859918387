import { FilterUsersDto } from "../../dtos/users/filter-users-dto";
import {
    buildPaginationSearchParameters,
    createPaginationRequest,
    PaginationRequest,
} from "../common/pagination-request";
import { buildSearchUsersSearchParameters, SearchUsersRequest } from "./search-users-request";

export interface FilterUsersRequest extends SearchUsersRequest, PaginationRequest {}

export const createFilterUsersRequestFromDto = (dto: FilterUsersDto): FilterUsersRequest => {
    const { pageNumber, pageSize, sortByColumn, sortByDescending } = dto;

    return {
        ...dto,
        ...createPaginationRequest(pageNumber, pageSize, sortByColumn, sortByDescending),
    };
};

export const createFilterUsersSearchRequest = (
    searchText: string | null,
    pageNumber: number,
    pageSize: number,
    isDeleted: boolean | null = null
): FilterUsersRequest => ({
    firstName: searchText,
    lastName: searchText,
    emailAddress: searchText,
    isDeleted: isDeleted,
    isLockedOut: null,
    ...createPaginationRequest(pageNumber, pageSize, null, false),
});

export const buildFilterSearchParameters = (request: FilterUsersRequest): URLSearchParams => {
    const searchParams = buildPaginationSearchParameters(request);
    buildSearchUsersSearchParameters(request, searchParams);

    return searchParams;
};
