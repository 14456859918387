import ReviewRating from "../../enums/review-reports/review-ratings";

export class EditQuestionSetInstanceReviewRatingDto {
    questionSetInstanceId: number;
    reviewRating: ReviewRating;

    public constructor(questionSetInstanceId: number, reviewRating: ReviewRating) {
        this.questionSetInstanceId = questionSetInstanceId;
        this.reviewRating = reviewRating;
    }
}
