import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { dissociateUser } from "../../../data/services/hierarchy/nodes-service";
import { getUserDetails } from "../../../data/services/users/users-service";
import { createUserDtoFromResponse, UserDto } from "../../dtos/users/user-dto";
import { createUserNodeAssociationRequest } from "../../requests/hierarchy/user-node-association-request";
import { Response } from "../../responses/common/response-response";

export interface UserNodeAssociationParameters {
    nodeId: number;
    userId: number;
}

export const createUserNodeAssociationParameters = (
    nodeId: number,
    userId: number
): UserNodeAssociationParameters => ({
    nodeId,
    userId,
});

export const useGetUserDetails = (userId: number): UseQueryResult<UserDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(["getUserDetails", userId], () => getUserDetails(url.baseUrl, userId), {
        select: createUserDtoFromResponse,
        onError: errorResponseToDisplayHandler,
    });
};

export const useDissociateUser = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    UserNodeAssociationParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: UserNodeAssociationParameters) => {
        const { nodeId, userId } = mutationKey;

        return dissociateUser(url.baseUrl, createUserNodeAssociationRequest(nodeId, userId));
    });
};
