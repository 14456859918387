import { AnswerDetailsRequest } from "../../../domain/requests/answer-summary/answer-details-request";
import {
    buildAnswerSummarySearchParameters,
    createFilterAnswerSummarySearchRequest,
} from "../../../domain/requests/answer-summary/filter-answer-summary-search-request";
import { AnswerDetailsResponse } from "../../../domain/responses/answer-summary/answer-details-response";
import { AnswerSummaryResponse } from "../../../domain/responses/answer-summary/answer-summary-response";
import QuestionSetInstanceAnswerFileDownloadResponse from "../../../domain/responses/answer-summary/question-set-instance-answer-file-download-response";
import { Response } from "../../../domain/responses/common/response-response";
import client from "../../http-client";

const route = "answersummary";

export const getTeamAnswerSummaries = async (
    prefixUrl: string,
    isTraining: boolean | null
): Promise<Response<AnswerSummaryResponse[]>> => {
    const searchParams = buildAnswerSummarySearchParameters(
        createFilterAnswerSummarySearchRequest(isTraining)
    );

    return await client
        .get(`${route}/team-answer-summary`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getAnswerSummaries = async (
    prefixUrl: string,
    isTraining: boolean | null
): Promise<Response<AnswerSummaryResponse[]>> => {
    const searchParams = buildAnswerSummarySearchParameters(
        createFilterAnswerSummarySearchRequest(isTraining)
    );

    return await client
        .get(`${route}/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const downloadQuestionSetInstanceAnswerFile = async (
    prefixUrl: string,
    questionSetInstanceAnswerFileId: number
): Promise<Response<QuestionSetInstanceAnswerFileDownloadResponse>> =>
    await client
        .get(`${route}/download-file/${questionSetInstanceAnswerFileId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const getAnswerDetails = async (
    prefixUrl: string,
    request: AnswerDetailsRequest
): Promise<Response<AnswerDetailsResponse[]>> => {
    const searchParams = AnswerDetailsRequest.buildAnswerDetailsSearchParameters(request);

    return await client
        .get(`${route}/answer-details`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};
