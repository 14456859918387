const getBase64 = async (file: File): Promise<string | ArrayBuffer | null> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const getBase64FileString = async (file: File): Promise<string | null> => {
    const base64String = await getBase64(file);

    if (base64String === null) {
        return null;
    }

    return base64String.toString().split(",")[1] as string;
};
