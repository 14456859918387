import { CreateActionItemNotificationTriggerDto } from "../../dtos/actions/create-action-item-notification-trigger-dto";
import { IntervalRequest } from "../common/interval-request";

export class CreateActionItemNotificationTriggersRequest {
    notificationTriggerId: number;
    sendWindowIntervalRequest: IntervalRequest;
    isEnabled: boolean;

    public constructor(dto: CreateActionItemNotificationTriggerDto) {
        const {
            notificationTriggerId,
            sendWindowInMinutesPeriodType,
            sendWindowInMinutes,
            isEnabled,
        } = dto;

        this.notificationTriggerId = notificationTriggerId;
        this.sendWindowIntervalRequest = new IntervalRequest(
            sendWindowInMinutes,
            sendWindowInMinutesPeriodType
        );
        this.isEnabled = isEnabled;
    }
}
