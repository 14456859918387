import FileUploadDto from "../file-storage/file-upload-dto";

export default class QuestionAnswerFileUploadDto extends FileUploadDto {
    questionSetInstanceAnswerEntityId: number;

    public constructor(
        questionSetInstanceAnswerEntityId: number,
        file: File,
        fileBase64String: string
    ) {
        super(file, fileBase64String);

        this.questionSetInstanceAnswerEntityId = questionSetInstanceAnswerEntityId;
    }
}
