import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import {
    filterNestedQuestions,
    orderNestedQuestions,
} from "../../../data/services/questions/question-service";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { createPaginationDto } from "../../dtos/common/pagination-dto";
import { createPaginationRequestFromDto } from "../../requests/common/pagination-request";
import { FilterNestedQuestionsRequest } from "../../requests/questions/filter-nested-questions-request";
import {
    OrderNestedQuestionsRequest,
    QuestionsOrderRequest,
} from "../../requests/questions/order-nested-questions";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { NestedQuestionResponse } from "../../responses/questions/nested-question-response";

export const useFilterNestedQuestions = (
    parentQuestionId: number
): UseQueryResult<PaginatedTableDto<number>, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterNestedQuestions", parentQuestionId],
        () =>
            filterNestedQuestions(
                url.baseUrl,
                new FilterNestedQuestionsRequest(
                    parentQuestionId,
                    createPaginationRequestFromDto(
                        createPaginationDto(1, 1000, "OrderIndex", false)
                    )
                )
            ),
        {
            keepPreviousData: true,
            select: transformToDataTableRows,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useOrderNestedQuestions = (
    parentQuestionId: number
): UseMutationResult<Response<boolean>, HTTPError, number[]> => {
    const url = useUrl();

    return useMutation((nestedQuestionIds: number[]) => {
        const questionsOrder = nestedQuestionIds.map(
            (x, index): QuestionsOrderRequest => new QuestionsOrderRequest(x, ++index)
        );

        const request = new OrderNestedQuestionsRequest(parentQuestionId, questionsOrder);

        return orderNestedQuestions(url.baseUrl, request);
    });
};

const transformToDataTableRows = (
    response: Response<PaginationResponse<NestedQuestionResponse>>
): PaginatedTableDto<number> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((nestedQuestion) => ({
        metadata: nestedQuestion.questionId,
        columns: [
            {
                // Order Index
                value: nestedQuestion.orderIndex.toString(),
                type: DataTableColumnTypes.Text,
                metadata: "Index",
            },
            {
                // Question Id
                value: nestedQuestion.questionId.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                // Question Type
                value: nestedQuestion.questionType.name,
                type: DataTableColumnTypes.Text,
            },
            {
                // Questions Text
                value: nestedQuestion.questionText.text,
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
