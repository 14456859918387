import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import { filterNonWorkingDaySets } from "../../../data/services/non-working-days/non-working-day-set-service";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { FilterNonWorkingDaySetDto } from "../../dtos/non-working-days/filter-non-working-day-set-dto";
import {
    createNonWorkingDaySetDto,
    NonWorkingDaySetDto,
} from "../../dtos/non-working-days/non-working-day-set-dto";
import { createFilterNonWorkingDaySetRequestFromDto } from "../../requests/non-working-days/filter-non-working-day-sets-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { NonWorkingDaySetResponse } from "../../responses/non-working-days/non-working-day-set-response";

export const useFilterNonWorkingDaySets = (
    dto: FilterNonWorkingDaySetDto
): UseQueryResult<PaginatedTableDto<NonWorkingDaySetDto>, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterNonWorkingDaySets", dto],
        () => filterNonWorkingDaySets(url.baseUrl, createFilterNonWorkingDaySetRequestFromDto(dto)),
        {
            keepPreviousData: true,
            select: transformToDataTableRows,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const transformToDataTableRows = (
    response: Response<PaginationResponse<NonWorkingDaySetResponse>>
): PaginatedTableDto<NonWorkingDaySetDto> => {
    const rows = response.data.results!.map((nonWorkingDaySet) => {
        return {
            metadata: createNonWorkingDaySetDto(nonWorkingDaySet),
            columns: [
                {
                    value: nonWorkingDaySet.name,
                    type: DataTableColumnTypes.Link,
                },
                {
                    value: nonWorkingDaySet.nonWorkingDayCount?.toString(),
                    type: DataTableColumnTypes.Text,
                },
            ],
        };
    });

    return {
        numberOfPages: response.data.pageCount,
        pageSize: response.data.pageSize,
        currentPage: response.data.currentPage,
        recordCount: response.data.recordCount,
        rows: rows,
    };
};
