import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useAuth } from "../../../core/store/auth-context";
import { useUrl } from "../../../core/store/url-context";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import { getUsersInRole } from "../../../data/services/dashboard/dashboard-services";
import { getRoles } from "../../../data/services/users/roles-service";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { UsersInRoleDto } from "../../dtos/dashboard/filter-users-in-role-dto";
import { RoleDto, toRoleDtos } from "../../dtos/users/role-dto";
import { splitCamelCase } from "../../helpers/split-camel-case/spilt-camel-case";
import { createPaginationRequest } from "../../requests/common/pagination-request";
import { createUsersInRoleRequestFromDto } from "../../requests/dashboard/users-in-role-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { UsersInRoleResponse } from "../../responses/dashboard/users-in-role-response";
import { RoleResponse } from "../../responses/users/role-response";

export const useGetUsersInRole = (
    filterDto: UsersInRoleDto
): UseQueryResult<PaginatedTableDto<UsersInRoleResponse>, HTTPError> => {
    const url = useUrl();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const isEnabled = auth.isImpersonating && auth.tenantId != null && auth.tenantId > 0;

    return useQuery(
        ["getUsersInRole", filterDto],
        () => getUsersInRole(url.baseUrl, createUsersInRoleRequestFromDto(filterDto)),
        {
            keepPreviousData: true,
            select: transformToDataTableRows,
            onError: errorResponseToDisplayHandler,
            enabled: isEnabled,
        }
    );
};

export const useGetRoles = (): UseQueryResult<RoleDto[], HTTPError> => {
    const url = useUrl();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(["getRoles"], () => getRoles(url.baseUrl, createPaginationRequest(1, 1000)), {
        select: transformToRoleDtos,
        onError: errorResponseToDisplayHandler,
        enabled: auth.isImpersonating,
    });
};

const transformToRoleDtos = (response: Response<PaginationResponse<RoleResponse>>): RoleDto[] =>
    toRoleDtos(response);

const transformToDataTableRows = (
    response: Response<PaginationResponse<UsersInRoleResponse>>
): PaginatedTableDto<UsersInRoleResponse> => {
    const rows = response.data.results!.map((user) => {
        return {
            metadata: user,
            columns: [
                {
                    value: user.userId.toString(),
                    type: DataTableColumnTypes.Text,
                },
                {
                    value: user.emailAddress,
                    type: DataTableColumnTypes.Text,
                },
                {
                    value: splitCamelCase(user.roleName),
                    type: DataTableColumnTypes.Text,
                },
            ],
        };
    });

    return {
        numberOfPages: response.data.pageCount,
        pageSize: response.data.pageSize,
        currentPage: response.data.currentPage,
        recordCount: response.data.recordCount,
        rows: rows,
    };
};
