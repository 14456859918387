import { AggregationTypeResponse } from "../../responses/aggregation-type/aggregation-type-response";

export interface AggregationTypeDto {
    aggregationTypeId: number;
    name: string;
}

export const createAggregationTypeDto = (
    aggregationTypeResponse: AggregationTypeResponse
): AggregationTypeDto => ({
    aggregationTypeId: aggregationTypeResponse.aggregationTypeId,
    name: aggregationTypeResponse.name,
});

export const toAggregationTypeDto = (data: AggregationTypeResponse[]): AggregationTypeDto[] =>
    data.map((aggregationType) => createAggregationTypeDto(aggregationType));
