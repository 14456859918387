import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ChecklistQuestions } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { isMutationLoading } from "../../../core/utilities/responseStateHelper";
import { useDeleteReferentialLinkType } from "../../../domain/viewmodels/referential-link-types/delete-referential-link-type-viewmodel";
import { DeleteButton } from "../../atoms/SbButton";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import { ActionConfirmationFragment } from "../../organisms/ActionConfirmationFragment";

interface ConfirmationDetailsProps {
    name: string;
}

const DeleteReferentialLinkTypeContainer: React.FC = () => {
    const menu = useMenu();
    const navigate = useNavigate();
    const location = useLocation();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const referentialLinkTypeId = Number(useParams().referentialLinkTypeId);
    const { name } = location.state as ConfirmationDetailsProps; // TODO: Fetching Checklist data on this page vs passing it through from the previous page

    const deleteReferentialLinkType = useDeleteReferentialLinkType();

    useLoader(isMutationLoading(deleteReferentialLinkType), DeleteReferentialLinkTypeContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.ReferentialLinkTypes);
    }, []);

    const handleDeleteReferentialLinkType = (referentialLinkTypeId: number): void => {
        deleteReferentialLinkType.mutate(referentialLinkTypeId, {
            onSuccess: async () => navigate(-1),
            onError: errorResponseToDisplayHandler,
        });
    };

    const buildDeleteButton = (): ReactElement<typeof Button> => (
        <DeleteButton onClick={() => handleDeleteReferentialLinkType(referentialLinkTypeId)} />
    );

    const buildConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow(t("Name"), name),
    ];

    return (
        <ActionConfirmationFragment
            pageHeading={t("DeleteReferentialLinkType")}
            panelTitle={t("DeleteReferentialLinkConfirmation")}
            rows={buildConfirmationRows()}
            primaryButton={buildDeleteButton()}
        />
    );
};

export default DeleteReferentialLinkTypeContainer;
