import {
    useMutation,
    UseMutationResult,
    useQueries,
    useQuery,
    UseQueryResult,
} from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useAuth } from "../../../core/store/auth-context";
import { useUrl } from "../../../core/store/url-context";
import { generateQuestionAnswerActionItem } from "../../../data/services/actions/action-items-service";
import {
    filterUserQuestions,
    getIndicatorQuestionAnswerFlag,
    getQuestionAnswerDetails,
    saveAnswers,
    submitAnswers,
} from "../../../data/services/answer-capture/answer-capture-service";
import { filterAvailableUserChecklists } from "../../../data/services/checklists/checklist-service";
import { filterUserNodes } from "../../../data/services/hierarchy/nodes-service";
import { createQuestionSetInstanceAnswerReview } from "../../../data/services/question-set-instance-answers/question-set-instance-answer-reviews-service";
import GenerateQuestionAnswerActionItemDto from "../../dtos/action-items/generate-question-answer-action-item-dto";
import BaseQuestionAnswerDto from "../../dtos/answer-capture/base-question-answer-dto";
import FilterQuestionAnswersDto from "../../dtos/answer-capture/filter-question-answers-dto";
import FilterUserQuestionsDto from "../../dtos/answer-capture/filter-user-questions-dto";
import IndicatorQuestionAnswerDto from "../../dtos/answer-capture/indicator-question-answer-dto";
import IndicatorQuestionAnswerFlagDto from "../../dtos/answer-capture/indicator-question-answer-flag-dto";
import QuestionAnswerDetailsDto from "../../dtos/answer-capture/question-answer-details-dto";
import BaseChecklistDto from "../../dtos/checklists/base-checklist-dto";
import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import {
    createReactSelectDropdownDto,
    createReactSelectDto,
    ReactSelectDropdownDto,
} from "../../dtos/common/react-select-dto";
import {
    createNodeDescriptionDetailsDto,
    NodeDescriptionDetailsDto,
} from "../../dtos/hierarchy/node-description-details-dto";
import CreateQuestionSetInstanceAnswerReviewDto from "../../dtos/question-set-instance-answers/create-question-set-instance-answer-review-dto";
import GenerateQuestionAnswerActionItemRequest from "../../requests/action-items/generate-question-answer-action-item-request";
import { createFilterUserQuestionsRequest } from "../../requests/answer-capture/filter-user-questions-request";
import IndicatorQuestionAnswerRequest from "../../requests/answer-capture/indicator-question-answer-request";
import QuestionAnswerRequest from "../../requests/answer-capture/question-answer-request";
import CreateQuestionSetInstanceAnswerReviewRequest from "../../requests/question-set-instance-answers/create-question-set-instance-answer-review-request";
import IndicatorQuestionAnswerFlagResponse from "../../responses/answer-capture/indicator-question-answer-flag-response";
import QuestionCaptureResultsResponse from "../../responses/answer-capture/question-capture-results-response";
import { BaseChecklistResponse } from "../../responses/checklists/base-checklist-response";
import { Response } from "../../responses/common/response-response";
import { NodeDescriptionDetailsResponse } from "../../responses/hierarchy/node-description-details-response";

export const useGetAnswerCaptureFilterData = (): [
    UseQueryResult<ReactSelectDropdownDto<NodeDescriptionDetailsDto>, HTTPError>,
    UseQueryResult<ReactSelectDropdownDto<BaseChecklistDto>, HTTPError>,
] => {
    const url = useUrl();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["filterNodesByUser", auth.userId],
                queryFn: () => filterUserNodes(url.baseUrl, auth.userId!), // TODO: Dependant on new work coming in (where this data will be stored)
                enabled: auth.userId !== null,
                select: toNodeReactSelectDropdownDto,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["filterAvailableChecklistsForUser", auth.userId],
                queryFn: () => filterAvailableUserChecklists(url.baseUrl, auth.userId!),
                enabled: auth.userId !== null,
                select: toChecklistReactSelectDropdownDto,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<ReactSelectDropdownDto<NodeDescriptionDetailsDto>, HTTPError>,
        UseQueryResult<ReactSelectDropdownDto<BaseChecklistDto>, HTTPError>,
    ];
};

export const useFilterUserQuestions = (
    paginationDto: BasePaginationDto,
    filterUserQuestionsDto: FilterUserQuestionsDto | null
): UseQueryResult<FilterQuestionAnswersDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterUserQuestions", filterUserQuestionsDto, paginationDto],
        () =>
            filterUserQuestions(
                url.baseUrl,
                createFilterUserQuestionsRequest(filterUserQuestionsDto!, paginationDto)
            ),
        {
            enabled: filterUserQuestionsDto != null,
            keepPreviousData: true,
            select: FilterQuestionAnswersDto.constructFromPaginatedQuestionCaptureSetResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useGetQuestionAnswerDetails = (
    questionSetInstanceAnswerId: number,
    requestedQuestionAnswerDetails: boolean
): UseQueryResult<QuestionAnswerDetailsDto> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getQuestionAnswerDetails", questionSetInstanceAnswerId],
        () => getQuestionAnswerDetails(url.baseUrl, questionSetInstanceAnswerId),
        {
            enabled: requestedQuestionAnswerDetails,
            select: QuestionAnswerDetailsDto.constructFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useGetIndicatorQuestionAnswerFlag = (
    dto: IndicatorQuestionAnswerDto | null
): UseQueryResult<IndicatorQuestionAnswerFlagDto> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getIndicatorQuestionAnswerFlag", dto],
        () =>
            getIndicatorQuestionAnswerFlag(
                url.baseUrl,
                IndicatorQuestionAnswerRequest.constructFromDto(dto!)
            ),
        {
            enabled:
                dto != null &&
                dto.questionId !== null &&
                (dto.textValue !== null ||
                    dto.intValue !== null ||
                    dto.decimalValue !== null ||
                    dto.boolValue !== null ||
                    dto.dateTimeValueLocal !== null),
            select: (response: Response<IndicatorQuestionAnswerFlagResponse>) =>
                new IndicatorQuestionAnswerFlagDto(response.data.indicatorQuestionAnswerFlag),
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useSubmitAnswers = (): UseMutationResult<
    Response<QuestionCaptureResultsResponse>,
    HTTPError,
    BaseQuestionAnswerDto[]
> => {
    const url = useUrl();

    return useMutation((mutationKey: BaseQuestionAnswerDto[]) => {
        const request = QuestionAnswerRequest.constructFromFlattenedDtos(mutationKey);

        return submitAnswers(url.baseUrl, request);
    });
};

export const useSaveAnswers = (): UseMutationResult<
    Response<QuestionCaptureResultsResponse>,
    HTTPError,
    BaseQuestionAnswerDto[]
> => {
    const url = useUrl();

    return useMutation((mutationKey: BaseQuestionAnswerDto[]) => {
        const request = QuestionAnswerRequest.constructFromFlattenedDtos(mutationKey);

        return saveAnswers(url.baseUrl, request);
    });
};

export const useCreateQuestionSetInstanceAnswerReview = (): UseMutationResult<
    Response<number | null>,
    HTTPError,
    CreateQuestionSetInstanceAnswerReviewDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: CreateQuestionSetInstanceAnswerReviewDto) => {
        const request = CreateQuestionSetInstanceAnswerReviewRequest.constructFromDto(mutationKey);

        return createQuestionSetInstanceAnswerReview(url.baseUrl, request);
    });
};

export const useGenerateQuestionAnswerActionItem = (): UseMutationResult<
    Response<number | null>,
    HTTPError,
    GenerateQuestionAnswerActionItemDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: GenerateQuestionAnswerActionItemDto) => {
        const request = GenerateQuestionAnswerActionItemRequest.constructFromDto(mutationKey);

        return generateQuestionAnswerActionItem(url.baseUrl, request);
    });
};

const toNodeReactSelectDropdownDto = (
    response: Response<NodeDescriptionDetailsResponse[]>
): ReactSelectDropdownDto<NodeDescriptionDetailsDto> => {
    const reactSelectDtos = response.data.map((x) => {
        const nodeDescriptionDetailsDto = createNodeDescriptionDetailsDto(x);

        return createReactSelectDto(
            nodeDescriptionDetailsDto,
            nodeDescriptionDetailsDto.shortDescriptionWithParent
        );
    });

    return createReactSelectDropdownDto(reactSelectDtos);
};

const toChecklistReactSelectDropdownDto = (
    response: Response<BaseChecklistResponse[]>
): ReactSelectDropdownDto<BaseChecklistDto> => {
    const reactSelectDtos = response.data.map((x) => {
        const baseChecklistDto = new BaseChecklistDto(x);

        return createReactSelectDto(baseChecklistDto, baseChecklistDto.name);
    });

    return createReactSelectDropdownDto(reactSelectDtos);
};
