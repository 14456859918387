export const enum HierarchyMenuType {
    NodeActions = 1 << 0,
    Details = 1 << 1,
    Edit = 1 << 2,
    Select = 1 << 3,
    ViewUsers = 1 << 4,
    All = ~(~0 << 5),
}

export const hasFlag = (menuTypeToCheck: HierarchyMenuType, menuType: HierarchyMenuType): boolean =>
    (menuTypeToCheck & menuType) === menuType;
