import { HTTPError } from "ky";
import { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EditNestedQuestionTriggerEvent } from "../../../core/constants/application-insights-events";
import { ChecklistQuestions, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isMutationLoading,
} from "../../../core/utilities/responseStateHelper";
import { EditNestedQuestionTriggerSelectionDto } from "../../../domain/dtos/questions/edit-nested-question-trigger-selection-dto";
import QuestionImportance from "../../../domain/enums/questions/question-importance";
import { Response } from "../../../domain/responses/common/response-response";
import {
    useEditTriggerSelectionForNestedQuestion,
    useGetNestedQuestionDetailsById,
} from "../../../domain/viewmodels/questions/edit-nested-question-trigger-selection-viewmodel";
import SbCheckField from "../../atoms/input/SbCheckField";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import { SbLink } from "../../atoms/SbLink";
import { CreateLabelToDetailRows } from "../../molecules/CreateLabelToDetailRows";
import { ConfirmationRow, TextConfirmationRow } from "../../organisms/ActionConfirmation";

const EditNestedQuestionTriggerSelectionContainer: React.FC = () => {
    const [urlSearchParams] = useSearchParams();
    const nestedQuestionId = Number(urlSearchParams.get("nestedQuestionId"));

    const menu = useMenu();
    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const getNestedQuestionDetails = useGetNestedQuestionDetailsById(nestedQuestionId);
    const nestedQuestionDetails = getNestedQuestionDetails[0];
    const questionImportance = getNestedQuestionDetails[1];
    const editTriggerWhenQuestionDesirable = useEditTriggerSelectionForNestedQuestion();

    useLoader(
        areQueriesLoading([...getNestedQuestionDetails]) ||
            isMutationLoading(editTriggerWhenQuestionDesirable),
        EditNestedQuestionTriggerSelectionContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Questions);
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const triggerWhenQuestionDesirable =
            formData.get("triggerWhenQuestionDesirableCheckBox") === "on";

        editTriggerWhenQuestionDesirable.mutate(
            new EditNestedQuestionTriggerSelectionDto(
                nestedQuestionId,
                triggerWhenQuestionDesirable
            ),
            {
                onSuccess: async (_: Response<boolean>) => {
                    trackAppInsightsEvent(
                        auth.email,
                        window.location.href,
                        EditNestedQuestionTriggerEvent
                    );
                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "messageKey",
                            "SuccessfullyUpdatedParentQuestionAnswerTrigger"
                        ),
                    ];

                    navigateSearch(
                        `${getPath(AccordionTitles.Questions)}/${
                            nestedQuestionDetails.data!.parentQuestionDto.questionId
                        }`,
                        params
                    );
                },
                onError: (error: HTTPError) => {
                    trackAppInsightsException(
                        auth.email,
                        window.location.href,
                        EditNestedQuestionTriggerEvent,
                        error
                    );
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    };

    const parentQuestionConfirmationRows = (): ConfirmationRow<any>[] => [
        new TextConfirmationRow(
            t("Type"),
            nestedQuestionDetails.data!.parentQuestionDto!.questionType.name || "-"
        ),
        new TextConfirmationRow(
            t("QuestionText"),
            nestedQuestionDetails.data!.parentQuestionDto!.questionText.text || "-"
        ),
        new TextConfirmationRow(
            t("Importance"),
            QuestionImportance[nestedQuestionDetails.data!.parentQuestionDto!.questionImportance] ||
                "-"
        ),
        new TextConfirmationRow(
            t("Category"),
            nestedQuestionDetails.data!.parentQuestionDto!.questionCategory.name || "-"
        ),
        new TextConfirmationRow(
            t("AnswerType"),
            nestedQuestionDetails.data!.parentQuestionDto!.answerTypeDto.name || "-"
        ),
        new TextConfirmationRow(
            t("IsAggregate"),
            nestedQuestionDetails.data!.parentQuestionDto!.isAggregate.toString() || "-"
        ),
    ];

    return (
        <>
            {areQueriesSuccessful(getNestedQuestionDetails) && (
                <>
                    <PageHeading>{t("EditNestedQuestionTrigger")}</PageHeading>
                    <PageSubHeading>{t("HeaderHelpTextNestedQuestionDetails")}</PageSubHeading>
                    <SectionVerticalSpace />
                    <ContentContainer>
                        <Form onSubmit={handleSubmit}>
                            <PageHeading>{t("Question")}</PageHeading>

                            <SectionVerticalSpace />

                            {CreateLabelToDetailRows(parentQuestionConfirmationRows(), 2, 10)}

                            <SectionVerticalSpace />

                            <PageHeading>{t("ChildQuestion")}</PageHeading>

                            <SectionVerticalSpace />

                            <Row>
                                <Col md="2">
                                    <DetailsLabel>{t("Type", { keyPrefix: Common })}</DetailsLabel>
                                    <DetailsLabel>{t("QuestionText")}</DetailsLabel>
                                    <DetailsLabel>{t("Importance")}</DetailsLabel>
                                    <DetailsLabel>
                                        {t("Category", { keyPrefix: Common })}
                                    </DetailsLabel>
                                    <DetailsLabel>{t("AnswerType")}</DetailsLabel>
                                    <DetailsLabel>{t("IsAggregate")}</DetailsLabel>
                                    <DetailsLabel>{t("TriggerWhenQuestionDesirable")}</DetailsLabel>
                                </Col>
                                <Col md="auto">
                                    <DetailsValue>
                                        {nestedQuestionDetails.data!.questionType.name}
                                    </DetailsValue>
                                    <DetailsValue>
                                        {nestedQuestionDetails.data!.questionText.text}
                                    </DetailsValue>
                                    <DetailsValue>
                                        {
                                            questionImportance.data!.find(
                                                (x) =>
                                                    x.questionImportanceId ===
                                                    nestedQuestionDetails.data!.questionImportance
                                            )!.name
                                        }
                                    </DetailsValue>
                                    <DetailsValue>
                                        <SbLink
                                            variant={"primary"}
                                            label={
                                                nestedQuestionDetails.data!.questionCategory.name
                                            }
                                            navigateTo={`${getPath(
                                                AccordionTitles.QuestionCategories
                                            )}/${nestedQuestionDetails.data!.questionCategoryId}`}
                                            navigationProps={{
                                                state: {
                                                    name: nestedQuestionDetails.data!
                                                        .questionCategory.name,
                                                },
                                            }}
                                        />
                                    </DetailsValue>
                                    <DetailsValue>
                                        {nestedQuestionDetails.data!.answerTypeDto.name}
                                    </DetailsValue>
                                    <DetailsValue>
                                        {nestedQuestionDetails.data!.isAggregate
                                            ? `${t("True", { keyPrefix: Common })}`
                                            : `${t("False", { keyPrefix: Common })}`}
                                    </DetailsValue>
                                    <DetailsValue>
                                        <SbCheckField
                                            type="checkbox"
                                            label=""
                                            name="triggerWhenQuestionDesirableCheckBox"
                                            defaultSelected={
                                                nestedQuestionDetails.data!
                                                    .triggerWhenParentDesirable
                                            }
                                            key="triggerWhenQuestionDesirableCheckBox"
                                        />
                                    </DetailsValue>
                                </Col>
                            </Row>
                            <EndAlignedDiv>
                                <SaveButton type="submit" />
                                <CancelButton onClick={() => navigate(-1)} />
                            </EndAlignedDiv>
                        </Form>
                    </ContentContainer>
                </>
            )}
        </>
    );
};

export default EditNestedQuestionTriggerSelectionContainer;
