import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { dissociateQuestionSetQuestion } from "../../../data/services/question-set/question-set-service";
import { getQuestionDetails } from "../../../data/services/questions/question-service";
import { DissociateQuestionSetQuestionDto } from "../../dtos/question-sets/dissociate-question-set-question-dto";
import {
    createQuestionDetailsDto,
    QuestionDetailsDto,
} from "../../dtos/questions/question-details-dto";
import { createDissociateQuestionSetQuestionRequest } from "../../requests/question-set/dissociate-question-set-question-request";
import { Response } from "../../responses/common/response-response";

export const useDissociateQuestionSetQuestion = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    DissociateQuestionSetQuestionDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: DissociateQuestionSetQuestionDto) =>
        dissociateQuestionSetQuestion(
            url.baseUrl,
            createDissociateQuestionSetQuestionRequest(mutationKey.questionSetQuestions)
        )
    );
};

export const useGetQuestionDetails = (
    questionId: number
): UseQueryResult<QuestionDetailsDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getQuestionDetails", questionId],
        () => getQuestionDetails(url.baseUrl, questionId),
        {
            select: createQuestionDetailsDto,
            onError: errorResponseToDisplayHandler,
        }
    );
};
