import { Response } from "../../../domain/responses/common/response-response";
import { FeatureResponse } from "../../../domain/responses/tenants/feature-response";
import client from "../../http-client";

const route = "features";

export const getFeatures = async (prefixUrl: string): Promise<Response<FeatureResponse[]>> => {
    return await client
        .get(`${route}`, {
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getUserFeatures = async (prefixUrl: string): Promise<Response<FeatureResponse[]>> => {
    return await client
        .get(`${route}/user-features`, {
            prefixUrl: prefixUrl,
        })
        .json();
};
