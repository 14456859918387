import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { HierarchyAssignableFlag } from "../../../core/utilities/enums";
import { filterHierarchyTypes } from "../../../data/services/hierarchy/hierarchy-types-service";
import { HierarchyTypeDto, toHierarchyTypeDtos } from "../../dtos/hierarchy/hierarchy-type-dto";
import { createBasePaginationRequest } from "../../requests/common/base-pagination-request";
import { createFilterHierarchyTypesRequest } from "../../requests/hierarchy/filter-hierarchy-types-request";
import { emptySearchHierarchyTypesRequest } from "../../requests/hierarchy/search-hierarchy-types-request";

export const useGetHierarchyTypesByHierarchyAssignableFlag = (
    hierarchyAssignableFlag: HierarchyAssignableFlag
): UseQueryResult<HierarchyTypeDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterHierarchyTypes", hierarchyAssignableFlag],
        () =>
            filterHierarchyTypes(
                url.baseUrl,
                createFilterHierarchyTypesRequest(createBasePaginationRequest(1, 1000), {
                    ...emptySearchHierarchyTypesRequest(),
                    hierarchyAssignableFlag,
                })
            ),
        {
            select: toHierarchyTypeDtos,
            onError: errorResponseToDisplayHandler,
        }
    );
};
