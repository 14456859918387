export interface SearchWizardNotificationHistoryRequest {
    userId: number;
}

const SearchParameters = {
    userId: "userId",
};

export const buildWizardNotificationHistorySearchParameters = (
    request: SearchWizardNotificationHistoryRequest,
    searchParams: URLSearchParams
): URLSearchParams => {
    if (request.userId) {
        searchParams.set(SearchParameters.userId.toString(), request.userId.toString());
    }

    return searchParams;
};
