export interface FilterHierarchyImportDto {
    fileName: string | null;
    dateTimeFrom: Date | null;
    dateTimeTo: Date | null;
    createdBy: string | null;
    sortByColumn: string | null;
    sortByDescending: boolean;
}

export const createFilterHierarchyImportDto = (
    fileName: string | null,
    dateTimeFrom: Date | null,
    dateTimeTo: Date | null,
    createdBy: string | null,
    sortByColumn: string | null,
    sortByDescending: boolean
): FilterHierarchyImportDto => ({
    fileName: fileName,
    dateTimeFrom: dateTimeFrom,
    dateTimeTo: dateTimeTo,
    createdBy: createdBy,
    sortByColumn: sortByColumn,
    sortByDescending: sortByDescending,
});

export const defaultFilterHierarchyImportDto = (): FilterHierarchyImportDto =>
    createFilterHierarchyImportDto(null, null, null, null, null, false);
