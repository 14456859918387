import { AssociateDisassociateNodeTypeDto } from "../../dtos/hierarchy/associate-disassociate-node-type-dto";

export interface AssociateDisassociateNodeTypeRequest {
    parentNodeTypeId: number;
    childNodeTypeId: number;
}

export const createAssociateDisassociateNodeTypeRequest = (
    dto: AssociateDisassociateNodeTypeDto
): AssociateDisassociateNodeTypeRequest => ({
    parentNodeTypeId: dto.parentNodeTypeId,
    childNodeTypeId: dto.childNodeTypeId,
});
