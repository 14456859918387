import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { ActionItemImportance } from "../../enums/action-items/ActionItemImportance";
import { ActionItemDetailsResponse } from "../../responses/actions/action-item-details-response";
import { Response } from "../../responses/common/response-response";
import {
    ActionItemTypeDto,
    createActionItemTypeDtoFromResponse,
} from "../action-items/action-item-type-dto";
import { FileDto } from "../file-storage/file-dto";
import { ActionInstanceDetailsDto } from "./action-instance-details-dto";

export class ActionItemDetailsDto {
    actionItemId: number;
    name: string;
    description: string;
    actionItemImportance: ActionItemImportance;
    requiredOutcome: string;
    includeInWeeklyReport: boolean;
    includeInMonthlyReport: boolean;
    isPersonalActionItem: boolean;
    raciSetId: number;
    actionItemTypeNodeId: number;
    actionItemSubTypeNodeId: number;
    externalSystemName: string;
    externalSystemLinkUrl: string;
    externalSystemReferenceRelationshipName: string;
    externalSystemReferenceId: string;
    createdByUserFullName: string;
    createdByUserName: string;
    dateCreatedLocal: string;
    modifiedByUserFullName: string;
    modifiedByUserName: string;
    dateModifiedLocal: string;
    actionInstanceDetailsDto: ActionInstanceDetailsDto;
    actionItemTypeDto: ActionItemTypeDto;
    actionItemSubTypeDto: ActionItemTypeDto;
    questionSetInstanceAnswerFileDtos: FileDto[];

    public constructor(response: ActionItemDetailsResponse) {
        const {
            actionItemId,
            name,
            description,
            actionItemImportance,
            requiredOutcome,
            includeInWeeklyReport,
            includeInMonthlyReport,
            isPersonalActionItem,
            raciSetId,
            actionItemTypeNodeId,
            actionItemSubTypeNodeId,
            externalSystemName,
            externalSystemLinkUrl,
            externalSystemReferenceRelationshipName,
            externalSystemReferenceId,
            createdByUserFullName,
            createdByUserName,
            dateCreatedUtc,
            modifiedByUserFullName,
            modifiedByUserName,
            dateModifiedUtc,
            actionItemInstanceResponse,
            actionItemTypeResponse,
            actionItemSubTypeResponse,
            questionSetInstanceAnswerFileDetailsResponses,
        } = response;

        this.actionItemId = actionItemId;
        this.name = name;
        this.description = description;
        this.actionItemImportance = actionItemImportance;
        this.requiredOutcome = requiredOutcome;
        this.includeInWeeklyReport = includeInWeeklyReport;
        this.includeInMonthlyReport = includeInMonthlyReport;
        this.isPersonalActionItem = isPersonalActionItem;
        this.raciSetId = raciSetId;
        this.actionItemTypeNodeId = actionItemTypeNodeId;
        this.actionItemSubTypeNodeId = actionItemSubTypeNodeId;
        this.externalSystemName = externalSystemName;
        this.externalSystemLinkUrl = externalSystemLinkUrl;
        this.externalSystemReferenceRelationshipName = externalSystemReferenceRelationshipName;
        this.externalSystemReferenceId = externalSystemReferenceId;
        this.createdByUserFullName = createdByUserFullName;
        this.createdByUserName = createdByUserName;
        this.dateCreatedLocal = toDateAndTimeFormat(dateCreatedUtc.toString());
        this.modifiedByUserFullName = modifiedByUserFullName;
        this.modifiedByUserName = modifiedByUserName;
        this.dateModifiedLocal = toDateAndTimeFormat(dateModifiedUtc.toString());
        this.actionInstanceDetailsDto = new ActionInstanceDetailsDto(actionItemInstanceResponse);
        this.actionItemTypeDto = createActionItemTypeDtoFromResponse(actionItemTypeResponse);
        this.actionItemSubTypeDto = createActionItemTypeDtoFromResponse(actionItemSubTypeResponse);
        this.questionSetInstanceAnswerFileDtos = FileDto.constructFromBaseFileDetailsResponses(
            questionSetInstanceAnswerFileDetailsResponses
        );
    }

    public static constructFromResponse = (
        response: Response<ActionItemDetailsResponse>
    ): ActionItemDetailsDto => new ActionItemDetailsDto(response.data);
}
