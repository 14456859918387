import { PaginationDto } from "../../dtos/common/pagination-dto";
import {
    BasePaginationRequest,
    buildBasePaginationSearchParameters,
    createBasePaginationRequest,
} from "./base-pagination-request";

export class PaginationRequest extends BasePaginationRequest {
    sortByColumn: string | null;
    sortByDescending: boolean;

    public constructor(paginationDto: PaginationDto) {
        super(paginationDto.pageNumber, paginationDto.pageSize);

        this.sortByColumn = paginationDto.sortByColumn;
        this.sortByDescending = paginationDto.sortByDescending;
    }
}

export const createPaginationRequest = (
    pageNumber: number = 1,
    pageSize: number = 10,
    sortByColumn: string | null = null,
    sortByDescending: boolean = false
): PaginationRequest => ({
    ...createBasePaginationRequest(pageNumber, pageSize),
    sortByColumn,
    sortByDescending,
});

export const createPaginationRequestFromDto = (
    paginationDto: PaginationDto
): PaginationRequest => ({
    ...paginationDto,
});

const PaginationParameters = {
    sortByColumn: "sortByColumn",
    sortByDescending: "sortByDescending",
    pageNumber: "pageNumber",
    pageSize: "pageSize",
};

export const buildPaginationSearchParameters = (request: PaginationRequest): URLSearchParams => {
    const searchParams = buildBasePaginationSearchParameters(request);

    if (request.sortByColumn) {
        searchParams.set(PaginationParameters.sortByColumn, request.sortByColumn);
    }

    searchParams.set(PaginationParameters.sortByDescending, request.sortByDescending.toString());

    return searchParams;
};
