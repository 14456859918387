import { useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { DataTableColumnTypes, DrawerTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { filterActionItemDynamicFields } from "../../../data/services/action-items/action-item-dynamic-fields-service";
import { getActionItemTypes } from "../../../data/services/action-items/action-item-types-service";
import {
    ActionItemTypeDto,
    toActionItemTypeDtos,
} from "../../dtos/action-items/action-item-type-dto";
import { FilterActionItemDynamicFieldsDto } from "../../dtos/action-items/filter-action-item-dynamic-fields-dto";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import AnswerValueType from "../../enums/answer-types/answer-value-type";
import { createFilterActionItemDynamicFieldsRequestFromDto } from "../../requests/action-items/filter-action-item-dynamic-fields-request";
import { ActionItemDynamicFieldDetailsResponse } from "../../responses/action-items/action-item-dynamic-field-details-response";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";

export const useGetActionItemDynamicFieldData = (
    dto: FilterActionItemDynamicFieldsDto
): [
    UseQueryResult<ActionItemTypeDto[], HTTPError>,
    UseQueryResult<PaginatedTableDto<number>, HTTPError>,
] => {
    const url = useUrl();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getActionItemTypes"],
                queryFn: () => getActionItemTypes(url.baseUrl),
                select: toActionItemTypeDtos,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["filterActionItemDynamicFields", dto],
                queryFn: () =>
                    filterActionItemDynamicFields(
                        url.baseUrl,
                        createFilterActionItemDynamicFieldsRequestFromDto(dto)
                    ),
                keepPreviousData: true,
                select: (
                    response: Response<PaginationResponse<ActionItemDynamicFieldDetailsResponse>>
                ) => toDataTableRows(response.data, navigate),
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<ActionItemTypeDto[], HTTPError>,
        UseQueryResult<PaginatedTableDto<number>, HTTPError>,
    ];
};

const toDataTableRows = (
    response: PaginationResponse<ActionItemDynamicFieldDetailsResponse>,
    navigate: NavigateFunction
): PaginatedTableDto<number> => {
    const { pageCount, pageSize, currentPage, recordCount } = response;

    const rows = response.results!.map((x) => ({
        metadata: x.actionItemDynamicFieldId,
        columns: [
            {
                value: x.orderIndex.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: x.fieldName,
                type: DataTableColumnTypes.Link,
                linkItemAction: (actionItemDynamicFieldId: number) => {
                    navigate(
                        `${getPath(
                            DrawerTitles.ActionItemDynamicFields
                        )}/${actionItemDynamicFieldId}`
                    );
                },
            },
            {
                value: x.actionItemTypeResponse.value.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: AnswerValueType[x.valueType].toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: x.isRequired.toString(),
                type: DataTableColumnTypes.Check,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
