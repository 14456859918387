export interface FilterAnswerSummarySearchRequest {
    isTraining: boolean | null;
}

export const createFilterAnswerSummarySearchRequest = (
    isTraining: boolean | null
): FilterAnswerSummarySearchRequest => ({
    isTraining: isTraining,
});

export const buildAnswerSummarySearchParameters = (
    request: FilterAnswerSummarySearchRequest
): URLSearchParams => {
    const searchParams = new URLSearchParams();

    searchParams.set("isTraining", request.isTraining?.toString() ?? "");

    return searchParams;
};
