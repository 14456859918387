import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    filterAssociatedRaciSetUsers,
    markCommentsAsRead,
} from "../../../data/services/actions/action-items-service";
import { ActionItemRaciSetUserDto } from "../../dtos/actions/action-item-raci-set-user-dto";
import { createFilterAssociatedRaciSetUsersRequest } from "../../requests/actions/filter-associated-raci-set-users-request";
import { createBasePaginationRequest } from "../../requests/common/base-pagination-request";
import { Response } from "../../responses/common/response-response";

export const useFilterAssociatedRaciSetUsers = (
    actionItemId: number
): UseQueryResult<ActionItemRaciSetUserDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterAssociatedRaciSetUsers", actionItemId],
        () =>
            filterAssociatedRaciSetUsers(
                url.baseUrl,
                createFilterAssociatedRaciSetUsersRequest(
                    createBasePaginationRequest(1, 1000),
                    actionItemId
                )
            ),
        {
            select: ActionItemRaciSetUserDto.constructFromPaginatedResponse,
            onError: errorResponseToDisplayHandler,
            enabled: actionItemId != null,
        }
    );
};

export const useMarkCommentsAsRead = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    number
> => {
    const url = useUrl();

    return useMutation((mutationKey: number) => markCommentsAsRead(url.baseUrl, mutationKey));
};
