import { PlaceOnHoldActionItemDto } from "../../dtos/action-items/place-on-hold-action-item-dto";

export class PlaceOnHoldActionItemRequest {
    actionItemInstanceId: number;
    comment: string;

    public constructor(dto: PlaceOnHoldActionItemDto) {
        this.actionItemInstanceId = dto.actionItemInstanceId;
        this.comment = dto.comment;
    }
}
