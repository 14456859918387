import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    EndAlignedDiv,
    NoDataStateDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isMutationLoading, isQueryLoading } from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import {
    BasePaginationDto,
    defaultBasePaginationDto,
} from "../../../domain/dtos/common/base-pagination-dto";
import { useSoftDeleteImage } from "../../../domain/viewmodels/image-library/delete-image-library-viewmodel";
import { useFilterActiveImages } from "../../../domain/viewmodels/image-library/view-image-library-viewmodel";
import Paginator from "../../atoms/Paginator";
import { CreateLink } from "../../atoms/SbLink";
import { CardGallery } from "../../organisms/CardGallery";

const ImageLibraryContainer: React.FC = () => {
    const menu = useMenu();
    const toast = useToast();
    const { t } = useTranslation("translation", { keyPrefix: Common });
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const [paginationDto, setPaginationDto] = useState<BasePaginationDto>(defaultBasePaginationDto);

    const getImageLibraryImages = useFilterActiveImages(paginationDto);
    const softDeleteImage = useSoftDeleteImage();

    const imageLibraryImagesData = getImageLibraryImages.data;
    const rows = imageLibraryImagesData?.results;
    const recordCount = imageLibraryImagesData?.recordCount;

    useLoader(
        isQueryLoading(getImageLibraryImages) || isMutationLoading(softDeleteImage),
        ImageLibraryContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.WizardCards, AccordionTitles.ImageLibrary);
    }, []);

    const deleteImage = (fileDetailsId: number): void => {
        softDeleteImage.mutate(fileDetailsId, {
            onSuccess: async () => {
                toast.addToast(createSuccessToastProps([t("SuccessfullyDeletedImage")]));
                queryClient.invalidateQueries(["getImageLibrary"]);
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    return (
        <>
            <PageHeading>{t("ImagesLibrary")}</PageHeading>
            <SectionVerticalSpace />
            <EndAlignedDiv>
                <CreateLink
                    label={t("Upload")}
                    navigateTo={`${getPath(AccordionTitles.ImageLibrary)}/upload`}
                />
            </EndAlignedDiv>
            <SectionVerticalSpace />
            {recordCount ? (
                <>
                    <CardGallery rows={rows} deleteImage={deleteImage} />
                    <EndAlignedDiv>
                        <Paginator
                            totalItems={recordCount}
                            paginationDto={paginationDto}
                            setPaginationProps={setPaginationDto}
                        />
                    </EndAlignedDiv>
                </>
            ) : (
                <NoDataStateDiv>{"No results found"}</NoDataStateDiv>
            )}
            <SectionVerticalSpace />
        </>
    );
};

export default ImageLibraryContainer;
