import { useEffect, useRef } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { ActionItems, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    maxContentWidthSelectStyle,
    PageHeading,
    SectionVerticalSpace,
    StyledFormLabel,
} from "../../../core/theme/global-styles";
import { getEnumsForType } from "../../../core/utilities/enum-helper";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import { createReactSelectDto, ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import { EditNotificationTriggerDto } from "../../../domain/dtos/notification-triggers/edit-notification-trigger-dto";
import { TriggeringDate } from "../../../domain/enums/notification-triggers/triggering-date";
import { PeriodType } from "../../../domain/enums/PeriodType";
import { Response } from "../../../domain/responses/common/response-response";
import {
    useEditNotificationTrigger,
    useGetNotificationTriggerDetails,
} from "../../../domain/viewmodels/notification-triggers/edit-notification-trigger-details-viewmodel";
import SbNumberBox from "../../atoms/input/SbNumberBox";
import { SbSelect } from "../../atoms/input/SbSelect";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import SbLabelText from "../../atoms/SbLabelText";
import { translateText } from "../../helpers/translate";
import SbFormCheckFieldGroup from "../../molecules/input/SbFormCheckFieldGroup";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const periodTypeSelectOptions: ReactSelectDto<PeriodType>[] = getEnumsForType(PeriodType).map((x) =>
    createReactSelectDto(x, PeriodType[x])
);

const EditNotificationTriggerContainer: React.FC = () => {
    const notificationTriggerId = Number(useParams().notificationTriggerId);

    const triggerDate = useRef<TriggeringDate | null>(null);
    const triggerTimeDeltaIntervalPeriodType = useRef<number | null>(null);
    const sendWindowIntervalPeriodType = useRef<number | null>(null);

    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });

    const getNotificationTriggerDetails = useGetNotificationTriggerDetails(notificationTriggerId);
    const editNotificationTrigger = useEditNotificationTrigger();

    const notificationTriggerDetailsData = getNotificationTriggerDetails.data;
    const getPeriodTypeOptionLabel = (option: ReactSelectDto<PeriodType>): string =>
        translateText(t, option.label, Common);

    useLoader(
        isQueryLoading(getNotificationTriggerDetails) || isMutationLoading(editNotificationTrigger),
        EditNotificationTriggerContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.ActionTracker);
        menu.changeActiveDrawerItem(DrawerTitles.NotificationTriggers);
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        editNotificationTrigger.mutate(
            new EditNotificationTriggerDto(
                notificationTriggerId,
                triggerDate.current!,
                triggerTimeDeltaIntervalPeriodType.current!,
                sendWindowIntervalPeriodType.current!,
                new FormData(event.currentTarget)
            ),
            {
                onSuccess: async (_: Response<boolean>) => {
                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "messageKey",
                            "SuccessfullyEditedNotificationTrigger"
                        ),
                    ];

                    navigateSearch(
                        `${getPath(DrawerTitles.NotificationTriggers)}/${notificationTriggerId}`,
                        params
                    );
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    const setDefaultTriggeringDate = (): ReactSelectDto<TriggeringDate> => {
        const triggeringDate = notificationTriggerDetailsData!.triggeringDate;

        if (!triggerDate.current) {
            triggerDate.current = triggeringDate;
        }

        return createReactSelectDto(triggeringDate, TriggeringDate[triggeringDate]);
    };

    const setDefaultTriggerTimeDeltaIntervalPeriodType = (): ReactSelectDto<PeriodType> => {
        const periodType = notificationTriggerDetailsData!.triggerTimeDeltaIntervalDto.periodType;

        if (!triggerTimeDeltaIntervalPeriodType.current) {
            triggerTimeDeltaIntervalPeriodType.current = periodType;
        }

        return createReactSelectDto(periodType, PeriodType[periodType]);
    };

    const setDefaultSendWindowIntervalPeriodType = (): ReactSelectDto<PeriodType> => {
        const periodType = notificationTriggerDetailsData!.sendWindowIntervalDto.periodType;

        if (!sendWindowIntervalPeriodType.current) {
            sendWindowIntervalPeriodType.current = periodType;
        }

        return createReactSelectDto(periodType, PeriodType[periodType]);
    };

    const onTriggerTimeDeltaIntervalPeriodTypeChanged = (
        option: SingleValue<ReactSelectDto<PeriodType>>
    ): void => {
        triggerTimeDeltaIntervalPeriodType.current = option!.value;
    };

    const onSendWindowIntervalPeriodTypeChanged = (
        option: SingleValue<ReactSelectDto<PeriodType>>
    ): void => {
        sendWindowIntervalPeriodType.current = option!.value;
    };

    return (
        <>
            <PageHeading>{t("EditNotificationTrigger")}</PageHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(getNotificationTriggerDetails) && (
                <TextTitledPanel title={t("Edit", { keyPrefix: Common })}>
                    <Form onSubmit={handleSubmit}>
                        <SbFormTextFieldGroup
                            name={"name"}
                            label={t("Name", { keyPrefix: Common })}
                            type={"text"}
                            maxLength={250}
                            defaultValue={notificationTriggerDetailsData!.name}
                            required
                        />

                        <SbFormCheckFieldGroup
                            fieldLabel={t("ShouldTriggerBefore")}
                            type="checkbox"
                            values={[
                                {
                                    name: "triggerBefore",
                                    defaultSelected: notificationTriggerDetailsData!.triggerBefore,
                                },
                            ]}
                        />

                        <SbFormSelectFieldGroup
                            name={"triggerDate"}
                            label={t("TriggerDate")}
                            searchable
                            clearable={false}
                            required
                            items={getEnumsForType(TriggeringDate).map((x) =>
                                createReactSelectDto(x, TriggeringDate[x])
                            )}
                            itemDisplayText={(option: ReactSelectDto<TriggeringDate>) =>
                                translateText(t, option.label, Common)
                            }
                            defaultSelectedItem={setDefaultTriggeringDate()}
                            onChange={(option: SingleValue<ReactSelectDto<TriggeringDate>>) => {
                                triggerDate.current = option!.value;
                            }}
                        />

                        <Form.Group as={Row} className="mb-3">
                            <StyledFormLabel column sm={2}>
                                <SbLabelText label={t("TriggerTimeInterval")} required />
                            </StyledFormLabel>
                            <Col sm={2}>
                                <SbNumberBox
                                    name={"triggerTimeDeltaIntervalValue"}
                                    defaultValue={
                                        notificationTriggerDetailsData!.triggerTimeDeltaIntervalDto
                                            .value
                                    }
                                />
                            </Col>
                            <Col sm={2}>
                                <SbSelect<ReactSelectDto<PeriodType>>
                                    name={"triggerTimeDeltaIntervalPeriodType"}
                                    searchable
                                    styles={maxContentWidthSelectStyle}
                                    clearable={false}
                                    items={periodTypeSelectOptions}
                                    itemLabel={getPeriodTypeOptionLabel}
                                    defaultSelectedItem={setDefaultTriggerTimeDeltaIntervalPeriodType()}
                                    onChange={onTriggerTimeDeltaIntervalPeriodTypeChanged}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <StyledFormLabel column sm={2}>
                                <SbLabelText label={t("SendWindowInterval")} required />
                            </StyledFormLabel>
                            <Col sm={2}>
                                <SbNumberBox
                                    name={"sendWindowIntervalValue"}
                                    defaultValue={
                                        notificationTriggerDetailsData!.sendWindowIntervalDto.value
                                    }
                                />
                            </Col>
                            <Col sm={2}>
                                <SbSelect<ReactSelectDto<PeriodType>>
                                    name={"sendWindowIntervalPeriodType"}
                                    searchable
                                    styles={maxContentWidthSelectStyle}
                                    clearable={false}
                                    items={periodTypeSelectOptions}
                                    itemLabel={getPeriodTypeOptionLabel}
                                    defaultSelectedItem={setDefaultSendWindowIntervalPeriodType()}
                                    onChange={onSendWindowIntervalPeriodTypeChanged}
                                />
                            </Col>
                        </Form.Group>

                        <EndAlignedDiv>
                            <SaveButton type="submit" />
                            <CancelButton onClick={() => navigate(-1)} />
                        </EndAlignedDiv>
                    </Form>
                </TextTitledPanel>
            )}
        </>
    );
};

export default EditNotificationTriggerContainer;
