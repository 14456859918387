import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { jwtDecode } from "jwt-decode";
import { HTTPError } from "ky";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../core/store/auth-context";
import { useUrl } from "../../../core/store/url-context";
import { convertBooleanStringToBoolean } from "../../../core/utilities/convertToBoolean";
import {
    createAadRefreshToken,
    createRefreshToken,
    login,
    loginAad,
    refreshTokens,
} from "../../../data/services/account/authentication-service";
import { AadAuthenticationRequestDto } from "../../dtos/auth/aad-authentication-request-dto";
import { AuthenticationRequestDto } from "../../dtos/auth/authentication-request-dto";
import { transformToRoleEnums } from "../../enums/Roles";
import { AadAuthenticationRequest } from "../../requests/auth/aad-authentication-request";
import { AuthenticationRequest } from "../../requests/auth/authentication-request";
import { RefreshTokenRequest } from "../../requests/auth/refresh-token-request";
import {
    AuthTokenResponse,
    RefreshTokenResponse,
    TokenResponse,
    WebTokenType,
} from "../../responses/account/token-response";
import { Response } from "../../responses/common/response-response";

export const useLogin = (): UseMutationResult<
    Response<TokenResponse>,
    HTTPError,
    AuthenticationRequestDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: AuthenticationRequestDto) => {
        const request = new AuthenticationRequest(mutationKey);
        return login(url.originalBaseUrl, request);
    });
};

export const useLoginAad = (): UseMutationResult<
    Response<TokenResponse>,
    HTTPError,
    AadAuthenticationRequestDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: AadAuthenticationRequestDto) => {
        const request = new AadAuthenticationRequest(mutationKey);
        return loginAad(url.originalBaseUrl, request);
    });
};

export const useCreateRefreshTokenAad = (): UseMutationResult<
    Response<RefreshTokenResponse>,
    HTTPError,
    AadAuthenticationRequestDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: AadAuthenticationRequestDto) => {
        const request = new AadAuthenticationRequest(mutationKey);
        return createAadRefreshToken(url.originalBaseUrl, request);
    });
};

export const useCreateRefreshToken = (): UseMutationResult<
    Response<RefreshTokenResponse>,
    HTTPError,
    AuthenticationRequestDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: AuthenticationRequestDto) => {
        const request = new AuthenticationRequest(mutationKey);
        return createRefreshToken(url.originalBaseUrl, request);
    });
};

export const useRefreshTokens = (): UseMutationResult<
    Response<AuthTokenResponse>,
    HTTPError,
    RefreshTokenRequest
> => {
    const url = useUrl();

    return useMutation((mutationKey: RefreshTokenRequest) => {
        const request = new RefreshTokenRequest(mutationKey);

        return refreshTokens(url.originalBaseUrl, request);
    });
};

export const useHandleLoginData: () => (response: TokenResponse) => void = () => {
    const auth = useAuth();
    const url = useUrl();
    const { i18n } = useTranslation();

    return (response: TokenResponse) => {
        const decodedToken = jwtDecode<WebTokenType>(response.token);
        const roles = decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
        const languageCulture =
            decodedToken[
                "http://schemas.controlfirst.net/identity/claims/profileclaims/languageculture"
            ];
        const ianaTimeZoneId =
            decodedToken[
                "http://schemas.controlfirst.net/identity/claims/profileclaims/timezone-iana"
            ];
        const homeScreenPreference =
            decodedToken[
                "http://schemas.controlfirst.net/identity/claims/profileclaims/homepreference"
            ];

        const transformedRoles = transformToRoleEnums(!Array.isArray(roles) ? [roles] : roles);

        auth.updateUserRoles(transformedRoles);
        auth.updateUserName(decodedToken.name);
        auth.updateEmail(decodedToken.email);
        auth.updateTenantId(Number(decodedToken.tenantId));
        auth.updateUserId(Number(decodedToken.userId));

        const isImpersonating = localStorage.getItem("isImpersonating");
        if (isImpersonating != null) {
            auth.updateIsImpersonating(convertBooleanStringToBoolean(isImpersonating));
        }

        const impersonatedTenant = localStorage.getItem("impersonatedTenant");
        if (impersonatedTenant) {
            auth.updateTenantId(Number(impersonatedTenant) || 0);
        }

        const impersonatedBaseUrl = localStorage.getItem("impersonatedBaseUrl");
        if (impersonatedBaseUrl) {
            url.updateBaseUrl(impersonatedBaseUrl);
        }

        i18n.changeLanguage(languageCulture);

        const tokenJson = JSON.stringify(response);

        localStorage.setItem("token", tokenJson);
        localStorage.setItem("ianaTimeZoneId", ianaTimeZoneId);
        localStorage.setItem("homeScreenPreference", homeScreenPreference);
    };
};

export const useHandleRefreshTokenData: () => (response: RefreshTokenResponse) => void = () => {
    return (response: RefreshTokenResponse) => {
        const refreshTokenJson = JSON.stringify(response);
        localStorage.setItem("refreshToken", refreshTokenJson);
    };
};

export const useHandleAuthTokenData: () => (response: AuthTokenResponse) => void = () => {
    return (response: AuthTokenResponse) => {
        const tokenJson = JSON.stringify(response.token);
        const refreshTokenJson = JSON.stringify(response.refreshToken);

        localStorage.setItem("token", tokenJson);
        localStorage.setItem("refreshToken", refreshTokenJson);
    };
};
