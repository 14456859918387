import { FilterQuestionSetDto } from "../../dtos/question-sets/filter-question-set-dto";
import {
    buildPaginationSearchParameters,
    createPaginationRequest,
    PaginationRequest,
} from "../common/pagination-request";

export interface FilterQuestionSetRequest extends PaginationRequest {
    questionSetName: string | null;
    description: string | null;
    questionText: string | null;
    processName: string | null;
    questionSetId: number | null;
}

export const createFilterQuestionSetRequestFromDto = (
    dto: FilterQuestionSetDto
): FilterQuestionSetRequest => {
    const { pageNumber, pageSize, sortByColumn, sortByDescending } = dto;

    return {
        ...dto,
        ...createPaginationRequest(pageNumber, pageSize, sortByColumn, sortByDescending),
    };
};

export const buildFilterQuestionSetSearchParameters = (
    request: FilterQuestionSetRequest
): URLSearchParams => {
    const searchParams = buildPaginationSearchParameters(request);

    if (request.questionSetName) {
        searchParams.set("questionSetName", request.questionSetName);
    }

    if (request.description) {
        searchParams.set("description", request.description);
    }

    if (request.questionText) {
        searchParams.set("questionText", request.questionText);
    }

    if (request.processName) {
        searchParams.set("processName", request.processName);
    }

    if (request.questionSetId) {
        searchParams.set("questionSetId", request.questionSetId.toString());
    }

    return searchParams;
};
