import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { getQuestionDetails } from "../../../data/services/questions/question-service";
import {
    createQuestionDetailsDto,
    QuestionDetailsDto,
} from "../../dtos/questions/question-details-dto";
import { Response } from "../../responses/common/response-response";
import { QuestionDetailsResponse } from "../../responses/questions/question-details-response";

export const useGetQuestionDetails = (
    questionId: number
): UseQueryResult<QuestionDetailsDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getQuestionDetails", questionId],
        () => getQuestionDetails(url.baseUrl, questionId),
        {
            select: toQuestionDetailsDto,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const toQuestionDetailsDto = (response: Response<QuestionDetailsResponse>): QuestionDetailsDto =>
    createQuestionDetailsDto(response);
