import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import { getAuditSecurityLogin } from "../../../data/services/audits/audits-service";
import { FilterAuditsSecurityLoginDto } from "../../dtos/audits/filter-audits-security-login-dto";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { createFilterAuditsSecurityLoginRequestFromDto } from "../../requests/audits/filter-audits-security-login-request";
import { AuditSecurityLoginResponse } from "../../responses/audits/audit-security-login-response";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";

export const useGetAuditSecurityLogins = (
    dto: FilterAuditsSecurityLoginDto
): UseQueryResult<PaginatedTableDto<number>, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getAuditSecurityLogins", dto],
        () =>
            getAuditSecurityLogin(url.baseUrl, createFilterAuditsSecurityLoginRequestFromDto(dto)),
        {
            keepPreviousData: true,
            select: transformToDataTableRows,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const transformToDataTableRows = (
    response: Response<PaginationResponse<AuditSecurityLoginResponse>>
): PaginatedTableDto<number> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((audit) => {
        return {
            metadata: audit.loginId,
            columns: [
                { value: audit.date.toString(), type: DataTableColumnTypes.Text },
                { value: audit.time.toString(), type: DataTableColumnTypes.Text },
                { value: audit.source.toString(), type: DataTableColumnTypes.Text },
                {
                    value: audit.wasSuccessful.toString(),
                    type: DataTableColumnTypes.Check,
                },
                {
                    value: audit.username.toString(),
                    type: DataTableColumnTypes.Text,
                },
                {
                    value: audit.ipAddress.toString(),
                    type: DataTableColumnTypes.Text,
                },
            ],
        };
    });

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
