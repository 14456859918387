import AnswerValueType from "../../enums/answer-types/answer-value-type";

export interface CreateActionItemDynamicFieldDto {
    actionItemTypeNodeId: number;
    valueType: AnswerValueType;
    formData: FormData;
}

export const createCreateActionItemDynamicFieldDto = (
    actionItemTypeNodeId: number,
    valueType: AnswerValueType,
    formData: FormData
): CreateActionItemDynamicFieldDto => ({
    actionItemTypeNodeId: actionItemTypeNodeId,
    valueType: valueType,
    formData: formData,
});
