import { AuthenticationProvidersResponse } from "../../../domain/responses/tenants/authentication-providers-response";
import client from "../../http-client";

const route = "authenticationproviders";

export const getAuthenticationProviders = async (
    prefixUrl: string
): Promise<AuthenticationProvidersResponse> =>
    await client
        .get(`${route}`, {
            prefixUrl: prefixUrl,
        })
        .json();
