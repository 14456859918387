import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Common, NonWorkingDays } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { isMutationLoading } from "../../../core/utilities/responseStateHelper";
import { useCreateNonWorkingDaySet } from "../../../domain/viewmodels/non-working-days/create-non-working-day-set-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const CreateNonWorkingDaySetContainer: React.FC = () => {
    const menu = useMenu();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: NonWorkingDays });

    const createNonWorkingDaySet = useCreateNonWorkingDaySet();

    useLoader(isMutationLoading(createNonWorkingDaySet), CreateNonWorkingDaySetContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.NonWorkingDay);
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        createNonWorkingDaySet.mutate(
            {
                formData: new FormData(event.currentTarget),
            },
            {
                onSuccess: async () => navigate(-1),
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    return (
        <>
            <PageHeading>{t("NonWorkingDaySetCreateTitle")}</PageHeading>
            <SectionVerticalSpace />
            <TextTitledPanel title={t("CreateNonWorkingDaySet")}>
                <Form onSubmit={handleSubmit}>
                    <SbFormTextFieldGroup
                        name="name"
                        label={t("Name", { keyPrefix: Common })}
                        type="text"
                        maxLength={50}
                        required
                    />

                    <EndAlignedDiv>
                        <SaveButton type="submit" />
                        <CancelButton onClick={() => navigate(-1)} />
                    </EndAlignedDiv>
                </Form>
            </TextTitledPanel>
        </>
    );
};

export default CreateNonWorkingDaySetContainer;
