import { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { ChecklistQuestions, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import {
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import ReviewStatus from "../../../domain/enums/review-reports/review-status";
import { Response } from "../../../domain/responses/common/response-response";
import {
    useDeleteReviewReportSet,
    useGetReviewReportSetDetails,
} from "../../../domain/viewmodels/review-reports/delete-review-report-set-viewmodel";
import { CancelButton, DeleteButton } from "../../atoms/SbButton";
import { TextTitledPanel } from "../../molecules/SbPanel";

const DeleteReviewReportSetContainer: React.FC = () => {
    const reviewReportSetId = Number(useParams().reviewReportSetId);

    const menu = useMenu();
    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const reviewReportSetDetails = useGetReviewReportSetDetails(reviewReportSetId);
    const deleteReviewReportSet = useDeleteReviewReportSet();

    useLoader(
        isQueryLoading(reviewReportSetDetails) || isMutationLoading(deleteReviewReportSet),
        DeleteReviewReportSetContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Checklists);
        menu.changeActiveDrawerItem(DrawerTitles.Reviews);
    }, []);

    const handleDelete = (): void => {
        deleteReviewReportSet.mutate(reviewReportSetId, {
            onSuccess: async (_: Response<boolean>) => {
                const params = [
                    createNavigateSearchParameter("success", "true"),
                    createNavigateSearchParameter("messageKey", "SuccessfullyDeletedReviewReport"),
                ];

                navigateSearch(`${getPath(DrawerTitles.Reviews)}`, params);
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    return (
        <>
            {isQuerySuccessful(reviewReportSetDetails) && (
                <>
                    <PageHeading>{t("DeleteReviewReportTitle")}</PageHeading>

                    <SectionVerticalSpace />

                    <TextTitledPanel title={t("DeleteReportReviewConfirmation")}>
                        <Form.Group as={Row} className="mb-3">
                            <Col sm={2}>
                                <DetailsLabel>{t("Name")}</DetailsLabel>
                            </Col>
                            <Col sm="auto">
                                <DetailsValue>
                                    {reviewReportSetDetails.data!.reviewReportName}
                                </DetailsValue>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Col sm={2}>
                                <DetailsLabel>{t("Status", { keyPrefix: Common })}</DetailsLabel>
                            </Col>
                            <Col sm="auto">
                                <DetailsValue>
                                    {ReviewStatus[reviewReportSetDetails.data!.reviewStatus]}
                                </DetailsValue>
                            </Col>
                        </Form.Group>

                        <EndAlignedDiv>
                            <DeleteButton onClick={handleDelete} />
                            <CancelButton onClick={() => navigate(-1)} />
                        </EndAlignedDiv>
                    </TextTitledPanel>
                </>
            )}
        </>
    );
};

export default DeleteReviewReportSetContainer;
