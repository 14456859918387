import { RoleResponse } from "../../responses/users/role-response";

export interface ViewUserRoleDto {
    roleId: number;
    name: string;
}

export interface EditUserRoleDto extends ViewUserRoleDto {
    isActive: boolean;
}

export const toViewUserRoleDto = (role: RoleResponse): ViewUserRoleDto => ({
    roleId: role.roleId,
    name: role.name,
});

export const toEditUserRoleDto = (role: RoleResponse, isActive: boolean): EditUserRoleDto => ({
    roleId: role.roleId,
    name: role.name,
    isActive: isActive,
});
