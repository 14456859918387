import { BaseReferentialLinkResponse } from "../../responses/referential-links/base-referential-link-response";

export default class BaseReferentialLinkDto {
    referentialLinkId: number;
    value: string;

    public constructor(referentialLinkId: number, value: string) {
        this.referentialLinkId = referentialLinkId;
        this.value = value;
    }

    public static constructFromResponse = (
        response: BaseReferentialLinkResponse
    ): BaseReferentialLinkDto => {
        const { referentialLinkId, value } = response;

        return new BaseReferentialLinkDto(referentialLinkId, value);
    };

    public static toBaseReferentialLinkDtos = (
        responses: BaseReferentialLinkResponse[]
    ): BaseReferentialLinkDto[] =>
        responses?.map((x) => BaseReferentialLinkDto.constructFromResponse(x));
}
