import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { deleteNonWorkingDay } from "../../../data/services/non-working-days/non-working-day-service";
import { Response } from "../../responses/common/response-response";

export const useDeleteNonWorkingDay = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    number
> => {
    const url = useUrl();

    return useMutation((nonWorkingDayId: number) =>
        deleteNonWorkingDay(url.baseUrl, nonWorkingDayId)
    );
};
