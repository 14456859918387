import { Response } from "../../responses/common/response-response";
import { SecretsResponse } from "../../responses/secrets/secrets-response";

export interface SecretsDto {
    clientId: string;
    tenantId: string;
    authority: string;
    redirectUri: string;
    scopeId: string;
}

export const createSecretsDtoFromResponse = (response: Response<SecretsResponse>): SecretsDto => ({
    ...response.data,
});
