import { HTTPError } from "ky";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CreateHierarchyTypeEvent } from "../../../core/constants/application-insights-events";
import { Common, HierarchyTypes } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isMutationLoading } from "../../../core/utilities/responseStateHelper";
import {
    HierarchyAssignable,
    HierarchyAssignableLabels,
    translateHierarchyAssignableLabel,
} from "../../../domain/enums/hierarchy/HierarchyAssignable";
import { Response } from "../../../domain/responses/common/response-response";
import { useCreateHierarchyType } from "../../../domain/viewmodels/hierarchy/create-hierarchy-type-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import SbFormCheckFieldGroup from "../../molecules/input/SbFormCheckFieldGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const CreateHierarchyTypeContainer: React.FC = () => {
    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const navigate = useNavigate();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: HierarchyTypes });

    const createHierarchyType = useCreateHierarchyType();

    useLoader(isMutationLoading(createHierarchyType), CreateHierarchyTypeContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.Admin);
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        createHierarchyType.mutate(new FormData(event.currentTarget), {
            onSuccess: async (response: Response<number | null>) => {
                trackAppInsightsEvent(auth.email, window.location.href, CreateHierarchyTypeEvent);

                const params = [
                    createNavigateSearchParameter("success", "true"),
                    createNavigateSearchParameter(
                        "messageKey",
                        "CreateHierarchyTypeSuccessMessage"
                    ),
                ];

                navigateSearch(`${getPath(AccordionTitles.Admin)}/${response.data}`, params);
            },
            onError: (error: HTTPError) => {
                trackAppInsightsException(
                    auth.email,
                    window.location.href,
                    CreateHierarchyTypeEvent,
                    error
                );
                errorResponseToDisplayHandler(error);
            },
        });
    };

    return (
        <>
            <PageHeading>{t("HierarchyTypeCreateTitle")}</PageHeading>
            <SectionVerticalSpace />

            <TextTitledPanel title={t("CreateHierarchyType")}>
                <Form onSubmit={handleSubmit}>
                    <SbFormTextFieldGroup
                        name="name"
                        maxLength={50}
                        label={t("Name", { keyPrefix: Common })}
                        type="text"
                        required
                    />

                    <SbFormCheckFieldGroup
                        fieldLabel={t("HierarchyAssignableFlag")}
                        type="checkbox"
                        values={Array.from(HierarchyAssignableLabels.entries()).map((x) => ({
                            name: HierarchyAssignable[x[0]],
                            label: translateHierarchyAssignableLabel(t, x[1]),
                        }))}
                    />

                    <EndAlignedDiv>
                        <SaveButton type="submit" />
                        <CancelButton onClick={() => navigate(-1)} />
                    </EndAlignedDiv>
                </Form>
            </TextTitledPanel>
        </>
    );
};

export default CreateHierarchyTypeContainer;
