import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useAuth } from "../../../core/store/auth-context";
import { useUrl } from "../../../core/store/url-context";
import { getActionItemSummaries } from "../../../data/services/action-items/action-item-summary-service";
import { ActionItemSummaryDto } from "../../dtos/action-items/action-item-summary-dto";
import { ActionItemSummaryResponse } from "../../responses/action-items/action-item-summary-response";

export const useGetActionItemSummary = (): UseQueryResult<
    ActionItemSummaryResponse[],
    HTTPError
> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const auth = useAuth();

    // If admin is impersonating, use the admin url
    const urlToUse = auth.isImpersonating ? url.baseUrl : url.originalBaseUrl;

    return useQuery(["getActionItemSummary"], () => getActionItemSummaries(urlToUse), {
        select: ActionItemSummaryDto.constructFromActionItemSummaryResponses,
        onError: errorResponseToDisplayHandler,
    });
};
