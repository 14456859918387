import AnswerValueType from "../../enums/answer-types/answer-value-type";
import {
    CreateActionItemDynamicFieldDto,
    createCreateActionItemDynamicFieldDto,
} from "./create-action-item-dynamic-field-dto";

export interface EditActionItemDynamicFieldDto extends CreateActionItemDynamicFieldDto {
    actionItemDynamicFieldId: number;
}

export const createEditActionItemDynamicFieldDto = (
    actionItemDynamicFieldId: number,
    actionItemTypeNodeId: number,
    valueType: AnswerValueType,
    formData: FormData
): EditActionItemDynamicFieldDto => ({
    actionItemDynamicFieldId: actionItemDynamicFieldId,
    ...createCreateActionItemDynamicFieldDto(actionItemTypeNodeId, valueType, formData),
});
