import React from "react";
import styled from "styled-components";

const StyledInput = styled.input`
    display: inline-block;
    vertical-align: middle;
    width: ${(props) => props.theme.dimensions.width.xxl};
    height: ${(props) => props.theme.dimensions.height.xl};
    background-color: ${(props) => props.theme.palette.purewhite};
    background-image: none;
    border: ${(props) => props.theme.padding.xxs} solid ${(props) => props.theme.palette.grey};
    border-radius: 0.5rem;
    color: inherit;
    transition: border-color .15s
    font-size: ${(props) => props.theme.font.md};
    text-align: center; 
`;

export const NumberInputBox: React.FC<{
    handleNumberInputChange?: React.ChangeEventHandler<HTMLInputElement>;
    defaultValue?: number;
    name?: string;
}> = ({ handleNumberInputChange, defaultValue, name }) => (
    <StyledInput
        type={"number"}
        onChange={handleNumberInputChange}
        key={defaultValue}
        defaultValue={defaultValue}
        name={name}
    />
);
