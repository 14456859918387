import { Response } from "../../responses/common/response-response";

export interface ValidationResponseDto<TType> {
    data: TType;
    message: string;
    errors: string[];
}

export const createValidationResponseDto = <TType>(
    response: Response<TType>
): ValidationResponseDto<TType> => ({
    data: response.data,
    message: response.message,
    errors: response.errors,
});
