import { useEffect } from "react";
import { useWizardNotification } from "../../../core/store/wizard-notification-context";
import { isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import { useGetWizardNotification } from "../../../domain/viewmodels/wizards/view-wizard-notification-viewmodel";

export const WizardNotificationChecker: React.FC<{ userId: number | null }> = ({ userId }) => {
    const getWizardCards = useGetWizardNotification(userId);
    const wizardNotification = useWizardNotification();

    useEffect(() => {
        if (isQuerySuccessful(getWizardCards) && getWizardCards.data!.length > 0) {
            wizardNotification.onWizardCardsFetch(getWizardCards.data!);
            wizardNotification.updateShow(true);
        }
    }, [getWizardCards.fetchStatus]);

    return <></>;
};
