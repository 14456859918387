import { CreateActionPlanDto } from "../../dtos/actions/create-action-plan-dto";

export class CreateActionPlanRequest {
    name: string;
    description: string;

    public constructor(dto: CreateActionPlanDto) {
        const { name, description } = dto;

        this.name = name;
        this.description = description;
    }
}
