import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import styled from "styled-components";
import { ChecklistQuestions, Common } from "../../core/constants/translation-namespace";
import { maxContentWidthSelectStyle } from "../../core/theme/global-styles";
import {
    DayOrdinalOptions,
    DaysOfTheWeekOptions,
    MonthsOfTheYearOptions,
} from "../../core/utilities/QuestionSetValues";
import { ReactSelectDto } from "../../domain/dtos/common/react-select-dto";
import { DayOrdinal } from "../../domain/enums/questions/question-sets/DayOrdinalNumber";
import { DaysOfWeek } from "../../domain/enums/questions/question-sets/DaysOfTheWeek";
import { MonthOfTheYear } from "../../domain/enums/questions/question-sets/MonthOfTheYear";
import { NumberInputBox } from "../atoms/input/NumberInputBox";
import { SbSelect } from "../atoms/input/SbSelect";
import { translateWithFallback } from "../helpers/translate-with-common-fallback";

const StyledDivFormField = styled.div`
    display: inline-block;
    padding-top: ${(props) => props.theme.padding.md};
    text-align: right;
    vertical-align: middle;
    margin-bottom: 0;
    box-sizing: border-box;
    padding-right: ${(props) => props.theme.padding.sm};
`;

const StyledDivAdditionalInfo = styled.div`
    display: inline-block;
    padding-top: ${(props) => props.theme.padding.sm};
    text-align: right;
    vertical-align: top;
    margin-bottom: 10;
    padding-right: ${(props) => props.theme.padding.sm};
    padding-left: ${(props) => props.theme.padding.xs};
    font-size: ${(props) => props.theme.font.md};
    color: ${(props) => props.theme.palette.secondary};
`;

export const YearlySchedule: React.FC<{
    handleYearIntervalChange: React.ChangeEventHandler<HTMLInputElement>;
    handleYearlyScheduleDescriptionChange: React.ChangeEventHandler<HTMLInputElement>;
    selectedYearlyScheduleDescription: string;
    handleMonthOfTheYearChange: (item: SingleValue<ReactSelectDto<MonthOfTheYear>>) => void;
    handleDayOfTheMonthChange: React.ChangeEventHandler<HTMLInputElement>;
    handleDayOrdinalChange: (item: SingleValue<ReactSelectDto<DayOrdinal>>) => void;
    handleDaysOfTheWeekChange: (item: SingleValue<ReactSelectDto<DaysOfWeek>>) => void;
    yearInterval?: number;
    dayOfTheMonth?: number;
    monthOfTheYear?: string;
    dayOrdinal?: string;
    dayOfTheWeek?: string;
}> = ({
    handleYearlyScheduleDescriptionChange,
    selectedYearlyScheduleDescription,
    handleMonthOfTheYearChange,
    handleYearIntervalChange,
    handleDayOfTheMonthChange,
    handleDayOrdinalChange,
    handleDaysOfTheWeekChange,
    yearInterval,
    dayOfTheMonth,
    monthOfTheYear,
    dayOrdinal,
    dayOfTheWeek,
}) => {
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    return (
        <>
            <Form.Group className="mb">
                <StyledDivFormField>{t("RecurEvery")}</StyledDivFormField>
                <StyledDivFormField>
                    <NumberInputBox
                        handleNumberInputChange={handleYearIntervalChange}
                        defaultValue={yearInterval}
                    />
                    &nbsp;{" "}
                    {t("YearsPluralOptional", {
                        keyPrefix: ChecklistQuestions,
                    })?.toLocaleLowerCase()}
                </StyledDivFormField>
            </Form.Group>
            <Form.Group className="mb">
                <StyledDivFormField>
                    <Form.Check
                        type="radio"
                        label={t("On")}
                        name="onX"
                        defaultChecked={selectedYearlyScheduleDescription === "onX"}
                        checked={selectedYearlyScheduleDescription === "onX"}
                        onChange={handleYearlyScheduleDescriptionChange}
                    />
                </StyledDivFormField>
                <StyledDivFormField>
                    <SbSelect
                        styles={maxContentWidthSelectStyle}
                        name={"monthsOfTheYear"}
                        placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                        searchable
                        clearable
                        items={MonthsOfTheYearOptions}
                        onChange={handleMonthOfTheYearChange}
                        itemLabel={(option: ReactSelectDto<MonthOfTheYear>) =>
                            translateWithFallback(
                                t,
                                MonthOfTheYear[option.value],
                                ChecklistQuestions
                            )
                        }
                        itemValue={(option: ReactSelectDto<MonthOfTheYear>) =>
                            option.value.toString()
                        }
                        defaultSelectedItem={MonthsOfTheYearOptions.find(
                            (x) => x.value.toString() === monthOfTheYear
                        )}
                    />
                </StyledDivFormField>
                <StyledDivFormField>
                    <NumberInputBox
                        handleNumberInputChange={handleDayOfTheMonthChange}
                        defaultValue={dayOfTheMonth}
                    ></NumberInputBox>
                    <StyledDivAdditionalInfo>
                        {t("NonWorkingDayRolloverTypeAppliesInfoMessage")}
                    </StyledDivAdditionalInfo>
                </StyledDivFormField>
            </Form.Group>
            <StyledDivFormField>
                <Form.Check
                    type="radio"
                    label={t("OnThe")}
                    name="onTheXOf"
                    defaultChecked={selectedYearlyScheduleDescription === "onTheXOf"}
                    checked={selectedYearlyScheduleDescription === "onTheXOf"}
                    onChange={handleYearlyScheduleDescriptionChange}
                />
            </StyledDivFormField>
            <StyledDivFormField>
                <SbSelect
                    styles={maxContentWidthSelectStyle}
                    name={"dayOrdinal"}
                    placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                    searchable
                    clearable
                    items={DayOrdinalOptions}
                    onChange={handleDayOrdinalChange}
                    itemLabel={(option: ReactSelectDto<DayOrdinal>) =>
                        translateWithFallback(t, DayOrdinal[option.value], ChecklistQuestions)
                    }
                    itemValue={(option: ReactSelectDto<DayOrdinal>) => option.value.toString()}
                    defaultSelectedItem={DayOrdinalOptions.find(
                        (x) => x.value.toString() === dayOrdinal
                    )}
                />
            </StyledDivFormField>
            <StyledDivFormField>
                <SbSelect
                    styles={maxContentWidthSelectStyle}
                    name={"daysOfTheWeek"}
                    placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                    searchable
                    clearable
                    items={DaysOfTheWeekOptions}
                    onChange={handleDaysOfTheWeekChange}
                    itemLabel={(option: ReactSelectDto<DaysOfWeek>) =>
                        translateWithFallback(t, DaysOfWeek[option.value], ChecklistQuestions)
                    }
                    itemValue={(option: ReactSelectDto<DaysOfWeek>) => option.value.toString()}
                    defaultSelectedItem={DaysOfTheWeekOptions.find(
                        (x) => x.value.toString() === dayOfTheWeek
                    )}
                />
            </StyledDivFormField>
            <StyledDivFormField> &nbsp; {t("Of")?.toLowerCase()}&nbsp;</StyledDivFormField>
            <StyledDivFormField>
                <SbSelect
                    styles={maxContentWidthSelectStyle}
                    name={"monthsOfTheYear"}
                    placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                    searchable
                    clearable
                    items={MonthsOfTheYearOptions}
                    onChange={handleMonthOfTheYearChange}
                    itemLabel={(option: ReactSelectDto<MonthOfTheYear>) =>
                        translateWithFallback(t, MonthOfTheYear[option.value], ChecklistQuestions)
                    }
                    itemValue={(option: ReactSelectDto<MonthOfTheYear>) => option.value.toString()}
                    defaultSelectedItem={MonthsOfTheYearOptions.find(
                        (x) => x.value.toString() === monthOfTheYear
                    )}
                />
            </StyledDivFormField>
        </>
    );
};
