import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAuth } from "../../core/store/auth-context";
import { LargeVerticalSpace, PageHeading, PageSubHeading } from "../../core/theme/global-styles";
import { DrawerTitles } from "../../core/utilities/enums";
import { getPath } from "../../core/utilities/getPath";
import { getTenantIdentifier } from "../../core/utilities/tenant-identifier";
import { ControlFirstTenantIdentifier } from "../../domain/enums/common/TenantIdentifier";
import { SbButton } from "../atoms/SbButton";

const StyledCenter = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LogoutContainer: React.FC = () => {
    const tenantIdentifier = getTenantIdentifier(window.location.hostname);

    const { t } = useTranslation("translation", { keyPrefix: "user.login" });
    const navigate = useNavigate();
    const auth = useAuth();

    useEffect(() => {
        if (auth.userName) {
            tenantIdentifier === ControlFirstTenantIdentifier.Admin
                ? navigate("/dashboard")
                : navigate(getPath(DrawerTitles.AnswerSummary));
        }
    }, [auth.userName]);

    const navigateToLogin = (): void => {
        navigate("/login");
    };

    return (
        <>
            <Container>
                <StyledCenter>
                    <PageHeading>{t("LoggedOff")}</PageHeading>
                </StyledCenter>

                <StyledCenter>
                    <PageSubHeading>{t("LogOutSuccess")}</PageSubHeading>
                </StyledCenter>

                <LargeVerticalSpace />

                <StyledCenter>
                    <SbButton
                        variant={"primary"}
                        type={"button"}
                        label={t("Login")}
                        onClick={navigateToLogin}
                    />
                </StyledCenter>
            </Container>
        </>
    );
};

export default LogoutContainer;
