import { AssociateLinkedNodeTypeRequest } from "../../../domain/requests/hierarchy/associate-linked-node-type-request";
import { CreateNodeTypeRequest } from "../../../domain/requests/hierarchy/create-node-type-request";
import { DisassociateLinkedNodeTypeRequest } from "../../../domain/requests/hierarchy/disassociate-linked-node-type-request";
import { EditNodeTypeRequest } from "../../../domain/requests/hierarchy/edit-node-type-request";
import {
    buildFilterByNodeTypeSearchParameters,
    FilterByNodeTypeRequest,
} from "../../../domain/requests/hierarchy/filter-by-node-type-request";
import {
    buildFilterNodeTYpeHierarchySearchParameters,
    FilterNodeTypeHierarchyRequest,
} from "../../../domain/requests/hierarchy/filter-node-type-hierarchy-request";
import {
    buildFilterSearchParameters as buildFilterNodesSearchParameters,
    FilterNodeTypesRequest,
} from "../../../domain/requests/hierarchy/filter-node-types-request";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import { AssociateDisassociateNodeTypeRequest } from "../../../domain/responses/hierarchy/associate-disassociate-node-type-request";
import { LinkedNodeTypeResponse } from "../../../domain/responses/hierarchy/linked-node-type-response";
import { NodeHierarchyResponse } from "../../../domain/responses/hierarchy/node-hierarchy-response";
import { NodeTypeDetailsResponse } from "../../../domain/responses/hierarchy/node-type-details-response";
import { NodeTypeResponse } from "../../../domain/responses/hierarchy/node-type-response";
import client from "../../http-client";

const route = "nodetypes";

export const filterNodeTypeHierarchy = async (
    prefixUrl: string,
    request: FilterNodeTypeHierarchyRequest
): Promise<Response<NodeHierarchyResponse>> => {
    const searchParams = buildFilterNodeTYpeHierarchySearchParameters(request);

    return await client
        .get(`${route}/hierarchy`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const filterNodeTypes = async (
    prefixUrl: string,
    request: FilterNodeTypesRequest
): Promise<Response<PaginationResponse<NodeTypeResponse>>> => {
    const searchParams = buildFilterNodesSearchParameters(request);

    return await client
        .get(`${route}/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const filterChildNodeTypes = async (
    prefixUrl: string,
    request: FilterByNodeTypeRequest
): Promise<Response<PaginationResponse<NodeTypeResponse>>> => {
    const searchParams = buildFilterByNodeTypeSearchParameters(request);

    return await client
        .get(`${route}/children`, {
            prefixUrl: prefixUrl,
            searchParams: searchParams,
        })
        .json();
};

export const filterLinkedToNodeTypes = async (
    prefixUrl: string,
    request: FilterByNodeTypeRequest
): Promise<Response<PaginationResponse<NodeTypeResponse>>> => {
    const searchParams = buildFilterByNodeTypeSearchParameters(request);

    return await client
        .get(`${route}/linked-to-node-types`, {
            prefixUrl: prefixUrl,
            searchParams: searchParams,
        })
        .json();
};

export const filterLinkedNodeTypes = async (
    prefixUrl: string,
    request: FilterByNodeTypeRequest
): Promise<Response<PaginationResponse<LinkedNodeTypeResponse>>> => {
    const searchParams = buildFilterByNodeTypeSearchParameters(request);

    return await client
        .get(`${route}/linked-node-types`, {
            prefixUrl: prefixUrl,
            searchParams: searchParams,
        })
        .json();
};

export const filterLinkableChildNodeTypes = async (
    prefixUrl: string,
    nodeTypeId: number
): Promise<Response<NodeTypeResponse[]>> =>
    await client
        .get(`${route}/linkable-child-node-types/${nodeTypeId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const getNodeTypeDetails = async (
    prefixUrl: string,
    nodeTypeId: number
): Promise<Response<NodeTypeDetailsResponse>> =>
    await client
        .get(`${route}/${nodeTypeId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const editNodeType = async (
    prefixUrl: string,
    request: EditNodeTypeRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/edit`, {
            prefixUrl: prefixUrl,
            body: JSON.stringify(request),
        })
        .json();

export const createNodeType = async (
    prefixUrl: string,
    request: CreateNodeTypeRequest
): Promise<Response<number | null>> =>
    await client
        .post(`${route}/create`, {
            prefixUrl: prefixUrl,
            body: JSON.stringify(request),
        })
        .json();

export const getCanDeleteNodeType = async (
    prefixUrl: string,
    nodeTypeId: number
): Promise<Response<boolean>> =>
    await client
        .get(`${route}/can-delete/${nodeTypeId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const deleteNodeType = async (
    prefixUrl: string,
    nodeTypeId: number
): Promise<Response<boolean>> =>
    await client
        .delete(`${route}/delete/${nodeTypeId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const associateChildNodeType = async (
    prefixUrl: string,
    request: AssociateDisassociateNodeTypeRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/associate-child`, {
            prefixUrl: prefixUrl,
            body: JSON.stringify(request),
        })
        .json();

export const disassociateChildNodeType = async (
    prefixUrl: string,
    request: AssociateDisassociateNodeTypeRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/disassociate-child`, {
            prefixUrl: prefixUrl,
            body: JSON.stringify(request),
        })
        .json();

export const associateLinkedNodeType = async (
    prefixUrl: string,
    request: AssociateLinkedNodeTypeRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/associate-linked`, {
            prefixUrl: prefixUrl,
            body: JSON.stringify(request),
        })
        .json();

export const disassociateLinkedNodeType = async (
    prefixUrl: string,
    request: DisassociateLinkedNodeTypeRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/disassociate-linked`, {
            prefixUrl: prefixUrl,
            body: JSON.stringify(request),
        })
        .json();
