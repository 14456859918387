import { TriggeringDate } from "../../enums/notification-triggers/triggering-date";
import { PeriodType } from "../../enums/PeriodType";
import { IntervalRequest } from "../common/interval-request";

export class EditNotificationTriggerRequest {
    notificationTriggerId: number;
    name: string;
    triggerBefore: boolean;
    triggeringDate: TriggeringDate;
    triggerTimeDeltaIntervalRequest: IntervalRequest;
    sendWindowIntervalRequest: IntervalRequest;

    public constructor(
        notificationTriggerId: number,
        name: string,
        triggerBefore: boolean,
        triggeringDate: TriggeringDate,
        triggerTimeDeltaIntervalValue: number,
        triggerTimeDeltaIntervalPeriodType: PeriodType,
        sendWindowIntervalValue: number,
        sendWindowIntervalPeriodType: PeriodType
    ) {
        this.notificationTriggerId = notificationTriggerId;
        this.name = name;
        this.triggerBefore = triggerBefore;
        this.triggeringDate = triggeringDate;
        this.triggerTimeDeltaIntervalRequest = new IntervalRequest(
            triggerTimeDeltaIntervalValue,
            triggerTimeDeltaIntervalPeriodType
        );
        this.sendWindowIntervalRequest = new IntervalRequest(
            sendWindowIntervalValue,
            sendWindowIntervalPeriodType
        );
    }
}
