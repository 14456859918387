import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import { getRolesWithUserCount } from "../../../data/services/users/roles-service";
import { ListTableDto } from "../../dtos/common/list-table-dto";
import { RoleWithUserCountDto, toRoleWithUserCountDto } from "../../dtos/users/role-dto";
import { Response } from "../../responses/common/response-response";

export const useGetRolesWithUserCount = (): UseQueryResult<
    ListTableDto<RoleWithUserCountDto>,
    HTTPError
> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(["getRolesWithUserCount"], () => getRolesWithUserCount(url.originalBaseUrl), {
        select: transformToDataTableRows,
        onError: errorResponseToDisplayHandler,
    });
};

const transformToDataTableRows = (
    response: Response<RoleWithUserCountDto[]>
): ListTableDto<RoleWithUserCountDto> => {
    const responseData = response.data;

    const rows = responseData?.map((role) => {
        return {
            metadata: toRoleWithUserCountDto(role),
            columns: [
                {
                    value: role.name,
                    type: DataTableColumnTypes.Text,
                },
                {
                    value: role.userCount.toString(),
                    type: DataTableColumnTypes.Text,
                },
            ],
        };
    });

    return { rows: rows };
};
