import { Form, Formik } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ActionPlans, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isMutationLoading } from "../../../core/utilities/responseStateHelper";
import { CreateActionPlanDto } from "../../../domain/dtos/actions/create-action-plan-dto";
import { Response } from "../../../domain/responses/common/response-response";
import { useCreateActionPlan } from "../../../domain/viewmodels/actions/create-action-plan-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import SbFormikTextAreaGroup from "../../molecules/input/SbFormikTextAreaGroup";
import SbFormikTextFieldGroup from "../../molecules/input/SbFormikTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";
import { CreateActionPlanValidator } from "../../validators/actions/create-action-plan-validator";

const CreateActionPlanContainer: React.FC = () => {
    const menu = useMenu();
    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ActionPlans });

    const createActionPlan = useCreateActionPlan();

    useLoader(isMutationLoading(createActionPlan), CreateActionPlanContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.ActionTracker);
        menu.changeActiveDrawerItem(DrawerTitles.ActionPlans);
    }, []);

    const handleSubmit = (dto: CreateActionPlanDto): void => {
        createActionPlan.mutate(dto, {
            onSuccess: async (response: Response<number>) => {
                const params = [
                    createNavigateSearchParameter("success", "true"),
                    createNavigateSearchParameter("messageKey", "CreateActionPlanSuccessMessage"),
                ];

                navigateSearch(`${getPath(DrawerTitles.ActionPlans)}/${response.data}`, params);
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    return (
        <>
            <PageHeading>{t("CreateActionPlan")}</PageHeading>
            <PageSubHeading>{t("HeaderHelpTextActionPlanCreate")}</PageSubHeading>
            <SectionVerticalSpace />
            <TextTitledPanel title={t("CreateActionPlan")}>
                <Formik
                    initialValues={{ name: "", description: "" }}
                    onSubmit={handleSubmit}
                    validationSchema={CreateActionPlanValidator}
                >
                    {({ handleChange, errors }) => (
                        <Form>
                            <SbFormikTextFieldGroup
                                name="name"
                                label={t("Name", { keyPrefix: Common })}
                                type="text"
                                required
                                onFormikChange={handleChange}
                                error={errors.name}
                            />

                            <SbFormikTextAreaGroup
                                name="description"
                                label={t("Description", { keyPrefix: Common })}
                                maxLength={1500}
                                required
                                rows={4}
                                error={errors.description}
                                onFormikChange={handleChange}
                            />

                            <EndAlignedDiv>
                                <SaveButton type="submit" />
                                <CancelButton onClick={() => navigate(-1)} />
                            </EndAlignedDiv>
                        </Form>
                    )}
                </Formik>
            </TextTitledPanel>
        </>
    );
};

export default CreateActionPlanContainer;
