import { HTTPError } from "ky";
import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { DeleteQuestionEvent } from "../../../core/constants/application-insights-events";
import { ChecklistQuestions } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import QuestionImportance from "../../../domain/enums/questions/question-importance";
import { useSoftDeleteQuestion } from "../../../domain/viewmodels/questions/delete-question-viewmodel";
import { useGetQuestionDetails } from "../../../domain/viewmodels/questions/view-question-viewmodel";
import { DeleteButton } from "../../atoms/SbButton";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import { ActionConfirmationFragment } from "../../organisms/ActionConfirmationFragment";

const DeleteQuestionContainer: React.FC = () => {
    const questionId = Number(useParams().questionId);

    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const questionDetailsResponse = useGetQuestionDetails(questionId);
    const softDeleteQuestion = useSoftDeleteQuestion();

    const questionDetailsResponseData = questionDetailsResponse.data;

    useLoader(
        isQueryLoading(questionDetailsResponse) || isMutationLoading(softDeleteQuestion),
        DeleteQuestionContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Questions);
    }, []);

    const deleteQuestion = (questionId: number): void => {
        softDeleteQuestion.mutate(questionId, {
            onSuccess: async () => {
                trackAppInsightsEvent(auth.email, window.location.href, DeleteQuestionEvent);

                const params = [
                    createNavigateSearchParameter("success", "true"),
                    createNavigateSearchParameter("messageKey", "DeleteQuestionSuccessMessage"),
                ];

                navigateSearch(`${getPath(AccordionTitles.Questions)}`, params);
            },
            onError: (error: HTTPError) => {
                trackAppInsightsException(
                    auth.email,
                    window.location.href,
                    DeleteQuestionEvent,
                    error
                );
                errorResponseToDisplayHandler(error);
            },
        });
    };

    const buildDeleteButton = (): ReactElement<typeof Button> => (
        <DeleteButton onClick={() => deleteQuestion(questionId)} />
    );

    const buildConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow(t("Type"), questionDetailsResponseData?.questionType.name),
        new TextConfirmationRow(
            t("Importance"),
            questionDetailsResponseData?.questionImportance
                ? QuestionImportance[questionDetailsResponseData?.questionImportance]
                : "-"
        ),
    ];

    return (
        <>
            <ActionConfirmationFragment
                pageHeading={t("DeleteQuestion")}
                panelTitle={t("DeleteQuestionConfirmation")}
                rows={buildConfirmationRows()}
                primaryButton={buildDeleteButton()}
                areQueriesSuccessful={isQuerySuccessful(questionDetailsResponse)}
            />
        </>
    );
};

export default DeleteQuestionContainer;
