import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { cloneQuestionSet } from "../../../data/services/question-set/question-set-service";
import { Response } from "../../responses/common/response-response";
import { QuestionSetResponse } from "../../responses/question-sets/question-set-response";

interface CloneParameters {
    questionSetId: number;
}
export const useCloneQuestionSet = (): UseMutationResult<
    Response<QuestionSetResponse>,
    HTTPError,
    CloneParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: CloneParameters) =>
        cloneQuestionSet(url.baseUrl, mutationKey.questionSetId)
    );
};
