import { Formik } from "formik";
import { HTTPError } from "ky";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { object, string } from "yup";
import { EdithecklistEvent } from "../../../core/constants/application-insights-events";
import { ChecklistQuestions, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
    StyledHiddenOverflowFormikForm,
} from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import { EditChecklistDto } from "../../../domain/dtos/checklists/edit-checklist-dto";
import { useEditChecklist } from "../../../domain/viewmodels/checklists/edit-checklist-viewmodel";
import { useGetChecklist } from "../../../domain/viewmodels/checklists/view-checklist-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import { SbRibbon } from "../../atoms/SbRibbon";
import SbFormikTextAreaGroup from "../../molecules/input/SbFormikTextAreaGroup";
import SbFormikTextFieldGroup from "../../molecules/input/SbFormikTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const EditChecklistContainer: React.FC = () => {
    const menu = useMenu();
    const navigate = useNavigate();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const checklistId = Number(useParams().checklistId);

    const checklistDetails = useGetChecklist(checklistId);
    const editChecklist = useEditChecklist();

    useLoader(
        isQueryLoading(checklistDetails) || isMutationLoading(editChecklist),
        EditChecklistContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Checklists);
    }, []);

    const handleSubmit = (dto: EditChecklistDto): void => {
        editChecklist.mutate(
            { checklistId, dto },
            {
                onSuccess: async () => {
                    trackAppInsightsEvent(auth.email, window.location.href, EdithecklistEvent);
                    navigate(`${getPath(AccordionTitles.Checklists)}/${checklistId}`);
                },

                onError: (error: HTTPError) => {
                    trackAppInsightsException(
                        auth.email,
                        window.location.href,
                        EdithecklistEvent,
                        error
                    );
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    };

    const editChecklistSchema = (): object => {
        return object({
            name: string().max(100, "Must be 100 characters or less").required("Name is required"),
            description: string().max(250, "Must be 250 characters or less"),
        });
    };

    return (
        <>
            <PageHeading>{t("ChecklistEditTitle")}</PageHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(checklistDetails) && (
                <TextTitledPanel title={t("EditChecklist")}>
                    <Formik
                        initialValues={
                            new EditChecklistDto(
                                checklistDetails.data!.name,
                                checklistDetails.data!.description
                            )
                        }
                        onSubmit={handleSubmit}
                        validationSchema={editChecklistSchema}
                    >
                        {({ handleChange, errors, touched }) => (
                            <StyledHiddenOverflowFormikForm>
                                {checklistDetails.data!.isTraining && (
                                    <SbRibbon
                                        size={"large"}
                                        label={t("Training", { keyPrefix: Common })}
                                    />
                                )}

                                <SbFormikTextFieldGroup
                                    name="name"
                                    label={`${t("Name")}`}
                                    type="text"
                                    onFormikChange={handleChange}
                                    required
                                    defaultValue={checklistDetails.data!.name}
                                    error={errors.name}
                                    touched={touched.name}
                                />

                                <SbFormikTextAreaGroup
                                    name="description"
                                    label={`${t("Description", { keyPrefix: Common })}`}
                                    rows={4}
                                    maxLength={250}
                                    defaultValue={checklistDetails.data!.description ?? ""}
                                    onFormikChange={handleChange}
                                    error={errors.description}
                                    touched={touched.description}
                                />

                                <EndAlignedDiv>
                                    <SaveButton type="submit" />
                                    <CancelButton onClick={() => navigate(-1)} />
                                </EndAlignedDiv>
                            </StyledHiddenOverflowFormikForm>
                        )}
                    </Formik>
                </TextTitledPanel>
            )}
        </>
    );
};

export default EditChecklistContainer;
