import { ReactElement, useEffect, useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaEye, FaPaperPlane, FaSave } from "react-icons/fa";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import { useWizardNotification } from "../../../core/store/wizard-notification-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { ImageLibraryDto } from "../../../domain/dtos/image-library/image-library-dto";
import { WizardCardDto } from "../../../domain/dtos/wizards/wizard-card-dto";
import {
    useFilterActiveImagesByWizardId,
    useGetAllActiveImageNames,
} from "../../../domain/viewmodels/image-library/view-image-library-viewmodel";
import { useOrderWizardCards } from "../../../domain/viewmodels/wizards/order-wizard-cards-viewmodel";
import { useGetWizardCards } from "../../../domain/viewmodels/wizards/view-wizard-cards-viewmodel";
import { useGetWizardDetails } from "../../../domain/viewmodels/wizards/view-wizard-viewmodel";
import { SbButton } from "../../atoms/SbButton";
import { CreateLink, SbLink, ViewLink } from "../../atoms/SbLink";
import { SbAccordion } from "../../molecules/SbAccordion";
import { WizardCards } from "../../organisms/WizardCards";
import { CreateWizardCardProps } from "./CreateWizardCardContainer";

interface ConfirmationDetailsProps {
    name: string;
}

const ViewWizardContainer: React.FC = () => {
    const [wizardCards, setWizardCards] = useState<WizardCardDto[]>();

    const toast = useToast();
    const menu = useMenu();
    const navigate = useNavigate();
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const { t } = useTranslation("translation", { keyPrefix: Common });
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";
    const wizardId = Number(useParams().wizardId);

    const wizardNotification = useWizardNotification();
    const getImageLibraryImages = useFilterActiveImagesByWizardId(wizardId);
    const getWizardDetails = useGetWizardDetails(wizardId);
    const getWizardCards = useGetWizardCards(wizardId);
    const orderWizardCards = useOrderWizardCards();
    const getAllActiveImageNames = useGetAllActiveImageNames();

    useLoader(
        areQueriesLoading([
            getImageLibraryImages,
            getWizardDetails,
            getWizardCards,
            getAllActiveImageNames,
        ]),
        ViewWizardContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.WizardCards, AccordionTitles.Wizards);

        if (success) {
            toast.addToast(createSuccessToastProps([t(messageKey)]));

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    useEffect(() => {
        isQuerySuccessful(getWizardCards) && setWizardCards(getWizardCards.data!);
    }, [getWizardCards]);

    const buildWizardCardsNotificationPreview = (): void => {
        wizardNotification.onImageLibraryFetch(getImageLibraryImages.data!);
        wizardNotification.onWizardCardsFetch(wizardCards!);
        wizardNotification.updateShow(wizardCards!.length > 0);
    };

    const buildLinks = (): ReactElement<typeof Link> => {
        return (
            <>
                {getWizardCards.data && getWizardCards.data.length > 0 && (
                    <>
                        <SbButton
                            variant="primary"
                            type={"button"}
                            label="Preview"
                            icon={FaEye}
                            onClick={buildWizardCardsNotificationPreview}
                        />
                        {!getWizardDetails.data!.sent && buildPublishLink()}
                    </>
                )}
                {buildWizardsLink()}
            </>
        );
    };

    const buildWizardsLink = (): ReactElement<typeof Link> => (
        <ViewLink label={t("Wizards")} navigateTo={`${getPath(AccordionTitles.Wizards)}`} />
    );

    const buildPublishLink = (): ReactElement<typeof Link> => (
        <SbLink
            variant="primary"
            label={"Release"}
            icon={FaPaperPlane}
            navigateTo={`${getPath(AccordionTitles.Wizards)}/${wizardId}/publish`}
            navigationProps={buildPublishLinkProps()}
        />
    );

    const buildPublishLinkProps = (): ConfirmationDetailsProps => ({
        name: getWizardDetails.data!.name || "-",
    });

    const navigateToEditWizardCard = (metadata: WizardCardDto): void => {
        const { cardId, heading, description, fileDetailsId } = metadata;

        navigate(`${getPath(AccordionTitles.Wizards)}/${cardId}/edit-wizard-card`, {
            state: {
                wizardId: wizardId,
                heading: heading,
                description: description,
                initialFileDetailsId: fileDetailsId,
                fileName: getImageLibraryImage(fileDetailsId)?.fileName,
                imageLibraryImages: getAllActiveImageNames.data!,
            },
        });
    };

    const navigateToDeleteWizardCard = (metadata: WizardCardDto): void => {
        const { cardId, heading, description } = metadata;

        navigate(`${getPath(AccordionTitles.Wizards)}/${cardId}/delete-wizard-card`, {
            state: {
                wizardId: wizardId,
                heading: heading,
                description: description,
            },
        });
    };

    const buildCreateLinkProps = (): CreateWizardCardProps => ({
        imageLibraryImages: getAllActiveImageNames.data!,
    });

    const onDragEnd = (result: DropResult): void => {
        if (!result.destination) {
            return;
        }

        const orderedWizardCards: WizardCardDto[] = reorder(
            wizardCards!,
            result.source.index,
            result.destination.index
        );

        setWizardCards(orderedWizardCards);
    };

    const reorder = (
        list: WizardCardDto[],
        startIndex: number,
        endIndex: number
    ): WizardCardDto[] => {
        let newList = list;
        const [removed] = newList.splice(startIndex, 1);
        newList.splice(endIndex, 0, removed);

        const updatedIndexes = list.map((wizardCard, index) => {
            let updatedWizardCard = wizardCard;
            updatedWizardCard.index = index + 1;

            return updatedWizardCard;
        });

        return updatedIndexes;
    };

    const saveWizardCardsOrder = (): void => {
        const orderedWizardCards = wizardCards?.map((wizardCard) => {
            return { wizardCardId: wizardCard.cardId, index: wizardCard.index };
        });

        orderWizardCards.mutate(
            {
                wizardId: wizardId,
                wizardCards: orderedWizardCards!,
            },
            {
                onSuccess: async () => {
                    toast.addToast(createSuccessToastProps([t("WizardsCardOrderUpdatedSuccess")]));
                    queryClient.invalidateQueries(["getWizardCards", wizardId]);
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    const getImageLibraryImage = (fileDetailsId: number): ImageLibraryDto | undefined => {
        return getImageLibraryImages.data?.find(
            (imageLibraryImage) => imageLibraryImage.fileDetailsId === fileDetailsId
        );
    };

    const renderWizardCardsSection = (): boolean | JSX.Element => {
        return (
            areQueriesSuccessful([getImageLibraryImages, getWizardDetails]) && (
                <SbAccordion title={t("WizardCards")}>
                    <>
                        {!getWizardDetails.data!.sent && (
                            <EndAlignedDiv>
                                <CreateLink
                                    label={t("CreateWizardCard")}
                                    navigateTo={`${getPath(
                                        AccordionTitles.Wizards
                                    )}/${wizardId}/create-wizard-card`}
                                    navigationProps={buildCreateLinkProps()}
                                />
                                {getWizardCards.data && getWizardCards.data.length > 0 && (
                                    <SbButton
                                        variant="primary"
                                        type={"button"}
                                        label="Save Order"
                                        icon={FaSave}
                                        onClick={saveWizardCardsOrder}
                                    />
                                )}
                            </EndAlignedDiv>
                        )}

                        {isQuerySuccessful(getWizardCards) && (
                            <WizardCards
                                onDragEnd={onDragEnd}
                                wizardCards={wizardCards}
                                editWizardCard={navigateToEditWizardCard}
                                deleteWizardCard={navigateToDeleteWizardCard}
                                getImageLibraryImage={getImageLibraryImage}
                                sent={getWizardDetails.data!.sent}
                            />
                        )}
                    </>
                </SbAccordion>
            )
        );
    };

    return (
        <>
            <PageHeading>{t("WizardDetails")}</PageHeading>
            <SectionVerticalSpace />
            {isQuerySuccessful(getWizardDetails) && (
                <>
                    <ContentContainer>
                        <Row>
                            <Col md="auto">
                                <DetailsLabel>{t("WizardName")}</DetailsLabel>
                                <DetailsLabel>{t("DateCreated")}</DetailsLabel>
                                <DetailsLabel>{t("CreatedBy")}</DetailsLabel>
                                <DetailsLabel>{t("WizardReleased")}</DetailsLabel>
                            </Col>
                            <Col md="auto">
                                <DetailsValue>{getWizardDetails.data!.name || "-"}</DetailsValue>
                                <DetailsValue>
                                    {getWizardDetails.data!.dateCreatedLocal}
                                </DetailsValue>
                                <DetailsValue>
                                    {getWizardDetails.data!.createdByUserName || "-"}
                                </DetailsValue>
                                <Form.Check checked={getWizardDetails.data!.sent} disabled />
                            </Col>
                        </Row>
                        <EndAlignedDiv>{buildLinks()}</EndAlignedDiv>
                    </ContentContainer>
                    <LargeVerticalSpace />
                    {renderWizardCardsSection()}
                </>
            )}
        </>
    );
};

export default ViewWizardContainer;
