import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import { filterQuestionCategories } from "../../../data/services/question-categories/question-category-service";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { FilterQuestionCategoryDto } from "../../dtos/question-categories/filter-question-category-dto";
import { createFilterQuestionCategoryRequestFromDto } from "../../requests/question-categories/filter-question-category-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { QuestionCategoryWithQuestionCountResponse } from "../../responses/question-category/question-category-with-question-count-response";

export const useGetQuestionCategories = (
    dto: FilterQuestionCategoryDto
): UseQueryResult<PaginatedTableDto<number>, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getQuestionCategories", dto],
        () =>
            filterQuestionCategories(url.baseUrl, createFilterQuestionCategoryRequestFromDto(dto)),
        {
            keepPreviousData: true,
            select: transformToDataTableRows,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const transformToDataTableRows = (
    response: Response<PaginationResponse<QuestionCategoryWithQuestionCountResponse>>
): PaginatedTableDto<number> => {
    const { pageCount, pageSize, currentPage, recordCount } = response.data;

    const rows = response.data!.results.map((questionCategory) => {
        return {
            metadata: questionCategory.questionCategoryId,
            columns: [
                {
                    value: questionCategory.name.toString(),
                    type: DataTableColumnTypes.Link,
                },
                {
                    value: questionCategory.questionCount?.toString(),
                    type: DataTableColumnTypes.Text,
                },
            ],
        };
    });

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
