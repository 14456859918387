import BaseChecklistDto from "../checklists/base-checklist-dto";
import { BaseNodeDetailsDto } from "../hierarchy/base-node-details-dto";
import { BaseUserDto } from "../users/base-user-dto";
import FilterReportSummariesDto from "./filter-report-summaries-dto";

export default class FilterChecklistReportSummariesDto extends FilterReportSummariesDto {
    checklistId: number | null;
    nodeId: number | null;
    nodeDto: BaseNodeDetailsDto | null;
    userDto: BaseUserDto | null;
    checklistDto: BaseChecklistDto | null;

    public constructor(
        startDateUtc: Date | null,
        endDateUtc: Date | null,
        nodeDto: BaseNodeDetailsDto | null,
        userDto: BaseUserDto | null,
        checklistDto: BaseChecklistDto | null
    ) {
        super(startDateUtc, endDateUtc, userDto?.userId);

        this.checklistId = checklistDto?.checklistId ?? null;
        this.nodeId = nodeDto?.nodeId ?? null;
        this.nodeDto = nodeDto;
        this.userDto = userDto;
        this.checklistDto = checklistDto;
    }

    public static emptyFilterChecklistReportSummariesDto = new FilterChecklistReportSummariesDto(
        null,
        null,
        null,
        null,
        null
    );
}
