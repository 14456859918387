import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ChecklistQuestions } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import {
    ContentContainer,
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { orderQuestionsColumnNames } from "../../../core/utilities/dataTableColumns";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import { Response } from "../../../domain/responses/common/response-response";
import {
    useFilterNestedQuestions,
    useOrderNestedQuestions,
} from "../../../domain/viewmodels/questions/order-nested-questions-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import DraggableDataTable from "../../organisms/DraggableDataTable";

const OrderNestedQuestionsContainer: React.FC = () => {
    const parentQuestionId = Number(useParams().parentQuestionId);

    const orderedNestedQuestions = useRef<number[]>([]);

    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const nestedQuestions = useFilterNestedQuestions(parentQuestionId);
    const orderNestedQuestions = useOrderNestedQuestions(parentQuestionId);

    useLoader(
        isQueryLoading(nestedQuestions) || isMutationLoading(orderNestedQuestions),
        OrderNestedQuestionsContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.QuestionSets);
    }, []);

    const handleOrderNestedQuestions = (): void => {
        const orderedQuestionSetQuestionIds = orderedNestedQuestions.current;
        if (!orderedQuestionSetQuestionIds.length) {
            return;
        }

        orderNestedQuestions.mutate(orderedQuestionSetQuestionIds, {
            onSuccess: async (_: Response<boolean>) => {
                const params = [
                    createNavigateSearchParameter("success", "true"),
                    createNavigateSearchParameter(
                        "messageKey",
                        "OrderNestedQuestionsSetSuccessMessage"
                    ),
                ];

                navigateSearch(`${getPath(AccordionTitles.Questions)}/${parentQuestionId}`, params);
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    return (
        <>
            <PageHeading>{t("QuestionSortOrder")}</PageHeading>
            <PageSubHeading>{t("HeaderHelpTextOrderQuestions")}</PageSubHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(nestedQuestions) && (
                <>
                    <SectionVerticalSpace />
                    <ContentContainer>
                        <DraggableDataTable
                            columns={orderQuestionsColumnNames}
                            keyPrefix={ChecklistQuestions}
                            rows={nestedQuestions.data?.rows}
                            onOrderChanged={(orderedNestedQuestionIds: number[]): void => {
                                orderedNestedQuestions.current = orderedNestedQuestionIds;
                            }}
                        />
                    </ContentContainer>

                    <LargeVerticalSpace />
                    <EndAlignedDiv>
                        <SaveButton type="button" onClick={handleOrderNestedQuestions} />
                        <CancelButton onClick={() => navigate(-1)} />
                    </EndAlignedDiv>
                </>
            )}
        </>
    );
};

export default OrderNestedQuestionsContainer;
