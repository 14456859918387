import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Common, NonWorkingDays } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { isMutationLoading } from "../../../core/utilities/responseStateHelper";
import { useDeleteNonWorkingDay } from "../../../domain/viewmodels/non-working-days/delete-non-working-day-viewmodel";
import { DeleteButton } from "../../atoms/SbButton";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import { ActionConfirmationFragment } from "../../organisms/ActionConfirmationFragment";

interface ConfirmationDetailsProps {
    name: string;
    date: Date;
}

const DeleteNonWorkingDayContainer: React.FC = () => {
    const menu = useMenu();
    const navigate = useNavigate();
    const location = useLocation();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: NonWorkingDays });

    const nonWorkingDayId = Number(useParams().nonWorkingDayId);
    const { name, date } = location.state as ConfirmationDetailsProps; // TODO: Fetching Checklist data on this page vs passing it through from the previous page

    const deleteNonWorkingDay = useDeleteNonWorkingDay();

    useLoader(isMutationLoading(deleteNonWorkingDay), DeleteNonWorkingDayContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.NonWorkingDay);
    }, []);

    const handleDeleteNonWorkingDay = (nonWorkingDayId: number): void => {
        deleteNonWorkingDay.mutate(nonWorkingDayId, {
            onSuccess: async () => navigate(-1),
            onError: errorResponseToDisplayHandler,
        });
    };

    const buildDeleteButton = (): ReactElement<typeof Button> => (
        <DeleteButton onClick={() => handleDeleteNonWorkingDay(nonWorkingDayId)} />
    );

    const buildConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow(t("Name", { keyPrefix: Common }), name),
        new TextConfirmationRow(t("Date", { keyPrefix: Common }), date.toLocaleDateString()),
    ];

    return (
        <ActionConfirmationFragment
            pageHeading={t("NonWorkingDayDeleteTitle")}
            panelTitle={t("DeleteNonWorkingDayConfirmation")}
            rows={buildConfirmationRows()}
            primaryButton={buildDeleteButton()}
        />
    );
};

export default DeleteNonWorkingDayContainer;
