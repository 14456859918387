import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { dissociateReferentialLink } from "../../../data/services/checklists/checklist-service";
import { createAssociateDissociateReferentialLinkToChecklistRequest } from "../../requests/checklists/associate-dissociate-referential-link-to-checklist-request";
import { Response } from "../../responses/common/response-response";

interface DissociateParameters {
    checklistId: number;
    referentialLinkIds: number[];
}

export const useDissociateReferentialLink = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    DissociateParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: DissociateParameters) => {
        const request = createAssociateDissociateReferentialLinkToChecklistRequest(
            mutationKey.checklistId,
            mutationKey.referentialLinkIds
        );

        return dissociateReferentialLink(url.baseUrl, request);
    });
};
