import React, { useContext, useState } from "react";

export interface LoaderContextType {
    isLoading: boolean;
    updateIsLoading: (isLoading: boolean, component: React.FC<any>) => void;
}

const LoaderContext = React.createContext<LoaderContextType>({
    isLoading: false,
    updateIsLoading: (_isLoading: boolean, _component: React.FC<any>) => {},
});

export const LoaderProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false);
    const [loadingComponentsSet, setLoadingComponentsSet] = useState<Set<string>>(new Set());

    const updateIsLoading = (isLoading: boolean, component: React.FC<any>): void => {
        const componentName = component.name;

        if (
            !isLoading &&
            (!loadingComponentsSet.size || !loadingComponentsSet.has(componentName))
        ) {
            return;
        }

        if (!isLoading) {
            setLoadingComponentsSet((prev) => {
                prev.delete(componentName);

                setIsLoading(prev.size > 0);

                return prev;
            });

            return;
        }

        setLoadingComponentsSet((prev) => {
            prev.add(componentName);

            return prev;
        });

        setIsLoading(true);
    };

    const value = {
        isLoading,
        updateIsLoading,
    };

    return <LoaderContext.Provider value={value}>{children}</LoaderContext.Provider>;
};

export const useLoaderProvider = (): LoaderContextType => useContext(LoaderContext);
