import { buildPaginationSearchParameters, PaginationRequest } from "../common/pagination-request";
import {
    buildSearchChecklistAssociationSearchParameters,
    SearchChecklistAssociationRequest,
} from "./search-checklist-association-request";

export interface FilterChecklistAssociationRequest
    extends SearchChecklistAssociationRequest,
        PaginationRequest {}

export const createFilterChecklistAssociationRequest = (
    paginationRequest: PaginationRequest,
    searchRequest: SearchChecklistAssociationRequest,
    checklistAssignmentId: number | null,
    nodeSearchText: string | null
): FilterChecklistAssociationRequest => ({
    ...paginationRequest,
    checklistId: searchRequest.checklistId,
    checklistAssignmentId: checklistAssignmentId,
    nodeSearchText: nodeSearchText,
});

export const buildFilterChecklistAssociationSearchParameters = (
    request: FilterChecklistAssociationRequest
): URLSearchParams => {
    const searchParams = buildPaginationSearchParameters(request);
    buildSearchChecklistAssociationSearchParameters(request, searchParams);

    return searchParams;
};
