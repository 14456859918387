import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { demoteNode, getNodeDetails } from "../../../data/services/hierarchy/nodes-service";
import { createNodeDetailsDto, NodeDetailsDto } from "../../dtos/hierarchy/node-details-dto";
import { Response } from "../../responses/common/response-response";

export const useGetNodeDetails = (nodeId: number): UseQueryResult<NodeDetailsDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(["useGetNodeDetails", nodeId], () => getNodeDetails(url.baseUrl, nodeId), {
        select: createNodeDetailsDto,
        onError: errorResponseToDisplayHandler,
    });
};

export const useDemoteNode = (): UseMutationResult<Response<boolean>, HTTPError, number> => {
    const url = useUrl();

    return useMutation((nodeId: number) => demoteNode(url.baseUrl, nodeId));
};
