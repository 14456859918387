import { Response } from "../../../domain/responses/common/response-response";
import { ReferentialLinkTypeResponse } from "../../responses/referential-links/referential-link-type-response";

export interface AssociateReferentialLinkTypeDto {
    referentialLinkTypeId: number;
    name: string;
}

export const createAssociateReferentialLinkTypeDto = (
    referentialLinkTypeResponse: ReferentialLinkTypeResponse
): AssociateReferentialLinkTypeDto => ({
    ...referentialLinkTypeResponse,
});

export const toAssociateReferentialLinkTypeDto = (
    response: Response<ReferentialLinkTypeResponse[]>
): AssociateReferentialLinkTypeDto[] =>
    response.data.map((referentialLinkType) =>
        createAssociateReferentialLinkTypeDto(referentialLinkType)
    );
