import { AssociateNonWorkingDaySetNodeRequest } from "../../../domain/requests/non-working-days/associate-non-working-day-set-node-request";
import { CreateNonWorkingDaySetRequest } from "../../../domain/requests/non-working-days/create-non-working-day-set-request";
import { EditNonWorkingDaySetRequest } from "../../../domain/requests/non-working-days/edit-non-working-day-set-request";
import {
    buildFilterSearchParameters,
    FilterNonWorkingDaySetRequest,
} from "../../../domain/requests/non-working-days/filter-non-working-day-sets-request";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import { BaseNonWorkingDaySetResponse } from "../../../domain/responses/non-working-days/base-non-working-day-set-response";
import { NonWorkingDaySetResponse } from "../../../domain/responses/non-working-days/non-working-day-set-response";
import client from "../../http-client";

const route = "nonworkingdaysets";

export const filterNonWorkingDaySets = async (
    prefixUrl: string,
    request: FilterNonWorkingDaySetRequest
): Promise<Response<PaginationResponse<NonWorkingDaySetResponse>>> => {
    const searchParams = buildFilterSearchParameters(request);

    return await client
        .get(`${route}/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getNonWorkingDaySet = async (
    prefixUrl: string,
    nonWorkingDaySetId: number
): Promise<Response<BaseNonWorkingDaySetResponse>> => {
    return await client
        .get(`${route}/${nonWorkingDaySetId}`, {
            prefixUrl: prefixUrl,
        })
        .json();
};

export const createNonWorkingDaySet = async (
    prefixUrl: string,
    request: CreateNonWorkingDaySetRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/create`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const editNonWorkingDaySet = async (
    prefixUrl: string,
    request: EditNonWorkingDaySetRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/edit`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const deleteNonWorkingDaySet = async (
    prefixUrl: string,
    nonWorkingDaySetId: number
): Promise<Response<boolean>> =>
    await client
        .delete(`${route}/delete/${nonWorkingDaySetId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const associateNode = async (
    prefixUrl: string,
    request: AssociateNonWorkingDaySetNodeRequest
): Promise<Response<number | null>> =>
    await client
        .post(`${route}/associate-node`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();
