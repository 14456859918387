import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// English
import enLinks from "./translations/locales/en/Accordion/links.lang.json";
import enActionItems from "./translations/locales/en/ActionItems/actionitems.lang.json";
import enActionItemType from "./translations/locales/en/ActionItems/actionitemtype.lang.json";
import enActionPlan from "./translations/locales/en/ActionPlans/actionplan.lang.json";
import enAudits from "./translations/locales/en/Audit/audits.lang.json";
import enAnswers from "./translations/locales/en/Checklists/answers.lang.json";
import enQuestions from "./translations/locales/en/Checklists/questions.lang.json";
import enCommon from "./translations/locales/en/Common/common.lang.json";
import enErrorMessages from "./translations/locales/en/Common/errormessages.lang.json";
import enMailTemplates from "./translations/locales/en/Common/mailtemplates.lang.json";
import enHierarchy from "./translations/locales/en/Hierarchy/hierarchy.lang.json";
import enHierarchyTreeView from "./translations/locales/en/Hierarchy/hierarchytreeview.lang.json";
import enHierarchyTypes from "./translations/locales/en/Hierarchy/hierarchytypes.lang.json";
import enNodes from "./translations/locales/en/Hierarchy/nodes.lang.json";
import enNodeTypes from "./translations/locales/en/Hierarchy/nodetypes.lang.json";
import enNodeTypeValues from "./translations/locales/en/Hierarchy/nodetypevalues.lang.json";
import enNonWorkingDays from "./translations/locales/en/Hierarchy/nonworkingdays.lang.json";
import enHome from "./translations/locales/en/Home/home.lang.json";
import enDropdowns from "./translations/locales/en/Navbar/dropdowns.lang.json";
import enReports from "./translations/locales/en/Reports/reports.lang.json";
import enLanguages from "./translations/locales/en/Static/languages_lang.json";
import enEmail from "./translations/locales/en/User/email.lang.json";
import enExternalLogin from "./translations/locales/en/User/externallogin.lang.json";
import enLogin from "./translations/locales/en/User/login.lang.json";
import enPassword from "./translations/locales/en/User/password.lang.json";
import enProfile from "./translations/locales/en/User/profile.lang.json";
import enRegister from "./translations/locales/en/User/register.lang.json";
import enRoles from "./translations/locales/en/User/roles.lang.json";
import enTenants from "./translations/locales/en/User/tenant.lang.json";
import enUserManage from "./translations/locales/en/User/usermanage.lang.json";
import enUsers from "./translations/locales/en/User/users.lang.json";
// French
import frLinks from "./translations/locales/fr/Accordion/links.lang.fr.json";
import frActionItems from "./translations/locales/fr/ActionItems/actionitems.lang.fr.json";
import frActionItemType from "./translations/locales/fr/ActionItems/actionitemtype.lang.fr.json";
import frActionPlan from "./translations/locales/fr/ActionPlans/actionplan.lang.fr.json";
import frAudits from "./translations/locales/fr/Audit/audits.lang.fr.json";
import frAnswers from "./translations/locales/fr/Checklists/answers.lang.fr.json";
import frQuestions from "./translations/locales/fr/Checklists/questions.lang.fr.json";
import frCommon from "./translations/locales/fr/Common/common.lang.fr.json";
import frMailTemplates from "./translations/locales/fr/Common/mailtemplates.lang.fr.json";
import frHierarchy from "./translations/locales/fr/Hierarchy/hierarchy.lang.fr.json";
import frHierarchyTreeView from "./translations/locales/fr/Hierarchy/hierarchytreeview.lang.fr.json";
import frHierarchyTypes from "./translations/locales/fr/Hierarchy/hierarchytypes.lang.fr.json";
import frNodes from "./translations/locales/fr/Hierarchy/nodes.lang.fr.json";
import frNodeTypes from "./translations/locales/fr/Hierarchy/nodetypes.lang.fr.json";
import frNodeTypeValues from "./translations/locales/fr/Hierarchy/nodetypevalues.lang.fr.json";
import frNonWorkingDays from "./translations/locales/fr/Hierarchy/nonworkingdays.lang.fr.json";
import frHome from "./translations/locales/fr/Home/home.lang.fr.json";
import frDropdowns from "./translations/locales/fr/Navbar/dropdowns.lang.fr.json";
import frReports from "./translations/locales/fr/Reports/reports.lang.fr.json";
import frLanguages from "./translations/locales/fr/Static/languages_lang.json";
import frEmail from "./translations/locales/fr/User/email.lang.fr.json";
import frExternalLogin from "./translations/locales/fr/User/externallogin.lang.fr.json";
import frLogin from "./translations/locales/fr/User/login.lang.fr.json";
import frPassword from "./translations/locales/fr/User/password.lang.fr.json";
import frProfile from "./translations/locales/fr/User/profile.lang.fr.json";
import frRegister from "./translations/locales/fr/User/register.lang.fr.json";
import frRoles from "./translations/locales/fr/User/roles.lang.fr.json";
import frTenant from "./translations/locales/fr/User/tenant.lang.fr.json";
import frUserManage from "./translations/locales/fr/User/usermanage.lang.fr.json";
import frUsers from "./translations/locales/fr/User/users.lang.fr.json";
// Portuguese
import ptLinks from "./translations/locales/pt/Accordion/links.lang.pt.json";
import ptActionItems from "./translations/locales/pt/ActionItems/actionitems.lang.pt.json";
import ptActionItemType from "./translations/locales/pt/ActionItems/actionitemtype.lang.pt.json";
import ptActionPlan from "./translations/locales/pt/ActionPlans/actionplan.lang.pt.json";
import ptAudits from "./translations/locales/pt/Audit/audits.lang.pt.json";
import ptAnswers from "./translations/locales/pt/Checklists/answers.lang.pt.json";
import ptQuestions from "./translations/locales/pt/Checklists/questions.lang.pt.json";
import ptCommon from "./translations/locales/pt/Common/common.lang.pt.json";
import ptMailTemplates from "./translations/locales/pt/Common/mailtemplates.lang.pt.json";
import ptHierarchy from "./translations/locales/pt/Hierarchy/hierarchy.lang.pt.json";
import ptHierarchyTreeView from "./translations/locales/pt/Hierarchy/hierarchytreeview.lang.pt.json";
import ptHierarchyTypes from "./translations/locales/pt/Hierarchy/hierarchytypes.lang.pt.json";
import ptNodes from "./translations/locales/pt/Hierarchy/nodes.lang.pt.json";
import ptNodeTypes from "./translations/locales/pt/Hierarchy/nodetypes.lang.pt.json";
import ptNodeTypeValues from "./translations/locales/pt/Hierarchy/nodetypevalues.lang.pt.json";
import ptNonWorkingDays from "./translations/locales/pt/Hierarchy/nonworkingdays.lang.pt.json";
import ptHome from "./translations/locales/pt/Home/home.lang.pt.json";
import ptDropdowns from "./translations/locales/pt/Navbar/dropdowns.lang.pt.json";
import ptReports from "./translations/locales/pt/Reports/reports.lang.pt.json";
import ptLanguages from "./translations/locales/pt/Static/languages_lang.json";
import ptEmail from "./translations/locales/pt/User/email.lang.pt.json";
import ptExternalLogin from "./translations/locales/pt/User/externallogin.lang.pt.json";
import ptLogin from "./translations/locales/pt/User/login.lang.pt.json";
import ptPassword from "./translations/locales/pt/User/password.lang.pt.json";
import ptProfile from "./translations/locales/pt/User/profile.lang.pt.json";
import ptRegister from "./translations/locales/pt/User/register.lang.pt.json";
import ptRoles from "./translations/locales/pt/User/roles.lang.pt.json";
import ptTenant from "./translations/locales/pt/User/tenant.lang.pt.json";
import ptUserManage from "./translations/locales/pt/User/usermanage.lang.pt.json";
import ptUsers from "./translations/locales/pt/User/users.lang.pt.json";

const resources = {
    en: {
        translation: {
            actionItems: {
                actionItems: enActionItems,
                actionItemType: enActionItemType,
            },
            actionPlans: {
                actionPlan: enActionPlan,
            },
            audit: {
                audits: enAudits,
            },
            checklists: {
                answers: enAnswers,
                questions: enQuestions,
            },
            common: {
                common: enCommon,
                mailTemplates: enMailTemplates,
                errorMessages: enErrorMessages,
            },
            hierarchy: {
                hierarchy: enHierarchy,
                hierarchyTreeView: enHierarchyTreeView,
                hierarchyTypes: enHierarchyTypes,
                nodes: enNodes,
                nodeTypes: enNodeTypes,
                nodeTypeValues: enNodeTypeValues,
                nonWorkingDays: enNonWorkingDays,
            },
            home: {
                home: enHome,
            },
            reports: {
                reports: enReports,
            },
            static: {
                languages: enLanguages,
            },
            user: {
                email: enEmail,
                externalLogin: enExternalLogin,
                login: enLogin,
                password: enPassword,
                profile: enProfile,
                register: enRegister,
                roles: enRoles,
                tenant: enTenants,
                userManage: enUserManage,
                users: enUsers,
            },
            accordion: {
                links: enLinks,
            },
            navbar: {
                dropdowns: enDropdowns,
            },
        },
    },
    fr: {
        translation: {
            actionItems: {
                actionItems: frActionItems,
                actionItemType: frActionItemType,
            },
            actionPlans: {
                actionPlan: frActionPlan,
            },
            audit: {
                audits: frAudits,
            },
            checklists: {
                answers: frAnswers,
                questions: frQuestions,
            },
            common: {
                common: frCommon,
                mailTemplates: frMailTemplates,
            },
            hierarchy: {
                hierarchy: frHierarchy,
                hierarchyTreeView: frHierarchyTreeView,
                hierarchyTypes: frHierarchyTypes,
                nodes: frNodes,
                nodeTypes: frNodeTypes,
                nodeTypeValues: frNodeTypeValues,
                nonWorkingDays: frNonWorkingDays,
            },
            home: {
                home: frHome,
            },
            reports: {
                reports: frReports,
            },
            static: {
                languages: frLanguages,
            },
            user: {
                email: frEmail,
                externalLogin: frExternalLogin,
                login: frLogin,
                password: frPassword,
                profile: frProfile,
                register: frRegister,
                roles: frRoles,
                tenant: frTenant,
                userManage: frUserManage,
                users: frUsers,
            },
            accordion: {
                links: frLinks,
            },
            navbar: {
                dropdowns: frDropdowns,
            },
        },
    },
    pt: {
        translation: {
            actionItems: {
                actionItems: ptActionItems,
                actionItemType: ptActionItemType,
            },
            actionPlans: {
                actionPlan: ptActionPlan,
            },
            audit: {
                audits: ptAudits,
            },
            checklists: {
                answers: ptAnswers,
                questions: ptQuestions,
            },
            common: {
                common: ptCommon,
                mailTemplates: ptMailTemplates,
            },
            hierarchy: {
                hierarchy: ptHierarchy,
                hierarchyTreeView: ptHierarchyTreeView,
                hierarchyTypes: ptHierarchyTypes,
                nodes: ptNodes,
                nodeTypes: ptNodeTypes,
                nodeTypeValues: ptNodeTypeValues,
                nonWorkingDays: ptNonWorkingDays,
            },
            home: {
                home: ptHome,
            },
            reports: {
                reports: ptReports,
            },
            static: {
                languages: ptLanguages,
            },
            user: {
                email: ptEmail,
                externalLogin: ptExternalLogin,
                login: ptLogin,
                password: ptPassword,
                profile: ptProfile,
                register: ptRegister,
                roles: ptRoles,
                tenant: ptTenant,
                userManage: ptUserManage,
                users: ptUsers,
            },
            accordion: {
                links: ptLinks,
            },
            navbar: {
                dropdowns: ptDropdowns,
            },
        },
    },
};

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        fallbackLng: "en",
    })
    .then(
        () => {},
        () => {}
    );

export default i18n;
