export interface AssociateDisassociateNodeTypeDto {
    parentNodeTypeId: number;
    childNodeTypeId: number;
}

export const createAssociateDisassociateNodeTypeDto = (
    parentNodeTypeId: number,
    childNodeTypeId: number
): AssociateDisassociateNodeTypeDto => ({
    parentNodeTypeId: parentNodeTypeId,
    childNodeTypeId: childNodeTypeId,
});
