export class EditProfileDto {
    userId: number;
    firstName: string;
    lastName: string;
    timeZoneCountry: string;
    languageCulture: string;
    homeScreenPreference: string;
    emailAddress: string;
    timeZoneNodeTypeValueId: number | null;

    public constructor(
        userId: number,
        firstName: string,
        lastName: string,
        timeZoneCountry: string,
        languageCulture: string,
        homeScreenPreference: string,
        emailAddress: string,
        timeZoneNodeTypeValueId: number | null
    ) {
        this.userId = userId;
        this.firstName = firstName;
        this.lastName = lastName;
        this.timeZoneCountry = timeZoneCountry;
        this.languageCulture = languageCulture;
        this.homeScreenPreference = homeScreenPreference;
        this.emailAddress = emailAddress;
        this.timeZoneNodeTypeValueId = timeZoneNodeTypeValueId;
    }
}
