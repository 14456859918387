import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EnterKey } from "../../../core/constants/KeyboardKeys";
import { ChecklistQuestions, Common } from "../../../core/constants/translation-namespace";
import useKeyPress from "../../../core/hooks/keyPress";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { referentialLinkTypeColumnNames } from "../../../core/utilities/dataTableColumns";
import { referentialLinkTypeColumnToProperty } from "../../../core/utilities/dataTableColumnToProperty";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import BaseReferentialLinkTypeDto from "../../../domain/dtos/referential-links/base-referential-link-type-dto";
import {
    defaultFilterReferentialLinkTypesDto,
    FilterReferentialLinkTypeDto,
} from "../../../domain/dtos/referential-links/filter-referential-link-type-dto";
import { useGetReferentialLinkTypes } from "../../../domain/viewmodels/referential-link-types/referential-link-type-viewmodel";
import { CreateLink } from "../../atoms/SbLink";
import { DataTable } from "../../organisms/DataTable";
import { ReferentialLinkTypesFilter } from "../../organisms/filters/ReferentialLinkTypesFilter";

interface SearchParams {
    referentialLinkTypeName: string | null;
    referentialLinkValue: string | null;
}

const createSearchParams = (
    referentialLinkTypeName: string | null,
    referentialLinkValue: string | null
): SearchParams => ({
    referentialLinkTypeName: referentialLinkTypeName,
    referentialLinkValue: referentialLinkValue,
});

const defaultSearchParams: SearchParams = createSearchParams(null, null);

const ReferentialLinkTypesContainer: React.FC = () => {
    const [searchParams, setSearchParams] = useState<SearchParams>(defaultSearchParams);
    const [filterDto, setFilterDto] = useState<FilterReferentialLinkTypeDto>(
        defaultFilterReferentialLinkTypesDto
    );

    const menu = useMenu();
    const navigate = useNavigate();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const getReferentialLinkTypes = useGetReferentialLinkTypes(filterDto);

    useLoader(isQueryLoading(getReferentialLinkTypes), ReferentialLinkTypesContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.ReferentialLinkTypes);
    }, []);

    const sortBy = (column: string): void => {
        setFilterDto({
            ...filterDto,
            sortByColumn: referentialLinkTypeColumnToProperty[column],
            sortByDescending: !filterDto.sortByDescending,
        });
    };

    const changeReferentialLinkTypeName = (referentialLinkTypeName: string): void => {
        setSearchParams({
            ...searchParams,
            referentialLinkTypeName:
                referentialLinkTypeName !== "" ? referentialLinkTypeName : null,
        });
    };

    const changeReferentialLinkValue = (referentialLinkValue: string): void => {
        setSearchParams({
            ...searchParams,
            referentialLinkValue: referentialLinkValue !== "" ? referentialLinkValue : null,
        });
    };

    const search = (): void => {
        setFilterDto({ ...filterDto, pageNumber: 1, ...searchParams });
    };

    const resetFilter = (): void => {
        setSearchParams(defaultSearchParams);
        setFilterDto(defaultFilterReferentialLinkTypesDto);
    };

    const navigateToEditReferentialLinkType = (metadata: BaseReferentialLinkTypeDto): void => {
        const { referentialLinkTypeId, name } = metadata;

        navigate(`${getPath(AccordionTitles.ReferentialLinkTypes)}/${referentialLinkTypeId}/edit`, {
            state: { name: name },
        });
    };

    const navigateToDeleteReferentialLinkType = (metadata: BaseReferentialLinkTypeDto): void => {
        const { referentialLinkTypeId, name } = metadata;

        navigate(
            `${getPath(AccordionTitles.ReferentialLinkTypes)}/${referentialLinkTypeId}/delete`,
            {
                state: { name: name },
            }
        );
    };

    const navigateToViewReferentialLinkType = (metadata: BaseReferentialLinkTypeDto): void => {
        const { referentialLinkTypeId, name } = metadata;

        navigate(`${getPath(AccordionTitles.ReferentialLinkTypes)}/${referentialLinkTypeId}`, {
            state: { name: name },
        });
    };

    useKeyPress(EnterKey, search, searchParams);

    return (
        <>
            <PageHeading>{t("ReferentialLinkTypes")}</PageHeading>
            <PageSubHeading>{t("ReferentialLinkTypeHeaderHelpText")}</PageSubHeading>
            <SectionVerticalSpace />

            <ReferentialLinkTypesFilter
                referentialLinkTypeName={searchParams.referentialLinkTypeName}
                changeReferentialLinkTypeName={changeReferentialLinkTypeName}
                referentialLinkValue={searchParams.referentialLinkValue}
                changeReferentialLinkValue={changeReferentialLinkValue}
                search={search}
                resetFilter={resetFilter}
            />
            <SectionVerticalSpace />

            <EndAlignedDiv>
                <CreateLink
                    label={t("CreateNew", { keyPrefix: Common })}
                    navigateTo={`${getPath(AccordionTitles.ReferentialLinkTypes)}/create`}
                />
            </EndAlignedDiv>
            <LargeVerticalSpace />

            {isQuerySuccessful(getReferentialLinkTypes) && (
                <DataTable
                    keyPrefix={ChecklistQuestions}
                    columns={referentialLinkTypeColumnNames}
                    rows={getReferentialLinkTypes.data!.rows}
                    linkItem={navigateToViewReferentialLinkType}
                    viewItem={navigateToViewReferentialLinkType}
                    editItem={navigateToEditReferentialLinkType}
                    deleteItem={navigateToDeleteReferentialLinkType}
                    sortBy={sortBy}
                    totalItems={getReferentialLinkTypes.data!.recordCount}
                    paginationDto={filterDto}
                    setPaginationDto={setFilterDto}
                />
            )}
        </>
    );
};

export default ReferentialLinkTypesContainer;
