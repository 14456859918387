import { useDebounce } from "@uidotdev/usehooks";
import { ReactElement, useEffect, useRef, useState } from "react";
import { HierarchyMenuType } from "../../../core/hierarchyMenuType";
import { useMenu } from "../../../core/store/menu-context";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesPartiallySuccessful,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import { HierarchyTypeDto } from "../../../domain/dtos/hierarchy/hierarchy-type-dto";
import { NodeHierarchyDescendantResponse } from "../../../domain/responses/hierarchy/node-hierarchy-response";
import {
    useFilterNodeHierarchy,
    useFilterNodeHierarchyByType,
    useFilterNodeHierarchyUpwards,
    useLookupHierarchyNodesPath,
} from "../../../domain/viewmodels/hierarchy/node-hierarchy-viewmodel";
import HierarchyTreeContainer, {
    createDataManagementProps,
    createLeafDataManagementProps,
    DataManagementProps,
    LeafDataManagementProps,
} from "../../hierarchy/HierarchyTreeContainer";
import { ComponentTitledPanel } from "../../molecules/SbPanel";
import HierarchyActionsContainer from "../../organisms/HierarchyActions";

const defaultDepth = 2;
const searchDepth = 1;

interface FilterProps {
    hierarchyTypeId: number | null;
    getForLeaf: boolean;
    nodeIdToExpand: number | null;
    depth: number;
}

const defaultFilterProps = {
    hierarchyTypeId: 1,
    getForLeaf: false,
    nodeIdToExpand: null,
    depth: defaultDepth,
};

const NodeHierarchyContainer: React.FC<{
    menuType?: HierarchyMenuType;
    hierarchyTypes: HierarchyTypeDto[];
    selectNode?: (nodeId: number) => void;
    viewInHierarchyId?: number;
}> = ({
    menuType = HierarchyMenuType.Details |
        HierarchyMenuType.Edit |
        HierarchyMenuType.NodeActions |
        HierarchyMenuType.ViewUsers,
    hierarchyTypes,
    selectNode,
    viewInHierarchyId,
}) => {
    const [filterProps, setFilterProps] = useState<FilterProps>(defaultFilterProps);
    const [treeData, setTreeData] = useState<NodeHierarchyDescendantResponse | undefined>(
        undefined
    );
    const [searchNodeId, setSearchNodeId] = useState<number | null>(null);

    const [searchText, setSearchText] = useState<string | null>(null);

    const rootNodeId = useRef<number | null>(null);

    const menu = useMenu();

    const { hierarchyTypeId, getForLeaf, nodeIdToExpand } = filterProps;

    const debounceSearch = useDebounce(searchText, 600);

    const lookupHierarchyNodesPath = useLookupHierarchyNodesPath(hierarchyTypeId, debounceSearch);
    const lookupHierarchyNodesPathData = lookupHierarchyNodesPath.data!;

    const filterNodeHierarchyByType = useFilterNodeHierarchyByType(
        hierarchyTypeId,
        defaultDepth,
        !viewInHierarchyId
    );

    const filterNodeHierarchy = useFilterNodeHierarchy(
        rootNodeId.current,
        searchDepth,
        !getForLeaf && !viewInHierarchyId && !searchNodeId
    );

    const filterNodeHierarchyForLeafNode = useFilterNodeHierarchy(
        nodeIdToExpand,
        searchDepth,
        getForLeaf
    );

    const filterNodeHierarchyUpwards = useFilterNodeHierarchyUpwards(
        viewInHierarchyId ? viewInHierarchyId : null
    );

    const searchNodeHierarchyUpwards = useFilterNodeHierarchyUpwards(searchNodeId);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.VisualTree);
    }, []);

    useEffect(() => {
        if (isQueryLoading(filterNodeHierarchyByType)) {
            setTreeData(undefined);
            return;
        }

        if (!viewInHierarchyId && !searchNodeId && isQuerySuccessful(filterNodeHierarchyByType)) {
            setTreeData(filterNodeHierarchyByType.data);
            rootNodeId.current = filterNodeHierarchyByType.data?.nodeId ?? null;
        }
    }, [filterNodeHierarchyByType]);

    useEffect(() => {
        if (isQuerySuccessful(filterNodeHierarchy)) {
            setTreeData(filterNodeHierarchy.data);
        }
    }, [filterNodeHierarchy.data]);

    useEffect(() => {
        if (isQueryLoading(searchNodeHierarchyUpwards)) {
            setTreeData(undefined);
            return;
        }

        if (!viewInHierarchyId && searchNodeId && isQuerySuccessful(searchNodeHierarchyUpwards)) {
            setTreeData(searchNodeHierarchyUpwards.data);
        }
    }, [searchNodeHierarchyUpwards.data, searchNodeId, viewInHierarchyId]);

    useEffect(() => {
        if (isQueryLoading(filterNodeHierarchyUpwards)) {
            setTreeData(undefined);
            return;
        }

        if (viewInHierarchyId && !searchNodeId && isQuerySuccessful(filterNodeHierarchyUpwards)) {
            setTreeData(filterNodeHierarchyUpwards.data);
        }
    }, [filterNodeHierarchyUpwards.data, searchNodeId, viewInHierarchyId]);

    const onLeafClicked = (nodeId: number): void =>
        setFilterProps({
            ...filterProps,
            getForLeaf: true,
            nodeIdToExpand: nodeId,
        });

    const onHierarchyTypeChanged = (hierarchyTypeId: number): void => {
        setFilterProps({
            ...filterProps,
            hierarchyTypeId: hierarchyTypeId,
            depth: defaultDepth,
        });
    };

    const buildDataManagementProps = (): DataManagementProps =>
        createDataManagementProps(
            treeData,
            areQueriesLoading([
                filterNodeHierarchyByType,
                filterNodeHierarchy,
                filterNodeHierarchyUpwards,
            ]),
            areQueriesPartiallySuccessful([
                filterNodeHierarchyByType,
                filterNodeHierarchy,
                filterNodeHierarchyUpwards,
            ])
        );

    const buildLeafDataManagementProps = (): LeafDataManagementProps =>
        createLeafDataManagementProps(
            filterNodeHierarchyForLeafNode.data,
            isQueryLoading(filterNodeHierarchyForLeafNode),
            isQuerySuccessful(filterNodeHierarchyForLeafNode),
            onLeafClicked
        );

    const buildFilterComponent = (): ReactElement<HTMLFormElement> => (
        <HierarchyActionsContainer
            hierarchyTypes={hierarchyTypes}
            onHierarchyTypeChanged={onHierarchyTypeChanged}
            onNodeSelected={setSearchNodeId}
            isLoading={isQueryLoading(lookupHierarchyNodesPath)}
            lookupNodesList={lookupHierarchyNodesPathData}
            setSearchText={setSearchText}
        />
    );

    return (
        <ComponentTitledPanel component={buildFilterComponent()}>
            <HierarchyTreeContainer
                depth={defaultDepth}
                focusedPathEnabled={true}
                dataManagement={buildDataManagementProps()}
                dataDetailsBaseNavigationUrl={getPath(AccordionTitles.VisualTree)}
                menuType={menuType}
                leafDataManagement={buildLeafDataManagementProps()}
                selectNode={selectNode}
            />
        </ComponentTitledPanel>
    );
};

export default NodeHierarchyContainer;
