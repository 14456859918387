import React from "react";
import styled, { css } from "styled-components";

interface StyledSbNotificationStatusDotProps {
    $variant: string;
}

const StyledSbNotificationStatusDot = styled.div<StyledSbNotificationStatusDotProps>`
    position: absolute;
    top: ${(props) => props.theme.padding.md};
    left: ${(props) => props.theme.padding.md};
    border-radius: 50%;
    width: ${(props) => props.theme.padding.lg};
    height: ${(props) => props.theme.padding.lg};

    ${({ $variant }) =>
        $variant === "danger" &&
        css`
            background-color: ${(props) => props.theme.palette.danger};
        `};
    ${({ $variant }) =>
        $variant === "success" &&
        css`
            background-color: ${(props) => props.theme.palette.success};
        `};
`;

export const SbNotificationStatusDot: React.FC<{
    variant: "danger" | "success";
}> = ({ variant }) => {
    return <StyledSbNotificationStatusDot $variant={variant} />;
};
