import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EnterKey } from "../../../core/constants/KeyboardKeys";
import { Common, NonWorkingDays } from "../../../core/constants/translation-namespace";
import useKeyPress from "../../../core/hooks/keyPress";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { nonWorkingDaySetColumnNames } from "../../../core/utilities/dataTableColumns";
import { nonWorkingDaySetColumnToProperty } from "../../../core/utilities/dataTableColumnToProperty";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import {
    defaultFilterNonWorkingDaySetDto,
    FilterNonWorkingDaySetDto,
} from "../../../domain/dtos/non-working-days/filter-non-working-day-set-dto";
import { NonWorkingDaySetDto } from "../../../domain/dtos/non-working-days/non-working-day-set-dto";
import { useFilterNonWorkingDaySets } from "../../../domain/viewmodels/non-working-days/view-non-working-day-sets-viewmodel";
import { CreateLink } from "../../atoms/SbLink";
import { DataTable } from "../../organisms/DataTable";
import { NonWorkingDaySetsFilter } from "../../organisms/filters/NonWorkingDaySetsFilter";

interface SearchParams {
    nonWorkingDaySetName: string | null;
    nonWorkingDayName: string | null;
    date: string | null;
}

const createSearchParams = (
    nonWorkingDaySetName: string | null,
    nonWorkingDayName: string | null,
    date: string | null
): SearchParams => ({
    nonWorkingDaySetName: nonWorkingDaySetName,
    nonWorkingDayName: nonWorkingDayName,
    date: date,
});

const defaultSearchParams: SearchParams = createSearchParams(null, null, null);

const NonWorkingDaySetsContainer: React.FC = () => {
    const [searchParams, setSearchParams] = useState<SearchParams>(defaultSearchParams);
    const [filterDto, setFilterDto] = useState<FilterNonWorkingDaySetDto>(
        defaultFilterNonWorkingDaySetDto
    );
    const menu = useMenu();
    const navigate = useNavigate();
    const { t } = useTranslation("translation", { keyPrefix: NonWorkingDays });

    const getFilterWorkingDaySets = useFilterNonWorkingDaySets(filterDto);

    useLoader(isQueryLoading(getFilterWorkingDaySets), NonWorkingDaySetsContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.NonWorkingDay);
    }, []);

    const sortBy = (column: string): void => {
        setFilterDto({
            ...filterDto,
            sortByColumn: nonWorkingDaySetColumnToProperty[column],
            sortByDescending: !filterDto.sortByDescending,
        });
    };

    const changeNonWorkingDaySetName = (nonWorkingDaySetName: string): void => {
        setSearchParams({
            ...searchParams,
            nonWorkingDaySetName: nonWorkingDaySetName !== "" ? nonWorkingDaySetName : null,
        });
    };

    const changeNonWorkingDayName = (nonWorkingDayName: string): void => {
        setSearchParams({
            ...searchParams,
            nonWorkingDayName: nonWorkingDayName !== "" ? nonWorkingDayName : null,
        });
    };

    const changeDate = (date: string): void => {
        setSearchParams({
            ...searchParams,
            date: date !== "" ? date : null,
        });
    };

    const search = (): void => {
        setFilterDto({ ...filterDto, pageNumber: 1, ...searchParams });
    };

    const resetFilter = (): void => {
        setSearchParams(defaultSearchParams);
        setFilterDto(defaultFilterNonWorkingDaySetDto);
    };

    const navigateToViewNonWorkingDaySet = (metadata: NonWorkingDaySetDto): void => {
        const { nonWorkingDaySetId } = metadata;

        navigate(`${getPath(DrawerTitles.NonWorkingDay)}/${nonWorkingDaySetId}`);
    };

    const navigateToEditNonWorkingDaySet = (metadata: NonWorkingDaySetDto): void => {
        const { nonWorkingDaySetId } = metadata;

        navigate(`${getPath(DrawerTitles.NonWorkingDay)}/${nonWorkingDaySetId}/edit`);
    };

    const navigateToDeleteNonWorkingDaySet = (metadata: NonWorkingDaySetDto): void => {
        const { nonWorkingDaySetId } = metadata;

        navigate(`${getPath(DrawerTitles.NonWorkingDay)}/${nonWorkingDaySetId}/delete`);
    };

    useKeyPress(EnterKey, search, searchParams);

    return (
        <>
            <PageHeading>{t("NonWorkingDaySets")}</PageHeading>
            <PageSubHeading>{t("HeaderHelpTextIndex")}</PageSubHeading>
            <SectionVerticalSpace />

            <NonWorkingDaySetsFilter
                nonWorkingDaySetName={searchParams.nonWorkingDaySetName}
                changeNonWorkingDaySetName={changeNonWorkingDaySetName}
                nonWorkingDayName={searchParams.nonWorkingDayName}
                changeNonWorkingDayName={changeNonWorkingDayName}
                date={searchParams.date}
                changeDate={changeDate}
                search={search}
                resetFilter={resetFilter}
            />
            <SectionVerticalSpace />

            <EndAlignedDiv>
                <CreateLink
                    label={t("CreateNew", { keyPrefix: Common })}
                    navigateTo={`${getPath(DrawerTitles.NonWorkingDay)}/create`}
                />
            </EndAlignedDiv>
            <LargeVerticalSpace />

            {isQuerySuccessful(getFilterWorkingDaySets) && (
                <DataTable
                    keyPrefix={NonWorkingDays}
                    columns={nonWorkingDaySetColumnNames}
                    rows={getFilterWorkingDaySets.data!.rows}
                    linkItem={navigateToViewNonWorkingDaySet}
                    viewItem={navigateToViewNonWorkingDaySet}
                    editItem={navigateToEditNonWorkingDaySet}
                    deleteItem={navigateToDeleteNonWorkingDaySet}
                    sortBy={sortBy}
                    totalItems={getFilterWorkingDaySets.data!.recordCount}
                    paginationDto={filterDto}
                    setPaginationDto={setFilterDto}
                />
            )}
        </>
    );
};

export default NonWorkingDaySetsContainer;
