import React, { useEffect, useState } from "react";
import {
    EndAlignedDiv,
    StyledBoldVariantText,
    StyledBorderedSection,
} from "../../../core/theme/global-styles";
import QuestionAnswerInstanceDto from "../../../domain/dtos/answer-capture/question-answer-instance-dto";
import { useAnswerCapture } from "../../../domain/stores/answer-capture-context";
import { useOnQuestionReviewSubmit } from "../../../domain/viewmodels/answer-capture/question-capture-review-comment-viewmodel";
import SbTextArea from "../../atoms/input/SbTextArea";
import { CancelButton, ConfirmButton } from "../../atoms/SbButton";
import SbLabelText from "../../atoms/SbLabelText";

const QuestionCaptureReviewComment: React.FC<{
    questionAnswerInstanceDto: QuestionAnswerInstanceDto;
    onCancelClicked: () => void;
}> = ({ questionAnswerInstanceDto, onCancelClicked }) => {
    const [savedComment, setSavedComment] = useState<string | null | undefined>();
    const [comment, setComment] = useState<string | null | undefined>();

    const answerCapture = useAnswerCapture();
    const onQuestionReviewSubmit = useOnQuestionReviewSubmit();

    useEffect(() => {
        const savedComment =
            answerCapture.getQuestionAnswer(questionAnswerInstanceDto)?.reviewCommentText;

        setSavedComment(savedComment);
        setComment(savedComment);
    }, [questionAnswerInstanceDto]);

    const onCommentChanged = (commentText: string | null): void => {
        answerCapture.updateQuestionAnswerDtosForReviewComment(
            questionAnswerInstanceDto,
            commentText
        );

        setComment(commentText);
    };

    const onConfirmClicked = (): void => {
        if (comment) {
            onQuestionReviewSubmit(questionAnswerInstanceDto.questionSetInstanceAnswerId, comment);
        }
    };

    return (
        <StyledBorderedSection>
            <StyledBoldVariantText $variant="secondary">
                <SbLabelText
                    label={"Please leave a comment below for this question to be put under review:"}
                />
            </StyledBoldVariantText>

            <SbTextArea
                name={"questionReviewComment"}
                defaultValue={savedComment}
                rows={4}
                maxLength={500}
                placeholder={"Enter a review comment"}
                onChange={onCommentChanged}
            />

            <EndAlignedDiv>
                <CancelButton onClick={onCancelClicked} />
                <ConfirmButton type={"button"} onClick={onConfirmClicked} />
            </EndAlignedDiv>
        </StyledBorderedSection>
    );
};

export default QuestionCaptureReviewComment;
