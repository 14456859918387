import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { BaseSbFieldProps } from "../../props/input/InputFieldProps";

export interface SbDecimalFieldProps extends BaseSbFieldProps {
    defaultValue?: number | null;
    onInputChange?: (_: number | null) => void;
}

const decimalNumberRegex = new RegExp(/^\d{1,}(\.\d{0,4})?$/);

const SbDecimalField: React.FC<SbDecimalFieldProps> = ({
    name,
    defaultValue,
    placeholder,
    required,
    disabled,
    onInputChange,
}) => {
    const [value, setValue] = useState<string>("");

    useEffect(() => {
        defaultValue && setDefaultValue(defaultValue);
    }, [defaultValue]);

    const setDefaultValue = (value: number): void => onChange(value.toString());

    const onChange = (value: string): void => {
        if (!value || decimalNumberRegex.test(value)) {
            setValue(value);

            onInputChange && onInputChange(value ? Number(value) : null);
        }
    };

    return (
        <Form.Control
            name={name}
            type={"text"}
            value={value}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            onChange={(event) => onChange(event.target.value)}
        />
    );
};

export default SbDecimalField;
