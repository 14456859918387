import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Email } from "../../../core/constants/translation-namespace";
import {
    ContentContainer,
    EndAlignedDiv,
    SectionContainer,
} from "../../../core/theme/global-styles";
import { ResetButton, SearchButton } from "../../atoms/SbButton";
import { FilterInput } from "../../molecules/FilterInput";
import { FilterCheckbox } from "../../molecules/input/FilterCheckbox";

export const EmailQueueFilter: React.FC<{
    subject: string | null;
    changeSubject: (subject: string) => void;
    recipients: string | null;
    changeRecipients: (recipients: string) => void;
    dateSentFrom: string | null;
    changeDateSentFrom: (dateSentFrom: string) => void;
    dateSentTo: string | null;
    failedToSend: boolean;
    changeDateSentTo: (dateSentTo: string) => void;
    changeFailedToSend: (name: string, failedToSend: boolean) => void;
    search: () => void;
    resetFilter: () => void;
}> = ({
    subject,
    changeSubject,
    recipients,
    changeRecipients,
    dateSentFrom,
    changeDateSentFrom,
    dateSentTo,
    failedToSend,
    changeDateSentTo,
    changeFailedToSend,
    search,
    resetFilter,
}) => {
    const { t } = useTranslation("translation", { keyPrefix: Email });
    return (
        <ContentContainer>
            <SectionContainer>
                <Row>
                    <Col>
                        <FilterInput
                            title={`${t("Subject")}`}
                            name={"subject"}
                            type={"text"}
                            onChangeEventHandler={changeSubject}
                            value={subject ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={`${t("Recipients")}`}
                            name={"recipients"}
                            type={"text"}
                            onChangeEventHandler={changeRecipients}
                            value={recipients ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={`${t("DateSentFrom")}`}
                            name={"dateSentFrom"}
                            type={"date"}
                            onChangeEventHandler={changeDateSentFrom}
                            value={dateSentFrom ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={`${t("DateSentTo")}`}
                            name={"dateSentTo"}
                            type={"date"}
                            onChangeEventHandler={changeDateSentTo}
                            value={dateSentTo ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterCheckbox
                            title={`${t("FailedToSend")}`}
                            name={"failedToSend"}
                            onChangeEventHandler={changeFailedToSend}
                            defaultSelected={failedToSend}
                        />
                    </Col>
                    <Col></Col>
                </Row>
            </SectionContainer>
            <EndAlignedDiv>
                <SearchButton onClick={search} />
                <ResetButton onClick={resetFilter} />
            </EndAlignedDiv>
        </ContentContainer>
    );
};
