import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NodeTypeValues } from "../../../core/constants/translation-namespace";
import {
    ContentContainer,
    EndAlignedDiv,
    SectionContainer,
} from "../../../core/theme/global-styles";
import { ResetButton, SearchButton } from "../../atoms/SbButton";
import { FilterInput } from "../../molecules/FilterInput";

export const NodeTypeValuesFilter: React.FC<{
    nodeTypeValueValue: string | null;
    changeNodeTypeValueValue: (lastName: string) => void;
    search: () => void;
    resetFilter: () => void;
}> = ({ nodeTypeValueValue, changeNodeTypeValueValue, search, resetFilter }) => {
    const { t } = useTranslation("translation", { keyPrefix: NodeTypeValues });
    return (
        <ContentContainer>
            <SectionContainer>
                <Row>
                    <Col>
                        <FilterInput
                            title={t("NodeTypeValue")}
                            name={"nodeTypeValueValue"}
                            type={"text"}
                            onChangeEventHandler={changeNodeTypeValueValue}
                            value={nodeTypeValueValue ?? ""}
                        />
                    </Col>
                </Row>
            </SectionContainer>
            <EndAlignedDiv>
                <SearchButton onClick={search} />
                <ResetButton onClick={resetFilter} />
            </EndAlignedDiv>
        </ContentContainer>
    );
};
