import { useState } from "react";
import { SingleValue } from "react-select";
import { isQueryLoading } from "../../../core/utilities/responseStateHelper";
import BaseReferentialLinkDto from "../../../domain/dtos/referential-links/base-referential-link-dto";
import BaseReferentialLinkTypeReferentialLinkDto from "../../../domain/dtos/referential-links/base-referential-link-type-referential-link-dto";
import {
    createFilterReferentialLinkTypesDto,
    FilterReferentialLinkTypeDto,
} from "../../../domain/dtos/referential-links/filter-referential-link-type-dto";
import { useFilterReferentialLinks } from "../../../domain/viewmodels/trigger-adhocs/trigger-adhocs-viewmodel";
import { SbFormCreateOnNoResultsSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";

const pageNumber = 1;
const pageSize = 50;
const orderByDesc = false;
const refLinkIdSortColumnName = "ReferentialLinkId";
const refLinkValueSortColumnName = "Value";

const ReferentialLinksSelectGroup: React.FC<{
    onReferentialLinkSelected: (
        option: SingleValue<BaseReferentialLinkDto>,
        referentialLinkTypeId: number
    ) => void;
    index: number;
    baseReferentialLinkTypeReferentialLinkDto: BaseReferentialLinkTypeReferentialLinkDto;
    createNewReferentialLinkSelectValue: (searchText: string) => BaseReferentialLinkDto;
}> = ({
    onReferentialLinkSelected,
    index,
    baseReferentialLinkTypeReferentialLinkDto,
    createNewReferentialLinkSelectValue,
}) => {
    const [filterDto, setFilterDto] = useState<FilterReferentialLinkTypeDto>(
        createFilterReferentialLinkTypesDto(
            baseReferentialLinkTypeReferentialLinkDto.referentialLinkTypeDto.name,
            "",
            null,
            pageNumber,
            pageSize,
            refLinkIdSortColumnName,
            orderByDesc
        )
    );

    const setRefLinksFilterDto = (searchText: string): void => {
        setFilterDto(
            createFilterReferentialLinkTypesDto(
                baseReferentialLinkTypeReferentialLinkDto.referentialLinkTypeDto.name,
                searchText,
                null,
                pageNumber,
                pageSize,
                searchText ? refLinkValueSortColumnName : refLinkIdSortColumnName,
                orderByDesc
            )
        );
    };

    const refLinkItems = useFilterReferentialLinks(filterDto);

    return (
        <>
            <SbFormCreateOnNoResultsSelectFieldGroup
                key={index}
                name={`referentialLinkType_${baseReferentialLinkTypeReferentialLinkDto.referentialLinkTypeDto.referentialLinkTypeId}`}
                label={baseReferentialLinkTypeReferentialLinkDto.referentialLinkTypeDto.name}
                items={refLinkItems.data}
                placeholderText={"Please select value"}
                onChange={(option: SingleValue<BaseReferentialLinkDto>) =>
                    onReferentialLinkSelected(
                        option,
                        baseReferentialLinkTypeReferentialLinkDto.referentialLinkTypeDto
                            .referentialLinkTypeId
                    )
                }
                onSearchTextChanged={(searchText) => setRefLinksFilterDto(searchText)}
                createNewValue={createNewReferentialLinkSelectValue}
                searchable
                clearable={false}
                itemDisplayText={(option: BaseReferentialLinkDto) => option.value}
                required={false}
                loading={isQueryLoading(refLinkItems)}
            />
        </>
    );
};

export default ReferentialLinksSelectGroup;
