import {
    BasePaginationDto,
    createBasePaginationDto,
    defaultBasePaginationDto,
} from "../common/base-pagination-dto";

export interface FilterAnswerTypesDto extends BasePaginationDto {
    name: string | null;
    answerCategoryId: number | null;
}

export const createFilterAnswerTypesDto = (
    name: string | null,
    answerCategoryId: number | null,
    pageNumber: number,
    pageSize: number
): FilterAnswerTypesDto => ({
    name: name,
    answerCategoryId: answerCategoryId,
    ...createBasePaginationDto(pageNumber, pageSize),
});

export const defaultFilterAnswerTypesDto = (): FilterAnswerTypesDto => {
    const { pageNumber, pageSize } = defaultBasePaginationDto;

    return createFilterAnswerTypesDto(null, null, pageNumber, pageSize);
};
