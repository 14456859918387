import { Col, Row, Table } from "react-bootstrap";
import styled from "styled-components";
import { DetailsLabel, DetailsValue, DetailsValueBlue } from "../../core/theme/global-styles";
import { QuestionSetQuestionCommentDto } from "../../domain/dtos/review-reports/question-set-question-comment-dto";

const StyledCommentRow = styled(Row)`
    padding: ${(props) => props.theme.padding.sm};
`;

const StyledLeftCommentCol = styled(Col)`
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    padding-right: ${(props) => props.theme.padding.lg};
`;

const StyledCenterCommentRightCol = styled(Col)`
    display: flex;
    justify-content: flex-end;
`;

const StyledRightCommentCol = styled(Col)`
    display: flex;
    flex-direction: column;
    padding-left: ${(props) => props.theme.padding.lg};
`;

const MySpeechBubble = styled.div`
    display: inline-block;
    padding: ${(props) => props.theme.padding.md};
    position: relative;
    border-radius: ${(props) => props.theme.padding.md};
    box-shadow: 0 3px 6px ${(props) => props.theme.palette.grey};
    border: 2px solid ${(props) => props.theme.palette.actionInstanceStatus.cancelled};

    :before {
        border-right: 10px solid ${(props) => props.theme.palette.actionInstanceStatus.cancelled};
        border-top: 10px solid ${(props) => props.theme.palette.actionInstanceStatus.cancelled};
    }

    :before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 10px solid transparent;
        border-bottom: 10px solid transparent;
        left: -21px;
        top: 6px;
    }
`;

const SpeechBubble = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${(props) => props.theme.padding.md};
    position: relative;
    border-radius: ${(props) => props.theme.padding.md};
    box-shadow: 0 3px 6px ${(props) => props.theme.palette.grey};
    justify-content: flex-start;
    border: 2px solid ${(props) => props.theme.palette.actionInstanceStatus.cancelled};

    :before {
        border-left: 10px solid ${(props) => props.theme.palette.actionInstanceStatus.cancelled};
        border-top: 10px solid ${(props) => props.theme.palette.actionInstanceStatus.cancelled};
    }

    :before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-right: 10px solid transparent;
        border-bottom: 10px solid transparent;
        right: -21px;
        top: 6px;
    }
`;

const StyledCommentsSection = styled.div`
    width: 100%;
    overflow-x: hidden;
    max-height: 250px;
    overflow-y: scroll;
    word-wrap: break-word;
    border: none;
`;

const StyledNestedTd = styled.th`
    font-weight: normal;
`;

export const StyledTable = styled(Table)`
    background-color: white;
`;

const StyledNestedDiv = styled.div`
    border-radius: 0.5rem;
    border: ${(props) => props.theme.padding.sm} solid ${(props) => props.theme.palette.blue};
`;

export const QuestionSetQuestionComments: React.FC<{
    comments: (metadata: any) => QuestionSetQuestionCommentDto[] | undefined | null;
    metadata: any;
    authUserId: number;
}> = ({ comments, metadata, authUserId }) => {
    return (
        <>
            <StyledNestedTd colSpan={11}>
                <StyledTable>
                    <StyledNestedDiv>
                        <StyledCommentsSection>
                            {comments(metadata)?.map((comment) => (
                                <StyledCommentRow>
                                    {authUserId === comment.userId ? (
                                        <StyledLeftCommentCol md="2">
                                            <DetailsLabel>{`${comment.userDto.firstName} ${comment.userDto.lastName}`}</DetailsLabel>
                                            <DetailsValue>{`${comment.commentDateTimeLocal}`}</DetailsValue>
                                        </StyledLeftCommentCol>
                                    ) : (
                                        <StyledLeftCommentCol md="2" />
                                    )}

                                    {authUserId === comment.userId ? (
                                        <Col md="8">
                                            <MySpeechBubble>
                                                <DetailsValueBlue>
                                                    {comment.commentText}
                                                </DetailsValueBlue>
                                            </MySpeechBubble>
                                        </Col>
                                    ) : (
                                        <StyledCenterCommentRightCol md="8">
                                            <SpeechBubble>
                                                <DetailsValueBlue>
                                                    {comment.commentText}
                                                </DetailsValueBlue>
                                            </SpeechBubble>
                                        </StyledCenterCommentRightCol>
                                    )}

                                    {authUserId === comment.userId ? (
                                        <StyledRightCommentCol md="2" />
                                    ) : (
                                        <StyledRightCommentCol md="2">
                                            <DetailsLabel>{`${comment.userDto.firstName} ${comment.userDto.lastName}`}</DetailsLabel>
                                            <DetailsValue>{`${comment.commentDateTimeLocal}`}</DetailsValue>
                                        </StyledRightCommentCol>
                                    )}
                                </StyledCommentRow>
                            ))}
                        </StyledCommentsSection>
                    </StyledNestedDiv>
                </StyledTable>
            </StyledNestedTd>
        </>
    );
};
