import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { AccordionLinks } from "../../core/constants/translation-namespace";
import { useMenu } from "../../core/store/menu-context";
import { StyledMenuItemLink } from "../../core/theme/global-styles";
import { getIcon } from "../../core/utilities/getIcon";
import { getPath } from "../../core/utilities/getPath";

interface StyledDrawerLinkProps {
    readonly $active?: boolean;
    readonly $drawerOpen: boolean;
}

const StyledDrawerLink = styled(StyledMenuItemLink)<StyledDrawerLinkProps>`
    width: 100%;
    padding-left: ${(props) => props.$drawerOpen && props.theme.padding.lg};
    padding-top: ${(props) => props.theme.padding.lg};
    padding-bottom: ${(props) => props.theme.padding.lg};
    display: flex;
    align-items: center;
    justify-content: ${(props) => (props.$drawerOpen ? "flex-start" : "center")};
    background-image: ${(props) =>
        props.$active &&
        "linear-gradient(to right, rgba(177, 80, 197, 0), rgba(177, 80, 197, 1) 50%)"};
`;

const StyledText = styled.div`
    padding-left: ${(props) => props.theme.padding.sm};
`;

const DrawerLink: React.FC<{
    title: string;
    active: boolean;
}> = ({ title, active }) => {
    const menu = useMenu();
    const { t } = useTranslation("translation", { keyPrefix: AccordionLinks });

    return (
        <StyledDrawerLink
            $active={active}
            $drawerOpen={menu.drawerOpen}
            to={getPath(title)}
            onClick={() => {
                menu.changeActiveAccordion("");
            }}
        >
            {getIcon(title, menu.drawerOpen ? "16px" : "32px")}
            {menu.drawerOpen && <StyledText>{t(title)}</StyledText>}
        </StyledDrawerLink>
    );
};

export default DrawerLink;
