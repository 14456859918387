export const commonFields = [
    "Actions",
    "IsDeleted",
    "LastModifiedBy",
    "DateLastModified",
    "Description",
    "Category",
    "Path",
    "Select",
    "Show",
    "Hide",
    "IsEnabled",
    "Value",
    "Name",
    "SubType",
    "ValueType",
    "IsRequired",
    "Default",
    "Rating",
    "Status",
    "Details",
];
