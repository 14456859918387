import ReviewRating from "../../enums/review-reports/review-ratings";
import { ReactSelectDto } from "../common/react-select-dto";
import { NodeTypeValueDto } from "../hierarchy/node-type-value-dto";

export class FilterReviewReportSetHistoryDto {
    name: string | null;
    reviewRating: ReactSelectDto<ReviewRating> | null;
    startDate: string | null;
    endDate: string | null;
    country: NodeTypeValueDto | null;

    public constructor(
        name: string | null,
        reviewRating: ReactSelectDto<ReviewRating> | null,
        startDate: string | null,
        endDate: string | null,
        country: NodeTypeValueDto | null
    ) {
        this.name = name;
        this.reviewRating = reviewRating;
        this.startDate = startDate;
        this.endDate = endDate;
        this.country = country;
    }

    public static defaultFilterReviewReportSetHistoryDto = (): FilterReviewReportSetHistoryDto =>
        new FilterReviewReportSetHistoryDto(null, null, null, null, null);
}
