import { FilterReferentialLinkTypeDto } from "../../dtos/referential-links/filter-referential-link-type-dto";
import {
    buildPaginationSearchParameters,
    createPaginationRequest,
    PaginationRequest,
} from "../common/pagination-request";

export interface FilterReferentialLinkTypeRequest extends PaginationRequest {
    referentialLinkTypeName: string | null;
    referentialLinkValue: string | null;
    referentialLinkTypeId: number | null;
}

export const createFilterReferentialLinkTypeRequestFromDto = (
    dto: FilterReferentialLinkTypeDto
): FilterReferentialLinkTypeRequest => {
    const { pageNumber, pageSize, sortByColumn, sortByDescending } = dto;

    return {
        ...dto,
        ...createPaginationRequest(pageNumber, pageSize, sortByColumn, sortByDescending),
    };
};

export const buildFilterReferentialLinkTypeSearchParameters = (
    request: FilterReferentialLinkTypeRequest
): URLSearchParams => {
    const searchParams = buildPaginationSearchParameters(request);

    if (request.referentialLinkTypeName) {
        searchParams.set("referentialLinkTypeName", request.referentialLinkTypeName);
    }

    if (request.referentialLinkValue) {
        searchParams.set("referentialLinkValue", request.referentialLinkValue);
    }

    if (request.referentialLinkTypeId) {
        searchParams.set("referentialLinkTypeId", request.referentialLinkTypeId.toString());
    }

    return searchParams;
};
