import { PeriodType } from "../../enums/PeriodType";
import { IntervalOverridesResponse } from "../../responses/checklists/interval-overrides-response";
import { BaseIntervalDto } from "../common/base-interval-dto";

export default class IntervalOverridesDto {
    dueDateTimeUtc: Date;
    openBeforeDueIntervalDto: BaseIntervalDto;

    public constructor(
        dueDateTimeUtc: Date,
        openBeforeDueValue: number,
        openBeforeDuePeriodType: PeriodType
    ) {
        this.dueDateTimeUtc = dueDateTimeUtc;
        this.openBeforeDueIntervalDto = new BaseIntervalDto(
            openBeforeDueValue,
            openBeforeDuePeriodType
        );
    }

    public static toIntervalOverridesDto = (
        response: IntervalOverridesResponse
    ): IntervalOverridesDto =>
        new IntervalOverridesDto(
            response.dueDateTimeUtc,
            response.adhocIntervalResponse.value,
            response.adhocIntervalResponse.periodType
        );
}
