import { HTTPError } from "ky";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { EditHierarchyTypeEvent } from "../../../core/constants/application-insights-events";
import {
    Common,
    HierarchyTypes,
    NodeTypes,
    NodeTypeValues,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import {
    HierarchyAssignable,
    HierarchyAssignableLabels,
    translateHierarchyAssignableLabel,
} from "../../../domain/enums/hierarchy/HierarchyAssignable";
import {
    createEditParameters,
    useEditHierarchyType,
    useGetHierarchyTypeDetails,
} from "../../../domain/viewmodels/hierarchy/edit-hierarchy-type-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import SbFormCheckFieldGroup from "../../molecules/input/SbFormCheckFieldGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const EditHierarchyTypeContainer: React.FC = () => {
    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const navigate = useNavigate();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const hierarchyTypeId = Number(useParams().hierarchyTypeId);
    const { t } = useTranslation("translation", { keyPrefix: NodeTypes });

    const getHierarchyTypeDetails = useGetHierarchyTypeDetails(hierarchyTypeId);
    const editHierarchyType = useEditHierarchyType();

    const hierarchyTypeDetailsResponseData = getHierarchyTypeDetails.data;

    useLoader(
        isQueryLoading(getHierarchyTypeDetails) || isMutationLoading(editHierarchyType),
        EditHierarchyTypeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.VisualStructure);
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const params = createEditParameters(hierarchyTypeId, new FormData(event.currentTarget));

        editHierarchyType.mutate(params, {
            onSuccess: async () => {
                trackAppInsightsEvent(auth.email, window.location.href, EditHierarchyTypeEvent);

                const params = [
                    createNavigateSearchParameter("success", "true"),
                    createNavigateSearchParameter(
                        "messageKey",
                        "UpdateHierarchyTypeSuccessMessage"
                    ),
                ];

                navigateSearch(`${getPath(AccordionTitles.Admin)}/${hierarchyTypeId}`, params);
            },
            onError: (error: HTTPError) => {
                trackAppInsightsException(
                    auth.email,
                    window.location.href,
                    EditHierarchyTypeEvent,
                    error
                );
                errorResponseToDisplayHandler(error);
            },
        });
    };

    return (
        <>
            <PageHeading>{t("NodeTypeEditTitle")}</PageHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(getHierarchyTypeDetails) && (
                <TextTitledPanel title={t("EditNodeTypeValue", { keyPrefix: NodeTypeValues })}>
                    <Form onSubmit={handleSubmit}>
                        <SbFormTextFieldGroup
                            name="name"
                            label={t("Name", { keyPrefix: Common })}
                            maxLength={50}
                            type="text"
                            required
                            defaultValue={hierarchyTypeDetailsResponseData!.name}
                        />

                        <SbFormCheckFieldGroup
                            fieldLabel={t("HierarchyAssignableFlag", { keyPrefix: HierarchyTypes })}
                            type="checkbox"
                            values={Array.from(HierarchyAssignableLabels.entries()).map((x) => ({
                                name: HierarchyAssignable[x[0]],
                                label: translateHierarchyAssignableLabel(t, x[1]),
                                defaultSelected:
                                    hierarchyTypeDetailsResponseData!.hierarchyAssignables.some(
                                        (y) => y == x[0]
                                    ),
                            }))}
                        />

                        <EndAlignedDiv>
                            <SaveButton type="submit" />
                            <CancelButton onClick={() => navigate(-1)} />
                        </EndAlignedDiv>
                    </Form>
                </TextTitledPanel>
            )}
        </>
    );
};

export default EditHierarchyTypeContainer;
