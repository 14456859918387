import { ActionInstanceStatusResponse } from "../../../domain/responses/actions/action-instance-status-response";
import { Response } from "../../../domain/responses/common/response-response";
import client from "../../http-client";

const route = "actioninstancestatus";

export const getActionInstanceStatus = async (
    prefixUrl: string
): Promise<Response<ActionInstanceStatusResponse[]>> =>
    await client
        .get(route, {
            prefixUrl: prefixUrl,
        })
        .json();
