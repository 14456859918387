import { QuestionScheduleBaseDto } from "./question-schedule-base-dto";

export interface ExistingQuestionSetQuestionDto extends QuestionScheduleBaseDto {
    orderIndex: number;
}

export const createExistingQuestionSetQuestionDto = (
    orderIndex: number,
    questionSetId: number,
    questionId: number,
    dueMinutesFromBaseStart: number,
    openDeltaMinutes: number,
    escalationL1MinutesFromBaseStart: number,
    escalationL2MinutesFromBaseStart: number | null,
    escalationL3MinutesFromBaseStart: number | null,
    isDefaultOpenDelta: boolean
): ExistingQuestionSetQuestionDto => ({
    orderIndex: orderIndex,
    questionSetId: questionSetId,
    questionId: questionId,
    dueMinutesFromBaseStart: dueMinutesFromBaseStart,
    openDeltaMinutes: openDeltaMinutes,
    escalationL1MinutesFromBaseStart: escalationL1MinutesFromBaseStart,
    escalationL2MinutesFromBaseStart: escalationL2MinutesFromBaseStart,
    escalationL3MinutesFromBaseStart: escalationL3MinutesFromBaseStart,
    isDefaultOpenDelta: isDefaultOpenDelta,
});
