import {
    buildPaginationSearchParameters,
    PaginationRequest,
} from "../../../domain/requests/common/pagination-request";
import { CreateWizardRequest } from "../../../domain/requests/wizards/create-wizard-request";
import { EditWizardRequest } from "../../../domain/requests/wizards/edit-wizard-request";
import { PublishWizardRequest } from "../../../domain/requests/wizards/publish-wizard-request";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import { WizardResponse } from "../../../domain/responses/wizards/wizard-response";
import client from "../../http-client";

const route = "wizardsadmin";

export const getWizards = async (
    prefixUrl: string,
    request: PaginationRequest
): Promise<Response<PaginationResponse<WizardResponse>>> => {
    const searchParams = buildPaginationSearchParameters(request);

    return await client
        .get(`${route}/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const createWizard = async (
    prefixUrl: string,
    request: CreateWizardRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/create`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const editWizard = async (
    prefixUrl: string,
    request: EditWizardRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/edit`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const getWizardDetails = async (
    prefixUrl: string,
    wizardId: number
): Promise<Response<WizardResponse>> =>
    await client
        .get(`${route}/${wizardId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const publishWizard = async (
    prefixUrl: string,
    request: PublishWizardRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/publish`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();
