import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { ChecklistAnswers, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { getEnumsForType } from "../../../core/utilities/enum-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import { createEditAnswerTypeDto } from "../../../domain/dtos/answer-types/edit-answer-type-dto";
import { createReactSelectDto, ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import AnswerCategory from "../../../domain/enums/answer-types/answer-category";
import AnswerFlagMode from "../../../domain/enums/answer-types/answer-flag-mode";
import AnswerRenderMode from "../../../domain/enums/answer-types/answer-render-mode";
import AnswerValueType from "../../../domain/enums/answer-types/answer-value-type";
import { Response } from "../../../domain/responses/common/response-response";
import {
    useEditAnswerType,
    useGetAnswerTypeDetails,
} from "../../../domain/viewmodels/answer-types/edit-answer-type-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import { translateText } from "../../helpers/translate";
import { translateWithFallback } from "../../helpers/translate-with-common-fallback";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const answerCategoriesArray = getEnumsForType(AnswerCategory).map((x) =>
    createReactSelectDto(x, AnswerCategory[x])
);

const answerFlagModesArray = getEnumsForType(AnswerFlagMode).map((x) =>
    createReactSelectDto(x, AnswerFlagMode[x])
);

const answerRenderModesArray = getEnumsForType(AnswerRenderMode).map((x) =>
    createReactSelectDto(x, AnswerRenderMode[x])
);

const answerValueTypesArray = getEnumsForType(AnswerValueType).map((x) =>
    createReactSelectDto(x, AnswerValueType[x])
);

const EditAnswerTypeContainer: React.FC = () => {
    const [answerCategorySelected, setAnswerCategorySelected] = useState<AnswerCategory | null>(
        null
    );
    const [renderMode, setRenderMode] = useState<AnswerRenderMode | null>(null);
    const [flagMode, setFlagMode] = useState<AnswerFlagMode | null>(null);
    const [valueType, setValueType] = useState<AnswerValueType | null>(null);

    const answerTypeId = Number(useParams().answerTypeId);
    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const navigate = useNavigate();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistAnswers });
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const getAnswerTypeDetails = useGetAnswerTypeDetails(answerTypeId);
    const answerTypeDetailsData = getAnswerTypeDetails.data;
    const editAnswerType = useEditAnswerType();

    useLoader(
        isQueryLoading(getAnswerTypeDetails) || isMutationLoading(editAnswerType),
        EditAnswerTypeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.AnswerTypes);
    }, []);

    useEffect(() => {
        if (answerTypeDetailsData) {
            if (answerTypeDetailsData.answerCategory) {
                setAnswerCategorySelected(answerTypeDetailsData.answerCategory);
            }
            if (answerTypeDetailsData.answerRenderMode) {
                setRenderMode(answerTypeDetailsData?.answerRenderMode);
            }
            if (answerTypeDetailsData.answerFlagMode) {
                setFlagMode(answerTypeDetailsData?.answerFlagMode);
            }
            if (answerTypeDetailsData.answerValueType) {
                setValueType(answerTypeDetailsData?.answerValueType);
            }
        }
    }, [answerTypeDetailsData]);

    const onAnswerCategoryChange = (option: SingleValue<ReactSelectDto<AnswerCategory>>): void =>
        setAnswerCategorySelected(option!.value);

    const onRenderModeSelected = (option: SingleValue<ReactSelectDto<AnswerRenderMode>>): void =>
        setRenderMode(option!.value);

    const onFlagModeSelected = (option: SingleValue<ReactSelectDto<AnswerFlagMode>>): void =>
        setFlagMode(option!.value);

    const onAnswerValueTypeSelected = (
        option: SingleValue<ReactSelectDto<AnswerValueType>>
    ): void => setValueType(option!.value);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        editAnswerType.mutate(
            createEditAnswerTypeDto(
                answerTypeId,
                new FormData(event.currentTarget),
                Number(answerCategorySelected),
                Number(renderMode),
                Number(flagMode),
                Number(valueType)
            ),
            {
                onSuccess: async (_: Response<boolean>) => {
                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "messageKey",
                            "SuccessfullyUpdatedAnswerType"
                        ),
                    ];

                    navigateSearch(
                        `${getPath(AccordionTitles.AnswerTypes)}/${answerTypeId}`,
                        params
                    );
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    return (
        <>
            <PageHeading>{t("EditAnswerType")}</PageHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(getAnswerTypeDetails) && (
                <TextTitledPanel title={t("Edit", { keyPrefix: Common })}>
                    <Form onSubmit={handleSubmit}>
                        <SbFormTextFieldGroup
                            name={"name"}
                            label={t("Name")}
                            maxLength={50}
                            type={"text"}
                            defaultValue={answerTypeDetailsData!.name}
                        />

                        <SbFormSelectFieldGroup
                            name={"answerCategory"}
                            label={t("AnswerCategory")}
                            clearable={false}
                            searchable={false}
                            required
                            items={answerCategoriesArray ?? []}
                            itemDisplayText={(option: ReactSelectDto<AnswerCategory>) =>
                                t(AnswerCategory[option.value])
                            }
                            defaultSelectedItem={answerCategoriesArray.find(
                                (x) => x.value === answerTypeDetailsData?.answerCategory
                            )}
                            onChange={onAnswerCategoryChange}
                        />

                        {answerCategorySelected == AnswerCategory.MutuallyExclusive && (
                            <>
                                <SbFormSelectFieldGroup
                                    name={"answerRenderMode"}
                                    label={t("AnswerRenderMode")}
                                    clearable={false}
                                    searchable
                                    items={answerRenderModesArray ?? []}
                                    itemDisplayText={(option: ReactSelectDto<AnswerRenderMode>) =>
                                        translateWithFallback(t, option.label, ChecklistAnswers)
                                    }
                                    defaultSelectedItem={answerRenderModesArray.find(
                                        (x) => x.value === answerTypeDetailsData?.answerRenderMode
                                    )}
                                    onChange={onRenderModeSelected}
                                />

                                <SbFormSelectFieldGroup
                                    name={"answerFlagMode"}
                                    label={t("FlagMode")}
                                    clearable={false}
                                    searchable={false}
                                    items={answerFlagModesArray ?? []}
                                    itemDisplayText={(option: ReactSelectDto<AnswerFlagMode>) =>
                                        translateText(t, option.label, ChecklistAnswers)
                                    }
                                    defaultSelectedItem={answerFlagModesArray.find(
                                        (x) => x.value === answerTypeDetailsData?.answerFlagMode
                                    )}
                                    onChange={onFlagModeSelected}
                                />
                            </>
                        )}

                        {answerCategorySelected == AnswerCategory.InputValue && (
                            <>
                                <SbFormSelectFieldGroup
                                    name={"answerValueType"}
                                    label={"Value Type"}
                                    clearable={false}
                                    searchable={false}
                                    items={answerValueTypesArray ?? []}
                                    itemDisplayText={(option: ReactSelectDto<AnswerValueType>) =>
                                        t(AnswerValueType[option.value], { keyPrefix: Common })
                                    }
                                    defaultSelectedItem={answerValueTypesArray.find(
                                        (x) => x.value === answerTypeDetailsData?.answerValueType
                                    )}
                                    onChange={onAnswerValueTypeSelected}
                                />
                            </>
                        )}

                        <EndAlignedDiv>
                            <SaveButton type="submit" />
                            <CancelButton onClick={() => navigate(-1)} />
                        </EndAlignedDiv>
                    </Form>
                </TextTitledPanel>
            )}
        </>
    );
};

export default EditAnswerTypeContainer;
