import { AdhocQuestionSetAssignmentScheduleResponse } from "../../responses/checklists/adhoc-question-set-assignment-schedule-response";
import BaseQuestionSetReferentialLinkTypeDto from "../question-sets/base-question-set-referential-link-type-dto";
import ChecklistAssignmentChecklistDto from "./checklist-assignment-checklist-dto";

export default class AdhocQuestionSetAssignmentScheduleDto {
    questionSetAssignmentScheduleId: number;
    checklistAssignmentChecklistDto: ChecklistAssignmentChecklistDto;
    questionSetReferentialLinkTypeDto: BaseQuestionSetReferentialLinkTypeDto;

    constructor(response: AdhocQuestionSetAssignmentScheduleResponse) {
        this.questionSetAssignmentScheduleId = response.questionSetAssignmentScheduleId;
        this.checklistAssignmentChecklistDto = new ChecklistAssignmentChecklistDto(
            response.checklistAssignmentChecklistResponse
        );
        this.questionSetReferentialLinkTypeDto = new BaseQuestionSetReferentialLinkTypeDto(
            response.questionSetReferentialLinkTypeResponse
        );
    }

    public static constructFromResponses = (
        responses: AdhocQuestionSetAssignmentScheduleResponse[]
    ): AdhocQuestionSetAssignmentScheduleDto[] =>
        responses.map((x) => new AdhocQuestionSetAssignmentScheduleDto(x));
}
