import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import styled from "styled-components";
import { ChecklistQuestions, Common } from "../../core/constants/translation-namespace";
import {
    maxContentWidthSelectStyle,
    SectionVerticalSpace,
    StyledFormLabel,
} from "../../core/theme/global-styles";
import { PeriodTypeOptions } from "../../core/utilities/QuestionSetValues";
import { ReactSelectDto, valuesToReactSelectDtos } from "../../domain/dtos/common/react-select-dto";
import { PeriodType } from "../../domain/enums/PeriodType";
import { SbSelect } from "../atoms/input/SbSelect";
import SbFormTimeFieldGroup from "../atoms/SbFormTimeFieldGroup";
import SbLabelText from "../atoms/SbLabelText";
import { translateText } from "../helpers/translate";
import SbFormCheckFieldGroup from "./input/SbFormCheckFieldGroup";

const StyledDivFormField = styled.div`
    display: inline-block;
    padding-right: ${(props) => props.theme.padding.sm};
`;

const StyledInput = styled.input`
display: inline-block;
    width: ${(props) => props.theme.dimensions.width.xxxl};
    height: ${(props) => props.theme.dimensions.height.xl};
    background-color:  ${(props) => props.theme.palette.purewhite};
    background-image: none;
    border: ${(props) => props.theme.padding.xxs} solid ${(props) => props.theme.palette.grey};
    border-radius: 0.5rem;
    color: inherit;
    transition: border-color .15s
    font-size: ${(props) => props.theme.font.md};
    text-align: center; 
`;

const StyledInputLong = styled.input`
display: inline-block;
    width: ${(props) => props.theme.dimensions.width.xxxxxl};
    height: ${(props) => props.theme.dimensions.height.xl};
    background-color:  ${(props) => props.theme.palette.purewhite};
    background-image: none;
    border: ${(props) => props.theme.padding.xxs} solid ${(props) => props.theme.palette.grey};
    border-radius: 0.5rem;
    color: inherit;
    transition: border-color .15s
    font-size: ${(props) => props.theme.font.md};
    text-align: center; 
`;

const viewableTimePeriodOptions: string[] = ["Hours", "Days"];

const holidayRollOverOptions: string[] = [
    "None",
    "Next working weekday",
    "Next working weekday incl Saturday",
    "Next day",
    "Previous working weekday",
    "Previous working weekday incl Saturday",
    "Previous day",
];

export const HolidayRollOverSchedule: React.FC<{
    handleViewableFromNumberChange: React.ChangeEventHandler<HTMLInputElement>;
    handleViewableFromDropDownChange: (item: SingleValue<{ value: string; label: string }>) => void;
    handleBaseStartTimeOfDayChange: React.ChangeEventHandler<HTMLInputElement>;
    handleLockoutOption: (_name: string, _checked: boolean) => void;
    selectedLockoutOption: boolean;
    handleMaxOccurrencesChange: React.ChangeEventHandler<HTMLInputElement>;
    handleHolidayRollOverChange: (item: SingleValue<{ value: string; label: string }>) => void;
    handleLockoutNumberAfterDue: React.ChangeEventHandler<HTMLInputElement>;
    handleLockoutOptionAfterDue: (item: SingleValue<{ value: string; label: string }>) => void;
    baseStartTimeOfDay?: string;
    viewableFromNumber?: number;
    viewableTimePeriod?: string;
    lockoutNumber?: number;
    lockoutOption?: string;
    maxOccurrences?: number;
    holidayRollOverOption?: string;
}> = ({
    handleViewableFromNumberChange,
    handleViewableFromDropDownChange,
    handleBaseStartTimeOfDayChange: handleBaseStartTimeOfDayChange,
    handleLockoutOption,
    selectedLockoutOption,
    handleMaxOccurrencesChange,
    handleHolidayRollOverChange,
    handleLockoutNumberAfterDue,
    handleLockoutOptionAfterDue,
    baseStartTimeOfDay: baseStartTimeOfDay,
    viewableFromNumber,
    viewableTimePeriod,
    lockoutNumber,
    lockoutOption,
    maxOccurrences,
    holidayRollOverOption,
}) => {
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    return (
        <>
            <SbFormTimeFieldGroup
                label={t("BaseStartTimeOfDay")}
                name={"baseStartTimeOfDay"}
                required
                defaultValue={baseStartTimeOfDay}
                handleTimeChange={handleBaseStartTimeOfDayChange}
            />

            <Form.Group as={Row} className="mb-3">
                <StyledFormLabel column sm={2}>
                    <SbLabelText label={t("ViewableTimeBeforeBaseStart")} />
                </StyledFormLabel>
                <Col md={"auto"}>
                    <StyledDivFormField>
                        <StyledInput
                            name={"viewableFromNumber"}
                            key={viewableFromNumber}
                            type={"Number"}
                            onChange={handleViewableFromNumberChange}
                            defaultValue={viewableFromNumber ?? 1}
                        />
                    </StyledDivFormField>

                    <StyledDivFormField>
                        <SbSelect
                            styles={maxContentWidthSelectStyle}
                            name={"viewableTimePeriod"}
                            placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                            searchable={false}
                            clearable={false}
                            items={valuesToReactSelectDtos(viewableTimePeriodOptions)}
                            onChange={handleViewableFromDropDownChange}
                            itemLabel={(option: ReactSelectDto<string>) =>
                                t(option.label, { keyPrefix: Common })
                            }
                            itemValue={(option: ReactSelectDto<string>) => option.value}
                            defaultSelectedItem={
                                viewableTimePeriod
                                    ? valuesToReactSelectDtos(viewableTimePeriodOptions).find(
                                          (x) => x.value === viewableTimePeriod
                                      )
                                    : valuesToReactSelectDtos(viewableTimePeriodOptions).find(
                                          (x) =>
                                              x.value ===
                                              PeriodTypeOptions.find(
                                                  (x) => x.value === PeriodType.Hours
                                              )!.label
                                      )
                            }
                        />
                    </StyledDivFormField>
                    <StyledFormLabel>
                        {t("Before", { keyPrefix: Common }).toLocaleLowerCase()}
                    </StyledFormLabel>
                </Col>
            </Form.Group>

            <SbFormCheckFieldGroup
                fieldLabel={t("IsLockoutEnabled")}
                type={"checkbox"}
                values={[
                    {
                        name: "lockoutEnabled",
                        label: "",
                        defaultSelected: selectedLockoutOption,
                        onChangeHandler: handleLockoutOption,
                    },
                ]}
            />

            {selectedLockoutOption && (
                <Form.Group as={Row} className="mb-3">
                    <StyledFormLabel column sm={2}>
                        <SbLabelText label={t("LockoutMinutesAfterDue")} />
                    </StyledFormLabel>
                    <Col md={"auto"}>
                        <StyledDivFormField>
                            <StyledInput
                                name={"lockoutInterval"}
                                key={viewableFromNumber}
                                type={"Number"}
                                onChange={handleLockoutNumberAfterDue}
                                defaultValue={lockoutNumber}
                            />
                        </StyledDivFormField>
                        <StyledDivFormField>
                            <SbSelect
                                styles={maxContentWidthSelectStyle}
                                name={"lockoutPeriod"}
                                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                                searchable={false}
                                clearable={false}
                                items={valuesToReactSelectDtos(viewableTimePeriodOptions)}
                                onChange={handleLockoutOptionAfterDue}
                                itemLabel={(option: ReactSelectDto<string>) =>
                                    t(option.label, { keyPrefix: Common })
                                }
                                itemValue={(option: ReactSelectDto<string>) => option.value}
                                defaultSelectedItem={
                                    lockoutOption
                                        ? valuesToReactSelectDtos(viewableTimePeriodOptions).find(
                                              (x) => x.value === lockoutOption
                                          )
                                        : valuesToReactSelectDtos(viewableTimePeriodOptions).find(
                                              (x) =>
                                                  x.value ===
                                                  PeriodTypeOptions.find(
                                                      (x) => x.value === PeriodType.Hours
                                                  )!.label
                                          )
                                }
                            />
                        </StyledDivFormField>
                    </Col>
                </Form.Group>
            )}

            <Form.Group as={Row} className="mb-3">
                <StyledFormLabel column sm={2}>
                    <SbLabelText label={t("MaxOccurrences")} />
                </StyledFormLabel>
                <Col md={"auto"}>
                    <StyledDivFormField>
                        <StyledInputLong
                            type="number"
                            onChange={handleMaxOccurrencesChange}
                            defaultValue={maxOccurrences}
                            key={maxOccurrences}
                        />
                    </StyledDivFormField>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
                <StyledFormLabel column sm={2}>
                    <SbLabelText label={t("HolidayRollover")} required />
                </StyledFormLabel>
                <Col md={"auto"}>
                    <StyledDivFormField>
                        <SbSelect
                            styles={maxContentWidthSelectStyle}
                            name={"holidayRollOver"}
                            placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                            searchable={false}
                            clearable={false}
                            items={valuesToReactSelectDtos(holidayRollOverOptions)}
                            onChange={handleHolidayRollOverChange}
                            itemLabel={(option: ReactSelectDto<string>) =>
                                translateText(t, option.label, Common)
                            }
                            itemValue={(option: ReactSelectDto<string>) => option.value}
                            defaultSelectedItem={valuesToReactSelectDtos(
                                holidayRollOverOptions
                            ).find((x) => x.value === holidayRollOverOption)}
                        />
                    </StyledDivFormField>
                </Col>
            </Form.Group>

            <SectionVerticalSpace />
        </>
    );
};
