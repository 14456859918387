import RiskLevel from "../../enums/associated-risks/risk-level";
import AssociatedRiskResponse from "../../responses/associated-risks/associated-risk-response";
import { Response } from "../../responses/common/response-response";

export default class AssociatedRiskDto {
    associatedRiskId: number;
    riskLevel: RiskLevel;
    name: string;
    tooltip: string;
    parentAssociatedRiskId: number | null;

    public constructor(response: AssociatedRiskResponse) {
        this.associatedRiskId = response.associatedRiskId;
        this.riskLevel = response.riskLevel;
        this.name = response.name;
        this.tooltip = response.tooltip;
        this.parentAssociatedRiskId = response.parentAssociatedRiskId;
    }

    public static constructFromResponse = (
        response: Response<AssociatedRiskResponse>
    ): AssociatedRiskDto => new AssociatedRiskDto(response.data);

    public static toAssociatedRiskResponses = (
        response: Response<AssociatedRiskResponse[]>
    ): AssociatedRiskDto[] => response?.data.map((x) => new AssociatedRiskDto(x));
}
