import CreateQuestionSetInstanceAnswerReviewRequest from "../../../domain/requests/question-set-instance-answers/create-question-set-instance-answer-review-request";
import { Response } from "../../../domain/responses/common/response-response";
import client from "../../http-client";

const route = "questionsetinstanceanswerreviews";

export const createQuestionSetInstanceAnswerReview = async (
    prefixUrl: string,
    request: CreateQuestionSetInstanceAnswerReviewRequest
): Promise<Response<number | null>> =>
    await client
        .post(`${route}/create`, {
            prefixUrl: `${prefixUrl}`,
            body: JSON.stringify(request),
        })
        .json();
