import React from "react";
import { Col, Row } from "react-bootstrap";
import { FaShare } from "react-icons/fa";
import styled from "styled-components";

const StyledRow = styled(Row)`
    padding-left: 0;
`;

const StyledCol = styled(Col)`
    display: flex;
    flex-direction: row;
    gap: ${(props) => props.theme.padding.md};
    background: ${(props) => props.theme.palette.danger};
    color: white;
    padding-top: ${(props) => props.theme.padding.sm};
    padding-bottom: ${(props) => props.theme.padding.sm};
    padding-left: ${(props) => props.theme.padding.md};
    padding-right: ${(props) => props.theme.padding.md};
`;

const StyledStatusLabel = styled.div`
    font-weight: bold;
    font-size: ${(props) => props.theme.font.md};
`;

const SbActionItemExtended: React.FC<{}> = () => (
    <StyledRow>
        <StyledCol md="auto">
            <StyledStatusLabel>Extended</StyledStatusLabel>
            <FaShare size={18} />
        </StyledCol>
    </StyledRow>
);

export default SbActionItemExtended;
