import { useMutation, UseMutationResult, useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import {
    associateExistingQuestionSet,
    getChecklistDetails,
} from "../../../data/services/checklists/checklist-service";
import { getQuestionSets } from "../../../data/services/question-set/question-set-service";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { FilterQuestionSetDto } from "../../dtos/question-sets/filter-question-set-dto";
import { createAssociateDissociateQuestionSetToChecklistRequest } from "../../requests/checklists/associate-dissociate-question-set-to-checklist-request";
import { createFilterQuestionSetRequestFromDto } from "../../requests/question-set/filter-question-set-request";
import { ChecklistResponse } from "../../responses/checklists/checklist-response";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { QuestionSetResponse } from "../../responses/question-sets/question-set-response";

export const useGetAssociateQuestionSets = (
    checklistId: number,
    dto: FilterQuestionSetDto
): [UseQueryResult<string, HTTPError>, UseQueryResult<PaginatedTableDto<number>, HTTPError>] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getChecklist", checklistId],
                queryFn: () => getChecklistDetails(url.baseUrl, checklistId),
                select: createChecklistDto,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getQuestionSets", dto],
                queryFn: () =>
                    getQuestionSets(url.baseUrl, createFilterQuestionSetRequestFromDto(dto)),
                keepPreviousData: true,
                select: transformToDataTableRows,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [UseQueryResult<string, HTTPError>, UseQueryResult<PaginatedTableDto<number>, HTTPError>];
};

const createChecklistDto = (response: Response<ChecklistResponse>): string => response.data.name;

const transformToDataTableRows = (
    response: Response<PaginationResponse<QuestionSetResponse>>
): PaginatedTableDto<number> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((questionSet) => ({
        metadata: questionSet.questionSetId,
        columns: [
            {
                // Name
                value: questionSet.name.toString(),
                type: DataTableColumnTypes.Link,
            },
            {
                // Process
                value: questionSet.process?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                // Schedule Description
                value: questionSet.description?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                // Questions Count
                value: questionSet.activeQuestionsCount.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                // Last Modified by
                value: questionSet.modifiedByUserFullName.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                // Last Modified Date
                value: toDateAndTimeFormat(questionSet.dateModifiedUtc.toString()),
                type: DataTableColumnTypes.Text,
            },
            {
                // Checklists Count
                value: questionSet.activeChecklistsCount.toString(),
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};

interface AssociateParameters {
    checklistId: number;
    questionSetIds: number[];
}

export const useAssociateExistingQuestionSet = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    AssociateParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: AssociateParameters) => {
        const request = createAssociateDissociateQuestionSetToChecklistRequest(
            mutationKey.checklistId,
            mutationKey.questionSetIds
        );

        return associateExistingQuestionSet(url.baseUrl, request);
    });
};
