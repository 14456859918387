import { Response } from "../../responses/common/response-response";
import { FunctionUserNodesResponse } from "../../responses/hierarchy/function-user-nodes-response";
import { BaseNodeDetailsDto, toBaseNodeDetailsDtos } from "./base-node-details-dto";

export default class FunctionUserNodesDto {
    assignedFunctionNodes: BaseNodeDetailsDto[];
    teamFunctionNodes: BaseNodeDetailsDto[];

    public constructor(response: FunctionUserNodesResponse) {
        const assignedFunctionNodes = response.assignedFunctionNodes;
        const teamFunctionNodes = response.teamFunctionNodes;

        this.assignedFunctionNodes = toBaseNodeDetailsDtos(assignedFunctionNodes);
        this.teamFunctionNodes = toBaseNodeDetailsDtos(teamFunctionNodes);
    }

    public static createFromResponse = (
        response: Response<FunctionUserNodesResponse>
    ): FunctionUserNodesDto => new FunctionUserNodesDto(response.data);
}
