import { createPaginationDto, defaultPaginationDto, PaginationDto } from "../common/pagination-dto";

export interface FilterReferentialLinkTypeDto extends PaginationDto {
    referentialLinkTypeName: string | null;
    referentialLinkValue: string | null;
    referentialLinkTypeId: number | null;
}

export const createFilterReferentialLinkTypesDto = (
    referentialLinkTypeName: string | null,
    referentialLinkValue: string | null,
    referentialLinkTypeId: number | null,
    pageNumber: number,
    pageSize: number,
    sortByColumn: string | null,
    sortByDescending: boolean
): FilterReferentialLinkTypeDto => ({
    referentialLinkTypeName: referentialLinkTypeName,
    referentialLinkValue: referentialLinkValue,
    referentialLinkTypeId: referentialLinkTypeId,
    ...createPaginationDto(pageNumber, pageSize, sortByColumn, sortByDescending),
});

export const defaultFilterReferentialLinkTypesDto = (): FilterReferentialLinkTypeDto => {
    const { pageNumber, pageSize, sortByColumn, sortByDescending } = defaultPaginationDto;

    return createFilterReferentialLinkTypesDto(
        null,
        null,
        null,
        pageNumber,
        pageSize,
        sortByColumn,
        sortByDescending
    );
};
