import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { Response } from "../../../domain/responses/common/response-response";
import { ChecklistResponse } from "../../responses/checklists/checklist-response";
import { QuestionSetResponse } from "../../responses/question-sets/question-set-response";
import BaseQuestionSetDto from "./base-question-set-dto";
import { QuestionSetScheduleBaseDto } from "./question-set-schedule-base-dto";

export interface QuestionSetDetailsDto extends BaseQuestionSetDto {
    isDeleted: boolean;
    isARMonitoring: boolean | null;
    dateCreatedLocal: string;
    dateModifiedLocal: string;
    createdByUserName: string;
    createdByUserId: number | null;
    modifiedByUserName: string;
    modifiedByUserId: number | null;
    createdByUserFullName: string;
    modifiedByUserFullName: string;
    questionSetDefaultScheduleDto: QuestionSetScheduleBaseDto;
    activeChecklistResponses: ChecklistResponse[];
    activeChecklistsCount: number;
    activeQuestionsCount: number;
}

export const createQuestionSetDetailsDto = (
    response: Response<QuestionSetResponse>
): QuestionSetDetailsDto => {
    const responseData = response.data;

    return {
        isDeleted: responseData.isDeleted,
        isARMonitoring: responseData.isARMonitoring,
        dateCreatedLocal: toDateAndTimeFormat(responseData.dateCreatedUtc.toString()),
        dateModifiedLocal: toDateAndTimeFormat(responseData.dateModifiedUtc.toString()),
        createdByUserName: responseData.createdByUserName,
        createdByUserId: responseData.createdByUserId,
        modifiedByUserName: responseData.modifiedByUserName,
        modifiedByUserId: responseData.modifiedByUserId,
        createdByUserFullName: responseData.createdByUserFullName,
        modifiedByUserFullName: responseData.modifiedByUserFullName,
        questionSetDefaultScheduleDto: QuestionSetScheduleBaseDto.constructFromResponse(
            responseData.questionSetDefaultScheduleResponse
        ),
        activeChecklistResponses: responseData.activeChecklistResponses,
        activeChecklistsCount: responseData.activeChecklistsCount,
        activeQuestionsCount: responseData.activeQuestionsCount,
        questionSetId: responseData.questionSetId,
        name: responseData.name,
        description: responseData.description,
        process: responseData.process,
    };
};

export const createQuestionSetDetailsDtoFromResponse = (
    response: QuestionSetResponse
): QuestionSetDetailsDto => {
    return {
        isDeleted: response.isDeleted,
        isARMonitoring: response.isARMonitoring,
        dateCreatedLocal: toDateAndTimeFormat(response.dateCreatedUtc.toString()),
        dateModifiedLocal: toDateAndTimeFormat(response.dateModifiedUtc.toString()),
        createdByUserName: response.createdByUserName,
        createdByUserId: response.createdByUserId,
        modifiedByUserName: response.modifiedByUserName,
        modifiedByUserId: response.modifiedByUserId,
        createdByUserFullName: response.createdByUserFullName,
        modifiedByUserFullName: response.modifiedByUserFullName,
        questionSetDefaultScheduleDto: QuestionSetScheduleBaseDto.constructFromResponse(
            response.questionSetDefaultScheduleResponse
        ),
        activeChecklistResponses: response.activeChecklistResponses,
        activeChecklistsCount: response.activeChecklistsCount,
        activeQuestionsCount: response.activeQuestionsCount,
        questionSetId: response.questionSetId,
        name: response.name,
        description: response.description,
        process: response.process,
    };
};
