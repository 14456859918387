import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import styled from "styled-components";
import { HorizontalForm } from "../../core/theme/global-styles";
import { SbButton } from "../atoms/SbButton";

const StyledDivider = styled.div`
    height: ${(props) => props.theme.padding.lg};
`;

const StyledFormGroupRow = styled(Row)`
    padding: 0;
    margin: 0;
`;

const StyledFormGroup = styled(Form.Group)`
    width: 100%;
`;

const StyledLabelCol = styled(Col)`
    display: flex;
    justify-content: flex-end;
    padding-right: ${(props) => props.theme.padding.lg};
    padding-top: ${(props) => props.theme.padding.sm};
`;

const StyledFormControlCol = styled(Col)`
    padding: 0;
`;

const LoginForm: React.FC<{
    handleLogin: (email: string, password: string) => void;
}> = ({ handleLogin }) => {
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);
        const email = formData.get("email") as string;
        const password = formData.get("password") as string;

        handleLogin(email, password);
    };

    return (
        <HorizontalForm onSubmit={handleSubmit}>
            <StyledFormGroup controlId="formBasicEmail">
                <StyledFormGroupRow>
                    <StyledLabelCol>
                        <Form.Label>
                            <b>Email *</b>
                        </Form.Label>
                    </StyledLabelCol>
                    <StyledFormControlCol>
                        <Form.Control name="email" type="email" placeholder="Enter email" />
                    </StyledFormControlCol>
                </StyledFormGroupRow>
            </StyledFormGroup>
            <StyledDivider />
            <StyledFormGroup controlId="formBasicPassword">
                <StyledFormGroupRow>
                    <StyledLabelCol>
                        <Form.Label>
                            <b>Password *</b>
                        </Form.Label>
                    </StyledLabelCol>
                    <StyledFormControlCol>
                        <Form.Control
                            name="password"
                            type="password"
                            placeholder="Password"
                            autoComplete="off"
                        />
                    </StyledFormControlCol>
                    <StyledDivider />
                </StyledFormGroupRow>
            </StyledFormGroup>

            <StyledDivider />
            <Container>
                <Row>
                    <Col md={{ span: 4, offset: 6 }}>
                        <SbButton variant="primary" type="submit" label="Log In" />
                    </Col>
                </Row>
            </Container>
        </HorizontalForm>
    );
};

export default LoginForm;
