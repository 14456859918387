import { Response } from "../../responses/common/response-response";
import { TimeZoneDetailsResponse } from "../../responses/timezone/time-zone-details-response";
import BaseTimeZoneDto from "./base-time-zone-dto";

export default class TimeZoneDetailsDto extends BaseTimeZoneDto {
    displayName: string;
    timeZoneNodeTypeValueId: number | null;

    public constructor(response: TimeZoneDetailsResponse) {
        super(response);

        this.displayName = response.displayName;
        this.timeZoneNodeTypeValueId = response.timeZoneNodeTypeValueId;
    }

    public static toTimeZoneDetailsDtos = (
        responses: Response<TimeZoneDetailsResponse[]>
    ): TimeZoneDetailsDto[] => responses.data.map((x) => new TimeZoneDetailsDto(x));
}
