import { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
    FaDownload,
    FaEdit,
    FaEye,
    FaPlusCircle,
    FaSave,
    FaSearch,
    FaSitemap,
    FaUpload,
} from "react-icons/fa";
import { IoCheckmark, IoCheckmarkDoneCircleOutline, IoCloseSharp } from "react-icons/io5";
import styled, { ThemeContext } from "styled-components";
import { Common, HierarchyTypes } from "../../core/constants/translation-namespace";
import SbCheckField from "../atoms/input/SbCheckField";
import SbFormCheckFieldGroup from "../molecules/input/SbFormCheckFieldGroup";

const StyledActionContainer = styled.div`
    color: ${(props) => props.theme.palette.primary};
    display: flex;
    justify-content: space-around;
    gap: ${(props) => props.theme.padding.md};
    cursor: pointer;
`;

const StyledDiv = styled.div`
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: ${(props) => props.theme.padding.sm};
`;

export interface RadioSelectProps {
    type: "radio";
    label?: string;
}

export interface DataTableActionProps<TType> {
    metadata: TType;
    selectedItem?: boolean;
    radioSelect?: RadioSelectProps;
    viewItem?: (metadata: TType) => void;
    searchItem?: (metadata: TType) => void;
    editItem?: (metadata: TType) => void;
    deleteItem?: (metadata: TType) => void;
    selectItem?: (metadata: TType) => void;
    addChild?: (metadata: TType) => void;
    viewInHierarchy?: (metadata: TType) => void;
    uploadFileItem?: (metadata: TType) => void;
    downloadFileItem?: (metadata: TType) => void;
    resolveItem?: (metadata: TType) => void;
    resolvedItem?: boolean;
    overrideItem?: (metadata: TType) => void;
}

export const DataTableAction = <TType,>({
    metadata,
    selectedItem,
    radioSelect,
    viewItem,
    searchItem,
    editItem,
    deleteItem,
    selectItem,
    addChild,
    viewInHierarchy,
    uploadFileItem,
    downloadFileItem,
    resolveItem,
    resolvedItem,
    overrideItem,
}: DataTableActionProps<TType>): JSX.Element => {
    const themeContext = useContext(ThemeContext);
    const { t } = useTranslation("translation", { keyPrefix: HierarchyTypes });
    return (
        <StyledActionContainer>
            {viewItem && (
                <FaEye size={themeContext!.padding.lg} onClick={() => viewItem(metadata)} />
            )}
            {searchItem && (
                <FaSearch size={themeContext!.padding.lg} onClick={() => searchItem(metadata)} />
            )}
            {editItem && (
                <FaEdit size={themeContext!.padding.lg} onClick={() => editItem(metadata)} />
            )}
            {deleteItem && (
                <IoCloseSharp
                    color={themeContext!.palette.danger}
                    size={themeContext!.padding.lg}
                    onClick={() => deleteItem(metadata)}
                />
            )}
            {selectItem && (
                <StyledDiv>
                    {radioSelect ? (
                        <SbCheckField
                            type={radioSelect.type}
                            label={radioSelect?.label}
                            name={"selectItem"}
                            key={`selectItem ${metadata}`}
                            defaultSelected={selectedItem}
                            onChangeHandler={() => selectItem(metadata)}
                        />
                    ) : (
                        <SbFormCheckFieldGroup
                            fieldLabel=""
                            type="checkbox"
                            values={[
                                {
                                    name: `selectItem ${metadata}`,
                                    onChangeHandler: () => selectItem(metadata),
                                    defaultSelected: selectedItem,
                                },
                            ]}
                        />
                    )}
                </StyledDiv>
            )}
            {addChild && (
                <StyledDiv onClick={() => addChild(metadata)}>
                    <FaPlusCircle
                        color={themeContext!.palette.primary}
                        size={themeContext!.padding.lg}
                    />
                    {t("AddChild", { keyPrefix: Common })}
                </StyledDiv>
            )}
            {viewInHierarchy && (
                <StyledDiv onClick={() => viewInHierarchy(metadata)}>
                    <FaSitemap
                        color={themeContext!.palette.primary}
                        size={themeContext!.padding.lg}
                    />
                    {t("ViewInTreeView")}
                </StyledDiv>
            )}
            {uploadFileItem && (
                <FaUpload size={themeContext!.padding.lg} onClick={() => uploadFileItem(metadata)} />
            )}
            {downloadFileItem && (
                <FaDownload
                    size={themeContext!.padding.lg}
                    onClick={() => downloadFileItem(metadata)}
                />
            )}
            {resolveItem &&
                (!resolvedItem ? (
                    <IoCheckmark
                        color={themeContext!.palette.success}
                        size={themeContext!.padding.lg}
                        onClick={() => resolveItem(metadata)}
                    />
                ) : (
                    <IoCheckmarkDoneCircleOutline
                        color={themeContext!.palette.success}
                        size={themeContext!.padding.lg}
                    />
                ))}
            {overrideItem && (
                <StyledDiv onClick={() => overrideItem(metadata)}>
                    <FaSave color={themeContext!.palette.primary} size={themeContext!.padding.lg} />
                    Override
                </StyledDiv>
            )}
        </StyledActionContainer>
    );
};
