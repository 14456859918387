import { FilterQuestionCategoryDto } from "../../dtos/question-categories/filter-question-category-dto";
import {
    buildPaginationSearchParameters,
    createPaginationRequest,
    PaginationRequest,
} from "../common/pagination-request";

export interface FilterQuestionCategoryRequest extends PaginationRequest {
    questionCategoryName: string | null;
}

export const createFilterQuestionCategoryRequestFromDto = (
    dto: FilterQuestionCategoryDto
): FilterQuestionCategoryRequest => {
    const { pageNumber, pageSize, sortByColumn, sortByDescending } = dto;

    return {
        ...dto,
        ...createPaginationRequest(pageNumber, pageSize, sortByColumn, sortByDescending),
    };
};

export const buildFilterSearchParameters = (
    request: FilterQuestionCategoryRequest
): URLSearchParams => {
    const searchParams = buildPaginationSearchParameters(request);
    if (request.questionCategoryName) {
        searchParams.set("questionCategoryName", request.questionCategoryName);
    }

    return searchParams;
};
