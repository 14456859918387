import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    editSchedule,
    getChecklistAssignmentDetails,
} from "../../../data/services/checklist-assignment/checklist-assignment-service";
import EditScheduleDto from "../../dtos/checklist-assignment/edit-schedule-dto";
import ScheduleDto from "../../dtos/checklist-assignment/schedule-dto";
import { QuestionSetScheduleRequest } from "../../requests/question-set/create-question-set-schedule-request";
import { ChecklistAssignmentDetailsResponse } from "../../responses/checklist-assignment/checklist-assignment-details-response";
import { Response } from "../../responses/common/response-response";

export const useGetScheduleDetails = (
    checklistAssignmentId: number,
    questionSetAssignmentScheduleId: number
): UseQueryResult<ScheduleDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getChecklistAssignmentDetails", checklistAssignmentId],
        () => getChecklistAssignmentDetails(url.baseUrl, checklistAssignmentId),
        {
            select: (response: Response<ChecklistAssignmentDetailsResponse>) =>
                ScheduleDto.constructFromResponse(questionSetAssignmentScheduleId, response),
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useEditSchedule = (
    questionSetScheduleRequest: QuestionSetScheduleRequest
): UseMutationResult<Response<boolean>, HTTPError, EditScheduleDto> => {
    const url = useUrl();

    return useMutation((dto: EditScheduleDto) => {
        const request = {
            ...dto,
            questionSetScheduleRequest: questionSetScheduleRequest,
        };

        return editSchedule(url.baseUrl, request);
    });
};
