import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import { ChecklistAnswers, Common } from "../../../core/constants/translation-namespace";
import {
    ContentContainer,
    EndAlignedDiv,
    SectionContainer,
} from "../../../core/theme/global-styles";
import { ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import AnswerCategory from "../../../domain/enums/answer-types/answer-category";
import { ResetButton, SearchButton } from "../../atoms/SbButton";
import { FilterInput } from "../../molecules/FilterInput";
import { FilterSelect } from "../../molecules/FilterSelect";

export const AnswerTypesFilter: React.FC<{
    answerTypeName: string | null;
    changeAnswerTypeName: (checklistName: string) => void;
    answerCategory: ReactSelectDto<AnswerCategory>;
    answerCategories: ReactSelectDto<AnswerCategory>[];
    changeAnswerCategories: (option: SingleValue<ReactSelectDto<AnswerCategory>>) => void;
    search: () => void;
    resetFilter: () => void;
}> = ({
    answerTypeName,
    changeAnswerTypeName,
    answerCategory,
    answerCategories,
    changeAnswerCategories: changeAnswerCategories,
    search,
    resetFilter,
}) => {
    const { t } = useTranslation("translation", { keyPrefix: ChecklistAnswers });

    return (
        <>
            <ContentContainer>
                <SectionContainer>
                    <Row>
                        <Col>
                            <FilterInput
                                title={t("Name")}
                                name="answerTypeName"
                                type="text"
                                onChangeEventHandler={changeAnswerTypeName}
                                value={answerTypeName ?? ""}
                            />
                        </Col>
                        <Col>
                            <FilterSelect
                                name="answerCategoryId"
                                label={t("AnswerCategory")}
                                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                                searchable
                                clearable
                                items={answerCategories ?? []}
                                itemDisplayText={(option: ReactSelectDto<AnswerCategory>) =>
                                    t(AnswerCategory[option.value])
                                }
                                onChange={changeAnswerCategories}
                                value={answerCategory.value != null ? answerCategory : null}
                            />
                        </Col>
                    </Row>
                </SectionContainer>
                <EndAlignedDiv>
                    <SearchButton onClick={search} />
                    <ResetButton onClick={resetFilter} />
                </EndAlignedDiv>
            </ContentContainer>
        </>
    );
};
