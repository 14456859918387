import { Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { StyledFormLabel } from "../../../core/theme/global-styles";
import SbCheckField, { CheckboxFieldProps } from "../../atoms/input/SbCheckField";
import SbFormToolTip from "../../atoms/SbFormToolTip";

const StyledFormGroup = styled(Form.Group)`
    display: flex;
    align-items: center;
`;

const SbFormCheckFieldGroup: React.FC<{
    fieldLabel: string;
    type: "checkbox" | "radio" | "switch";
    values: CheckboxFieldProps[];
    toolTip?: string | null;
    useAutoColumnWidth?: boolean | null;
}> = ({ fieldLabel, type, values, toolTip, useAutoColumnWidth }) => {
    return (
        <StyledFormGroup as={Row} className="mb-3">
            <StyledFormLabel column sm={2}>
                {fieldLabel}
            </StyledFormLabel>
            <Col sm={useAutoColumnWidth ? "auto" : 4}>
                {values.map((x) => (
                    <SbCheckField
                        type={type}
                        name={x.name}
                        key={x.name}
                        label={x.label}
                        defaultSelected={x.defaultSelected}
                        disabled={x.disabled}
                        onChangeHandler={x.onChangeHandler}
                    />
                ))}
            </Col>
            {toolTip && (
                <Col sm={1}>
                    <SbFormToolTip toolTip={toolTip} />
                </Col>
            )}
        </StyledFormGroup>
    );
};

export default SbFormCheckFieldGroup;
