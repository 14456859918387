import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import styled from "styled-components";
import { ChecklistQuestions, Common } from "../../core/constants/translation-namespace";
import {
    disabledSelectStyles,
    maxContentWidthSelectStyle,
    StyledDisabledProps,
    StyledFormLabel,
} from "../../core/theme/global-styles";
import { PeriodTypeOptions } from "../../core/utilities/QuestionSetValues";
import { ReactSelectDto, valuesToReactSelectDtos } from "../../domain/dtos/common/react-select-dto";
import { PeriodType } from "../../domain/enums/PeriodType";
import { SbSelect } from "../atoms/input/SbSelect";
import SbLabelText from "../atoms/SbLabelText";
import SbFormCheckFieldGroup from "./input/SbFormCheckFieldGroup";

const StyledDivFormField = styled.div`
    display: inline-block;
    padding-right: ${(props) => props.theme.padding.sm};
`;

const StyledDivConditionalField = styled.div`
    display: inline-block;
    padding-top: ${(props) => props.theme.padding.lg};
    text-align: right;
    vertical-align: middle;
    margin-bottom: 0;
    box-sizing: border-box;
    padding-right: ${(props) => props.theme.padding.sm};
    overflow: visible;
`;

const StyledInput = styled.input<StyledDisabledProps>`
    display: inline-block;
    width: ${(props) => props.theme.dimensions.width.xxxl};
    height: ${(props) => props.theme.dimensions.height.xl};
    pointer-events: ${(props) => (props.$disabledStyling ? "none" : "auto")};
    background-color: ${(props) =>
        props.$disabledStyling ? props.theme.palette.disableGrey : props.theme.palette.purewhite};
    background-image: none;
    border: ${(props) => props.theme.padding.xxs} solid ${(props) => props.theme.palette.grey};
    border-radius: 0.5rem;
    color: inherit;
    transition: border-color .15s
    font-size: ${(props) => props.theme.font.md};
    text-align: center; 
`;

const viewableTimePeriodOptions: string[] = ["Hours", "Days"];

export const AdhocHolidayRollOverSchedule: React.FC<{
    handleLockoutOption: (_name: string, _checked: boolean) => void;
    selectedLockoutOption: boolean;
    handleLockoutNumberAfterDue: React.ChangeEventHandler<HTMLInputElement>;
    handleLockoutOptionAfterDue: (item: SingleValue<{ value: string; label: string }>) => void;
    lockoutNumber?: number;
    lockoutOption?: string;
    viewableFromNumber?: number;
    viewableTimePeriod?: string;
    handleViewableFromNumberChange: React.ChangeEventHandler<HTMLInputElement>;
    handleViewableFromDropDownChange: (item: SingleValue<{ value: string; label: string }>) => void;
    arMonitoring?: boolean;
}> = ({
    handleLockoutOption,
    selectedLockoutOption,
    handleLockoutNumberAfterDue,
    handleLockoutOptionAfterDue,
    lockoutNumber,
    lockoutOption,
    viewableFromNumber,
    viewableTimePeriod: viewableTimePeriod,
    handleViewableFromNumberChange,
    handleViewableFromDropDownChange,
    arMonitoring,
}) => {
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    return (
        <>
            <Form.Group as={Row} className="mb-3">
                <StyledFormLabel column sm={2}>
                    <SbLabelText label={t("ViewableTimeBeforeBaseStart")} />
                </StyledFormLabel>
                <Col md={"auto"}>
                    <StyledDivFormField>
                        <StyledInput
                            name={"viewableFromNumber"}
                            key={viewableFromNumber}
                            type={"Number"}
                            onChange={handleViewableFromNumberChange}
                            defaultValue={viewableFromNumber ?? 1}
                            $disabledStyling={arMonitoring}
                            value={arMonitoring ? "1" : undefined}
                        />
                    </StyledDivFormField>

                    <StyledDivFormField>
                        <SbSelect
                            styles={
                                arMonitoring ? disabledSelectStyles : maxContentWidthSelectStyle
                            }
                            name={"viewableTimePeriod"}
                            key={"Viewable from time period for new question set"}
                            placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                            searchable={false}
                            clearable={false}
                            items={
                                arMonitoring
                                    ? valuesToReactSelectDtos(viewableTimePeriodOptions).filter(
                                          (x) =>
                                              x.value ===
                                              PeriodTypeOptions.find(
                                                  (x) => x.value === PeriodType.Days
                                              )!.label
                                      )
                                    : valuesToReactSelectDtos(viewableTimePeriodOptions)
                            }
                            onChange={handleViewableFromDropDownChange}
                            itemLabel={(option: ReactSelectDto<string>) =>
                                t(option.label, { keyPrefix: Common })
                            }
                            itemValue={(option: ReactSelectDto<string>) => option.value}
                            defaultSelectedItem={
                                viewableTimePeriod
                                    ? valuesToReactSelectDtos(viewableTimePeriodOptions).find(
                                          (x) => x.value === viewableTimePeriod
                                      )
                                    : valuesToReactSelectDtos(viewableTimePeriodOptions).find(
                                          (x) =>
                                              x.value ===
                                              PeriodTypeOptions.find(
                                                  (x) =>
                                                      x.value ===
                                                      (arMonitoring
                                                          ? PeriodType.Days
                                                          : PeriodType.Hours)
                                              )!.label
                                      )
                            }
                        />
                    </StyledDivFormField>
                    <StyledFormLabel>
                        {t("Before", { keyPrefix: Common }).toLocaleLowerCase()}
                    </StyledFormLabel>
                </Col>
            </Form.Group>

            <SbFormCheckFieldGroup
                fieldLabel={t("IsLockoutEnabled")}
                type={"checkbox"}
                values={[
                    {
                        name: "lockoutEnabled",
                        label: "",
                        defaultSelected: selectedLockoutOption,
                        onChangeHandler: handleLockoutOption,
                    },
                ]}
            />

            {selectedLockoutOption && (
                <Form.Group as={Row} className="mb-3">
                    <StyledFormLabel column sm={2}>
                        <SbLabelText label={t("LockoutMinutesAfterDue")} />
                    </StyledFormLabel>
                    <Col md={"auto"}>
                        <StyledDivConditionalField>
                            <StyledInput
                                name={"lockoutInterval"}
                                key={viewableFromNumber}
                                type={"Number"}
                                onChange={handleLockoutNumberAfterDue}
                                defaultValue={lockoutNumber}
                            />
                        </StyledDivConditionalField>
                        <StyledDivConditionalField>
                            <SbSelect
                                styles={maxContentWidthSelectStyle}
                                name={"lockoutPeriod"}
                                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                                searchable={false}
                                clearable={false}
                                items={valuesToReactSelectDtos(viewableTimePeriodOptions)}
                                onChange={handleLockoutOptionAfterDue}
                                itemLabel={(option: ReactSelectDto<string>) =>
                                    t(option.label, { keyPrefix: Common })
                                }
                                itemValue={(option: ReactSelectDto<string>) => option.value}
                                defaultSelectedItem={
                                    lockoutOption
                                        ? valuesToReactSelectDtos(viewableTimePeriodOptions).find(
                                              (x) => x.value === lockoutOption
                                          )
                                        : valuesToReactSelectDtos(viewableTimePeriodOptions).find(
                                              (x) =>
                                                  x.value ===
                                                  PeriodTypeOptions.find(
                                                      (x) => x.value === PeriodType.Hours
                                                  )!.label
                                          )
                                }
                            />
                        </StyledDivConditionalField>
                    </Col>
                </Form.Group>
            )}
        </>
    );
};
