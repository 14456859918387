import AnswerFlagMode from "../../enums/answer-types/answer-flag-mode";

export interface CreatePredefinedAnswerRequest {
    answerTypeId: number;
    languageId: number;
    value: string;
    answerFlagMode: AnswerFlagMode;
    tooltipDescription: string | null;
}

export const createCreatePredefinedAnswerRequest = (
    answerTypeId: number,
    languageId: number,
    value: string,
    answerFlagModeId: number,
    tooltipDescription: string | null
): CreatePredefinedAnswerRequest => ({
    answerTypeId: answerTypeId,
    languageId: languageId,
    value: value,
    answerFlagMode: answerFlagModeId,
    tooltipDescription: tooltipDescription,
});
