export interface SearchUsersRequest {
    firstName: string | null;
    lastName: string | null;
    emailAddress: string | null;
    isDeleted: boolean | null;
    isLockedOut: boolean | null;
}

export const createEmptySearchUsersRequest = (): SearchUsersRequest => ({
    firstName: null,
    lastName: null,
    emailAddress: null,
    isDeleted: null,
    isLockedOut: null,
});

export const createSearchUsersRequest = (searchText: string): SearchUsersRequest => ({
    firstName: searchText,
    lastName: searchText,
    emailAddress: searchText,
    isDeleted: null,
    isLockedOut: null,
});

const SearchParameters = {
    firstName: "firstName",
    lastName: "lastName",
    emailAddress: "emailAddress",
    isDeleted: "isDeleted",
    isLockedOut: "isLockedOut",
};

export const buildSearchUsersSearchParameters = (
    request: SearchUsersRequest,
    searchParams: URLSearchParams
): URLSearchParams => {
    if (request.firstName) {
        searchParams.set(SearchParameters.firstName, request.firstName);
    }

    if (request.lastName) {
        searchParams.set(SearchParameters.lastName, request.lastName);
    }

    if (request.emailAddress) {
        searchParams.set(SearchParameters.emailAddress, request.emailAddress);
    }

    if (request.isDeleted != null) {
        searchParams.set(SearchParameters.isDeleted, request.isDeleted.toString());
    }

    if (request.isLockedOut != null) {
        searchParams.set(SearchParameters.isLockedOut, request.isLockedOut.toString());
    }

    return searchParams;
};
