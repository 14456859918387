import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { dissociateQuestionSet } from "../../../data/services/checklists/checklist-service";
import { getQuestionSetDetails } from "../../../data/services/question-set/question-set-service";
import { Response } from "../../../domain/responses/common/response-response";
import { createAssociateDissociateQuestionSetToChecklistRequest } from "../../requests/checklists/associate-dissociate-question-set-to-checklist-request";
import { QuestionSetResponse } from "../../responses/question-sets/question-set-response";

export const useGetDissociateQuestionSetDetails = (
    questionSetId: number
): UseQueryResult<string, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getQuestionSetDetails", questionSetId],
        () => getQuestionSetDetails(url.baseUrl, questionSetId),
        {
            select: toDissociateQuestionSetDetailsDto,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const toDissociateQuestionSetDetailsDto = (response: Response<QuestionSetResponse>): string =>
    response.data.name;

interface DissociateParameters {
    checklistId: number;
    questionSetIds: number[];
}

export const useDissociateQuestionSet = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    DissociateParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: DissociateParameters) => {
        const request = createAssociateDissociateQuestionSetToChecklistRequest(
            mutationKey.checklistId,
            mutationKey.questionSetIds
        );

        return dissociateQuestionSet(url.baseUrl, request);
    });
};
