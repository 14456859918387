import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import styled from "styled-components";
import { ChecklistQuestions, Common } from "../../core/constants/translation-namespace";
import { maxContentWidthSelectStyle } from "../../core/theme/global-styles";
import { DayOrdinalOptions, DaysOfTheWeekOptions } from "../../core/utilities/QuestionSetValues";
import { ReactSelectDto } from "../../domain/dtos/common/react-select-dto";
import { DayOrdinal } from "../../domain/enums/questions/question-sets/DayOrdinalNumber";
import { DaysOfWeek } from "../../domain/enums/questions/question-sets/DaysOfTheWeek";
import { NumberInputBox } from "../atoms/input/NumberInputBox";
import { SbSelect } from "../atoms/input/SbSelect";
import { translateWithFallback } from "../helpers/translate-with-common-fallback";

const StyledDivFormField = styled.div`
    display: inline-block;
    padding-top: ${(props) => props.theme.padding.md};
    text-align: right;
    vertical-align: middle;
    margin-bottom: 0;
    box-sizing: border-box;
    padding-right: ${(props) => props.theme.padding.sm};
`;

const StyledDivAdditionalInfo = styled.div`
    display: inline-block;
    padding-top: ${(props) => props.theme.padding.sm};
    text-align: right;
    vertical-align: top;
    margin-bottom: 10;
    padding-right: ${(props) => props.theme.padding.sm};
    padding-left: ${(props) => props.theme.padding.xs};
    font-size: ${(props) => props.theme.font.md};
    color: ${(props) => props.theme.palette.secondary};
`;

export const MonthlySchedule: React.FC<{
    handleMonthlyScheduleDescriptionChange: React.ChangeEventHandler<HTMLInputElement>;
    selectedMonthlyScheduleDescription: string;
    handleDayOfTheMonthChange: React.ChangeEventHandler<HTMLInputElement>;
    handleMonthsOfTheYearIntervalChange: React.ChangeEventHandler<HTMLInputElement>;
    handleDayOrdinalChange: (item: SingleValue<ReactSelectDto<DayOrdinal>>) => void;
    handleDayOfTheWeekChange: (item: SingleValue<ReactSelectDto<DaysOfWeek>>) => void;
    dayOfTheMonth?: number;
    monthOfTheYearInterval?: number;
    dayOfTheWeek?: string;
    dayOrdinal?: string;
}> = ({
    handleMonthlyScheduleDescriptionChange,
    selectedMonthlyScheduleDescription,
    handleDayOfTheMonthChange,
    handleMonthsOfTheYearIntervalChange,
    handleDayOrdinalChange,
    handleDayOfTheWeekChange,
    dayOfTheMonth,
    monthOfTheYearInterval,
    dayOfTheWeek,
    dayOrdinal,
}) => {
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    return (
        <>
            <Form.Group className="mb">
                <StyledDivFormField>
                    <Form.Check
                        type="radio"
                        label={t("Day")}
                        name="dayXofEveryXMonths"
                        defaultChecked={selectedMonthlyScheduleDescription === "dayXofEveryXMonths"}
                        checked={selectedMonthlyScheduleDescription === "dayXofEveryXMonths"}
                        onChange={handleMonthlyScheduleDescriptionChange}
                    />
                </StyledDivFormField>
                <StyledDivFormField>
                    <NumberInputBox
                        handleNumberInputChange={handleDayOfTheMonthChange}
                        defaultValue={dayOfTheMonth}
                    />
                    &nbsp; {t("OfEvery")?.toLowerCase()}&nbsp;
                    <NumberInputBox
                        handleNumberInputChange={handleMonthsOfTheYearIntervalChange}
                        defaultValue={monthOfTheYearInterval}
                    />
                    &nbsp; {t("MonthsPluralOptional")?.toLocaleLowerCase()}
                    <StyledDivAdditionalInfo>
                        {t("NonWorkingDayRolloverTypeAppliesInfoMessage")}
                    </StyledDivAdditionalInfo>
                </StyledDivFormField>
            </Form.Group>

            <StyledDivFormField>
                <Form.Check
                    type="radio"
                    label={t("The")}
                    name="theXOfEveryXMonth"
                    defaultChecked={selectedMonthlyScheduleDescription === "theXOfEveryXMonth"}
                    checked={selectedMonthlyScheduleDescription === "theXOfEveryXMonth"}
                    onChange={handleMonthlyScheduleDescriptionChange}
                />
            </StyledDivFormField>
            <StyledDivFormField>
                <SbSelect
                    styles={maxContentWidthSelectStyle}
                    name={"dayOrdinal"}
                    placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                    searchable
                    clearable
                    items={DayOrdinalOptions}
                    onChange={handleDayOrdinalChange}
                    itemLabel={(option: ReactSelectDto<DayOrdinal>) =>
                        translateWithFallback(t, DayOrdinal[option.value], ChecklistQuestions)
                    }
                    itemValue={(option: ReactSelectDto<DayOrdinal>) => option.value.toString()}
                    defaultSelectedItem={DayOrdinalOptions.find(
                        (x) => x.value.toString() === dayOrdinal
                    )}
                />
            </StyledDivFormField>
            <StyledDivFormField>
                <SbSelect
                    styles={maxContentWidthSelectStyle}
                    name={"dayOfTheWeek"}
                    placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                    searchable
                    clearable
                    items={DaysOfTheWeekOptions}
                    onChange={handleDayOfTheWeekChange}
                    itemLabel={(option: ReactSelectDto<DaysOfWeek>) =>
                        translateWithFallback(t, DaysOfWeek[option.value], ChecklistQuestions)
                    }
                    itemValue={(option: ReactSelectDto<DaysOfWeek>) => option.value.toString()}
                    defaultSelectedItem={DaysOfTheWeekOptions.find(
                        (x) => x.value.toString() === dayOfTheWeek
                    )}
                />
            </StyledDivFormField>
            <StyledDivFormField>
                &nbsp; {t("OfEvery")?.toLowerCase()}&nbsp;
                <NumberInputBox
                    handleNumberInputChange={handleMonthsOfTheYearIntervalChange}
                    defaultValue={monthOfTheYearInterval}
                />
                &nbsp; {t("MonthsPluralOptional")?.toLocaleLowerCase()}
            </StyledDivFormField>
        </>
    );
};
