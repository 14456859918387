import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import { ActionItems, Common } from "../../../core/constants/translation-namespace";
import {
    ContentContainer,
    EndAlignedDiv,
    SectionContainer,
} from "../../../core/theme/global-styles";
import { ActionInstanceStatusDto } from "../../../domain/dtos/actions/action-instance-status-dto";
import { ResetButton, SearchButton } from "../../atoms/SbButton";
import { translateWithFallback } from "../../helpers/translate-with-common-fallback";
import { FilterInput } from "../../molecules/FilterInput";
import { FilterSelect } from "../../molecules/FilterSelect";
import { FilterNumberInput } from "../../molecules/input/FilterNumberInput";

const filterAssignedActionsStyle = {
    border: "0.01rem solid rgba(0, 0, 0, 0.1)",
};

export const UserAssignedActionItemsFilter: React.FC<{
    actionItemName: string | null;
    onChangeActionItemName: (actionItemName: string) => void;
    actionItemId: string | null;
    onChangeActionItemId: (actionItemId: string) => void;
    actionInstanceStatus: ActionInstanceStatusDto | null;
    actionInstanceStatuses: ActionInstanceStatusDto[];
    onChangeActionInstanceStatus: (option: SingleValue<ActionInstanceStatusDto>) => void;
    search: () => void;
    resetFilter: () => void;
}> = ({
    actionItemName,
    onChangeActionItemName,
    actionItemId,
    onChangeActionItemId,
    actionInstanceStatus,
    actionInstanceStatuses,
    onChangeActionInstanceStatus,
    search,
    resetFilter,
}) => {
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });
    return (
        <ContentContainer style={filterAssignedActionsStyle}>
            <SectionContainer>
                <Row>
                    <Col>
                        <FilterNumberInput
                            title={t("ActionItemId")}
                            name={"actionItemId"}
                            onChangeEventHandler={onChangeActionItemId}
                            value={actionItemId ?? ""}
                            minValue={1}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("Name", { keyPrefix: Common })}
                            name={"actionItemName"}
                            type={"text"}
                            onChangeEventHandler={onChangeActionItemName}
                            value={actionItemName ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterSelect
                            name={"actionInstanceStatuses"}
                            label={`${t("Status", { keyPrefix: Common })}`}
                            placeholderText={"All"}
                            searchable
                            clearable={false}
                            items={actionInstanceStatuses}
                            itemDisplayText={(option: ActionInstanceStatusDto) =>
                                translateWithFallback(t, option.name, ActionItems)
                            }
                            onChange={onChangeActionInstanceStatus}
                            value={actionInstanceStatus}
                        />
                    </Col>
                </Row>
            </SectionContainer>
            <EndAlignedDiv>
                <SearchButton onClick={search} />
                <ResetButton onClick={resetFilter} />
            </EndAlignedDiv>
        </ContentContainer>
    );
};
