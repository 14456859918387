export interface FilterActionPlansDto {
    name: string | null;
    description: string | null;
}

export const createFilterActionPlansDto = (
    name: string | null,
    description: string | null
): FilterActionPlansDto => ({
    name: name,
    description: description,
});

export const defaultFilterActionPlansDto: FilterActionPlansDto = createFilterActionPlansDto(
    null,
    null
);
