import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { getNullableFormField } from "../../../core/utilities/formDataHelper";
import {
    editNodeTypeValue,
    getNodeTypeValueDetails,
} from "../../../data/services/hierarchy/node-type-values-service";
import { getTimeZones } from "../../../data/services/timezones/time-zones-service";
import {
    createNodeTypeValueDetailsDto,
    NodeTypeValueDetailsDto,
} from "../../dtos/hierarchy/node-type-value-details-dto";
import TimeZoneDetailsDto from "../../dtos/timezone/time-zone-details-dto";
import { getTimeZoneId } from "../../helpers/timezone/get-time-zone-id";
import { createEditNodeTypeValueRequest } from "../../requests/hierarchy/edit-node-type-value-request";
import { Response } from "../../responses/common/response-response";

export const useGetNodeTypeValueDetails = (
    nodeTypeValueId: number
): UseQueryResult<NodeTypeValueDetailsDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getNodeTypeValueDetails", nodeTypeValueId],
        () => getNodeTypeValueDetails(url.baseUrl, nodeTypeValueId),
        {
            select: createNodeTypeValueDetailsDto,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useGetTimeZones = (
    nodeTypeContainsTimeZoneCode: boolean | null
): UseQueryResult<TimeZoneDetailsDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(["getTimeZones"], () => getTimeZones(url.baseUrl), {
        enabled: nodeTypeContainsTimeZoneCode == true,
        select: TimeZoneDetailsDto.toTimeZoneDetailsDtos,
        onError: errorResponseToDisplayHandler,
    });
};

interface EditParameters {
    nodeTypeValueId: number;
    formData: FormData;
    timeZones: TimeZoneDetailsDto[] | null;
    roleIds: number[] | null;
}

export const createEditParameters = (
    nodeTypeValueId: number,
    formData: FormData,
    timeZones: TimeZoneDetailsDto[] | null,
    roleIds: number[] | null
): EditParameters => ({
    nodeTypeValueId: nodeTypeValueId,
    formData: formData,
    timeZones: timeZones,
    roleIds: roleIds,
});

export const useEditNodeTypeValue = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    EditParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: EditParameters) => {
        const { nodeTypeValueId, formData, timeZones, roleIds } = mutationKey;

        let code = getNullableFormField(formData.get("code") as string);
        const value = formData.get("value") as string;
        const description = getNullableFormField(formData.get("description") as string);

        if (timeZones != null) {
            code = getTimeZoneId(code!, timeZones);
        }

        const request = createEditNodeTypeValueRequest(
            nodeTypeValueId,
            value,
            code,
            description,
            roleIds
        );

        return editNodeTypeValue(url.baseUrl, request);
    });
};
