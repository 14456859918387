export const localDateSerialiserReplacer =
    (propertyNames: string[]) =>
    (propertyName: string, propertyValue: string): string => {
        if (propertyNames.includes(propertyName)) {
            if (!propertyValue) {
                return propertyValue;
            }

            const date = new Date(propertyValue);

            return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
                .toISOString()
                .slice(0, -1);
        }

        return propertyValue;
    };

//TODO: Update date field naming conventions with local/utc
