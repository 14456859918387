import IndicatorAnswerDto from "../../../domain/dtos/answer-capture/indicator-answer-dto";
import QuestionAnswerInstanceDto from "../../../domain/dtos/answer-capture/question-answer-instance-dto";
import AnswerTypeDto from "../../../domain/dtos/answer-types/answer-type-dto";
import PredefinedAnswerDto from "../../../domain/dtos/predefined-answers/predefined-answer-dto";
import AnswerCategory from "../../../domain/enums/answer-types/answer-category";
import AnswerCaptureInputValueInput from "./question-capture-input-value-input";
import AnswerCaptureMutuallyExclusiveInput from "./question-capture-mutually-exclusive-input";

const QuestionCaptureInput: React.FC<{
    answerTypeDto: AnswerTypeDto;
    questionAnswerInstanceDto: QuestionAnswerInstanceDto;
    onMutuallyExclusiveAnswerClicked?: (_: PredefinedAnswerDto) => void;
    onIndicatorAnswerChanged: <TType>(_: IndicatorAnswerDto<TType>) => void;
}> = ({
    answerTypeDto,
    questionAnswerInstanceDto,
    onMutuallyExclusiveAnswerClicked,
    onIndicatorAnswerChanged,
}) => {
    switch (answerTypeDto.answerCategory) {
        case AnswerCategory.MutuallyExclusive:
            return (
                <AnswerCaptureMutuallyExclusiveInput
                    answerTypeDto={answerTypeDto}
                    questionAnswerInstanceDto={questionAnswerInstanceDto}
                    onMutuallyExclusiveAnswerClicked={onMutuallyExclusiveAnswerClicked}
                />
            );
        case AnswerCategory.InputValue:
            return (
                <AnswerCaptureInputValueInput
                    answerTypeDto={answerTypeDto}
                    questionAnswerInstanceDto={questionAnswerInstanceDto}
                    onIndicatorAnswerChanged={onIndicatorAnswerChanged}
                />
            );
        default:
            return <></>;
    }
};

export default QuestionCaptureInput;
